import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	iconsImg: {
		width: 18,
		marginRight: 5,
	},
	txtPrincipales: {
		color: '#333333',
		fontSize: '15px !important',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		[theme.breakpoints.down('sm')]: {
			fontSize: '13px !important',
		},
	},
	divPrincipal: {
		padding: '40px 130px',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '30px',
		},
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: '20px 120px',
		paddingBlock: 50,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
		[theme.breakpoints.down('sm')]: {
			padding: '20px',
		},
	},
	divChecks: {
		paddingTop: '10px',
	},
	principalTitle: {
		fontSize: 26,
		textAlign: 'center',
		color: '#293990',
	},
	contentFile: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
		[theme.breakpoints.down('md')]: {},
	},
	contentFileDataLink: {
		fontSize: 14,
	},
	contentFileData: {
		display: 'flex',
		alignItems: 'center',
	},
	contentFileDataTitle: {
		fontSize: 16,
		minWidth: 250,
	},
	titleNoticeContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	contentFooterData: {
		maxWidth: 550,
		margin: '20px auto',
		textAlign: 'center',
	},
	contentFileDataButtons: {
		display: 'flex',
		justifyContent: 'center',
		marginBlock: 10,
		marginBottom: 10,
	},
	contentFooterDataLink: {
		color: '#D1001F',
		display: 'flex',
		fontSize: '15px',
		alignItems: 'center',
		justifyContent: 'center',
	},
	titleNotice: {
		marginTop: 0,
		fontSize: 22,
		color: '#293990',
	},
	subTitleNotice: {
		fontSize: 12,
		color: '#333333',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			textAlign: 'right',
			marginTop: 15,
		},
	},
}));
