import { Box } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

interface Props {
	listItem: Array<{
		title?: string | JSX.Element | JSX.Element[];
		description?: string | JSX.Element | JSX.Element[];
	}>;
}

const StepComponent = ({ listItem }: Props) => {
	return (
		<Box>
			{listItem.map((list, key) => (
				<Box display={'flex'} key={key}>
					<Box
						display={'flex'}
						alignItems={'center'}
						flexDirection={'column'}
					>
						<CheckCircleOutline
							style={{
								color: '#29398E',
								padding: 0,
								margin: 0,
								fontSize: 16,
								marginTop: '3px',
							}}
						/>

						{key < listItem.length - 1 && (
							<hr
								style={{
									color: 'rgba(41, 57, 142, 0.6)',
									height: '100%',
									width: 0,
									margin: 0,
									border: '0.5px dashed',
								}}
							/>
						)}
					</Box>
					<Box
						textAlign={'left'}
						marginLeft={1}
						color={'#333333'}
						fontSize={15}
						marginBottom={'10px'}
					>
						{list.title && (
							<span
								style={{
									display: 'block',
									color: '#7B7B7C',
									fontSize: 15,
								}}
							>
								{list.title}
							</span>
						)}
						{list.description}
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default StepComponent;
