export interface LoadingState {
	isLoading: boolean;
}

export const SET_START_LOADING = 'loading/startLoading';
export const SET_STOP_LOADING = 'loading/stopLoading';

export const INITIAL_STATE: LoadingState = {
	isLoading: false,
};
