import { Paper, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ModalLoading from '../../components/ModalLoading';
import { associateNssAPI } from '../../api/modules/AssociateNSS';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { RootState } from '../../store';
import { Base } from './Styles';
import CustomAlert from '../../components/CustomAlert';
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import { formatDate } from '../../utils/dates';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { useBitacora } from '../../hooks/useBitacora';

interface Props {
	setEtapa: React.Dispatch<React.SetStateAction<number>>;
	associated: any;
	setIsNew: React.Dispatch<React.SetStateAction<Boolean>>;
}

const ViewerNss = ({ setEtapa, associated, setIsNew }: Props) => {
	const { user } = useSelector((state: RootState) => state.session);
	const matches = useMediaQuery('(min-width:900px)');
	const classes = Base();
	const [alert, setAlert] = useState<any>({ message: '', color: '' });
	const [loader, setLoader] = useState(false);
	const { regBitacoraNotOld } = useBitacora();

	const handleSubmitAssociated = async () => {
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const USER_NSS_BASE64 = await encrypt(user?.nss?.toString() || '', key);
			const ASSOCIATED_NSS_BASE64 = await encrypt(associated?.nssSecundario.toString() || '', key);
			const ASSOCIATED_TYPE_ASSOS_BASE64 = await encrypt(associated?.tipoAsociacion.toString() || '', key);
			const ASSOCIATED_TYPE_RELATION_BASE64 = await encrypt(
				associated?.tipoRelacion || '',
				key
			);
			const ASSOCIATED_GENDER_BASE64 = await encrypt(
				associated?.genero || '',
				key
			);

			let REQ_BASE64 = {
				nss: USER_NSS_BASE64,
				nssSecundario: ASSOCIATED_NSS_BASE64,
				tipoAsociacion: ASSOCIATED_TYPE_ASSOS_BASE64,
				tipoRelacion: ASSOCIATED_TYPE_RELATION_BASE64,
				genero: ASSOCIATED_GENDER_BASE64,
			};

			let dataBitacora = {
				nss: user?.nss,
				nssSecundario: associated?.nssSecundario.toString(),
				tipoAsociacion: associated?.tipoAsociacion.toString(),
				tipoRelacion: associated?.tipoRelacion,
				genero: associated?.genero,
			}

			associateNssAPI.associatesNSS
				.post<
					{
						nss: string;
						nssSecundario: string;
						tipoAsociacion: string;
						tipoRelacion: string;
						genero: string;
					},
					any
				>('', REQ_BASE64)
				.then((res) => {
					if (res.error)
						setAlert({
							message: INTERNAL_MESSAGES.UNEXPECTED_ERROR,
							color: 'error',
						});
					else {
						if (res.msg?.nombre && res.msg?.description) {
							setAlert({ message: res.msg.description, color: 'success' });
							setEtapa(2);
							regBitacoraNotOld(associateNssAPI.associatesNSS.scheme, dataBitacora);
						} else setAlert({ message: res.msg.description || INTERNAL_MESSAGES.ERROR_MESSAGE, color: 'error' });
					}
				})
				.catch((error) => {
					setAlert({
						message: INTERNAL_MESSAGES.ERROR_MESSAGE,
						color: 'success',
					});
				})
				.finally(() => setLoader(false));
		} catch (error) {
			setAlert({
				message: INTERNAL_MESSAGES.ERROR_REQUEST,
				color: 'error',
			});
			setLoader(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loader} />
			<HeaderList
				title="Asocia tu NSS y suma créditos"
				date={formatDate('PP')}
			/>
			<Paper className={classes.papper}>
				<h4
					style={{
						fontSize: 16,
						marginBottom: 50,
						textAlign: 'center',
					}}
				>
					Los datos que tenemos registrados son:
				</h4>
				<div
					style={{
						textAlign: matches ? 'center' : 'left',
						paddingLeft: matches ? 60 : 0,
						paddingRight: matches ? 60 : 0,
					}}
				>
					<div className={classes.visorItem}>
						<label
							style={{ display: matches ? 'inline' : 'block' }}
						>
							Número de Seguridad Social
						</label>
						{associated?.nssSecundario}
					</div>
					<div className={classes.visorItem}>
						<label
							style={{ display: matches ? 'inline' : 'block' }}
						>
							Nombre
						</label>
						{associated?.nombre}
					</div>
					{/*<div className={classes.visorItem}>
						<label
							style={{ display: matches ? 'inline' : 'block' }}
						>
							CURP
						</label>
						{associated?.curp}
					</div>
					<div className={classes.visorItem}>
						<label
							style={{ display: matches ? 'inline' : 'block' }}
						>
							RFC
						</label>
						{associated?.rfc}
					</div> */}

					<h3
						style={{
							color: '#293990',
							textAlign: 'center',
							maxWidth: 440,
							margin: 'auto',
							marginTop: 40,
						}}
					>
						Tu asociado recibirá un correo de confirmación. Una vez
						aceptado, se unirán sus créditos.
					</h3>

					<p
						style={{
							color: '#636363',
							marginTop: 35,
							marginBottom: 35,
							display: 'block',
							textAlign: 'center',
						}}
					>
						¿Quieres asociar este NSS con el tuyo?
					</p>

					<CustomAlert
						message={alert.message}
						severity={'error'}
						show={Boolean(alert.message)}
					/>

					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: matches
								? 'inherit'
								: 'column-reverse',
						}}
					>
						<div
							style={{
								width: matches ? 200 : 'auto',
								margin: 10,
								display: matches ? 'inline-flex' : 'flex',
							}}
						>
							<CustomButton
								label={'No'}
								variant="outlined"
								onClick={() => setEtapa(0)}
								data-testid="buttonNo"
							/>
						</div>
						<div
							style={{
								width: matches ? 200 : 'auto',
								margin: 10,
								display: matches ? 'inline-flex' : 'flex',
							}}
						>
							<CustomButton
								data-testid="buttonSi"
								variant="solid"
								onClick={handleSubmitAssociated}
								label={'Sí'}
							/>
						</div>
					</div>
				</div>
			</Paper>
		</>
	);
};

export default ViewerNss;
