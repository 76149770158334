import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useController } from "../../hooks/useController";
import { RootState } from "../../../../store";
import { CREDIT_OPTIONS, getMappedOptions } from "../../utils/creditOptions.utils";
import { isEmpty } from "../../../../utils/validators";
import { IProductCard, ProductCard } from '../ProductCard/productCard';
import { actions as actionLoading } from "../../../../store/modules/loading";
import { encodeAllJSONData } from "../../../../utils/encrypt";
import { ITiposCredito } from "../../../../interfaces/creditRequest";
import { useState } from "react";
import { CODES_RESPONSE } from "../../../../config/miucConstants";
import { useAlertCustom } from "../../hooks/useAlertCustom";
import { apiMIUC } from "../../../../api/modules/MIUC";

export const useCreditOptions = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state: RootState) => state.session);

	const { addSelection, saveData, nextStep, getProgressStep, dataProcessController, returnStep } = useController();
	const { setAlertClean, AlertInput, setAlertCustom } = useAlertCustom();
	const [creditOptions, setCreditOptions] = useState<ITiposCredito[]>([])
	const { active, total } = getProgressStep();
	const newStep = {
		title: "Selecciona el destino en el que deseas tomar tu crédito",
		subtitle: "Elige el que más te convenga, tu elección nos ayudará a encontrar el que más se adapte a tus necesidades",
		active,
		total,
	}

	interface IParams {
		title: string;
		creditType: string;
	}

	const onSelectCreditType = async ({ title, creditType }: IParams) => {

		if (creditType === CREDIT_OPTIONS.SUMA_CREDITOS) {
			await saveData(
				{
					processInfo: {
						sumaCreditos: true
					},
				},
				true
			);
			navigate('/mi-tramite-credito/sumar-creditos');
		} else {
			addSelection({
				title: 'Modalidad de crédito:',
				description: title
			})
			await saveData({
				processInfo: {
					creditType
				},
				processData: {
					active: getProgressStep().active + 1,
				}
			})
			nextStep();
		}
	};

	const getCreditOptions = () => {
		const mappedCreditOptions = getMappedOptions(dataProcessController.processInfo?.credit);

		if (!isEmpty(dataProcessController?.conyuge)) {
			return mappedCreditOptions.filter(option => option.product !== CREDIT_OPTIONS.SUMA_CREDITOS)
				.map((card: IProductCard) => {
					const creditFound = creditOptions.find(credit => credit.codigo === card.product)
					return (
						<ProductCard
							key={card.product}
							product={card.product}
							icon={card.icon}
							title={card.title}
							description={card.description}
							onClick={() => onSelectCreditType({
								title: card.title as string,
								creditType: card.product
							})}
							disabled={!creditFound?.estatus}
						/>
					)
				})
		} else {
			const availableCredits = [
				CREDIT_OPTIONS.INDIVIDUAL,
				CREDIT_OPTIONS.SUMA_CREDITOS
			];
			return mappedCreditOptions.filter(option => availableCredits.includes(option.product))
				.map((card: IProductCard) => (
					<ProductCard
						key={card.product}
						product={card.product}
						icon={card.icon}
						title={card.title}
						description={card.description}
						onClick={() => onSelectCreditType({
							title: card.title as string,
							creditType: card.product
						})}
					/>
				))
		}
	}

	const handleGetCreditType = async () => {
		dispatch(actionLoading.startLoading());
		try {
			const conyugeData = isEmpty(dataProcessController.conyuge)
			? {}
			: {
				conyuge: {
					nss: dataProcessController.conyuge?.nss,
					tipoAsociacion: dataProcessController.conyuge?.tipoAsociacion,
					destinosAplicables:
						dataProcessController.conyuge.code === CODES_RESPONSE.CORRECTO
							? dataProcessController?.destinosAplicables
							: [],
					mevi: {
						stageId: dataProcessController.conyuge?.stageId?.toString(),
					},
				}
			}
			const data = {
				nss: user?.nss,
				destino: dataProcessController.processInfo.credit,
				...conyugeData,
			};
			const dataEncripted = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const result = await apiMIUC.consultarTiposCredito(dataEncripted);

			if (result.code === CODES_RESPONSE.CORRECTO) {
				setCreditOptions(result.data.creditos);
			}
			setAlertClean();
		} catch (error: any) {
			setAlertCustom({
				show: true,
				message: error.message ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			})
		} finally {
			dispatch(actionLoading.stopLoading());
		}
	};

	return {
		onSelectCreditType,
		newStep,
		returnStep,
		dataProcessController,
		getCreditOptions,
		handleGetCreditType,
		AlertInput,
	}
}