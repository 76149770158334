/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Material Components
import { Box } from '@mui/material';

//Components
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import BaseModal from '../../components/Modal';
import DeleteAcount from '../DeleteAccount';
import CustomAlert from '../../components/CustomAlert';
import Captcha from '../../components/Captcha';

// Resources
import { isNumber, nssValid } from '../../utils/expressions';

// Assets
import { useStyles } from './styles';

// Intefaces
import { IEventTab } from '../../interfaces/eventTab';

const NssView = (ActionsButtons: IEventTab) => {
	const [codeInput, setCodeInput] = useState('');
	const [code, setCode] = useState('');
	const [openModal, setopenModal] = useState(false);
	const [Value, setValue] = useState('');
	const [errorNSS, setErrorNSS] = useState({ valid: false, msg: '' });
	const [toHome, setToHome] = useState(false);
	const navigate = useNavigate();

	const handleOnChangeNSS = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { value } = event.target;
		if (!isNumber(value)) return;
		if (value.length > 11) return;
		const validate = nssValid(value);
		setErrorNSS(validate);
		setValue(value);
	};
	const handleOnContinuar = () => {
		ActionsButtons.onValues({ name: 'NSS', value: Value });
		ActionsButtons.onNextTab();
	};

	// const onClickOpenModal = () => {
	// 	setopenModal(true);
	// };

	const onClickCloseModal = () => {
		setopenModal(false);

		if (toHome) {
			navigate('/inicio');
		}
	};

	const onContinuar = (): boolean => {
		return errorNSS.valid &&
			codeInput === code &&
			ActionsButtons.getValue.nssValidate
			? false
			: true;
	};
	useEffect(() => {
		if (ActionsButtons.getValue.nss !== '') {
			setValue(ActionsButtons.getValue.nss);
		}
	}, []);

	const styles = useStyles();
	return (
		<>
			<Box className={styles.container}>
				<Box>
					<TextInput
						id="NSS"
						name="NSS"
						label="Número de Seguridad Social (NSS)"
						value={Value}
						onChange={handleOnChangeNSS}
						error={!errorNSS.valid}
						helperText={!errorNSS.valid ? errorNSS.msg : ''}
						isRequired
						onBlur={ActionsButtons.onValidateNSS}
					/>
					<Box>
						<CustomAlert
							message={
								!ActionsButtons.onAlert2
									? ''
									: ActionsButtons.onAlert2?.message
							}
							severity={
								!ActionsButtons.onAlert2
									? 'success'
									: ActionsButtons.onAlert2?.severity
							}
							show={
								!ActionsButtons.onAlert2
									? false
									: ActionsButtons.onAlert2?.show
							}
							onClose={() => {
								ActionsButtons.onClearAlert('NSS2');
							}}
						/>
					</Box>
					<Box>
						<a
							href="https://serviciosdigitales.imss.gob.mx/gestionAsegurados-web-externo/asignacionNSS"
							target="_blank"
							className={styles.link}
							rel="noreferrer"
						>
							Consulta tu NSS
						</a>
					</Box>
					<Box className={styles.containerCaptcha}>
						<Captcha
							value={codeInput}
							getNew={ActionsButtons.refreshCaptcha}
							onChange={(
								newValue: string,
								codeCaptcha: string
							) => {
								setCodeInput(newValue);
								setCode(codeCaptcha);
							}}
						/>
					</Box>

					<Box className={styles.divboton}>
						<CustomAlert
							message={ActionsButtons.onAlert.message}
							severity={ActionsButtons.onAlert.severity}
							show={ActionsButtons.onAlert.show}
							onClose={() => {
								ActionsButtons.onClearAlert('NSS');
							}}
						/>
					</Box>

					<Box className={styles.divboton}>
						<CustomButton
							onClick={handleOnContinuar}
							variant="solid"
							styles={{ marginBottom: 20 }}
							label="Continuar"
							disabled={onContinuar()}
						/>
					</Box>
					<Box className={styles.defaultBox}>
					<span className={styles.defaultText}>
					Si no recuerdas o no tienes acceso a los correos electrónicos que registraste, deberás eliminar tu cuenta. Para este proceso por favor acude al Centro de Servicio más cercano, con una identificación oficial vigente.
					</span>
					</Box>
					
					<br />
					{/* <Box className={styles.divboton}>
						<CustomButton
							onClick={onClickOpenModal}
							variant="solid"
							label="Borrar cuenta"
							disabled={toHome}
						/>
					</Box> */}
				</Box>
				<BaseModal
					title="Eliminar cuenta"
					open={openModal}
					width="sm"
					onClose={onClickCloseModal}
				>
					<DeleteAcount
						onCancel={onClickCloseModal}
						toHome={(e) => {
							setToHome(e);
						}}
					/>
				</BaseModal>
			</Box>
		</>
	);
};

export default NssView;
