import API from '../';

interface IData_constanciaInteresDeclaracionAnual {
	credit: string;
}

interface IData_constanciaInteresDeclaracionAnualFiles {
	credit: string;
	entidadFinanciera: string;
	year: string;
}

class ConstanciaInteresesDeclaracionAnualAPI extends API {
	constanciaInteresDeclaracionAnual(
		data: IData_constanciaInteresDeclaracionAnual
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `constancia-interes-declaracion-anual`,
				method: 'POST',
				data,
			})
		);
	}

	constanciaInteresDeclaracionAnualFiles(
		data: IData_constanciaInteresDeclaracionAnualFiles
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `constancia-interes-declaracion-anual-files`,
				method: 'POST',
				data,
			})
		);
	}
}

export const constanciaInteresesDeclaracionAnualAPI =
	new ConstanciaInteresesDeclaracionAnualAPI('');
