export const PATHNAME = {
	CAMBIAR_CONTRASENA: '/mi-perfil/cambiar-contrasenia',
	ACTUALIZACION_DATOS_CONTACTO: '/mi-perfil/actualizar-datos-contacto',
	SUMAR_CREDITOS: '/mi-tramite-credito/sumar-creditos',
	REGISTRO_CORRECCION_RFC: '/mi-perfil/correccion-rfc',
	CORRECCION_CURP_NOMBRE: '/mi-perfil/correccion-curp-nombre',
	SOCIO_INFONAVIT: '/mi-perfil/socio-infonavit',
	DESVINCULA_DISPOSITIVO_MOVIL: '/mi-perfil/desvincula-tu-dispositivo-movil',
	CUANTO_AHORRO_TENGO: '/mi-ahorro/cuanto-ahorro-tengo',
	HAZ_APORTACIONES_EXTRAORDINARIAS:
		'/mi-ahorro/haz-aportaciones-extraordinarias',
	CALCULADORA_DEL_AHORRO: '/mi-ahorro/calculadora-ahorro',
	RESUMEN_MOVIMIENTOS_MI_AHORRO: '/mi-ahorro/resumen-movimientos-ahorro',
	CONSULTA_RELACIONES_LABORABLES: '/mi-ahorro/consulta-relaciones-laborales',
	DEVOLUCION_DE_MIS_AHORROS: '/mi-ahorro/devolucion-fondo-ahorro-72-92',
	SELECCIONA_TU_NOTARIO: '/mi-tramite-credito/seleccion-notarios',
	CONFIRMACION_DE_TRAMITE: '/mi-tramite-credito/confirmacion-tramite',
	TALLER_MAS_PARA_DECIDIR_MEJOR: '/mi-tramite-credito/taller-saber-mas',
	HOGAR_A_TU_MEDIDA: '/mi-tramite-credito/hogar-medida',
	SALDOS_Y_MOVIMIENTOS: '/mi-credito/saldos-movimientos',
	AVISO_SUSPENSION_RETENCION_Y_MODIFICACION_DE_DESCUENTOS:
		'/mi-credito/avisos-suspension-retencion-modificacion-descuentos',
	CONSTANCIA_INTERES_PARA_TU_DECLARACION_ANUAL:
		'/mi-credito/constancia-intereses-declaracion-anual',
	CONSTANCIA_DE_ECOTECNOLOGIAS: '/mi-credito/constancia-ecotecnologias',
	DEVOLUCION_PAGOS_EN_EXCESO: '/mi-credito/devolucion-pagos-exceso',
	RESPONSABILIDAD_COMPARTIDA:
		'/mi-credito/responsabilidad-compartida-programa-conversion-pesos',
	ENTREGA_ESCRITURAS: '/mi-credito/entrega-escrituras',
	CARTA_DE_CANCELACION_DE_HIPOTECA: '/mi-credito/carta-cancelacion-hipoteca',
	SOLICITUD_DE_RESTRUCTURA: '/mi-credito/solicitud-reestructuras',
	SOLICITUD_DE_PRORROGA: '/mi-credito/solicitud-prorroga',
	ACLARACION_DE_PAGOS: '/mi-credito/aclaracion-pagos',
	SIGUE_TU_CASO_Y_ADJUNTA_DOCUMENTOS:
		'/contactanos/sigue-caso-adjunta-documentos',
	HAZ_Y_SIGUE_TU_QUEJA: '/contactanos/presenta-sigue-queja',
	OFICINAS_DE_ANTENCION: '/contactanos/oficinas-atencion',
	CANALES_DE_SERVICIO: '/contactanos/canales-servicio',
	HAZ_UNA_CITA: '/contactanos/haz-una-cita',
	CALCULADORA_DE_PESOS_UNIDAD_MEDIDA_UMA:
		'/accesorios/calculadora-pesos-unidad-medida-actualizacion-uma',
	NIP_INFONATEL: '/mi-perfil/nip-infonatel',
	//---------------- Precalificacion Puntos -------------
	COMPRAR_VIVIENDA: '/precalificacion-puntos/comprar-vivienda',
	PAGAR_HIPOTECA: '/precalificacion-puntos/pagar-hipoteca',
	CONSTRUIR_VIVIENDA: '/precalificacion-puntos/construir-una-vivienda',
	MEJORA_TU_HOGAR: '/precalificacion-puntos/mejora-tu-hogar',
	SIN_RELACION_LABORAL: '/precalificacion-puntos/sin-relacion-laboral',
	COMPRAR_TERRENO: '/precalificacion-puntos/comprar-terreno',
	SIN_PUNTOS: '/precalificacion-puntos/sin-puntos',
	MEJORA_SI: '/precalificacion-puntos/mejora-si',
	AGROINDUSTRIA: '/precalificacion-puntos/agroindustria',
	CREDITO_EN_LINEA: '/precalificacion-puntos/credito-en-linea',
	UNIDADES_VALUACION: '/precalificacion-puntos/unidades-valuacion',
	COMPRAR_TERRENO_O_CONSTRUIR_VIVIENDA:
		'/precalificacion-puntos/comprar-terreno-o-comprar-terreno-y-construir-una-vivienda',
	EN_QUE_VA_MI_SOLICITUD_DE_CREDITO: '/mi-tramite-credito/en-que-va-mi-solicitud-credito',
	SELECCION_DE_NOTARIOS: '/mi-tramite-credito/seleccion-notarios',
	PRECALIFICACION_PUNTOS: '/mi-tramite-credito/precalificacion-puntos',
};
