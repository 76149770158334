import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { actions } from '../store/modules/bitacora';
import {
	ApiBitacora,
	getHeadersByReferences,
	getHeadersByScheme,
} from '../api/api-bitacora';
import {
	IRequestBitacora,
	IPropsRegister,
	IRegisterData,
	ITypeBitacora,
} from '../interfaces/bitacora';
import { encodeAllJSONData } from '../utils/encrypt';

export const useBitacora = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state: RootState) => state.session);
	const { dataBitacora } = useSelector((state: RootState) => state.bitacora);

	const setDataBitacora = (data: IRegisterData) => {
		const objectos: IRegisterData = Object.keys(data).reduce(
			(arr, current) => {
				return {
					...arr,
					[current]: {
						...data[current],
					},
				};
			},
			{}
		);
		dispatch(
			actions.setData({
				...dataBitacora,
				...objectos,
			})
		);
	};

	const setDataOld = (key: string, valorAnterior: any) => {
		dispatch(
			actions.setData({
				...dataBitacora,
				[key]: {
					valorAnterior,
				},
			})
		);
	};

	const regBitacora = async ({ key, valorNuevo, scheme }: IPropsRegister) => {
		try {
			const request: IRequestBitacora = {
				...dataBitacora[key],
				valorNuevo,
				identificador: user?.nss || '',
			};
			const requestEncrypted = await encodeAllJSONData(
				{ data: JSON.stringify(request) },
				user?.k || ''
			);
			ApiBitacora({
				data: requestEncrypted,
				headers: {
					...getHeadersByScheme(scheme),
					Authorization: `Bearer ${user?.token || ''}`,
				},
			});
			dispatch(
				actions.setData({
					...dataBitacora,
					[key]: {
						valorAnterior: valorNuevo,
					},
				})
			);
		} catch (error) {}
	};

	const regBitacoraNotOld = async (scheme: string, valorNuevo: any) => {
		try {
			const valorAnterior = Object.keys(valorNuevo).reduce((acc, key) => {
				acc[key] = 'N/A';
				return acc;
			}, {} as any);
			const request: IRequestBitacora = {
				valorAnterior,
				valorNuevo,
				identificador: user?.nss || '',
			};
			const requestEncrypted = await encodeAllJSONData(
				{ data: JSON.stringify(request) },
				user?.k || ''
			);
			ApiBitacora({
				data: requestEncrypted,
				headers: {
					...getHeadersByScheme(scheme),
					Authorization: `Bearer ${user?.token || ''}`,
				},
			});
		} catch (error) {}
	};

	const regBitacoraReferences = (
		reference: ITypeBitacora,
		valorNuevo: any = {}
	) => {
		try {
			const valorAnterior = Object.keys(valorNuevo).reduce((acc, key) => {
				acc[key] = 'N/A';
				return acc;
			}, {} as any);
			const request: IRequestBitacora = {
				valorAnterior,
				valorNuevo,
				identificador: user?.nss || '',
			};
			encodeAllJSONData(
				{ data: JSON.stringify(request) },
				user?.k || ''
			).then((requestEncrypted) => {
				ApiBitacora({
					data: requestEncrypted,
					headers: {
						...getHeadersByReferences(reference),
						Authorization: `Bearer ${user?.token || ''}`,
					},
				});
			});
		} catch (error) {}
	};

	const cleanDataBitacora = () => {
		dispatch(actions.clearData());
	};

	const getMaskString = (cadena?: string) => {
		if (cadena) {
			return cadena.replace(/./g, '*');
		}
		return cadena;
	};

	return {
		setDataBitacora,
		setDataOld,
		regBitacora,
		regBitacoraNotOld,
		cleanDataBitacora,
		getMaskString,
		regBitacoraReferences,
	};
};
