import { CheckCircleOutlined } from '@mui/icons-material';
// Assets
import { useStyles } from '../../styles';

interface props {
	sitioRegistrado: string;
}

const MovementsHeader = ({ sitioRegistrado }: props) => {
	const styles = useStyles();
	return (
		<>
			{sitioRegistrado === 'AC' && (
				<p className={styles.listItem}>
					<CheckCircleOutlined
						color="info"
						className={styles.listCheckMovements}
					/>
					<label className={styles.listTextMovements}>
						Continúa con tu ahorro recuerda que puedes realizar el
						pago en varias partes siempre y cuando cumplas en cada
						mes con el importe mensual indicado.
					</label>
					<br />
				</p>
			)}
			{sitioRegistrado === 'TX' && (
				<p className={styles.listItem}>
					<CheckCircleOutlined
						color="info"
						className={styles.listCheckMovements}
					/>
					<label className={styles.listTextMovements}>
						Has cumplido con los 12 meses de ahorro pero aún no
						alcanzas la puntuación necesaria para solicitar tu
						crédito, <strong>debes continuar ahorrando.</strong>
					</label>
					<br />
				</p>
			)}
			{sitioRegistrado === 'CA' && (
				<>
					<p
						className={styles.listItem}
					>
						<label className={styles.listTextMovements}>
							<strong>
								Tu ahorro fue interrumpido, deberás registrarte
								nuevamente para realizar tu ahorro desde la
								primera mensualidad.
							</strong>
						</label>
						<br />
					</p>
					<p
						className={styles.listItem}
					>
						<label className={styles.listTextMovements}>
							Los recursos de los depósitos extraordinarios que
							realizaste se mantendrán como parte de tu Saldo de Subcuenta de 
							Vivienda y podrás disponer de ellos cuando solicites
							un crédito o bien cuando te retires.
						</label>
						<br />
					</p>
				</>
			)}
			{sitioRegistrado === 'TE' && (
				<>
					<p className={styles.listItem}>
						<CheckCircleOutlined
							color="info"
							className={styles.listCheckMovements}
						/>
						<label className={styles.listTextMovements}>
							Has cumplido con tu ahorro y ya alcanzas la
							puntuación necesaria para solicitar tu crédito,
							consulta tu precalificación para que conozcas tus
							condiciones preliminares de tu crédito y elijas la
							vivienda que deseas adquirir. En Me interesa un
							crédito te indicaremos los pasos a seguir para
							solicitar tu crédito
						</label>
						<br />
					</p>
					<ul>
						<li>
							Debes continuar cumpliendo con el depósito
							extraordinario mensual hasta el momento en que
							inicies el trámite para solicitar tu crédito.
						</li>
						<li>
							Es necesario que tengas relación laboral para
							consultar tu precalificación y para solicitar tu
							crédito.
						</li>
					</ul>
				</>
			)}
			{sitioRegistrado === 'BJ' && (
				<>
					<p
						className={styles.listItem}
					>
						<label className={styles.listTextMovements}>
							<strong>
								Al corte mensual identificamos que no cumpliste
								con el ahorro mensual establecido al momento del
								registro en tu programa de ahorro
							</strong>
						</label>
						<br />
					</p>
					<p
						className={styles.listItem}
					>
						<label className={styles.listTextMovements}>
							Debido a esto, y conforme a lo establecido en las
							CONDICIONES PREVIAS A CONTRATACIÓN DEL CRÉDITO PARA
							TRABAJADORES DE LA AGROINDUSTRIA{' '}
							<span className={styles.colorMain}>
								has sido dado de baja de manera permanente del
								Programa.
							</span>{' '}
							Los recursos de los depósitos extraordinarios que
							realizaste se mantendrán como parte de tu Saldo de
							Subcuenta de Vivienda y podrás disponer de ellos{' '}
							<span className={styles.colorMain}>
								cuando solicites un crédito o bien cuando
								realices el trámite de retiro de la actividad
								laboral.
							</span>
						</label>
						<br />
					</p>
				</>
			)}
		</>
	);
};

export default MovementsHeader;
