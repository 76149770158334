// Dependencies
import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';

// Material Components
import { Grid } from '@mui/material';

// Assets
import { useStyles } from './styles';

// Components
import Layout from '../../components/Layout';
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import TextInputPassword from '../../components/TextInputPassword';
import ModalLoading from '../../components/ModalLoading';
import { HeaderList } from '../../components/HeaderList';
import ModalConfirmation from '../../components/ModalConfirmation';
import CustomAlert from '../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';

// Interfaces
import { IAlert } from '../../interfaces/alert';
import { IInputPasswordResult } from '../../interfaces/inputPassword';

// Api-Modules
import { changePassAPI } from '../../api/modules/changePassword';
import { keyAPI } from '../../api/modules/key';

// Resource
import { encrypt } from '../../utils/encrypt';
import { formatDate } from '../../utils/dates';
import { useBitacora } from '../../hooks/useBitacora';

const listItems = [
	'Cambiar tu contraseña constantemente te ayuda a mantener la seguridad de tu cuenta.',
	'Asegúrate de cumplir con las características necesarias de tu nueva contraseña.',
];

interface ICredentials {
	currentPassword: string;
	newPassword: string;
}

export const ChangePass = () => {
	const [credentials, setCredentials] = useState<ICredentials>({
		currentPassword: '',
		newPassword: '',
	});
	const [errorInputPassword, setErrorInputPassword] = useState(false);
	const [buttonValidate, setButtonValidate] = useState(true);
	const classes = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const [edit, setEdit] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [modalConfirm, setModalConfirm] = useState(false);
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState({} as IInputPasswordResult);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const navigate = useNavigate();
	const { regBitacoraNotOld, getMaskString } = useBitacora();

	useEffect(() => {
		if (credentials.currentPassword !== '' && password.valid) {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	}, [credentials.currentPassword, password.valid]);

	const onChange = (value: IInputPasswordResult) => {
		setAlert({ show: false, message: '', severity: 'error' });
		setPassword(value);
		setCredentials({ ...credentials, newPassword: value.password });
	};

	const setData = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	): void => {
		const { name, value } = event.target;
		setCredentials({ ...credentials, [name]: value });
	};

	const handleOnSubmit = async () => {
		setModalConfirm(false);
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const current_pass_base64 = await encrypt(credentials.currentPassword.toString() || '', key);
			const new_pass_base64 = await encrypt(credentials.newPassword.toString() || '', key);
			const dataFlag = {
				nss: nss_base64,
				contraseniaActual: current_pass_base64,
				contraseniaNueva: new_pass_base64,
			};
			changePassAPI
				.post<any, any>('', { ...dataFlag })
				.then((res) => {
					setConfirm(true);
					regBitacoraNotOld(changePassAPI.scheme, {
						nss: user?.nss.toString(),
						contraseniaActual: getMaskString(credentials.currentPassword.toString()),
						contraseniaNueva: getMaskString(credentials.newPassword.toString()),
					});
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						message:
							error && error.description
								? error.description
								: error.err
									? error.err
									: INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message:
					error && error.description
						? error.description
						: error.err
							? error.err
							: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	return (
		<Layout>
			<div className={classes.container}>
				<HeaderList
					title="Cambiar contraseña"
					date={formatDate('PP')}
					list={listItems}
				/>
				<Grid container className={classes.subContainer}>
					<Grid item xs={12} lg={3}>
						<p className={classes.txtContainer}>
							<b>Contraseña</b>
						</p>
					</Grid>
					{!edit && !confirm ? (
						<Fragment>
							<Grid item xs={7} lg={5}>
								<p className={classes.txtContainer}>
									<b>********</b>
								</p>
							</Grid>
							<Grid item xs={5} lg={4}>
								<label
									className={classes.links}
									onClick={() => setEdit(true)}
								>
									Editar
								</label>
							</Grid>
						</Fragment>
					) : edit && !confirm ? (
						<Fragment>
							<Grid item xs={12} sm={12} md={12} lg={8}>
								<p>Modifica tu contraseña</p>
								<TextInput
									data-testid="inputCurrentPassword"
									id="currentPassword"
									name="currentPassword"
									label="Contraseña actual"
									placeholder="Contraseña actual"
									type="password"
									onChange={setData}
									isRequired
									value={credentials.currentPassword}
								/>
								<TextInputPassword
									data-testid="inputNewPassword"
									value={password.password}
									onChange={onChange}
									isRequired
									confirmPassword
									placeholder="Nueva contraseña"
									labelCurrentPass="Nueva contraseña"
									placeholderConfirm="Confirmar nueva contraseña"
									labelNewPass="Confirmar nueva contraseña"
									setErrorInputPassword={setErrorInputPassword}
								/>
							</Grid>
							<Grid item xs={12} lg={4}></Grid>
							<Grid item xs={12} lg={6}>
								<CustomAlert
									message={alert.message}
									severity={alert.severity}
									show={alert.show}
									onClose={() => {
										setAlert({
											show: false,
											message: '',
											severity: alert.severity,
										});
									}}
								/>
								<div className={classes.containerBtns}>
									<div
										className={classes.subContainerButtons}
									>
										<CustomButton
											data-testid="Cancelar"
											label="Cancelar"
											onClick={() => setEdit(false)}
											variant="outlined"
											styles={{ marginTop: 15 }}
										/>
									</div>
									<div
										className={classes.subContainerButtons}
									>
										<CustomButton
											data-testid="Guardar"
											label="Guardar cambios"
											onClick={() =>
												setModalConfirm(!modalConfirm)
											}
											variant="solid"
											styles={{ marginTop: 15 }}
											disabled={buttonValidate || errorInputPassword} />
									</div>
								</div>
							</Grid>
						</Fragment>
					) : (
						<Fragment>
							<Grid
								className={classes.contConfirm}
								item
								xs={12}
								lg={6}
							>
								<p className={classes.txtInicial}>
									Cambio de contraseña en proceso
								</p>
								<p className={classes.txtPrincipales}>
									Se envió un correo electrónico con el
									seguimiento de cambio de contraseña
								</p>
								<CustomButton
									label="Salir"
									onClick={() => navigate('/')}
									variant="solid"
								/>
							</Grid>
						</Fragment>
					)}
				</Grid>
				<ModalLoading loading={loading} />
				<ModalConfirmation
					open={modalConfirm}
					confirmLabel="Sí"
					cancelLabel="No"
					onClose={() => setModalConfirm(!modalConfirm)}
					onConfirm={handleOnSubmit}
					cancelButton
					width="sm"
				>
					<p className={classes.titleModalAlert}>
						¿Estas seguro que quieres cambiar la contraseña?
					</p>
				</ModalConfirmation>
			</div>
		</Layout>
	);
};

export default ChangePass;
