import { useState } from 'react';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import DataForm from './dataForm';
import DataInfo from './dataInfo';
import DocumentList from './documentList';
import ImportantAspects from './importantAspects';
import NoDocuments from './noDocumentsFinish';
import UpLoadDocuments from './upLoadDocuments';

interface ICaso {
	or_description?: string;
	or_fecha?: string;
	or_object_id?: string;
	or_status?: string;
	or_status_reason?: string;
	or_fecha_stat?: string;
	or_status_message?: string;
	descripcion_estatus?: string;
}

const DeedsDelivery = () => {
	const [page, setPage] = useState(0);
	const [caso, setCaso] = useState<ICaso>({});
	const [documentos, setDocumentos] = useState([]);

	return (
		<Layout>
			<>
				<HeaderList title="Entrega de escrituras" date="12 Julio 2021" />
				{page === 0 && <ImportantAspects setPage={setPage} setCaso={setCaso} />}
				{page === 1 && (
					<DataForm setPage={setPage} setCaso={setCaso} caso={caso} />
				)}
				{page === 2 && <DataInfo setPage={setPage} caso={caso} />}
				{page === 3 && (
					<DocumentList
						setPage={setPage}
						setDocumentos={setDocumentos}
						documentos={documentos}
						caso={caso}
					/>
				)}
				{page === 4 && (
					<NoDocuments setPage={setPage} caso={caso} setCaso={setCaso} />
				)}
				{page === 5 && (
					<UpLoadDocuments
						setPage={setPage}
						caso={caso}
						documentos={documentos}
					/>
				)}
			</>
		</Layout>
	);
};

export default DeedsDelivery;
