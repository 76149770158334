import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: 0,
		},
		h3: {
			[theme.breakpoints.up('xs')]: {
				padding: 40,
				paddingBottom: 0,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				paddingTop: 30,
				fontSize: 18,
			},
			[theme.breakpoints.up('md')]: {
				paddingTop: 30,
				fontSize: 18,
			},
		},
		botonContainerRadio: {
			[theme.breakpoints.up('xs')]: {
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				marginLeft: 30,
				paddingRight: 40,
			},
			[theme.breakpoints.up('sm')]: {
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
			},
			[theme.breakpoints.up('md')]: {
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
			},
		},
		chooseFileOK: {
			[theme.breakpoints.up('xs')]: {
				display: 'none',
				width: '30%',
			},
			[theme.breakpoints.up('sm')]: {
				display: 'none',
				width: '30%',
			},
			[theme.breakpoints.up('md')]: {
				display: 'none',
				width: '30%',
			},
		},
		hr: {
			opacity: 0.1,
			marginLeft: 15,
			marginRight: 20,
			marginBottom: 0,
		},
		inputContainerFirmElec: {
			marginTop: '-20px',
			[theme.breakpoints.up('xs')]: {
				width: '85%',
				marginLeft: 25,
				marginBottom: 30,
			},
			[theme.breakpoints.up('sm')]: {
				width: '30%',
				display: 'flex',
				marginLeft: 260,
				marginBottom: 30,
			},
			[theme.breakpoints.up('md')]: {
				width: '30%',
				display: 'flex',
				marginLeft: 260,
				marginBottom: 30,
			},
		},
		h4: {
			color: '#29398F',
			[theme.breakpoints.up('xs')]: {
				textAlign: 'left',
				paddingTop: 0,
				fontSize: 16,
				marginLeft: 15,
				marginBottom: 0,
				width: '50%',
			},
			[theme.breakpoints.up('sm')]: {
				textAlign: 'left',
				paddingTop: 0,
				fontSize: 18,
				marginLeft: 22,
				width: '20%',
			},
			[theme.breakpoints.up('md')]: {
				textAlign: 'left',
				paddingTop: 0,
				fontSize: 18,
				marginLeft: 22,
			},
		},
		botonContainerFirmElec: {
			[theme.breakpoints.up('xs')]: {
				display: 'flex',
				flexDirection: 'column-reverse',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 20,
				paddingBottom: 20,
			},

			[theme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				marginTop: 10,
			},
		},
		message: {
			width: '100%',
			maxWidth: '800px',
			textAlign: 'center',
			margin: '40px auto',
		},
		pTextPayslip: {
			fontSize: 16,
			padding: '10px 40px'
		},
		inputContainer: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			maxWidth: 700,
			margin: '0 auto'
		},
		inputRfc: {
			width: '100%',
			height: 40,
			marginTop: 30,
			marginBottom: 30
		},
		botonContainer: {
			[theme.breakpoints.up('xs')]: {
				width: '100%',
				display: 'flex',
				flexDirection: 'column-reverse',
				alignItems: 'center',
				justifyContent: 'center',
				marginTop: 20,
				paddingBottom: 30,
			},

			[theme.breakpoints.up('md')]: {
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				marginTop: 10,
			},
		},
		title: {
			[theme.breakpoints.up('xs')]: {
				textAlign: 'center',
				paddingTop: 5,
				fontSize: 26,
				marginLeft: 20,
				marginRight: 20,
				color: '#29398F',
			},
			[theme.breakpoints.up('sm')]: {
				textAlign: 'center',
				paddingTop: 5,
				fontSize: 26,
				marginLeft: 0,
				marginRight: 0,
				color: '#29398F',
			},
			[theme.breakpoints.up('md')]: {
				textAlign: 'center',
				paddingTop: 5,
				fontSize: 30,
				marginLeft: 10,
				marginRight: 0,
				color: '#29398F',
			},
		},
		listContainer: {
			width: '100%',
			maxWidth: '900px',
			margin: '0 auto 5px',
			display: 'flex',
			textAlign: 'center',
		},
		pGotSAT: {
			[theme.breakpoints.up('xs')]: {
				marginTop: 0,
				marginBottom: 0,
				textAlign: 'left',
				fontSize: 14,
			},
			[theme.breakpoints.up('sm')]: {
				marginTop: 0,
				marginBottom: 0,
				textAlign: 'left',
				fontSize: 14,
			},

			[theme.breakpoints.up('md')]: {
				marginTop: 0,
				marginBottom: 0,
				textAlign: 'left',
				fontSize: 16,
			},
		},
		pMakeDate: {
			[theme.breakpoints.up('xs')]: {
				marginTop: 15,
				marginBottom: 0,
				textAlign: 'center',
				fontSize: 14,
				width: '85%',
				marginLeft: 25,
			},
			[theme.breakpoints.up('sm')]: {
				marginTop: 0,
				marginBottom: 0,
				textAlign: 'center',
				fontSize: 14,
			},

			[theme.breakpoints.up('md')]: {
				marginLeft: 90,
				marginTop: 20,
				marginBottom: 30,
				width: '85%',
				textAlign: 'center',
				fontSize: 16,
			},
		},
		botonContainerThanksSAT: {
			[theme.breakpoints.up('xs')]: {
				display: 'flex',
				flexDirection: 'column-reverse',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 20,
				// marginLeft: 25,
				paddingBottom: 20,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				marginTop: 35,
				alignItems: 'center',
				paddingBottom: 35,
			},
		},
		buttons: {
			width: '100%',
			maxWidth: '600px',
			margin: '40px auto 0',
			display: 'flex',
			justifyContent: 'space-around',
			[theme.breakpoints.down('md')]: {
				display: 'block',
			},
		},
		form: {
			padding: '20px'
		},
		containerFiles: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		containerFilesOne: {
			width: '40%',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				textAlign: 'center',
			},
		},
		containerFilesTwo: {
			paddingTop: '2px',
			width: '30%',
			paddingRight: '20px',
			textAlign: 'right',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				textAlign: 'center',
				margin: '30px auto',
			},
		},
		containerFilesThree: {
			width: '30%',
			textAlign: 'right',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				textAlign: 'center',
			},
		},
		textFile: {
			color: '#7F8487',
			margin: '0 0 10px',
			lineHeight: '18px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		p: {
			color: '#7F8487',
			margin: '0 0 10px',
			lineHeight: '18px',
		},
		containerInput: {
			width: '100%',
			maxWidth: '550px',
			margin: '0 auto 15px'
		},
		buttonCenter: {
			marginTop: '30px',
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
			[theme.breakpoints.down('md')]: {
				marginTop: '10px',
			},
		},
		movilButton: {
			width: '100%',
			textAlign: 'center'
		},
		VerificacionLogo: {
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
			marginBottom: 20,
		},
		numbersVerification: {
			width: '100%',
			maxWidth: '600px',
			margin: '0 auto',
		},
		VerificacionText: {
			fontSize: '17px',
			textAlign: 'center',
			width: '100%',
		},
		VerificacionCodigoTitle: {
			marginLeft: '10%',
			marginRight: '10%',
			fontSize: '35px',
			textAlign: 'center',
			color: '#293990',
			width: '100%',
		},
		containerAlert: {
			width: '90%',
			maxWidth: '800px',
			margin: '0 auto',
		},
		footer: {
			width: '100%',
			textAlign: 'center',
			padding: '20px 20px 0'
		},
		alertIcon: {
			'& .MuiAlert-icon': {
				color: `#D1001F !important`,
			},
			'& .MuiAlert-message': {
				padding: '0px !important',
			},
		},
		sizeIcon: {
			width: '22px',
			height: '22px',
		},
	})
);
