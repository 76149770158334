// Dependencies
import { useState } from 'react';
import { useNavigate } from 'react-router';

// Material Components
import { Box, Checkbox, FormControlLabel, Paper, useTheme } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Components
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import TextInput from '../../components/TextInput';
import RowComponent from '../../components/Rows';
import BaseModal from '../../components/Modal';
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';

// Assets
import { useStyles } from './styles';

// Resources
import { curpValid } from '../../utils/expressions';
import { encrypt } from '../../utils/encrypt';

// Api-Modules
import { keyAPI } from '../../api/modules/key';
import { correctionCurpAPI } from '../../api/modules/CorrectionCURP';

// Interfaces
import { IAlert } from '../../interfaces/alert';

const describeText = [
	'En este servicio puedes modificar tu Clave Única de Registro de Población (CURP) que tenemos registrado en nuestro sistema.',
]

const CurpCorrectionRegister = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [CURP, setCURP] = useState({ curp: '', confirmCurp: '' });
	const [errorCURP, setErrorCURP] = useState({ valid: false, msg: '' });
	const [errorConfirmCURP, setConfirmErrorCURP] = useState({
		valid: false,
		msg: '',
	});

	const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		let validate = null;
		let values = CURP;

		setAlert({
			show: false,
			message: '',
			severity: alert.severity,
		});

		if (name === 'CURP') {
			if (value.length > 18) return;
			validate = curpValid(value);
			setErrorCURP(validate);
			values.curp = value;
		}
		if (name === 'confirmCURP') {
			if (value.length > 18) return;
			validate = curpValid(value);
			setConfirmErrorCURP(validate);
			values.confirmCurp = value;
		}
		setCURP(values);
	};
	const onValidChangeCurp = async () => {
		setLoading(true);

		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt('nss', key);
			const curp_base64 = await encrypt(CURP.curp || '', key);
			const dataFlag = {
				nss: nss_base64,
				curp: curp_base64,
			};

			correctionCurpAPI
				.ValidChangeCurp({ ...dataFlag })
				.then((res) => {
					if (res.error) {
						setAlert({
							show: true,
							message: res.error.description
								? res.error.description
								: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
							severity: 'error',
						});
					}
					if (res.result) {
						if (res.result.codigo === '005' || res.result.codigo === '009') {
							//console.log(res)
						} else {
							setAlert({
								show: true,
								message: res.result.description
									? res.result.description
									: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
								severity: 'error',
							});
						}
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description
							? error.description
							: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message:
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const style = useStyles();
	return (
		<Layout>
			<div>
				<HeaderList
					key="HeaderCorreccionCURP"
					title="Corrección de CURP"
					date="12 julio 2021"
					list={describeText}
				/>
				<Paper>
					<Box className={style.container}>
						<Box className={style.marginContainer}>
							<Box className={style.containerInputs} sx={{}}>
								<Box>
									<TextInput
										data-testid="CURPtextInput"
										id="CURP"
										name="CURP"
										label="CURP"
										value={CURP.curp}
										onChange={onChangeInput}
										error={!errorCURP.valid}
										helperText={
											!errorCURP.valid ? errorCURP.msg : ''
										}
										isRequired
									/>
									<TextInput
										data-testid="confirmCURP"
										id="confirmCURP"
										name="confirmCURP"
										label="Confirmar CURP"
										value={CURP.confirmCurp}
										onChange={onChangeInput}
										error={!errorConfirmCURP.valid}
										helperText={
											!errorConfirmCURP.valid
												? errorConfirmCURP.msg
												: ''
										}
										isRequired
									/>
								</Box>
							</Box>

							<div className={style.containerCenter}>
								<div className={style.containerInputs}>
									<CustomAlert
										data-testid="mensageAlert"
										message={alert.message}
										severity={alert.severity}
										show={alert.show}
										onClose={() => {
											setAlert({
												show: false,
												message: '',
												severity: alert.severity,
											});
										}}
									/>
								</div>
							</div>

							<Box className={style.containerButton}>
								<Box className={style.button}>
									<CustomButton
										data-testid="CancelarCambioCURP"
										onClick={() => { }}
										variant="outlined"
										styles={{}}
										label="Cancelar"
									/>
								</Box>
								<Box className={style.button}>
									<CustomButton
										data-testid="ContinuarCambioCURP"
										onClick={onValidChangeCurp}
										variant="solid"
										styles={{}}
										label="Continuar"
										disabled={
											errorCURP.valid &&
												errorConfirmCURP.valid &&
												CURP.curp === CURP.confirmCurp
												? false
												: true
										}
									/>
								</Box>
							</Box>

							<Box style={{ marginBottom: 40 }}>
								<span className={style.fuentefooter}>
									Si quieres consultar tu CURP en el Registro
									Nacional de Población (RENAPO),{' '}
									<span
										onClick={() => {
											window.open(
												'https://www.gob.mx/curp/',
												'_blank'
											);
										}}
										className={style.fuentefooterRed}
									>
										ingresa aquí
									</span>
								</span>
							</Box>
						</Box>
						<hr className={style.hrColor} />
						<span className={style.DatosObligatorios}>
							* Datos obligatorios
						</span>
					</Box>
				</Paper>
			</div>
		</Layout>
	);
};
export default CurpCorrectionRegister;
