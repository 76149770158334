import { useSelector } from 'react-redux';
import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { useController } from '../../../hooks/useController';
import { RootState } from '../../../../../store';
import { useEffect, useState } from 'react';
import { MIUC_PRODUCTS } from '../../../constants/products';
import { CompraSteps } from '../productSteps/ComprarSteps';
import { ConstruirSteps } from '../productSteps/ConstruirSteps';
import { HipotecaSteps } from '../productSteps/HipotecaSteps';
import { CrediterrenoSteps } from '../productSteps/CrediterrenoSteps';
import { ReparacionesMenoresSteps } from '../productSteps/ReparacionesMenoresSteps';
import { AsistTecnicaSteps } from '../productSteps/AsistTecnicaSteps';
import { CreditoConstructoraSteps } from '../productSteps/CreditoConstructoraSteps';
import { MejoraTuHogarSteps } from '../productSteps/MejoraTuHogarSteps';
import { MejoraDirectaSteps } from '../productSteps/MejoraDirectaSteps';
import { CICBSteps } from '../productSteps/CICBSteps';
import { ApoyoInfonavitSteps } from '../productSteps/ApoyoFinonavitSteps';
import { CreditoSeguroSteps } from '../productSteps/CreditoSeguroSteps';
import { SueloConstruccionSteps } from '../productSteps/SueloConstruccionSteps';
import { WORKSHOP_VIEWS } from '../../../views/TallerSaberMas/utils';
import { tallerSaberMasAPI } from '../../../../../api/modules/tallerSaberMas';

export const useFollowStepsCard = () => {
	const { dataProcessController } = useController();
	const { user } = useSelector((state: RootState) => state.session);
	const [alert, setAlert] = useState(String);
	const [avanceTaller, setAvanceTaller] = useState(0);

	const { credit, creditType } = dataProcessController.processInfo;

	// Obtener elementos para curso Saber Más
	useEffect(() => {
		handleElementsList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleElementsList = async () => {
		if (
			credit !== MIUC_PRODUCTS.MEJORA_DIRECTA &&
			credit !== MIUC_PRODUCTS.MEJORAVIT &&
			credit !== MIUC_PRODUCTS.CICB
		) {
			try {
				const request = await encodeAllJSONData(
					{
						data: JSON.stringify({
							destino: WORKSHOP_VIEWS.FINANTIAL_EDUCATION.destino,
						}),
					},
					user?.k || ''
				);
				const { data } = await tallerSaberMasAPI.getElements(request);
				setAvanceTaller(Number(data?.avance || '0'));
			} catch (error: any) {
				if (error.description) setAlert(error.description);
			}
		}
	};

	// Pasos que se mostrarán con dataProcessController
	const data = {
		avanceTaller,
		alert,
	};

	const productSteps =
		credit === MIUC_PRODUCTS.COMPRA
			? CompraSteps(data)
			: credit === MIUC_PRODUCTS.CREDITERRENO
			? CrediterrenoSteps(data)
			: credit === MIUC_PRODUCTS.CONSTRUCCION
			? ConstruirSteps(data)
			: credit === MIUC_PRODUCTS.AUTOPRODUCCION && creditType === 'RM'
			? ReparacionesMenoresSteps(data)
			: credit === MIUC_PRODUCTS.AUTOPRODUCCION && creditType === 'AT'
			? AsistTecnicaSteps(data)
			: credit === MIUC_PRODUCTS.AUTOPRODUCCION && creditType === 'CN'
			? CreditoConstructoraSteps(data)
			: credit === MIUC_PRODUCTS.HIPOTECA
			? HipotecaSteps(data)
			: credit === MIUC_PRODUCTS.MEJORAVIT
			? MejoraTuHogarSteps()
			: credit === MIUC_PRODUCTS.MEJORA_DIRECTA
			? MejoraDirectaSteps()
			: credit === MIUC_PRODUCTS.CICB
			? CICBSteps()
			: credit === MIUC_PRODUCTS.APOYO_INFONAVIT
			? ApoyoInfonavitSteps()
			: credit === MIUC_PRODUCTS.CREDITO_SEGURO
			? CreditoSeguroSteps()
			: credit === MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION
			? SueloConstruccionSteps(data)
			: null;

	// Mostrar/Oculta información adicional
	const [viewMoreStates, setViewMoreStates] = useState<{
		[key: number]: boolean;
	}>({});

	const handleViewMore = (index: number) => {
		setViewMoreStates((prevStates) => ({
			...prevStates,
			[index]: true,
		}));
	};

	const handleViewLess = (index: number) => {
		setViewMoreStates((prevStates) => ({
			...prevStates,
			[index]: false,
		}));
	};

	return {
		productSteps,
		viewMoreStates,
		handleViewMore,
		handleViewLess,
		alert,
	};
};
