import { useState } from 'react';
import { Divider } from '@mui/material';
// COMPONENTS
import CustomAlert from '../../../components/CustomAlert';
import CustomButton from '../../../components/Button';
// RESOURCES
import { IAlert } from '../../../interfaces/alert';
import { useStyles } from './styles';
import { getBase64File, getBinaryFile, IDataInfo, IFiles2 } from '../utils';
import useCustomScript from '../../../hooks/useCustomScript';
import { apiCancelledPayments } from '../../../api/modules/CancelledPayments';
import { encrypt } from '../../../utils/encrypt';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useBitacora } from '../../../hooks/useBitacora';

interface Props {
	onContinue: any;
	onReturn: any;
	setLoading: (arg: boolean) => void;
	dataInfo: IDataInfo;
}

const VerifyView = ({ onContinue, onReturn, setLoading, dataInfo }: Props) => {
    const { regBitacoraNotOld } = useBitacora();
	const classes = useStyles();
	const firmar = window.firmar;
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	//const serverIP = 'https://serviciosweb.infonavit.org.mx:8993'; //QA
	//const serverIP = 'https://serviciosweb.infonavit.org.mx:8991/firma'; //prod
	const serverIP = process.env.REACT_APP_URL_VALIDA_FIEL;
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [files, setFiles] = useState<IFiles2>({
		file1: {
			name: 'No se eligió ningún archivo',
			data: null,
			binary: '',
			base64: '',
			type: '',
		},
		file2: {
			name: 'No se eligió ningún archivo',
			data: null,
			binary: '',
			type: '',
		},
	});
	const [password, setPassword] = useState('');
	const [infoData, setInfoData] = useState<{ [key: string]: string }>({});

	useCustomScript('/jquery/jquery.min.js');
	useCustomScript('/efirma/firma-documento.js');
	useCustomScript('/efirma/fiel-validacion.ofu.min.js');

	const handleFiles = async (e: any) => {
		const file = e.target.files[0];

		if (e.target.name === 'file1') {
			const base64 = await getBase64File(file);
			const binary = await getBinaryFile(file);
			setFiles({
				...files,
				file1: {
					name: file.name,
					data: file,
					type: file.type,
					base64,
					binary,
				},
			});
			return;
		}

		if (e.target.name === 'file2') {
			const binary = await getBinaryFile(file);
			setFiles({
				...files,
				file2: {
					name: file.name,
					data: file,
					type: file.type,
					binary,
				},
			});
			return;
		}
	};

	const encolarArchivo = async (pdfFirmado: string, noCaso: string) => {
		try {
			const data = {
				idTramite: noCaso, // Numero de caso
				base64: pdfFirmado,
			};
			const dataEncripted = await encrypt(
				JSON.stringify(data),
				user?.k || ''
			);
			const { result } = await apiCancelledPayments.cargarArchivo(
				dataEncripted
			);
			if (String(result.code || '') === '0000') {
				onContinue();
				return;
			}
			setAlert({
				show: true,
				message:
					result.message ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		} catch (error: any) {
			const { description } = error;
			setAlert({
				show: true,
				message:
					description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const firmarPdf = (pdfAcuse: string, noCaso: string) => {
		const inputs = {
			keyBinary: files.file2.binary,
			cerBinary: files.file1.binary,
			cerB64: files.file1.base64,
			password: password,
			serverIP: serverIP,
			pdfB64: pdfAcuse,
			pdfContentType: 'application/pdf',
		};
		firmar(inputs, (error: any, response: any) => {
			if (error === null) {
				const pdfFirmado = response.pdfSignedB64;
				encolarArchivo(pdfFirmado, noCaso);
				return;
			}
			setAlert({
				show: true,
				message:
					error ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
			setLoading(false);
		});
	};

	const handleSubmitFile = async () => {
		try {
			setLoading(true);
			setAlert({
				show: false,
				message: '',
				severity: 'error',
			});

			if (infoData?.pdfAcuse && infoData?.noCaso) {
				firmarPdf(infoData?.pdfAcuse, infoData?.noCaso);
				return;
			}

            const dataEncript1 = {
                nss: user?.nss?.toString() || '',
                correo: user?.email?.toString() || '',
                nombre: user?.nombres?.toString() || '',
                apellidoPaterno: user?.apPaterno?.toString() || '',
                apellidoMaterno: user?.apMaterno?.toString() || '',
                curp: user?.curp?.toString() || '',
                rfc: user?.rfc?.toString() || '',
                credito: credit?.toString() || '',
                monto: dataInfo.saldo || '0',
            };

			let data_encript: string = await encrypt(
				JSON.stringify(dataEncript1),
				user?.k || ''
			);

			//Creamos el caso
			const { result: set_caso } = await apiCancelledPayments.setCaso({
				data: data_encript,
			});

			if (Number(set_caso.code || '99') !== 0) {
				throw new Error(
					set_caso?.message ||
						'Ha ocurrido un error al obtener los datos, intente nuevamente'
				);
			}
            
            regBitacoraNotOld(apiCancelledPayments.schemeUrl, dataEncript1);

			let data_encript2: string = await encrypt(
				JSON.stringify({
					caso: set_caso.data?.noCaso || '',
					cuentaClabe: dataInfo?.clabe || '',
				}),
				user?.k || ''
			);

			// Obtener marca
			const { result: marca_cuenta } =
				await apiCancelledPayments.getMarcaCuenta({
					data: data_encript2,
				});

			if (Number(marca_cuenta.code || '99') !== 0) {
				throw new Error(
					marca_cuenta?.message ||
						'Ha ocurrido un error al obtener los datos, intente nuevamente'
				);
			}

			// Crear Solicitud
			const { result: solicitud } =
				await apiCancelledPayments.crearSolicitud({
					data: data_encript2,
				});

			if (Number(solicitud.code || '99') !== 0) {
				throw new Error(
					solicitud?.message ||
						'Ha ocurrido un error al obtener los datos, intente nuevamente'
				);
			}

			// Obtener el pdf Acuse
			const { result: pdfAcuse } = await apiCancelledPayments.getPdfAcuse(
				{
					data: data_encript2,
				}
			);

			if (Number(pdfAcuse.code || '99') !== 0) {
				throw new Error(
					pdfAcuse?.message ||
						'Ha ocurrido un error al obtener los datos, intente nuevamente'
				);
			}

			setInfoData({
				noCaso: set_caso.data?.noCaso,
				pdfAcuse: pdfAcuse?.data.pdf,
			});

			firmarPdf(pdfAcuse?.data.pdf, set_caso.data?.noCaso);
		} catch (error: any) {
			setAlert({
				show: true,
				message:
					error?.message ||
					'Ha ocurrido un error al obtener los datos, intente nuevamente',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	return (
		<>
			<div className={classes.containerFirma}>
				<div>
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<span className={classes.contentFileDataTitle}>
									Certificado (.cer)
									<span style={{ color: '#DD0528' }}>*</span>
								</span>
								<p
									className={classes.contentFileDataText}
									style={{
										color:
											files.file1.name ===
											'No se eligió ningún archivo'
												? '#7F7F7F'
												: 'black',
									}}
								>
									{files.file1.name}
								</p>
							</div>
							<div className={classes.contentFileDataLink}>
								<label style={{ cursor: 'pointer' }}>
									<input
										type="file"
										accept=".cer"
										name="file1"
										onChange={handleFiles}
										style={{ display: 'none' }}
									/>
									Elegir archivo
								</label>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 20 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<span className={classes.contentFileDataTitle}>
									Clave privada (.key)
									<span style={{ color: '#DD0528' }}>*</span>
								</span>
								<p
									className={classes.contentFileDataText}
									style={{
										color:
											files.file2.name ===
											'No se eligió ningún archivo'
												? '#7F7F7F'
												: 'black',
									}}
								>
									{files.file2.name}
								</p>
							</div>
							<div className={classes.contentFileDataLink}>
								<label style={{ cursor: 'pointer' }}>
									<input
										type="file"
										accept=".key"
										name="file2"
										onChange={handleFiles}
										style={{ display: 'none' }}
									/>
									Elegir archivo
								</label>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 20 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<span className={classes.contentFileDataTitle}>
									Contraseña de clave privada
									<span style={{ color: '#DD0528' }}>*</span>
								</span>
							</div>
						</div>
						<input
							className={classes.contentFileDataInput}
							type="password"
							name="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<CustomAlert
						show={alert.show}
						severity={alert.severity}
						message={alert.message}
					/>
					<div className={classes.contentFileDataButtons}>
						<CustomButton
							onClick={() => {
								onReturn();
							}}
							variant="outlined"
							styles={{
								marginTop: 80,
								width: 200,
								marginInline: 10,
							}}
							label="Regresar"
						/>
						<CustomButton
							onClick={() => {
								handleSubmitFile();
							}}
							variant="solid"
							disabled={
								files.file1.data === null ||
								files.file2.data === null ||
								password === ''
									? true
									: false
							}
							styles={{
								marginTop: 80,
								width: 200,
								marginInline: 10,
							}}
							label="Continuar"
						/>
					</div>
					<Divider style={{ marginBlock: 20 }} />
					<h3 className={classes.principalTitle}>
						* Datos obligatorios
					</h3>
				</div>
			</div>
		</>
	);
};

export default VerifyView;
