// DEPEDENCIES
import React, { useState } from 'react';
import { Divider } from '@mui/material';
// COMPONENTS
import BaseModal from '../../../components/ModalConfirmation';
import { HeaderList } from '../../../components/HeaderList';
import CustomAlert from '../../../components/CustomAlert';
import CustomButton from '../../../components/Button';
import ModalLoading from '../../../components/ModalLoading';
// RESOURCES
import { IAlert } from '../../../interfaces/alert';
import { useStyles } from './styles';

const LISTTODO = [
	'Para solicitar tu devolución de tus pagos en exceso, deberás contar con tu firma electrónica avanzada (e.firma) vigente.',
	'Si quieres obtener tu firma electrónica avanzada (e.firma), ingresa a www.sat.gob.mx',
];

interface IFiles {
	file1: {
		name: 'No se eligió ningún archivo';
		data: null;
	};
	file2: {
		name: 'No se eligió ningún archivo';
		data: null;
	};
}

interface Props {
	setPage: (data: number) => void;
	fileCer: string;
	fileKey: string;
	fileCerB: string;
	setAlert: (alert: IAlert) => void;
	handleOnSubmitSat: (a: string, b: string) => void;
	files: IFiles;
	passw: string;
	handleCer: (e: any) => void;
	handleKey: (e: any) => void;
	onCloseError: () => void;
	onChange: (e: any) => void;
	hasError: IAlert;
	setOpenModal: (any: any) => void;
	openModal: any;
	caso: boolean;
}
// 1
// Agregamos una nueva propiedad globar para la interface Window, la cuál es la función(es) que
// queremos traer del archivo custom

const LayoutNotice = ({
	setPage,
	setAlert,
	handleCer,
	fileCer,
	fileKey,
	fileCerB,
	handleOnSubmitSat,
	passw,
	handleKey,
	files,
	onChange,
	onCloseError,
	hasError,
	openModal,
	caso,
	setOpenModal,
}: Props) => {
	// 2
	// Importamos el custom hook que necesitamos cargar el archivo al iniciar nuestro componente
	// el hook recibe una prop de tipo string que es la url
	// recomendamos guardar estos custom script en public

	// 3
	// obtenemos la funcion en una variable del scop global que generamos

	const classes = useStyles();
	const [errorMsg, setErrorMsg] = useState('');
	const [loading, setLoading] = useState(false);
	const validarFiel = window.validarFiel;
	// var serverIP = 'https://serviciosweb.infonavit.org.mx:8993'; //QA
	var serverIP = process.env.REACT_APP_URL_VALIDA_FIEL; //prod

	const handleSubmitFile = () => {
		setLoading(true);
		const inputs = {
			keyBinary: fileKey,
			cerBinary: fileCer,
			cerB64: fileCerB,
			password: passw,
			serverIP: serverIP,
			pdfB64: '',
			pdfContentType: 'application/pdf',
		};

		// 4, usamos la función
		validarFiel(
			inputs,
			(
				error: string,
				response: {
					valido: boolean;
					rfc: string;
					nombre: string;
					mensajeValidacion: string;
					fechaVigenciaInicio: string;
					fechaVigenciaFin: string;
				}
			) => {
				if (error === null) {
					if (response?.valido === true) {
						handleOnSubmitSat(response.rfc, response.nombre);
						setLoading(false);
					} else {
						setOpenModal(true);
						setErrorMsg(response.mensajeValidacion);
						setLoading(false);
					}
				} else {
					if (error === 'No se proporcionó un pdf a firmar') {
						handleSubmitFile();
					} else {
						setLoading(false);
						setAlert({
							show: true,
							message: error,
							severity: 'error',
						});
					}
				}
			}
		);
	};

	return (
		<>
			<HeaderList
				title="Devolución de pagos en exceso"
				list={LISTTODO}
			></HeaderList>
			<ModalLoading loading={loading} />
			{caso === false ? (
				<>
					<div className={classes.container}>
						<div>
							<div>
								<div className={classes.contentFile}>
									<div className={classes.contentFileData}>
										<span
											className={
												classes.contentFileDataTitle
											}
										>
											Certificado (.cer)
											<span style={{ color: '#DD0528' }}>
												*
											</span>
										</span>
										<p
											className={
												classes.contentFileDataText
											}
											style={{
												color:
													files.file1.name ===
														'No se eligió ningún archivo'
														? '#7F7F7F'
														: 'black',
											}}
										>
											{files.file1.name}
										</p>
									</div>
									<div
										className={classes.contentFileDataLink}
									>
										<label style={{ cursor: 'pointer' }}>
											<input
												type="file"
												accept=".cer"
												name="file1"
												onChange={handleCer}
												style={{ display: 'none' }}
											/>
											Elegir archivo
										</label>
									</div>
								</div>
							</div>
							<Divider style={{ marginBlock: 20 }} />
							<div>
								<div className={classes.contentFile}>
									<div className={classes.contentFileData}>
										<span
											className={
												classes.contentFileDataTitle
											}
										>
											Clave privada (.key)
											<span style={{ color: '#DD0528' }}>
												*
											</span>
										</span>
										<p
											className={
												classes.contentFileDataText
											}
											style={{
												color:
													files.file2.name ===
														'No se eligió ningún archivo'
														? '#7F7F7F'
														: 'black',
											}}
										>
											{files.file2.name}
										</p>
									</div>
									<div
										className={classes.contentFileDataLink}
									>
										<label style={{ cursor: 'pointer' }}>
											<input
												type="file"
												accept=".key"
												name="file2"
												onChange={handleKey}
												style={{ display: 'none' }}
											/>
											Elegir archivo
										</label>
									</div>
								</div>
							</div>
							<Divider style={{ marginBlock: 20 }} />
							<div>
								<div className={classes.contentFile}>
									<div className={classes.contentFileData}>
										<span
											className={
												classes.contentFileDataTitle
											}
										>
											Contraseña de clave privada
											<span style={{ color: '#DD0528' }}>
												*
											</span>
										</span>
									</div>
								</div>
								<input
									className={classes.contentFileDataInput}
									type="password"
									name="password"
									value={passw}
									onChange={onChange}
								/>
							</div>
							<CustomAlert
								show={hasError.show}
								severity={hasError.severity}
								message={hasError.message}
								onClose={onCloseError}
							/>
							<div className={classes.contentFileDataButtons}>
								<CustomButton
									onClick={() => {
										setPage(0);
									}}
									variant="outlined"
									styles={{
										marginTop: 80,
										width: 200,
										marginInline: 10,
									}}
									label="Regresar"
								/>
								<CustomButton
									// onClick={() => {
									// 	setPage(3);
									// }}
									onClick={handleSubmitFile}
									variant="solid"
									disabled={
										files.file1.data === null ||
											files.file2.data === null ||
											passw === ''
											? true
											: false
									}
									styles={{
										marginTop: 80,
										width: 200,
										marginInline: 10,
									}}
									label="Continuar"
								/>
							</div>
							<Divider style={{ marginBlock: 20 }} />
							<h3 className={classes.principalTitle}>
								* Datos obligatorios
							</h3>
						</div>
						<BaseModal
							open={openModal}
							title="S"
							onConfirm={() => setOpenModal(!openModal)}
							onClose={() => setOpenModal(!openModal)}
							cancelButton={true}
							confirmLabel="Aceptar"
							cancelLabel="Cancelar"
							width="sm"
							children={
								<div
									style={{
										paddingLeft: 40,
										paddingRight: 40,
										paddingTop: 30,
										paddingBottom: 10,
									}}
								>
									<p
										style={{
											display: 'flex',
											textAlign: 'center',
											justifyContent: 'center',
											margin: 0,
										}}
									>
										{errorMsg}
									</p>
								</div>
							}
						/>
					</div>
				</>
			) : (
				<>
					<div className={classes.container2}>
						<div>
							<b>Devolución de líneas de pagos en exceso</b>
							<br></br>
							<br></br>
							<span className={classes.descriptionContainerTitle}>
								Actualmente estás en un proceso de aclaración
								por devolución de pagos en exceso; comunicate
								sin costo a infonatel (Ciudad de México al
								5591715050 ó 800 003 900 para cualquier parte
								del país) para verificar el estado de tu
								aclaración.
							</span>
							<br></br>
							<br></br>
							<CustomButton
								onClick={() => {
									setPage(0);
								}}
								variant="solid"
								styles={{
									marginTop: 70,
									width: 200,
									margin: '0 auto',
								}}
								disabled={false}
								label="Salir"
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default LayoutNotice;
