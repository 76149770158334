import { CREDIT_TYPES, MIUC_PRODUCTS } from '../../constants/products';
import {
	creditTypes,
	mockCreditOptions,
	mockRequestCredit,
} from '../../../CreditRequest/MIUC/utils';
import { useStyles } from './styles';
import { useController } from '../../hooks/useController';
import { useProductTableMIUC } from '../../hooks/useProductTableMIUC';
import {
	IFormat,
	selectionResumeConfig,
	formatValues,
} from '../../utils/selectionResume.utils';
import {
	IProductMonto,
	IProductoAutoproduccion,
	IProductoMejora,
} from '../../../../interfaces/creditRequest';
import { encodeAllJSONData } from '../../../../utils/encrypt';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { downloadPDF } from '../../../../utils/downloadPDF';
import { CODES_RESPONSE } from '../../../../config/miucConstants';
import { IAlert } from '../../../../interfaces/alert';
import { useState } from 'react';
import { apiMIUC } from '../../../../api/modules/MIUC';
import { Box, Link, TableCell } from '@mui/material';

import individualIcon from '../../../../assets/img/productsMIUC/individual.svg';
import conyugalIcon from '../../../../assets/img/productsMIUC/conyugal.svg';
import iconoPDF from '../../../../assets/img/icono-descarga.png';
import { useUserInfo } from '../../hooks/useUserInfo';

export const useSelectionResume = () => {
	const classes = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const { returnStep, dataProcessController, nextStep, getProgressStep } =
		useController();
	const { handleAmortizationTable } = useProductTableMIUC();

	const [product] = useState(
		dataProcessController?.processInfo?.productSelect
	);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		severity: 'success',
		show: false,
		message: '',
	});
	const { active, total } = getProgressStep();
	const { credit, creditType } = dataProcessController.processInfo;
	let { tableRowTitles } = selectionResumeConfig[credit];

	const { conyuge } = useUserInfo();

	let tableRowWithConyuge = [...tableRowTitles];

	if (conyuge) {
		tableRowTitles.forEach((row) => {
			const keys = Object.keys(product);
			const result = keys.find((key) => key === `${row.key}Cny`);

			if (result !== undefined) {
				tableRowWithConyuge.push({
					...row,
					key: result,
				});
			}
		});
	}

	const totales = [
		Number(product['cdcMon'] || 0) + Number(product['cdcMonCny'] || 0),
		Number(product['mmcMon'] || 0) + Number(product['mmcMonCny'] || 0),
		Number(product['contarias'] || 0) +
			Number(product['contariasCny'] || 0),
	];

	let totalCuantity = product['cdcMon'] || product['mmcMon'] || product['contarias'];

	if(dataProcessController?.processInfo?.creditType !== 'I'){
		totalCuantity = totales[0] || totales[1] || totales[2];
	}
	
	const formatedData = formatValues(
		product,
		tableRowWithConyuge as IFormat[]
	);
	const newStep = {
		title: 'Resumen de tus elecciones',
		subtitle:
			'Considera el importe que te retendrán para realizar tu presupuesto personal',
		active,
		total,
	};

	if (
		[MIUC_PRODUCTS.AUTOPRODUCCION, MIUC_PRODUCTS.MEJORAVIT].includes(credit)
	) {
		newStep.title = 'Resumen de precalificación';
		newStep.subtitle = 'Conoce el resultado de tu precalificación';
	}

	const getMessage = (producto: string) => {
		switch (producto) {
			case MIUC_PRODUCTS.AUTOPRODUCCION:
				return {
					image: creditTypes[producto].image,
					title: creditTypes[producto].title,
					text: (
						<p>
							Has seleccionado un crédito{' '}
							<b className={classes.title}>
								{mockRequestCredit[credit].description}
							</b>{' '}
							para{' '}
							<b className={classes.title}>
								{mockCreditOptions[creditType].title}
							</b>
							, contarías con:
						</p>
					),
				};
			case CREDIT_TYPES.CREDITO_INFONAVIT:
				return {
					image: creditTypes[producto].image,
					title: creditTypes[producto].title,
					text: (
						<p>
							Has seleccionado un{' '}
							<b className={classes.title}>
								{creditTypes[producto].title}
							</b>{' '}
							tradicional, contarías con:
						</p>
					),
				};
			default:
				return {
					image: creditTypes[producto].image,
					title: creditTypes[producto].title,
					text: (
						<p>
							Has seleccionado un crédito{' '}
							<b className={classes.title}>
								{creditTypes[producto].title}
							</b>
							{', '}
							contarías con:
						</p>
					),
				};
		}
	};

	const { image, title, text } = getMessage(product.producto);

	const handleGetAmortizationTableAutoproduction = async (
		product: IProductoAutoproduccion
	) => {
		try {
			setLoading(true);
			const data = {
				nss: user?.nss || '',
				tipoCredito: 'I',
				producto:
					dataProcessController.processInfo?.creditType === 'RM'
						? 'AUT_MEN'
						: 'AUT_MAY',
				montoMaximoCredito: product.montoCalculado || product.monto,
				descuentoTrabajador:
					product.pagoCalculado?.toString() ||
					product.pago?.toString(),
				tasaInteres: product.tasaInteres,
				salarioDiarioIntegrado:
					dataProcessController?.titular.salarioDiario,
				descuentoCredito:
					product.pagoCalculado?.toString() ||
					product.pago?.toString(),
				salarioMinimoDiario: product?.valorUMA,
				fondoProteccionPagos: '0',
				descuentoTrabajadorEcos: '',
				destino: dataProcessController.processInfo?.credit?.substring(
					1,
					2
				),
				cat: '',
				descuentoTrabajadorSegDan: '',
				montoMaximoEcos: '',
				conyuge: false,
			};
			// console.log('Auto', data)
			const ENCODED_DATA = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const result = await apiMIUC.tablaAmortizacion(ENCODED_DATA);
			if (result?.code === CODES_RESPONSE.CORRECTO) {
				downloadPDF({
					payload: result?.data.data,
					name: 'Tabla de amortización',
				});
				setAlert({ show: false, message: '', severity: 'error' });
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const downloadTable = async (conyuge: boolean) => {
		let newProduct: any = { ...product };

		Object.entries(product).forEach(([key, value]) => {
			if (typeof value === 'number') {
				newProduct[key] = value.toString();
			}
		});

		// let noFormatProduct: { [key: string]: any } = {};
		// const alphanumerickeys = ['producto', 'tipoCredito', 'destino','cat','catCny']
		// Object.keys(product).forEach(key => {
		//   const value = product[key];
		//   if (!alphanumerickeys.includes(key)) {
		//     noFormatProduct[key] = value.toString().replace(/[^0-9\.-]+/g, "");
		//   } else {
		//     noFormatProduct[key] = value;
		//   }
		// });

		if (
			dataProcessController.processInfo?.credit ===
			MIUC_PRODUCTS.AUTOPRODUCCION
		) {
			handleGetAmortizationTableAutoproduction(
				newProduct as IProductoAutoproduccion
			);
		} else {
			try {
				setLoading(true);
				const data = await handleAmortizationTable(
					newProduct as IProductMonto,
					conyuge
				);
				const ENCODED_DATA = await encodeAllJSONData(
					{
						data: JSON.stringify(data),
					},
					user?.k || ''
				);
				const result = await apiMIUC.tablaAmortizacion(ENCODED_DATA);

				if (result?.code === CODES_RESPONSE.CORRECTO) {
					downloadPDF({
						payload: result?.data.data,
						name: 'Tabla de amortización',
					});
					setAlert({ show: false, message: '', severity: 'error' });
				}
				setLoading(false);
			} catch (error: any) {
				setAlert({
					show: true,
					message:
						error.description ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					severity: 'error',
				});
				setLoading(false);
			}
		}
	};
	const getCellData = (
		title: (typeof tableRowTitles)[0],
		idx: number,
		conyuge: boolean
	) => {        
		const key = conyuge ? `${title.key}Cny` : title.key;
		return (
			<TableCell sx={{ ...title.styleDescription, textAlign: 'right' }}>
				<Box className={classes.cellContainer}>
					<Box className={classes.cellContent}>
						{idx === 0 && (
							<img
								src={conyuge ? conyugalIcon : individualIcon}
								alt={
									conyuge ? 'conyugalIcon' : 'individualIcon'
								}
							/>
						)}
						<p
							style={{
								fontWeight:
									Number(product[key] || 0) > 0
										? 500
										: 'normal',
							}}
						>
							{formatedData[key]}
						</p>
					</Box>
				</Box>
			</TableCell>
		);
	};

	const getCellDownload = (conyuge: boolean) => (
		<TableCell>
			<Box className={classes.flexRight}>
				<Link
					className={classes.donwloadLink}
					onClick={async () => downloadTable(conyuge)}
					underline="none"
				>
					Descargar
					<img src={iconoPDF} alt="iconoPDF" />
				</Link>
			</Box>
		</TableCell>
	);

	return {
		getMessage,
		newStep,
		classes,
		downloadTable,
		returnStep,
		nextStep,
		tableRowTitles,
		formatedData,
		alert,
		loading,
		product,
		image,
		title,
		text,
		totalCuantity,
		conyuge,
		getCellData,
		getCellDownload,
	};
};
