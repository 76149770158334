import { useContext } from 'react';
import { useNavigate } from 'react-router';
import CustomButton from '../../../components/Button';
import MessageCustom from '../../../components/MessageCustom';
import { dataReturnSavingsContext } from '../contextData';
import { useStyles } from '../style';
import warning from '../../../assets/img/icono-advertencia-rojo.png';

const MessagePage = () => {
	const style = useStyles();
	const navigate = useNavigate();
	const { data } = useContext(dataReturnSavingsContext);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<div>
				<img src={warning} alt="warning" />
			</div>
			<div
				style={{
					paddingLeft: '35px',
					paddingRight: '35px',
					marginTop: '35px',
					textAlign: 'center',
				}}
			>
				<MessageCustom
					msg={
						data?.ConsultaDatosDisponibilidadResponse?.data?.msg ||
						''
					}
				></MessageCustom>
			</div>
			<div
				style={{
					paddingLeft: '35px',
					paddingRight: '35px',
					marginTop: '35px',
					textAlign: 'center',
				}}
			>
				Si ya liquidaste el crédito, comunicate sin costo al Infonatel
				al 55 9171 5050 en la Ciudad de México o al 800 008 3900 desde
				cualquier parte del país.
			</div>
			<div className={style.divButtons}>
				<div className={style.Buttons}>
					<CustomButton
						onClick={() => {
							navigate('/inicio');
						}}
						variant="solid"
						styles={{
							width: '100%',
						}}
						label="Salir"
					/>
				</div>
			</div>
		</div>
	);
};

export default MessagePage;
