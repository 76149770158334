/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
//Assets

import imgAltanto from '../../../assets/img/mantente-al-tanto.png';
import imgChecaContacto from '../../../assets/img/ChecaDatosConto.png';
import imgAgendaCita from '../../../assets/img/Agenda-tu-cita.png';
import imgInsSocio from '../../../assets/img/inscribete-socio.png';
import imgPagaMenos from '../../../assets/img/paga-menos.png';
//import { CheckCircleOutlined } from '@mui/icons-material';
const AccreditedCompliance = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	/*const itemsList = [
		{ id: 1, text: 'Obtén tu Constancia de intereses.' },
		{
			id: 2,
			text: 'Mantén actualizados tus Datos de contacto.',
		},
		{ id: 3, text: 'Disfruta los beneficios de ser Socio Infonavit.' },
	];*/

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={12} lg={4}>
				<Paper className={classes.card}>
					<div className={classes.titleCard}>
						<h3>
							Agradecemos el buen cumplimiento de tus pagos.
						</h3>
					</div>
					<div className={classes.textCard}>
						<p>
							Recuerda que en Saldos y Movimientos encuentras
							toda la información de tu crédito, incluso puedes
							realizar tu Pago en línea o consultar lugares de
							pago para tu crédito.
						</p>
						<p>
							Si necesitas hacer trámites presenciales, ahorra
							tiempo agendando tu cita.
						</p>
						<p>
							¡Deduce los intereses de tu crédito en tu
							declaración del SAT! Obtén tu Constancia de
							intereses.
						</p>
						<br />
					</div>
				</Paper>
			</Grid>

			<Grid item sm={12} md={12} lg={8}>
				<Grid container spacing={2}>
					<Grid item sm={12} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-credito/saldos-movimientos')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgAltanto}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item md={6} lg={6}>
						<Grid item lg={12}>
							<div
								onClick={() =>
									navigate('/contactanos/haz-una-cita')
								}
							>
								<img
									src={imgAgendaCita}
									className={classes.imageStyle}
									alt="img banner"
								/>
							</div>
						</Grid>
						<Grid item lg={12} mt={2}>
							<div
								onClick={() =>
									navigate('/mi-perfil/socio-infonavit')
								}
								style={{ height: '100%' }}
							>
								<img
									src={imgInsSocio}
									className={classes.imageStyle}
									alt="img banner"
								/>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container justifyContent="flex-end" mt={2}>
				<Grid
					container
					spacing={2}
					direction="row"
					xs={12}
					sm={12}
					md={12}
					lg={8}
				>
					<Grid item sm={12} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-perfil/actualizar-datos-contacto')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgChecaContacto}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item sm={12} md={6} lg={6}>
						<div
							onClick={() =>
								navigate(
									'/mi-credito/constancia-intereses-declaracion-anual'
								)
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgPagaMenos}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default AccreditedCompliance;
