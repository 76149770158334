import {
	createAsyncThunk,
	createReducer,
	PayloadAction,
} from '@reduxjs/toolkit';
import {
	CLEAR_DATA,
	IDefaultData,
	INITIAL_STATE,
	IProcessBitacora,
	SET_DATA_BITACORA,
} from '../types/bitacora';

export const actions = {
	setData: createAsyncThunk(
		SET_DATA_BITACORA,
		(dataBitacora: IProcessBitacora) => {
			return dataBitacora;
		}
	),
	clearData: createAsyncThunk(CLEAR_DATA, () => {
		return {};
	}),
};

export default createReducer(INITIAL_STATE, {
	[actions.setData.fulfilled.type]: (
		_state: IDefaultData,
		action: PayloadAction<IProcessBitacora>
	) => ({
		..._state,
		dataBitacora: action.payload,
	}),
	[actions.clearData.fulfilled.type]: () => ({
		dataBitacora: {} as IProcessBitacora,
	}),
});
