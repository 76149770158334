/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Divider, Grid, MenuItem, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StaticDatePicker, LocalizationProvider } from '@mui/lab';
import moment, { MomentInput } from 'moment';
import es from 'date-fns/locale/es';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { encodeAllJSONData } from '../../utils/encrypt';
import { IAlert } from '../../interfaces/alert';
import { IDataServiceId, IDataSucursal } from '../../interfaces/makeDate24';
import { makeDate24API } from '../../api/modules/makeDate24';
import { PickersDayProps } from '@mui/lab/PickersDay';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ModalLoading from '../../components/ModalLoading';
import { CustomPickersDay } from './styles';
import CustomButton from '../../components/Button';
import CustomAlert from '../../components/CustomAlert';
import { getHour } from '../../utils/dates';
import { MESSAGES_ERROR } from './utils'

interface Props {
	setPage: (data: number) => void;
	service: string;
	services: any;
	setFechaCita: any;
	setCedisForm: any;
	setHorarioForm: any;
	onConfirm: () => void;
	timerActive: boolean;
	setTimerActive: (data: boolean) => void;
	acceptCita: any;
	caso: any;
	onCloseError: () => void;
	hasError: IAlert;
	handleDoctosRequeridos: (serviceId: string, organizationCode: string) => void;
	doctosRequeridos?: string;
	agendaId: string;
	setAgendaId: (data: any) => void;
	setDataHorario: (data: any) => void;
	serviceDes: string;
}

interface DaysFormat {
	startday: MomentInput | number;
	endday: MomentInput | number;
	typeday: string | null;
}

interface IDataEstados {
	RegionCode: string;
	RegionDes: string;
	ParentRegionCode: string;
}

interface IDataCedis {
	OrganizationAlias: string;
	OrganizationCloseTime: string;
	OrganizationCode: string;
	OrganizationDescription: string;
	OrganizationLocation: string;
	OrganizationOpenTime: string;
}

const defaulTime = {
	seconds: 59,
};

const FormAppointment = ({
	setPage,
	service,
	setFechaCita,
	setCedisForm,
	setHorarioForm,
	onConfirm,
	caso,
	services,
	timerActive,
	setTimerActive,
	acceptCita,
	onCloseError,
	hasError,
	handleDoctosRequeridos,
	doctosRequeridos,
	agendaId,
	setAgendaId,
	setDataHorario,
	serviceDes,
}: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const [descServicio, setDescServicio] = useState('');
	const [fecha, setFecha] = useState<Date | null>(null);
	const [estados, setEstados] = useState<Array<IDataEstados>>([]);
	const [cedis, setCedis] = useState<Array<IDataCedis>>([]);
	const [meses, setMeses] = useState<
		Array<{ startday: string; endday: string; typeday: string }>
	>([]);
	const [horarios, setHorarios] = useState<
		Array<{ cupoCanalId: any; intervaloId: any; FromHour: string; FromMinute: string; ToHour: string; ToMinute: string, numeroDeCupo: string, }>
	>([]);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [form, setForm] = useState({
		cedis: '',
		estado: '',
		codigo: '',
		organization: '',
		horario: '',
	});
	const [time, setTime] = useState(defaulTime);
	const [timerFinal, setTimerFinal] = useState(false);


	useEffect(() => {
		handleOnSubmit();
	}, []);

	const handleOnSubmit = async () => {
		setLoading(true);
		try {
			setDescServicio(serviceDes || '');
			const data: IDataServiceId = {
				pServiceId: service
			}
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const responseTotal = await makeDate24API.statusService(dataEncript);
			if (responseTotal.err) {
				setAlert({
					show: true,
					message: responseTotal.err.description,
					severity: 'error',
				});
			} else {
				setLoading(false);
				if (responseTotal?.data?.regiones && responseTotal?.data?.regiones.length > 0) {
					setEstados(
						responseTotal.data.regiones.sort((a: any, b: any) => {
							if (a.regionDes > b.regionDes) {
								return 1;
							}
							if (a.regionDes < b.regionDes) {
								return -1;
							}
							return 0;
						})
					);
				} else {
					setAlert({
						show: true,
						message: MESSAGES_ERROR.errorService,
						severity: 'error',
					});
				}
			}
		} catch (err) {
			setAlert({
				show: true,
				message: MESSAGES_ERROR.errorService,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleCedis = async (e: any) => {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		setCedis([]);
		setMeses([]);
		setHorarios([]);
		setLoading(true);
		try {
			setForm({ ...form, estado: e.target.value });
			const data: IDataSucursal = {
				entidadFederativaId: e.target.value,
				pServiceId: service
			}
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const responseTotal = await makeDate24API.cesis(dataEncript);
			if (responseTotal.code === '0000') {
				setLoading(false);
				if (responseTotal?.data?.sucursales && responseTotal?.data?.sucursales.length > 0) {
					setCedis(
						responseTotal.data.sucursales.sort((a: any, b: any) => {
							if (a.organizationDescription > b.organizationDescription) {
								return 1;
							}
							if (a.organizationDescription < b.organizationDescription) {
								return -1;
							}
							return 0;
						})
					);
				} else {
					setAlert({
						show: true,
						message: MESSAGES_ERROR.errorSucursales,
						severity: 'error',
					});
				}
			} else {
				setAlert({
					show: true,
					message: responseTotal.message || MESSAGES_ERROR.errorService,
					severity: 'error',
				});
			}
		} catch (err) {
			setAlert({
				show: true,
				message: MESSAGES_ERROR.errorService,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleMeses = async (e: string, date: Date) => {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		setMeses([]);
		setHorarios([]);
		setLoading(true);
		try {
			let month = date?.getMonth() + 1;
			let year = date?.getFullYear();
			setForm({ ...form, cedis: e });
			setCedisForm(e);
			const params = {
				pMonth: month.toString(),
				pYear: year.toString(),
				servicioId: service,
				centroAtencionId: e,
				grupoClienteId: '1'
			};
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(params),
				},
				user?.k || ''
			);
			const responseMeses = await makeDate24API.meses(
				dataEncript
			);
			if (responseMeses.code === '0000') {
				let arrayMes: any = [];
				let Status: string = 'none';
				let contMes: number = 0;
				setAgendaId(responseMeses?.data?.agendaId)
				if (responseMeses?.data?.monthDays && responseMeses?.data?.monthDays.length > 0) {
					responseMeses.data.monthDays.forEach((mes: any) => {
						if (Status !== mes.available) {
							if (mes.available === false) {
								arrayMes[contMes] = {
									startday: mes.dateTime,
									disabled: true,
									endday: null,
									typeday: 'baja',
								};
							} else {
								arrayMes[contMes] = {
									startday: mes.dateTime,
									endday: null,
									typeday: 'alta',
								};
							}
							contMes++;
							Status = mes.available;
						} else {
							const mesAnt = contMes - 1 < 0 ? 0 : contMes - 1;
							arrayMes[mesAnt] = {
								...arrayMes[mesAnt],
								endday: mes.dateTime,
							};
						}
					});
					setMeses(arrayMes);
				} else {
					setAlert({
						show: true,
						message: MESSAGES_ERROR.errorDays,
						severity: 'error',
					});
				}
			} else {
				setAlert({
					show: true,
					message: responseMeses.err?.description || MESSAGES_ERROR.errorService,
					severity: 'error',
				});
			}
		} catch (error) {
			setAlert({
				show: true,
				message: MESSAGES_ERROR.errorService,
				severity: 'error',
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const handleHorario = async (newValue: any) => {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		setHorarios([]);
		setLoading(true);
		try {
			let day = String(newValue.getDate()).padStart(2, '0');
			let month = String(newValue.getMonth() + 1).padStart(2, '0');
			let year = newValue.getFullYear();
			const params = {
				servicioId: service,
				pDate: year + '-' + month + '-' + day || '',
				agendaId: agendaId,
			};
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(params),
				},
				user?.k || ''
			);
			const responseHorario = await makeDate24API.horario(
				dataEncript
			);
			if (responseHorario.code === '0000') {
				if (responseHorario?.data?.intervals && responseHorario.data?.intervals.length > 0) {
					let arrayHora: any = [];
					let contMes: number = 0;
					responseHorario.data.intervals.forEach((hora: any) => {
						const frm = hora.horarioInicio.split(':', 3);
						const toH = hora.horarioFin.split(':', 3);
						arrayHora[contMes] = {
							FromHour: frm[0],
							FromMinute: frm[1],
							ToHour: toH[0],
							ToMinute: toH[1],
							intervaloId: hora.intervaloId,
							cupoCanalId: hora.cupoCanalId,
							numeroDeCupo: hora.numeroDeCupo,
						};
						contMes++;
					});
					setHorarios(arrayHora);
				} else {
					setAlert({
						show: true,
						message: MESSAGES_ERROR.errorHorario,
						severity: 'error',
					});
				}
			} else {
				setLoading(false);
				setAlert({
					show: true,
					message: responseHorario.message || MESSAGES_ERROR.errorService,
					severity: 'error',
				});
			}
		} catch (error) {
			setAlert({
				show: true,
				message: MESSAGES_ERROR.errorService,
				severity: 'error',
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const disabledDays = (date: Date) => {
		let disabled = true;
		if (meses.length > 0) {
			const dayCalender = moment(date);
			meses.forEach((day) => {
				const valid = dayCalender.isBetween(
					moment(day.startday),
					moment(day.endday)
				);
				if (
					day.typeday !== 'baja' &&
					(valid ||
						dayCalender.isSame(moment(day.startday)) ||
						dayCalender.isSame(moment(day.endday)))
				) {
					disabled = false;
				}
			});
		} else {
			disabled = true;
		}
		return disabled;
	};

	const renderWeekPickerDay = (
		date: Date,
		selectedDates: Array<Date | null>,
		pickersDayProps: PickersDayProps<Date>
	) => {
		const dayCalender = moment(date);
		let selectDate: DaysFormat = {
			startday: null,
			endday: null,
			typeday: null,
		};

		meses.forEach((day) => {
			if (
				dayCalender.isBetween(moment(day.startday), moment(day.endday)) ||
				dayCalender.isSame(moment(day.startday)) ||
				dayCalender.isSame(moment(day.endday))
			) {
				selectDate = {
					startday: moment(day.startday).format('DD/MM/YYYY'),
					endday: moment(day.endday).format('DD/MM/YYYY'),
					typeday: day.typeday,
				};
			}
		});

		const isFirstDay =
			dayCalender.format('DD/MM/YYYY') === selectDate.startday ? true : false;
		const isLastDay =
			dayCalender.format('DD/MM/YYYY') === selectDate.endday ? true : false;

		return (
			<CustomPickersDay
				{...pickersDayProps}
				disableMargin
				isFirstDay={isFirstDay}
				isLastDay={isLastDay}
				typeDay={selectDate.typeday}
			/>
		);
	};

	useEffect(() => {
		if (timerActive) {
			const intervalId = setInterval(() => {
				updateTime();
			}, 1000);
			return () => clearInterval(intervalId);
		}
	}, [time, timerActive]);

	const updateTime = () => {
		let timeActual = time.seconds;
		if (timeActual > 1) {
			let params = {
				seconds: timeActual - 1,
			};
			setTime(params);
		} else {
			setTimerFinal(true);
			setTimerActive(false);
		}
	};

	const resetTimer = () => {
		setTime(defaulTime);
	};

	useEffect(() => {
		const dataHora = horarios.find(hora => hora.FromHour === form.horario.substring(0, 2) && hora.FromMinute === form.horario.substring(3, 5));
		if (dataHora) {
			setDataHorario({
				intervaloId: dataHora.intervaloId,
				cupoCanalId: dataHora.cupoCanalId,
				numeroDeCupo: dataHora.numeroDeCupo,
			})
		}
	}, [form.horario]);

	return (
		<Paper
			sx={{
				pt: matches ? 5 : 3,
				pb: matches ? 5 : 5,
				pl: matches ? 3 : 3,
				pr: matches ? 3 : 3,
			}}
		>
			{caso !== undefined && (
				<Grid container columns={12}>
					<Grid item xs={12} sm={12} md={3} lg={3}>
						<h3
							style={{
								fontSize: matches ? 14 : 16,
								marginBottom: matches ? 0 : 16,
							}}
						>
							Número de caso
						</h3>
					</Grid>
					<Grid item xs={12} sm={12} md={9} lg={9}>
						<p
							style={{
								fontSize: matches ? 14 : 16,
								marginTop: matches ? 0 : 16,
							}}
						>
							{caso}
						</p>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Divider />
					</Grid>
				</Grid>
			)}
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={3} lg={3}>
					<h3
						style={{
							fontSize: matches ? 14 : 16,
							marginBottom: matches ? 0 : 16,
						}}
					>
						Servicio
					</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={9} lg={9}>
					<p
						style={{
							fontSize: matches ? 14 : 16,
							marginTop: matches ? 0 : 16,
						}}
					>
						{descServicio}
					</p>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid>
			</Grid>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={3} lg={3}>
					<h3
						style={{
							fontSize: matches ? 14 : 16,
							marginBottom: matches ? 0 : 16,
						}}
					>
						Ubicación
					</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={4.5}
					lg={4.5}
					sx={{ pt: 2, pb: matches ? 1 : 6 }}
				>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: '1fr',
							fontSize: matches ? 14 : 16,
						}}
					>
						<span style={{ marginBottom: 10 }}>Estado</span>
						<Select
							displayEmpty
							sx={{ width: matches ? 305 : 370, height: matches ? 40 : 50 }}
							name="estado"
							value={form.estado}
							onChange={handleCedis}
							renderValue={
								form.estado !== ''
									? undefined
									: () => (
										<label
											style={{ fontSize: 15, color: '#7F7F7F' }}
										>
											Selecciona tu estado
										</label>
									)
							}
							style={{ maxWidth: matches ? '100%' : 390 }}
						>
							{estados.map((element: any, i: number) => (
								<MenuItem
									key={element.regionCode}
									value={element.regionCode}
								>
									{element.regionDes}
								</MenuItem>
							))}
						</Select>
					</div>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={4.5}
					lg={4.5}
					sx={{ pt: 2, pb: matches ? 3 : 6 }}
				>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: '1fr',
							fontSize: matches ? 14 : 16,
						}}
					>
						<span style={{ marginBottom: 10 }}>Centro de servicio</span>
						<Select
							displayEmpty
							sx={{ width: matches ? 305 : 370, height: matches ? 40 : 50 }}
							name="cedis"
							value={form.cedis}
							onChange={(e) => {
								handleMeses(
									e.target.value.toString(),
									fecha || new Date()
								);
								handleDoctosRequeridos(
									e.target.value.toString(),
									service

								);
							}}
							disabled={form.estado
								? false
								: true
							}
							renderValue={
								form.cedis !== ''
									? undefined
									: () => (
										<label
											style={{ fontSize: 15, color: '#7F7F7F' }}
										>
											Selecciona el servicio
										</label>
									)
							}
							style={{ maxWidth: matches ? '100%' : 390 }}
						>
							{cedis.map((element: any, i: number) => (
								<MenuItem
									key={element.organizationCode}
									value={element.organizationCode}
								>
									{element.organizationDescription}
								</MenuItem>
							))}
						</Select>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid>
			</Grid>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={3} lg={3}>
					<h3 style={{ fontSize: matches ? 14 : 16 }}>Documentos necesarios</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={8} lg={8}>
					<p
						dangerouslySetInnerHTML={{
							__html: doctosRequeridos || '',
						}}
					/>
				</Grid>

				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Divider />
				</Grid>
			</Grid>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={3} lg={3}>
					<h3 style={{ fontSize: matches ? 14 : 16 }}>Fecha y hora</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={4.5} lg={4.5}>
					<div
						style={{
							display: 'flex',
							width: '100%',
							height: 280,
							justifyContent: matches ? 'center' : 'normal',
						}}
					>
						<LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
							<StaticDatePicker
								displayStaticWrapperAs="desktop"
								openTo="day"
								onMonthChange={(newValue) => {
									setFecha(newValue);
									setFechaCita(newValue);
									handleMeses(form.cedis, newValue);
								}}
								minDate={new Date()}
								value={fecha}
								onChange={(newValue) => {
									setFecha(newValue);
									setFechaCita(newValue);
									handleHorario(newValue);
								}}
								disabled={
									form.estado && form.cedis
										? false
										: true
								}
								renderInput={(params) => (
									<TextField style={{ margin: 0 }} {...params} />
								)}
								renderDay={renderWeekPickerDay}
								shouldDisableDate={disabledDays}
							/>
						</LocalizationProvider>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={4.5} lg={4.5} sx={{ pt: 2 }}>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: '1fr',
							paddingBottom: matches ? 0 : 30,
						}}
					>
						<span
							style={{
								marginBottom: 15,
								fontWeight: 500,
								color: '#333333',
							}}
						>
							Disponibilidad
						</span>
						<ul
							style={{
								margin: 0,
								padding: 0,
								listStyle: 'none',
								marginBottom: matches ? 20 : 0,
							}}
						>
							<li>
								<span
									style={{
										width: 10,
										height: 10,
										border: '1px solid #293990',
										background: '#293990',
										borderRadius: '50%',
										display: 'inline-block',
										marginRight: 5,
									}}
								/>
								Día seleccionado
							</li>
							<li>
								<span
									style={{
										width: 10,
										height: 10,
										border: '1px solid #3ABE00',
										background: '#3ABE00',
										borderRadius: '50%',
										display: 'inline-block',
										marginRight: 5,
									}}
								/>
								Disponibilidad
							</li>
							<li>
								<span
									style={{
										width: 10,
										height: 10,
										border: '1px solid #FF1D1D',
										background: '#FF1D1D',
										borderRadius: '50%',
										display: 'inline-block',
										marginRight: 5,
									}}
								/>
								Sin disponibilidad
							</li>
						</ul>
					</div>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: '1fr',
							marginBottom: 40,
						}}
					>
						<span style={{ marginBottom: matches ? 5 : 15 }}>
							Seleccionar horario
						</span>
						<Select
							displayEmpty
							name="horario"
							value={form.horario}
							onChange={(e) => {
								setForm({ ...form, [e.target.name]: e.target.value });
								setHorarioForm(e.target.value);
								setTimerFinal(false);
							}}
							disabled={
								form.estado && form.cedis && horarios.length !== 0
									? false
									: true
							}
							renderValue={
								form.horario !== ''
									? undefined
									: () => (
										<label
											style={{
												fontSize: 15,
												color: '#7F7F7F',
											}}
										>
											Selecciona el horario
										</label>
									)
							}
							style={{
								maxWidth: matches ? '100%' : 350,
								display: 'flex',
							}}
						>
							{horarios.map((element: any, i: number) => (
								<MenuItem
									key={'horario' + i}
									value={element.FromHour + ':' + element.FromMinute}
								>
									{getHour(element.FromHour + ':' + element.FromMinute)}{' '}
									- {getHour(element.ToHour + ':' + element.ToMinute)}
								</MenuItem>
							))}
						</Select>
					</div>
				</Grid>
				<CustomAlert
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
					onClose={() => {
						setAlert({
							show: false,
							message: '',
							severity: alert.severity,
						});
					}}
				/>
				<CustomAlert
					show={hasError.show}
					severity={hasError.severity}
					message={hasError.message}
					onClose={onCloseError}
				/>
				{timerActive && (
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div style={{ textAlign: 'center', margin: '20px auto' }}>
							Tienes{' '}
							<span style={{ color: 'rgb(255, 29, 29)' }}>
								0:{time.seconds > 9 ? time.seconds : '0' + time.seconds}
							</span>{' '}
							segundos para confirmar tu cita
						</div>
					</Grid>
				)}
				{timerFinal && (
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div style={{ textAlign: 'center', margin: '20px auto' }}>
							Expiró tu tiempo para confirmar tu cita
						</div>
					</Grid>
				)}
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							marginTop: !timerActive ? '35px' : '0px',
						}}
					>
						{!timerActive && (
							<CustomButton
								label="Reservar"
								onClick={() => {
									onConfirm();
									resetTimer();
									setTimerFinal(false);
								}}
								disabled={
									form.estado && form.cedis && form.horario
										? false
										: true
								}
								variant="solid"
								styles={{
									marginTop: 70,
									width: 260,
									height: 40,
									margin: '0 auto',
								}}
							/>
						)}
						{timerActive && (
							<CustomButton
								label="Confirmar"
								onClick={acceptCita}
								variant="solid"
								styles={{
									width: 260,
									height: 40,
								}}
							/>
						)}
					</div>
				</Grid>
			</Grid>
			<ModalLoading loading={loading} />
		</Paper>
	);
};

export default FormAppointment;
