import API from '..';

interface IDataCitasActuales {
	pAppointmentCode: string;
}

interface IDataServiceId {
	pServiceId: string;
}

interface IDataSucursal {
	entidadFederativaId: string;
	pServiceId: string;
}

interface IDataCancelar {
	pAppointmentId: string;
	motivoCancelacion: string;
}

interface IDataReservar {
	pRequestedDate: string;
	pRequestedHour: string;
	pRequestedMinute: string;
	centroAtencionId: string;
	servicioId: string;
}

interface IDataCrear {
	pCentroAtencionId: string;
	pServicioId: string;
	pDate: string;
	pHour: string;
	pMinute: string;
	pAppointmentCode: string;
	pClientCustomField: string;
	pAppointmentCustomField: string;
	pReservationId: string;
}

interface IDataUpdate {
	pAppointmentId: string;
	pCentroAtencionId: string;
	pServicioId: string;
	pDate: string;
	pHour: string;
	pMinute: string;
	pAppointmentCode: string;
	pClientCustomField: string;
	pAppointmentCustomField: string;
	pReservationId: string;
}

interface IDataMeses {
	pMonth: string;
	pYear: string;
	servicioId: string;
	centroAtencionId: string;
	grupoClienteId: string;
}

interface IDataHorarios {
	servicioId: string;
	centroAtencionId: string;
	pDate: string;
	grupoClienteId: string;
}

interface IDataDocumentosRequeridos {
	pOrganizationCode: string;
	pServiceId: string;
}

interface IDataDownloadPDF {
	service: string;
	estatus: string;
	fechaEstatus: string;
	codigoCita: string;
	fechaCita: string;
	horarioCita: string;
	organizationDes: string;
	organizationLocation: string;
	listadoFormateado: string;
	numeroCaso: string;
	CreationDate: string;
}

class MakeDate24API extends API {
	services(data?: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/obtener-servicios-disponibles`,
				method: 'POST',
				data,
				//filtro: serviceId
			})
		);
	}
	statusService(data: IDataServiceId): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/obtener-regiones`,
				method: 'POST',
				data,
			})
		);
	}
	cesis(data: IDataSucursal): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/obtener-sucursales`,
				method: 'POST',
				data,
			})
		);
	}
	meses(data: IDataMeses): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/obtener-programacion-mensual`,
				method: 'POST',
				data,
			})
		);
	}
	horario(data: IDataHorarios): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/obtener-programacion-laboral`,
				method: 'POST',
				data,
			})
		);
	}
	reservar(data: IDataReservar): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/reservar-cita`,
				method: 'POST',
				data,
			})
		);
	}
	crear(data: IDataCrear): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/crear-cita`,
				method: 'POST',
				data,
			})
		);
	}
	citasActuales(data: IDataCitasActuales): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/obtener-cita`,
				method: 'POST',
				data,
			})
		);
	}
	cancelar(data: IDataCancelar): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/cancelar-cita`,
				method: 'POST',
				data,
			})
		);
	}
	actualizar(data: IDataUpdate): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/crear-cita`,
				method: 'POST',
				data,
			})
		);
	}
	downloadPDF(data: IDataDownloadPDF): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/generar-comprobante`,
				method: 'POST',
				data,
			})
		);
	}
	documentosRequeridos(data: IDataDocumentosRequeridos): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/obtener-requisitos`,
				method: 'POST',
				data,
			})
		);
	}

	//Endpoints sin token
	servicesOfline(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/funcion/obtener-servicios-disponibles`,
				method: 'POST',
				data: {},
			})
		);
	}
	statusServiceOfline(data: IDataServiceId): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/funcion/obtener-regiones`,
				method: 'POST',
				data,
			})
		);
	}
	cesisOfline(data: IDataSucursal): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/funcion/obtener-sucursales`,
				method: 'POST',
				data,
			})
		);
	}
	mesesOfline(data: IDataMeses): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/funcion/obtener-programacion-mensual`,
				method: 'POST',
				data,
			})
		);
	}
	horarioOfline(data: IDataHorarios): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/funcion/obtener-programacion-laboral`,
				method: 'POST',
				data,
			})
		);
	}
	reservarOfline(data: IDataReservar): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/funcion/reservar-cita`,
				method: 'POST',
				data,
			})
		);
	}
	crearOfline(data: IDataCrear): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/funcion/crear-cita`,
				method: 'POST',
				data,
			})
		);
	}
	citasActualesOfline(data: IDataCitasActuales): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/funcion/obtener-cita`,
				method: 'POST',
				data,
			})
		);
	}
	cancelarOfline(data: IDataCancelar): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/funcion/cancelar-cita`,
				method: 'POST',
				data,
			})
		);
	}
	actualizarOfline(data: IDataUpdate): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/funcion/crear-cita`,
				method: 'POST',
				data,
			})
		);
	}
	downloadPDFOfline(data: IDataDownloadPDF): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/funcion/generar-comprobante`,
				method: 'POST',
				data,
			})
		);
	}
	documentosRequeridosOfline(data: IDataDocumentosRequeridos): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/citas-24/funcion/obtener-requisitos`,
				method: 'POST',
				data,
			})
		);
	}
}

export const makeDate24API = new MakeDate24API('');
