const DisabilityCertificate = () => {
	return (
		<>
			<p style={{ textAlign: 'left' }}>
				El certificado debe indicar el tipo de discapacidad, sea mental,
				auditiva, visual o motriz. Debe ser emitido por el IMSS, ISSSTE,
				Secretaría del Trabajo y Previsión Social o por algún médico de
				la Procuraduría Federal o del Sistema Nacional para el
				Desarrollo Integral de la Familia (DIF), cuando quien padece la
				discapacidad es un familiar.
			</p>
		</>
	);
};

export default DisabilityCertificate;
