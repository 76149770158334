import { Box, Divider, Link, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ApoyoInfonavitSteps } from '../../MIUC/components/FollowSteps/productSteps/ApoyoFinonavitSteps';
import { grey } from '@mui/material/colors';
import { RoundBtn } from '../../MIUC/components/RoundBtn/roundBtn';
import {
	centeredItems,
	useGeneralStyles,
} from '../../../pages/MIUC/views/styles/general.styles';
import iconoPDF from '../../../assets/img/icono-descarga.png';
import { useStyles } from './styles';

interface IProps {
	setView: (arg: number) => void;
}

const InfoData = ({ setView }: IProps) => {
	const classes = useStyles();
	const generalStyle = useGeneralStyles();
	const navigate = useNavigate();

	return (
		<>
			<Box className={classes.mainContainer}>
				<div className={classes.header}>
					<span className={classes.headerTitulo}>
						Pasos a seguir para solicitar tu crédito
					</span>
					<span className={classes.headerSubtitulo}>
						Ya elegiste tu crédito, revisa los pasos para
						solicitarlo y recuerda que en el Infonavit todos los
						trámites son gratuitos
					</span>
				</div>
			</Box>
			{ApoyoInfonavitSteps().map((data: any, index) => (
				<Paper
					elevation={1}
					className={
						data.paperType === 'blue'
							? classes.stepPaperBlue
							: classes.stepPaper
					}
					key={index}
				>
					<div className={classes.paperLayout}>
						<div>
							{typeof data.paperIcon === 'string' ? (
								<img
									src={data.paperIcon}
									className={classes.icoTemas}
									alt={data.paperIcon}
								/>
							) : (
								<Box
									sx={{
										background: grey[100],
										borderRadius: 100,
										margin: '20px',
										padding: '8px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										'&> .MuiSvgIcon-root': {
											fontSize: '50px',
										},
									}}
								>
									{/* <PaperIcon color="info" fontSize="large" /> */}
								</Box>
							)}
						</div>
						<div className={classes.stepText}>
							<span className={classes.title}>{data.title}</span>
							<span className={classes.description}>
								{data.description}
							</span>
							<div className={classes.enlaces}>{data.link}</div>
						</div>
					</div>
				</Paper>
			))}
			<Link
				underline="hover"
				className={classes.downloadPDF}
				onClick={() => {
					window.print();
				}}
			>
				Imprimir
				<img
					src={iconoPDF}
					alt="iconoPDF"
					className={classes.icoDescarga}
				/>
			</Link>
			<div style={centeredItems}>
				<Divider
					flexItem
					orientation="horizontal"
					className={classes.division}
				/>
			</div>
			<div className={generalStyle.bottonsContainer}>
				<RoundBtn variant="outlined" onClick={() => setView(0)}>
					Regresar
				</RoundBtn>
				<RoundBtn variant="contained" onClick={() => navigate('/')}>
					Finalizar
				</RoundBtn>
			</div>
		</>
	);
};

export default InfoData;
