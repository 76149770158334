import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyle = makeStyles((theme: Theme) => ({
	progressBar: {
		marginTop: 8,
		height: 5,
		background: '#EBEBEB',
		border: '1px solid #EBEBEB',
		borderRadius: 6,
	},
	progressBarContent: {
		background: '#EBEBEB',
		border: '0px solid #EBEBEB',
		borderRadius: 6,
		marginLeft: 40,
		marginRight: 40,

		[theme.breakpoints.down('md')]: {
			marginLeft: 1,
			marginRight: 1,
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: 1,
			marginRight: 1,
		},
	},
	LabelContent: {
		marginLeft: 40,
		marginRight: 40,
		textAlign: 'right',

		[theme.breakpoints.down('md')]: {
			marginLeft: 1,
			marginRight: 1,
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: 1,
			marginRight: 1,
		},
	},
	Label: {
		fontSize: 13,
	},
	ContraseñaSpanGray: {
		fontSize: '16px',
		color: '#8F8F8F',
	},
	containerElement: {
		display: 'flex',
		alignItems: 'center',
	},
	iconPointer: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
	fontSize: {
		marginTop: 2.5,
		fontSize: 17,
	},
}));
