import { useState } from 'react';
import {
	Box,
	FormControlLabel,
	Paper,
	Radio,
	RadioGroup,
	useMediaQuery,
} from '@mui/material';
import { useStyles } from './styles';
import CustomButton from '../../components/Button';
import { useTheme } from '@mui/material/styles';
import { HeaderList } from '../../components/HeaderList';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

interface Props {
	setPage: (data: number) => void;
	setStatus: (data: number) => void;
}

const Home = ({ setPage, setStatus }: Props) => {
	const [enableButton, setEnableButton] = useState(true);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [pageTemp, setPageTemp] = useState(0);
	const classes = useStyles();
	const handleOnChangeRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name } = event.target;
		if (event.target.checked && name === 'one') {
			//REA
			setPageTemp(2);
			setStatus(1);
			setEnableButton(false);
		} else if (event.target.checked && name === 'two') {
			//ROA
			setPageTemp(2);
			setStatus(2);
			setEnableButton(false);
		} else if (event.target.checked && name === 'three') {
			//Pasivo
			setPageTemp(2);
			setStatus(3);
			setEnableButton(false);
		}
	};

	return (
		<Box>
			<HeaderList title="Aclaración de pagos" />
			<Paper sx={{ pt: 5, pb: 5, pr: matches ? 5 : 15, pl: matches ? 5 : 15 }}>
				<div id="radioOptions">
					<h3 className={classes.title}>¿Cómo fue realizado tu pago?</h3>
					<p style={{ marginLeft: '-20px' }}><b>Elige una de las siguientes opciones:</b></p>
					<RadioGroup>
						<FormControlLabel
							sx={{
								mb: 1,
							}}
							control={
								<Radio onChange={handleOnChangeRadioButton} name="one" />
							}
							style={{ color: '#000000' }}
							label="Realicé mi pago"
							value="1"
						/>
						<FormControlLabel
							sx={{
								mb: 1,
							}}
							control={
								<Radio onChange={handleOnChangeRadioButton} name="two" />
							}
							style={{ color: '#000000' }}
							label="Mi patrón realizó el pago"
							value="2"
						/>
						<FormControlLabel
							control={
								<Radio
									onChange={handleOnChangeRadioButton}
									name="three"
								/>
							}
							style={{ color: '#000000' }}
							label="Pago a pasivo"
							value="3"
						/>
						<p
							style={{
								color: '#293990',
								fontSize: 12,
								display: 'flex',
								alignItems: 'center',
								margin: 0,
								padding: '0px 32px',
							}}
						>
							<ErrorOutlinedIcon
								sx={{
									color: '#293990',
									fontSize: 16,
									marginRight: '2px'
								}}
							/>
							Vendí mi casa y se liquidó con otro crédito Infonavit
						</p>
					</RadioGroup>
					<h3 style={{ textAlign: 'center', marginTop: '30px' }}>
						Para hacer una aclaración debes esperar 10 días hábiles después de haber realizado tu pago.
					</h3>
					<div
						style={{
							width: matches ? '100%' : '600px',
							margin: '30px auto 20px',
							display: matches ? 'block' : 'flex',
							justifyContent: 'space-between',
						}}
					>
						<CustomButton
							label="Regresar"
							onClick={() => setPage(0)}
							variant="outlined"
							styles={{
								width: 260,
								height: 40,
								margin: '10px auto',
							}}
						/>
						<CustomButton
							label="Continuar"
							onClick={() => setPage(pageTemp)}
							disabled={enableButton}
							variant="solid"
							styles={{
								width: 260,
								height: 40,
								margin: '10px auto',
							}}
						/>
					</div>
				</div>
			</Paper>
		</Box>
	);
};

export default Home;
