import { Bitacora, IBitacora } from '../../interfaces/bitacora';

const _bitacora = new Bitacora('114', 'resumen-movimientos-ahorro');

export const ENDPOINTS_RESUMEN_MOVIMIENTOS_MI_AHORRO: IBitacora = {
	'/verify-file': _bitacora.Read('114100', 'Verificar archivo'),
	'/file-required': _bitacora.Update('114101', 'Actualizar peticion'),
	'/valid-status': _bitacora.Read('114102', 'Validar estatus'),
	'/consula-decreto': _bitacora.Read('114103', 'Consultar decreto'),
	'/valid-mark': _bitacora.Read('114104', 'Validar marca'),
};
