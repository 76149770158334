import { HeaderList } from '../../../../../components/HeaderList';
import styles from './styles/styles.module.css';

const EstimatedTerm = () => {
	return (
		<>
			<p>
				Será menor que el plazo que seleccionaste para pagar tu crédito,
				debido a que las aportaciones patronales se utilizan para el
				pago de tu crédito, lo que significa que acabarás de pagarlo
				mucho antes, siempre y cuando mantengas tus condiciones
				laborales y financieras actuales:
			</p>

			<HeaderList
				listSizeIcon={18}
				list={['Tener relación laboral', 'Mismo ingreso salarial']}
			/>

			<p>
				<b>Y que tu patrón cumpla en tiempo y forma con:</b>
			</p>

			<HeaderList
				listSizeIcon={18}
				list={[
					'El pago de las aportaciones',
					'Descontar de tu salario el monto para el pago de tu crédito',
				]}
			/>
		</>
	);
};

export default EstimatedTerm;
