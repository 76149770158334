import { Grid } from '@mui/material';
import { HeaderList } from "../../../../../components/HeaderList";

const BirthCertificate = () => {
	return (
		<>
			<p>
				En estos estados se emiten actas de nacimiento digitales; sin
				embargo, debes presentarla impresa.
			</p>
			<Grid container columns={12}>
				<Grid item xs={12} md={4}>
					<HeaderList
						listSizeIcon={18}
						list={[
							'Aguascalientes',
							'Baja California',
							'Baja California Sur',
							'Campeche',
							'Colima',
							'Chihuahua',
							'Coahuila',
							'Durango',
						]}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<HeaderList
						listSizeIcon={18}
						list={[
							'Estado de México',
							'Guerrero',
							'Hidalgo',
							'Jalisco',
							'Nuevo León',
							'Morelos',
							'Oaxaca',
							'Puebla',
						]}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<HeaderList
						listSizeIcon={18}
						list={[
							'Quintana Roo',
							'San Luis Potosí',
							'Tamaulipas',
							'Tlaxcala',
							'Veracruz',
							'Zacatecas.',
						]}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default BirthCertificate;
