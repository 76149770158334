/* eslint-disable react-hooks/exhaustive-deps */
import { useState, Fragment, useEffect } from 'react';
import {
	Grid,
	FormControlLabel,
	RadioGroup,
	Radio,
	FormControl,
	Divider,
	Select,
	Paper,
	MenuItem,
	Tooltip,
} from '@mui/material';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

// Components
import Layout from '../../components/Layout';
import { HeaderList } from '../../components/HeaderList';
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';

//Assets
import { useStyles } from './styles';
import { currencyFormat, isNumberFloat } from '../../utils/expressions';
import { formatMoney } from '../../utils/validators';

//Resources
import { IAlert } from '../../interfaces/alert';

import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { calculatorRequestAPI } from '../../api/modules/CalculatorUma';
import { useBitacora } from '../../hooks/useBitacora';

interface IDataUma {
	opcion: string;
	cantidad: string;
	conversion: string;
}
interface ResCalculo {
	code: string;
	message: string;
	data: {
		calculo: number;
		tipoCredito: [{ 1: number }, { 2: number }];
	};
}
interface DataChange {
	target: {
		value: string;
	};
}

const CalculatorUma = () => {
	const { regBitacoraNotOld } = useBitacora();
	const classes = useStyles();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [loading, setLoading] = useState(false);
	const [dataUma, setDataUma] = useState<IDataUma>({
		opcion: '1',
		cantidad: '0',
		conversion: '1',
	});
	const [dataConvertion, setDataConvertion] = useState(0);
	const [typeUmaOne, setTypeUmaOne] = useState(0);
	const [typeUmaTwo, setTypeUmaTwo] = useState(0);
	const handleSubmit = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				opcion: dataUma.opcion ? dataUma.opcion.toString() : '',
				cantidad: dataUma.cantidad
					.toString()
					.replaceAll(',', '')
					.replaceAll(' ', ''),
				conversion: dataUma.conversion ? dataUma.conversion.toString() : '',
			};
			const dataSendEncrypt = await encrypt(JSON.stringify(paramsData), key);
			calculatorRequestAPI.getConvertion
				.post<
					{
						data: string;
					},
					ResCalculo
				>('', {
					data: dataSendEncrypt,
				})
				.then((res: ResCalculo) => {
					if (res.data?.calculo) {
						setDataConvertion(res.data.calculo);
						regBitacoraNotOld(calculatorRequestAPI.getConvertion.scheme, paramsData);
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
					setLoading(false);
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false);
		}
	};
	const getTypeUma = async () => {
		setLoading(true);
		try {
			calculatorRequestAPI.getTypeUma
				.post<{}, ResCalculo>('', {})
				.then((res: ResCalculo) => {
					if (res.data?.tipoCredito) {
						setTypeUmaOne(res.data.tipoCredito[0]['1']);
						setTypeUmaTwo(res.data.tipoCredito[1]['2']);
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
					setLoading(false);
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		getTypeUma();
	}, []);

	return (
		<Layout>
			<Fragment>
				<HeaderList
					title="Calculadora de pesos y unidad de medida"
					date="Información al 12 julio 2021"
				/>
				<ModalLoading loading={loading} />
				<Paper elevation={0}>
					<div className={classes.containerGlobal}>
						<Grid container spacing={4} className={classes.secondary}>
							<Grid
								item
								xs={12}
								sm={12}
								md={6}
								lg={7}
								className={classes.containerSecond}
							>
								<Paper
									variant="outlined"
									className={classes.containerInfo}
								>
									<div>
										<h3
											className={classes.h3}
											style={{ marginBottom: 10 }}
										>
											Elige una de las siguientes opciones
										</h3>
										<RadioGroup
											value={dataUma.opcion}
											onChange={(e: DataChange) => {
												setDataUma({
													...dataUma,
													opcion: e.target.value,
												});
												setDataConvertion(0);
											}}
											style={{ marginLeft: 60, marginBottom: 10 }}
										>
											<FormControlLabel
												control={<Radio name="hasCredit" />}
												style={{ color: '#000000' }}
												label="Tengo un crédito"
												value={1}
											/>
											<FormControlLabel
												control={<Radio name="noCredit" />}
												style={{ color: '#000000' }}
												label="No tengo un crédito"
												value={2}
											/>
										</RadioGroup>
										<Divider />
										<h3 className={classes.h3}>
											Cantidad a convertir
										</h3>
										<p style={{ margin: 0, fontSize: 16 }}>
											Cantidad en pesos o UMA
											<label
												style={{
													color: '#D1001F',
													position: 'relative',
												}}
											>
												*
												<Tooltip
													arrow
													title="Unidad de Medida y Actualización"
													placement="right"
												>
													<ErrorOutlinedIcon
														sx={{
															color: '#D1001F',
															position: 'absolute',
															top: '-6px',
															fontSize: '1.4rem',
															left: '15px',
															cursor: 'pointer',
															transform: 'rotate(180deg)',
														}}
													/>
												</Tooltip>
											</label>
										</p>
										<TextInput
											id="currentSalary"
											name="currentSalary"
											placeholder="0.00"
											onChange={(e: DataChange) => {
												if (isNumberFloat(e.target.value)) {
													let valueInt = parseInt(
														e.target.value
													);
													if (valueInt.toString().length < 9) {
														setDataUma({
															...dataUma,
															cantidad: e.target.value,
														});
														setDataConvertion(0);
													}
												}
											}}
											onBlur={() => {
												if (dataUma.cantidad) {
													if (dataUma.cantidad === '.') {
														setDataUma({
															...dataUma,
															cantidad: '',
														});
													} else {
														setDataUma({
															...dataUma,
															cantidad: formatMoney(
																Number(dataUma.cantidad)
															).substring(1),
														});
													}
												}
											}}
											onFocus={() => {
												if (dataUma.cantidad) {
													setDataUma({
														...dataUma,
														cantidad: dataUma.cantidad
															.toString()
															.replaceAll(',', '')
															.replaceAll(' ', ''),
													});
												}
											}}
											value={dataUma.cantidad}
										/>
										<FormControl fullWidth>
											<p
												style={{
													margin: 0,
													fontSize: 16,
													marginTop: 20,
												}}
											>
												Conversión que quieres hacer
												<label style={{ color: '#D1001F' }}>
													*{' '}
												</label>
											</p>
											<Select
												name="estado"
												value={dataUma.conversion}
												sx={{ height: 40 }}
												onChange={(e: DataChange) => {
													setDataUma({
														...dataUma,
														conversion:
															e.target.value.toString(),
													});
													setDataConvertion(0);
												}}
												inputProps={{
													'aria-label': 'Without label',
												}}
											>
												<MenuItem value={0}>Selecciona</MenuItem>
												<MenuItem value={1}>UMA - Pesos</MenuItem>
												<MenuItem value={2}>
													UMA Mensual - Pesos
												</MenuItem>
												<MenuItem value={3}>Pesos - UMA</MenuItem>
												<MenuItem value={4}>
													Pesos - UMA Mensual
												</MenuItem>
											</Select>
										</FormControl>
										<div
											className={classes.botonContainerRadio}
											style={{ marginTop: 30, paddingBottom: 20 }}
										>
											<CustomButton
												data-testid="buttonContinuar"
												label="Hacer conversión"
												onClick={() => handleSubmit()}
												variant="solid"
												disabled={
													!Boolean(
														dataUma.opcion &&
														dataUma.conversion &&
														dataUma.cantidad &&
														parseFloat(dataUma.cantidad) >
														0
													)
												}
												styles={{
													width: 282,
													height: 40,
													marginRight: 20,
													marginBottom: 10,
												}}
											/>
										</div>
										<Divider />
										<h3
											style={{
												marginTop: 10,
												color: '#293990',
												fontSize: 18,
											}}
										>
											* Datos obligatorios
										</h3>
									</div>
								</Paper>
							</Grid>
							<Grid
								item
								sm={12}
								md={6}
								lg={5}
								p={2}
								className={classes.containerSecond}
							>
								<div className={classes.containerAmountHeader}>
									<h3 className={classes.titleAmount}>
										Resultado de la conversión:
									</h3>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											padding: '0 5px',
										}}
									>
										<p className={classes.amount}>
											{dataUma.conversion === '1' ||
												dataUma.conversion === '2'
												? ''
												: '$'}
											{currencyFormat(
												dataUma.cantidad
													.toString()
													.replaceAll(',', '')
													.replaceAll(' ', '')
											)}
											<span
												className={classes.amountCustom}
												style={{ marginRight: 5 }}
											>
												{' '}
												{dataUma.conversion === '1' ||
													dataUma.conversion === '2'
													? 'UMA'
													: 'Pesos'}
												<span className={classes.smallText}>
													{dataUma.conversion === '2'
														? ' Mensual'
														: ''}
												</span>
											</span>
											={' '}
											{dataUma.conversion === '1' ||
												dataUma.conversion === '2'
												? '$'
												: ''}
											{currencyFormat(dataConvertion)}
											<span className={classes.amountCustom}>
												{' '}
												{dataUma.conversion === '1' ||
													dataUma.conversion === '2'
													? 'Pesos'
													: 'UMA'}
												<span className={classes.smallText}>
													{dataUma.conversion === '4'
														? ' Mensual'
														: ''}
												</span>
											</span>
										</p>
									</div>
									<div style={{ padding: '0 20px' }}>
										<p
											style={{
												marginTop: 5,
												color: '#293990',
												fontSize: 12,
												position: 'relative',
												padding: '0px 12px',
											}}
										>
											<ErrorOutlinedIcon
												className={classes.icon}
												sx={{
													color: '#293990',
												}}
											/>
											Determinado a partir del crecimiento
											porcentual de la unidad de medida y
											actualización del 2022.
										</p>
									</div>
								</div>
								<div className={classes.containerAmountBody}>
									<Grid container>
										<Grid
											item
											xs={6}
											sm={6}
											md={8}
											className={classes.containerText}
										>
											Tengo un crédito
										</Grid>
										<Grid
											item
											xs={6}
											sm={6}
											md={4}
											className={classes.containerResult}
										>
											{dataUma.opcion === '1' ? 'Sí' : 'No'}
										</Grid>
										<Grid
											item
											xs={6}
											sm={6}
											md={8}
											className={classes.containerText}
										>
											Incremento porcentual diario
										</Grid>
										<Grid
											item
											xs={6}
											sm={6}
											md={4}
											className={classes.containerResult}
										>
											$
											{dataUma.opcion === '1'
												? currencyFormat(
													typeUmaOne ? typeUmaOne : 100.81
												)
												: currencyFormat(
													typeUmaTwo ? typeUmaTwo : 108.57
												)}{' '}
											MXN
										</Grid>
										<Grid
											item
											xs={6}
											sm={6}
											md={8}
											className={classes.containerText}
										>
											Cantidad
										</Grid>
										<Grid
											item
											xs={6}
											sm={6}
											md={4}
											className={classes.containerResult}
										>
											{currencyFormat(
												dataUma.cantidad
													.toString()
													.replaceAll(',', '')
													.replaceAll(' ', '')
											)}
										</Grid>
										<Grid
											item
											xs={6}
											sm={6}
											md={6}
											className={classes.containerText}
										>
											Conversión que deseas hacer
										</Grid>
										<Grid
											item
											xs={6}
											sm={6}
											md={6}
											className={classes.containerResult}
										>
											{dataUma.conversion === '1'
												? 'UMA - Pesos'
												: dataUma.conversion === '2'
													? 'UMA Mensual - Pesos'
													: dataUma.conversion === '3'
														? 'Pesos - UMA'
														: 'Pesos - UMA Mensual'}
										</Grid>
									</Grid>
								</div>
								<CustomAlert
									message={alert.message}
									severity={alert.severity}
									show={alert.show}
								/>
							</Grid>
						</Grid>
					</div>
				</Paper>
			</Fragment>
		</Layout>
	);
};

export default CalculatorUma;
