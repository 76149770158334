import { makeStyles } from '@mui/styles';
import { COLORS } from '../../utils';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		marginTop: 20,
		width: '100%',
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: COLORS.PINK,
		padding: 20,
		paddingTop: 50,
		position: 'relative',
	},
	containerHeader: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
		height: 'auto',
		backgroundColor: '#F2F2F2',
		paddingInline: 20,
		paddingTop: 20,
		paddingBottom: 20,
	},
	labelHeaderTitle: {
		color: COLORS.PINK,
		fontWeight: '500',
		fontSize: 18,
		marginBottom: 7,
	},
	labelHeaderSubTitle: {
		fontWeight: '500',
		fontSize: 16,
	},
	containerVideo: {
		width: '100%',
		height: 500,
		marginBottom: 30,
	},
	labelQ: {
		fontWeight: 500,
		margin: '30px 0px',
		'& > div > p': {
			display: 'inline',
			width: 'auto',
			margin: 0,
		},
	},
	divider: {
		fontSize: 18,
		fontWeight: '400',
		'& > span': {
			color: COLORS.PINK,
		},
	},
	dividerContainer: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		height: 50,
		borderTopWidth: 2,
		borderTopStyle: 'solid',
		borderTopColor: COLORS.PINK,
	},
	dividerSubContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: COLORS.PINK,
		width: '30%',
		height: '100%',
		borderBottomLeftRadius: 150,
		borderBottomRightRadius: 150,
		borderBottomColor: COLORS.PINK,

		[theme.breakpoints.down('md')]: {
			width: '70%',
		},
	},
	dividerButtons: {
		height: 20,
		borderLeftColor: '#FFFFFF',
		borderLeftWidth: 1,
		borderLeftStyle: 'solid',
		marginInline: 15,
	},
	buttonDivider: {
		fontSize: 14,
		color: '#FFFFFF',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '500',
		},

		[theme.breakpoints.down('md')]: {
			fontSize: 13,
			textAlign: 'center'
		},
	},
	containerVideos: {
		display: 'flex',
		gap: 15,
		width: '100%',
		padding: '10px 5px',
		// overflow: 'auto',
		// marginTop: 20,
		// marginInline: 10,
	},
	startIconAbsolute: {
		position: 'absolute',
		right: 0,
		top: 0,
		width: '100%',
		height: 'auto',
		display: 'flex',
		justifyContent: 'end',

		'& div': {
			background: COLORS.PINK,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'baseline',
			width: '110px',
			height: '35px',
			position: 'relative',
			borderRadius: 0,
			marginRight: 30,
			color: '#fff',
			fontSize: 14,

			'&:before': {
				content: '""',
				position: 'absolute',
				top: 29,
				left: 0,
				borderWidth: '0 50px 7px 60px',
				borderStyle: 'solid',
				borderColor: 'transparent transparent #fff transparent',
			},
		},
	},
	mainVideoContainer: {
		width: '100%',
		margin: '20px 0px 10px 0px',
	},
	arrowVideo: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		'& div': {
			color: '#d8d8d9',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 30,
			width: 30,
			borderRadius: '100%',
			border: '2px solid #d8d8d9',
			cursor: 'pointer',

			'&:hover': {
				color: 'gray',
				borderColor: 'gray',
			},
		},
	},
    arrowVideoR: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
        top: 65,
        right: 5,

		'& div': {
			color: 'gray',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 30,
			width: 30,
			borderRadius: '100%',
			border: '3px solid gray',
			cursor: 'pointer',
			margin: '5px 0px',
			background: 'rgba(0,0,0,0.05)',
		},
	},
	arrowVideoL: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
        top: 65,
        left: 5,

		'& div': {
			color: 'gray',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 30,
			width: 30,
			borderRadius: '100%',
			border: '3px solid gray',
			cursor: 'pointer',
			margin: '5px 0px',
			background: 'rgba(0,0,0,0.05)',
		},
	},
}));
