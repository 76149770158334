import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { COLORS } from '../../utils';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
	container: {
		marginTop: 20,
		width: '100%',
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: COLORS.GREEN,
		padding: 20,
		// paddingTop: 50,
	},
	containerHeader: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
		paddingTop: 20,
		paddingBottom: 20,
		height: 'auto',
		backgroundColor: '#F2F2F2',
		paddingInline: 20,
	},
	labelHeaderTitle: {
		fontSize: 15,
		fontWeight: 500,
	},
	containerVideo: {
		height: 500,
	},
	containerVideos: {
		paddingInline: 10,
		maxHeight: 450,
		height: '100%',
		display: 'grid',
		gap: '10px',
		// overflow: 'auto',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			marginTop: 20,
		},
	},
	labelLegend: {
		fontWeight: 500,
		marginTop: 20,
		'& > div > p': {
			display: 'inline',
			width: 'auto',
			margin: 0,
		},
	},
	miniMediaContainer: {
		background: '#f6f7f7',
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'center',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'row',
		},
	},
	miniMediaContainer2: {
		background: '#f6f7f7',
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'center',
	},
	miniMediaContainerStriped: {
		background: '#fff !important',
	},
	miniMediaHeader: {
		display: 'flex',
		alignItems: 'center',
		margin: '10px 20px 10px 20px',
		height: 30,
		fontWeight: 600,
		paddingLeft: '30px',
		fontSize: '18px',
		width: '100%',

		'& > span': {
			display: 'inline',
			background: COLORS.GREEN,
			width: '3px',
			height: '16px',
			marginRight: '5px',
		},

		[theme.breakpoints.down('md')]: {
			paddingLeft: '20px',
		},
	},
	arrowVideoR: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
        top: 80,
        right: 2,

		'& div': {
			color: 'gray',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 30,
			width: 30,
			borderRadius: '100%',
			border: '3px solid gray',
			cursor: 'pointer',
			margin: '5px 0px',
			background: 'rgba(0,0,0,0.05)',
		},
	},
	arrowVideoL: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
        top: 80,
        left: 2,

		'& div': {
			color: 'gray',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 30,
			width: 30,
			borderRadius: '100%',
			border: '3px solid gray',
			cursor: 'pointer',
			margin: '5px 0px',
			background: 'rgba(0,0,0,0.05)',
		},
	},
}));
