/* eslint-disable react-hooks/exhaustive-deps */
import {
	Grid,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio,
	Box,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useStyles } from '../Register/styles';
import { isNumber, emailValid } from '../../utils/expressions';
import { useTheme } from '@mui/material/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BaseModal from '../../components/Modal';
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import { createAccountAPI } from '../../api/modules/createAccount';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import CustomAlert from '../../components/CustomAlert';
import { IAlert } from '../../interfaces/alert';
import TermsAndConditions from './termsAndConditions';
import PrivacyTerms from './privacy';
import ModalLoading from '../../components/ModalLoading';
import cel from '../../assets/img/cel.png';
import EmailConfirmation2 from '../Profile/Contact/ContactInformation/component/EmailConfirmation2';

import { contactRequestAPI } from '../../api/modules/profile';

interface IContactData {
	onChange: (name: string, value: string | boolean) => void;
	onContinue: () => void;
	onCloseError: () => void;
	hasError: IAlert;
}
interface ICredentials {
	phone: string;
	phone1: string;
	email: string;
	email1: string;
}

const ContactData = (props: IContactData) => {
	const [credentials, setCredentials] = useState<ICredentials>({
		phone: '',
		phone1: '',
		email: '',
		email1: '',
	});
	const [buttonValidate, setButtonValidate] = useState(true);
	const [emailValidate, setEmailValidate] = useState({
		valid: false,
		msg: '',
	});
	const [email1Validate, setEmail1Validate] = useState({
		valid: false,
		msg: '',
	});
	const [phoneValidate, setPhoneValidate] = useState({
		valid: false,
		msg: '',
	});
	const [phone1Validate, setPhone1Validate] = useState({
		valid: false,
		msg: '',
	});
	const [radioButton, setRadioButton] = useState(0);
	const [modalOpenPriv, setModalOpenPriv] = useState(false);
	const [modalOpenTerm, setModalOpenTerm] = useState(false);
	const [modalButtonPriv, setModalButtonPriv] = useState(true);
	const [modalButtonTerm, setModalButtonTerm] = useState(true);
	const { onContinue, onChange, hasError, onCloseError } = props;
	const theme = useTheme();
	const [loader, setLoader] = useState(false);
	const classes = useStyles();
	const [alert, setAlert] = useState<IAlert>({
		message: '',
		severity: 'success',
		show: false,
	});
	const [errorValidationPhone, setErrorValidationPhone] = useState(false);
	const [errorValidationEmail, setErrorValidationEmail] = useState(false);

	useEffect(() => {
		if (
			emailValidate.valid &&
			phoneValidate.valid &&
			radioButton > 0 &&
			credentials.phone === credentials.phone1 &&
			credentials.email === credentials.email1
		) {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	}, [radioButton, emailValidate, phoneValidate, credentials]);

	useEffect(() => {
		if (credentials.phone !== credentials.phone1) {
			if (!credentials.phone1) {
				setPhone1Validate({
					valid: false,
					msg: '',
				});
			} else {
				setPhone1Validate({
					valid: false,
					msg: INTERNAL_MESSAGES.CHECK_PHONE_NUMBER,
				});
			}
		} else {
			setPhone1Validate({ valid: true, msg: '' });
		}
	}, [credentials.phone1]);

	useEffect(() => {
		if (credentials.email !== credentials.email1) {
			if (!credentials.email1) {
				setEmail1Validate({
					valid: false,
					msg: '',
				});
			} else {
				setEmail1Validate({
					valid: false,
					msg: INTERNAL_MESSAGES.CHECK_EMAIL,
				});
			}
		} else {
			setEmail1Validate({ valid: true, msg: '' });
		}
	}, [credentials.email1]);

	const setData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		let validate = null;

		if (name === 'phone') {
			if (!isNumber(value)) return;
			if (value.length > 10) return;
			if (value.length < 10) {
				setPhoneValidate({
					valid: false,
					msg: INTERNAL_MESSAGES.LAST_TEN_DIGITS_CELL_PHONE,
				});
			} else {
				setPhoneValidate({
					valid: false,
					msg: '',
				});
			}
			if (!value) {
				setPhoneValidate({
					valid: false,
					msg: '',
				});
			}
			onChange(name, value);
		}
		if (name === 'phone1') {
			if (!isNumber(value)) return;
			if (value.length > 10) return;
		}
		if (name === 'email') {
			if (!value) {
				setEmailValidate({
					msg: '',
					valid: false,
				});
			}
			validate = emailValid(value);
			setEmailValidate(validate);
			onChange(name, value);
		}
		if (name === 'email1') {
			if (!value) {
				setEmail1Validate({
					msg: '',
					valid: false,
				});
			}
			validate = emailValid(value);
			setEmail1Validate(validate);
		}
		setCredentials({ ...credentials, [name]: value });
	};

	const handleOnChangeRadioButton = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { name } = event.target;
		setRadioButton(
			event.target.checked ? radioButton + 1 : radioButton - 1
		);
		onChange('byPoE', name === 'Por celular' ? 'byPhone' : 'byEmail');
	};

	const handleOnChangeModalButtonPriv = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setModalButtonPriv(!event.target.checked);
	};

	const handleOnChangeModalButtonTerm = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setModalButtonTerm(!event.target.checked);
	};

	const handleValidateEmail = async () => {
		if (!emailValidate.valid) return;
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_base64 = await encrypt(credentials.email || '', key);
			createAccountAPI
				.validateEmailAddress(email_base64)
				.then(() => {
					setEmailValidate({ msg: '', valid: true });
				})
				.catch((err) => {
					if (err.description) {
						setEmailValidate({
							msg: err.description,
							valid: false,
						});
					}
				});
		} catch (error) {}
	};

	const handleValidatePhone = async () => {
		if (!credentials.phone) {
			setPhoneValidate({
				msg: '',
				valid: false,
			});
			return;
		}
		if (credentials.phone.length < 10) {
			setPhoneValidate({
				msg: INTERNAL_MESSAGES.LAST_TEN_DIGITS_CELL_PHONE,
				valid: false,
			});
			return;
		}
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const phone_base64 = await encrypt(credentials.phone, key);
			createAccountAPI
				.validatePhone(phone_base64)
				.then((res) => {
					setPhoneValidate({ msg: '', valid: true });
				})
				.catch((err) => {
					if (err.description) {
						setPhoneValidate({
							msg: err.description,
							valid: false,
						});
					}
				});
		} catch (error) {}
	};

	const initialConfirmPhone = async () => {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const phone_base64 = await encrypt(credentials.phone || '', key);
			contactRequestAPI.validationPhone
				.post<{ celular: string }, any>('', {
					celular: phone_base64,
				})
				.then((res) => {
					if (res?.result?.codigo && res?.result?.codigo === '1') {
						setErrorValidationPhone(false);
						secondConfirmEmail();
					} else {
						if (errorValidationPhone) {
							setAlert({
								show: true,
								message:
									!res.result.codigo && res?.result?.message
										? res.result.message
										: INTERNAL_MESSAGES.CELL_PHONE_NOT_EXIST,
								severity: 'error',
							});
						}
						setErrorValidationPhone(true);
					}
				})
				.catch((e) => {
					setAlert({
						show: true,
						message: e.description
							? e.description
							: INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
					throw e;
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoader(false);
		}
	};

	const secondConfirmEmail = async () => {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_pass_base64 = await encrypt(
				credentials.email || '',
				key
			);
			contactRequestAPI.validationEmail
				.post<{ email: string }, any>('', {
					email: email_pass_base64,
				})
				.then((res) => {
					if (
						res?.result?.emailCodigoEstatus &&
						res?.result?.emailCodigoEstatus === '01'
					) {
						setErrorValidationEmail(false);
						setModalOpenPriv(true);
					} else {
						if (errorValidationEmail) {
							setAlert({
								show: true,
								message:
									!res.result.emailCodigoEstatus &&
									res?.result?.message
										? res.result.message
										: INTERNAL_MESSAGES.NOT_EXISTING_MAIL,
								severity: 'error',
							});
						}
						setErrorValidationEmail(true);
					}
				})
				.catch((e) => {
					setAlert({
						show: true,
						message: e.description
							? e.description
							: INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoader(false);
		}
	};

	return (
		<div style={{ width: '100%' }}>
			<ModalLoading loading={loader} />
			{!errorValidationPhone && !errorValidationEmail && (
				<Grid container className={classes.container} spacing={0}>
					<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div className={classes.containerInput}>
							<TextInput
								id="phone"
								name="phone"
								label="Número de celular"
								value={credentials.phone}
								error={!phoneValidate.valid}
								helperText={
									!phoneValidate.valid
										? phoneValidate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								onBlur={handleValidatePhone}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.containerInput}>
							<TextInput
								id="phone1"
								name="phone1"
								label="Confirma tu número de celular"
								value={credentials.phone1}
								error={!phone1Validate.valid}
								helperText={
									!phone1Validate.valid
										? phone1Validate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								disabled={!phoneValidate.valid}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.containerInput}>
							<TextInput
								id="email"
								name="email"
								label="Correo electrónico"
								value={credentials.email}
								error={!emailValidate.valid}
								helperText={
									!emailValidate.valid
										? emailValidate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								onBlur={handleValidateEmail}
								disabled={!phoneValidate.valid}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.containerInput}>
							<TextInput
								id="email1"
								name="email1"
								label="Confirma tu correo electrónico"
								value={credentials.email1}
								error={!email1Validate.valid}
								helperText={
									!email1Validate.valid
										? email1Validate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								disabled={!emailValidate.valid}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							<p>
								¿Por cuál medio te gustaría que el Infonavit te
								mantuviera informado?
							</p>
							<FormControlLabel
								sx={{
									marginLeft: '20px',
									[theme.breakpoints.down('md')]: {
										marginLeft: 0,
									},
								}}
								control={
									<Radio
										onChange={handleOnChangeRadioButton}
										name="Por celular"
									/>
								}
								label="Por celular"
								value="celular"
							/>
							<FormControlLabel
								sx={{
									marginLeft: '20px',
									[theme.breakpoints.down('md')]: {
										marginLeft: 0,
									},
								}}
								control={
									<Radio
										onChange={handleOnChangeRadioButton}
										name="Por correo electrónico"
									/>
								}
								label="Por correo electrónico"
								value="email"
							/>
						</RadioGroup>
						<div className={classes.buttonCenter}>
							<div className={classes.movilButton}>
								<div style={{ width: '100%' }}>
									<CustomAlert
										show={hasError.show}
										severity={hasError.severity}
										message={hasError.message}
										onClose={onCloseError}
									/>
								</div>
								<CustomButton
									label="Continuar"
									onClick={() => initialConfirmPhone()}
									variant="solid"
									disabled={buttonValidate}
								/>
							</div>
						</div>
						<br />
					</Grid>
					<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
				</Grid>
			)}
			{errorValidationPhone && (
				<div>
					<Box className={classes.VerificacionLogo}>
						<img alt="mobile" src={cel} />
					</Box>
					<Box
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
							display: 'flex',
							marginTop: '20px',
						}}
					>
						<span className={classes.VerificacionCodigoTitle}>
							<strong>Confirmación de número celular</strong>
						</span>
					</Box>
					<Box
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
							display: 'flex',
							marginBottom: '35px',
						}}
					>
						<span className={classes.VerificacionText}>
							<strong>
								El número de teléfono celular que nos
								proporcionaste no existe, por favor actualízalo{' '}
								<br />
								para continuar con tu registro.
							</strong>
						</span>
					</Box>
					<div className={classes.numbersVerification}>
						<div className={classes.containerInput}>
							<TextInput
								id="phone"
								name="phone"
								label="Número de celular"
								value={credentials.phone}
								error={!phoneValidate.valid}
								helperText={
									!phoneValidate.valid
										? phoneValidate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								onBlur={handleValidatePhone}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.containerInput}>
							<TextInput
								id="phone1"
								name="phone1"
								label="Confirma tu número de celular"
								value={credentials.phone1}
								error={!phone1Validate.valid}
								helperText={
									!phone1Validate.valid
										? phone1Validate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								disabled={!phoneValidate.valid}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.buttonCenter}>
							<div className={classes.movilButton}>
								<CustomButton
									label="Validar"
									onClick={() => initialConfirmPhone()}
									variant="solid"
									disabled={
										!phoneValidate.valid ||
										credentials.phone !== credentials.phone1
									}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
			{errorValidationEmail && (
				<>
					<div style={{ marginTop: '-20px' }}></div>
					<EmailConfirmation2 />
					<div className={classes.numbersVerification}>
						<div className={classes.containerInput}>
							<TextInput
								id="email"
								name="email"
								label="Correo electrónico"
								value={credentials.email}
								error={!emailValidate.valid}
								helperText={
									!emailValidate.valid
										? emailValidate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								onBlur={handleValidateEmail}
								disabled={!phoneValidate.valid}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.containerInput}>
							<TextInput
								id="email1"
								name="email1"
								label="Confirma tu correo electrónico"
								value={credentials.email1}
								error={!email1Validate.valid}
								helperText={
									!email1Validate.valid
										? email1Validate.msg
										: ''
								}
								size="small"
								isRequired={true}
								onChange={setData}
								disabled={!emailValidate.valid}
								noCopy={true}
								noPaste={true}
							/>
						</div>
						<div className={classes.buttonCenter}>
							<div className={classes.movilButton}>
								<CustomButton
									label="Validar"
									onClick={() => secondConfirmEmail()}
									variant="solid"
									disabled={
										!emailValidate.valid ||
										credentials.email !== credentials.email1
									}
								/>
							</div>
						</div>
					</div>
				</>
			)}
			<div className={classes.containerAlert}>
				<CustomAlert
					show={Boolean(alert.message)}
					severity={alert.severity}
					message={alert.message}
				/>
			</div>
			<BaseModal
				open={modalOpenPriv}
				title="Aviso de privacidad"
				width="md"
				onClose={() => {
					setModalButtonPriv(true);
					setModalOpenPriv(false);
				}}
				children={
					<>
						<PrivacyTerms />
						<div className={classes.dialogCheck}>
							<FormControlLabel
								control={
									<Checkbox
										className={classes.formControlLabel}
										icon={<CheckBoxOutlineBlankIcon />}
										checkedIcon={<CheckBoxIcon />}
										onChange={handleOnChangeModalButtonPriv}
										name="checkModal"
									/>
								}
								style={{
									color: !modalButtonPriv
										? theme.palette.primary.main
										: 'black',
								}}
								label="Acepto el Aviso de privacidad"
								value="checkAviso"
							/>
						</div>
						<div className={classes.dialogButtonGroup}>
							<div className={classes.dialogButtonRight}>
								<CustomButton
									label="Cancelar"
									onClick={() => {
										setModalButtonPriv(true);
										setModalOpenPriv(false);
									}}
									variant="outlined"
									styles={{ marginTop: 10 }}
								/>
							</div>
							<div className={classes.dialogButtonRight}>
								<CustomButton
									label="Continuar"
									onClick={() => {
										setModalButtonPriv(true);
										setModalOpenPriv(false);
										setModalOpenTerm(true);
									}}
									variant="solid"
									disabled={modalButtonPriv}
									styles={{ marginTop: 10 }}
								/>
							</div>
						</div>
					</>
				}
			/>
			<BaseModal
				open={modalOpenTerm}
				title="Términos y condiciones"
				width="md"
				onClose={() => {
					setModalOpenTerm(false);
					setModalButtonTerm(true);
				}}
				children={
					<>
						<TermsAndConditions />
						<div className={classes.dialogCheck}>
							<FormControlLabel
								control={
									<Checkbox
										className={classes.formControlLabel}
										icon={<CheckBoxOutlineBlankIcon />}
										checkedIcon={<CheckBoxIcon />}
										onChange={handleOnChangeModalButtonTerm}
										name="checkModal"
									/>
								}
								style={{
									color: !modalButtonTerm
										? theme.palette.primary.main
										: 'black',
								}}
								label="Acepto términos y condiciones"
								value="checkAviso"
							/>
						</div>
						<div className={classes.dialogButtonGroup}>
							<div className={classes.dialogButtonRight}>
								<CustomButton
									label="Cancelar"
									onClick={() => {
										setModalButtonTerm(true);
										setModalOpenTerm(false);
									}}
									variant="outlined"
									styles={{ marginTop: 10 }}
								/>
							</div>
							<div className={classes.dialogButtonRight}>
								<CustomButton
									label="Continuar"
									onClick={() => {
										setModalOpenTerm(false);
										setModalButtonTerm(true);
										onContinue();
									}}
									variant="solid"
									disabled={modalButtonTerm}
									styles={{ marginTop: 10 }}
								/>
							</div>
						</div>
					</>
				}
			/>
		</div>
	);
};

export default ContactData;
