export const PROFILES = {
	PERFIL_CERO: '0',

	AHORRADOR_NUEVO_AFILIADO: '1.1',
	AHORRADOR_SIN_PUNTUACION: '1.2',
	AHORRADOR_PERDIO_RELACION_LABORAL: '1.3',
	AHORRADOR_CREDITO_CERRADO: '1.4',

	PROSPECTO_PUNTUACION_REQUERIDA: '2.1',
	PROSPECTO_TRAMITE_CREDITO: '2.2',
	PROSPECTO_CREDITO_CERRADO: '2.3',

	ACREDITADO_CUMPLIDO: '3.1',
	ACREDITADO_PROBLEMAS_PAGO: '3.2',
	ACREDITADO_PROCESO_JUDICIAL: '3.3',
	ACREDITADO_DEMANDADO: '3.4',
	ACREDITADO_HISTORICO: '3.5',

	PENSIONADO_SIN_CREDITO: '4.1',
	PENSIONADO_CREDITO_CUMPLIDO: '4.2',
	PENSIONADO_PROBLEMAS_PAGO: '4.3',
	PENSIONADO_PROCESO_JUDICIAL: '4.4',
	PENSIONADO_CREDITO_CERRADO: '4.5',
	PENSIONADO_HISTORICO: '4.6',
	PENSIONADO_RELACION_LABORAL: '4.7',

	BENEFICIARIO_SIN_CREDITO: '5.1',
	BENEFICIARIO_CREDITO_VIGENTE: '5.2',
	BENEFICIARIO_CREDITO_CERRADO: '5.3',

	ACLARACIONES_UNIFICACION_CUENTAS: '6.1',
	ACLARACIONES_SEPARACION_CUENTAS: '6.2',
	ACLARACIONES_VINCULACION_NSS: '6.3',
	ACLARACIONES_NO_RFC: '6.4',
	ACLARACIONES_NO_CURP: '6.5',
}

export const MESSAGES = {
	BENEFICIARIO_SIN_CREDITO: 'Identificamos que el titular de este Número de Seguridad Social (NSS) está fallecido, para mayor información consulta <a href="http://www.infonavit.org.mx" target="_blank">www.infonavit.org.mx</a>, en la sección de, "Mi ahorro / Retiro de mi ahorro / Devolución a beneficiarios"',
	BENEFICIARIO_CREDITO_VIGENTE: 'Identificamos que el titular de este Número de Seguridad Social (NSS) está fallecido y tiene un crédito vigente,  para mayor información consulta <a href="http://www.infonavit.org.mx" target="_blank">www.infonavit.org.mx</a>, en la sección de, "Tengo un crédito / Soluciones para ti / Seguros para tu crédito".',
	BENEFICIARIO_CREDITO_CERRADO: 'Identificamos que el titular de este Número de Seguridad Social (NSS) está fallecido, para mayor información consulta <a href="http://www.infonavit.org.mx" target="_blank">www.infonavit.org.mx</a>, en la sección de, "Mi ahorro / Retiro de mi ahorro / Devolución a beneficiarios", así como la sección de, "Tengo un crédito / Devolución de pago a beneficiarios"',

	ACLARACIONES_UNIFICACION_CUENTAS: 'El Número de Seguridad Social (NSS) que proporcionaste se encuentra en proceso de unificación de cuentas, por lo que deberás esperar a que el proceso concluya para poder continuar con tu trámite.',
	ACLARACIONES_SEPARACION_CUENTAS: 'El Número de Seguridad Social (NSS) que proporcionaste se encuentra en proceso de separación de cuentas, por lo que deberás esperar a que el proceso concluya para poder continuar con tu trámite.',
	ACLARACIONES_VINCULACION_NSS: 'El número de Seguridad Social (NSS) que proporcionaste, no tiene asignado un número de crédito, para más información llama a Infonatel o acude al centro de servicio más cercano.',
	ACLARACIONES_NO_RFC: '',
	ACLARACIONES_NO_CURP: '',

	PENSIONADO: 'Consulta información relevante sobre el Fondo de </br>Pensiones para el Bienestar <a href="https://infonavitfacil.mx/pensiones" target="_blank">aquí.</a>',

	USERS_BLOCKED: 'De acuerdo con tu solicitud, toda información que requieras consultar deberás hacerla a través de los Centros de Servicio Infonavit o comunicándote a Infonatel, de esta manera mantenemos resguardados tus datos.'
}

export const USERS_BLOCKED = [
	'18159502170',
]