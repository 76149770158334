import { useState } from 'react';
import { CheckCircleOutlined } from '@mui/icons-material';
import { Grid, Paper, useMediaQuery } from '@mui/material';
import CustomButton from '../../components/Button';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import { apiDeedsDelivery } from '../../api/modules/Deeds';
import { useSelector } from 'react-redux';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { RootState } from '../../store';
import ModalLoading from '../../components/ModalLoading';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import CustomAlert from '../../components/CustomAlert';
import { IAlert } from '../../interfaces/alert';
import { useNavigate } from 'react-router-dom';
import BaseModal from '../../components/ModalConfirmation';

interface Props {
	setPage: (data: number) => void;
	setCaso: (arg: object) => void;
}

interface IDataMensaje {
	entrega_escritura_habilitado: string;
	mensajes: any;
	notario_informacion: any;
}

const ImportantAspects = ({ setPage, setCaso }: Props) => {
	const [modal, setModal] = useState(false);
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [loading, setLoading] = useState(false);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const navigate = useNavigate();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const [dataMensaje, setDataMensaje] = useState<IDataMensaje>({
		entrega_escritura_habilitado: '',
		mensajes: '',
		notario_informacion: '',
	});

	const validaCaso = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss?.toString() || '', key);
			const credito_base64 = await encrypt(credit.toString() || '', key);
			const { result } = await apiDeedsDelivery.getConsultaCaso(
				nss_base64
			);
			if (result?.codigo_respuesta && result?.codigo_respuesta !== '0001') {
				setAlert({
					show: true,
					message: result?.descripcion || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			} else {
				if (result?.existe_caso_abierto === true) {
					if (result?.caso) {
						setCaso(result.caso);
						setPage(2);
					}
				} else {
					if (
						result?.existe_caso_abierto === false ||
						result?.codigo_respuesta === '0001'
					) {
						const valida =
							await apiDeedsDelivery.getEscrituraValida(
								credito_base64
							);
						if (valida?.result?.codigo_respuesta) {
							setAlert({
								show: true,
								message: valida?.result?.descripcion || INTERNAL_MESSAGES.ERROR_MESSAGE,
								severity: 'error',
							});
						} else {
							if (
								valida?.result?.entrega_escritura_habilitado ===
								true
							) {
								setCaso({});
								setPage(1);
							} else {
								setModal(true);
								setDataMensaje(valida.result);
							}
						}
					} else {
						setAlert({
							show: true,
							message:
								INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				}
			}
		} catch (error) {
			setAlert({
				show: true,
				message:
					INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Paper>
			<ModalLoading loading={loading} />
			<h3
				className={classes.titleh3}
				style={{
					paddingLeft: matches ? 15 : 45,
					paddingTop: matches ? 20 : 40,
					marginBottom: matches ? 0 : 16,
				}}
			>
				Aspectos Importantes
			</h3>
			<Grid container columns={12}>
				<Grid item xs={0} sm={0} md={1} lg={1}></Grid>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						padding: 15,
						alignContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<Grid item xs={12} sm={10} md={10} lg={10}>
						<label className={classes.label}>
							<p style={{ margin: '8px 0px' }}>
								En este servicio puedes solicitar la escritura
								de tu casa.
							</p>
							<p style={{ margin: '8px 0px' }}>
								Proporciona tus datos, genera tu caso y dale
								seguimiento por este mismo medio.
							</p>
							En caso de que proceda tu trámite de Entrega de
							escritura, es importante que tengas en cuenta que
							para recibirla deberás presentar la siguiente
							documentación en original y copia, según sea el
							caso:
						</label>
						<br />
						<br />

						<div className={classes.divText}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 22 }}
							/>

							<label className={classes.label}>
								Si eres el titular, una identificación oficial
								vigente con fotografía, como tu credencial para
								votar o cédula profesional vigente.
							</label>
						</div>
						<div className={classes.divText}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 22 }}
							/>
							<label className={classes.label}>
								Si no puedes recoger tu escritura, la persona
								que te represente, debe contar con poder
								notarial otorgado a su favor, así como
								credencial para votar vigente de ambos.
							</label>
						</div>
						<div className={classes.divText}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 22 }}
							/>
							<label className={classes.label}>
								Si el titular es declarado en estado de
								interdicción, el tutor deberá presentar, la
								sentencia donde se le otorgue ese carácter, el
								acuerdo que decreta que ha causado ejecutoria,
								así como credencial para votar vigente de ambos.
							</label>
						</div>
						<div className={classes.divText}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 22 }}
							/>
							<label className={classes.label}>
								Si el titular falleció, en caso de sucesión
								testamentaria o intestamentaria, la persona que
								solicite la escritura debe presentar el
								documento judicial o notarial en el que conste
								su designación, aceptación, protesta y
								discernimiento del cargo de albacea, así como su
								credencial para votar vigente. O bien, si eres
								beneficiario debes presentar copia certificada
								del laudo, donde se te reconozca como
								beneficiario, así como tu credencial para votar
								vigente.
							</label>
						</div>
						<div className={classes.divText}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 22 }}
							/>
							<label className={classes.label}>
								Si tienes un crédito conyugal, una
								identificación oficial vigente con fotografía de
								ambos cónyuges, como la credencial para votar o
								cédula profesional. La escritura la podrá
								recoger el titular o cotitular del crédito.
							</label>
						</div>
						<div className={classes.divText}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 22 }}
							/>
							<label className={classes.label}>
								Si tienes un crédito Infonavit-Fovissste, la
								escritura la entrega el notario.
							</label>
						</div>

						<CustomAlert
							show={alert.show}
							message={alert.message}
							severity="warning"
							onClose={() =>
								setAlert({
									show: false,
									message: '',
									severity: 'warning',
								})
							}
						/>

						<div className={classes.buttonContainer}>
							<div
								style={{
									width: matches ? '100%' : 200,
									height: 40,
								}}
							>
								<CustomButton
									label="Regresar"
									onClick={() => navigate('/')}
									variant="outlined"
								/>
							</div>
							<div
								style={{
									width: matches ? '100%' : 200,
									height: 40,
									marginLeft: matches ? 0 : 60,
									marginBottom: matches ? 15 : 0,
								}}
							>
								<CustomButton
									label="Continuar"
									onClick={() => validaCaso()}
									variant="solid"
								/>
							</div>
						</div>
					</Grid>
				</div>
				<Grid item xs={0} sm={0} md={1} lg={1}></Grid>
			</Grid>
			<BaseModal
				open={modal}
				title="S"
				onConfirm={() => setModal(false)}
				onClose={() => setModal(false)}
				cancelButton={true}
				confirmLabel="Aceptar"
				cancelLabel="Cerrar"
				width="sm"
				children={
					<div
						style={{
							paddingLeft: 40,
							paddingRight: 40,
							paddingTop: 30,
							paddingBottom: 10,
						}}
					>
						<h3
							style={{
								color: '#293990',
								fontSize: 16,
								display: 'flex',
								textAlign: 'center',
								justifyContent: 'center',
								margin: 0,
							}}
						>
							No es posible la entrega de tus escrituras debido a
							las siguientes condiciones de tu crédito:
						</h3>
						<br></br>
						<p
							style={{
								color: 'black',
								fontSize: 14,
								display: 'flex',
								textAlign: 'center',
								justifyContent: 'center',
								margin: 0,
							}}
						>
							{dataMensaje.mensajes
								? dataMensaje?.mensajes[0][0]
								: ''}
							{'. '}
							{dataMensaje.mensajes
								? dataMensaje?.mensajes[0][1]
								: ''}
						</p>
						<p
							style={{
								color: 'black',
								fontSize: 14,
								fontWeight: 'bold',
								display: 'flex',
								textAlign: 'center',
								justifyContent: 'center',
								margin: 0,
							}}
						>
							{Object.keys(dataMensaje.notario_informacion)
								.length === 0 ? (
								''
							) : (
								<>
									Acude a la notaría No.{' '}
									{
										dataMensaje.notario_informacion
											.notario_numero
									}{' '}
									con el notario{' '}
									{
										dataMensaje.notario_informacion
											.notario_nombre
									}
									; a la dirección Calle{' '}
									{dataMensaje.notario_informacion.calle}{' '}
									colonia{' '}
									{dataMensaje.notario_informacion.colonia},
									CP {dataMensaje.notario_informacion.cp} o
									comunícate al{' '}
									{dataMensaje.notario_informacion.telefono1}
								</>
							)}
						</p>
					</div>
				}
			/>
		</Paper>
	);
};

export default ImportantAspects;
