import { Paper, Box } from '@mui/material';
import Layout from '../../../../../components/Layout';
import { HeaderList } from '../../../../../components/HeaderList';
import { IProfileResponse } from '../../../../../api/modules/MIUC';
import { useStyles } from './styles/styles';
import { CREDIT_CASE_OWNER_DATA } from '../../../../../config/miucConstants';

interface IHaveCreditApp {
	data: IProfileResponse;
}
const HaveCreditApp = (props: IHaveCreditApp) => {
	const { data } = props;
	const classes = useStyles();

	let title = 'Tienes una solicitud de ';
	let message = '';

	if (
		data?.conyuge?.tipoAsociacion ===
		CREDIT_CASE_OWNER_DATA.CONYUGE_CONSULTADO_POR_TITULAR
	) {
		title += 'crédito conyugal';
		message = 'Tu cónyuge,';
	}

	if (
		data?.conyuge?.tipoAsociacion ===
		CREDIT_CASE_OWNER_DATA.TITULAR_FAMILIAR
	) {
		title += 'crédito familiar';
		message = 'Tu familiar,';
	}

	if (
		data?.conyuge?.tipoAsociacion ===
		CREDIT_CASE_OWNER_DATA.TITULAR_CORRESIDENCCIAL
	) {
		title += 'crédito corresidencial';
		message = 'Tu corresidente,';
	}

	if (
		data?.conyuge?.tipoAsociacion === CREDIT_CASE_OWNER_DATA.INDIVIDUAL_SOC
	) {
		title += 'crédito individual';
		message = 'Tu';
	}

	return (
		<Layout>
			<div>
				<HeaderList
					title="Me interesa un crédito"
					list={['Registro de solicitud de crédito en línea']}
				/>
				<Paper>
					<Box padding={3} maxWidth={900} margin={'auto'}>
						<h3 className={classes.textCenter}>{title}</h3>

						<p className={classes.margin20p0p}>
							{message} como titular, inicio una solicitud de
							crédito, por lo cual es la única persona que puede
							darle continuidad o cancelarla. Cuando{' '}
							<strong className={classes.colorBlue}>
								la solicitud esté autorizada
							</strong>
							, tambien tu{' '}
							<strong className={classes.colorBlue}>
								podrás dar seguimiento al trámite
							</strong>{' '}
							desde este servicio.
						</p>
					</Box>
				</Paper>
			</div>
		</Layout>
	);
};
export default HaveCreditApp;
