import React from 'react';

// Styles
import { useStyles } from './styles';

interface IButton {
	label: string | React.ReactElement;
	onClick?: () => void;
	variant?: 'solid' | 'outlined';
	styles?: React.CSSProperties;
	disabled?: boolean;
}

const CustomButton = (props: IButton) => {
	const { onClick, label, variant = 'solid', styles, disabled } = props;
	const classes = useStyles();
	return (
		<button
			onClick={onClick}
			style={{ ...styles, backgroundColor: disabled ? '#CDCDCD' : '' }}
			disabled={disabled}
			className={
				variant === 'solid'
					? classes.buttonLogInSolid
					: classes.buttonLogInOutlined
			}
		>
			{label}
		</button>
	);
};

export default CustomButton;