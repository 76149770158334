/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Link, Badge } from '@mui/material';
import { useStyles } from './styles';
import TooltipCustom from '../../../components/TooltipCustom';
import HelpPanel from '../../HelpPanel';
import { Info } from '@mui/icons-material';
import expediente from '../../../assets/svg/expediente.svg';
import { ConstantSteps } from '../constantSteps/constantSteps';
import formalizaCreditoIcon from '../../../assets/svg/formalizaCredito.svg';
import iconoPDF from '../../../../../assets/img/icono-descarga.png';
import ModalExtiSumary from '../../../views/MejoraSi/components/ModalExtiSumary';

export const MejoraDirectaSteps = () => {
	const classes = useStyles();
	const { verificaTusDatos, solicitaTuCreditoMejoraSi } = ConstantSteps();
	const [openModal, setOpenModal] = useState(false);

	return [
		verificaTusDatos,
		{
			paperType: 'viewMore',
			paperIcon: expediente,
			title: 'Integra tu expediente',
			description: 'Reúne tus documentos para tramitar tu crédito',
			viewMoreButton: 'Consulta la documentación',
			viewMoreContent: (
				<>
					<ModalExtiSumary open={openModal} setOpen={setOpenModal} />
					<div className={classes.viewMoreContent}>
						<span className={classes.viewMoreTitle}>
							Reúne estos documentos.
						</span>
						<ul className={classes.viewMoreList}>
							<li>
								Solicitud de inscripción de crédito.{' '}
								<Link
									underline="hover"
									href={require('../../../../../assets/files/MejoraDirectaFiles2023/Solicitud_Inscripcion_Credito_MejOraSi.pdf')}
									target="_blank"
								>
									Descárgala aquí
								</Link>
							</li>
							<li>
								Acta de nacimiento en original y copia.{' '}
								<Badge
									badgeContent={
										<HelpPanel type="ACTA_NACIMIENTO" />
									}
									className={classes.helpPanel}
								></Badge>
							</li>
							<li>
								Identificación original vigente en original y
								copia.{' '}
								<TooltipCustom
									arrow
									placement="right"
									title={
										<>
											<b>
												La identificación oficial puede
												ser:
											</b>
											<ul>
												<li>
													Credencial para votar
													vigente
												</li>
												<li>Pasaporte</li>
											</ul>
										</>
									}
								>
									<Info
										color="primary"
										className={classes.infoStyle}
									/>
								</TooltipCustom>
							</li>
							<li>Copia del CURP.</li>
							<li>
								Carta bajo protesta de decir verdad en la que
								manifiestes que te comprometes a destinar el
								importe para pago de mano de obra, y a realizar
								la mejora a tu vivienda.{' '}
								<Link
									underline="hover"
									href={require('../../../../../assets/files/MejoraDirectaFiles2023/Carta_bajo_protesta_MejOraSi.pdf')}
									target="_blank"
								>
									Descárgala aquí
								</Link>
							</li>
							<li>
								Comprobante de domicilio de la vivienda a
								reparar o mejorar en original y copia.
							</li>
							<li>
								Comprobante para acreditar la legítima tenencia
								de la vivienda.
							</li>
							<li>Constancia de elegibilidad.</li>
							<li>
								<Link
									underline="hover"
									href={require('../../../../../assets/files/MejoraDirectaFiles2023/Documentos_posesion.pdf')}
									target="_blank"
								>
									Documentos para acreditar la legítima
									tenencia de la vivienda{' '}
									<img
										src={iconoPDF}
										alt="iconoPDF"
										className={classes.icoDescarga}
									/>
								</Link>
							</li>
						</ul>
					</div>
				</>
			),
		},
		solicitaTuCreditoMejoraSi(() => setOpenModal(true)),
		{
			paperType: 'simple',
			paperIcon: formalizaCreditoIcon,
			title: 'Formaliza tu crédito',
			description:
				'Una vez inscrito tu trámite te indicaremos el momento de realizar tu cita para acudir a firmar tu contrato y recibir tu tarjeta con la que quedará formalizado tu crédito.',
		},
	];
};
