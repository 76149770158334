import API from '..';

class EcotechConstancy extends API {
	getValid(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/valid-eco`,
				method: 'POST',
				data: { nss },
			})
		);
	}

	getValidDocument(credito: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/valid-documents`,
				method: 'POST',
				data: { credito },
			})
		);
	}
}

export const apiEcotechConstancy = new EcotechConstancy('');
