/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
//Assets
import imgChecaContacto from '../../../assets/img/ChecaDatosConto.png';
import imgAgendaCita from '../../../assets/img/Agenda-tu-cita.png';
import imgNoSatisfecho from '../../../assets/img/no-satisfecho.png';

const AccreditedDemand = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={12} lg={4}>
				<Paper className={classes.card}>
					<div className={classes.titleCard}>
						<h3>
							¿Tienes problemas con el pago de tu crédito?
						</h3>
					</div>
					<div className={classes.textCard}>
						<p>
							Si tuviste problemas de pago, agenda tu cita
							para recibir una asesoría en tu Centro de
							Servicio Infonavit (Cesi) más cercano.
						</p>
					</div>
				</Paper>
			</Grid>

			<Grid item lg={8}>
				<Grid container spacing={2}>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/contactanos/haz-una-cita')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgAgendaCita}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate(
									'/mi-perfil/actualizar-datos-contacto'
								)
							}
						>
							<img
								src={imgChecaContacto}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>
				<Grid container spacing={2} mt={1}>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/contactanos/presenta-sigue-queja')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgNoSatisfecho}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default AccreditedDemand;
