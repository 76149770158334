import { Box, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Radio, RadioGroup, Typography } from "@mui/material"
import RadioButtonInput from "../../../../components/RadioButtonInput"
import theme from "../../../../config/theme";
import { useState } from "react";
import {CheckCircleOutlineRounded} from '@mui/icons-material';
import { useStyles } from "./styles";
import { RoundBtn } from "../RoundBtn/roundBtn";
import CustomAlert from "../../../../components/CustomAlert";
import { HeaderList } from "../../../../components/HeaderList";
import { useNavigate } from "react-router-dom";

interface IProps {
	setPage: (arg: number) => void;
}
export const EvaluationUnit = ({ setPage }: IProps) => {

  const navigate = useNavigate();
  const classes = useStyles();

  const options = {
    PARTICULAR: 'particular',
    DESARROLLADOR: 'desarrollador'
  }

  const [selectedValue, setSelectedValue] = useState(options.PARTICULAR);

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  }

  const documentList = [
    'Escritura antecedente de propiedad o régimen de propiedad en condominio.',
    'Boleta predial (última).',
    'Boleta de agua (última).',
    'Plano o croquis del inmueble (si no cuentas con él, pueden hacértelo con un costo adicional).',
    'Recibos de servicios para comprobar que es vivienda usada o terminación de obra (en caso de que la escritura no lo indique).',
    'Copia de la Identificación oficial del vendedor y del comprador.',
    'Adicionalmente te pedirán llenar una Solicitud de avalúo.',
  ]

  const alertMessage = 'La constructora realizará el trámite, te notificaremos cuando ya sea registrada.'

  return (
    <Box className={classes.mainContainer}>
      <Box>
        <h3 className={classes.titleBlue}>Elige tu unidad de valuación</h3>
        <p>Si compras tu vivienda con un desarrollador, este se encargará de solicitarlo. Si la compras con un particular, pídelo en las unidades de evaluación autorizadas</p>
      </Box>
      <Paper>
      <Box className={classes.container}>
        <h3 style={{ marginTop: 0 }}>Seleccione una de las opciones</h3>
        <Box className={classes.options}>
          <FormControlLabel
            value={options.PARTICULAR}
            checked={ selectedValue === options.PARTICULAR }
            control={<Radio />}
            label={
              <Box>
                <h3 className={classes.noMargin}>Compras tu vivienda con un particular:</h3>
                <p className={classes.noMargin}>Elige una unidad de evaluación.</p>
              </Box>
            }
            onChange={ (event) => handleChange(event) }
          />
          <FormControlLabel 
            value={options.DESARROLLADOR}
            checked={ selectedValue === options.DESARROLLADOR }
            control={<Radio />}
            label={
              <Box>
                <h3 className={classes.noMargin}>Compras tu vivienda con un desarrollador</h3>
                <p className={classes.noMargin}>Él eligirá la unidad de evaluación.</p>
              </Box>
            }
            onChange={ (event) => handleChange(event) }
            />
        </Box>
        {
          selectedValue === options.PARTICULAR
          ? (
            <>
                <h3 className={classes.title}>Documentos para solicitar un avalúo</h3>
                <h3>La unidad de valuación con la que realizarás tu avalúo, te solicitará los siguientes documentos:</h3>
                <Divider />
                <p>Una vez que selecciones la unidad de valuación, prepara la siguiente documentación.</p>
                <HeaderList 
                  list={documentList}
                />
            </>
          )
          : (
            <CustomAlert
              show
              severity='warning'
              message={alertMessage}
            ></CustomAlert>
          )
        }
        <div className={classes.buttons}>
          <Divider />
          <RoundBtn variant="outlined" onClick={ () => navigate(-1) }>
            Regresar
          </RoundBtn>
          {
            selectedValue === options.PARTICULAR 
            ? (
              <RoundBtn
                variant="contained"
                onClick={ () => setPage(1) }
              >
                Continuar
              </RoundBtn>
            )
            : (
              <RoundBtn
                variant="contained"
                onClick={ () => navigate(-1) }
              >
                Finalizar
              </RoundBtn>
            )
          }
        </div>
      </Box>
    </Paper>
    </Box>
  )
}