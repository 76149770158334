import React from 'react';
import { Divider } from '@mui/material';
import { useStyles } from './styles';

const DatosObligatorios = () => {
	const styles = useStyles();

	return (
		<>
			<div style={{ padding: '0px 80px 20px 80px' }}>
				<Divider />
				<span className={styles.txtBlue}>
					<b>* Datos obligatorios</b>
				</span>
			</div>
		</>
	);
};

export default DatosObligatorios;
