import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useStyles } from './styles';

type Props = {
	items: string[];
};

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
	event.preventDefault();
}

const Breadcrumb = ({ items }: Props) => {
	const classes = useStyles();
	return (
		<Box className={classes.container} role="presentation" onClick={handleClick}>
			<Breadcrumbs separator={items.length > 1 ? '›' : ''} aria-label="breadcrumb">
				<Link sx={{ fontSize: 14 }} underline="none" color="inherit">
					{items[0] !== '404' && items[0]}
				</Link>
				<Typography sx={{ fontSize: 14, fontWeight: 900 }} color="text.primary">
					{items[1]}
				</Typography>
				{/* {items.slice(1).map((item, index) => (
					<Typography
						key={index}
						sx={{ fontSize: 14, fontWeight: 900 }}
						color="text.primary"
					>
						{item}
					</Typography>
				))} */}
			</Breadcrumbs>
		</Box>
	);
};

export default Breadcrumb;
