/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

// Material Components
import { Box, Checkbox, FormControlLabel, Paper, useTheme,useMediaQuery } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Components
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import TextInput from '../../components/TextInput';
import RowComponent from '../../components/Rows';
import BaseModal from '../../components/Modal';
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';

// Assets
import { useStyles } from './style';

// Resources
import { curpValid } from '../../utils/expressions';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { encrypt } from '../../utils/encrypt';

// Api-Modules
import { keyAPI } from '../../api/modules/key';
import { correctionCurpAPI } from '../../api/modules/CorrectionCURP';

// Interfaces
import { IAlert } from '../../interfaces/alert';
import { useBitacora } from '../../hooks/useBitacora';
import { CURP_CORRECTION_ACTIONS } from '../../config/actions-bitacora/CURPCorrection-actions';

const CURPCorrection = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const theme = useTheme();
	const { regBitacora, setDataOld } = useBitacora();

	const [CURPstring, setCURPstring] = useState('');
	const [NAMEstring, setNAMEstring] = useState('');
	const [NSSstring, setNSSstring] = useState('');
	const [RFCstring, setRFCstring] = useState('');
	const [EMAILstring, setEMAILstring] = useState('');

	const [NumCasostring, setNumCasostring] = useState('');
	const [Serviciostring, setServiciostring] = useState('');
	const [FechaSolicitudstring, setFechaSolicitudstring] = useState('');
	const setEstatusstring = useState('')[1];
	const [FechaEstatusstring, setFechaEstatusstring] = useState('');
	const [Descriptionstring, setDescriptionstring] = useState('');

	const [FechaNacimientostring, setFechaNacimientostring] = useState('');
	const [Nombrestring, setNombrestring] = useState('');
	const [Paternostring, setPaternostring] = useState('');
	const [Maternostring, setMaternostring] = useState('');
	const [Generostring, setGenerostring] = useState('');
	const [NuevaCURPstring, setNuevaCURPstring] = useState('');

	const [Page, setPage] = useState(0);
	const [displayFooter, setdisplayFooter] = useState(true);
	const [displayFooterAceptarCambio, setdisplayFooterAceptarCambio] = useState(false);
	const [displayFooterFinalizar, setdisplayFooterFinalizar] = useState(false);
	const [CURP, setCURP] = useState({ curp: '', confirmCurp: '' });
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [errorCURP, setErrorCURP] = useState({ valid: false, msg: '' });
	const [errorConfirmCURP, setConfirmErrorCURP] = useState({
		valid: false,
		msg: '',
	});
	const [buttonAceptarCambios, setButtonAceptarCambios] = useState(false);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [modalConfirm, setModalConfirm] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const describeText =
		Page < 2
			? [
				'En este servicio puedes modificar tu Clave Única de Registro de Población (CURP) que tenemos registrado en nuestro sistema.',
			]
			: [];

	const onNextPage = () => {
		let newPage = Page + 1;

		setPage(newPage);
		onDisplayFooter(newPage);
	};
	const onPrevPage = () => {
		let newPage = Page - 1;
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		setPage(newPage);
		onDisplayFooter(newPage);
	};

	const onDisplayFooter = (newPage: number) => {
		setdisplayFooter(false);
		setdisplayFooterAceptarCambio(false);
		setdisplayFooterFinalizar(false);
		if (newPage === 0 || newPage === 1) {
			setdisplayFooter(true);
		}
		if (newPage === 2) {
			setdisplayFooterAceptarCambio(true);
		}
		if (newPage === 3) {
			setdisplayFooterFinalizar(true);
		}
	};

	const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		let validate = null;
		let values = CURP;

		setAlert({
			show: false,
			message: '',
			severity: alert.severity,
		});

		if (name === 'CURP') {
			if (value.length > 18) return;
			validate = curpValid(value);
			setErrorCURP(validate);
			values.curp = value;
		}
		if (name === 'confirmCURP') {
			if (value.length > 18) return;
			validate = curpValid(value);
			setConfirmErrorCURP(validate);
			values.confirmCurp = value;
		}
		setCURP(values);
	};

	const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		setButtonAceptarCambios(event.target.checked);
	};

	const onSetUser = () => {
		let dataUser: any = user;
		setCURPstring(dataUser.curp);
		setNAMEstring(dataUser.given_name);
		setNSSstring(dataUser.nss);
		setRFCstring(dataUser.rfc);
		setEMAILstring(dataUser.email);

		setDataOld(CURP_CORRECTION_ACTIONS.VALID_CHANGE_CURP, { curp: dataUser.curp });
	};

	useEffect(() => {
		// Inicializar Valores

		setCURPstring('');
		setNAMEstring('');
		setNSSstring('');

		setNumCasostring('');
		setServiciostring('');
		setFechaSolicitudstring('');
		setEstatusstring('');
		setFechaEstatusstring('');
		setDescriptionstring('');

		setFechaNacimientostring('');
		setNombrestring('');
		setPaternostring('');
		setMaternostring('');
		setGenerostring('');
		setNuevaCURPstring('');

		setPage(0);
		setdisplayFooter(true);
		setdisplayFooterAceptarCambio(false);

		setdisplayFooterFinalizar(false);
		setCURP({ curp: '', confirmCurp: '' });
		setErrorCURP({ valid: false, msg: '' });
		setConfirmErrorCURP({
			valid: false,
			msg: '',
		});
		setButtonAceptarCambios(false);
		setLoading(false);
		setModalConfirm(false);
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});

		onSetUser();
	}, []);

	const onCorrectionCurpName = async (nss: string) => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(nss.toString() || '', key);
			const dataFlag = {
				nss: nss_base64,
			};

			correctionCurpAPI
				.correctionCurpName({ ...dataFlag })
				.then((res) => {
					setDescriptionstring(
						'La actualización de tu CURP o nombre se verá reflejada en 72 horas'
					);
					if (res?.result?.code && res?.result?.code === '0000') {
						setNumCasostring(res.result.caso);
						setServiciostring(res.result.servicio);
						setFechaSolicitudstring(res.result.fechaSolicitud);
						setEstatusstring(res.result.estatus);
						setFechaEstatusstring(res.result.fechaEstatus);
						setDescriptionstring(res.result.description);
						setPage(3);
						onDisplayFooter(3);
					}
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						message:
							error && error.err
								? error.err
								: INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message:
					INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const onValidChangeCurp = async () => {
		setLoading(true);

		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(NSSstring.toString() || '', key);
			const curp_base64 = await encrypt(CURP.curp.toString() || '', key);
			const dataFlag = {
				nss: nss_base64,
				curp: curp_base64,
			};

			correctionCurpAPI
				.ValidChangeCurp({ ...dataFlag })
				.then((res) => {
					if (res?.error) {
						setAlert({
							show: true,
							message: res.error.description
								? res.error.description
								: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
					if (res?.result) {
						
							setFechaNacimientostring(res.result.fechaNacimiento);
							setNombrestring(res.result.nombre);
							setPaternostring(res.result.paterno);
							setMaternostring(res.result.materno);
							setGenerostring(res.result.desc);
							setNuevaCURPstring(res.result.curp);
							regBitacora({ 
								key: CURP_CORRECTION_ACTIONS.VALID_CHANGE_CURP,
								scheme: correctionCurpAPI.schemeUrl,
								valorNuevo: { curp:CURP.curp.toString() }
							});
							onNextPage();
						
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description
							? error.description
							: INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message:
					INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const onCreateOrder = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			const nss_base64 = await encrypt(NSSstring.toString() || '', key);
			const curp_base64 = await encrypt(NuevaCURPstring.toString() || '', key);
			const nombre_base64 = await encrypt(Nombrestring.toString() || '', key);
			const paterno_base64 = await encrypt(Paternostring.toString() || '', key);
			const materno_base64 = await encrypt(Maternostring.toString() || '', key);
			const fechaNacimiento_base64 = await encrypt(FechaNacimientostring.toString() || '', key);
			const email_base64 = await encrypt(EMAILstring.toString() || '', key);
			const rfc_base64 = await encrypt(RFCstring.toString() || '', key);

			const dataFlag = {
				nss: nss_base64,
				curp: curp_base64,
				nombre: nombre_base64,
				paterno: paterno_base64,
				materno: materno_base64,
				fechaNacimiento: fechaNacimiento_base64,
				email: email_base64,
				rfc: rfc_base64,
			};

			correctionCurpAPI
				.CreateOrder({ ...dataFlag })
				.then((res) => {
					if (res?.result?.codigo && res?.result?.codigo === '0000') {
						setNumCasostring(res.result.ordenServicio);
						setServiciostring(res.result.servicio);
						setFechaSolicitudstring(res.result.fechaSolicitud);
						setEstatusstring(res.result.estatus);
						setFechaEstatusstring(res.result.fechaEstatus);
						setDescriptionstring(res.result.estatus);

						onNextPage();
					}

					if (res?.result?.codigo && res?.result?.codigo !== '0000') {
						setAlert({
							show: true,
							message: res.result.description,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.error.mensaje
							? error.error.mensaje
							: INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message:
					INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const style = useStyles();
	return (
		<Layout>
			<div>
				<HeaderList
					key="HeaderCorreccionCURP"
					title="Corrección de CURP"
					date="12 julio 2021"
					list={describeText}
				/>
				<Paper>
					<Box className={style.container}>
						{Page === 0 && (
							<Box>
								<Box className={style.containerCenter}>
									<Box className={style.TextoCentrado}>
										<span className={style.TextBlack_H3}>
											Datos que tenemos registrados en nuestro
											sistema
										</span>
									</Box>
								</Box>
								{/* --------------------------------------------------------- */}
								<RowComponent
									items={[
										{
											key: 'CURP_Title',
											value: 'Clave Única de Registro de Población (CURP)',
											bold: true,
										},
										{
											key: 'CURP_Value',
											value: CURPstring,
											bold: false,
											alignItems: 'left',
										},
									]}
									links={[
										{
											key: 'Link_Actualizar',
											value: alert.show ? '' : 'Actualizar',
											bold: false,
											link: true,
											Event: () => onNextPage(),
											alignItems: 'right',
										},
									]}
								/>

								{/* --------------------------------------------------------- */}
								<RowComponent
									items={[
										{
											key: 'Nombre_Title',
											value: 'Nombre completo',
											bold: true,
										},
										{
											key: 'Nombre_Value',
											value: NAMEstring,
											bold: false,
											alignItems: 'left',
										},
									]}
									links={[
										{
											key: 'Link_Empty',
											value: '',
											bold: false,
											link: true,
											Event: () => { },
											alignItems: 'right',
										},
									]}
								/>
								<CustomAlert
									data-testid="mensageAlert"
									message={alert.message}
									severity={alert.severity}
									show={alert.show}
									onClose={() => {
										setAlert({
											show: false,
											message: '',
											severity: alert.severity,
										});
									}}
								/>
							</Box>
						)}

						{Page === 1 && (
							<Box>
								<Box className={style.TextoIzquierda}>
									<Box>
										<span
											className={style.TextBlack_H3}
											data-testid="CURPlabel"
										>
											Clave Única de Registro de Población (CURP)
										</span>
									</Box>
								</Box>
								{/* --------------------------------------------------------- */}
								<Box className={style.containerCenter}>
									<Box className={style.containerInputs} sx={{}}>
										<Box>
											<TextInput
												data-testid="CURPtextInput"
												id="CURP"
												name="CURP"
												label="CURP"
												value={CURP.curp}
												onChange={onChangeInput}
												error={!errorCURP.valid}
												helperText={
													!errorCURP.valid ? errorCURP.msg : ''
												}
												isRequired
											/>
											<TextInput
												data-testid="confirmCURP"
												id="confirmCURP"
												name="confirmCURP"
												label="Confirmar CURP"
												value={CURP.confirmCurp}
												onChange={onChangeInput}
												error={!errorConfirmCURP.valid}
												helperText={
													!errorConfirmCURP.valid
														? errorConfirmCURP.msg
														: ''
												}
												isRequired
											/>
										</Box>
									</Box>
									<Box className={style.containerCenter}>
										<span className={style.fuentefooter}>
											Si deseas consultar tu CURP en el Registro
											Nacional de Población (RENAPO),{' '}
											<span
												onClick={() => {
													window.open(
														'https://www.gob.mx/curp/',
														'_blank'
													);
												}}
												className={style.fuentefooterRed}
											>
												ingresa aquí
											</span>
										</span>
									</Box>
									<div className={style.containerCenter}>
										<div className={style.containerInputs}>
											<CustomAlert
												data-testid="mensageAlert"
												message={alert.message}
												severity={alert.severity}
												show={alert.show}
												onClose={() => {
													setAlert({
														show: false,
														message: '',
														severity: alert.severity,
													});
												}}
											/>
										</div>
									</div>
									<Box className={style.containerButton}>
										<Box className={style.button}>
											<CustomButton
												data-testid="CancelarCambioCURP"
												onClick={onPrevPage}
												variant="outlined"
												styles={{}}
												label="Cancelar"
											/>
										</Box>
										<Box className={style.button}>
											<CustomButton
												data-testid="ContinuarCambioCURP"
												onClick={onValidChangeCurp}
												variant="solid"
												styles={{}}
												label="Continuar"
												disabled={
													errorCURP.valid &&
														errorConfirmCURP.valid &&
														CURP.curp === CURP.confirmCurp
														? false
														: true
												}
											/>
										</Box>
									</Box>
								</Box>
								<hr className={style.hrColor} />
								{/* --------------------------------------------------------- */}
							</Box>
						)}

						{Page === 2 && (
							<Box>
								<RowComponent
									items={[
										{
											key: 'CURP2_Title',
											value: 'Clave Única de Registro de Población (CURP)',
											bold: true,
											alignItems: 'left',
										},
										{
											key: 'CURP2_Value',
											value: NuevaCURPstring,
											bold: false,
											alignItems: 'right',
										},
									]}
								/>
								<RowComponent
									items={[
										{
											key: 'Nombres_Title',
											value: 'Nombre(s)',
											bold: true,
										},
										{
											key: 'Nombres_Value',
											value: Nombrestring,
											bold: false,
											alignItems: 'right',
										},
									]}
								/>
								<RowComponent
									items={[
										{
											key: 'Paterno_Title',
											value: 'Primer apellido',
											bold: true,
										},
										{
											key: 'Paterno_Value',
											value: Paternostring,
											bold: false,
											alignItems: 'right',
										},
									]}
								/>
								<RowComponent
									items={[
										{
											key: 'Materno_Title',
											value: 'Segundo apellido',
											bold: true,
										},
										{
											key: 'Materno_Value',
											value: Maternostring,
											bold: false,
											alignItems: 'right',
										},
									]}
								/>
								<RowComponent
									items={[
										{
											key: 'FechaNacimiento_Title',
											value: 'Fecha de nacimiento',
											bold: true,
										},
										{
											key: 'FechaNacimiento_Value',
											value: FechaNacimientostring,
											bold: false,
											alignItems: 'right',
										},
									]}
								/>
								<RowComponent
									items={[
										{
											key: 'Genero_Title',
											value: 'Género',
											bold: true,
										},
										{
											key: 'Genero_Value',
											value: Generostring,
											bold: false,
											alignItems: 'right',
										},
									]}
								/>
							</Box>
						)}

						{Page === 3 && (
							<Box style={{
								paddingLeft: matches ? 20 : 50,
								paddingRight: matches ? 15 : 50,
								paddingTop: matches ? 30 : 30,
								paddingBottom: matches ? 0 : 10,
							}}>
								<Box className={style.containerCenter}>
									<Box className={style.TextoCentrado}>
										<span className={style.title}>
											Gracias por utilizar los servicios
											digitales Infonavit
										</span>
									</Box>
								</Box>
								<br/>
								<br/>
								
								{/* --------------------------------------------------------- */}
								<RowComponent
									items={[
										{
											key: 'NomeroCaso_Title',
											value: 'Número de caso',
											bold: true,
										},
										{
											key: 'NumeroCaso_Value',
											value: NumCasostring,
											bold: false,
											alignItems: 'right',
										},
									]}
								/>
								<RowComponent
									items={[
										{
											key: 'Servicio_Title',
											value: 'Servicio',
											bold: true,
										},
										{
											key: 'Servicio_Value',
											value: Serviciostring,
											bold: false,
											alignItems: 'right',
										},
									]}
								/>
								<RowComponent
									items={[
										{
											key: 'FechaSolicitud_Title',
											value: 'Fecha de solicitud',
											bold: true,
										},
										{
											key: 'FechaSolicitud_Value',
											value: FechaSolicitudstring,
											bold: false,
											alignItems: 'right',
										},
									]}
								/>
								<RowComponent
									items={[
										{
											key: 'Estatus_Title',
											value: 'Estatus',
											bold: true,
										},
										{
											key: 'Estatus_Value',
											value: Descriptionstring,
											bold: false,
											alignItems: 'right',
										},
									]}
								/>
								<RowComponent
									items={[
										{
											key: 'FechaEstatus_Title',
											value: 'Fecha de estatus',
											bold: true,
										},
										{
											key: 'FechaEstatus_Value',
											value: FechaEstatusstring,
											bold: false,
											alignItems: 'right',
										},
									]}
								/>
							</Box>
						)}

						{/* ----------- Footers ----------- */}
						{displayFooter && (
							<Box>
								<Box className={style.containerCenter}>
									{Page !== 1 && (
										<span className={style.fuentefooter}>
											Si deseas consultar tu CURP en el Registro
											Nacional de Población (RENAPO),{' '}
											<span
												onClick={() => {
													window.open(
														'https://www.gob.mx/curp/',
														'_blank'
													);
												}}
												className={style.fuentefooterRed}
											>
												ingresa aquí
											</span>
										</span>
									)}
								</Box>
								{Page === 1 && (
									<span className={style.DatosObligatorios}>
										* Datos obligatorios
									</span>
								)}
							</Box>
						)}

						{displayFooterAceptarCambio && (
							<Box>
								<Box className={style.containerCenter}>
									<Box className={style.BoxFooterAceptarCambio}>
										<span className={style.Text_H3}>
											De acuerdo con el Registro Nacional de
											Población (RENAPO), la CURP registrada tiene
											asociados estos datos. <br></br>
											<br></br>
											Verifica tu CURP y los datos que aparecen. Si
											alguno está incorrecto, ingresa a la página
											del RENAPO en la sección Trámites y Servicios,
											consulta los pasos a seguir para solicitar la
											modificación o corrección.
										</span>
										<br></br>
										<br></br>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<FormControlLabel
												control={
													<Checkbox
														icon={
															<CheckBoxOutlineBlankIcon />
														}
														checkedIcon={<CheckBoxIcon />}
														onChange={onChangeCheckbox}
														name="checkModal"
													/>
												}
												style={{
													color: buttonAceptarCambios
														? theme.palette.primary.main
														: 'black',
													fontFamily: 'Roboto',
													lineHeight: 'normal',
													fontSize: 12,
												}}
												label="Acepto que se realice la actualización de mis datos en los sistemas del Infonavit"
												value="buttonAceptarCambios"
											/>
										</Box>
									</Box>
								</Box>
							</Box>
						)}

						{displayFooterAceptarCambio && (
							<Box>
								{Page !== 0 && (
									<CustomAlert
										message={alert.message}
										severity={alert.severity}
										show={alert.show}
										onClose={() => {
											setAlert({
												show: false,
												message: '',
												severity: alert.severity,
											});
										}}
									/>
								)}

								<Box className={style.containerCenter}>
									<Box
										className={style.containerButtonBoxAceptarCambios}
									>
										<Box className={style.button}>
											<CustomButton
												onClick={onPrevPage}
												variant="outlined"
												styles={{}}
												label="Cancelar"
											/>
										</Box>
										<Box className={style.button}>
											<CustomButton
												onClick={onCreateOrder}
												variant="solid"
												styles={{}}
												label="Continuar"
												disabled={!buttonAceptarCambios}
											/>
										</Box>
									</Box>
								</Box>
							</Box>
						)}

						{displayFooterFinalizar && (
							<Box>
								<Box className={style.containerCenter}>
									<Box className={style.BoxFooterAceptarCambio}>
										<h2 className={style.subtitle}>
											La actualización de tu CURP o nombre se verá
											reflejada en 72 horas
											{/*  */}
										</h2>
										<br />
										<span className={style.Text_H3}>
											en los sistemas de Infonavit. Recibirás en tu
											correo electrónico un mensaje de confirmación
											del trámite realizado.
											<br />
											<br />
											Revisa el estado de cuenta de tu Afore y en
											caso de existir inconsistencias deberás acudir
											a la Afore para solicitar la corrección de tus
											datos.
										</span>
										<br />
										<br />
										{Page !== 0 && (
											<CustomAlert
												data-testid="mensageAlert"
												message={alert.message}
												severity={alert.severity}
												show={alert.show}
												onClose={() => {
													setAlert({
														show: false,
														message: '',
														severity: alert.severity,
													});
												}}
											/>
										)}

										<Box className={style.boxButtonFinalizar}>
											<CustomButton
												onClick={() => {
													navigate('/inicio');
												}}
												variant="solid"
												styles={{}}
												label="Finalizar"
												disabled={false}
											/>
										</Box>
									</Box>
								</Box>
							</Box>
						)}

						<ModalLoading loading={loading} />
						<BaseModal
							title="Confirmar"
							open={modalConfirm}
							width="sm"
							onClose={() => setModalConfirm(!modalConfirm)}
							onConfirm={() => null}
							confirmLabel={'Sí'}
							cancelLabel={'No'}
							cancelButton
						>
							<p>¿Estás seguro de crear la cuenta?</p>
						</BaseModal>
					</Box>
				</Paper>
			</div>
		</Layout>
	);
};
export default CURPCorrection;
