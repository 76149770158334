import { HeaderList } from "../../../../../components/HeaderList";

const Ecotechnology = () => {
	return (
		<>
			<span>
				El programa Hipoteca Verde consiste en otorgar un monto de
				crédito para equipar con ecotecnologías las viviendas que sean
				adquiridas con un crédito otorgado por Infonavit, con el
				objetivo de generar ahorros en los consumos de agua, luz y gas y
				contribuir al cuidado del medio ambiente.
			</span>
			<p>
				El monto para ecotecnologías se determina de acuerdo a tu edad y
				salario, y su pago está incluido en el descuento mensual de tu
				crédito para vivienda.
			</p>
			<div>
				<p>Considera que:</p>
				<HeaderList
					listSizeIcon={18}
					list={[
						<>
							En caso de destinar tu crédito para comprar en forma
							directa a un particular una vivienda nueva o usada,
							podrás disponer de tu monto para ecotecnologías en
							el momento que tú lo decidas y con los proveedores
							que elijas del padrón autorizado por Infonavit.
						</>,
						<>
							Si se trata una vivienda nueva cuyo trámite sea
							gestionado por un desarrollador, esta tendrá
							ecotecnologías incluidas de forma obligatoria,
							adicionalmente el desarrollador podría instalar
							otras que serían descontadas de tu monto de crédito
							para ecotecnologías y el monto que quedara
							disponible podrás utilizarlo en el momento que tú lo
							decidas y con los proveedores que elijas del padrón
							autorizado por Infonavit.
						</>,
					]}
				/>
			</div>
		</>
	);
};

export default Ecotechnology;
