/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Material Components
import { Box, Paper, Checkbox, FormControlLabel, useMediaQuery, Alert } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { CheckCircleOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

// Components
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';

// Assets
import warningRedAssets from '../../assets/img/icono-advertencia-rojo.png';
import { useStyles } from './styles';
import { IAlert } from '../../interfaces/alert';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { ApiRfcCorrectionRegister } from '../../api/modules/rfcCorrectionRegister';

interface Props {
	setPage: (data: number) => void;
	params: {
		nss: string;
		curp: string;
		rfc: string;
		name: string;
		phone: string;
		email: string;
		rfcCrm: string;
	};
}

interface IDataRFC {
	nombre?: string;
	nss?: string;
	rfcBDSAT?: {
		_text?: string;
	};
	curp?: string;
	email?: string;
	mensaje?: string;
}

const PageSat = ({ setPage, params }: Props) => {
	const navigate = useNavigate();
	const style = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [disableButton, setDisableButton] = useState(true);
	const [dataRFC, setDataRFC] = useState<IDataRFC>({});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [errorWithLink, setErrorWithLink] = useState(false)

	useEffect(() => {
		const handleOnSubmit = async () => {
			let messageError = 'No se localizó RFC asociado a la CURP registrada en el Infonavit. Ingresa a la opción de CURP y nombre y verifica tus datos, posteriormente podrás intentar nuevamente el trámite de corrección de RFC. Si quieres verificar tus datos registrados en el SAT ingresa aquí.'
			try {
				setLoading(true);
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const nss_base64 = await encrypt(params?.nss.toString() || '', key);
				ApiRfcCorrectionRegister.consultaDbsat
					.post<
						{
							nss: string;
						},
						any
					>('', {
						nss: nss_base64,
					})
					.then((responseStatusRFC: any) => {
						if (responseStatusRFC.result.mensaje === messageError || responseStatusRFC.result.description === messageError) {
							setErrorWithLink(true);
						} else if (responseStatusRFC.result.mensaje === 'CURP_NO_LOCALIZADA_EN_BDSAT') {
							setAlert({
								show: true,
								message: 'No se encontró la CURP registrada',
								severity: 'error',
							});
						} else if (responseStatusRFC.result.mensaje === 'NOMBRES_NO_CORRESPONDEN') {
							setAlert({
								show: true,
								message: 'El nombre no corresponde al rfc ingresado',
								severity: 'error',
							});
						} else if (responseStatusRFC.result.mensaje === 'PROCEDE_ACTUALIZACION') {
							const { rfcBDSAT } = responseStatusRFC.result;
							setDataRFC({ ...dataRFC, rfcBDSAT: rfcBDSAT });
						}
					})
					.catch((err: any) => {
						if (err.description === messageError) {
							setErrorWithLink(true);
						} else {
							setAlert({
								show: true,
								message: err.description
									? err.description
									: 'Por el momento el servicio no está disponible. Intenta más tarde.',
								severity: 'error',
							});
						}
					})
					.finally(() => setLoading(false));

			} catch (err: any) {
				if (err.description === messageError) {
					setErrorWithLink(true);
				} else {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				}
			}
		};
		handleOnSubmit();
	}, []);

	const handleOnChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked && !alert.show && !errorWithLink) {
			setDisableButton(false);
		} else {
			setDisableButton(true);
		}
	};

	const actualizarRFC = async () => {
		const keyRes = await keyAPI.get<'', { k: string }>();
		const key = keyRes['k'].toString();
		setLoading(true);
		try {
			const nss_base64 = await encrypt(params?.nss.toString() || '', key);
			const email_base64 = await encrypt(params?.email.toString() || '', key);
			const celular_base64 = await encrypt(params?.phone.toString() || '', key);
			const rfcBdsat_base64 = await encrypt(dataRFC.rfcBDSAT?._text?.toString() || '', key);
			ApiRfcCorrectionRegister.actualizaDbsat
				.post<
					{
						nss: string;
						email: string;
						telefonoCelular: string;
						rfcBDSAT: string;
					},
					any
				>('', {
					nss: nss_base64,
					email: email_base64,
					telefonoCelular: celular_base64,
					rfcBDSAT: rfcBdsat_base64
				})
				.then((res: any) => {
					if (res) {
						setSuccess(true)
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false)
		}
	};

	return (
		<Paper>
			<ModalLoading loading={loading} />
			<Box className={style.container} style={{ padding: matches ? 20 : 0 }}>
				{!success && (
					<div
						id="rfcCorrection"
						style={{ display: 'inline' }}
					>
						<h3 className={style.h3} style={{ textAlign: 'center' }}>
							El RFC obtenido de la consulta a la información del SAT es:
						</h3>
						<div className={style.inputContainer}>
							<div className={style.inputRfc} style={{ marginTop: 0 }}>
								<TextInput
									data-testid="inputRFC"
									id="rfc"
									name="rfcInput"
									label="RFC"
									value={
										dataRFC.rfcBDSAT?._text ? dataRFC.rfcBDSAT?._text : ''
									}
									disabled
								/>
							</div>
						</div>

						<div>
							<FormControlLabel
								control={
									<Checkbox
										onChange={handleOnChangeCheck}
										data-testid="checkRFC"
										checkedIcon={<CheckBoxOutlinedIcon />}
									/>
								}
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									marginTop: matches ? 5 : 2,
									paddingLeft: matches ? 3 : 0,
								}}
								label="Acepto que se realice el registro o actualización de mi
									RFC en los sistemas del Infonavit"
							/>
						</div>

						<div
							className={style.inputContainer}
							style={{ display: alert.show ? 'flex' : 'none' }}
						>
							<div
								style={{ marginTop: 10, marginBottom: 15, width: '100%' }}
							>
								<CustomAlert
									show={alert.show}
									severity={alert.severity}
									message={alert.message}
								/>
							</div>
						</div>

						{errorWithLink && (
							<div className={style.inputContainer} style={{ display: 'flex' }}>
								<div className={style.inputRfc} style={{ marginTop: 10, marginBottom: 15, height: 'auto' }}>
									<Alert
										style={{
											borderRadius: '6px',
											display: 'flex',
											boxSizing: 'border-box',
											alignItems: 'center',
											marginTop: 10,
											marginBottom: 10,
											backgroundColor: '#FFE2E5',
											color: '#D1001F',
										}}
										className={style.alertIcon}
										severity={'error'}
										sx={{ width: '100%' }}
										icon={
											<img
												src={warningRedAssets}
												className={style.sizeIcon}
												alt="close"
											/>
										}
									>
										<p>
											No se localizó RFC asociado a la CURP registrada en el Infonavit.
											Ingresa a la opción de CURP y nombre y verifica tus datos, posteriormente
											podrás intentar nuevamente el trámite de corrección de RFC. Si quieres
											verificar tus datos registrados en el SAT ingresa <a style={{ color: 'rgb(209, 0, 31)' }} href="https://www.sat.gob.mx/home" target="blank">aquí.</a>
										</p>
									</Alert>
								</div>
							</div>
						)}

						<div className={style.botonContainer}>
							<CustomButton
								data-testid="gotSATExit"
								label="Salir"
								onClick={() => {
									setPage(0);
								}}
								variant="outlined"
								styles={{
									width: matches ? 350 : 250,
									minHeight: 40,
									marginBottom: 10,
								}}
							/>
							<CustomButton
								data-testid="gotSATSave"
								label="Continuar"
								onClick={actualizarRFC}
								variant="solid"
								disabled={disableButton}
								styles={{
									width: matches ? 350 : 250,
									minHeight: 40,
									marginBottom: 15,
									marginLeft: matches ? 0 : 25,
								}}
							/>
						</div>
					</div>
				)}
				{success && (
					<div
						id="thanksSAT"
						style={{ display: 'inline' }}
					>
						<h3 className={style.title} style={{ paddingTop: 40 }}>
							Tu RFC ha sido actualizado con éxito
						</h3>
						<h3 className={style.h3} style={{ paddingTop: 0, textAlign: 'center' }}>
							Tu clave de RFC es {dataRFC.rfcBDSAT?._text}
						</h3>
						<br />
						<div className={style.listContainer}>
							<CheckCircleOutlined
								color="info"
								style={{ marginTop: 2, marginRight: 5 }}
								fontSize="small"
							/>
							<p className={style.pGotSAT}>
								Para efecto de precalificación para un crédito, la
								actualización se verá reflejada en un plazo máximo de 5 días
								hábiles.
							</p>
						</div>
						<div className={style.listContainer}>
							<CheckCircleOutlined
								color="info"
								style={{ marginTop: 2, marginRight: 5 }}
								fontSize="small"
							/>
							<p className={style.pGotSAT}>
								Para la Constancia de Intereses de Crédito deberás acudir al
								CESI para el trámite de actualización de datos
								correspondientes.
							</p>
						</div>
						<div className={style.listContainer}>
							<CheckCircleOutlined
								color="info"
								style={{ marginTop: 2, marginRight: 5 }}
								fontSize="small"
							/>
							<p className={style.pGotSAT}>
								Es importante que también tengas tus datos actualizados en tu
								Afore; revisa tu estado de cuenta y en caso de que no esté
								correcto alguno de tus datos, solicita su corrección.
							</p>
						</div>
						<div className={style.botonContainerThanksSAT}>
							<CustomButton
								label="Finalizar"
								styles={{ width: 260 }}
								onClick={() => {
									localStorage.removeItem('dataUserRegister');
									navigate('/registro');
								}}
								variant="solid"
							/>
						</div>
					</div>
				)}
			</Box>
		</Paper>
	);
};
export default PageSat;
