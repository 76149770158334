import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			[theme.breakpoints.down('sm')]: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
		},
		noCreditLabel: {
			marginLeft: 10,
			color: '#293990',
			backgroundColor: '#DAE2EC',
			paddingTop: 8,
			paddingBottom: 8,
			paddingLeft: 12,
			paddingRight: 12,
			borderRadius: 20,
			fontSize: 14,
			fontWeight: 'bold',
		},
	})
);
