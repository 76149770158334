import { useState } from 'react';
import { useStyles } from './styles';
import { Divider, Grid, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Close, Search } from '@mui/icons-material';
import CustomButton from '../../components/Button';
import BaseModal from '../../components/ModalConfirmation';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { IAlert } from '../../interfaces/alert';
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';
import DocumentView from './DocumentView';

interface ICaso {
	or_description?: string;
	or_fecha?: string;
	or_object_id?: string;
	or_status?: string;
	or_status_reason?: string;
	or_fecha_stat?: string;
	or_status_message?: string;
	descripcion_estatus?: string;
}

interface Props {
	setPage: (data: number) => void;
	documentos: any;
	caso: ICaso;
}

const UpLoadDocuments = ({ setPage, caso, documentos }: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const [modal, setModal] = useState(false);
	const [modalDoc, setModalDoc] = useState(null);
	const [modalFile, setModalFile] = useState(false);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [files, setFiles] = useState({
		file1: {
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
			size: 0,
		},
		file2: {
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
			size: 0,
		},
		file3: {
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
			size: 0,
		},
		file4: {
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
			size: 0,
		},
	});

	const handleFile = (e: any) => {
		if (
			e.target.files[0].size < 20971520 &&
			(e.target.files[0].type === 'image/jpeg' ||
				e.target.files[0].type === 'application/pdf' ||
				e.target.files[0].type === 'image/tiff' ||
				e.target.files[0].type === 'video/x-msvideo' ||
				e.target.files[0].type === 'image/gif' ||
				e.target.files[0].type === 'audio/mpeg')
		) {
			setFiles({
				...files,
				[e.target.name]: {
					name: e.target.files[0].name,
					data: e.target.files[0],
					type: e.target.files[0].type,
					size: e.target.files[0].size,
				},
			});
		} else {
			setModalFile(true);
		}
	};

	const handleDeleteFile = (index: string) => {
		setFiles({
			...files,
			[index]: {
				name: 'No se eligió ningún archivo',
				data: null,
			},
		});
	};


	const uploadFilesWeb = async () => {
		try {
			setModal(false);
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const caso_base64 = await encrypt(caso.or_object_id || '', key);
			let formData = new FormData();
			formData.append('numero_caso', caso_base64);
			formData.append('Z202', files.file1.data || '');
			formData.append('Z203', files.file2.data || '');
			formData.append('Z204', files.file3.data || '');
			formData.append('Z205', files.file4.data || '');
			const response: any = await uploadToFileServer(
				formData,
				`${process.env.REACT_APP_API_URL}/entrega-escritura-subir-documentos`
			);
			if (response?.codigo_respuesta) {
				setAlert({
					show: true,
					message: response?.descripcion || '',
					severity: 'success',
				});
			} else {
				setPage(4);
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const uploadToFileServer = (formdata: any, url: string) => {
		return new Promise((resolve) => {
			const xhr = new XMLHttpRequest();
			xhr.responseType = 'json';
			xhr.open('POST', url, true);
			xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			xhr.send(formdata);
			xhr.onload = () => {
				if (xhr.status === 200) {
					resolve(xhr.response.result);
				} else {
					resolve(null);
				}
			};
		});
	};

	return (
		<Paper
			style={{
				paddingLeft: matches ? 0 : 100,
				paddingRight: matches ? 20 : 100,
				paddingTop: matches ? 10 : 25,
				paddingBottom: matches ? 10 : 10,
			}}
		>
			<DocumentView file={modalDoc} setClose={setModalDoc} />
			<ModalLoading loading={loading} />
			<h3
				className={classes.h4Date}
				style={{
					paddingTop: matches ? 5 : 20,
					paddingBottom: 40,
					textAlign: matches ? 'center' : 'left',
					marginLeft: matches ? 20 : 20,
					marginRight: matches ? 25 : 0,
				}}
			>
				Adjunta alguno de los siguientes documentos que debes adjuntar son:
			</h3>
			<Grid container columns={12} sx={{ paddingLeft: matches ? 2 : 0 }}>
				<Grid item xs={12} sm={6} md={3}>
					<h3 style={{ fontSize: 16, margin: 0 }}>Número de caso</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={9}
					style={{ textAlign: matches ? 'left' : 'right' }}
				>
					{caso.or_object_id}
				</Grid>
				<Grid item xs={12} sm={12} md={12} style={{ margin: '20px 0px' }}>
					<Divider />
				</Grid>
				{/* ---------------------------------------------------------- */}
				<Grid item xs={8} sm={4} md={4} style={{ order: 1, margin: 0 }}>
					<h3
						style={{
							margin: 0,
							color: 'black',
							fontSize: 16,
							marginBottom: matches ? 3 : 0,
						}}
					>
						Escritura
					</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={4}
					md={4}
					style={{
						order: matches && !files.file1.data ? 3 : 2,
						paddingTop: matches ? 0 : 0,
					}}
				>
					<p
						style={{
							margin: 0,
							opacity: 0.5,
							overflow: 'hidden',
							marginBottom: matches && !files.file1.data ? 50 : 0,
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
					>
						{files.file1.name}
					</p>
				</Grid>
				<Grid
					item
					xs={!files.file1.data ? 4 : 12}
					sm={4}
					md={4}
					style={{
						textAlign: 'right',
						order: matches && !files.file1.data ? 2 : 3,
					}}
				>
					{files.file1.data && (
						<label
							onClick={() => handleDeleteFile('file1')}
							style={{
								cursor: 'pointer',
								display: matches ? 'flex' : 'inline-flex',
								alignItems: 'center',
								margin: matches ? '5px 0px' : '0px 5px',
								color: '#D1001F',
							}}
						>
							<Close style={{ fontSize: 20, marginRight: 2 }} />
							Eliminar archivo
						</label>
					)}
					<label
						style={{
							cursor: 'pointer',
							display: matches ? 'flex' : 'inline-flex',
							alignItems: 'center',
							margin: matches ? '5px 0px' : '0px 5px',
							color: '#D1001F',
							textAlign: 'right',
						}}
						onClick={() => files.file1.data && setModalDoc(files.file1.data)}
					>
						{files.file1.data ? (
							<>
								<Search
									style={{
										fontSize: 20,
										marginRight: 2,
									}}
								/>
								Ver archivo
							</>
						) : (
							<label
								style={{
									cursor: 'pointer',
									paddingBottom: matches ? 5 : 0,
								}}
							>
								<input
									type="file"
									accept="*"
									name="file1"
									onChange={handleFile}
									className={classes.chooseFileOK}
								/>
								Elegir archivo
							</label>
						)}
					</label>
				</Grid>
				<Grid item xs={12} md={12} style={{ margin: '20px 0px', order: 4 }}>
					<Divider />
				</Grid>
			</Grid>
			{/* ---------------------------------------------------------------- */}
			<Grid container columns={12} sx={{ paddingLeft: matches ? 2 : 0 }}>
				<Grid item xs={8} sm={4} md={4} style={{ order: 1, margin: 0 }}>
					<h3
						style={{
							margin: 0,
							color: 'black',
							fontSize: 16,
						}}
					>
						Carta de otorgamiento de crédito
					</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={4}
					md={4}
					style={{
						order: matches && !files.file2.data ? 3 : 2,
						paddingTop: matches ? 0 : 0,
					}}
				>
					<p
						style={{
							margin: 0,
							opacity: 0.5,
							overflow: 'hidden',
							marginBottom: matches && !files.file1.data ? 50 : 0,
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
					>
						{files.file2.name}
					</p>
				</Grid>
				<Grid
					item
					xs={!files.file2.data ? 4 : 12}
					sm={4}
					md={4}
					style={{
						textAlign: 'right',
						order: matches && !files.file2.data ? 2 : 3,
						color: '#D1001F',
					}}
				>
					{files.file2.data && (
						<label
							onClick={() => handleDeleteFile('file2')}
							style={{
								cursor: 'pointer',
								display: matches ? 'flex' : 'inline-flex',
								alignItems: 'center',
								margin: matches ? '5px 0px' : '0px 5px',
							}}
						>
							<Close style={{ fontSize: 20, marginRight: 2 }} />
							Eliminar archivo
						</label>
					)}
					<label
						style={{
							cursor: 'pointer',
							display: matches ? 'flex' : 'inline-flex',
							alignItems: 'center',
							margin: matches ? '0px 0px' : '0px 5px',
						}}
						onClick={() => files.file2.data && setModalDoc(files.file2.data)}
					>
						{files.file2.data ? (
							<>
								<Search style={{ fontSize: 20, marginRight: 2 }} />
								Ver archivo
							</>
						) : (
							<label
								style={{
									cursor: 'pointer',
									paddingBottom: matches ? 5 : 0,
									color: '#D1001F',
								}}
							>
								<input
									type="file"
									accept="*"
									name="file2"
									onChange={handleFile}
									className={classes.chooseFileOK}
								/>
								Elegir archivo
							</label>
						)}
					</label>
				</Grid>
				<Grid item xs={12} md={12} style={{ margin: '20px 0px', order: 4 }}>
					<Divider />
				</Grid>
			</Grid>
			{/* ------------------------------------------------------- */}
			<Grid container columns={12} sx={{ paddingLeft: matches ? 2 : 0 }}>
				<Grid item xs={8} sm={4} md={4} style={{ order: 1, margin: 0 }}>
					<h3
						style={{
							margin: 0,
							color: 'black',
							fontSize: 16,
						}}
					>
						Certificación de vivienda
					</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={4}
					md={4}
					style={{
						order: matches && !files.file3.data ? 3 : 2,
						paddingTop: matches ? 0 : 0,
					}}
				>
					<p
						style={{
							margin: 0,
							opacity: 0.5,
							overflow: 'hidden',
							marginBottom: matches && !files.file1.data ? 50 : 0,
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
					>
						{files.file3.name}
					</p>
				</Grid>
				<Grid
					item
					xs={!files.file3.data ? 4 : 12}
					sm={4}
					md={4}
					style={{
						textAlign: 'right',
						order: matches && !files.file3.data ? 2 : 3,
						color: '#D1001F',
					}}
				>
					{files.file3.data && (
						<label
							onClick={() => handleDeleteFile('file3')}
							style={{
								cursor: 'pointer',
								display: matches ? 'flex' : 'inline-flex',
								alignItems: 'center',
								margin: matches ? '5px 0px' : '0px 5px',
							}}
						>
							<Close style={{ fontSize: 20, marginRight: 2 }} />
							Eliminar archivo
						</label>
					)}
					<label
						style={{
							cursor: 'pointer',
							display: matches ? 'flex' : 'inline-flex',
							alignItems: 'center',
							margin: matches ? '0px 0px' : '0px 5px',
						}}
						onClick={() => files.file3.data && setModalDoc(files.file3.data)}
					>
						{files.file3.data ? (
							<>
								<Search style={{ fontSize: 20, marginRight: 2 }} />
								Ver archivo
							</>
						) : (
							<label
								style={{
									cursor: 'pointer',
									paddingBottom: matches ? 5 : 0,
									color: '#D1001F',
								}}
							>
								<input
									type="file"
									accept="*"
									name="file3"
									onChange={handleFile}
									className={classes.chooseFileOK}
								/>
								Elegir archivo
							</label>
						)}
					</label>
				</Grid>
				<Grid item xs={12} md={12} style={{ margin: '20px 0px', order: 4 }}>
					<Divider />
				</Grid>
			</Grid>

			<Grid container columns={12} sx={{ paddingLeft: matches ? 2 : 0 }}>
				<Grid item xs={8} sm={4} md={4} style={{ order: 1, margin: 0 }}>
					<h3
						style={{
							margin: 0,
							color: 'black',
							fontSize: 16,
						}}
					>
						Recibo predial
					</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={4}
					md={4}
					style={{
						order: matches && !files.file4.data ? 3 : 2,
						paddingTop: matches ? 0 : 0,
					}}
				>
					<p
						style={{
							margin: 0,
							opacity: 0.5,
							overflow: 'hidden',
							marginBottom: matches && !files.file1.data ? 50 : 0,
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
					>
						{files.file4.name}
					</p>
				</Grid>
				<Grid
					item
					xs={!files.file4.data ? 4 : 12}
					sm={4}
					md={4}
					style={{
						textAlign: 'right',
						order: matches && !files.file4.data ? 2 : 3,
						color: '#D1001F',
					}}
				>
					{files.file4.data && (
						<label
							onClick={() => handleDeleteFile('file4')}
							style={{
								cursor: 'pointer',
								display: matches ? 'flex' : 'inline-flex',
								alignItems: 'center',
								margin: matches ? '5px 0px' : '0px 5px',
							}}
						>
							<Close style={{ fontSize: 20, marginRight: 2 }} />
							Eliminar archivo
						</label>
					)}
					<label
						style={{
							cursor: 'pointer',
							display: matches ? 'flex' : 'inline-flex',
							alignItems: 'center',
							margin: matches ? '0px 0px' : '0px 5px',
						}}
						onClick={() => files.file4.data && setModalDoc(files.file4.data)}
					>
						{files.file4.data ? (
							<>
								<Search style={{ fontSize: 20, marginRight: 2 }} />
								Ver archivo
							</>
						) : (
							<label
								style={{
									cursor: 'pointer',
									paddingBottom: matches ? 5 : 0,
									color: '#D1001F',
								}}
							>
								<input
									type="file"
									accept="*"
									name="file4"
									onChange={handleFile}
									className={classes.chooseFileOK}
								/>
								Elegir archivo
							</label>
						)}
					</label>
				</Grid>
				<Grid item xs={12} md={12} style={{ margin: '20px 0px', order: 4 }}>
					<Divider />
				</Grid>
			</Grid>

			<CustomAlert
				show={alert.show}
				message={alert.message}
				severity="warning"
				onClose={() =>
					setAlert({
						show: false,
						message: '',
						severity: 'warning',
					})
				}
			/>

			<div>
				<h3
					style={{
						display: 'flex',
						marginTop: 20,
						justifyContent: 'center',
						textAlign: 'center',
						paddingLeft: matches ? 10 : 0,
						paddingRight: matches ? 10 : 0,

						fontSize: matches ? 16 : 18,
					}}
				>
					Antes de enviarnos verifica que estén correctos.
				</h3>

				<p className={classes.pMakeDate}>
					Antes de enviar tus documento verifica que estén correctos.
					<br />
					En cualquiera de los siguientes formatos: PDF, JPEG, GIF, TIFF, AVI Y
					MP3.
					<br /> El tamaño del archivo no debe exceder los 20 MB.
				</p>
				<div className={classes.botonContainerDate}>
					<CustomButton
						label="Regresar"
						onClick={() => {
							setPage(3);
						}}
						variant="outlined"
						styles={{
							width: 250,
							height: 40,
							marginRight: 20,
							marginBottom: 20,
						}}
					/>
					<CustomButton
						label="Enviar documentos"
						onClick={() => {
							setModal(true);
						}}
						disabled={
							files.file1.data ||
								files.file2.data ||
								files.file3.data ||
								files.file4.data
								? false
								: true
						}
						variant="solid"
						styles={{ width: 250, height: 40, marginBottom: 20 }}
					/>
				</div>
			</div>
			<BaseModal
				open={modal}
				title="S"
				onConfirm={() => uploadFilesWeb()}
				onClose={() => setModal(false)}
				cancelButton={true}
				confirmLabel="Sí"
				cancelLabel="No"
				width="sm"
				children={
					<div
						style={{
							paddingLeft: 40,
							paddingRight: 40,
							paddingTop: 30,
							paddingBottom: 10,
						}}
					>
						<h3
							style={{
								color: '#293990',
								fontSize: 26,
								display: 'flex',
								textAlign: 'center',
								justifyContent: 'center',
								margin: 0,
							}}
						>
							¿Estás seguro de que adjuntaste todos tus documentos?
						</h3>
					</div>
				}
			/>
			<BaseModal
				open={modalFile}
				title="S"
				onConfirm={() => {
					setModalFile(false);
				}}
				onClose={() => setModalFile(false)}
				confirmLabel="Aceptar"
				width="xs"
				children={
					<div
						style={{
							paddingLeft: 40,
							paddingRight: 40,
							paddingTop: 30,
							paddingBottom: 10,
						}}
					>
						<h3
							style={{
								color: '#293990',
								fontSize: 20,
								display: 'flex',
								textAlign: 'center',
								justifyContent: 'center',
								margin: 0,
							}}
						>
							Por favor, adjunta un tipo de documento válido.
						</h3>
					</div>
				}
			/>
		</Paper>
	);
};

export default UpLoadDocuments;
