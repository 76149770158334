import { CheckCircleOutlined } from '@mui/icons-material';
import BaseModal from '../../components/Modal';
import { useStyles } from './styles';
import { formatDate } from '../../utils/dates';
import { downloadPDF } from '../../utils/downloadPDF';
import CustomButton from '../../components/Button';

interface ListCorreo {
	id: number;
	title: string;
}

const LISTCORREOS: ListCorreo[] = [
	{
		id: 0,
		title: 'Se envió un correo electrónico a la dirección que tenemos registrada con la ficha de depósito generada.',
	},
	{
		id: 1,
		title: 'De no encontrar el correo en la bandeja de entrada, revisa la carpeta de correos no deseados (spam).',
	},
	{
		id: 2,
		title: 'De lo contrario comunícate al Infonatel.',
	},
];

const SuccessContribution = ({ open, setOpen, onChangeTab, results }: any) => {
	const styles = useStyles();
	return (
		<BaseModal showDialogTitle open={open} width="md" onClose={() => setOpen(!open)}>
			<>
				<div className={styles.divAlert}>
					<h2 className={styles.txtInicial} style={{ margin: 0 }}>
						Gracias por utilizar los servicios digitales del Infonavit
					</h2>
					{/* <p>Pago realizado</p> */}
					<h3 className={styles.txtInicial} style={{ margin: 0 }}>
						${results?.monto} MXN
					</h3>
					<div className={styles.summary}>
						<div>
							<small>Número de caso</small>
						</div>
						<small>
							<b>{results?.numero_caso}</b>
						</small>
					</div>
					<div className={styles.summary}>
						<div>
							<small>Fecha de solicitud</small>
						</div>
						<small>
							<b>{formatDate('P')}</b>
						</small>
					</div>
					<div className={styles.summary}>
						<div>
							<small>Servicio</small>
						</div>
						<small>
							<b>{results?.servicio}</b>
						</small>
					</div>
					<div className="contribution-actions">
						<CustomButton
							onClick={() =>
								downloadPDF({
									payload: results.pdf,
									name: `Aportacion-${formatDate('P')}`,
								})
							}
							variant="outlined"
							styles={{ marginTop: 70, width: 200, margin: '0 auto' }}
							label="Descargar ficha de depósito"
						/>
						<CustomButton
							onClick={() => onChangeTab(1)}
							variant="solid"
							styles={{ marginTop: 70, width: 200, margin: '0 auto' }}
							label="Finalizar"
						/>
					</div>
				</div>
				{LISTCORREOS.map(({ id, title }) => (
					<small style={{ display: 'flex', alignItems: 'center' }} key={id}>
						<CheckCircleOutlined fontSize="small" color="info" />
						{title} <br />
					</small>
				))}
			</>
		</BaseModal>
	);
};

export default SuccessContribution;
