/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import CustomButton from '../../../components/Button';
import ModalLoading from '../../../components/ModalLoading';
import TextInput from '../../../components/TextInput';
import CustomAlert from '../../../components/CustomAlert';

import { dataReturnSavingsContext } from '../contextData';
import { useStyles } from '../style';

const NRPRequestPage = () => {
	const style = useStyles();
	const [nrp, setNrp] = useState<string>('');
	const [disabledButton, setDisabledButton] = useState<boolean>(true);
	const {
		handlePrevPage,
		handleConsultaDatosDisponibilidad,
		setData,
		data,
		loading,
		alert,
		setAlert
	} = useContext(dataReturnSavingsContext);

	useEffect(() => {
		setData({ ...(data || undefined), nrpNumber: nrp } || undefined);
	}, [nrp]);

	useEffect(() => {
		if (data?.nrpNumber === undefined || data?.nrpNumber === '') {
			return;
		}

		if (data?.nrpNumber !== nrp) {
			return;
		}

		setDisabledButton(nrp.length > 0 ? false : true);
	}, [data?.nrpNumber]);

	return (
		<div>
			<ModalLoading loading={loading}></ModalLoading>
			<span className={style.TextBlack_H3}>
				Número de Registro Patronal (NRP){' '}
			</span>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divNRP}>
					<TextInput
						data-testid="NRPInput"
						id="NRP"
						name="NRPInput"
						sx={{ color: 'red' }}
						value={nrp}
						onChange={(event) => {
							const { value } = event.target;
							setNrp(value);
						}}
						placeholder={'Escribe tu NRP'}
					/>
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divButtons}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={() => {
								handlePrevPage();
							}}
							variant="outlined"
							styles={{
								width: '100%',
								height: '100%'
							}}
							label="Regresar"
						/>
					</div>
					<div className={style.Buttons}>
						<CustomButton
							onClick={() => {
								handleConsultaDatosDisponibilidad();
							}}
							variant="solid"
							styles={{
								width: '100%',
							}}
							label="Continuar"
							disabled={disabledButton}
						/>
					</div>
				</div>
			</div>
			<div style={{ width: '100%', margin: '20px auto' }}>
				<CustomAlert
					data-testid="mensageAlertthree"
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
					onClose={() => {
						setAlert({
							show: false,
							message: '',
							severity: 'success',
						});
					}}
				/>
			</div>
		</div>
	);
};

export default NRPRequestPage;
