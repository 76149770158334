import React from 'react';
import { Navigate } from 'react-router-dom';
import { RootState } from '../store';
import { useSelector } from 'react-redux';

interface Props {
	component: React.ComponentType;
}

const ProtectedNotLoggedRoute = ({ component: RouteComponent }: Props) => {
	const { user, isLogged } = useSelector((state: RootState) => state.session);
	const userLogged = user && isLogged;

	// /mi-perfil/cambiar-contrasenia
	// locationElement[1] - main page
	// locationElement[2] - child page

	if (!userLogged) {
		return <RouteComponent />;
	} else {
		return <Navigate to="/" />;
	}
};

export default ProtectedNotLoggedRoute;
