export const DESTINOS = {
	COMPRA: {
		codigo: 'L2',
		descripcion: 'Compra',
	},
	CONSTRUCCION: {
		codigo: 'L3',
		descripcion: 'Construir',
	},
	REMODELACION: {
		codigo: 'L4',
		descripcion: 'Ampliar o Reparar',
	},
	HIPOTECA: {
		codigo: 'L5',
		descripcion: 'Hipoteca',
	},
	AUTOPRODUCCION: {
		codigo: 'L6',
		descripcion: 'Autoproducción',
	},
	CICB: {
		codigo: 'L7',
		descripcion: 'CICB',
	},
	CREDITERRENO: {
		codigo: 'L8',
		descripcion: 'CrediTerreno',
	},
    SUELO_CONSTRUCCION: {
		codigo: 'L9',
		descripcion: 'Suelo Construcción',
	},
	CREDITO_SEGURO: {
		codigo: 'CS',
		descripcion: 'Crédito seguro',
	},
	APOYO_INFONAVIT: {
		codigo: 'AP',
		descripcion: 'Apoyo Infonavit',
	},
	MEJORA_DIRECTA: {
		codigo: 'MD',
		descripcion: 'mejOraSí',
	},
};

export const CREDITOS = {
	INDIVIDUAL: {
		descripcion: 'Crédito Individual',
		codigo: 'I',
	},
	CONYUGAL: {
		descripcion: 'Crédito Conyugal',
		codigo: 'C',
	},
	FAMILIAR: {
		descripcion: 'Crédito Familiar',
		codigo: 'F',
	},
	CORRESIDENCIAL: {
		descripcion: 'Crédito Corresidencial',
		codigo: 'R',
	},
};

export const STEPS_MAIN_VIEWS = {
	MAIN_CREDIT_PROCESS: 0,
	MIUC: 1,
	CREDIT_REQ_CAN: 2,
	REQUEST_PRO_CAN: 3,
	VALIDATE_IDENTITY: 4,
	WAITING_DOCUMENT: 5,
	WAITING_VAL_PROCESS: 6,
	DOCUMENT_COMPLETE: 7,
	HOW_CREDIT_GOING: 8,
	HAVE_CREDIT_APP: 9,
	AGROINDUSTRY: 10,
	CICB: 11,
	MAIN_CREDIT_TER: 12,
	ERROR: 13,
};

export const CONSULTAR_CASO = {
	INSCRITO: 'AT',
	PREAUTORIZADO: 'CT',
	FORMALIZABLE: 'RE',
	CANCELADOY: 'CY',
	CANCELADOF: 'CF',
	EJERCIDO: 'EJ',
	ES: 'ES',
	E1: 'E1',
	E2: 'E2',
	E3: 'E3',
	E4: 'E4',
	E5: 'E5',
	E6: 'E6',
	E7: 'E7',
	BA: 'BA',
	BB: 'BB',
	AD: 'AD',
	EP: 'EP',
	EV: 'EV',
	AC: 'AC',
	AJ: 'AJ',
	SR: 'SR',
	PS: 'PS',
	BC: 'BC',
	CC: 'CC',
	CJ: 'CJ',
	CV: 'CV',
	AR: 'AR',
	SA: 'SA',
};

export const BIOMETRIC_CASE_DATA = {
	ABIERTO: 'E0001',
	ABIERTO_CON_CITA: 'E0003',
	CERRADO: 'E0015',
	SOLICITAR_CITA: 'E0017',
};

export const CRM_CASE_DATA = {
	CASO_ABIERTO: 'E0001',
	EN_ESPERA_DE_DOCUMENTACION: 'E0002',
	REENVIO_DE_DOCUMENTOS: 'E0009',
	REVISION_DE_DOCUMENTOS: 'E0003',
	REVISION_DOS: 'E0010',
	DOCUMENTACION_COMPLETA: 'E0004',
	PROCESO_DE_FORMALIZACION: 'E0006',
	CERRADO: 'E0008',
};
export const CRM_MEJORAVIT_CASE_DATA = {
	CASO_ABIERTO: 'E0001',
	EN_ESPERA_DE_DOCUMENTACION: 'E0006',
	REVISION_DE_DOCUMENTOS: 'E0007',
	DOCUMENTACION_COMPLETA: 'E0008',
	CERRADO: 'E0010',
};

export const CREDIT_CASE_OWNER_DATA = {
	INDIVIDUAL_SOC: 'I',
	CONYUGE_CONSULTADO_POR_TITULAR: 'C',
	CONYUGE_CONSULTADO_POR_CONYUGE: 'Y',
	TITULAR_FAMILIAR: 'F',
	CORRESPONSAL_FAMILAR: 'A',
	TITULAR_CORRESIDENCCIAL: 'R',
	CORRESPONSABLE_CORRESIDENCIAL: 'B',
};

export const MIUC_PROCESS_DATA = {
	SELECTION_CREDIT: 0,
	CREDIT_OPTIONS: 1,
	AUTHORIZATION: 2,
	USER_COMPLETE_INFO: 3,
	AVAILABLE_CREDIT: 4,
	CHOOSE_HOME: 5,
	SUMMARY_REQUEST: 6,
	ONLINE_CREDIT: 7,
	APPRAISAL_MANAGEMENT: 8,
	NOTARY_INFO: 9,
	NOTARY_SELECTION: 10,
	AGROINDUSTRY: 11,
	CICB: 12,
	MAINCREDITTER: 13,
	MIUC: 14,
	UNAUTHORIZED: 15,
	MAINCREDITSECURE: 16,
	CREDIT_OPTION_CONSTRUIR: 17,
	SOCIOECONOMIC_STUDY_QUESTION: 18,
	SOCIOECONOMIC_STUDY_QUESTION_STEPS: 19,
	MEJORAVIT_REPARA_RENUEVA: 20,
	INFORMATIVE_CONTENT: 21,
};

export const CODES_RESPONSE = {
	CORRECTO: '0000',
	FAIL: '0001',
	SIN_RESULTADOS: '0002',
	ERROR: 'ERROR',
	NO_RESGITRADO_AGRO: '1504',
	CODIGO_ERROR_PUNTOS_PROPAGADO_TITULAR: '2102',
	CODIGO_ERROR_PUNTOS_PROPAGADO_CONYUGE: '2202',
	CODIGO_ERROR_INFO_GENERAL_TITULAR_PUNTOS_INSUFICIENTES: '9102',
};

export const INFO_APP = {
	CREDITO_SOC: '074',
};

export const INIT_PROFILE = {
	CANAL_MCI: 'Z4',
	CRED_CYG: 'ZMIC',
	BIOMETRICOS: 'ZCCB',
	MEJORAVIT: 'ZMRR',
};

export const CLTA_CRED_CYGAL = {
	TITULAR: '01',
	CONYUGE: '02',
};
export const CONSULTA_ASOCIACION_ENUM = {
	EN_ESPERA_DE_CONFIRMACION: '02',
	EN_ESPERA_DE_CONFIRMACIÓN_DEL_ASOCIADO: '03',
	ASOCIADO: '04',
};

export const MOTIVOS_CANCELACION: { [key: string]: string } = {
	ZN07: 'Cambio de vivienda o producto de crédito',
	ZN08: 'Prefiero realizarlo en forma presencial',
	ZN09: 'Error en captura de datos',
	ZN10: 'El trámite en línea resultó complejo',
};

export const URL_APLICACION = {
	CANCELACION_WFI: 'Cancelacion',
	REIMPRESION_WFI: 'Impresion',
	AUTOSERVICIO: 'AutoServicio',
	VISIBILIDAD: 'visibilidad',
};

export const ESTADOS_VALIDACION = {
	JALISCO: '14',
	MICHOACAN: '16',
};

export const MIUC_PRODUCTS = {
	MEJORAVIT_REPARA: 'MEJORAVIT REPARA',
	MEJORAVIT_RENUEVA: 'MEJORAVIT RENUEVA'
};