import LabelStatus, { TLabelStatus } from './label-status.component';
import PlayButton from './play-button.component';
import { useStyles } from './styles/media-card.styles';
import { Elemento } from '../../../../../interfaces/tallerSaberMas';

interface IMediaCard extends Elemento {
	onClick: (arg: Elemento) => void;
	elemento: Elemento;
}

const getEstatus = (estatus: number): TLabelStatus => {
	if (estatus === 1) {
		return 'completed';
	}
	if (estatus === 3) {
		return 'updated';
	}
	return 'pending';
};

export default function MediaCard(props: IMediaCard) {
	const styles = useStyles(props);
	return (
		<div
			className={
				props.idElemento === props.elemento?.idElemento
					? styles.cardVideoActive
					: styles.cardVideo
			}
		>
			<div className={styles.containerHeaderCard}>
				<div className={styles.containerHeaderCardInfo}>
					<label className={styles.containerHeaderCardLabelTitle}>
						{props.nombre}
					</label>
					<label className={styles.containerHeaderCardLabelSubTitle}>
						Fecha de término: {props.fechaTermino || '---'}
					</label>
				</div>
			</div>
			<div className={styles.containerHeaderCard}>
				<div className={styles.containerHeaderCardButtons}>
					<div className={styles.containerPlay}>
						<LabelStatus variantLabel={getEstatus(props.estatus)} />
						<PlayButton
							isPlaying={
								props.idElemento === props.elemento?.idElemento
							}
							isComplete={props.estatus === 1}
							onClick={() => props.onClick(props)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
