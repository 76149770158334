import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	paperCustomMore: {
		boxShadow: '0px 10px 20px #A3AEBE26',
		borderRadius: '5px',
		padding: '20px 15px 25px',
		marginBottom: '15px',
		transition: 'all .3s',
		border: '1px solid #E5E5E5',
		height: '140px',
		overflow: 'hidden',
	},
	paperCustomMoreShow: {
		boxShadow: '0px 10px 20px #A3AEBE26',
		borderRadius: '5px',
		padding: '20px 15px 25px',
		marginBottom: '15px',
		transition: 'all .3s',
		border: '1px solid #E5E5E5',
		height: 'auto',
	},
	paperCustomTitle: {
		margin: '0px 0px 10px'
	},
	paperCustomTitleBlue: {
		margin: '0px 0px 5px',
		color: '#293990'
	},
	textOpen: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		color: '#D1001F',
		gap: '5px',
		'&>svg': {
			marginTop: '-2px'
		},
	},
	seccionItem: {
		marginBottom: '-10px'
	}
}));
