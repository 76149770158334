import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: '#333333',
			padding: theme.spacing(3),
			paddingLeft: 50,
			paddingRight: 50,
			color: theme.palette.common.white,
			[theme.breakpoints.down('md')]: {
				textAlign: 'center',
			},
		},
		margin: {
			margin: `${theme.spacing(1.5)} 0`,
			[theme.breakpoints.up('md')]: {
				margin: `0  0 ${theme.spacing(1.5)} 0`,
			},
		},
		img: {
			width: 120,
		},
		row: {
			display: 'flex',
			flexDirection: 'row',
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.up('md')]: {
				flexDirection: 'row',
			},
		},
		containerSocialMediaList: {
			width: '100%',
			paddingLeft: 10,
			paddingRight: 10,
			boxSizing: 'border-box',
		},
		socialMediaList: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			[theme.breakpoints.down('md')]: {
				alignItems: 'center',
				justifyContent: 'center',
			},
		},
		socialMediaItem: {
			marginRight: '15px !important',
			display: 'flex',
			justifyContent: 'center',
			[theme.breakpoints.between(900, 1134)]: {
				marginRight: '5px !important',
			},
		},
		labelTitle: {
			fontSize: 16,
			fontWeight: '500',
		},
		labelSubTitle: {
			fontSize: 12,
		},
		labelSubTitlePhone: {
			display: 'block',
			fontSize: 17,
			color: '#fff !important',
		},
	})
);
