import API from '../';

//export const passwordRecovery = new API('/password-recovery');
export const sendMessage = new API('/send-message');
export const validCode = new API('/valid-code');
export const resetPassword = new API('/reset-password');
export const deleteAcount = new API('/delete-account');
// export const confirmRecoverPassword = new API('/valid-code-email');

interface IDataValidCheckNSS {
	nss: string;
}

class ResetPasswordAPI extends API {
	passwordRecovery(data: { nss: string }): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `password-recovery`,
				method: 'POST',
				data: data,
			})
		);
	}
	confirmRecoverPassword(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `valid-code-email`,
				method: 'POST',
				data,
			})
		);
	}
	ValidCheckNSS(data: IDataValidCheckNSS): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `valid-check-nss`,
				method: 'POST',
				data,
			})
		);
	}
	ValideDeathMark(data: IDataValidCheckNSS): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `death-mark`,
				method: 'POST',
				data,
			})
		);
	}
}

export const resetPasswordAPI = new ResetPasswordAPI('');
