import { Link } from '@mui/material';

export const arrayMeses = [
	'Ene',
	'Feb',
	'Mar',
	'Abr',
	'May',
	'Jun',
	'Jul',
	'Ago',
	'Sep',
	'Oct',
	'Nov',
	'Dic',
];

export const arrayMesesComplete = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
];

interface IMessages {
	CodePay: string;
	CodeCabeceraPrincipal: string;
	CodeDatosMicredito: string;
	CodeDemanda: string;
	Juridico: Array<number>;
	Especiales: Array<number>;
	Links: Array<{ code: string; label: string }>;
}

export const Messages: IMessages = {
	CodePay: '36|37|38|19',
	CodeCabeceraPrincipal: '13|14|06|12|15|04|01|05',
	CodeDatosMicredito: '33|35|01|04|07',
	CodeDemanda: '06|07',
	Juridico: [23, 28],
	Especiales: [21],
	Links: [
		{ code: '07', label: 'CPC' },
		{ code: '17', label: 'Mis movimientos y estado de cuenta' },
		{ code: '23', label: 'CPC' },
		{
			code: '40',
			label:
				'' +
				new Date().getDate().toString().padStart(2, '0') +
				'/' +
				(new Date().getMonth() + 1).toString().padStart(2, '0') +
				'/' +
				new Date().getFullYear(),
		},
		{
			code: '08',
			label:
				'' +
				new Date().getDate().toString().padStart(2, '0') +
				'/' +
				(new Date().getMonth() + 1).toString().padStart(2, '0') +
				'/' +
				new Date().getFullYear(),
		},
	],
};

export const formatMessage = (
	cadena: string | undefined | null,
	code: string,
	credito?: ICredito | null
) => {
	if (cadena && cadena.match('%s')) {
		if (code === '04') {
			return (
				<>
					<p style={{ margin: 0, marginBottom: 5 }}>
						El crédito tipo {credito?.v1TipoCredito} tiene la
						situación de ENTREGA DE VIVIENDA CON PODER NOTARIAL
					</p>
				</>
			);
		}
		if (code === '01' && credito?.tipoCasoEspecial?.code === 4) {
			return (
				<>
					<p style={{ margin: 0, marginBottom: 5 }}>
						Si quieres saber si tu crédito tiene pagos en exceso
						consulta la información en{' '}
						<Link href="/mi-credito/devolucion-pagos-exceso">
							Pagos en exceso
						</Link>
					</p>
					<p style={{ margin: 0 }}>
						<strong>
							El crédito {credito?.v1TipoCredito} se liquidó el
							día {formatDate(credito?.fechaLiquidacion)}
						</strong>
						{', '}a través de {credito?.v3TipoLiquidacion}.
					</p>
				</>
			);
		}
		const Message = Messages.Links.filter((msg) => msg.code === code)[0];
		if (Message) {
			const { label } = Message;
			return cadena.replace('%s', label);
		} else {
			return cadena;
		}
	} else {
		return cadena;
	}
};

export const formatDate = (cadena: string | undefined, format?: string) => {
	if (cadena) {
		if (!isNaN(Number(cadena))) {
			let anio = cadena.substring(0, 4);
			let mes = cadena.substring(4, 6);
			let dia = cadena.substring(6, cadena.length);
			if (format === 'full')
				return (
					dia + ' ' + arrayMesesComplete[Number(mes) - 1] + ' ' + anio
				);
			if (format === 'fullDay')
				return (
					dia +
					' de ' +
					arrayMesesComplete[Number(mes) - 1] +
					' del ' +
					anio
				);
			if (!format)
				return dia + ' ' + arrayMeses[Number(mes) - 1] + ' ' + anio;
			return dia + format + mes + format + anio;
		} else {
			return cadena;
		}
	} else {
		return '';
	}
};

export const formatNumber = new Intl.NumberFormat('es-MX', {
	style: 'currency',
	currency: 'MXN',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

export const formatNumero = new Intl.NumberFormat('es-MX', {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

export const fnNumberFormat = (arg?: number) => {
	if (arg) return <strong>{formatNumber.format(arg)}</strong>;
	else return '0.00';
};

export interface ICredito {
	derechohabiente?: string;
	nss?: string;
	credito?: string;
	totalCredit?: number;
	fechaLiquidacion?: string;
	v1TipoCredito?: string;
	v6FechaActualAls?: string;
	v3TipoLiquidacion?: string;
	tipoCasoCredito?: {
		key: number;
		value: string;
		wsTipoCaso: string;
	};
	tipoCasoEspecial?: {
		code: number;
		text: string;
	};
	tipoCredito?: {
		key: number;
		value: string;
	};
	pagosMensualidad?: {
		tp11MensPgoCorriente: number;
		tp12MensPgoMensualidad: number;
		tp13PesPgoCorriente: number;
		tp14PesPgoMensualidad: number;
		tp15VsmPgoCorriente: number;
		tp16VsmPgoMensualidad: number;
		tp17PgoCorriente: string;
		tp18PgoMensualidad: string;
	};
	mensualidadREA_ROA?: {
		tp35mensRea: number;
		tp36MensRoa: number;
	};
	msgs?: [
		{
			key: string;
			value: string;
		}
	];
	FPPData?: {
		tp210AbonoFppMensualVsm: number;
		tp211CorrienteFpp: string;
		tp212MensualidadFpp: string;
		tp21MensPgoCorrienteFpp: number;
		tp22MensPgoMensualidadFpp: number;
		tp23PesPgoCorrienteFpp: number;
		tp24PesPgoMensualidadFpp: number;
		tp25VsmPgoCorrienteFpp: number;
		tp26VsmPgoMensualidadFpp: number;
		tp27AbonoFppCorrientePes: number;
		tp28AbonoFppMensualPes: number;
		tp29AbonoFppCorrienteVsm: number;
	};
	opcionesPago?: {
		v11Sdoliqpes: number;
		v12Sdoliqvsm: number;
		v15PgocorrienteDiaconsulta: number;
		v16PgomensualidadDiaconsulta: number;
	};
	apoyoFlexible?: {
		tp31cveflexipago: number;
		tp32benefDispFpp: number;
		tp33MesesDispProrr: number;
		tp37IniProrr: string;
		tp38FinProrr: string;
		v10PeriodoFin: string;
		v10PeriodoIni: string;
	};
}

export interface ICreditoDetalle {
	header?: {
		montoOtorgamiento: number;
		plazo: number;
		tazaInteres: string;
		tipoCredito: string;
	};
	origen?: {
		fechaOtorgamiento: string;
		mtoOtorgaVsm: number;
		tipoMoneda: string;
		tipoTasaInteres: string;
		tipoPago: string;
		tasInteres: number;
		tipoCredito: string;
		v20MtoOtorgaPes: number;
		v25Plazo: number;
	};
	creditoActual?: {
		fechaMovimiento: string;
		tipoMoneda: string;
		tipoTasaInteres: string;
		tipoMovimiento: string;
		plazo: number;
		tazaInteres: number;
		tazaPago: string;
	};
}

export interface IMovimientos {
	Clave: string;
	Concepto: string;
	Fecha: string;
	MontoTransaccion: number;
	MontoTransaccionVSM: number;
	Origen: string;
	PagoACapital: number;
	PagoACapitalVSM: number;
	PagoAInteresVSM: number;
	PagoAIntereses: number;
	PagoAMoratorios: number;
	PagoAMoratoriosVSM: number;
	PagoASeguro: number;
	SaldoACapital: number;
	SaldoACapitalVSM: number;
}

export const MsgErrorMensual = [
	{
		code: '02',
		msg: 'No se localizó un estado de cuenta. Comunícate a Infonatel. Desde la CDMX 55 9171 5050 Desde cualquier parte del país 800 008 3900',
	},
	{
		code: '04',
		msg: 'Tu crédito ha sido cerrado. Consulta la sección Estado de Cuenta Histórico',
	},
	{
		code: '05',
		msg: 'Tu crédito presenta problemas. Comunícate a Infonatel. Desde la CDMX 559171 5050. Desde cualquier parte del país 800 008 3900',
	},
	{
		code: '06',
		msg: 'Tu crédito es de reciente creación. No contamos con datos históricos',
	},
];
