import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Grid, Paper, Divider, Box } from '@mui/material';
import {
	ICredito,
	formatNumber,
	formatDate,
} from '../../../interfaces/SaldAndMovement';
import { UseStyle } from '../Style';
import TooltipCustom from '../../../components/TooltipCustom';

interface Props {
	matches: boolean;
	credito: ICredito | null;
	formasPagos: any;
}

const MonthlyPayment = ({ matches, credito, formasPagos }: Props) => {
	const classes = UseStyle();

	return (
		<>
			{credito?.tipoCasoEspecial?.code !== 4 &&
				credito?.pagosMensualidad && (
					<>
						<Grid
							container
							columns={12}
							spacing={2}
							marginBottom={2}
						>
							<Grid item xs={12} md={3}>
								<Paper variant="outlined">
									<Box className={classes.BoxContainer}></Box>
									<Box
										padding={2}
										color={'#7F7F7F'}
										textAlign={matches ? 'center' : 'left'}
									>
										Pago para estar al corriente
										<TooltipCustom
											title="Monto de dinero que incluye la mensualidad, pagos atrasados o incompletos y seguros y cuotas."
											placement="right"
											arrow
										>
											<InfoRoundedIcon
												style={{
													fontSize: 14,
													margin: 0,
													padding: 0,
													marginLeft: 5,
													color: '#D1001F',
												}}
											/>
										</TooltipCustom>
									</Box>
								</Paper>
							</Grid>
							<Grid
								item
								xs={matches ? 6 : 12}
								md={credito?.tipoCredito?.key === 10 ? 2 : 3}
							>
								<Paper variant="outlined">
									<Box className={classes.BoxContainer}>
										Mensualidades
									</Box>
									<Box
										padding={2}
										textAlign={'center'}
										fontWeight={500}
									>
										{credito?.pagosMensualidad
											?.tp11MensPgoCorriente || 0}
									</Box>
								</Paper>
							</Grid>
							<Grid
								item
								xs={matches ? 6 : 12}
								md={credito?.tipoCredito?.key === 10 ? 2 : 3}
							>
								<Paper variant="outlined">
									<Box className={classes.BoxContainer}>
										¿Cuánto debo?
									</Box>
									<Box
										padding={2}
										textAlign={'center'}
										fontWeight={500}
									>
										{formatNumber.format(
											credito?.pagosMensualidad
												?.tp13PesPgoCorriente || 0
										)}
									</Box>
								</Paper>
							</Grid>
							{credito?.tipoCredito?.key === 10 && (
								<Grid item xs={matches ? 6 : 12} md={2}>
									<Paper variant="outlined">
										<Box className={classes.BoxContainer}>
											VSM
											<TooltipCustom
												title="Es el monto de ¿Cuánto debo? pero en Veces Salario Mínimo. El valor de VSM es actualizado cada año y entra en vigor en el mes de enero."
												placement="right"
												arrow
											>
												<InfoRoundedIcon
													style={{
														fontSize: 14,
														margin: 0,
														padding: 0,
														marginLeft: 5,
														color: '#D1001F',
													}}
												/>
											</TooltipCustom>
										</Box>
										<Box
											padding={2}
											textAlign={'center'}
											fontWeight={500}
										>
											{credito?.pagosMensualidad
												?.tp15VsmPgoCorriente || 0}
										</Box>
									</Paper>
								</Grid>
							)}
							<Grid
								item
								xs={
									matches && credito?.tipoCredito?.key === 10
										? 6
										: 12
								}
								md={3}
							>
								<Paper variant="outlined">
									<Box className={classes.BoxContainer}>
										¿Cuándo pago?
									</Box>
									<Box
										padding={2}
										textAlign={'center'}
										fontWeight={500}
									>
										{formatDate(
											credito?.pagosMensualidad
												?.tp17PgoCorriente || ''
										)}
									</Box>
								</Paper>
							</Grid>
						</Grid>

						<Grid
							container
							columns={12}
							spacing={2}
							marginBottom={2}
						>
							<Grid item xs={12} md={3}>
								<Paper variant="outlined">
									<Box className={classes.BoxContainer}></Box>
									<Box
										padding={2}
										color={'#7F7F7F'}
										textAlign={matches ? 'center' : 'left'}
									>
										Pago de mi mensualidad
										<TooltipCustom
											title="Monto de dinero para pagar el mes actual; incluye seguros y cuotas."
											placement="right"
											arrow
										>
											<InfoRoundedIcon
												style={{
													fontSize: 14,
													margin: 0,
													padding: 0,
													marginLeft: 5,
													color: '#D1001F',
												}}
											/>
										</TooltipCustom>
									</Box>
								</Paper>
							</Grid>
							<Grid
								item
								xs={matches ? 6 : 12}
								md={credito?.tipoCredito?.key === 10 ? 2 : 3}
							>
								<Paper variant="outlined">
									<Box className={classes.BoxContainer}>
										Mensualidades
									</Box>
									<Box
										padding={2}
										textAlign={'center'}
										fontWeight={500}
									>
										{credito?.pagosMensualidad
											?.tp12MensPgoMensualidad || 0}
									</Box>
								</Paper>
							</Grid>
							<Grid
								item
								xs={matches ? 6 : 12}
								md={credito?.tipoCredito?.key === 10 ? 2 : 3}
							>
								<Paper variant="outlined">
									<Box className={classes.BoxContainer}>
										¿Cuánto debo?
									</Box>
									<Box
										padding={2}
										textAlign={'center'}
										fontWeight={500}
									>
										{formatNumber.format(
											credito?.pagosMensualidad
												?.tp14PesPgoMensualidad || 0
										)}
									</Box>
								</Paper>
							</Grid>
							{credito?.tipoCredito?.key === 10 && (
								<Grid item xs={matches ? 6 : 12} md={2}>
									<Paper variant="outlined">
										<Box className={classes.BoxContainer}>
											VSM
											<TooltipCustom
												title="Es el monto de ¿Cuánto debo? pero en Veces Salario Mínimo. El valor de VSM es actualizado cada año y entra en vigor en el mes de enero."
												placement="right"
												arrow
											>
												<InfoRoundedIcon
													style={{
														fontSize: 14,
														margin: 0,
														padding: 0,
														marginLeft: 5,
														color: '#D1001F',
													}}
												/>
											</TooltipCustom>
										</Box>
										<Box
											padding={2}
											textAlign={'center'}
											fontWeight={500}
										>
											{credito?.pagosMensualidad
												?.tp16VsmPgoMensualidad || 0}
										</Box>
									</Paper>
								</Grid>
							)}
							<Grid
								item
								xs={
									matches && credito?.tipoCredito?.key === 10
										? 6
										: 12
								}
								md={3}
							>
								<Paper variant="outlined">
									<Box className={classes.BoxContainer}>
										¿Cuándo pago?
									</Box>
									<Box
										padding={2}
										textAlign={'center'}
										fontWeight={500}
									>
										{formatDate(
											credito?.pagosMensualidad
												?.tp18PgoMensualidad || ''
										)}
									</Box>
								</Paper>
							</Grid>
						</Grid>

						<Grid
							container
							columns={12}
							spacing={2}
							marginBottom={4}
						>
							<Grid item xs={12} md={3}>
								<Paper variant="outlined">
									<Box className={classes.BoxContainer}></Box>
									<Box
										padding={2}
										color={'#7F7F7F'}
										textAlign={matches ? 'center' : 'left'}
									>
										Forma de pago
										<TooltipCustom
											title="Medio por el cual el acreditado hace sus pagos. Sin relación laboral lo puede hacer en bancos y establecimientos autorizados por el Infonavit. Cuando sí hay una relación de trabajo formal, el patrón hace la retención y la entrega al Instituto."
											placement="right"
											arrow
										>
											<InfoRoundedIcon
												style={{
													fontSize: 14,
													margin: 0,
													padding: 0,
													marginLeft: 5,
													color: '#D1001F',
												}}
											/>
										</TooltipCustom>
									</Box>
								</Paper>
							</Grid>
							<Grid item xs={12} md={9}>
								<Paper variant="outlined">
									<Box className={classes.BoxContainer}></Box>
									<Box
										padding={2}
										textAlign={'center'}
										fontWeight={500}
									>
										{formasPagos.length > 0
											? formasPagos[0].value
											: 'Si vas a liquidar tu crédito, paga directamente en cualquier banco autorizado.'}
									</Box>
								</Paper>
							</Grid>
						</Grid>
					</>
				)}

			{credito?.mensualidadREA_ROA && (
				<>
					<Paper
						variant="outlined"
						style={{
							padding: '30px 20px',
							margin: matches ? '10px 0px' : '20px 0px',
							textAlign: 'center',
						}}
					>
						<h4 style={{ margin: '10px 0px' }}>
							Mensualidad REA y ROA
						</h4>
						<p style={{ marginBottom: 30 }}>
							Esta es la cantidad que debes pagar para cubrir tus
							mensualidades y mantener tu crédito al corriente: y
							la información de Si pagas por tu cuenta (REA)
						</p>
						<Divider style={{ marginBottom: 20 }} />
						<Grid
							container
							columns={12}
							style={{ textAlign: 'left' }}
						>
							<Grid item xs={12} md={6}>
								Si pagas por tu cuenta (REA)
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								style={{
									textAlign: matches ? 'left' : 'right',
									fontWeight: 500,
								}}
							>
								{formatNumber.format(
									credito?.mensualidadREA_ROA
										? credito?.mensualidadREA_ROA
												.tp35mensRea
										: 0
								)}
							</Grid>
							<Grid
								item
								xs={12}
								md={12}
								style={{ margin: '20px 0px' }}
							>
								<Divider />
							</Grid>
							<Grid item xs={12} md={6}>
								Si el descuento es por nomina (ROA)
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								style={{
									textAlign: matches ? 'left' : 'right',
									fontWeight: 500,
								}}
							>
								{formatNumber.format(
									credito?.mensualidadREA_ROA
										? credito?.mensualidadREA_ROA
												.tp36MensRoa
										: 0
								)}
							</Grid>
							<Grid
								item
								xs={12}
								md={12}
								style={{ margin: '20px 0px' }}
							>
								<Divider />
							</Grid>
							<Grid item xs={12} md={12}>
								<h4 style={{ textAlign: 'center' }}>
									Pagos parciales
								</h4>
								<p
									style={{
										margin: 0,
										color: '#293990',
										fontWeight: 700,
									}}
								>
									¿Qué son y cómo se aplican los pagos
									parciales?
								</p>
								<p>
									Los pagos parciales son aquellos pagos que
									puedes hacer durante el mes, para completar
									poco a poco la cantidad que se observa en el
									renglón <b>de “Pago por tu cuenta REA”</b>{' '}
									en <b>la sección Mensualidades REA y ROA</b>
									, considerando como fecha límite para
									completarla antes del último día del mes.
									<br />
									<br />
									En caso de que con las parcialidades que
									realices antes del último día del mes, no
									cubras el total indicado en “Pago por tu
									cuenta REA”, los pagos hechos, se aplicarán
									al saldo de tu crédito, no se tomará en
									cuenta como pago efectivo del mes efectivo.
									<br />
									<br />
									Si antes del último día del mes terminas de
									cubrir el monto total de “Pago por tu cuenta
									REA” y continúas realizando pagos, lo que
									pagues adicional, se aplicará al saldo de
									crédito.
								</p>
							</Grid>
						</Grid>
					</Paper>
				</>
			)}
		</>
	);
};

export default MonthlyPayment;
