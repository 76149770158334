import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	containerCardSM: {
		height: 170,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		position: 'relative',
	},
	containerCard: {
		height: 170,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		position: 'relative',

		'&:hover': {
			borderWidth: 2,
			borderStyle: 'solid',
			transform: 'scale(1.1)',
			borderColor: (props: { color: string }) => props.color,
			transition: 'transform 0.2s, borderColor 0.1s',
		},
	},
	containerActiveCard: {
		transform: 'scale(1.1)',
		cursor: 'pointer',
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: (props: { color: string }) => props.color,

		[theme.breakpoints.down('md')]: {
			transform: 'none',
		},
	},
	containerBackground: {
		display: 'flex',
		flexDirection: 'row',
		position: 'relative',
		top: 10,
		width: '100%',
		height: '50%',
		backgroundColor: (props: { color: string }) => props.color,

		[theme.breakpoints.down('md')]: {
			top: 8,
			height: '50px',
		},
	},
	containerImg: {
		width: '40%',
		height: '100%',
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',

		'& img': {
			width: '100%',
			maxWidth: '100px',
			position: 'absolute',
			bottom: 0,

			[theme.breakpoints.down('md')]: {
				maxWidth: '55px',
			},
		},

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	containerLabels: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		paddingTop: 5,
		paddingBottom: 5,
		width: '60%',
		height: '100%',
		cursor: 'pointer',

		[theme.breakpoints.down('md')]: {
			width: '100%',
			paddingTop: 20,
			justifyContent: 'normal',
			paddingBottom: 0,
			height: '80px',
		},
	},
	labelTitle: {
		color: '#FFFFFF',
		lineHeight: 1.2,
		fontWeight: '500',
		fontSize: 18,
		cursor: 'pointer',

		[theme.breakpoints.down('md')]: {
			color: '#000',
			fontSize: 13,
			textAlign: 'center',
			padding: '0px 3px 0px 3px',
		},
	},
	labelSubtitle: {
		color: '#FFFFFF',
		fontSize: 15,
		fontWeight: '400',
		cursor: 'pointer',

		[theme.breakpoints.down('md')]: {
			color: '#000',
			fontSize: 12,
			textAlign: 'center',
		},
	},
	labelFooter: {
		position: 'absolute',
		top: 142,
		fontSize: 14,
		fontWeight: 600,
		color: (props: { color: string }) => props.color,

		[theme.breakpoints.down('md')]: {
			display: 'block',
			position: 'relative',
			top: 0,
			fontSize: 11,
			textAlign: 'center',
			height: '10px',
		},
	},
	startIconAbsolute: {
		position: 'absolute',
		right: 0,
		top: 0,
		width: '100%',
		height: 'auto',
		display: 'flex',
		justifyContent: 'end',

		'& div': {
			background: (props: { color: string }) => props.color,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'baseline',
			width: '30px',
			height: '30px',
			position: 'relative',
			borderRadius: 0,
			marginRight: 15,

			'&:before': {
				content: '""',
				position: 'absolute',
				top: 23,
				left: 0,
				borderWidth: '0 15px 8px 15px',
				borderStyle: 'solid',
				borderColor: 'transparent transparent #fff transparent',
			},

			[theme.breakpoints.down('md')]: {
				background: '#fff !important',
				marginRight: 0,
				height: '18px',
			},
		},
	},
}));
