import React, { Fragment } from 'react';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
} from '@mui/material';
import { useStyles } from './styles';

export interface IRadioButton {
	id?: string;
	name: string;
	label?: string | React.ReactChild;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	options: any;
	row?: boolean;
	required?: boolean;
}

export const CheckBoxInputs = ({
	label = '',
	name = '',
	onChange,
	options = [],
	row = false,
	required = false,
}: IRadioButton) => {
	const classes = useStyles();
	return (
		<FormControl className={classes.containerFull}>
			<FormLabel>
				<span className={classes.labelContainerInput}>
					{label}
					{required && (
						<span className={classes.labelRequired}>*</span>
					)}
				</span>
			</FormLabel>
			<FormGroup
				row={row} // DIRECTION ROW (HORIZONTAL)
				className={classes.optionsContainer}
			>
				{options.map((option: any) => (
					<Fragment key={option.value}>
						<FormControlLabel
							control={<Checkbox name={option.value} onChange={onChange} />}
							label={option.label}
							disabled={option.diabled}
							labelPlacement={option.labelPos}
						/>
						{option?.container ? option.container : ''}
					</Fragment>
				))}
			</FormGroup>
		</FormControl>
	);
};
