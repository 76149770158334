export interface IDisableProduct {
    product: string;
    disable: boolean;
}

export interface IProductsAmount {
    producto: string;
    [PRODUCT_KEYS.monto]?: string;
    [PRODUCT_KEYS.montoConyuge]?: string;
    [PRODUCT_KEYS.mmcMonSuelo]?: string;
    [PRODUCT_KEYS.montoConstruccion]?: string;
}

export interface ILoadProductData {
    plazos: IPlazos;
    products: IProductsAmount[]
}

export interface IInputData {
    product: string;
    value: string;
    key: PRODUCT_KEYS;
    onChange: (value: string) => void;
}

export enum PRODUCT_KEYS {
    monto = 'monto',
    montoConyuge = 'montoConyuge',
    mmcMonSuelo = 'mmcMonSuelo',
    montoConstruccion = 'montoConstruccion',
}

export interface IMontosSolicitados {
    montoTitular: string;
    montoConyuge: string;
}

export interface IPlazos {
    plazo: string;
    plazoCny?: string;
};