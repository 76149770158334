import { Radio } from '@mui/material';
import { mappedEstatus } from '../../MyComplaints/utils';

export const columns = (selected: any, setSelected: any) => [
	{
		column: 'caso',
		label: 'Selecciona',
		renderValue: (value: any, i: number, cArray: any) => (
			<Radio
				checked={selected === value}
				onChange={() => setSelected(cArray[i])}
			/>
		),
	},
	{ column: 'caso', label: 'Número de caso', sort: true },
	{
		column: 'fechaCreacion',
		label: 'Fecha de solicitud',
		renderValue: (value: string) => {
			let day = value.substring(6);
			let month = value.substring(4, 6);
			let year = value.substring(0, 4);

			return `${day}/${month}/${year}`;
		},
	},
	{
		column: 'fechaModificacion',
		label: 'Fecha de atención',
		renderValue: (value: string) => {
			let day = value.substring(6, 8);
			let month = value.substring(4, 6);
			let year = value.substring(0, 4);

			return `${day}/${month}/${year}`;
		},
	},
	{
		column: 'estatus',
		label: 'Estatus',
		sort: true,
		style: { fontWeight: 500, minWidth: 180 },
		renderValue: (value: any) => (
			<span
				style={{
					fontWeight: 500,
					color: mappedEstatus(value).color,
				}}
			>
				{mappedEstatus(value).descripcion}
			</span>
		),
	},
];
