import React from 'react';
import { Paper, Typography } from '@mui/material';
import { useStyles } from './styles';
import Layout from '../../components/Layout';
//Assets
import imgNotFound from '../../assets/img/infonavit-not-found.png';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/Button';

const NotFound = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	return (
		<Layout>
			<Paper elevation={1} className={classes.contenedor}>
				<div className={classes.cont1}>
					<div className={classes.contButtom}>
						<div className={classes.container}>
							<Typography variant="subtitle2" sx={{ fontSize: 18 }}>
								Mi Cuenta Infonavit
							</Typography>
							<div className={classes.notFoundImage}>
								<img
									className={classes.image}
									src={imgNotFound}
									alt="No encontrado"
								></img>
							</div>
							<Typography
								variant="subtitle2"
								sx={{ color: 'secondary.light', fontSize: 18 }}
							>
								Página no encontrada
							</Typography>
							<br />
							<Typography variant="subtitle2">
								Recuerda que únicamente podrás ingresar si ya estás
								registrado
							</Typography>
							<br />
							<Typography variant="body2">
								Antes de solicitar tu crédito, es importante que realices
								el curso Saber más para decidir mejor, ya que te orientará
								sobre el uso que le puedes dar a tu ahorro, las diferentes
								opciones de crédito que el Infonavit te ofrece u los
								elementos a considerar para que elijas la mejor solución
								que te permita construir un patrimonio familiar.
							</Typography>
							<br />
							<CustomButton
								label="Regresar"
								variant="solid"
								styles={{ width: 240, margin: '0 auto' }}
								onClick={() => {
									navigate('/inicio');
								}}
							/>
						</div>
					</div>
				</div>
			</Paper>
		</Layout>
	);
};

export default NotFound;
