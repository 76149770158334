import API from '..';

class SharedResponsabilityAPI extends API {
	elegibility(credito: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/responsabilidad-compartida/elegibilidad`,
				method: 'POST',
				data: { credito },
			})
		);
	}
	consultCase(nss: string | undefined): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/consult-case-responsibility`,
				method: 'POST',
				data: { nss },
			})
		);
	}
	responsabilityCase(caso: string | undefined): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/responsabilidad-compartida/descargar-archivos`,
				method: 'POST',
				data: { caso },
			})
		);
	}
	amortizationTable(
		tipoOperacion: string | undefined,
		credito: string | undefined,
		grupo: string | undefined
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/responsibility-amortizacion`,
				method: 'POST',
				data: { tipoOperacion, credito, grupo },
			})
		);
	}
	consultAmortization(
		tipoOperacion: string | number,
		credito: string | undefined,
		grupo: string
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/responsibility-amortizacion`,
				method: 'POST',
				data: { tipoOperacion, credito, grupo },
			})
		);
	}
	rejectionCatalog(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `responsabilidad-compartida/catalogo-motivos-rechazo`,
				method: 'POST',
			})
		);
	}
	statesCatalog(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `responsabilidad-compartida/catalogo-estados`,
				method: 'POST',
			})
		);
	}
	createCase(
		nss: string,
		credito: string,
		opcionAceptada: string,
		quita: string,
		saldoPrevio: string,
		marcaReestructura: string,
		reaVsm: string,
		reaPesos: string,
		roaVsm: string,
		roaPesos: string,
		intereses: string,
		plazo: string,
		porcentajeDescuento: string,
		estado: string,
		cp: string
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/create-case-responsibility`,
				method: 'POST',
				data: {
					nss,
					credito,
					opcionAceptada,
					quita,
					saldoPrevio,
					marcaReestructura,
					reaVsm,
					reaPesos,
					roaVsm,
					roaPesos,
					intereses,
					plazo,
					porcentajeDescuento,
					estado,
					cp,
				},
			})
		);
	}
	rejectCase(
		nss: string | undefined,
		credito: string,
		opcionAceptada: string,
		quita: string,
		saldoPrevio: string,
		marcaReestructura: string,
		reaVsm: string,
		reaPesos: string,
		roaVsm: string,
		roaPesos: string,
		intereses: string,
		plazo: string,
		porcentajeDescuento: string,
		estado: string,
		cp: string,
		motivoRechazo: string,
		notas: string,
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/rejection-case-responsibility`,
				method: 'POST',
				data: {
					nss,
					credito,
					opcionAceptada,
					quita,
					saldoPrevio,
					marcaReestructura,
					reaVsm,
					reaPesos,
					roaVsm,
					roaPesos,
					intereses,
					plazo,
					porcentajeDescuento,
					estado,
					cp,
					motivoRechazo,
					notas,
				},
			})
		);
	}
}

export const sharedResponsabilityAPI = new SharedResponsabilityAPI('');
