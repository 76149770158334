import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			color: '#283990',
			textAlign: 'center',
			fontSize: 26,
			fontWeight: 500,
		},
		subtitle: {
			textAlign: 'center',
			fontSize: 18,
			fontWeight: 500,
		},
		buttons: {
			width: '100%',
			maxWidth: '600px',
			margin: '40px auto 0',
			display: 'flex',
			justifyContent: 'space-around',
			[theme.breakpoints.down('md')]: {
				display: 'block',
			},
		},
		form: {
			width: '100%',
			maxWidth: '1000px',
			margin: '0 auto',
		},
		containerFiles: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		containerFilesOne: {
			width: '40%',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				textAlign: 'center',
			},
		},
		containerFilesTwo: {
			paddingTop: '2px',
			width: '30%',
			paddingRight: '20px',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				textAlign: 'center',
				margin: '30px auto',
			},
		},
		containerFilesThree: {
			width: '30%',
			textAlign: 'right',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				textAlign: 'center',
			},
		},
		textFile: {
			color: '#7F8487',
			margin: '0 0 10px',
			lineHeight: '18px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		p: {
			color: '#7F8487',
			margin: '0 0 10px',
			lineHeight: '18px',
		},
		message: {
			textAlign: 'center',
			margin: '40px auto',
		},
		hr: {
			opacity: 0.4,
			marginLeft: 15,
			marginRight: 20,
			marginBottom: 0,
		},
	})
);
