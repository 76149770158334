import { Dialog, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/Button';
import { IStep } from './utils';

interface IProps {
	step: IStep;
	setStep: (arg: IStep) => void;
}

const FinishDownload = ({ step, setStep }: IProps) => {
	const navigate = useNavigate();

	return (
		<Dialog open={step.nextStep === 2} fullWidth maxWidth="md">
			<Box
				padding={2}
				width={'100%'}
				maxWidth={650}
				margin={'auto'}
				textAlign={'center'}
			>
				<p style={{ fontWeight: 600, margin: '50px 0px' }}>{step.msg}</p>

				<Box width={'100%'} maxWidth={300} margin={'30px auto'}>
					<CustomButton
						label={'Finalizar'}
						onClick={() => navigate('/')}
						variant={'solid'}
					/>
				</Box>
			</Box>
		</Dialog>
	);
};

export default FinishDownload;
