import {
	Construction,
	Info,
	Home,
	Notifications,
	ShoppingCart,
} from '@mui/icons-material';
import { Badge, Link } from '@mui/material';
import { green, red } from '@mui/material/colors';
import TooltipCustom from '../../../components/TooltipCustom';
import NotaryDetailColumnFormat from '../components/NotaryDetailColumnFormat';
import CreditoInfonavitImg from '../../../assets/img/productsMIUC/logo-creditoinfonavit.png';
import InfonavitTotalImg from '../../../assets/img/productsMIUC/infonavit_total.png';
import CofinavitImg from '../../../assets/img/productsMIUC/cofinavit.png';
import CofinavitIngresosAdicionalesImg from '../../../assets/img/productsMIUC/cofinavit_ingresos_adicionales.png';
import MejoravitImg from '../../../assets/img/productsMIUC/mejoravit.png';
import Tu2doCreditoImg from '../../../assets/img/productsMIUC/tu_2do_credito.png';
import ApoyoInfonavitImg from '../../../assets/img/productsMIUC/logo-apoyo.png';
import CreditoSeguroImg from '../../../assets/img/productsMIUC/logo-credito-seguro.png';
import HelpPanel from '../../../components/HelpPanel';
//import AyudaIcon from '../../../assets/svg/ayuda.svg';
import YatengoIcon from '../../../assets/svg/ya-tengo.svg';
import CasitaIcon from '../../../assets/img/productsMIUC/casita.png';
import CasitaBlackIcon from '../../../assets/img/productsMIUC/casita_black.png';
import AmpliarIcon from '../../../assets/img/productsMIUC/ampliar.png';
import AmpliarBlackIcon from '../../../assets/img/productsMIUC/ampliar_black.png';
import HipotecaIcon from '../../../assets/img/productsMIUC/hipoteca.png';
import HipotecaBlackIcon from '../../../assets/img/productsMIUC/hipoteca_black.png';
import ConstruyoIcon from '../../../assets/img/productsMIUC/construyo.png';
import ReparacionMenoresIcon from '../../../assets/img/productsMIUC/reparacion_menores.png';
import AsistenciaTecnicaIcon from '../../../assets/img/productsMIUC/asistencia_tecnica.png';
import ConstruccionIcon from '../../../assets/img/productsMIUC/construccion.png';
import IndividualIcon from '../../../assets/img/productsMIUC/individual.svg';
import ConyugarIcon from '../../../assets/img/productsMIUC/conyugal.svg';
import FamiliarIcon from '../../../assets/img/productsMIUC/familiar.svg';
import ConstruirIcon from '../../../assets/svg/construir-vivienda.svg';

import CreditoSeguroIcon from '../../../assets/svg/crediseguro.svg';
import Casita2Icon from '../../../assets/svg/casita2.svg';
import MejorasiIcon from '../../../assets/img/productsMIUC/MejOraSi.png';
import mejoravitRepara from '../../../assets/img/productsMIUC/Mejoravit_REPARA_2023.png';
import mejoravitRenueva from '../../../assets/img/productsMIUC/Mejoravit_RENUEVA_2023.png';
import construYoText from '../../../assets/img/productsMIUC/construYoText.png';

import theme from '../../../config/theme';
import { DESTINOS } from '../../../config/miucConstants';

export const complements = [
	{
		description: 'Hipoteca verde',
		Icon: Notifications,
	},
	{
		description: 'Hogar a tu medida',
		Icon: ShoppingCart,
	},
	{
		description: 'Hipoteca con servicios',
		Icon: Construction,
	},
];
export const creditTypes: {
	[key: string]: { title: string; image: string; tooltip?: JSX.Element };
} = {
	CI: {
		title: 'Crédito Infonavit',
		image: CreditoInfonavitImg,
		tooltip: (
			<div style={{ marginLeft: 14 }}>
				<Badge
					badgeContent={
						<HelpPanel
							type="AVIABLE_CREDITS"
							dataAviable={{
								title: 'Crédito Infonavit',
								image: CreditoInfonavitImg,
								description:
									'Es el crédito tradicional hipotecario que otorga el Infonavit.',
								// 'En el resultado de tus condiciones financieras será determinado el tipo de garantía del crédito.',
							}}
						/>
					}
				/>
			</div>
		),
	},
	IT: {
		title: 'Infonavit Total',
		image: InfonavitTotalImg,
		tooltip: (
			<div style={{ marginLeft: 14 }}>
				<Badge
					badgeContent={
						<HelpPanel
							type="AVIABLE_CREDITS"
							dataAviable={{
								title: 'Infonavit Total',
								image: InfonavitTotalImg,
								description:
									'Es un crédito hipotecario que otorga el Infonavit.',
									// 'El crédito lo otorgan el Infonavit y una entidad financiera para que tengas un monto mayor.',
							}}
						/>
					}
				/>
			</div>
		),
	},
	COF: {
		title: 'Cofinavit',
		image: CofinavitImg,
		tooltip: (
			<div style={{ marginLeft: 14 }}>
				<Badge
					badgeContent={
						<HelpPanel
							type="AVIABLE_CREDITS"
							dataAviable={{
								title: 'Cofinavit',
								image: CofinavitImg,
								description:
									'El crédito lo otorgan el Infonavit y una entidad financiera para que tengas un monto mayor.',
							}}
						/>
					}
				/>
			</div>
		),
	},
	COFIA: {
		title: 'Cofinavit ingresos adicionales',
		image: CofinavitIngresosAdicionalesImg,
		tooltip: (
			<div style={{ marginLeft: 14 }}>
				<Badge
					badgeContent={
						<HelpPanel
							type="AVIABLE_CREDITS"
							dataAviable={{
								title: 'Cofinavit ingresos adicionales',
								image: CofinavitIngresosAdicionalesImg,
								description:
									'El crédito lo otorgan el Infonavit y una entidad financiera, ante la cuál puedes comprobar ingresos adicionales como propinas o comisiones, para que tengas un monto mayor.',
							}}
						/>
					}
				/>
			</div>
		),
	},
	MVIT: {
		title: 'Mejoravit',
		image: MejoravitImg,
		tooltip: (
			<div style={{ marginLeft: 14 }}>
				<Badge
					badgeContent={
						<HelpPanel
							type="AVIABLE_CREDITS"
							dataAviable={{
								title: 'Mejoravit',
								image: MejoravitImg,
								description:
									'Este crédito lo otorga una entidad financiera y por lo tanto mantendrás tu derecho al crédito hipotecario del Infonavit.',
							}}
						/>
					}
				/>
			</div>
		),
	},
	CII: {
		title: 'Tu 2do Crédito',
		image: Tu2doCreditoImg,
		tooltip: (
			<div style={{ marginLeft: 14 }}>
				<Badge
					badgeContent={
						<HelpPanel
							type="AVIABLE_CREDITS"
							dataAviable={{
								title: 'Tu 2do Crédito',
								image: Tu2doCreditoImg,
								description:
									'Es para trabajadores que ya utilizaron su Crédito Infonavit y es otorgado por el Infonavit y una entidad financiera para tener un monto mayor.',
							}}
						/>
					}
				/>
			</div>
		),
	},
	AI: {
		title: 'Apoyo Infonavit',
		image: ApoyoInfonavitImg,
		tooltip: (
			<div style={{ marginLeft: 14 }}>
				<Badge
					badgeContent={
						<HelpPanel
							type="AVIABLE_CREDITS"
							dataAviable={{
								title: 'Apoyo Infonavit',
								image: ApoyoInfonavitImg,
								description: 'Apoyo Infonavit',
							}}
						/>
					}
				/>
			</div>
		),
	},
	CS: {
		title: 'Crédito seguro',
		image: CreditoSeguroImg,
		tooltip: (
			<div style={{ marginLeft: 14 }}>
				<Badge
					badgeContent={
						<HelpPanel
							type="AVIABLE_CREDITS"
							dataAviable={{
								title: 'Crédito Seguro',
								image: CreditoSeguroImg,
								description:
									'Es un programa en conjunto con el Banco del Bienestar, con el que podrás ahorrar de manera mensual una cantidad de dinero y al terminar podrás inscribir tu solicitud para obtener un crédito Infonavit; el monto que ahorres se sumará a tu crédito Infonavit.',
							}}
						/>
					}
				/>
			</div>
		),
	},
	MD: {
		title: 'mejOraSí',
		image: MejorasiIcon,
	},
	MREP: {
		title: 'Mejoravit Repara',
		image: mejoravitRepara,
	},
	MREN: {
		title: 'Mejoravit Renueva',
		image: mejoravitRenueva,
	},
	L6: {
		title: 'ConstruYO',
		image: construYoText,
	}
};
export const rowsTitle = [
	{
		name: 'Retención mensual',
		title: 'Retención mensual',
		description:
			'Cantidad que tu patrón descontará vía nómina para el pago de tu crédito. En Cofinavit, si pierdes tu relación laboral, deberás pagar este monto más la aportación patronal (5% de tu salario).',
		bgGrayActive: true,
	},
	{
		name: 'Monto de crédito',
		title: 'Monto de crédito',
		description: 'Cantidad que te presta el Infonavit.',
	},
	{
		name: 'Más tu ahorro',
		title: 'Más tu ahorro',
		description:
			'Cantidad que tienes en la Subcuenta de Vivienda conformada por las aportaciones de tu patrón.',
	},
	{
		name: 'Más monto de crédito Equipa tu casa',
		title: 'Más monto de crédito Equipa tu casa',
		description:
			'Monto adicional de crédito para mejorar tu vivienda que se otorga en caso de que el valor de la vivienda sea menor al monto máximo de crédito.',
	},
	{
		name: 'Monto de crédito para uso en comercios',
		title: 'Monto de crédito para uso en comercios',
		description:
			'Monto para consumo en los comercios afiliados al Infonavit.',
	},
	{
		name: 'Monto de crédito para mano de obra',
		title: 'Monto de crédito para mano de obra',
		description:
			'Monto para pagar mano de obra, será del  20% del importe que se te otorgó para Equipa tu casa.',
	},
	{
		name: 'Menos gastos',
		title: 'Menos gastos',
		description: 'Gastos de titulación, financieros y de operación.',
	},
	{
		name: 'Contarías con',
		title: 'Contarías con',
		description:
			'Cantidad que resulta de sumar el monto de crédito más tu ahorro, restándole los gastos.',
	},
	{
		name: 'Diferencia a cubrir',
		title: 'Diferencia a cubrir',
		description:
			'Cantidad a cubrir con tus propios recursos en caso de que el valor de la vivienda supere el monto con el que contarías.',
	},
	{
		name: 'Tabla de amortización',
		title: 'Tabla de amortización',
		description:
			'Documento que muestra el detalle de amortización de tu crédito.',
	},
	{
		name: 'Complementos',
		title: (
			<>
				Complementos <HelpPanel type="INFORMACION_COMPLEMENTOS" />{' '}
			</>
		),
	},
	{
		name: 'Monto máximo para ecotecnologias',
		title: (
			<>
				Monto máximo para ecotecnologias{' '}
				<HelpPanel type="ECOTECNOLOGIAS" />
			</>
		),
	},
];
export const rowsTitleSeguro = [
	{
		name: 'Retención mensual de tu salario',
		description:
			'Cantidad que tu patrón descontará vía nómina para el pago de tu crédito en Cofinavit, si tu pierdes tu relación laboral, deberás pagar este monto más la aportación patronal (5% de tu salario).',
		bgGrayActive: true,
	},
	{
		name: 'Monto de crédito',
		description: 'Cantidad que te presta el Infonavit.',
	},
	{
		name: 'Más el ahorro que harás en Banco del Bienestar',
		description:
			'Cantidad que deberás tener ahorrada al momento de solicitar tu crédito.',
	},
	{
		name: 'Más tu ahorro',
		description:
			'Cantidad que tienes en la Subcuenta de Vivienda conformada por las aportaciones de tu patrón.',
	},
	{
		name: 'Menos gastos',
		description: 'Gastos de titulación, financieros y de operación.',
	},
	{
		name: 'Contarías con',
		description:
			'Cantidad que resulta de sumar el monto de crédito más tu ahorro, restándole los gastos.',
	},
	{
		name: 'Monto máximo para ecotecnologias',
		tooltip: <HelpPanel type="ECOTECNOLOGIAS" />,
	},
];
export const rowsTitleCrediterreno = [
	{
		name: 'Retención mensual de tu salario',
		description:
			'Cantidad que tu patrón descontará vía nómina para el pago de tu crédito. En Cofinavit, si pierdes tu relación laboral, deberás pagar este monto más la aportación patronal (5% de tu salario).',
		bgGrayActive: true,
	},
	{
		name: 'Monto de crédito',
		description: 'Cantidad que te presta el Infonavit.',
	},
	{
		name: 'Más tu ahorro',
		description:
			'Cantidad que tienes en la Subcuenta de Vivienda conformada por las aportaciones de tu patrón.',
	},
	{
		name: 'Más tu enganche',
		description:
			'Es el monto de los recursos propios con los que cuentas para la compra de tu terreno, este será uno de los factores que determinarán las condiciones financieras.',
	},
	{
		name: 'Menos gastos',
		description: 'Gastos de titulación, financieros y de operación.',
	},
	{
		name: 'Contarías con',
		description:
			'Cantidad que resulta de sumar el monto de crédito más tu ahorro, restándole los gastos.',
	},
	{
		name: 'Plazo',
		description:
			'Periodo máximo en el que se deberá pagar el crédito e intereses.',
	},
	{
		name: 'Tabla de amortización',
		description:
			'Documento que muestra el detalle de amortización de tu crédito.',
	},
];
export const rowsTitleAmpliar = [
	{
		name: 'Retención mensual de tu salario',
		description:
			'Cantidad que tu patrón descontará vía nómina para el pago de tu crédito.',
		bgGrayActive: true,
	},
	{
		name: 'Monto de crédito',
		description: 'Cantidad que te presta el Infonavit.',
	},
	{
		name: 'Tasa de interés',
		description:
			'Porcentaje a pagar por el monto de crédito otorgado durante el plazo seleccionado.',
	},
	{
		name: 'Plazo',
		description:
			'Periodo máximo en el que se deberá pagar el crédito e intereses.',
	},
	{
		name: 'Garantía del crédito',
		description:
			'Monto del saldo de tu subcuenta de vivienda que quedará como respaldo del crédito en caso de pérdida de relación laboral hasta por 12 mensualidades.',
	},
	{
		name: 'Tabla de amortización',
		description:
			'Documento que muestra el detalle de amortización de tu crédito.',
	},
];
export const rowsTitleMejoraTuHogar = [
	{
		name: 'Retención mensual de tu salario',
		description:
			'Cantidad que tu empleador (patrón) descontará de tu nómina para el pago de la mensualidad de tu crédito.',
		bgGrayActive: true,
	},
	{
		name: 'Monto de crédito',
		description: 'Cantidad que te presta el Infonavit.',
	},
	{
		name: 'Monto de crédito para uso en comercios',
		description:
			'Cantidad destinada para compra de productos autorizados únicamente en comercios afiliados al Infonavit.',
	},
	{
		name: 'Monto de crédito para mano de obra',
		description:
			'Cantidad destinada para pago de mano de obra que puedes retirar en efectivo, determinada al momento de contratar tu crédito.',
	},
	{
		name: 'Monto de crédito para regularización de vivienda*',
		description:
			'Cantidad de apoyo para que realices la titulación de tu vivienda.',
	},
	{
		name: 'Tasa de interés',
		description:
			'Porcentaje a pagar por el monto de crédito otorgado durante el plazo seleccionado.',
	},
	{
		name: 'Plazo',
		description:
			'Periodo máximo en el que se deberá pagar el crédito incluyendo sus intereses.',
	},
	{
		name: 'Tabla de amortización',
		description:
			'Documento que muestra el detalle del comportamiento de tu crédito a través del tiempo.',
	},
];
export const rowsTitleHipoteca = [
	{
		name: 'Retención mensual de tu salario',
		description:
			'Cantidad que tu patrón descontará vía nómina para el pago de tu crédito.',
		bgGrayActive: true,
	},
	{
		name: 'Monto de crédito',
		description: 'Cantidad que te presta el Infonavit.',
	},
	{
		name: 'Más tu ahorro',
		description:
			'Cantidad que tienes en la Subcuenta de Vivienda conformada por las aportaciones de tu patrón.',
	},
	{
		name: 'Menos gastos',
		description: 'Gastos de titulación, financieros y de operación.',
	},
	{
		name: 'Contarías con',
		description:
			'Cantidad que resulta de sumar el monto de crédito más tu ahorro, restándole los gastos.',
	},
	{
		name: 'Ambos contarían con',
		description:
			'Cantidad que resulta de sumar el monto de crédito más tu ahorro del titular y cónyuge, restándole los gastos.',
	},
	{
		name: 'Diferencia a cubrir',
		description:
			'Cantidad a cubrir con tus propios recursos en caso de que el valor de la vivienda supere el monto con el que contarías.',
	},
	{
		name: 'Tasa de interés',
		description:
			'Porcentaje a pagar por el monto de crédito otorgado durante el plazo seleccionado.',
	},
	{
		name: 'Plazo',
		description:
			'Plazo máximo en el que se deberá pagar el crédito mas intereses.',
	},
	{
		name: 'Garantía del crédito',
		description: 'Puede ser Hipotecaria o Saldo de Subcuenta de vivienda.',
	},
	{
		name: 'Tabla de amortización',
		description:
			'Documento que muestra el detalle de amortización de tu crédito.',
	},
];
export const rowsTitleConstruir = [
	{
		name: 'Retención mensual de tu salario',
		description:
			'Cantidad que tu patrón descontará vía nómina para el pago de tu crédito.',
		bgGrayActive: true,
	},
	{
		name: 'Monto de crédito',
		description: 'Cantidad que te presta el Infonavit.',
	},
	{
		name: 'Más tu ahorro',
		description:
			'Cantidad que tienes en la Subcuenta de Vivienda conformada por las aportaciones de tu patrón.',
	},
	{
		name: 'Menos gastos',
		description: 'Gastos de titulación, financieros y de operación.',
	},
	{
		name: 'Contarías con',
		description:
			'Cantidad que resulta de sumar el monto de crédito más tu ahorro, restándole los gastos.',
	},
	{
		name: 'Diferencia a cubrir',
		description:
			'Cantidad a cubrir con tus propios recursos en caso de que el valor de la vivienda supere el monto con el que contarías.',
	},
	{
		name: 'Tabla de amortización',
		description:
			'Documento que muestra el detalle de amortización de tu crédito.',
	},
];
export const rowsTitleAutoProduccion = [
	{
		name: 'Retención mensual de tu salario',
		label: 'Retención mensual de tu salario',
		description:
			'Cantidad que tu patrón descontará de tu salario para el pago del crédito o la que deberás pagar directamente si pierdes tu relación laboral.',
		bgGrayActive: true,
	},
	{
		name: 'Monto de crédito',
		label: 'Monto de crédito',
		description: 'Cantidad que te presta el Infonavit.',
	},
	{
		name: 'Tasa de interés',
		label: 'Tasa de interés',
		description: 'Es el porcentaje que debés pagar por el crédito.',
	},
	{
		name: 'Plazo estimado en meses',
		label: (
			<>
				Plazo estimado en meses <HelpPanel type="PLAZO_ESTIMADO" />
			</>
		),
	},
	{
		name: 'Garantía del crédito',
		label: 'Garantía del crédito',
		description: 'Respaldo del crédito en caso de no cumplir con el pago.',
	},
	{
		name: 'Tabla de amortización',
		label: 'Tabla de amortización',
		description:
			'Documento que muestra el detalle de amortización de tu crédito.',
	},
	{
		name: '*',
		label: (
			<Link
				href={process.env.REACT_APP_URL_MIUC_SIMULADOR_PRESUPUESTO}
				target="_blank"
			>
				Conoce para qué te alcanza
			</Link>
		),
	},
];
export const rowsMejoraDirecta = [
	{
		name: 'Tu pago mensual',
		description:
			'Cantidad que tu patrón descontará vía nómina para el pago de tu crédito. En Cofinavit, si pierdes tu relación laboral, deberás pagar este monto más la aportación patronal (5% de tu salario).',
		bgGrayActive: true,
	},
	{
		name: 'Monto de crédito',
		description: 'Cantidad que te presta el Infonavit.',
	},
	{
		name: 'Menos gastos',
		description: 'Gastos de titulación, financieros y de operación.',
	},
	{
		name: 'Tasa de interés',
		description: 'Porcentaje a pagar por el monto de crédito otorgado.',
	},
	{
		name: 'Contarías con',
		description:
			'Cantidad que resulta de sumar el monto de crédito más tu ahorro, restándole los gastos.',
	},
	{
		name: 'Plazo',
		description:
			'Cantidad a cubrir con tus propios recursos en caso de que el valor de la vivienda supere el monto con el que contarías.',
	},
	{
		name: 'Constancia elegibilidad',
		description:
			'Documento que muestra el detalle de amortización de tu crédito.',
	},
];

export const defaultAddress = {
	cp: '',
	denominacionSociedad: '',
	delegacion: '',
	colonia: '',
	calle: '',
	numeroExterior: '',
	numeroInterior: '',
	telefono: '',
	tieneTDC: false,
	tieneAuto: false,
	tieneHipotecario: false,
	ultimos4DigitosTDC: '',
	validacion: false,
	salarioMensual: 0,
};

export const addressForm = [
	{
		label: 'C.P.',
		name: 'cp',
		id: 'credit-request-zipcode',
		required: true,
		isNumber: true,
		tooltip:
			'El código postal deberá coincidir con el Estado y Municipio donde tengas el crédito que estás colocando como  referencia.',
	},
	{
		label: 'Estado',
		name: 'denominacionSociedad',
		id: 'credit-request-state',
		disabled: true,
	},
	{
		label: 'Municipio o delegación',
		name: 'delegacion',
		id: 'credit-request-delegation',
		disabled: true,
	},
	{
		label: 'Colonia',
		name: 'colonia',
		id: 'credit-request-colony',
		required: true,
	},
	{
		label: 'Calle',
		name: 'calle',
		id: 'credit-request-street',
		required: true,
	},
	{
		label: 'No. Ext.',
		name: 'numeroExterior',
		id: 'credit-request-no-ext',
		required: true,
	},
	{
		label: 'No. Int.',
		name: 'numeroInterior',
		id: 'credit-request-no-int',
	},
	{
		label: 'Teléfono',
		name: 'telefono',
		id: 'credit-request-phone',
		isNumber: true,
		required: true,
	},
];

export const creditOptions = [
	{
		label: 'Tarjeta de crédito',
		name: 'tieneTDC',
	},
	{
		label: 'Crédito automotriz',
		name: 'tieneAuto',
	},
	{
		label: 'Crédito hipotecario',
		name: 'tieneHipotecario',
	},
];

export const availableDetails = [
	{
		detail: 'Retención mensual',
		credito: '10000.00',
		cofinavit: '10000.00',
		style: { fontWeight: 'bold', color: theme.palette.info.main },
	},
	{
		detail: 'Monto del crédito',
		credito: '10000.00',
		cofinavit: '10000.00',
		style: { fontWeight: 'bold' },
	},
	{ detail: 'Más tu ahorro', credito: '10000.00', cofinavit: '10000.00' },
	{ detail: 'Menos gastos', credito: '10000.00', cofinavit: '10000.00' },
	{
		detail: 'Contarías con',
		credito: '10000.00',
		cofinavit: '10000.00',
		style: { fontWeight: 'bold' },
	},
	{
		detail: 'Diferencia a cubrir',
		credito: '10000.00',
		cofinavit: '10000.00',
	},
];

export const userColumns = [
	{
		title: 'Nombre(s)',
		key: 'nombre',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		title: 'Apellido paterno',
		key: 'apellidoPaterno',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		title: 'Apellido materno',
		key: 'apellidoMaterno',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		title: 'RFC',
		key: 'rfc',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		title: 'CURP',
		key: 'curp',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
];

export const availableDetailsMock = [
	{
		id: 1,
		title: 'Credito Infonavit',
		description:
			'Es el crédito tradicional hipotecario que otorga el infonavit.',
		monthly: 10000,
		credit: 10000,
		saving: 10000,
		expanses: 10000,
		count: 10000,
		difference: 10000,
		complements: [
			{
				description: 'Hipoteca verde',
				Icon: Notifications,
			},
			{
				description: 'Hogar a tu medida',
				Icon: ShoppingCart,
			},
			{
				description: 'Hipoteca con servicios',
				Icon: Construction,
			},
		],
		ecotech: 38158,
	},
	{
		id: 2,
		title: 'Cofinavit',
		description:
			'El crédito lo otorgan el Infonavit y una entidad financiera para que tengas un monto mayor.',
		monthly: '10000',
		credit: '10000',
		saving: '10000',
		expanses: '10000',
		count: '10000',
		difference: '10000',
		complements: [
			{
				description: 'Hipoteca verde',
				Icon: Notifications,
			},
			{
				description: 'Hogar a tu medida',
				Icon: ShoppingCart,
			},
			{
				description: 'Hipoteca con servicios',
				Icon: Construction,
			},
		],
		ecotech: 38158,
	},
	{
		id: 3,
		title: 'Infonavit total',
		description:
			'Combina el financiamiento del Infonavit, el saldo de tu subcuenta de vivienda y un crédito otorgado por un banco o sofol, para que puedas adquirir una casa que se adapte a tus necesidades.',
		monthly: '10000',
		credit: '10000',
		saving: '10000',
		expanses: '10000',
		count: '10000',
		difference: '10000',
		complements: [
			{
				description: 'Hipoteca verde',
				Icon: Notifications,
			},
			{
				description: 'Hogar a tu medida',
				Icon: ShoppingCart,
			},
			{
				description: 'Hipoteca con servicios',
				Icon: Construction,
			},
		],
		ecotech: 38158,
	},
	{
		id: 4,
		title: 'Infonavit total',
		description:
			'Combina el financiamiento del Infonavit, el saldo de tu subcuenta de vivienda y un crédito otorgado por un banco o sofol, para que puedas adquirir una casa que se adapte a tus necesidades.',
		monthly: '10000',
		credit: '10000',
		saving: '10000',
		expanses: '10000',
		count: '10000',
		difference: '10000',
		complements: [
			{
				description: 'Hipoteca verde',
				Icon: Notifications,
			},
			{
				description: 'Hogar a tu medida',
				Icon: ShoppingCart,
			},
			{
				description: 'Hipoteca con servicios',
				Icon: Construction,
			},
		],
		ecotech: 38158,
	},
	{
		id: 5,
		title: 'Infonavit total',
		description:
			'Combina el financiamiento del Infonavit, el saldo de tu subcuenta de vivienda y un crédito otorgado por un banco o sofol, para que puedas adquirir una casa que se adapte a tus necesidades.',
		monthly: '10000',
		credit: '10000',
		saving: '10000',
		expanses: '10000',
		count: '10000',
		difference: '10000',
		complements: [
			{
				description: 'Hipoteca verde',
				Icon: Notifications,
			},
			{
				description: 'Hogar a tu medida',
				Icon: ShoppingCart,
			},
			{
				description: 'Hipoteca con servicios',
				Icon: Construction,
			},
		],
		ecotech: 38158,
	},
];

export const onlineExceptions = [
	'El vendedor realizará la transacción a traves de un tercero con poder notarial.',
	'La diferencia entre tu Saldo de Subcuenta de Vivienda es alto y el monto de crédito es bajo.',
	'Si te tenemos identificado con discapacidad mayor al 50%.',
];

export const notaryInfo = [
	{
		text: 'Mi Notario Ideal es el sistema que te permite elegir al notario idóneo para la titulación de tu vivienda',
	},
	{
		text: 'La lista que te presentamos para que realiceds tu selección incluye a los mejores 20 notarios del estado en donde se localiza la vivienda a adquirir, toma en cuenta lo siguiente',
		sublevel: [
			{
				sText: 'Si vas a comprar a un particular o a una inmobiliaria, cuando acudas a inscribir tu crédito se te presentará la lista de notarios con los que podrás realizar tu tramite de escrituras, y que están ordenados con base en los siguientes aspectos:',
				style: { marginBottom: 15 },
			},
			{
				sText: 'Distancia entre la vivienda (paraa la que solicitas el crédito) y el notario.',
			},
			{
				sText: 'Capacidad de atención del notario, de acuerdo con el volumen dde escrituras en proceso y el tiempo promedio de entrega de las escrituras.',
			},
			{
				sText: 'Si la vivienda que te interesa la vas a adquirir con una desarrolladora, no tendrás que elefir un notario, pues el desarrollador te apoyara en el trámite de escrituración',
				style: { fontWeight: 600, marginTop: 10 },
			},
		],
	},
	{
		text: 'Una vez que inicien con el proceso de inscripción de tu crédito en las oficinas de artención del Infonavit podrás realizar la selección libre y confidencial mediante las siguientes alternativas:',
		sublevel: [
			{
				sText: 'Recibirás un correo electrónico con un enlace que te mostrará el listado para que realices tu selección.',
			},
			{
				sText: 'Desde tu celular puedes ingresar a Mi Cuenta Infonavit, después abrir "Mi Tramite de Crédito", dar clic en "Ver lista" y hacer tu selección.',
			},
		],
	},
	{
		text: 'En todas las alternativas el sistema te confirma tu elección y te muestra un código, el cual debes compartir con el asesor para finalizar tu trámite de inscripción. Es muy importasnte que lo anotes tal cual se presenta (respetando las mayúsculas y minúsculas del código), pues no es recuperable.',
	},
];

export const defaultFilterNotary = {
	location: '',
	town: '',
	package: '',
};

export const filterNotaryForm = [
	{ id: 'notary-location-input', name: 'location', label: 'Ubicación' },
	{ id: 'notary-town-input', name: 'town', label: 'Municipio' },
	{
		id: 'notary-package-input',
		name: 'package',
		label: 'Tipo de paquete o MAI',
	},
];

export const defaultAppriasal = {
	clave: '',
	unit: '',
};

export const appraisalForm = (states = [], units = []): any => [
	{
		id: 'appraisal-clave-input',
		name: 'clave',
		label: 'Entidad',
		options: states,
	},
	{
		id: 'appraisal-unit-input',
		name: 'unit',
		label: 'Unidad de valuación',
		options: units,
	},
];

export const appraisalInfo = [
	{ value: 'descripcion', label: 'Unidad' },
	{ value: 'estado', label: 'Estado' },
	{ value: 'direccion', label: 'Oficinas de atención' },
	{ value: 'responsable', label: 'Representante' },
	{ value: 'horario', label: 'Datos de contacto' },
];

export const columnsNotaryTable = [
	{ column: 'id', label: 'No Notario', style: { minWidth: 120 } },
	{ column: 'name', label: 'Nombre', style: { minWidth: 200 } },
	{ column: 'group', label: 'Grupo' },
	{ column: 'inex', label: 'INEX' },
	{
		column: 'total',
		label: 'Infonavit Total',
		style: { fontWeight: 600, color: green['A700'] },
		renderValue: (value: any, i: number, oArray: any) => `${value}`,
	},
	{ column: 'college', label: 'Colegio' },
	{
		column: 'id',
		label: '',
		style: { fontWeight: 500, color: red[500], minWidth: 180 },
		renderValue: NotaryDetailColumnFormat,
	},
];

export const onlineProgressFlow = (destino: string) => [
	{ description: 'Captura la información solicitada.' },
	{
		description: (
			<>
				Digitaliza y carga cada documento que conforma tu expediente y
				envíalo para su validación.
				{/* {
          destino === 'L2' && */}
          <TooltipCustom title="Enviar documento en formato PDF con un peso no mayor a 5 MG.">
            <Info
              style={{
                marginBottom: -5,
                marginLeft: 5,
                fontSize: 18,
              }}
              color="primary"
            />
          </TooltipCustom>
        {/* } */}
			</>
		),
	},
	{
		description:
			'Espera la notificación por correo electrónico de que tu expediente está completo y correcto.',
	},
	{ description: 'Ingresa nuevamente para concluir el registro.' },
	{
		description:
			destino === DESTINOS.REMODELACION.codigo
				? 'Obtén tu Constancia de Crédito.'
				: 'Obtén tu Constancia de Crédito y comunícate con el notario.',
	},
];

export const mockNotaryData = [
	{
		id: 1,
		name: 'Ruiz Aguirre Marco Antonio',
		group: 'A',
		inex: '100',
		total: 'Confirmada',
		college: 'Nacional',
	},
	{
		id: 2,
		name: 'Sanchez Cordero Davila',
		group: 'A',
		inex: '100',
		total: 'Confirmada',
		college: 'Nacional',
	},
	{
		id: 3,
		name: 'Morales Cordero Davila',
		group: 'B',
		inex: '100',
		total: 'Confirmada',
		college: 'Nacional',
	},
	{
		id: 4,
		name: 'Diaz Soto Gabriel Benjamin',
		group: 'B',
		inex: '100',
		total: 'Confirmada',
		college: 'Nacional',
	},
	{
		id: 5,
		name: 'Labrdini Jose Daniel',
		group: 'N',
		inex: '100',
		total: 'Confirmada',
		college: 'Nacional',
	},
	{
		id: 7,
		name: 'Garzon Jimenez Roberto',
		group: 'N',
		inex: '100',
		total: 'Confirmada',
		college: 'Nacional',
	},
];

export const mockRequestCredit: any = {
	L2: {
		icon: (disable?: boolean, sizeIcon?: number) =>
			disable ? (
				<img
					src={CasitaIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			) : (
				<img
					src={CasitaBlackIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			),
		description: 'Compra',
		codigo: 'L2',
		title: (disable?: boolean) => (
			<>
				<span style={{ color: disable ? '#293990' : 'inherit' }}>
					Comprar
				</span>{' '}
				<TooltipCustom
					arrow
					title={
						<>
							<b style={{ marginBottom: 1, display: 'block' }}>
								Puedes comprar una vivienda:
							</b>
							<br />
							Nueva o usada.
							<br />
							Usada y remodelarla.
							<br />
							Adquirida con un crédito vigente.
						</>
					}
				>
					<Info
						color={disable ? 'primary' : 'disabled'}
						style={{ fontSize: 17, marginLeft: 3 }}
					/>
				</TooltipCustom>
			</>
		),
	},
	L3: {
		icon: (disable?: boolean, sizeIcon?: number) =>
			disable ? (
				<img
					src={ConstruirIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			) : (
				<img
					src={ConstruirIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			),
		description: 'Construir',
		codigo: 'L3',
		title: (disable?: boolean) => (
			<>
				<span style={{ color: disable ? '#293990' : 'inherit' }}>
					Construir
				</span>{' '}
				<TooltipCustom
					arrow
					title="En un terreno propio o en el de tu cónyuge."
				>
					<Info
						color={disable ? 'primary' : 'disabled'}
						style={{ fontSize: 17, marginLeft: 3 }}
					/>
				</TooltipCustom>
			</>
		),
	},
	L4: {
		icon: (disable?: boolean, sizeIcon?: number) =>
			disable ? (
				<img
					src={AmpliarIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			) : (
				<img
					src={AmpliarBlackIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			),
		description: 'Ampliar o Reparar',
		codigo: 'L4',
		title: (disable?: boolean) => (
			<>
				<span style={{ color: disable ? '#293990' : 'inherit' }}>
					Ampliar o Reparar
				</span>{' '}
				<TooltipCustom
					arrow
					title="Puedes desde remodelar hasta ampliar tu vivienda."
				>
					<Info
						color={disable ? 'primary' : 'disabled'}
						style={{ fontSize: 17, marginLeft: 3 }}
					/>
				</TooltipCustom>
			</>
		),
	},
	L4_1: {
		icon: (disable?: boolean, sizeIcon?: number) =>
			disable ? (
				<img
					src={CasitaIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			) : (
				<img
					src={CasitaBlackIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			),
		description: 'Mejoravit',
		codigo: 'L4_1',
		title: (disable?: boolean) => (
			<>
				<span style={{ color: disable ? '#293990' : 'inherit' }}>
					Mejoravit
				</span>{' '}
				<TooltipCustom
					arrow
					title="Puedes desde remodelar hasta ampliar tu vivienda."
				>
					<Info
						color={disable ? 'primary' : 'disabled'}
						style={{ fontSize: 17, marginLeft: 3 }}
					/>
				</TooltipCustom>
			</>
		),
	},
	L4_2: {
		icon: (disable?: boolean, sizeIcon?: number) =>
			disable ? (
				<img
					src={AmpliarIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			) : (
				<img
					src={AmpliarBlackIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			),
		description: 'Mejoravit repara / Mejoravit renueva',
		codigo: 'L4_2',
		title: (disable?: boolean) => (
			<>
				<span style={{ color: disable ? '#293990' : 'inherit' }}>
					Mejoravit repara / <br />
					Mejoravit renueva
				</span>{' '}
				<TooltipCustom
					arrow
					title="Puedes desde remodelar hasta ampliar tu vivienda."
				>
					<Info
						color={disable ? 'primary' : 'disabled'}
						style={{ fontSize: 17, marginLeft: 3 }}
					/>
				</TooltipCustom>
			</>
		),
	},
	L5: {
		icon: (disable?: boolean, sizeIcon?: number) =>
			disable ? (
				<img
					src={HipotecaIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			) : (
				<img
					src={HipotecaBlackIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			),
		description: 'Pagar mi Hipoteca',
		codigo: 'L5',
		title: (disable?: boolean) => (
			<>
				<span style={{ color: disable ? '#293990' : 'inherit' }}>
					Pagar mi hipoteca
				</span>{' '}
				<TooltipCustom
					arrow
					title="Pagar la deuda adquirida por un crédito hipotecario con una entidad financiera."
				>
					<Info
						color={disable ? 'primary' : 'disabled'}
						style={{ fontSize: 17, marginLeft: 3 }}
					/>
				</TooltipCustom>
			</>
		),
	},
	L6: {
		icon: (disable?: boolean, sizeIcon?: number) =>
			disable ? (
				<img
					src={ConstruyoIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			) : (
				<img
					src={ConstruyoIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			),
		description: 'ConstruYO',
		codigo: 'L6',
		title: (disable?: boolean) => (
			<>
				<span style={{ color: disable ? '#293990' : 'inherit' }}>
					ConstruYO
				</span>{' '}
				<TooltipCustom
					arrow
					title="Puedes construir, mejorar, ampliar o remodelar tu vivienda, el crédito lo otorga una entidad financiera."
				>
					<Info
						color={disable ? 'primary' : 'disabled'}
						style={{ fontSize: 17, marginLeft: 3 }}
					/>
				</TooltipCustom>
			</>
		),
	},
	L8: {
		icon: (disable?: boolean, sizeIcon?: number) =>
			disable ? (
				<img
					src={Casita2Icon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			) : (
				<img
					src={CasitaBlackIcon}
					alt="casita"
					width={sizeIcon ? sizeIcon : 45}
				/>
			),
		description: 'Comprar un terreno',
		codigo: 'L8',
		title: (disable?: boolean) => (
			<>
				<span style={{ color: disable ? '#293990' : 'inherit' }}>
					Comprar un terreno
				</span>{' '}
				<TooltipCustom
					arrow
					title="Puedes construir, mejorar, ampliar o remodelar tu vivienda, el crédito lo otorga una entidad financiera."
				>
					<Info
						color={disable ? 'primary' : 'disabled'}
						style={{ fontSize: 17, marginLeft: 3 }}
					/>
				</TooltipCustom>
			</>
		),
	},
	CS: {
		icon: (disable?: boolean, sizeIcon?: number) => (
			<img
				src={CreditoSeguroIcon}
				alt="casita"
				width={sizeIcon ? sizeIcon : 45}
				style={{
					filter: !disable ? 'brightness(0.1) opacity(0.2)' : '',
				}}
			/>
		),
		description: 'Crédito seguro',
		codigo: 'CS',
		title: (disable?: boolean) => (
			<>
				<span style={{ color: disable ? '#293990' : 'inherit' }}>
					Crédito seguro
				</span>{' '}
				<TooltipCustom
					arrow
					title="Puedes construir, mejorar, ampliar o remodelar tu vivienda, el crédito lo otorga una entidad financiera."
				>
					<Info
						color={disable ? 'primary' : 'disabled'}
						style={{ fontSize: 17, marginLeft: 3 }}
					/>
				</TooltipCustom>
			</>
		),
	},
	MD: {
		icon: (disable?: boolean, sizeIcon?: number) => (
			<img
				src={Casita2Icon}
				alt="casita"
				width={sizeIcon ? sizeIcon : 45}
				style={{
					filter: !disable ? 'brightness(0.1) opacity(0.2)' : '',
				}}
			/>
		),
		description: 'mejOraSí',
		codigo: 'MD',
		title: (disable?: boolean) => (
			<>
				<span style={{ color: disable ? '#293990' : 'inherit' }}>
					mejOraSí
				</span>{' '}
				<TooltipCustom
					arrow
					title="Reparar, ampliar o mejorar tu vivienda con mejOraSí."
				>
					<Info
						color={disable ? 'primary' : 'disabled'}
						style={{ fontSize: 17, marginLeft: 3 }}
					/>
				</TooltipCustom>
			</>
		),
	},
};

export const mockCreditOptions: any = {
	I: {
		Icono: (
			<img src={IndividualIcon} alt="logoindi" style={{ width: 20 }} />
		),
		img: IndividualIcon,
		codigo: 'I',
		title: `Crédito Individual`,
		description: 'Te sugerimos opciones basadas en tus posibilidades.',
	},
	C: {
		Icono: <img src={ConyugarIcon} alt="logoindi" style={{ width: 20 }} />,
		img: ConyugarIcon,
		codigo: 'C',
		title: 'Crédito Conyugal',
		description:
			'Amplía tus posibilidades y las de tu pareja, sumando su monto de crédito.',
	},
	F: {
		Icono: <img src={FamiliarIcon} alt="logoindi" style={{ width: 20 }} />,
		img: FamiliarIcon,
		codigo: 'F',
		title: 'Crédito Familiar',
		description:
			'Amplía tus posibilidades y las de un familiar: padres, hermanos o hijo(s), sumando su monto de crédito.',
	},
	R: {
		Icono: <img src={FamiliarIcon} alt="logoindi" style={{ width: 20 }} />,
		img: FamiliarIcon,
		codigo: 'R',
		title: 'Crédito Corresidencial',
		description:
			'Amplía tus posibilidades y las de un amigo, pareja en unión libre, etc., sumando su monto de crédito.',
	},
	RM: {
		IconView: (
			<img
				src={ReparacionMenoresIcon}
				alt="logoicon"
				style={{ width: 16 }}
			/>
		),
		img: ReparacionMenoresIcon,
		codigo: 'RM',
		title: 'Reparaciones Menores',
		badge: <HelpPanel type="CARACTERISTICAS_RM" />,
		badgeTitle: <HelpPanel type="CARACTERISTICAS_RM" icon={true} />,
		badgeOrigin: <HelpPanel type="CONSTRUYO_RM" showInfo />,
	},
	AT: {
		IconView: (
			<img
				src={AsistenciaTecnicaIcon}
				alt="logoicon"
				style={{ width: 16 }}
			/>
		),
		img: AsistenciaTecnicaIcon,
		codigo: 'AT',
		title: 'Asistencia Técnica',
		badge: <HelpPanel type="CARACTERISTICAS_AT" />,
		badgeTitle: <HelpPanel type="CARACTERISTICAS_AT" icon={true} />,
		badgeOrigin: <HelpPanel type="CONSTRUYO_AT" showInfo />,
	},
	CN: {
		IconView: (
			<img src={ConstruccionIcon} alt="logoicon" style={{ width: 16 }} />
		),
		img: ConstruccionIcon,
		codigo: 'CN',
		title: 'Crédito con Constructora',
		badge: <HelpPanel type="CARACTERISTICAS_CN" />,
		badgeTitle: <HelpPanel type="CARACTERISTICAS_CN" icon={true} />,
		badgeOrigin: <HelpPanel type="CONSTRUYO_CN" showInfo />,
	},
};

export const mockPickCreditOptions = [
	{
		//Icon: Home,
		title: 'Ya la tengo en mente',
		description: 'Asegúrate que es la ideal para ti',
		img: YatengoIcon,
	},
	/*{
		//Icon: HelpCenter,
		title: 'Ayúdenme a elegir',
		description: 'Te daremos algunas recomendaciones',
		img: AyudaIcon,
	},*/
];

export const mockRecomendations = [
	{
		title: 'Cambiar tu vivienda',
		description:
			'Te recomendamos que busques otra vivienda, Si te interesa comprar, puedes usar el ahorro de subcuenta y un crédito de Infonavit',
		Icon: Home,
	},
	{
		title: 'Comprar vivienda',
		description:
			'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat, debitis quam tempore quasi magnam inventore deleniti officiis vitae, animi ipsa quia itaque quo doloribus illum facere iste praesentium consequuntur mollitia.',
		Icon: Home,
	},
	{
		title: 'Comprar terreno',
		description:
			'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat, debitis quam tempore quasi magnam inventore deleniti officiis vitae, animi ipsa quia itaque quo doloribus illum facere iste praesentium consequuntur mollitia.',
		Icon: Home,
	},
];

export const chooseLivingTexts = [
	'Conoce los servicios públicos que hay en la zona de tu interés: recolección de basura, alumbrado público, drenaje, abasto de agua.',
	'Rutas alternativas y/o transporte público. Estudia las opciones de transporte público y las vialidades disponibles para llegar a tu casa (distancia, costo y tráfico).',
	'Espacios públicos de esparcimiento. Considera que te queden cerca parques, canchas y otros.',
];

export const getLocation = (destino: string, proceso: number) => {
	switch (destino) {
		case 'L2':
			switch (proceso) {
				case 0:
					return 1;
				case 1:
					return 2;
				case 2:
					return 3;
				case 3:
					return 4;
				case 4:
					return 5;
				case 5:
					return 6;
				case 6:
					return 7;
			}
			break;
		case 'L3':
			switch (proceso) {
				case 0:
					return 8;
				case 1:
					return 9;
				case 2:
					return 13;
				case 3:
					return 14;
				case 4:
					return 15;
				case 5:
					// NO TIENE ELIGE VIVIENDA
					return;
				case 6:
					return 16;
				case 7:
					return 12;
			}
			break;
		case 'L4':
			switch (proceso) {
				// case 0:
				// 	return 17;
				// case 1:
				// 	return 21;
				// case 2:
				// 	return 25;
				// case 3:
				// 	return 26;
				// case 4:
				// 	return 27;
				// case 5:
				// 	// NO TIENE ELIGE VIVIENDA
				// 	return;
				case 6:
					return 28;
				case 20:
					return 21;
				case 21:
					return 18;
			}
			break;
		case 'L5':
			switch (proceso) {
				case 0:
					return 29;
				case 1:
					return 30;
				case 2:
					return 31;
				case 3:
					return 32;
				case 4:
					return 33;
				case 5:
					// NO TIENE ELIGE VIVIENDA
					return;
				case 6:
					return 34;
			}
			break;
		case 'L6':
			switch (proceso) {
				// case 0:
				// 	return 1
				case 1:
					return 40;
				// case 2:
				// 	return 3
				// case 3:
				// 	return 4
				case 4:
					return 41;
				// case 5:
				// 	return 6
				case 6:
					return 42;
			}
			break;
		case 'L7':
			switch (proceso) {
				// case 0:
				// 	return 1
				// case 1:
				// 	return 2
				// case 2:
				// 	return 3
				// case 3:
				// 	return 4
				// case 4:
				// 	return 5
				case 6:
					return 47;
				case 7:
					return 48;
			}
			break;
		case 'L8':
			switch (proceso) {
				case 0:
					return 50;
				case 2:
					return 50;
				case 4:
					return 51;
				case 6:
					return 52;
			}
			break;
		default:
			return 1;
	}
};

export const msgErrorMevi = (
	getId: number,
	handleByPassBuro?: () => void,
	sinRedirigir?: boolean
) => {
	if (getId !== 8) {
		return (
			<>
				La consulta de tu historial crediticio muestra irregularidades.
				Aclara directamente tu situación con las Sociedades de
				Información Crediticia (Buró de Crédito). Llama al 5449 4954
				desde la ciudad de México al 01800 640 7920, desde cualquier
				parte del país. También puedes hacerlo en www.burodecredito.com
				sección Personas, Reporte de Crédito Especial.
			</>
		);
	}

	if (sinRedirigir) {
		return (
			<>
				La consulta de tu historial crediticio muestra un nivel de
				endeudamiento alto y un comportamiento de pago no adecuado. Te
				sugerimos obtener tu reporte de crédito especial en{' '}
				<Link href="https://www.burodecredito.com.mx/" target="_blank">
					(Buró de Crédito)
				</Link>{' '}
				sección consumidor para conocer el detalle, y con esto, realizar
				la aclaración con la entidad de crédito correspondiente en su
				caso.
			</>
		);
	}

	return (
		<>
			En este momento, debido a tu historial crediticio, no podemos
			evaluar tus condiciones financieras, te sugerimos obtener tu reporte
			de crédito especial en{' '}
			<Link href="https://www.burodecredito.com.mx/" target="_blank">
				Buró de Crédito
			</Link>{' '}
			en la sección "Consumidor" para conocer el detalle. Sin embargo,
			Infonavit te ofrece alternativas, que puedes conocer seleccionando a
			continuación: "
			<Link onClick={handleByPassBuro}>
				No autorizo la consulta a mi historial crediticio
			</Link>
			", de esta manera te aparecerán las opciones que tenemos para ti
		</>
	);
};

export const msgErrorMeviCrediterreno = (getId: number) => {
	if (getId === 8) {
		return (
			<>
				La consulta de tu historial crediticio muestra un nivel de
				endeudamiento alto y un comportamiento de pago no adecuado. Te
				sugerimos obtener tu reporte de crédito especial en (Buró de
				Crédito) sección consumidor para conocer el detalle, y con esto,
				realizar la aclaración con la entidad de crédito correspondiente
				en su caso.
			</>
		);
	}

	return (
		<>
			La consulta de tu historial crediticio muestra irregularidades.
			Aclara directamente tu situación con las Sociedades de Información
			Crediticia (Buró de Crédito). Llama al 5449 4954 desde la ciudad de
			México al 01800 640 7920, desde cualquier parte del país. También
			puedes hacerlo en{' '}
			<Link href="https://www.burodecredito.com.mx/" target="_blank">
				www.burodecredito.com
			</Link>{' '}
			sección Personas, Reporte de Crédito Especial.
		</>
	);
};

export const getUMA3_001_MIUC = (salarioMinimoDF: number): number => {
	const uma_3_001 = Math.floor(3 * 0.01 * 30.4 * salarioMinimoDF * 10) / 10;

	return uma_3_001;
};
