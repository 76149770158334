import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: '#f2f2f2',
			padding: `${theme.spacing(2.5)} 0`,
		},
	})
);
