import { Link, Badge } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useStyles } from './styles';
import TooltipCustom from '../../../components/TooltipCustom';
import HelpPanel from '../../HelpPanel';
import { Info } from '@mui/icons-material';
import seleccion from '../../../assets/svg/seleccion.svg';
import tramiteLinea from '../../../assets/svg/tramiteLinea.svg';
import avaluo from '../../../assets/svg/avaluo.svg';
import expediente from '../../../assets/svg/expediente.svg';
import oficinasInfonavit from '../../../assets/svg/oficinasInfonavit.svg';
import { ConstantSteps } from '../constantSteps/constantSteps';
import { useNavigate } from 'react-router-dom';
import { useController } from '../../../hooks/useController';

export const CompraSteps = (data: { avanceTaller: number; alert: String }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { tallerSaberMas, verificaTusDatos } = ConstantSteps(data);
	const { dataProcessController } = useController();

	const titleSolicitudLinea = 'Solicitud en línea';
	const documents = [
		tallerSaberMas,
		verificaTusDatos,
		{
			paperType: 'simple',
			paperIcon: avaluo,
			title: 'Solicita tu avalúo',
			description: (
				<>
					<b>Si la vivienda es de un desarrollador, </b>
					el elegirá la unidad de valuación.
					<br />
					<b>Si la vivienda es de un particular, </b>
					solicita el avalúo en cualquiera de las Unidades de
					Valuación
				</>
			),
			link: (
				<a
					href="/precalificacion-puntos/unidades-valuacion"
					className={classes.optionEnlace}
				>
					Consulta el directorio <ChevronRightIcon />{' '}
				</a>
			),
		},
		{
			paperType: 'viewMore',
			paperIcon: expediente,
			title: 'Integra tu expediente',
			description: 'Reúne tus documentos para tramitar tu crédito',
			viewMoreButton: 'Consulta la documentación',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<span className={classes.viewMoreTitle}>
							Reúne estos documentos.
						</span>
						<ul className={classes.viewMoreList}>
							<li>
								Solicitud de inscripción de crédito.{' '}
								<Link
									href={
										'https://portalmx.infonavit.org.mx/wps/wcm/connect/f2b56503-7a4c-44c3-9ef5-075b8325ae9e/SolicituddeInscripciondeCredito_CRED.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CVID=ozkrCXH'
									}
									target="_blank"
								>
									Descárgala aquí.
								</Link>
							</li>
							<li>
								Acta de nacimiento en original y copia.{' '}
								<Badge
									badgeContent={
										<HelpPanel type="ACTA_NACIMIENTO" />
									}
									className={classes.helpPanel}
								></Badge>
							</li>
							<li>
								Identificación oficial INE/IFE o pasaporte
								vigente, en original y copia.
								<TooltipCustom
									arrow
									placement="right"
									title={
										<>
											<b>
												La identificación oficial puede
												ser:
											</b>
											<ul>
												<li>
													Credencial para votar
													vigente
												</li>
												<li>Pasaporte</li>
											</ul>
										</>
									}
								>
									<Info
										color="primary"
										className={classes.infoStyle}
									/>
								</TooltipCustom>
							</li>
							<li>Copia del CURP.</li>
							<li>Copia de la cédula fiscal.</li>
							<li>
								Estado de cuenta bancario del vendedor con Clabe
								(Clave Bancaria Estandarizada) en copia, no
								mayor a 2 meses.
							</li>
							<li>
								Si la vivienda es de un particular, presenta
								copia del título de propiedad de la vivienda a
								adquirir. En caso de ser de una constructora, el
								título de propiedad se emitirá a tu nombre al
								concluir el proceso de titulación.
							</li>
							<li>Avalúo de la vivienda. </li>
							<li>
								Copia de la constancia del curso Saber más para
								decidir mejor.
							</li>
							<li>
								Certificado de discapacidad si vas a solicitar
								el programa Hogar a tu medida.{' '}
								<Badge
									badgeContent={
										<HelpPanel type="CERTIFICADO_DISCAPACIDAD" />
									}
									className={classes.helpPanel}
								></Badge>
							</li>
							<li>
								En caso de solicitar crédito Equipa tu casa,
								presentar:
								<ul>
									<li>
										Carta bajo protesta de decir verdad.{' '}
										<Link
											href={require('../../../assets/files/compra-vivienda/carta-bajo-protesta-equipa-tu-casa.pdf')}
											target={'_blank'}
										>
											Descárgala aquí.
										</Link>
									</li>
									<li>
										Presupuesto inicial indicativo{' '}
										{/* <Link
											href={require('../../../assets/files/compra-vivienda/presupuesto-inicial-equipa-tu-casa.pdf')}
											target={'_blank'}
										>
											Descárgala aquí.
										</Link> */}
										<Link
											href={
												'https://portalmx.infonavit.org.mx/wps/wcm/connect/5cfcacbd-9fca-45d9-9baa-0fcd1932ba9b/ETC_Formato_de_Presupuesto-Proyecto_de_Obra.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CVID=oW.7YjS'
											}
											target={'_blank'}
										>
											Descárgala aquí.
										</Link>
									</li>
									<li>
										Estado de cuenta bancario del
										Derechohabiente con CLABE (Clave
										Bancaria Estandarizada) no mayor a 2
										meses.
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</>
			),
		},
		{
			paperType: 'simple',
			paperIcon: seleccion,
			title: 'Selecciona un notario',
			description: (
				<>
					<b>Si la vivienda es de un desarrollador, </b>
					él elegirá la unidad de valuación.
					<br />
					<b>Si la vivienda es de un particular, </b>
					al momento de inscribir tu crédito en las oficinas del
					Infonavit seleccionarás al notario que más te convenga.
				</>
			),
			link: (
				<a
					rel="noreferrer"
					className={classes.optionEnlace}
					target="_blank"
					href="https://portal.infonavit.org.mx/wps/portal/Infonavit/Servicios/infonavit%20Ampliado/ServicioInex/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziTZyMTJycDB0NDAIsXQw8vUICTEItLAwMjAz0w9EUmLkAFTiFOgYGG3gaW5gb60eRph9VgYEpcfoNcABHA8rsN4Lox2N8uH4UmhWYIYDXDcEmBBSAwoCQKwpyQ0NDIwwyPdMVFQFttxce/dz/d5/L2dBISEvZ0FBIS9nQSEh/#1102000090"
				>
					Consulta el directorio <ChevronRightIcon />{' '}
				</a>
			),
		},
		{
			paperType: 'blue',
			paperIcon: tramiteLinea,
			title: titleSolicitudLinea,
			description:
				'Inicia tu trámite en línea, sin tener que acudir a un Centro de servicio Infonavit.',
			link: (
				<a
					href="/precalificacion-puntos/credito-en-linea"
					className={classes.optionEnlace}
				>
					Solicitar en línea{' '}
					<ChevronRightIcon className={classes.greyArrow} />{' '}
				</a>
			),
		},
		{
			paperType: 'simple',
			paperIcon: oficinasInfonavit,
			title: 'Solicita tu crédito en las oficinas de Infonavit',
			description: (
				<>
					Registra tu solicitud de crédito en una de las oficinas del
					Infonavit. Regresa a la página principal de Mi Cuenta
					Infonavit, en el menú sellado superior, puedes hacer tu cita
					para acudir al centro de servicio Infonavit más cercano.
				</>
			),
			link: (
				<Link
					className={classes.optionEnlace}
					target="_blank"
					onClick={() => navigate('/contactanos/haz-una-cita')}
				>
					Hacer una cita <ChevronRightIcon />{' '}
				</Link>
			),
		},
	];

	if (dataProcessController.processInfo?.productSelect?.producto === 'CI') {
		return documents;
	} else {
		return documents.filter((doc) => doc.title !== titleSolicitudLinea);
	}
};
