import { makeStyles } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';
import { Radio } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
	titleNoticeContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	titleNotice: {
		marginTop: 0,
		fontSize: 22,
		color: '#293990',
	},
	subTitleNotice: {
		fontSize: 12,
		color: '#333333',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			textAlign: 'right',
			marginTop: 15,
		},
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		paddingBlock: 50,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},

	containerButtons: {
		display: 'flex',
		justifyContent: 'space-around',
		width: '500px',
		margin: '30px auto',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	panelAsociacion: {
		width: '900px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			flexDirection: 'column',
			alignItems: 'center',
		},
	},

	link: {
		textDecoration: 'underline',
		color: 'red',
		cursor: 'pointer',
		'&:hover': {
			cursor: 'pointer',
		},
	},

	VerificacionText: {
		fontSize: '17px',
		textAlign: 'center',
		width: '100%',
	},

	TextoCentrado: {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
	},
	title: {
		color: '#293990',
		display: 'flex',
		fontWeight: 'bold',
		fontSize: 22,
		marginBottom: '10%',
		lineHeight: 1,
		'&>h2': {
			color: theme.palette.info.dark,
		},
		'&>p': {
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
		},
	},
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
	margin: 0,
	padding: 0,
	marginRight: 5,
	marginTop: 3,
}));
