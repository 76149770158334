// Dependencies
import { useState } from 'react';

// Material Components
import { Box, useMediaQuery, Paper, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// Components
import CustomButton from '../../components/Button';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';

// Assets
import { useStyles } from './styles';
import { IAlert } from '../../interfaces/alert';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { ApiRfcCorrectionRegister } from '../../api/modules/rfcCorrectionRegister';

interface Props {
	setPage: (data: number) => void;
	params: {
		nss: string;
		curp: string;
		rfc: string;
		name: string;
		phone: string;
		email: string;
		rfcCrm: string;
	};
	setViewHeader: (data: boolean) => void;
	setViewFooter: (data: boolean) => void;
}

const PageNomina = ({ setPage, params, setViewHeader, setViewFooter }: Props) => {
	const navigate = useNavigate();
	const style = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState({
		name: 'No se eligió ningún archivo',
		data: null,
		type: '',
		size: ''
	});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [dataStatus, setDataStatus] = useState({
		noCaso: '',
		fecha: '',
		descripcionServicio: '',
		fechaStatus: '',
		status: {
			description: ''
		}
	});
	const [proccessComplete, setproccessComplete] = useState(false)

	const handleFile = (e: any) => {
		if (e.target.files[0].type === 'text/xml') {
			if (e.target.files[0].size < 5000000) {
				setFile({
					name: e.target.files[0].name,
					data: e.target.files[0],
					type: e.target.files[0].type,
					size: e.target.files[0].size,
				});
				e.target.value = null;
			} else {
				setAlert({
					show: true,
					message: 'El tamaño del archivo no debe ser mayor a 5 MB.',
					severity: 'error',
				});
			}
		} else {
			setAlert({
				show: true,
				message: 'Formato no válido, verifica que el formato del archivo tenga la extensión .xml',
				severity: 'error',
			});
		}
	};
	const consultaCFDI = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const rfc_base64 = await encrypt(params?.rfc.toString() || '', key);
			const nss_base64 = await encrypt(params?.nss.toString() || '', key);
			const curp_base64 = await encrypt(params?.curp.toString() || '', key);
			const email_base64 = await encrypt(params?.email.toString() || '', key);
			const fullName_base64 = await encrypt(params?.name.toString() || '', key);
			const telefonoMovil_base64 = await encrypt(params?.phone.toString() || '', key);

			let formData = new FormData();
			formData.append('nss', nss_base64);
			formData.append('recibo', file.data ? file.data : '');
			formData.append('email', email_base64);
			formData.append('given_name', fullName_base64);
			formData.append('telefonoMovil', telefonoMovil_base64);
			formData.append('rfc', rfc_base64);
			formData.append('curp', curp_base64);

			uploadToFileServer(
				formData,
				`${process.env.REACT_APP_API_URL}/cambio-rfc-registro/cfdi`
			)
				.then((res: any) => {
					if (res) {
						getStatus()
					}
				})
				.catch((err: any) => {
					let messageError = err.description ? err.description :
						err.value?.mensajeError ? err.value?.mensajeError :
							'Por el momento el servicio no está disponible. Intenta más tarde.'
					setAlert({
						show: true,
						message: messageError === 'N - 601: La expresión impresa proporcionada no es válida.' ? 'El documento proporcionado no es válido' : messageError,
						severity: 'error',
					});
					setLoading(false)
				})
		} catch (err: any) {
			let messageError = err.description ? err.description :
				err.value?.mensajeError ? err.value?.mensajeError :
					'Por el momento el servicio no está disponible. Intenta más tarde.'
			setAlert({
				show: true,
				message: messageError === 'N - 601: La expresión impresa proporcionada no es válida.' ? 'El documento proporcionado no es válido' : messageError,
				severity: 'error',
			});
			setLoading(false)
		}
	};
	const uploadToFileServer = (formdata: any, url: string) => {
		return new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			const token = '';
			xhr.responseType = 'json';
			xhr.open('POST', url, true);
			xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			xhr.setRequestHeader('Authorization', `Bearer ${token}`);
			xhr.send(formdata);

			xhr.onload = () => {
				if (xhr.status === 200) {
					resolve(xhr.response.result);
				} else {
					reject(xhr.response.err);
				}
			};
		});
	};
	const getStatus = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const rfc_base64 = await encrypt(params?.rfc.toString() || '', key);
			const nss_base64 = await encrypt(params?.nss.toString() || '', key);
			const curp_base64 = await encrypt(params?.curp.toString() || '', key);
			ApiRfcCorrectionRegister.getStatus
				.post<
					{
						nss: string;
						rfc: string;
						curp: string;
					},
					any
				>('', {
					nss: nss_base64,
					rfc: rfc_base64,
					curp: curp_base64
				})
				.then((response: any) => {
					setDataStatus(response.result)
					setViewFooter(true)
					setViewHeader(false)
					setproccessComplete(true)
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		}
	};

	return (
		<Paper sx={{
			pt: proccessComplete ? 5 : 0,
			pb: proccessComplete ? 5 : 0,
			pr: proccessComplete ? matches ? 5 : 15 : 0,
			pl: proccessComplete ? matches ? 5 : 15 : 0
		}}>
			{!proccessComplete && (
				<Box className={style.container}>
					<ModalLoading loading={loading} />
					<div
						id="gotPayslip"
						style={{ display: 'inline' }}
					>
						<div
							style={{
								display: 'flex',
								alignItems: matches ? 'normal' : 'center',
								justifyContent: 'space-between',
								flexWrap: 'wrap',
								paddingLeft: matches ? 25 : 20,
								paddingRight: 20,
								paddingBottom: matches ? 20 : 10,
								paddingTop: 25,
								flexDirection:
									file.data && matches ? 'column' : 'inherit',
							}}
						>
							<h3
								style={{
									color: 'black',
									width: matches ? '100%' : 300,
									fontSize: matches ? 16 : 18,
									order: 1,
									margin: 0,
									textAlign: matches ? 'center' : 'left',
								}}
							>
								Recibo de nómina
							</h3>
							<p
								style={{
									flexGrow: 1,
									width: matches ? '100%' : 0,
									order: matches && !file.data ? 3 : 2,
									margin: 0,
									opacity: 0.5,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									textAlign: matches ? 'center' : 'left',
								}}
							>
								{file.name}
							</p>
							<div
								style={{
									width: matches ? '100%' : 300,
									textDecoration: 'none',
									color: '#D1001F',
									paddingTop: 0,
									fontSize: 16,
									order: 3,
								}}
							>
								<label
									onClick={() => {
										setFile({
											name: 'No se eligió ningún archivo',
											data: null,
											type: '',
											size: ''
										})
										setAlert({
											show: false,
											message: '',
											severity: 'success',
										})
									}}
									style={{
										width: '100%',
										display: file.data ? 'flex' : 'none',
										alignItems: 'center',
										margin: matches ? '5px 0px' : '0px 5px',
										cursor: 'pointer',
										justifyContent: matches ? 'center' : 'right',
									}}
								>
									<Close style={{ fontSize: 20, marginRight: 2 }} />
									Eliminar archivo
								</label>
								<label
									style={{
										width: '100%',
										cursor: 'pointer',
										display: 'flex',
										alignItems: 'center',
										margin: matches ? '5px 0px' : '0px 5px',
										justifyContent: matches ? 'center' : 'right',
									}}
								>
									<label
										style={{
											cursor: 'pointer',
											display: file.data ? 'none' : 'flex',
										}}
									>
										<input
											type="file"
											accept=".xml"
											name="file1"
											onChange={handleFile}
											className={style.chooseFileOK}
										/>
										Elegir archivo
									</label>
								</label>
							</div>
						</div>

						<hr className={style.hr} />
						<p className={style.pTextPayslip} style={{ textAlign: 'center' }}>
							El documento que debes adjuntar es tu recibo de nómina digital,
							antes de enviarlo verifica que esté correcto. <br />El formato permitido
							es .XML, cuyo tamaño no debe exceder los 5 MB.
						</p>
						<div style={{ paddingLeft: 98, paddingRight: 98 }}>
							<CustomAlert
								show={alert.show}
								severity={alert.severity}
								message={alert.message}
							/>
						</div>

						<div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									flexDirection: matches ? 'column-reverse' : 'inherit',
									padding: matches ? 10 : 0,
									paddingBottom: 20,
									paddingTop: 20,
								}}
							>
								<CustomButton
									data-testid="gtoPayCancelar"
									label="Cancelar"
									onClick={() => {
										setPage(0);
									}}
									variant="outlined"
									styles={{
										width: matches ? '100%' : 200,
										height: 40,
										marginRight: 20,
										marginBottom: 20,
									}}
								/>
								<CustomButton
									data-testid="gtoPayContinuar"
									label="Continuar"
									onClick={consultaCFDI}
									disabled={!alert.show && file.data ? false : true}
									variant="solid"
									styles={{
										width: matches ? '100%' : 200,
										height: 40,
										marginBottom: 20,
									}}
								/>
							</div>
						</div>
					</div>
				</Box>
			)}
			{proccessComplete && (
				<div>
					<h2 style={{ color: '#293990', textAlign: 'center', paddingBottom: 20 }}>
						Gracias por utilizar los servicios digitales del Infonavit
					</h2>
					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matchesSm ? 'center' : 'initial',
								}}
							>
								Número de caso
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matchesSm ? 'center' : 'right' }}
						>
							<label>{dataStatus?.noCaso}</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />
					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matchesSm ? 'center' : 'initial',
								}}
							>
								Fecha de solicitud
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matchesSm ? 'center' : 'right' }}
						>
							<label>{dataStatus.fecha}</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matchesSm ? 'center' : 'initial',
								}}
							>
								Servicio
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matchesSm ? 'center' : 'right' }}
						>
							<label>{dataStatus.descripcionServicio}</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matchesSm ? 'center' : 'initial',
								}}
							>
								Estatus
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matches ? 'center' : 'right' }}
						>
							{dataStatus.status?.description}
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matchesSm ? 'center' : 'initial',
								}}
							>
								Fecha de estatus
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matchesSm ? 'center' : 'right' }}
						>
							<label>{dataStatus.fechaStatus}</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

					<div
						style={{
							maxWidth: 800,
							margin: 'auto',
							marginTop: 30,
						}}
					>
						<div className={style.message}>
							<span>
								Cuando tus datos sean actualizados se te notificará a través de correo electrónico y podrás continuar con la creación de tu Cuenta Infonavit.
							</span>
						</div>
						<div
							style={{
								maxWidth: 260,
								display: 'flex',
								margin: 'auto',
								marginTop: 30,
							}}
						>
							<CustomButton
								variant="solid"
								label={'Finalizar'}
								onClick={() => {
									localStorage.removeItem('dataUserRegister');
									navigate('/registro');
								}}
							/>
						</div>
					</div>
				</div>
			)}
		</Paper>
	);
};
export default PageNomina;
