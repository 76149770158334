import { MIUC_PRODUCTS } from '../constants/products';
import { DESTINOS } from '../../../config/miucConstants';
import { useController } from '../hooks/useController';

const getNumber = (arg?: string) => {
  return new Intl.NumberFormat('es-MX').format(Number(arg || ''));
};

export const ListasPuntuacion = () => {
  const { dataProcessController } = useController();

  const listPuntuacion = [
    {
			label: 'Edad y Salario',
			description:
				'Se tomó en cuenta tu salario mensual integrado que incluye las prestaciones acorde con la ley del IMSS y tu edad.',
			points: getNumber(dataProcessController.titular?.puntosEdadSalario),
			divider: true,
			// hidden: ![
			// 	MIUC_PRODUCTS.COMPRA,
			// 	MIUC_PRODUCTS.HIPOTECA,
			// 	MIUC_PRODUCTS.AUTOPRODUCCION,
			// 	'',
			// ].includes(dataProcessController.processInfo?.credit),
		},
		{
			label: 'Ahorro en la subcuenta de vivienda',
			description:
				'Se consideró el dinero (saldo) que tienes en tu Subcuenta de Vivienda individual perteneciente al sistema de ahorro para el retiro.',
			points: getNumber(dataProcessController.titular?.puntosSaldoSAR),
			divider: true,
		},
		{
			label: 'Cotización continua',
			description:
				'Se contempló el número de los últimos bimestres de cotización continua que has tenido en tu trabajo.',
			points: getNumber(
				dataProcessController.titular?.puntosBimestresContinuos
			),
			divider: true,
		},
		{
			label: 'Tipo de trabajador',
			description:
				'Se consideró si tu trabajo es de tipo permanente o temporal.',
			points: getNumber(
				dataProcessController.titular?.puntosTipoTrabajador
			),
			divider: true,
		},
    {
			label: 'Mujer Infonavit**',
			description: 'Bono otorgado por el Infonavit a todas las mujeres.',
			points: getNumber(dataProcessController.titular?.puntosMujer),
			divider: true,
			hidden: ![
				DESTINOS.COMPRA.codigo,
				DESTINOS.HIPOTECA.codigo,
				DESTINOS.AUTOPRODUCCION.codigo,
        DESTINOS.CREDITO_SEGURO.codigo,
        DESTINOS.APOYO_INFONAVIT.codigo,
				'',
			].includes(dataProcessController.processInfo?.credit),
		},
  ];

  const listPuntuacionPatronal = [
		{
			label: 'Estabilidad laboral',
			description:
				'Se contempló el promedio de los últimos tres años de la retención de personal que ha tenido la empresa donde laboras.',
			points: getNumber(
				dataProcessController.titular?.puntosPatronEstabilidad
			),
			divider: true,
		},
		{
			label: 'Comportamiento de pago de la empresa',
			description:
				'Se consideró el nivel de cumplimiento que ha tenido tu patrón en sus obligaciones fiscales y jurídicas.',
			points: getNumber(
				dataProcessController.titular?.puntosPatronComportamiento
			),
			divider: true,
		},
		{
			label: 'Giro del patrón',
			description:
				'Es la clasificación que se le da a un emprendimiento, dependiendo de las actividades que realiza. Es decir, este concepto hace referencia a la actividad económica a la que se dedica una determinada empresa.',
			points: getNumber(
				dataProcessController.titular?.puntosPatronContexto
			),
			divider: true,
			hidden:
				dataProcessController.processInfo?.credit !==
				MIUC_PRODUCTS.CONSTRUCCION,
		},
		{
			label: 'Contexto*',
			description:
				dataProcessController.processInfo?.credit !==
				MIUC_PRODUCTS.CREDITERRENO
					? 'Se tomó en cuenta la ubicación y giro de la empresa donde laboras'
					: 'El bono es mayor para los derechohabientes que adquieren un terreno en zonas de alta demanda (estados del sureste).',
			points: getNumber(
				dataProcessController.titular?.puntosPatronContexto
			),
			divider: true,
			hidden:
				dataProcessController.processInfo?.credit ===
				MIUC_PRODUCTS.CONSTRUCCION,
		},
	];

	return { listPuntuacion, listPuntuacionPatronal };
};
