import { Divider, Grid, MenuItem, Paper, Select, useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';
import { Link } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ahorros from '../../assets/img/oficina_opcion.png';
import pagos from '../../assets/img/pagos@2x.png';
import { useTheme } from '@mui/material/styles';
import { IAlert } from '../../interfaces/alert';
import CustomButton from '../../components/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { IResponseCitasActuales } from '../../interfaces/makeDate';
import CustomAlert from '../../components/CustomAlert';

interface Props {
	setPage: (data: number) => void;
	setService: (data: string) => void;
	modulo: any;
	setServices: (services: any) => void;
	services: any;
	responseCitasActuales: IResponseCitasActuales[];
	ResponseServices: any;
	errorResponseCitas: boolean;
	errorResponseServices: boolean;
	setServiceDes: (data: string) => void;
}

const Tab1Appointment = ({
	setPage,
	setService,
	ResponseServices,
	errorResponseCitas,
	errorResponseServices,
	setServiceDes,
}: Props) => {
	const [form, setForm] = useState({
		servicio: '',
	});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { user } = useSelector((state: RootState) => state.session);

	const validPagosExcesos = user?.perfilamiento['mi-credito']
		? user?.perfilamiento['mi-credito'].includes('devolucion-pagos-exceso')
		: false;

	useEffect(() => {
		if (errorResponseCitas || errorResponseServices) {
			setAlert({
				show: true,
				message:
					'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
				severity: 'error',
			});
		}
	}, [errorResponseCitas, errorResponseServices]);

	return (
		<Paper sx={{ pt: 5, pb: 5, pl: matches ? 3 : 5, pr: matches ? 3 : 5 }}>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<div
						style={{
							backgroundColor: '#EEF4FA',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'left',
							paddingLeft: matches ? 10 : 20,
							paddingRight: 15,
						}}
					>
						<div>
							<ErrorOutlineOutlinedIcon
								sx={{ color: '#293990', mr: matches ? 1 : 3 }}
							/>
						</div>
						<div>
							<h3 style={{ fontSize: matches ? 14 : 18, color: '#293990' }}>
								Antes de solicitar una cita, recuerda que puedes hacer los
								siguientes trámites en línea
							</h3>
						</div>
					</div>
				</Grid>

				<Grid item xs={12} sm={12} md={6} lg={6} sx={{ pt: matches ? 4 : 6 }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'flex-start',
							justifyContent: matches ? 'left' : 'center',
						}}
					>
						<img
							src={ahorros}
							alt=""
							style={{
								width: matches ? 40 : 62,
							}}
						/>

						<div style={{ paddingLeft: 15 }}>
							<p
								style={{
									fontSize: matches ? 16 : 18,
									margin: matches ? '0 0 10px 0' : '0 0 20px 0',
								}}
							>
								Devolución de Mis ahorros
							</p>
							<Link
								href="/mi-ahorro/devolucion-fondo-ahorro-72-92"
								style={{ color: '#D1001F', fontSize: matches ? 16 : 18 }}
							>
								Solicitar en línea
							</Link>
						</div>
					</div>
				</Grid>

				{validPagosExcesos && (
					<Grid item xs={12} sm={12} md={6} lg={6} sx={{ pt: matches ? 4 : 6 }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'flex-start',
								justifyContent: matches ? 'left' : 'center',
							}}
						>
							<img
								src={pagos}
								alt=""
								style={{
									width: matches ? 40 : 62,
								}}
							/>
							<div style={{ paddingLeft: 15 }}>
								<p
									style={{
										fontSize: matches ? 16 : 18,
										margin: matches ? '0 0 10px 0' : '0 0 20px 0',
									}}
								>
									Devolución de pagos en exceso
								</p>
								<Link
									href="/mi-credito/devolucion-pagos-exceso"
									style={{
										color: '#D1001F',
										fontSize: matches ? 16 : 18,
									}}
								>
									Solicitar en línea
								</Link>
							</div>
						</div>
					</Grid>
				)}

				<Grid item xs={12} sm={12} md={12} lg={12} sx={{ pt: matches ? 4 : 6 }}>
					<Divider />
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} sx={{ pt: 1 }}>
					<div>
						<h3 style={{ fontSize: 16 }}>
							Para programar una cita, primero selecciona un servicio:
						</h3>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} sx={{ pt: 1 }}>
					<div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
						<span style={{ marginBottom: 10 }}>Servicio:</span>
						<Select
							disabled={errorResponseCitas || errorResponseServices}
							displayEmpty
							sx={{ width: matches ? 305 : 370 }}
							name="servicio"
							value={form.servicio}
							onChange={(e: any) => {
								const selectedService = ResponseServices.find((element: any) => element.serviceId === e.target.value);
								setForm({ ...form, servicio: e.target.value });
								setService(e.target.value);
								if (selectedService) {
									setServiceDes(selectedService.serviceDes);
								} else {
									setServiceDes('');
								}
							}}
							renderValue={
								form.servicio !== ''
									? undefined
									: () => (
										<label
											style={{ fontSize: 15, color: '#7F7F7F' }}
										>
											Selecciona un servicio
										</label>
									)
							}
							style={{ maxWidth: matches ? '100%' : 390 }}
						>
							{ResponseServices.map((element: any, i: number) => (
								<MenuItem
									key={element.serviceId}
									value={element.serviceId}
								>
									{element.serviceDes}
								</MenuItem>
							))}
						</Select>
					</div>
				</Grid>
				<CustomAlert
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
				/>
				<Grid item xs={12} sm={12} md={12} lg={12} sx={{ pt: matches ? 5 : 10 }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<CustomButton
							label="Continuar"
							onClick={() => {
								setPage(1);
							}}
							disabled={form.servicio ? false : true}
							variant="solid"
							styles={{
								width: 260,
								height: 40,
							}}
						/>
					</div>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default Tab1Appointment;
