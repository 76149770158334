/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
	Box,
	Paper,
	Grid,
	Select,
	useMediaQuery,
	MenuItem,
	TextField,
} from '@mui/material';
import moment, { MomentInput } from 'moment';
import { StaticDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import es from 'date-fns/locale/es';
import { PickersDayProps } from '@mui/lab/PickersDay';
import { formatDate } from '../../interfaces/SaldAndMovement';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

import { downloadPDF } from '../../utils/downloadPDF';

//Assets
import { CustomPickersDay, RoundBtn } from '../MakeAppointment/styles';
import { getHour } from '../../utils/dates';
import IconDes from '../../assets/img/icono-descarga.png';
import { IAlert } from '../../interfaces/alert';
import { makeDate24API } from '../../api/modules/makeDate24';
import { encrypt, encodeAllJSONData } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { useStyles } from './styles';

//Components
import CustomButton from '../../components/Button';
import { useTheme } from '@mui/material/styles';
import { HeaderList } from '../../components/HeaderList';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';
import BaseModal from '../../components/ModalConfirmation';
import { PaymentRequestAPI } from '../../api/modules/PaymentClarification';
import { ResponseCaso } from '../../interfaces/PaymentClarification';

import { getFormatHour, getFormatDateShort } from '../../utils/dates';
import { IDataServiceId, IDataSucursal } from '../../interfaces/makeDate24';
import { MESSAGES_ERROR } from './utils';
import { useBitacora } from '../../hooks/useBitacora';

interface Props {
	setPage: (data: number) => void;
	dataCaso: {
		noCaso: string;
		estatus: string;
		fecha: string;
		fechaEstatus: string;
		descripcion: string;
		tipificacion: string;
		estatusDetalle: {
			descripcion: string;
			estatus: string;
		};
		casoCerradoDetalle: {
			razon: string;
			id: string;
			descripcion: string;
		};
	};
	citaActual: any;
	doctosRequeridosArray: string;
	updateCaso: () => void;
	dataPayment: {
		date: any;
		type: any;
		amount: any;
		payment: any;
		folio: any;
		banco: any;
		creditCorrect: any;
		detallePago: any;
	};
	setDataCaso: (data: any) => void;
	makeDateNow: boolean;
}
interface DaysFormat {
	startday: MomentInput | number;
	endday: MomentInput | number;
	typeday: string | null;
}
interface IDataEstados {
	RegionCode: string;
	RegionDes: string;
	ParentRegionCode: string;
}
interface IDataCedis {
	OrganizationAlias: string;
	OrganizationCloseTime: string;
	OrganizationCode: string;
	OrganizationDescription: string;
	OrganizationLocation: string;
	OrganizationOpenTime: string;
}
const defaulTime = {
	seconds: 59,
};

const MakeDate = ({
	setPage,
	dataCaso,
	citaActual,
	doctosRequeridosArray,
	updateCaso,
	dataPayment,
	setDataCaso,
	makeDateNow,
}: Props) => {
	const { regBitacoraNotOld } = useBitacora();
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const theme = useTheme();
	const classes = useStyles();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [pageDate, setPageDate] = useState(false);

	const [form, setForm] = useState({
		cedis: '',
		estado: '',
		codigo: '',
		organization: '',
		horario: '',
	});
	const [fecha, setFecha] = useState<Date | null>(null);
	const [estados, setEstados] = useState<Array<IDataEstados>>([]);
	const [cedis, setCedis] = useState<Array<IDataCedis>>([]);
	const [meses, setMeses] = useState<
		Array<{ startday: string; endday: string; typeday: string }>
	>([]);
	const [horarios, setHorarios] = useState<
		Array<{ cupoCanalId: any; intervaloId: any; FromHour: string; FromMinute: string; ToHour: string; ToMinute: string }>
	>([]);
	const [fechaCita, setFechaCita] = useState<Date | null>(null);
	const [horarioForm, setHorarioForm] = useState('');

	const [cedisForm, setCedisForm] = useState('');
	const [citaConfirm, setCitaConfirm] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [modalCancelar, setModalCancelar] = useState(false);
	const [modalCancelada, setModalCancelada] = useState(false);
	const [modal, setModal] = useState(false);
	const [modalAgendar, setModalAgendar] = useState(false);
	const [reprogramar, setReprogramar] = useState(false);
	const [responseCita, setResponseCita] = useState({
		data: {
			appointmentReservationId: '',
			AttendantCode: '',
		},
	});
	const [dataConfirm, setDataConfirm] = useState({
		appointmentCode: '',
		creationDate: '',
		date: '',
		id: '',
		caso: '',
		organizationDes: '',
		organizationLocation: '',
		organizationCode: '',
		service: '',
		serviceCode: '',
		ts: '',
	});
	const [timerActive, setTimerActive] = useState(false);
	const [time, setTime] = useState(defaulTime);
	const [timerFinal, setTimerFinal] = useState(false);
	const [doctosRequeridos, setDoctosRequeridos] = useState('');
	const [idCancel, setIdCancel] = useState('');
	const [agendaId, setAgendaId] = useState('');
	const [dataHorario, setDataHorario] = useState({
		intervaloId: '',
		cupoCanalId: ''
	})

	useEffect(() => {
		getEstados();
	}, []);

	useEffect(() => {
		if (!makeDateNow) {
			if (dataCaso.estatusDetalle.estatus === 'Solicitar Cita' || dataCaso.estatusDetalle.estatus === 'Cambiar Cita' || dataCaso.estatusDetalle.estatus === 'Abierto con cita') {
				setPageDate(true);
			}
		}
		if (citaActual.id) {
			setDataConfirm({
				...dataConfirm,
				appointmentCode: citaActual.appointmentCode,
				creationDate: citaActual.creationDate,
				date: citaActual.date,
				id: citaActual.id,
				caso: citaActual.caso || '',
				organizationDes: citaActual.organizationDes,
				organizationLocation: citaActual.organizationLocation,
				serviceCode: '8',
				service: citaActual.serviceId,
			});
			setCitaConfirm(true);
			setDoctosRequeridos(doctosRequeridosArray);
		}
	}, [estados]);

	useEffect(() => {
		if (timerActive) {
			const intervalId = setInterval(() => {
				updateTime();
			}, 1000);
			return () => clearInterval(intervalId);
		}
	}, [time, timerActive]);

	useEffect(() => {
		const dataHora = horarios.find(hora => hora.FromHour === form.horario.substring(0, 2) && hora.FromMinute === form.horario.substring(3, 5));
		if (dataHora) {
			setDataHorario({
				intervaloId: dataHora.intervaloId,
				cupoCanalId: dataHora.cupoCanalId,
			})
		}
	}, [form.horario]);

	const updateTime = () => {
		let timeActual = time.seconds;
		if (timeActual > 1) {
			let params = {
				seconds: timeActual - 1,
			};
			setTime(params);
		} else {
			setTimerFinal(true);
			cancelCita();
		}
	};

	const handleCedis = async (e: any) => {
		setForm({
			cedis: '',
			estado: e.target.value,
			codigo: '',
			organization: '',
			horario: '',
		})
		setCedis([])
		setMeses([])
		setHorarios([])
		setFecha(null);
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		setLoading(true);
		try {
			const data: IDataSucursal = {
				entidadFederativaId: e.target.value,
				pServiceId: '0231',
				nss: user?.nss || ''
			}
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const responseTotal = await makeDate24API.cesis(dataEncript);
			if (responseTotal.code === '0000') {
				setLoading(false);
				if (responseTotal?.data?.sucursales && responseTotal?.data?.sucursales.length > 0) {
					setCedis(
						responseTotal.data.sucursales.sort((a: any, b: any) => {
							if (a.organizationDescription > b.organizationDescription) {
								return 1;
							}
							if (a.organizationDescription < b.organizationDescription) {
								return -1;
							}
							return 0;
						})
					);
				} else {
					setAlert({
						show: true,
						message: MESSAGES_ERROR.errorSucursales,
						severity: 'error',
					});
				}
			} else {
				setAlert({
					show: true,
					message: responseTotal.message || MESSAGES_ERROR.errorService,
					severity: 'error',
				});
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || MESSAGES_ERROR.errorService,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleMeses = async (e: string, date: any) => {
		setMeses([])
		setHorarios([])
		setFecha(null);
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		try {
			setLoading(true);
			let month = date?.getMonth() + 1;
			let year = date?.getFullYear();
			setForm({ ...form, cedis: e });
			setCedisForm(e);
			const data = {
				pMonth: month.toString(),
				pYear: year.toString(),
				servicioId: '0231',
				centroAtencionId: e,
				grupoClienteId: '1',
				nss: user?.nss || '',
			};
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const responseMeses = await makeDate24API.meses(
				dataEncript
			);
			if (responseMeses.code === '0000') {
				let arrayMes: any = [];
				let Status: string = 'none';
				let contMes: number = 0;
				setAgendaId(responseMeses?.data?.agendaId)
				if (responseMeses?.data?.monthDays && responseMeses?.data?.monthDays.length > 0) {
					responseMeses.data.monthDays.forEach((mes: any) => {
						if (Status !== mes.available) {
							if (mes.available === false) {
								arrayMes[contMes] = {
									startday: mes.dateTime,
									disabled: true,
									endday: null,
									typeday: 'baja',
								};
							} else {
								arrayMes[contMes] = {
									startday: mes.dateTime,
									endday: null,
									typeday: 'alta',
								};
							}
							contMes++;
							Status = mes.available;
						} else {
							const mesAnt = contMes - 1 < 0 ? 0 : contMes - 1;
							arrayMes[mesAnt] = {
								...arrayMes[mesAnt],
								endday: mes.dateTime,
							};
						}
					});
					setMeses(arrayMes);
				} else {
					setAlert({
						show: true,
						message: MESSAGES_ERROR.errorDays,
						severity: 'error',
					});
				}
			} else {
				setAlert({
					show: true,
					message: responseMeses.err?.description || MESSAGES_ERROR.errorService,
					severity: 'error',
				});
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || MESSAGES_ERROR.errorService,
				severity: 'error',
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const getEstados = async () => {
		setLoading(true);
		try {
			const data: IDataServiceId = {
				pServiceId: '0231',
				nss: user?.nss || '',
			}
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const responseTotal = await makeDate24API.statusService(dataEncript);
			if (responseTotal.err) {
				setAlert({
					show: true,
					message: responseTotal.err.description,
					severity: 'error',
				});
			} else {
				setLoading(false);
				if (responseTotal?.data?.regiones && responseTotal?.data?.regiones.length > 0) {
					setEstados(
						responseTotal.data.regiones.sort((a: any, b: any) => {
							if (a.regionDes > b.regionDes) {
								return 1;
							}
							if (a.regionDes < b.regionDes) {
								return -1;
							}
							return 0;
						})
					);
				} else {
					setAlert({
						show: true,
						message: MESSAGES_ERROR.errorService,
						severity: 'error',
					});
				}
			}
		} catch (error) {
			setAlert({
				show: true,
				message: MESSAGES_ERROR.errorService,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleHorario = async (newValue: any) => {
		setForm({ ...form, horario: '', });
		setHorarios([])
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		try {
			setLoading(true);
			let day = String(newValue.getDate()).padStart(2, '0');
			let month = String(newValue.getMonth() + 1).padStart(2, '0');
			let year = newValue.getFullYear();
			const data = {
				servicioId: '0231',
				pDate: year + '-' + month + '-' + day || '',
				agendaId: agendaId,
				nss: user?.nss || ''
			};
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const responseHorario = await makeDate24API.horario(dataEncript);
			if (responseHorario.code === '0000') {
				if (responseHorario?.data?.intervals && responseHorario.data?.intervals.length > 0) {
					let arrayHora: any = [];
					let contMes: number = 0;
					responseHorario.data.intervals.forEach((hora: any) => {
						const frm = hora.horarioInicio.split(':', 3);
						const toH = hora.horarioFin.split(':', 3);
						arrayHora[contMes] = {
							FromHour: frm[0],
							FromMinute: frm[1],
							ToHour: toH[0],
							ToMinute: toH[1],
							intervaloId: hora.intervaloId,
							cupoCanalId: hora.cupoCanalId,
						};
						contMes++;
					});
					setHorarios(arrayHora);
				} else {
					setAlert({
						show: true,
						message: MESSAGES_ERROR.errorHorario,
						severity: 'error',
					});
				}
			} else {
				setLoading(false);
				setAlert({
					show: true,
					message: responseHorario.message || MESSAGES_ERROR.errorService,
					severity: 'error',
				});
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || MESSAGES_ERROR.errorService,
				severity: 'error',
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const disabledDays = (date: Date) => {
		let disabled = true;
		if (meses.length > 0) {
			const dayCalender = moment(date);
			meses.forEach((day) => {
				const valid = dayCalender.isBetween(
					moment(day.startday),
					moment(day.endday)
				);
				if (
					day.typeday !== 'baja' &&
					(valid ||
						dayCalender.isSame(moment(day.startday)) ||
						dayCalender.isSame(moment(day.endday)))
				) {
					disabled = false;
				}
			});
		} else {
			disabled = true;
		}
		return disabled;
	};

	const renderWeekPickerDay = (
		date: Date,
		selectedDates: Array<Date | null>,
		pickersDayProps: PickersDayProps<Date>
	) => {
		const dayCalender = moment(date);
		let selectDate: DaysFormat = {
			startday: null,
			endday: null,
			typeday: null,
		};

		meses.forEach((day) => {
			if (
				dayCalender.isBetween(
					moment(day.startday),
					moment(day.endday)
				) ||
				dayCalender.isSame(moment(day.startday)) ||
				dayCalender.isSame(moment(day.endday))
			) {
				selectDate = {
					startday: moment(day.startday).format('DD/MM/YYYY'),
					endday: moment(day.endday).format('DD/MM/YYYY'),
					typeday: day.typeday,
				};
			}
		});

		const isFirstDay =
			dayCalender.format('DD/MM/YYYY') === selectDate.startday
				? true
				: false;
		const isLastDay =
			dayCalender.format('DD/MM/YYYY') === selectDate.endday
				? true
				: false;

		return (
			<CustomPickersDay
				{...pickersDayProps}
				disableMargin
				isFirstDay={isFirstDay}
				isLastDay={isLastDay}
				typeDay={selectDate.typeday}
			/>
		);
	};

	const acceptCita = async () => {
		//serviceId = 19
		setLoading(true);
		setModalAgendar(false)
		setCitaConfirm(true);
		if (reprogramar === true) {
			try {
				let day = String(fechaCita?.getDate()).padStart(2, '0');
				let month = String((fechaCita?.getMonth() || 0) + 1).padStart(2, '0');
				let year = fechaCita?.getFullYear();
				const hr = horarioForm.split(':', 3);
				const data = {
					citaId: dataConfirm?.id,
					motivoReprogracion: '',
					tipoReprogramacion: '1',
					pCentroAtencionId: cedisForm,
					pServicioId: '0231',
					pDate: `${year}-${month}-${day} ${hr[0]}:${hr[1]}:00`,
					pHour: hr[0],
					pMinute: hr[1],
					pReservationId: responseCita.data.appointmentReservationId,
					agendaId: agendaId,
					intervaloId: dataHorario.intervaloId,
					cupoCanalId: dataHorario.cupoCanalId,
					canalId: '4',
					numeroDeCupo: '1',
					nss: user?.nss || '',
				}
				const dataEncript = await encodeAllJSONData(
					{
						data: JSON.stringify(data),
					},
					user?.k || ''
				);
				const responseRsv = await makeDate24API.actualizar(dataEncript)
				if (responseRsv.code === '0000' && responseRsv.data) {
					regBitacoraNotOld(makeDate24API.schemeUrl, data);
					setLoading(false);
					setDataConfirm(responseRsv.data);
					setAlert({
						show: false,
						message: '',
						severity: 'error',
					});
					if (responseRsv.data?.citaId) {
						//handlecitasActuales(user?.nss);
						setIdCancel(responseRsv?.data?.citaId)
						setPage(4)
					}
				} else {
					setCitaConfirm(false);
					setLoading(false);
					setAlert({
						show: true,
						message: responseRsv.message || 'Por el momento el servicio no está disponible. Intenta más tarde',
						severity: 'error',
					});
				}
			} catch (error: any) {
				setAlert({
					show: true,
					message: error.description
						? error.description
						: 'Por el momento el servicio no está disponible. Intenta más tarde',
					severity: 'error',
				});
				setCitaConfirm(false);
				setLoading(false);
			} finally {
				setTimerActive(false);
				setTimerFinal(false);
				setLoading(false);
			}

		} else {
			try {
				let day = String(fechaCita?.getDate()).padStart(2, '0');
				let month = String((fechaCita?.getMonth() || 0) + 1).padStart(2, '0');
				let year = fechaCita?.getFullYear();
				const hr = horarioForm.split(':', 3);
				const data = {
					pCentroAtencionId: cedisForm,
					pServicioId: '0231',
					pDate: `${year}-${month}-${day} ${hr[0]}:${hr[1]}:00`,
					pHour: hr[0],
					pMinute: hr[1],
					pReservationId: responseCita.data.appointmentReservationId,
					agendaId: agendaId,
					intervaloId: dataHorario.intervaloId,
					cupoCanalId: dataHorario.cupoCanalId,
					canalId: '4',
					numeroDeCupo: '1',
					noCaso: dataCaso.noCaso || 'Sin caso',
					nss: user?.nss || '',
				}
				const dataEncript = await encodeAllJSONData(
					{
						data: JSON.stringify(data),
					},
					user?.k || ''
				);
				const responseRsv = await makeDate24API.crear(dataEncript);
				if (responseRsv.code === '0000' && responseRsv.data) {
					regBitacoraNotOld(makeDate24API.schemeUrl, data);
					setLoading(false);
					setDataConfirm(responseRsv.data);
					getDocuments(form.cedis, '0231');
					setAlert({
						show: false,
						message: '',
						severity: 'error',
					});
					if (responseRsv.data?.citaId) {
						//handlecitasActuales(params.nss);
						setIdCancel(responseRsv?.data?.citaId)
						setPage(4)
					}
				} else {
					setLoading(false);
					setAlert({
						show: true,
						message: responseRsv.message || 'Por el momento el servicio no está disponible. Intenta más tarde',
						severity: 'error',
					});
				}
			} catch (err: any) {
				let errorMessage = err.description ? err.description : err.des ? err.des : 'Por el momento el servicio no está disponible. Intenta más tarde'
				setLoading(false);
				setAlert({
					show: true,
					message: errorMessage,
					severity: 'error',
				});
				setCitaConfirm(false);
			}
		}
	};

	const handleReservar = async () => {
		try {
			setLoading(true);
			let day = String(fechaCita?.getDate()).padStart(2, '0');
			let month = String((fechaCita?.getMonth() || 0) + 1).padStart(2, '0');
			let year = fechaCita?.getFullYear();
			const hr = horarioForm.split(':', 3);
			const data: any = {
				pRequestedDate: `${year}-${month}-${day} ${hr[0]}:${hr[1]}:00`,
				servicioId: '0231',
				agendaId: agendaId,
				numeroCupo: '1',
				nss: user?.nss || '',
			}
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const responseRsv = await makeDate24API.reservar(dataEncript)
			if (responseRsv.code === '0000' && responseRsv?.data) {
				regBitacoraNotOld(makeDate24API.schemeUrl, data);
				setResponseCita(responseRsv);
				setTimerActive(true);
				setLoading(true);
			} else {
				setLoading(false);
				setAlert({
					show: true,
					message: responseRsv.message || 'Por el momento el servicio no está disponible. Intenta más tarde',
					severity: 'error',
				});
			}
		} catch (error) {
			setAlert({
				show: true,
				message: MESSAGES_ERROR.errorService,
				severity: 'error',
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const resetTimer = () => {
		setTime(defaulTime);
	};

	const cancelCita = () => {
		setTimerActive(false);
	};

	const handleCancelar = async () => {
		setModalCancelar(false);
		setLoading(true);
		try {
			const data = {
				pAppointmentId: idCancel ? idCancel : dataConfirm.id,
				motivoCancelacion: '',
				nss: user?.nss || ''
			}
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const responseRsv = await makeDate24API.cancelar(dataEncript)
			if (responseRsv.code === '0000') {
				setModalCancelada(true);
			} else {
				setLoading(false);
				setAlert({
					show: true,
					message: responseRsv.message || MESSAGES_ERROR.errorService,
					severity: 'error',
				});
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || MESSAGES_ERROR.errorService,
				severity: 'error',
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const onDownloadPDFTicket = async (value: any, bash: any) => {
		const arrayItemsSinBullet = doctosRequeridos ? doctosRequeridos
			.split('<br/>')
			.map((item: any) => item.replace(/^•\s*/, ''))
			.filter((item: any) => item !== 'Cualquier de los siguientes documentos dependiendo cómo se llevará a cabo el trámite') : '';
		try {
			setLoading(true);
			const params = {
				service: citaActual.serviceDes || '',
				estatus: '',
				fechaEstatus: value.creationDate,
				codigoCita: value.appointmentCode || '',
				fechaCita: value.date || new Date(),
				horarioCita: value.date || new Date(),
				organizationDes: value.organizationDes || '',
				organizationLocation: value.organizationLocation || '',
				listadoFormateado: arrayItemsSinBullet || [''],
				numeroCaso: citaActual.caso || dataCaso.noCaso || 'Sin caso',
				CreationDate: value.creationDate,
				nss: user?.nss || '',
			};
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(params),
				},
				user?.k || ''
			);
			const responsePDF = await makeDate24API.downloadPDF(dataEncript);
			if (responsePDF.code === '0000' && responsePDF.data?.base64) {
				downloadPDF({
					payload: responsePDF.data.base64,
					name: `Haz_tu_cita-${formatDate('P')}`,
				});
			} else {
				setAlert({
					show: true,
					message: responsePDF.message || 'Ocurrio un error al descargar el PDF, intentalo de nuevo.',
					severity: 'error',
				})
			}
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const getDocuments = async (
		pOrganizationCode: string,
		pServiceId: string
	) => {
		setLoading(true);
		let doctosRequerido: string = '';
		const data = {
			pOrganizationCode: pOrganizationCode,
			pServiceId: pServiceId,
			nss: user?.nss || ''
		};
		const dataEncript = await encodeAllJSONData(
			{
				data: JSON.stringify(data),
			},
			user?.k || ''
		);
		makeDate24API.documentosRequeridos(dataEncript)
			.then((response: any) => {
				if (response.code === '0000') {
					const listado = saltosDeLinea(
						response?.data?.requirementsList[0]?.requirementText
					);
					if (listado && listado.length > 0) {
						doctosRequerido = response?.data?.requirementsList[0]?.requirementText.replace(
							'style="color:windowtext"',
							"style={{color:'windowtext'}}"
						) || '';
						doctosRequerido = doctosRequerido.replace(/&bull;• /g, '• ');
						doctosRequerido = doctosRequerido.replace(/&bull;/g, '• ');
					}
				}
				setDoctosRequeridos(doctosRequerido);
			})
			.catch((err) => {
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const saltosDeLinea = (docs: any) => {
		let str = docs;
		let search = '';
		let replacer;

		str = str.replace(replacer, '\n');

		search = '</p>';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '');

		search = '<br />';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '\n');

		search = '&nbsp;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, ' ');

		search = '&gt;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '->');

		search = '&bull;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '');
		str = str.split('<p>');

		str = str.map((a: any) => {
			return a.trim();
		});

		return str;
	};

	const creaFondoAhorro = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			let fecha = dataPayment.date
			const paramsData = {
				nss: user?.nss,
				correo: user?.email,
				claseOperacion: dataPayment.type,
				nombreCompleto: user?.given_name,
				credito: user?.credit,
				monto: dataPayment.amount,
				anioPago: fecha.slice(0, 4),
				mesPago: fecha.substring(5, 7),
				diaPago: fecha.substring(8, 10),
				referenciaPago: dataPayment.payment,
				folioPago: dataPayment.folio,
				emisora: dataPayment.banco,
				tipoPago: '',
				nota: '',
				estatusCreditoCorrecto: dataPayment.creditCorrect,
				estatusCreditoIncorrecto: dataPayment.creditCorrect !== '0' ? '0' : '1',
				detallePago: dataPayment.detallePago
			}
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData), key
			);
			PaymentRequestAPI.crearFondoAhorro
				.post<
					{
						data: string;
					},
					ResponseCaso
				>('', {
					data: dataSendEncrypt
				})
				.then((res: ResponseCaso) => {
					if (res.code === '0000') {
						consultaCaso()
					} else {
						setAlert({
							show: true,
							message: res.message
								? res.message
								: 'Por el momento el servicio no está disponible. Intenta más tarde.',
							severity: 'error',
						});
						setLoading(false)
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
					setLoading(false)
				})
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false)
		}
	}

	const consultaCaso = async () => {
		const keyRes = await keyAPI.get<'', { k: string }>();
		const key = keyRes['k'].toString();
		setLoading(true);
		try {
			const paramsData = {
				valida: '0',
				claseOperacion: '',
				nss: user?.nss,
			}
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData), key
			);
			PaymentRequestAPI.consultaCaso
				.post<
					{
						data: string
					},
					ResponseCaso
				>('', {
					data: dataSendEncrypt
				})
				.then((res: ResponseCaso) => {
					const resCode = res.code;
					if (resCode === '0000' && res.data?.caso?.noCaso) {
						setDataCaso(res.data.caso)
						setPageDate(true)
					} else {
						setAlert({
							show: true,
							message: res.message
								? res.message
								: 'Por el momento el servicio no está disponible. Intenta más tarde.',
							severity: 'error',
						});
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
		}
	}

	return (
		<Box>
			<HeaderList title="Aclaración de pagos" />
			<Paper
				sx={{
					pt: 5,
					pb: 5,
					pr: matches ? 5 : 15,
					pl: matches ? 5 : 15,
				}}
			>
				{!pageDate && (
					<div id="radioOptions">
						<div className={classes.message}>
							{!citaActual.id && (
								<span style={{ lineHeight: '40px' }}>
									<b>El pago que realizaste se encuentra aplicado a otro crédito, {' '}</b>
									haz una cita para solicitar una aclaración.{' '}
									<br />
									Si tienes varios pagos a aclarar, lleva a
									tu cita todos los comprobantes a aclarar.
								</span>
							)}
							{citaActual.id && (
								<span>
									{dataCaso.estatusDetalle.descripcion}
								</span>
							)}
						</div>
						<div
							style={{
								width: '600px',
								margin: '40px auto 30px',
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<CustomButton
								label="Salir"
								onClick={() => setPage(1)}
								variant="outlined"
								styles={{
									width: 260,
									height: 40,
									margin: '0 auto',
								}}
							/>
							<CustomButton
								label={
									citaActual.id ? 'Ver cita' : 'Hacer cita'
								}
								onClick={() => {
									if (citaActual.id) {
										setCitaConfirm(true);
									} else {
										creaFondoAhorro()
									}
								}}
								variant="solid"
								styles={{
									width: 260,
									height: 40,
									margin: '0 auto',
								}}
							/>
						</div>
					</div>
				)}
				{pageDate && (
					<div className={classes.form}>
						{!citaConfirm && (
							<div>
								<Grid container columns={12}>
									<Grid item xs={12} sm={12} md={3} lg={3}>
										<h3
											style={{
												fontSize: matches ? 14 : 16,
												color: '#283990',
											}}
										>
											Haz una cita
										</h3>
									</Grid>
								</Grid>
								<hr
									style={{
										opacity: 0.2,
									}}
								/>
							</div>
						)}

						{citaConfirm && (
							<h2
								style={{
									color: '#293990',
									textAlign: 'center',
									paddingBottom: 20,
								}}
							>
								Cita confirmada
							</h2>
						)}

						<Grid container columns={12}>
							<Grid item xs={12} sm={12} md={3} lg={3}>
								<h3
									style={{
										fontSize: matches ? 14 : 16,
									}}
								>
									Número de caso
								</h3>
							</Grid>
							<Grid item xs={12} sm={12} md={9} lg={9}>
								<p
									style={{
										fontSize: matches ? 14 : 16,
									}}
								>
									{dataCaso.noCaso}
								</p>
							</Grid>
						</Grid>
						<hr
							style={{
								opacity: 0.2,
							}}
						/>

						<Grid container columns={12}>
							<Grid item xs={12} sm={12} md={3} lg={3}>
								<h3
									style={{
										fontSize: matches ? 14 : 16,
									}}
								>
									Fecha de solicitud
								</h3>
							</Grid>
							<Grid item xs={12} sm={12} md={9} lg={9}>
								<p
									style={{
										fontSize: matches ? 14 : 16,
									}}
								>
									{formatDate(dataCaso?.fecha, '/')}
								</p>
							</Grid>
						</Grid>
						<hr
							style={{
								opacity: 0.2,
							}}
						/>

						<Grid container columns={12}>
							<Grid item xs={12} sm={12} md={3} lg={3}>
								<h3
									style={{
										fontSize: matches ? 14 : 16,
									}}
								>
									Servicio
								</h3>
							</Grid>
							<Grid item xs={12} sm={12} md={9} lg={9}>
								<p
									style={{
										fontSize: matches ? 14 : 16,
									}}
								>
									{dataCaso.descripcion}
								</p>
							</Grid>
						</Grid>
						<hr
							style={{
								opacity: 0.2,
							}}
						/>

						<Grid container columns={12}>
							<Grid item xs={12} sm={12} md={3} lg={3}>
								<h3
									style={{
										fontSize: matches ? 14 : 16,
									}}
								>
									Estatus
								</h3>
							</Grid>
							<Grid item xs={12} sm={12} md={9} lg={9}>
								<p
									style={{
										fontSize: matches ? 14 : 16,
									}}
								>
									{dataCaso.estatusDetalle.estatus}
								</p>
							</Grid>
						</Grid>
						<hr
							style={{
								opacity: 0.2,
							}}
						/>

						<Grid container columns={12}>
							<Grid item xs={12} sm={12} md={3} lg={3}>
								<h3
									style={{
										fontSize: matches ? 14 : 16,
									}}
								>
									Fecha de estatus
								</h3>
							</Grid>
							<Grid item xs={12} sm={12} md={9} lg={9}>
								<p
									style={{
										fontSize: matches ? 14 : 16,
									}}
								>
									{formatDate(dataCaso?.fechaEstatus, '/')}
								</p>
							</Grid>
						</Grid>
						<hr
							style={{
								opacity: 0.2,
							}}
						/>

						<Grid container columns={12}>
							<Grid item xs={12} sm={12} md={3} lg={3}>
								<h3
									style={{
										fontSize: matches ? 14 : 16,
									}}
								>
									Descripción
								</h3>
							</Grid>
							<Grid item xs={12} sm={12} md={9} lg={9}>
								<p
									style={{
										fontSize: matches ? 14 : 16,
									}}
								>
									{dataCaso.estatusDetalle.descripcion}
								</p>
							</Grid>
						</Grid>
						<hr
							style={{
								opacity: 0.2,
							}}
						/>

						<Grid container columns={12}>
							<Grid item xs={12} sm={12} md={3} lg={3}>
								<h3
									style={{
										fontSize: matches ? 14 : 16,
										marginBottom: matches ? 0 : 16,
									}}
								>
									Documentos necesarios
								</h3>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={4.5}
								lg={4.5}
								sx={{ pt: 2, pb: matches ? 1 : 2 }}
							>
								<div
									style={{
										display: 'grid',
										gridTemplateColumns: '1fr',
										fontSize: matches ? 14 : 16,
									}}
								>
									<div>
										<h4 style={{ marginTop: 0 }}>
											Identificación oficial vigente
										</h4>
										<p className={classes.p}>
											INE / IFE del titular
										</p>
										<p className={classes.p}>
											Pasaporte del titular
										</p>
										<p className={classes.p}>
											Cartilla militar del titular
										</p>
										<p className={classes.p}>
											Cédula profesional del titular
										</p>
										<p className={classes.p}>Pasaporte</p>
										<p className={classes.p}>
											Forma de calidad migratoria - 2
										</p>
										<p className={classes.p}>
											Forma de calidad migratoria - 3
										</p>
									</div>
								</div>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={4.5}
								lg={4.5}
								sx={{ pt: 2, pb: matches ? 1 : 2 }}
							>
								<div
									style={{
										display: 'grid',
										gridTemplateColumns: '1fr',
										fontSize: matches ? 14 : 16,
									}}
								>
									<div>
										<h4 style={{ marginTop: 0 }}>
											Otros documentos obligatorios
										</h4>
										<p className={classes.p}>
											Comprobante(es) de pago
										</p>
									</div>
								</div>
							</Grid>
						</Grid>
						<hr
							style={{
								opacity: 0.2,
							}}
						/>

						{citaConfirm && (
							<div>
								<Grid container columns={12}>
									<Grid item xs={12} sm={12} md={3} lg={3}>
										<h3
											style={{
												fontSize: matches ? 14 : 16,
											}}
										>
											Código de cita
										</h3>
									</Grid>
									<Grid item xs={12} sm={12} md={9} lg={9}>
										<p
											style={{
												fontSize: matches ? 14 : 16,
											}}
										>
											{dataConfirm?.appointmentCode}
										</p>
									</Grid>
								</Grid>
								<hr
									style={{
										opacity: 0.2,
									}}
								/>

								<Grid container columns={12}>
									<Grid item xs={12} sm={12} md={3} lg={3}>
										<h3
											style={{
												fontSize: matches ? 14 : 16,
											}}
										>
											Fecha de cita
										</h3>
									</Grid>
									<Grid item xs={12} sm={12} md={9} lg={9}>
										{citaActual?.date && (
											<p style={{
												fontSize: matches ? 14 : 16,
											}}>
												{getFormatDateShort(citaActual?.date)}
											</p>
										)}
									</Grid>
								</Grid>
								<hr
									style={{
										opacity: 0.2,
									}}
								/>

								<Grid container columns={12}>
									<Grid item xs={12} sm={12} md={3} lg={3}>
										<h3
											style={{
												fontSize: matches ? 14 : 16,
											}}
										>
											Horario de cita
										</h3>
									</Grid>
									<Grid item xs={12} sm={12} md={9} lg={9}>
										<p
											style={{
												fontSize: matches ? 14 : 16,
											}}
										>
											{getFormatHour(
												dataConfirm.date || undefined
											)}
										</p>
									</Grid>
								</Grid>
								<hr
									style={{
										opacity: 0.2,
									}}
								/>

								<Grid container columns={12}>
									<Grid item xs={12} sm={12} md={3} lg={3}>
										<h3
											style={{
												fontSize: matches ? 14 : 16,
												marginBottom: matches ? 0 : 16,
											}}
										>
											Ubicación
										</h3>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={4.5}
										lg={4.5}
										sx={{ pt: 2, pb: matches ? 1 : 6 }}
									>
										<div
											style={{
												display: 'grid',
												gridTemplateColumns: '1fr',
												fontSize: matches ? 14 : 16,
											}}
										>
											<span style={{ marginBottom: 10 }}>
												{
													dataConfirm?.organizationLocation
												}
											</span>
										</div>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={4.5}
										lg={4.5}
										sx={{ pt: 2, pb: matches ? 3 : 6 }}
									>
										<div
											style={{
												display: 'grid',
												gridTemplateColumns: '1fr',
												fontSize: matches ? 14 : 16,
											}}
										></div>
									</Grid>
								</Grid>

								<Grid container columns={12}>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										sx={{ pb: 4 }}
									>
										<p
											style={{
												fontSize: matches ? 14 : 16,
												marginTop: matches ? 0 : 16,
												textAlign: 'center',
												color: '#000000',
												margin: 4,
											}}
										>
											Se ha enviado esta información a tu
											correo.
										</p>

										<div
											style={{
												fontSize: matches ? 14 : 16,
												marginTop: matches ? 0 : 5,
												color: '#D1001F',
												textAlign: 'center',
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'center',
												cursor: 'pointer',
												width: '200px',
												margin: '0 auto',
											}}
											onClick={() => {
												onDownloadPDFTicket(
													dataConfirm,
													''
												);
											}}
										>
											Descargar comprobante
											<div>
												<img
													src={IconDes}
													style={{ marginLeft: 5 }}
													alt="Descarga"
												/>
											</div>
										</div>
									</Grid>
									<CustomAlert
										message={alert.message}
										severity={alert.severity}
										show={alert.show}
									/>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<div
											style={{
												textAlign: 'center',
												margin: '20px 0px',
												display: 'flex',
												flexDirection: matches
													? 'column-reverse'
													: 'inherit',
												justifyContent: 'center',
											}}
										>
											<div
												style={{
													width: matches
														? '100%'
														: 250,
													display: 'inline-flex',
												}}
											>
												<RoundBtn
													onClick={() =>
														setModalCancelar(true)
													}
												>
													Cancelar cita
												</RoundBtn>
											</div>
											<div
												style={{
													width: matches
														? '100%'
														: 250,
													display: 'inline-flex',
												}}
											>
												<RoundBtn
													variant="outlined"
													onClick={() => {
														setModal(true);
													}}
												>
													Reprogramar
												</RoundBtn>
											</div>
											<div
												style={{
													width: matches
														? '100%'
														: 250,
													display: 'inline-flex',
												}}
											>
												<RoundBtn
													variant="contained"
													onClick={() => {
														updateCaso();
													}}
												>
													Finalizar
												</RoundBtn>
											</div>
										</div>
										<div className={classes.message}>
											<span>
												¿Quieres hacer una aclaración de otros pagos?
											</span>
										</div>
										<div
											style={{
												width: matches ? '100%' : '600px',
												margin: '0px auto',
												display: matches ? 'block' : 'flex',
												justifyContent: 'space-between',
											}}
										>
											<CustomButton
												label="Solicitar aclaración"
												onClick={() => setPage(0)}
												variant="solid"
												styles={{
													width: 260,
													height: 40,
													margin: '0px auto',
												}}
											/>
										</div>
									</Grid>
								</Grid>
							</div>
						)}

						{!citaConfirm && (
							<div>
								<Grid container columns={12}>
									<Grid item xs={12} sm={12} md={3} lg={3}>
										<h3
											style={{
												fontSize: matches ? 14 : 16,
												marginBottom: matches ? 0 : 16,
											}}
										>
											Ubicación
										</h3>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={4.5}
										lg={4.5}
										sx={{ pt: 2, pb: matches ? 1 : 6 }}
									>
										<div
											style={{
												display: 'grid',
												gridTemplateColumns: '1fr',
												fontSize: matches ? 14 : 16,
											}}
										>
											<span style={{ marginBottom: 10 }}>
												Estado
											</span>
											<Select
												displayEmpty
												sx={{
													width: '95%',
													height: matches ? 40 : 50,
												}}
												name="estado"
												value={form.estado}
												onChange={handleCedis}
												renderValue={
													form.estado !== ''
														? undefined
														: () => (
															<label
																style={{
																	fontSize: 15,
																	color: '#7F7F7F',
																}}
															>
																Selecciona
																tu estado
															</label>
														)
												}
												style={{
													maxWidth: matches
														? '100%'
														: 390,
												}}
											>
												{estados.map(
													(
														element: any,
														i: number
													) => (
														<MenuItem
															key={
																element.regionCode
															}
															value={
																element.regionCode
															}
														>
															{element.regionDes}
														</MenuItem>
													)
												)}
											</Select>
										</div>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={4.5}
										lg={4.5}
										sx={{ pt: 2, pb: matches ? 3 : 6 }}
									>
										<div
											style={{
												display: 'grid',
												gridTemplateColumns: '1fr',
												fontSize: matches ? 14 : 16,
											}}
										>
											<span style={{ marginBottom: 10 }}>
												Centro de servicio
											</span>
											<Select
												displayEmpty
												sx={{
													width: '95%',
													height: matches ? 40 : 50,
												}}
												name="cedis"
												value={form.cedis}
												disabled={
													form.estado ? false : true
												}
												onChange={(e) => {
													handleMeses(
														e.target.value.toString(),
														fecha || new Date()
													);
													getDocuments(
														e.target.value.toString(),
														'0231',
													);
												}}
												renderValue={
													form.cedis !== ''
														? undefined
														: () => (
															<label
																style={{
																	fontSize: 15,
																	color: '#7F7F7F',
																}}
															>
																Selecciona
																el servicio
															</label>
														)
												}
												style={{
													maxWidth: matches
														? '100%'
														: 390,
												}}
											>
												{cedis.map(
													(
														element: any,
														i: number
													) => (
														<MenuItem
															key={
																element.organizationCode
															}
															value={
																element.organizationCode
															}
														>
															{
																element.organizationDescription
															}
														</MenuItem>
													)
												)}
											</Select>
										</div>
									</Grid>
								</Grid>
								<hr
									style={{
										opacity: 0.2,
									}}
								/>

								<Grid container columns={12}>
									<Grid item xs={12} sm={12} md={3} lg={3}>
										<h3
											style={{
												fontSize: matches ? 14 : 16,
											}}
										>
											Fecha y hora
										</h3>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={4.5}
										lg={4.5}
									>
										<div
											style={{
												display: 'flex',
												width: '100%',
												height: 280,
												justifyContent: matches
													? 'center'
													: 'normal',
											}}
										>
											<LocalizationProvider
												dateAdapter={AdapterDateFns}
												locale={es}
											>
												<StaticDatePicker
													displayStaticWrapperAs="desktop"
													openTo="day"
													onMonthChange={(
														newValue
													) => {
														setFecha(newValue);
														setFechaCita(newValue);
														handleMeses(
															form.cedis,
															newValue
														);
													}}
													minDate={new Date()}
													value={fecha}
													disabled={
														form.estado &&
															form.cedis
															? false
															: true
													}
													onChange={(newValue) => {
														setFecha(newValue);
														setFechaCita(newValue);
														handleHorario(newValue);
													}}
													renderInput={(params) => (
														<TextField
															style={{
																margin: 0,
															}}
															{...params}
														/>
													)}
													renderDay={
														renderWeekPickerDay
													}
													shouldDisableDate={
														disabledDays
													}
												/>
											</LocalizationProvider>
										</div>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={4.5}
										lg={4.5}
										sx={{ pt: 2 }}
									>
										<div
											style={{
												display: 'grid',
												gridTemplateColumns: '1fr',
												marginBottom: 40,
											}}
										>
											<span
												style={{
													marginBottom: matches
														? 5
														: 15,
												}}
											>
												Seleccionar horario
											</span>
											<Select
												displayEmpty
												name="horario"
												value={form.horario}
												onChange={(e) => {
													setForm({
														...form,
														[e.target.name]:
															e.target.value,
													});
													setHorarioForm(
														e.target.value
													);
												}}
												disabled={
													form.estado &&
														form.cedis &&
														horarios.length !== 0
														? false
														: true
												}
												renderValue={
													form.horario !== ''
														? undefined
														: () => (
															<label
																style={{
																	fontSize: 15,
																	color: '#7F7F7F',
																}}
															>
																Selecciona
																el horario
															</label>
														)
												}
												style={{
													maxWidth: '95%',
													display: 'flex',
												}}
											>
												{horarios.map(
													(
														element: any,
														i: number
													) => (
														<MenuItem
															key={'horario' + i}
															value={
																element.FromHour +
																':' +
																element.FromMinute
															}
														>
															{getHour(
																element.FromHour +
																':' +
																element.FromMinute
															)}{' '}
															-{' '}
															{getHour(
																element.ToHour +
																':' +
																element.ToMinute
															)}
														</MenuItem>
													)
												)}
											</Select>
										</div>
										<div
											style={{
												display: 'grid',
												gridTemplateColumns: '1fr',
												paddingBottom: matches ? 0 : 30,
											}}
										>
											<span
												style={{
													marginBottom: 15,
													fontWeight: 500,
													color: '#333333',
												}}
											>
												Disponibilidad
											</span>
											<ul
												style={{
													margin: 0,
													padding: 0,
													listStyle: 'none',
													marginBottom: matches
														? 20
														: 0,
												}}
											>
												<li>
													<span
														style={{
															width: 10,
															height: 10,
															border: '1px solid #293990',
															background:
																'#293990',
															borderRadius: '50%',
															display:
																'inline-block',
															marginRight: 5,
														}}
													/>
													Día seleccionado
												</li>
												<li>
													<span
														style={{
															width: 10,
															height: 10,
															border: '1px solid #3ABE00',
															background:
																'#3ABE00',
															borderRadius: '50%',
															display:
																'inline-block',
															marginRight: 5,
														}}
													/>
													Disponibilidad alta
												</li>
												<li>
													<span
														style={{
															width: 10,
															height: 10,
															border: '1px solid #EFA22A',
															background:
																'#EFA22A',
															borderRadius: '50%',
															display:
																'inline-block',
															marginRight: 5,
														}}
													/>
													Disponibilidad media
												</li>
												<li>
													<span
														style={{
															width: 10,
															height: 10,
															border: '1px solid #FF1D1D',
															background:
																'#FF1D1D',
															borderRadius: '50%',
															display:
																'inline-block',
															marginRight: 5,
														}}
													/>
													Sin disponibilidad
												</li>
												<li>
													<span
														style={{
															width: 10,
															height: 10,
															border: '1px solid #C7C6C5',
															background:
																'#C7C6C5',
															borderRadius: '50%',
															display:
																'inline-block',
															marginRight: 5,
														}}
													/>
													Inhabilitado
												</li>
											</ul>
										</div>
									</Grid>

									<CustomAlert
										message={alert.message}
										severity={alert.severity}
										show={alert.show}
									/>

									{timerActive && (
										<Grid
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
										>
											<div
												style={{
													textAlign: 'center',
													margin: '20px auto',
												}}
											>
												Tienes{' '}
												<span
													style={{
														color: 'rgb(255, 29, 29)',
													}}
												>
													0:
													{time.seconds > 9
														? time.seconds
														: '0' + time.seconds}
												</span>{' '}
												segundos para confirmar tu cita
											</div>
										</Grid>
									)}
									{timerFinal && (
										<Grid
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
										>
											<div
												style={{
													textAlign: 'center',
													margin: '20px auto',
												}}
											>
												Expiró tu tiempo para confirmar
												tu cita
											</div>
										</Grid>
									)}

									<Grid item xs={12} sm={12} md={12} lg={12}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
												marginTop: '35px',
											}}
										>
											{!timerActive && (
												<CustomButton
													label="Agendar cita"
													onClick={() => {
														handleReservar();
														resetTimer();
														setTimerFinal(false);
													}}
													disabled={
														form.estado &&
															form.cedis &&
															form.horario
															? false
															: true
													}
													variant="solid"
													styles={{
														width: 260,
														margin: matches
															? '10px auto'
															: 10,
													}}
												/>
											)}
											{timerActive && (
												<CustomButton
													label="Confirmar"
													onClick={() => { setModalAgendar(true) }}
													variant="solid"
													styles={{
														width: 260,
														height: 40,
													}}
												/>
											)}
										</div>
									</Grid>
								</Grid>
							</div>
						)}
					</div>
				)}
				<ModalLoading loading={loading} />
				<BaseModal
					open={modal}
					title="S"
					onConfirm={() => {
						setReprogramar(true);
						setCitaConfirm(false);
						setModal(false);
					}}
					onClose={() => setModal(false)}
					cancelButton={true}
					confirmLabel="Sí"
					cancelLabel="No"
					width="sm"
					children={
						<div
							style={{
								paddingTop: 30,
								paddingBottom: 10,
							}}
						>
							<h3
								style={{
									color: '#293990',
									fontSize: 26,
									display: 'flex',
									textAlign: 'center',
									justifyContent: 'center',
									margin: 0,
								}}
							>
								¿Estás seguro que quieres reprogramar tu cita?
							</h3>
						</div>
					}
				/>
				<BaseModal
					open={modalCancelar}
					title="S"
					onConfirm={() => {
						handleCancelar();
					}}
					onClose={() => setModalCancelar(false)}
					cancelButton={true}
					confirmLabel="Sí"
					cancelLabel="No"
					width="sm"
					children={
						<div
							style={{
								paddingTop: 30,
								paddingBottom: 10,
							}}
						>
							<h3
								style={{
									color: '#293990',
									fontSize: 26,
									display: 'flex',
									textAlign: 'center',
									justifyContent: 'center',
									margin: 0,
								}}
							>
								¿Estás seguro que quieres cancelar tu cita?
							</h3>
						</div>
					}
				/>
				<BaseModal
					open={modalCancelada}
					title="S"
					onConfirm={() => {
						setModalCancelada(false);
						updateCaso();
					}}
					onClose={() => {
						setModalCancelada(false)
						updateCaso();
					}}
					cancelButton={false}
					confirmLabel="Finalizar"
					width="sm"
					children={
						<div
							style={{
								paddingTop: 30,
								paddingBottom: 10,
							}}
						>
							<h3
								style={{
									color: '#293990',
									fontSize: 26,
									display: 'flex',
									textAlign: 'center',
									justifyContent: 'center',
									margin: 0,
								}}
							>
								Tu cita ha sido cancelada
							</h3>
						</div>
					}
				/>
				<BaseModal
					open={modalAgendar}
					title="S"
					onConfirm={() => {
						acceptCita();
					}}
					onClose={() => setModalAgendar(false)}
					cancelButton={true}
					confirmLabel="Sí"
					cancelLabel="No"
					width="sm"
					children={
						<div
							style={{
								paddingTop: 30,
								paddingBottom: 10,
							}}
						>
							<h3
								style={{
									color: '#293990',
									fontSize: 26,
									display: 'flex',
									textAlign: 'center',
									justifyContent: 'center',
									margin: 0,
								}}
							>
								¿Estás seguro que quieres agendar una cita?
							</h3>
						</div>
					}
				/>
			</Paper>
		</Box>
	);
};

export default MakeDate;
