import BaseModal from '../../../../components/Modal';
import theme from '../../../../config/theme';

const RegisterModal = ({ open, setOpen, onConfirm }: any) => {
	return (
		<BaseModal
			title={
				<span style={{ color: theme.palette.info.main }}>
					Registro en el programa
				</span>
			}
			onClose={() => setOpen(false)}
			confirmLabel="Confirmar"
			onConfirm={onConfirm}
			cancelButton
			open={open}
			width="xs"
		>
			<div style={{ textAlign: 'center' }}>
				Una vez que quedes registrado en el programa, tu compromiso del
				primer depósito extraordinario será el que corresponda al mes
				actual
				<p style={{ fontWeight: 700 }}>
					Confirma si deseas continuar con tu registro, de lo
					contrario espera a que se inicie el mes siguiente para hacer
					tu registro.
				</p>
			</div>
		</BaseModal>
	);
};

export default RegisterModal;
