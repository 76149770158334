import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const UseStyle = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: '10px 0px',
			'& span': {
				color: '#7E8094',
				fontSize: 16,
				paddingBottom: 5,
				display: 'block',
			},
			'& div': {
				height: 40,
			},
			'& input': {
				height: 40,
			},
		},
		inputFile: {
			display: 'flex',
			alignItems: 'center',
			'& p': {
				fontWeight: 700,
				width: 300,
				margin: 0,
			},
			'& span': {
				flexGrow: 1,
				color: '#7F7F7F',
				margin: '5px 0px',
			},
			'& label': {
				display: 'flex',
				alignItems: 'center',
				margin: '0px 5px',
				color: '#D1001F',
				userSelect: 'none',
			},
			[theme.breakpoints.down('md')]: {
				flexWrap: 'wrap',
			},
		},
	})
);
