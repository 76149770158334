import { Link } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import ApplicationProcess from './ApplicationProcess';
import MainSumary from './MainSumary';
import SelectionMovements from './SelectionMovements';
import { IAlert } from '../../interfaces/alert';
import { summarySavingsAPI } from '../../api/modules/summarySavings';
import ModalLoading from '../../components/ModalLoading';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import ModalConfirmation from '../../components/ModalConfirmation';
import { useStyles } from './styles';
import ErrorPage from './ErrorPage';

const SummarySavings = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [page, setPage] = useState(-2);
	const [modalInProcess, setModalInProcess] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const handleVerifyDecreto = async () => {
		try {
			setLoading(true);
			const { result } = await summarySavingsAPI.consultaDecreto();
			if (Number(result.codigo || '0') === 1) {
				handleValidMark();
				return;
			}
			setPage(0);
			setLoading(false);
		} catch (err) {
			setPage(-1);
			setLoading(false);
		}
	};

	const handleVerifyStatus = async () => {
		setLoading(true);
		summarySavingsAPI
			.validStatus()
			.then((res) => {
				const savedParameters = localStorage.getItem('parameters');
				switch (res?.result?.codigo || '00') {
					case '02':
						//Error el servicio no se encuentra disponible
						setAlert({
							show: true,
							message:
								res?.result?.description ||
								INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
						setDisabled(true);
						setLoading(false);
						setPage(1);
						break;
					case '03':
						//Esta en proceso
						if (savedParameters !== null) {
							setPage(2);
						} else {
							setPage(1);
						}
						setLoading(false);
						break;
					case '05':
						if (savedParameters !== null) {
							setPage(2);
						} else {
							setPage(1);
						}
						setLoading(false);
						//El ticket fue atendido - ejecuta very-file
						break;
					default:
						setDisabled(false);
						setPage(1);
						setLoading(false);
						//handleVerifyDecreto();
						break;
				}
			})
			.catch((err) => {
				setDisabled(true);
				setAlert({
					show: true,
					message: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
				setPage(1);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleValidMark = async () => {
		try {
			setLoading(true);
			const { msg } = await summarySavingsAPI.validMark();
			switch (msg?.codigo) {
				case '1':
					setModalInProcess(true);
					setLoading(false);
					setPage(1);
					break;
				case '2':
					handleVerifyStatus();
					break;
				default:
					setAlert({
						show: true,
						message: INTERNAL_MESSAGES.MOVE_NOT_FOUND,
						severity: 'error',
					});
					setDisabled(true);
					setPage(1);
					setLoading(false);
					break;
			}
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setDisabled(true);
			setPage(1);
			setLoading(false);
		}
	};

	useEffect(() => {
		handleVerifyDecreto();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout>
			<>
				<HeaderList
					title="Resumen de movimientos de mi ahorro"
					list={
						page > 0
							? [
									<p style={{ margin: 0 }}>
										Antes de consultar los movimientos de tu
										ahorro, te recomendamos{' '}
										<Link
											download
											href={require('../../assets/files/ResumenDeMovimientos_ManualDeUsuario2022_VF.pdf')}
										>
											consultar aquí
										</Link>{' '}
										el manual de usuario.
									</p>,
							  ]
							: []
					}
				/>
				{page === -1 && <ErrorPage />}
				{page === 0 && (
					<MainSumary
						setPage={setPage}
						alert={alert}
						disabledButton={disabled}
					/>
				)}
				{page === 1 && (
					<SelectionMovements
						setPage={setPage}
						alert={alert}
						setAlert={setAlert}
						disabledButton={disabled}
					/>
				)}
				{page === 2 && <ApplicationProcess setPage={setPage} />}
				<ModalLoading loading={loading} />
				<ModalConfirmation
					open={modalInProcess}
					confirmLabel="Salir"
					onConfirm={() => navigate('/inicio')}
					width="sm"
				>
					<p className={classes.titleModalAlert}>
						Existe un proceso de separación de cuenta con el NSS que
						tenemos regístrado, en cuanto concluya el trámite podrás
						consultar tus movimientos. Si requieres mayor
						información acude a tu Afore.
						<br />
						<br />
					</p>
				</ModalConfirmation>
			</>
		</Layout>
	);
};

export default SummarySavings;
