import { Paper, useMediaQuery } from '@mui/material';
import ModalLoading from '../../../components/ModalLoading';
import { HeaderList } from '../../../components/HeaderList';
import { formatDate } from '../../../utils/dates';
import { UseStyle } from '../Style';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../../components/Button';
import theme from '../../../config/theme';

const ProceedPayment = ({
	method_payment,
	showProceed,
	onSubmit,
	loading,
}: any) => {
	const classes = UseStyle();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	return (
		<>
			<ModalLoading loading={loading} />
			<HeaderList
				title="Opciones de pago"
				date={formatDate('P')}
				list={[
					'Haz tus pagos completos antes del último día del mes actual.',
					'Si vas a liquidar tu crédito, paga directamente en cualquier banco autorizado.',
				]}
			/>
			<Paper className={classes.proceedPaymentContainer}>
				<div className="info-text">
					<p>
						<span
							style={{
								color: theme.palette.info.main,
								fontWeight: 500,
							}}
						>
							Para continuar con tu proceso de pago, al presionar
							el botón “Continuar”{' '}
						</span>
						se te redireccionará a un portal operado por{' '}
						{method_payment === 'codi' ? 'CoDi' : 'BBVA'} fuera del
						sitio oficial de Mi Cuenta Infonavit, cuentas con total
						seguridad en la protección de la información que
						proporcionarás para realizar el pago. En caso de que se
						interrumpa la comunicación, inicia nuevamente la sesión
						en Mi Cuenta Infonavit.
					</p>
					<h5>¿Deseas continuar?</h5>
				</div>
				<div className="proceed-actions">
					<CustomButton
						label={'Regresar'}
						variant="outlined"
						onClick={() => showProceed(false)}
						styles={{
							maxWidth: matches ? '100%' : 200,
							margin: 10,
						}}
					/>
					<CustomButton
						label={'Continuar'}
						variant="solid"
						onClick={onSubmit}
						styles={{
							maxWidth: matches ? '100%' : 200,
							margin: 10,
						}}
					/>
				</div>
			</Paper>
		</>
	);
};

export default ProceedPayment;
