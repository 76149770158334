import { Grid, Divider } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { UseStyle } from './Style';
import CustomButton from '../../../components/Button';

interface Props {
	setOpenModal: (avg: boolean) => void;
}

const ListaBancos = [
	{
		nombre: 'Scotiabank',
		incisos: (
			<label>
				I) Paga en línea a través de ScotiaWeb <br />
				II) Acude a la sucursal de tu preferencia y dile al cajero la cantidad a
				pagar y los 10 dígitos de tu número de crédito.
			</label>
		),
	},
	{
		nombre: 'Banco Bienestar',
		incisos: (
			<label>
				I) Acude a la sucursal de tu preferencia, dale al cajero los 10 dígitos de
				tu número de crédito e indícale la cantidad a pagar.
			</label>
		),
	},
	{
		nombre: 'BanCoppel',
		incisos: (
			<label>
				I) Acude a la sucursal de tu preferencia, dale al cajero los 10 dígitos de
				tu número de crédito e indícale la cantidad a pagar.
			</label>
		),
	},
	{
		nombre: 'Citibanamex',
		incisos: (
			<label>
				I) Paga en línea en BancaNet Banamex. <br />
				II) Acude a la sucursal de tu preferencia y dile al cajero la cantidad a
				pagar y los 10 dígitos de tu número de crédito.
			</label>
		),
	},
	{
		nombre: 'BBVA',
		incisos: (
			<label>
				I) Pagar en su Banca en línea. <br />
				II) Cajeros automáticos las 24 horas de los 365 días del año. Si quieres
				hacerlo sólo necesitas los 10 dígitos de tu número de crédito; la cantidad
				a pagar se descontará del saldo de tu tarjeta de débito BBVA Bancomer.{' '}
				<br />
				III) Practicajas (cajeros receptores) de este banco las 24 horas de los
				365 días del año. Si quieres hacerlo sólo necesitas los 10 dígitos de tu
				número de crédito. <br />
				IV) Acude a la sucursal de tu preferencia dale al cajero los 10 dígitos de
				tu número de crédito e indícale la cantidad a pagar así como el número de
				convenio con el Infonavit CIE 828289.
			</label>
		),
	},
	{
		nombre: 'Santander',
		incisos: (
			<label>
				I) Paga en línea a través de Supernet. <br />
				II) Acude a la sucursal de tu preferencia y dile al cajero que deseas
				depositar en la cuenta 1234 a nombre del Infonavit. Indícale la cantidad a
				pagar y los 10 dígitos de tu número de crédito.
			</label>
		),
	},
	{
		nombre: 'HSBC',
		incisos: (
			<label>
				I) Si lo prefieres paga en Banca personal por Internet. <br />
				II) Cajeros automáticos las 24 horas de los 365 días del año. Si quieres
				hacerlo sólo necesitas los 10 dígitos de tu número de crédito, la cantidad
				a pagar se descontará del saldo de tu tarjeta de débito HSBC. <br />
				III) Acude a la sucursal de tu preferencia y dile al cajero que deseas
				depositar en el RAP 5081 a nombre de Infonavit. Indícale la cantidad a
				pagar, y cuando te pida el número de referencia, dale los 10 dígitos de tu
				número de crédito.
			</label>
		),
	},
	{
		nombre: 'Banorte',
		incisos: <label>I) Recepción en Banco en Línea o Banca Móvil Banorte.</label>,
	},
	{
		nombre: 'Banco Azteca',
		incisos: (
			<label>
				I) Acude a la sucursal de tu preferencia y dile al cajero la cantidad a
				pagar y los 10 dígitos de tu número de crédito.
			</label>
		),
	},
	{
		nombre: 'BanRegio',
		incisos: (
			<label>
				I) Acude a la sucursal de tu preferencia y dile al cajero la cantidad a
				pagar y los 10 dígitos de tu número de crédito.
			</label>
		),
	},
];

const PayBaks = ({ setOpenModal }: Props) => {
	const classes = UseStyle();

	return (
		<Grid container columns={12} spacing={1} style={{ marginTop: 50, fontSize: 15 }}>
			<Grid item xs={12} md={3}>
				<label style={{ fontWeight: 500 }}>Pago en bancos</label>
			</Grid>
			<Grid item xs={12} md={9}>
				<label style={{ fontWeight: 500 }}>Todos los bancos</label>
				<p>
					En ventanilla o a través de la Banca Electrónica, vía SPEI,{' '}
					<strong>
						haz una transferencia electrónica, con los siguientes datos:
					</strong>
				</p>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>CLABE 021180550300050811</label>
				</div>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>Banco destino: HSBC</label>
				</div>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>
						El número 5503, en el campo que pide la referencia o referencia
						numérica y que puedes distinguir porque no admite más de 7
						dígitos.
					</label>
				</div>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>
						Tu número de crédito que consta de 10 dígitos, en el campo
						nombrado como: <br />· Concepto de pago <br />· Motivo de pago{' '}
						<br />· Referencia alfanumérica entre otros
					</label>
				</div>

				<Divider style={{ margin: '10px 0px' }} />

				<label style={{ fontWeight: 500 }}>Todos los bancos</label>
				<p>
					En ventanilla o a través de la banca electrónica, vía SPEI,{' '}
					<strong>
						haz una transferencia electrónica, con los siguientes datos:
					</strong>
				</p>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>CLABE 012914002008282892</label>
				</div>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>Banco destino: BBVA</label>
				</div>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>Convenio CIE: 828289</label>
				</div>
				<div className={classes.PayStateUniteItem}>
					<CheckCircleOutlineRoundedIcon
						style={{
							fontSize: 18,
							color: '#29398E',
							borderColor: '#EDF4FF',
							marginTop: 2,
							marginRight: 5,
						}}
					/>
					<label>
						Referencia: únicamente los 10 dígitos de tu número de crédito
					</label>
				</div>

				<p style={{ color: '#000' }}>
					Al hacer tu pago por banca electrónica, por el mismo medio se te
					enviará si tu pago fue aceptado o rechazado, así como la clave de
					rastreo correspondiente.
				</p>

				{/* Lista de bancos */}
				<div className={classes.PayBaksItem}>
					<label style={{ fontWeight: 500, color: '#000' }}>Banco</label>
					<label style={{ fontWeight: 500, color: '#000' }}>
						¿Qué debo hacer?
					</label>
				</div>
				{ListaBancos.map((elemnt, index) => (
					<div
						key={index}
						className={
							index === 0 ? classes.PayBaksItemFirts : classes.PayBaksItem
						}
					>
						<label>{elemnt.nombre}</label>
						{elemnt.incisos}
					</div>
				))}
			</Grid>
			<Grid item xs={12} md={12}>
				<Divider style={{ margin: '20px 0px' }} />
			</Grid>
			<Grid item xs={12} md={12}>
				<div style={{ maxWidth: 250, margin: 'auto', marginBottom: 40 }}>
					<CustomButton
						variant="solid"
						onClick={() => setOpenModal(false)}
						label="Regresar"
					/>
				</div>
			</Grid>
		</Grid>
	);
};

export default PayBaks;
