import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			border: `solid 1px ${grey[200]}`,
			borderRadius: 3,
			background: 'white',
			width: '100%',
			'&>.phrase-container': {
				fontWeight: 500,
			},
			'&>.input-container': {
				background: grey[200],
				display: 'flex',
				justifyContent: 'space-between',
				'&>.MuiInput-root': {
					paddingLeft: 6,
				},
				'&>.reload-button': {
					borderLeft: `solid 1px ${grey[300]}`,
					height: 35,
					width: 35,
					'&:hover': {
						background: grey[300],
						cursor: 'pointer',
					},
				},
			},
		},
		textLine: {
			textDecoration: 'line-through',
			userSelect: 'none',
		},
	})
);

export const centeredItems = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};
