import { Info } from '@mui/icons-material';
import { centeredItems, useStyles } from '../../styles';
import { RoundBtn } from '../../../../../AssociateNssCredit/Styles';
import { formatMoney2 } from '../../../../../../utils/validators';
import TooltipCustom from '../../../../../../components/TooltipCustom';
import { useNavigate } from 'react-router-dom';

interface Props {
	AhorroMensual: any;
	handlerContinue: (value: string) => void;
}

const Booklet = ({ AhorroMensual, handlerContinue }: Props) => {
	const styles = useStyles();
	const navigate = useNavigate();

	return (
		<>
			<div className={styles.alignLeft}>
				Es un programa que atiende a los trabajadores de la
				agroindustria que por su comportamiento atípico laboral no
				logran obtener el puntaje necesario para poder acceder a un
				crédito del Instituto para adquirir una vivienda.
				<br />
				<br />
				El programa consiste en realizar depósitos extraordinarios que
				incrementarán tu Subcuenta de Vivienda, y deberás cumplir con
				los requisitos de puntuación para solicitar tu crédito.
				<br />
				<br />
				Los depósitos extraordinarios podrás realizarlos en ventanilla o
				a través del servicio de banca en línea de cualquier banco y
				deberán realizarse al menos durante 12 meses en forma
				consecutiva, ya que si se interrumpe el pago deberás iniciar
				nuevamente.
				<br />
				<br />
				En caso de que ya no quisieras o no pudieras continuar hasta el
				final de los 12 meses, los recursos de los depósitos
				extraordinarios que realizaste se mantendrán como parte de tu
				Saldo de Subcuenta de Vivienda y podrás disponer de ellos cuando
				solicites un crédito o bien cuando te retires.
			</div>
			<br />
			<p className={styles.alignLeft}>
				Tu ahorro mensual sería por el monto correspondiente al 30% de
				tu salario, que en tu caso corresponde a:
			</p>
			<label style={centeredItems}>
				<h5 className={styles.bookletAhorro}>
					Ahorro mensual
					<TooltipCustom
						title={
							'El monto de ahorro mensual corresponde al 30% de tu salario.'
						}
					>
						<Info
							color="primary"
							className={styles.helpPanelBooklet}
						/>
					</TooltipCustom>
				</h5>
				<span
					style={{
						fontWeight: Number(AhorroMensual) > 0 ? 'bold' : 500,
					}}
				>
					{formatMoney2(Number(AhorroMensual))}
				</span>
			</label>
			<div className="credit-request-actions-container">
				<RoundBtn variant="outlined" onClick={() => navigate('/')}>
					Regresar
				</RoundBtn>
				<RoundBtn
					variant="contained"
					onClick={() => handlerContinue('REGISTRO')}
				>
					Continuar
				</RoundBtn>
			</div>
		</>
	);
};

export default Booklet;
