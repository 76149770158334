import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import { useStyles } from "./styles/styles";

interface IProps {
	title?: string;
	subtitle?: string;
	steps?: {
		title: string;
		subtitle: string;
		total: number;
		active: number;
	} | null;
}

const Header = ({ title, subtitle, steps }: IProps) => {
	const classes = useStyles();
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		if (steps) {
			let cant = 100 / steps.total * steps.active;
			setProgress(cant);
		}
	}, [steps])

	return (
		<Box className={classes.header}>
			{!steps && (
				<>
					<div className={classes.containerFull}>
						<h2 className={classes.titleBlue}>{title}</h2>
						<p className={classes.textCenter}>{subtitle}</p>
					</div>
				</>
			)}
			{steps && (
				<>
					<div className={classes.containerFull}>
						<p className={classes.text}><b>{steps.title}</b></p>
						<p className={classes.text}>{steps.subtitle}</p>
					</div>
					<Box className={classes.progress}>
						<LinearProgress variant="determinate" value={progress} className={classes.linearProgress} />
					</Box>
					<div className={classes.total}>
						<p><b>{steps.active} de {steps.total}</b></p>
					</div>
				</>
			)}

		</Box>
	);
};

export default Header;
