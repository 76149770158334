import { useState, useEffect } from 'react';

import QuestStep1 from '../components/QuestStep1';
import QuestStep2 from '../components/QuestStep2';
import QuestStep3 from '../components/QuestStep3';
import QuestStep4 from '../components/QuestStep4';
import QuestStep5 from '../components/QuestStep5';
import QuestStep6 from '../components/QuestStep6';
import QuestStep7 from '../components/QuestStep7';
import QuestStep8 from '../components/QuestStep8';
import QuestStep9 from '../components/QuestStep9';
import QuestStep10 from '../components/QuestStep10';

const QuestionnaireSteps = ({
	questionnaireData,
	afterQuestionarySteps,
	encolaCuestionario,
	completoCuestionario,
	alert
}: any) => {
	const [step, setStep] = useState(0);
	const [quest, setQuest] = useState([] as any);
	const [nextDisabled, setNextDisabled] = useState([])

	useEffect(() => {
		if (questionnaireData.paginas) {
			setStep(parseInt(questionnaireData.paginas[0]?.pagina));
			setQuest(questionnaireData.paginas[0]?.preguntas);
		}
	}, [questionnaireData]);

	const handleChange = (newValue: any, key: any) => {
		const productos = quest.map((item: any) => {
			if (item.numero === key) {
				return {
					...item,
					respuesta: newValue,
				};
			} else {
				return item;
			}
		});
		setQuest(productos);
	};

	const nextStep = () => {
		const stepNext = step + 1;
		if (stepNext === 11) {
			completoCuestionario(quest);
		} else {
			encolaCuestionario(quest);
		}
	};

	return (
		<>
			{step === 1 && (
				<QuestStep1
					nextStep={nextStep}
					quest={quest}
					handleChange={handleChange}
					afterQuestionarySteps={afterQuestionarySteps}
					alert={alert}
				/>
			)}
			{step === 2 && (
				<QuestStep2
					nextStep={nextStep}
					quest={quest}
					handleChange={handleChange}
					afterQuestionarySteps={afterQuestionarySteps}
					alert={alert}
				/>
			)}
			{step === 3 && (
				<QuestStep3
					nextStep={nextStep}
					quest={quest}
					handleChange={handleChange}
					afterQuestionarySteps={afterQuestionarySteps}
					alert={alert}
				/>
			)}
			{step === 4 && (
				<QuestStep4
					nextStep={nextStep}
					quest={quest}
					handleChange={handleChange}
					afterQuestionarySteps={afterQuestionarySteps}
					alert={alert}
				/>
			)}
			{step === 5 && (
				<QuestStep5
					nextStep={nextStep}
					quest={quest}
					handleChange={handleChange}
					afterQuestionarySteps={afterQuestionarySteps}
					alert={alert}
				/>
			)}
			{step === 6 && (
				<QuestStep6
					nextStep={nextStep}
					quest={quest}
					handleChange={handleChange}
					afterQuestionarySteps={afterQuestionarySteps}
					alert={alert}
					setNextDisabled={setNextDisabled}
				/>
			)}
			{step === 7 && (
				<QuestStep7
					nextStep={nextStep}
					quest={quest}
					handleChange={handleChange}
					afterQuestionarySteps={afterQuestionarySteps}
					alert={alert}
					nextDisabled={nextDisabled}
				/>
			)}
			{step === 8 && (
				<QuestStep8
					nextStep={nextStep}
					quest={quest}
					handleChange={handleChange}
					afterQuestionarySteps={afterQuestionarySteps}
					alert={alert}
				/>
			)}
			{step === 9 && (
				<QuestStep9
					nextStep={nextStep}
					quest={quest}
					handleChange={handleChange}
					afterQuestionarySteps={afterQuestionarySteps}
					alert={alert}
				/>
			)}
			{step === 10 && (
				<QuestStep10
					nextStep={nextStep}
					quest={quest}
					handleChange={handleChange}
					afterQuestionarySteps={afterQuestionarySteps}
					alert={alert}
				/>
			)}
		</>
	);
};

export default QuestionnaireSteps;
