import { Paper, Box, Divider, Grid, InputAdornment } from '@mui/material';

import Header from '../../../components/Header';
import ProductsMiuc from '../../../components/ProductsMiuc';
import { RoundBtn } from '../../../components/RoundBtn/roundBtn';
import ModalLoading from '../../../../../components/ModalLoading';
import TextInput from '../../../../../components/TextInput';
import CustomButton from '../../../../../components/Button';

import { useStyles } from '../styles/styles';
import { useGeneralStyles } from '../../styles/general.styles';
import { useConstruirTable } from '../hooks/useConstruirTable';
import { rowsTitleConstruir } from '../../../utils/selectionResume.utils';
import { formatMoney2 } from '../../../../../utils/validators';
import { useAlertCustom } from '../../../hooks/useAlertCustom';
import CustomAlert from '../../../../../components/CustomAlert';
import SelectInput from '../../../../../components/SelectInput';

const Products = () => {
	const styles = useStyles();
	const generalStyle = useGeneralStyles();
	const {
		// loading,
		handleContinue,
		returnStep,
		setSelectProduct,
		selectProduct,
		// products,
		newStep,
		// handleGetAmortizationTable,
		disableButton,
		valorConstruir,
		setValorConstruir,
		handleSetDataConstruir,
		handleCalculate,
		isSearch,
		handleClearSearch,
		// productsOriginal,
		productsTable,
		alertInput,
		productsToCalculate,
		rowsTitle,
		conyuge,
		disableSelectPlazo,
		getPlazos,
		onChangePlazo,
		plazo,
		setPlazo,
		plazos,
		handleButtonCalculate,
		disableCalculateBtn,
		plazoCny,
		plazosCny,
		setPlazoCny,
	} = useConstruirTable();

	return (
		<Paper>
			<Header steps={newStep}></Header>
			<Box className={styles.container}>
				<div className={styles.centeredContent}>
					<div className={styles.inputs}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<CustomAlert
									show={alertInput.show}
									severity={alertInput.severity}
									message={alertInput.message}
								/>
							</Grid>
							<Grid item xs={12} md={8}>
								<TextInput
									startAdornment={
										<InputAdornment position="start">
											$
										</InputAdornment>
									}
									label="Necesito para construir"
									placeholder="0.00"
									name="cost"
									id="comprar-costo"
									value={valorConstruir}
									disabled={isSearch && conyuge}
									onChange={handleSetDataConstruir}
									onBlur={() => {
										if (valorConstruir) {
											if (valorConstruir === '.') {
												setValorConstruir('');
											} else {
												setValorConstruir(
													formatMoney2(
														Number(valorConstruir)
													).substring(1)
												);
											}
										}
									}}
									onFocus={() => {
										if (valorConstruir) {
											setValorConstruir(
												valorConstruir
													.toString()
													.replaceAll(',', '')
													.replaceAll(' ', '')
											);
										}
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={4}
								className={styles.bCalcular}
							>
								<CustomButton
									onClick={handleButtonCalculate}
									label="Calcular"
									variant="solid"
									disabled={isSearch || disableCalculateBtn}
								/>
								{isSearch && (
									<CustomButton
										onClick={handleClearSearch}
										label="Limpiar"
										variant="outlined"
									/>
								)}
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput
									id="plazo"
									name="plazo"
									value={plazo}
									disabled={disableSelectPlazo}
									hiddenTextSelecciona={true}
									onChange={(event) => {
										onChangePlazo(event.target.value);
										setPlazo(event.target.value);
									}}
									options={plazos}
									label="Ingresa el plazo que más te convenga"
								/>
							</Grid>
							{conyuge && (
								<Grid item xs={12} md={6}>
									<SelectInput
										id="plazoCny"
										name="plazoCny"
										value={plazoCny}
										disabled={disableSelectPlazo}
										hiddenTextSelecciona={true}
										onChange={(event) => {
											onChangePlazo(
												event.target.value,
												true
											);
											setPlazoCny(event.target.value);
										}}
										options={plazosCny}
										label="Ingresa el plazo que más te convenga"
									/>
								</Grid>
							)}
							<CustomAlert
								severity="warning"
								show={isSearch}
								message={
									'Con los datos ingresados estas son las condiciones a las que puedes acceder.'
								}
							/>
						</Grid>
					</div>
				</div>
				<Divider sx={{ margin: '20px 0px' }} />
				<ProductsMiuc
					keys={rowsTitle}
					products={productsTable}
					keyHeader={'producto'}
					selectProduct={selectProduct}
					setSelectProduct={setSelectProduct}
					textFooter="Considera que en cualquier momento de tu crédito podrás adelantar pagos o realizar la liquidación de tu crédito sin tener ninguna penalización."
					// getTablaComparativa={() => handleGetAmortizationTable()}
				/>
			</Box>
			<Divider />
			<div className={generalStyle.bottonsContainer}>
				<RoundBtn variant="outlined" onClick={() => returnStep()}>
					Regresar
				</RoundBtn>
				<RoundBtn
					disabled={disableButton}
					variant="contained"
					onClick={() => handleContinue()}
				>
					Continuar
				</RoundBtn>
			</div>
			{/* <ModalLoading loading={loading} /> */}
		</Paper>
	);
};

export default Products;
