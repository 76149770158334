import { ReactComponent as IndividualIcon } from '../../../assets/img/productsMIUC/individual.svg';
import { ReactComponent as FamiliarIcon } from '../../../assets/img/productsMIUC/familiar.svg';
import { ReactComponent as ConyugalIcon } from '../../../assets/img/productsMIUC/conyugal.svg';
import Reparaciones from '../../../assets/img/productsMIUC/casita.png';
import { ReactComponent as Asistencia } from '../../../assets/img/productsMIUC/contruir.svg';
import Constructora from '../../../assets/img/productsMIUC/ampliar.png';
import { IProductCard } from '../components/ProductCard/productCard';
import HelpPanel from '../components/HelpPanel';
import { MIUC_PRODUCTS } from '../constants/products';


export const CREDIT_OPTIONS = {
  INDIVIDUAL: 'I',
  CONYUGAL: 'C',
  FAMILIAR: 'F',
  CORRESIDENCIAL: 'R',
  SUMA_CREDITOS: 'S',
  REPARACIONES_MENORES: 'RM',
	ASISTENCIA_TECNICA: 'AT',
	CONSTRUCTORA: 'CN',
}

const allCredits: IProductCard[] = [
  {
    product: CREDIT_OPTIONS.CONYUGAL,
    icon: <ConyugalIcon />,
    title: 'Crédito Conyugal',
    description: 'Amplía tus posibilidades y las de tu pareja, sumando su monto de crédito.',
  },
  {
    product: CREDIT_OPTIONS.FAMILIAR,
    icon: <FamiliarIcon />,
    title: 'Crédito Familiar',
    description: 'Amplía tus posibilidades y las de un familiar, padres, hermanos o hijo(s), sumando su monto de crédito.',
  },
  {
    product: CREDIT_OPTIONS.CORRESIDENCIAL,
    icon: <FamiliarIcon />,
    title: 'Crédito Corresidencial',
    description: 'Amplía tus posibilidades y las de un amigo, pareja en union libre, etc, sumando su monto de crédito.',
  },
  {
    product: CREDIT_OPTIONS.SUMA_CREDITOS,
    icon: <FamiliarIcon />,
    title: 'Une tu NSS y suma créditos',
    description: 'Amplía tus posibilidades y las de tu pareja, sumando su monto de crédito.',
  }
]

const creditOptionsCompra: IProductCard[] = [
  {
    product: CREDIT_OPTIONS.INDIVIDUAL,
    icon: <IndividualIcon />,
    title: 'Crédito individual',
    description: 'Puedes comprar tu vivienda sólo con el importe de crédito que te den de manera individual.',
  },
  ...allCredits
]

const creditOptionsDefault: IProductCard[] = [
  {
    product: CREDIT_OPTIONS.INDIVIDUAL,
    icon: <IndividualIcon />,
    title: 'Crédito individual',
    description: 'Te sugerimos opciones basadas en tus posibilidades',
  },
  ...allCredits
]

export const getMappedOptions = (credit: string)=> {
  switch(credit) {
    case MIUC_PRODUCTS.COMPRA:
      return creditOptionsCompra;
    default:
      return creditOptionsDefault;
  }
}

const handleButtonClick = (event: React.MouseEvent) => {
  event.stopPropagation();
  };


export const construYoOptions: IProductCard[] = [
  {
    product: CREDIT_OPTIONS.REPARACIONES_MENORES,
    icon: <img
            src={Reparaciones}
            alt="iconoPDF"
          />,
    title: 'Reparaciones menores',
    description: <span onClick={handleButtonClick}><HelpPanel type="CONSTRUYO_RM" showInfo={true}/></span>,
  },
  {
    product: CREDIT_OPTIONS.ASISTENCIA_TECNICA,
    icon: <Asistencia />,
    title: 'Asistencia Técnica',
    description: <span onClick={handleButtonClick}><HelpPanel type="CONSTRUYO_AT" showInfo={true}/></span>,
  },
  {
    product: CREDIT_OPTIONS.CONSTRUCTORA,
    icon: <img
            src={Constructora}
            alt="iconoPDF"
          />,
    title: 'Con constructora',
    description: <span onClick={handleButtonClick}><HelpPanel type="CONSTRUYO_CN" showInfo={true}/></span>,
  },
]