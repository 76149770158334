import Modal from '../../components/Modal';
import CustomButton from '../../components/Button';
import { Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
	showModal: boolean;
	setShowModal: (avg: boolean) => void;
	setAccept: () => void;
}

const Paperless = ({ showModal, setShowModal, setAccept }: Props) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	return (
		<Modal
			onClose={() => setShowModal(false)}
			open={showModal}
			width={'sm'}
			children={
				<div style={{ textAlign: 'center', fontSize: 15 }}>
					<h2 style={{ color: 'rgb(41, 57, 144)', fontSize: 30, margin: 0 }}>
						¡Más árboles, más vida!
					</h2>

					<p>
						Al registrarse en Mi cuenta Infonavit ya no recibirás de forma
						impresa el estado de cuenta en tu domicilio, y te enviaremos
						mensualmente un correo electrónico para avisarte que ya está
						disponible en el portal.
					</p>

					<p style={{ fontWeight: 500, margin: 0 }}>¡Práctico y seguro!</p>

					<p style={{ fontWeight: 500, margin: 0, marginBottom: 40 }}>
						¡Ayúdanos a cuidar el medio ambiente al reducir el uso de papel!
					</p>

					<Divider style={{ margin: '20px 0px' }} />

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: matches ? 'column-reverse' : 'inherit',
						}}
					>
						<div
							style={{
								width: matches ? '100%' : 180,
								display: matches ? 'flex' : 'inline-flex',
								margin: '8px 10px',
							}}
						>
							<CustomButton
								label="No acepto"
								variant="outlined"
								onClick={() => setShowModal(false)}
							/>
						</div>
						<div
							style={{
								width: matches ? '100%' : 180,
								display: matches ? 'flex' : 'inline-flex',
								margin: '8px 10px',
							}}
						>
							<CustomButton
								label="Acepto"
								variant="solid"
								onClick={() => setAccept()}
							/>
						</div>
					</div>

					<p style={{ marginTop: 30 }}>
						Al no recibir respuesta se da por hecho que estás de acuerdo en
						recibir por correo electrónico el aviso de que tu estado de cuenta
						está disponible en el portal.
					</p>
				</div>
			}
		/>
	);
};

export default Paperless;
