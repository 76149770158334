import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		titleh3: {
			[theme.breakpoints.up('xs')]: {
				fontSize: 16,
				fontWeight: 'bold',
				// paddingLeft: 15,
				// paddingTop: 20,
			},
			[theme.breakpoints.up('sm')]: {
				fontSize: 18,
				fontWeight: 'bold',
			},
			[theme.breakpoints.up('md')]: {
				fontSize: 18,
				fontWeight: 'bold',
				paddingLeft: 50,
				paddingTop: 40,
			},
		},
		label: {
			marginTop: 2.3,
			[theme.breakpoints.up('xs')]: {
				fontSize: 16,
				// paddingLeft: 15,
			},
			[theme.breakpoints.up('sm')]: {
				fontSize: 16,
				// paddingLeft: 15,
			},
			[theme.breakpoints.up('md')]: {
				fontSize: 16,

				// paddingLeft: 0,
				// verticalAlign: 'top',
			},
		},
		divText: {
			[theme.breakpoints.up('xs')]: {
				display: 'flex',
				marginBottom: 12,
				alignItems: 'flex-start',
			},
			[theme.breakpoints.up('sm')]: {
				display: 'flex',
				alignItems: 'center',
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				marginBottom: 12,
				alignItems: 'flex-start',
				// flexWrap: 'nowrap',
			},
		},
		buttonContainer: {
			[theme.breakpoints.up('xs')]: {
				display: 'flex',
				flexDirection: 'column-reverse',
				justifyContent: 'center',
				alignItems: 'center',

				paddingTop: 20,
				paddingBottom: 40,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				paddingTop: 40,
				paddingBottom: 40,
			},
		},
		divInput: {
			[theme.breakpoints.up('xs')]: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				marginTop: 10,
			},
		},
		textList: {
			display: 'flex',
			alignItems: 'flex-start',
			paddingBottom: 20,
		},
		textListNew: {
			display: 'grid',
			paddingBottom: 0,
			gridTemplateColumns: '30px repeat(1, 1fr)',
		},
		pTextList: {
			fontSize: 16,
			marginTop: 5,
		},
		h4Date: {
			[theme.breakpoints.up('xs')]: {
				textAlign: 'left',
				paddingTop: 0,
				fontSize: 16,
				marginLeft: 20,

				paddingRight: 0,
				marginBottom: 0,
			},
			[theme.breakpoints.up('sm')]: {
				textAlign: 'left',
				paddingTop: 0,
				fontSize: 18,
				marginLeft: 22,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				justifyContent: 'center',
				textAlign: 'center',
				alignItems: 'center',
			},
		},
		hr: {
			opacity: 0.4,
			marginLeft: 15,
			marginRight: 20,
			marginBottom: 0,
		},
		chooseFileOK: {
			[theme.breakpoints.up('xs')]: {
				display: 'none',
				width: '30%',
			},
			[theme.breakpoints.up('sm')]: {
				display: 'none',
				width: '30%',
			},
			[theme.breakpoints.up('md')]: {
				display: 'none',
				width: '30%',
			},
		},
		pMakeDate: {
			[theme.breakpoints.up('xs')]: {
				marginTop: 15,
				marginBottom: 0,
				textAlign: 'center',
				fontSize: 14,
				width: '85%',
				marginLeft: 25,
			},
			[theme.breakpoints.up('sm')]: {
				marginTop: 0,
				marginBottom: 0,
				textAlign: 'center',
				fontSize: 14,
			},

			[theme.breakpoints.up('md')]: {
				marginTop: 20,
				marginBottom: 50,
				display: 'flex',
				justifyContent: 'center',
				marginLeft: 0,
				width: '100%',
				paddingLeft: 130,
				paddingRight: 130,
				alignItems: 'center',
				fontSize: 16,
			},
		},
		botonContainerDate: {
			[theme.breakpoints.up('xs')]: {
				width: '100%',
				display: 'flex',
				flexDirection: 'column-reverse',
				justifyContent: 'right',
				marginTop: 15,
				marginLeft: 35,
				paddingBottom: 20,
				paddingTop: 0,
			},
			[theme.breakpoints.up('sm')]: {
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				marginTop: 10,
				marginLeft: 6,
				paddingTop: 0,
			},
			[theme.breakpoints.up('md')]: {
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				marginTop: 10,
			},
		},
	})
);
