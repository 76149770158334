import { makeStyles } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { linearProgressClasses } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
	iconCircleStep: {
		marginBottom: 5,
		zIndex: 1,
		color: '#E51D76',
	},
	backContainerStep: {
		position: 'absolute',
		top: -50,
	},
	mainStep: {
		display: 'flex',
		width: '100%',
		paddingTop: 50,

		[theme.breakpoints.down('md')]: {
			paddingTop: 0,
		},
	},
	containerStep: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',

		'& > label': {
			[theme.breakpoints.down('md')]: {
				textAlign: 'center',
				fontSize: 12,
				padding: '10px 3px 0px 3px',
				fontWeight: 500,
			},
		},
	},
	pointStep: {
		width: '100%',
		position: 'absolute',
		height: 3,
		background: '#E51D76',
		top: 10,
		zIndex: 0,
	},
	logoStep: {
		width: 30,

		[theme.breakpoints.down('md')]: {
			width: 25,
			position: 'absolute',
			right: -13,
			top: 48,
			zIndex: 2,
		},
	},
	logoStepDiploma: {
		width: 50,

		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			right: -15,
			top: 45,
			zIndex: 2,
			width: 35,
		},
	},
	title: {
		margin: '0px 0px 0px 0px',
	},
	certificado: {
		display: 'flex',
		alignItems: 'center',
		background: '#F7FBFF 0% 0% no-repeat padding-box',
		border: '1px solid #293990',
		borderRadius: '4px',
		padding: '10px 30px',
		marginTop: '10px',

		'& img': {
			marginRight: '20px',
		},

		'& p': {
			color: '#293990',
			fontSize: '15px',
		},
	},
	buttonCustom: {
		width: 250,
		margin: '20px auto',

		[theme.breakpoints.down('md')]: {
			width: 'auto',
		},
	},
	alert: {
		display: 'flex',
		alignItems: 'center',
		background: '#EEF4FA 0% 0% no-repeat padding-box',
		boxShadow: '0px 1px 8px #EEF4FA80',
		borderRadius: '3px',
		padding: '5px 30px',

		'& img': {
			marginRight: '20px',
		},

		'& p': {
			color: '#293990',
			fontSize: '15px',
		},
	},
	link: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '20px 0px !important',
		fontWeight: '500',
		userSelect: 'none',
	},
	cardMedia: {
		display: 'Grid',
		gridTemplateColumns: '50px auto 70px',
		margin: '10px 0px',
		fontSize: '10px',
		userSelect: 'none',
		cursor: 'pointer',
		position: 'relative',
		borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
		paddingBottom: '10px',

		'&:last-child': {
			paddingBottom: 0,
			borderBottomColor: 'transparent',
		},

		'& iframe': {
			borderRadius: '10px',
			border: '1px solid transparent',
			pointerEvents: 'none',
		},
		'& section': {
			display: 'block',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			padding: '0px 20px',
		},
		'& section.active': {
			color: '#293990',
		},
		'& h4': {
			margin: 0,
			fontSize: '14px',
		},
		'& span': {
			background: '#FFF4DE 0% 0% no-repeat padding-box',
			color: '#FFA800',
			borderRadius: '100px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '20px',
			fontSize: '10px',
		},
		'& span.active': {
			background: '#D1FFD0 0% 0% no-repeat padding-box',
			color: '#07C100',
		},
		'& span.select': {
			background: '#E1F1FF 0% 0% no-repeat padding-box',
			color: '#307AFF',
		},
	},
	cardImagen: {
		borderRadius: '10px',
		border: '1px solid rgba(0, 0, 0, 0.1)',
		pointerEvents: 'none',
		width: '60px',
		height: '50px',
	},
	cardTitle: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',

		'& div': {
			flexGrow: 1,

			'& section': {
				display: 'flex',
				alignItems: 'center',
				fontSize: '14px',
				color: '#293990',
				margin: '0px 10px 0px 0px',

				'& label': {
					margin: '0px 10px',
				},
			},
		},
		'& img': {
			marginRight: '20px',
		},
		'& h4': {
			margin: 0,
			color: '#333333',
			fontSize: '16px',
		},
		'& p': {
			margin: 0,
			color: '#333333',
			fontSize: '13px',
		},
	},
	cardMediaInvisible: {
		display: 'none',
	},
	dividerCard: {
		display: 'block',
		position: 'absolute',
		top: 0,
		left: -15,
		width: '5px',
		height: '75%',
		backgroundColor: '#293990',
		borderRadius: '10px',
	},
	linearProgress: {
		height: '7px !important',
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor:
				theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: '#293990',
		},
	},
	linearProgres2: {
		height: '10px !important',
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: 'transparent',
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
		},
	},
	headerTitle: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '16px',
		padding: '10px 0px',

		'& section': {
			margin: '0px 10px',
			flexGrow: 1,
		},
		'& h4': {
			margin: 0,
		},
		'& p': {
			margin: 0,
		},
		'& span': {
			color: '#333333',
			fontWeight: 500,
		},
	},
	headerTitlePrimary: {
		padding: '0px 30px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 14px #00000014',
		borderRadius: '3px',
	},
}));

export const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	margin: '10px 0px',
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={
			<ExpandMoreOutlinedIcon
				sx={{ fontSize: '1.4rem', color: '#DD0528' }}
			/>
		}
		{...props}
	/>
))(({ theme }) => ({
	flexDirection: 'row',
	borderBottom: '1px solid rgba(0, 0, 0, .125)',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(180deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderBottom: '1px solid rgba(0, 0, 0, .125)',
}));
