import { Box } from '@mui/material';
import { useStyles } from './style';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

type Props = {
	children?: JSX.Element;
};

const CheckParagraph = ({ children }: Props) => {
	const style = useStyles();
	return (
		<>
			<Box
				className={style.Box_Agreement_Table}
				sx={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'top',
					}}
				>
					<CheckCircleTwoToneIcon
						color={'info'}
						style={{
							marginRight: '5px',
							height: '18px',
							marginTop: '20px',
						}}
					/>{' '}
					{children}
				</Box>
			</Box>
		</>
	);
};

export default CheckParagraph;
