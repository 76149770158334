/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid, Box } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
//Assets
import imgPension from '../../../assets/img/pensionado-banner.png';
import imgAgendaCita from '../../../assets/img/Agenda-tu-cita.png';
import imgAportaciones from '../../../assets/img/aportaciones-banner.png';
import { CheckCircleOutlined } from '@mui/icons-material';

const PensionerWithEmployment = () => {
	const classes = useStyles();

	const navigate = useNavigate();

	const itemsList = [
		{
			id: 1,
			text: 'En qué puedes usar tu crédito Infonavit.',
		},
		{
			id: 2,
			text: 'El monto y las condiciones financieras del crédito que se te puede otorgar y los',
		},
		{
			id: 3,
			text: 'Requisitos y documentos para solicitarlo.',
		},
	];

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={12} lg={4}>
				<Paper className={classes.card}>
					<div className={classes.titleCard}>
						<h3>
							¿Eres pensionado(a) por parte del IMSS, ISSSTE
							o si alguna vez también trabajaste para el Estado?
						</h3>
					</div>
					<div className={classes.textCard}>
						<p>
							Solicita la Devolución de tu ahorro del saldo de la
							subcuenta de vivienda; te sugerimos que previamente
							valides el monto de ahorro que tienes y valides que
							todos los empleadores con los que has trabajado se
							encuentren registrados.
						</p>
						<p>
							En Precalificación y puntos y conoce:
						</p>
						<div style={{ marginLeft: '0', marginRight: '0' }}>
							{itemsList.map((item) => (
								<Box key={item.id} className={classes.lineText}>
									<CheckCircleOutlined
										color="info"
										style={{
											marginRight: 5,
											width: 14,
											height: '90%',
										}}
									/>{' '}
									<span
										style={{
											marginBottom: 5,
											textAlign: 'left',
										}}
									>
										{item.text}
									</span>
								</Box>
							))}
						</div>
					</div>
				</Paper>
			</Grid>
			<Grid item sm={12} md={12} lg={8}>
				<Grid container spacing={2}>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate(
									'/mi-ahorro/devolucion-fondo-ahorro-72-92'
								)
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgPension}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/contactanos/haz-una-cita')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgAgendaCita}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>
				<Grid container spacing={2} mt={1}>
					<Grid item lg={12}>
						<div
							onClick={() =>
								navigate(
									'/mi-ahorro/haz-aportaciones-extraordinarias'
								)
							}
						>
							<img
								src={imgAportaciones}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PensionerWithEmployment;
