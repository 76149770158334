/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies
import React, { useEffect, useState } from 'react';

// Material Components
import { FormControlLabel, Box, Radio, RadioGroup } from '@mui/material';

// Components
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import CustomAlert from '../../components/CustomAlert';

// Resources
import { isNumber, emailValid } from '../../utils/expressions'; //

// Assets
import { useStyles } from './styles';

// Intefaces
import { IEventTab } from '../../interfaces/eventTab';

const DatosContactoView = (ActionsButtons: IEventTab) => {
	const [Celular, setCelular] = useState('');
	const [Correo, setCorreo] = useState('');
	const [MedioComucacion, setMedioComucacion] = useState('');

	const [errorPhone, setErrorPhone] = useState({ valid: false, msg: '' });
	const [errorEmail, setErrorEmail] = useState({ valid: false, msg: '' });
	const [errorMedioComunicacion, setErrorMedioComunicacion] = useState({
		valid: false,
		msg: '',
	});

	const handleOnChangePhone = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { value } = event.target;

		setCelular(value);
		if (!isNumber(value) || value.length < 10) {
			setErrorPhone({
				valid: false,
				msg: 'Número de Celular Incorrecto',
			});
			return;
		}
		setErrorPhone({ valid: true, msg: '' });
	};
	const handleOnChangeEmail = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { value } = event.target;

		setCorreo(value);
		if (value.length === 0) return;
		const validate = emailValid(value);
		setErrorEmail(validate);
	};
	const handleOnChangeMedioComunicacion = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { name } = event.target;
		setErrorMedioComunicacion({ valid: true, msg: '' });
		setMedioComucacion(name);
	};
	const handleOnContinuar = () => {
		ActionsButtons.onValues({ name: 'DatosContactoPhone', value: Celular });
		ActionsButtons.onValues({ name: 'DatosContactoEmail', value: Correo });
		ActionsButtons.onValues({
			name: 'DatosContactoMedioComunicacion',
			value: MedioComucacion,
		});

		ActionsButtons.onNextTab();
	};

	const continueValid = (): boolean => {
		let result: boolean = true;
		if (
			MedioComucacion === 'Por celular' &&
			Celular.toString().length > 0 &&
			errorMedioComunicacion.valid
		) {
			result = false;
		}

		if (
			MedioComucacion !== 'Por celular' &&
			Correo.toString().length > 0 &&
			errorMedioComunicacion.valid
		) {
			result = false;
		}

		return result;
	};

	useEffect(() => {
		if (ActionsButtons.getValue.email) {
			setCorreo(ActionsButtons.getValue.email);
		}
		if (ActionsButtons.getValue.phone) {
			setCelular(ActionsButtons.getValue.phone);
		}
	}, []);

	const styles = useStyles();
	return (
		<>
			<Box className={styles.container}>
				<Box>
					<TextInput
						id="Celular"
						name="Celular"
						label="Número de celular"
						placeholder=""
						value={Celular}
						onChange={handleOnChangePhone}
						error={!errorPhone.valid}
						helperText={!errorPhone.valid ? errorPhone.msg : ''}
						isRequired
						disabled
					/>
					<TextInput
						id="Correo"
						name="Correo"
						label="Correo electrónico"
						placeholder=""
						value={Correo}
						onChange={handleOnChangeEmail}
						error={!errorEmail.valid}
						helperText={!errorEmail.valid ? errorEmail.msg : ''}
						isRequired
						disabled
					/>
					<span className={styles.textGray14}>
						¿Por qué medio quieres que te mandemos el
						restablecimiento de la contraseña?
					</span>

					<RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						name="radio-buttons-group"
						sx={{ marginLeft: '30px', marginTop: '10px' }}
					>
						<FormControlLabel
							value="Por celular"
							disabled={
								!ActionsButtons.getValue.enableUpdatePhone
							}
							control={
								<Radio
									name="Por celular"
									onChange={handleOnChangeMedioComunicacion}
								/>
							}
							label="Por celular"
						/>
						<FormControlLabel
							value="Por correo electrónico"
							disabled={
								!ActionsButtons.getValue.enableUpdateEmail
							}
							control={
								<Radio
									value="Por correo electrónico"
									name="Por correo electrónico"
									onChange={handleOnChangeMedioComunicacion}
								/>
							}
							label="Por correo electrónico"
						/>
					</RadioGroup>
				</Box>
				<CustomAlert
					message={ActionsButtons.onAlert.message}
					severity={ActionsButtons.onAlert.severity}
					show={ActionsButtons.onAlert.show}
					onClose={() => {
						ActionsButtons.onClearAlert('Datos Contacto');
					}}
				/>
				<CustomAlert
					message={'Servicio no disponible temporalmente'}
					severity="error"
					show={
						!ActionsButtons.getValue.enableUpdateEmail &&
						!ActionsButtons.getValue.enableUpdatePhone
					}
				/>
			</Box>

			<div className={styles.divBotones}>
				<div className={styles.divBotonFlex}>
					<CustomButton
						onClick={ActionsButtons.onPrevTab}
						variant="outlined"
						styles={{ margin: 5, height: '40px' }}
						label="Regresar"
					/>
				</div>
				<div className={styles.divBotonFlex}>
					<CustomButton
						onClick={handleOnContinuar}
						variant="solid"
						styles={{ margin: 5 }}
						label="Continuar"
						disabled={continueValid()}
					/>
				</div>
			</div>
		</>
	);
};

export default DatosContactoView;
