import Layout from '../../../components/Layout';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/Button';

import { useStyles } from './styles';
const LayoutNotice = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Layout>
			<>
				<div className={classes.titleNoticeContainer}>
					<div>
						<h2 className={classes.titleNotice}>
							Resumen de movimientos de Mi ahorro
						</h2>
					</div>
					<div className={classes.subTitleNotice}></div>
				</div>
				<div className={classes.container}>
					<div className={classes.containerMin}>
						<h3 className={classes.containerSectionTitle}>
							Tu solicitud está siendo procesada.
						</h3>
						<p className={classes.containerSectionText}>
							El trámite podrá demorar hasta 5 minutos, ingresa de
							nuevo al servicio para visualizar el documento en
							formato PDF.
						</p>
						<div className={classes.containerSectionButtons}>
							<CustomButton
								onClick={() => navigate('/inicio')}
								variant="solid"
								styles={{
									marginTop: 70,
									width: 200,
									margin: '20px auto',
								}}
								label="Regresar"
							/>
							{/* <CustomButton
								onClick={() =>
									navigate(
										'/mi-credito/devolucion-pagos-exceso/archivos'
									)
								}
								variant="solid"
								styles={{
									marginTop: 70,
									width: 200,
									margin: '20px auto',
								}}
								disabled={false}
								label="Descargar"
							/> */}
						</div>
						<p className={classes.containerSectionText}>
							Si tienes problemas para descargar el archivo, llama
							a Infonatel al <span>55 9171 5050</span> en la
							Ciudad de México y al <span>800 008 3900</span>{' '}
							desde cualquier parte del país.
						</p>
					</div>
				</div>
			</>
		</Layout>
	);
};

export default LayoutNotice;
