import { Checkbox, FormControlLabel, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import BaseModal from '../../../../../components/Modal';
import { useStyles } from '../styles/styles';


const NoticePrivacy = ({
	modalOpenPriv,
	setModalOpenPriv,
	setProcess,
}: any) => {
	const classes = useStyles();
	const [modalButtonPriv, setModalButtonPriv] = useState(false);
	const [disabledModal, setDisabledModal] = useState(true);

	useEffect(() => {
		setModalButtonPriv(false);
	}, [modalOpenPriv]);

	const handleOnChangeModalButtonPriv = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		event.target.checked
			? setDisabledModal(false)
			: setDisabledModal(true);
		setModalButtonPriv(!event.target.checked);
	};

	const confirmButtonsAlert = () => {
		setModalOpenPriv(false);
		setProcess();
	};

	return (
		<BaseModal
			open={modalOpenPriv}
			onConfirm={confirmButtonsAlert}
			onClose={() => {
				setModalOpenPriv(false)
				setDisabledModal(true)
			}}
			cancelButton={true}
			confirmLabel="Continuar"
			cancelLabel="Cancelar"
			disableOnConfirm={disabledModal}
			hideCircularProgress={true}
			hideCloseButton={true}
			width="sm"
			children={
				<div>
					<h3 className={classes.titleModal}>
						Aviso de privacidad
					</h3>
					<>
						<div className={classes.dialogCheck}>
							<p>
								Para iniciar con el formulario debes consultar y
								aceptar el Aviso de privacidad.
							</p>
							<p>
								Consulta en el siguiente{' '}
								<Link
									target={'_blank'}
									href="https://portalmx.infonavit.org.mx/wps/wcm/connect/aff44644-0564-45cb-9215-ccc42d4bd0cd/AP[…]df?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CVID=olQ13U2"
								>
									link.
								</Link>
							</p>
							<FormControlLabel
								control={
									<Checkbox
										className={classes.formControlLabel}
										onChange={handleOnChangeModalButtonPriv}
										name="checkModal"
									/>
								}
								className={!modalButtonPriv ? classes.linkInit : ''}
								label="He leído el aviso de privacidad"
								value="checkAviso"
							/>
						</div>
					</>
				</div>
			}
		/>
	);
};

export default NoticePrivacy;
