/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Paper, useMediaQuery } from '@mui/material';
import { useStyles } from './styles';
import CustomButton from '../../components/Button';
import { useTheme } from '@mui/material/styles';
import { formatDate } from '../../interfaces/SaldAndMovement';
import ModalLoading from '../../components/ModalLoading';
import { apiDeedsDelivery } from '../../api/modules/Deeds';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { RootState } from '../../store';

interface ICaso {
	or_description?: string;
	or_fecha?: string;
	or_object_id?: string;
	or_status?: string;
	or_status_reason?: string;
	or_fecha_stat?: string;
	or_status_message?: string;
	descripcion_estatus?: string;
}

interface Props {
	caso: ICaso;
	setPage: (data: number) => void;
	setCaso: (arg: object) => void;
}

const history = [
	{
		id: 2,
		header: 'En espera de documentos',
		text: (
			<>
				Tienes 5 días hábiles para adjuntar tus documentos; de lo contrario, tu
				caso se escalará a un especialista.
			</>
		),
	},
	{
		id: 3,
		header: 'Escalado a especialista',
		text: (
			<>
				Tu caso se analiza. En 5 días hábiles,{' '}
				<strong>revisa tu caso y obtén información del avance.</strong>
			</>
		),
	},
	{
		id: 5,
		header: 'Asignar solicitud de escritura',
		text: (
			<>
				Tu caso se turnó al área administradora de expedientes de crédito. En
				aproximadamente 40 días hábiles, revisa tu caso y obtén información del
				avance.
				<br />
				<strong style={{ color: '#293990' }}>Nota:</strong> El Nivel de servicio
				puede variar dependiendo de la situación de cada crédito.
			</>
		),
	},
	{
		id: 6,
		header: 'Responsable de area',
		text: (
			<>
				'El área responsable se encuentra en proceso de localización de tu
				expediente físico de crédito. Revisa el estatus de tu caso en 20 días
				hábiles.
			</>
		),
	},
	{
		id: 7,
		header: 'Existencia escritura',
		text: (
			<>
				Tu escritura será enviada al Centro de Servicio Infonavit (Cesi) que
				seleccionaste. En 20 días hábiles, revisa tu caso y obtén información del
				avance.
				<br />
				<strong style={{ color: '#293990' }}>Nota:</strong> El Nivel de servicio
				puede variar dependiendo de la situación de cada crédito.
			</>
		),
	},
	{
		id: 8,
		header: 'En espera de cotejo',
		text: (
			<>
				Tu caso se turnó al área de cotejo de escrituras. En 15 días hábiles,
				revisa el avance de tu caso.
			</>
		),
	},
	{
		id: 9,
		header: 'Certificada y en espera de envio',
		text: (
			<>
				Tu escritura está certificada y se enviará al Archivo Nacional de
				Expedientes de Crédito (ANEC) para su registro. En 10 días hábiles, revisa
				el avance de tu caso.
			</>
		),
	},
	{
		id: 15,
		header: 'Enviado a Cesi',
		text: (
			<>
				Tu escritura se envió al Centro de servicio Infonavit (Cesi) que
				seleccionaste, se te informará cuando se encuentre en sitio. En 15 días
				hábiles, revisa tu caso y obtén información del avance.
				<br />
				<strong style={{ color: '#293990' }}>Nota:</strong> El Nivel de servicio
				puede variar dependiendo de la situación de cada crédito.
			</>
		),
	},
	{
		id: 16,
		header: 'Escrituras recibidas en CESI',
		text: (
			<>
				Te informamos que debes acudir por tu escritura al Centro de servicio
				Infonavit (Cesi) que seleccionaste. Para entregártela, debes presentar la
				siguiente documentación en original y copia, según sea el caso:
				<br />
				<ul style={{ paddingLeft: 15 }}>
					<p>
						• Si eres el titular, una identificación oficial vigente con
						fotografía, como tu credencial para votar o cédula profesional
						vigente.
					</p>
					<p>
						• Si no puedes recoger tu escritura, la persona que te represente,
						debe contar con poder notarial otorgado a su favor, así como la
						identificación oficial vigente con fotografía de ambos.
					</p>
					<p>
						• Si el titular es declarado en estado de interdicción, el tutor
						deberá presentar, la sentencia donde se le otorgue ese carácter,
						el acuerdo que decreta que ha causado ejecutoria, así como
						identificación oficial vigente con fotografía de ambos.
					</p>
					<p>
						• Si el titular falleció, en caso de sucesión testamentaria o
						intestamentaría, la persona que solicite la escritura debe
						presentar el documento judicial o notarial en el que conste su
						designación, aceptación, protesta y discernimiento del cargo de
						albacea, así como su credencial para votar vigente con fotografía.
						O bien, si eres beneficiario debes presentar copia certificada del
						laudo, donde se te reconozca como beneficiario, así como tu
						credencial para votar vigente con fotografía.
					</p>
					<p>
						• Si tienes un crédito conyugal, una identificación oficial
						vigente con fotografía de ambos cónyuges, como la credencial para
						votar o cédula profesional. La escritura la podrá recoger el
						titular o cotitular del crédito.
					</p>
					<p>
						• Si tienes un crédito Infonavit-Fovissste, la escritura la
						entrega el notario.
					</p>
				</ul>
				Te informamos que debes acudir por tu escritura al Centro de servicio
				Infonavit (Cesi) que seleccionaste. Para entregártela, debes presentar la
				siguiente documentación en original y copia, según sea el caso.
			</>
		),
	},
	{
		id: 17,
		header: 'Escritura entregada al solicitante',
		text: (
			<>
				<strong style={{ color: '#293990' }}>¡Felicidades!</strong>
				<br />
				La escritura fue devuelta al Archivo Nacional de Expedientes de Crédito
				(ANEC) del Infonavit.
			</>
		),
	},
	{
		id: 18,
		header: 'Devolución de escrituras al ANEC',
		text: (
			<>
				La escritura fue devuelta al Archivo Nacional de Expedientes de Crédito
				(ANEC) del Infonavit.
			</>
		),
	},
	{
		id: 19,
		header: 'Cerrado',
		text: (
			<>
				<p style={{ fontSize: 16, color: '#293990' }}>
					Escrituras Entregadas con Acuse en ANEC
				</p>
				Tu caso se cerró. Nuestro sistema identificó que tu escritura ya fue
				entregada, generándose el acuse de recibo correspondiente y el documento
				de identificación de la persona que la recibió. Para mayor/más
				información, comunícate a Infonatel, al 55 9171 5050 en la CDMX, o al 800
				008 3900 sin costo, desde cualquier localidad del país.
			</>
		),
	},
];

const NoDocuments = ({ setPage, setCaso, caso }: Props) => {
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [loading, setLoading] = useState(false);
	const [casoNumber, setCasoNumber] = useState(0);

	useEffect(() => {
		const req = async () => {
			try {
				setLoading(true);
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const nss_base64 = await encrypt(user?.nss.toString() || '', key);
				const { result } = await apiDeedsDelivery.getConsultaCaso(nss_base64);
				if (result?.existe_caso_abierto === true) {
					if (result?.caso) {
						setCaso(result.caso);
					}
				}
				setCasoNumber(
					Number(caso.or_status?.substring(1, caso.or_status.length))
				);
			} catch (error) {
			} finally {
				setLoading(false);
			}
		};
		req();
	}, []);

	return (
		<Paper
			style={{
				paddingLeft: matches ? 20 : 50,
				paddingRight: matches ? 15 : 50,
				paddingTop: matches ? 30 : 30,
				paddingBottom: matches ? 0 : 10,
			}}
		>
			<ModalLoading loading={loading} />

			<h3
				style={{
					color: '#293990',
					fontSize: 26,
					display: matches ? 'none' : 'block',
					textAlign: 'center',
					margin: 0,
					marginTop: 15,
					marginBottom: 40,
				}}
			>
				Gracias por utilizar los servicios digitales del Infonavit
			</h3>

			<Grid
				container
				columns={12}
				sx={{ display: 'flex', flexDirection: matches ? 'column' : 'row' }}
			>
				<Grid item xs={12} md={3}>
					<h3 style={{ fontSize: 18, margin: 0 }}>Número de caso</h3>
				</Grid>
				<Grid item xs={12} md={9} style={{ textAlign:'right'}}>
					<label>{caso.or_object_id}</label>
				</Grid>
				<Grid item xs={12} md={12}>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />
				</Grid>
				<Grid item xs={12} md={3}>
					<h3 style={{ fontSize: 18, margin: 0 }}>Fecha de solicitud</h3>
				</Grid>
				<Grid item xs={12} md={9} style={{ textAlign:'right'}}>
					<label>{formatDate(caso.or_fecha, '/')}</label>
				</Grid>
				<Grid item xs={12} md={12}>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />
				</Grid>
				<Grid item xs={12} md={3}>
					<h3 style={{ fontSize: 18, margin: 0 }}>Servicio</h3>
				</Grid>
				<Grid item xs={12} md={9} style={{ textAlign:'right'}}>
					<label>{caso.or_description}</label>
				</Grid>
				<Grid item xs={12} md={12}>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />
				</Grid>
				<Grid item xs={12} md={3}>
					<h3 style={{ fontSize: 18, margin: 0 }}>Estatus</h3>
				</Grid>
				<Grid item xs={12} md={9} style={{ textAlign:'right'}}>
					<label>{caso.descripcion_estatus}</label>
				</Grid>
				<Grid item xs={12} md={12}>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />
				</Grid>
				<Grid item xs={12} md={3}>
					<h3 style={{ fontSize: 18, margin: 0 }}>Fecha de estatus</h3>
				</Grid>
				<Grid item xs={12} md={9} style={{ textAlign:'right'}}>
					<label>{formatDate(caso.or_fecha_stat, '/')}</label>
				</Grid>
				<Grid item xs={12} md={12}>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />
				</Grid>
			</Grid>

			<h3 style={{ fontSize: 18, marginBottom: 30 }}>
				El proceso de tu trámite es el siguiente:
			</h3>

			<div style={{ display: 'block', margin: 'auto', maxWidth: 800 }}>
				{history
					.filter((hist) => hist.id <= casoNumber)
					.map((element, index) => (
						<div key={index}>
							<div
								style={{
									display: 'block',
								}}
							>
								<h3 className={classes.label} style={{ margin: 0 }}>
									{element.header}
								</h3>
								<p className={classes.pTextList}>{element.text}</p>
							</div>
						</div>
					))}

				<div className={classes.buttonContainer}>
					<CustomButton
						label="Finalizar"
						onClick={() => {
							setPage(0);
						}}
						variant="solid"
						styles={{
							maxWidth: 282,
							height: 40,
						}}
					/>
				</div>
			</div>
		</Paper>
	);
};

export default NoDocuments;
