import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Divider, Paper } from '@mui/material';
import { blue } from '@mui/material/colors';
import { HeaderList } from '../../../../components/HeaderList';
import ModalLoading from '../../../../components/ModalLoading';
import CustomAlert from '../../../../components/CustomAlert';
import CustomButton from '../../../../components/Button';
import AdditionalInfoReason from '../../views/AdditionalInfoReason';
import ComplaintReason from '../../views/ComplaintReason';
import Summary from '../../views/Summary';
import { apiFollowComplaint } from '../../../../api/modules/followComplaint';
import { encrypt } from '../../../../utils/encrypt';
import { formatDate } from '../../../../utils/dates';
import { defaultComplaintForm } from '../utils';
import { RootState } from '../../../../store';
import { defaultResponse, pattern } from '../../utils';
import { useStyles } from '../../styles';
import { keyAPI } from '../../../../api/modules/key';
import { useBitacora } from '../../../../hooks/useBitacora';

const LivingPlaceTroubles = ({ setSelectedComplaint, tipificacion }: any) => {
	const { regBitacoraNotOld } = useBitacora();
	const { credit, user } = useSelector((state: RootState) => ({
		credit: state.credit.credit,
		user: state.session.user,
	}));
	const classes = useStyles();
	const navigation = useNavigate();
	const [step, setStep] = useState<number>(1);
	const [complaintForm, setComplaintForm] = useState(defaultComplaintForm);
	const [loader, setLoader] = useState(false);
	const [alert, setAlert] = useState('');
	const [resp, setResp] = useState(defaultResponse);

	const createComplaint = async () => {
		try {
			setLoader(true);
			const data1 = {
				...complaintForm,
				credito: credit,
				nss: user?.nss || '',
				tipificacion,
				claseOperacion: 'ZQUE',
				fechaOcurrido: formatDate('P', complaintForm.fechaOcurrido),
			};

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const dataEncripted = await encrypt(JSON.stringify(data1) || '', key);
			const result = await apiFollowComplaint.crearCaso(dataEncripted);
			if (result.code && result.code === '00000') {
				regBitacoraNotOld(apiFollowComplaint.schemeUrl, data1);
				const data = {
					nss: user?.nss || '',
					estatus: 'B',
					claseOperacion: 'ZQUE',
					numeroOrden: result?.data?.orderServicio || '',
				};
				const dataEncripted = await encrypt(JSON.stringify(data) || '', key);
				const suggestion = await apiFollowComplaint.getMyComplaints(
					dataEncripted
				);
				if (suggestion.code && suggestion.code === '0000') {
					setAlert('');
					setStep(step + 1);
					setResp({
						...suggestion.data.casos[0],
					});
				}
			} else {
				setAlert(
					result.message ||
						'Por el momento el servicio no se encuentra disponible, intente más tarde.'
				);
			}
		} catch (error: any) {
			setAlert(
				error?.description ||
				'Por el momento el servicio no se encuentra disponible, intente más tarde.'
			);
		} finally {
			setLoader(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loader} />
			<HeaderList
				title="Haz y sigue una queja"
				list={[
					step === 1
						? 'Escribe en los siguientes campos toda la información adicional reliacionada con tu queja de vivienda'
						: step === 2
							? 'Para darte una mejor atención, escribe el motivo de tu queja de la manera más clara posible y proporciónanos toda la información que tengas disponible. Escribe en los siquientes campos toda la información adicional relacionada con tu queja. En caso de que necesitemos datos adicionales, nos comunicaremos contigo.'
							: 'Para conocer la respuesta o estatus de tu queja, ingresa a Mi cuenta Infonavit al apartado Consultar mis quejas, o bien comunicate al Infonatel al 5591715050 en la Ciudad de México, o al 800 008 3900 desde cualquier parte del país o ingresa al correo electrónico que nos proporcionaste.',
				]}
			/>
			<Paper className={classes.mainContainer}>
				{step === 1 && (
					<AdditionalInfoReason
						formValues={complaintForm}
						setFormValues={setComplaintForm}
					/>
				)}
				{step === 2 && (
					<ComplaintReason
						formValues={complaintForm}
						setFormValues={setComplaintForm}
					/>
				)}
				{step === 3 && <Summary response={resp} />}
				{Boolean(alert) && (
					<CustomAlert message={alert} severity="error" show />
				)}
				<div className="actions-container">
					{step !== 3 ? (
						<>
							<CustomButton
								onClick={() =>
									step === 1
										? setSelectedComplaint(0)
										: setStep(step - 1)
								}
								variant="outlined"
								label={'Regresar'}
							/>
							<CustomButton
								onClick={() =>
									step === 2
										? createComplaint()
										: setStep(step + 1)
								}
								variant="solid"
								label="Continuar"
								disabled={
									step === 1
										? !complaintForm.empDesarrolladora ||
										!pattern.test(
											complaintForm.empDesarrolladora
										) ||
										!complaintForm.entidad ||
										!complaintForm.cobertura ||
										!complaintForm.utilizado
										: !complaintForm.personaQueja ||
										!complaintForm.tramite ||
										!complaintForm.estado ||
										!complaintForm.direcPersona ||
										!complaintForm.telPersona ||
										!complaintForm.fechaOcurrido ||
										!complaintForm.notas ||
										!pattern.test(
											complaintForm.personaQueja
										) ||
										!pattern.test(
											complaintForm.tramite
										) ||
										!pattern.test(
											complaintForm.direcPersona
										) ||
										!pattern.test(complaintForm.notas)
								}
							/>
						</>
					) : (
						<CustomButton
							onClick={() => navigation('/')}
							variant="solid"
							label="Finalizar"
						/>
					)}
				</div>
				{step !== 3 && (
					<>
						<Divider style={{ marginTop: 15 }} />
						<h4 style={{ color: blue[900] }}>
							*Datos obligatorios
						</h4>
					</>
				)}
			</Paper>
		</>
	);
};

export default LivingPlaceTroubles;
