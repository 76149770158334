import { Paper, Grid, Divider, Box } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { UseStyle } from '../Style';
import {
	ICredito,
	formatDate,
	formatNumber,
} from '../../../interfaces/SaldAndMovement';
import TooltipCustom from '../../../components/TooltipCustom';

interface Props {
	matches: boolean;
	credito: ICredito | null;
	formasPagos: any;
}

const MonthlyPaymentFpp = ({ matches, credito, formasPagos }: Props) => {
	const classes = UseStyle();

	return (
		<div style={{ textAlign: 'center' }}>
			<Grid container columns={12} spacing={2} marginBottom={2}>
				<Grid item xs={12} md={3}>
					<Paper variant="outlined">
						<Box className={classes.BoxContainer}></Box>
						<Box
							padding={2}
							color={'#7F7F7F'}
							textAlign={matches ? 'center' : 'left'}
						>
							Pago para estar al corriente con el FPP (agosto del
							2021)
							<TooltipCustom
								title="Monto de dinero que incluye la mensualidad, pagos atrasados o incompletos y seguros y cuotas."
								placement="right"
								arrow
							>
								<InfoRoundedIcon
									style={{
										fontSize: 14,
										margin: 0,
										padding: 0,
										marginLeft: 5,
										color: '#D1001F',
									}}
								/>
							</TooltipCustom>
						</Box>
					</Paper>
				</Grid>
				<Grid item xs={12} md={3}>
					<Paper variant="outlined">
						<Box className={classes.BoxContainer}>
							¿Cuándo pago?
						</Box>
						<Box padding={2} color={'#7F7F7F'} textAlign={'center'}>
							<Grid
								container
								columns={12}
								style={{
									alignItems: 'center',
									display: 'flex',
									justifyItems: 'center',
								}}
							>
								<Grid item xs={matches ? 5 : 12} md>
									<p style={{ margin: 0 }}>Mensualidades</p>
									<strong style={{ color: '#000' }}>
										{
											credito?.FPPData
												?.tp21MensPgoCorrienteFpp
										}
									</strong>
								</Grid>
								<Grid item xs={matches ? 2 : 12} md>
									<hr
										style={{
											width: 1,
											height: 30,
											color: 'rgba(0, 0, 0, 0.12)',
										}}
									/>
								</Grid>
								<Grid item xs={matches ? 5 : 12} md>
									<strong style={{ color: '#000' }}>
										{formatDate(
											credito?.FPPData?.tp211CorrienteFpp
										)}
									</strong>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
				<Grid item xs={12} md={3}>
					<Paper variant="outlined">
						<Box className={classes.BoxContainer}>
							¿Cuánto debo?
						</Box>
						<Box padding={2} color={'#7F7F7F'} textAlign={'center'}>
							<Grid
								container
								columns={12}
								style={{
									alignItems: 'center',
									display: 'flex',
									justifyItems: 'center',
								}}
							>
								<Grid
									item
									xs={
										matches &&
										credito?.tipoCredito?.key === 10
											? 5
											: 12
									}
									md
								>
									<p style={{ margin: 0 }}>Pesos</p>
									<strong style={{ color: '#000' }}>
										{formatNumber.format(
											credito?.FPPData
												?.tp23PesPgoCorrienteFpp || 0
										)}
									</strong>
								</Grid>
								{credito?.tipoCredito?.key === 10 && (
									<>
										<Grid item xs={matches ? 2 : 12} md>
											<hr
												style={{
													width: 1,
													height: 30,
													color: 'rgba(0, 0, 0, 0.12)',
												}}
											/>
										</Grid>
										<Grid item xs={matches ? 5 : 12} md>
											<p style={{ margin: 0 }}>
												VSM
												<TooltipCustom
													title="Es el monto de cuánto debo pero en Veces Salario Mínimo. El valor de VSM es actualizado cada año y entra en vigor en el mes de enero."
													placement="right"
													arrow
												>
													<InfoRoundedIcon
														style={{
															fontSize: 14,
															margin: 0,
															padding: 0,
															marginLeft: 5,
															color: '#D1001F',
														}}
													/>
												</TooltipCustom>
											</p>
											<strong style={{ color: '#000' }}>
												{
													credito?.FPPData
														?.tp25VsmPgoCorrienteFpp
												}
											</strong>
										</Grid>
									</>
								)}
							</Grid>
						</Box>
					</Paper>
				</Grid>
				<Grid item xs={12} md={3}>
					<Paper variant="outlined">
						<Box className={classes.BoxContainer}>
							Abono FPP
							<TooltipCustom
								title="Es el complemento que se hace al  pago del FPP para cubrir la mensualidad en pesos."
								placement="right"
								arrow
							>
								<InfoRoundedIcon
									style={{
										fontSize: 14,
										margin: 0,
										padding: 0,
										marginLeft: 5,
										color: '#D1001F',
									}}
								/>
							</TooltipCustom>
						</Box>
						<Box padding={2} color={'#7F7F7F'} textAlign={'center'}>
							<Grid
								container
								columns={12}
								style={{
									alignItems: 'center',
									display: 'flex',
									justifyItems: 'center',
								}}
							>
								<Grid
									item
									xs={
										matches &&
										credito?.tipoCredito?.key === 10
											? 5
											: 12
									}
									md
								>
									<p style={{ margin: 0 }}>Pesos</p>
									<strong style={{ color: '#000' }}>
										{formatNumber.format(
											credito?.FPPData
												?.tp27AbonoFppCorrientePes || 0
										)}
									</strong>
								</Grid>
								{credito?.tipoCredito?.key === 10 && (
									<>
										<Grid item xs={matches ? 2 : 12} md>
											<hr
												style={{
													width: 1,
													height: 30,
													color: 'rgba(0, 0, 0, 0.12)',
												}}
											/>
										</Grid>
										<Grid item xs={matches ? 5 : 12} md>
											<p style={{ margin: 0 }}>
												VSM
												<TooltipCustom
													title="Es el monto en Abono FPP pero en Veces Salario Mínimo. El valor de VSM es actualizado cada año y entra en vigor en el mes de enero."
													placement="right"
													arrow
												>
													<InfoRoundedIcon
														style={{
															fontSize: 14,
															margin: 0,
															padding: 0,
															marginLeft: 5,
															color: '#D1001F',
														}}
													/>
												</TooltipCustom>
											</p>
											<strong style={{ color: '#000' }}>
												{
													credito?.FPPData
														?.tp29AbonoFppCorrienteVsm
												}
											</strong>
										</Grid>
									</>
								)}
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>
			{/* Seccion pago mensualidad */}
			<Grid container columns={12} spacing={2} marginBottom={2}>
				<Grid item xs={12} md={3}>
					<Paper variant="outlined">
						<Box className={classes.BoxContainer}></Box>
						<Box
							padding={2}
							color={'#7F7F7F'}
							textAlign={matches ? 'center' : 'left'}
						>
							Pago de mi mensualidad con el FPP (noviembre del
							2021)
							<TooltipCustom
								title="Monto de dinero para pagar el mes actual; incluye seguros y cuotas."
								placement="right"
								arrow
							>
								<InfoRoundedIcon
									style={{
										fontSize: 14,
										margin: 0,
										padding: 0,
										marginLeft: 5,
										color: '#D1001F',
									}}
								/>
							</TooltipCustom>
						</Box>
					</Paper>
				</Grid>
				<Grid item xs={12} md={3}>
					<Paper variant="outlined">
						<Box className={classes.BoxContainer}>
							¿Cuándo pago?
						</Box>
						<Box padding={2} color={'#7F7F7F'} textAlign={'center'}>
							<Grid
								container
								columns={12}
								style={{
									alignItems: 'center',
									display: 'flex',
									justifyItems: 'center',
								}}
							>
								<Grid item xs={matches ? 5 : 12} md>
									<p style={{ margin: 0 }}>Mensualidades</p>
									<strong style={{ color: '#000' }}>
										{
											credito?.FPPData
												?.tp22MensPgoMensualidadFpp
										}
									</strong>
								</Grid>
								<Grid item xs={matches ? 2 : 12} md>
									<hr
										style={{
											width: 1,
											height: 30,
											color: 'rgba(0, 0, 0, 0.12)',
										}}
									/>
								</Grid>
								<Grid item xs={matches ? 5 : 12} md>
									<strong style={{ color: '#000' }}>
										{formatDate(
											credito?.FPPData
												?.tp212MensualidadFpp
										)}
									</strong>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
				<Grid item xs={12} md={3}>
					<Paper variant="outlined">
						<Box className={classes.BoxContainer}>
							¿Cuánto debo?
						</Box>
						<Box padding={2} color={'#7F7F7F'} textAlign={'center'}>
							<Grid
								container
								columns={12}
								style={{
									alignItems: 'center',
									display: 'flex',
									justifyItems: 'center',
								}}
							>
								<Grid
									item
									xs={
										matches &&
										credito?.tipoCredito?.key === 10
											? 5
											: 12
									}
									md
								>
									<p style={{ margin: 0 }}>Pesos</p>
									<strong style={{ color: '#000' }}>
										{formatNumber.format(
											credito?.FPPData
												?.tp24PesPgoMensualidadFpp || 0
										)}
									</strong>
								</Grid>
								{credito?.tipoCredito?.key === 10 && (
									<>
										<Grid item xs={matches ? 2 : 12} md>
											<hr
												style={{
													width: 1,
													height: 30,
													color: 'rgba(0, 0, 0, 0.12)',
												}}
											/>
										</Grid>
										<Grid item xs={matches ? 5 : 12} md>
											<p style={{ margin: 0 }}>
												VSM
												<TooltipCustom
													title="Es el monto de cuánto debo pero en Veces Salario Mínimo. El valor de VSM es actualizado cada año y entra en vigor en el mes de enero."
													placement="right"
													arrow
												>
													<InfoRoundedIcon
														style={{
															fontSize: 14,
															margin: 0,
															padding: 0,
															marginLeft: 5,
															color: '#D1001F',
														}}
													/>
												</TooltipCustom>
											</p>
											<strong style={{ color: '#000' }}>
												{
													credito?.FPPData
														?.tp26VsmPgoMensualidadFpp
												}
											</strong>
										</Grid>
									</>
								)}
							</Grid>
						</Box>
					</Paper>
				</Grid>
				<Grid item xs={12} md={3}>
					<Paper variant="outlined">
						<Box className={classes.BoxContainer}>
							Abono FPP
							<TooltipCustom
								title="Es el complemento que se hace al  pago del FPP para cubrir la mensualidad en pesos."
								placement="right"
								arrow
							>
								<InfoRoundedIcon
									style={{
										fontSize: 14,
										margin: 0,
										padding: 0,
										marginLeft: 5,
										color: '#D1001F',
									}}
								/>
							</TooltipCustom>
						</Box>
						<Box padding={2} color={'#7F7F7F'} textAlign={'center'}>
							<Grid
								container
								columns={12}
								style={{
									alignItems: 'center',
									display: 'flex',
									justifyItems: 'center',
								}}
							>
								<Grid
									item
									xs={
										matches &&
										credito?.tipoCredito?.key === 10
											? 5
											: 12
									}
									md
								>
									<p style={{ margin: 0 }}>Pesos</p>
									<strong style={{ color: '#000' }}>
										{formatNumber.format(
											credito?.FPPData
												?.tp28AbonoFppMensualPes || 0
										)}
									</strong>
								</Grid>
								{credito?.tipoCredito?.key === 10 && (
									<>
										<Grid item xs={matches ? 2 : 12} md>
											<hr
												style={{
													width: 1,
													height: 30,
													color: 'rgba(0, 0, 0, 0.12)',
												}}
											/>
										</Grid>
										<Grid item xs={matches ? 5 : 12} md>
											<p style={{ margin: 0 }}>
												VSM
												<TooltipCustom
													title="Es el monto en Abono FPP pero en Veces Salario Mínimo. El valor de VSM es actualizado cada año y entra en vigor en el mes de enero."
													placement="right"
													arrow
												>
													<InfoRoundedIcon
														style={{
															fontSize: 14,
															margin: 0,
															padding: 0,
															marginLeft: 5,
															color: '#D1001F',
														}}
													/>
												</TooltipCustom>
											</p>
											<strong style={{ color: '#000' }}>
												{
													credito?.FPPData
														?.tp210AbonoFppMensualVsm
												}
											</strong>
										</Grid>
									</>
								)}
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>

			<Grid container columns={12} spacing={2} marginBottom={4}>
				<Grid item xs={12} md={3}>
					<Paper variant="outlined">
						<Box className={classes.BoxContainer}></Box>
						<Box
							padding={2}
							color={'#7F7F7F'}
							textAlign={matches ? 'center' : 'left'}
						>
							Forma de pago
							<TooltipCustom
								title="Medio por el cual el acreditado hace sus pagos. Sin relación laboral lo puede hacer en bancos y establecimientos autorizados por el Infonavit. Cuando sí hay una relación de trabajo formal, el patrón hace la retención y la entrega al Instituto."
								placement="right"
								arrow
							>
								<InfoRoundedIcon
									style={{
										fontSize: 14,
										margin: 0,
										padding: 0,
										marginLeft: 5,
										color: '#D1001F',
									}}
								/>
							</TooltipCustom>
						</Box>
					</Paper>
				</Grid>
				<Grid item xs={12} md={9}>
					<Paper variant="outlined">
						<Box className={classes.BoxContainer}></Box>
						<Box padding={2} textAlign={'center'} fontWeight={500}>
							{formasPagos.length > 0
								? formasPagos[0].value
								: 'Si vas a liquidar tu crédito, paga directamente en cualquier banco autorizado.'}
						</Box>
					</Paper>
				</Grid>
			</Grid>

			{credito?.mensualidadREA_ROA && (
				<Paper
					variant="outlined"
					style={{
						padding: '30px 30px',
						textAlign: 'center',
						marginTop: 25,
					}}
				>
					<h4 style={{ margin: '10px 0px' }}>
						Mensualidad REA y ROA
					</h4>
					<p style={{ marginBottom: 30, marginTop: 20 }}>
						Esta es la cantidad que debes pagar para cubrir tus
						mensualidades y mantener tu crédito al corriente: y la
						información de Si pagas por tu cuenta (REA)
					</p>
					<Divider style={{ marginBottom: 20 }} />
					<Grid container columns={12} style={{ textAlign: 'left' }}>
						<Grid item xs={12} md={6}>
							Si pagas por tu cuenta (REA)
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							style={{
								textAlign: matches ? 'left' : 'right',
								fontWeight: 500,
							}}
						>
							{formatNumber.format(
								credito?.mensualidadREA_ROA
									? credito?.mensualidadREA_ROA.tp35mensRea
									: 0
							)}
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							style={{ margin: '20px 0px' }}
						>
							<Divider />
						</Grid>
						<Grid item xs={12} md={6}>
							Si el descuento es por nomina (ROA)
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							style={{
								textAlign: matches ? 'left' : 'right',
								fontWeight: 500,
							}}
						>
							{formatNumber.format(
								credito?.mensualidadREA_ROA
									? credito?.mensualidadREA_ROA.tp36MensRoa
									: 0
							)}
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							style={{ margin: '20px 0px' }}
						>
							<Divider />
						</Grid>
						<Grid item xs={12} md={12}>
							<h4 style={{ textAlign: 'center' }}>
								Pagos parciales
							</h4>
							<p
								style={{
									margin: 0,
									color: '#293990',
									fontWeight: 700,
								}}
							>
								¿Qué son y cómo se aplican los pagos parciales?
							</p>
							<p>
								Los pagos parciales son aquellos pagos que
								puedes hacer durante el mes, para completar poco
								a poco la cantidad que se observa en el renglón{' '}
								<b>de “Pago por tu cuenta REA”</b> en{' '}
								<b>la sección Mensualidades REA y ROA</b>,
								considerando como fecha límite para completarla
								antes del último día del mes.
								<br />
								<br />
								En caso de que con las parcialidades que
								realices antes del último día del mes, no cubras
								el total indicado en “Pago por tu cuenta REA”,
								los pagos hechos, se aplicarán al saldo de tu
								crédito, no se tomará en cuenta como pago
								efectivo del mes efectivo.
								<br />
								<br />
								Si antes del último día del mes terminas de
								cubrir el monto total de “Pago por tu cuenta
								REA” y continúas realizando pagos, lo que pagues
								adicional, se aplicará al saldo de crédito.
							</p>
						</Grid>
					</Grid>
				</Paper>
			)}
		</div>
	);
};

export default MonthlyPaymentFpp;
