import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useGeneralStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: '0 48px',
    [theme.breakpoints.down('md')]: {
      padding: '0 8px',
    },
    height: 'auto',
	},
  content: {
    textAlign: 'center',
    padding: '5px 25px',
  },
  bottonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>button': {
      width: 260,
      margin: '32px 8px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 0px',
      flexDirection: 'column',
      '&>button': {
        margin: '8px 0px',
        width: '90%',
      },
    },
  },
}));

export const centeredItems = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};