import { createAsyncThunk, createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
	INITIAL_STATE,
	SET_AVAILABLE_CREDITS,
	SET_SELECTED_CREDIT,
	IS_LOADING,
	CLEAR_CREDITS,
} from '../types/credit';
import { CreditState } from '../types/credit';
import { ICredit } from '../../interfaces/credit';

export const actions = {
	setAvailableCredits: createAsyncThunk(SET_AVAILABLE_CREDITS, (credits: ICredit[]) => {
		return credits;
	}),
	isLoading: createAsyncThunk(IS_LOADING, (loading: boolean) => {
		return loading;
	}),
	setCredit: createAsyncThunk(SET_SELECTED_CREDIT, (credit: string) => {
		return credit;
	}),
	clearCredit: createAsyncThunk(CLEAR_CREDITS, () => {
		return {};
	}),
};

export default createReducer(INITIAL_STATE, {
	[actions.setCredit.fulfilled.type]: (
		_state: CreditState,
		action: PayloadAction<string>
	) => ({
		..._state,
		credit: action.payload,
		isLoading: false,
	}),
	[actions.setCredit.pending.type]: (_state: CreditState) => ({
		..._state,
		isLoading: true,
	}),
	[actions.setAvailableCredits.fulfilled.type]: (
		_state: CreditState,
		action: PayloadAction<[ICredit]>
	) => ({ availableCredits: action.payload, credit: '0', isLoading: false }),
	[actions.clearCredit.fulfilled.type]: () => ({
		isLoading: false,
		availableCredits: [],
		credit: '0',
	}),
});
