/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Link, Paper, Grid } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../../../utils/validators';

//Assets
import imgCoin from '../../../assets/img/coin.jpg';
import ViewMoreButton from '../components/viewMoreButton';
import imgSolicitaDev from '../../../assets/img/solicita-dev-banner1.png';
import imgCartaCan from '../../../assets/img/banner-carta-canelacion.png';
import imgIncrement from '../../../assets/img/incrementa-ahorro-banner.png';
import { useProfile } from '../../MIUC/hooks/useProfile';
import { MIUC_PRODUCTS_COMPONENT } from '../../MIUC/utils/home.utils';
import CustomAlert from '../../../components/CustomAlert';

const SaverCreditClose = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	const [hoverStates, setHoverStates] = useState(
		MIUC_PRODUCTS_COMPONENT.map(() => false)
	);

	const { getInitialProfileHome, saldoSARTotal, errorMesage } = useProfile({
		initProcess: true,
	});

	const handleMouseEnter = (index: any) => {
		setHoverStates((prevStates) => {
			const newStates = [...prevStates];
			newStates[index] = true;
			return newStates;
		});
	};

	const handleMouseLeave = (index: any) => {
		setHoverStates((prevStates) => {
			const newStates = [...prevStates];
			newStates[index] = false;
			return newStates;
		});
	};

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={12} lg={4}>
				<Paper className={classes.card}>
					<div className={classes.titleCard}>
						<h3>¡Terminaste de pagar tu crédito!</h3>
					</div>
					<div className={classes.textCard}>
						<p>
							Si por algún motivo se realizaron pagos de más a tu
							crédito, solicita la devolución de Pagos en exceso.
						</p>
						<p>
							Recuerda solicitar tu Carta de Cancelación de
							hipoteca para liberar tu crédito con el notario.
						</p>
						<p>
							Continúa trabajando para generar más ingresos al
							ahorro en tu Subcuenta de Vivienda.
						</p>
					</div>
				</Paper>
			</Grid>
			<Grid item sm={12} md={6} lg={4}>
				<Grid item lg={12}>
					<Paper className={classes.mainContainerCardMoney}>
						<label className={classes.titleCardMoney}>
							Tienes ahorrado en tu Subcuenta de Vivienda:
						</label>
						<div className={classes.containerCardMoney}>
							<img
								src={imgCoin}
								alt="icono pesos"
								className={classes.iconPesos}
							/>
							<label className={classes.labelSSV}>
								{formatMoney(Number(saldoSARTotal) || 0)} MXN
							</label>
						</div>
					</Paper>
				</Grid>
				<Grid item lg={12} mt={1}>
					<Paper className={classes.mainContainerCard}>
						<div className={classes.containerPaperList}>
							<label className={classes.titleCardCredit}>
								¿Qué quieres hacer con tu crédito Infonavit?
							</label>

							<p className={classes.textCardPaperList}>
								Para conocer tus opciones y condiciones de
								crédito, selecciona el destino de tu interés.
							</p>

							{MIUC_PRODUCTS_COMPONENT.map(
								(productEle, index) => (
									<div
										key={productEle.alt}
										className={classes.containerCardLine}
										onMouseEnter={() =>
											handleMouseEnter(index)
										}
										onMouseLeave={() =>
											handleMouseLeave(index)
										}
									>
										<img
											src={productEle.icon}
											alt={'iconMiuc' + index}
											className={classes.iconCompra}
										/>
										<label className={classes.labelBtnLine}>
											{productEle.name}
										</label>
										<div>
											<ViewMoreButton
												onclick={() =>
													getInitialProfileHome(
														productEle
													)
												}
												labelText="Iniciar"
												hover={hoverStates[index]}
											/>
										</div>
									</div>
								)
							)}
							<CustomAlert
								message={errorMesage}
								show={Boolean(errorMesage)}
								severity="warning"
							/>
							<p className={classes.labelRestrictions}>
								<span>*</span>Aplican restricciones,{' '}
								<Link
									href="/mi-tramite-credito/precalificacion-puntos"
									color="primary"
								>
									conoce más.
								</Link>
							</p>
						</div>
					</Paper>
				</Grid>
			</Grid>
			<Grid item sm={12} md={6} lg={4}>
				<Grid container spacing={2}>
					<Grid item sm={12} md={12} lg={12}>
						<div
							onClick={() =>
								navigate('/mi-credito/devolucion-pagos-exceso')
							}
						>
							<img
								src={imgSolicitaDev}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item sm={12} md={12} lg={12}>
						<div
							onClick={() =>
								navigate(
									'/mi-credito/carta-cancelacion-hipoteca'
								)
							}
						>
							<img
								src={imgCartaCan}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item sm={12} md={12} lg={12}>
						<div
							onClick={() =>
								navigate(
									'/mi-ahorro/haz-aportaciones-extraordinarias'
								)
							}
						>
							<img
								src={imgIncrement}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SaverCreditClose;
