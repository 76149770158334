// RESOURCES
import { Box, Grid, Link } from '@mui/material';
// COMPONENTS
import CustomButton from '../../../components/Button';
// ASSETS
import phone from '../../../assets/img/telefono.png';
import appstore from '../../../assets/img/app-store.png';
import googlePlay from '../../../assets/img/google-play.png';
import huawei from '../../../assets/img/huawei_app.png';
import { useStyles } from './styles';
import CustomAlert from '../../../components/CustomAlert';
import checkicon from '../../../assets/img/mini-check-azul.png';
import { useNavigate } from 'react-router-dom';

interface ListToHave {
	id: number;
	icon: string;
	title: string;
}

interface SocialMedia {
	id: number;
	icon: string;
	title: string;
	url: string;
}

// const listItems: ListToHave[] = [
// 	{
// 		id: 1,
// 		icon: checkicon,
// 		title: 'Instala la aplicación desde tu tienda de aplicaciones (App store o Google play).',
// 	},
// 	{
// 		id: 2,
// 		icon: checkicon,
// 		title: 'Inicia sesión desde la aplicación.',
// 	},
// 	{
// 		id: 3,
// 		icon: checkicon,
// 		title: 'Selecciona que sí quieres vincular tu dispositivo móvil.',
// 	},
// 	{
// 		id: 4,
// 		icon: checkicon,
// 		title: '¡Felicidades! Tu dispositivo móvil estará vinculado.',
// 	},
// ];

const APPS: SocialMedia[] = [
	{
		id: 1,
		icon: appstore,
		title: 'Apple Store',
		url: 'https://apps.apple.com/us/app/mi-cuenta-infonavit-m%C3%B3vil/id1542403564',
	},
	{
		id: 2,
		icon: googlePlay,
		title: 'Play Store',
		url: 'https://play.google.com/store/apps/details?id=com.infonavit.micuentainfonavit',
	},
	{
		id: 3,
		icon: huawei,
		title: 'Huawei Store',
		url: 'https://appgallery.huawei.com/app/C104066105',
	},
];

const NotInfoDevice = () => {
	const navigate = useNavigate();
	const classes = useStyles();

	return (
		<>
			{/* <h4>Requisitos</h4>
			{listItems.map((sm) => (
				<Grid
					item
					xs={12}
					lg={12}
					className={classes.divChecks}
					key={sm.id}
				>
					<img
						className={classes.iconsImg}
						alt="checkicon"
						src={sm.icon}
					></img>
					<span className={classes.txtPrincipales}>{sm.title}</span>
					<br></br>
				</Grid>
			))} */}
			<div className={classes.container}>
				<div className={classes.containerMin}>
					<div className={classes.containerImage}>
						<img
							className={classes.phoneImage}
							src={phone}
							alt="check-blue"
						/>
					</div>
					<div className={classes.containerInfo}>
						<p className={classes.txtContainer}>
							<b>Dispositivo</b>
						</p>
						<p className={classes.normalText}>Sin información</p>
						{/* <p className={classes.txtContainer}>
							<b>Fecha de vinculación</b>
						</p>
						<p className={classes.normalText}>Sin información</p> */}
					</div>
				</div>

				<div className={classes.alertContainer}>
					<CustomAlert
						message="Debes iniciar sesión de la aplicación del Infonavit para poder vincular a tu dispositivo móvil."
						show={true}
						severity="warning"
					/>
				</div>

				<p className={classes.txtDownload}>Descarga la aplicación</p>
				<div className={classes.containerStore}>
					{APPS.map((app) => (
						<Link
							key={app.id}
							target="_blank"
							rel="noopener"
							href={app.url}
						>
							<img
								className={classes.stores}
								src={app.icon}
								alt={app.title}
							/>
						</Link>
					))}
				</div>
				<Box className={classes.containerButtons}>
					<CustomButton
						onClick={() => navigate('/')}
						styles={{ width: '200px' }}
						variant="solid"
						label="Salir"
					/>
				</Box>
			</div>
		</>
	);
};

export default NotInfoDevice;
