import { useState } from 'react';
import Layout from '../../components/Layout';
import InfoDevice from './InfoDevice';
import WayToUnlink from './WayToUnlink';
import SendCode from './SendCode';
import CloseCase from './CloseCase';
import NotInfoDevice from './NotInfoDevice';
import ServiceNotAvailable from './ServiceNotAvailable';
import { useStyles } from './InfoDevice/styles';
import ConfirmPassword from './ConfirmPassword';

const UnpairDevice = () => {
	const classes = useStyles();
	const [page, setPage] = useState(0); //Estado para el cambio de vistas
	const [device, setDevice] = useState(0); //Estado para elegir el medio de desvinculación
	const dataRequest = {
		grupo: 'cn=GS_MICUENTA,ou=atencionservicios,ou=areasapoyo,O=INFONAVIT',
		id_cat: 'APL0104',
	};
	const [request, setRequest] = useState({
		datetime_creation: '',
		fechaCreacionTDS: '',
		idDispositivo: '',
	});

	return (
		<div>
			<Layout>
				<>
					<div className={classes.titleNoticeContainer}>
						<div>
							<h2 className={classes.titleNotice}>
								Desvincula tu dispositivo móvil{' '}
							</h2>
						</div>
					</div>
					{page === 0 && (
						<ConfirmPassword
							setPage={setPage}
							dataRequest={dataRequest}
							setRequest={setRequest}
						/>
					)}
					{page === 1 && (
						<InfoDevice setPage={setPage} request={request} />
					)}
					{page === 2 && (
						<WayToUnlink setDevice={setDevice} setPage={setPage} />
					)}
					{page === 3 && (
						<SendCode
							device={device}
							setDevice={setDevice}
							setPage={setPage}
							dataRequest={dataRequest}
						/>
					)}
					{page === 4 && <CloseCase />}
					{page === 5 && <NotInfoDevice />}
					{page === 6 && <ServiceNotAvailable />}
				</>
			</Layout>
		</div>
	);
};

export default UnpairDevice;
