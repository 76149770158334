import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    textAlign: 'center',
  },
  title: {
    color:  theme.palette.info.main
  },
  savingContainer: {
    maxWidth: '432px',
    width: '100%',
    border: `1px solid #BEBEBE`,
    borderRadius: 5,
    margin: '0 auto 41px',
    '&> img': {
      width: '40%',
      // height: '30px',
      marginTop: 24,
      marginBottom: 16,
    }
  },
  saving: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.info.main,
    marginBottom: '24px',
    '&> h2': {
      fontSize: '36px',
      margin: 0,
    },
    '&> img': {
      width: '51px',
      marginRight: '16px',
    },
  },
  flexRight: {
    display: 'flex',
    justifyContent: 'end',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  donwloadLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    '&> img': {
      marginLeft: 5,
      width: 24,
    },
    textDecoration: 'none',
    '&:hover':{
      textDecoration: 'underline',
    }
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    marginBottom: '16px'
  },
  cellTitle: {
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% / 3)',
    },
  },
  cellContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  cellContent: {
    display: 'flex',
    flexDirection: 'column',
    '&> img': {
      width: 30,
      alignSelf: 'center'
    },
    '&> p': {
      margin: 0,
    }
  }
}))