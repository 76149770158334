import { useMemo } from "react";
import { useNavigation } from "../../hooks/useNavigation"
import { useGeneralStyles } from "../styles/general.styles";
import { useMejoraHogar } from "./useMejoraHogar";
import { MIUC_PROCESS_VIEWS } from "../../constants/process";
import { SelectionCredit } from "../../components/SelectionCredit/selectionCredit";
import Layout from "../../../../components/Layout";
import { Box, Grid, Paper } from "@mui/material";
import { AmpliarReparar } from "../AmpliarReparar/ampliarReparar";
import ViewPoints from "../../components/ViewPoints";
import { ConstruYO } from "../ConstruYO/construYO";
import ErrnoPage from "../../../CreditRequest/ErrnoPage";

export const MejoraHogar = () => {

  const { view, onSelect, extraMessage, errorMessage } = useMejoraHogar();
	const generalStyle = useGeneralStyles();

	const size = useMemo(() => {
		if (view !== MIUC_PROCESS_VIEWS.SELECTION_CREDIT) {
			return 12;
		} else {
			return 8;
		}
	}, [view]);

	return (
		<Layout>
			<Box className={generalStyle.container}>
				<Grid container spacing={2}>
          {
            errorMessage !== '' && 
            <Paper>
              <ErrnoPage msgString={errorMessage} />
            </Paper>
          }
          {
            errorMessage === '' && 
            <>
              <Grid item xs={12} md={size}>
                {view === MIUC_PROCESS_VIEWS.SELECTION_CREDIT && (
                  <Paper>
                    <SelectionCredit onSelectCredit={onSelect} extraMessage={extraMessage} />
                  </Paper>
                )}
                {view === MIUC_PROCESS_VIEWS.MEJORAVIT && (
                  <AmpliarReparar />
                )}
                {view === MIUC_PROCESS_VIEWS.AUTOPRODUCCION && (
                  <ConstruYO />
                )}
              </Grid>
              {
                view === MIUC_PROCESS_VIEWS.SELECTION_CREDIT &&
                <Grid item xs={12} md={4}>
                  <Box sx={{ textAlign: 'center' }}>
                    <ViewPoints />
                  </Box>
                </Grid>
              }
            </>
          }
				</Grid>
			</Box>
		</Layout>
	);
}