import { Navigate, useParams } from 'react-router-dom';

const styleCss: React.CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: '100%',
}

const DocumentViewMIUC = () => {
	const { documento } = useParams();

	if (String(documento || '').toLowerCase() === 'solicitud_inscripcion_credito_cicb') {
		return (
			<embed
				src={require('../../../../assets/files/guia-pasos-a-seguir/Solicitud_Inscripcion_Credito_CICB.pdf')}
				type="application/pdf"
				style={styleCss}
			/>
		);
	}

    if (String(documento || '').toLowerCase() === 'carta_instruccion_irrevocable_cicb') {
		return (
			<embed
				src={require('../../../../assets/files/guia-pasos-a-seguir/Carta_instruccion_irrevocable_CICB.pdf')}
				type="application/pdf"
                style={styleCss}
			/>
		);
	}

	return <Navigate to={'/'} />;
};

export default DocumentViewMIUC;
