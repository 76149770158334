import API from '../';

export interface IDataConsultaCasos {
	nss: string;
	claseOperacion: 'ZCHI' | 'ZCHB';
	orden: string;
}

class CancelLetterAPI extends API {
	descargaDoc(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/descargar-documento`,
				method: 'POST',
				data,
			})
		);
	}
	consultaHipoteca(num_credito: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/consulta-hipoteca`,
				method: 'POST',
				data: { num_credito },
			})
		);
	}
	consultaCasos(data: IDataConsultaCasos): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/consulta-casos-carta-cancelacion`,
				method: 'POST',
				data: data,
			})
		);
	}
	creaCarta(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/crear-carta-cancelacion`,
				method: 'POST',
				data,
			})
		);
	}
	adjuntarEscritura(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/adjuntar-escritura`,
				method: 'POST',
				data,
			})
		);
	}
	cargaDocto(archivo: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/subir-documento-carta-cancelacion`,
				method: 'POST',
				data: { archivo },
			})
		);
	}
	descargaDocOtx(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/descargar-documento-otx`,
				method: 'POST',
				data: { ...data },
			})
		);
	}
}

export const cancelLetterAPI = new CancelLetterAPI('');
