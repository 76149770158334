import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Paper,
	Box,
	Grid,
	TextField,
	Divider,
	useMediaQuery,
} from '@mui/material';
import CustomButton from '../../../components/Button';
import { Clear, Search } from '@mui/icons-material';
import theme from '../../../config/theme';
import ModalViewDoc from './ModalViewDoc';
import { apiFollowComplaint } from '../../../api/modules/followComplaint';
import { encrypt } from '../../../utils/encrypt';
import { RootState } from '../../../store';
import ModalLoading from '../../../components/ModalLoading';
import CustomAlert from '../../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import ModalFinish from './ModalFinish';
import { useBitacora } from '../../../hooks/useBitacora';

const AttachDocument = ({ setSelectedType, queja }: any) => {
	const { regBitacoraNotOld } = useBitacora();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { user } = useSelector((state: RootState) => ({
		user: state.session.user,
	}));
	const [nota, setNota] = useState('');
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState('');
	const [openModal, setOpenModal] = useState<File | null>(null);
	const [openFinish, setOpenFinish] = useState(false);
	const [file, setFile] = useState({
		name: '',
		archivo: '',
		file: null,
	});

	const handleFile = (e: any) => {
		const { files } = e.target;
		if (files) {
			const { name } = files[0];
			if (files[0].size > 5242880) {
				setAlert(INTERNAL_MESSAGES.FILE_SIZE);
				return;
			}
			if (
				![
					'application/pdf',
					'image/jpeg',
					'image/gif',
					'image/tiff',
					'video/mpeg',
					'video/x-msvideo',
					'audio/mp3',
				].includes(files[0].type)
			) {
				setAlert(INTERNAL_MESSAGES.FILE_NOT_SUPPORTED);
				return;
			}
			const reader = new FileReader();
			reader.onload = function () {
				const nameOrigin = String(name).replace(/[^a-zA-Z0-9\s.]/g, '');
				const base64Data = reader.result?.toString().split(',')[1];
				if (base64Data) {
					setFile({
						name: nameOrigin,
						archivo: base64Data,
						file: files[0],
					});
				}
			};
			reader.readAsDataURL(files[0]);
		}
	};

	const handleDeleteFile = (e: React.MouseEvent<HTMLLabelElement>) => {
		e.preventDefault();
		setFile({
			name: '',
			archivo: '',
			file: null,
		});
	};

	const handleFinish = async () => {
		try {
			setAlert('');
			setLoading(true);
			const data1 = {
				nss: user?.nss,
				caso: queja.caso,
				archivos: [
					{
						archivo: file.archivo,
						nombreArchivo: file.name,
					},
				],
			};
			const dataEncripted1 = await encrypt(
				JSON.stringify(data1) || '',
				user?.k || ''
			);
			const resAdjuntar = await apiFollowComplaint.adjuntarDocumento(
				dataEncripted1
			);
			if (resAdjuntar?.code !== '0000') {
				setAlert(
					resAdjuntar?.message ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde'
				);
				return;
			}
			regBitacoraNotOld(apiFollowComplaint.schemeUrl, data1);
			if (nota) {
				const data2 = {
					nss: user?.nss,
					caso: queja.caso,
					notas: nota,
				};
				const dataEncripted2 = await encrypt(
					JSON.stringify(data2) || '',
					user?.k || ''
				);
				apiFollowComplaint
					.crearNota(dataEncripted2)
					.then(() => {
						regBitacoraNotOld(apiFollowComplaint.schemeUrl, data2);
					})
					.catch(() => {})
					.finally(() => {
						setLoading(false);
						setOpenFinish(true);
					});
				return;
			}
			setLoading(false);
			setOpenFinish(true);
		} catch (ex) {
			setAlert(
				'Por el momento el servicio no se encuentra disponible, intenta más tarde'
			);
			setLoading(false);
		}
	};

	const closeModal = () => {
		setOpenFinish(false);
		setSelectedType(0);
	};

	return (
		<>
			<ModalFinish
				open={openFinish}
				setClose={closeModal}
				noQueja={queja.caso || ''}
			/>
			<ModalLoading loading={loading} />
			<ModalViewDoc open={openModal} setOpen={setOpenModal} />
			<Paper>
				<Box
					maxWidth={matches ? '100%' : 950}
					padding={2}
					margin={'0px auto'}
				>
					<h3 style={{ textAlign: 'center' }}>Adjuntar documento</h3>

					<Grid container columns={12}>
						<Grid
							item
							xs={12}
							md={6}
							textAlign={'left'}
							fontWeight={500}
						>
							Situación de queja
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							textAlign={matches ? 'left' : 'right'}
						>
							Adjuntar documento
						</Grid>

						<Grid item xs={12} md={12}>
							<Divider style={{ margin: '15px 0px' }} />
						</Grid>

						<Grid
							item
							xs={12}
							md={6}
							textAlign={'left'}
							fontWeight={500}
						>
							Número de queja
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							textAlign={matches ? 'left' : 'right'}
						>
							{queja.caso || ''}
						</Grid>

						<Grid item xs={12} md={12}>
							<Divider style={{ margin: '15px 0px' }} />
						</Grid>

						<Grid item xs={12} md={3} textAlign={'left'}>
							<span style={{ fontWeight: 500 }}>
								Documento adicional
							</span>
							<p style={{ color: '#7f7f7f', margin: '5px 0px' }}>
								Documento requerido
							</p>
						</Grid>
						<Grid
							item
							xs={12}
							md={5}
							textAlign={matches ? 'left' : 'center'}
							color={file.name ? '#000' : '#7f7f7f'}
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{file.name || 'No se eligió ningún archivo'}
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							textAlign={matches ? 'left' : 'right'}
							color={'#DD0528'}
						>
							{file.archivo ? (
								<>
									<label
										onClick={handleDeleteFile}
										style={{
											cursor: 'pointer',
											display: 'inline-flex',
											alignItems: 'center',
											marginRight: '10px',
										}}
									>
										<Clear style={{ fontSize: 17 }} />
										Eliminar archivo
									</label>

									<label
										onClick={() => setOpenModal(file.file)}
										style={{
											cursor: 'pointer',
											display: 'inline-flex',
											alignItems: 'center',
										}}
									>
										<Search style={{ fontSize: 17 }} />
										Ver archivo
									</label>
								</>
							) : (
								<label
									style={{
										cursor: 'pointer',
									}}
								>
									<input
										name="file"
										type="file"
										onChange={handleFile}
										multiple
										style={{ display: 'none' }}
										accept=".pdf, .jpg, .gif, .tiff, .mpeg, .avi, .mp3"
									/>
									Elegir archivo
								</label>
							)}
						</Grid>
					</Grid>

					<p style={{ textAlign: 'center', fontWeight: 500 }}>
						Antes de enviarlos verifica que estén correctos.
					</p>
					<p
						style={{
							textAlign: 'center',
							margin: '0px 0px 0px 0px',
							fontWeight: 500,
						}}
					>
						Los formatos permitidos: pdf, jpg,gif,tiff,mpeg.avi y
						mp3, cuyo tamaño no debe de exceder los 5 MB.
					</p>
					<p
						style={{
							textAlign: 'center',
							margin: '0px 0px 20px 0px',
							fontWeight: 500,
						}}
					>
						El archivo no deberá contar con acentos, ni caracteres
						especiales (*%,$#&/=“!:.;)
					</p>

					<label>
						O si deseas solo enviar una nota. Sin exceder los 1,000
						caracteres.
					</label>
					<Box display={'block'} width={'100%'}>
						<TextField
							placeholder="Maximo 1,000 caracteres."
							multiline
							rows={4}
							fullWidth
							inputProps={{ maxLength: 1000 }}
							value={nota}
							onChange={({ target }) => setNota(target.value)}
						/>
					</Box>

					<CustomAlert
						message={alert}
						show={alert !== ''}
						severity="error"
					/>

					<Box
						display={'flex'}
						justifyContent={'center'}
						flexDirection={matches ? 'column-reverse' : 'row'}
						gap={2}
						margin={'30px 0px'}
					>
						<Box width={matches ? '100%' : 200}>
							<CustomButton
								label={'Salir'}
								variant="outlined"
								onClick={() => setSelectedType(2)}
							/>
						</Box>
						<Box width={matches ? '100%' : 200}>
							<CustomButton
								label={'Finalizar'}
								onClick={handleFinish}
								disabled={file.archivo === ''}
							/>
						</Box>
					</Box>
				</Box>
			</Paper>
		</>
	);
};

export default AttachDocument;
