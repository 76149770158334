import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		buttonLarge: {
			width: '100%',
			// padding: `${theme.spacing(0.5)}px ${theme.spacing(6)}px`,
			height: 40,
			maxWidth: 250,
		},
		title: {
			width: '100%',
			fontSize: 26,
			margin: 0,
		},
	})
);
