import { Box, Grid } from '@mui/material';
// Components
import ViewPoints from '../../components/ViewPoints';
import FlowCard from '../../components/FlowCard';
// Pages
import ApoyoInfonavit from './pages/ApoyoInfonavit';
import ResumenApoyo from './pages/ResumenApoyo';
import { useController } from '../../hooks/useController';
import { useMemo } from 'react';
import FollowSteps from '../../components/FollowSteps';

const pageViews: JSX.Element[] = [
	<ApoyoInfonavit />,
	<FollowSteps imprimir />,
];

const PuntosInsuficientesMiuc = () => {
	const { dataProcessController } = useController()
	const progress = [50,100]
	
	// const size = useMemo(() => {
	// 	if (dataProcessController.processData.step == 1) {
	// 		return 12;
	// 	} else {
	// 		return 8;
	// 	}
	// }, [dataProcessController]);
	
	return (
		<Grid container columns={12} spacing={2}>
			<Grid item xs={12} md={8}>
				{pageViews[dataProcessController?.processData?.step] ??
					pageViews[0]}
			</Grid>
      <Grid item xs={12} md={4}>
        <Box sx={{ textAlign: 'center' }}>
          <ViewPoints />
        </Box>
        <Box mt={2}>
          <FlowCard
            title="Tu selección"
            flow={dataProcessController.processInfo?.selection}
            progress={
              progress[dataProcessController?.processData?.step] ??
                progress[0]
            }
          />
        </Box>
      </Grid>
		</Grid>
	);
};

export default PuntosInsuficientesMiuc;