import BaseModal from '../../../../../components/Modal';

import { useStyles } from '../styles/styles';

const ModalSaveProcess = ({ open, setConfirm }: any) => {
	const classes = useStyles();
	return (
		<BaseModal
			open={open}
			onConfirm={setConfirm}
			cancelButton={false}
			confirmLabel="Continuar"
			hideCircularProgress={true}
			hideCloseButton={true}
			width="sm"
			children={
				<>
					<div>
						<h2 className={classes.titleModal}>
							Tu progreso se ha guardado exitosamente
						</h2>
						<div className={classes.dialogCheck + ' ' + classes.textModal}>
						</div>
					</div>
				</>
			}
		/>
	);
};

export default ModalSaveProcess;
