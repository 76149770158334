/* eslint-disable no-throw-literal */
import axios, {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosPromise,
	AxiosRequestHeaders,
} from 'axios';
//@ts-ignore
import { Service } from 'axios-middleware';
import { actions } from '../store/modules/session';
import { actions as creditActions } from '../store/modules/credit';
import { Store } from 'redux';
import { isEmpty } from '../utils/validators';
import { getHeadersByScheme } from './api-bitacora';

interface RequestPayload {
	data: unknown;
	err?: string;
	errmsg?: string;
}

let localStore: any = null;

export default class API {
	baseURL: string | undefined;
	scheme: string;
	request: AxiosInstance;
	schemeUrl: string = "";

	constructor(scheme: string, baseURL = process.env.REACT_APP_API_URL) {
		this.baseURL = baseURL;
		this.scheme = scheme;
		let headersBuild = {};

		if (process.env.REACT_APP_ENV === 'qa') {
			headersBuild = {
				'X-Build-Frontend-Branch': process.env.REACT_APP_BRANCH,
				'X-Build-Frontend-BuildDate': process.env.REACT_APP_DATE_BUILD,
				'X-Build-Frontend-VersionTag': process.env.REACT_APP_TAG,
				'X-Build-Frontend-CommitHashShort':
					process.env.REACT_APP_COMMIT_HASH_SHORT,
				'X-Build-Frontend-CommitMessage':
					process.env.REACT_APP_COMMIT_MESSAGE,
				'X-Build-Frontend-CommitHashLong':
					process.env.REACT_APP_COMMIT_HASH_LONG,
				'X-Build-Frontend-CommitDate':
					process.env.REACT_APP_COMMIT_DATE,
			};
		}

		this.request = axios.create({
			baseURL: this.baseURL,
			headers: {
				'Content-Type': 'application/json',
				...headersBuild,
			},
		});

		const service = new Service(this.request);
		service.register({
			async onRequest(config: AxiosRequestConfig) {
				const configFlag = { ...config };
				if (configFlag?.headers?.Authorization) {
					return configFlag;
				} else {
					const token = localStore.getState().session?.user?.token;
					if (token) {
						configFlag.headers = {
							...configFlag.headers,
							Authorization: `Bearer ${token}`,
						};
					}
				}
				return configFlag;
			},
		});

		this.request.interceptors.request.use((config) => {
			if (config?.url) {
				const headers = getHeadersByScheme(config?.url ?? '');
				if (headers) {
					config.headers = {
						...config.headers,
						...headers,
					};
				}
			}
			return config;
		});
	}

	requestWrapper<AxiosResponse>(
		req: AxiosPromise<any>
	): Promise<AxiosResponse> {
		return req
			.then(({ data, config }) => {
				try {
					if (data.err) {
						throw Error(data.err);
					}
					this.schemeUrl = config?.url || '';
					return data;
				} catch (error) {
					throw error;
				}
			})
			.catch((err) => {
				const errorEstatus = err.request.status;
				if (errorEstatus === 401) {
					localStore.dispatch(actions.signOut());
					localStore.dispatch(creditActions.clearCredit());
				} else {
					if (err.response?.statusText === 'Unauthorized') {
						localStore.dispatch(actions.checkToken());
					}
					if (
						(err.response?.data?.err &&
							err.response?.data?.err ===
								'La sesión ha expirado') ||
						(err.response?.data?.error &&
							err.response?.data?.error ===
								'La sesión ha expirado')
					) {
						localStore.dispatch(actions.signOut());
						localStore.dispatch(creditActions.clearCredit());
					}
					if (err.response?.data) {
						if (
							err.response?.status?.toString().charAt(0) === '5'
						) {
							throw {
								description:
									'Por el momento el servicio no se encuentra disponible, intenta más tarde',
							};
						}
						if (
							err.response?.data?.err &&
							typeof err.response?.data?.err === 'object'
						) {
							if (
								err.response?.data?.err.hasOwnProperty(
									'description'
								)
							) {
								throw err.response?.data?.err;
							}
						}
						if (
							!err.response?.data?.err ||
							typeof err.response?.data?.err !== 'object' ||
							!err.response?.data?.err.hasOwnProperty(
								'description'
							)
						) {
							throw {
								...err.response?.data?.err,
								description:
									err.response?.data?.err?.mensaje ||
									err.response?.data?.err?.descripcion ||
									err.response?.data?.err?.msg ||
									'Por el momento el servicio no se encuentra disponible, intenta más tarde',
							};
						}
						throw err.response?.data?.err;
					}
					if (isEmpty(err)) {
						throw {
							description:
								'Por el momento el servicio no se encuentra disponible, intenta más tarde',
						};
					}
				}
				throw err;
			});
	}

	get<ParamsType, AxiosResponse>(
		path?: string,
		params?: ParamsType
	): Promise<AxiosResponse> {
		const url = path ? `${this.scheme}/${path}` : this.scheme;
		return this.requestWrapper<AxiosResponse>(
			this.request({ url, data: params })
		);
	}

	post<ParamsType, AxiosResponse>(
		path: string,
		data?: ParamsType,
		headers?: AxiosRequestHeaders,
		cancelToken?: any
	): Promise<AxiosResponse> {
		const url = path ? `${this.scheme}/${path}` : this.scheme;
		return this.requestWrapper<AxiosResponse>(
			this.request({
				url,
				data,
				method: 'POST',
				headers,
				cancelToken: cancelToken,
			})
		);
	}

	put<ParamsType, AxiosResponse>(
		id: number | string,
		data?: ParamsType
	): Promise<AxiosResponse> {
		const url = id ? `${this.scheme}/${id}` : this.scheme;
		return this.requestWrapper<AxiosResponse>(
			this.request({
				url,
				data,
				method: 'PUT',
			})
		);
	}

	delete(id: number): Promise<RequestPayload> {
		return this.requestWrapper(
			this.request({ url: `${this.scheme}/${id}`, method: 'DELETE' })
		);
	}

	show(id: number, params?: any): Promise<RequestPayload> {
		return this.requestWrapper(
			this.request({ url: `${this.scheme}/${id}`, params })
		);
	}
}

export const getStore = (store: Store) => {
	localStore = store;
};

export const baseRequest = new API('/');
