import { Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CustomButton from '../../../components/Button';

interface Props {
	setPage: (arg: number) => void;
}

const AttachDocument = ({ setPage }: Props) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	return (
		<Paper style={{ padding: 20 }}>
			<h3 style={{ textAlign: 'center', color: '#333333', margin: '30px 0px' }}>
				Para hacer una aclaración deberás adjuntar los siguientes documentos:
			</h3>

			<div style={{ maxWidth: 800, margin: '0px auto' }}>
				<div style={{ display: 'flex', alignItems: 'baseline' }}>
					<CheckCircleOutlineRoundedIcon
						style={{
							color: '#293990',
							fontSize: 18,
							padding: 0,
							margin: 0,
							marginRight: 4,
						}}
					/>
					Identificación oficial vigente (INE/IFE, pasaporte, cartilla militar,
					cédula profesional, forma de calidad migratoria- 2 o forma de calidad
					migratoria-3)
				</div>
				<div style={{ display: 'flex', alignItems: 'baseline' }}>
					<CheckCircleOutlineRoundedIcon
						style={{
							color: '#293990',
							fontSize: 18,
							padding: 0,
							margin: 0,
							marginRight: 4,
						}}
					/>{' '}
					XXXXXXXXXXXXXXXXXXXXXXXXXXXX
				</div>
			</div>

			<p
				style={{
					textAlign: 'center',
					margin: 0,
					marginTop: 20,
					fontWeight: 400,
					fontSize: 17,
				}}
			>
				¿Quieres adjuntar tus documentos?
			</p>

			<div
				style={{
					display: 'flex',
					flexDirection: matches ? 'column-reverse' : 'initial',
					justifyContent: 'center',
					margin: '20px 0px',
				}}
			>
				<CustomButton
					styles={{
						width: matches ? 'auto' : 250,
						margin: '10px 10px',
						display: 'inline-flex',
					}}
					label={'No'}
					variant="outlined"
					onClick={() => setPage(0)}
				/>

				<CustomButton
					styles={{
						width: matches ? 'auto' : 250,
						margin: '10px 10px',
						display: 'inline-flex',
					}}
					label={'Si'}
					variant="solid"
					onClick={() => setPage(6)}
				/>
			</div>
		</Paper>
	);
};

export default AttachDocument;
