import { useEffect } from 'react';

function useCustomScript(url: string) {
	// El archivo es cargado en el render del componente requerido
	useEffect(() => {
		const customScript = document.createElement('script');
		customScript.src = url;
		customScript.async = true;
		document.body.appendChild(customScript);

		return () => {
			document.body.removeChild(customScript);
		};
	}, [url]);
}

export default useCustomScript;
