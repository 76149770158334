import './AssistantVirtual.css';

export class clsAssistantVirtual {
	data = {
		virtualAsistantUrl:
			'https://avi.infonavit.org.mx/?environment=dbo&apiKeyToken=eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJjbXIiOiIxek9vQzk2SEp6WHk5UTJsdEdsb290eG1BaWFIZDNYWDNGL094ejRpQ084QS9VMXgrR2dMeVlvdGNoc056ZFhQIiwiYXBrIjoiQ2praXJaeU9vWm15b2tpbzNVZ0tNUzBCSGZCZHAzM0ZVaDQ2WU5ZeHUveVkySjJGUi84bGhzMnk5UDVvVWdWcCIsIm5iZiI6MTU0MzI2MTIwNiwiZXhwIjoxODU4ODgwNDA2LCJpc3MiOiJodHRwOi8vYXZpLmluZm9uYXZpdC5vcmcubXgiLCJhdWQiOiJodHRwOi8vYXZpLmluZm9uYXZpdC5vcmcubXgifQ.U-gEJdCjk1S0xqbx0eSizkl4iLKnI17L2yhOvkVxMXw&flowId=SALUDO8BOTONES&https://micuenta.infonavit.org.mx=micuenta.infonavit.org.mx',
		queryStringProperties: [
			{
				key: process.env.REACT_APP_KEY_CHATBOT || '',
				value: window.location.host.toString(),
			},
		],
	};
	virtualAssistantIframeContainer: HTMLDivElement =
		document.createElement('div');
	virtualAssistantIframe: HTMLIFrameElement =
		document.createElement('iframe');
	startChat: boolean = false;

	constructor() {
		this.virtualAssistantIframeContainer.style.transition = 'all 0.4s';
		this.virtualAssistantIframeContainer.style.position = 'fixed';
		this.virtualAssistantIframeContainer.style.right = '-500px';
		this.virtualAssistantIframeContainer.style.bottom = '0';
		this.virtualAssistantIframeContainer.style.top = '0';
		this.virtualAssistantIframeContainer.style.zIndex = '999999';
		this.virtualAssistantIframeContainer.id =
			'virtual-assistant-iframe-container';
		this.virtualAssistantIframeContainer.appendChild(
			this.virtualAssistantIframe
		);
		this.virtualAssistantIframe.id = 'virtual-assistant-iframe';
		document.body.appendChild(this.virtualAssistantIframeContainer);

		document.addEventListener('click', (e) => {
			const { id }: any = e.target;
			if (id !== 'ChatBotero') {
				this.hiddeChat();
			}
		});
	}

	showChat() {
		if (!this.startChat) {
			let iframeSrc = this.virtualAssistantIframe.src;

			iframeSrc = this.updateQueryStringParameter(
				process.env.REACT_APP_URL_CHATBOT ||
					this.data.virtualAsistantUrl ||
					'',
				this.data.queryStringProperties[0].key ||
					process.env.REACT_APP_KEY_CHATBOT ||
					'',
				this.data.queryStringProperties[0].value
			);
			this.virtualAssistantIframeContainer.style.right = '0';
			this.virtualAssistantIframe.src = iframeSrc;
			this.startChat = true;
		}
	}

	hiddeChat() {
		if (this.startChat) {
			this.virtualAssistantIframeContainer.style.right = '-500px';
			this.virtualAssistantIframe.src = '';
			this.startChat = false;
		}
	}

	updateQueryStringParameter = (uri: string, key: string, value: string) => {
		let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
		let separator = uri.indexOf('?') !== -1 ? '&' : '?';
		if (uri.match(re)) {
			return uri.replace(re, '$1' + key + '=' + value + '$2');
		} else {
			return uri + separator + key + '=' + value;
		}
	};
}
