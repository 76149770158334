import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        progressBar: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '60%',
        },
        porcentajeCurso: {
            color: '#293990',
            minWidth: '100%',
            marginRight: '10px',
            marginLeft: '20px',
            [theme.breakpoints.down('md')]: {
                marginLeft: '0px',
                marginRight: '10px',
              },
            borderRadius: '3px',
            minHeight: '5px',
        },
        optionEnlace: {
            display: 'flex',
            alignItems: 'center',
            margin: '2px',
            [theme.breakpoints.down('md')]: {
                marginLeft: '0px',
              },
            fontSize: 13,
            fontWeight: 'bold',
            color: '#D1001F',
            textDecoration: 'none',
        },
        greyArrow: {
            color: '#C7C6C5'
        },
        viewMoreContent: {
            textAlign: 'left',
            marginLeft: 120,
            [theme.breakpoints.down('md')]: {
                marginLeft: 10,
              },
            marginRight: '20px !important',
        },
        viewMoreTitle: {
            margin: '2px !important',
            fontSize: 13,
            fontWeight: 'bold',
        },
        viewMoreList: {
            margin: '2px !important',
            fontSize: 15,
            marginBottom: '10px !important',
            listStylePosition: 'outside',
            padding: 10,
        },
        infoStyle: {
            fontSize: '18px !important',
            marginBottom: -2,
            marginLeft: 4,
        },
        helpPanel: {
            color: 'rgb(221, 5, 40)',
            marginLeft: '8px !important',
        },
        requestIcon: {
            marginLeft: 5, 
            width: 18
        },
        listColumn: {
            display: 'flex', 
            flexDirection: 'column'
        },
        columnContent: {
            display: 'column',
        },
        blockContent: {
            display: 'block',
        },
        alert: {
            fontSize: '13px',
        },
        icoDescarga: {
            maxWidth: 18,
            marginBottom: -2,
        },
    })
);