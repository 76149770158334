import { Box, Dialog, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

export const pageInit = [
	'/mi-tramite-credito/precalificacion-punto',
	'/mi-credito/saldos-movimientos',
	'/mi-ahorro/devolucion-fondo-ahorro-72-92',
	'/mi-ahorro/consulta-relaciones-laborales',
	'/mi-ahorro/resumen-movimientos-ahorro',
	'/mi-ahorro/calculadora-ahorro',
	'/mi-ahorro/haz-aportaciones-extraordinarias',
	'/mi-ahorro/cuanto-ahorro-tengo',
	'/mi-perfil/correccion-curp-nombre',
	'/mi-perfil/correccion-rfc',
	'/mi-perfil/socio-infonavit',
	'/mi-perfil/actualizar-datos-contacto',
];

export const setInitPoll = () => {
	const idCalificaScript = document.getElementById('idCalificaScript');
	if (!idCalificaScript) {
		(function (h, o, t, j) {
			h.hj =
				h.hj ||
				function () {
					(h.hj.q = h.hj.q || []).push(arguments);
				};
			h._hjSettings = {
				hjid: 2859093, // Infonavit
				// hjid: 3022676, // Proveedor
				hjsv: 6,
			};
			let r = o.createElement('script');
			r.id = 'idCalificaScript';
			r.async = true;
			r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
			o.body.appendChild(r);
		})(
			window as any,
			document,
			'https://static.hotjar.com/c/hotjar-',
			'.js?sv='
		);
	}
};

export const setOpenAutomatic = (setPollShow: boolean, url: string = '/') => {
	if (typeof (window as any).repeatHotjar === 'undefined') {
		(window as any).repeatHotjar = false;
	}
	const checkPage = pageInit.includes(url);
	const repeatHoja: boolean = (window as any).repeatHotjar;

	if (setPollShow && !repeatHoja && checkPage) {
		setTimeout(() => {
			showModal(true);
		}, 60000);
	}
};

export const openModalEncuesta = () => {
	const hj =
		(window as any).hj ||
		function () {
			// eslint-disable-next-line no-undef
			(hj.q = hj.q || []).push(arguments);
		};
	// eslint-disable-next-line no-undef
	hj('event', 'INFONAVIT_CLICK');
};

export const showModal = (checkMain: boolean = false) => {
	openModalEncuesta();
	if (checkMain) {
		(window as any).repeatHotjar = true;
	}
};

interface IModalAuxiliar {
	show: boolean;
	setShow: (arg: boolean) => void;
}

export const ModalAuxiliar = ({ show, setShow }: IModalAuxiliar) => {
	return (
		<Dialog open={show} onClose={() => setShow(false)}>
			<Box display={'flex'} justifyContent={'end'} padding={'5px 10px'}>
				<IconButton color="primary" onClick={() => setShow(false)}>
					<ClearIcon />
				</IconButton>
			</Box>
			<iframe
				src={process.env.REACT_APP_URL_MODAL_ENCUESTA}
				width={600}
				height={700}
				style={{ border: 0 }}
			/>
		</Dialog>
	);
};
