import { IProcessController } from '../../pages/MIUC/interfaces/processController';

export interface IDefaultData {
	dataProcessController: IProcessController;
}

export const SET_DATA_PROCESS_CONTROLLER = 'miuc/setDataProcessController';
export const CLEAR_DATA = 'miuc/clearData';

export const INITIAL_STATE: IDefaultData = {
	dataProcessController: {} as IProcessController,
};
