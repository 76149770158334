import React from 'react';
import BaseModal from '../../components/Modal';
import CustomButton from '../../components/Button';

const ConfirmAttachDocument = ({ open, setOpen, sendDataCaso }: any) => {
	return (
		<BaseModal
			title={
				<span style={{ color: '#293990', textAlign: 'center', display: 'block' }}>
					¿Estás seguro de que adjuntaste todos tus documentos?
				</span>
			}
			open={open}
			width="sm"
			disableOnConfirm={true}
		>
			<div style={{ textAlign: 'center' }}>
				<div
					style={{
						display: 'flex',
						width: 540,
						justifyContent: 'space-between',
						margin: '20px auto 10px',
					}}
				>
					<div style={{ width: 260 }}>
						<CustomButton
							label="No"
							variant="outlined"
							onClick={() => setOpen(false)}
						/>
					</div>
					<div style={{ width: 260 }}>
						<CustomButton
							label="Sí"
							variant="solid"
							onClick={() => sendDataCaso()}
						/>
					</div>
				</div>
			</div>
		</BaseModal>
	);
};

export default ConfirmAttachDocument;
