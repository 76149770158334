import casitaIcon from '../../../assets/svg/casita.svg';
import terrenoIcon from '../../../assets/svg/casita2.svg';
import mejoraIcon from '../../../assets/img/productsMIUC/ampliar.png';
import construirIcon from '../../../assets/img/productsMIUC/contruir.svg';
import hipotecaIcon from '../../../assets/img/productsMIUC/hipoteca.png';
import iconoCreditoInfo from '../../../assets/svg/con-credito.svg';
import iconoMejoravitInfo from '../../../assets/svg/con-mejoravit.svg';
import { MIUC_MODELS } from '../constants/models';
import { MIUC_PRODUCTS, PRODUCT_LINES } from '../constants/products';

export const MIUC_PRODUCTS_COMPONENT = [
	{
		name: 'Comprar vivienda',
		alt: 'icono compra',
		icon: casitaIcon,
		model: MIUC_MODELS.T1000,
		code: MIUC_PRODUCTS.COMPRA,
		line: PRODUCT_LINES.COMPRAR_VIVIENDA,
	},
	{
    title: <p style={{ textAlign: 'left', margin: 0 }}>
      Comprar terreno o
      Comprar terreno y Construir una vivienda
    </p>,
		name: 'Comprar terreno o Comprar terreno y Construir una vivienda',
		alt: 'icono terreno',
		icon: terrenoIcon,
		model: MIUC_MODELS.T900,
		code: MIUC_PRODUCTS.CREDITERRENO,
		line: PRODUCT_LINES.COMPRAR_TERRENO,
	},
	{
		name: 'Construir una vivienda',
		alt: 'icono construir',
		icon: construirIcon,
		model: MIUC_MODELS.T800,
		code: MIUC_PRODUCTS.CONSTRUCCION,
		line: PRODUCT_LINES.CONTRUIR_VIVIENDA,
	},
	{
		name: 'Mejora tu hogar',
		alt: 'icono mejora tu hogar',
		icon: mejoraIcon,
		model: MIUC_MODELS.T1000,
		code: '',
		line: PRODUCT_LINES.MEJORA_HOGAR,
	},
	{
		name: 'Pagar hipoteca',
		alt: 'icono hipoteca',
		icon: hipotecaIcon,
		model: MIUC_MODELS.T1000,
		code: MIUC_PRODUCTS.HIPOTECA,
		line: PRODUCT_LINES.PAGAR_HIPOTECA,
	},
	//Para pruebas
	// {
	// 	name: 'Sin relacion laboral',
	// 	alt: 'icono sin relacion laboral',
	// 	icon: hipotecaIcon,
	// 	model: MIUC_MODELS.T1000,
	// 	code: MIUC_PRODUCTS.CICB,
	// 	line: PRODUCT_LINES.SIN_RELACION_LABORAL,
	// },
];

export const MIUC_INFORMATION_COMPONENT = [
	{
		alt: 'icono crédito infonavit',
		icon: iconoCreditoInfo,
		element: (
			<>
				<strong>Con Crédito Infonavit</strong> puedes Comprar vivienda
				nueva o existente, comprar terreno, construir, ampliar o
				remodelar y pagar hipoteca.
			</>
		),
    code: 'goToHome',
	},
	// {
	// 	alt: 'icono mejoravit',
	// 	icon: iconoMejoravitInfo,
	// 	element: (
	// 		<>
	// 			Mejoravit, crédito de corto plazo con el que{' '}
	// 			<strong>
	// 				puedes reparar y remodelar tu vivienda, sin afectar su
	// 				estructura.
	// 			</strong>
	// 		</>
	// 	),
    // code: MIUC_PRODUCTS.MEJORAVIT,
	// },
];
