import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { COLORS } from '../../utils';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		marginTop: 20,
		width: '100%',
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: COLORS.BLUE,
		padding: 20,
		// paddingTop: 50,
	},
	containerHeader: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
		paddingTop: 20,
		paddingBottom: 20,
		height: 'auto',
		backgroundColor: '#F2F2F2',
		paddingInline: 20,
	},
	labelHeaderTitle: {
		fontSize: 16,
	},
	containerVideo: {
		height: 500,
	},
	containerVideos: {
		paddingInline: 10,
		maxHeight: 450,
		height: '100%',
		// overflow: 'auto',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			marginTop: 20,
		},
	},
	labelLegend: {
		marginTop: 20,
		fontWeight: 500,
		'& > div > p': {
			display: 'inline',
			width: 'auto',
			margin: 0,
		},
	},
	arrowVideo: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		'& div': {
			color: '#d8d8d9',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 30,
			width: 30,
			borderRadius: '100%',
			border: '2px solid #d8d8d9',
			cursor: 'pointer',
			margin: '5px 0px',

			'&:hover': {
				color: 'gray',
				borderColor: 'gray',
			},
		},
	},
	generalVideoContainer: {
		height: '100%',

		[theme.breakpoints.down('md')]: {
			display: 'grid',
			gridTemplateColumns: '50px auto 50px',
		},
	},
	arrowVideoR: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: 30,
		right: 2,

		'& div': {
			color: 'gray',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 30,
			width: 30,
			borderRadius: '100%',
			border: '3px solid gray',
			cursor: 'pointer',
			margin: '5px 0px',
			background: 'rgba(0,0,0,0.05)',
		},
	},
	arrowVideoL: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: 30,
		left: 2,

		'& div': {
			color: 'gray',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 30,
			width: 30,
			borderRadius: '100%',
			border: '3px solid gray',
			cursor: 'pointer',
			margin: '5px 0px',
			background: 'rgba(0,0,0,0.05)',
		},
	},
}));
