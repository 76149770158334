import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			textAlign: 'center',
		},
		contentPadding: {
			padding: '5px 25px',
		},
		inputs: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'end',
			justifyContent: 'center',
			maxWidth: '90%',
			[theme.breakpoints.down('md')]: {
				maxWidth: '100%',
			  },
			paddingRight: '50px',
			paddingLeft: '50px',
			textAlign: 'start',
		},
		valueInput: {
			paddingBottom: '4px',
		},
		labelInput: {
			color: '#636363',
		},
		buttonsMVIT: {
			display: 'flex',
			flexDirection: 'row',
			paddingTop: '3px',
			[theme.breakpoints.down('md')]: {
				paddingTop: 0,
			  },
		},
		buttonsL6: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'end',
			paddingBottom: '5px',
			[theme.breakpoints.down('md')]: {
				paddingTop: 0,
			  },
		},
		boxAportaciones: {
			display: 'flex',
			flexDirection: 'row',
			backgroundColor: '#F2F2F2',
			textAlign: 'left',
			width: '100%',
			margin: 0,
			justifyContent: 'center',
			left: 0,
		},
		divAportaciones: {
			width: '81%',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		infoStyle: {
			fontSize: '20px !important',
			marginBottom: -2,
			marginLeft: 4,
		},
		radioContainer: {
			textAlign: 'left',
			display: 'flex',
			flexDirection: 'row',
		},
		radioOption: {
			marginLeft: '15px !important',
		},
		divCreditosRequeridos: {
			display: 'flex',
			flexDirection: 'row',
			width: '75%',
			justifyContent: 'space-between',
			marginBottom: '20px',
		},
		divSeleccionCreditos: {
			display: 'flex',
			flexDirection: 'column',
		},
		divRadioGroup: {
			display: 'flex',
			flexDirection: 'row',
		},
		centeredContent: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
		},
		helpPanel: {
			color: 'rgb(221, 5, 40)',
			marginLeft: 8,
		},
    	textDisclaimer: {
			textAlign: 'left',
			padding: '10px 20px',
			backgroundColor: 'rgb(250, 226, 149)',
			borderRadius: '6px',
			'&>p': {
				margin: 0,
				color: 'rgb(227, 87, 0)',
				fontSize: '0.875rem'
			}
		},
		cardSelect: {
			height: 40, 
			minWidth: 300,
			[theme.breakpoints.down('md')]: {
				minWidth: 250,
			  },
			marginTop: 5,
		},
    regularizacion: { 
      width: '80%',
      margin: '0 auto'
    },
	})
);
