import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			paddingTop: 50,
			paddingBottom: 0,
			[theme.breakpoints.down('md')]: {
				paddingLeft: 20,
				paddingRight: 20,
				paddingTop: 25,
				paddingBottom: 25,
			},
		},
		divContainerWeb: {
			width: '100%',
			maxWidth: '600px',
			display: 'flex',
			justifyContent: 'space-around',
			margin: '0 auto',
			flexWrap: 'wrap',
		},
		divLeft: {
			width: '50%',
			fontWeight: 700,
			fontSize: 16,
			color: '#7E8094',
			minHeight: '24px',
		},
		divRight: {
			width: '50%',
			textAlign: 'right',
			fontSize: 16,
			color: '#7E8094',
			minHeight: '24px',
		},
		h3: {
			marginTop: 40,
			marginBottom: 30,
			fontSize: 16,
			textAlign: 'center',
			color: '#7E8094',
		},
		p: {
			fontSize: 16,
			color: '#7E8094',
			minHeight: '24px',
		},
		hr: {
			display: 'block',
			margin: '0',
			width: '100%',
			opacity: 0.2,
			[theme.breakpoints.down('xs')]: {
				marginRight: 18,
				opacity: 0.5,
			},
		},
		back: {
			display: 'flex',
			width: '100px',
			color: '#DD0528',
			alignItems: 'center',
			cursor: 'pointer',
			textAlign: 'center',
		},
		containerButtons: {
			width: '100%',
			maxWidth: '600px',
			display: 'flex',
			justifyContent: 'center',
			margin: '20px auto',
		},
	})
);
