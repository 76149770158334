import API from '../';
import { IDetail, IDetailsState } from '../../interfaces/serviceOffice';

class ServiceOfficeAPI extends API {
	globalInfo(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/global-info`,
				method: 'POST',
			})
		);
	}
	detailInfo(data: IDetailsState[]): Promise<{ detail: IDetail[] }> {
		return this.requestWrapper(
			this.request({
				url: `/detail-info`,
				method: 'POST',
				data,
			})
		);
	}
}
export const serviceOfficeAPI = new ServiceOfficeAPI('');
