import React, { useState } from 'react';
import { Paper } from '@mui/material';
import { HeaderList } from '../../../components/HeaderList';
import CustomButton from '../../../components/Button';
import ComplaintTypes from '../views/ComplaintTypes';
import { useStyles } from '../styles';
import LivingPlaceTroubles from './LivingPlaceTroubles';
import BadAttention from './BadAttention';
import Disagreement from './Disagreement';

const CreateComplaint = ({ setSelectedType }: any) => {
	const classes = useStyles();
	const [complaintType, setComplaintType] = useState<number>(0);
	const [selectedComplaint, setSelectedComplaint] = useState<number>(0);
	const [tipificacion, setTipificacion] = useState<string>('');
	const [disableContinue, setDisableContinue] = useState<boolean>(true);

	return (
		<>
			{selectedComplaint === 0 && (
				<HeaderList
					title="Haz y sigue una queja"
					list={[
						'Selecciona la situación que quieres reportar y sigue las indicaciones; al finalizar agrega información complementaria que consideres importante para la atención de tu queja. Si requerimos más datos nos pondremos en contacto contigo.',
					]}
				/>
			)}
			{selectedComplaint === 0 && (
				<Paper className={classes.mainContainer}>
					<ComplaintTypes
						complaintType={complaintType}
						setComplaintType={setComplaintType}
						setTipificacion={setTipificacion}
						setDisableContinue={setDisableContinue}
					/>
					<div className="actions-container">
						<CustomButton
							onClick={() => setSelectedType(0)}
							variant="outlined"
							label={'Salir'}
						/>
						<CustomButton
							disabled={
								complaintType === 0 ||
								([1, 2].includes(complaintType) &&
									disableContinue)
							}
							onClick={() => setSelectedComplaint(complaintType)}
							variant="solid"
							label="Continuar"
						/>
					</div>
				</Paper>
			)}
			{selectedComplaint === 1 && (
				// ZQES
				<BadAttention
					setSelectedComplaint={setSelectedComplaint}
					tipificacion={tipificacion}
				/>
			)}
			{selectedComplaint === 2 && (
				// ZQUE
				<LivingPlaceTroubles
					setSelectedComplaint={setSelectedComplaint}
					tipificacion={tipificacion}
				/>
			)}
			{selectedComplaint === 3 && (
				// ZQES
				<Disagreement setSelectedComplaint={setSelectedComplaint} />
			)}
		</>
	);
};

export default CreateComplaint;
