import { useState, useEffect } from 'react';
import { Box, Paper, useMediaQuery } from '@mui/material';
import { HeaderList } from '../../components/HeaderList';
import { nipInfonatelAPI } from '../../api/modules/NipInfonatel';
import Layout from '../../components/Layout';
import { CheckCircleOutlined } from '@mui/icons-material';
import ModalLoading from '../../components/ModalLoading';
import { useTheme } from '@mui/material/styles';
import CustomAlert from '../../components/CustomAlert';
import GeneratePin from '../NipInfonatel/generatePin';
import PinBlocked from '../NipInfonatel/UnlockPin/index';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../NipInfonatel/styles';
import CustomButtom from '../../components/Button';
import Socio from '../../assets/img/Socio.png';
import { IAlert } from '../../interfaces/alert';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';

interface Validations {
	[index: number]: string;
}

const NipInfonatel = () => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [activeNip, setActiveNip] = useState(0);
	const [loading, setLoading] = useState(false);
	const [errorReq, setErrorReq] = useState('');
	const [page, setPage] = useState(0);
	const navigate = useNavigate();
	const style = useStyles();
	const [nip, setNip] = useState('');
	const [nipM, setNipM] = useState('');
	const [blockNip, setBlockNip] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});
	const [validations, setValidations] = useState<Validations>([]);
	const [nipsRecents, setNipsRecents] = useState<Validations>([]);

	useEffect(() => {
		consultCase();
	}, []);

	const consultCase = async () => {
		try {
			setLoading(true);
			const { result } = await nipInfonatelAPI.consultCase();

			setErrorReq('');
			setValidations(result.bd || []);
			setNipsRecents(result.history || []);
			if (Number(result.code) === 0) {
				setActiveNip(1);
				setErrorReq(
					'Tu NIP Infonatel no esta activado por favor actívalo.'
				);
			} else if (Number(result.code) === 1) {
				setNip(result.nip);
				setNipM(result.nipM);
				setActiveNip(2);
			} else if (Number(result.code) === 2) {
				setNip(result.nip);
				setNipM(result.nipM);
				setBlockNip(true);
				setActiveNip(2);
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const itemsList = [
		{ id: 1, text: 'Tener Mi Cuenta Infonavit activa.' },
		//{ id: 2, text: 'Tener activo Token móvil Infonavit.' },
		{ id: 3, text: 'Tener un número de teléfono celular.' },
	];

	return (
		<Layout>
			<>
				{page === 0 && (
					<>
						<Box>
							<HeaderList title="NIP Infonatel" />
							<ModalLoading loading={loading} />
							<p className={style.parrafoBold}>Requisitos</p>
							{itemsList.map((item) => (
								<Box key={item.id} className={style.lineText}>
									<CheckCircleOutlined
										color="info"
										style={{ marginRight: 5, width: 22 }}
									/>{' '}
									<span className={style.parrafo}>
										{item.text}
									</span>
								</Box>
							))}

							<br />
							<br />
						</Box>
						<Paper>
							<Box>
								<Box className={style.titleContainer}>
									<h1>NIP Infonatel</h1>
								</Box>
								<div className={style.containerNip}>
									<Box>
										<Box className={style.BoxTextContainer}>
											<p className={style.parrafo}>
												El NIP Infonatel es una clave que te permitirá
												acceder de forma segura a tu información desde
												nuestra atención telefónica, por lo que podrás:
											</p>
										</Box>
										<Box
											className={
												style.BoxTextContainerList
											}
										>
											<Box className={style.lineText}>
												<CheckCircleOutlined
													color="info"
													style={{
														marginRight: 5,
														width: 22,
													}}
												/>{' '}
												<span className={style.parrafo}>
													Reducir el tiempo de espera
													para ser atendido por
													nuestro servicio
													automático.
												</span>
											</Box>

											<Box className={style.lineText}>
												<CheckCircleOutlined
													color="info"
													style={{
														marginRight: 5,
														width: 22,
													}}
												/>{' '}
												<span className={style.parrafo}>
													Acceder a información
													oportuna y específica de
													acuerdo a tu situación
													actual.
												</span>
											</Box>

											<Box className={style.lineText}>
												<CheckCircleOutlined
													color="info"
													style={{
														marginRight: 5,
														width: 22,
													}}
												/>{' '}
												<span className={style.parrafo}>
													Mantener la integridad de tu
													información al tener
													controlado el acceso a tus
													servicios.
												</span>
											</Box>

											<Box className={style.lineText}>
												<CheckCircleOutlined
													color="info"
													style={{
														marginRight: 5,
														width: 22,
													}}
												/>{' '}
												<span className={style.parrafo}>
													El registro es fácil y
													sencillo a través de nuestro
													autoservicio digital.
												</span>
											</Box>

											<Box className={style.lineText}>
												<CheckCircleOutlined
													color="info"
													style={{
														marginRight: 5,
														width: 22,
													}}
												/>{' '}
												<span className={style.parrafo}>
													No esperes más y obtén tu
													NIP Infonatel y mantén
													segura tu información.
												</span>
											</Box>
										</Box>
									</Box>
									<div className={style.imageContainer}>
										<img
											src={Socio}
											style={{
												width: matches ? 120 : 300,
												height: matches ? 130 : 300,
											}}
											alt=""
										/>
									</div>
								</div>
								{errorReq && (
									<Box className={style.firstAlert}>
										<CustomAlert
											show
											message={errorReq}
											severity="warning"
										/>
									</Box>
								)}
								<Box className={style.firstAlert}>
									<CustomAlert
										message={alert.message}
										severity={alert.severity}
										show={alert.show}
									/>
								</Box>
								<Box className={style.containerButtonsEnd}>
									<Box
										margin={'20px 10px'}
										width={matches ? '80%' : 250}
									>
										<CustomButtom
											label={'Salir'}
											onClick={() => navigate('/')}
											variant="outlined"
											styles={{ height: '40px' }}
										/>
									</Box>
									{activeNip === 1 && (
										<Box
											margin={'20px 10px'}
											width={matches ? '80%' : 250}
										>
											<CustomButtom
												label={'Activar NIP'}
												onClick={() => setPage(1)}
												variant="solid"
											/>
										</Box>
									)}
									{activeNip === 2 && (
										<Box
											margin={'20px 10px'}
											width={matches ? '80%' : 250}
										>
											<CustomButtom
												label={'Continuar'}
												onClick={() => setPage(2)}
												variant="solid"
											/>
										</Box>
									)}
								</Box>
							</Box>
						</Paper>
					</>
				)}
				{page === 1 && (
					<>
						<Box>
							<HeaderList title="NIP Infonatel" />
							<ModalLoading loading={loading} />
							<p className={style.parrafoBold}>Requisitos</p>
							{itemsList.map((item) => (
								<Box key={item.id} className={style.lineText}>
									<CheckCircleOutlined
										color="info"
										style={{ marginRight: 5, width: 22 }}
									/>{' '}
									<span className={style.parrafo}>
										{item.text}
									</span>
								</Box>
							))}

							<br />
							<br />
						</Box>
						<GeneratePin validations={validations} nipsRecents={nipsRecents} />{' '}
					</>
				)}
				{page === 2 && (
					<>
						<HeaderList title="NIP Infonatel" />
						<PinBlocked
							nip={nip}
							blockNip={blockNip}
							nipM={nipM}
							validations={validations}
							nipsRecents={nipsRecents}
						/>{' '}
					</>
				)}
			</>
		</Layout>
	);
};

export default NipInfonatel;
