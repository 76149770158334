import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		text: {
			color: '#8A919C',
		},
		container: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			[theme.breakpoints.down('sm')]: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
		},
	})
);
