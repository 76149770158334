import API from '..';
class ApiRfcCorrection extends API {
	// OBTENER STATUS INICIAL
	getInitialStatus(nss: string, rfc: string, curp: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/cambio-rfc/verify-status`,
				method: 'POST',
				data: { nss, rfc, curp },
			})
		);
	}
	// HACER CITA
	createCase(nss: string, rfc: string, email: string, celular: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/cambio-rfc/haz-cita/caso`,
				method: 'POST',
				data: { nss, rfc, email, celular },
			})
		);
	}

	attachDocuments(formData: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/cambio-rfc/haz-cita/attach-documents`,
				method: 'POST',
				data: { formData },
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		);
	}

	// VALIDAR CON SAT
	consultaDbsat(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/cambio-rfc/consulta-dbsat`,
				method: 'POST',
				data: { nss },
			})
		);
	}

	actualizaDbsat(
		nss: string,
		rfcBDSAT: string,
		email: string,
		telefonoMovil: string
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/cambio-rfc/actualiza-dbsat`,
				method: 'POST',
				data: { nss, rfcBDSAT, email, telefonoMovil },
			})
		);
	}

	// VALIDAR CON FIRMA ELECTRÓNICA
	firmaElectronica(
		nss: string,
		email: string,
		fullName: any,
		telefonoMovil: string,
		rfc: string,
		curp: string
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/cambio-rfc/firma-electronica`,
				method: 'POST',
				data: { nss, email, fullName, telefonoMovil, rfc, curp },
			})
		);
	}

	// VALIDAR CON CFDI
	consultaCFDI(formData: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/cambio-rfc/cfdi`,
				method: 'POST',
				data: { formData },
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		);
	}
}

export const apiRfcCorrection = new ApiRfcCorrection('');
