// DEPENDENCIES
// COMPONNETS
import { RoundBtn } from '../../../AssociateNssCredit/Styles';
import TwoColumnTable from '../../../../components/TwoColumnTable';
// RESOURCES
import { actualConditionsRows } from '../../utils';
import theme from '../../../../config/theme';

const ActualConditions = ({ step, setStep, creditData }: any) => {
	return (
		<>
			<div style={{ textAlign: 'center' }}>
				<h2 style={{ color: theme.palette.info.main }}>
					Condiciones actuales de tu crédito
				</h2>
				<TwoColumnTable
					data={creditData}
					principal={actualConditionsRows}
				/>
				<p>
					Consulta las condiciones de tu crédito con el programa de
					Responsabilidad Compartida
				</p>
			</div>
			<div className="actions-buttons">
				<RoundBtn onClick={() => setStep(step - 1)} variant="outlined">
					Regresar
				</RoundBtn>
				<RoundBtn onClick={() => setStep(step + 1)} variant="contained">
					Ver condiciones
				</RoundBtn>
			</div>
		</>
	);
};

export default ActualConditions;
