import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	iconsImg: {
		width: 13,
		marginRight: 5,
	},
	txtPrincipales: {
		color: '#293990',
		fontSize: '14px !important',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		[theme.breakpoints.down('sm')]: {
			fontSize: '10px !important',
		},
	},
	txtRadio: {
		fontSize: '16px !important',
		letterSpacing: 0,
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px !important',
		},
	},
	divPrincipal: {
		padding: '40px 90px',
		[theme.breakpoints.down('sm')]: {
			padding: '30px',
		},
	},
	divChecks: {
		paddingLeft: '40px',
	},
	contentFileDataButtons: {
		display: 'flex',
		justifyContent: 'center',
		marginBlock: 10,
		marginBottom: 50,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
		},
		[theme.breakpoints.down('md')]: {},

		'&>button': {
			[theme.breakpoints.down('sm')]: {
				margin: '20px 0px !important',
				width: '100% !important',
			},
		},
	},
	divDocType: {
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	divImg: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	divTxt: {
		width: '50%',
		fontSize: '14px',
		padding: '20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '0px',
		},
	},
	imgs: {
		width: '100%',
	},
}));
