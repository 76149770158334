const createData = (year: string, tasaRen: string) => {
	return { year, tasaRen };
};

const rows = [
	createData('2023', '6.33%'),
	createData('2022', '7.82%'),
	createData('2021', '7.36%'),
	createData('2020', '6.00%'),
	createData('2019', '7.32%'),
	createData('2018', '6.38%'),
	createData('2017', '8.32%'),
	createData('2016', '6.81%'),
	createData('2015', '6.81%'),
	createData('2014', '6.51%'),
	createData('2013', '5.98%'),
	createData('2012', '5.76%'),
	createData('2011', '5.14%'),
	createData('2010', '7.47%'),
	createData('2009', '5.76%'),
	createData('2008', '7.12%'),
	createData('2007', '7.91%'),
	createData('2006', '8.21%'),
	createData('2005', '6.95%'),
	createData('2004', '8.35%'),
	createData('2003', '6.84%'),
	createData('2002', '8.34%'),
	createData('2001', '7.01%'),
	createData('2000', '11.14%'),
];

export default rows;
