import { Alert, Box, Divider } from '@mui/material';
import { useStyles } from './styles';
import { RoundBtn } from '../RoundBtn/roundBtn';
import Header from '../Header';
import { useGeneralStyles } from '../../views/styles/general.styles';
import { useCreditOptions } from './useCreditOptions';
import CustomAlert from '../../../../components/CustomAlert';
import { MIUC_PRODUCTS } from '../../constants/products';
import { ErrorOutline } from '@mui/icons-material';
import HelpPanel from '../HelpPanel';
import { isEmpty } from '../../../../utils/validators';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const CreditOptions = ({ returnMain }: { returnMain?: boolean}) => {

  const { newStep, returnStep, dataProcessController, getCreditOptions, handleGetCreditType, AlertInput } = useCreditOptions();
  const classes = useStyles();
  const generalStyle = useGeneralStyles();
  const navigate = useNavigate();
  
  useEffect(() => {
    handleGetCreditType();
  },[])

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Header steps={newStep}></Header>
      </Box>
      <Box className={generalStyle.content}>
        {dataProcessController.processInfo?.credit !== MIUC_PRODUCTS.AUTOPRODUCCION && (
          <Box mt={2}>
            <CustomAlert
              show
              severity="warning"
              message={
                <Box textAlign={'left'}>
                  Con Unamos Crédito Infonavit, puedes sumar tu
                  crédito con el crédito de algún familiar, pareja o
                  amigo, para adquirir una mejor vivienda.
                  Precalifícate eligiendo la opción "familiar o
                  corresidencial" y conoce el monto al que juntos
                  pueden acceder.
                </Box>
              }
            />
          </Box>
        )}
        <AlertInput />
        <h4 className="title">
          {
            dataProcessController.processInfo?.credit === MIUC_PRODUCTS.COMPRA 
            ? 'Puedes solicitar tu crédito de forma individual o sumarlo con alguien más.'
            : 'Suma tu crédito con alguien más o solicítalo de forma individual.'
          }
          
        </h4>
        <Box className={classes.optionList}>
          {getCreditOptions()}
        </Box>
        {/* {dataProcessController.conyuge?.estatusAsociacion === '04' &&
          (
            <Alert
              className={classes.alert}
              icon={<ErrorOutline />}
              severity="info"
              style={{ textAlign: 'left' }}
            >
              Tu cónyuge está vinculado a tu cuenta.
            </Alert>
          )}
        {!isEmpty(dataProcessController.conyuge) &&
          dataProcessController.conyuge?.estatusAsociacion !== '04' &&
          (
            <Alert
              className={classes.alert}
              icon={<ErrorOutline />}
              severity="info"
              style={{ textAlign: 'left' }}
            >
              Tu cónyuge, familiar o corresidente no esta asociado a
              tu cuenta. <HelpPanel type="VINCULA_CONYUGE" />
            </Alert>
          )} */}
        {/* { dataProcessController.conyuge?.tipoAsociacion === 'C' &&
          // dataProcessController.conyuge?.code === '2102'
          (
            <Alert
              icon={<ErrorOutline />}
              severity="info"
              style={{ textAlign: 'left' }}
            >
              Tu cónyuge aún no esta en posibilidades de solicitar su
              crédito.
            </Alert>
          )} */}
        {/* {dataProcessController.conyuge?.tipoAsociacion === 'C' &&
          ['4', '5'].includes(
            dataProcessController.conyuge?.stageId.toString()
          ) &&
          (
            <Alert
              className={classes.alert}
              icon={<ErrorOutline />}
              severity="info"
              style={{ textAlign: 'left' }}
            >
              El historial crediticio de tu cónyuge muestra
              irregularidades. Aclara su situación con las Sociedades
              de información Crediticia.
            </Alert>
          )} */}
        {/* { dataProcessController.conyuge?.tipoAsociacion === 'C' &&
          !['4', '5'].includes(
            dataProcessController.conyuge?.stageId.toString()
          ) &&
          (
            <Alert
              icon={<ErrorOutline />}
              severity="info"
              style={{ textAlign: 'left' }}
            >
              {dataProcessController.conyuge?.mensajeAsociacion}
            </Alert>
          )} */}
        {/* {
          <Alert
            icon={<ErrorOutline />}
            severity="info"
            style={{ textAlign: 'left' }}
          >
            Te invitamos a hacer tu presupuesto familiar. Si
            administras bien tus gastos te alcanzará para hacer más.
          </Alert>
        } */}
        <Divider sx={{ marginBottom: '32px' }} />
        <div className="credit-request-actions-container">
          <RoundBtn 
            variant="contained" 
            onClick={ 
              returnMain 
              ? () => navigate(-1)
              : () => returnStep()
            }>
            Regresar
          </RoundBtn>
        </div>
      </Box>
    </Box>
  );
};
