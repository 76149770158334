import { Grid, Link, Badge } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useStyles } from './styles';
import TooltipCustom from '../../../components/TooltipCustom';
import HelpPanel from '../../HelpPanel';
import { Info } from '@mui/icons-material';
import iconoPDF from '../../../../../assets/img/icono-descarga.png';
import avaluo from '../../../assets/svg/avaluo.svg';
import expediente from '../../../assets/svg/expediente.svg';
import { ConstantSteps } from '../constantSteps/constantSteps';

export const ConstruirSteps = (data: {
	avanceTaller: number;
	alert: String;
}) => {
	const classes = useStyles();
	const {
		tallerSaberMas,
		verificaTusDatos,
		notarioTitulacion,
		solicitaTuCredito,
	} = ConstantSteps(data);

	return [
		tallerSaberMas,
		verificaTusDatos,
		{
			paperType: 'simple',
			paperIcon: avaluo,
			title: 'Gestión de avalúo',
			description:
				'Solicita el estudio del valor y dictamen técnico del terreno, con cualquiera de las Unidades de Valuación con las que trabaja el Infonavit.',
			link: (
				<>
					<a
						href="/precalificacion-puntos/unidades-valuacion"
						className={classes.optionEnlace}
					>
						Consulta el directorio <ChevronRightIcon />
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href="https://portalmx.infonavit.org.mx/wps/wcm/connect/ae52f200-3a81-4a96-be71-4c08979dbccf/Solicitud_de_estudio_de_valor_y_dictamen_tecnico_del_terreno.pdf?MOD=AJPERES&CVID=mV1NiKw"
						className={classes.optionEnlace}
					>
						Descarga la solicitud aquí{' '}
						<img
							src={iconoPDF}
							alt="iconoPDF"
							className={classes.requestIcon}
						/>
					</a>
				</>
			),
		},
		{
			paperType: 'viewMore',
			paperIcon: expediente,
			title: 'Integra tu expediente',
			description: 'Reúne tus documentos para tramitar tu crédito',
			viewMoreButton: 'Consulta la documentación',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<b>Reúne estos documentos.</b>
						<div>
							<ul className={classes.viewMoreList}>
								<li>
									Solicitud de inscripción de crédito.
									<Link
										target="_blank"
										href="https://portalmx.infonavit.org.mx/wps/wcm/connect/f2b56503-7a4c-44c3-9ef5-075b8325ae9e/Solicitud-inscripcion-credito.pdf?MOD=AJPERES&CVID=nNmSlHE"
									>
										Descárgala aquí
									</Link>
								</li>
								<li>
									Acta de nacimiento en original y copia.{' '}
									<Badge
										badgeContent={
											<HelpPanel type="ACTA_NACIMIENTO" />
										}
										className={classes.helpPanel}
									></Badge>
								</li>
								<li>
									Identificación oficial vigente en original y
									copia.{' '}
									<TooltipCustom
										arrow
										placement="right"
										title={
											<>
												<b>
													La identificación oficial
													puede ser:
												</b>
												<ul>
													<li>
														Credencial para votar
														vigente
													</li>
													<li>Pasaporte</li>
												</ul>
											</>
										}
									>
										<Info
											color="primary"
											className={classes.infoStyle}
										/>
									</TooltipCustom>
								</li>
								<li>
									Estado de cuenta bancario del trabajador con
									Clave Interbancaria Estandarizada (CLABE).
								</li>
								<li>
									Acta de nacimiento en original y copia del
									cónyuge (en su caso).
								</li>
								<li>
									Identificación oficial (INE/IFE) o pasaporte
									vigente, en original y copia del cónyuge (en
									su caso).
								</li>
								<li>Acta de matrimonio (en su caso).</li>
								<li>
									Copia de la Constancia del curso "Saber más
									para decidir mejor" a través de Mi Cuenta
									Infonavit.
								</li>
							</ul>
						</div>
						<div className={classes.listColumn}>
							<Grid container columns={12} spacing={2}>
								<Grid item xs={12} md>
									<b className={classes.blockContent}>
										Documentos de construcción:
									</b>
									<ul className={classes.viewMoreList}>
										<li>
											Solicítalos con este{' '}
											<Link
												href="https://portalmx.infonavit.org.mx/wps/wcm/connect/ae52f200-3a81-4a96-be71-4c08979dbccf/Solicitud_de_estudio_de_valor_y_dictamen_tecnico_del_terreno.pdf?MOD=AJPERES&CVID=mV1NiKw"
												target="_blank"
											>
												formato
											</Link>{' '}
											en cualquiera de las unidades de
											valuación con las que trabaja el
											Infonavit.
										</li>
										<li>
											Contrato de obra a precio alzado y
											tiempo determinado.
										</li>
										<li>
											<Link
												href="https://portalmx.infonavit.org.mx/wps/wcm/connect/fe677bfa-8de6-45a6-9725-f2dffaca78a8/Ced_Prest_Ind_LIV.pdf?MOD=AJPERES&CVID=mV1Nvdd"
												target="_blank"
											>
												Cédula de presentación y ficha
												técnica.
											</Link>
										</li>
									</ul>
								</Grid>
								<Grid item xs={12} md>
									<b className={classes.blockContent}>
										Proyecto Ejecutivo, el cuál debe
										contener:
									</b>
									<ul className={classes.viewMoreList}>
										<li>
											Proyecto Arquitectónico: plano de
											localización y planos
											arquitectónicos, planos
											estructurales, planos de
											instalaciones (eléctrica,
											hidráulica, sanitaria, gas,
											teléfono, etc.), estudio de mecánica
											de suelo (en su caso) y memoria de
											cálculo.
										</li>
										<li>
											<Link
												href="https://portalmx.infonavit.org.mx/wps/wcm/connect/923b7483-aee7-4308-83e6-1ccfca817564/Programa_de_obra.xls?MOD=AJPERES&CVID=mV1ND.y"
												target="_blank"
											>
												Programa de obra y programa
												físico financiero.
											</Link>
										</li>
										<li>
											<Link
												href="https://portalmx.infonavit.org.mx/wps/wcm/connect/0861db6b-dade-4bcd-b159-a1681b6a336e/Presupuesto_de_obra+%281%29.xls?MOD=AJPERES&CVID=mV1QQaj"
												target="_blank"
											>
												Presupuesto para la
												construcción.
											</Link>
										</li>
										<li>Licencia de construcción.</li>
									</ul>
								</Grid>
							</Grid>
						</div>
					</div>
				</>
			),
		},
		notarioTitulacion,
		solicitaTuCredito,
	];
};
