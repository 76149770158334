import Header from '../../../components/Header';
import { useGeneralStyles } from '../../styles/general.styles';
import { useStyles } from '../styles';
import { Box, Divider, Grid, InputAdornment } from '@mui/material';
import CustomAlert from '../../../../../components/CustomAlert';
import { RoundBtn } from '../../../components/RoundBtn/roundBtn';
import ModalLoading from '../../../../../components/ModalLoading';
import ProductsMiuc from '../../../components/ProductsMiuc';
import { formatMoney2 } from '../../../../../utils/validators';
import TextInput from '../../../../../components/TextInput';
import CustomButton from '../../../../../components/Button';
import { rowsTitleConstruYo } from '../../../utils/selectionResume.utils';
import SelectCustom from '../../../../../components/SelectCustom';
import { useConstruYoTable } from '../hooks/useConstruYoTable';

interface IProps {
	setProduct: (product: any) => void;
}

export const ConstruYoTable = () => {
	const classes = useStyles();

	const generalStyle = useGeneralStyles();

	const {
		loading,
		next,
		returnStep,
		setSelectProduct,
		selectProduct,
		newStep,
		AlertInput,
    	AlertInputCalc,
		handleCalculate,
		plazoOptions,
		handleChangePlazo,
		productsSelect,
		disableButton,
		valorObra,
		setValorObra,
		handleSetDataObra,
		plazo,
	} = useConstruYoTable();

	return (
		<Box>
			<Header steps={newStep}></Header>
			<Box className={classes.content}>
				<div className={classes.contentPadding}>
          			<AlertInputCalc />
				</div>
				<Grid container justifyContent="center">
					<Grid container className={classes.inputs} spacing={2} justifyContent="flex-end">
						<Grid item xs={12} md={8}>
							<TextInput
								startAdornment={
									<InputAdornment position="start">
										$
									</InputAdornment>
								}
								label="Costo estimado de la obra"
								placeholder="0.00"
								name="cost"
								id="obra-costo"
								value={valorObra}
								onChange={handleSetDataObra}
								onBlur={() => {
									if (valorObra) {
										if (
											valorObra === '.'
										) {
											setValorObra(valorObra);
										} else {
											setValorObra(
												formatMoney2(
													Number(
														valorObra
													)
												).substring(1),
											);
										}
									}
								}}
								onFocus={() => {
									if (valorObra) {
										setValorObra(valorObra
													.toString()
													.replaceAll(',', '')
													.replaceAll(' ', ''),
										);
									}
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<div className={classes.buttonsL6}>
								<CustomButton
									onClick={handleCalculate}
									label="Calcular"
									variant="solid"
								/>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div>
								<SelectCustom
									value={plazo}
									options={plazoOptions}
									label="Plazo Seleccionado"
									placeholder={'Selecciona'}
									onChange={handleChangePlazo}
								></SelectCustom>
							</div>
						</Grid>
					</Grid>
				</Grid>
				<Divider sx={{ margin: '20px 50px' }} />
				<div className={classes.contentPadding}>
          <AlertInput />
					<Box sx={{ maxWidth: '100%' }}>
						<ProductsMiuc
							keys={rowsTitleConstruYo}
							products={productsSelect}
							keyHeader={'producto'}
							selectProduct={selectProduct}
							setSelectProduct={setSelectProduct}
						/>
					</Box>
				</div>
				<Divider sx={{ margin: '20px 50px' }} />
				<div className={generalStyle.bottonsContainer}>
					<RoundBtn
						id="regresar"
						variant="outlined"
						onClick={() => returnStep()}
					>
						Regresar
					</RoundBtn>
					<RoundBtn
						id="continuar"
						disabled={disableButton}
						variant="contained"
						onClick={() => next()}
					>
						Continuar
					</RoundBtn>
				</div>
			</Box>
			<ModalLoading loading={loading} />
		</Box>
	);
};
