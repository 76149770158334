/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { actions } from '../../../store/modules/miuc';
import { MIUC_PROCESS_STEPS } from '../constants/processSteps';
import { IProcessController } from '../interfaces/processController';
import { useEffect, useState } from 'react';
import { PRODUCT_LINES } from '../constants/products';
import { MIUC_PROCESS_VIEWS } from '../constants/process';
import { MIUC_FLOW_STEPS } from '../constants/basicSteps';

export interface ISelection {
	title: string;
	description: string;
}

const FIRST_PAGES = {
	[PRODUCT_LINES.COMPRAR_TERRENO]: MIUC_PROCESS_VIEWS.SELECTION_CREDIT,
	[PRODUCT_LINES.MEJORA_HOGAR]: MIUC_PROCESS_VIEWS.SELECTION_CREDIT,
	[PRODUCT_LINES.PAGAR_HIPOTECA]: MIUC_PROCESS_VIEWS.SELECTION_CREDIT,
	[PRODUCT_LINES.COMPRAR_VIVIENDA]: MIUC_PROCESS_VIEWS.SELECTION_CREDIT,
	[PRODUCT_LINES.CONTRUIR_VIVIENDA]: MIUC_PROCESS_VIEWS.SELECTION_CREDIT,
	[PRODUCT_LINES.SIN_RELACION_LABORAL]: MIUC_PROCESS_VIEWS.NO_WORK_MENU,
};

export interface IUseController {
	firstPage?: number;
	pages?: number[];
}

export const isControlled = (
	props: IUseController | undefined
): props is IUseController => {
	return props !== undefined;
};

export const useController = (props?: IUseController | undefined) => {
	const dispatch = useDispatch();
	const { dataProcessController } = useSelector(
		(state: RootState) => state.miuc
	);
	const [save, setSave] = useState<'SAVE' | 'BACKUP' | 'IGNORE'>('IGNORE');
	const [controller, setController] = useState<IProcessController>({
		...dataProcessController,
	});

	useEffect(() => {
		setSave('IGNORE');
		setController({
			...dataProcessController,
		});
	}, [dataProcessController]);

	useEffect(() => {
		if (save === 'SAVE') {
			dispatch(
				actions.setDataProcessController({
					...dataProcessController,
					processInfo: {
						...dataProcessController?.processInfo,
						...controller?.processInfo,
					},
					processData: {
						...dataProcessController?.processData,
						...controller?.processData,
					},
					agroData: {
						...dataProcessController?.agroData,
						...controller?.agroData,
					},
					titular: {
						...dataProcessController?.titular,
						...controller?.titular,
					},
					conyuge: {
						...dataProcessController?.conyuge,
						...controller?.conyuge,
					},
					destinosAplicables:
						dataProcessController?.destinosAplicables,
					history: [...controller.history],
				})
			);
			setSave('IGNORE');
		} else if (save === 'BACKUP') {

			const data = {
				...controller,
				processData: {
					...controller?.processData,
				},
				processInfo: {
					...controller?.processInfo,
				},
				history: [...controller.history],
			};
			dispatch(actions.backupState(data));
			setSave('IGNORE');
		}
	}, [controller]);

	//Agrega al arreglo que se usa para indicar la seleccion
	const addSelection = ({ title, description }: ISelection) => {
		setController((value) => {
			const selection =
				value.processInfo.selection !== undefined
					? [
						...value.processInfo.selection,
						{
							title: title,
							description: description,
						},
					]
					: [
						{
							title: title,
							description: description,
						},
					];

			return {
				...value,
				processInfo: {
					...value.processInfo,
					selection: selection,
				},
				processData: {
					...value.processData,
				},
				history: value.history,
			};
		});
	};

	const removeSelection = () => {
		setController((value) => {
			const selection = [...value.processInfo.selection];
			selection.pop();

			return {
				...value,
				processInfo: {
					...value.processInfo,
					selection: selection,
				},
				processData: {
					...value.processData,
				},
				history: value.history,
			};
		});
	};

	//Guardar cualquier informacion relevante del flujo, y aplica el guardado en Redux
	const saveData = async(param?: any, save?: boolean) => {
		if (save === true) setSave('SAVE');

		setController((value) => {
			const dataPrimary = {
				processInfo: {
					...value?.processInfo,
					...param?.processInfo || {},
				},
				processData: {
					...value?.processData,
					...param?.processData || {},
				},
				agroData: {
					...value?.agroData,
					...param?.agroData || {},
				},
			};
			const data = {
				...dataPrimary,
				titular: {
					...value?.titular,
					...param?.titular || {},
				},
				conyuge: {
					...value?.conyuge,
					...param?.conyuge || {},
				},
				destinosAplicables: value?.destinosAplicables,
				history: [...(value?.history || []), ...param.history || []],
			};
			return data;
		});
	};

	const nextStep = (customStep?: number) => {
		setSave('SAVE');
		setController((value) => {
			const step = customStep !== undefined
				? customStep
				: value.processData.step !== undefined
					? value.processData.step + 1
					: 0;
          
			const data = {
				processInfo: {
					...value.processInfo,
				},
				processData: {
					...value.processData,
					step,
				},
			};
			let history =
				value.history !== undefined ? [...(value.history || []), data] : [data];

			return {
				...value,
				...data,
				history,
			};
		});
	};

	//Obtiene la vista que se debe mostrar en base al credit y el step de IProcessControlller
	const getStepView = () => {
		const { productLine, step } = dataProcessController?.processData;
		const currentCredit = dataProcessController?.processInfo?.credit;

		const firstPage =
			isControlled(props) && props.firstPage !== undefined
				? props.firstPage
				: FIRST_PAGES[productLine];

		if (step !== undefined) {
			return isControlled(props) && props.pages !== undefined
				? props.pages[step]
				: MIUC_PROCESS_STEPS[currentCredit]?.steps[step];
		} else {
			return firstPage;
		}
	};

	//Retorna el paso y hace un backup de los datos del paso anterior en Redux
	const returnStep = () => {
		setSave('BACKUP');

		setController((value) => {
			const copy = JSON.parse(JSON.stringify(value.history));
			copy.pop();
			const lastIndex = copy.length - 1;
			const lastHistory = copy[lastIndex];

			const data = {
				...value,
				processData: {
					...lastHistory?.processData,
				},
				processInfo: {
					...lastHistory?.processInfo,
				},
				history: [...copy],
			};
			return data;
		});
	};

	const getProgressStep = () => ({
		active: dataProcessController.processData?.active || 0,
		total: dataProcessController.processData?.total || 0
	});

	const calcProgress = () => {
		const { active, total } = getProgressStep();
		if (total > 0) return (100 / total) * active;
		return 0;
	};

	return {
		addSelection,
		removeSelection,
		saveData,
		nextStep,
		returnStep,
		getStepView,
		getProgressStep,
		calcProgress,
		dataProcessController,
	};
};
