import thunk from 'redux-thunk';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
	combineReducers,
	createStore,
	applyMiddleware,
	compose,
	Store,
} from 'redux';
import session from './modules/session';
import credit from './modules/credit';
import miuc from './modules/miuc';
import loading from './modules/loading';
import saldo from './modules/saldo';
import bitacora from './modules/bitacora';

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}

export const mainReducer = combineReducers({ session, credit, miuc, loading, saldo, bitacora });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(
	{
		key: 'root',
		storage,
		whitelist: ['session', 'credit', 'miuc', 'saldo', 'bitacora'],
		version: 1,
	},
	mainReducer
);

export const storeFactory = (
	initialState = {}
): { store: Store; persistor: Persistor } => {
	const store = createStore(
		persistedReducer,
		initialState,
		composeEnhancers(applyMiddleware(thunk))
	);
	const persistor = persistStore(store);
	composeEnhancers(applyMiddleware(thunk));
	return { store, persistor };
};

export default storeFactory();

export type RootState = ReturnType<typeof mainReducer>;
