import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('107', 'correccion-rfc');

export const ENDPOINTS_REGISTRO_CORRECCION_RFC: IBitacora = {
	'/cambio-rfc/verify-status': _bitacora.Read(
		'107100',
		'Obtener estatus inicial'
	),
	'/cambio-rfc/haz-cita/caso': _bitacora.Create('107101', 'Crear Cita'),
	'/cambio-rfc/haz-cita/attach-documents': _bitacora.Update(
		'107102',
		'Adjuntar documetos'
	),
	'/cambio-rfc/consulta-dbsat': _bitacora.Read('107103', 'Validar con SAT'),
	'/cambio-rfc/actualiza-dbsat': _bitacora.Update(
		'107104',
		'Actualizar con SAT'
	),
	'/cambio-rfc/firma-electronica': _bitacora.Read(
		'107105',
		'Validar con firma electronica'
	),
	'/cambio-rfc/cfdi': _bitacora.Read('107106', 'Validar con CFDI'),
};
