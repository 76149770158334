/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { Radio } from '@mui/material';
import { useStyles } from '../styles/styles';
import HeaderStep from './HeaderStep';
import TextInput from '../../../../../components/TextInput';
import { isNumber } from '../../../../../utils/expressions';

const QuestStep6 = ({
	quest,
	nextStep,
	handleChange,
	afterQuestionarySteps,
	alert,
	setNextDisabled
}: any) => {
	const classes = useStyles();
	const [quests, setQuests] = useState([] as any);
	const [numTarjetas, setNumTarjetas] = useState('');
	const [dataTarjetas, setDataTarjetas] = useState([] as any);
	const [numBancarias, setNumBancarias] = useState('');
	const [dataBancarias, setDataBancarias] = useState([] as any);
	const [date, setDate] = useState({
		month: '',
		year: '',
	});
	const [valor, setValor] = useState('');
	const [years, setYears] = useState('');

	useEffect(() => {
		drawQuestions();
	}, [
		quest,
		numTarjetas,
		dataTarjetas,
		numBancarias,
		dataBancarias,
		date,
		valor,
		years,
	]);

	useEffect(() => {
		let respuestaDate = `Mes ${date.month} Año ${date.year}`;
		handleChange(respuestaDate, '4.8');
	}, [date]);

	useEffect(() => {
		let respuesta = `$${valor}`;
		handleChange(respuesta, '4.9');
	}, [valor]);

	useEffect(() => {
		let respuesta = '';
		parseInt(valor) > 0 && valor.length > 0 ? respuesta = `${years} años` : respuesta = ''
		handleChange(respuesta, '4.10');
	}, [years]);

	const setearTarjetas = (cant: any) => {
		let cuentas = [];
		let quantity = parseInt(cant) + 1;
		for (let i = 1; i < quantity; i++) {
			let item = {
				id: i,
				text: 'Tienda ' + i + ':',
				value: '',
				name: '',
			};
			cuentas.push(item);
		}
		setDataTarjetas(cuentas);
	};

	const updateTarjetas = (value: any, type: any, index: any) => {
		const newCuenta = dataTarjetas.map((item: any) => {
			if (item.id === index + 1) {
				return {
					...item,
					[type]: value,
				};
			}
			return item;
		});
		setDataTarjetas(newCuenta);
		if (type === 'name') {
			let respuesta = '';
			for (let idx = 0; idx < dataTarjetas.length; idx++) {
				let item = `${idx + 1}) ${dataTarjetas[idx].text} ${index === idx ? value : dataTarjetas[idx].name
					}; `;
				respuesta = respuesta + item;
			}
			handleChange(respuesta, '4.2');
		} else {
			let respuesta = '';
			for (let idx = 0; idx < dataTarjetas.length; idx++) {
				let item = `${idx + 1}) ${dataTarjetas[idx].text} $${index === idx ? value : dataTarjetas[idx].value
					}; `;
				respuesta = respuesta + item;
			}
			handleChange(respuesta, '4.3');
		}
	};

	const setearBancarias = (cant: any) => {
		let cuentas = [];
		let quantity = parseInt(cant) + 1;
		for (let i = 1; i < quantity; i++) {
			let item = {
				id: i,
				text: 'Banco ' + i + ':',
				value: '',
				name: '',
			};
			cuentas.push(item);
		}
		setDataBancarias(cuentas);
	};

	const updateBancarias = (value: any, type: any, index: any) => {
		const newCuenta = dataBancarias.map((item: any) => {
			if (item.id === index + 1) {
				return {
					...item,
					[type]: value,
				};
			}
			return item;
		});
		setDataBancarias(newCuenta);
		if (type === 'name') {
			let respuesta = '';
			for (let idx = 0; idx < dataBancarias.length; idx++) {
				let item = `${idx + 1}) ${dataBancarias[idx].text} ${index === idx ? value : dataBancarias[idx].name
					}; `;
				respuesta = respuesta + item;
			}
			handleChange(respuesta, '4.5');
		} else {
			let respuesta = '';
			for (let idx = 0; idx < dataBancarias.length; idx++) {
				let item = `${idx + 1}) ${dataBancarias[idx].text} $${index === idx ? value : dataBancarias[idx].value
					}; `;
				respuesta = respuesta + item;
			}
			handleChange(respuesta, '4.6');
		}
	};

	const drawQuestions = () => {
		const questions: any = [];
		quest.map((item: any, index: any) => {
			const newItem = {
				title: `${item.numero.split('.')[1]}.- ${item.pregunta}`,
				body: (
					<>
						{item.opciones.length > 0 &&
							item.opciones.map((opcion: any) => (
								<div
									className={classes.itemsFlexCenter}
									key={opcion + item.pregunta}
								>
									<Radio
										checked={
											item.respuesta ===
											opcion.split(')')[0]
										}
										onChange={(e: any) => {
											let value =
												e.target.value.split(')')[0];
											handleChange(value, item.numero);
										}}
										name={opcion.split(')')[0]}
										value={opcion.split(')')[0]}
									/>
									{opcion}
								</div>
							))}
						{item.numero === '4.1' && (
							<div style={{ maxWidth: 100 }}>
								<TextInput
									id="valor"
									name="valor"
									value={numTarjetas}
									placeholder="0"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										setNumTarjetas(e.target.value);
										handleChange(e.target.value, '4.1');
									}}
									onBlur={() => {
										if (numTarjetas) {
											setearTarjetas(numTarjetas);
										} else {
											setearTarjetas([]);
										}
									}}
								/>
							</div>
						)}
						{item.numero === '4.2' &&
							dataTarjetas.length > 0 &&
							dataTarjetas.map((opcion: any, index: any) => (
								<div
									className={classes.itemsQuest9}
									key={index + item.pregunta}
								>
									<div style={{ flexGrow: 1 }}>
										{opcion.text}
									</div>
									<div className={classes.itemsFlexInput}>
										<div
											style={{
												textAlign: 'left',
												marginRight: '20px',
												maxWidth: '500px',
											}}
										>
											<TextInput
												id={
													'question-name-tar-' +
													item.numero
												}
												name={'name-tar'}
												placeholder="Nombre"
												onChange={(e: any) => {
													updateTarjetas(
														e.target.value,
														'name',
														index
													);
												}}
												contentStyles={{
													maxWidth: 500,
												}}
												value={opcion.name}
											/>
										</div>
									</div>
								</div>
							))}
						{item.numero === '4.2' && dataTarjetas.length === 0 && (
							<div
								className={classes.itemsQuest9}
								key={0 + item.pregunta}
								style={{ border: 'none', color: 'gray' }}
							>
								<div style={{ flexGrow: 1 }}>Tienda</div>
								<div className={classes.itemsFlexInput}>
									<div
										style={{
											textAlign: 'left',
											marginRight: '20px',
											maxWidth: '500px',
										}}
									>
										<TextInput
											id={'question-tarj-' + item.numero}
											name={'question-tarj'}
											placeholder="Nombre"
											contentStyles={{ maxWidth: 500 }}
											value={''}
											disabled={true}
										/>
									</div>
								</div>
							</div>
						)}
						{item.numero === '4.3' &&
							dataTarjetas.length > 0 &&
							dataTarjetas.map((opcion: any, index: any) => (
								<div
									className={classes.itemsQuest9}
									key={index + item.pregunta}
								>
									<div style={{ flexGrow: 1 }}>
										{opcion.text}
									</div>
									<div className={classes.itemsFlexInput}>
										<div
											style={{
												textAlign: 'left',
												marginRight: '20px',
												maxWidth: '500px',
											}}
										>
											<TextInput
												id={
													'question-cant-tar-' +
													item.numero
												}
												name={'cant-tar'}
												placeholder="$0.00"
												onChange={(e: any) => {
													if (
														!isNumber(
															e.target.value
														)
													)
														return;
													updateTarjetas(
														e.target.value,
														'value',
														index
													);
												}}
												contentStyles={{
													maxWidth: 500,
												}}
												value={opcion.value}
											/>
										</div>
									</div>
								</div>
							))}
						{item.numero === '4.3' && dataTarjetas.length === 0 && (
							<div
								className={classes.itemsQuest9}
								key={0 + item.pregunta}
								style={{ border: 'none', color: 'gray' }}
							>
								<div style={{ flexGrow: 1 }}>Tienda</div>
								<div className={classes.itemsFlexInput}>
									<div
										style={{
											textAlign: 'left',
											marginRight: '20px',
											maxWidth: '500px',
										}}
									>
										<TextInput
											id={'question-cant-' + item.numero}
											name={'question-cant'}
											placeholder="$0.00"
											contentStyles={{ maxWidth: 500 }}
											value={''}
											disabled={true}
										/>
									</div>
								</div>
							</div>
						)}

						{item.numero === '4.4' && (
							<div style={{ maxWidth: 100 }}>
								<TextInput
									id="valor"
									name="valor"
									value={numBancarias}
									placeholder="0"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										setNumBancarias(e.target.value);
										handleChange(e.target.value, '4.4');
									}}
									onBlur={() => {
										if (numTarjetas) {
											setearBancarias(numBancarias);
										} else {
											setearBancarias([]);
										}
									}}
								/>
							</div>
						)}
						{item.numero === '4.5' &&
							dataBancarias.length > 0 &&
							dataBancarias.map((opcion: any, index: any) => (
								<div
									className={classes.itemsQuest9}
									key={index + item.pregunta}
								>
									<div style={{ flexGrow: 1 }}>
										{opcion.text}
									</div>
									<div className={classes.itemsFlexInput}>
										<div
											style={{
												textAlign: 'left',
												marginRight: '20px',
												maxWidth: '500px',
											}}
										>
											<TextInput
												id={
													'question-name-ban-' +
													item.numero
												}
												name={'name-ban'}
												placeholder="Nombre"
												onChange={(e: any) => {
													updateBancarias(
														e.target.value,
														'name',
														index
													);
												}}
												contentStyles={{
													maxWidth: 500,
												}}
												value={opcion.name}
											/>
										</div>
									</div>
								</div>
							))}
						{item.numero === '4.5' &&
							dataBancarias.length === 0 && (
								<div
									className={classes.itemsQuest9}
									key={0 + item.pregunta}
									style={{ border: 'none', color: 'gray' }}
								>
									<div style={{ flexGrow: 1 }}>Banco</div>
									<div className={classes.itemsFlexInput}>
										<div
											style={{
												textAlign: 'left',
												marginRight: '20px',
												maxWidth: '500px',
											}}
										>
											<TextInput
												id={
													'question-ban-' +
													item.numero
												}
												name={'question-ban'}
												placeholder="Nombre"
												contentStyles={{
													maxWidth: 500,
												}}
												value={''}
												disabled={true}
											/>
										</div>
									</div>
								</div>
							)}
						{item.numero === '4.6' &&
							dataBancarias.length > 0 &&
							dataBancarias.map((opcion: any, index: any) => (
								<div
									className={classes.itemsQuest9}
									key={index + item.pregunta}
								>
									<div style={{ flexGrow: 1 }}>
										{opcion.text}
									</div>
									<div className={classes.itemsFlexInput}>
										<div
											style={{
												textAlign: 'left',
												marginRight: '20px',
												maxWidth: '500px',
											}}
										>
											<TextInput
												id={
													'question-cant-ban-' +
													item.numero
												}
												name={'cant-ban'}
												placeholder="$0.00"
												onChange={(e: any) => {
													if (
														!isNumber(
															e.target.value
														)
													)
														return;
													updateBancarias(
														e.target.value,
														'value',
														index
													);
												}}
												contentStyles={{
													maxWidth: 500,
												}}
												value={opcion.value}
											/>
										</div>
									</div>
								</div>
							))}
						{item.numero === '4.6' &&
							dataBancarias.length === 0 && (
								<div
									className={classes.itemsQuest9}
									key={0 + item.pregunta}
									style={{ border: 'none', color: 'gray' }}
								>
									<div style={{ flexGrow: 1 }}>Tienda</div>
									<div className={classes.itemsFlexInput}>
										<div
											style={{
												textAlign: 'left',
												marginRight: '20px',
												maxWidth: '500px',
											}}
										>
											<TextInput
												id={
													'question-ban-' +
													item.numero
												}
												name={'question-ban'}
												placeholder="$0.00"
												contentStyles={{
													maxWidth: 500,
												}}
												value={''}
												disabled={true}
											/>
										</div>
									</div>
								</div>
							)}

						{item.numero === '4.8' && (
							<div
								style={{ maxWidth: 400, display: 'flex' }}
								key={'mes' + item.numero}
							>
								<div
									style={{
										textAlign: 'left',
										marginRight: '20px',
									}}
								>
									<TextInput
										id={'question-month-' + item.numero}
										name={'month'}
										placeholder="Mes"
										onChange={(e: any) => {
											if (!isNumber(e.target.value))
												return;
											if (e.target.value.length > 2)
												return;
											if (e.target.value > 12)
												return;
											if (e.target.value === '00') {
												setDate({
													...date,
													month: '01',
												});
											} else {
												setDate({
													...date,
													month: e.target.value,
												});
											}
										}}
										contentStyles={{ maxWidth: 100 }}
										value={date.month}
										disabled={quest[6].respuesta === '2'}
									/>
								</div>
								<div
									style={{
										textAlign: 'left',
										marginRight: '20px',
									}}
								>
									<TextInput
										id={'question-year-' + item.numero}
										name={'year'}
										placeholder="Año"
										onChange={(e: any) => {
											if (!isNumber(e.target.value))
												return;
											if (e.target.value.length > 4)
												return;
											if (e.target.value > 2023)
												return;
											if (e.target.value.length === 4 && e.target.value < '1900') {
												setDate({
													...date,
													year: '1900',
												});
											} else {
												setDate({
													...date,
													year: e.target.value,
												});
											}
										}}
										contentStyles={{ maxWidth: 300 }}
										value={date.year}
										disabled={quest[6].respuesta === '2'}
									/>
								</div>
							</div>
						)}

						{item.numero === '4.9' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor"
									name="valor"
									value={valor}
									placeholder="$0.00"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										if (e.target.value === '0') return;
										setValor(e.target.value);
									}}
									disabled={quest[6].respuesta === '2'}
								/>
							</div>
						)}
						{item.numero === '4.10' && (
							<div
								style={{
									maxWidth: 150,
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<TextInput
									id="valor"
									name="valor"
									value={years}
									placeholder="0"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										setYears(e.target.value);
									}}
									disabled={quest[6].respuesta === '2'}
								/>
								<div style={{ paddingLeft: 10 }}>Años</div>
							</div>
						)}
					</>
				),
				disabled:
					((index === 7 || index === 8 || index === 9) &&
						quest[6].respuesta === '2') || ((index === 1 || index === 2) && dataTarjetas.length === 0) || ((index === 4 || index === 5) && dataBancarias.length === 0)
						? true
						: false,
			};
			return questions.push(newItem);
		});
		setQuests(questions);
	};

	const verifyComplete = () => {
		let questHabilitados: any = []
		for (let i = 0; i < quests.length; i++) {
			if (quests[i].disabled === false) {
				questHabilitados.push(i)
			}
		}
		let respuesta = quest.every((item: any, index: any) => {
			if (questHabilitados.includes(index) && item.respuesta.length === 0) return false
			else return true
		})
		return respuesta
	};

	const sendData = () => {
		setNextDisabled(['4.11', '4.12', '4.13'])
		nextStep();
	};

	return (
		<HeaderStep
			title={'Egresos y deudas'}
			subTitle={'Tienda departamental'}
			nextStep={sendData}
			afterQuestionarySteps={afterQuestionarySteps}
			disabledContinue={!verifyComplete()}
			disabledButton={!verifyComplete()}
			children={quests}
			alert={alert}
		/>
	);
};

export default QuestStep6;
