/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Paper, FormControlLabel, Checkbox } from '@mui/material';
import { RootState } from '../../../../store';
import { useStyles } from '../styles';
import { useNavigate } from 'react-router-dom';

import ModalLoading from '../../../../components/ModalLoading';
import CustomAlert from '../../../../components/CustomAlert';
import CustomButton from '../../../../components/Button';

import { IAlert } from '../../../../interfaces/alert';
import { encrypt } from '../../../../utils/encrypt';
import { INTERNAL_MESSAGES } from '../../../../config/messageCatalog';
import { keyAPI } from '../../../../api/modules/key';
import { contactRequestAPI } from '../../../../api/modules/profile';

interface IProps {
	setValidateDatos: (data: boolean) => void;
}

const ValidateContact = ({ setValidateDatos }: IProps) => {
	const navigate = useNavigate();
	const classes = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const [accept, setAccept] = useState(false);
	const [dataUser, setDataUser] = useState({
		correo: '',
		correoAlterno: '',
		celular: '',
		calle: '',
		nInterior: '',
		nExterior: '',
		colonia: '',
		codigoPostal: '',
		delegacionMunicipio: '',
		estado: '',
		refNombre: '',
		refApellidoPaterno: '',
		refApellidoMaterno: '',
		refCelular: '',
	});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	useEffect(() => {
		getData();
	}, [])

	const getData = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				nss: user?.nss.toString() || '',
			}
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			contactRequestAPI.getDatos
				.post<any,
					any
				>('', { data: dataSendEncrypt })
				.then((res) => {
					if (res.result.code === '00') {
						setDataUser(res.result.data)
					} else {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const updateData = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				...dataUser,
				nss: user?.nss.toString() || '',
			}
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			contactRequestAPI.updateDatos
				.post<any,
					any
				>('', { data: dataSendEncrypt })
				.then((res) => {
					if (res && res.result?.code === '0000') {
						if (res.result.data.actualizacion === 'completa') {
							navigate('/');
						} else {
							setAlert({
								show: true,
								message: res.result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
								severity: 'error',
							});
						}
					} else {
						setAlert({
							show: true,
							message: res.result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<Paper>
				<div className={classes.containerUpdateData}>
					<div className={classes.labelheader}>
						<h3>Valida la siguiente información</h3>
					</div>
					<br />
					<div className={classes.divContainerCustom}>
						<p><b>Datos de contacto</b></p>
						<hr className={classes.hr} />
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Correo electrónico</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.correo}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Teléfono celular</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.celular}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Correo electrónico alterno</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.correoAlterno}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Calle</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.calle}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Número interior</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.nInterior}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Número exterior</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.nExterior}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Colonia</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.colonia}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Código postal</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.codigoPostal}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Delegación o municipio</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.delegacionMunicipio}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Estado</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.estado}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<br></br>
					<br></br>
					<div className={classes.divContainerCustom}>
						<p><b>Datos de referencia</b></p>
						<hr className={classes.hr} />
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Nombre</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.refNombre}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Apellido paterno</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.refApellidoPaterno}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Apellido materno</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.refApellidoMaterno}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<div className={classes.divContainer}>
						<div className={classes.divItem}>
							<p><b>Teléfono celular</b></p>
							<hr className={classes.hr} />
						</div>
						<div className={classes.divItem}>
							<p>{dataUser.refCelular}</p>
							<hr className={classes.hr} />
						</div>
					</div>
					<br></br>
					<br></br>
					<div className={classes.divContainerCustom}>
						<CustomAlert
							show={alert.show}
							severity={alert.severity}
							message={alert.message}
						/>
					</div>
					<div className={classes.link}>
						<FormControlLabel
							control={
								<Checkbox
									checked={accept}
									onChange={() => setAccept(!accept)}
								/>
							}
							label="Revisé mis datos y están correctos"
						/>
					</div>
					<div className={classes.buttonsAceptDatos}>
						<CustomButton
							disabled={!accept}
							variant="outlined"
							onClick={() => updateData()}
							label="Mis datos son correctos"
						/>
						<CustomButton
							variant="solid"
							onClick={() => setValidateDatos(false)}
							label="Actualizar"
						/>
					</div>
				</div>
			</Paper>
		</>
	);
};

export default ValidateContact;
