import { Divider, Box } from '@mui/material';
import CustomButton from '../../Button';

interface Props {
	dataAviable?: {
		title: string;
		image: any;
		description: string;
	};
	setOpen: (arg: boolean) => void;
	setOpenModal?: (arg: boolean) => void;
}

const AviableCreditPanel = ({ dataAviable, setOpen, setOpenModal }: Props) => {
	return (
		<>
			<Box display={'flex'} justifyContent={'center'}>
				<img
					src={dataAviable?.image}
					alt="logo"
					style={{ width: 150 }}
				/>
			</Box>
			<Divider style={{ margin: '30px 0px' }} />
			<p style={{ fontWeight: 'bold' }}>Características</p>
			{dataAviable?.description}
			<Divider style={{ margin: '30px 0px' }} />
			<Box width={250} margin={'auto'}>
				<CustomButton
					label={'Cerrar'}
					onClick={() => {
						setOpen(false);
						setOpenModal && setOpenModal(false);
					}}
					variant={'solid'}
				/>
			</Box>
		</>
	);
};

export default AviableCreditPanel;
