import styles from './styles/styles.module.css';
import { HeaderList } from "../../../../../components/HeaderList";

const CICB = () => {
	return (
		<div className={styles.margenIzquierda}>
			<HeaderList
				listSizeIcon={18}
				list={[
					'Banorte',
					'BBVA',
					'HSBC',
					'Mifel',
					'Santander',
					'Scotiabank.',
				]}
			/>
		</div>
	);
};

export default CICB;
