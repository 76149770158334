import Header from '../../../components/Header';
import { useGeneralStyles } from '../../styles/general.styles';
import { useStyles } from '../styles';
import {
	Badge,
	Box,
	Divider,
	FormControlLabel,
	Grid,
	InputAdornment,
	Radio,
	RadioGroup,
} from '@mui/material';
import CustomAlert from '../../../../../components/CustomAlert';
import HelpPanel from '../../../components/HelpPanel';
import { RoundBtn } from '../../../components/RoundBtn/roundBtn';
import ModalLoading from '../../../../../components/ModalLoading';
import ProductsMiuc from '../../../components/ProductsMiuc';
import { formatMoney2 } from '../../../../../utils/validators';
import TextInput from '../../../../../components/TextInput';
import CustomButton from '../../../../../components/Button';
import { Info } from '@mui/icons-material';
import TooltipCustom from '../../../components/TooltipCustom';
import { useComprarViviendaTable } from '../hooks/useComprarViviendaTable';
import { CREDIT_OPTIONS } from '../../../utils/creditOptions.utils';
import { useNavigate } from 'react-router-dom';
import { ModalGastosTitulacion } from '../../../components/ModalGastosTitulación/modalGastosTitulacion';
import SelectInput from '../../../../../components/SelectInput';

export const ComprarViviendaTable = () => {
	const classes = useStyles();
	const generalStyle = useGeneralStyles();
	const navigate = useNavigate();

	const {
		// loading,
		handleContinue,
		returnStep,
		setSelectProduct,
		selectProduct,
		products,
		productsTable,
		newStep,
		disableButton,
		valorVivienda,
		setValorVivienda,
		handleSetDataVivienda,
		handleCalculate,
		isSearch,
		handleClearSearch,
		radioOptionsEquipa,
		onChangeEquipa,
		radioOptionsObra,
		onChangeObra,
		valueOptionEquipa,
		productsOriginal,
		AlertInput,
		AlertInputCalc,
		Etc,
		getAlertStatus,
		rowsTitleCompra,
		conyuge,
		dataProcessController,
		productsToCalculate,
		setGastosTitulacion,
		gastosTitulacion,
		showGastosModal,
		setShowGastosModal,
		disableSelectPlazo,
		getPlazos,
		onChangePlazo,
		plazo,
		setPlazo,
		plazos,
		handleButtonCalculate,
		disableCalculateBtn,
		plazosCny,
		plazoCny,
		setPlazoCny,
		refreshProducts,
	} = useComprarViviendaTable();

	return (
		<Box>
			<ModalGastosTitulacion
				setGastos={setGastosTitulacion}
				show={showGastosModal}
				gastos={gastosTitulacion}
				setShow={setShowGastosModal}
			/>
			<Header steps={newStep}></Header>
			<Box className={classes.content}>
				<div className={classes.contentPadding}>
					{getAlertStatus().status && (
						<CustomAlert
							show={getAlertStatus().status}
							severity={'warning'}
							message={getAlertStatus().message}
						/>
					)}
					<AlertInputCalc />
				</div>
				<div className={classes.centeredContent}>
					<div className={classes.inputs}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={8}>
								<span className={classes.inputLabel}>
									Valor de la vivienda que quieres comprar
								</span>
								<TextInput
									startAdornment={
										<InputAdornment position="start">
											$
										</InputAdornment>
									}
									placeholder="0.00"
									name="cost"
									id="comprar-costo"
									value={valorVivienda}
									disabled={isSearch && conyuge}
									onChange={handleSetDataVivienda}
									onBlur={() => {
										if (valorVivienda) {
											if (valorVivienda === '.') {
												setValorVivienda('');
											} else {
												setValorVivienda(
													formatMoney2(
														Number(valorVivienda)
													).substring(1)
												);
											}
										}
									}}
									onFocus={() => {
										if (valorVivienda) {
											setValorVivienda(
												valorVivienda
													.toString()
													.replaceAll(',', '')
													.replaceAll(' ', '')
											);
										}
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={4}
								className={classes.bCalcular}
							>
								<CustomButton
									onClick={handleButtonCalculate}
									label="Calcular"
									variant="solid"
									disabled={isSearch || disableCalculateBtn}
								/>
								{isSearch && (
									<CustomButton
										onClick={handleClearSearch}
										label="Limpiar"
										variant="outlined"
									/>
								)}
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectInput
									id="plazo"
									name="plazo"
									value={plazo}
									disabled={disableSelectPlazo}
									hiddenTextSelecciona={true}
									onChange={(event) => {
										onChangePlazo(event.target.value);
										setPlazo(event.target.value);
									}}
									options={plazos}
									label="Ingresa el plazo que más te convenga"
								/>
							</Grid>
							{conyuge && (
								<Grid item xs={12} md={6}>
									<SelectInput
										id="plazoCny"
										name="plazoCny"
										value={plazoCny}
										disabled={disableSelectPlazo}
										hiddenTextSelecciona={true}
										onChange={(event) => {
											onChangePlazo(
												event.target.value,
												true
											);
											setPlazoCny(event.target.value);
										}}
										options={plazosCny}
										label="Ingresa el plazo asociado que más te convenga"
									/>
								</Grid>
							)}
							{isSearch &&
								dataProcessController.processInfo
									?.creditType !== 'I' && (
									<Grid item xs={12}>
										<p>
											Si deseas consultar nuevamente tus
											condiciones máximas, da clic en{' '}
											<b>"Regresar"</b>. En caso de que
											desees realizar otro cálculo, da
											clic en <b>"Limpiar"</b>
										</p>
									</Grid>
								)}
							<CustomAlert
								severity="warning"
								show={isSearch}
								message={
									'Con los datos ingresados estas son las condiciones a las que puedes acceder.'
								}
							/>
						</Grid>
					</div>
				</div>
				<Divider sx={{ margin: '20px 50px' }} />
				{/* !conyuge &&
					<Box className={classes.boxAportaciones}>
						<div className={classes.divAportaciones}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={8}>
							<p>
								<b>
								¿Tienes aportaciones voluntarias en tu
								Afore?{' '}
								<TooltipCustom
									arrow
									placement="bottom"
									title={
									<>
										<p>
										Las Aportaciones
										Voluntarias son
										depósitos que realizas
										en tu Cuenta Individual
										de Ahorro en tu Afore,
										que se ven reflejados en
										el estado de cuenta que
										genera tu Afore.
										<br />
										<br />
										Mientras que las
										Aportaciones
										Extraordinarias son
										aquellas que realizas
										directamente al
										Infonavit para
										incrementar el saldo de
										tu Subcuenta de
										Vivienda.
										</p>
									</>
									}
								>
									<Info
									color="primary"
									className={classes.infoStyle}
									/>
								</TooltipCustom>
								</b>
								<br />
								Puedes usarlas para incrementar tu capacidad
								de compra.
							</p>
							</Grid>
							<Grid
							item
							xs={12}
							md={4}
							className={classes.bConsultar}
							>
							{<CustomButton
								onClick={() => navigate('/aportaciones-voluntarias')}
								label="Consultar"
								variant="outlined"
								/>}
							</Grid>
						</Grid>
						</div>
					</Box>
					}
				{ !conyuge && <Divider sx={{ margin: '20px 50px' }} />*/}

				<div className={classes.contentPadding}>
					<Box sx={{ maxWidth: '100%' }}>
						{!conyuge && (
							<div className={classes.centeredContent}>
								<Grid
									container
									className={classes.gridCreditosRequeridos}
								>
									<div
										className={classes.divSeleccionCreditos}
									>
										<Grid
											item
											xs={6}
											md={12}
											className={classes.optionsGrid}
										>
											<Grid item xs={12}>
												<b>
													¿Requieres monto de crédito
													Equipa tu casa?{' '}
													<Badge
														badgeContent={
															<HelpPanel type="EQUIPA_TU_CASA" />
														}
														className={
															classes.helpPanel
														}
													></Badge>
												</b>
											</Grid>
											<Grid
												item
												className={classes.radioGrid}
											>
												<RadioGroup
													defaultValue={
														radioOptionsEquipa.No
															.value
													}
													onChange={onChangeEquipa}
													className={
														classes.radioButtons
													}
												>
													<Grid item xs={6} md={12}>
														<FormControlLabel
															value={
																radioOptionsEquipa
																	.Si.value
															}
															control={
																<Radio size="small" />
															}
															label={
																radioOptionsEquipa
																	.Si.label
															}
															labelPlacement="end"
														/>
													</Grid>
													<Grid item xs={6} md={12}>
														<FormControlLabel
															value={
																radioOptionsEquipa
																	.No.value
															}
															control={
																<Radio size="small" />
															}
															label={
																radioOptionsEquipa
																	.No.label
															}
															labelPlacement="end"
														/>
													</Grid>
												</RadioGroup>
											</Grid>
										</Grid>
									</div>
									<div
										className={classes.divSeleccionCreditos}
									>
										<Grid
											item
											xs={6}
											md={12}
											className={classes.optionsGrid}
										>
											<Grid item xs={12}>
												<b>
													¿Requieres monto de crédito
													para mano de obra?
												</b>
											</Grid>
											<Grid
												item
												className={classes.radioGrid}
											>
												<RadioGroup
													defaultValue={
														radioOptionsObra.No
															.value
													}
													onChange={onChangeObra}
													className={
														classes.radioButtons
													}
												>
													<Grid item md={12} xs={6}>
														<FormControlLabel
															value={
																radioOptionsObra
																	.Si.value
															}
															control={
																<Radio size="small" />
															}
															label={
																radioOptionsObra
																	.Si.label
															}
															labelPlacement="end"
															disabled={
																!valueOptionEquipa
															}
														/>
													</Grid>
													<Grid item md={12} xs={6}>
														<FormControlLabel
															value={
																radioOptionsObra
																	.No.value
															}
															control={
																<Radio size="small" />
															}
															label={
																radioOptionsObra
																	.No.label
															}
															labelPlacement="end"
															disabled={
																!valueOptionEquipa
															}
														/>
													</Grid>
												</RadioGroup>
											</Grid>
										</Grid>
									</div>
								</Grid>
							</div>
						)}
						<AlertInput />
						<ProductsMiuc
							rememberHiddenProducts
							refresh={refreshProducts}
							keys={rowsTitleCompra}
							products={productsTable || []}
							keyHeader={'producto'}
							selectProduct={selectProduct}
							setSelectProduct={setSelectProduct}
							textFooter="Considera que en cualquier momento de tu crédito podras adelantar pagos o realizar la liquidación de tu crédito sin tener ninguna penalización."
						/>
					</Box>
				</div>
				<Divider sx={{ margin: '20px 50px' }} />
				<div className={generalStyle.bottonsContainer}>
					<RoundBtn variant="outlined" onClick={() => returnStep()}>
						Regresar
					</RoundBtn>
					<RoundBtn
						disabled={disableButton}
						variant="contained"
						onClick={() => handleContinue()}
					>
						Continuar
					</RoundBtn>
				</div>
			</Box>
			{/* <ModalLoading loading={loading} /> */}
		</Box>
	);
};
