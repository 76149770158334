/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { Radio } from '@mui/material';
import { useStyles } from '../styles/styles';
import HeaderStep from './HeaderStep';
import TextInput from '../../../../../components/TextInput';
import { isNumber } from '../../../../../utils/expressions';


const QuestStep10 = ({
	quest,
	nextStep,
	handleChange,
	afterQuestionarySteps,
	alert,
}: any) => {
	const classes = useStyles();
	const [quests, setQuests] = useState([] as any);
	const [valor, setValor] = useState('');
	const [valor2, setValor2] = useState('');
	const [valor3, setValor3] = useState('');
	const [otherType, setOtherType] = useState('');

	useEffect(() => {
		drawQuestions();
	}, [quest, valor, valor2, valor3, otherType]);

	useEffect(() => {
		let respuesta = `7 ${otherType}`;
		handleChange(respuesta, '6.3');
	}, [otherType]);

	useEffect(() => {
		let respuesta = `${valor}`;
		handleChange(respuesta, '6.1');
	}, [valor]);

	useEffect(() => {
		let respuesta = `${valor2} personas`;
		handleChange(respuesta, '6.4');
	}, [valor2]);

	useEffect(() => {
		let respuesta = `${valor3} personas`;
		handleChange(respuesta, '6.5');
	}, [valor3]);

	const drawQuestions = () => {
		const questions: any = [];
		quest.map((item: any) => {
			const newItem = {
				title: `${item.numero.split('.')[1]}.- ${item.pregunta}`,
				body: (
					<>
						{item.opciones.length > 0 &&
							item.numero !== '6.3' &&
							item.opciones.map((opcion: any) => (
								<div
									className={classes.itemsFlexCenter}
									key={opcion + item.pregunta}
								>
									<Radio
										checked={
											item.respuesta ===
											opcion.split(')')[0]
										}
										onChange={(e: any) => {
											let value =
												e.target.value.split(')')[0];
											handleChange(value, item.numero);
										}}
										name={opcion.split(')')[0]}
										value={opcion.split(')')[0]}
									/>
									{opcion}
								</div>
							))}

						{item.opciones.length > 0 &&
							item.numero === '6.3' &&
							item.opciones.map((opcion: any, index: any) => (
								<div
									className={classes.itemsFlexCenter}
									key={opcion + item.pregunta}
								>
									<Radio
										checked={
											item.respuesta ===
											opcion.split(')')[0] ||
											(index === 6 &&
												item.respuesta ===
												'7 ' + otherType)
										}
										onChange={(e: any) => {
											let value =
												e.target.value.split(')')[0];
											if (index === 6) {
												handleChange(
													value + ' ' + otherType,
													item.numero
												);
											} else {
												handleChange(
													value,
													item.numero
												);
											}
										}}
										name={opcion.split(')')[0]}
										value={opcion.split(')')[0]}
									/>
									{opcion}
									{index === 6 && (
										<div style={{ width: '300px', marginLeft: '10px' }}>
											<TextInput
												id={'otherType'}
												name={'otherType'}
												value={otherType}
												disabled={
													item.respuesta !==
													'7 ' + otherType
												}
												onChange={(e) =>
													setOtherType(e.target.value)
												}
											/>
										</div>
									)}
								</div>
							))}

						{item.numero === '6.1' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor"
									name="valor"
									value={valor}
									placeholder="00000"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										if (e.target.value.length > 5) return;
										setValor(e.target.value);
									}}
								/>
							</div>
						)}

						{item.numero === '6.4' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor2"
									name="valor2"
									value={valor2}
									placeholder="0 personas"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										if (e.target.value.length > 5) return;
										setValor2(e.target.value);
									}}
								/>
							</div>
						)}

						{item.numero === '6.5' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor3"
									name="valor3"
									value={valor3}
									placeholder="0 personas"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										setValor3(e.target.value);
									}}
								/>
							</div>
						)}

						{item.numero === '6.7' && (
							<div style={{ maxWidth: 600 }}>
								<TextInput
									id={item.pregunta}
									name={item.pregunta}
									value={item.respuesta}
									onChange={(e) => {
										if (e.target.value.length > 280) return;
										handleChange(
											e.target.value,
											item.numero
										)
									}}
									multiline={true}
									rows={4}
									contentStyles={{ maxWidth: 600 }}
								/>
							</div>
						)}
					</>
				),
			};
			return questions.push(newItem);
		});
		setQuests(questions);
	};

	const verifyComplete = () => {
		let respuesta = quest.every((item: any) => {
			if (item.respuesta.length === 0) return false
			else return true
		})
		return respuesta
	};

	return (
		<HeaderStep
			title={'Situación residencial actual'}
			nextStep={nextStep}
			afterQuestionarySteps={afterQuestionarySteps}
			disabledContinue={!verifyComplete()}
			disabledButton={!verifyComplete()}
			children={quests}
			alert={alert}
		/>
	);
};

export default QuestStep10;
