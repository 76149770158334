import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	buttonLogInSolid: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#FFFFFF',
		borderRadius: 50,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: 'white',
		minHeight: 40,
		height: 'auto',
		width: '100%',
		fontWeight: 'bold',
		fontSize: 14,
		backgroundColor: theme.palette.primary.main,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			transition: '0.5s',
		},
		'&:active': {
			backgroundColor: theme.palette.primary.main,
			transition: '0.5s',
		},
		'&:disabled': {
			backgroundColor: '#C7C6C5',
		},
	},
	buttonLogInSolidDisabled: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#FFFFFF',
		borderRadius: 50,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: 'white',
		minHeight: 40,
		height: 'auto',
		width: '100%',
		fontWeight: 'bold',
		fontSize: 14,
		cursor: 'pointer',
		backgroundColor: 'rgb(205, 205, 205)',
	},
	table: {
		border: '1px solid rgba(138,145,156,.3)',
		padding: '20px 20px 12px',
	},
	text: {
		color: '#7F7F7F',
		fontSize: '14px !important',
		margin: 0,
	},
	textName: {
		paddingRight: '10px',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	},
}));
