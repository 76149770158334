import { useEffect, useState } from "react";
import { IFormat, formatValues, rowsTitleSeguro } from "../../../utils/selectionResume.utils";
import { useController } from "../../../hooks/useController";
import { IAlert } from "../../../../../interfaces/alert";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import { useProductTableMIUC } from "../../../hooks/useProductTableMIUC";
import { formatDate } from "../../../../../utils/dates";
import { encodeAllJSONData } from "../../../../../utils/encrypt";
import { creditRequestAPI } from "../../../../../api/modules/CreditRequest";
import { apiMIUC } from "../../../../../api/modules/MIUC";
import { isEmpty } from "../../../../../utils/validators";
import { DESTINOS } from "../../../../../config/miucConstants";
import { useCats } from "../../../hooks/useCats";
import { IProductMonto } from "../../../../../interfaces/creditRequest";
import { useAlertCustom } from "../../../hooks/useAlertCustom";
import { useTrazabilidad } from "../../../hooks/useTrazabilidad";

interface IProps {
	setProduct: (pruduct: any) => void;
}

export const useSecureCreditTable = () => {

  const { user } = useSelector((state: RootState) => state.session);
  const { dataProcessController } = useSelector((state: RootState) => state.miuc);

  const { returnStep, addSelection, nextStep, saveData, getProgressStep } = useController();
	const [loading, setLoading] = useState(false);
  const [selectProduct, setSelectProduct] = useState('CS');
	const [products, setProducts] = useState<any[]>([]);
	const disableButton = isEmpty(selectProduct)
	const { active, total } = getProgressStep();
	const newStep = {
		title: 'Estas son las opciones de financiamiento que tenemos para ti',
		subtitle:
			'Revisa cada opción, compara entre ellas y elige la que más te convenga',
		active,
		total,
	};

  const { getAllCats } = useCats();
	const { getDataProductAmount } = useProductTableMIUC();
  const { AlertInput, setAlertCustom, setAlertClean, getAlertStatus } = useAlertCustom()
  const { createTrazabilidad } = useTrazabilidad();

	const next = async() => {
		//console.log(products)
    const product = products.find( prod => prod.producto === selectProduct)
		
		handleTrazabilidad();
    await saveData({
      processInfo: {
        productSelect: product,
      }
    })
		addSelection({
			title: 'Uso de:',
			description: 'Crédito Seguro'
		})
		nextStep();
	};

	const handleTrazabilidad = async () => {
		const selectedProduct = products.find( prod => prod.producto === selectProduct);
		createTrazabilidad({
			credit: dataProcessController.processInfo?.credit,
			subtipoTrazabilidad: selectedProduct?.subtipoTrazabilidad
		});
	};

  const getProducts = async () => {
		try {
			setLoading(true);
			setAlertClean();
			const data = getDataProductAmount({
				valorTerreno: '',
				plazoPart1: '',
			});
			const ENCODED_DATA = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const response = await apiMIUC.consultarProductosMontos(
				ENCODED_DATA
			);
			if(Number(response.code || '0') !== 0) {
				setAlertCustom({
					show: true,
					message:
						response.message ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
					severity: 'error',
				});
				return;
			}
			const products = response.data.productos;
			const productos = products.map((product: any) => {
				return {
				...product,
							ahorro: dataProcessController.titular.saldoSARTotal
						}
			})
      		const productsCatsAll: IProductMonto[] = await getAllCats(productos);
			setProducts(productsCatsAll);
		} catch (error: any) {
			setAlertCustom({
				show: true,
				message:
					error.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getProducts();
	}, []);

  return {
    loading,
    next,
    returnStep,
    setSelectProduct,
    selectProduct,
    products,
    newStep,
		disableButton,
    AlertInput,
    getAlertStatus,
  }
}