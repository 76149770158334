import { Dialog, Box, useMediaQuery } from '@mui/material';
import CustomButton from '../../../components/Button';
import theme from '../../../config/theme';

const ModalConfirmUrl = ({ url, setUrl }: any) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const handleClick = (arg: boolean) => {
		if (arg) {
			window.open(url, '_blank');
		}
		setUrl('');
	};

	return (
		<Dialog onClose={() => setUrl('')} open={url}>
			<Box padding={3} textAlign={'center'}>
				<p>
					A partir de este momento estás saliendo de la página de Mi
					Cuenta Infonavit, por lo que ya no tiene responsabilidad
					alguna referente a la información de la página que vas a
					consultar.
				</p>
				<p style={{ fontWeight: 500 }}>¿Deseas continuar?</p>

				<Box
					display={'flex'}
					flexDirection={matches ? 'column-reverse' : 'row'}
					justifyContent={'center'}
				>
					<Box width={matches ? 'auto' : 200} margin="10px">
						<CustomButton
							label={'Cancelar'}
							variant={'outlined'}
							onClick={() => handleClick(false)}
						/>
					</Box>
					<Box width={matches ? 'auto' : 200} margin="10px">
						<CustomButton
							label={'Continuar'}
							variant={'solid'}
							onClick={() => handleClick(true)}
						/>
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
};

export default ModalConfirmUrl;