export const additionInfoForm = (
	entities = [],
	secureOpt = [],
	usedOpt = []
) => [
	{
		id: 'follow-empDesarrolladora',
		name: 'empDesarrolladora',
		label: 'Nombre de la empresa desarrolladora',
	},
	{
		id: 'follow-entidad',
		name: 'entidad',
		label: 'Delegación',
		options: entities,
	},
	{
		id: 'follow-cobertura',
		name: 'cobertura',
		label: 'Si el crédito cuenta con cobertura o seguro de calidad',
		options: secureOpt,
	},
	{
		id: 'follow-utilizado',
		name: 'utilizado',
		label: 'Si esta ya ha sido utilizada',
		options: usedOpt,
	},
];
