import { useState } from 'react';
import { IAlert } from '../../../interfaces/alert';
import { AlertColor } from '@mui/material';
import CustomAlert from '../../../components/CustomAlert';
import { MIUC_PRODUCTS } from '../constants/products';
import HelpPanel from '../components/HelpPanel';
import { useController } from './useController';

interface IAlertProps {
	show?: boolean;
	message?: string;
	severity?: AlertColor;
}

export const useAlertCustom = () => {
  const { dataProcessController } = useController();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});

	const setAlertCustom = ({ show, message, severity }: IAlertProps) => {
		setAlert({
			show: message ? true : show ?? false,
			message: message ?? '',
			severity: severity ?? 'error',
		});
	};

	const setAlertClean = () => {
		setAlert({
			show: false,
			message: '',
			severity: 'error',
		});
	};

	const AlertInput = () => (
		<CustomAlert
			message={alert.message}
			severity={alert.severity}
			show={alert.show}
		/>
	);

  const getAlertStatus = (): {
    status: boolean;
    message: string | React.ReactChild;
  } => {
    const buro = Number(dataProcessController?.titular?.stageId) || '4';
    if (buro.toString() === '4') {
        if (dataProcessController?.processInfo.creditType === 'I') {
            return {
                status: true,
                message:
                    'Considera que al realizar la consulta a tu historial crediticio, podrías obtener un monto mayor de crédito.',
            };
        } else {
            return {
                status: true,
                message: (
                    <>
                        Considera que al realizar la consulta al historial
                        crediticio de tu asociado, podrían obtener un monto
                        mayor de crédito.{' '}
                        <HelpPanel type="AUTORIZACION_BC_CONYUGE" />
                    </>
                ),
            };
        }
    }
    return {
        status: false,
        message: '',
    };
};

	return {
		...alert,
		setAlertCustom,
		setAlertClean,
		AlertInput,
    getAlertStatus,
	};
};
