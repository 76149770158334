import { HeaderList } from "../../../../../components/HeaderList";

const InfonavitSupport = () => {
	return (
		<>
			<HeaderList
				listSizeIcon={18}
				list={[
          <>ABC capital</>,
          <>Afirme</>,
          <>Banamex</>,
          <>Banco Azteca</>,
					<>Banco del Bajío</>,
          <>Banco Inbursa</>,
					<>Banco Inmobiliario Mexicano</>,
					<>Banco ve por más</>,
					<>Banorte</>,
					<>Banregio</>,
					<>BBVA Bancomer</>,
					// <>Citibanamex</>,
					<>HSBC</>,
					// <>Inbursa</>,
					// <>ION Financiera</>,
					// <>Metrofinanciera</>,
					// <>Patrimonio</>,
          <>Banca Mifel</>,
					// <>Scotiabank Inverlat</>,
          <>Scotiabank</>,
					<>Santander</>,
				]}
			/>
		</>
	);
};

export default InfonavitSupport;
