import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { linearProgressClasses } from '@mui/material/LinearProgress';

export const useStyles = makeStyles((theme: Theme) => ({
	header: {
		position: 'relative',
		width: '100%',
		minHeight: '100px',
		maxHeight: '100px',
		boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
		padding: '5px 50px 5px 20px',
		display: 'flex',
		alignItems: 'center',
	},
	textCenter: {
		textAlign: 'center',
		margin: '0px 0px 10px',
		fontSize: '14px',
	},
	titleBlue: {
		color: '#293990',
		textAlign: 'center',
		margin: '10px 0'
	},
	text: {
		margin: '5px 0'
	},
	progress: {
		position: 'absolute',
		padding: '0 10px',
		width: '100%',
		left: 0,
		bottom: '-5px',
	},
	total: {
		position: 'absolute',
		right: 20,
		bottom: 5,
		fontSize: '14px',
	},
	containerFull: {
		width: '100%',
	},
	linearProgress: {
		height: '10px !important',
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
		},
	}
}));
