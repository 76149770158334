import { Divider, useMediaQuery } from '@mui/material';
import Modal from '../../../components/Modal';
import theme from '../../../config/theme';
import CustomButton from '../../../components/Button';
import { useNavigate } from 'react-router-dom';

interface IProps {
	show: boolean;
}

const ModalMessageTemp = ({ show }: IProps) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();

	return (
		<Modal
			hideCloseButton
			open={show}
			width={'md'}
			children={
				<div style={{ textAlign: 'center', fontSize: 15 }}>
					<p style={{ fontWeight: 500, margin: '0px 0px 10px 0px' }}>
						Importante
					</p>

					<p
						style={{
							margin: 0,
							marginBottom: 40,
							textAlign: 'left',
						}}
					>
						Te Informamos que servicio de devolución de pagos en
						exceso estará suspendido del 14 al 26 de noviembre 2023,
						debido a la actualización de sistemas aplicativos,
						agradecemos su comprensión por la afectación generada y
						te invitamos a realizar el tramite a partir del próximo
						lunes 27 de noviembre 2023.
					</p>

					<Divider style={{ margin: '20px 0px' }} />

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: matches
								? 'column-reverse'
								: 'inherit',
						}}
					>
						<div
							style={{
								width: matches ? '100%' : 180,
								display: matches ? 'flex' : 'inline-flex',
								margin: '8px 10px',
							}}
						>
							<CustomButton
								label="Salir"
								variant="solid"
								onClick={() => navigate('/')}
							/>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default ModalMessageTemp;
