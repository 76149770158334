import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
// import { margin } from '@mui/system';

export const useStyles = makeStyles((Theme: Theme) =>
	createStyles({
		card: {
			boxShadow: '0px 3px 6px #00000029 !important',
			paddingBottom: '20px'
		},
		cardPerfilCero: {
			position: 'relative',
			overflow: 'hidden',
			width: '100%',
			maxWidth: '800px',
			margin: '0 auto',
			height: '100%',
		},
		titleCard: {
			textAlign: 'center',
			paddingTop: '30px',
			paddingBottom: '10px',
			marginLeft: '5%',
			marginRight: '5%',
			'&>h2': {
				margin: 0,
				color: Theme.palette.info.dark,
			},
			'&>h3': {
				margin: 0,
				color: Theme.palette.info.dark,
			},
		},
		textCard: {
			marginLeft: '5%',
			marginRight: '5%',
			fontSize: '12px',
		},
		textCardPerfilCero: {
			width: '100%',
			maxWidth: '620px',
			margin: '10px auto 40px',
			fontSize: '16px',
			padding: '0 20px',
			'&>p': {
				margin: '16px 0',
			},
		},
		fontbold: {
			fontWeight: 'bold',
		},
		containerCardLine: {
			width: '100%',
			height: 50,
			marginTop: 10,
			borderRadius: 5,
			borderWidth: 1,
			borderColor: '#CBCBCB',
			borderStyle: 'solid',
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
			paddingInline: 15,
			position: 'relative',
			'&:hover': {
				borderColor: 'red',
				transition: 'border-color 0.3s ease',
			},
		},
		iconCompra: {
			width: 32,
		},
		labelBtnLine: {
			fontWeight: '500',
			fontSize: 15,
			marginLeft: 10,
			marginTop: 3,
			textAlign: 'left'
		},
		paperImageStyle: {
			width: '100%',
			height: '100%',
			overflow: 'hidden',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		lineText: {
			display: 'flex',
			justifyContent: 'start',
			flexDirection: 'row',
			height: 'auto',
			marginBottom: '1%',

			[Theme.breakpoints.down('sm')]: {
				marginBottom: '1%',
			},
		},
		containerCardHouse: {
			width: '100%',
			height: '100%',
			borderRadius: 5,
			borderColor: '#CBCBCB',
			borderStyle: 'solid',
			position: 'relative',
		},
		mainContainerCard: {
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'center',
			boxShadow: '0px 3px 6px #00000029 !important',
		},
		imageStyle: {
			width: '100%',
			height: '100%',
			borderRadius: '5px',
			boxShadow: '0px 3px 6px #00000029 !important',
			cursor: 'pointer',
		},
		containerCardMoney: {
			width: '100%',
			marginTop: 15,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		iconPesos: {
			width: 55,
			marginRight: 10,
		},
		labelSSV: {
			fontWeight: '500',
			fontSize: 32,
			color: Theme.palette.info.main,
			[Theme.breakpoints.down('sm')]: {
				fontSize: 26,
			},
		},
		titleCardMoney: {
			fontWeight: 'bold',
			fontSize: 14,
		},
		mainContainerCardMoney: {
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'center',
			padding: '10px 0',
			boxShadow: '0px 3px 6px #00000029 !important',
		},
		titleCardCredit: {
			fontWeight: '500',
			fontSize: 22,
		},
		labelRestrictions: {
			fontSize: 12,
			marginTop: 30,
			marginBottom: 10,
			color: Theme.palette.info.dark,
			'& span': {
				color: Theme.palette.primary.main,
			},
		},
		containerPaperList: {
			marginTop: '10px',
			marginLeft: '5%',
			marginRight: '5%',
		},
		textCardPaperList: {
			textAlign: 'center',
			fontSize: '12px',
			fontWeight: 'bold',
		},
		textCreditInfonaBanck: {
			marginLeft: '3%',
			fontSize: '16px',
			fontWeight: 'bold',
			color: Theme.palette.info.dark,
		},
		paperHeight: {
			height: '91.7%',
			boxShadow: '0px 3px 6px #00000029 !important',
			[Theme.breakpoints.down('md')]: {
				height: 'auto',
			},
		},
		iconPerfilCero: {
			width: '100%',
			maxWidth: '240px',
			margin: '0 auto',
			display: 'block'
		}
	})
);
