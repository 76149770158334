import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import { HeaderList } from '../../components/HeaderList';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import { useStyles } from './styles';
import { formatDate } from '../../interfaces/SaldAndMovement';
import { useNavigate } from 'react-router-dom';

interface Props {
	setPage: (data: number) => void;
	dataCaso: {
		noCaso: string;
		estatus: string;
		fecha: string;
		fechaEstatus: string;
		descripcion: string;
		tipificacion: string;
		estatusDetalle: {
			descripcion: string;
			estatus: string
		},
		casoCerradoDetalle: {
			razon: string;
			id: string;
			descripcion: string;
		};
	};
}

const InfoCase = ({ setPage, dataCaso }: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Box>
			<HeaderList title="Aclaración de pagos" />
			<Paper sx={{ pt: 5, pb: 5, pr: matches ? 5 : 15, pl: matches ? 5 : 15 }}>
				<h2 style={{ textAlign: 'center', paddingBottom: 20 }}>
					Información del caso
				</h2>
				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Número de caso
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={9}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'left' }}
					>
						<label>{dataCaso.noCaso}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />
				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Fecha de solicitud
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={9}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'left' }}
					>
						<label>{formatDate(dataCaso?.fecha, '/')}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Servicio
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={9}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'left' }}
					>
						<label>{dataCaso.descripcion}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Estatus
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={9}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'left' }}
					>
						{dataCaso.estatusDetalle.estatus}
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Fecha de estatus
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={9}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'left' }}
					>
						<label>{formatDate(dataCaso?.fechaEstatus, '/')}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

				<div
					style={{
						maxWidth: 800,
						margin: 'auto',
						marginTop: 30,
					}}
				>
					<div className={classes.message}>
						<span style={{ display: 'block', marginBottom: '-10px' }}>
							{dataCaso.estatusDetalle.estatus === 'Cerrado' ? dataCaso.casoCerradoDetalle.descripcion : dataCaso.estatusDetalle.descripcion}
						</span>
						<br />
						<span>
							¿Quieres hacer una aclaración de otros pagos?
						</span>
					</div>
					<div
						style={{
							width: matches ? '100%' : '600px',
							margin: '0px auto',
							display: matches ? 'block' : 'flex',
							justifyContent: 'space-between',
						}}
					>
						<CustomButton
							label="Solicitar aclaración"
							onClick={() => setPage(0)}
							variant="outlined"
							styles={{
								width: 260,
								height: 40,
								margin: '0px auto',
							}}
						/>
						<CustomButton
							label="Finalizar"
							onClick={() => navigate('/')}
							variant="solid"
							styles={{
								width: 260,
								height: 40,
								margin: '0px auto',
							}}
						/>
					</div>
				</div>
			</Paper>
		</Box>
	);
};

export default InfoCase;
