import { useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { IDisableProduct, IInputData, ILoadProductData, IPlazos, IProductsAmount, PRODUCT_KEYS } from "../interfaces/montoPlazoFlexible";
import { useController } from "./useController";
import { FormatMoney } from "../utils/tableFormat.utils";

export const useMontoPlazoFlexible = () => {
    const [disableInputMonto, setDisableInputMonto] = useState<boolean>();
    const [maxProductAmounts, setMaxProductAmounts] = useState<IProductsAmount[]>([]);
    const [maxPlazos, setMaxPlazos] = useState<IPlazos>({
        plazo: '',
        plazoCny: ''
    });
    const [disablePlazos, setDisablePlazos] = useState<IDisableProduct[]>([]);
    const [disableSelectPlazo, setSelectDisablePlazo] = useState<boolean>(false);
    const [inputErrorMessages, setInputErrorMessages] = useState<{
        [key: string]: string,
    }>({});
    const [disableCalculateBtn, setDisableCalculateBtn] = useState<boolean>(false);
    const {dataProcessController} = useController();

    useEffect(() => {
        setSelectDisablePlazo(disablePlazos.some(product => product.disable));
    }, [disablePlazos])

    useEffect(() => {
        const keys = Object.keys(inputErrorMessages);
        let disable = false;
        for(let key of keys){
            if(inputErrorMessages[key] !== ''){
                disable = true;
                break;
            }
        }
        setDisableCalculateBtn(disable);
    }, [inputErrorMessages])

    const loadProductData = (data: ILoadProductData) => {
        setMaxPlazos(data.plazos);
        setMaxProductAmounts(data.products);
        setDisablePlazos(data.products.map(p => ({
            product: p.producto,
            disable: false
        })));
        setDisableInputMonto(false);
    }

    const onChangePlazo = (newPlazo: string, isConyuge: boolean = false) => {
        const key = isConyuge ? 'plazoCny' : 'plazo';

        const isDiferent = newPlazo !== maxPlazos[key];
        setDisableInputMonto(isDiferent);
    }

    const onChageMonto = (product: string, amount: string, key: PRODUCT_KEYS) => {
        const newDisablePlazos = disablePlazos.map(p => {
            if(p.product !== product) return p;
            
            const foundProduct = maxProductAmounts.find(p => p.producto === product);
            if(!foundProduct) return p;

            if(foundProduct[key] !== amount) return {
                ...p,
                disable: true
            }

            return {
                ...p,
                disable: false
            }
        });
        setDisablePlazos(newDisablePlazos);
    }

    const validateMonto = (monto: string, product: string, key: PRODUCT_KEYS) => {
        if(isNaN(Number(monto))) return {
            errorMessge: '',
            isValid: false,
        };

        const minimumValue = Number(dataProcessController?.titular?.valorUMA || 0) * 3;
        if(Number(monto) < minimumValue && key !== PRODUCT_KEYS.montoConstruccion) return {
            errorMessge: `El monto mínimo valido es 3 UMAs`,
            isValid: true,
        }

        let isValid = false;
        let errorMessage = '';
        setMaxProductAmounts((maxProds) => {
            const montoProducto = maxProds.find(p => p.producto === product);
            if(montoProducto && key === PRODUCT_KEYS.montoConstruccion){
                const min65 = Number(montoProducto[key]) * .65;
                isValid = true;
                errorMessage = Number(monto) >= min65
                    ? ''
                    : 'Monto mínimo debe ser 65% del monto total de crédito'
            }else if(montoProducto && (Number(monto) <= Number(montoProducto[key]))) isValid = true;

            return maxProds;
        })

        return {
            errorMessge: errorMessage,
            isValid: isValid,
        }
    }

    const getInputMonto = (inputData: IInputData): React.ReactElement => {
        const errorKey = `${inputData.key}-${inputData.product}`;
        return <div style={{ margin: '0px 10px' }}>
            <TextField
                id={inputData.key}
                name={inputData.key}
                value={inputData.value}
                disabled={disableInputMonto}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            $
                        </InputAdornment>
                    )
                }}
                onChange={(e) => {
                    const validationResult = validateMonto(e.target.value, inputData.product, inputData.key)
                    if(validationResult.isValid){
                        onChageMonto(inputData.product, e.target.value, inputData.key);
                        inputData.onChange(e.target.value);
                    }
                    setInputErrorMessages({
                        ...inputErrorMessages,
                        [errorKey]: validationResult.errorMessge
                    });
                }}
                helperText={inputErrorMessages[errorKey] || ''}
                error={!!inputErrorMessages[errorKey]}
                size="small"
            />
        </div>
    }

    const getPlazos = (plazoMaximo: number) => {
        const plazos = [];
		for (let index = 1; index <= plazoMaximo; index++) {
            const anio = index === 1 ? 'año' : 'años';
			plazos.push({
				value: index.toString(),
				text: `${index.toString()} ${anio}`,
			});
		}
		return plazos;
    }

    const disableAll = () => {
        setDisableInputMonto(true);
        setSelectDisablePlazo(true);
    }

    const enableAll = () => {
        setDisableInputMonto(false);
        setDisablePlazos(disablePlazos.map(p => ({
            ...p,
            disable: false
        })));
    }

    return {
        loadProductData,
        getInputMonto,
        onChangePlazo,
        disableSelectPlazo,
        getPlazos,
        disableInputMonto,
        disableAll,
        enableAll,
        disableCalculateBtn
    }
}