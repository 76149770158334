/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { Radio } from '@mui/material';
import { useStyles } from '../styles/styles';
import HeaderStep from './HeaderStep';
import TextInput from '../../../../../components/TextInput';
import { isNumber } from '../../../../../utils/expressions';

const QuestStep5 = ({
	quest,
	nextStep,
	handleChange,
	afterQuestionarySteps,
	alert,
}: any) => {
	const classes = useStyles();
	const [quests, setQuests] = useState([] as any);
	const [paymentForm, setPaymentForm] = useState([
		{
			id: 1,
			text: '1) Por comisión',
			value: '',
		},
		{
			id: 2,
			text: '2) A destajo (por pieza), servicio u obra realizada',
			value: '',
		},
		{
			id: 3,
			text: '3) Por honorarios',
			value: '',
		},
		{
			id: 4,
			text: '4) Con propinas',
			value: '',
		},
		{
			id: 5,
			text: '5) Con bonos de compensación o de productividad',
			value: '',
		},
		{
			id: 6,
			text: '6) Con vales o productos comercializables',
			value: '',
		},
		{
			id: 7,
			text: '7) Solo recibo sueldo, salario o jornal',
			value: '',
		},
		{
			id: 8,
			text: '8) Solo lo que deja tu negocio',
			value: '',
		},
		{
			id: 9,
			text: '9) No te pagan ni recibe ingresos',
			value: '',
		},
	]);
	const [paymentType, setPaymentType] = useState([
		{
			id: 1,
			text: '1) Cada semana',
			value: '',
		},
		{
			id: 2,
			text: '2) Cada 15 días',
			value: '',
		},
		{
			id: 3,
			text: '3) Cada mes',
			value: '',
		},
		{
			id: 4,
			text: '4) Otro periodo de pago:',
			value: '',
		},
		{
			id: 5,
			text: '5) ¿Te pagan por pieza producida o vendida, servicio u obra realizada?',
			value: '',
		},
	]);
	const [otherType, setOtherType] = useState('');
	const [totalPayment, setTotalPayment] = useState('');
	const [totalCuentas, setTotalCuentas] = useState('');
	const [dataCuentas, setDataCuentas] = useState([] as any);
	const [articles, setArticles] = useState([
		{
			id: 1,
			text: '1) Casa propia',
			value: '',
		},
		{
			id: 2,
			text: '2) Terreno',
			value: '',
		},
		{
			id: 3,
			text: '3) Automóvil o camioneta',
			value: '',
		},
		{
			id: 4,
			text: '4) Inversión bancaria o bursatil',
			value: '',
		},
		{
			id: 5,
			text: '5) Electrodoméstico',
			value: '',
		},
		{
			id: 6,
			text: '6) Joyas, obras de arte',
			value: '',
		},
		{
			id: 7,
			text: '7) Otro:',
			value: '',
		},
	]);
	const [otherArticle, setOtherArticle] = useState('');
	const [valor, setValor] = useState('');

	useEffect(() => {
		drawQuestions();
	}, [
		quest,
		paymentForm,
		paymentType,
		otherType,
		totalPayment,
		totalCuentas,
		dataCuentas,
		articles,
		otherArticle,
		valor,
	]);

	useEffect(() => {
		let respuesta = `1) ${paymentForm[0].value === '1' ? '1' : '2'}; 2) ${paymentForm[1].value === '1' ? '1' : '2'}; 3) ${paymentForm[2].value === '1' ? '1' : '2'}; 4) ${paymentForm[3].value === '1' ? '1' : '2'}; 5) ${paymentForm[4].value === '1' ? '1' : '2'}; 6) ${paymentForm[5].value === '1' ? '1' : '2'}; 7) ${paymentForm[6].value === '1' ? '1' : '2'}; 8) ${paymentForm[7].value === '1' ? '1' : '2'}; 9) ${paymentForm[8].value === '1' ? '1' : '2'};`;
		handleChange(respuesta, '3.1');
	}, [paymentForm]);

	useEffect(() => {
		let respuesta = `1) ${paymentType[0].value === '1' ? '1' : '2'}; 2) ${paymentType[1].value === '1' ? '1' : '2'}; 3) ${paymentType[2].value === '1' ? '1' : '2'}; 4)${otherType} ${paymentType[3].value === '1' ? '1' : '2'}; 5) ${paymentType[4].value === '1' ? '1' : '2'};`;
		handleChange(respuesta, '3.2');
	}, [paymentType, otherType]);

	useEffect(() => {
		handleChange(totalCuentas, '3.6');
	}, [totalCuentas]);

	useEffect(() => {
		let respuesta = `1) ${paymentType[0].value === '1' ? '$' + totalPayment : '0'}; 2) ${paymentType[1].value === '1' ? '$' + totalPayment : '0'}; 3) ${paymentType[2].value === '1' ? '$' + totalPayment : '0'}; 4) ${otherType && paymentType[3].value === '1' ? otherType + ' ' : ''}${paymentType[3].value === '1' ? '$' + totalPayment : '0'}; 5) ${paymentType[4].value === '1' ? '$' + totalPayment : '0'};`;
		handleChange(respuesta, '3.3');
	}, [totalPayment]);

	useEffect(() => {
		let respuesta = '';
		if (dataCuentas.length > 0) {
			for (let idx = 0; idx < dataCuentas.length; idx++) {
				let item = `${idx + 1}) ${dataCuentas[idx].text} ${dataCuentas[idx].years ? dataCuentas[idx].years : '0'
					} años ${dataCuentas[idx].months ? dataCuentas[idx].months : '0'} meses ;`;
				respuesta = respuesta + item;
			}
		} else {
			respuesta = ''
		}

		handleChange(respuesta, '3.7');
	}, [dataCuentas]);

	useEffect(() => {
		let respuesta = `1) ${articles[0].value === '1' ? '1' : '2'}; 2) ${articles[1].value === '1' ? '1' : '2'}; 3) ${articles[2].value === '1' ? '1' : '2'}; 4) ${articles[3].value === '1' ? '1' : '2'}; 5) ${articles[4].value === '1' ? '1' : '2'}; 6) ${articles[5].value === '1' ? '1' : '2'}; 7) ${otherArticle && articles[6].value === '1' ? otherArticle + ' ' : ''}${articles[6].value === '1' ? '1' : '2'};`;
		handleChange(respuesta, '3.9');
	}, [articles, otherArticle]);

	useEffect(() => {
		let respuesta = '';
		parseInt(valor) > 0 && valor.length > 0 ? respuesta = `$${valor}` : respuesta = ''
		handleChange(respuesta, '3.11');
	}, [valor]);

	const updatevalueQuestion = (newValue: any, index: any, type: any) => {
		let data =
			type === 'form'
				? paymentForm
				: type === 'type'
					? paymentType
					: articles;
		const newQuestion = data.map((item: any) => {
			if (item.id === index + 1) {
				return {
					...item,
					value: newValue,
				};
			}
			return item;
		});
		if (type === 'form') {
			setPaymentForm(newQuestion);
		} else if (type === 'type') {
			if (index === 3 && newValue === '2') {
				setOtherType('');
			}
			setPaymentType(newQuestion);
		} else {
			if (index === 3 && newValue === '2') {
				setOtherArticle('');
			}
			setArticles(newQuestion);
		}
	};

	const setearCuentas = (cant: any) => {
		let cuentas = [];
		let quantity = parseInt(cant) + 1;
		for (let i = 1; i < quantity; i++) {
			let item = {
				id: i,
				text: 'Cuenta ' + i + ' bancaria',
				years: '',
				months: '',
			};
			cuentas.push(item);
		}
		setDataCuentas(cuentas);
	};

	const updateCuentas = (value: any, type: any, index: any) => {
		const newCuenta = dataCuentas.map((item: any) => {
			if (item.id === index + 1) {
				return {
					...item,
					[type]: value,
				};
			}
			return item;
		});
		setDataCuentas(newCuenta);
	};

	const drawQuestions = () => {
		const questions: any = [];
		quest.map((item: any) => {
			const newItem = {
				title: `${item.numero.split('.')[1]}.- ${item.pregunta}`,
				body: (
					<>
						{item.opciones.length > 0 &&
							item.numero !== '3.1' &&
							item.numero !== '3.2' &&
							item.numero !== '3.3' &&
							item.numero !== '3.9' &&
							item.opciones.map((opcion: any) => (
								<div
									className={classes.itemsFlexCenter}
									key={opcion + item.pregunta}
								>
									<Radio
										checked={
											item.respuesta ===
											opcion.split(')')[0]
										}
										onChange={(e: any) => {
											let value =
												e.target.value.split(')')[0];
											handleChange(value, item.numero);
										}}
										name={opcion.split(')')[0]}
										value={opcion.split(')')[0]}
									/>
									{opcion}
								</div>
							))}
						{item.numero === '3.1' &&
							paymentForm.map((opcion: any, index: any) => (
								<div
									className={classes.itemsQuest9}
									key={index + item.pregunta}
								>
									<div style={{ flexGrow: 1 }}>
										{opcion.text}
									</div>
									<div className={classes.itemsFlexInput}>
										<div
											className={classes.itemsFlexCenter}
										>
											<Radio
												checked={opcion.value === '1'}
												onChange={(e: any) => {
													let value = e.target.value;
													updatevalueQuestion(
														value,
														index,
														'form'
													);
												}}
												name={'noWorking'}
												value={'1'}
											/>
											Sí
										</div>
										<div
											className={classes.itemsFlexCenter}
										>
											<Radio
												checked={opcion.value === '2'}
												onChange={(e: any) => {
													let value = e.target.value;
													updatevalueQuestion(
														value,
														index,
														'form'
													);
												}}
												name={'noWorking'}
												value={'2'}
											/>
											No
										</div>
									</div>
								</div>
							))}
						{item.numero === '3.2' &&
							paymentType.map((opcion: any, index: any) => (
								<div
									className={classes.itemsQuest9}
									key={index + item.pregunta}
								>
									<div style={{ flexGrow: 1 }}>
										{opcion.text}
									</div>
									{index === 3 && (
										<div>
											<TextInput
												id={'otherType'}
												name={'otherType'}
												value={otherType}
												placeholder="¿Cuál?"
												disabled={
													paymentType[3].value ===
													'' ||
													paymentType[3].value === '2'
												}
												onChange={(e) =>
													setOtherType(e.target.value)
												}
											/>
										</div>
									)}
									<div className={classes.itemsFlexInput}>
										<div
											className={classes.itemsFlexCenter}
										>
											<Radio
												checked={opcion.value === '1'}
												onChange={(e: any) => {
													let value = e.target.value;
													updatevalueQuestion(
														value,
														index,
														'type'
													);
												}}
												name={'noWorking'}
												value={'1'}
											/>
											Sí
										</div>
										<div
											className={classes.itemsFlexCenter}
										>
											<Radio
												checked={opcion.value === '2'}
												onChange={(e: any) => {
													let value = e.target.value;
													updatevalueQuestion(
														value,
														index,
														'type'
													);
												}}
												name={'noWorking'}
												value={'2'}
											/>
											No
										</div>
									</div>
								</div>
							))}
						{item.numero === '3.3' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="totalPayment"
									name="totalPayment"
									value={totalPayment}
									placeholder="$0.00"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										if (e.target.value === '0') return;
										setTotalPayment(e.target.value);
									}}
								/>
							</div>
						)}
						{item.numero === '3.6' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="totalCuentas"
									name="totalCuentas"
									value={totalCuentas}
									placeholder="0"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										setTotalCuentas(e.target.value);
									}}
									onBlur={() => {
										if (totalCuentas) {
											setearCuentas(totalCuentas);
										} else {
											setearCuentas([]);
										}
									}}
								/>
							</div>
						)}
						{item.numero === '3.7' &&
							dataCuentas.length > 0 &&
							dataCuentas.map((opcion: any, index: any) => (
								<div
									className={classes.itemsQuest9}
									key={index + item.pregunta}
								>
									<div style={{ flexGrow: 1 }}>
										{opcion.text}
									</div>
									<div className={classes.itemsFlexInput}>
										<div
											style={{
												textAlign: 'left',
												marginRight: '20px',
											}}
										>
											<div>Año</div>
											<TextInput
												id={
													'question-year-' +
													item.numero
												}
												name={'year'}
												placeholder="Años"
												onChange={(e: any) => {
													if (
														!isNumber(
															e.target.value
														)
													)
														return;
													if (
														e.target.value.length >
														4
													)
														return;
													updateCuentas(
														e.target.value,
														'years',
														index
													);
												}}
												contentStyles={{
													maxWidth: 300,
												}}
												value={opcion.years}
											/>
										</div>
										<div
											style={{
												textAlign: 'left',
												marginRight: '20px',
											}}
										>
											<div>Mes</div>
											<TextInput
												id={
													'question-month-' +
													item.numero
												}
												name={'month'}
												placeholder="Meses"
												onChange={(e: any) => {
													if (
														!isNumber(
															e.target.value
														)
													)
														return;
													if (
														e.target.value.length >
														2
													)
														return;
													updateCuentas(
														e.target.value,
														'months',
														index
													);
												}}
												contentStyles={{
													maxWidth: 100,
												}}
												value={opcion.months}
											/>
										</div>
									</div>
								</div>
							))}
						{item.numero === '3.7' && dataCuentas.length === 0 && (
							<div
								className={classes.itemsQuest9}
								key={0 + item.pregunta}
								style={{ border: 'none', color: 'gray' }}
							>
								<div style={{ flexGrow: 1 }}>
									Cuenta bancaria
								</div>
								<div className={classes.itemsFlexInput}>
									<div
										style={{
											textAlign: 'left',
											marginRight: '20px',
										}}
									>
										<div>Año</div>
										<TextInput
											id={'question-year-' + item.numero}
											name={'year'}
											placeholder="Años"
											contentStyles={{ maxWidth: 300 }}
											value={''}
											disabled={true}
										/>
									</div>
									<div
										style={{
											textAlign: 'left',
											marginRight: '20px',
										}}
									>
										<div>Mes</div>
										<TextInput
											id={'question-month-' + item.numero}
											name={'month'}
											placeholder="Meses"
											contentStyles={{ maxWidth: 100 }}
											value={''}
											disabled={true}
										/>
									</div>
								</div>
							</div>
						)}
						{item.numero === '3.9' &&
							articles.map((opcion: any, index: any) => (
								<div
									className={classes.itemsQuest9}
									key={index + item.pregunta}
								>
									<div style={{ flexGrow: 1 }}>
										{opcion.text}
									</div>
									{index === 6 && (
										<div>
											<TextInput
												id={'otherType'}
												name={'otherType'}
												value={otherArticle}
												placeholder="¿Cuál?"
												disabled={
													articles[6].value === '' ||
													articles[6].value === '2'
												}
												onChange={(e) =>
													setOtherArticle(
														e.target.value
													)
												}
											/>
										</div>
									)}
									<div className={classes.itemsFlexInput}>
										<div
											className={classes.itemsFlexCenter}
										>
											<Radio
												checked={opcion.value === '1'}
												onChange={(e: any) => {
													let value = e.target.value;
													updatevalueQuestion(
														value,
														index,
														'articles'
													);
												}}
												name={'noWorking'}
												value={'1'}
											/>
											Sí
										</div>
										<div
											className={classes.itemsFlexCenter}
										>
											<Radio
												checked={opcion.value === '2'}
												onChange={(e: any) => {
													let value = e.target.value;
													updatevalueQuestion(
														value,
														index,
														'articles'
													);
												}}
												name={'noWorking'}
												value={'2'}
											/>
											No
										</div>
									</div>
								</div>
							))}
						{item.numero === '3.11' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor"
									name="valor"
									value={valor}
									placeholder="$0.00"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										if (e.target.value === '0') return;
										setValor(e.target.value);
									}}
								/>
							</div>
						)}
					</>
				),
			};
			return questions.push(newItem);
		});
		setQuests(questions);
	};

	const verifyComplete = () => {
		let respuesta = quest.every((item: any) => {
			if (item.numero !== '3.7' && item.respuesta.length === 0) return false
			else return true
		})
		return respuesta
	};

	const sendData = () => {
		nextStep();
	};

	return (
		<HeaderStep
			title={'Ingresos'}
			subTitle={'Trabajo principal'}
			nextStep={sendData}
			afterQuestionarySteps={afterQuestionarySteps}
			disabledContinue={!verifyComplete()}
			disabledButton={!verifyComplete()}
			children={quests}
			alert={alert}
		/>
	);
};

export default QuestStep5;
