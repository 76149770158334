import { Paper, useMediaQuery } from '@mui/material';
import CustomButton from '../../components/Button';
import { useTheme } from '@mui/material/styles';
import { HeaderList } from '../../components/HeaderList';
import { formatDate } from '../../utils/dates';

const ThanksDocument = ({ data, setShowDetail }: any) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	return (
		<>
			<HeaderList
				title="Sigue tu caso y adjunta documentos"
				date={formatDate('PP')}
			/>
			<Paper style={{ padding: 20, textAlign: 'center' }}>
				<p
					style={{
						fontSize: 30,
						color: '#293990',
						fontWeight: 500,
						margin: 0,
						marginTop: 20,
					}}
				>
					Caso {data.caso}
				</p>
				<p
					style={{
						fontSize: 18,
						color: '#333333',
					}}
				>
					<b>La situación de tu caso es: {data.textoStatus}</b>
				</p>
				<p
					style={{
						fontSize: 18,
						color: '#333333',
						width: '90%',
						maxWidth: '950px',
						margin: '30px auto',
					}}
				>
					Tu trámite ha concluido. Para mayor información comunícate a
					Infonatel al 55 9171 5050 en la Ciudad de México, o al 800
					003 3900 desde cualquier parte del país, de lunes a viernes
					de 7:30 a 21:00 horas, sábados, domingos y días festivos de
					9:00 a 15:00 horas.
				</p>
				<div
					style={{
						width: matches ? '100%' : 250,
						margin: 'auto',
						marginBottom: 20,
					}}
				>
					<CustomButton
						label="Regresar"
						variant="solid"
						onClick={() => {
							setShowDetail(0);
						}}
					/>
				</div>
			</Paper>
		</>
	);
};

export default ThanksDocument;
