import { Box, Link, Paper, useMediaQuery } from '@mui/material';
import theme from '../../../config/theme';
import { downloadPDF } from '../../../utils/downloadPDF';
import Header from '../../MIUC/components/Header';
import CustomAlert from '../../../components/CustomAlert';
import ButtonGroup from '../../MIUC/components/ButtonGroup';
import descarga from '../../../assets/img/icono-descarga.png';
import { useNavigate } from 'react-router-dom';

interface IProps {
	setView: (arg: number) => void;
	pdf64: string;
	message: string;
}

const HomePanel = ({ setView, pdf64, message }: IProps) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();

	const downloadCertificate = async () => {
		if (pdf64) {
			downloadPDF({
				name: 'certificado-de-apoyo-infonavit',
				payload: pdf64,
			});
		}
	};

	return (
		<Paper>
			<Header title="Apoyo Infonavit puede ser una alternativa para ti" />
			<Box padding={2}>
				<p style={{ margin: '30px 30px' }}>
					Con esta opción, lo que tienes ahorrado en tu Subcuenta de
					Vivienda queda como garantía de pago en caso de que pierdas
					tu empleo y tus aportaciones patronales serán abonadas para
					amortizar tu deuda con la entidad financiera y así pagarás
					más rápido tu crédito.
				</p>
				<Link
					underline="none"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						margin: '50px 0px',
						flexDirection: matches ? 'column' : 'initial',
						userSelect: 'none',
					}}
					onClick={downloadCertificate}
				>
					Descarga tu Certificado de Apoyo Infonavit
					<img
						src={descarga}
						alt="LogoDescarga"
						style={{ marginLeft: 5, fontSize: 30 }}
					/>
				</Link>

				<CustomAlert
					message={message}
					severity="warning"
					show={Boolean(message)}
				/>

				<ButtonGroup
					buttons={[
						{
							label: 'Salir',
							variant: 'outlined',
							execute: () => navigate('/'),
						},
						{
							label: 'Continuar',
							execute: () => setView(1),
							disabled: Boolean(message),
						},
					]}
				/>
			</Box>
		</Paper>
	);
};

export default HomePanel;
