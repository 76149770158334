import API from '../';

interface IDataFirstStep {
	nss: string;
	curp: string;
	rfc: string;
}
interface IDataSecondStep {
	nss: string;
}
interface IDataThirdStep {
	email: string;
	phone: string;
	nss: string;
	byPoE: string;
	password: string;
}

interface IDataValidate {
	code: string;
	key: string;
	nss: string;
}
class CreateAccountAPI extends API {
	// Cuenta activa, puede realizar login  ----> mandarlo al login
	// Cuenta bloqueada, ir a recuperacion de contraseña  ----> mandarlo al a cambio de contraseña
	// current step mas 1
	validateNSS(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/validate-nss`,
				method: 'POST',
				data: { nss },
			})
		);
	}
	validateCURP(nss: string, curp: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/validate-curp`,
				method: 'POST',
				data: { nss, curp },
			})
		);
	}
	validateRFC(nss: string, rfc: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/validate-rfc`,
				method: 'POST',
				data: { nss, rfc },
			})
		);
	}

	// Primer paso
	/*
		nss
		curp
		rfc
	*/
	createAccountFirstStep(data: IDataFirstStep): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/create-account-first-step`,
				method: 'POST',
				data,
			})
		);
	}
	// Second paso
	/*
		nss
	*/
	createAccountSecondStep(data: IDataSecondStep): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/create-account-second-step`,
				method: 'POST',
				data,
			})
		);
	}

	// Name incorrect
	createCaso(
		nss: string,
		curpIngresado: string,
		rfcIngresado: string
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/crear-caso`,
				method: 'POST',
				data: { nss, curpIngresado, rfcIngresado },
			})
		);
	}

	validatePhone(phone: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/validate-phonenumber`,
				method: 'POST',
				data: { phone },
			})
		);
	}
	validateEmailAddress(email: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/validate-email`,
				method: 'POST',
				data: { email },
			})
		);
	}

	// Third paso hasta contrase;a se ejecuta
	/*
		nss
		phone
		email
		byPoE
		password
	*/
	createAccountThirdStep(data: IDataThirdStep): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/create-account-third-step`,
				method: 'POST',
				data,
			})
		);
	}
	// para el boton de reenviar email
	resendEmail(key: string, nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/send-email-validation`,
				method: 'POST',
				data: { key, nss },
			})
		);
	}
	// Si ya esta el correo manda un error 409 Correo expirado
	validateEmail(key: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/validate-email-account`,
				method: 'POST',
				data: { key },
			})
		);
	}
	// reenvio de sms
	resendSMS(key: string, nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/send-sms-validation`,
				method: 'POST',
				data: { key, nss },
			})
		);
	}
	valideteCreation(data: IDataValidate): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/validate-sms-account-code`,
				method: 'POST',
				data,
			})
		);
	}
	deletePreRegister(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `registro/eliminar-preregistro`,
				method: 'POST',
				data: { nss },
			})
		);
	}
}
export const createAccountAPI = new CreateAccountAPI('');
