import { useState, useEffect } from 'react';
// COMPONENTS
import CustomButton from '../../../components/Button';
// RESOURCES
import { IAlert } from '../../../interfaces/alert';
import { useStyles } from './styles';
import { apiCancelledPayments } from '../../../api/modules/CancelledPayments';
import CustomAlert from '../../../components/CustomAlert';
import { FormatMoney } from '../../../interfaces/EcotechConstancy';
import { IDataInfo } from '../utils';

interface Props {
	onContinue: any;
	onReturn: any;
	dataInfo: IDataInfo;
	setDataInfo: (arg: IDataInfo) => void;
	setLoading: (arg: boolean) => void;
}

const LayoutNotice = ({
	onContinue,
	onReturn,
	setLoading,
	dataInfo,
	setDataInfo,
}: Props) => {
	const classes = useStyles();
	const [showAlert, setShowAlert] = useState('');
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const consultAvailibility = async () => {
		try {
			setLoading(true);
			const { result } = await apiCancelledPayments.getMontoDevolver();
			const { code, data, message } = result;
			if (Number(code || '99') === 0) {
				setDataInfo({
					...dataInfo,
					saldo: data.saldopesos || '0',
				});
				return;
			}
			setDataInfo({
				...dataInfo,
				saldo: '0',
			});
			if (Number(code) === 97) {
				setShowAlert(
					'Para continuar con tu trámite en línea, por seguridad, debes acudir al Centro de Servicio Infonavit más cercano y solicitar una desmarca de tu Devolución de pagos por créditos cancelados, no olvides que es indispensable presentar una identificación oficial vigente.'
				);
				setAlert({
					show: false,
					message: '',
					severity: 'error',
				});
				return;
			}
			setAlert({
				show: true,
				message:
					message ||
					'Ha ocurrido un error al obtener los datos, intente nuevamente',
				severity: 'error',
			});
		} catch (error) {
			setDataInfo({
				...dataInfo,
				saldo: '0',
			});
			setAlert({
				show: true,
				message:
					'Ha ocurrido un error al obtener los datos, intente nuevamente',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		consultAvailibility();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={classes.containerFirma}>
				{showAlert ? (
					<div
						style={{
							fontWeight: 500,
							textAlign: 'justify',
							margin: '10px 20px',
						}}
					>
						{showAlert}
					</div>
				) : (
					<div>
						<div className={classes.descriptionContainerTitles}>
							<p className={classes.descriptionContainerTitle}>
								El importe mostrado corresponde a la información
								que tenemos registrada, si te parece que el
								importe no refleja lo que tienes identificado
								como descuentos a tu nómina, te sugerimos
								consultar nuevamente en un mes.
							</p>
						</div>
						<div className={classes.descriptionContainerTitles}>
							<p className={classes.descriptionContainerTitle}>
								En caso de proceder con la solicitud de
								devolución, esta se realizará en un lapso no
								mayor a 10 días hábiles.
							</p>
						</div>
						<div className={classes.contentFileDataButtons}>
							<p className={classes.amount}>
								<b>El monto total a devolver es</b>
							</p>
						</div>
						<div className={classes.contentFileDataButtons}>
							<h1 className={classes.txtMonto}>
								{FormatMoney(Number(dataInfo.saldo || '0'))}
							</h1>
						</div>

						<CustomAlert
							message={alert.message}
							show={alert.show}
							severity={alert.severity}
						/>
					</div>
				)}
				<div className={classes.contentFileDataButtons}>
					<CustomButton
						onClick={() => {
							onReturn();
						}}
						variant="outlined"
						styles={{
							marginTop: 30,
							width: 200,
							marginInline: 10,
						}}
						label="Regresar"
					/>
					<CustomButton
						onClick={() => {
							onContinue();
						}}
						variant="solid"
						disabled={Number(dataInfo.saldo || '0') === 0}
						styles={{
							marginTop: 30,
							width: 200,
							marginInline: 10,
						}}
						label="Solicitar devolución"
					/>
				</div>
			</div>
		</>
	);
};

export default LayoutNotice;
