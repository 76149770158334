import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	titleServiceOfficeContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	titleServiceOffice: {
		marginTop: 0,
		fontSize: 22,
		color: '#293990',
	},
	subTitleServiceOffice: {
		fontSize: 12,
		color: '#333333',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			textAlign: 'right',
			marginTop: 15,
		},
	},
	checkHeader: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 7,
	},
	checkHeaderImg: {
		width: 17,
		marginRight: 5,
	},
	checkHeaderText: {
		color: '#333',
		fontSize: 13,
		padding: 0,
		margin: 0,
	},
	contentainter: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
	},
	contentainterTitle: {
		fontWeight: 600,
		fontSize: 16,
	},
	officeOptionContainer: {
		marginBlock: 50,
		display: 'flex',
		alignItems: 'self-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	officeOption: {
		display: 'flex',
		alignItems: 'self-start',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			marginBlock: 30,
		},
	},
	officeOptionImg: {
		width: 40,
		marginRight: 15,
	},
	officeOptionTitle: {
		fontSize: 14,
		padding: 0,
		margin: 0,
	},
	officeOptionText: {
		fontSize: 12,
		padding: 0,
		margin: 0,
		marginRight: 90,
		[theme.breakpoints.down('md')]: {
			marginRight: 0,
		},
	},
	filterContainer: {
		marginBlock: 50,
	},
	searchContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	searchButton: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	searchButtonBottom: {
		marginTop: 50,
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	searchContainerTitle: {
		margin: 0,
		padding: 0,
		fontSize: 16,
	},
	selectsContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	selectsContainerForm: {
		marginRight: 20,

		[theme.breakpoints.down('md')]: {
			marginRight: 0,
		},
	},
	resultsContainer: {
		marginBlock: 50,
	},
	resultsTitle: {
		fontSize: 15,
		fontWeight: 400,
	},
	results: {
		color: '#293990',
		fontWeight: 600,
	},
	resultsListContainer: {
		marginBlock: 50,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	listModules: {
		height: 500,
		flex: 1,
		marginRight: 50,
		overflow: 'auto',
		padding: '5px 10px 5px 5px',
		[theme.breakpoints.down('md')]: {
			marginRight: 0,
			marginBottom: 40,
		},
	},
	listContent: {
		backgroundColor: '#FFFFFF',
		display: 'flex',
		alignItems: 'flex-start',
		borderRadius: 12,
		boxShadow: '0px 2px 4px #CBCBCB80',
		padding: 20,
		marginBottom: 40,
		border: 0,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#F0F0F0',
			transition: '0.5s',
		},
	},
	step: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 40,
		height: 40,
		fontWeight: 800,
		color: '#fff',
		background: 'red',
		borderRadius: '50%',
		marginRight: 20,
	},
	stepTitle: {
		margin: 0,
		padding: 0,
		marginBottom: 15,
		fontSize: 14,
	},
	stepSubTitle: {
		margin: 0,
		padding: 0,
		fontSize: 12,
		marginBottom: 5,
	},
	stepText: {
		margin: 0,
		padding: 0,
		fontSize: 12,
		marginBottom: 5,
	},
	map: {
		width: '100%',
		height: '500',
	},
	select: {
		'&:before': {
			borderColor: 'red',
		},
		'&:after': {
			borderColor: 'red',
		},
	},
	icon: {
		fill: 'red',
	},
}));
