import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        mainContainer: {
			display: 'flex',
			flexDirection: 'column',
            alignItems: 'start',
		},
        header: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
        },
        headerTitulo: {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#293990',
            marginBottom: '5px',
        },
        headerSubtitulo: {
            fontSize: 13,
        },
        division:{
            marginTop: 5,
            marginBottom: 10,
            marginLeft: 30,
            width: '90%',
        },
        downloadPDF: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px !important',
            [theme.breakpoints.down('md')]: {
                margin: '5px !important',
              },
        },
        downloadButtons: {
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                margin: '10px',
              },
            alignItems: 'center', 
            justifyContent: 'center'
        },
        icoDescarga: {
            marginLeft: 5, 
            maxWidth: 18
        },
    })
);