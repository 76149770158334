import { useEffect, useState } from "react"
import HowCreditGoing from "./HowCreditGoing";
import { IProfileResponse, apiMIUC } from "../../../../../api/modules/MIUC";

export const MenuHowCreditGoing = () => {
    const [data, setData] = useState<IProfileResponse>({});

    useEffect(() => {
		getInitialProfile();
	}, []);

	const getInitialProfile = async() => {
		try {
			const response = await apiMIUC.getProfile();
			setData({ ...response.data });
		} catch (error: any) {}
	}

    return <HowCreditGoing data={data} />
}