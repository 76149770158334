export const phrases = `Quos accusantium hic praesentium ad. Numquam adipisci quam deserunt. Adipisci, optio totam minus voluptates recusandae, fugiat explicabo doloribus pariatur ab ducimus non.
Fugiat quisquam odit neque velit beatae laudantium praesentium commodi quia adipisci minus accusamus nemo soluta dolor similique provident rerum et, veniam rem ab consequuntur. Atque optio expedita sint distinctio architecto.
Nesciunt atque, deserunt velit assumenda, nemo deleniti et ut natus totam, accusamus eligendi molestias at voluptatum molestiae magnam eius quisquam reprehenderit saepe consequatur quas repudiandae similique unde minima. Deleniti, ipsum.
Autem ea facere beatae dolore quasi ab quam? Eius quis, dolorum sunt culpa doloribus ex eos nemo quam voluptatem impedit quas nostrum tempore corporis, alias dolores rerum cumque vero natus.
Rem assumenda nemo quam nesciunt aperiam doloremque perferendis voluptatibus explicabo natus tempore at, architecto cum esse, labore, vero commodi sapiente vel animi et corporis laboriosam? Minima distinctio provident quia libero.
Magnam natus dolores voluptate, quos accusantium aspernatur amet deleniti nihil. Delectus neque harum saepe qui sequi ad aut quasi ipsam quos esse nostrum nisi dolore, eius laudantium numquam modi quod!
Voluptatibus corrupti quae earum cumque corporis dolorum reprehenderit doloribus labore laborum, id nostrum ullam in repudiandae optio veritatis sequi totam mollitia sint odit! Ratione mollitia odit, magni quae dicta quas!
Voluptatum aliquam quam, voluptas quos iure facere quod neque magnam minima fugit quo eum aliquid. Laborum fuga natus possimus molestias quisquam? Nisi rem delectus quidem maxime enim ex laborum placeat?
Voluptatum facilis optio pariatur voluptatibus modi architecto rem, provident in quasi suscipit, ipsa similique ut aspernatur beatae omnis magnam quo. Quidem harum iste molestias quam animi voluptas eius reiciendis. Est.
Voluptate sit eligendi facere, repudiandae est sed, ullam inventore nam debitis animi culpa. Nam cum ipsam molestias, libero et eius repellendus repudiandae fugit? Quo, suscipit reprehenderit. Nihil itaque quaerat culpa?
Cupiditate impedit sed omnis tenetur, laboriosam obcaecati assumenda natus autem esse cumque quaerat architecto doloremque incidunt! Ducimus deserunt eveniet esse adipisci id aliquid repudiandae, totam blanditiis cum sint neque autem!
Id autem unde inventore, rem necessitatibus architecto natus quaerat tempore mollitia molestias modi libero voluptatem consectetur nemo officia aut dicta sunt placeat. Doloribus, nemo. Corporis sed inventore et quo repellat!
Repudiandae exercitationem voluptate rem sapiente ipsa minus molestiae tenetur saepe totam alias at est quo veniam fuga officia asperiores, nesciunt laudantium aut iusto voluptatum. Molestias eaque officia quae vitae earum!
Nobis officia aspernatur quidem illum incidunt neque repudiandae corporis iusto. Odio, at exercitationem sit consequatur vel quos nesciunt. In optio labore asperiores veniam voluptate harum nostrum et minima voluptatum eius.
Minima modi, rerum ex itaque earum adipisci suscipit sequi accusamus sed recusandae ipsum eligendi voluptates soluta quidem, assumenda cum, voluptatem aliquid nobis quam? Ipsam reiciendis culpa, laborum beatae ipsum iusto.
Molestias, consectetur illo. Aspernatur ipsam sapiente provident? Adipisci, sequi eius? Sapiente molestiae quos a voluptatum, quasi quae dolorem nostrum id obcaecati nulla cupiditate eius at beatae, ducimus dolore cumque repellat.
Laboriosam dicta, itaque, non officia ducimus autem explicabo placeat voluptas excepturi culpa nobis sint saepe iste atque veniam minima repellendus voluptatum labore? Eaque non itaque dolorum nesciunt, ad praesentium ipsa.
Quas quibusdam assumenda asperiores quos deserunt corporis a nemo eaque incidunt tenetur ab pariatur nam nostrum delectus, quasi, officiis nesciunt corrupti, deleniti aperiam inventore veritatis maiores? Voluptatibus laborum deserunt iste.
Quas unde necessitatibus ipsam in, enim vitae aperiam? Atque blanditiis itaque possimus et est! Iste delectus porro rem vero possimus expedita illum aliquam, obcaecati, molestiae incidunt necessitatibus perspiciatis cumque ratione.
Ipsam aspernatur distinctio officia voluptate delectus nostrum minima obcaecati voluptates amet earum cupiditate rerum eligendi molestiae deserunt eius tempore quod sed beatae consequatur, a libero. Voluptas unde quo necessitatibus quod?
Fugit iusto quisquam praesentium deleniti unde hic odio beatae pony ea. Vel iusto esse rem, pariatur provident, aspernatur architecto sit mollitia quia corporis maxime nemo, rerum voluptatibus dolores porro labore. Dicta.
Nihil pariatur ratione dolore molestias numquam sit accusamus, porro totam itaque consectetur, ipsum nulla dolorem ullam? Optio architecto harum nobis labore omnis rem libero facilis, veritatis excepturi, voluptatum neque sunt.
Voluptate dolor tenetur adipisci omnis, itaque aliquam rerum vitae, numquam maxime nesciunt, quis distinctio vero illo laudantium obcaecati dignissimos iste atque aliquid voluptas ullam libero voluptatem. Sapiente facilis dolor odio.
Delectus aut similique dolorum voluptatem dolor cum numquam possimus! Voluptatibus exercitationem facilis voluptate. Impedit iure blanditiis est soluta perferendis amet atque totam similique. Tempora facilis velit autem vero odit nisi?
Nostrum neque quos laudantium, perspiciatis laboriosam minima ratione delectus eveniet quo reiciendis ipsam id doloremque fuga. Enim necessitatibus iste, modi accusantium, soluta possimus esse ab neque natus blanditiis, unde odio?
Fugit commodi voluptas, molestias assumenda aliquam explicabo numquam natus perspiciatis animi vero? Quam ut aspernatur, voluptatum error architecto id delectus minima beatae repudiandae, vitae ipsam provident, maxime esse nihil neque.
Quidem eius unde, tempora eveniet, soluta iusto officiis quaerat incidunt perferendis deleniti dolores, hic nihil blanditiis. Numquam voluptatum dignissimos eligendi doloribus nostrum? Assumenda, culpa! Nulla alias quaerat architecto corrupti dolorum.
A tenetur dicta doloremque commodi veniam ab quia minima accusantium, rerum, sapiente, laboriosam quae magni ea esse earum numquam dolores consequuntur inventore reprehenderit. Fugiat eius, ad quae magni unde eos!
Nobis facilis inventore alias, error fuga commodi et voluptatem mollitia quod impedit placeat ipsam harum cum quia voluptatibus repellendus minus aperiam numquam nihil accusamus. Eveniet fugiat in aliquid vero dolore.
Vel cupiditate commodi harum cum suscipit. Ipsam quia unde impedit facere magni ducimus consequatur minima fugit iste quibusdam perspiciatis beatae modi, asperiores voluptate architecto hic aliquam, esse assumenda sunt sapiente!
Accusamus molestiae animi at est cum recusandae explicabo inventore tempora ratione vero? Cum, dolor quaerat consectetur blanditiis sunt eveniet hic deserunt odio aliquid quidem est omnis, delectus necessitatibus tempora. Rerum!
Ut delectus fugiat vel ex? Sapiente amet rem reiciendis harum minus at, eos cumque suscipit aut non dolore! Reiciendis nobis voluptas cumque. Quaerat minima error ab deleniti quas voluptate quisquam?
Ullam deserunt aliquam tempore delectus quibusdam! Magni nihil consequuntur, autem, possimus exercitationem animi ab vitae vero eum itaque iure quae et. Totam, quidem ea aut suscipit commodi ut voluptates! Totam?
Mollitia, exercitationem nemo soluta ex ipsam natus beatae. Eveniet inventore quas sunt fugit dicta optio numquam, totam, ipsum officiis itaque maiores eligendi? Libero, saepe explicabo? Ad dolorum amet ea ab.
Sed aspernatur natus harum, quod fugit est error aperiam excepturi, magni aut repellendus nemo eligendi id ducimus nam voluptas. Et ullam quod voluptas vero assumenda quas qui sequi. In, incidunt?
Rem, sint! Ipsum officiis ex harum veniam explicabo, minima ipsa ducimus aperiam, qui mollitia ea inventore eos voluptatum placeat ratione asperiores soluta nihil aut fuga. Ab totam molestiae doloribus incidunt.
Amet sint dicta cum perferendis, corrupti quidem dolore quae voluptatum eius sequi dignissimos nulla deleniti dolorum qui voluptatibus rem facere consectetur fugiat ab voluptatem consequatur distinctio incidunt exercitationem eligendi. Doloribus!
Exercitationem deleniti facilis voluptates voluptas? Cum repellat accusantium temporibus placeat hic quidem voluptates obcaecati modi amet autem tempora facilis nobis, doloribus ipsum, at totam vitae, vel ab. Officia, eveniet quis.
Pariatur sequi aliquam, saepe et excepturi, nobis, voluptates repellendus tempora rem harum nostrum quia eum libero fugiat voluptas minima esse voluptate voluptatum laboriosam vero ut veritatis magni ab? Commodi, neque!
Perferendis cupiditate qui ipsa nesciunt delectus unde saepe natus dicta sapiente, eum autem, nobis fuga. Voluptatem magni, et maxime minima facere odio, eaque obcaecati, ipsum ratione est libero voluptas. Delectus.
Numquam rem facere animi perferendis, nesciunt voluptatum minus illum. Asperiores vitae consectetur quam ex sit id cumque totam, aliquid obcaecati ad fugit! Unde dolores dicta tempora veniam necessitatibus, rem nam?
Sunt voluptatum asperiores nemo nesciunt illo debitis similique obcaecati perspiciatis quas. Laborum dicta eligendi architecto praesentium voluptatum fugiat vero sit sequi at cupiditate! Vero velit impedit at ipsa veniam enim.
Rem inventore animi tempore nihil, ipsum iusto. Quam, cupiditate magni. Accusamus, asperiores quasi unde saepe quod libero esse voluptatibus fugit quibusdam hic id dolore laborum sit voluptatem explicabo consequatur impedit!
Delectus possimus explicabo minima provident sequi sapiente recusandae animi, velit tempora blanditiis dignissimos beatae accusantium earum odio labore nostrum consequuntur numquam cupiditate vel modi quia incidunt nesciunt. Minima, voluptatum harum.
Quia, unde? Animi eius reprehenderit voluptatum cumque voluptatibus a unde assumenda cupiditate quaerat facere. Accusantium veniam ullam esse est, repudiandae dolores quibusdam quas fuga corrupti. Similique ut illum repudiandae exercitationem.
Numquam in nihil, dolor exercitationem facilis suscipit laudantium consectetur reiciendis illum at minus alias impedit autem perspiciatis enim modi nostrum non asperiores dolores debitis voluptate vitae recusandae quam veritatis? Quidem?
Molestias accusantium quod illo dolore amet harum. Reiciendis ipsam pariatur labore, veritatis delectus accusamus cum. Architecto exercitationem ut enim quas, earum neque eveniet quidem aut, perspiciatis, laboriosam repudiandae repellat facere.
Repellendus velit, quia cupiditate numquam asperiores reprehenderit rerum facilis sequi perferendis dolorum quis omnis est iusto molestias nostrum ut ex cum vitae inventore provident ipsa! Cumque optio error ea molestias!
Officia asperiores unde nesciunt minima, fugit vel sed voluptatum eum numquam assumenda aperiam beatae, quis iste architecto doloribus iusto nobis dignissimos veniam eligendi, placeat nostrum rerum porro qui alias? Ratione.
Voluptate aspernatur rerum dignissimos deserunt mollitia similique quaerat reiciendis blanditiis iure obcaecati, vero minima eum perferendis quia dicta id in sit voluptas. Libero dolorem architecto iusto provident minima, vero amet?`
.replaceAll(/[^a-zA-Z 0-9]+/g,'').replaceAll(/(\r\n|\n|\r)/gm, "").split(' ').filter(phrase => phrase.length < 8)