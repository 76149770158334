import { useState } from 'react';
import TabsCustom from '../../components/TabsCustom';
import TermsAndConditions from './termsAndConditions';
import ActivationOption from '../NipInfonatel/activationOption';
import ActivationMedium from '../NipInfonatel/activationMedium';
import NipGenaration from '../NipInfonatel/nipGeneration';
import ActivationNip from '../NipInfonatel/activationNip';

interface Validations {
	[index: number]: string;
}

interface IData {
	validations: Validations;
	nipsRecents: Validations;
}

const GeneratePin = ({ validations, nipsRecents }: IData) => {
	const [isEmail, setIsEmail] = useState(false);
	const [tabs, setTabs] = useState(0);
	const [typeInformation] = useState(5); //Se inicializa en 5 ya que en PinBlocked se esta utilizando del 1 al 4, aunque realmente no se va a utilizar para el happy path
	const [nip] = useState('');

	const handleFirstStep = async () => {
		setTabs(tabs + 1);
	};

	return (
		<TabsCustom
			selectIndex={tabs}
			activeStep
			setSelectIndex={setTabs}
			components={[
				{
					title: 'Términos y condiciones',
					component: (
						<TermsAndConditions onContinue={handleFirstStep} />
					),
				},
				{
					title: 'Opciones de activación',
					component: (
						<ActivationOption
							onContinue={handleFirstStep}
							isEmail={isEmail}
							setIsEmail={setIsEmail}
						/>
					),
				},
				{
					title: 'Medio de activación',
					component: (
						<ActivationMedium
							onContinue={handleFirstStep}
							isEmail={isEmail}
							onCloseError={() => { }}
							reSend={() => { }}
							typeInformation={typeInformation}
							nip={nip}
							action={'activar'}
						/>
					),
				},
				{
					title: 'Generación de NIP',
					component: (
						<NipGenaration
							onContinue={handleFirstStep}
							typeInformation={typeInformation}
							validations={validations}
							nipsRecents={nipsRecents}
						/>
					),
				},
				{
					title: 'Activación de NIP',
					component: <ActivationNip onContinue={handleFirstStep} />,
				},
			]}
		/>
	);
};

export default GeneratePin;
