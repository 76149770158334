// Dependencies
import { useEffect, useState } from 'react';

// Material components
import { Box, Divider, MenuItem, FormControl, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

// Components
import CustomButton from '../../components/Button';
import Layout from '../../components/Layout';
import Map from '../../components/Map';
import ModalLoading from '../../components/ModalLoading';

// Resources
import { serviceOfficeAPI } from '../../api/modules/serviceOffice';
import { IDetailsState, IState, IDetail } from '../../interfaces/serviceOffice';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { IAlert } from '../../interfaces/alert';

// Assets
import officeOption from '../../assets/img/oficina_opcion.png';
import { useStyles } from './styles';
import { HeaderList } from '../../components/HeaderList';
import CustomAlert from '../../components/CustomAlert';
import { useBitacora } from '../../hooks/useBitacora';

const LayoutExample = () => {
	const { regBitacoraNotOld } = useBitacora();
	const classes = useStyles();
	const [states, setStates] = useState<IState[]>([]);
	const [details, setDetails] = useState<IDetail[]>([]);
	const [center, setCenter] = useState<{ lat: number; lng: number }>({
		lat: 19.385376886038948,
		lng: -99.14469443420937,
	});
	const [marketSelected, setMarketSelected] = useState<IDetail>({} as IDetail);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [alertEstado, setAlertEstado] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [stateActive, setStateActive] = useState({} as IState);
	const [loading, setLoading] = useState(false);
	const [zoom, setZoom] = useState(10);
	const [redFlag, setRedFlag] = useState(false);

	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		try {
			serviceOfficeAPI
				.globalInfo()
				.then((res) => {
					if (res?.estados) {
						setStates(res.estados);
					} else {
						setAlert({
							show: true,
							message:
								INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message:
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				});
		} catch (error) {
			setAlert({
				show: true,
				message:
					INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		}
	};

	const handleChange = (event: SelectChangeEvent) => {
		const stateFlag: IState =
			states.find((e) => e.idDelEdo === event.target.value) || ({} as IState);
		setStateActive(stateFlag);
		setRedFlag(false);
		event.target.value &&
			setAlertEstado({
				show: false,
				message: INTERNAL_MESSAGES.SELECT_STATE,
				severity: 'warning',
			});
	};

	const handleSearch = async () => {
		if (!stateActive.idDelEdo) {
			setRedFlag(true);
			return setAlertEstado({
				show: true,
				message: INTERNAL_MESSAGES.SELECT_STATE,
				severity: 'warning',
			});
		}
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const state_id_base64 = await encrypt(stateActive.idDelEdo || '', key);
			const detailsPromise: Promise<IDetailsState[]> = Promise.all(
				(stateActive.oficinas || []).map(async (ele) => {
					const place_id_base64 = await encrypt(ele.idPlaza || '', key);
					const long_base64 = await encrypt(ele.longitud || '', key);
					const lat_base64 = await encrypt(ele.latitud || '', key);
					const delcesis_base64 = await encrypt(ele.delCesis || '', key);
					return Promise.resolve({
						idDelEdo: state_id_base64,
						idPlaza: place_id_base64,
						longitud: long_base64,
						latitud: lat_base64,
						delCesis: delcesis_base64,
					});
				})
			);
			detailsPromise
				.then((response) => {
					serviceOfficeAPI
						.detailInfo(response)
						.then(({ detail }) => {							
							setZoom(10);
							var bounds = new window.google.maps.LatLngBounds();
							var i;
							var polygonCoords = detail.map(
								(item) =>
									new window.google.maps.LatLng(
										Number(item.latitud),
										Number(item.longitud)
									)
							);

							for (i = 0; i < polygonCoords.length; i++) {
								bounds.extend(polygonCoords[i]);
							}

							setCenter({
								lat: bounds.getCenter().lat(),
								lng: bounds.getCenter().lng(),
							});
							setDetails(detail);
							regBitacoraNotOld(serviceOfficeAPI.schemeUrl, {
								idDelEdo: stateActive.idDelEdo
							});
						})
						.catch((error) => {
							setLoading(false);
							setAlert({
								show: true,
								message:
									INTERNAL_MESSAGES.ERROR_MESSAGE,
								severity: 'error',
							});
						})
						.finally(() => {
							setAlertEstado({
								show: false,
								message: INTERNAL_MESSAGES.SELECT_STATE,
								severity: 'warning',
							});
							setLoading(false);
						});
				})
				.catch((error) => {
					setLoading(false);
					setAlert({
						show: true,
						message:
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				});
		} catch (error) {
			setAlert({
				show: true,
				message:
					INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleSelectDetail = (item: IDetail) => {
		setZoom(17);
		setMarketSelected(item);
		setCenter({
			lat: Number(item.latitud),
			lng: Number(item.longitud),
		});
	};

	const getDelegation = (item: string): string => {
		let text = '';
		switch (item) {
			case 'C':
				text = 'Cesi';
				break;
			case 'D':
				text = 'Capde';
				break;
			case 'P':
				text = 'Delegación';
				break;

			default:
				break;
		}
		return text;
	};

	return (
		<Layout
			childrenSubheader={
				<Box sx={{ textAlign: 'center' }}>
					<strong>Subheader Children</strong>
				</Box>
			}
		>
			<>
				<HeaderList
					title="Oficinas de atención"
					list={[
						'Recuerda que en este portal puedes hacer trámites y servicios sin necesidad de acudir a una oficina.',
						'Consulta aquí la lista de trámites y servicios.',
					]}
					date="12 julio 2021"
				/>
				<div className={classes.contentainter}>
					<h2 className={classes.contentainterTitle}>
						Puedes localizar la oficina más cercana a tu domicilio en alguna
						de las siguientes formas:
					</h2>
					<div className={classes.officeOptionContainer}>
						<div className={classes.officeOption}>
							<img
								src={officeOption}
								className={classes.officeOptionImg}
								alt="office-op"
							/>
							<div>
								<h3 className={classes.officeOptionTitle}>CESI</h3>
								<p className={classes.officeOptionText}>
									Están en todo el país para darte atención personal
									sobre los trámites relativos a tu ahorro y tu crédito.
								</p>
							</div>
						</div>
						<div className={classes.officeOption}>
							<img
								src={officeOption}
								className={classes.officeOptionImg}
								alt="office-op"
							/>
							<div>
								<h3 className={classes.officeOptionTitle}>CAPDE</h3>
								<p className={classes.officeOptionText}>
									Atienden a las personas que necesitan información
									sobre su ahorro en el Infonavit y a quienes solicitan
									su devolución.
								</p>
							</div>
						</div>
					</div>
					<Divider />
					<CustomAlert
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
						onClose={() => {
							setAlert({
								show: false,
								message: '',
								severity: alert.severity,
							});
						}}
					/>
					<CustomAlert
						message={alertEstado.message}
						severity={alertEstado.severity}
						show={alertEstado.show}
					/>
					<div className={classes.filterContainer}>
						<div className={classes.searchContainer}>
							<h3 className={classes.searchContainerTitle}>
								Ubica tu módulo por:
							</h3>
							<div className={classes.searchButton}>
								<CustomButton
									onClick={handleSearch}
									variant="solid"
									styles={{ width: 150 }}
									disabled={alert.show}
									label="Buscar"
								/>
							</div>
						</div>
						<div className={classes.selectsContainer}>
							<FormControl fullWidth>
								<p>Ubicación</p>
								<Select
									error={redFlag}
									className={classes.selectsContainerForm}
									labelId="states-label-dropdown"
									id="states-dropdown"
									inputProps={{ 'data-testid': 'locationID' }}
									value={stateActive.idDelEdo || ''}
									displayEmpty
									onChange={handleChange}
								>
									<MenuItem value="">Selecciona el estado</MenuItem>
									{states.map((ele) => (
										<MenuItem key={ele.idDelEdo} value={ele.idDelEdo}>
											{ele.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<div className={classes.searchButtonBottom}>
								<CustomButton
									onClick={handleSearch}
									variant="solid"
									disabled={alert.show}
									label="Buscar"
								/>
							</div>
						</div>
					</div>
					<Divider />
					<div className={classes.resultsContainer}>
						{details.length > 0 && (
							<h3 className={classes.resultsTitle}>
								Tu búsqueda ha dado:
								<span className={classes.results}>
									{` ${details.length} resultados`}
								</span>
							</h3>
						)}
						<div className={classes.resultsListContainer}>
							<div className={classes.listModules}>
								{details.map((item, index) => (
									<div
										key={index}
										onClick={() => handleSelectDetail(item)}
										className={classes.listContent}
										style={{
											backgroundColor:
												marketSelected.latitud === item.latitud &&
													marketSelected.longitud === item.longitud
													? '#F0F0F0'
													: '#FFFFFF',
										}}
									>
										<div>
											<span className={classes.step}>
												{index + 1}
											</span>
										</div>
										<div style={{ flex: 1 }}>
											<h4 className={classes.stepTitle}>
												Infonavit Módulo{' '}
												{getDelegation(item.delCesis)}
											</h4>
											<h5 className={classes.stepSubTitle}>
												Dirección:
											</h5>
											<p className={classes.stepText}>
												{`${item.calle} ${item.cp} ${item.entreCalle
														? 'entre ' + item.entreCalle
														: ''
													} ${item.YCalle ? 'y ' + item.YCalle : ''
													} ${item.colonia}`}
											</p>
											<h5 className={classes.stepSubTitle}>
												Horario:
											</h5>
											<p className={classes.stepText}>
												{item.horario}
											</p>
											<h5 className={classes.stepSubTitle}>
												Servicios:
											</h5>
											<p className={classes.stepText}>
												{item.servicios
													.reduce(
														(previousValue, currentValue) =>
															`${previousValue}${currentValue}, `,
														''
													)
													.slice(0, -2)}
											</p>
										</div>
									</div>
								))}
							</div>
							<div style={{ flex: 2 }}>
								<Map
									googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_KEY_GOOGLE_MAPS}&v=3.exp`}
									containerElement={<div style={{ flex: 1 }} />}
									mapElement={
										<div
											style={{
												width: '100%',
												height: 500,
											}}
										/>
									}
									loadingElement={<p>Cargando...</p>}
									markers={details}
									center={center}
									zoom={zoom}
									onClickPoint={(item) => handleSelectDetail(item)}
								/>
							</div>
						</div>
					</div>
				</div>
				<ModalLoading loading={loading} />
			</>
		</Layout>
	);
};

export default LayoutExample;
