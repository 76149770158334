import { Dialog, DialogContent, Box } from '@mui/material';

interface Props {
	open: File | null;
	setOpen: (arg: File | null) => void;
}

const ModalViewDoc = ({ open, setOpen }: Props) => {
	return (
		<Dialog
			maxWidth={'md'}
			fullWidth
			open={open ? true : false}
			onClose={() => setOpen(null)}
		>
			<DialogContent>
				<Box
					width={'100%'}
					height={'100%'}
					minHeight={100}
					color="#000"
					position={'relative'}
				>
					{open && (
						<>
							{open.type.indexOf('image') > -1 && (
								<img
									style={{ width: '100%' }}
									alt="imagen"
									src={URL.createObjectURL(open)}
								/>
							)}
							{open.type.indexOf('video') > -1 && (
								<video
									controls
									src={URL.createObjectURL(open)}
									style={{ width: '100%', height: 500 }}
								>
									Vídeo no es soportado
								</video>
							)}
							{open.type.indexOf('audio') > -1 && (
								<audio
									style={{ width: '100%' }}
									src={URL.createObjectURL(open)}
									controls
								>
									este es un elemento de audio no soportado por tu
									navegador, prueba con otro
								</audio>
							)}
							{open.type.indexOf('pdf') > -1 && (
								<iframe
									title="visor"
									src={URL.createObjectURL(open)}
									style={{ width: '100%', height: 500 }}
								/>
							)}
						</>
					)}

					{/* <Box
						position={'absolute'}
						display={'flex'}
						left={0}
						top={0}
						right={0}
						bottom={0}
						justifyContent={'center'}
						alignItems={'center'}
						fontWeight={700}
					>
						Si no puedes visualizar tu archivo, es por que no es soportado /
						valido.
					</Box> */}
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default ModalViewDoc;
