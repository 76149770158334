import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	titleNoticeContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	titleNotice: {
		marginTop: 0,
		fontSize: 22,
		color: '#293990',
	},
	subTitleNotice: {
		fontSize: 12,
		color: '#333333',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			textAlign: 'right',
			marginTop: 15,
		},
	},
	checkHeader: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 7,
	},
	checkHeaderImg: {
		width: 17,
		marginRight: 5,
	},
	checkHeaderText: {
		color: '#333',
		fontSize: 13,
		padding: 0,
		margin: 0,
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: '20px 170px',
		paddingBlock: 50,
		textAlign: 'center',
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
		[theme.breakpoints.down('sm')]: {
			padding: '10px',
		},
	},
	contentDescription: {},
	descriptionTitle: {
		fontWeight: 400,
		fontSize: 15,
	},
	descriptionList: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	descriptionListTitle: {
		fontSize: 14,
	},
	descriptionListText: {
		fontSize: 14,
	},
	descriptionContainer: {
		margin: ' 30px auto',
		textAlign: 'center',
	},
	descriptionContainerTitles: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	descriptionContainerTitle: {
		fontSize: 14,
		marginRight: 5,
	},
	contentDescriptionBottomText: {
		fontSize: 14,
		marginBlock: 20,
	},
	contentDescriptionBottomLink: {
		color: '#D1001F',
		marginLeft: 5,
	},
	containerMin: {
		maxWidth: 1100,
		margin: '0 auto',
	},
	amountText: {
		color: '#293990',
		fontSize: 16,
		margin: 0,
		padding: 0,
		fontWeight: 600,
		marginTop: 15,
	},
	amount: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
		color: '#293990',
		fontSize: 24,
		margin: 0,
		padding: 0,
	},
	currency: {
		fontSize: 15,
		margin: 0,
		marginLeft: 5,
		marginBottom: 5,
		padding: 0,
		color: '#7F7F7F',
		fontWeight: 400,
	},
	contentFileDataButtons: {
		display: 'flex',
		justifyContent: 'center',
		marginBlock: 10,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
		},
		[theme.breakpoints.down('md')]: {},

		'&>button': {
			[theme.breakpoints.down('sm')]: {
				margin: '20px 0px !important',
				width: '100% !important',
			},
		},
	},
	amountContainer: {
		border: '1px solid #293990',
		borderRadius: 4,
		maxWidth: 300,
		margin: '10px auto',
		padding: '5px 40px',
	},
}));
