/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { Radio, Checkbox } from '@mui/material';
import { useStyles } from '../styles/styles';
import HeaderStep from './HeaderStep';
import { isNumber } from '../../../../../utils/expressions';
import TextInput from '../../../../../components/TextInput';

const QuestStep2 = ({
	quest,
	nextStep,
	handleChange,
	afterQuestionarySteps,
	alert,
}: any) => {
	const classes = useStyles();
	const [quests, setQuests] = useState([] as any);
	const [itemsActives, setItemsActives] = useState([] as any);
	const [value1, setValue1] = useState('');
	const [value2, setValue2] = useState('');
	const [value3, setValue3] = useState('');
	const [value4, setValue4] = useState('');
	const [activity, setActivity] = useState('');

	useEffect(() => {
		drawQuestions();
	}, [quest, itemsActives, value1, value2, value3, value4]);

	const setearRespuesta = (value: any, idxGral: any) => {
		let verify = false;
		let index = idxGral;
		itemsActives.forEach(function (item: any) {
			if (item === value) {
				verify = true;
				return;
			} else {
				verify = false;
			}
		});
		if (!verify) {
			let arrayNew = itemsActives;
			arrayNew.push(value);
			setItemsActives(arrayNew);
		} else {
			let resultado = itemsActives.filter((item: any) => item !== value);
			if (index === 0) {
				setValue1('');
			} else if (index === 1) {
				setValue2('');
			} else if (index === 2) {
				setValue3('');
			} else {
				setValue4('');
			}
			setItemsActives(resultado);
		}
		drawQuestions();
	};

	const handleChangeInput = (value: any, index: any) => {
		if (!isNumber(value)) return;
		if (index === 0) {
			setValue1(value);
		} else if (index === 1) {
			setValue2(value);
		} else if (index === 2) {
			setValue3(value);
		} else {
			setValue4(value);
		}
	};

	const drawQuestions = () => {
		const questions: any = [];
		quest.map((item: any, index: any) => {
			const newItem = {
				title: `${item.numero.split('.')[1]}.- ${item.pregunta}`,
				body: (
					<>
						{item.opciones.length > 0 &&
							item.numero !== '2.9' &&
							item.opciones.map((opcion: any, idx: number) => (
								<div
									className={classes.itemsFlexCenter}
									key={opcion + item.pregunta}
								>
									<Radio
										checked={
											item.respuesta ===
											opcion.split(')')[0]
										}
										onChange={(e: any) => {
											let value =
												e.target.value.split(')')[0];
											handleChange(value, item.numero);
										}}
										disabled={
											((index === 1 || index === 2) &&
												quest[0].respuesta === '1') ||
											(index === 2 &&
												quest[1].respuesta === '1') ||
											(index === 2 &&
												quest[1].respuesta === '2') ||
											(quest[2].respuesta === '2' &&
												[
													3, 4, 5, 6, 7, 8, 9, 10,
												].includes(index)) ||
											((index === 6 ||
												index === 7 ||
												index === 8 ||
												index === 9) &&
												quest[5].respuesta === '1') ||
											((index === 7 ||
												index === 8 ||
												index === 9) &&
												quest[6].respuesta === '2')
										}
										name={opcion.split(')')[0]}
										value={opcion.split(')')[0]}
									/>
									{opcion}
									{item.numero === '2.4' && idx === 6 && (
										<div
											style={{
												minWidth: '500px',
												paddingLeft: '10px',
											}}
										>
											<TextInput
												id={'otherType'}
												name={'otherType'}
												value={activity}
												disabled={
													quest[3].respuesta === '1' ||
													quest[3].respuesta === '2' ||
													quest[3].respuesta === '3' ||
													quest[3].respuesta === '4' ||
													quest[3].respuesta === '5' ||
													quest[3].respuesta === '6'
												}
												onChange={(e) => {
													setActivity(e.target.value);
													handleChange(
														`7 ${activity}`,
														item.numero
													);
												}}
											/>
										</div>
									)}
								</div>
							))}
						{item.opciones.length === 0 &&
							item.numero !== '2.9' && (
								<div style={{ maxWidth: 400 }}>
									<TextInput
										id={item.pregunta}
										name={item.pregunta}
										value={item.respuesta}
										disabled={quest[2].respuesta === '2'}
										onChange={(e) =>
											handleChange(
												e.target.value,
												item.numero
											)
										}
									/>
								</div>
							)}
						{item.numero === '2.9' &&
							item.opciones.map((opcion: any, index: any) => {
								let value =
									index === 0
										? value1
										: index === 1
											? value2
											: index === 2
												? value3
												: value4;
								return (
									<div
										className={classes.itemsQuest9}
										key={opcion + item.pregunta}
									>
										<div style={{ flexGrow: 1 }}>
											<Checkbox
												checked={
													itemsActives.indexOf(
														opcion.split(')')[0]
													) !== -1
														? true
														: false
												}
												onChange={(e: any) => {
													let value =
														opcion.split(')')[0];
													setearRespuesta(
														value,
														index
													);
												}}
												disabled={
													quest[5].respuesta ===
													'1' ||
													quest[6].respuesta ===
													'2' ||
													quest[7].respuesta ===
													'2' ||
													quest[2].respuesta === '2'
												}
											/>
											{opcion}
										</div>
										<div className={classes.itemsFlexInput}>
											<span>Número</span>
											<TextInput
												id={item.pregunta + index}
												name={item.pregunta + index}
												value={value}
												disabled={
													quest[2].respuesta !==
														'2' &&
														itemsActives.indexOf(
															opcion.split(')')[0]
														) !== -1
														? false
														: true
												}
												onChange={(e) =>
													handleChangeInput(
														e.target.value,
														index
													)
												}
											/>
										</div>
									</div>
								);
							})}
					</>
				),
				disabled:
					((index === 1 || index === 2) &&
						quest[0].respuesta === '1') ||
					(index === 2 && quest[1].respuesta === '1') ||
					(index === 2 && quest[1].respuesta === '2') ||
					(quest[2].respuesta === '2' &&
						[3, 4, 5, 6, 7, 8, 9, 10].includes(index)) ||
					((index === 6 ||
						index === 7 ||
						index === 8 ||
						index === 9) &&
						quest[5].respuesta === '1') ||
					((index === 7 || index === 8 || index === 9) &&
						quest[6].respuesta === '2') || ((index === 8) &&
							quest[7].respuesta === '2'),
			};
			return questions.push(newItem);
		});
		setQuests(questions);
	};

	const verifyComplete = () => {
		let questHabilitados: any = []
		for (let i = 0; i < quests.length; i++) {
			if (i !== 8 && quests[i].disabled === false) {
				questHabilitados.push(i)
			}
		}
		let respuesta = quest.every((item: any, index: any) => {
			if (questHabilitados.includes(index) && item.respuesta.length === 0) return false
			else return true
		})
		return respuesta
	};

	const sendData = () => {
		let respuesta = `1) ${value1}; 2) ${value2}; 3) ${value3}; 4) ${value4};`;
		handleChange(respuesta, '2.9');
		nextStep();
	};

	return (
		<HeaderStep
			title={'Situación laboral'}
			subTitle={'Trabajo principal'}
			nextStep={sendData}
			afterQuestionarySteps={afterQuestionarySteps}
			disabledContinue={!verifyComplete()}
			disabledButton={!verifyComplete()}
			children={quests}
			alert={alert}
		/>
	);
};

export default QuestStep2;
