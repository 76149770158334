import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { linearProgressClasses } from '@mui/material/LinearProgress';

export const useStyles = makeStyles((theme: Theme) => ({
	header: {
		textAlign: 'left',
		marginBottom: '30px',
	},
	titleBlue: {
		color: '#293990',
		margin: '10px 0 0'
	},
	textCenter: {
		textAlign: 'center',
		margin: '10px 0'
	},
	textLeft: {
		textAlign: 'left',
		margin: '10px 0'
	},
	container: {
		width: '90%',
		margin: '0 auto',
		paddingBottom: '2px'
	},
	textTop: {
		textAlign: 'center',
		paddingTop: '20px'
	},
	products: {
		display: 'flex',
		justifyContent: 'center',
		margin: '40px auto',
		flexWrap: 'wrap',
	},
	productsItem: {
		display: 'flex',
		justifyContent: 'center',
		maxWidth: '375px',
		width: '100%',
		padding: '10px',
	},
	footer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0'
	},
	itemQuestion: {
		width: '100%',
		paddingBottom: '25px',
		borderBottom: '1px solid #e5e5e5'
	},
	containerCalculate: {
		display: 'flex',
		alignItems: 'center',
		padding: '40px 40px 20px'
	},
	containerInput: {
		paddingBottom: '10px'
	},
	box: {
		marginBottom: '20px',
		border: '1px solid transparent',
		transition: '0.5s',
		'&:hover': {
			border: '1px solid #293990',
			backgroundColor: '#F7FBFF',
			transition: '0.5s',
			'&>div>div>h4': {
				color: '#293990',
			},
		},
	},
	boxItem: {
		padding: '20px',
		display: 'flex',
		gap: 20,
		alignItems: 'center',
		transition: '0.5s',
	},
	boxItemLogo: {
		width: '80px',
		height: '80px',
	},
	boxItemContent: {
		width: 'calc(100% - 80px)',
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
	},
	boxItemContentTitle: {
		fontSize: '18px',
		margin: '0px'
	},
	boxItemHidden: {
		width: '100%',
		marginBottom: '20px',
		padding: '0px 20px 20px',
		transition: '0.5s',
	},
	boxItemHiddenContent: {
		paddingLeft: '100px',
		'&>div>h4': {
			color: '#293990',
		},
	},
	boxItemHiddenContentList: {
		paddingLeft: '15px',
	},
	boxItemHiddenContentFlex: {
		display: 'flex',
		gap: '40px'
	},
	boxItemFooter: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	breadcrumb: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		color: '#D1001F',
		marginRight: '20px',
		fontWeight: 'bold',
	},
	iconSpaced: {
		marginLeft: '5px'
	},
	loader: {
		maxWidth: '400px',
		width: '80%',
		display: 'flex',
		alignItems: 'center',
	},
	linearProgress: {
		width: '100%',
		height: '7px !important',
		borderRadius: 5,
		marginRight: '10px',
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: '#293990',
		},
	},
	paperCustom: {
		boxShadow: '0px 5px 30px #00000017',
		borderRadius: '5px',
		padding: '30px',
		marginBottom: '40px'
	},
	paperCustomTitle: {
		margin: '0px 0px 10px'
	},
	paperCustomListItem: {
		display: 'flex',
		alignItems: 'center',
		gap: '4px',
		margin: '0px 0px 6px'
	},
	formSelectContainer: {
		paddingRight: '20px'
	},
	formSelectItem: {
		marginBottom: '20px'
	},
	mt: {
		marginTop: '16px'
	},
	paginationContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '20px 0'
	},
	centeredContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		minWidth: '100%',
	},
	inputs: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		margin: '20px 0'
	},
	bCalcular: {
		display: 'flex',
		alignItems: 'end',
		margin: '5px',
		paddingBottom: '5px',
	},
  selectBg: {
    backgroundColor: 'white',
  },
}));
