/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';
import CustomButton from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import DireccionView from './direccionData';
import NotarioView from './notarioData';
import { IAddressData, INotaryData, MortgageCancellationType } from '.';
import { keyAPI } from '../../../api/modules/key';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { states } from '../../../config/states';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { CARTA_NOTARIO_ESCRITURA_PUBLICA } from '../../../config/constants';
import { cancelLetterAPI } from '../../../api/modules/cancellationLetter';
import { formatDate, formatStringToDateChar } from '../../../utils/dates';
import ModalLoading from '../../../components/ModalLoading';
import { encrypt } from '../../../utils/encrypt';
import SelectInput from '../../../components/SelectInput';
import { IAlert } from '../../../interfaces/alert';
import CustomAlert from '../../../components/CustomAlert';
import { useCancellationForm } from '../../../hooks/useCancellationForm';
import { FormCancellation } from '../contextFormCancellation';
import { removeAccents } from '../../../utils/expressions';
import parseISO from 'date-fns/parseISO';
import { useBitacora } from '../../../hooks/useBitacora';

interface IEscritura {
	noEscritura: string;
	fecha: Date | string | null;
	nombre: string;
	numero: string;
	estado: string;
}
interface IPublicNotarioView {
	setPage: (data: number) => void;
	dataHipoteca: {
		caso: string;
		fecha_liq: string;
		est_credito: string;
		casollave: string;
		tipificacion: string;
	};
	handlerConsultaCaso: () => void;
	docType: MortgageCancellationType;
}

export const PublicNotarioView = ({
	setPage,
	dataHipoteca,
	handlerConsultaCaso,
	docType,
}: IPublicNotarioView) => {
	const { regBitacoraNotOld } = useBitacora();
	const [buttonValidate, setButtonValidate] = useState(true);
	const [loading, setLoading] = useState(false);
	const [addressData, setAddressData] = useState<IAddressData>({
		cp: '',
		estado: '',
		municipio: '',
		colonia: '',
		calle: '',
		noint: '',
		noext: '',
	} as IAddressData);
	const [notaryData, setNotaryData] = useState<INotaryData>({
		nombre: '',
		numero: '',
		estado: '',
		correo: '',
	} as INotaryData);
	const [escritura, setEscritura] = useState<IEscritura>({
		noEscritura: '',
		fecha: new Date(),
		nombre: '',
		numero: '',
		estado: '',
	});
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [optionsSelect, setOptionsSelect] = useState<{ value: string; text: string }[]>(
		[]
	);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const setData = useCancellationForm()[1];
	const [FlagDate, setFlagDate] = useState(false);

	const handleChangeNotary = (target: { name: string; value: string }) => {
		const { name, value } = target;

		if (name === 'numero') {
			if (!isNaN(Number(value)) && Number(value) >= 0) {
				setNotaryData({ ...notaryData, [name]: value });
			}
		} else {
			setNotaryData({ ...notaryData, [name]: value });
		}
	};

	const handleChangeEscritura = (target: {
		name: string;
		value: Date | string | null;
	}) => {
		const { name, value } = target;
		setEscritura({ ...escritura, [name]: value });
	};

	const handleOnSubmit = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const credit_base64 = await encrypt(credit || '', key);

			const nombre_base64 = await encrypt(removeAccents(notaryData.nombre) || '', key);
			const numero_base64 = await encrypt(removeAccents(notaryData.numero) || '', key);
			const estado_notary_base64 = await encrypt(removeAccents(notaryData.estado) || '', key);
			const correo_base64 = await encrypt(removeAccents(notaryData.correo) || '', key);
			const no_escritura_base64 = await encrypt(removeAccents(escritura.noEscritura) || '', key);
			const fecha_base64 = await encrypt(formatDate('dd.MM.yyyy', new Date((escritura.fecha || new Date()) as Date)).toString(), key);

			const estado_base64 = await encrypt(removeAccents(addressData.estado) || '', key);
			const colonia_base64 = await encrypt(removeAccents(`${addressData.colonia} ${addressData.municipio} ${addressData.cp}`) || '', key);
			const calle_base64 = await encrypt(removeAccents(`${addressData.calle} ${addressData.noext} ${addressData.noint}`) || '', key);

			const nombre_escritura_base64 = await encrypt(removeAccents(escritura.nombre) || '', key);
			const numero_escritura_base64 = await encrypt(removeAccents(escritura.numero) || '', key);
			const estado_escritura_base64 = await encrypt(removeAccents(escritura.estado) || '', key);

			const tipo_carta_base64 = await encrypt(CARTA_NOTARIO_ESCRITURA_PUBLICA || '', key);
			const fecha_liq_base64 = await encrypt(formatStringToDateChar(dataHipoteca.fecha_liq, '.'), key);
			const caso_llave_base64 = await encrypt(dataHipoteca.casollave ? dataHipoteca.casollave : '', key);
			const tipificacion_base64 = await encrypt(dataHipoteca.tipificacion ? dataHipoteca.tipificacion : '', key);
			const est_credito_base64 = await encrypt(dataHipoteca.est_credito ? removeAccents(dataHipoteca.est_credito) : '', key);

			const dataValues: FormCancellation = {
				nss: user?.nss.toString() || '',
				numero_credito: credit || '',
				numero_instrumento: removeAccents(escritura.noEscritura) || '',
				libro: '',
				calle: removeAccents(`${addressData.calle} ${addressData.noext} ${addressData.noint}`) || '',
				colonia: removeAccents(`${addressData.colonia} ${addressData.municipio} ${addressData.cp}`) || '',
				ciudad: removeAccents(addressData.estado) || '',
				seccion: '',
				numero_escritura: removeAccents(escritura.noEscritura) || '',
				fecha_elaboracion: formatDate('dd.MM.yyyy', new Date((escritura.fecha || new Date()) as Date)).toString(),
				numero_notario: removeAccents(notaryData.numero) || '',
				nombre_notario: removeAccents(notaryData.nombre) || '',
				correo_notario: removeAccents(notaryData.correo) || '',
				ciudad_notario: removeAccents(notaryData.estado) || '',
				folio_real: '',
				nombre_notario_origen: removeAccents(escritura.nombre) || '',
				numero_notario_origen: removeAccents(escritura.numero) || '',
				ciudad_notario_origen: removeAccents(escritura.estado) || '',
				tipo_carta: CARTA_NOTARIO_ESCRITURA_PUBLICA || '',
				fecha_liquidacion: formatStringToDateChar(dataHipoteca.fecha_liq, '.'),
				clase_operacion: dataHipoteca.casollave || '',
				tipificacion: dataHipoteca.tipificacion || '',
				estado_credito: removeAccents(dataHipoteca.est_credito) || '',
			};
			const dataFlag: FormCancellation = {
				nss: nss_base64,
				numero_credito: credit_base64,
				numero_instrumento: no_escritura_base64,
				libro: '',
				calle: calle_base64,
				colonia: colonia_base64,
				ciudad: estado_base64,
				seccion: '',
				numero_escritura: no_escritura_base64,
				fecha_elaboracion: fecha_base64,
				numero_notario: numero_base64,
				nombre_notario: nombre_base64,
				correo_notario: correo_base64,
				ciudad_notario: estado_notary_base64,
				folio_real: '',
				nombre_notario_origen: nombre_escritura_base64,
				numero_notario_origen: numero_escritura_base64,
				ciudad_notario_origen: estado_escritura_base64,
				tipo_carta: tipo_carta_base64,
				fecha_liquidacion: fecha_liq_base64,
				clase_operacion: caso_llave_base64,
				tipificacion: tipificacion_base64,
				estado_credito: est_credito_base64,
			};
			cancelLetterAPI
				.creaCarta(dataFlag)
				.then((response) => {
					regBitacoraNotOld(cancelLetterAPI.schemeUrl, dataValues);
					handlerConsultaCaso();
					setData(dataValues);
					setPage(6);
				})
				.catch((err) => {
					setAlert({
						show: true,
						message:
							err.description ||
							INTERNAL_MESSAGES.ERROR_GETTING_DATA,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) { }
	};

	useEffect(() => {
		if (
			escritura.noEscritura &&
			escritura.fecha &&
			escritura.nombre &&
			escritura.numero &&
			escritura.estado &&
			addressData.calle &&
			addressData.colonia &&
			addressData.estado &&
			dataHipoteca.fecha_liq &&
			dataHipoteca.casollave &&
			dataHipoteca.tipificacion &&
			dataHipoteca.est_credito &&
			notaryData.numero &&
			notaryData.nombre &&
			notaryData.correo &&
			notaryData.estado
		) {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	}, [escritura, addressData, dataHipoteca, notaryData]);

	useEffect(() => {
		let newArray: { value: string; text: string }[] = [];
		states.forEach((state: { name: string; code: string }) => {
			newArray.push({ value: state.code, text: state.name });
		});

		setOptionsSelect(newArray);
	}, [states]);

	return (
		<>
			<span>
				<b>Escribe los siguientes datos</b>
			</span>
			<NotarioView
				data={notaryData}
				changeData={handleChangeNotary}
				docType={docType}
			/>
			<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
				<TextInput
					id="noEscritura"
					name="noEscritura"
					onChange={(event) => {
						handleChangeEscritura({
							name: event.target.name,
							value: event.target.value,
						});
					}}
					value={escritura.noEscritura}
					isRequired
					label="Número de Escritura pública"
					maxLength={30}
				/>
			</div>
			<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
				<p
					style={{
						margin: 0,
						fontSize: 14,
						color: '#7E8094',
						marginBottom: '8px',
					}}
				>
					Fecha elaboración de la escritura pública
					<label style={{ color: theme.palette.primary.main }}>* </label>
				</p>
				<DesktopDatePicker
					inputFormat="dd/MM/yyyy"
					value={escritura.fecha}
					minDate={parseISO('1972-05-01')}
					onChange={(value) => {
						if (!FlagDate) {
							handleChangeEscritura({
								name: 'fecha',
								value,
							});
						}
						setFlagDate(false);
					}}
					InputProps={{ style: { height: 40, width: '100%' } }}
					renderInput={(params) => <TextField fullWidth {...params} />}
					onYearChange={(year) => {
						setFlagDate(true);
						handleChangeEscritura({
							name: 'fecha',
							value: year,
						});
					}}
				/>
			</div>
			<DireccionView data={addressData} changeData={setAddressData} />
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<hr
						style={{
							opacity: 0.4,
							marginTop: 15,
							marginBottom: 15,
						}}
					/>
				</Grid>
			</Grid>
			<span>
				<b>Datos del notario</b>
			</span>
			<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
				<TextInput
					id="nombre"
					name="nombre"
					onChange={(event) => {
						handleChangeEscritura({
							name: event.target.name,
							value: event.target.value,
						});
					}}
					value={escritura.nombre}
					isRequired
					label="Nombre del notario que originó"
					maxLength={30}
				/>
			</div>
			<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
				<TextInput
					id="numero"
					name="numero"
					onChange={(event) => {
						const { value, name } = event.target;
						if (!isNaN(Number(value)) && Number(value) >= 0) {
							handleChangeEscritura({
								name: name,
								value: value,
							});
						}
					}}
					value={escritura.numero}
					isRequired
					label="Número de la notaría que originó"
					maxLength={30}
				/>
			</div>
			<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
				<SelectInput
					data-testid="estado"
					id="estado"
					name="estado"
					label="Estado donde se localiza la notaría que originó"
					isRequired
					value={escritura.estado}
					helperText={''}
					onChange={(event) => {
						handleChangeEscritura({
							name: event.target.name,
							value: event.target.value,
						});
					}}
					options={optionsSelect}
					placeholder="Selecciona"
				/>
			</div>

			<ModalLoading loading={loading} />
			<div className={classes.contentFileDataButtons}>
				<CustomAlert
					data-testid="mensageAlerttwo"
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
					onClose={() => {
						setAlert({
							show: false,
							message: '',
							severity: alert.severity,
						});
					}}
				/>
			</div>
			<div className={classes.contentFileDataButtons}>
				<CustomButton
					onClick={() => {
						setPage(2);
					}}
					variant="outlined"
					styles={{
						width: 250,
						marginInline: 10,
					}}
					label="Regresar"
				/>
				<CustomButton
					onClick={handleOnSubmit}
					disabled={buttonValidate}
					variant="solid"
					styles={{
						width: 250,
						marginInline: 10,
					}}
					label="Continuar"
				/>
			</div>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<hr
						style={{
							opacity: 0.4,
							marginTop: 50,
							marginBottom: 0,
						}}
					/>
					<h3
						style={{
							marginLeft: matches ? 10 : 20,
							marginTop: 8,
							color: '#293990',
							fontSize: matches ? 16 : 18,
							paddingBottom: 15,
						}}
					>
						* Datos obligatorios
					</h3>
				</Grid>
			</Grid>
		</>
	);
};

export default PublicNotarioView;
