import { LinearProgress, Paper } from '@mui/material';
import { useStyles } from '../../styles';
import StepComponent from '../StepComponent';

const FlowCard = ({
	title = '',
	progress = 0,
	hideProgress = false,
	flow = [],
}: any) => {
	const classes = useStyles();
	return (
		<Paper className={classes.flowCard} variant="outlined">
			<h4>{title}</h4>
			{!hideProgress && (
				<div>
					<LinearProgress
						style={{ height: 8, background: '#00000017' }}
						variant="determinate"
						color="info"
						value={progress}
					/>
				</div>
			)}
			<div className="stepper-container">
				<StepComponent listItem={flow} />
			</div>
			{flow?.length === 0 && (
				<h5 style={{ color: '#293990', margin: '10px 0px' }}>
					Aún no hay elementos que mostrar
				</h5>
			)}
		</Paper>
	);
};

export default FlowCard;
