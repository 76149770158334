import { typesCharacteristic } from "./utils/typesCharacteristic";

interface Props {
	type: string;
}

const Characteristic = ({ type }: Props) => {
    return typesCharacteristic[type] ? typesCharacteristic[type] : <></>;
};

export default Characteristic;
