import { useState } from 'react';

import ActivationMedium from '../activationMedium';
import HalfUnlock from '../../NipInfonatel/UnlockPin/halfUnlock';
import NipActivated from './nipActivation';
import TabsCustom from '../../../components/TabsCustom';

interface IData {
	blockNip: boolean;
	typeInformation: number;
	nip: string;
}

const UnlockType = ({ blockNip, typeInformation, nip }: IData) => {
	const [isEmail, setIsEmail] = useState(false);
	const [tabs, setTabs] = useState(0);

	const handleFirstStep = async () => {
		setTabs(tabs + 1);
	};

	return (
		<div>
			<TabsCustom
				selectIndex={tabs}
				activeStep
				setSelectIndex={setTabs}
				components={[
					{
						title: 'Medio de desbloqueo de NIP',
						component: (
							<HalfUnlock
								onContinue={handleFirstStep}
								isEmail={isEmail}
								setIsEmail={setIsEmail}
								blockNip={blockNip}
								typeInformation={typeInformation}
							/>
						),
					},
					{
						title: 'Código de confirmación',
						component: (
							<ActivationMedium
								onContinue={handleFirstStep}
								isEmail={isEmail}
								onCloseError={() => { }}
								reSend={() => { }}
								typeInformation={typeInformation}
								nip={nip}
								action={'desbloquear'}
							/>
						),
					},
					{
						title: 'Desbloqueo de NIP',
						component: (
							<NipActivated onContinue={handleFirstStep} />
						),
					},
				]}
			/>
		</div>
	);
};

export default UnlockType;
