import API from '../';

class NotariosAPI extends API {
	validateURL(nss: string, correo: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/selection-notaries`,
				method: 'POST',
				data: { nss, correo },
			})
		);
	}
}

export const notariosAPI = new NotariosAPI('');
