import { Link } from '@mui/material';
import { useStyles } from './styles';
import { RoundBtn } from '../RoundBtn/roundBtn';
import { useNavigate } from 'react-router-dom';
import { useGeneralStyles } from '../../views/styles/general.styles';

interface IProps {
	setFlag: any;
}

export const BuroMssg = (props: IProps) => {
	const { setFlag } = props;
	const classes = useStyles();
	const navigate = useNavigate();
	const generalStyle = useGeneralStyles();

	return (
		<>
			<div className={classes.mainInfoBuro}>
				Después de realizar la consulta a tu Buró de Crédito,
				identificamos algunos retrasos en pagos, situación que solo nos
				permite otorgarte el 60% del monto autorizado para tu crédito,
				si estás de acuerdo, para continuar, da clic en{' '}
				<Link onClick={() => setFlag(false)} color={'#293990'}>
					"No autorizo"
				</Link>
				.
			</div>
			<div className={classes.mainInfoBuro}>
				Si deseas incrementar el monto de tu crédito, realiza la
				aclaración correspondiente ante{' '}
				<Link
					href="https://www.burodecredito.com.mx/"
					target="_blank"
					color={'#293990'}
				>
					Buró de Crédito
				</Link>{' '}
				y después vuelve a solicitar tu precalificación Infonavit.
			</div>

			<div className={generalStyle.bottonsContainer}>
				<RoundBtn variant="outlined" onClick={() => navigate('/')}>
					Salir
				</RoundBtn>
			</div>
		</>
	);
};
