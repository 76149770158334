/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies
import { useState, useEffect } from 'react';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

// Components
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import ModalLoading from '../../components/ModalLoading';

// pages
import Home from './home';
import PageEfirm from './pageEfirm';
import Status from './status';
import PageNomina from './pageNomina';
import PageSat from './pageSat';
import AttachDocument from './attachDocument';
import MakeDate from './makeDate';
import CloseCase from './closeCase';
import { IAlert } from '../../interfaces/alert';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { ApiRfcCorrectionRegister } from '../../api/modules/rfcCorrectionRegister';
import { makeDate24API } from '../../api/modules/makeDate24';
import { encodeAllJSONData } from '../../utils/encrypt';
import { IDataCitasActuales, IDataConfirm } from '../../interfaces/makeDate24';

// Assets
import { useStyles } from './styles';

const describeText = [
	'En caso de que no tengas e.firma, adjunta tu recibo de nómina digital (.XML). Este debe tener antigüedad no mayor a un año.',
	'Si no cuentas con e.firma o recibo de nómina digital, slecciona la opción "Validación con información del SAT". Para ello, es necesario que tu CURP registrada en el Infonavit sea la misma a la que esté asociado tu RFC en el SAT.',
	'Si no es posible realizar el trámite con las opciones anteriores, acude a un Centro de Servicio Infonavit, para ello selecciona "Haz tu cita".'
];

const headerListLink = [
	['Haz el trámite con tu firma electrónica avanzada (e.firma). Si quieres obtenerla ingresa a'],
	['www.sat.gob.mx'],
	[''],
	['https://www.sat.gob.mx'],
	[0]
];

const RfcCorrectionRegister = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const [page, setPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [makeDate, setMakeDate] = useState(false);
	const [hasDate, setHasDate] = useState(false);
	const [hasDateToCase, setHasDateToCase] = useState(false);
	const [citaActual, setCitaActual] = useState<IDataConfirm>({
		appointmentCode: '',
		appointmentEndDate: '',
		centroAtencionDes: '',
		centroAtencionId: '',
		centroAtencionLocation: '',
		clientNSS: '',
		creationDate: new Date(),
		customField1: 0,
		date: new Date(),
		day: '',
		hour: '',
		id: '',
		minute: '',
		serviceDes: '',
		serviceId: '',
		stateId: '',
		organizationLocation: ''
	})
	const [initialPage, setInitialPage] = useState(true);
	const [viewFooter, setViewFooter] = useState(false);
	const [viewHeader, setViewHeader] = useState(true);
	const [Params, setParams] = useState<{
		nss: string;
		curp: string;
		rfc: string;
		name: string;
		phone: string;
		email: string;
		rfcCrm: string
	}>({
		nss: '',
		curp: '',
		rfc: '',
		name: '',
		phone: '',
		email: '',
		rfcCrm: ''
	});
	const [dataStatus, setDataStatus] = useState({});
	const [doctosRequeridosArray, setDoctosRequeridosArray] = useState('');
	const [doctosRequeridos, setDoctosRequeridos] = useState('');

	useEffect(() => {
		getStatus();
	}, []);

	useEffect(() => {
		let values = {
			nss: Params.nss,
			curp: Params.curp,
			rfc: Params.rfc,
			name: Params.name,
			phone: Params.phone,
			email: Params.email,
			rfcCrm: Params.rfcCrm
		};
		localStorage.setItem('dataUserRegister', JSON.stringify(values));
	}, [Params]);

	useEffect(() => {
		if (hasDate) {
			handlecitasActuales(Params.nss)
		}
	}, [hasDate]);

	const getStatus = async () => {
		setHasDate(false)
		const userRegisterStorage: any = localStorage.getItem('dataUserRegister');
		let values;
		if (userRegisterStorage) {
			let paramsStorage = JSON.parse(userRegisterStorage)
			values = {
				nss: paramsStorage.nss,
				curp: paramsStorage.curp,
				rfc: paramsStorage.rfc,
				name: paramsStorage.name,
				phone: paramsStorage.phone ? paramsStorage.phone : '',
				email: paramsStorage.email ? paramsStorage.email : '',
				rfcCrm: paramsStorage.rfcCrm,
			};
			setParams(values);
		}
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const rfc_base64 = await encrypt(values?.rfc.toString() || '', key);
			const nss_base64 = await encrypt(values?.nss.toString() || '', key);
			const curp_base64 = await encrypt(values?.curp.toString() || '', key);
			ApiRfcCorrectionRegister.getStatus
				.post<
					{
						nss: string;
						rfc: string;
						curp: string;
					},
					any
				>('', {
					nss: nss_base64,
					rfc: rfc_base64,
					curp: curp_base64
				})
				.then((response: any) => {
					if (
						response.result.status !== '' &&
						response.result.status !==
						'No se encontraron casos para los criterios de búsqueda'
					) {
						if (response.result.status.code === 'E0007') {
							setMakeDate(false)
							setHasDate(false)
							setViewHeader(true)
							setPage(0);
						} else if (response.result.status.code === 'E0015') {
							setDataStatus(response.result);
							setViewFooter(true)
							setViewHeader(false)
							setPage(7);
						} else {
							if (response.result.status.description === 'Hacer cita') {
								setMakeDate(true)
								setHasDate(false)
							}
							if (response.result.status.description === 'Cambiar cita') {
								setMakeDate(true)
								setHasDate(true)
							}
							setDataStatus(response.result);
							setViewFooter(true)
							setViewHeader(false)
							setPage(5);
						}
					} else {
						setMakeDate(false)
						setHasDate(false)
						setViewHeader(true)
						setPage(0);
					}
				})
				.catch((err: any) => {
					setMakeDate(false)
					setHasDate(false)
					setPage(0);
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setMakeDate(false)
			setHasDate(false)
			setPage(0);
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		}
	};
	const handlecitasActuales = async (nss: string | any) => {
		setCitaActual({
			appointmentCode: '',
			appointmentEndDate: '',
			centroAtencionDes: '',
			centroAtencionId: '',
			centroAtencionLocation: '',
			clientNSS: '',
			creationDate: new Date(),
			customField1: 0,
			date: new Date(),
			day: '',
			hour: '',
			id: '',
			minute: '',
			serviceDes: '',
			serviceId: '',
			stateId: '',
			organizationLocation: ''
		})
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const data: IDataCitasActuales = {
				pAppointmentCode: '',
				nss: nss || ''
			}
			const dataEncript = await encodeAllJSONData({ data: JSON.stringify(data), }, key);
			makeDate24API
				.citasActualesOfline(dataEncript)
				.then((response) => {
					if (response.code === '0000') {
						if (response.data?.citas && response.data.citas.length > 0) {
							let doctosRequerido: string = '';
							response.data.citas.forEach(async (element: any) => {
								let hasDateLocal = false
								if (element.serviceId === '0125') {
									setCitaActual(element)
									hasDateLocal = true
								}
								const params = {
									pOrganizationCode: element?.organizationCode,
									pServiceId: element?.serviceId,
									nss: nss || ''
								};
								const dataEncript = await encodeAllJSONData(
									{
										data: JSON.stringify(params),
									}, key
								);
								const responseDoctosRequeridos = await makeDate24API.documentosRequeridosOfline(dataEncript);
								if (responseDoctosRequeridos.code === '0000') {
									const listado = saltosDeLinea(
										responseDoctosRequeridos?.data?.requirementsList[0]?.requirementText
									);
									if (listado && listado.length > 0) {
										doctosRequerido = responseDoctosRequeridos?.data?.requirementsList[0]?.requirementText.replace(
											'style="color:windowtext"',
											"style={{color:'windowtext'}}",
										) || '';
										doctosRequerido = doctosRequerido.replace(/&bull;/g, '• ');
										doctosRequerido = doctosRequerido.replace(/&bull;• /g, '• ');
									}
									setDoctosRequeridosArray(doctosRequerido);
									setDoctosRequeridos(doctosRequerido);
								}
								hasDateLocal ? setHasDateToCase(true) : setHasDateToCase(false);
							})
						}
					} else {
						setAlert({
							show: true,
							message: response.message || 'Por el momento el servicio no está disponible. Intenta más tarde',
							severity: 'error',
						});
					}
				})
				.catch((err: any) => {
					if (!err.res || !err.res.id || err.res.id !== '2') {
						setAlert({
							show: true,
							message:
								err.description ? err.description : 'Por el momento el servicio no está disponible. Intenta más tarde',
							severity: 'error',
						});
					} else {
						setMakeDate(true)
					}
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (err: any) {
			setAlert({
				show: true,
				message: err.description ? err.description : 'Por el momento el servicio no está disponible. Intenta más tarde',
				severity: 'error',
			});
		}
	};
	const saltosDeLinea = (docs: any) => {
		let str = docs;
		let search = '';
		let replacer;

		if (!str || str.length === 0) {
			return '';
		}

		str = str.replace(replacer, '\n');

		search = '</p>';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '');

		search = '<br />';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '\n');

		search = '&nbsp;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, ' ');

		search = '&gt;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '->');

		search = '&bull;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '');
		str = str.split('<p>');

		str = str.map((a: any) => {
			return a.trim();
		});

		return str;
	};
	const stripHTML = (html: any) => {
		let div = document.createElement('div');
		div.innerHTML = html;
		return div.textContent || div.innerText || '';
	};

	const style = useStyles();
	return (
		<Layout>
			<div>
				<ModalLoading loading={loading} />
				<HeaderList
					key="HeaderCorreccionCURP"
					title="Corrección de RFC"
					list={viewHeader ? initialPage ? ['Ingresa tus datos de contacto.'] : describeText : []}
					listLink={!initialPage ? headerListLink : []}
					linkExterno={true}
				/>
				{page === 0 && (
					<Home setPage={setPage} initialPage={initialPage} setInitialPage={setInitialPage} setParams={setParams} alertGlobal={alert} />
				)}
				{page === 1 && (
					<PageEfirm setPage={setPage} params={Params} setViewHeader={setViewHeader} setViewFooter={setViewFooter} />
				)}
				{page === 2 && (
					<PageNomina setPage={setPage} params={Params} setViewHeader={setViewHeader} setViewFooter={setViewFooter} />
				)}
				{page === 3 && (
					<PageSat setPage={setPage} params={Params} />
				)}
				{page === 4 && (
					<AttachDocument setPage={setPage} setMakeDate={setMakeDate} getStatus={getStatus} setDataStatus={setDataStatus} params={Params} dataStatus={dataStatus} setViewHeader={setViewHeader} />
				)}
				{page === 5 && (
					<Status setPage={setPage} makeDate={makeDate} hasDate={hasDate} dataStatus={dataStatus} setViewHeader={setViewHeader} />
				)}
				{page === 6 && (
					<MakeDate params={Params} dataStatus={dataStatus} citaActual={citaActual} doctosRequeridosArray={doctosRequeridosArray} doctosRequeridosDate={doctosRequeridos} handlecitasActuales={handlecitasActuales} />
				)}
				{page === 7 && (
					<CloseCase setPage={setPage} dataStatus={dataStatus} />
				)}
				{viewFooter && (
					<div className={style.footer}>
						<p>Responsable de la información: <b>Gerencia de Administración del Patrimonio Social y Servicios</b></p>
					</div>
				)}
			</div>
		</Layout>
	);
};
export default RfcCorrectionRegister;
