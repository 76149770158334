import API from '../';

interface IDefaultRequest {
	data: string;
}

class NipInfonatelAPI extends API {
	consultCase(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/nip-infonatel/consulta-nip`,
				method: 'POST',
			})
		);
	}

	sendSMS(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/nip-infonatel/envio-sms`,
				method: 'POST',
			})
		);
	}

	sendEmail(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/nip-infonatel/envio-email`,
				method: 'POST',
			})
		);
	}

	//Validar codigo
	validCode(data: IDefaultRequest): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/nip-infonatel/valida-codigo`,
				method: 'POST',
				data,
			})
		);
	}

	//Activar NIP
	activeNip(data: IDefaultRequest): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/nip-infonatel/activar-nip`,
				method: 'POST',
				data,
			})
		);
	}

	//Cambiar NIP
	changeNip(data: IDefaultRequest): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/nip-infonatel/cambiar-nip`,
				method: 'POST',
				data,
			})
		);
	}

	//Bloquear NIP
	// lockNip(data: IDefaultRequest): Promise<any> {
	lockNip(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/nip-infonatel/bloquear-nip`,
				method: 'POST',
				// data,
			})
		);
	}

	//Cancelar NIP
	// cancellNip(data: IDefaultRequest): Promise<any> {
	cancellNip(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/nip-infonatel/cancelar-nip`,
				method: 'POST',
				// data,
			})
		);
	}

	//Desbloquear NIP
	// unlockNip(data: IDefaultRequest): Promise<any> {
	unlockNip(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/nip-infonatel/desbloquear-nip`,
				method: 'POST',
				// data,
			})
		);
	}

	//Confirmación con email
	// emailConfirmation(servicio: string, descripcion: string): Promise<any> {
	// 	return this.requestWrapper(
	// 		this.request({
	// 			url: `/nip-infonatel/envio-email-confirmation`,
	// 			method: 'POST',
	// 			data: { servicio, descripcion },
	// 		})
	// 	);
	// }

	// Confirmación con email
	emailConfirmation(data: IDefaultRequest): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/nip-infonatel/envio-email-confirmation`,
				method: 'POST',
				data,
			})
		);
	}
}

export const nipInfonatelAPI = new NipInfonatelAPI('');
