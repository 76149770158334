import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	titleNoticeContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	titleNotice: {
		marginTop: 0,
		fontSize: 22,
		color: '#293990',
	},
	subTitleNotice: {
		fontSize: 12,
		color: '#333333',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			textAlign: 'right',
			marginTop: 15,
		},
	},
	checkHeader: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 7,
	},
	checkHeaderImg: {
		width: 17,
		marginRight: 5,
	},
	checkHeaderText: {
		color: '#333',
		fontSize: 15,
		padding: 0,
		margin: 0,
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		paddingBlock: 50,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
	},
	contentDescription: {},
	descriptionTitle: {
		fontWeight: 400,
		fontSize: 15,
	},
	descriptionList: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	descriptionListCustom: {
		display: 'flex',
		alignItems: 'top',
		justifyContent: 'space-between',
	},
	descriptionListTitle: {
		fontSize: 14,
	},
	descriptionListText: {
		fontSize: 14,
	},
	descriptionContainer: {
		maxWidth: 600,
		margin: ' 30px auto',
		textAlign: 'center',
	},
	descriptionContainerDownload: {
		color: '#D1001F',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'right',
		cursor: 'pointer',
		marginBottom: '12px'
	},
	descriptionContainerDownloadText: {
		fontSize: 14,
		marginRight: 5,
	},
	contentDescriptionBottomText: {
		fontSize: 14,
		marginBlock: 5,
	},
	contentDescriptionBottomLink: {
		color: '#D1001F',
		marginLeft: 5,
	},
	containerMin: {
		maxWidth: 900,
		margin: '0 auto',
	},
}));
