import { Box, Divider, InputAdornment } from "@mui/material"
import BaseModal from "../../../../components/Modal";
import TextInput from "../../../../components/TextInput";
import { IProps, useModalTitulacion } from "./hooks/useModalTitulacion";
import { formatMoney2 } from "../../../../utils/validators";
import theme from "../../../../config/theme";
import { ReactComponent as CalculadoraIcon  } from '../../../../assets/img/iconsMIUC/calculadora.svg';

export const ModalGastosTitulacion = (props: IProps) => {
    const { show } = props;
    const {
        onBlur,
        onFocus,
        handleClose,
        handleConfirm,
        handleChange,
        handleCalculate,
        isCalculated,
        inputError,
        valorTitulacion,
        gastosTitulacion,
    } = useModalTitulacion(props);

    return (
        <BaseModal 
            title={
                <label style={{ color: theme.palette.info.main }}>Menos gastos de titulación</label>
            } 
            open={show}
            confirmLabel="Aceptar"
            onConfirm={handleConfirm}
            disableOnConfirm={!isCalculated}
            hideCircularProgress
            cancelButton
            onCancel={handleClose}
            onClose={handleClose}
        >
            <Box>
                <Divider />
                <p>
                    Estos gastos de titulación corresponden únicamente a <b>honorarios notariales 
                    que serán descontados del monto de tu crédito.</b> Para Crédito Infonavit, 
                    se determinan considerando el valor de avalúo.
                </p>
                <Divider />
                <h4>Para conocer los gastos de titulación ingresa el valor de avalúo</h4>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: {
                        xs: 'center',
                        sm: 'start'
                    },
                    gap: { xs: '0', sm: '32px' },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row'
                    },
                    pr: { xs: '0', sm: '64px'}
                }}>
                    <TextInput
                        startAdornment={
                            <InputAdornment position="start">
                                $
                            </InputAdornment>
                        }
                        label="Ingresa una cantidad" 
                        id="gastos-titular"
                        name="gastos-titular"
                        placeholder="0.00"
                        value={valorTitulacion}
                        onChange={handleChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        helperText={inputError}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                            height: '40px',
                            mt: { xs: '0', sm: '32px' },
                            mb: { xs: '0', sm: '4px' },
                            color: theme.palette.error.main,
                            '& :hover': {
                                cursor: 'pointer',
                            }
                        }}
                        onClick={handleCalculate}
                    >
                        <CalculadoraIcon />
                        <label>
                            {
                                isCalculated ? 'Recalcular' : 'Calcular'
                            }
                        </label>
                    </Box>
                </Box>
                <Divider sx={{ margin: '20px 0' }} />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Box sx={{
                        maxWidth: '292px',
                        flexGrow: 1,
                        textAlign: 'center'
                    }}>
                        <h4 style={{ margin: '12px 0'}}>Gastos de titulación</h4>
                        <Box sx={{ 
                            color: theme.palette.info.main,
                            backgroundColor: '#EEF4FA',
                            py: '16px',
                        }}>
                            { formatMoney2(gastosTitulacion) }
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ marginTop: '20px' }} />
            </Box>
        </BaseModal>
    )
}