import { Box, useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../styles';
import CustomButtom from '../../../components/Button';
import candado from '../../../assets/img/candado.png';

interface IPersonalData {
	onContinue: () => void;
}
const NipActivation = (props: IPersonalData) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<>
			<br />
			<br />
			<Box className={classes.VerificacionLogo}>
				<img alt={'candado'} src={candado} />
			</Box>
			<br />
			<Box className={classes.VerificacionCodigoTitle}>
				<h1>Desbloqueaste tu NIP Infonatel</h1>
			</Box>
			<Box style={{ width: '100%', maxWidth: '650px', margin: '0 auto' }} className={classes.VerificacionText}>
				Tu NIP Infonatel se desbloqueo exitosamente,
				continúa disfrutando de los servicios que Mi Cuenta
				Infonavit tiene para ti.
			</Box>
			<br />
			<Box
				display={'flex'}
				justifyContent={'center'}
				flexDirection={matches ? 'column' : 'row'}
				alignItems={matches ? 'center' : ''}
			>
				<Box margin={'20px 10px'} width={matches ? '80%' : 250}>
					<CustomButtom
						label={'Finalizar'}
						onClick={() => navigate('/')}
						variant="solid"
					/>
				</Box>
			</Box>
		</>
	);
};

export default NipActivation;
