import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import fondoHome from '../../assets/img/fondo-home.jpg';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: '100%',
		height: '100%',
		backgroundImage: `url(${fondoHome})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			overflow: 'auto',
		},
		[theme.breakpoints.down('md')]: {
			height: 'auto',
			overflow: 'auto',
		},
	},
	subContainer: {
		width: '100%',
		height: 'auto',
		minHeight: '95vh',
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
		[theme.breakpoints.down('md')]: {
			height: 'auto',
			overflow: 'auto',
			paddingTop: 20,
			paddingBottom: 20,
		},
		[theme.breakpoints.down('sm')]: {
			overflow: 'auto',
			height: 'auto',
			paddingTop: 20,
			paddingBottom: 20,
		},
	},
	mainContainerImgs: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	containerImgs: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '65%',
		// backgroundColor: '#FFFFFF',
	},
	subContainerImgs: {
		display: 'flex',
		justifyContent: 'space-between',
		// backgroundColor: 'red',
	},
	imgLogoInfonavit: {
		width: '100%',
		height: 'auto',
	},
	imgLogoMCIColor: {
		width: '70%',
		height: 'auto',
	},
	divider: {
		width: 1,
		height: 'auto',
		backgroundColor: '#FFFFFF',
	},
	textInformation: {
		color: '#FFFFFF',
		width: '90%',
		height: 'auto',
		fontSize: 24,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			textAlign: 'center',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			width: '100%',
			textAlign: 'center',
		},
	},
	textInformationIcon: {
		position: 'relative',
		paddingLeft: '30px',
		color: '#FFFFFF',
		width: '90%',
		height: 'auto',
		fontSize: 24,
		marginTop: '0px',
		[theme.breakpoints.down('md')]: {
			marginTop: '24px',
			width: '100%',
			textAlign: 'center',
			paddingLeft: '0px',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			width: '100%',
			textAlign: 'center',
			paddingLeft: '0px',
		},
	},
	IconInfo: {
		position: 'absolute',
		top: '0px',
		left: '0px',
		[theme.breakpoints.down('md')]: {
			left: 'calc( 50% - 12px)',
			top: '-24px',
		},
	},
	containerButtons: {
		width: '90%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			flexDirection: 'column',
			display: 'none',
		},
		// backgroundColor: 'green',
	},
	containerButtonsSmall: {
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: 10,
			paddingLeft: 25,
			paddingRight: 25,
			width: '100%',
			flexDirection: 'column',
		},
		// backgroundColor: 'green',
	},
	buttonMainSection: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#FFFFFF',
		borderRadius: 50,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: 'white',
		minHeight: 40,
		height: 'auto',
		padding: 7,
		width: 220,
		backgroundColor: 'transparent',
		'&:hover': {
			borderColor: theme.palette.primary.main,
			backgroundColor: theme.palette.primary.main,
			transition: '0.5s',
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 10,
			width: '100%',
		},
	},
	imgIconAttention: {
		width: 22,
		height: 'auto',
		marginRight: 10,
	},
	mainContainerForm: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	mainContainerFormCustom: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'center',
		padding: '50px 0'
	},
	containerForm: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 45,
		height: 'auto',
		width: 350,
		[theme.breakpoints.down('sm')]: {
			padding: 20,
			width: 300,
		},
		[theme.breakpoints.down('md')]: {
			marginTop: 20,
		},
	},
	containerInput: {
		display: 'flex',
		alignItems: 'flex-start !important',
		margin: '0px !important',
		width: '100%',
	},
	containerCheckbox: {
		width: '100%',
	},
	textField: {
		width: '100%',
	},
	containerConsultNSS: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 20,
		marginBottom: 20,
	},
	labelConsultNSS: {
		color: theme.palette.primary.main,
	},
	labelForgotPass: {
		color: '#7E8094',
		textDecoration: 'none',
	},
	containerButtonsForm: {
		width: '100%',
	},
	buttonLogIn: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#FFFFFF',
		borderRadius: 50,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: 'white',
		minHeight: 45,
		height: 'auto',
		width: '100%',
		marginBottom: 20,
		fontWeight: 'bold',
		fontSize: 14,
		backgroundColor: theme.palette.primary.main,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			transition: '0.5s',
		},
		'&:active': {
			backgroundColor: theme.palette.primary.main,
			transition: '0.5s',
		},
	},
	buttonCreateAccount: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.primary.main,
		borderRadius: 50,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.primary.main,
		minHeight: 45,
		height: 'auto',
		width: '100%',
		backgroundColor: '#FFFFFF',
		cursor: 'pointer',
		fontWeight: 'bold',
		fontSize: 14,
		'&:active': {
			backgroundColor: '#F0F0F0',
			transition: '0.5s',
		},
	},
	dividerButtons: {
		width: '100%',
		backgroundColor: '#CBCBCB',
		height: 1,
		marginBottom: 30,
		marginTop: 30,
	},
	containerCaptcha: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		marginTop: 20,
		marginBottom: 20,
	},
	containerModalInfo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	containerBtnAcceptModal: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	titleModalAlert: {
		textAlign: 'center',
		fontSize: 16,
		'& > a': {
			color: 'red',
			fontStyle: 'outline',
		},
	},
	labelContainerInput: {
		margin: 0,
		color: '#7E8094',
		fontSize: 16,
		'& > label': {
			fontSize: 13,
		},
	},
	nameUser: {
		margin: '20px auto 0',
		textAlign: 'center',
		color: '#7E8094',
		fontSize: 16,
	},
	containerModalNoAccount: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		gap: '20px',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
			flexDirection: 'column-reverse',
			gap: '0px',
			marginTop: '20px',
		},
	},
}));
