// Dependencies
import { useState } from 'react';

// Material Components
import { Box, useMediaQuery, Paper, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// Components
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';

// Assets
import { useStyles } from './styles';
import { IAlert } from '../../interfaces/alert';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { ApiRfcCorrectionRegister } from '../../api/modules/rfcCorrectionRegister';
import useCustomScript from '../../hooks/useCustomScript';

declare global {
	interface Window {
		validarFiel: any;
	}
}

interface Props {
	setPage: (data: number) => void;
	params: {
		nss: string;
		curp: string;
		rfc: string;
		name: string;
		phone: string;
		email: string;
		rfcCrm: string
	};
	setViewHeader: (data: boolean) => void;
	setViewFooter: (data: boolean) => void;
}

const messageErrorName = 'Tu firma electrónica avanzada tiene asociado un nombre diferente al registrado en el Infonavit. Revisa que los archivos ingresados sean los correctos o si lo requieres podrás solicitar una cita para realizar el trámite en cualquiera de nuestros Centros de Servicio Infonavit.'
const messageErrorCurp = 'Tu firma electrónica avanzada tiene asociado un CURP diferente al registrado en el Infonavit. Revisa que los archivos ingresados sean los correctos o si lo requieres podrás solicitar una cita para realizar el trámite en cualquiera de nuestros Centros de Servicio Infonavit.'

const PageEfirm = ({ setPage, params, setViewHeader, setViewFooter }: Props) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const style = useStyles();
	const [loading, setLoading] = useState(false);
	const [files, setFiles] = useState({
		file1: {
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
		},
		file2: {
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
		},
	});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [inputValue, setInputValue] = useState('');
	const [cerB, setcerB64] = useState('');
	const [cer, setCer] = useState('');
	const [key, setKey] = useState('');
	const [dataStatus, setDataStatus] = useState({
		noCaso: '',
		fecha: '',
		descripcionServicio: '',
		fechaStatus: '',
		status: {
			description: ''
		}
	});
	const [proccessComplete, setproccessComplete] = useState(false)
	const validarFiel = window.validarFiel;

	useCustomScript('/jquery/jquery.min.js');
	useCustomScript('/efirma/firma-documento.js');
	useCustomScript('/efirma/fiel-validacion.ofu.min.js');

	// var serverIP = 'https://serviciosweb.infonavit.org.mx:8993'; //QA
	const serverIP = process.env.REACT_APP_URL_VALIDA_FIEL; //prod

	const handleDeleteFile = (index: string) => {
		setAlert({
			show: false,
			message: '',
			severity: 'error',
		});
		setFiles({
			...files,
			[index]: {
				name: 'No se eligió ningún archivo',
				data: null,
			},
		});
	};
	const handleFileCer = (e: any) => {
		if (e.target.files.length > 0) {
			let readerFile = new FileReader();
			readerFile.readAsDataURL(e.target.files[0]);
			readerFile.onload = function (event) {
				setcerB64(
					(event.target?.result as string).replace(
						/^data:(.*;base64,)?/,
						''
					)
				);
			};
		}
		if (e.target.files.length > 0) {
			let readerFile = new FileReader();
			readerFile.onload = function (event) {
				setCer(event?.target?.result as string);
			};
			readerFile.readAsArrayBuffer(e.target.files[0]);
			setFiles({
				...files,
				[e.target.name]: {
					name: e.target.files[0].name,
					data: e.target.files[0],
					type: e.target.files[0].type,
				},
			});
			e.target.value = null;
			return cer;
		}
	};
	const handleFileKey = (e: any) => {
		if (e.target.files.length > 0) {
			let readerFile = new FileReader();
			readerFile.onload = function (event) {
				setKey((event?.target?.result as string) || '');
			};
			readerFile.readAsArrayBuffer(e.target.files[0]);
			setFiles({
				...files,
				[e.target.name]: {
					name: e.target.files[0].name,
					data: e.target.files[0],
				},
			});
			e.target.value = null;
		}
	};
	const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setInputValue(value);
	};
	const handleSubmitFile = (i: number) => {
		if (i >= 5) {
			setLoading(false);
			setAlert({
				show: true,
				message: 'Por el momento el servicio no está disponible. Intenta más tarde',
				severity: 'error',
			});
			return;
		}
		setLoading(true);
		let inputs = {
			keyBinary: key,
			cerBinary: cer,
			cerB64: cerB,
			password: inputValue,
			serverIP: serverIP,
			pdfB64: '',
			pdfContentType: 'application/pdf',
		};
		if (typeof validarFiel === "function") {
			validarFiel(inputs, (error: any, response: any) => {
				if (error === null) {
					if (response.valido === true) {
						if (response.curp === params.curp) {
							if (response.nombre === params.name) {
								handleOnSubmit(response.rfc);
							} else {
								setLoading(false);
								setAlert({
									show: true,
									message: messageErrorName,
									severity: 'error',
								});
							}
						} else {
							setLoading(false);
							setAlert({
								show: true,
								message: messageErrorCurp,
								severity: 'error',
							});
						}
					} else {
						setLoading(false);
						setAlert({
							show: true,
							message: response.mensajeValidacion,
							severity: 'error',
						});
					}
				} else {
					if (error === 'No se proporcionó un pdf a firmar') {
						handleSubmitFile(i + 1);
					} else {
						setLoading(false);
						setAlert({
							show: true,
							message: error,
							severity: 'error',
						});
					}
				}
			});
		} else {
			setLoading(false);
			setAlert({
				show: true,
				message: 'Por el momento el servicio no está disponible. Intenta más tarde',
				severity: 'error',
			});
		}
	};
	const handleOnSubmit = async (rfc: string) => {
		const keyRes = await keyAPI.get<'', { k: string }>();
		const key = keyRes['k'].toString();
		setLoading(true);
		try {
			const rfc_base64 = await encrypt(rfc.toString() || '', key);
			const curp_base64 = await encrypt(params?.curp.toString() || '', key);
			const nss_base64 = await encrypt(params?.nss.toString() || '', key);
			const email_base64 = await encrypt(params?.email.toString() || '', key);
			const given_name_base64 = await encrypt(params?.name.toString() || '', key);
			const telefonoCelular_base64 = await encrypt(params?.phone.toString() || '', key);

			ApiRfcCorrectionRegister.firmaElectronica
				.post<
					{
						nss: string;
						email: string;
						given_name: string;
						rfc: string;
						curp: string;
						telefonoMovil: string;
					},
					any
				>('', {
					nss: nss_base64,
					email: email_base64,
					given_name: given_name_base64,
					rfc: rfc_base64,
					curp: curp_base64,
					telefonoMovil: telefonoCelular_base64
				})
				.then((res: any) => {
					getStatus()
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false)
		}
	};
	const getStatus = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const rfc_base64 = await encrypt(params?.rfc.toString() || '', key);
			const nss_base64 = await encrypt(params?.nss.toString() || '', key);
			const curp_base64 = await encrypt(params?.curp.toString() || '', key);
			ApiRfcCorrectionRegister.getStatus
				.post<
					{
						nss: string;
						rfc: string;
						curp: string;
					},
					any
				>('', {
					nss: nss_base64,
					rfc: rfc_base64,
					curp: curp_base64
				})
				.then((response: any) => {
					setDataStatus(response.result)
					setViewFooter(true)
					setViewHeader(false)
					setproccessComplete(true)
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		}
	};

	return (
		<Paper sx={{
			pt: proccessComplete ? 5 : 0,
			pb: proccessComplete ? 5 : 0,
			pr: proccessComplete ? matches ? 5 : 15 : 0,
			pl: proccessComplete ? matches ? 5 : 15 : 0
		}}>
			{!proccessComplete && (<Box className={style.container}>
				<ModalLoading loading={loading} />
				<div
					id="gotElecSig"
					style={{ display: 'inline' }}
				>

					<div
						style={{
							display: 'flex',
							alignItems: matches ? 'normal' : 'center',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							paddingLeft: matches ? 25 : 20,
							paddingRight: 20,
							paddingBottom: matches ? 20 : 10,
							paddingTop: 20,

							flexDirection:
								files.file1.data && matches
									? 'column'
									: 'inherit',
						}}
					>
						<h3
							style={{
								color: 'black',
								width: matches ? '100%' : 500,
								fontSize: matches ? 16 : 18,
								order: 1,
								margin: 0,
								textAlign: matches ? 'center' : 'left',
							}}
						>
							Certificado (.cer)
							<label style={{ color: 'red' }}>*</label>
						</h3>
						<p
							style={{
								flexGrow: 1,
								width: matches ? '100%' : 0,
								order: matches && !files.file1.data ? 3 : 2,
								margin: 0,
								opacity: 0.5,
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								textAlign: matches ? 'center' : 'left',
							}}
						>
							{files.file1.name}
						</p>
						<div
							style={{
								width: matches ? '100%' : 300,
								textDecoration: 'none',
								color: '#D1001F',
								paddingTop: 0,
								fontSize: 16,
								order: 3,
							}}
						>
							<label
								onClick={() => handleDeleteFile('file1')}
								style={{
									display: files.file1.data ? 'flex' : 'none',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
									cursor: 'pointer',
									justifyContent: matches ? 'center' : 'right',
								}}
							>
								<Close
									style={{ fontSize: 20, marginRight: 2 }}
								/>
								Eliminar archivo
							</label>
							<label
								style={{
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
									justifyContent: matches ? 'center' : 'right',
								}}
							>
								<label
									style={{
										display: files.file1.data
											? 'none'
											: 'flex',
										cursor: 'pointer',
										paddingBottom: matches ? 25 : 0,
									}}
								>
									<input
										type="file"
										accept=".cer"
										name="file1"
										onChange={handleFileCer}
										className={style.chooseFileOK}
									/>
									Elegir archivo
								</label>
							</label>
						</div>
					</div>
					<hr className={style.hr} />

					<div
						style={{
							display: 'flex',
							alignItems: matches ? 'normal' : 'center',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							paddingLeft: matches ? 25 : 20,
							paddingRight: 20,
							paddingBottom: matches ? 20 : 10,
							paddingTop: 20,

							flexDirection:
								files.file2.data && matches
									? 'column'
									: 'inherit',
						}}
					>
						<h3
							style={{
								color: 'black',
								width: matches ? '100%' : 500,
								fontSize: matches ? 16 : 18,
								order: 1,
								margin: 0,
								textAlign: matches ? 'center' : 'left',
							}}
						>
							Clave privada (.key)
							<label style={{ color: 'red' }}>*</label>
						</h3>

						<p
							style={{
								flexGrow: 1,
								width: matches ? '100%' : 0,
								order: matches && !files.file1.data ? 3 : 2,
								margin: 0,
								opacity: 0.5,
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								textAlign: matches ? 'center' : 'left',
							}}
						>
							{files.file2.name}
						</p>
						<div
							style={{
								width: matches ? '100%' : 300,
								textDecoration: 'none',
								color: '#D1001F',
								paddingTop: 0,
								fontSize: 16,
								order: 3,
							}}
						>
							<label
								onClick={() => handleDeleteFile('file2')}
								style={{
									display: files.file2.data ? 'flex' : 'none',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
									cursor: 'pointer',
									justifyContent: matches ? 'center' : 'right',
								}}
							>
								<Close
									style={{ fontSize: 20, marginRight: 2 }}
								/>
								Eliminar archivo
							</label>
							<label
								style={{
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
									justifyContent: matches ? 'center' : 'right',
								}}
							>
								<label
									style={{
										display: files.file2.data
											? 'none'
											: 'flex',
										cursor: 'pointer',
										paddingBottom: matches ? 25 : 0,
									}}
								>
									<input
										type="file"
										accept=".key"
										name="file2"
										onChange={handleFileKey}
										className={style.chooseFileOK}
									/>
									Elegir archivo
								</label>
							</label>
						</div>
					</div>
					<hr className={style.hr} />

					<div style={{
						paddingLeft: matches ? 25 : 20,
						paddingRight: 20,
						paddingBottom: matches ? 70 : 10,
						paddingTop: 20,
					}}>
						<h3 style={{
							color: 'black',
							width: matches ? '100%' : 280,
							fontSize: matches ? 16 : 18,
							order: 1,
							margin: 0,
							textAlign: matches ? 'center' : 'left',
							marginBottom: matches ? 20 : 0,
						}}
						>
							Contraseña de clave privada
							<label style={{ color: 'red' }}>*</label>
						</h3>

						<div className={style.inputContainerFirmElec}>
							<TextInput
								data-testid="privPass"
								id="privPass"
								name="privPass"
								type="password"
								label=""
								value={inputValue}
								onChange={handleTextInput}
							/>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								paddingLeft: 50,
								paddingRight: 50,
								margin: 0,
								paddingBottom: 10,
							}}
						>
							<CustomAlert
								show={alert.show}
								severity={alert.severity}
								message={alert.message}
							/>
						</div>

						<div className={style.botonContainerFirmElec}>
							<CustomButton
								data-testid="gotElecCancelar"
								label="Cancelar"
								onClick={() => {
									setPage(0);
								}}
								variant="outlined"
								styles={{
									width: matches ? 340 : 250,
									height: 40,
									marginBottom: 20,
									marginRight: matches ? 0 : 25,
								}}
							/>
							<CustomButton
								data-testid="gotElecContinuar"
								label="Continuar"
								onClick={() => {
									handleSubmitFile(1)
								}}
								disabled={
									files.file1.data &&
										inputValue &&
										files.file2.data
										? false
										: true
								}
								variant="solid"
								styles={{
									width: matches ? 340 : 250,
									height: 40,
									marginBottom: 20,
								}}
							/>
						</div>

						<hr className={style.hr} style={{}} />
						<h3
							className={style.h4}
							style={{
								paddingBottom: 10,
								width: '100%',
								marginTop: 10,
								marginLeft: 15,
							}}
						>
							*Datos obligatorios
						</h3>
					</div>

				</div>
			</Box>
			)}
			{proccessComplete && (
				<div>
					<h2 style={{ color: '#293990', textAlign: 'center', paddingBottom: 20 }}>
						Gracias por utilizar los servicios digitales del Infonavit
					</h2>
					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matchesSm ? 'center' : 'initial',
								}}
							>
								Número de caso
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matchesSm ? 'center' : 'right' }}
						>
							<label>{dataStatus?.noCaso}</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />
					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matchesSm ? 'center' : 'initial',
								}}
							>
								Fecha de solicitud
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matchesSm ? 'center' : 'right' }}
						>
							<label>{dataStatus.fecha}</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matchesSm ? 'center' : 'initial',
								}}
							>
								Servicio
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matchesSm ? 'center' : 'right' }}
						>
							<label>{dataStatus.descripcionServicio}</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matchesSm ? 'center' : 'initial',
								}}
							>
								Estatus
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matches ? 'center' : 'right' }}
						>
							{dataStatus.status?.description}
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matchesSm ? 'center' : 'initial',
								}}
							>
								Fecha de estatus
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matchesSm ? 'center' : 'right' }}
						>
							<label>{dataStatus.fechaStatus}</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

					<div
						style={{
							maxWidth: 800,
							margin: 'auto',
							marginTop: 30,
						}}
					>
						<div className={style.message}>
							<span>
								Cuando tus datos sean actualizados se te notificará a través de correo electrónico y podrás continuar con la creación de tu Cuenta Infonavit.
							</span>
						</div>
						<div
							style={{
								maxWidth: 260,
								display: 'flex',
								margin: 'auto',
								marginTop: 30,
							}}
						>
							<CustomButton
								variant="solid"
								label={'Finalizar'}
								onClick={() => {
									localStorage.removeItem('dataUserRegister');
									navigate('/registro');
								}}
							/>
						</div>
					</div>
				</div>
			)}
		</Paper>
	);
};
export default PageEfirm;
