import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { tallerSaberMasAPI } from '../../../../../api/modules/tallerSaberMas';
import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { IRequestTaller, IWorkshopItem, WORKSHOP_VIEWS } from '../utils';
import { INTERNAL_MESSAGES } from '../../../../../config/messageCatalog';
import { useContextTaller } from '../context';
import {
	ActualizarElementoMCIResponseModel,
	Elemento,
	ObtenerCertificadoMCIResponseModel,
	ObtenerElementosMCIResponseModel,
} from '../../../../../interfaces/tallerSaberMas';
import theme from '../../../../../config/theme';
import { useMediaQuery } from '@mui/material';

const useTallerMas = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const context = useContextTaller();

	const getElementsAll = async (): Promise<IRequestTaller> => {
		try {
			const destino9 = await encodeAllJSONData(
				{
					data: JSON.stringify({
						destino: WORKSHOP_VIEWS.FINANTIAL_EDUCATION.destino,
					}),
				},
				user?.k || ''
			);
			const destino10 = await encodeAllJSONData(
				{
					data: JSON.stringify({
						destino: WORKSHOP_VIEWS.I_WANT_A_CREDIT.destino,
					}),
				},
				user?.k || ''
			);
			const destino11 = await encodeAllJSONData(
				{
					data: JSON.stringify({
						destino: WORKSHOP_VIEWS.I_HAVE_A_CREDIT.destino,
					}),
				},
				user?.k || ''
			);
			const destino12 = await encodeAllJSONData(
				{
					data: JSON.stringify({
						destino: WORKSHOP_VIEWS.MY_SUB_ACCOUNT.destino,
					}),
				},
				user?.k || ''
			);
			const { data: Data9 } = await tallerSaberMasAPI.getElements(
				destino9
			);
			const { data: Data10 } = await tallerSaberMasAPI.getElements(
				destino10
			);
			const { data: Data11 } = await tallerSaberMasAPI.getElements(
				destino11
			);
			const { data: Data12 } = await tallerSaberMasAPI.getElements(
				destino12
			);
			return {
				[WORKSHOP_VIEWS.FINANTIAL_EDUCATION.key]: {
					...Data9,
				},
				[WORKSHOP_VIEWS.I_WANT_A_CREDIT.key]: {
					...Data10,
				},
				[WORKSHOP_VIEWS.I_HAVE_A_CREDIT.key]: {
					...Data11,
				},
				[WORKSHOP_VIEWS.MY_SUB_ACCOUNT.key]: {
					...Data12,
				},
			};
		} catch (error) {
			throw Error(INTERNAL_MESSAGES.ERROR_MESSAGE);
		}
	};

	const getElementViews = (): Number => {
		const elements =
			context.elementos[WORKSHOP_VIEWS.FINANTIAL_EDUCATION.key];
		if (elements && elements?.secciones.length > 0) {
			const returnViews = elements.secciones[0].elementos.reduce(
				(arr, curr) => {
					return curr.estatus === 1 ? arr + 1 : arr;
				},
				0
			);
			return returnViews;
		}
		return 0;
	};

	const getElements = async (
		destino: string
	): Promise<ObtenerElementosMCIResponseModel> => {
		try {
			const dataEncript = await encodeAllJSONData(
				{ data: JSON.stringify({ destino }) },
				user?.k || ''
			);
			const { data, code, message } = await tallerSaberMasAPI.getElements(
				dataEncript
			);
			if (code === '0000') {
				return data;
			}
			throw Error(message || INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (error) {
			throw Error(INTERNAL_MESSAGES.ERROR_MESSAGE);
		}
	};

	const updateElement = async (
		elemento: Elemento,
		activeView: IWorkshopItem
	): Promise<ActualizarElementoMCIResponseModel> => {
		try {
			const data = {
				destino: activeView.destino,
				elementoVisto: elemento.idElemento.toString(),
				idCurso: '2',
			};
			const dataEncript = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const response = await tallerSaberMasAPI.updateElement(dataEncript);
			if (response.code === '0000') {
				return response.data;
			}
			throw Error(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (error) {
			throw Error(INTERNAL_MESSAGES.ERROR_MESSAGE);
		}
	};

	const getCertificate =
		async (): Promise<ObtenerCertificadoMCIResponseModel> => {
			try {
				const data = {
					nombreCompleto: `${user?.nombres} ${user?.apPaterno} ${user?.apMaterno}`,
				};
				const dataEncript = await encodeAllJSONData(
					{ data: JSON.stringify(data) },
					user?.k || ''
				);
				const response = await tallerSaberMasAPI.getCertification(
					dataEncript
				);
				if (response.code === '0000') {
					return response.data;
				}
				throw Error(
					response?.message || INTERNAL_MESSAGES.ERROR_MESSAGE
				);
			} catch (error) {
				throw Error(INTERNAL_MESSAGES.ERROR_MESSAGE);
			}
		};

	const handleChangeCard = (workPosition: IWorkshopItem) => {
		const { setActiveView, elementos, setFinishLearn } = context;
		const sections = elementos[workPosition.key].secciones;
		setActiveView(workPosition);
		if (workPosition.key !== WORKSHOP_VIEWS.FINANTIAL_EDUCATION.key) {
			setFinishLearn(false);
		}
		if (
			workPosition.key === WORKSHOP_VIEWS.FINANTIAL_EDUCATION.key &&
			sections[0].elementos.length === getElementViews()
		) {
			setFinishLearn(true);
		}
	};

	return {
		...context,
		matches,
		getElementsAll,
		getElements,
		getElementViews,
		updateElement,
		handleChangeCard,
		getCertificate,
	};
};

export default useTallerMas;
