export const states = [
	{ name: 'Aguascalientes', code: '001' },
	{ name: 'Baja California', code: '002' },
	{ name: 'Baja California Sur', code: '003' },
	{ name: 'Campeche', code: '004' },
	{ name: 'Coahuila', code: '005' },
	{ name: 'Colima', code: '006' },
	{ name: 'Chiapas', code: '007' },
	{ name: 'Chihuahua', code: '008' },
	{ name: 'Ciudad de México', code: '009' },
	{ name: 'Durango', code: '010' },
	{ name: 'Guanajuato', code: '011' },
	{ name: 'Guerrero', code: '012' },
	{ name: 'Hidalgo', code: '013' },
	{ name: 'Jalisco', code: '014' },
	{ name: 'Estado de México', code: '015' },
	{ name: 'Michoacán', code: '016' },
	{ name: 'Morelos', code: '017' },
	{ name: 'Nayarit', code: '018' },
	{ name: 'Nuevo León', code: '019' },
	{ name: 'Oaxaca', code: '020' },
	{ name: 'Puebla', code: '021' },
	{ name: 'Querétaro', code: '022' },
	{ name: 'Quintana Roo', code: '023' },
	{ name: 'San Luis Potosí', code: '024' },
	{ name: 'Sinaloa', code: '025' },
	{ name: 'Sonora', code: '026' },
	{ name: 'Tabasco', code: '027' },
	{ name: 'Tamaulipas', code: '028' },
	{ name: 'Tlaxcala', code: '029' },
	{ name: 'Veracruz', code: '030' },
	{ name: 'Yucatán', code: '031' },
	{ name: 'Zacatecas', code: '032' },
];
