import { CheckCircleOutlined } from '@mui/icons-material';
import SimpleModal from '../../../../components/SimpleModal';
import { chooseLivingTexts } from '../../MIUC/utils';
import theme from '../../../../config/theme';

const ChooseLivingModal = ({ open, setOpen }: any) => {
	return (
		<SimpleModal
			contentDividers
			title={
				<h4 style={{ color: theme.palette.info.main, margin: 0 }}>
					Elegir vivienda
				</h4>
			}
			open={open}
			onClose={() => setOpen(!open)}
		>
			<>
				<p>
					Al elegir la vivienda considera que la ubicación del lugar
					es un aspecto muy importante que debes atender porque no se
					puede modificar. Vivir cerca del trabajo, escuelas, clínicas
					de salud, hospitales, tiendas, mercados y de la familia, son
					factores que pueden hacer la diferencia entre sólo tener un
					lugar para alojarte o disfrutar de tu hogar.
				</p>
				{chooseLivingTexts.map((text: string) => (
					<p key={text} style={{ display: 'flex' }}>
						<CheckCircleOutlined
							color="info"
							style={{ marginRight: 5, paddingTop: 4 }}
						/>
						<label style={{ marginTop: 2.45 }}>{text}</label>
					</p>
				))}
				<p>
					Reflexiona antes de decidir, no compres a ciegas, no hagas
					una sola visita a la casa que te interesa y date una vuelta
					por la zona en diferentes horas y días para que observes la
					dinámica del entorno.
					<br />
					<br />
					Evalúa si es el momento adecuado y revisa tus finanzas, no
					compres si en vez de beneficios te traerá dificultades
					económicas.
					<br />
					<br />
					No olvides revisar los aspectos legales, asegúrate que esté
					al corriente en las cuotas de mantenimiento, predial y agua,
					así como de gas y luz.
				</p>
			</>
		</SimpleModal>
	);
};

export default ChooseLivingModal;
