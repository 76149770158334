import { makeStyles, createStyles } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';
import { Button, TableCell, TableRow } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

export const UseStyle = makeStyles((theme: Theme) =>
	createStyles({
		PayStateUniteItem: {
			display: 'flex',
			fontSize: 14,
			color: '#333333',
			margin: '5px 0px',
			maxWidth: 800,
		},
		PayBaksItem: {
			display: 'grid',
			gridTemplateColumns: '30% 70%',
			borderTop: '1px solid rgba(0,0,0,0.1)',
			padding: 11,
			fontSize: 14,
			color: '#333333',
			'& label': {
				padding: '0x 10px',
				color: '#333333',
			},
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns: '1fr',
			},
		},
		PayBaksItemFirts: {
			display: 'grid',
			gridTemplateColumns: '30% 70%',
			padding: 11,
			paddingTop: 5,
			fontSize: 14,
			color: '#333333',
			'& label': {
				padding: '0x 10px',
				color: '#333333',
			},
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns: '1fr',
			},
		},
		ServicesTradeItem: {
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 1fr)',
			borderTop: '1px solid rgba(0,0,0,0.1)',
			padding: 11,
			fontSize: 14,
			color: '#333333',
			'& label': {
				padding: '0x 10px',
				color: '#333333',
			},
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns: '1fr',
			},
		},
		ServicesTradeItemFirst: {
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 1fr)',
			padding: 11,
			fontSize: 14,
			color: '#333333',
			'& label': {
				padding: '0x 10px',
				color: '#333333',
			},
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns: '1fr',
			},
		},
		modalTab: {
			background: '#F2F2F2',
			fontSize: 13,
			fontWeight: 500,
			padding: 10,
			maxWidth: 170,
			height: 55,
			textAlign: 'center',
			margin: 'auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			'& label': {
				color: '#7F7F7F',
				userSelect: 'none',
				cursor: 'pointer',
			},
			[theme.breakpoints.down('md')]: {
				maxWidth: 'none',
			},
		},
		modalTabActive: {
			background: '#D1001F',
			fontSize: 13,
			fontWeight: 500,
			padding: 10,
			maxWidth: 170,
			height: 55,
			textAlign: 'center',
			margin: 'auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			'& label': {
				color: '#FFFFFF',
				userSelect: 'none',
				cursor: 'pointer',
			},
			[theme.breakpoints.down('md')]: {
				maxWidth: 'none',
			},
		},
	})
);

export const RoundBtn = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	borderRadius: 40,
	width: '100%',
	margin: 10,
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#D1001F',
		color: theme.palette.common.white,
		padding: '25px 50px',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		padding: '25px 50px',
	},
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));
