/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Grid, Box, Paper } from '@mui/material';

import { useNavigation } from '../../hooks/useNavigation';
import { MIUC_PROCESS_VIEWS } from '../../constants/process';

import Home from './pages/Home';
import Questionnaire from './pages/Questionnaire';
import Products from './pages/Product';
import FlowCard from '../../components/FlowCard';
import Layout from '../../../../components/Layout';
import ViewPoints from '../../components/ViewPoints';
import { BuroForm } from '../../components/BuroForm/buroForm';
import { SelectionResume } from '../../components/SelectionResume/selectionResume';
import FollowSteps from '../../components/FollowSteps';
import ResumeCard from '../../components/ResumeCard';

import { useGeneralStyles } from '../styles/general.styles';

const MejoraSi = () => {
	const generalStyle = useGeneralStyles();
	const [activeQuestMejora, setActiveQuestMejora] = useState(false);
	const [product, setProduct] = useState<any>();
	const [cuestionarioCompleto, setCuestionarioCompleto] = useState(false);
	const { calcProgress, currentView, dataProcessController, saveData, nextStep, } = useNavigation({
		pages: [
			MIUC_PROCESS_VIEWS.CREDIT_OPTIONS,
			MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO,
			MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT,
			MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
			MIUC_PROCESS_VIEWS.NEXT_STEPS,
		],
	});
	useEffect(() => {
		// let credit = '';
		// saveData({
		// 	processInfo: {
		// 		credit,
		// 		esMejoraDirecta: true,
		// 	},
		// });
		// nextStep();
	}, []);

	return (
		<Layout>
			<Box className={generalStyle.container}>
				<Grid container columns={12} spacing={2}>
					<Grid item xs={12} md={!activeQuestMejora ? 8 : 12}>
						{currentView === MIUC_PROCESS_VIEWS.CREDIT_OPTIONS ? (
							<Home />
						) : currentView === MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO ? (
							<Paper>
								<BuroForm onlyAuthorization />
							</Paper>
						) : currentView === MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT && !cuestionarioCompleto ? (
							<Questionnaire setActiveQuestMejora={setActiveQuestMejora} setCuestionarioCompleto={setCuestionarioCompleto} />
						) : currentView === MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT && cuestionarioCompleto ? (
							<Products />
						) : currentView === MIUC_PROCESS_VIEWS.SUMMARY_REQUEST ? (
							<Paper>
								<SelectionResume hideAmortization />
							</Paper>
						) : currentView === MIUC_PROCESS_VIEWS.NEXT_STEPS ? (
							<FollowSteps guideLink={require('../../../../assets/files/MejoraDirectaFiles2023/Guia_pasos_Mejora_Directa.pdf')}/>
						) : (
							<></>
						)}
					</Grid>
					{!activeQuestMejora && (
						<Grid item xs={12} md={4}>
							{currentView !== MIUC_PROCESS_VIEWS.SUMMARY_REQUEST && currentView !== MIUC_PROCESS_VIEWS.NEXT_STEPS && (
								<Box sx={{ textAlign: 'center' }} mb={2}>
									<ViewPoints showPoints={false} />
								</Box>
							)}
							{currentView === MIUC_PROCESS_VIEWS.NEXT_STEPS && (
								<Box sx={{ textAlign: 'center' }} mb={2}>
									<ResumeCard />
								</Box>
							)}
							<Box>
								<FlowCard
									title="Tu selección"
									flow={
										dataProcessController.processInfo
											.selection
									}
									progress={calcProgress()}
								/>
							</Box>
						</Grid>
					)}
				</Grid>
			</Box>
		</Layout>
	);
};

export default MejoraSi;
