export const getStyleSection = (
	selected: boolean,
	item: any
): string | undefined => {
	let returnClass: string = '';

	if (selected) {
		returnClass += 'active ';
	}

	if (item.textShadow) {
		returnClass += 'textShadow ';
	}

	if (item.textPrimary) {
		returnClass += 'textPrimary ';
	}

	return returnClass ? returnClass.trim() : undefined;
};

export const stylesRow: React.CSSProperties = {
	display: 'grid',
	gridTemplateColumns: '49% 2% 49%'
};
