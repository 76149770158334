/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { Radio } from '@mui/material';
import { useStyles } from '../styles/styles';
import HeaderStep from './HeaderStep';
import TextInput from '../../../../../components/TextInput';
import { isNumber } from '../../../../../utils/expressions';

const QuestStep9 = ({
	quest,
	nextStep,
	handleChange,
	afterQuestionarySteps,
	alert,
}: any) => {
	const classes = useStyles();
	const [quests, setQuests] = useState([] as any);
	const [valor, setValor] = useState('');
	const [valor2, setValor2] = useState('');
	const [valor3, setValor3] = useState('');
	const [value513, setValue513] = useState([] as any);

	useEffect(() => {
		drawQuestions();
	}, [quest, valor, valor2, valor3, value513]);

	useEffect(() => {
		if (valor) {
			let respuesta = `${valor} años`;
			handleChange(respuesta, '5.5');
		} else {
			handleChange('No sé', '5.5');
		}
	}, [valor]);

	useEffect(() => {
		let respuesta = `${valor2}`;
		handleChange(respuesta, '5.8');
	}, [valor2]);

	useEffect(() => {
		let respuesta = `${valor3}`;
		handleChange(respuesta, '5.9');
	}, [valor3]);

	const setQuestion513 = (value: any, num: any) => {
		let newArray = value513
		let idx = newArray.indexOf(value);
		//console.log(idx)
		if (idx === -1) {
			newArray.push(value)
		} else {
			newArray = newArray.filter((item: any) => item !== value)
		}
		setValue513(newArray)
		drawQuestions();
		let resp = ''
		for (let i = 0; i < newArray.length; i++) {
			resp = resp + (newArray[i] + ' ')
		}
		handleChange(resp, num)
	}

	const drawQuestions = () => {
		const questions: any = [];
		quest.map((item: any, index: any) => {
			const newItem = {
				title: `${item.numero.split('.')[1]}.- ${item.pregunta}`,
				body: (
					<>
						{item.opciones.length > 0 &&
							item.numero !== '5.5' && item.numero !== '5.13' &&
							item.opciones.map((opcion: any) => (
								<div
									className={classes.itemsFlexCenter}
									key={opcion + item.pregunta}
								>
									<Radio
										checked={
											item.respuesta ===
											opcion.split(')')[0]
										}
										onChange={(e: any) => {
											let value =
												e.target.value.split(')')[0];
											handleChange(value, item.numero);
										}}
										name={opcion.split(')')[0]}
										value={opcion.split(')')[0]}
										disabled={((index === 6) && (quest[5].respuesta === '2')) ||
											((index === 10) && (quest[9].respuesta === '3' || quest[9].respuesta === '4' || quest[9].respuesta === '5' || quest[9].respuesta === '6' || quest[9].respuesta === '7'))}
									/>
									{opcion}
								</div>
							))}

						{item.numero === '5.5' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor"
									name="valor"
									value={valor}
									placeholder=""
									label="(Menos de un año, anota 00. Más de 99, escribe 99.)"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										if (parseInt(e.target.value) > 99) return;
										setValor(e.target.value);
									}}
								/>
								<div className={classes.itemsFlexCenter}>
									<Radio
										checked={item.respuesta === 'No sé'}
										onChange={(e: any) => {
											let value = e.target.value;
											setValor('');
											handleChange(value, item.numero);
										}}
										name={'No sé'}
										value={'No sé'}
									/>
									No sé
								</div>
							</div>
						)}

						{item.numero === '5.8' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor2"
									name="valor2"
									value={valor2}
									placeholder="0"
									label="Anota el número"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										setValor2(e.target.value);
									}}
								/>
							</div>
						)}

						{item.numero === '5.9' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor3"
									name="valor3"
									value={valor3}
									placeholder="0"
									label="Anota el número"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										setValor3(e.target.value);
									}}
								/>
							</div>
						)}

						{item.numero === '5.13' && item.opciones.map((opcion: any) => (
							<div
								className={classes.itemsFlexCenter}
								key={opcion + item.pregunta}
							>
								<Radio
									checked={value513.indexOf(opcion.split(')')[0]) !== -1 ? true : false}
									onClick={(e: any) => {
										let value = e.target.value.split(')')[0];
										setQuestion513(value, item.numero);
									}}
									name={opcion.split(')')[0]}
									value={opcion.split(')')[0]}
								/>
								{opcion}
							</div>
						))}
					</>
				),
				disabled: ((index === 6) && (quest[5].respuesta === '2')) ||
					((index === 10) && (quest[9].respuesta === '3' || quest[9].respuesta === '4' || quest[9].respuesta === '5' || quest[9].respuesta === '6' || quest[9].respuesta === '7'))
					? true : false
			};
			return questions.push(newItem);
		});
		setQuests(questions);
	};

	const verifyComplete = () => {
		let questHabilitados: any = []
		for (let i = 0; i < quests.length; i++) {
			if (quests[i].disabled === false) {
				questHabilitados.push(i)
			}
		}
		let respuesta = quest.every((item: any, index: any) => {
			if (questHabilitados.includes(index) && item.respuesta.length === 0) return false
			else return true
		})
		return respuesta
	};

	const sendData = () => {
		nextStep();
	};

	return (
		<HeaderStep
			title={'Situación residencial actual'}
			subTitle={'Caracteristicas de la vivienda'}
			nextStep={sendData}
			afterQuestionarySteps={afterQuestionarySteps}
			disabledContinue={!verifyComplete()}
			disabledButton={!verifyComplete()}
			children={quests}
			alert={alert}
		/>
	);
};

export default QuestStep9;
