import axios from 'axios';
import { VARIABLES_BITACORA } from '../config/bitacora';
import { IApiBitacora, ITypeBitacora } from '../interfaces/bitacora';

export const ApiBitacora = async ({ data, headers }: IApiBitacora) => {
	axios.post('/v1/bitacora/registrar', data, {
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			...headers,
		},
	});
};

export const getHeadersByScheme = (scheme: string) => {
	try {
		const pathname = window.location.pathname;
		const variables = VARIABLES_BITACORA[pathname];
		if (variables) {
			const pathScheme = scheme[0] !== '/' ? '/' + scheme : scheme;
			const endpoint = variables[pathScheme];
			if (endpoint) {
				return {
					'mci-accion': endpoint?.ACTION || '',
					'mci-tipo': endpoint?.TYPE || '',
					'mci-id-tipo': endpoint?.ID_TYPE || '',
					'mci-evento': endpoint?.EVENT || '',
					'mci-id-evento': endpoint?.ID_EVENT || '',
				};
			}
		}
		return {
			'mci-accion': '',
			'mci-tipo': '',
			'mci-id-tipo': '',
			'mci-evento': '',
			'mci-id-evento': '',
		};
	} catch (error) {
		return {
			'mci-accion': '',
			'mci-tipo': '',
			'mci-id-tipo': '',
			'mci-evento': '',
			'mci-id-evento': '',
		};
	}
};

export const getHeadersByReferences = (reference: ITypeBitacora) => {
	try {
		return {
			'mci-accion': reference?.ACTION || '',
			'mci-tipo': reference?.TYPE || '',
			'mci-id-tipo': reference?.ID_TYPE || '',
			'mci-evento': reference?.EVENT || '',
			'mci-id-evento': reference?.ID_EVENT || '',
		};
	} catch (error) {
		return {
			'mci-accion': '',
			'mci-tipo': '',
			'mci-id-tipo': '',
			'mci-evento': '',
			'mci-id-evento': '',
		};
	}
};
