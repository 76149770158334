import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ModalLoading from '../../../../components/ModalLoading';
import SimpleTable from '../../../../components/SimpleTable';
import TextInput from '../../../../components/TextInput';
import { defaultDisagreement } from '../../CreateComplaint/Disagreement/utils';
import { apiFollowComplaint } from '../../../../api/modules/followComplaint';
import { keyAPI } from '../../../../api/modules/key';
import { encrypt } from '../../../../utils/encrypt';
import { RootState } from '../../../../store';
import { columns } from './utils';
import CustomAlert from '../../../../components/CustomAlert';

const DisagreementTable = ({ form = defaultDisagreement, setForm }: any) => {
	const { user } = useSelector((state: RootState) => state.session);
	const [oComplaints, setOComplaints] = useState([]);
	const [complaints, setComplaints] = useState([]);
	const [loading, setLoading] = useState(false);
	const [query, setQuery] = useState('');
	const [alert, setAlert] = useState('');
	const [page, setPage] = useState(1);

	const fetchMyComplaints = async () => {
		try {
			setLoading(true);
			const data = {
				nss: user?.nss || '',
				estatus: 'B', // 'A' PARA MODO BUSQUEDA | 'B' PARA CONSULTA GENERAL
				claseOperacion: 'ZQUE', // CONSTANTE
			};
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const dataEncripted = await encrypt(JSON.stringify(data) || '', key);
			const result = await apiFollowComplaint.getMyComplaints(
				dataEncripted
			);
			if (result.code && result.code === '0000') {
				let compl = result?.data?.casos
					?.filter((x: any) => x.estatus === 'E0003')
					.sort((x: any, y: any) =>
						y.caso > x.caso ? -1 : y.caso < x.caso ? 1 : 0
					);
				setComplaints(compl);
				setOComplaints(compl);
			}
		} catch (error: any) {
			setAlert(
				error?.description ||
				'Por el momento el servicio no se encuentra disponible, intenta más tarde'
			);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchMyComplaints();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setSelected = (selected: object) => setForm({ ...form, ...selected });

	const handleSearch = () => {
		let filteredC = oComplaints.filter((c: any) => c.caso === query);
		setComplaints(filteredC);
		setPage(1);
	};

	const handleClearSearch = () => {
		fetchMyComplaints();
		setQuery('');
		setPage(1);
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<h4>Tus quejas generadas son:</h4>
			<div style={{ maxWidth: 300 }}>
				<TextInput
					id="follow-complaint-search"
					label="Buscar por número de caso"
					name="search"
					type="search"
					value={query}
					handleSearch={handleSearch}
					handleClearSearch={handleClearSearch}
					onChange={({ target }) => setQuery(target.value)}
				/>
			</div>
			<SimpleTable
				data={complaints}
				columns={columns(form.caso, setSelected)}
				page={page}
				setPage={setPage}
				rowsPerPage={10}
				count={complaints.length}
			/>
			<CustomAlert
				message={alert}
				show={Boolean(alert)}
				severity="error"
			/>
		</>
	);
};

export default DisagreementTable;
