import {
	ArrowForwardIosOutlined,
	PaidOutlined,
	PaymentsOutlined,
	Medication,
} from '@mui/icons-material';
import { Paper, Grid } from '@mui/material';
import {
	ICredito,
	formatNumber,
	formatDate,
} from '../../../interfaces/SaldAndMovement';
import { UseStyle } from '../Style';

interface Props {
	setView: (arg: number) => void;
	matches: boolean;
	credito: ICredito | null;
}

const CreditDetail = ({ matches, credito, setView }: Props) => {
	const classes = UseStyle();

	return (
		<Grid
			container
			columns={12}
			spacing={2}
			style={{ marginBottom: 22, marginTop: 20 }}
		>
			<Grid item xs={12} md={8}>
				<Paper
					variant="outlined"
					style={{
						padding: 10,
						minHeight: 210,
					}}
				>
					<div className={classes.infoPer}>
						Derechohabiente:
						<label style={{ textAlign: matches ? 'start' : 'end' }}>
							{credito?.derechohabiente}
						</label>
					</div>
					<div className={classes.infoPer}>
						No. Crédito:
						<label style={{ textAlign: matches ? 'start' : 'end' }}>
							{credito?.credito}
						</label>
					</div>
					<div className={classes.infoPer}>
						Mensualidades atrasadas:
						<label>
							{credito?.tipoCasoEspecial?.code === 4
								? 'Liquidado'
								: credito?.tipoCasoEspecial?.code === 2
								? credito?.FPPData?.tp22MensPgoMensualidadFpp ||
								  0
								: credito?.pagosMensualidad
										?.tp11MensPgoCorriente || 0}
						</label>
					</div>
					<div className={classes.infoPer}>
						Fecha de actualización:
						<label style={{ textAlign: matches ? 'start' : 'end' }}>
							{formatDate(credito?.v6FechaActualAls || '')}
						</label>
					</div>
					<div className={classes.infoPer}>
						Crédito total:
						<label style={{ textAlign: matches ? 'start' : 'end' }}>
							{formatNumber.format(credito?.totalCredit || 0)}
						</label>
					</div>
					<div className={classes.infoPer}>
						Tipo de crédito:
						<label style={{ textAlign: matches ? 'start' : 'end' }}>
							{credito?.v1TipoCredito}
						</label>
					</div>
				</Paper>
			</Grid>

			<Grid item xs={12} md={4} className={classes.flexContainer}>
				<Paper
					variant="outlined"
					style={{
						padding: '10px 15px',
						display: 'flex',
						alignItems: 'center',
						cursor: 'pointer',
					}}
					onClick={() => setView(1)}
				>
					<PaidOutlined style={{ fontSize: 16, marginRight: 5 }} />
					<label
						style={{
							width: '100%',
							fontSize: 15,
							userSelect: 'none',
							cursor: 'pointer',
						}}
					>
						Datos de originación de mi crédito
					</label>
					<ArrowForwardIosOutlined style={{ fontSize: 16 }} />
				</Paper>

				<Paper
					variant="outlined"
					style={{
						padding: '10px 15px',
						display: 'flex',
						alignItems: 'center',
						cursor: 'pointer',
					}}
					onClick={() => setView(2)}
				>
					<PaidOutlined style={{ fontSize: 16, marginRight: 5 }} />
					<label
						style={{
							width: '100%',
							fontSize: 15,
							userSelect: 'none',
							cursor: 'pointer',
						}}
					>
						Movimientos y estados de cuenta
					</label>
					<ArrowForwardIosOutlined style={{ fontSize: 16 }} />
				</Paper>

				{!['00220', '00030'].includes(
					credito?.tipoCasoCredito?.wsTipoCaso || ''
				) && (
					<Paper
						variant="outlined"
						style={{
							padding: '10px 15px',
							display: 'flex',
							alignItems: 'center',
							cursor: 'pointer',
							color: '#D1001F',
							fontWeight: 500,
						}}
						onClick={() => setView(3)}
					>
						<PaymentsOutlined
							style={{ fontSize: 16, marginRight: 5 }}
						/>
						<label
							style={{
								width: '100%',
								fontSize: 15,
								userSelect: 'none',
								cursor: 'pointer',
							}}
						>
							Pagar mi crédito
						</label>
						<ArrowForwardIosOutlined style={{ fontSize: 16 }} />
					</Paper>
				)}
			</Grid>
		</Grid>
	);
};

export default CreditDetail;
