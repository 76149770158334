import API from '../';

class UnpairDeviceAPI extends API {
	getInfoDevice(grupo: string, id_cat: string, password: string, id_device: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/vinculacion/verifica-vinculacion`,
				method: 'POST',
				data: { grupo, id_cat, password, id_device },
			})
		);
	}

	unbindDevice(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/vinculacion/realiza-desvinculacion`,
				method: 'POST',
				//data: { nss, grupo, id_cat },
			})
		);
	}

	sendSMS(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/vinculacion/envio-sms`,
				method: 'POST',
				data: { nss },
			})
		);
	}

	sendEmail(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/vinculacion/envio-email`,
				method: 'POST',
				data: { nss },
			})
		);
	}

	validCode(nss: string, code: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/vinculacion/valida-codigo`,
				method: 'POST',
				data: { nss, code },
			})
		);
	}

	consultDevices(grupo: string, id_cat: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/vinculacion/consultar-vinculacion`,
				method: 'POST',
				data: { grupo, id_cat },
			})
		);
	}
}

export const unpairDeviceAPI = new UnpairDeviceAPI('');
