import { useNavigation } from "../../hooks/useNavigation";
import { Box, Grid, Paper } from "@mui/material";
import { ConstruYoOptions } from "../../components/construYoOptions/construYoOptions";
import { MIUC_PROCESS_VIEWS } from "../../constants/process";
import { BuroForm } from "../../components/BuroForm/buroForm";
import FollowSteps from "../../components/FollowSteps";
import ViewPoints from "../../components/ViewPoints";
import FlowCard from "../../components/FlowCard";
import { SelectionResume } from "../../components/SelectionResume/selectionResume";
import { ConstruYoTable } from "../AmpliarReparar/views/construYoTable";
import { CREDIT_OPTIONS } from "../../utils/creditOptions.utils";
import ResumeCard from "../../components/ResumeCard";
import { STAGE_ID } from "../../constants/stageId";
import { mappedStageErrors } from "../../utils/buroForm.utils";
import ErrnoPage from "../../../CreditRequest/ErrnoPage";

export const ConstruYO = () => {
	const {
		calcProgress,
		currentView,
		dataProcessController,
	} = useNavigation();

	const { creditType } = dataProcessController?.processInfo;

  const showViewPoints = ![
    MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
    MIUC_PROCESS_VIEWS.NEXT_STEPS,
  ].includes(currentView);

  const authorization = creditType !== CREDIT_OPTIONS.REPARACIONES_MENORES

  const validateBuro = (creditType === CREDIT_OPTIONS.ASISTENCIA_TECNICA || creditType === CREDIT_OPTIONS.CONSTRUCTORA) 
  						&& dataProcessController.titular.stageId === STAGE_ID.ENDEUDAMIENTO

  const paperSize = !validateBuro ? 8 : 12;

	const GPSFile = creditType === CREDIT_OPTIONS.REPARACIONES_MENORES ?
					require('../../../../assets/files/guia-pasos-a-seguir/GPS_L6_RM.pdf') :
					creditType === CREDIT_OPTIONS.ASISTENCIA_TECNICA ?
					require('../../../../assets/files/guia-pasos-a-seguir/GPS_L6_AT.pdf') :
					creditType === CREDIT_OPTIONS.CONSTRUCTORA ?
					require('../../../../assets/files/guia-pasos-a-seguir/GPS_L6_Constructora.pdf') :
					'';

	const creditDetails = creditType === CREDIT_OPTIONS.REPARACIONES_MENORES ?
						require('../../../../assets/files/guia-pasos-a-seguir/Detalles_L6_RM.pdf') :
						creditType === CREDIT_OPTIONS.ASISTENCIA_TECNICA ?
						require('../../../../assets/files/guia-pasos-a-seguir/Detalles_L6_AT.pdf') :
						creditType === CREDIT_OPTIONS.CONSTRUCTORA ?
						require('../../../../assets/files/guia-pasos-a-seguir/Detalles_L6_Constructora.pdf') :
						'';

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={paperSize}>
				<Paper>
					{currentView === MIUC_PROCESS_VIEWS.CREDIT_OPTIONS && (
						<ConstruYoOptions />
					)}
					{currentView === MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO && !validateBuro ? (
						<BuroForm onlyAuthorization={authorization} />
					) : currentView === MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO && validateBuro && (
            			<ErrnoPage msgString={mappedStageErrors(Number(dataProcessController.titular.stageId))} />
					)}
					{currentView === MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT && (
						<ConstruYoTable />
					)}
					{currentView === MIUC_PROCESS_VIEWS.SUMMARY_REQUEST && (
						<SelectionResume />
					)}
				</Paper>
				{currentView === MIUC_PROCESS_VIEWS.NEXT_STEPS && (
					<FollowSteps guideLink={GPSFile} creditLink={creditDetails}/>
				)}
			</Grid>
			<Grid item xs={12} md={4}>
				{showViewPoints && !validateBuro && (
					<Box sx={{ textAlign: 'center' }} mb={2}>
						<ViewPoints />
					</Box>
				)}
				{currentView === MIUC_PROCESS_VIEWS.NEXT_STEPS && (
					<Box sx={{ textAlign: 'center' }} mb={2}>
						<ResumeCard />
					</Box>
				)}

				{currentView !== MIUC_PROCESS_VIEWS.CREDIT_OPTIONS && !validateBuro && (
					<Box>
						<FlowCard
							title="Tu selección"
							flow={
								dataProcessController.processInfo?.selection
							}
							progress={calcProgress()}
						/>
					</Box>
				)}
			</Grid>
		</Grid>
	);
};