import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	containerInput: {
		display: 'flex',
		alignItems: 'flex-start !important',
		margin: '0px !important',
		width: '100%',
	},
	labelContainerInput: {
		margin: 0,
		color: '#7E8094',
		fontSize: 14,
	},
	labelRequired: {
		color: theme.palette.primary.main,
	},
	textField: {
		width: '100%',
	},
	textFieldHelperText: {
		margin: 0,
		borderRadius: 5,
		height: 30,
		color: '#CE0020',
		backgroundColor: '#FFE2E5',
		borderColor: '#CE0020',
		borderStyle: 'solid',
		borderWidth: 1,
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 10,
	},
}));
