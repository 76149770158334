// DEPENDENCIES
import { useState } from 'react';
import { Paper, Box } from '@mui/material';
// COMPONENTS
import ModalLoading from '../../components/ModalLoading';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
//VIEWS
import InfoPayments from './InfoPayments';
import CreditProcess from './CreditProcess';
import UnregisteredPayment from './UnregisteredPayment';
import { ISesionData } from './utils';
import ResumeView from './CreditProcess/resumeView';

const listItems = [
	'Tu firma electrónica (e.firma) debe estar vigente para poder consultar el monto disponible y para solicitar la devolución.',
	'La devolución se realizará en un lapso no mayor a 10 días hábiles, la cual deberá estar a tu nombre.',
	'En caso de que hayas entregado el aviso de retención a tu patrón de un crédito que no ejerciste, es necesario que obtengas tu aviso de suspensión de descuentos a Mi espacio Infonavit con tu número de seguridad social.',
];

const CancelledPayments = () => {
	const [page, setPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [session, setSession] = useState<ISesionData>({});
	const [caso, setCaso] = useState(false);

	return (
		<Layout>
			<>
				<HeaderList
					title="Devolución de pagos por créditos cancelados"
					list={listItems}
				/>
				{page === 0 && (
					<InfoPayments
						setPage={setPage}
						setLoading={setLoading}
						setCaso={setCaso}
						setSession={setSession}
					/>
				)}
				{page === 1 && (
					<CreditProcess
						caso={caso}
						setPage={setPage}
						setLoading={setLoading}
						session={session}
					/>
				)}
				{page === 2 && <UnregisteredPayment setPage={setPage} />}
				{page === 3 && (
					<Paper>
						<Box sx={{ width: '100%' }}>
							<ResumeView />
						</Box>
					</Paper>
				)}
				<ModalLoading loading={loading} />
			</>
		</Layout>
	);
};

export default CancelledPayments;
