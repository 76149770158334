import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			background: '#F2F2F2',
			height: 90,
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
		},
		Title: {
			fontSize: 25,
		},
		fechaCreacion: {
			position: 'absolute',
			right: 70,
			fontSize: 14,
		},
		body: {
			width: '75%',
		},
		bodyContent: {
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
			background: '#F8F8F8',
		},
		backgroundtabs: {
			background: '#F8F8F8',
		},
		backgroundBody: {
			background: '#F8F8F8',
			width: '100%',
			height: '100%',
			position: 'absolute',
		},
		backgroundContentabs: {
			background: '#FFFFFF',
			height: '600px',
			borderBottomLeftRadius: '10px',
			borderBottomRightRadius: '10px',
			boxShadow: '0px 3px 5px gray',
		},
		backgroundContenMargin: {
			border: '1px solid green',
			marginLeft: '10%',
			marginRight: '10%',
			marginTop: '3%',
			paddingBottom: '2%',
		},
		test: {
			color: '#D1001F',
			background: 'black',
		},

		textRed: {
			color: 'red',
		},
		input: {
			border: '1px solid #DFE4E6',
		},
		link: {
			color: 'red',
		},
		textGray14: {
			fontSize: '14px',
			color: '#858585',
		},
		buttonContinueMobile: {
			containerViewStyle: 'width: 100%',
			fontSize: 19,
			border: '1px solid',
			lineHeight: 1.5,
			backgroundColor: '#D1001F',
			borderRadius: '20px',
			width: '130px',
			color: '#FFFFFF',
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			'&:hover': {
				backgroundColor: '#D1001F',
				boxShadow: 'none',
			},
			'&:active': {
				boxShadow: 'none',
				backgroundColor: '#D1001F',
			},
			'&:focus': {
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
			},
			'&:disabled': {
				backgroundColor: '#C7C6C5',
			},
		},
		buttonContinue: {
			containerViewStyle: 'width: 100%',
			fontSize: 19,
			border: '1px solid',
			lineHeight: 1.5,
			backgroundColor: '#D1001F',
			borderRadius: '20px',
			width: '450px',
			color: '#FFFFFF',
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			'&:hover': {
				backgroundColor: '#D1001F',
				boxShadow: 'none',
			},
			'&:active': {
				boxShadow: 'none',
				backgroundColor: '#D1001F',
			},
			'&:focus': {
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
			},
			'&:disabled': {
				backgroundColor: '#C7C6C5',
			},
		},
		buttonContinueRight: {
			boxShadow: 'none',
			float: 'right',
			textTransform: 'none',
			fontSize: 19,
			padding: '6px 12px',
			border: '1px solid',
			lineHeight: 1.5,
			backgroundColor: '#D1001F',
			borderRadius: '20px',
			width: '200px',
			color: '#FFFFFF',
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			'&:hover': {
				backgroundColor: '#D1001F',
				boxShadow: 'none',
			},
			'&:active': {
				boxShadow: 'none',
				backgroundColor: '#D1001F',
			},
			'&:focus': {
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
			},
			'&:disabled': {
				backgroundColor: '#C7C6C5',
			},
		},
		buttonContinueLeft: {
			boxShadow: 'none',
			float: 'left',
			textTransform: 'none',
			fontSize: 19,
			padding: '6px 12px',
			border: '1px solid',
			lineHeight: 1.5,
			backgroundColor: '#FFFFFF',
			borderRadius: '20px',
			width: '200px',
			color: '#D1001F',
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			'&:hover': {
				backgroundColor: '#D1001F',
				boxShadow: 'none',
				color: '#FFFFFF',
			},
			'&:active': {
				boxShadow: 'none',
				backgroundColor: '#D1001F',
			},
			'&:focus': {
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
			},
			'&:disabled': {
				backgroundColor: '#C7C6C5',
			},
		},
		buttonContinueRightMobile: {
			boxShadow: 'none',
			float: 'right',
			textTransform: 'none',
			fontSize: 17,
			padding: '6px 12px',
			border: '1px solid',
			lineHeight: 1.5,
			backgroundColor: '#D1001F',
			borderRadius: '20px',
			width: '130px',
			color: '#FFFFFF',
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			'&:hover': {
				backgroundColor: '#D1001F',
				boxShadow: 'none',
			},
			'&:active': {
				boxShadow: 'none',
				backgroundColor: '#D1001F',
			},
			'&:focus': {
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
			},
			'&:disabled': {
				backgroundColor: '#C7C6C5',
			},
		},
		buttonContinueLeftMobile: {
			boxShadow: 'none',
			float: 'left',
			textTransform: 'none',
			fontSize: 17,
			padding: '6px 12px',
			border: '1px solid',
			lineHeight: 1.5,
			backgroundColor: '#FFFFFF',
			borderRadius: '20px',
			width: '130px',
			color: '#D1001F',
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			'&:hover': {
				backgroundColor: '#D1001F',
				boxShadow: 'none',
				color: '#FFFFFF',
			},
			'&:active': {
				boxShadow: 'none',
				backgroundColor: '#D1001F',
			},
			'&:focus': {
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
			},
			'&:disabled': {
				backgroundColor: '#C7C6C5',
			},
		},
		VerificacionText: {
			fontSize: '17px',
			textAlign: 'center',
			width: '100%',
		},
		VerificacionCodigoTitle: {
			fontSize: '22px',
			fontWeight: 'bold',
			textAlign: 'center',
			color: '#293990',
			width: '100%',
			display: 'block',
			clear: 'both',
		},
		VerificacionCodigoTitle2: {
			fontSize: '22px',
			fontWeight: 'bold',
			textAlign: 'center',
			color: '#293990',
			width: '100%',
			display: 'block',
			clear: 'both',
			marginBottom: '30px',
		},
		VerificacionCodigo: {
			width: '50px',
			background: '#F2F2F2',
			borderRadius: '5px',
			height: '50px',
			color: '#000000',
			borderColor: '#BABABA',
			borderStyle: 'solid',
			borderWidth: '1',
			display: 'flex',
			alignItems: 'center',
			paddingleft: '10',
			fontSize: '2em',
			textAlign: 'center',
		},
		VerificacionLogo: {
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
		},
		ContraseñaSpan: {
			marginLeft: '3%',
		},
		ContraseñaError: {
			marginleft: '20px',
			// borderWidth: '1px',
		},
		ContraseñaSpanGray: {
			fontSize: '16px',
			color: '#8F8F8F',
		},
		ContraseñaImg: {
			height: '15px',
			width: '15px',
			opacity: '50%',
		},

		defaultText: {
			width: 'auto',
			margin: 0,
			color: '#7E8094',
			fontSize: 14
		},
		defaultBox: {
			textAlign: 'center'
		},

		container: {
			width: 'auto',
			paddingRight: 210,
			paddingLeft: 210,
			paddingTop: 40,
			paddingBottom: 40,
			[theme.breakpoints.down('md')]: {
				paddingRight: 22,
				paddingLeft: 22,
				paddingTop: 20,
				paddingBottom: 20,
			},
			[theme.breakpoints.down('sm')]: {
				paddingRight: 22,
				paddingLeft: 22,
				paddingTop: 20,
				paddingBottom: 20,
			},
		},

		divCodigos: {
			alignItems: 'center',
			justifyContent: 'space-around',
			flexDirection: 'row',
			display: 'flex',
			marginBottom: '35px',
			marginRight: 120,
			marginLeft: 120,
			alignContent: 'center',
			[theme.breakpoints.down('md')]: {
				marginRight: 1,
				marginLeft: 1,
			},
			[theme.breakpoints.down('sm')]: {
				marginRight: 1,
				marginLeft: 1,
			},
		},
		divBotones: {
			paddingBottom: 15,
			paddingLeft: 15,
			paddingRight: 15,
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'row',
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column-reverse',
				paddingBottom: 20,
				paddingLeft: 15,
				paddingRight: 15,
			},
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column-reverse',
				paddingBottom: 20,
				paddingLeft: 15,
				paddingRight: 15,
			},
		},
		divBotonFlex: {
			width: 260,
			margin: '0 5px',
			[theme.breakpoints.down('md')]: {
				width: '100%',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		containerCaptcha: {
			width: 'auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			marginTop: 20,
			marginBottom: 20,
			paddingLeft: 150,
			paddingRight: 150,
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 1,
				paddingRight: 1,
			},
		},
		divboton: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			width: 'auto',
			paddingTop: '5%',
			paddingLeft: 150,
			paddingRight: 150,
			[theme.breakpoints.down('md')]: {
				paddingLeft: 1,
				paddingRight: 1,
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 1,
				paddingRight: 1,
			},
		},
		divider: {
			width: '100%',
			height: 1.5,
			marginBottom: 6,
			backgroundColor: '#E1E3E6',
		},
		DatosObligatorios: {
			fontFamily: 'Geomanist, Medium',
			fontSize: '16px',
			color: '#293990',
			fontWeight: 'bold',
			marginTop: '15px',
			marginBottom: '15px',
		},
	})
);
