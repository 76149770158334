// Dependencies
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createAccountAPI } from '../../api/modules/createAccount';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { contactRequestAPI } from '../../api/modules/profile';

// Material Components
import { Box, FormControlLabel, Paper, Radio, RadioGroup, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Components
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import EmailConfirmation2 from '../Profile/Contact/ContactInformation/component/EmailConfirmation2';
import CustomAlert from '../../components/CustomAlert';
import { IAlert } from '../../interfaces/alert';
import ModalLoading from '../../components/ModalLoading';

// Assets
import { isNumber, emailValid } from '../../utils/expressions';
import { useStyles } from './styles';
import cel from '../../assets/img/cel.png';

interface Props {
	setPage: (data: number) => void;
	initialPage: boolean;
	setInitialPage: (data: boolean) => void;
	setParams: (data: any) => void;
	alertGlobal: any
}

interface ICredentials {
	phone: string;
	phone1: string;
	email: string;
	email1: string;
}

const Home = ({ setPage, initialPage, setInitialPage, setParams, alertGlobal }: Props) => {
	const navigate = useNavigate();
	const style = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [loader, setLoader] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		message: '',
		severity: 'success',
		show: false,
	});
	const [pageTemp, setPageTemp] = useState(0);
	const [errorValidationPhone, setErrorValidationPhone] = useState(false);
	const [errorValidationEmail, setErrorValidationEmail] = useState(false);
	const [credentials, setCredentials] = useState<ICredentials>({
		phone: '',
		phone1: '',
		email: '',
		email1: '',
	});
	const [emailValidate, setEmailValidate] = useState({ valid: false, msg: '' });
	const [email1Validate, setEmail1Validate] = useState({ valid: false, msg: '' });
	const [phoneValidate, setPhoneValidate] = useState({ valid: false, msg: '' });
	const [phone1Validate, setPhone1Validate] = useState({ valid: false, msg: '' });
	const [emailValidFinal, setEmailValidFinal] = useState(false);
	const [phoneValidFinal, setPhoneValidFinal] = useState(false);
	const [buttonValidate, setButtonValidate] = useState(true);

	useEffect(() => {
		if (
			emailValidate.valid &&
			phoneValidate.valid &&
			credentials.phone === credentials.phone1 &&
			credentials.email === credentials.email1
		) {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	}, [emailValidate, phoneValidate, credentials]);

	useEffect(() => {
		if (credentials.phone !== credentials.phone1) {
			if (!credentials.phone1) {
				setPhone1Validate({
					valid: false,
					msg: '',
				});
			} else {
				setPhone1Validate({
					valid: false,
					msg: 'El número de teléfono celular que proporcionaste no coincide, verifícalo e intenta de nuevo',
				});
			}
		} else {
			setPhone1Validate({ valid: true, msg: '' });
		}
	}, [credentials.phone, credentials.phone1]);

	useEffect(() => {
		if (credentials.email !== credentials.email1) {
			if (!credentials.email1) {
				setEmail1Validate({
					valid: false,
					msg: '',
				});
			} else {
				setEmail1Validate({
					valid: false,
					msg: 'El correo electrónico que proporcionaste no coincide, verifícalo e intenta de nuevo',
				});
			}
		} else {
			setEmail1Validate({ valid: true, msg: '' });
		}
	}, [credentials.email, credentials.email1]);

	useEffect(() => {
		setAlert(alertGlobal)
	}, [alertGlobal])

	const handleOnChangeRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name } = event.target;
		if (event.target.checked && name === 'firmElec') {
			setPageTemp(1);
		} else if (event.target.checked && name === 'reciboDig') {
			setPageTemp(2);
		} else if (event.target.checked && name === 'validSat') {
			setPageTemp(3);
		} else if (event.target.checked && name === 'cita') {
			setPageTemp(4);
		}
	};

	const setData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		let validate = null;

		if (name === 'email') {
			if (!value) {
				setEmailValidate({
					msg: '',
					valid: false,
				});
			}
			validate = emailValid(value);
			setEmailValidate(validate);
		}
		if (name === 'email1') {
			if (!value) {
				setEmail1Validate({
					msg: '',
					valid: false,
				});
			}
			validate = emailValid(value);
			setEmail1Validate(validate);
		}
		if (name === 'phone') {
			if (!isNumber(value)) return;
			if (value.length > 10) return;
			if (value.length < 10) {
				setPhoneValidate({
					valid: false,
					msg: 'Debes de escribir los diez dígitos de tu número de teléfono celular',
				});
			} else {
				setPhoneValidate({
					valid: false,
					msg: '',
				});
			}
			if (!value) {
				setPhoneValidate({
					valid: false,
					msg: '',
				});
			}
		}
		if (name === 'phone1') {
			if (!isNumber(value)) return;
			if (value.length > 10) return;
		}
		setCredentials({ ...credentials, [name]: value });
	};

	const handleValidateEmail = async () => {
		if (!emailValidate.valid) return;
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_base64 = await encrypt(credentials.email, key);
			createAccountAPI
				.validateEmailAddress(email_base64)
				.then(() => {
					setEmailValidate({ msg: '', valid: true });
					setEmailValidFinal(true)
				})
				.catch((err) => {
					if (err.description) {
						setEmailValidate({ msg: err.description, valid: false });
						setEmailValidFinal(false)
					}
				});
		} catch (error) { }
	};

	const handleValidatePhone = async () => {
		if (!credentials.phone) {
			setPhoneValidate({
				msg: '',
				valid: false,
			});
			return;
		}
		if (credentials.phone.length < 10) {
			setPhoneValidate({
				msg: 'Debes de escribir los últimos diez dígitos de tu número de teléfono celular',
				valid: false,
			});
			return;
		}
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const phone_base64 = await encrypt(credentials.phone, key);
			createAccountAPI
				.validatePhone(phone_base64)
				.then((res) => {
					setPhoneValidate({ msg: '', valid: true });
					setPhoneValidFinal(true)
				})
				.catch((err) => {
					if (err.description) {
						setPhoneValidate({ msg: err.description, valid: false });
						setPhoneValidFinal(false)
					}
				});
		} catch (error) { }
	};

	const initialConfirmPhone = async () => {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const phone_base64 = await encrypt(credentials.phone, key);
			contactRequestAPI.validationPhone
				.post<{ celular: string }, any>('', {
					celular: phone_base64,
				})
				.then((res) => {
					if (res.result.codigo === '1') {
						setErrorValidationPhone(false);
						secondConfirmEmail();
					} else {
						if (errorValidationPhone) {
							setAlert({
								show: true,
								message:
									!res.result.codigo && res.result.message
										? res.result.message
										: 'El número de celular que nos proporcionaste no existe.',
								severity: 'error',
							});
						}
						setErrorValidationPhone(true);
						setPhoneValidFinal(false)
						setLoader(false)
					}
				})
				.catch((e) => {
					setAlert({
						show: true,
						message: e.description
							? e.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde',
						severity: 'error',
					});
					setLoader(false)
					throw e;
				})
		} catch (error: any) {
			setAlert({
				show: true,
				message:
					'Por el momento el servicio no está disponible. Intenta más tarde',
				severity: 'error',
			});
			setLoader(false);
		}
	};

	const secondConfirmEmail = async () => {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_pass_base64 = await encrypt(credentials.email, key);
			contactRequestAPI.validationEmail
				.post<{ email: string }, any>('', {
					email: email_pass_base64,
				})
				.then((res) => {
					if (res.result.emailCodigoEstatus === '01') {
						setErrorValidationEmail(false);
						const userRegisterStorage: any = localStorage.getItem('dataUserRegister');
						if (userRegisterStorage) {
							let paramsStorage = JSON.parse(userRegisterStorage)
							let values = {
								nss: paramsStorage.nss,
								curp: paramsStorage.curp,
								rfc: paramsStorage.rfc,
								name: paramsStorage.name,
								phone: credentials.phone,
								email: credentials.email,
								rfcCrm: paramsStorage.rfcCrm,
							};
							setParams(values);
						}
						setInitialPage(false)
					} else {
						if (errorValidationEmail) {
							setAlert({
								show: true,
								message:
									!res.result.emailCodigoEstatus && res.result.message
										? res.result.message
										: 'El correo eléctronico que nos proporcionaste no existe.',
								severity: 'error',
							});
						}
						setErrorValidationEmail(true);
						setEmailValidFinal(false)
					}
				})
				.catch((e) => {
					setAlert({
						show: true,
						message: e.description
							? e.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde',
						severity: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message:
					'Por el momento el servicio no está disponible. Intenta más tarde',
				severity: 'error',
			});
			setLoader(false);
		}
	};

	return (
		<Paper>
			{!initialPage && (
				<Box className={style.container}>
					<div
						id="radioOptions"
						style={{ display: 'inline' }}
					>
						<h3 className={style.h3}>Elige una de las opciones</h3>
						<RadioGroup>
							<FormControlLabel
								sx={{
									ml: {
										xs: 4,
										sm: 14,
										md: 14,
									},
									mb: 1,
								}}
								control={
									<Radio
										onChange={handleOnChangeRadioButton}
										name="firmElec"
									/>
								}
								style={{ color: '#000000', width: 'max-content' }}
								label="Tengo firma electrónica avanzada"
								value="firmElec"
							/>
							<FormControlLabel
								sx={{
									ml: {
										xs: 4,
										sm: 14,
										md: 14,
									},
									mb: 1,
								}}
								control={
									<Radio
										onChange={handleOnChangeRadioButton}
										name="reciboDig"
									/>
								}
								style={{ color: '#000000', width: 'max-content' }}
								label="Tengo recibo de nómina digital"
								value="reciboDig"
							/>
							<FormControlLabel
								sx={{
									ml: {
										xs: 4,
										sm: 14,
										md: 14,
									},
									mb: 1,
								}}
								control={
									<Radio
										onChange={handleOnChangeRadioButton}
										name="validSat"
									/>
								}
								style={{ color: '#000000', width: 'max-content' }}
								label="Validación con información del SAT"
								value="validSat"
							/>
							<FormControlLabel
								sx={{
									ml: {
										xs: 4,
										sm: 14,
										md: 14,
									},
									mb: 1,
								}}
								control={
									<Radio onChange={handleOnChangeRadioButton} name="cita" />
								}
								style={{ color: '#000000', width: 'max-content' }}
								label="Haz una cita"
								value="cita"
							/>
						</RadioGroup>
						<div className={style.containerAlert}>
							<CustomAlert
								show={alert.show}
								severity={alert.severity}
								message={alert.message}
							/>
						</div>
						<div
							className={style.botonContainerRadio}
							style={{ marginTop: 40, paddingBottom: 30 }}
						>
							<CustomButton
								data-testid="buttonContinuar"
								label="Continuar"
								onClick={() => setPage(pageTemp)}
								disabled={pageTemp === 0 || alert.show}
								variant="solid"
								styles={{
									width: 282,
									height: 40,
									marginRight: 20,
									marginBottom: 10,
								}}
							/>
						</div>
					</div>
				</Box>
			)}
			{initialPage && (
				<div style={{ width: '100%' }}>
					<ModalLoading loading={loader} />
					<Box className={style.container} style={{ paddingTop: 40 }}>
						{!errorValidationPhone && !errorValidationEmail && (
							<Grid container className={style.container} spacing={0}>
								<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<div className={style.containerInput}>
										<TextInput
											id="email"
											name="email"
											label="Correo electrónico"
											value={credentials.email}
											error={!emailValidate.valid}
											helperText={!emailValidate.valid ? emailValidate.msg : ''}
											size="small"
											isRequired={true}
											onChange={setData}
											onBlur={handleValidateEmail}
											noCopy={true}
											noPaste={true}
										/>
									</div>
									<div className={style.containerInput}>
										<TextInput
											id="email1"
											name="email1"
											label="Confirma tu correo electrónico"
											value={credentials.email1}
											error={!email1Validate.valid}
											helperText={
												!email1Validate.valid ? email1Validate.msg : ''
											}
											size="small"
											isRequired={true}
											onChange={setData}
											disabled={!emailValidFinal}
											noCopy={true}
											noPaste={true}
										/>
									</div>
									<div className={style.containerInput}>
										<TextInput
											id="phone"
											name="phone"
											label="Teléfono"
											value={credentials.phone}
											error={!phoneValidate.valid}
											helperText={!phoneValidate.valid ? phoneValidate.msg : ''}
											size="small"
											isRequired={true}
											onChange={setData}
											onBlur={handleValidatePhone}
											disabled={credentials.email !== credentials.email1}
											noCopy={true}
											noPaste={true}
										/>
									</div>
									<div className={style.containerInput}>
										<TextInput
											id="phone1"
											name="phone1"
											label="Confirma tu teléfono"
											value={credentials.phone1}
											error={!phone1Validate.valid}
											helperText={
												!phone1Validate.valid ? phone1Validate.msg : ''
											}
											size="small"
											isRequired={true}
											onChange={setData}
											disabled={!phoneValidFinal}
											noCopy={true}
											noPaste={true}
										/>
									</div>
									<div className={style.containerAlert}>
										<CustomAlert
											show={alert.show}
											severity={alert.severity}
											message={alert.message}
										/>
									</div>
									<div className={style.botonContainerFirmElec} style={{ paddingTop: 20 }}>
										<CustomButton
											data-testid="gotElecCancelar"
											label="Regresar"
											onClick={() => {
												localStorage.removeItem('dataUserRegister');
												navigate('/registro');
											}}
											variant="outlined"
											styles={{
												width: matches ? 340 : 260,
												height: 40,
												marginBottom: 20,
												marginRight: matches ? 0 : 25,
											}}
										/>
										<CustomButton
											label="Continuar"
											onClick={() => initialConfirmPhone()}
											variant="solid"
											disabled={buttonValidate || alert.show}
											styles={{
												width: matches ? 340 : 260,
												height: 40,
												marginBottom: 20,
											}}
										/>
									</div>
								</Grid>
								<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
							</Grid>
						)}
						{errorValidationPhone && (
							<div>
								<Box className={style.VerificacionLogo}>
									<img alt="mobile" src={cel} />
								</Box>
								<Box
									sx={{
										alignItems: 'center',
										justifyContent: 'center',
										display: 'flex',
										marginTop: '20px',
									}}
								>
									<span className={style.VerificacionCodigoTitle}>
										<strong>Confirmación de número celular</strong>
									</span>
								</Box>
								<Box
									sx={{
										alignItems: 'center',
										justifyContent: 'center',
										display: 'flex',
										marginBottom: '35px',
									}}
								>
									<span className={style.VerificacionText}>
										<strong>
											El número de teléfono celular que nos proporcionaste no
											existe, por favor actualízalo <br />
											para continuar con la corrección.
										</strong>
									</span>
								</Box>
								<div className={style.numbersVerification}>
									<div className={style.containerInput}>
										<TextInput
											id="phone"
											name="phone"
											label="Teléfono"
											value={credentials.phone}
											error={!phoneValidate.valid}
											helperText={!phoneValidate.valid ? phoneValidate.msg : ''}
											size="small"
											isRequired={true}
											onChange={setData}
											onBlur={handleValidatePhone}
											noCopy={true}
											noPaste={true}
										/>
									</div>
									<div className={style.containerInput}>
										<TextInput
											id="phone1"
											name="phone1"
											label="Confirma tu teléfono"
											value={credentials.phone1}
											error={!phone1Validate.valid}
											helperText={
												!phone1Validate.valid ? phone1Validate.msg : ''
											}
											size="small"
											isRequired={true}
											onChange={setData}
											disabled={!phoneValidFinal}
											noCopy={true}
											noPaste={true}
										/>
									</div>
									<div className={style.containerAlert}>
										<CustomAlert
											show={alert.show}
											severity={alert.severity}
											message={alert.message}
										/>
									</div>
									<div className={style.buttonCenter}>
										<div className={style.movilButton}>
											<CustomButton
												label="Continuar"
												onClick={() => initialConfirmPhone()}
												variant="solid"
												disabled={
													!phoneValidate.valid ||
													credentials.phone !== credentials.phone1
												}
												styles={{
													width: matches ? 340 : 260,
													height: 40,
													margin: '0 auto 20px'
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
						{errorValidationEmail && (
							<>
								<div style={{ marginTop: '-20px' }}></div>
								<EmailConfirmation2 />
								<div className={style.numbersVerification}>
									<div className={style.containerInput}>
										<TextInput
											id="email"
											name="email"
											label="Correo electrónico"
											value={credentials.email}
											error={!emailValidate.valid}
											helperText={!emailValidate.valid ? emailValidate.msg : ''}
											size="small"
											isRequired={true}
											onChange={setData}
											onBlur={handleValidateEmail}
											disabled={!phoneValidate.valid}
											noCopy={true}
											noPaste={true}
										/>
									</div>
									<div className={style.containerInput}>
										<TextInput
											id="email1"
											name="email1"
											label="Confirma tu correo electrónico"
											value={credentials.email1}
											error={!email1Validate.valid}
											helperText={
												!email1Validate.valid ? email1Validate.msg : ''
											}
											size="small"
											isRequired={true}
											onChange={setData}
											disabled={!emailValidFinal}
											noCopy={true}
											noPaste={true}
										/>
									</div>
									<div className={style.containerAlert}>
										<CustomAlert
											show={alert.show}
											severity={alert.severity}
											message={alert.message}
										/>
									</div>
									<div className={style.buttonCenter}>
										<div className={style.movilButton}>
											<CustomButton
												label="Validar"
												onClick={() => secondConfirmEmail()}
												variant="solid"
												disabled={
													!emailValidate.valid ||
													credentials.email !== credentials.email1
												}
												styles={{
													width: matches ? 340 : 260,
													height: 40,
													margin: '0 auto 20px'
												}}
											/>
										</div>
									</div>
								</div>
							</>
						)}

						<hr className={style.hr} style={{}} />
						<h3
							className={style.h4}
							style={{
								paddingBottom: 30,
								width: '100%',
								marginTop: 15,
								marginLeft: 15,
								fontSize: 16
							}}
						>
							*Datos obligatorios
						</h3>
					</Box>
				</div>
			)}
		</Paper>
	);
};
export default Home;
