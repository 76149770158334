/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Material Components
import { Alert, Box, Radio, InputAdornment, Link } from '@mui/material';

// COMPONENTS
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import SelectInput from '../../components/SelectInput';
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';

// Resources
import { contributionsAPI } from '../../api/modules/contribution';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { IAlert } from '../../interfaces/alert';
import { keyAPI } from '../../api/modules/key';
import { defaultContribution } from './utils';
import { encrypt, encodeAllJSONData } from '../../utils/encrypt';
import { RootState } from '../../store';
import { useStyles } from './styles';

import SuccessContribution from './SuccessContribution';
import ConfirmCodyPayment from './ConfirmCodyPayment';
import DatosObligatorios from './DatosObligatorios';
import Questions from './Questions';

// Assets
import CodiIcon from '../../assets/img/codi-icon.png';
import { formatMoney2 } from '../../utils/validators';
import { useBitacora } from '../../hooks/useBitacora';

const NewContributionView = ({ tabs, onChangeTab, setModalActive }: any) => {
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const { regBitacoraNotOld } = useBitacora();
	const [modalConfirm, setModalConfirm] = useState(false);
	const [modalCody, setModalCody] = useState(false);
	const [separacion, setSeparacion] = useState(false);
	const [consulta, setConsulta] = useState(false);
	const [form, setForm] = useState(defaultContribution);
	const [errors, setErrors] = useState(defaultContribution);
	const [segmentOptions, setSegmentOptions] = useState([] as any);
	const [buttonValidate, setButtonValidate] = useState(true);
	const [stateOptions, setStateOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [results, setResults] = useState<any>(null);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const styles = useStyles();
	let [MAX, setMAX] = useState(0 as any);
	let [MIN, setMIN] = useState(0 as any);

	useEffect(() => {
		handleConsultaSeparacion();
		setErrors(defaultContribution);
	}, []);

	const fetchStateOptions = useCallback(async () => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				key
			);

			contributionsAPI.aportationsStates
				.post<{ nss: string }, any>('', {
					nss: USER_NSS_BASE64,
				})
				.then(({ estados }: any) =>
					setStateOptions(
						estados.map(({ id, nombre }: any) => ({
							value: id,
							text: nombre,
						}))
					)
				)
				.catch();
		} catch (error) { }
	}, [user?.nss]);

	useEffect(() => {
		fetchStateOptions();
	}, [fetchStateOptions]);

	useEffect(() => {
		if (
			form.codigo_estado !== '' &&
			form.monto !== '' &&
			form.pago !== '' &&
			separacion === false &&
			errors === defaultContribution
		) {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	}, [form.codigo_estado, form.monto, errors, form.pago, separacion]);

	const fetchSegmentOptions = useCallback(async () => {
		const keyRes = await keyAPI.get<'', { k: string }>();
		const key = keyRes['k'].toString();
		const USER_NSS_BASE64 = await encrypt(user?.nss?.toString() || '', key);
		// const USER_NSS_BASE64 = Number(user?.nss);
		contributionsAPI.segments
			.post<{ nss: any }, any>('', {
				nss: USER_NSS_BASE64,
			})
			.then(({ segmentos }: any) => {
				if (segmentos && segmentos.length > 0) {
					setSegmentOptions(
						segmentos.map(({ codigo, desc_corta }: any) => ({
							value: codigo,
							text: desc_corta,
						}))
					)
				}
			})
			.catch();
	}, [user?.nss]);

	useEffect(() => {
		fetchSegmentOptions();
	}, [fetchSegmentOptions]);

	// aportationSeparacion

	const handleConsultaSeparacion = async () => {
		setLoading(true);
		contributionsAPI.aportationSeparacion
			.post<{}, any>('')
			.then(({ result }: any) => {
				if (result.valid === false) {
					setSeparacion(true);
					setAlert({
						show: true,
						message:
							result.description ||
							'Tienes un proceso de separación de cuentas, en cuanto concluyas este trámite podrás solicitar tu ficha de depósito referenciado. Si quieres más información acude a tu AFORE.',
						severity: 'error',
					});
				} else {
					setSeparacion(false);
				}
				fetchMinMax();
			})
			.catch((error) => {
				setLoading(false);
				setAlert({
					show: true,
					message:
						error.error?.descripcion ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					severity: 'error',
				});
			})
			.finally(() => setLoading(false));
	};

	const fetchMinMax = useCallback(async () => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				key
			);
			contributionsAPI.aportationMaxMin
				.post<{ nss: string }, any>('', {
					nss: USER_NSS_BASE64,
				})
				.then(({ result }: any) => {
					setMAX(result.monto_maximo);
					setMIN(result.monto_minimo);
				})
				.catch((error) => {
					if (Number(error?.code || '0') === 9999) {
						setModalActive(true);
					}
					setLoading(false);
					setAlert({
						show: true,
						message:
							error.error?.descripcion ||
							'Por el momento el servicio no se encuentra disponible, intenta más tarde',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message:
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		}
	}, [user?.nss]);

	useEffect(() => {
		if (form.monto && form.monto.length > 0) {
			handleValidAmount(form.monto);
		}
	}, [form.pago]);

	const handleChange = ({ target }: any) => {
		const { name, value } = target;
		setForm({ ...form, [name]: value });
	};

	const handleValidAmount = async ({ target }: any) => {
		const val: Number = isNaN(
			Number(form.monto.replaceAll(' ', '').replaceAll(',', ''))
		)
			? 0
			: Number(form.monto.replaceAll(' ', '').replaceAll(',', ''));

		if (form.monto) {
			if (form.monto === '.') {
				setForm({ ...form, monto: '' });
			} else {
				setForm({
					...form,
					monto: formatMoney2(Number(val.toString())).substring(1),
				});
			}
		}
		if (form.pago === 'referenciado') {
			if (!(val >= MIN && val <= MAX)) {
				setErrors({
					...errors,
					monto: `El monto es inválido, el monto "mínimo" es $${MIN} y el "máximo" es $${MAX}`,
				});
			} else {
				setErrors(defaultContribution);
			}
		} else {
			if (val < MIN || val > MAX) {
				setErrors({
					...errors,
					monto: `El monto es inválido, el monto "mínimo" es $${MIN} y el "máximo" es $${MAX}`,
				});
			} else {
				setErrors(defaultContribution);
			}
		}
	};

	const handleConfirmMonto = async () => {
		const valor = Number(
			form.monto.replaceAll(' ', '').replaceAll(',', '')
		);
		if (form.pago === 'codi' || form.pago === 'cuenta') {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const MONTO_BASE64 = await encrypt(valor.toString() || '', key);
			contributionsAPI.aportationMaxMinCoDi
				.post<
					{
						monto: string;
					},
					any
				>('', {
					monto: MONTO_BASE64,
				})
				.then(({ result }: any) => {
					if (result.monto_valido === true) {
						setModalCody(true);
					} else {
						if (form.pago === 'codi' || form.pago === 'cuenta') {
							setErrors({
								...errors,
								monto:
									`El monto es inválido, el monto "mínimo" es $` +
									result.monto_minimo +
									`y el "máximo" es $` +
									result.monto_maximo,
							});
						}
					}
				})
				.catch()
				.finally(() => setLoading(false));
		} else if (form.pago === 'referenciado') {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const MONTO_BASE64 = await encrypt(valor.toString() || '', key);
			contributionsAPI.aportationMaxMin
				.post<
					{
						monto: string;
					},
					any
				>('', {
					monto: MONTO_BASE64,
				})
				.then(({ result }: any) => {
					if (result.monto_valido === true) {
						handleCreateContribution();
					} else {
						if (form.pago === 'referenciado') {
							setErrors({
								...errors,
								monto: `El monto es inválido, el monto "mínimo" es $${MIN} y el "máximo" es $${MAX}`,
							});
						}
					}
				})
				.catch()
				.finally(() => setLoading(false));
		}
	};

	const handleCreateContribution = async () => {
		if (user?.email === '') {
			setAlert({
				show: true,
				message: 'Datos incompletos',
				severity: 'error',
			});
		} else {
			setButtonValidate(true);
			setLoading(true);
			const valor = Number(
				form.monto.replaceAll(' ', '').replaceAll(',', '')
			);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const NSS_BASE64 = await encrypt(user?.nss?.toString() || '', key);
			const MONTO_BASE64 = await encrypt(valor.toString() || '', key);
			const CD_ESTADO_BASE64 = await encrypt(
				form.codigo_estado || '0',
				key
			);
			const SEGMENTO_BASE64 = await encrypt(form.segmento || '', key);
			const CORREO_BASE64 = await encrypt(user?.email || '', key);
			let dataBitacora = {
				nss: user?.nss,
				monto: valor.toString(),
				codigo_estado: form.codigo_estado,
				segmento: form.segmento,
				email: user?.email,
			}
			contributionsAPI.createAportation
				.post<
					{
						nss: any;
						monto: string;
						codigo_estado: any;
						segmento: string;
						email: string;
					},
					any
				>('', {
					nss: NSS_BASE64,
					monto: MONTO_BASE64,
					codigo_estado: CD_ESTADO_BASE64,
					segmento: SEGMENTO_BASE64,
					email: CORREO_BASE64,
				})
				.then(({ result }: any) => {
					if (result[0]) {
						setModalConfirm(true);
						setResults({ ...result[0], monto: form?.monto });
						regBitacoraNotOld(contributionsAPI.createAportation.scheme, dataBitacora);
					}
					setLoading(false);
					setButtonValidate(false);
				})
				.catch((error) => {
					setLoading(false);
					setAlert({
						show: true,
						message:
							error?.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
					setLoading(false);
					setButtonValidate(false);
				})
				.finally(() => setLoading(false));
		}
	};

	const handleCreateContributionCodi = async () => {
		if (user?.email === '') {
			setAlert({
				show: true,
				message: 'Datos incompletos',
				severity: 'error',
			});
		} else {
			setLoading(true);
			const valor = Number(form.monto.replaceAll(' ', '').replaceAll(',', ''));
			if (form.pago === 'codi') {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const NSS_BASE64 = await encrypt(user?.nss?.toString() || '', key);
				const MONTO_BASE64 = await encrypt(valor.toString() || '', key);
				const CD_ESTADO_BASE64 = await encrypt(form.codigo_estado || '', key);
				const SEGMENTO_BASE64 = await encrypt(form.segmento || '0', key);
				const CORREO_BASE64 = await encrypt(user?.email || '', key);
				const NOMBRE_BASE64 = await encrypt(user?.given_name || '', key);
				const NO_CREDITO_BASE64 = await encrypt(credit || '', key);
				let dataBitacora = {
					nss: user?.nss,
					monto: valor.toString(),
					codigo_estado: form.codigo_estado,
					segmento: form.segmento,
					email: user?.email,
				}
				contributionsAPI.createAportationCodi
					.post<
						{
							nss: any;
							monto: string;
							codigo_estado: any;
							segmento: string;
							email: string;
							nombre: string;
							numero_credito: string;
						},
						any
					>('', {
						nss: NSS_BASE64,
						monto: MONTO_BASE64,
						codigo_estado: CD_ESTADO_BASE64,
						segmento: SEGMENTO_BASE64,
						email: CORREO_BASE64,
						nombre: NOMBRE_BASE64,
						numero_credito: NO_CREDITO_BASE64,
					})
					.then(({ result }: any) => {
						if (result) {
							let html = window.atob(result);
							let wnd = window.open('about:blank', '_blank');
							wnd?.document.write(`${html}`);
							wnd?.document.close();
							setModalCody(false);
							setForm(defaultContribution);
							regBitacoraNotOld(contributionsAPI.createAportationCodi.scheme, dataBitacora);
						}
					})
					.catch((error) => {
						setLoading(false);
						setAlert({
							show: true,
							message:
								error.error?.descripcion ||
								'Por el momento el servicio no se encuentra disponible, intenta más tarde',
							severity: 'error',
						});
					})
					.finally(() => setLoading(false));
			} else if (form.pago === 'cuenta') {
				const data = {
					email: user?.email || '',
					acreditado: user?.given_name || '',
					numero_credito: credit || '',
					segmento: form.segmento || '0',
					codigo_estado: form.codigo_estado || '',
					monto: valor.toString() || '',
					referencia: 'AE'
				};
				const dataEncript = await encodeAllJSONData(
					{
						data: JSON.stringify(data),
					},
					user?.k || ''
				);
				contributionsAPI.createAportationCuenta
					.post<{}, any>('', dataEncript)
					.then(({ result }: any) => {
						if (result) {
							let html = window.atob(result);
							let wnd = window.open('about:blank', '_blank');
							wnd?.document.write(`${html}`);
							wnd?.document.close();
							setModalCody(false);
							setForm(defaultContribution);
							regBitacoraNotOld(contributionsAPI.createAportationCuenta.scheme, data);
						}
					})
					.catch((error) => {
						setLoading(false);
						setAlert({
							show: true,
							message:
								error.error?.descripcion ||
								'Por el momento el servicio no se encuentra disponible, intenta más tarde',
							severity: 'error',
						});
					})
					.finally(() => setLoading(false));
			}
		}
	};

	return (
		<>
			<Box className={styles.backgroundContenMargin}>
				<SelectInput
					value={form.segmento}
					onChange={handleChange}
					id="segmento"
					name="segmento"
					label="Segmento"
					options={segmentOptions}
					disabled={!(segmentOptions?.length > 0)}
				/>
				<SelectInput
					value={form.codigo_estado}
					onChange={handleChange}
					isRequired
					id="codigo_estado"
					name="codigo_estado"
					label="Estado"
					options={stateOptions}
					disabled={!(stateOptions?.length > 0)}
				/>
				<TextInput
					id="monto"
					name="monto"
					label="Monto de la aportación"
					value={form.monto}
					onChange={(e) =>
						!isNaN(
							Number(
								e.target.value
									.replaceAll(' ', '')
									.replaceAll(',', '')
							)
						) && handleChange(e)
					}
					onBlur={handleValidAmount}
					helperText={errors.monto}
					isRequired
					disabled={MAX === 0 && MIN === 0}
					startAdornment={
						<InputAdornment position="start">$</InputAdornment>
					}
				/>
				<br />
				<br />
				<div className={styles.divPagos}>
					<div className={styles.contPagos}>
						<Radio
							data-testid="RadioOne"
							checked={form.pago === 'codi'}
							onChange={(e: any) => {
								handleChange({
									target: {
										name: 'pago',
										value: e.target.value,
									},
								});
							}}
							value="codi"
							size="small"
						/>
						<span style={{ display: 'flex', alignItems: 'center' }}>
							<b>Pago con CoDi </b>
							<img
								style={{ marginLeft: 130 }}
								width={30}
								src={CodiIcon}
								alt="codi-logo"
							/>
						</span>
					</div>
					<div className={styles.contPagos}>
						<Radio
							data-testid="Radiotwo"
							checked={form.pago === 'referenciado'}
							onChange={(e: any) => {
								handleChange({
									target: {
										name: 'pago',
										value: e.target.value,
									},
								});
							}}
							value="referenciado"
							name="pago"
							size="small"
						/>
						<span>
							<b>Ficha de pago referenciado</b>
						</span>
					</div>
				</div>
				<div className={styles.divPagosOnly}>
					<div className={styles.contPagos}>
						<Radio
							data-testid="Radiothree"
							checked={form.pago === 'cuenta'}
							onChange={(e: any) => {
								handleChange({
									target: {
										name: 'pago',
										value: e.target.value,
									},
								});
							}}
							value="cuenta"
							name="cuenta"
							size="small"
						/>
						<span>
							<b>Pago con cuenta bancaria en México</b>
						</span>
					</div>
				</div>
				<br />
				{form.pago === 'codi' && (
					<Alert severity="info">
						Podrás realizar la cantidad de Aportaciones Extraordinarias
						que consideres conveniente, siempre y cuando la suma mensual
						sea menor al 5% de 25 Salarios Mínimos Vigentes Mensuales,
						que corresponden a $9,334.87 pesos en 2024.
					</Alert>
				)}
				{form.pago === 'referenciado' && (
					<Alert severity="info">
						<b>
							Se enviará la ficha referenciada al correo
							electrónico que tenemos registrado.&nbsp;
						</b>
						En caso de no encontrar el correo en la bandeja
						de entrada, revisa la carpeta de correos no
						deseados (spam). <br />
						Recuerda que puedes realizar tu pago por medio de:
						<div className={styles.divTextMessage}>
							<ul>
								<li>Ventanilla bancaria o banca en línea.</li>
								<li>Establecimientos comerciales afiliados.</li>
							</ul>
							<ul>
								<li>Cheque en línea BBVA.</li>
								<li>Cajeros depositadores.</li>
							</ul>
						</div>
					</Alert>
				)}
				{form.pago === 'cuenta' && (
					<>
						<Alert severity="info">
							Podrás realizar la cantidad de Aportaciones Extraordinarias
							que consideres conveniente, siempre y cuando la suma mensual
							sea menor al 5% de 25 Salarios Mínimos Vigentes Mensuales,
							que corresponden a $9,334.87 pesos en 2024.
						</Alert>
						<Alert severity="info" style={{ marginTop: '20px' }}>
							En caso de pago con cuenta bancaria en México, considerar
							que antes de hacer la transferencia debe dar de alta, en su
							banca en línea, la cuenta CLABE que aparecerá en la "Ficha
							para pago interbancario BBVA" que se muestra al dar clic en
							el botón continuar; dependiendo del banco, el tiempo de espera
							es de 30 minutos a 3 horas.
						</Alert>
					</>

				)}
				<CustomAlert
					show={alert.show}
					severity={alert.severity}
					message={alert.message}
					onClose={() => {
						setAlert({
							show: false,
							message: '',
							severity: alert.severity,
						});
					}}
				/>
				<div className={styles.divButton}>
					<div className={styles.divBtn}>
						<CustomButton
							label="Continuar"
							variant="solid"
							onClick={handleConfirmMonto}
							disabled={buttonValidate}
						/>
					</div>
				</div>
				<div className={styles.divButton}>
					<span>
						Antes de realizar Aportaciones Extraordinarias, te
						recomendamos&nbsp;
						<Link
							className={styles.txtRed}
							onClick={() => setConsulta(!consulta)}
						>
							consultar aquí
						</Link>
						&nbsp;las consideraciones y preguntas frecuentes de este
						servicio.
					</span>
				</div>
				{consulta && <Questions />}
				<ModalLoading loading={loading} />
				<SuccessContribution
					open={modalConfirm}
					setOpen={setModalConfirm}
					onChangeTab={onChangeTab}
					results={results}
				/>
				<ConfirmCodyPayment
					open={modalCody}
					setOpen={setModalCody}
					onConfirm={handleCreateContributionCodi}
				/>
			</Box>
			{!consulta && <DatosObligatorios />}
		</>
	);
};

export default NewContributionView;
