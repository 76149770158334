export const defaultDisagreement = {
	idRegistro: 0,
	caso: '',
	claseOperacion: '',
	descripcionEstatus: '',
	estatus: '',
	fechaModificacion: '',
	fechaSol: '',
	marcaOrigen: '',
	tipificacion: '',
	notas: '',

	razon: '',

	entidad: '',
	cobertura: '',
	utilizado: '',

	personaQueja: '',
	tramite: '',
	estado: '',
	direcPersona: '',
	telPersona: '',
};
