import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    titulo: {
        color: '#333333',
        fontSize: 15,
        fontWeight: 700,
        marginBottom: 5,
        marginTop: 0,
    },
    ahorroSub: {
        fontSize: 25,
        fontWeight: 800,
        margin: 0,
        color: theme.palette.info.main
    },
    iconoPesos: {
        maxWidth: 50, 
        margin: '0px 10px'
    },
    pAcumulados: {
        color: '#293990',
        fontWeight: 700,
        fontSize: 18,
        margin: 0,
    },
    bPuntuacion: {
        display: 'flex',
	    alignItems: 'center',
	    justifyContent: 'center',
    },
    boxVentana:{
        display: 'flex', 
        alignItems: 'center'
    },
    headerVentana: {
        fontSize: 12,
        color: '#293990',
        fontWeight: 600,
        flex: 1,
    },
    tituloVentana: {
        textAlign: 'center',
        margin: 0,
        marginBottom: 10
    },
    footerVentana: {
        margin: 0,
        color: '#293990',
        fontWeight: 700,
    },
    tituloColumna: {
        color: '#293990',
        padding: '10px',
        bgcolor: '#F2F2F2',
        fontWeight: 700,
    },
    centeredColumn: {
        minWidth: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center',
        margin: 0, 
    },
    bMargin: {
        marginBottom: '10px !important',
    }
}));

export const centeredItems = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};