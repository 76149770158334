import { Paper } from '@mui/material';
import { SelectionResume } from '../../../components/SelectionResume/selectionResume';

const CreditSummary = () => {
	return (
		<Paper>
			<SelectionResume />
		</Paper>
	);
};

export default CreditSummary;
