export interface IResponse {
	avaluo?: string;
	codigoCanje?: string;
	estatus?: string;
	fechaCanje?: string;
	lista?: Array<string>;
	mensajeCanje?: string;
	montoOtorgado?: number;
	nombreDH?: string;
	pantalla?: string;
	proveedor?: string;
}

export interface IDocument {
	description?: string;
	documento?: string;
}
const moneyNum = new Intl.NumberFormat('es-MX', {
	style: 'currency',
	currency: 'MXN',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});
export const FormatMoney = (monto: number) => {
	return moneyNum.format(monto);
};
