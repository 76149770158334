import { Box, useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../styles';
import CustomButtom from '../../../components/Button';

interface IPersonalData {
	onContinue: () => void;
}
const NipActivationChange = (props: IPersonalData) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<>
			<br />
			<br />
			<Box className={classes.VerificacionCodigoTitle}>
				<h1>Cambiaste tu NIP Infonatel</h1>
			</Box>
			<br />
			<Box style={{ width: '100%', maxWidth: '650px', margin: '0 auto' }} className={classes.VerificacionText}>
				<strong>Tu NIP Infonatel se actualizó correctamente,</strong>{' '}
				recuerda que lo utilizaras cuando requieras atención  telefónica
				de esta manera tu información se mantendrá segura.
			</Box>
			<br />
			<Box
				display={'flex'}
				justifyContent={'center'}
				flexDirection={matches ? 'column' : 'row'}
				alignItems={matches ? 'center' : ''}
			>
				<Box margin={'20px 10px'} width={matches ? '80%' : 250}>
					<CustomButtom
						label={'Finalizar'}
						onClick={() => navigate('/')}
						variant="solid"
					/>
				</Box>
			</Box>
			<br />
		</>
	);
};

export default NipActivationChange;
