import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Grid, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Clear, Search } from '@mui/icons-material';
import { UseStyle } from './Styles';
import CustomButton from '../../../components/Button';
import ModalViewDoc from './ModalViewDoc';

interface IFile {
	file1: File | null;
	file2: File | null;
}

interface Props {
	setPage: (arg: number) => void;
}

const listOficial = [
	'INE / IFE del titular',
	'Pasaporte del titular',
	'Cartilla militar del titular',
	'Cédula profesional del titular',
	'Forma de calidad migratoria 2',
	'Forma de calidad migratoria 3',
];

const listObligatorio = ['XXXXXX', 'XXXXXXX'];

const AttachDocumentForm = ({ setPage }: Props) => {
	const navigate = useNavigate();
	const classes = UseStyle();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [openModal, setOpenModal] = useState<File | null>(null);
	const [filedoc, setFileDoc] = useState<IFile>({
		file1: null,
		file2: null,
	});

	const handleFile = (e: any) => {
		const { files, name } = e.target;
		if (files) {
			setFileDoc({
				...filedoc,
				[name]: files[0],
			});
		}
	};

	const deleteFile = (name: string) => {
		setFileDoc({
			...filedoc,
			[name]: null,
		});
	};

	return (
		<Paper style={{ padding: 10 }}>
			<ModalViewDoc open={openModal} setOpen={setOpenModal} />

			<Grid container columns={12} padding={matches ? '20px 20px' : '40px 80px'}>
				<Grid item xs={12} md={6} fontWeight={700}>
					Número de caso
				</Grid>
				<Grid item xs={12} md={6} textAlign={matches ? 'initial' : 'end'}>
					0123456789
				</Grid>
				<Grid item xs={12} md={12} margin={'10px 0px'}>
					<Divider />
				</Grid>
				<Grid item xs={12} md={12} fontWeight={700} marginBottom={1}>
					<p style={{ margin: 0 }}>
						Estos son los documentos que debes adjuntar son:
					</p>
				</Grid>
				<Grid item xs={12} md={6}>
					<p style={{ margin: 0, fontWeight: 700 }}>
						Identificación oficial vigente
					</p>
					<ul style={{ color: '#7F7F7F' }}>
						{listOficial.map((element, index) => (
							<li key={index}>{element}</li>
						))}
					</ul>
				</Grid>
				<Grid item xs={12} md={6}>
					<p style={{ margin: 0, fontWeight: 700 }}>
						Otros documentos obligatorios
					</p>
					<ul style={{ color: '#7F7F7F' }}>
						{listObligatorio.map((element, index) => (
							<li key={index}>{element}</li>
						))}
					</ul>
				</Grid>
				<Grid item xs={12} md={12} margin={'10px 0px'}>
					<Divider />
				</Grid>
				<Grid item xs={12} md={12}>
					<div className={classes.inputFile}>
						<p>Identificación oficial vigente</p>
						<span style={{ width: matches ? '100%' : 'auto' }}>
							{filedoc.file1?.name || 'No se eligió ningún archivo'}
						</span>
						{filedoc.file1 ? (
							<>
								<label
									onClick={() => deleteFile('file1')}
									style={{
										cursor: 'pointer',
									}}
								>
									<Clear style={{ fontSize: 17 }} />
									Eliminar archivo
								</label>

								<label
									onClick={() => setOpenModal(filedoc.file1)}
									style={{
										cursor: 'pointer',
									}}
								>
									<Search style={{ fontSize: 17 }} />
									Ver archivo
								</label>
							</>
						) : (
							<label
								style={{
									cursor: 'pointer',
								}}
							>
								<input
									type="file"
									accept="*"
									name="file1"
									onChange={handleFile}
									style={{ display: 'none' }}
								/>
								Elegir archivo
							</label>
						)}
					</div>
				</Grid>
				<Grid item xs={12} md={12} margin={'10px 0px'}>
					<Divider />
				</Grid>
				<Grid item xs={12} md={12}>
					<div className={classes.inputFile}>
						<p>Documento obligatorio</p>
						<span style={{ width: matches ? '100%' : 'auto' }}>
							{filedoc.file2?.name || 'No se eligió ningún archivo'}
						</span>

						{filedoc.file2 ? (
							<>
								<label
									onClick={() => deleteFile('file2')}
									style={{
										cursor: 'pointer',
									}}
								>
									<Clear style={{ fontSize: 17 }} />
									Eliminar archivo
								</label>

								<label
									onClick={() => setOpenModal(filedoc.file2)}
									style={{
										cursor: 'pointer',
									}}
								>
									<Search style={{ fontSize: 17 }} />
									Ver archivo
								</label>
							</>
						) : (
							<label
								style={{
									cursor: 'pointer',
								}}
							>
								<input
									type="file"
									accept="*"
									name="file2"
									onChange={handleFile}
									style={{ display: 'none' }}
								/>
								Elegir archivo
							</label>
						)}
					</div>
				</Grid>
				<Grid item xs={12} md={12}>
					<div
						style={{
							display: 'flex',
							flexDirection: matches ? 'column-reverse' : 'initial',
							justifyContent: 'center',
							marginTop: 30,
						}}
					>
						<CustomButton
							styles={{
								width: matches ? 'auto' : 250,
								margin: '10px 10px',
								display: 'inline-flex',
							}}
							label={'Cancelar'}
							variant="outlined"
							onClick={() => navigate('/')}
						/>

						<CustomButton
							disabled={filedoc.file2 ? false : true}
							styles={{
								width: matches ? 'auto' : 250,
								margin: '10px 10px',
								display: 'inline-flex',
							}}
							label={'Continuar'}
							variant="solid"
							onClick={() => setPage(3)}
						/>
					</div>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default AttachDocumentForm;
