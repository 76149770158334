import { useState } from 'react';
import { MIUC_PROCESS_VIEWS } from '../../constants/process';
import { Box, Grid, Paper } from '@mui/material';
import ViewPoints from '../../components/ViewPoints';
import FlowCard from '../../components/FlowCard';
import { SelectionResume } from '../../components/SelectionResume/selectionResume';
import { useNavigation } from '../../hooks/useNavigation';
import { MejorarViviendaTable } from './views/mejorarViviendaTable';
import { InformativeContent } from '../../components/InformativeContent/informativeContent';
import FollowSteps from '../../components/FollowSteps';
import ResumeCard from '../../components/ResumeCard';

export const AmpliarReparar = () => {
	const { calcProgress, currentView, dataProcessController } =
		useNavigation();

  const showViewPoints = ![
    MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
    MIUC_PROCESS_VIEWS.NEXT_STEPS,
  ].includes(currentView);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={8}>
				<Paper>
					{currentView === MIUC_PROCESS_VIEWS.INFORMATIVE_CONTENT && (
						<InformativeContent />
					)}
					{currentView === MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT && (
						<MejorarViviendaTable />
					)}
					{currentView === MIUC_PROCESS_VIEWS.SUMMARY_REQUEST && (
						<SelectionResume />
					)}
				</Paper>
				{currentView === MIUC_PROCESS_VIEWS.NEXT_STEPS && (
					<FollowSteps guideLink={require('../../../../assets/files/guia-pasos-a-seguir/GPSMEJORAVIT.pdf')} />
				)}
			</Grid>
			<Grid item xs={12} md={4}>
				{showViewPoints && (
					<Box sx={{ textAlign: 'center' }} mb={2}>
						<ViewPoints showPoints={false}/>
					</Box>
				)}
				{currentView === MIUC_PROCESS_VIEWS.NEXT_STEPS && (
					<Box sx={{ textAlign: 'center' }} mb={2}>
						<ResumeCard />
					</Box>
				)}

				{currentView !== MIUC_PROCESS_VIEWS.INFORMATIVE_CONTENT && (
					<Box>
						<FlowCard
							title="Tu selección"
							flow={dataProcessController.processInfo?.selection}
							progress={calcProgress()}
						/>
					</Box>
				)}
			</Grid>
		</Grid>
	);
};
