import { useContext, useEffect, useState } from 'react';
import CustomButton from '../../../components/Button';
import CustomAlert from '../../../components/CustomAlert';
import ModalLoading from '../../../components/ModalLoading';
import TextInput from '../../../components/TextInput';
import { dataReturnSavingsContext } from '../contextData';
import { useStyles } from '../style';

const SelectTypePensionPage = () => {
	const style = useStyles();
	const [typePension, setTypePension] = useState<string>('');
	const [disabledButton, setDisabledButton] = useState<boolean>(true);
	const { handlePrevPage, loading, alert, setAlert, handleValidaPension } =
		useContext(dataReturnSavingsContext);

	useEffect(() => {
		setDisabledButton(typePension.length > 0 ? false : true);
	}, [typePension]);

	return (
		<div>
			<ModalLoading loading={loading}></ModalLoading>
			<span className={style.TextBlack_H3}>Tipo de pensión </span>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divNRP}>
					<TextInput
						data-testid="PensionTypeInput"
						id="PensionType"
						name="PensionTypeInput"
						sx={{ color: 'red' }}
						value={typePension}
						placeholder={'Escribe tu tipo de pensión'}
						onChange={(event) => {
							const { value } = event.target;
							setTypePension(value.toUpperCase());
							setAlert({
								show: false,
								message: '',
								severity: alert.severity,
							});
						}}
					/>
				</div>
			</div>
			<div className={style.divAlert}>
				<CustomAlert
					message={alert.message}
					show={alert.show}
					severity={alert.severity}
					onClose={() => {
						setAlert({
							show: false,
							message: '',
							severity: alert.severity,
						});
					}}
				/>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divButtons}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={handlePrevPage}
							variant="outlined"
							styles={{
								width: '260px',
								height: '100%'
							}}
							label="Regresar"
						/>
					</div>
					<div className={style.Buttons}>
						<CustomButton
							onClick={() => handleValidaPension(typePension)}
							variant="solid"
							styles={{
								width: '260px',
							}}
							label="Continuar"
							disabled={disabledButton}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectTypePensionPage;
