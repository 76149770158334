import { Link } from '@mui/material';

export const mappedStageErrors = (stageID: number) => {
	switch (stageID) {
		// ERROR FRAUDE DEFUNCION
		case 4:
			return (
				<>
					Escribe tus datos correctos. Los datos que escribiste no
					corresponden a los que hay registrados en tu evaluación
					integral. Verifica la información del último estado de
					cuenta de tu tarjeta de crédito, de tu crédito de automóvil
					o del crédito hipotecario; los datos deben ser capturados
					exactamente como ahí se presentan.
					<br />
					Si tienes problemas para consultar, visita la siguiente
					dirección para verificar tu historial crediticio
					<br />
					Consulta de reporte de crédito.
					<br />
					Cuando ingreses a la “Solicitud de reporte” te sugerimos no
					elegir la opción de “Incluir MI SCORE”, quitando la marca
					del cuadro. De esta manera el reporte no tendrá costo.
				</>
			);
		// ERROR FRAUDE DEFUNCION
		case 5:
			return (
				<>
					Escribe tus datos correctos. Los datos que escribiste no
					corresponden a los que hay registrados en tu evaluación
					integral. Verifica la información del último estado de
					cuenta de tu tarjeta de crédito, de tu crédito de automóvil
					o del crédito hipotecario; los datos deben ser capturados
					exactamente como ahí se presentan.
					<br />
					Si tienes problemas para consultar, visita la siguiente
					dirección para verificar tu historial crediticio
					<br />
					Consulta de reporte de crédito.
					<br />
					Cuando ingreses a la “Solicitud de reporte” te sugerimos no
					elegir la opción de “Incluir MI SCORE”, quitando la marca
					del cuadro. De esta manera el reporte no tendrá costo.
				</>
			);
		// ERROR DATOS INCORRECTOS
		case 7:
			return (
				<>
					Escribe tus datos correctos. Los datos que escribiste no
					corresponden a los que hay registrados en tu evaluación
					integral. Verifica la información del último estado de
					cuenta de tu tarjeta de crédito, de tu crédito de automóvil
					o del crédito hipotecario; los datos deben ser capturados
					exactamente como ahí se presentan.
					<br />
					Si tienes problemas para consultar, visita la siguiente
					dirección para verificar tu historial crediticio
					<br />
					<Link
						href="https://www.burodecredito.com.mx/"
						target="_blank"
					>
						Consulta de reporte de crédito.
					</Link>
					<br />
					Cuando ingreses a la “Solicitud de reporte” te sugerimos no
					elegir la opción de “Incluir MI SCORE”, quitando la marca
					del cuadro. De esta manera el reporte no tendrá costo.
				</>
			);
		// ERROR ENDEUDAMIENTO
		case 8:
			return (
				<>
					La consulta de tu historial crediticio muestra un nivel de
					endeudamiento alto y un comportamiento de pago no adecuado.{' '}
					Te sugerimos obtener tu reporte de crédito especial en{' '}
					<Link
						href="https://www.burodecredito.com.mx/"
						target="_blank"
					>
						(Buró de Crédito)
					</Link>{' '}
					sección consumidor para conocer el detalle, y con esto,
					realizar la aclaración con la entidad de crédito
					correspondiente en su caso.
				</>
			);
		// SERVICIO NO DISPONIBLE
		case 0:
			return (
				<>
					Los servicios de verificación de historial crediticio no
					están disponibles, intenta más tarde.
				</>
			);
		default:
			return (
				<>
					Los datos introducidos están mal o incompletos, por favor
					intentalo de nuevo
				</>
			);
	}
};

export const ERROR_MESSAGE = {
	NO_VERIFICATION_SERVICE: 'Los servicios de verificación de historial crediticio no están disponibles, intenta más tarde',
	BAD_INFORMATION: 'Los datos introducidos están mal o incompletos, por favor inténtalo de nuevo',
	NO_SERVICE: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
}

export interface IButtons {
  label: string;
  tooltip?: string;
  value: string;
  container?: boolean;
}

export const creditOptionsInfo: IButtons[] = [
  {
    label: 'Tarjeta de crédito',
    tooltip: 'Ingresa los últimos cuatro dígitos de tu número de tarjeta de crédito, este crédito debe estar VIGENTE en tu Reporte de Buró de Crédito ejemplo XXXX XXXX XXXX 1234”. En caso de tener varias tarjetas es indistinto cuál utilices debido a que esta información solo se usa para comprobar tu identidad.',
    value: 'tieneTDC',
    container: true
  },
  {
    label: 'Crédito automotriz',
    value: 'tieneAuto',
    tooltip: 'Márcalo únicamente si el crédito fue aperturado en una agencia automotriz o en un banco bajo el concepto de crédito automotriz, y si fue aperturado y/o cerrado en los últimos dos años.'
  },
  {
    label: 'Crédito hipotecario',
    value: 'tieneHipotecario',
    tooltip: 'Márcalo únicamente si el crédito hipotecario está VIGENTE, es decir, si aún lo estás pagando. Si el crédito hipotecario fue otorgado por Fovissste, no se debe indicar.'
  },
];

export const list = [
  <>
    Los datos que ingresarás para realizar esta consulta comprueban tu
    identidad al compararlos con la información que existe en Buró de
    Crédito, por lo que para continuar deben coincidir.
  </>,
  <>
    Dispones de tres intentos cada siete días, antes de iniciar te
    recomendamos obtener tu Reporte de Crédito Especial que emite Buró
    de Crédito en{' '}
    <Link
      sx={{ color: '#D1001F' }}
      href="https://www.burodecredito.com.mx"
      target="_blank"
    >
      www.burodecredito.com.mx
    </Link>
    , donde también podrás aclarar cualquier duda relacionada con tu
    reporte.
  </>,
  <>
    En caso de no poder obtener el Reporte de Crédito Especial con Buró
    de Crédito, obtenlo de manera gratuita en CONDUSEF.
  </>,
];

export const months = [
	'enero',
	'febrero',
	'marzo',
	'abril',
	'mayo',
	'junio',
	'julio',
	'agosto',
	'septiembre',
	'octubre',
	'noviembre',
	'diciembre',
];

export const addressForm = [
	{
		label: 'C.P.',
		name: 'cp',
		id: 'credit-request-zipcode',
		required: true,
		isNumber: true,
    tooltip: 'El código postal deberá coincidir con el Estado y Municipio donde tengas el crédito que estás colocando como referencia.'
	},
	{
		label: 'Estado',
		name: 'denominacionSociedad',
		id: 'credit-request-state',
		disabled: true,
	},
	{
		label: 'Municipio o delegación',
		name: 'delegacion',
		id: 'credit-request-delegation',
		disabled: true,
	},
	{
		label: 'Colonia',
		name: 'colonia',
		id: 'credit-request-colony',
		required: true,
	},
	{
		label: 'Calle',
		name: 'calle',
		id: 'credit-request-street',
		required: true,
	},
	{
		label: 'No. Ext.',
		name: 'numeroExterior',
		id: 'credit-request-no-ext',
		required: true,
	},
	{
		label: 'No. Int.',
		name: 'numeroInterior',
		id: 'credit-request-no-int',
	},
	{
		label: 'Teléfono',
		name: 'telefono',
		id: 'credit-request-phone',
		isNumber: true,
		required: true,
	},
];

export const WARNING_MESSAGE = 'Considera que al no autorizar, el monto del resultado de tu precalificación será con un monto menor.';