const Grounds = () => {
	return (
		<>
			<p>
				Usa una parte de tus ahorros para poner el terreno a tu nombre,
				inscribirlo ante el Registro Público de la Propiedad, o bien,
				subdividir predios.
			</p>
			<p>
				Solicita a un notario el presupuesto para hacer este trámite y
				preséntalo al momento de inscribir tu crédito.
			</p>
		</>
	);
};

export default Grounds;
