import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
    display: 'flex',
		[theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },

    '&> div': {
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      color: theme.palette.info.main,
      '&> label': {
        fontWeight: 'bold'
      }
    },
	},
}));