import { Box, useMediaQuery } from '@mui/material';
import Eyelashes from './eyelashes';
import theme from '../../config/theme';

interface IProps {
	components: Array<{
		title: string;
		component: JSX.Element[] | JSX.Element;
	}>;
	selectIndex?: number;
	activeStep?: boolean;
	setSelectIndex?: (arg: number) => void;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
};

const TabsCustom = ({
	components,
	selectIndex,
	setSelectIndex,
	activeStep,
}: IProps) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const countTabs = components.length;

	return (
		<Box
			sx={{
				width: '100%',
				display: 'grid',
				gridTemplateColumns: matches
					? '100%'
					: `repeat(${countTabs}, 1fr)`,
			}}
		>
			{components.map((c, i) => (
				<Eyelashes
					label={c.title}
					key={i}
					index={i}
					activeIndex={selectIndex}
					handleChange={setSelectIndex}
					activeStep={activeStep}
					countTabs={countTabs}
				/>
			))}

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					background: '#fff',
					padding: '10px',
					border: '1px solid rgba(0,0,0,0.1)',
					borderRadius: '5px 5px 10px 10px',
					gridColumnStart: matches ? 0 : 1,
					gridColumnEnd: matches ? 0 : countTabs + 1,
					order: matches ? countTabs : undefined,
					boxShadow: matches
						? undefined
						: '0px 10px 15px -3px rgba(0,0,0,0.1)',
				}}
			>
				{components.map((c, i) => (
					<TabPanel value={selectIndex || 0} key={i} index={i}>
						{c.component}
					</TabPanel>
				))}
			</Box>
		</Box>
	);
};

export default TabsCustom;
