import { Info } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Link,
} from '@mui/material';
import TooltipCustom from '../../../../../../components/TooltipCustom';
import { centeredItems } from '../../styles';
import { formatMoney } from '../../../../../../utils/validators';
// Assets
import { useStyles } from '../../styles';
import MovementsHeader from './MovementsHeader';
import MovementsFooter from './MovementsFooter';
import ModalLoading from '../../../../../../components/ModalLoading';
import { useState } from 'react';
import { RootState } from '../../../../../../store';
import { encodeAllJSONData } from '../../../../../../utils/encrypt';
import iconoPDF from '../../../../../../assets/img/icono-descarga.png';
import {
	SaldoMCIResponseModel,
	apiMIUC,
	IDataconsultarAgroconsultaRes,
	IAgroindustryDatosGenerales,
} from '../../../../../../api/modules/MIUC';
import { RETURN_CODE } from '../../../../constants/returnCode';
import { INTERNAL_MESSAGES } from '../../../../../../config/messageCatalog';
import CustomAlert from '../../../../../../components/CustomAlert';

interface props {
	datosGenerales: IAgroindustryDatosGenerales;
	handlerSubmit: () => void;
	agroData: IDataconsultarAgroconsultaRes;
	balanceData: SaldoMCIResponseModel;
}

const Movements = ({
	datosGenerales,
	handlerSubmit,
	agroData,
	balanceData,
}: props) => {
	const styles = useStyles();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const { user } = useSelector((state: RootState) => state.session);

	const getDateFormat = (date: string) => {
		if (date !== '') {
			const fecha = date.split('-');
			const fechaFormateada = `${fecha[2]}-${fecha[1]}-${fecha[0]}`;
			return fechaFormateada;
		} else {
			const fechaFormateada = '00-00-0000'; // FECHA ASIGNADA SOLO PARA PRUEBAS
			return fechaFormateada;
		}
	};

	const getFullName = (): string => {
		let nombre: string = '';
		if (
			balanceData.nombre &&
			balanceData.apellidoPaterno &&
			balanceData.apellidoMaterno
		) {
			nombre = `${balanceData.nombre} ${balanceData.apellidoPaterno} ${balanceData.apellidoMaterno}`;
		} else {
			nombre = `${user?.nombres} ${user?.apPaterno} ${user?.apMaterno}`;
		}
		return nombre;
	};

	const getPdfMovimientos = async () => {
		try {
			setLoading(true);
			const dataMovimientosPDF = {
				pdfParamFeInsc: getDateFormat(agroData.fechaInscripcion),
				pdfParamNombre: getFullName(),
				pdfParamNss: user?.nss.toString(),
				pdfParamNuEdoPat: agroData.numEdoPat,
				pdfParamAhoMen: agroData.ahorroMensual,
				pdfParamFeCor: getDateFormat(agroData.fechaCorte),
				paramTotalDepositos: datosGenerales.saldoAhorro,
				pdfEstado: agroData.sitioRegistrado,
				paramDepositos: agroData.pagos,
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(dataMovimientosPDF) },
				user?.k || ''
			);
			const { code, data } = await apiMIUC.getMovimientoPDF(
				dataEncripted
			);
			if (code === RETURN_CODE.CORRECT) {
				const { pdf } = data.result;
				if (pdf) {
					let link = document.createElement('a');
					link.download = 'consulta_movimientos.pdf';
					link.href = 'data:application/octet-stream;base64,' + pdf;
					link.click();
				}
				setMessage('');
			}
		} catch (error) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	const getPdfRegistro = async () => {
		try {
			setLoading(true);
			const dataPdfRegistro = {
				pdfParamFeInsc: getDateFormat(agroData.fechaInscripcion),
				pdfParamNombre: getFullName(),
				pdfParamNss: user?.nss.toString() || '',
				pdfParamAhoMen: agroData.ahorroMensual,
				paramTotalDepositos: datosGenerales.saldoAhorro,
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(dataPdfRegistro) },
				user?.k || ''
			);
			const { code, data } = await apiMIUC.getComprobanteRegistroPDF(
				dataEncripted
			);
			if (code === RETURN_CODE.CORRECT) {
				const { pdf } = data.result;
				if (pdf) {
					let link = document.createElement('a');
					link.download = 'comprobante_registro.pdf';
					link.href = 'data:application/octet-stream;base64,' + pdf;
					link.click();
				}
				setMessage('');
			}
		} catch (error) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<div className={styles.alignLeft}>
				<MovementsHeader
					sitioRegistrado={datosGenerales.sitioRegistrado}
				></MovementsHeader>
			</div>
			<Table>
				<TableBody>
					<TableRow>
						<TableCell className={styles.cellMovements}>
							<label style={centeredItems}>
								Tu ahorro mensual
								<TooltipCustom
									arrow
									title="Monto mínimo que se debe cubrir por mes"
								>
									<Info
										color="primary"
										className={styles.helpPanelMovements}
									/>
								</TooltipCustom>
							</label>
						</TableCell>
						<TableCell align="center">
							<label
								style={{
									fontWeight:
										Number(
											datosGenerales.ahorroMensual.replaceAll(
												',',
												''
											)
										) > 0
											? 'bold'
											: 'normal',
								}}
							>
								{formatMoney(
									Number(
										datosGenerales.ahorroMensual.replaceAll(
											',',
											''
										)
									)
								)}
							</label>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className={styles.cellMovements}>
							<label style={centeredItems}>
								Tu ahorro realizado
								<TooltipCustom
									arrow
									title="Monto total ahorrado"
								>
									<Info
										color="primary"
										className={styles.helpPanelMovements}
									/>
								</TooltipCustom>
							</label>
						</TableCell>
						<TableCell align="center">
							<label
								style={{
									fontWeight:
										Number(
											datosGenerales.saldoAhorro.replaceAll(
												',',
												''
											)
										) > 0
											? 'bold'
											: 'normal',
								}}
							>
								{formatMoney(
									Number(
										datosGenerales.saldoAhorro.replaceAll(
											',',
											''
										)
									)
								)}
							</label>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className={styles.cellMovements}>
							<label style={centeredItems}>
								Periodo de ahorro
								<TooltipCustom
									arrow
									title="Total de meses realizando ahorro"
								>
									<Info
										color="primary"
										className={styles.helpPanelMovements}
									/>
								</TooltipCustom>
							</label>
						</TableCell>
						<TableCell align="center">
							<label style={centeredItems}>
								{datosGenerales.numeroMesesContinuos} meses
							</label>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Grid
				container
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: 2,
				}}
				spacing={2}
			>
				<Grid item sm={12} md={6}>
					<Link
						underline="none"
						onClick={() => getPdfMovimientos()}
						className={styles.linkMovements}
					>
						<span>Consulta tus movimientos</span>
						<TooltipCustom title="Consulta tus movimientos" arrow>
							<div>
								<img
									src={iconoPDF}
									alt="iconoPdf"
									className={styles.downloadPdf}
								/>
							</div>
						</TooltipCustom>
					</Link>
				</Grid>
				{datosGenerales.sitioRegistrado !== 'TE' && (
					<Grid item sm={12} md={6}>
						<Link
							underline="none"
							onClick={() => getPdfRegistro()}
							className={styles.linkMovements}
						>
							<span>Comprobante de registro</span>
							<TooltipCustom
								title="Comprobante de registro"
								arrow
							>
								<div>
									<img
										src={iconoPDF}
										alt="iconoPdf"
										className={styles.downloadPdf}
									/>
								</div>
							</TooltipCustom>
						</Link>
					</Grid>
				)}
				<CustomAlert
					message={message}
					show={message ? true : false}
					severity={'warning'}
				/>
			</Grid>
			<br />
			<div className={styles.divButtons}>
				<MovementsFooter
					datosGenerales={datosGenerales}
					handlerSubmit={handlerSubmit}
				></MovementsFooter>
			</div>
		</>
	);
};

export default Movements;
