/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { encodeAllJSONData } from '../../../utils/encrypt';
import {
	DESTINO_PERFILAMIENTO_ENUM,
	IDataconsultarAgroconsultaRes,
	IDestino,
	MIUCUserResponseModel,
	apiMIUC,
} from '../../../api/modules/MIUC';
import { RETURN_CODE } from '../constants/returnCode';
import { IProcessController } from '../interfaces/processController';
import { MIUC_MODELS } from '../constants/models';
import { actions } from '../../../store/modules/miuc';
import { actions as actionsLoading } from '../../../store/modules/loading';
import { useNavigate } from 'react-router-dom';
import { MIUC_PRODUCTS, PRODUCT_LINES } from '../constants/products';
import { ESTADOS_VALIDACION } from '../../../config/miucConstants';
import { isEmpty } from '../../../utils/validators';
import {
	msgErrorMevi,
	msgErrorMeviCrediterreno,
} from '../../CreditRequest/MIUC/utils';
import { STAGE_ID } from '../constants/stageId';
import { mappedStageErrors } from '../utils/buroForm.utils';
import { actions as saldAction } from '../../../store/modules/saldo';

interface IProps {
	initProcess: boolean;
	initSaldo?: boolean;
}

export const useProfile = ({ initProcess, initSaldo }: IProps) => {
	const [errorMesage, setErrorMessage] = useState<string | JSX.Element>('');
	const [isProfileDone, setIsProfileDone] = useState<boolean>(false);
	const [saldoSARTotal, setSaldoSARTotal] = useState<string>('');
	const [balanceMessage, setBalanceMessage] = useState('');
	const { user } = useSelector((state: RootState) => state.session);
	const { dataProcessController } = useSelector(
		(state: RootState) => state.miuc
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showModalTemp, setShowModalTemp] = useState(false);
	const [navigateInfo, setNavigateInfo] = useState<{
		url: string;
		options?: any;
	}>();
	const [redirecting] = useState(
		dataProcessController.processInfo?.redirecting || false
	);
	const { saldo } = useSelector((state: RootState) => state.saldo);
	const [salioDeAgro, setSalioDeAgro] = useState(
		dataProcessController?.processInfo?.salioDeAgroindustria
	);
	const [savedAgroData, setSavedAgroData] = useState(
		dataProcessController.agroData
	);

	useEffect(() => {
		if (initProcess) {
			clearReduxData();
			getBalance();
		}
		if (initSaldo) {
			getBalance();
		}
	}, []);

	useEffect(() => {
		if (navigateInfo !== undefined && showModalTemp === false) {
			navigate(navigateInfo.url, navigateInfo.options);
		}
	}, [navigateInfo, showModalTemp]);

	const clearReduxData = async () => {
		if (salioDeAgro) {
			setSalioDeAgro(salioDeAgro);
			await dispatch(actions.clearData());
		} else {
			await dispatch(actions.clearData());
		}
	};

	const getInitialProfile = async (value: {
		model: MIUC_MODELS;
		code: string;
		name: string;
		estadoMunicipio?: string;
		line?: string;
	}) => {
		try {
			dispatch(actionsLoading.startLoading());
			const data = await apiMIUC.getProfile();
			if (data.data?.bajaTemporal === '1') {
				setShowModalTemp(true);
			}
			if (redirecting) {
				return dispatch(actionsLoading.stopLoading());
			}
			if (data.code === RETURN_CODE.CORRECT) {
				setIsProfileDone(true);
				if (
					data.data.destino &&
					data.data.destino !== DESTINO_PERFILAMIENTO_ENUM.MIUC &&
					data.data.destino !==
						DESTINO_PERFILAMIENTO_ENUM.SOLICITUD_CANCELADA &&
					data.data.destino in DESTINO_PERFILAMIENTO_ENUM
				) {
					setNavigateInfo({
						url: '/precalificacion-status',
						options: {
							state: {
								params: { ...data.data },
							},
						},
					});
					dispatch(actionsLoading.stopLoading());
				} else {
					dispatch(actionsLoading.stopLoading());
				}
			} else {
				setErrorMessage(data.message);
				dispatch(actionsLoading.stopLoading());
			}
		} catch (error: any) {
			setErrorMessage(error.description);
			dispatch(actionsLoading.stopLoading());
		}
	};

	const getInitialProfileHome = async (value: {
		model: MIUC_MODELS;
		code: string;
		name: string;
		estadoMunicipio?: string;
		line?: string;
	}) => {
		try {
			dispatch(actionsLoading.startLoading());
			const data = await apiMIUC.getProfile();
			if (data.data?.bajaTemporal === '1') {
				setShowModalTemp(true);
			}
			if (data.code === RETURN_CODE.CORRECT) {
				setIsProfileDone(true);
				if (
					data.data.destino === DESTINO_PERFILAMIENTO_ENUM.MIUC ||
					data.data.destino ===
						DESTINO_PERFILAMIENTO_ENUM.SOLICITUD_CANCELADA
				) {
					getMIUCProfile(value);
				} else if (
					data.data.destino &&
					data.data.destino in DESTINO_PERFILAMIENTO_ENUM
				) {
					setNavigateInfo({
						url: '/precalificacion-status',
						options: {
							state: {
								params: { ...data.data },
							},
						},
					});
					dispatch(actionsLoading.stopLoading());
				} else {
					dispatch(actionsLoading.stopLoading());
				}
			} else {
				setErrorMessage(data.message);
				dispatch(actionsLoading.stopLoading());
			}
		} catch (error: any) {
			setErrorMessage(error.description);
			dispatch(actionsLoading.stopLoading());
		}
	};

	const getBalance = async () => {
		try {
			if (!saldo) {
				const result = await apiMIUC.getBalance();
				if (result.code === RETURN_CODE.CORRECT) {
					dispatch(saldAction.setSaldo(result.data.saldoSARTotal));
					setSaldoSARTotal(result.data.saldoSARTotal);
				} else {
					setBalanceMessage(result.message);
				}
				return;
			}
			setSaldoSARTotal(saldo);
		} catch (error) {}
	};

	const validateAgro = (
		edoMun: String,
		dataAgro: IDataconsultarAgroconsultaRes,
		tipoTrabajador: String,
		codePuntos: String
	) => {
		return (
			((edoMun === ESTADOS_VALIDACION.JALISCO ||
				edoMun === ESTADOS_VALIDACION.MICHOACAN) &&
				dataAgro.sitioRegistrado === 'SR' &&
				tipoTrabajador === '4' &&
				codePuntos === RETURN_CODE.NO_POINTS) ||
			dataAgro.sitioRegistrado === 'AC' ||
			dataAgro.sitioRegistrado === 'TX' ||
			dataAgro.sitioRegistrado === 'CA' ||
			(dataAgro.sitioRegistrado === 'TE' && !salioDeAgro) ||
			(dataAgro.sitioRegistrado === 'BJ' && !salioDeAgro)
		);
	};

	const getErrorEstatusCrediTer = (
		titular: MIUCUserResponseModel
	): boolean => {
		return [4, 5, 8].includes(Number(titular?.stageId));
	};

	const getSituacionAgroReg = (
		titular: any,
		agroData: IDataconsultarAgroconsultaRes
	): boolean => {
		return (
			agroData.sitioRegistrado === 'AC' ||
			agroData.sitioRegistrado === 'TX' ||
			agroData.sitioRegistrado === 'CA' ||
			(agroData.sitioRegistrado === 'TE' &&
				titular?.agroFinalizado !== 'F') ||
			(agroData.sitioRegistrado === 'BJ' && titular?.agroBaja !== 'F')
		);
	};

	const errorMsg = `No es posible aplicar para esta modalidad, deberás
  regresar a la pantalla principal y seleccionar la opción
  Comprar vivienda, construir, ampliar o reparar mi
  vivienda o pagar mi hipoteca.`;

	const validateCreditTer = (
		titular: MIUCUserResponseModel,
		agroData: IDataconsultarAgroconsultaRes
	) => {
		const errorStatus = getErrorEstatusCrediTer(titular);
		if (errorStatus) {
			return msgErrorMeviCrediterreno(Number(titular.stageId));
		} else if (
			(agroData.sitioRegistrado === 'SR' &&
				titular?.tipoTrabajador === '4') ||
			getSituacionAgroReg(titular, agroData)
		) {
			return errorMsg;
		}
		return '';
	};

	const getDataAgro = async () => {
		if (!isEmpty(savedAgroData)) {
			return savedAgroData;
		}

		const data = user?.nss;
		const ENCODED_DATA = await encodeAllJSONData(
			{
				data: JSON.stringify(data),
			},
			user?.k || ''
		);
		const response = await apiMIUC.consultarAgroconsulta(ENCODED_DATA);
		let dataAgro: IDataconsultarAgroconsultaRes = response?.data;

		if (response?.code === RETURN_CODE.NO_RESGITRADO_AGRO) {
			dataAgro = { ...dataAgro, sitioRegistrado: 'SR' };
		} else if (
			dataAgro?.sitioRegistrado === 'TE' &&
			Number(dataAgro?.puntosTotalCorte) < 1080
		) {
			dataAgro = { ...dataAgro, sitioRegistrado: 'TX' };
		}
		setSavedAgroData(dataAgro);
		return dataAgro;
	};

	const getMIUCProfile = async (value: {
		model: MIUC_MODELS;
		code: string;
		name: string;
		estadoMunicipio?: string;
		line?: string;
	}) => {
		try {
			dispatch(actionsLoading.startLoading());
			const data = {
				modelo: value.model,
				estadoMunicipio: value.estadoMunicipio || '',
			};
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const result = await apiMIUC.getMIUCProfile(dataEncript);
			if (
				result.code === RETURN_CODE.CORRECT ||
				result.code === RETURN_CODE.NO_POINTS
			) {
				if (
					result.message.includes('SIN RELACION LABORAL') &&
					value.line === PRODUCT_LINES.MEJORA_HOGAR
				) {
					dispatch(actionsLoading.stopLoading());
					return setErrorMessage(result.message);
				}

				const destino = getDestiny(
					result.data.destinosAplicables,
					value.code
				);
				let creditUrl: string = value.name
					? value.name.replaceAll(' ', '-').toLowerCase()
					: '';
				const agroData = await getDataAgro();

				const edoMun = (
					result?.data?.titular?.estadoMunicipio || ''
				).substring(0, 2);
				const tipoTrabajador =
					result?.data?.titular?.tipoTrabajador || '';

				const lineas = [MIUC_PRODUCTS.COMPRA, MIUC_PRODUCTS.MEJORAVIT];

				const msgMevi = handleValidateBuro({
					titular: {
						...result.data.titular,
					},
					codigo: value.code,
					destino,
					agroData,
				});

				const lineasBC8 = [
					MIUC_PRODUCTS.COMPRA,
					MIUC_PRODUCTS.CONSTRUCCION,
					MIUC_PRODUCTS.HIPOTECA,
				];

				let lineaBC8 = false;

				if (
					result.data.titular.stageId === STAGE_ID.ENDEUDAMIENTO &&
					lineasBC8.includes(value.code)
				) {
					lineaBC8 = true;
				}

				if (
					value.code !== 'L2' &&
					agroData.sitioRegistrado !== 'SR' &&
					!salioDeAgro
				) {
					dispatch(actionsLoading.stopLoading());
					return setErrorMessage(
						`NO CUMPLES CON LOS REQUISITOS PARA LA OPCIÓN DE ${value.name.toUpperCase()}.`
					);
				}
				if (
					value.code === MIUC_PRODUCTS.CREDITERRENO &&
					result.data.titular.stageId === STAGE_ID.ENDEUDAMIENTO
				) {
					dispatch(actionsLoading.stopLoading());
					return setErrorMessage(
						mappedStageErrors(Number(result.data.titular.stageId))
					);
				}
				const saldo = result.data.titular.saldoSARTotal;
				if (
					value.code === MIUC_PRODUCTS.CREDITERRENO &&
					!value.estadoMunicipio
				) {
					// Se deja pasar la primera vez
				} else if (!!msgMevi) {
					dispatch(actionsLoading.stopLoading());
					return setErrorMessage(msgMevi);
				} else if (destino === DESTINY.ERROR_PAGE) {
					dispatch(actionsLoading.stopLoading());
					setErrorMessage(result.message);
				} else if (
					validateAgro(edoMun, agroData, tipoTrabajador, result.code)
				) {
					//TODO: Mensaje de error temporal. Es posible que cambie en base a:
					// Si quieren que se muestre un mensaje o  no al seleccionar una opcion
					// cuando es Agroindustria
					if (value.code === 'L2') {
						creditUrl = 'agroindustria';
					}
				} else if (
					lineas.includes(value.code) &&
					destino === DESTINY.SIN_PUNTOS
				) {
					creditUrl = 'sin-puntos';
				} else if (destino === DESTINY.SIN_RELACION) {
					if (!saldo) {
						dispatch(actionsLoading.stopLoading());
						return setErrorMessage(
							'Por el momento el servicio no se encuentra disponible, intenta más tarde'
						);
					} else if (Number(saldo) <= 0) {
						dispatch(actionsLoading.stopLoading());
						return setErrorMessage(
							`NO CUMPLES CON LOS CRITERIOS PARA ESTA OPCIÓN DE FINANCIAMIENTO.`
						);
					} else if (Number(saldo) * 0.9 < 4856.7) {
						dispatch(actionsLoading.stopLoading());
						return setErrorMessage(result.message);
					} else {
						creditUrl = 'sin-relacion-laboral';
					}
				}
				const info = {
					processInfo: {
						...dataProcessController.processInfo,
						credit: value.code,
						creditName: creditUrl,
						selectedState: value.estadoMunicipio || '',
						lineaBC8: lineaBC8,
						salioDeAgroindustria: salioDeAgro,
					},
					processData: {
						...dataProcessController.processData,
						productLine: value.line || '',
					},
					agroData: {
						...agroData,
					},
				};

				const dataFlag: IProcessController = {
					...dataProcessController,
					...result.data,
					...info,
					history: [info],
				};
				await dispatch(actions.setDataProcessController(dataFlag));

				await dispatch(actionsLoading.stopLoading());
				setSaldoSARTotal(saldo);
				dispatch(saldAction.setSaldo(saldo));

				if (
					initProcess &&
					(destino !== DESTINY.ERROR_PAGE ||
						value.code === MIUC_PRODUCTS.CREDITERRENO)
				) {
					setNavigateInfo({
						url: `/precalificacion-puntos/${creditUrl}`,
					});
				}
				return {
					code: result.code,
					message: errorMesage,
				};
			} else {
				dispatch(actionsLoading.stopLoading());
				return setErrorMessage(
					result?.message ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde'
				);
			}
		} catch (error: any) {
			dispatch(actionsLoading.stopLoading());
			return setErrorMessage(
				error?.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde'
			);
		}
	};

	const handleValidateBuro = ({
		codigo,
		destino,
		titular,
		agroData,
	}: {
		codigo: string;
		destino: number;
		titular: MIUCUserResponseModel;
		agroData: IDataconsultarAgroconsultaRes;
	}) => {
		const lineas = [
			MIUC_PRODUCTS.COMPRA,
			MIUC_PRODUCTS.CONSTRUCCION,
			MIUC_PRODUCTS.HIPOTECA,
			MIUC_PRODUCTS.AUTOPRODUCCION,
		];
		const stageIds = [
			STAGE_ID.FRAUDE_DEFUNCION,
			STAGE_ID.DEFUNCION,
			//STAGE_ID.ENDEUDAMIENTO,
		];

		if (codigo === MIUC_PRODUCTS.CREDITERRENO) {
			return validateCreditTer(titular, agroData);
		} else if (stageIds.includes(titular.stageId)) {
			if (codigo === MIUC_PRODUCTS.MEJORA_DIRECTA) {
				if (titular.stageId === STAGE_ID.ENDEUDAMIENTO) return '';
				return msgErrorMevi(Number(titular.stageId));
			} else if (lineas.includes(codigo)) {
				return msgErrorMevi(Number(titular.stageId));
			}
		}
	};

	const DESTINY = {
		SIN_PUNTOS: 0,
		SIN_RELACION: 1,
		MIUC: 2,
		ERROR_PAGE: 3,
	};

	const getDestiny = (destinos: IDestino[], linea: string) => {
		const sinPuntos = [
			MIUC_PRODUCTS.CREDITO_SEGURO,
			MIUC_PRODUCTS.APOYO_INFONAVIT,
		];
		const sinRelacion = [MIUC_PRODUCTS.CICB, MIUC_PRODUCTS.MEJORA_DIRECTA];

		if (isEmpty(destinos)) return DESTINY.ERROR_PAGE;

		for (const destino of destinos) {
			if (sinRelacion.includes(destino.codigo)) {
				if (linea === MIUC_PRODUCTS.HIPOTECA) return DESTINY.ERROR_PAGE;
				return DESTINY.SIN_RELACION;
			} else if (sinPuntos.includes(destino.codigo)) {
				if (linea === MIUC_PRODUCTS.HIPOTECA) return DESTINY.ERROR_PAGE;
				return DESTINY.SIN_PUNTOS;
			}
		}
		return DESTINY.MIUC;
	};

	return {
		getInitialProfile,
		getInitialProfileHome,
		getMIUCProfile,
		isProfileDone,
		saldoSARTotal,
		errorMesage,
		balanceMessage,
		showModalTemp,
		setShowModalTemp,
		salioDeAgro,
		savedAgroData,
		getDataAgro,
	};
};
