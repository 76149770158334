import React from 'react';
import TextInput from '../../../../components/TextInput';
import { pattern } from '../../utils';

const SuggestionForm = ({ sugg = '', setSugg = () => {} }: any) => {
	const handleChange = ({ target }: any) => {
		const { value } = target;
		if (value.length > 1500) return;
		setSugg(value);
	};

	return (
		<>
			<h5 style={{ fontSize: 15 }}>
				Escribe la información que se solicita
			</h5>
			<div className="form-container">
				<TextInput
					rows={6}
					multiline
					isRequired
					value={sugg}
					name="suggestion"
					onChange={handleChange}
					id="follow-complaint-suggestion"
					placeholder="Máximo 1,500 caracteres"
					label="Describe tu felicitación, sugerencia o petición"
					helperText={!pattern.test(sugg) && 'Caracter no permitido'}
				/>
				<span className="notas-helper">
					Te quedan {1500 - sugg.length} caracteres.
				</span>
			</div>
		</>
	);
};

export default SuggestionForm;
