import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
	paperContainer: {
		width: '100%',
		height: 500,
		marginBottom: 30,
		position: 'relative',

		[theme.breakpoints.down('md')]: {
			height: 250,
		}
	},
	iframePlayer: {
		width: '100%',
		height: '100%',
		border: 'none',
		outline: 'none',
	},
	buttonContainer: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 50,
		borderWidth: 0,
		padding: '5px 20px',
		background: theme.palette.primary.main,
		color: '#fff',
		cursor: 'pointer',
		marginTop: '30px',

		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			transition: '0.5s',
		},

		[theme.breakpoints.down('md')]: {
			fontSize: 14,
			marginTop: '10px',
			fontWeight: 700,
		},
	},
	finishLearn: {
		background: 'rgba(91,11,47,0.9)',
		color: '#fff',
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		left: 0,
		top: 0,
		flexDirection: 'column',
		textAlign: 'center',

		[theme.breakpoints.down('md')]: {
			padding: '0px 5px',
		},

		'& > h1': {
			display: 'flex',
			alignItems: 'center',
			fontSize: 40,
			fontWeight: 500,
			
			[theme.breakpoints.down('md')]:{
				fontSize: 25,
				margin: '0px 0px 5px 0px',
			},

			'& > img': {
				width: 40,
				marginRight: '15px',

				[theme.breakpoints.down('md')]:{
					width: 25,
				}
			},
		},
		'& > p': {
			margin: 0,
			fontSize: 22,
			maxWidth: 700,
			width: '100%',

			[theme.breakpoints.down('md')]:{
				fontSize: 13,
				margin: '5px 0px',
			}
		},
	},
}));
