import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import ButtonCustom from '../../../components/Button';

interface Props {
	setPage: (arg: number) => void;
}

const ThanksNegative = ({ setPage }: Props) => {
    const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	return (
		<div style={{ fontSize: 15, textAlign: 'center' }}>
			<p style={{ marginTop: 30 }}>
				A partir de este momento dejarás de recibir el correo electrónico con el
				aviso de disponibilidad de tu estado de cuenta.
			</p>
			<p style={{ fontWeight: 500 }}>Ahora lo enviaremos por correo postal.</p>

			<div
				style={{
					width: matches ? '100%' : 250,
					display: 'flex',
					margin: 'auto',
					marginTop: 25,
					marginBottom: 50,
				}}
			>
				<ButtonCustom label="Finalizar" variant="solid" onClick={() => setPage(0)} />
			</div>
		</div>
	);
};

export default ThanksNegative;
