import { Link } from '@mui/material';
import { HeaderList } from '../../../../../../components/HeaderList';
import { typesContruir } from '../../utils/utils';

export const typesCharacteristic: {[key: string]: JSX.Element} = {
	[typesContruir.RM]: (
		<>
			<p>Características</p>
			<HeaderList
				listSizeIcon={18}
				list={[
					<>Crédito otorgado por una entidad financiera.</>,
					<>No pierdes el derecho al crédito Infonavit.</>,
					<>Tú eliges el plazo y el monto que mejor te convenga.</>,
					<>
						Puedes tener varios créditos sucesivos, si pagas a
						tiempo y compruebas que concluiste con tu proyecto de
						obra.
					</>,
					<>
						El crédito se entrega por partes contra avance de obra.
					</>,
					<>
						Tu vivienda no se queda hipotecada, la garantía es el
						saldo de tu Subcuenta de Vivienda por los pagos que
						pudieras incumplir si llegas a perder tu empleo.
					</>,
					<>
						Tú recibes, administras el dinero y defines quién hará
						el trabajo.
					</>,
				]}
			/>

			<p>
				En esta modalidad puedes realizar mejoras a tu vivienda que NO
				AFECTEN LA ESTRUCTURA, no puedes ampliar espacios, quitar muros,
				colar una losa etc., pero si puedes cambiar ventanas, pisos,
				pintar, impermeabilizar, cambiar la cocina, muebles de baño,
				etc.
			</p>
			<p>
				Una vez formalizado tu crédito se te asignará un verificador que
				será responsable de evaluar que la vivienda no se encuentre en
				zona de riesgo y si cuenta con servicios (agua, luz y drenaje).
			</p>
			<p>
				Si tienes algún problema que no se pueda resolver con
				reparaciones menores, el verificador te recomendará cambiarte de
				modalidad a ConstruYo con Asistencia Técnica o ConstruYo con
				Constructora.
			</p>
			<p>
				Es importante que cuentes con un teléfono celular inteligente
				para que puedas compartir los avances de obra con tu Verificador
				y así se puedan autorizar las ministraciones.
			</p>
		</>
	),
	[typesContruir.AT]: (
		<>
			<p>Características</p>
			<HeaderList
				listSizeIcon={18}
				list={[
					<>Crédito otorgado por una entidad financiera.</>,
					<>No se pierde el derecho al crédito Infonavit.</>,
					<>Tú eliges el plazo y el monto que mejor te convenga.</>,
					<>
						Puedes tener varios créditos sucesivos, si pagas a
						tiempo y compruebas que concluiste con tu proyecto de
						obra.
					</>,
					<>
						El crédito se entrega por partes contra avance de obra.
					</>,
					<>
						Tu vivienda no se queda hipotecada, la garantía es el
						saldo de tu Subcuenta de Vivienda por los pagos que
						pudieras incumplir si llegas a perder tu empleo.
					</>,
					<>
						Tú recibes, administras el dinero y defines quién hará
						el trabajo.
					</>,
				]}
			/>

			<p>
				En esta modalidad puedes construir una vivienda nueva o
				sustituir la que está mal construida o deteriorada; si tienes
				casa, puedes ampliar o construir nuevos espacios, reparar la
				estructura, colar losas, reacomodar espacios, abrir cubos de
				iluminación, etc.
			</p>
			<p>
				Se puede hacer en cualquier tipo de propiedad: privada, ejidal,
				comunal o derivada de un programa gubernamental de vivienda. No
				es indispensable que esté a tu nombre, basta con que tengas una{' '}
				<Link
					href={require('../../../../../../assets/files/POSESION_SEGURA_VC_03.pdf')}
					target="_blank"
				>
					posesión segura.
				</Link>
			</p>
			<p>
				Deberás elegir un Asesor Técnico registrado en el Infonavit para
				que diseñe tu proyecto, te dará asesoría durante toda la obra y
				hará el registro de tu proyecto y los avances. Tú te encargarás
				de la construcción de la obra, de la contratación de la mano
				obra y la compra de los materiales necesarios.
			</p>
			<p>
				Es importante que la obra cumpla con seguridad estructural y
				habitabilidad que acuerdes con tu prestador de servicios para
				garantizar la calidad y seguridad de tu vivienda.
			</p>
		</>
	),
	[typesContruir.CN]: (
		<>
			<p>Características</p>
			<HeaderList
				listSizeIcon={18}
				list={[
					<>Crédito otorgado por una entidad financiera.</>,
					<>No se pierde el derecho al crédito Infonavit.</>,
					<>Tú eliges el plazo y el monto que mejor te convenga.</>,
					<>
						Puedes tener varios créditos sucesivos, si pagas a
						tiempo y compruebas que concluiste con tu proyecto de
						obra.
					</>,
					<>
						El crédito se entrega por partes contra avance de obra.
					</>,
					<>
						Tu vivienda no se queda hipotecada, la garantía es el
						saldo de tu Subcuenta de Vivienda por los pagos que
						pudieras incumplir si llegas a perder tu empleo.
					</>,
					<>
						El constructor recibe, administra el dinero y se encarga
						de la obra de principio a fin.
					</>,
					<>
						Desde el inicio de la obra pactarás con la Constructora
						un precio fijo.
					</>,
				]}
			/>

			<p>
				En esta modalidad puedes construir una vivienda nueva o
				sustituir la que está mal construida o deteriorada; si tienes
				casa, puedes ampliar o construir nuevos espacios, reparar la
				estructura, colar losas, reacomodar espacios, abrir cubos de
				iluminación, etc.
			</p>
			<p>
				Se puede hacer en cualquier tipo de propiedad: privada, ejidal,
				comunal o derivada de un programa gubernamental de vivienda. No
				es indispensable que esté a tu nombre, basta con que tengas una{' '}
				<Link
					href={require('../../../../../../assets/files/POSESION_SEGURA_VC_03.pdf')}
					target="_blank"
				>
					posesión segura.
				</Link>
			</p>
			<p>
				Deberás elegir una constructora registrada en el Infonavit para
				que diseñe y construya tu proyecto, el cual deberá cumplir con
				las indicaciones acordadas contigo, en un contrato, para
				garantizar la calidad y seguridad en tu vivienda.
			</p>
		</>
	),
};
