import { Paper, Box } from '@mui/material';
import { BuroForm } from '../../../components/BuroForm/buroForm';

const CreditBureau = () => {
	return (
		<Paper>
			<BuroForm onlyAuthorization />
		</Paper>
	);
};

export default CreditBureau;
