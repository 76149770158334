import { Box } from "@mui/material";
import theme from "../../../../config/theme";
import { ReactComponent as CalculadoraIcon  } from '../../../../assets/img/iconsMIUC/calculadora.svg';

interface Props {
    onClick: () => void;
}

export const CalculateLabel = ({ onClick }:Props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: theme.palette.error.main,
                '& :hover': {
                    cursor: 'pointer',
                }
            }}
            onClick={onClick}
        >
            <CalculadoraIcon />
            <label>Calcular</label>
        </Box>
    );
}