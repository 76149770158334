import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/Button';
import ModalConfirmation from '../../components/ModalConfirmation';
import SelectCustom from '../../components/SelectCustom';
import CustomAlert from '../../components/CustomAlert';
import { useStyles } from './style';

interface Props {
	dataRejection: any;
	alertGral: any;
	RechazoCaseRequest: (data: any) => void;
	showModalRechazo: any
	setShowModalRechazo: (data: any) => void;
}

const FormRejection = ({ dataRejection, alertGral, RechazoCaseRequest, showModalRechazo, setShowModalRechazo }: Props) => {
	const navigate = useNavigate();
	const [typesRejection, setTypesRejection] = useState([] as any);
	const [typesRejectionSelected, setTypesRejectionSelected] = useState(
		{
			value: '0',
			label: ''
		}
	);

	useEffect(() => {
		setTypesRejection(dataRejection);
	}, [dataRejection]);

	const style = useStyles();
	return (
		<>
			<p className={style.bold_Center}>
				Elige el motivo por el cual no aceptaste este beneficio
			</p>
			<Box className={style.Box_Motivo_Rechazo} sx={{ marginTop: '60px', marginBottom: '60px' }}>
				<span className={style.text}>Elige una de las opciones</span>
				<SelectCustom
					value={typesRejectionSelected.value}
					onChange={(e) => {
						const { value } = e.target;
						let filtro = typesRejection.filter(
							(item: any) => item.value.toString() === value.toString()
						);
						setTypesRejectionSelected(filtro[0]);
					}}
					label={''}
					placeholder={'Selecciona ...'}
					name={'Rejection'}
					id={'Rejection'}
					options={typesRejection}
				/>
			</Box>
			<Box className={style.BoxAlert}>
				<CustomAlert
					show={alertGral?.show}
					severity={alertGral?.severity}
					message={alertGral?.message}
				/>
			</Box>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Box className={style.Box_Button_Finalizar}>
					<CustomButton
						onClick={() => {
							RechazoCaseRequest(typesRejectionSelected.value);
						}}
						variant="solid"
						label="Continuar"
						disabled={typesRejectionSelected.value === '0' ? true : false}
					/>
				</Box>
			</Box>
			<ModalConfirmation open={showModalRechazo} confirmLabel="Finalizar" width="sm"
				onConfirm={() => {
					setShowModalRechazo(false);
					navigate('/inicio');
				}}>
				<>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
						<p className={style.bold_Center_Blue}>
							No aceptaste ninguna reestructura
						</p>
						<p className={style.textAlign_Bold}>
							Gracias por utilizar los servicios digitales del Infonavit
						</p>
					</Box>
				</>
			</ModalConfirmation>
		</>
	);
};

export default FormRejection;
