import { PROFILE_ROUTES } from '../../../config/profile';
import { firstLetterStringToCapital } from '../../../utils/strings';

export interface MenuElements {
	id: number;
	name: string;
	path: string;
	children: [] | MenuElements[];
}

export const menuOptionsByProfile = (profileAccess: {}): MenuElements[] => {
	const entries = Object.entries(profileAccess);
	const menuFormatted = entries.map((entry, index) => {
		// Recursividad Pendiente
		let title = entry[0].replace(/-/g, ' ');
		let subElement: any = entry[1];
		return {
			id: index,
			name: PROFILE_ROUTES[entry[0]] || firstLetterStringToCapital(title),
			path: entry[0],
			children: subElement.map((name: string, index: number) => {
				let subTitle = name.replace(/-/g, ' ');
				return {
					id: index,
					name: PROFILE_ROUTES[name] || firstLetterStringToCapital(subTitle),
					path: name,
					children: [],
				};
			}),
		};
	});
	//Por el momento, sacamos al id 6 que es el item de accesorios
	// return menuFormatted.filter((m) => m.id !== 6);
	return menuFormatted;
};
