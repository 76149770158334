// Dependencies
import { useNavigate } from 'react-router-dom';

// Material Components
import { Box } from '@mui/material';

//Components
import CustomButton from '../../components/Button';

// Assets
import { useStyles } from './styles';

// Intefaces
import { IEventTab } from '../../interfaces/eventTab';

const ConfirmacionView = (ActionsButtons: IEventTab) => {
	const navigate = useNavigate();
	const styles = useStyles();
	return (
		<Box className={styles.container}>
			<Box
				sx={{
					justifyContent: 'center',
					paddingLeft: 3,
					paddingRight: 3,
				}}
			>
				<span className={styles.VerificacionCodigoTitle2}> ¡Felicidades!</span>
				<p className={styles.VerificacionText}>
					Tu contraseña se cambio exitosamente, ahora puedes seguir disfrutando
					de los servicios que Mi Cuenta Infonavit tiene para ti.
				</p>
			</Box>
			<Box className={styles.divboton}>
				<CustomButton
					onClick={() => {
						navigate('/login');
					}}
					variant="solid"
					styles={{}}
					label="Finalizar"
				/>
			</Box>
		</Box>
	);
};

export default ConfirmacionView;
