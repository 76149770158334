import { HeaderList } from '../../../../../components/HeaderList';

const NotarySelectInfo = () => {
	return (
		<>
			<HeaderList
				listSizeIcon={18}
				list={[
					'Mi Notario Ideal es el sistema que te permite elegir al notario idóneo para la titulación de tu vivienda',
					'La lista que te presentamos para que realices tu selección incluye a los mejores 20 notarios del estado en donde se localiza la vivienda a adquirir; toma en cuenta lo siguiente:',
				]}
			/>
			<ul>
				<li>
					Si vas a comprar a un particular o a una inmobiliaria,
					cuando acudas a inscribir tu crédito se te presentará la
					lista de notarios con los que podrás realizar tu trámite de
					escrituras, y que están ordenados con base en los siguientes
					aspectos:
					<ul>
						<li>
							Distancia entre la vivienda [para la que solicitas
							el crédito) y el notario.
						</li>
						<li>
							Desempeño del notario, calificación INEX [Índice de
							Excelencia en el Servicio) al momento de la
							solicitud de la titulación.
						</li>
						<li>
							Capacidad de atención del notario, de acuerdo con el
							volumen de escrituras en proceso y el tiempo
							promedio de entrega de las escrituras.
						</li>
						<li>
							Sila vivienda que te interesa la vas a adquirir con
							una desarrolladora, no tendrás que elegir un
							notario, pues el desarrollador te apoyará en el
							trámite de escrituración.
						</li>
					</ul>
				</li>
				<li>
					Una vez que inicien con el proceso de inscripción de tu
					crédito en las oficinas de atención del Infonavit, podrás
					realizar la selección libre y confidencial mediante las
					siguientes alternativas:
					<ul>
						<li>
							Recibirás un correo electrónico con un enlace que te
							mostrará el listado para que realices tu selección.
						</li>
						<li>
							Desde tu celular puedes ingresar a Mi cuenta
							Infonavit, después abrir "Mi trámite de crédito”,
							dar clic en "Ver lista" y hacer tu selección.
						</li>
					</ul>
				</li>
				<li>
					En todas las alternativas el sistema te confirma tu elección
					y te muestra un código, el cual debes compartir con el
					asesor para finalizar tu trámite de inscripción. Es muy
					importante que lo anotes tal cual se presenta [respetando
					las mayúsculas y las minúsculas del código), pues no es
					recuperable.
				</li>
			</ul>
		</>
	);
};

export default NotarySelectInfo;
