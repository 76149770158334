import API from '../';
import {
	IConsultaBancoResponse,
	IConsultaDatos,
	ICreateAfore,
	ICreateEntidades,
	ICuentaDestino,
	IRequestBase,
	IResponseBase,
	IResponseBusquedaCasos,
	IResponseConsultaDatosDisponibilidad,
	IResponseObtieneArchivoAcuse,
	IResponseValidaAfore,
} from '../../pages/ReturnSavings/interfaces';

class ReturnSavingsAPI extends API {
	catalogoAfore(): Promise<ICreateAfore> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/catalogo-afore',
				method: 'POST',
			})
		);
	}
	catalogoEntidades(): Promise<ICreateEntidades> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/catalogo-entidades',
				method: 'POST',
			})
		);
	}
	consultaDatos(): Promise<IConsultaDatos> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/consulta-datos',
				method: 'POST',
			})
		);
	}
	consultaBanco(data: IRequestBase): Promise<IConsultaBancoResponse> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/consulta-banco',
				method: 'POST',
				data,
			})
		);
	}
	validaCausaRaiz(data: IRequestBase): Promise<IResponseBase> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/valida-causa-raiz',
				method: 'POST',
				data,
			})
		);
	}
	cuentaDestino(): Promise<ICuentaDestino> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/cuenta-destino',
				method: 'POST',
			})
		);
	}
	validaAfore(data: IRequestBase): Promise<IResponseValidaAfore> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/valida-afore',
				method: 'POST',
				data,
			})
		);
	}
	validaFechas(data: IRequestBase): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/valida-fechas',
				method: 'POST',
				data,
			})
		);
	}
	validaPension(data: IRequestBase): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/valida-pension',
				method: 'POST',
				data,
			})
		);
	}
	marcarCuenta(data: IRequestBase): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/marcar-cuenta',
				method: 'POST',
				data,
			})
		);
	}
	obtieneArchivoAcuse(
		data: IRequestBase
	): Promise<IResponseObtieneArchivoAcuse> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/obtiene-archivo-acuse',
				method: 'POST',
				data,
			})
		);
	}
	reintentoPDFAcuse(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/reintento-pdf-acuse',
				method: 'POST',
			})
		);
	}
	consultaSaldoCero(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/consulta-saldo-cero',
				method: 'POST',
			})
		);
	}
	consultaDatosDisponibilidad(
		data: IRequestBase
	): Promise<IResponseConsultaDatosDisponibilidad> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/consulta-datos-disponibilidad',
				method: 'POST',
				data,
			})
		);
	}
	consultaDatosDisponibilidadContinuaSolicitud(
		data: IRequestBase
	): Promise<IResponseConsultaDatosDisponibilidad> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/consulta-datos-disponibilidad-continua-solicitud',
				method: 'POST',
				data,
			})
		);
	}
	busquedaCasos(data: IRequestBase): Promise<IResponseBusquedaCasos> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/busqueda-casos',
				method: 'POST',
				data: data,
			})
		);
	}
	creacionCaso(data: IRequestBase): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/v1/dssv/creacion-caso',
				method: 'POST',
				data,
			})
		);
	}
	validEfirma(data: IRequestBase): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/valid-data-efirma`,
				method: 'POST',
				data,
			})
		);
	}
	persisteDatos(data: IRequestBase): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/dssv/persiste-datos`,
				method: 'POST',
				data,
			})
		);
	}
	consultaDocumentacion(data: IRequestBase): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/dssv/consulta-documentacion`,
				method: 'POST',
				data,
			})
		);
	}
	adjuntarArchivo(data: IRequestBase): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/dssv/adjuntar-archivo`,
				method: 'POST',
				data,
			})
		);
	}
}

export const returnSavingsAPI = new ReturnSavingsAPI('');
