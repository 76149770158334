/* eslint-disable react-hooks/exhaustive-deps */
import { Radio, Checkbox, Divider, Badge } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import ScrollContainer from 'react-indiana-drag-scroll';
import {
	IFormatRow,
	IPropsProductsMiuc,
	formatTypesRow,
} from './interfaces/product.interface';
import { creditTypes } from '../../../CreditRequest/MIUC/utils';
import logoMejorasi from '../../../../assets/img/productsMIUC/MejOraSi.png';
import iconoDescarga from '../../../../assets/img/icono-descarga.png';
import { useStyles } from './styles/styles';
import { getStyleSection, stylesRow } from './utils';
import individualIcon from '../../../../assets/img/productsMIUC/individual.svg';
import conyugalIcon from '../../../../assets/img/productsMIUC/conyugal.svg';
import { useController } from '../../hooks/useController';
import { formatMoney2, formatPor } from '../../../../utils/validators';
import HelpPanel from '../HelpPanel';
import { CREDIT_OPTIONS } from '../../constants/products';

const ProductsMiuc = ({
	keys,
	products,
	selectProduct,
	setSelectProduct,
	keyHeader,
	textFooter,
	getTablaComparativa,
	headerText,
	options,
	maxWidthCard,
	rememberHiddenProducts,
	refresh,
}: IPropsProductsMiuc) => {
	const { dataProcessController } = useController();
	const refScroll = useRef<ScrollContainer>(null);
	const styles = useStyles();
	const [viewProducts, setViewProducts] = useState<
		Array<{ label: string; value: string; active: boolean }>
	>([]);

	const haveConyuge = [
		CREDIT_OPTIONS.CONYUGAL,
		CREDIT_OPTIONS.FAMILIAR,
		CREDIT_OPTIONS.CORRESIDENCIAL,
	].includes(dataProcessController?.processInfo?.creditType);

	const maxWidth = maxWidthCard ? maxWidthCard : haveConyuge ? 400 : 300;
	const [leftInit, setLeftInit] = useState(0);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [maxColWidth, setMaxColWidth] = useState(maxWidth);

	const autoProduccionType =
		dataProcessController.processInfo?.creditType === 'RM'
			? 'CARACTERISTICAS_RM'
			: dataProcessController.processInfo?.creditType === 'AT'
			? 'CARACTERISTICAS_AT'
			: 'CARACTERISTICAS_CN';

	const handleChangeCheckbox = async ({
		target,
	}: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = target;
		if (setSelectProduct) {
			await setSelectProduct('');
		}
		const newViewProducts = viewProducts.map((v) => {
			if (value === v.value) {
				v.active = !v.active;
			}
			return v;
		});
		setViewProducts(newViewProducts);
		handleResize();
	};

	const handleScroll = () => {
		const scrollLeftStat = refScroll.current?.getElement().scrollLeft || 0;
		setLeftInit(scrollLeftStat);
	};

	const handleResize = () => {
		const widthOffset =
			refScroll.current?.getElement().offsetWidth || window.innerWidth;
		setWindowWidth(widthOffset);
		refScroll.current?.getElement().scrollTo({
			left: 0,
		});
		setMaxColWidth(widthOffset < 600 ? widthOffset - 5 : maxWidth);
		handleScroll();
	};

	const getFormat = (item: any, format: IFormatRow) => {
		let newItem = '';
		let prefijo = '';
		if (format.type === formatTypesRow.MONEY) {
			if (typeof item === 'number') {
				newItem = formatMoney2(item > 0 ? item : 0);
			} else {
				const numeroReal = Number(
					String(item || '0')
						.replaceAll('$', '')
						.replaceAll(',', '')
				);
				newItem = formatMoney2(numeroReal);
			}
		}
		if (format.type === formatTypesRow.PERCENTAGE) {
			if (typeof item === 'number') {
				newItem = formatPor(item);
			} else {
				newItem = formatPor(Number(item));
			}
			prefijo = '%';
		}
		if (format.type === formatTypesRow.MONTH) {
			if (typeof item === 'number') {
				prefijo = item > 1 ? 'Meses' : 'Mes';
			} else {
				const numberReal = Number(item);
				prefijo = numberReal > 1 ? 'Meses' : 'Mes';
			}
		}
		if (format.type === formatTypesRow.YEAR) {
			if (typeof item === 'number') {
				prefijo = item > 1 ? 'Años' : 'Año';
			} else {
				const numberReal = Number(item);
				prefijo = numberReal > 1 ? 'Años' : 'Año';
			}
		}
		const itemValue = Number(Number.parseFloat(item).toFixed(2));
		return (
			<label style={{ fontWeight: itemValue > 0 ? 500 : 'normal' }}>
				{newItem || item} {format?.prefix || prefijo}
			</label>
		);
	};

	const getViewProducts = () => {
		return products?.map((p) => {
			return {
				label: creditTypes[p[keyHeader].toString()].title,
				value: p[keyHeader].toString(),
				active: true,
			};
		});
	};

	useEffect(() => {
		setTimeout(handleResize, 100);
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (viewProducts.length !== 0 && rememberHiddenProducts) return;
		const newProducts = getViewProducts();
		setViewProducts(newProducts);
	}, [products]);

	useEffect(() => {
		if (!refresh) return;
		const newProducts = getViewProducts();
		setViewProducts(newProducts);
	}, [refresh]);

	return (
		<>
			<div className={styles.cabeceraTabla}>
				<h2>Créditos disponibles para ti:</h2>
				<p>
					Selecciona entre tus opciones disponibles para compararlas:
				</p>
				<section>
					{viewProducts.map((v, j) => (
						<div key={j}>
							{' '}
							<Checkbox
								checked={v.active}
								onChange={handleChangeCheckbox}
								value={v.value}
								size="small"
								icon={<CheckBoxOutlineBlank />}
								checkedIcon={<CheckBoxOutlined />}
							/>{' '}
							{v.label}{' '}
						</div>
					))}
				</section>
			</div>

			{getTablaComparativa && (
				<div
					className={styles.tablaComparativa}
					onClick={getTablaComparativa}
				>
					Descargar tabla comparativa{' '}
					<img
						src={iconoDescarga}
						alt="download icon"
						className={styles.iconoDescarga}
					/>
				</div>
			)}

			{headerText && headerText}

			<Divider sx={{ margin: '20px 10px' }} />

			<ScrollContainer
				className="scroll-container"
				style={{ position: 'relative' }}
				ref={refScroll}
				onScroll={handleScroll}
				ignoreElements={'input'}
			>
				<table className={styles.tableCustom}>
					<thead>
						<tr>
							{keyHeader &&
								products.map(
									(p, i) =>
										viewProducts.find(
											(v) =>
												v.value ===
												p[keyHeader].toString()
										)?.active && (
											<th
												key={i}
												style={{
													width: maxColWidth,
												}}
											>
												<section
													style={{
														width: maxColWidth,
													}}
													className={
														p[
															keyHeader
														].toString() ===
														selectProduct
															? 'active'
															: undefined
													}
												>
													<header>
														{p[keyHeader] !==
															'MD' && (
															<img
																src={
																	creditTypes[
																		p[
																			keyHeader
																		].toString()
																	].image
																}
																alt="logo"
																style={{
																	maxWidth: 150,
																	maxHeight: 30,
																}}
															/>
														)}
														{p[keyHeader] ===
															'MD' && (
															<img
																src={
																	logoMejorasi
																}
																alt="logo"
																style={{
																	maxWidth: 150,
																	maxHeight: 30,
																}}
															/>
														)}
														<span>
															{
																creditTypes[
																	p[
																		keyHeader
																	].toString()
																].tooltip
															}
															{p[keyHeader] ===
																'L6' && (
																<Badge
																	className={
																		styles.helpPanelL6
																	}
																	badgeContent={
																		<HelpPanel
																			type={
																				autoProduccionType
																			}
																		/>
																	}
																/>
															)}
														</span>
													</header>
													<div>
														<Radio
															checked={
																selectProduct ===
																p[
																	keyHeader
																].toString()
															}
															onChange={({
																target,
															}: any) =>
																setSelectProduct
																	? setSelectProduct(
																			target.value
																	  )
																	: {}
															}
															value={p[
																keyHeader
															].toString()}
															name={
																'radio-button-' +
																p[
																	keyHeader
																].toString()
															}
															id={
																'radio-button-' +
																p[
																	keyHeader
																].toString()
															}
														/>{' '}
														Seleccionar
													</div>
													{options && options[i] && (
														<div
															className={
																styles.optionsMVIT
															}
														>
															{options[i]}
														</div>
													)}

													{haveConyuge && (
														<article
															style={stylesRow}
														>
															<div className="conyuge">
																<img
																	src={
																		individualIcon
																	}
																	alt="individualIcon"
																	style={{
																		width: 30,
																	}}
																/>
															</div>
															<Divider
																orientation="vertical"
																variant="middle"
																flexItem
															/>
															<div className="conyuge">
																<img
																	src={
																		conyugalIcon
																	}
																	alt="conyugalIcon"
																	style={{
																		width: 30,
																	}}
																/>
															</div>
														</article>
													)}
												</section>
											</th>
										)
								)}
						</tr>
					</thead>
					<tbody>
						{keys.map((k, i) =>
							k.hideRow ? undefined : (
								<React.Fragment key={i}>
									<tr>
										<th
											scope="row"
											colSpan={products.length}
										>
											<div
												className={styles.rowTitleSup}
												style={{
													maxWidth: windowWidth,
													left: leftInit,
												}}
											>
												{k.title}
											</div>
										</th>
									</tr>
									<tr>
										{products.map(
											(p, j) =>
												viewProducts.find(
													(v) =>
														v.value ===
														p[keyHeader].toString()
												)?.active && (
													<td
														key={j}
														className={
															styles.tableCustomTd
														}
														style={{
															width: maxColWidth,
														}}
													>
														<section
															style={
																p[
																	k.key +
																		'Cny'
																] !==
																	undefined &&
																haveConyuge
																	? {
																			width: maxColWidth,
																			...stylesRow,
																	  }
																	: {
																			width: maxColWidth,
																	  }
															}
															className={getStyleSection(
																p[
																	keyHeader
																].toString() ===
																	selectProduct,
																k
															)}
														>
															{k.format
																? getFormat(
																		p[
																			k
																				.key
																		],
																		k.format
																  )
																: p[k.key]}

															{/* Si tiene una variable conyuge aparecera de esta manera con el prefijo Cny */}
															{p[
																k.key + 'Cny'
															] !== undefined &&
																haveConyuge && (
																	<>
																		<Divider
																			orientation="vertical"
																			variant="middle"
																		/>
																		{k.format
																			? getFormat(
																					p[
																						k.key +
																							'Cny'
																					],
																					k.format
																			  )
																			: p[
																					k.key +
																						'Cny'
																			  ]}
																	</>
																)}
														</section>
													</td>
												)
										)}
									</tr>
								</React.Fragment>
							)
						)}
					</tbody>
				</table>
			</ScrollContainer>
			{textFooter && (
				<div className={styles.textFooter}>{textFooter}</div>
			)}
		</>
	);
};

export default ProductsMiuc;
