export const typesOptions = [
	{ value: 1, label: 'Quiero presentar una queja' },
	{ value: 2, label: 'Consultar mis quejas' },
	{ value: 3, label: 'Quiero presentar una sugerencia o felicitación' },
];

export const defaultResponse = {
	idRegistro: '',
	fechaCreacion: '',
	claseOperacion: '',
	estatus: '',
};

export const pattern = /^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ,.\s]*$/;
