import { useStyles } from './styles';

const TermsAndConditions = () => {
	const classes = useStyles();

	return (
		<div className={classes.dialogBody}>
			<p className={classes.titleTermsCenter}>
				Sobre el uso del portal del Infonavit
			</p>
			Al utilizar este portal estás de acuerdo en cumplir con los términos y
			condiciones que aquí se señalan y cualquier otro documento contenido en este
			portal o que regula tu participación en su uso. Si no estás de acuerdo con
			esto, no lo uses.
			<p className={classes.titleTerms}>Obligaciones del usuario</p>
			<li>
				Estoy de acuerdo en cumplir con los términos y condiciones aquí
				establecidos y confirmo que las he leído, las entiendo y estoy de acuerdo
				en sujetarme a ellas, lo cual confirmo al entrar a este portal y consultar
				su información.
			</li>
			<p className={classes.titleTerms}>Términos generales</p>
			<li>
				A la información y los servicios que el Infonavit ofrece a través de este
				portal le aplican las normas de México.
			</li>
			<li>
				Al utilizar los servicios y consultar la información contenida en este
				portal, estás de acuerdo con los términos y condiciones así como a las
				modificaciones de éstos.
			</li>
			<li>
				El Infonavit proporciona a través de este portal la información, los
				materiales descritos en él y el software “como son” por lo que el
				Infonavit no otorga garantía alguna sobre estos.
			</li>
			<li>
				Si no vives en México y quieres consultar la información del portal,
				asegúrate que las leyes de tu país no te lo prohíban o limiten, es tu
				responsabilidad.
			</li>
			<li>
				Recuerda que el Infonavit puede suspender, interrumpir o dejar de operar
				este portal o los servicios en cualquier momento sin que sea responsable
				de esto.
			</li>
			<p className={classes.titleTerms}>Marcas derechos de autor</p>
			<li>
				Las marcas, logotipos, nombres comerciales, patentes, diseños
				industriales, personajes, y demás derechos de Propiedad Intelectual (la
				"Propiedad Intelectual") que ves en este portal, son propiedad del
				Infonavit y de terceros según sea el caso.
			</li>
			<li>
				Por lo que no deberás usar la “Propiedad Intelectual” sin el permiso por
				escrito de Infonavit o del tercero a quien pertenezca la Propiedad
				Intelectual, ya que las leyes en México la protegen.
			</li>
			<li>
				Podrás consultar y usar la información, lo cual no te da ningún derecho de
				propiedad en relación con la “Propiedad Intelectual”.
			</li>
			<p className={classes.titleTerms}>Enlaces con otros sitios de internet</p>
			<li>
				Al usar este portal, podrás enlazarte con otros sitios de Internet. Sin
				embargo, dichos enlaces son independientes del Infonavit por lo que éste
				no es responsable de tales sitios, así que su enlace y consulta serán de
				tu absoluta responsabilidad.
			</li>
			<li>
				No coloques en el portal ligas ni páginas propias o de terceros sin
				autorización previa y por escrito del Infonavit.
			</li>
			<p className={classes.titleTerms}>Seguridad de la información</p>
			<li>
				El Infonavit protege tu información confidencial, si a través de este
				portal transmites tú información confidencial a un tercero, la protección
				de tu información es tu responsabilidad y la de la del tercero con quien
				decidiste compartirla.
			</li>
			<li>
				Como sabes, el uso de medio electrónicos implica un riesgo, el cual al
				utilizar este portal reconoces y aceptas, por ello te recordamos que el
				Infonavit no será legalmente responsable por cualquier daño que pudieras
				sufrir al utilizarlo.
			</li>
			<p className={classes.titleTerms}>
				Integridad, autenticidad y archivos de los mensajes de datos
			</p>
			<li>
				Tu clave de usuario y tu contraseña, servirán para hacer las operaciones
				propias del este portal a fin de: (a) saber que se trata de ti, (b)
				determinar que el mensaje no ha sido alterado, y (c) atribuirte el
				mensaje.
			</li>
			<li>
				Los mensajes de datos recibidos o archivados en medios electrónicos,
				identificados con la clave de usuario y la contraseña, serán atribuibles
				al usuario respectivo y servirán para evidenciar su origen.
			</li>
			<li>
				Deberás cuidar tu clave de usuario y contraseña, ya que todas las
				operaciones que se hagan con ellas, se considerarán hechas por ti.
			</li>
			<p className={classes.titleTerms}>
				Claves de acceso y claves de identificación
			</p>
			<li>
				Mantén en secreto tu clave de usuario y contraseña, es tu responsabilidad.
			</li>
			<p className={classes.titleTerms}>Responsabilidad limitada</p>
			<li>
				Estás de acuerdo en que el Infonavit no será responsable por la
				interrupción o demora de servicios o información contenida en este portal,
				incluyendo sin limitación los virus, que pudieran infectar tu equipo de
				cómputo o software.
			</li>
			<li>
				El Infonavit no será responsable de información que terceras personas
				incluyan en el portal.
			</li>
			<p className={classes.titleTerms}>Responsabilidad por daños y perjuicios</p>
			<li>
				Si no cumples con los términos y condiciones y causas un daño o perjuicio
				al Infonavit deberás responder de ellos legalmente.
			</li>
			<p className={classes.titleTerms}>Contingencia</p>
			<li>
				Si por caso fortuito, fuerza mayor o por cualquier otro acontecimiento o
				circunstancia inevitable, no pudieras hacer uso de este portal o llevar a
				cabo alguna de las operaciones que ofrece, no será responsabilidad del
				Infonavit.
			</li>
			<p className={classes.titleTerms}>Usos permitidos</p>
			<li>
				El uso de este portal es tu responsabilidad por lo que al utilizarlo,
				estás de acuerdo en respetar las buenas costumbres, la dignidad y los
				derechos de otras personas.
			</li>
			<li>
				Podrás visualizar y descargar los materiales contenidos en el portal
				solamente para tu uso personal y no para un uso comercial. Esta
				autorización no transmite la propiedad de los materiales ni de las copias
				de los mismos, y estará sujeta a las siguientes restricciones:
			</li>
			<ol>
				<li>
					El acceso a la información no está condicionado a que se acredite
					interés jurídico alguno o se justifique su utilización.
				</li>
				<li>
					El usuario no deberá modificar, reproducir o mostrar pública o
					comercialmente los materiales, ni podrá distribuir o utilizarlos con
					algún propósito público o comercial.
				</li>
				<li>
					El usuario no deberá transferir los materiales a ningún tercero.
					Asimismo, se compromete a cumplir con todas las restricciones
					adicionales vistas en el portal, aún y cuando éstas sean actualizadas
					frecuentemente. Recuerda que este portal, incluyendo todos los
					materiales contenidos en el mismo, se encuentran protegidos por la Ley
					Federal del Derecho de Autor de los Estados Unidos Mexicanos.
				</li>
			</ol>
			<li>
				Si deseas dar un uso distinto a lo que aquí se autoriza, debes obtener el
				consentimiento previo por escrito del titular de los derechos de que se
				trate.
			</li>
			<li>
				No podrás modificar, copiar, reproducir, volver a publicar, cargar,
				exponer, transmitir o distribuir de cualquier forma el contenido
				disponible a través del servicio y los sitios asociados, incluidos el
				código y el software.
			</li>
			<p className={classes.titleTerms}>
				Exclusion de garantías y de responsabilidad por el funcionamiento de este
				portal
			</p>
			<li>
				El Infonavit no puede garantizar la disponibilidad y continuidad del
				funcionamiento del portal, de los servicios y de aquellos otros sitios con
				los que se haya establecido una liga, por lo que si de ocurrir una falla
				se te causa un daño o perjuicio tampoco será responsabilidad del
				Infonavit.
			</li>
			<li>
				Los materiales pueden contener inexactitudes y errores tipográficos, por
				lo que Infonavit no garantiza la exactitud o perfección de los materiales,
				ni la confiabilidad de algún aviso, opinión, declaración o cualquier otra
				información vista o distribuida a través de este portal.
			</li>
			<li>
				El Infonavit podrá hacer cualquier cambio a este portal, así como a
				cualquier material, productos, programas, servicios o precios (si
				existieran) descritos, en cualquier momento y sin necesidad de aviso
				previo.
			</li>
			<li>
				El Infonavit adopta medidas de protección para proteger el portal contra
				ataques informáticos de terceros. No obstante, no garantiza que terceros
				no autorizados no puedan tener acceso al tipo de uso del portal que hacen
				sus usuarios o las condiciones, características y circunstancias en las
				cuales se hace dicho uso. En consecuencia, el Infonavit no será en ningún
				caso responsable de los daños y perjuicios que pudieran derivarse de dicho
				acceso no autorizado.
			</li>
			<li>
				Al incluir en este portal contenidos provenientes de terceros, el
				Infonavit no se convierte en editor de los mismos; y en consecuencia, no
				garantiza ni se hace responsable de la licitud, fiabilidad, utilidad,
				veracidad, exactitud, exhaustividad y actualidad de dichos contenidos.
			</li>
			<p className={classes.titleTerms}>Cambios al aviso legal</p>
			<li>
				El Infonavit podrá modificar el presente Aviso Legal en cualquier momento,
				por lo que deberás revisarlo frecuentemente.
			</li>
			<p className={classes.titleTerms}>Ley aplicable</p>
			<li>
				El servicio de este portal es controlado por el Infonavit desde sus
				oficinas en la Ciudad de México, Distrito Federal, en los Estados Unidos
				Mexicanos. Al ingresar, el usuario y el Infonavit aceptan someterse a la
				legislación aplicable en los Estados Unidos Mexicanos, sin tomar en cuenta
				los principios de conflictos de leyes que pudieran surgir.
			</li>
			<p className={classes.titleTerms}>Cambios al aviso legal</p>
			<li>
				Para la interpretación, ejecución y cumplimiento del presente Aviso Legal,
				las partes están de acuerdo en someterse a la jurisdicción de los
				tribunales competentes en la Ciudad de México, y renuncian expresamente a
				cualquier otro fuero que por sus domicilios presentes o futuros pudiera
				corresponderles.
			</li>
			<li>
				El usuario declara haber leído y aceptar íntegramente los presentes
				Términos y condiciones vigentes en el momento de entrar a este portal y
				usar los servicios. En el caso de que no estés de acuerdo con las
				disposiciones del presente Aviso Legal deberás abstenerte de usarlo.
			</li>
		</div>
	);
};

export default TermsAndConditions;
