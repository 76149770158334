import {
	Dialog,
	DialogTitle,
	DialogContent,
	Box,
	IconButton,
	Divider,
	Grid,
} from '@mui/material';
import { MIUC_PRODUCTS, PRODUCT_LINES } from '../../constants/products';
import { Close } from '@mui/icons-material';
import { Fragment, useMemo } from 'react';
import CustomButton from '../CustomButton/index';
import { useStyles } from './styles';
import { ListasPuntuacion } from '../../utils/modalHowPoints.utils';
import { DESTINOS } from '../../../../config/miucConstants';
import { useController } from '../../hooks/useController';

interface ModalHowPointsProps {
	show: boolean;
	setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const getNumber = (arg?: string) => {
	return new Intl.NumberFormat('es-MX').format(Number(arg || ''));
};

const ModalHowPoints = ({ show, setShow }: ModalHowPointsProps) => {
	const { dataProcessController } = useController()
	const classes = useStyles();

	const bimestreAnio =
	dataProcessController.titular?.bimestreTope?.substring(0, 4) || 'XXXX';
	const bimestre = dataProcessController.titular?.bimestreTope?.substring(5, 6) || 'X';

	const { listPuntuacion, listPuntuacionPatronal } = ListasPuntuacion();

  const puntosMinimos = useMemo(() => {
    switch (dataProcessController.processData.productLine) {
      case PRODUCT_LINES.COMPRAR_VIVIENDA: 
      case PRODUCT_LINES.PAGAR_HIPOTECA: 
      case PRODUCT_LINES.MEJORA_HOGAR: return 1080;
      case PRODUCT_LINES.COMPRAR_TERRENO: return 980;
      case PRODUCT_LINES.CONTRUIR_VIVIENDA: return 880;
    }
  }, [dataProcessController.processInfo?.credit])

	return (
		<Dialog
			fullWidth
			maxWidth={'md'}
			onClose={() => setShow(false)}
			open={show}
		>
			<DialogTitle>
				<Box className={classes.boxVentana}>
					<span className={classes.headerVentana}>
						Los criterios mínimos para el otorgamiento de un crédito
						son: 3 bimestres de cotización continua y contar con{' '}
						{puntosMinimos || dataProcessController.titular?.puntosMinimos || 0} puntos.
					</span>
					<IconButton
						aria-label="close"
						onClick={() => setShow(false)}
						sx={{
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<Close />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent dividers={true}>
				<h3 className={classes.tituloVentana}>
					¿Cómo se calculó mi puntuación?
				</h3>

				<Box margin={'20px 30px'}>
					<Grid container columns={12} spacing={1} marginBottom={3}>
						<Grid item xs={12} md={10}>
							<Box className={classes.tituloColumna}>
								Tu puntuación
							</Box>
						</Grid>
						<Grid item xs={12} md={2} textAlign={'center'}>
							<Box className={classes.tituloColumna}>
								Puntos
							</Box>
						</Grid>

						<Grid item xs={12} margin={'5px 0px'}></Grid>

						{(listPuntuacion || []).map((item) => (
							!item.hidden && (
								<Fragment key={item.points}>
									<Grid
										item
										xs={12}
										md={3}
										padding={'10px'}
										alignItems={'center'}
									>
										{item.label}
									</Grid>
									<Grid item xs={12} md={7} padding={'10px'}>
										{item.description}
									</Grid>
									<Grid
										item
										xs={12}
										md={2}
										padding={'10px'}
										textAlign={'center'}
									>
										{item.points}
									</Grid>
									{item.divider && (
										<Grid item xs={12} margin={'10px 0px'}>
											<Divider />
										</Grid>
									)}
								</Fragment>
							)
						))}
						<Grid
							item
							xs={12}
							md={10}
							fontWeight={700}
							padding={'10px'}
						>
							Total de tu puntuación
						</Grid>
						<Grid
							item
							xs={12}
							md={2}
							fontWeight={700}
							textAlign={'center'}
							padding={'10px'}
						>
							{getNumber(
								dataProcessController.titular?.puntosUsuarioTotales
							)}
						</Grid>
					</Grid>

					<Grid container columns={12} spacing={1}>
						<Grid item xs={12} md={10}>
							<Box className={classes.tituloColumna}>
								Tu puntuación patronal
							</Box>
						</Grid>
						<Grid item xs={12} md={2} textAlign={'center'}>
							<Box className={classes.tituloColumna}>
								Puntos
							</Box>
						</Grid>

						<Grid item xs={12} margin={'5px 0px'}></Grid>

						{listPuntuacionPatronal.map(
							(item) =>
								!item.hidden && (
									<Fragment key={item.description}>
										<Grid
											item
											xs={12}
											md={3}
											padding={'10px'}
											alignItems={'center'}
										>
											{item.label}
										</Grid>
										<Grid
											item
											xs={12}
											md={7}
											padding={'10px'}
										>
											{item.description}
										</Grid>
										<Grid
											item
											xs={12}
											md={2}
											padding={'10px'}
											textAlign={'center'}
										>
											{item.points}
										</Grid>
										{item.divider && (
											<Grid
												item
												xs={12}
												margin={'10px 0px'}
											>
												<Divider />
											</Grid>
										)}
									</Fragment>
								)
						)}
						<Grid
							item
							xs={12}
							md={10}
							fontWeight={700}
							padding={'10px'}
						>
							Total de tu puntuación patronal
						</Grid>
						<Grid
							item
							xs={12}
							md={2}
							fontWeight={700}
							textAlign={'center'}
							padding={'10px'}
						>
							{getNumber(dataProcessController.titular?.puntosPatronTotales)}
						</Grid>
						<Grid item xs={12} margin={'10px 0px'}>
							<Divider />
						</Grid>
						<Grid
							item
							xs={12}
							md={10}
							fontWeight={700}
							fontSize={18}
							color={'#D1001F'}
							padding={'10px'}
						>
							Tu puntuación + tu puntuación patronal
						</Grid>
						<Grid
							item
							xs={12}
							md={2}
							fontWeight={700}
							fontSize={18}
							textAlign={'center'}
							color={'#D1001F'}
							padding={'10px'}
						>
							{getNumber(dataProcessController.titular?.puntosTotales)}
						</Grid>
						<Grid item xs={12} padding={'10px'} marginTop={6}>
							<Divider />
						</Grid>
						<Grid item xs={12} padding={'10px'} marginBottom={1}>
							El cálculo se realiza con la información al corte
							del bimestre número {bimestre} de {bimestreAnio}
							{dataProcessController.processInfo?.credit !==
								MIUC_PRODUCTS.CONSTRUCCION && (
								<p className={classes.footerVentana}>
									{dataProcessController.processInfo?.credit !==
										MIUC_PRODUCTS.CREDITERRENO &&
										'*Contexto: ubicación y giro del patrón.'}
									{dataProcessController.processInfo?.credit ===
										MIUC_PRODUCTS.CREDITERRENO &&
										'*Contexto: giro del patrón y bono.'}
								</p>
							)}
							{[
								DESTINOS.COMPRA.codigo,
								DESTINOS.HIPOTECA.codigo,
								DESTINOS.AUTOPRODUCCION.codigo,
                DESTINOS.CREDITO_SEGURO.codigo,
                DESTINOS.APOYO_INFONAVIT.codigo,
								'',
							].includes(dataProcessController.processInfo?.credit) && (
								<p className={classes.footerVentana}>
									**Aplica a toda persona que se identifique
									legalmente con el género femenino.
								</p>
							)}
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
			<Box maxWidth={240} minWidth={200} margin={'15px auto'}>
        <CustomButton
          label={'Regresar'}
          onClick={() => setShow(false)}
          variant="solid"
        />
			</Box>
		</Dialog>
	);
};

export default ModalHowPoints;
