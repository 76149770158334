import { HeaderList } from '../../HeaderList';

const InfonavitSupport = () => {
	return (
		<>
			<HeaderList
				listSizeIcon={18}
				list={[
					<>Banco del Bajío</>,
					<>Banco Inmobiliario Mexicano</>,
					<>Banca Mifel</>,
					<>Banorte</>,
					<>Banregio</>,
					<>Banco Ve por Más</>,
					<>BBVA Bancomer</>,
					<>Citibanamex</>,
					<>HSBC</>,
					<>Inbursa</>,
					<>ION Financiera</>,
					<>Metrofinanciera</>,
					<>Patrimonio</>,
					<>Scotiabank Inverlat</>,
					<>Santander</>,
				]}
			/>
		</>
	);
};

export default InfonavitSupport;
