/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { Radio } from '@mui/material';
import HeaderStep from './HeaderStep';
import { useStyles } from '../styles/styles';
import TextInput from '../../../../../components/TextInput';
import { isNumber } from '../../../../../utils/expressions';

const QuestStep4 = ({
	quest,
	nextStep,
	handleChange,
	afterQuestionarySteps,
	alert,
}: any) => {
	const classes = useStyles();
	const [quests, setQuests] = useState([] as any);
	const [date, setDate] = useState({
		month: '',
		year: '',
	});
	const [timeWork, setTimeWork] = useState({
		days: '',
		hrs: '',
	});
	const [nameCompany, setNameCompany] = useState('');
	const [working, setWorking] = useState('');

	useEffect(() => {
		drawQuestions();
	}, [quest, date, nameCompany, timeWork, working]);

	useEffect(() => {
		let respuestaDate = `Mes ${date.month} Año ${date.year}`;
		handleChange(respuestaDate, '2.29');
	}, [date]);

	const drawQuestions = () => {
		const questions: any = [];
		quest.map((item: any, index: any) => {
			const newItem = {
				title: `${item.numero.split('.')[1]}.- ${item.pregunta}`,
				body: (
					<>
						{item.numero !== '2.22' &&
							item.numero !== '2.28' &&
							item.opciones.map((opcion: any) => (
								<div
									className={classes.itemsFlexCenter}
									key={opcion + item.pregunta}
								>
									<Radio
										checked={
											item.respuesta ===
											opcion.split(')')[0]
										}
										onChange={(e: any) => {
											let value =
												e.target.value.split(')')[0];
											handleChange(value, item.numero);
										}}
										name={opcion.split(')')[0]}
										value={opcion.split(')')[0]}
										disabled={
											((index === 3 ||
												index === 4 ||
												index === 5) &&
												(quest[2].respuesta === '2' ||
													quest[2].respuesta ===
													'3')) ||
											(index === 5 &&
												quest[4].respuesta === '2') ||
											(quest[0].respuesta === '7' &&
												index !== 0) ||
											(['3', '4'].includes(
												quest[1].respuesta
											) &&
												![0, 1].includes(index)) ||
											(quest[2].respuesta === '1' &&
												![0, 1, 2].includes(index)) ||
											(['1', '2'].includes(
												quest[3].respuesta
											) &&
												![0, 1, 2, 3].includes(
													index
												)) ||
											(['3'].includes(
												quest[4].respuesta
											) &&
												![0, 1, 2, 3, 4].includes(
													index
												))
										}
									/>
									{opcion}
								</div>
							))}
						{item.numero === '2.22' &&
							item.opciones.map((opcion: any, index: any) => {
								if (index === 0) {
									return (
										<div style={{ maxWidth: 400 }}>
											<TextInput
												id="company"
												name="company"
												value={nameCompany}
												label={opcion}
												disabled={
													quest[0].respuesta === '7'
												}
												onChange={(e) => {
													setNameCompany(
														e.target.value
													);
													handleChange(
														'1 ' + e.target.value,
														item.numero
													);
												}}
											/>
										</div>
									);
								} else {
									return (
										<div
											className={classes.itemsFlexCenter}
											key={opcion + item.pregunta}
										>
											<Radio
												checked={
													item.respuesta ===
													opcion.split(')')[0]
												}
												onChange={(e: any) => {
													setNameCompany('');
													let value =
														e.target.value.split(
															')'
														)[0];
													handleChange(
														value,
														item.numero
													);
												}}
												disabled={
													quest[0].respuesta === '7'
												}
												name={opcion.split(')')[0]}
												value={opcion.split(')')[0]}
											/>
											{opcion}
										</div>
									);
								}
							})}
						{item.numero === '2.28' && (
							<div key={'mes' + item.numero}>
								<div>
									<div className={classes.itemsFlexCenter}>
										<Radio
											checked={working === '0'}
											onChange={(e: any) => {
												let value = e.target.value;
												setWorking(value);
												setTimeWork({
													days: '',
													hrs: '',
												});
											}}
											disabled={
												(quest[0].respuesta === '7' &&
													index !== 0) ||
												(['3', '4'].includes(
													quest[1].respuesta
												) &&
													![0, 1].includes(index)) ||
												(quest[2].respuesta === '1' &&
													![0, 1, 2].includes(
														index
													)) ||
												(['1', '2'].includes(
													quest[3].respuesta
												) &&
													![0, 1, 2, 3].includes(
														index
													)) ||
												(['3'].includes(
													quest[4].respuesta
												) &&
													![0, 1, 2, 3, 4].includes(
														index
													))
											}
											name={'noWorking'}
											value={'0'}
										/>
										No trabajé la semana pasada
									</div>
									<div className={classes.itemsFlexCenter}>
										<Radio
											checked={working === '1'}
											onChange={(e: any) => {
												let value = e.target.value;
												setWorking(value);
											}}
											disabled={
												(quest[0].respuesta === '7' &&
													index !== 0) ||
												(['3', '4'].includes(
													quest[1].respuesta
												) &&
													![0, 1].includes(index)) ||
												(quest[2].respuesta === '1' &&
													![0, 1, 2].includes(
														index
													)) ||
												(['1', '2'].includes(
													quest[3].respuesta
												) &&
													![0, 1, 2, 3].includes(
														index
													)) ||
												(['3'].includes(
													quest[4].respuesta
												) &&
													![0, 1, 2, 3, 4].includes(
														index
													))
											}
											name={'noWorking'}
											value={'1'}
										/>
										Trabajé
									</div>
								</div>
								<div
									style={{
										display:
											working === '1' ? 'flex' : 'none',
										paddingLeft: 15,
										paddingTop: 15,
									}}
								>
									<div
										style={{
											textAlign: 'left',
											marginRight: '20px',
										}}
									>
										<div>Número de días</div>
										<TextInput
											id={'question-day-' + item.numero}
											name={'days'}
											placeholder="00"
											onChange={(e: any) => {
												if (!isNumber(e.target.value))
													return;
												setTimeWork({
													...timeWork,
													days: e.target.value,
												});
											}}
											disabled={
												(quest[0].respuesta === '7' &&
													index !== 0) ||
												(['3', '4'].includes(
													quest[1].respuesta
												) &&
													![0, 1].includes(index)) ||
												(quest[2].respuesta === '1' &&
													![0, 1, 2].includes(
														index
													)) ||
												(['1', '2'].includes(
													quest[3].respuesta
												) &&
													![0, 1, 2, 3].includes(
														index
													)) ||
												(['3'].includes(
													quest[4].respuesta
												) &&
													![0, 1, 2, 3, 4].includes(
														index
													))
											}
											contentStyles={{ maxWidth: 100 }}
											value={timeWork.days}
										/>
									</div>
									<div
										style={{
											textAlign: 'left',
											marginRight: '20px',
										}}
									>
										<div>Número de horas</div>
										<TextInput
											id={'question-hrs-' + item.numero}
											name={'hrs'}
											placeholder="00"
											onChange={(e: any) => {
												if (!isNumber(e.target.value))
													return;
												setTimeWork({
													...timeWork,
													hrs: e.target.value,
												});
											}}
											disabled={
												(quest[0].respuesta === '7' &&
													index !== 0) ||
												(['3', '4'].includes(
													quest[1].respuesta
												) &&
													![0, 1].includes(index)) ||
												(quest[2].respuesta === '1' &&
													![0, 1, 2].includes(
														index
													)) ||
												(['1', '2'].includes(
													quest[3].respuesta
												) &&
													![0, 1, 2, 3].includes(
														index
													)) ||
												(['3'].includes(
													quest[4].respuesta
												) &&
													![0, 1, 2, 3, 4].includes(
														index
													))
											}
											contentStyles={{ maxWidth: 300 }}
											value={timeWork.hrs}
										/>
									</div>
								</div>
							</div>
						)}
						{item.opciones.length === 0 &&
							item.numero === '2.29' && (
								<div
									style={{ maxWidth: 400, display: 'flex' }}
									key={'mes' + item.numero}
								>
									<div
										style={{
											textAlign: 'left',
											marginRight: '20px',
										}}
									>
										<div>Mes</div>
										<TextInput
											id={'question-month-' + item.numero}
											name={'month'}
											placeholder="01"
											onChange={(e: any) => {
												if (!isNumber(e.target.value))
													return;
												if (e.target.value.length > 2)
													return;
												if (e.target.value > 12)
													return;
												if (e.target.value === '00') {
													setDate({
														...date,
														month: '01',
													});
												} else {
													setDate({
														...date,
														month: e.target.value,
													});
												}
											}}
											disabled={
												(quest[0].respuesta === '7' &&
													index !== 0) ||
												(['3', '4'].includes(
													quest[1].respuesta
												) &&
													![0, 1].includes(index)) ||
												(quest[2].respuesta === '1' &&
													![0, 1, 2].includes(
														index
													)) ||
												(['1', '2'].includes(
													quest[3].respuesta
												) &&
													![0, 1, 2, 3].includes(
														index
													)) ||
												(['3'].includes(
													quest[4].respuesta
												) &&
													![0, 1, 2, 3, 4].includes(
														index
													))
											}
											contentStyles={{ maxWidth: 100 }}
											value={date.month}
										/>
									</div>
									<div
										style={{
											textAlign: 'left',
											marginRight: '20px',
										}}
									>
										<div>Año</div>
										<TextInput
											id={'question-year-' + item.numero}
											name={'year'}
											placeholder="1999"
											onChange={(e: any) => {
												if (!isNumber(e.target.value))
													return;
												if (e.target.value.length > 4)
													return;
												if (e.target.value > 2023)
													return;
												if (e.target.value.length === 4 && e.target.value < '1900') {
													setDate({
														...date,
														year: '1900',
													});
												} else {
													setDate({
														...date,
														year: e.target.value,
													});
												}
											}}
											disabled={
												(quest[0].respuesta === '7' &&
													index !== 0) ||
												(['3', '4'].includes(
													quest[1].respuesta
												) &&
													![0, 1].includes(index)) ||
												(quest[2].respuesta === '1' &&
													![0, 1, 2].includes(
														index
													)) ||
												(['1', '2'].includes(
													quest[3].respuesta
												) &&
													![0, 1, 2, 3].includes(
														index
													)) ||
												(['3'].includes(
													quest[4].respuesta
												) &&
													![0, 1, 2, 3, 4].includes(
														index
													))
											}
											contentStyles={{ maxWidth: 300 }}
											value={date.year}
										/>
									</div>
								</div>
							)}
					</>
				),
				disabled:
					((index === 3 || index === 4 || index === 5) &&
						(quest[2].respuesta === '2' ||
							quest[2].respuesta === '3')) ||
					(index === 5 && quest[4].respuesta === '2') ||
					(quest[0].respuesta === '7' && index !== 0) ||
					(['3', '4'].includes(quest[1].respuesta) &&
						![0, 1].includes(index)) ||
					(quest[2].respuesta === '1' &&
						![0, 1, 2].includes(index)) ||
					(['1', '2'].includes(quest[3].respuesta) &&
						![0, 1, 2, 3].includes(index)) ||
					(['3'].includes(quest[4].respuesta) &&
						![0, 1, 2, 3, 4].includes(index)),
			};
			return questions.push(newItem);
		});
		setQuests(questions);
	};

	const verifyComplete = () => {
		let questHabilitados: any = []
		for (let i = 0; i < quests.length; i++) {
			if (quests[i].disabled === false) {
				questHabilitados.push(i)
			}
		}
		let respuesta = quest.every((item: any, index: any) => {
			if (questHabilitados.includes(index) && item.respuesta.length === 0) return false
			else return true
		})
		return respuesta
	};

	const sendData = () => {
		let respuesta = `Número de días ${timeWork.days.length > 0 ? timeWork.days : 0
			} Número de horas ${timeWork.hrs.length > 0 ? timeWork.hrs : 0}`;
		handleChange(respuesta, '2.28');
		nextStep();
	};

	return (
		<HeaderStep
			title={'Situación laboral'}
			subTitle={'Segundo empleo'}
			nextStep={sendData}
			afterQuestionarySteps={afterQuestionarySteps}
			disabledContinue={!verifyComplete()}
			disabledButton={!verifyComplete()}
			children={quests}
			alert={alert}
		/>
	);
};

export default QuestStep4;
