/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import GotElecSig from './gotElecSig';
import GotPayslip from './gotPayslip';
import GotSAT from './gotSAT';
import MakeDate from './makeDate';
import RfcHome from './rfcCorrectionHome';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import ModalLoading from '../../components/ModalLoading';
import { IAlert } from '../../interfaces/alert';
import { apiRfcCorrection } from '../../api/modules/rfcCorrection';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import Status from './status';
import CurpRegister from './registraCURP';
import NotCurp from './notCurp';
import NotName from './notName';
import CloseCase from './closeCase';
interface IDataStatus {
	apMaterno?: string;
	apPaterno?: string;
	consecutivo?: string;
	curp?: string;
	nombre?: string;
	nss?: string;
	rfc?: string;
	status?: {
		code?: string;
		description?: string;
	};
	CodigoError?: string;
	TipoError?: string;
	descripcionServicio?: string;
	fecha?: string;
	fechaStatus?: string;
	noCaso?: string;
	nombreOperacion?: string;
	operacion?: string;
	description?: string;
}
const RFCCorrection = () => {
	const [page, setPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const { user } = useSelector((state: RootState) => state.session);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [dataStatus, setDataStatus] = useState<IDataStatus>({});
	useEffect(() => {
		handleOnSubmit();
	}, [user?.curp, user?.nss, user?.rfc]);

	const handleOnSubmit = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const rfc_base64 = await encrypt(user?.rfc.toString() || '', key);
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const curp_base64 = await encrypt(user?.curp.toString() || '', key);

			apiRfcCorrection
				.getInitialStatus(nss_base64, rfc_base64, curp_base64)
				.then((response) => {
					if (response?.result) {
						setDataStatus(response.result);
						if (response?.result?.isEmpty) {
							setPage(0);
						} else {
							setPage(5);
						}
					}
				})
				.catch((error: any) => {
					if (error?.code && error?.code === 'CURP') {
						setPage(10);
					} else if (user?.rfc === '' && error?.code !== 'CURP') {
						setPage(0);
					} else {
						setAlert({
							show: true,
							message:
								error && error.description
									? error.description
									: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			if (error?.code && error?.code === 'CURP') {
				setPage(10);
			} else if (user?.rfc === '' && error?.code !== 'CURP') {
				setPage(0);
				setAlert({
					show: true,
					message: error.description
						? error.description
						: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			} else {
				setAlert({
					show: true,
					message: error.description
						? error.description
						: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			}
		}
	};

	return (
		<Layout>
			<Box>
				<ModalLoading loading={loading} />
				{page === 0 && (
					<RfcHome
						setPage={setPage}
						rfc={user?.rfc}
						alertInitialStatus={alert}
					/>
				)}
				{page === 1 && <GotElecSig setPage={setPage} />}
				{page === 2 && <GotPayslip setPage={setPage} />}
				{page === 3 && <GotSAT setPage={setPage} />}
				{page === 4 && <MakeDate setPage={setPage} status={dataStatus} />}
				{page === 5 && <Status status={dataStatus} setPage={setPage} />}
				{page === 6 && <CloseCase status={dataStatus} setPage={setPage} />}
				{page === 7 && <NotCurp setPage={setPage} />}
				{page === 9 && <NotName setPage={setPage} />}
				{page === 10 && <CurpRegister setPage={setPage} alert={alert} />}
				<div style={{ textAlign: 'center', paddingTop: 15 }}>
					<p>
						Responsable de la información:{' '}
						<strong>
							Gerencia de Administración del Patrimonio Social y Servicios
						</strong>
					</p>
				</div>
			</Box>
		</Layout>
	);
};

export default RFCCorrection;
