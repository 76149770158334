import { Paper, useMediaQuery } from '@mui/material';
import { HeaderList } from '../../../components/HeaderList';
import { formatDate } from '../../../utils/dates';
import { UseStyle } from '../Style';
import CustomButton from '../../../components/Button';
import { useTheme } from '@mui/material/styles';
import theme from '../../../config/theme';

const PaymentRejected = ({ rejected, showRejected }: any) => {
	const classes = UseStyle();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	return (
		<>
			<HeaderList
				title="Opciones de pago"
				date={formatDate('P')}
				list={[
					'Haz tus pagos completos antes del último día del mes actual.',
					'Si vas a liquidar tu crédito, paga directamente en cualquier banco autorizado.',
				]}
			/>
			<Paper className={classes.proceedPaymentContainer}>
				<div className="info-text">
					<p
						style={{
							color: theme.palette.info.main,
							fontWeight: 500,
							fontSize: 22,
						}}
					>
						{rejected}
					</p>
				</div>
				<div className="proceed-actions">
					<CustomButton
						label={'Regresar'}
						variant="outlined"
						onClick={() => showRejected(false)}
						styles={{
							maxWidth: matches ? '100%' : 200,
							margin: 10,
						}}
					/>
				</div>
			</Paper>
		</>
	);
};

export default PaymentRejected;
