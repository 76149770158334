import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		inputContainer: {
			marginBottom: '5px',
			[theme.breakpoints.up('xs')]: {
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
			},
			[theme.breakpoints.up('sm')]: {
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
			},
			[theme.breakpoints.up('md')]: {
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
			},
		},
		inputContainerFirmElec: {
			[theme.breakpoints.up('xs')]: {
				width: '85%',
				marginLeft: 25,
				marginBottom: 30,
			},
			[theme.breakpoints.up('sm')]: {
				width: '30%',
				display: 'flex',
				marginLeft: 250,
				marginBottom: 30,
			},
			[theme.breakpoints.up('md')]: {
				width: '30%',
				display: 'flex',
				marginLeft: 400,
				marginBottom: 30,
			},
		},
		inputRfc: {
			[theme.breakpoints.up('xs')]: {
				width: 342,
				height: 40,
				marginLeft: 21,
				marginRight: 20,
				marginTop: 20,
			},
			[theme.breakpoints.up('sm')]: {
				width: 512,
				height: 52,
				marginRight: 0,
			},
			[theme.breakpoints.up('md')]: {
				width: 812,
				height: 52,
			},
		},
		inputRfcAlter: {
			marginBottom: 20,
			[theme.breakpoints.up('xs')]: {
				width: 342,
				height: 40,
				marginLeft: 21,
				marginRight: 20,
				marginTop: 20,
			},
			[theme.breakpoints.up('sm')]: {
				width: 512,
				height: 52,
				marginRight: 0,
			},
			[theme.breakpoints.up('md')]: {
				width: 812,
				height: 52,
			},
		},
		pContainer: {
			[theme.breakpoints.up('xs')]: {
				width: 342,
				height: 40,
				marginLeft: 21,
				marginRight: 20,
				paddingTop: 5,
				paddingBottom: 80,
			},
			[theme.breakpoints.up('sm')]: {
				width: 650,
				height: 52,
				marginLeft: 55,
				marginRight: 0,
			},
			[theme.breakpoints.up('md')]: {
				width: 812,
				height: 52,
				marginLeft: 65,
			},
		},
		pContainerPayslip: {
			[theme.breakpoints.up('xs')]: {
				// width: '100%',
				// height: 40,
				// // marginLeft: 0,
				// // marginRight: 20,
				// marginTop: 20,
			},
			[theme.breakpoints.up('md')]: {
				// width: 812,
				// height: 52,
				// marginLeft: 65,
			},
		},

		h3: {
			[theme.breakpoints.up('xs')]: {
				textAlign: 'center',
				padding: 40,
				paddingBottom: 0,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				textAlign: 'center',
				paddingTop: 30,
				fontSize: 18,
			},
			[theme.breakpoints.up('md')]: {
				textAlign: 'center',
				paddingTop: 30,
				fontSize: 18,
			},
		},
		p: {
			[theme.breakpoints.up('xs')]: {
				textAlign: 'center',
				marginTop: 30,
				// marginLeft: 1,
				// marginRight: 39,
				opacity: 0.6,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				textAlign: 'center',
				marginTop: 25,
				// marginLeft: 120,
				opacity: 0.6,
				fontSize: 18,
			},
			[theme.breakpoints.up('md')]: {
				textAlign: 'center',
				marginTop: 25,
				// marginLeft: 68,
				opacity: 0.6,
				fontSize: 18,
			},
		},
		pDate: {
			[theme.breakpoints.up('xs')]: {
				textAlign: 'center',
				paddingTop: 30,
				marginLeft: 20,
				marginRight: 20,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				textAlign: 'center',
				paddingTop: 30,
				marginLeft: 50,
				marginRight: 50,

				fontSize: 18,
			},
			[theme.breakpoints.up('md')]: {
				textAlign: 'center',
				paddingTop: 40,
				marginLeft: 68,

				fontSize: 18,
			},
		},
		botonContainer: {
			[theme.breakpoints.up('xs')]: {
				width: '100%',
				display: 'flex',
				flexDirection: 'column-reverse',
				alignItems: 'center',
				justifyContent: 'center',
				marginTop: 20,
				paddingBottom: 30,
			},

			[theme.breakpoints.up('md')]: {
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				marginTop: 10,
			},
		},
		botonContainerFirmElec: {
			[theme.breakpoints.up('xs')]: {
				display: 'flex',
				flexDirection: 'column-reverse',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 20,
				paddingBottom: 20,
			},

			[theme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				marginTop: 10,
			},
		},
		botonContainerPayslip: {
			[theme.breakpoints.up('xs')]: {
				width: 300,
				display: 'flex',
				flexDirection: 'column-reverse',
				justifyContent: 'right',
				marginTop: 20,
				marginLeft: 65,
				paddingBottom: 20,
			},
			[theme.breakpoints.up('sm')]: {
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				marginTop: 25,
				marginLeft: 8,
			},
			[theme.breakpoints.up('md')]: {
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				marginTop: 10,
			},
		},
		botonContainerRadio: {
			[theme.breakpoints.up('xs')]: {
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				marginLeft: 30,
				paddingRight: 40,
			},
			[theme.breakpoints.up('sm')]: {
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
			},
			[theme.breakpoints.up('md')]: {
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
			},
		},
		h4: {
			[theme.breakpoints.up('xs')]: {
				textAlign: 'left',
				paddingTop: 0,
				fontSize: 16,
				marginLeft: 15,
				marginBottom: 0,
				width: '50%',
			},
			[theme.breakpoints.up('sm')]: {
				textAlign: 'left',
				paddingTop: 0,
				fontSize: 18,
				marginLeft: 22,
				width: '20%',
			},
			[theme.breakpoints.up('md')]: {
				textAlign: 'left',
				paddingTop: 0,
				fontSize: 18,
				marginLeft: 22,
				color: '#29398F',
			},
		},
		h4Date: {
			[theme.breakpoints.up('xs')]: {
				textAlign: 'left',
				paddingTop: 0,
				fontSize: 16,
				marginLeft: 20,
				width: '55%',
				paddingRight: 0,
				marginBottom: 0,
			},
			[theme.breakpoints.up('sm')]: {
				textAlign: 'left',
				paddingTop: 0,
				fontSize: 18,
				marginLeft: 22,
			},
			[theme.breakpoints.up('md')]: {
				textAlign: 'left',
				paddingTop: 0,
				fontSize: 18,
				marginLeft: 22,
				color: '#29398F',
				marginRight: 0,
				paddingRight: 0,
			},
		},
		hr: {
			opacity: 0.4,
			marginLeft: 15,
			marginRight: 20,
			marginBottom: 0,
		},
		hrPayslip: {
			[theme.breakpoints.up('xs')]: {
				opacity: 0.4,
				marginLeft: 15,
				marginRight: 20,
				marginBottom: 0,
				marginTop: 70,
			},
			[theme.breakpoints.up('sm')]: {
				opacity: 0.4,
				marginLeft: 15,
				marginRight: 20,
				marginBottom: 0,
				marginTop: 0,
			},
		},
		phidesmall: {
			[theme.breakpoints.up('xs')]: {
				display: 'block',
				opacity: 0.5,
				marginLeft: 15,
				marginTop: 0,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'none',
				opacity: 0.5,
			},
			[theme.breakpoints.up('md')]: {
				display: 'none',
				opacity: 0.5,
				fontSize: 18,
			},
		},
		phidesmallMakeDate: {
			[theme.breakpoints.up('xs')]: {
				display: 'block',
				opacity: 0.5,
				marginLeft: 15,
				marginTop: 0,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'none',
				opacity: 0.5,
			},
			[theme.breakpoints.up('md')]: {
				display: 'none',
				opacity: 0.5,
				fontSize: 18,
			},
		},
		phidesmallDate: {
			[theme.breakpoints.up('xs')]: {
				display: 'block',
				opacity: 0.5,
				marginLeft: 20,
				marginTop: 0,
				marginBottom: 90,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'none',
				opacity: 0.5,
			},
			[theme.breakpoints.up('md')]: {
				display: 'none',
				opacity: 0.5,
				fontSize: 18,
			},
		},
		phidesmallthanks: {
			[theme.breakpoints.up('xs')]: {
				display: 'block',
				marginLeft: 15,
				marginTop: 0,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'none',
			},
			[theme.breakpoints.up('md')]: {
				display: 'none',
				fontSize: 18,
			},
		},
		phidelarge: {
			[theme.breakpoints.up('xs')]: {
				display: 'none',
				opacity: 0.5,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'flex',
				opacity: 0.5,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				opacity: 0.5,
				marginRight: 30,
				fontSize: 18,
			},
		},
		phidelargeMakeDate: {
			[theme.breakpoints.up('xs')]: {
				display: 'none',
				opacity: 0.5,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'flex',
				opacity: 0.5,
			},
			[theme.breakpoints.up('md')]: {
				opacity: 1,
				marginRight: 0,
				fontSize: 18,
				marginLeft: 150,
				marginTop: 15,
				marginBottom: 10,
			},
		},
		phidelargeDate: {
			[theme.breakpoints.up('xs')]: {
				display: 'none',
				opacity: 0.5,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'flex',
				opacity: 0.5,
				width: '40%',
				paddingBottom: 0,
				paddingTop: 3,
				marginRight: 0,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				opacity: 0.5,
				width: '40%',
				paddingTop: 3,
				marginRight: 280,
				fontSize: 18,
			},
		},
		numbersDate: {
			[theme.breakpoints.up('xs')]: {
				display: 'none',
				opacity: 0.5,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'flex',
				opacity: 0.5,
				marginRight: 360,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				opacity: 0.5,
				width: '40%',
				paddingTop: 3,
				marginRight: 432,
				fontSize: 18,
			},
		},
		phidelargePayslip: {
			[theme.breakpoints.up('xs')]: {
				display: 'none',
				opacity: 0.5,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'inline',
				marginRight: 120,
				marginTop: 30,
			},
			[theme.breakpoints.up('md')]: {
				display: 'inline',
				marginRight: 500,
				marginTop: 35,
			},
		},

		phidelargethanks: {
			[theme.breakpoints.up('xs')]: {
				display: 'none',
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'flex',
				marginRight: 30,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',

				marginRight: 30,
				fontSize: 18,
			},
		},
		chooseFile: {
			[theme.breakpoints.up('xs')]: {
				paddingRight: 18,
				textDecoration: 'none',
				color: '#D1001F',
				paddingTop: 0,
				fontSize: 16,
				width: '50%',
			},
			[theme.breakpoints.up('sm')]: {
				paddingRight: 0,
				textDecoration: 'none',
				color: '#D1001F',
				paddingTop: 0,
				width: '20%',
			},
			[theme.breakpoints.up('md')]: {
				paddingRight: 40,
				textDecoration: 'none',
				color: '#D1001F',
				paddingTop: 0,
				fontSize: 18,
				width: '15%',
			},
		},
		chooseFilePayslip: {
			[theme.breakpoints.up('xs')]: {
				paddingRight: 18,
				textDecoration: 'none',
				color: '#D1001F',
				paddingTop: 0,
				fontSize: 16,
				width: '50%',
			},
			[theme.breakpoints.up('sm')]: {
				paddingRight: 0,
				textDecoration: 'none',
				color: '#D1001F',
				paddingTop: 0,
				width: '20%',
			},
			[theme.breakpoints.up('md')]: {
				paddingRight: 40,
				textDecoration: 'none',
				color: '#D1001F',
				paddingTop: 0,
				fontSize: 18,
				width: '55%',
			},
		},
		chooseFileDate: {
			[theme.breakpoints.up('xs')]: {
				paddingRight: 18,
				textDecoration: 'none',
				color: '#D1001F',
				paddingTop: 0,
				paddingBottom: 20,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				marginRight: 0,
				paddingRight: 0,
				textDecoration: 'none',
				color: '#D1001F',
				width: '20%',
				paddingTop: 20,
			},
			[theme.breakpoints.up('md')]: {
				marginRight: 20,
				width: '20%',
				textDecoration: 'none',
				color: '#D1001F',
				paddingTop: 20,
				fontSize: 18,
			},
		},
		title: {
			[theme.breakpoints.up('xs')]: {
				textAlign: 'center',
				paddingTop: 5,
				fontSize: 26,
				marginLeft: 20,
				marginRight: 20,
				color: '#29398F',
			},
			[theme.breakpoints.up('sm')]: {
				textAlign: 'center',
				paddingTop: 5,
				fontSize: 26,
				marginLeft: 0,
				marginRight: 0,
				color: '#29398F',
			},
			[theme.breakpoints.up('md')]: {
				textAlign: 'center',
				paddingTop: 5,
				fontSize: 30,
				marginLeft: 10,
				marginRight: 0,
				color: '#29398F',
			},
		},
		containerThanksElecSig: {
			[theme.breakpoints.up('xs')]: {
				width: '100%',
				marginLeft: 5,
			},
			[theme.breakpoints.up('sm')]: {
				width: '90%',
				marginLeft: 35,
			},
			[theme.breakpoints.up('md')]: {
				display: 'none',
				//padding: 500,
			},
		},
		pText: {
			[theme.breakpoints.up('xs')]: {
				width: '85%',
				marginLeft: 30,
				fontSize: 16,
				textAlign: 'center',
			},
			[theme.breakpoints.up('sm')]: {
				width: '85%',
				marginLeft: 65,
				fontSize: 16,
			},
			[theme.breakpoints.up('md')]: {
				textAlign: 'center',
				width: '85%',
				marginLeft: 80,
				fontSize: 18,
			},
		},
		pTextPayslip: {
			[theme.breakpoints.up('xs')]: {
				width: '85%',
				marginLeft: 25,
				fontSize: 16,
			},
			[theme.breakpoints.up('sm')]: {
				width: '85%',
				marginLeft: 65,
				fontSize: 16,
			},
			[theme.breakpoints.up('md')]: {
				width: '85%',
				marginLeft: 80,
				fontSize: 18,
			},
		},
		botonContainerThanksElecSig: {
			[theme.breakpoints.up('xs')]: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				marginTop: 20,
				alignItems: 'center',
				paddingBottom: 20,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 10,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 10,
			},
		},
		botonContainerThanks: {
			[theme.breakpoints.up('xs')]: {
				width: 280,
				display: 'flex',
				flexDirection: 'column-reverse',
				justifyContent: 'center',
				marginTop: 20,
				marginLeft: 45,
				paddingBottom: 20,
			},
			[theme.breakpoints.up('sm')]: {
				width: 500,
				display: 'flex',
				justifyContent: 'center',
				marginTop: 10,
				marginLeft: 140,
			},
			[theme.breakpoints.up('md')]: {
				width: 500,
				display: 'flex',
				justifyContent: 'center',
				marginTop: 10,
				marginLeft: 300,
			},
		},
		botonContainerThanksPayslip: {
			[theme.breakpoints.up('xs')]: {
				display: 'flex',
				flexDirection: 'column-reverse',
				alignItems: 'center',
				justifyContent: 'center',
				marginTop: 20,
				// marginLeft: 45,
				paddingBottom: 20,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				marginTop: 10,
				// marginLeft: 300,
			},
		},
		botonContainerThanksSAT: {
			[theme.breakpoints.up('xs')]: {
				display: 'flex',
				flexDirection: 'column-reverse',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 20,
				// marginLeft: 25,
				paddingBottom: 20,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				marginTop: 35,
				alignItems: 'center',
				paddingBottom: 35,
			},
		},
		listContainer: {
			[theme.breakpoints.up('xs')]: {
				display: 'flex',
				textAlign: 'center',
				paddingLeft: 20,
				paddingRight: 29,
				marginBottom: 5,
			},
			[theme.breakpoints.up('sm')]: {
				display: 'flex',
				textAlign: 'center',
				paddingLeft: 25,
				paddingRight: 29,
				marginBottom: 5,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				textAlign: 'center',
				paddingLeft: 85,
				paddingRight: 70,
				marginBottom: 5,
			},
		},
		pGotSAT: {
			[theme.breakpoints.up('xs')]: {
				marginTop: 0,
				marginBottom: 0,
				textAlign: 'left',
				fontSize: 14,
			},
			[theme.breakpoints.up('sm')]: {
				marginTop: 0,
				marginBottom: 0,
				textAlign: 'left',
				fontSize: 14,
			},

			[theme.breakpoints.up('md')]: {
				marginTop: 0,
				marginBottom: 0,
				textAlign: 'left',
				fontSize: 16,
			},
		},
		pMakeDate: {
			[theme.breakpoints.up('xs')]: {
				marginTop: 15,
				marginBottom: 0,
				textAlign: 'center',
				fontSize: 14,
				width: '85%',
				marginLeft: 25,
			},
			[theme.breakpoints.up('sm')]: {
				marginTop: 0,
				marginBottom: 0,
				textAlign: 'center',
				fontSize: 14,
			},

			[theme.breakpoints.up('md')]: {
				marginLeft: 90,
				marginTop: 20,
				marginBottom: 30,
				width: '85%',
				textAlign: 'center',
				fontSize: 16,
			},
		},
		botonContainerDate: {
			[theme.breakpoints.up('xs')]: {
				width: '100%',
				display: 'flex',
				flexDirection: 'column-reverse',
				justifyContent: 'right',
				marginTop: 15,
				marginLeft: 35,
				paddingBottom: 20,
				paddingTop: 0,
			},
			[theme.breakpoints.up('sm')]: {
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				marginTop: 10,
				marginLeft: 6,
				paddingTop: 0,
			},
			[theme.breakpoints.up('md')]: {
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				marginTop: 10,
			},
		},
		divColumnText: {
			//textAlign: 'center',
			width: '100%',
			marginTop: 0,
			marginBottom: 0,
		},
		rfcCorrection: { display: 'inline', height: 0 },
		chooseFileOK: {
			[theme.breakpoints.up('xs')]: {
				display: 'none',
				width: '30%',
			},
			[theme.breakpoints.up('sm')]: {
				display: 'none',
				width: '30%',
			},
			[theme.breakpoints.up('md')]: {
				display: 'none',
				width: '30%',
			},
		},
		botonesArchivo: {
			[theme.breakpoints.down('sm')]: {
				display: 'block',
			},

			[theme.breakpoints.up('md')]: {},
		},
		alertIcon: {
			'& .MuiAlert-icon': {
				color: `#D1001F !important`,
			},
			'& .MuiAlert-message': {
				padding: '0px !important',
			},
		},
		sizeIcon: {
			width: '22px',
			height: '22px',
		},
	})
);
