import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('131', 'entrega-escrituras');

export const ENDPOINTS_ENTREGA_ESCRITURAS: IBitacora = {
	'/entrega-escritura-crea-caso': _bitacora.Read('131100', 'Crear caso'),
	'/entrega-escritura-consulta-caso': _bitacora.Read(
		'131101',
		'Consultar casos'
	),
	'/entrega-escritura-valida': _bitacora.Read('131102', 'Validar caso'),
	'/address-catalog': _bitacora.Read('131103', 'Consultar direccion'),
	'/entrega-escritura-obtener-estados': _bitacora.Read(
		'131104',
		'Consultar estados'
	),
	'/entrega-escritura-cesi-estado': _bitacora.Read(
		'131105',
		'Consultar cesi estados'
	),
	'/entrega-escritura-lista-documentos': _bitacora.Read(
		'131106',
		'Consultar lista de documentos'
	),
	'/entrega-escritura-subir-documentos': _bitacora.Read(
		'131107',
		'Subir documentos'
	),
	'/entrega-escritura-sin-documentos': _bitacora.Read(
		'131108',
		'Continuar sin documentos'
	),
};
