import { useEffect, useState } from 'react';
import { ErrorRounded } from '@mui/icons-material';
import { Box, Paper, Grid, Divider, InputAdornment } from '@mui/material';
import Header from '../../../components/Header';
import TextInput from '../../../../../components/TextInput';
import ButtonGroup from '../../../components/ButtonGroup';
import ProductsMiuc from '../../../components/ProductsMiuc';
import { useAlertCustom } from '../../../hooks/useAlertCustom';
import ModalLoading from '../../../../../components/ModalLoading';
import SelectCustom from '../../../../../components/SelectCustom';
import {
	arrayPlazos,
	arrayPlazosSuelo,
	colCrediterreno,
	colSueloContruccion,
	numberFormat,
} from './utils';
import style from './styles/productos.module.css';
import { useCrediterreno } from '../hooks/useCrediterreno';
import { useNavigation } from '../../../hooks/useNavigation';
import { creditTypes } from '../../../../CreditRequest/MIUC/utils';
import CustomAlert from '../../../../../components/CustomAlert';
import { MIUC_PRODUCTS } from '../../../constants/products';
import { FormatMoney } from '../../../utils/tableFormat.utils';
import { ModalGastosTitulacion } from '../../../components/ModalGastosTitulación/modalGastosTitulacion';
import { CalculateLabel } from '../../../components/CalculateLabel/calculateLabel';
import { useGastosTitulacion } from '../../../hooks/useGastosTitulacion';
import { CODES_RESPONSE } from '../../../../../config/miucConstants';
import { useMontoPlazoFlexible } from '../../../hooks/useMontoPlazoFlexible';
import { PRODUCT_KEYS } from '../../../interfaces/montoPlazoFlexible';
import SelectInput from '../../../../../components/SelectInput';

const ProductsCrediterreno = () => {
	const {
		saveData,
		returnStep,
		addSelection,
		nextStep,
		getProgressStep,
		dataProcessController,
	} = useNavigation();
	const { setAlertClean, setAlertCustom, AlertInput, getAlertStatus } =
		useAlertCustom();
	const { active, total } = getProgressStep();
	const [selectProduct, setSelectProduct] = useState('');
	const [productos, setProductos] = useState<any>([]);
	const [productosOriginal, setProductosOriginal] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState(false);
	const { getProducts, handleTrazabilidad, handleTrazaSuelo, plazos } =
		useCrediterreno();
	const [data, setData] = useState({
		plazo: '',
		valorTerreno: '',
		valorConstruccion: '',
	});

	const [productsTable, setProductsTable] = useState<any>([]);
	const [gastosTitulacion, setGastosTitulacion] = useState<number>(0);
	const [showGastosModal, setShowGastosModal] = useState(false);
	const { recalcularMontosTitulacion, enableCalcularGastos } =
		useGastosTitulacion();
	const { credit } = dataProcessController.processInfo;
	const [productsToCalculate, setProductsToCalculate] = useState<any>([]);
	const {
		getInputMonto,
		loadProductData,
		disableSelectPlazo,
		onChangePlazo,
		disableInputMonto,
		disableAll,
		enableAll,
		disableCalculateBtn,
	} = useMontoPlazoFlexible();
	const [rowsTitle, setRowsTitle] = useState(
		dataProcessController.processInfo.credit === MIUC_PRODUCTS.CREDITERRENO
			? colCrediterreno
			: colSueloContruccion
	);
	const [refreshProducts, setRefreshProducts] = useState<boolean>(false);

	useEffect(() => {
		if (credit === MIUC_PRODUCTS.CREDITERRENO)
			recalcularMontosTitulacion(
				gastosTitulacion,
				productos,
				(newProducts) => setProductos(newProducts)
			);
	}, [gastosTitulacion]);

	useEffect(() => {
		formatProducts();
	}, [productos, disableInputMonto]);

	const formatProducts = () => {
		setRowsTitle(
			rowsTitle.map((row) => {
				if (row.key === 'mmcMon')
					return {
						...row,
						format: undefined,
					};
				if (row.key === 'mmcMonSuelo')
					return {
						...row,
						format: undefined,
					};
				if (row.key === 'montoConstruccion')
					return {
						...row,
						format: undefined,
					};
				return row;
			})
		);
		setProductsTable(
			(productos || []).map((product: any) => {
				let inputs = {};
				if (
					dataProcessController.processInfo?.credit ===
					MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION
				) {
					inputs = {
						mmcMonSuelo: getInputMonto({
							key: PRODUCT_KEYS.mmcMonSuelo,
							product: product.producto,
							onChange: (value) =>
								handleChangeProductMonto(
									PRODUCT_KEYS.mmcMonSuelo,
									value,
									product.producto
								),
							value:
								productos?.find(
									(v: any) => v.producto === product.producto
								)?.mmcMonSuelo || '',
						}),
						montoConstruccion: getInputMonto({
							key: PRODUCT_KEYS.montoConstruccion,
							product: product.producto,
							onChange: (value) =>
								handleChangeProductMonto(
									PRODUCT_KEYS.montoConstruccion,
									value,
									product.producto
								),
							value:
								productos?.find(
									(v: any) => v.producto === product.producto
								)?.montoConstruccion || '',
						}),
					};
				} else {
					inputs = {
						mmcMon: getInputMonto({
							key: PRODUCT_KEYS.monto,
							product: product.producto,
							onChange: (value) =>
								handleChangeProductMonto(
									PRODUCT_KEYS.mmcMonSuelo,
									value,
									product.producto
								),
							value:
								productos?.find(
									(v: any) => v.producto === product.producto
								)?.mmcMon || '',
						}),
					};
				}
				return {
					...product,
					...inputs,
					gasMonCny: (
						<FormatMoney moneyToFormat={product.gasMonCny} />
					),
					gasMon:
						credit === MIUC_PRODUCTS.CREDITERRENO &&
						product.producto === 'CI' ? (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								{(gastosTitulacion !== 0 ||
									!enableCalcularGastos) && (
									<FormatMoney
										moneyToFormat={gastosTitulacion}
									/>
								)}
								{enableCalcularGastos && (
									<CalculateLabel
										onClick={() => setShowGastosModal(true)}
									/>
								)}
							</Box>
						) : (
							<FormatMoney moneyToFormat={product.gasMon} />
						),
				};
			})
		);
	};

	const getAllProducts = async () => {
		try {
			setLoading(true);
			setAlertClean();
			const response = await getProducts('', '', 0);
			if (response.code !== CODES_RESPONSE.CORRECTO) {
				setAlertCustom({
					message:
						response.message ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
				});
				return;
			}
			setProductosOriginal(response.products);
			setProductsToCalculate(response.products);

			let plazoMaximo = '';
			response.products.forEach((prod) => {
				if (Number(prod.pzoCre) > Number(plazoMaximo))
					plazoMaximo = prod.pzoCre;
			});
			if (credit === MIUC_PRODUCTS.CREDITERRENO) {
				setGastosTitulacion((gastos) => {
					recalcularMontosTitulacion(
						gastos,
						response.products,
						(newProducts) => setProductos(newProducts)
					);
					return gastos;
				});
				loadProductData({
					plazos: {
						plazo: plazoMaximo,
					},
					products: response.products.map((p) => ({
						producto: p.producto,
						[PRODUCT_KEYS.monto]: p.mmcMon,
					})),
				});
			} else {
				setProductos(response.products);
				loadProductData({
					plazos: {
						plazo: plazoMaximo,
					},
					products: response.products.map((p) => ({
						producto: p.producto,
						[PRODUCT_KEYS.mmcMonSuelo]: p.mmcMonSuelo,
						[PRODUCT_KEYS.montoConstruccion]:
							p.mmcMonSuelo + p.montoConstruccion,
					})),
				});
			}
		} catch (error: any) {
			setAlertCustom({
				message:
					error.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
			});
		} finally {
			setLoading(false);
		}
	};

	const nextProccess = async () => {
		const productSelect = productos.find(
			(e: any) => e.producto === selectProduct
		);
		const dataTemp = {
			processInfo: {
				productSelect: productSelect,
			},
			processData: {
				active: getProgressStep().active + 1,
			},
		};
		saveData({
			...dataTemp,
		});
		addSelection({
			title: 'Producto seleccionado:',
			description: creditTypes[productSelect.producto].title,
		});
		if (
			dataProcessController?.processInfo?.credit ===
			MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION
		) {
			handleTrazaSuelo();
		} else {
			// handleTrazabilidad(productSelect);
		}
		nextStep();
	};

	const onChangeTerreno = ({
		target,
	}: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = target;
		if (name === 'valorTerreno' && isNaN(Number(value))) {
			return;
		}
		setData({
			...data,
			[name]: value,
		});
	};

	const onChangeConstruccion = ({
		target,
	}: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = target;
		if (name === 'valorConstruccion' && isNaN(Number(value))) {
			return;
		}
		setData({
			...data,
			[name]: value,
		});
	};

	const handlChangeAmount = (type: string) => {
		let newAmount = data.valorTerreno;
		if (type === 'blur' && data.valorTerreno !== '') {
			newAmount = numberFormat.format(Number(data.valorTerreno));
		}
		if (type === 'focus') {
			newAmount = data.valorTerreno.replace('$', '').replaceAll(',', '');
		}
		setData({
			...data,
			valorTerreno: newAmount,
		});
	};

	const handlChangeAmountConst = (type: string) => {
		let newAmount = data.valorConstruccion;
		if (type === 'blur' && data.valorConstruccion !== '') {
			newAmount = numberFormat.format(Number(data.valorConstruccion));
		}
		if (type === 'focus') {
			newAmount = data.valorConstruccion
				.replace('$', '')
				.replaceAll(',', '');
		}
		setData({
			...data,
			valorConstruccion: newAmount,
		});
	};

	const getCalcular = async () => {
		// setRefreshProducts(false);
		setRefreshProducts(true);

		try {
			setLoading(true);
			setAlertClean();
			const valorTotalTerrenoComprar = Number(
				(data.valorTerreno || '0')
					.replaceAll('$', '')
					.replaceAll(',', '')
			);
			const valorTotalConstruccion = Number(
				(data.valorConstruccion || '0')
					.replaceAll('$', '')
					.replaceAll(',', '')
			);
			if (
				dataProcessController.processInfo?.credit ===
					MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION &&
				valorTotalTerrenoComprar < 1
			) {
				setAlertCustom({
					message: 'Capture el valor del Terreno.',
				});
				return;
			}
			if (
				dataProcessController.processInfo?.credit ===
					MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION &&
				valorTotalConstruccion < 1
			) {
				setAlertCustom({
					message:
						'Capture el valor del presupuesto de construcción de vivienda.',
				});
				return;
			}
			let maxMontoSolicitado = '';
			let montosMaximos: {
				producto: string;
				montoSuelo: string;
				montoConstruccion: string;
			}[] = [];
			productsToCalculate.forEach((prod: any) => {
				if (
					dataProcessController.processInfo?.credit ===
					MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION
				) {
					const total =
						Number(prod?.montoCreditoSolicitadoSuelo || 0) +
						Number(prod?.montoCreditoSolicitadoConstruccion || 0);

					if (Number(maxMontoSolicitado) < total)
						maxMontoSolicitado = total.toString();

					montosMaximos.push({
						producto: prod.producto,
						montoSuelo: prod?.montoCreditoSolicitadoSuelo || 0,
						montoConstruccion:
							prod?.montoCreditoSolicitadoConstruccion || 0,
					});
				} else {
					if (
						Number(maxMontoSolicitado) <
						Number(prod?.montoCreditoSolicitadoSuelo || 0)
					)
						maxMontoSolicitado = prod?.montoCreditoSolicitadoSuelo;
				}
			});

			const valorTotalTerreno =
				valorTotalTerrenoComprar + valorTotalConstruccion;
			const response = await getProducts(
				valorTotalTerreno.toString(),
				data.plazo,
				valorTotalTerrenoComprar,
				maxMontoSolicitado,
				montosMaximos
			);
			if (credit === MIUC_PRODUCTS.CREDITERRENO) {
				recalcularMontosTitulacion(
					gastosTitulacion,
					response.products,
					(newProducts) => setProductos(newProducts)
				);
			} else {
				setProductos(response.products);
			}
			setSearch(true);
			disableAll();
		} catch (error: any) {
			setAlertCustom({
				message:
					error?.description ||
					error?.message ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
			});
		} finally {
			setLoading(false);
		}
	};

	const handlCleanData = () => {
		setSelectProduct('');
		setData({
			plazo: '',
			valorTerreno: '',
			valorConstruccion: '',
		});
		setGastosTitulacion(0);
		getAllProducts();
		setSearch(false);
		enableAll();
		// setRefreshProducts(true);
		setRefreshProducts(false);
	};

	useEffect(() => {
		getAllProducts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeProductMonto = (
		key: string,
		value: string,
		product: string
	) => {
		const subKey =
			key === PRODUCT_KEYS.mmcMonSuelo
				? 'montoCreditoSolicitadoSuelo'
				: 'montoCreditoSolicitadoConstruccion';
		const finalKey =
			dataProcessController?.processInfo?.credit ===
			MIUC_PRODUCTS.CREDITERRENO
				? 'mmcMon'
				: key;

		setProductos(
			productos.map((input: any) => {
				if (input.producto === product) {
					return {
						...input,
						[finalKey]: value,
					};
				}
				return input;
			})
		);
		setProductsToCalculate(
			productsToCalculate.map((input: any) => {
				if (input.producto === product) {
					return {
						...input,
						[subKey]: value,
					};
				}
				return input;
			})
		);
	};

	return (
		<Paper>
			<ModalGastosTitulacion
				setGastos={setGastosTitulacion}
				show={showGastosModal}
				gastos={gastosTitulacion}
				setShow={setShowGastosModal}
			/>
			<Header
				steps={{
					subtitle:
						'Revisa cada opción, compara entre ellas y elige la que más te convenga',
					title: 'Estas son las opciones de financiamiento que tenemos para ti',
					active,
					total,
				}}
			/>

			<Box padding={2}>
				<Grid container columns={12} margin={'10px 0px'}>
					<Grid item xs={12}>
						{getAlertStatus().status && (
							<CustomAlert
								show={getAlertStatus().status}
								severity={'warning'}
								message={getAlertStatus().message}
							/>
						)}
					</Grid>
					<Grid item xs={12} md={4} padding={'5px 10px'}>
						<TextInput
							id="valorTerreno"
							name="valorTerreno"
							value={data.valorTerreno}
							onChange={onChangeTerreno}
							label="Valor del terreno que quieres comprar"
							onBlur={() => handlChangeAmount('blur')}
							onFocus={() => handlChangeAmount('focus')}
							startAdornment={
								<InputAdornment position="start">
									$
								</InputAdornment>
							}
						/>
					</Grid>
					<Grid item xs={12} md={4} padding={'5px 10px'}>
						<label className={style.labelSelect}>
							Selecciona el plazo que más te convenga
						</label>
						<SelectInput
							id="plazo"
							name="plazo"
							value={data.plazo}
							placeholder={'Seleccionar'}
							onChange={(e) => {
								onChangePlazo(String(e.target.value));
								setData({
									...data,
									plazo: String(e.target.value),
								});
							}}
							options={plazos}
							disabled={disableSelectPlazo}
						/>
						<div className={style.subTitleWarning}>
							<ErrorRounded
								style={{ width: 14, marginRight: 2 }}
							/>
							{dataProcessController.processInfo?.credit ===
							MIUC_PRODUCTS.CREDITERRENO
								? 'Puede ser desde 1 y hasta 15 años.'
								: 'Puede ser desde 1 y hasta 30 años.'}
						</div>
					</Grid>
					{dataProcessController.processInfo?.credit ===
						MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION && (
						<Grid item xs={12} md={8} padding={'5px 10px'}>
							<TextInput
								id="valorConstruccion"
								name="valorConstruccion"
								value={data.valorConstruccion}
								onChange={onChangeConstruccion}
								label="Valor de la construcción"
								onBlur={() => handlChangeAmountConst('blur')}
								onFocus={() => handlChangeAmountConst('focus')}
								startAdornment={
									<InputAdornment position="start">
										$
									</InputAdornment>
								}
							/>
						</Grid>
					)}
					<Grid
						item
						xs={12}
						md={4}
						padding={'5px 10px'}
						alignSelf={'end'}
					>
						<ButtonGroup
							buttons={
								search
									? [
											{
												label: 'Calcular',
												execute: getCalcular,
												disabled:
													search ||
													disableCalculateBtn,
											},
											{
												label: 'Limpiar',
												execute: handlCleanData,
												variant: 'outlined',
											},
									  ]
									: [
											{
												label: 'Calcular',
												execute: getCalcular,
												disabled: disableCalculateBtn,
											},
									  ]
							}
						/>
					</Grid>
				</Grid>

				<CustomAlert
					severity="warning"
					show={search}
					message={
						'Con los datos ingresados estas son las condiciones a las que puedes acceder.'
					}
				/>

				<AlertInput />

				<Divider />

				<ProductsMiuc
					rememberHiddenProducts
					refresh={refreshProducts}
					keys={rowsTitle}
					products={productsTable}
					keyHeader={'producto'}
					selectProduct={selectProduct}
					setSelectProduct={setSelectProduct}
					textFooter={
						'Considera que en cualquier momento de tu crédito podrás adelantar pagos o realizar la liquidación de tu crédito sin tener ninguna penalización.'
					}
				/>

				<Divider style={{ marginTop: '20px' }} />

				<ButtonGroup
					buttons={[
						{
							label: 'Regresar',
							variant: 'outlined',
							execute: () => returnStep(),
						},
						{
							label: 'Continuar',
							execute: nextProccess,
							disabled: selectProduct !== '' ? false : true,
						},
					]}
				/>
			</Box>
			<ModalLoading loading={loading} />
		</Paper>
	);
};

export default ProductsCrediterreno;
