import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../../../components/Button';

interface Props {
	setPage: (arg: number) => void;
	setOpenModal: (value: boolean) => void;
	isMejoravit?: boolean;
}

const ConfirmModal = ({ setPage, setOpenModal, isMejoravit }: Props) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	return (
		<Box textAlign={'center'}>
			<p>
				Si cancelas tu solicitud de crédito deberás iniciar de nuevo tu
				trámite y enviar toda la documentación
			</p>
			{isMejoravit && (
				<p>
					En caso de que hayas entregado un aviso de retención a tu
					patrón, debes obtener tu aviso de suspensión de descuentos y
					entregárselo.
				</p>
			)}
			<p style={{ fontWeight: 700, color: '#283990' }}>
				¿Estás seguro qué quieres cancelar tu solicitud?
			</p>
			<Box
				display={'flex'}
				justifyContent={'center'}
				flexDirection={matches ? 'column-reverse' : 'row'}
			>
				<Box width={matches ? 'auto' : 250} margin={2}>
					<CustomButton
						label={'No'}
						onClick={() => setOpenModal(false)}
						variant="outlined"
					/>
				</Box>
				<Box width={matches ? 'auto' : 250} margin={2}>
					<CustomButton
						label={'Si'}
						onClick={() => setPage(1)}
						variant="solid"
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default ConfirmModal;
