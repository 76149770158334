import {
	Box,
	Grid,
	Typography,
	Divider,
} from '@mui/material';
import { useState } from 'react';
import mejoravitRepara from '../../../../assets/img/productsMIUC/Mejoravit_REPARA_2023.png';
import mejoravitRenueva from '../../../../assets/img/productsMIUC/Mejoravit_RENUEVA_2023.png';
import SelectCustom from '../../../../components/SelectCustom';
import { useStyles } from './Styles';
import { useController } from '../../hooks/useController';
import { RoundBtn } from '../RoundBtn/roundBtn';
import { useGeneralStyles } from '../../views/styles/general.styles';
import Header from '../Header';
import { HeaderList } from '../../../../components/HeaderList';
import CustomAlert from '../../../../components/CustomAlert';

export const InformativeContent = () => {
	const classes = useStyles();
	const generalStyle = useGeneralStyles();

	const [tipoVivienda, setTipoVivienda] = useState('');
	const [disableButton, setDisableButton] = useState(true);

	const { returnStep, addSelection, nextStep, saveData, getProgressStep } =
		useController();

    const next = () => {
      saveData({
        processInfo: {
          creditType: 'I',
          tipoVivienda
        },
        processData: {
          active: getProgressStep().active + 1,
        }
      });
      nextStep();
    };

	const { active, total } = getProgressStep();
	const newStep = {
		title: 'Mejora tu hogar',
		subtitle:
			'Con este crédito puedes mejorar o remodelar tu vivienda sin afectar su estructura.',
		active,
		total,
	};

	const handleChange = (event: any) => {
		setTipoVivienda(event.target.value);
		setDisableButton(false);
	};

	const selectOptions: {
		label: string;
		value: string;
	}[] = [
		{
			label: 'Propia',
			value: 'P',
		},
		{
			label: 'Del Cónyuge o Concubino',
			value: 'C',
		},
		{
			label: 'Familiar',
			value: 'F',
		},
	];

	return (
		<Box>
			<Header steps={newStep}></Header>
			<Box className={classes.content}>
				<Grid container className={classes.mainContainer}>
					<Grid item xs={12}>
						<Typography variant="subtitle1">
							Tu Subcuenta de Vivienda queda como garantía del
							crédito.
						</Typography>
						<Typography mt={2} variant="h6">
							Conoce tus opciones:
						</Typography>
					</Grid>

					<div className={classes.productsDisplay}>
						<div className={classes.productBox}>
							<Box maxWidth={'100%'}>
								<img
									alt="logoMejoravirRepara"
									src={mejoravitRepara}
									width={150}
								></img>
							</Box>
							<ul
								className={classes.CustomUl}
								style={{ textAlign: 'left' }}
							>
								<li>
									Puedes solicitar hasta el 90% de tu Saldo de
									Subcuenta de Vivienda.
								</li>
								<li>Plazo de 1 a 5 años.</li>
								<li>
									Monto mínimo de crédito <b>$9,901.59</b> y
									máximo
									<b> $39,606.36</b> dependiendo de tu ahorro.
								</li>
								<li>
									<b>Tasa de interés fija 10%.</b>
								</li>
								<li>
									<b>Sin comisiones.</b>
								</li>
							</ul>
						</div>
						<div className={classes.productBox}>
							<Box maxWidth={'100%'}>
								<img
									alt="logoMejoravirRenueva"
									src={mejoravitRenueva}
									width={150}
								></img>
							</Box>
							<ul className={classes.CustomUl}>
								<li>
									Puede solicitar el 90% de tu Saldo de
									Subcuenta de Vivienda.
								</li>
								<li>Plazo de 1 a 10 años.</li>
								<li>
									Monto mínimo de crédito <b>$39,606.36</b> y
									máximo
									<b> $156,445.12</b> dependiendo de tu ahorro.
								</li>
								<li>
									<b>Tasa de interés fija 11%.</b>
								</li>
								<li>
									<b>Sin comisiones.</b>
								</li>
							</ul>
						</div>
					</div>
					<Divider
						orientation="horizontal"
						className={classes.divider}
					/>
					<Grid
						container
						sx={{
							mt: { xs: 2, sm: 4 },
							textAlign: 'left',
						}}
						className={classes.centeredItems}
					>
						<Box
							sx={{
								width: {
									xs: '100%',
									sm: 500,
								},
							}}
						>
							<SelectCustom
								value={tipoVivienda}
								options={selectOptions}
								label={'La vivienda que deseas mejorar es:'}
								placeholder={'Selecciona'}
								onChange={handleChange}
							></SelectCustom>
						</Box>
					</Grid>
          <Box mt={2}>
            <CustomAlert 
              show
              severity='warning'
              message={'no'}
            >
              <p style={{ textAlign: 'start', margin: 0 }}>
                Las condiciones financieras mostradas a continuación
                no consideran la consulta a tu historial crediticio.
                Una vez que se lleve a cabo la inscripción de tu crédito,
                se realizará la consulta a Sociedades de Información
                Crediticia (Buró de Crédito) para determinar las condiciones
                finales del crédito a otorgar.
              </p>
            </CustomAlert>
          </Box>
          {/* <Box className={classes.textDisclaimer}>
              
            <p>
              Las condiciones financieras mostradas a continuación
              no consideran la consulta a tu historial crediticio.
              Una vez que se lleve a cabo la inscripción de tu crédito,
              se realizará la consulta a Sociedades de Información
              Crediticia (Buró de Crédito) para determinar las condiciones
              finales del crédito a otorgar.
            </p>
          </Box> */}
					<div className={classes.selectionList}>
						<HeaderList
							listSizeIcon={18}
							list={[
								<>
									En caso de que la vivienda que deseas
									mejorar sea propia, tienes la opción de
									utilizar hasta un 30% del monto de crédito
									autorizado para su regularización.
								</>,
							]}
						/>
					</div>
                    <Divider
						orientation="horizontal"
						className={classes.divider}
					/>
					<Grid
						container
						spacing={2}
						sx={{
							display: 'inline-flex',
							justifyContent: 'center',
							mt: 2,
							mb: 2,
						}}
					>
						<div className={classes.bottonsContainer}>
							<RoundBtn
								variant="outlined"
								onClick={() => returnStep()}
							>
								Regresar
							</RoundBtn>
							<RoundBtn
								variant="contained"
								onClick={() => next()}
								disabled={disableButton}
							>
								Continuar
							</RoundBtn>
						</div>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
