import { useState, useEffect } from 'react';
import { Radio, Link, Divider, useMediaQuery, Alert } from '@mui/material';
import { Error } from '@mui/icons-material';
import SelectCustom from '../../../components/SelectCustom';
import CustomButton from '../../../components/Button';
import CalenderPays from './CalenderPays';
import { apiSaldAndMovements } from '../../../api/modules/SaldAndMovements';
import {
	ICredito,
	formatMessage,
	MsgErrorMensual,
} from '../../../interfaces/SaldAndMovement';
import { encrypt } from '../../../utils/encrypt';
import { keyAPI } from '../../../api/modules/key';
import { useTheme } from '@mui/material/styles';
import { WarningAmberRounded, Close } from '@mui/icons-material';
interface Props {
	credito: ICredito | null;
	setPage: (arg: number) => void;
	setLoading: (arg: boolean) => void;
	periodo: any[];
	anios: any[];
	paperless: boolean;
}

const StateAccount = ({
	credito,
	setPage,
	periodo,
	anios,
	setLoading,
	paperless,
}: Props) => {
	const [anioLabelError, setAnioLabelError] = useState<string>('');
	const [mesLabelError, setMesLabelError] = useState<string>('');
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [modalCalenderPays, setModalCalenderPays] = useState<boolean>(false);
	const [option, setOption] = useState<{
		value: string;
		mes: string | number;
		anio: string | number;
	}>({
		value: '',
		mes: '',
		anio: '',
	});
	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		//Encontramos el objetito que tiene el mismo mes en periodo con el que se seleccionó
		const arrayPeriodo = periodo.find(
			(element) => element.value === option.mes
		);
		//Luego buscamos si ese objetito trae un código de error
		const resp = MsgErrorMensual.find(
			(msg) => msg.code === arrayPeriodo?.code
		);
		if (resp) {
			setMesLabelError(resp?.msg);
			setShowButton(false);
		} else {
			setMesLabelError('');
			setShowButton(true);
		}
	}, [option.mes, periodo]);

	useEffect(() => {
		// periodo.forEach((element) => {
		// 	const resp = MsgErrorMensual.find(
		// 		(msg) => msg.code === element.code
		// 	);
		// 	setMesLabelError(resp?.msg || '');
		// });
		anios.forEach((element) => {
			const resp = MsgErrorMensual.find(
				(msg) => msg.code === element.code
			);
			setAnioLabelError(resp?.msg || '');
		});
	}, [anios]);

	const codigoDemanda = !['00200', '00220', '00010', '00030'].includes(
		credito?.tipoCasoCredito?.wsTipoCaso || ''
	);

	const pdfHistorico = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const credit_encript = await encrypt(credito?.credito || '', key);
			const { result } = await apiSaldAndMovements.getPdfHistorico(
				credit_encript
			);
			let link = document.createElement('a');
			link.download = credito?.nss + '_movimientos_cuenta_historico.pdf';
			link.href = 'data:application/octet-stream;base64,' + result;
			link.click();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const pdfMensual = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const credit_encript = await encrypt(credito?.credito || '', key);
			const mes_encript = await encrypt(option.mes.toString() || '', key);
			const { result } = await apiSaldAndMovements.getPdfMensual(
				credit_encript,
				mes_encript
			);
			let link = document.createElement('a');
			link.download = credito?.nss + '_movimientos_cuenta_mensual.pdf';
			link.href = 'data:application/octet-stream;base64,' + result;
			link.click();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const pdfPagoServicio = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const credit_encript = await encrypt(credito?.credito || '', key);
			const anio_encript = await encrypt(
				option.anio.toString() || '',
				key
			);
			const { result } = await apiSaldAndMovements.getPdfPagoServ(
				credit_encript,
				anio_encript
			);
			let link = document.createElement('a');
			link.download =
				credito?.nss + '_movimientos_cuenta_pago_servicio.pdf';
			link.href = 'data:application/octet-stream;base64,' + result;
			link.click();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<CalenderPays
				openModal={modalCalenderPays}
				setOpenModal={setModalCalenderPays}
			/>
			{codigoDemanda ? (
				<p
					style={{
						margin: '20px 0px',
						fontSize: 15,
						fontWeight: 500,
					}}
				>
					Selecciona el estado de cuenta que deseas consultar
				</p>
			) : (
				<Alert
					icon={
						<WarningAmberRounded
							fontSize="inherit"
							style={{ color: '#E35700' }}
						/>
					}
					severity="warning"
					style={{
						marginTop: 30,
						marginBottom: 30,
						background: '#FAE295',
						color: '#E35700',
					}}
				>
					{credito?.msgs?.map(
						(msg: any) =>
							['01', '04', '07'].includes(msg.key) && (
								<p style={{ margin: 0 }}>
									{formatMessage(msg.value, msg.key, credito)}
								</p>
							)
					)}
				</Alert>
			)}

			<div
				style={{
					maxWidth: matches ? '100%' : 760,
					marginLeft: matches ? 0 : 65,
				}}
			>
				{codigoDemanda && (
					<>
						{periodo.length > 0 && (
							<>
								<div
									style={{
										display: 'flex',
										alignItems: 'flex-start',
										margin: '20px 0px',
									}}
								>
									<Radio
										checked={option.value === 'mensual'}
										onChange={(e) =>
											setOption({
												...option,
												value: e.target.value,
											})
										}
										value="mensual"
										name="radio-buttons"
										inputProps={{
											'aria-label': 'mensual',
										}}
										style={{
											margin: 0,
											padding: 0,
											marginTop: 4,
										}}
										size="small"
									/>

									<div
										style={{
											marginLeft: 10,
											color: '#29398F',
										}}
									>
										<p style={{ margin: 0, color: '#000' }}>
											Mensual
										</p>
										<span
											style={{
												display: 'flex',
												alignItems: 'baseline',
												fontSize: 13,
											}}
										>
											<Error
												style={{
													fontSize: 12,
													marginRight: 5,
												}}
											/>{' '}
											Movimientos de tu crédito mes con
											mes. Se actualiza el día 10 de cada
											mes, pero con corte al último día
											del mes anterior.
										</span>
									</div>
								</div>
								{mesLabelError && (
									<Alert icon={<Close />} severity="error">
										{mesLabelError}{' '}
									</Alert>
								)}
							</>
						)}

						{anios.length > 0 && (
							<>
								<div
									style={{
										display: 'flex',
										alignItems: 'flex-start',
										margin: '20px 0px',
									}}
								>
									{!anioLabelError && (
										<Radio
											checked={
												option.value === 'servicios'
											}
											onChange={(e) =>
												setOption({
													...option,
													value: e.target.value,
												})
											}
											value="servicios"
											name="radio-buttons"
											inputProps={{
												'aria-label': 'servicios',
											}}
											style={{
												margin: 0,
												padding: 0,
												marginTop: 4,
											}}
											size="small"
										/>
									)}

									<div
										style={{
											marginLeft: 10,
											color: '#29398F',
										}}
									>
										<p style={{ margin: 0, color: '#000' }}>
											Resumen de pago por servicios
										</p>
										<span
											style={{
												display: 'flex',
												alignItems: 'baseline',
												fontSize: 13,
											}}
										>
											<Error
												style={{
													fontSize: 12,
													marginRight: 5,
												}}
											/>
											Pago del predial y cuota de
											conservación.
											<Link
												href="#calendario"
												onClick={() =>
													setModalCalenderPays(true)
												}
												style={{ marginLeft: 2 }}
											>
												Calendario de pagos de servicios
											</Link>
										</span>
									</div>
								</div>
								{anioLabelError && (
									<Alert icon={<Close />} severity="error">
										{anioLabelError}
									</Alert>
								)}
							</>
						)}
						<div
							style={{
								display: 'flex',
								alignItems: 'flex-start',
								margin: '20px 0px',
							}}
						>
							<Radio
								checked={option.value === 'historico'}
								onChange={(e) => {
									setOption({
										...option,
										value: e.target.value,
									});
									if (e.target.value === 'historico') {
										setMesLabelError('');
									}
								}}
								value="historico"
								name="radio-buttons"
								inputProps={{ 'aria-label': 'historico' }}
								style={{ margin: 0, padding: 0, marginTop: 4 }}
								size="small"
							/>
							<div style={{ marginLeft: 10, color: '#29398F' }}>
								<p style={{ margin: 0, color: '#000' }}>
									Histórico
								</p>
								<span
									style={{
										display: 'flex',
										alignItems: 'baseline',
										fontSize: 13,
									}}
								>
									<Error
										style={{ fontSize: 12, marginRight: 5 }}
									/>{' '}
									Movimientos desde el inicio de tu crédito.
									El estado de cuenta se actualiza el día 10
									de cada mes.
								</span>
							</div>
						</div>

						<div style={{ minHeight: 20, overflow: 'hidden' }}>
							{option.value === 'mensual' && (
								<>
									<SelectCustom
										name="mes"
										label="Periodo:"
										value={option.mes}
										options={periodo}
										placeholder="Selecciona mes"
										onChange={(e) =>
											setOption({
												...option,
												mes: e.target.value,
											})
										}
									/>
									<div
										style={{
											display: 'flex',
											justifyContent: 'start',
											marginTop: 40,
											flexDirection: matches
												? 'column-reverse'
												: 'inherit',
										}}
									>
										{paperless && (
											<div
												style={{
													width: matches
														? 'auto'
														: 240,
													display: 'flex',
													margin: 10,
												}}
											>
												<CustomButton
													label={
														'Recibir de manera electrónica'
													}
													variant="outlined"
													onClick={() => setPage(1)}
												/>
											</div>
										)}
										<div
											style={{
												width: matches ? 'auto' : 240,
												display: 'flex',
												margin: 10,
											}}
										>
											{showButton ? (
												<CustomButton
													disabled={
														option.mes === ''
															? true
															: false
													}
													label={'Descargar'}
													variant="solid"
													onClick={() => pdfMensual()}
												/>
											) : (
												''
											)}
										</div>
									</div>
								</>
							)}
							{option.value === 'servicios' && (
								<>
									<SelectCustom
										name="anio"
										label="Periodo:"
										value={option.anio}
										options={anios}
										placeholder="Selecciona año"
										onChange={(e) =>
											setOption({
												...option,
												anio: e.target.value,
											})
										}
									/>
									<div
										style={{
											display: 'flex',
											justifyContent: 'start',
											marginTop: 40,
											flexDirection: matches
												? 'column-reverse'
												: 'inherit',
										}}
									>
										<div
											style={{
												width: matches ? 'auto' : 240,
												display: 'flex',
												margin: 10,
											}}
										>
											<CustomButton
												disabled={
													option.anio === ''
														? true
														: false
												}
												label={'Descargar'}
												variant="solid"
												onClick={() =>
													pdfPagoServicio()
												}
											/>
										</div>
									</div>
								</>
							)}
							{option.value === 'historico' && (
								<>
									<div
										style={{
											display: 'flex',
											justifyContent: 'start',
											flexDirection: matches
												? 'column-reverse'
												: 'inherit',
										}}
									>
										<div
											style={{
												width: matches ? 'auto' : 240,
												display: 'flex',
												margin: 10,
											}}
										>
											<CustomButton
												label={'Descargar'}
												variant="solid"
												onClick={() => pdfHistorico()}
											/>
										</div>
									</div>
								</>
							)}
						</div>
					</>
				)}
			</div>

			<Divider style={{ margin: '20px 0px' }} />
		</>
	);
};

export default StateAccount;
