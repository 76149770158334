import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	txtPrincipales: {
		// color: '#333333',
		color: '#283990',
		fontSize: '16px !important',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px !important',
		},
	},
	divPrincipal: {
		padding: '40px 80px',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '30px',
		},
	},
	divBtn: {
		display: 'flex',
		width: '30%',
		padding: '0px 15px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '10px 0px',
		},
	},
	divContainer: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse',
		},
	},
	txtBlue: {
		color: '#293990',
		fontWeight: 'bold',
		fontSize: '24px',
	},
	divContent: {
		display: 'flex',
		width: '100%',
		flexWrap: 'wrap',
		paddingBottom: '20px',
	},
	divServices: {
		width: '80%',
		textAlign: 'left',
		padding: '15px 0px',
		borderBottom: '1px #00000017 solid',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '5px 0px',
		},
	},
	divUbicacion: {
		width: '40%',
		textAlign: 'left',
		padding: '15px 0px',
		borderBottom: '1px #00000017 solid',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '5px 0px',
			borderBottom: '0px',
		},
	},
	divMaps: {
		width: '40%',
		textAlign: 'left',
		padding: '15px 0px',
		borderBottom: '1px #00000017 solid',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '5px 0px',
		},
	},
	divName: {
		width: '20%',
		textAlign: 'left',
		padding: '15px 0px',
		borderBottom: '1px #00000017 solid',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '5px 0px',
			borderBottom: '0px',
		},
	},
}));
