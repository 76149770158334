import { useState } from 'react';
import { ICredito } from '../../../interfaces/SaldAndMovement';
import { HeaderList } from '../../../components/HeaderList';
import InfoDoc from './InfoDoc';
import HowPaymentMade from './HowPaymentMade';
import FormData from './FormData';
import ThankServices from './ThankServices';
import PayOtherService from './PayOtherService';
import AttachDocument from './AttachDocument';
import AttachDocumentForm from './AttachDocumentForm';

interface Props {
	credito: ICredito;
}

const ClarificationPayments = ({ credito }: Props) => {
	const [page, setPage] = useState(6);
	return (
		<>
			<HeaderList title="Aclaración de pagos" />
			{page === 0 && <InfoDoc setPage={setPage} />}
			{page === 1 && <HowPaymentMade setPage={setPage} />}
			{page === 2 && <FormData setPage={setPage} />}
			{page === 3 && <ThankServices setPage={setPage} />}
			{page === 4 && <PayOtherService />}
			{page === 5 && <AttachDocument setPage={setPage} />}
			{page === 6 && <AttachDocumentForm setPage={setPage} />}
		</>
	);
};

export default ClarificationPayments;
