import { useState, useEffect, useCallback, Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import Location from './views/Location';
import ActualConditions from './views/ActualConditions';
import CompareConditions from './views/CompareConditions';
import CustomAlert from '../../../components/CustomAlert';
import ModalLoading from '../../../components/ModalLoading';
import { sharedResponsabilityAPI } from '../../../api/modules/sharedResponsability';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';

const Procedure = () => {
	const [searchParams] = useSearchParams();
	const credit = searchParams.get('credito');
	const [step, setStep] = useState(0);
	const [elegible, setElegible] = useState(true);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<any>(null);
	const [currentCredit, setCurrentCredit] = useState(null);
	const [creditData, setCreditData] = useState(null);
	const [creditOptions, setCreditOptions] = useState(null);
	const [selectedCredit, setSelectedCredit] = useState<any>(null);
	const [messageConvertion, setMessageConvertion] = useState('');

	const fetchEligibility = useCallback(async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const CREDIT_BASE64 = await encrypt(credit || '', key);

			const { result } = await sharedResponsabilityAPI.elegibility(
				CREDIT_BASE64
			);
			if (result?.status === 'Elegible') {
				setCreditData(result?.datosCredito);
				setCurrentCredit(result?.creditoSinDescuento);
				setCreditOptions(result?.opciones);
			} else {
				setElegible(false);
				if (result?.mensaje) {
					setMessageConvertion(result?.mensaje);
				} else if (result?.mensajes && result?.mensajes.length > 0) {
					setAlert(result?.mensajes);
				} else {
					setAlert([INTERNAL_MESSAGES.ERROR_MESSAGE]);
				}
			}
		} catch (error: any) {
			setAlert(Array.isArray(error.description)
				? error.description
				: typeof error.description === 'string'
					? [error.description]
					: [INTERNAL_MESSAGES.ERROR_MESSAGE]);
		} finally {
			setLoading(false);
		}
	}, [credit]);

	useEffect(() => {
		if (credit && credit !== '0') fetchEligibility();
	}, [fetchEligibility, credit]);

	// Se quita esta validación para que se puedan realizar pruebas en QA
	// if (isAfter(now, firstDay) && isBefore(now, finalDay))
	// 	return (
	// 		<>
	// 			{`Estamos realizando las conversiones a pesos de los créditos inscritos en el mes pasado y la evaluación de las condiciones
	// 				para los créditos que aún no aceptan este beneficio, si tú ya tienes un folio asignado, consulta el estatus en “Contáctanos > Sigue tu caso y adjunta documentos”.

	// 				Si aún no has ingresado tu solicitud de conversión a pesos, te invitamos a regresar el día 09, en esa fecha ya podrás consultar la información del programa.`}
	// 		</>
	// 	);

	if (messageConvertion.length > 0)
		return (
			<>
				<div
					style={{
						width: '100%',
						maxWidth: '800px',
						margin: '0 auto',
					}}
				>
					{messageConvertion}
				</div>
			</>
		);

	if (!elegible)
		return (
			<>
				{alert?.map((msg: string) => (
					<Fragment key={msg}>
						<CustomAlert
							show={true}
							severity={'error'}
							message={msg}
							onClose={(): any => setAlert(null)}
						/>
					</Fragment>
				))}
			</>
		);

	return (
		<>
			<ModalLoading loading={loading} />
			{alert?.map((msg: string) => (
				<Fragment key={msg}>
					<CustomAlert
						show={true}
						severity={'error'}
						message={msg}
						onClose={(): any => setAlert(null)}
					/>
				</Fragment>
			))}
			{step === 0 && (
				<Location
					step={step}
					setStep={setStep}
					creditData={creditData}
					selectedCredit={selectedCredit}
					setSelectedCredit={setSelectedCredit}
				/>
			)}
			{step === 1 && (
				<ActualConditions
					step={step}
					setStep={setStep}
					creditData={creditData}
				/>
			)}
			{step === 2 && (
				<CompareConditions
					step={step}
					setStep={setStep}
					currentCredit={currentCredit}
					creditOptions={creditOptions}
					selectedCredit={selectedCredit}
					setSelectedCredit={setSelectedCredit}
				/>
			)}
		</>
	);
};

export default Procedure;
