import { AddBoxOutlined } from '@mui/icons-material';

const NotaryDetailColumnFormat = (value: any, index: number, array: any) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			Detalles de notario <AddBoxOutlined />
		</div>
	);
};

export default NotaryDetailColumnFormat;
