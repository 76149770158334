import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
        display: 'block',
		padding: '20px 40px',

		'& > h3': {
			textAlign: 'center',
            margin: '20px 0px',
		},

        [theme.breakpoints.down('md')]: {
			padding: '20px',
		},
	},

	containerMessage: {
		display: 'flex',
		margin: '10px 0px',
	},

	containerButton: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '10px',
        margin: '20px 0px',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},

	buttonComponent: {
		width: 250,

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
}));
