import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Dispatch, useState } from 'react';
import { useStyles } from './styles';

const radioOptions = {
	Autorizo: {
		label: 'Autorizo al instituto del Fondo Nacional de la Vivienda para los Trabajadores [Infonavit] a consultar mi historial crediticio, ante las sociedades de Información Crediticia [Buró de crédito]. Declaro que conozco la naturaleza, alcance y uso que el Infonavit hará con mi información.',
		value: true,
	},
	NoAutorizo: {
		label: 'En otro momento',
		value: false,
	},
};

interface IAuthorization {
	authorization: boolean;
	setAuthorization: Dispatch<boolean>;
	onlyAuthorization?: boolean;
}

export const Authorization = ({
	authorization,
	setAuthorization,
	onlyAuthorization = false,
}: IAuthorization) => {
	const onChange = ({ target }: any) => {
		const value = target.value === 'true' ? true : false;
		setAuthorization(value);
	};

	const classes = useStyles();

	return (
		<>
			<RadioGroup
				defaultValue={radioOptions.Autorizo.value}
				onChange={onChange}
				className={classes.radioContainer}
			>
				<FormControlLabel
					className={classes.radioOption}
					value={radioOptions.Autorizo.value}
					control={<Radio size="small" />}
					label={radioOptions.Autorizo.label}
					labelPlacement="end"
				/>
				{!onlyAuthorization && (
					<FormControlLabel
						value={radioOptions.NoAutorizo.value}
						control={<Radio size="small" />}
						label={radioOptions.NoAutorizo.label}
						labelPlacement="end"
					/>
				)}
			</RadioGroup>
		</>
	);
};
