import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { COLORS } from '../../utils';
import { Elemento } from '../../../../../../interfaces/tallerSaberMas';

export const useStyles = makeStyles<Theme, Elemento>((theme: Theme) => ({
	cardVideo: {
		width: '100%',
		maxWidth: 350,
		height: 150,
		padding: 20,
		borderRadius: 10,
		boxShadow: 'rgba(0, 0, 0, 0.3) 0px 4px 10px',
		borderWidth: 2,
		borderStyle: 'solid',
		transition: 'all 0.3s',
		margin: '6px',
		background: ({ estatus }) =>
			estatus === 1 ? '#fffafc' : 'transparent',
		borderColor: ({ estatus }) =>
			estatus === 1 ? COLORS.PINK : 'transparent',
		'&:hover': {
			borderColor: COLORS.PINK,
		},

		[theme.breakpoints.down('md')]: {
			margin: '6px 0px 6px 0px',
			borderColor: ({ estatus }) =>
				estatus > 0 ? COLORS.PINK : '#e4e4e4 !important',
			boxShadow: 'none',
		},
	},
	cardVideoActive: {
		width: '100%',
		maxWidth: 350,
		height: 150,
		padding: 20,
		borderRadius: 10,
		boxShadow: 'rgba(0, 0, 0, 0.3) 0px 4px 10px',
		borderWidth: 2,
		borderStyle: 'solid',
		transition: 'all 0.3s',
		margin: '6px',
		background: '#fffafc',
		borderColor: COLORS.PINK,

		[theme.breakpoints.down('md')]: {
			margin: '6px 0px 6px 0px',
			boxShadow: 'none',
		},
	},
	containerHeaderCard: {
		display: 'flex',
		width: '100%',
		height: '50%',
	},
	containerHeaderCardIcon: {
		width: '25%',
		height: '100%',
	},
	containerHeaderCardInfo: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	},
	containerHeaderCardLabelTitle: {
		color: COLORS.PINK,
		fontSize: 14,
		fontWeight: '500',
		overflow: 'hidden',
		display: '-webkit-box',
		'-webkit-box-orient': 'vertical',
		'-webkit-line-clamp': 1,
	},
	containerHeaderCardLabelSubTitle: {
		fontSize: 14,
		overflow: 'hidden',
		display: '-webkit-box',
		'-webkit-box-orient': 'vertical',
		'-webkit-line-clamp': 2,
	},
	containerHeaderCardButtons: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',

		[theme.breakpoints.down('md')]: {
			marginTop: '20px',
		},
	},
	containerPlay: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
}));
