import { Paper, Box, Divider, Link } from '@mui/material';

import Header from '../../../components/Header';
import ProductsMiuc from '../../../components/ProductsMiuc';
import { RoundBtn } from '../../../components/RoundBtn/roundBtn';
import ModalLoading from '../../../../../components/ModalLoading';

import { useStyles } from '../styles/styles';
import { useGeneralStyles } from '../../styles/general.styles';
import { useMejoraSiTable } from '../hooks/useMejoraSiTable';
import { rowsTitleMejoraDirecta } from '../../../utils/selectionResume.utils';
import iconoPDF from '../../../../../assets/img/icono-descarga.png';
import theme from '../../../../../config/theme';
import TooltipCustom from '../../../components/TooltipCustom';
import ErrorIcon from '@mui/icons-material/Error';
interface IProps {
	setProduct: (pruduct: any) => void;
}

const Products = () => {
	const styles = useStyles();
	const generalStyle = useGeneralStyles();
	const {
		loading,
		next,
		returnStep,
		setSelectProduct,
		selectProduct,
		products,
		newStep,
		handleGetAmortizationTable,
		disableButton,
    handleDownloadConstancia,
    tooltipConstancia,
	} = useMejoraSiTable();

  
	return (
		<Paper>
			<Header steps={newStep}></Header>
			<Box className={styles.container}>
        <Box sx={{ textAlign: 'center' }}>
          <h1 style={{ color: theme.palette.info.main }}>Importante</h1>
          <p>
            Elige la opción que más te convenga y <b>obtén tu Constancia de elegibilidad</b>,
            que forma parte del expediente que deberás integrar para solicitar tu crédito
          </p>
        </Box>
        <Divider />
				<ProductsMiuc
					keys={rowsTitleMejoraDirecta}
					products={products}
					keyHeader={'producto'}
					selectProduct={selectProduct}
					setSelectProduct={setSelectProduct}
					textFooter="Considera que en cualquier momento de tu crédito podrás adelantar pagos o realizar la liquidación de tu crédito sin tener ninguna penalización."
				/>
			</Box>
			<Divider />
      <Box className={styles.linkContainer}>
        <Link 
          underline='hover' 
          className={styles.donwloadLink}
          onClick={ () => handleDownloadConstancia()}
        >
          Constancia de elegibilidad
          <img src={iconoPDF} alt="iconoPDF" />
        </Link>
        <TooltipCustom title={tooltipConstancia} placement='right' >
          <ErrorIcon className={styles.icon} />
        </TooltipCustom>
      </Box>
			<div className={generalStyle.bottonsContainer}>
				<RoundBtn variant="outlined" onClick={() => returnStep()}>
					Regresar
				</RoundBtn>
				<RoundBtn disabled={disableButton} variant="contained" onClick={() => next()}>
					Continuar
				</RoundBtn>
			</div>
			<ModalLoading loading={loading} />
		</Paper>
	);
};

export default Products;
