// RESOURCES
import React from 'react';
import { Link } from '@mui/material';
// COMPONENTS
import CustomButton from '../../../components/Button';
// ASSETS
import checkBlue from '../../../assets/img/mini-check-azul.png';
import { useStyles } from './styles';
import { IAlert } from '../../../interfaces/alert';
import CustomAlert from '../../../components/CustomAlert';

interface Props {
	setPage: (data: number) => void;
	buttonInfo: boolean;
	onCloseError: () => void;
	hasError: IAlert;
}

const InfoPayments = ({ setPage, buttonInfo, onCloseError, hasError }: Props) => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.titleNoticeContainer}>
				<div>
					<h2 className={classes.titleNotice}>
						Devolución de pagos en exceso{' '}
					</h2>
				</div>

				<div className={classes.subTitleNotice}></div>
			</div>
			<div className={classes.container}>
				<div className={classes.containerMin}>
					<div className={classes.checkHeader}>
						<img
							src={checkBlue}
							className={classes.checkHeaderImg}
							alt="check-blue"
						/>
						<div className={classes.checkHeaderText}>
							Consulta en este servicio los pagos en exceso que puedes
							solicitar en devolución por tu crédito liquidado.
						</div>
					</div>
					<div className={classes.checkHeader}>
						<img
							src={checkBlue}
							className={classes.checkHeaderImg}
							alt="check-blue"
						/>
						<p className={classes.checkHeaderText}>
							Si tienes una relación laboral, asegúrate de obtener y
							entregar a tu patrón el Aviso de suspensión de descuentos. En
							caso de que tu patrón te haya hecho descuentos que no enteró
							al Infonavit debe devolvértelos.
						</p>
					</div>
					<div className={classes.checkHeader}>
						<img
							src={checkBlue}
							className={classes.checkHeaderImg}
							alt="check-blue"
						/>
						<p className={classes.checkHeaderText}>
							Posteriormente haz el trámite de cancelación de hipoteca.
							Entra{' '}
							<Link
								href="/mi-credito/carta-cancelacion-hipoteca"
								target="_blank"
								rel="noreferrer"
							>
								aquí
							</Link>{' '}
							para más información.
						</p>
					</div>
					<div className={classes.checkHeader}>
						<img
							src={checkBlue}
							className={classes.checkHeaderImg}
							alt="check-blue"
						/>
						<p className={classes.checkHeaderText}>
							Si necesitas aclarar el monto de tu devolución, llama al
							Infonatel al 5591715050 en la Ciudad de México, o al 800 008
							3900 sin costo desde cualquier parte del país.
						</p>
					</div>
					<CustomAlert
						show={hasError.show}
						severity={hasError.severity}
						message={hasError.message}
						onClose={onCloseError}
					/>
					<div>
						<CustomButton
							onClick={() => {
								setPage(1);
							}}
							variant="solid"
							styles={{ marginTop: 70, width: 200, margin: '0 auto' }}
							disabled={buttonInfo}
							label="Continuar"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default InfoPayments;
