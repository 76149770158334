import { Paper, useMediaQuery } from '@mui/material';
import { Base } from './Styles';
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import { formatDate } from '../../utils/dates';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SumarNssModal } from './components/SumarNssModal';
import { useState } from 'react';

interface Props {
	setEtapa: React.Dispatch<React.SetStateAction<number>>;
	associated: any;
	setAssociated: any;
	isNew: Boolean;
}

const FinishNss = ({ setEtapa, associated, setAssociated, isNew }: Props) => {
	const matches = useMediaQuery('(min-width:900px)');
	const classes = Base();

  const { dataProcessController } = useSelector((state: RootState) => state.miuc);
	const [ openModal, setOpenModal ] = useState(false);
	const navigate = useNavigate();

	const handleFinish = () => {

		setOpenModal(false);

		if( dataProcessController.processInfo?.sumaCreditos ){
			navigate(-1);
		}

		setEtapa(3);
		setAssociated({
			nssSecundario: '',
			nssError: '',
			parentesco: '',
			tipoRelacion: '',
			genero: '',
			tipoAsociacion: '',
		});
	};

	return (
		<Paper className={classes.papper}>
			<>
				<div style={{ textAlign: matches ? 'center' : 'left' }}>
				<h2 style={{ color: '#293990', textAlign: 'center' }}>
						Se ha enviado un correo electrónico a tu asociado para confirmar que quiere unir su crédito
					</h2>
					<p style={{ textAlign: 'center' }}>
						Gracias por utilizar los servicios digitales del
						Infonavit
					</p>

					<div
						style={{
							paddingLeft: matches ? 60 : 0,
							paddingRight: matches ? 60 : 0,
						}}
					>
						<div className={classes.visorItem}>
							<label
								style={{
									display: matches ? 'inline' : 'block',
								}}
							>
								Número de Seguridad Social
							</label>
							{associated?.nssSecundario}
						</div>
						<div className={classes.visorItem}>
							<label
								style={{
									display: matches ? 'inline' : 'block',
								}}
							>
								Nombre completo
							</label>
							{associated?.nombre}
						</div>
						<div className={classes.visorItem}>
							<label
								style={{
									display: matches ? 'inline' : 'block',
								}}
							>
								CURP
							</label>
							{associated?.curp}
						</div>
					</div>
				</div>

				<div
					style={{
						maxWidth: 450,
						display: 'flex',
						margin: 'auto',
						marginTop: 30,
					}}
				>
					<CustomButton
						variant="solid"
						label={'Finalizar'}
						onClick={ () => setOpenModal(true)}
						data-testid="buttonFinalizar"
					/>
				</div>
			</>
			<SumarNssModal open={openModal} setOpen={setOpenModal} onFinish={handleFinish}/>
		</Paper>
	);
};

export default FinishNss;
