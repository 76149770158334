/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-self-compare */
import { Box, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import RowComponent from '../../components/Rows';
import { IItemTable, ITableRow } from '../../interfaces/tableRow';
import { useStyles } from './style';
import CustomButton from '../../components/Button';
import ModalConfirmation from '../../components/ModalConfirmation';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ICartaRestructuracion } from '../../interfaces/cartaConvenioRestructuracion';
import { getFormatDate2 } from '../../utils/dates';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { restructuringRequestAPI } from '../../api/modules/RestructuringRequest';
import { currencyFormat } from '../../utils/expressions';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CheckParagraph from './checkParagraph';
import CustomAlert from '../../components/CustomAlert';
import { IAlert } from '../../interfaces/alert';
import ViewArchive from './ViewArchive';
import iconDownload from '../../assets/img/icono-descarga.png';
//import { getFormatDateShort } from '../../utils/dates';
import ModalLoading from '../../components/ModalLoading';

interface btnOption {
	option: string;
	showModal: boolean;
	msg: string;
}

interface Props {
	convenios: any;
	reestructuras: any;
	typeSelected: any;
	onNextPage: (data?: boolean) => void;
	onPrevPage: () => void;
	onChangePage: (data: any) => void;
	CreateCaseRequest: (data: any, type: string, beneficio: string) => void;
	TypesRejection: (beneficio: string) => void;
	factorPagos: any;
	alertGral: any;
}

const Summary = ({ convenios, reestructuras, typeSelected, onChangePage, CreateCaseRequest, TypesRejection, factorPagos, alertGral }: Props) => {
	const setParagraphs = useState<string[]>()[1];
	const setConvenio = useState('')[1];
	const [pdf, setPdf] = useState('' as any);
	const [openModal, setOpenModal] = useState(false);
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [DataOfTable, setDataOfTable] = useState({
		data: [
			{
				header: 'Nombre de la persona acreditada',
				value: user?.given_name,
				keyTitle: 'NombreAcreditado_Title',
				keyValue: 'NombreAcreditadoValue',
			},
			{
				header: 'NSS',
				value: user?.nss,
				keyTitle: 'NSS_Title',
				keyValue: 'NSS_Value',
			},
			{
				header: 'Número de crédito',
				value: credit.toString(),
				keyTitle: 'Num_Credito_Title',
				keyValue: 'Num_Credito_Value',
			},

			{
				header: 'Ubicación del inmueble',
				value: '',
				keyTitle: 'Ubicacion_Title',
				keyValue: 'Ubicacion_Value',
			},
		],
	} as ITableRow);
	const [FechaEmision] = useState(getFormatDate2(new Date()));
	const [btnOptions, setBtnOptions] = useState({
		option: '',
		showModal: false,
		msg: '',
	} as btnOption);
	const [dataCartaConvenio, setDataCartaConvenio] = useState({
		FechaAplicacion: '',
		NombreAcreditado: '',
		Nss: '',
		NumCredito: '',
		UbicacionInmueble: '',
		Convenio: '',
		MontoConRelacionActual: 0,
		MontoSinRelacionActual: 0,
		MontoConRelacionDespues: 0,
		MontoSinRelacionDespues: 0,
	} as ICartaRestructuracion);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'warning',
	});
	const [loading, setLoading] = useState(false);
	const [pdf64, setPdf64] = useState('');
	const [tipoOperacion, setTipoOperacion] = useState('');
	const [beneficio, setBeneficio] = useState('');

	const onConfirm_SI = () => {
		setBtnOptions({
			option: '',
			showModal: false,
			msg: '',
		});
		if (pdf) {
			CreateCaseRequest(pdf64, tipoOperacion, beneficio)
		} else {
			getPdfBnc(false)
		}
	};

	const onConfirm_NO = () => {
		setBtnOptions({
			option: '',
			showModal: false,
			msg: '',
		});
	};

	const onCancel_SI = () => {
		setBtnOptions({
			option: '',
			showModal: false,
			msg: '',
		});
		TypesRejection(beneficio);
		onChangePage(5);
	};

	const onCancel_NO = () => {
		setBtnOptions({
			option: '',
			showModal: false,
			msg: '',
		});
	};

	const SaveSessionStorage = () => {
		let _CartaConvenio: string = '';

		if (typeSelected === 'STM') {
			_CartaConvenio = 'Solución a Tu Medida';
		} else if (typeSelected === 'DCP') {
			_CartaConvenio = 'Dictamen de Capacidad de Pago Privado';
		} else if (typeSelected === 'BCP') {
			_CartaConvenio = 'Borrón y Cuenta Nueva por Convenio Privado';
		} else if (typeSelected === 'BCN') {
			_CartaConvenio = 'Borrón y Cuenta Nueva';
		} else if (typeSelected === 'NTP') {
			_CartaConvenio = 'Nivela Tu Pago (NTP)';
		}

		setConvenio(_CartaConvenio);

		let _MontoConRelacionDespues: number = 0;
		let _MontoSinRelacionDespues: number = 0;

		let filtrado: any[] = [];
		filtrado = convenios.filter((e: any) => e.producto === typeSelected) || [];
		if (reestructuras.moneda === '010') {
			setBeneficio(typeSelected === 'NTP' ? factorPagos?.beneficioPesos : filtrado[0].benefPes);
		} else if (reestructuras.moneda === '020') {
			setBeneficio(typeSelected === 'NTP' ? factorPagos?.beneficioVsm : filtrado[0].benef);
		} else {
			setBeneficio('');
		}

		if (typeSelected === 'NTP') {
			_MontoSinRelacionDespues = factorPagos?.factorReaPesos
			_MontoConRelacionDespues = factorPagos?.factorRoaPesos
		} else {
			if (typeSelected !== 'DCP') {
				_MontoSinRelacionDespues = filtrado[0].factorReaPesos;
				_MontoConRelacionDespues = filtrado[0].factorRoaPesos;
			} else {
				_MontoSinRelacionDespues = factorPagos?.creditoPesos
				_MontoConRelacionDespues = factorPagos?.creditoPesos
			}
		}

		setDataCartaConvenio({
			FechaAplicacion: FechaEmision,
			NombreAcreditado: reestructuras.nombre || '',
			Nss: user?.nss || '',
			NumCredito: credit.toString() || '',
			UbicacionInmueble: reestructuras?.direccion || '',
			Convenio: _CartaConvenio || '',
			MontoConRelacionActual: typeSelected === 'BCP' ? _MontoConRelacionDespues : reestructuras?.ctFacRoaPes,
			MontoSinRelacionActual: typeSelected === 'BCP' ? _MontoSinRelacionDespues : reestructuras?.ctFacReaPes,
			MontoConRelacionDespues: _MontoConRelacionDespues,
			MontoSinRelacionDespues: _MontoSinRelacionDespues,
		});
		formatText();
		sessionStorage.setItem('dataCartaConvenio', JSON.stringify(dataCartaConvenio));
	};

	const formatText = () => {
		let _paragraphs: string[] = [];
		_paragraphs.push(
			'Al momento de la aceptación de la presente Carta, la persona acreditada manifiesta su consentimiento expresamente sobre su contenido, así como de las Condiciones Financieras del convenio Titulado “{dataCartaConvenio.Convenio}”, que se ponen a la vista en este mismo acto, ya sea que su consentimiento lo manifieste, mediante: (i) “ Mi Cuenta Infonavit ”, (ii) firma autógrafa”, (iii) o en su caso cualquier otro medio electrónico, óptico o por cualquier otra tecnología o por signos inequívocos, conforme a las disposiciones legales actuales en materia de uso de medios electrónicos que el Infonavit ponga a su alcance.'
		);
		setParagraphs(_paragraphs);
	};

	useEffect(() => {
		let _dataTable: ITableRow;
		_dataTable = {
			data: [
				{
					header: 'Nombre de la persona acreditada',
					value: reestructuras.nombre || '',
					keyTitle: 'NombreAcreditado_Title',
					keyValue: 'NombreAcreditadoValue',
				},
				{
					header: 'NSS',
					value: user?.nss || '',
					keyTitle: 'NSS_Title',
					keyValue: 'NSS_Value',
				},
				{
					header: 'Número de crédito',
					value: credit || '',
					keyTitle: 'Num_Credito_Title',
					keyValue: 'Num_Credito_Value',
				},

				{
					header: 'Ubicación del inmueble',
					value: reestructuras.direccion || '',
					keyTitle: 'Ubicacion_Title',
					keyValue: 'Ubicacion_Value',
				},
			],
		};
		setDataOfTable(_dataTable);
		SaveSessionStorage();
	}, [reestructuras]);

	const getPdfBnc = async (visiblePdf: boolean) => {
		setAlert({
			show: false,
			message: '',
			severity: 'warning',
		});
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			let paramsData = {};
			if (typeSelected === 'NTP') {
				paramsData = {
					producto: typeSelected,
					credito: credit,
					factorRoaVsm: factorPagos.factorRoaVsm,
					factorRoaPesos: factorPagos.factorRoaPesos,
					factorReaVsm: factorPagos.factorReaVsm,
					factorReaPesos: factorPagos.factorReaPesos,
					estatusContable: factorPagos.estatusContable,
					relacionLaboral: factorPagos.relacionLaboral,
					accesoriosPesos: factorPagos.accesoriosPesos,
					accesoriosVsm: factorPagos.accesoriosVsm,
					beneficioPesos: factorPagos.beneficioPesos,
					beneficioVsm: factorPagos.beneficioVsm,
					jurisdiccion: factorPagos.jurisdiccion,
				};
			} else {
				paramsData = {
					producto: typeSelected,
					credito: credit,
					factorPago: typeSelected === 'DCP' ? factorPagos.factorPago : '',
					factorPagoDiario: typeSelected === 'DCP' ? factorPagos.factorPagoDiario : '',
					factorPagoMensual: typeSelected === 'DCP' ? factorPagos.factorPagoMensual : '',
				};
			}
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			restructuringRequestAPI
				.GenerarConvenioBcn(dataSendEncrypt)
				.then((res: any) => {
					if (res.code === '0002') {
						setAlert({
							show: true,
							message: res.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
						setPdf('');
						setPdf64('')
					} else {
						setPdf64(res.data.documento)
						setTipoOperacion(res.data.tipoOperacion)
						const byteArray = window.atob(res.data.documento).split('').map(char => char.charCodeAt(0));
						const blob = new Blob([new Uint8Array(byteArray)], { type: "application/pdf" });
						const file = new File([blob], "Convenio.pdf", { type: "application/pdf" });
						setPdf(file);
						if (visiblePdf) {
							setOpenModal(true);
						} else {
							CreateCaseRequest(res.data.documento, res.data.tipoOperacion, beneficio)
						}
					}
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
					setPdf('');
					setPdf64('')
				})
				.finally(() => {
					setLoading(false);
				});
		} catch {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
			setPdf('');
			setPdf64('')
		}
	};

	useEffect(() => {
		if (alertGral.show) {
			setAlert({
				show: alertGral.show,
				message: alertGral.message,
				severity: alertGral.severity,
			});
		}
	}, [alertGral])

	const style = useStyles();
	return (
		<>
			<ModalLoading loading={loading} />
			<p className={style.textAlign_Center_spaciado}>
				<span className={style.textAlign_Bold_Title}>
					Carta modificatoria a las condiciones de pago
				</span>
				<br />
				<br />
				Del contrato de apertura de crédito con garantía hipotecaria en términos del convenio <br />denominado{' '}
				<span className={style.textAlign_Bold_Blue}>
					{dataCartaConvenio.Convenio}
				</span>
			</p>

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
					marginBottom: '40px',
				}}
			>
				<p className={style.textAlign_Bold}>Fecha de emisión</p>
				<span>{FechaEmision}</span>
			</Box>
			<Box className={style.Box_Agreement_Table}>
				{DataOfTable.data.map((item: IItemTable, index: any) => (
					<RowComponent
						items={[
							{
								value: item.header,
								bold: true,
								key: item.keyTitle,
							},
							{
								value: item.value,
								bold: false,
								alignItems: 'right',
								key: item.keyValue,
							},
						]}
						links={[]}
						key={'row' + index}
					/>
				))}
			</Box>
			<Box
				className={style.Box_Agreement_Table}
				sx={{ marginTop: '35px' }}
			>
				<p className={style.Cartas_Parrafo}>
					La persona acreditada conoce, entiende y otorga su
					consentimiento para que sea aplicada la modificación
					temporal de las condiciones financieras que el Infonavit
					le propone mediante la presente Carta Modificatoria a
					las Condiciones de Pago del Contrato de Apertura de
					Crédito con Garantía Hipotecaria (en adelante Carta), en
					términos del convenio denominado{' '}
					<span className={style.Cartas_Title_Bold_Fecha}>
						“{dataCartaConvenio.Convenio}”
					</span>{' '}
					(en adelante convenio) que se le ofrece, las
					Disposiciones Generales Jurídicas (en adelante
					Disposiciones Generales), las Condiciones Financieras,
					aplicable a este producto:
				</p>
			</Box>

			<Box className={style.Box_Agreement_Table}>
				<p className={style.textAlign_Bold_Blue}>
					Condiciones de Pago del convenio respecto a la Modificación
					del Crédito:
				</p>
			</Box>
			<Box
				className={style.Box_Agreement_Table}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}
				>
					{' '}
					<CheckCircleTwoToneIcon
						color={'info'}
						style={{
							marginRight: '5px',
							height: '18px',
							marginBottom: '0px',
						}}
					/>{' '}
					<p className={style.textoAjustesFinancieros}>
						Ajustes financieros:
					</p>{' '}
				</Box>
				<Box
					className={style.Box_Agreement_Table}
					sx={{ paddingLeft: '35px' }}
				>
					<p className={style.Cartas_Parrafo_Left}>
						- Monto de la mensualidad actual (en pesos) con relación
						laboral:{' '}
						${currencyFormat(dataCartaConvenio.MontoConRelacionActual)}
						<br />- Monto de la mensualidad actual (en pesos) sin
						relación laboral:{' '}
						${currencyFormat(dataCartaConvenio.MontoSinRelacionActual)}
						<br />- Monto del pago mensual después de la
						formalización del convenio (en pesos) con relación
						laboral:{' '}
						${currencyFormat(dataCartaConvenio.MontoConRelacionDespues)}
						<br />- Monto del pago mensual después de la
						formalización del convenio (en pesos) sin relación
						laboral:{' '}
						${currencyFormat(dataCartaConvenio.MontoSinRelacionDespues)}
					</p>
				</Box>
			</Box>
			<Box className={style.Box_Agreement_Table}>
				<Divider />
			</Box>
			<Box
				className={style.Box_Agreement_Table}
				sx={{ marginBottom: '15px', marginTop: '15px' }}
			>
				<p className={style.textAlign_Bold_Blue}>
					Disposiciones Generales
				</p>
			</Box>
			<CheckParagraph>
				<p className={style.Cartas_Parrafo}>
					Al momento de la aceptación de la presente Carta, la persona
					acreditada manifiesta su consentimiento expresamente
					sobre su contenido así como de las Condiciones
					Financieras del convenio Titulado{' '}
					<span className={style.Cartas_Title_Bold_Fecha}>
						“{dataCartaConvenio.Convenio}”
					</span>{' '}
					que se ponen a la vista en este mismo acto, ya sea que
					su consentimiento lo manifieste, mediante: (i) “Mi
					Cuenta Infonavit” , (ii) firma autógrafa”, (iii) o en su
					caso cualquier otro medio electrónico, óptico o por
					cualquier otra tecnología o por signos inequívocos,
					conforme a las disposiciones legales actuales en materia
					de uso de medios electrónicos que el Infonavit ponga a
					su alcance.
				</p>
			</CheckParagraph>
			<CheckParagraph>
				<p className={style.Cartas_Parrafo}>
					La persona acreditada acepta que la hipoteca subsiste en los
					términos del artículo 2911 del Código Civil Federal y sus
					correlativos en los estados. Dicha hipoteca consta en el
					Contrato de otorgamiento de Crédito con Garantía Hipotecaria
					que en su momento la persona acreditada celebró con el Infonavit,
					así como la presente Carta y convenio.
				</p>
			</CheckParagraph>
			<CheckParagraph>
				<p className={style.Cartas_Parrafo}>
					La persona acreditada acepta que esta reestructura tendrá una
					vigencia de {typeSelected === 'BCP' ? '6 meses' : '12 meses'} contando a partir de la fecha de
					formalización y cuando efectúe sus pagos completos y
					puntuales durante el tiempo convenido.
					Asimismo, manifiesta que está de acuerdo con la obligación
					de pagar al Infonavit una cuota fija mensual según los
					factores señalados en el citado documento, por lo que en
					este mismo acto renuncia a cualquier derecho mediante el
					cual pueda controvertir los factores que se han hecho de su
					conocimiento y que constan en el convenio.
				</p>
			</CheckParagraph>
			<CheckParagraph>
				<p className={style.Cartas_Parrafo}>
					Manifiesta y está de acuerdo en que el Infonavit le informó a
					detalle y resolvió sus dudas sobre el contenido de la presente
					Carta, las Condiciones Financieras del convenio titulado{' '}
					<span className={style.Cartas_Title_Bold_Fecha}>
						“{dataCartaConvenio.Convenio}”
					</span>
					, por lo que conoce, entiende y acepta las mismas, y se
					obliga a efectuar el pago del saldo insoluto del crédito,
					mismo que fue calculado en las condiciones actuales a la
					aceptación. Asimismo, la persona acreditada reconoce que
					las modificaciones en las condiciones inherentes al
					trabajador no lo eximen de su obligación de pago. Habiendo
					entendido y aceptado las modificaciones aquí contenidas,
					la persona acreditada está de acuerdo en que no podrá
					solicitar el reverso de estas condiciones originalmente
					pactadas. Se tendrá por cancelada la reestructura y en
					consecuencia serán reestablecidas las condiciones
					crediticias y financieras existentes antes de la
					reestructura en caso de incumplimiento de pago completo
					y en tiempo de cada mensualidad de reestructura. Asimismo,
					se dará por cancelada esta reestructura al término del
					plazo del presente convenio según exista relación laboral
					o no, conservando los beneficios aplicados durante el plazo
					que duró la reestructura, siempre y cuando la persona
					acreditada cumpla con los pagos en tiempo y forma
					establecidos en el Convenio.
				</p>
			</CheckParagraph>
			<CheckParagraph>
				<p className={style.Cartas_Parrafo}>
					Salvo las modificaciones que resulten conforme a lo pactado
					en la presente carta, la persona acreditada reconoce que
					subsisten las cláusulas del contrato de apertura de crédito
					con garantía hipotecaria y con todo su valor y fuerza
					legales, por lo que la persona acreditada y el Infonavit
					manifiestan expresamente que esta carta no constituye ni
					implica novación alguna, pues no ha sido su intención crear
					una nueva obligación.
				</p>
			</CheckParagraph>
			<CheckParagraph>
				<p className={style.Cartas_Parrafo}>
					Que para todo lo relacionado con el cumplimiento y la
					interpretación de los actos jurídicos que se deriven del
					Contrato De Apertura De Crédito Con Garantía Hipotecaria
					y, de la presente Carta, de las Condiciones Financieras
					del convenio titulado{' '}
					<span className={style.Cartas_Title_Bold_Fecha}>
						“{dataCartaConvenio.Convenio}”
					</span>
					, las partes expresamente convienen en someterse a la
					jurisdicción de los tribunales competentes en la Ciudad
					de México o a la de los tribunales de la parte actora,
					por lo que las partes renuncian a la jurisdicción de
					cualquier otro tribunal que, por razón de su domicilio
					presente o futuro del lugar de la celebración de este
					instrumento o de su nacionalidad pudiera
					corresponderles.
				</p>
			</CheckParagraph>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '1em',
				}}
			>
				<div className={style.contentFooterDataLink}>
					<div
						onClick={() => {
							getPdfBnc(true)
						}}
						style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', margin: '20px auto' }}>
						<label style={{ marginTop: 7, cursor: 'pointer' }}>
							Previsualizar convenio
						</label>
						<img src={iconDownload} alt="download" />
					</div>
				</div>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					marginBottom: '20px',
				}}
			>
				¿Estás de acuerdo con las condiciones de la reestructura?
			</Box>
			<Box className={style.BoxAlert}>
				<CustomAlert
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
					onClose={() => {
						setAlert({
							show: false,
							message:
								'Por el momento no eres candidato para recibir algún apoyo de reestructura, posiblemente tu crédito no se encuentre con problemas graves de pago. Te invitamos a que sigas manteniendo el sano manejo de tu crédito o regularices el pago de tu crédito',
							severity: 'warning',
						});
					}}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				}}
			>
				<Box className={style.Box_Buttons}>
					<Box className={style.BoxButton}>
						<CustomButton
							onClick={() => {
								setBtnOptions({
									showModal: true,
									option: 'NO',
									msg: '¿Estás seguro que no aceptas las condiciones de la reestructura?',
								});
							}}
							variant="outlined"
							styles={{ height: '100%' }}
							label="No"
						/>
					</Box>
					<Box className={style.BoxButton}>
						<CustomButton
							onClick={() => {
								setBtnOptions({
									showModal: true,
									option: 'SI',
									msg: '¿Estás seguro que aceptas las condiciones de la reestructura?',
								});
							}}
							variant="solid"
							styles={{}}
							label="Sí"
						/>
					</Box>
				</Box>
			</Box>
			<ViewArchive show={openModal} setShow={setOpenModal} file={pdf} />
			<ModalConfirmation
				open={btnOptions.showModal}
				confirmLabel={
					btnOptions.option === 'SI' ? 'Sí acepto' : 'No acepto'
				}
				cancelLabel="Regresar"
				onConfirm={
					btnOptions.option === 'SI' ? onConfirm_SI : onCancel_SI
				}
				onClose={
					btnOptions.option === 'SI' ? onConfirm_NO : onCancel_NO
				}
				width="sm"
				cancelButton
			>
				<p className={style.textoModal}>{btnOptions.msg}</p>
			</ModalConfirmation>
		</>
	);
};

export default Summary;
