import { useState } from 'react';
import { Box, useMediaQuery, FormControlLabel, Checkbox } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../NipInfonatel/styles';
import CustomButtom from '../../components/Button';

interface IPersonalData {
	onContinue: () => void;
}

const TermsAndConditions = (props: IPersonalData) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [remember, setRemember] = useState(false);
	const { onContinue } = props;
	const navigate = useNavigate();
	const style = useStyles();

	return (
		<>
			<div>
				<br />
				<br />
				<h2 className={style.parrafoBoldSpace}>
					Términos y condiciones
				</h2>
				<div className={style.parrafospace}>
					Bienvenido(a) a nuestro servicio de NIP telefónico. Antes de utilizar
					nuestro servicio, te recomendamos leer detenidamente los siguientes
					términos y condiciones que rigen el uso del NIP telefónico proporcionado
					por nosotros, además del aviso de privacidad. Al acceder y utilizar
					nuestro servicio, aceptas cumplir con estos términos y condiciones en
					su totalidad.
				</div>
				<h4 className={style.parrafoBoldSpace}>
					Uso del NIP Telefónico
				</h4>
				<div className={style.parrafospace} style={{ marginTop: 0 }}>
					1.1. Nuestro NIP telefónico es un código personal y confidencial que
					te permite acceder a servicios y realizar transacciones a través del teléfono.
					<br />
					1.2. Al utilizar nuestro NIP telefónico, aceptas ser el único responsable
					de mantenerlo en secreto y no compartirlo con terceros. Cualquier uso no
					autorizado del NIP telefónico será responsabilidad exclusiva del titular
					de la cuenta.
				</div>
				<h4 className={style.parrafoBoldSpace}>
					Seguridad del NIP Telefónico
				</h4>
				<div className={style.parrafospace} style={{ marginTop: 0 }}>
					2.1. Nos comprometemos a mantener la confidencialidad y garantizar la seguridad
					de tu NIP telefónico como Institución administradora del mismo.
					<br />
					2.2. Es tu responsabilidad mantener tu NIP telefónico en un lugar seguro y
					no revelarlo a nadie, ni siquiera a nuestro personal o representantes.
					<br />
					2.3. En caso de sospecha de que tu NIP telefónico ha sido comprometido o
					utilizado sin tu autorización, debes informarnos de inmediato a través de
					cualquiera de nuestros canales de atención para que podamos tomar las
					medidas necesarias para proteger tu cuenta.
				</div>
				<h4 className={style.parrafoBoldSpace}>
					Responsabilidades del Usuario
				</h4>
				<div className={style.parrafospace} style={{ marginTop: 0 }}>
					3.1. El usuario acepta utilizar el NIP telefónico únicamente para acceder
					a los servicios autorizados y realizar transacciones permitidas.
					<br />
					3.2. El usuario acepta no utilizar el NIP telefónico para actividades
					ilegales, fraudulentas o que puedan causar daño a terceros o al Instituto.
					<br />
					3.3. El usuario es responsable de todas las transacciones realizadas
					utilizando su NIP telefónico, y cualquier pérdida financiera resultante
					será de su exclusiva responsabilidad.
				</div>
				<h4 className={style.parrafoBoldSpace}>
					Responsabilidades de la Institución
				</h4>
				<div className={style.parrafospace} style={{ marginTop: 0 }}>
					4.1. Nos esforzamos por brindar un servicio seguro y confiable. Sin embargo,
					no podemos garantizar la disponibilidad ininterrumpida del servicio debido a
					posibles interrupciones técnicas, tecnológicas o de otra causa de fuerza mayor
					ajena a nuestra voluntad.
					<br />
					4.2. No asumimos responsabilidad por pérdidas financieras o daños que puedan
					surgir como resultado del uso no autorizado o indebido del NIP telefónico,
					excepto en casos de negligencia grave o conducta fraudulenta por parte del Infonavit.
				</div>
				<h4 className={style.parrafoBoldSpace}>
					Cancelación y Suspensión
				</h4>
				<div className={style.parrafospace} style={{ marginTop: 0 }}>
					5.1. Nos reservamos el derecho de cancelar o suspender el NIP telefónico si sospechamos
					actividad fraudulenta, incumplimiento de estos términos y condiciones o violación de
					las leyes y regulaciones aplicables en favor de su seguridad y confidencialidad.
					<br />
					5.2. Nos reservamos el derecho de realizar cambios en estos términos y condiciones
					en cualquier momento. Te notificaremos sobre cualquier cambio importante a través
					de los medios de comunicación disponibles.
				</div>
				<h4 className={style.parrafoBoldSpace}>
					Propiedad Intelectual
				</h4>
				<div className={style.parrafospace} style={{ marginTop: 0 }}>
					6.1. Todos los derechos de propiedad intelectual relacionados con nuestro servicio
					de NIP telefónico son propiedad exclusiva nuestra o de nuestros licenciantes.
					<br />
					6.2. No se permite la reproducción, distribución o uso no autorizado de ningún
					aspecto de nuestro servicio de NIP telefónico sin nuestro consentimiento por escrito.
					<br />
					Estos términos y condiciones constituyen el acuerdo completo.
				</div>
				<h4 className={style.parrafoBoldSpace}>
					Términos y Condiciones de operación y prestación de servicios digitales
				</h4>
				<div className={style.parrafospace} style={{ marginTop: 0 }}>
					1. Aceptación de los términos: Al acceder y utilizar los servicios digitales,
					el usuario acepta cumplir con los términos y condiciones establecidas en este documento.
					<br />
					2. Servicios ofrecidos: Se describe detalladamente el tipo de servicio digital
					provisto, incluyendo características, funcionalidades, limitaciones y cualquier
					requisito específico.
					<br />
					3. Registro de cuenta: Puede requerirse que el usuario crea una cuenta para acceder
					a los servicios digitales. El usuario es responsable de proporcionar información
					precisa y mantener la confidencialidad de sus credenciales de acceso.
					<br />
					4. Uso autorizado: Los servicios digitales están destinados únicamente para uso personal
					y no comercial. El usuario se compromete a utilizar los servicios de acuerdo con las
					leyes y normativa aplicables, así como a no utilizarlos de manera que pueda interferir
					o dañar la operación de la plataforma o de otros usuarios.
					<br />
					5. Propiedad intelectual: Todos los derechos de propiedad intelectual asociados con los
					servicios digitales, incluyendo software, contenido, marcas registradas y derechos
					de autor, son propiedad del Infonavit como proveedor del servicio y están protegidos
					por las leyes correspondientes. El usuario acepta no utilizar, reproducir o distribuir
					dicho contenido sin autorización previa.
					<br />
					6. Privacidad y protección de datos: Se establece cómo se recopila, utiliza, almacena y
					protege la información personal del usuario. También se describe el uso de cookies u
					otras tecnologías similares y se proporciona información sobre las opciones de privacidad
					del usuario.
					<br />
					Consulte  nuestro aviso de privacidad, para que esté informado de como tratamos sus
					datos personales y a donde puede acudir en caso de dudas al respecto, se encuentra
					disponible en: <span><a href="https://portalmx.infonavit.org.mx/wps/wcm/connect/db10280c-541a-4eb7-9d0c-90109a02e92c/AvisoPrivacidadIntegralMCI.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CVID=n.CdknR" target="_blank" rel="noreferrer">https://portalmx.infonavit.org.mx/wps/wcm/connect/db10280c-541a-4eb7-9d0c-90109a02e92c/AvisoPrivacidadIntegralMCI.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CVID=n.CdknR</a></span>
					<br />
					7. Limitación de responsabilidad: El Infonavit como proveedor del servicio no se hace
					responsable de cualquier pérdida, daño o inconveniente causado por el uso de los servicios
					digitales, interrupciones del servicio, errores o virus informáticos. El usuario utiliza
					los servicios bajo su propio riesgo.
					<br />
					8. Modificaciones y terminación: El Infonavit se reserva el derecho de realizar modificaciones
					en los servicios digitales, incluyendo cambios en las características, funcionalidades o
					términos y condiciones. Asimismo, puede terminar o suspender el acceso a los servicios en
					caso de incumplimiento de los términos y condiciones por parte del usuario.
					<br />
					9. Disputas y resolución de conflictos: Se establece el procedimiento para resolver disputas
					o reclamaciones entre el usuario y el proveedor del servicio, ya sea a través de arbitraje,
					mediación u otros medios acordados.
				</div>
				<br />
				<FormControlLabel
					className={style.containerCheckbox}
					control={
						<Checkbox
							checked={remember}
							onChange={() => setRemember(!remember)}
						/>
					}
					label="Acepto el aviso de privacidad"
					classes={{
						label: remember ? style.Checklabel : 'inherit',
					}}
				/>
				<br />
				<Box className={style.containerButtonsEnd}>
					<Box margin={'20px 10px'} width={matches ? '80%' : 250}>
						<CustomButtom
							label={'Salir'}
							onClick={() => navigate('/')}
							variant="outlined"
							styles={{ height: '40px' }}
						/>
					</Box>
					<Box margin={'20px 10px'} width={matches ? '80%' : 250}>
						<CustomButtom
							label={'Continuar'}
							onClick={onContinue}
							variant="solid"
							disabled={!remember}
						/>
					</Box>
				</Box>
			</div>
		</>
	);
};

export default TermsAndConditions;
