import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Link } from '@mui/material';
import { green } from '@mui/material/colors';

const mappingClaseOperacion = (claseOperacion: string) => {
	switch (claseOperacion) {
		case 'ZAPO':
			return 'Felicitación';
		default:
			return 'Queja';
	}
};

export const columns = [
	{
		column: 'caso',
		label: 'Número de queja',
		renderValue: (
			value: any,
			i: number,
			oArray: any,
			selected: any,
			setSelected: any
		) => (
			<span
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Link
					underline="none"
					onClick={() => setSelected(value === selected ? 0 : value)}
				>
					{oArray[i].caso === selected ? (
						<ArrowDropUp color="primary" />
					) : (
						<ArrowDropDown color="primary" />
					)}
				</Link>
				{value}
			</span>
		),
	},
	{
		column: 'fechaCreacion',
		label: 'Fecha de solicitud',
		renderValue: (value: string) => {
			let day = value.substring(6);
			let month = value.substring(4, 6);
			let year = value.substring(0, 4);

			return `${day}/${month}/${year}`;
		},
	},
	{
		column: 'claseOperacion',
		label: 'Servicio',
		renderValue: mappingClaseOperacion,
	},
	{
		column: 'descripcionEstatus',
		label: 'Estatus',
		renderValue: (value: any, index: any, oArray: any) => (
			<span
				style={{
					fontWeight: 500,
					color: mappedEstatus(oArray[index].estatus).color,
				}}
			>
				{value}
			</span>
		),
	},
	{
		column: 'fechaModificacion',
		label: 'Fecha de estatus',
		renderValue: (value: string) => {
			let day = value.substring(6, 8);
			let month = value.substring(4, 6);
			let year = value.substring(0, 4);

			return `${day}/${month}/${year}`;
		},
	},
	{
		column: 'caso',
		label: 'Acciones',
		renderValue: (
			value: any,
			i: number,
			oArray: any,
			selected: any,
			setSelected: any
		) => (
			<Link
				underline="hover"
				onClick={() => setSelected(value === selected ? 0 : value)}
			>
				{value === selected ? 'Ocultar' : 'Ver'} detalles
			</Link>
		),
	},
];

export const mappedEstatus = (clave: string, texto?: any) => {
	switch (clave) {
		case 'E0001':
			return {
				descripcion2: 'Tu queja está en proceso.',
				descripcion: 'Abierto',
				color: '#ffb835',
			};
		case 'E0002':
			return {
				descripcion2:
					'Tu queja está en análisis; consulta el estatus por este mismo medio o en tu correo electrónico, a donde te enviaremos la respuesta.',
				descripcion: 'Asignado a analista',
				color: '#ffb835',
			};
		case 'E0003':
			return {
				descripcion2:
					'Tu queja está en análisis; consulta el estatus por este mismo medio o en tu correo electrónico, a donde te enviaremos la respuesta.',
				descripcion: 'Cerrado',
				color: green[500],
			};
		case 'E0004':
			return {
				descripcion2:
					'Tienes 5 días hábiles para enviar tus documentos, de lo contrario la queja se cerrará y deberás iniciar de nuevo.',
				descripcion: 'En espera de documentación',
				color: '#ffb835',
			};
		case 'E0005':
			return {
				descripcion2:
					'Tu queja está en análisis; consulta el estatus por este mismo medio o en tu correo electrónico, a donde te enviaremos la respuesta',
				descripcion: 'Escalado a especialista',
				color: '#ffb835',
			};
		case 'E0006':
			return {
				descripcion2: texto,
				descripcion: 'Queja Atendida',
				color: '#ffb835',
			};
		case 'E0007':
			return {
				descripcion2: texto,
				descripcion: 'Cerrado',
				color: green[500],
			};
		case 'E0008':
			return {
				descripcion2:
					'Hemos recibido tus documentos para que el área correspondiente inicie el análisis. Revisa el avance o la respuesta de tu queia por este mismo medio.',
				descripcion: 'No procede Queja',
				color: '#ffb835',
			};
		case 'E0009':
			return {
				descripcion2: texto,
				descripcion: 'Cerrado',
				color: green[500],
			};
		case 'E0010':
			return {
				descripcion2: 'En espera de actualización',
				descripcion: 'En espera de actualización',
				color: '#ffb835',
			};
		default:
			return {
				descripcion2: '',
				descripcion: '',
				color: 'black',
			};
	}
};
