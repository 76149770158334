import Close from '@mui/icons-material/Close';
import { Paper, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import BaseModal from '../../components/ModalConfirmation';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { apiRfcCorrection } from '../../api/modules/rfcCorrection';
import { IAlert } from '../../interfaces/alert';
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';
import { useNavigate } from 'react-router-dom';
import { useBitacora } from '../../hooks/useBitacora';

interface Props {
	setPage: (data: number) => void;
}

interface IDataStatus {
	apMaterno?: string;
	apPaterno?: string;
	consecutivo?: string;
	curp?: string;
	nombre?: string;
	nss?: string;
	rfc?: string;
	status?: {
		code?: string;
		description?: string;
	};
	CodigoError?: string;
	TipoError?: string;
	descripcionServicio?: string;
	fecha?: string;
	fechaStatus?: string;
	noCaso?: string;
	nombreOperacion?: string;
	operacion?: string;
}

const GotPayslip = ({ setPage }: Props) => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [displayView, setDisplayView] = useState(0);
	const setModal = useState(null)[1];
	const [modalFile, setModalFile] = useState(false);
	const [loading, setLoading] = useState(false);
	const { user } = useSelector((state: RootState) => state.session);
	const [dataStatus, setDataStatus] = useState<IDataStatus>({});
	const { regBitacoraNotOld } = useBitacora();
	const navigate = useNavigate();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [files, setFiles] = useState({
		file1: {
			name: INTERNAL_MESSAGES.NO_FILE,
			data: null,
		},
	});

	const consultaCFDI = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			const rfc_base64 = await encrypt(user?.rfc.toString() || '', key);
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const curp_base64 = await encrypt(user?.curp.toString() || '', key);
			const email_base64 = await encrypt(user?.email.toString() || '', key);
			const telefonoMovil_base64 = await encrypt(
				user?.telefonoCelular.toString() || '',
				key
			);
			const fullName_base64 = await encrypt(user?.given_name.toString() || '', key);

			let formData = new FormData();
			formData.append('nss', nss_base64);
			formData.append('recibo', files?.file1.data ? files.file1.data : '');
			formData.append('email', email_base64);
			formData.append('fullName', fullName_base64);
			formData.append('telefonoMovil', telefonoMovil_base64);
			formData.append('rfc', rfc_base64);
			formData.append('curp', curp_base64);

			uploadToFileServer(
				formData,
				`${process.env.REACT_APP_API_URL}/cambio-rfc/cfdi`
			)
				.then((response) => {
					if (response) {
						consultaStatus();
						regBitacoraNotOld('/cambio-rfc/cfdi', files?.file1?.name);
					} else {
						setLoading(false);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error?.codigoError === 'E') {
						setAlert({
							show: true,
							message:
								INTERNAL_MESSAGES.CFDI_PROOF,
							severity: 'error',
						});
					} else if (error.description || error.value) {
						let messageError = error.description ? error.description :
							error.value?.mensajeError ? error.value?.mensajeError :
								INTERNAL_MESSAGES.ERROR_MESSAGE
						setAlert({
							show: true,
							message: messageError === 'N - 601: La expresión impresa proporcionada no es válida.' ? 'El documento proporcionado no es válido' : messageError,
							severity: 'error',
						});
					} else {
						setAlert({
							show: true,
							message:
								INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				});
		} catch (err: any) {
			let messageError = err.description ? err.description :
				err.value?.mensajeError ? err.value?.mensajeError :
					'Por el momento el servicio no está disponible. Intenta más tarde.'
			setAlert({
				show: true,
				message: messageError === 'N - 601: La expresión impresa proporcionada no es válida.' ? 'El documento proporcionado no es válido' : messageError,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const consultaStatus = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			const rfc_base64 = await encrypt(user?.rfc.toString() || '', key);
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const curp_base64 = await encrypt(user?.curp.toString() || '', key);

			const responseStatusRFC = await apiRfcCorrection.getInitialStatus(
				nss_base64,
				rfc_base64,
				curp_base64
			);
			if (responseStatusRFC?.result) {
				setDataStatus(responseStatusRFC.result);
				setDisplayView(1);
			}
		} catch (error: any) {
			setLoading(false);
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_GETTING_DATA,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const uploadToFileServer = (formdata: any, url: string) => {
		return new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			const token = user?.token;
			xhr.responseType = 'json';
			xhr.open('POST', url, true);
			xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			xhr.setRequestHeader('Authorization', `Bearer ${token}`);
			xhr.send(formdata);

			xhr.onload = () => {
				if (xhr.status === 200) {
					resolve(xhr.response.result);
				} else {
					reject(xhr.response.err);
				}
			};
		});
	};

	const handleFile = (e: any) => {
		if (e.target.files[0].type === 'text/xml') {
			if (e.target.files[0].size < 5000000) {
				setFiles({
					...files,
					[e.target.name]: {
						name: e.target.files[0].name,
						data: e.target.files[0],
						type: e.target.files[0].type,
						size: e.target.files[0].size,
					},
				});
				e.target.value = null;
			} else {
				setAlert({
					show: true,
					message: 'El tamaño del archivo no debe ser mayor a 5 MB.',
					severity: 'error',
				});
			}
		} else {
			//setModalFile(true);
			setAlert({
				show: true,
				message: 'Formato no válido, verifica que el formato del archivo tenga la extensión .xml',
				severity: 'error',
			});
		}
	};
	const handleDeleteFile = (index: string) => {
		setFiles({
			...files,
			[index]: {
				name: INTERNAL_MESSAGES.NO_FILE,
				data: null,
			},
		});
	};

	return (
		<div>
			<HeaderList title="Registro o corrección de RFC" date=" 12 julio 2021" />
			<ModalLoading loading={loading} />
			<Paper>
				<div
					id="gotPayslip"
					style={{ display: displayView === 0 ? 'inline' : 'none' }}
				>
					<div
						style={{
							display: 'flex',
							alignItems: matches ? 'normal' : 'center',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							paddingLeft: matches ? 25 : 20,
							paddingRight: 20,
							paddingBottom: matches ? 70 : 10,

							flexDirection:
								files.file1.data && matches ? 'column' : 'inherit',
						}}
					>
						<h3
							style={{ color: 'black', width: 180, order: 1 }}
							data-testid="Recibonómina"
						>
							Recibo de nómina
						</h3>

						<p
							style={{
								flexGrow: 1,
								width: matches ? '100%' : 0,
								order: matches && !files.file1.data ? 3 : 2,
								margin: 0,
								opacity: 0.5,
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
						>
							{files.file1.name}
						</p>
						<div
							style={{
								textDecoration: 'none',
								color: '#D1001F',
								paddingTop: 0,
								fontSize: 16,
								order: matches && !files.file1.data ? 2 : 3,
								display: 'flex',
								alignItems:
									files.file1.data && matches ? 'normal' : 'center',
								flexDirection:
									files.file1.data && matches ? 'column' : 'inherit',
							}}
						>
							<label
								onClick={() => handleDeleteFile('file1')}
								style={{
									display: files.file1.data ? 'flex' : 'none',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
									cursor: 'pointer',
								}}
							>
								<Close style={{ fontSize: 20, marginRight: 2 }} />
								Eliminar archivo
							</label>
							<label
								style={{
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
								}}
								onClick={() =>
									files.file1.data && setModal(files.file1.data)
								}
							>
								<label
									style={{
										cursor: 'pointer',
										display: files.file1.data ? 'none' : 'flex',
									}}
								>
									<input
										type="file"
										accept=".xml"
										name="file1"
										onChange={handleFile}
										className={classes.chooseFileOK}
									/>
									Buscar archivo
								</label>
							</label>
						</div>
					</div>

					<hr style={{ margin: '0px 25px', opacity: 0.6 }} />

					<p className={classes.pTextPayslip} style={{ textAlign: 'center' }}>
						El documento que debes adjuntar es tu recibo de nómina digital,
						antes de enviarlo verifica que esté correcto. El formato permitido
						es .XML, cuyo tamaño no debe exceder los 5 MB.
					</p>
					<div style={{ paddingLeft: 98, paddingRight: 98 }}>
						<CustomAlert
							show={alert.show}
							severity={alert.severity}
							message={alert.message}
						/>
					</div>
					<div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: matches ? 'column-reverse' : 'inherit',
								padding: matches ? 10 : 0,
								paddingBottom: 20,
								paddingTop: 20,
							}}
						>
							<CustomButton
								data-testid="gtoPayCancelar"
								label="Cancelar"
								onClick={() => {
									setPage(0);
								}}
								variant="outlined"
								styles={{
									width: matches ? '100%' : 200,
									height: 40,
									marginRight: 20,
									marginBottom: 20,
								}}
							/>
							<CustomButton
								data-testid="gtoPayContinuar"
								label="Continuar"
								onClick={consultaCFDI}
								disabled={files.file1.data ? false : true}
								variant="solid"
								styles={{
									width: matches ? '100%' : 200,
									height: 40,
									marginBottom: 20,
								}}
							/>
						</div>
					</div>
				</div>
				<div
					style={{
						paddingTop: 0,
						paddingBottom: 10,
						paddingLeft: 120,
						paddingRight: 120,
					}}
				>
					<div
						id="thanksgotPayslip"
						className={classes.containerThanksElecSig}
						style={{
							display: displayView === 1 ? 'inline' : 'none',
						}}
					>
						<h3 className={classes.title} style={{ paddingTop: 10 }}>
							Gracias por utilizar los servicios electrónicos del Infonavit
						</h3>
						<br />
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<h3 className={classes.h4} style={{ color: 'black' }}>
								Número de caso
							</h3>
							<p className={classes.phidelargethanks}>
								{dataStatus.noCaso}
							</p>
						</div>
						<p className={classes.phidesmallthanks}>{dataStatus.noCaso}</p>
						<hr className={classes.hr} />

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<h3 className={classes.h4} style={{ color: 'black' }}>
								Fecha de solicitud
							</h3>
							<p className={classes.phidelargethanks}>{dataStatus.fecha}</p>
						</div>
						<p className={classes.phidesmallthanks}>{dataStatus.fecha}</p>
						<hr className={classes.hr} />

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<h3 className={classes.h4} style={{ color: 'black' }}>
								Servicio
							</h3>
							<p className={classes.phidelargethanks}>
								{dataStatus.descripcionServicio}
							</p>
						</div>
						<p className={classes.phidesmallthanks}>
							{dataStatus.descripcionServicio}
						</p>
						<hr className={classes.hr} />

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<h3 className={classes.h4} style={{ color: 'black' }}>
								Estatus
							</h3>
							<p className={classes.phidelargethanks}>
								{dataStatus.status?.description}
							</p>
						</div>
						<p className={classes.phidesmallthanks}>
							{dataStatus.status?.description}
						</p>
						<hr className={classes.hr} />

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<h3 className={classes.h4} style={{ color: 'black' }}>
								Fecha de estatus
							</h3>
							<p className={classes.phidelargethanks}>
								{dataStatus.fechaStatus}
							</p>
						</div>
						<p className={classes.phidesmallthanks}>
							{dataStatus.fechaStatus}
						</p>

						<div className={classes.botonContainerThanksPayslip}>
							<CustomButton
								label="Finalizar"
								onClick={() => navigate('/inicio')}
								variant="solid"
								styles={{
									width: matches ? 350 : 500,
									height: 40,
									marginBottom: 10,
									marginTop: 20,
								}}
							/>
						</div>
					</div>
				</div>
				<BaseModal
					open={modalFile}
					title="S"
					onConfirm={() => {
						setModalFile(false);
					}}
					onClose={() => setModalFile(false)}
					confirmLabel="Aceptar"
					width="xs"
					children={
						<div
							style={{
								paddingLeft: 40,
								paddingRight: 40,
								paddingTop: 30,
								paddingBottom: 10,
							}}
						>
							<h3
								style={{
									color: '#293990',
									fontSize: 20,
									display: 'flex',
									textAlign: 'center',
									justifyContent: 'center',
									margin: 0,
								}}
							>
								Por favor, adjunta un tipo de documento válido.
							</h3>
						</div>
					}
				/>
			</Paper>
		</div>
	);
};

export default GotPayslip;
