import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export default styled(({ className, ...props }: TooltipProps) => (
	<Tooltip
		{...props}
		leaveTouchDelay={3000}
		enterTouchDelay={50}
		classes={{ popper: className }}
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
	},
}));
