import { typesConstruyo } from './utils/typesConstruyo';

interface Props {
	type: string;
}

const Build = ({ type }: Props) => {
	return typesConstruyo[type] ? typesConstruyo[type] : <></>;
};

export default Build;
