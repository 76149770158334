import API from '..';
import {
	IResponseElementosMCI,
	IResponseUpdateMCI,
	IResponseCertificationMCI,
} from '../../interfaces/tallerSaberMas';

class TallerSaberMasAPI extends API {
	getElements(data: string): Promise<IResponseElementosMCI> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/taller-saber-mas/obtener-elementos`,
				method: 'POST',
				data,
			})
		);
	}
	updateElement(data: string): Promise<IResponseUpdateMCI> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/taller-saber-mas/actualizar-elemento`,
				method: 'POST',
				data,
			})
		);
	}
	getCertification(data: string): Promise<IResponseCertificationMCI> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/taller-saber-mas/obtener-certificado-pdf`,
				method: 'POST',
				data,
			})
		);
	}
}

export const tallerSaberMasAPI = new TallerSaberMasAPI('');
