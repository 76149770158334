import { useState } from 'react';
import { Help, Info } from '@mui/icons-material';
import { useStyles, stylesInLine } from './styles/style';
import LinksSpouse from './Panels/LinksSpouse';
import BirthCertificate from './Panels/BirthCertificate';
import DisabilityCertificate from './Panels/DisabilityCertificate';
import PluginInformation from './Panels/PluginInformation';
import Grounds from './Panels/Grounds';
import LetterOfInstruction from './Panels/LetterOfInstruction';
import InfonavitSupport from './Panels/InfonavitSupport';
import Ecotechnology from './Panels/Ecotechnology';
import AuthorizationSpouseBC from './Panels/AuthorizationSpouseBC';
import ChooseHousing from './Panels/ChooseHousing';
import AppraisalManagement from './Panels/AppraisalManagement';
import Build from './Panels/Build';
import Characteristic from './Panels/Characteristic';
import EstimatedTerm from './Panels/EstimatedTerm';
import CICB from './Panels/CICB';
import NeedToKnow from './Panels/NeedToKnow';
import EquipYourHouse from './Panels/EquipYourHouse';
import AuthorizedVouchers from './Panels/AuthorizedVouchers';
import GroundsVouchers from './Panels/GroundsVouchers';
import { Link, Divider, Box } from '@mui/material';
import AviableCreditPanel from './Panels/AviableCreditPanel';
import CustomButton from '../../../../components/Button';
import BaseModal from '../../../../components/ModalConfirmation';
import {
	typesSizes,
	typeType,
	typesTitles,
	typesPanels,
	typesContruir,
} from './utils/utils';
import NotarySelectInfo from './Panels/NotarySelectInfo';

type props = {
	type: typeType;
	showInfo?: boolean;
	icon?: boolean;
	dataAviable?: { title: string; image: any; description: string };
	openModal?: boolean;
	setOpenModal?: (arg: boolean) => void;
	customText?: string;
	infoIcon?: boolean;
};

const HelpPanel = ({
	type,
	showInfo,
	dataAviable,
	icon,
	openModal,
	setOpenModal,
	customText,
	infoIcon,
}: props) => {
	const classes = useStyles();
	const [open, setOpen] = useState<boolean>(false);

	return (
		<>
			<span
				onClick={() => {
					setOpen(true);
					setOpenModal && setOpenModal(true);
				}}
				className={classes.divContainer}
				style={{
					display:
						typeof openModal !== 'undefined' ? 'none' : 'contents',
				}}
			>
				{showInfo && (
					<Link className={classes.showInfoClass}>
						Más información
					</Link>
				)}

				{icon && (
					<Link underline="none" style={stylesInLine.linkClassOne}>
						<Help className={classes.HelpClass} />
					</Link>
				)}

				{customText && (
					<Link className={classes.customClass}>{customText}</Link>
				)}

				{infoIcon && (
					<Info color="primary" className={classes.infoIconClass} />
				)}

				{!showInfo && !icon && !customText && !infoIcon && (
					<Help style={stylesInLine.HelpClass} />
				)}
			</span>
			<BaseModal
				open={openModal ? openModal : open}
				width={typesSizes[type] ? typesSizes[type] : 'md'}
				onClose={() => {
					setOpen(false);
					setOpenModal && setOpenModal(false);
				}}
				showTitle
				title={
					<h4 className={classes.titleSuperior}>
						{typesTitles[type] ? typesTitles[type] : ''}
					</h4>
				}
			>
				<>
					{!dataAviable && <Divider style={{ marginBottom: 10 }} />}
					{type === typesPanels.VINCULA_CONYUGE && <LinksSpouse />}
					{type === typesPanels.DEBES_CONOCER && <NeedToKnow />}
					{type === typesPanels.EQUIPA_TU_CASA && <EquipYourHouse />}
					{type === typesPanels.COMPROBANTES_AUTORIZADOS && (
						<AuthorizedVouchers />
					)}
					{type === typesPanels.ACTA_NACIMIENTO && (
						<BirthCertificate />
					)}
					{type === typesPanels.CERTIFICADO_DISCAPACIDAD && (
						<DisabilityCertificate />
					)}
					{type === typesPanels.INFORMACION_COMPLEMENTOS && (
						<PluginInformation />
					)}
					{type === typesPanels.TERRENO && <Grounds />}
					{type === typesPanels.CARTA_INSTRUCCION && (
						<LetterOfInstruction />
					)}
					{type === typesPanels.APOYO_INFONAVIT && (
						<InfonavitSupport />
					)}
					{type === typesPanels.ECOTECNOLOGIAS && <Ecotechnology />}
					{type === typesPanels.AUTORIZACION_BC_CONYUGE && (
						<AuthorizationSpouseBC />
					)}
					{type === typesPanels.ELEGIR_VIVIENDA && <ChooseHousing />}
					{type === typesPanels.GESTION_AVALUO && (
						<AppraisalManagement />
					)}
					{(type === typesPanels.CONSTRUYO_RM ||
						type === typesPanels.CONSTRUYO_AT ||
						type === typesPanels.CONSTRUYO_CN) && (
						<Build type={typesContruir[type]} />
					)}
					{(type === typesPanels.CARACTERISTICAS_RM ||
						type === typesPanels.CARACTERISTICAS_AT ||
						type === typesPanels.CARACTERISTICAS_CN) && (
						<Characteristic type={typesContruir[type]} />
					)}
					{type === typesPanels.PLAZO_ESTIMADO && <EstimatedTerm />}
					{type === typesPanels.ENTIDADES && <CICB />}
					{type === typesPanels.AVIABLE_CREDITS && (
						<AviableCreditPanel
							setOpen={setOpen}
							setOpenModal={setOpenModal}
							dataAviable={dataAviable}
						/>
					)}
					{type === typesPanels.NOTARIO_SELECT && (
						<NotarySelectInfo />
					)}
					{type === typesPanels.COMPROBANTES_CREDITERRENO && (
						<GroundsVouchers />
					)}

					{!dataAviable && (
						<>
							<Divider style={{ margin: '20px 0px' }} />
							<Box className={classes.BoxCustom2}>
								{type === typesPanels.NOTARIO_SELECT && (
									<>
										<Box className={classes.buttonCustom}>
											<CustomButton
												label={'Regresar'}
												onClick={() => {
													setOpen(false);
													setOpenModal &&
														setOpenModal(false);
												}}
												variant="outlined"
											/>
										</Box>
										<Box className={classes.buttonCustom}>
											<CustomButton
												label={'Continuar'}
												onClick={() => {
													setOpen(false);
													setOpenModal &&
														setOpenModal(false);
												}}
												variant="solid"
											/>
										</Box>
									</>
								)}

								{type !== typesPanels.NOTARIO_SELECT && (
									<Box className={classes.buttonCustom}>
										<CustomButton
											label={'Regresar'}
											onClick={() => {
												setOpen(false);
												setOpenModal &&
													setOpenModal(false);
											}}
											variant="solid"
										/>
									</Box>
								)}
							</Box>
						</>
					)}
				</>
			</BaseModal>
		</>
	);
};

export default HelpPanel;
