import { useState } from 'react';
import { ITabElement } from '../interfaces/tabElement';

export const useTabs = (tabs: ITabElement[], contPosition: number, tabAct: number) => {
	const [tabActive, setTabActive] = useState(tabAct || 0);
	const [containerPosition, setContainerPosition] = useState(contPosition || 1);
	const [tabsEle, setTabsEle] = useState(tabs);

	const changeTabNext = (newTab?: number) => {
		const tabsFlag = [...tabsEle];
		const tabFlag = { ...tabsFlag[newTab || tabActive], checked: true };
		const nextTabActive = {
			...tabsFlag[newTab || tabActive + 1],
			disabled: false,
			orderSM: tabsFlag[newTab || tabActive + 1].orderSM - 1,
		};

		tabsFlag[newTab || tabActive] = { ...tabFlag };
		tabsFlag[newTab || tabActive + 1] = { ...nextTabActive };
		setContainerPosition(newTab ? newTab + 2 : containerPosition + 1);
		setTabsEle(tabsFlag);
		setTabActive(newTab || tabActive + 1);
	};

	const changeTabNextCustom = (newTab: number) => {
		let tabsFlag = [...tabsEle];
		tabsFlag = tabsFlag.map((ele) => {
			if (ele.id <= newTab) {
				return { ...ele, checked: true, disabled: true };
			}
			if (ele.id === newTab + 1) {
				return {
					...ele,
					checked: false,
					disabled: false,
					orderSM: ele.orderSM - 1,
				};
			}
			return { ...ele, orderSM: ele.orderSM - 1 };
		});
		setContainerPosition(newTab);
		setTabsEle(tabsFlag);
		setTabActive(newTab);
	};

	const changeTabPrevious = () => {
		const tabsFlag = [...tabsEle];
		const tabFlag = {
			...tabsFlag[tabActive],
			disabled: false,
			orderSM: tabsFlag[tabActive].orderSM + 1,
		};
		const previousTabActive = {
			...tabsFlag[tabActive - 1],
			checked: false,
			disabled: false,
		};

		tabsFlag[tabActive] = { ...tabFlag };
		tabsFlag[tabActive - 1] = { ...previousTabActive };
		setTabsEle(tabsFlag);
		setContainerPosition(containerPosition - 1);
		setTabActive(tabActive - 1);
	};

	const changeTabCustom = (newTab: number) => {
		const nextTabActive = tabsEle.map((Element, i) => {
			const index = i + 1;
			if (i <= newTab) {
				//Element.checked = true;
				Element.disabled = false;
				Element.orderSM = i > 0 ? index - 1 : 0;
				return Element;
			} else {
				//Element.checked = false;
				Element.orderSM = index;
				return Element;
			}
		});
		setContainerPosition(newTab ? newTab : containerPosition);
		setTabsEle(nextTabActive);
		setTabActive(newTab);
	};

	return {
		tabActive,
		containerPosition,
		tabsEle,
		changeTabNext,
		changeTabPrevious,
		changeTabCustom,
		changeTabNextCustom,
	};
};
