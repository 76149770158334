import { useState } from "react"
import Layout from "../../../../components/Layout"
import { MIUC_PRODUCTS } from "../../constants/products"
import { useController } from "../../hooks/useController"
import { EvaluationUnit } from "./evaluationUnit"
import ChooseValuation from "../../views/construir/pages/ChooseValuation"



export const EvaluationFlow = () => {

  const { dataProcessController } = useController();
  const [page, setPage] = useState(
    dataProcessController?.processInfo?.credit === MIUC_PRODUCTS.COMPRA ? 0 : 1
  )

  const pages = [
    <EvaluationUnit setPage={setPage} />,
    <ChooseValuation />,
  ]

  return (
    <Layout>
      { pages[page] }
    </Layout>
  )
}
