import React from 'react';
import { DatePicker } from '@mui/lab';
import { FormControlLabel, TextField } from '@mui/material';
import { useStyles } from './styles';

interface IDateInput {
	id?: string;
	name?: string;
	value?: any;
	label?: string | React.ReactChild;
	onChange?: any;
	required?: boolean;
	minDate?: string;
	maxDate?: string;
	views?: ['day' | 'month' | 'year'];
	disabled?: boolean;
	disableFuture?: boolean;
	disablePast?: boolean;
}

const DateInput = ({
	id,
	value,
	label,
	name,
	onChange,
	required,
	minDate,
	maxDate,
	views,
	disabled,
	disableFuture,
	disablePast,
}: IDateInput) => {
	const classes = useStyles();

	return (
		<FormControlLabel
			label={
				<span className={classes.labelContainerInput}>
					{label}
					{required && <span className={classes.labelRequired}>*</span>}
				</span>
			}
			className={classes.containerInput}
			labelPlacement="top"
			control={
				<DatePicker
					disabled={disabled}
					disableFuture={disableFuture}
					disablePast={disablePast}
					views={views}
					minDate={minDate}
					maxDate={maxDate}
					value={value}
					onChange={(newValue: string | null) =>
						onChange({ target: { name: name, value: newValue } })
					}
					renderInput={(params) => {
						return (
							<TextField
								{...params}
								id={id}
								fullWidth
								name={name}
								error={false}
								color="primary"
								margin="dense"
								classes={{ root: classes.inputRoot }}
								inputProps={{
									...params.inputProps,
									placeholder: 'dd/mm/yyyy',
								}}
							/>
						);
					}}
				/>
			}
		/>
	);
};

export default DateInput;
