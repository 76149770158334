import React, { useCallback, useState, useEffect } from 'react';
import { Paper, Typography } from '@mui/material';
import { useStyles } from './styles';
import Layout from '../../components/Layout';
//Assets
import imgNotFound from '../../assets/img/infonavit-not-found.png';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/Button';
import { contactRequestAPI } from '../../api/modules/profile';

const Maintenance = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [linkRedirect, setLinkRedirect] = useState('');

	const getRedirect = useCallback(async () => {
		try {
			contactRequestAPI.getRedirect
				.post<any, any>('')
				.then((res: any) => {
					setLinkRedirect(res?.redirect);
				})
				.catch();
		} catch (error) { }
	}, []);

	const redirectPortal = () => {
		linkRedirect ? (window.location.href = linkRedirect) : navigate('/inicio');
	};

	useEffect(() => {
		getRedirect();
	}, [getRedirect]);

	return (
		<Layout>
			<Paper elevation={1} className={classes.contenedor}>
				<div className={classes.cont1}>
					<div className={classes.contButtom}>
						<div className={classes.container}>
							<div className={classes.notFoundImage}>
								<img
									className={classes.image}
									src={imgNotFound}
									alt="Sitio en mantenimiento"
								></img>
							</div>

							<br />
							<Typography
								variant="subtitle2"
								sx={{ color: 'secondary.light', fontSize: 30 }}
							>
								¡Escribe nuevamente tu contraseña y continúa con el
								servicio!
							</Typography>

							<br />
							<Typography variant="body2">
								<b>
									El sitio al que pasarás es completamente seguro y es
									un canal oficial del Infonavit.
								</b>
							</Typography>
						</div>
					</div>
				</div>
				<div className={classes.cont1}>
					<div className={classes.contButtom}>
						<div className={classes.container}>
							<div className={classes.containerButtons}>
								<CustomButton
									label="Regresar"
									variant="outlined"
									styles={{ width: 240, margin: '0 10px' }}
									onClick={() => {
										navigate('/inicio');
									}}
								/>
								<CustomButton
									label="Continuar"
									variant="solid"
									styles={{ width: 240, margin: '0 10px' }}
									onClick={() => {
										redirectPortal();
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</Paper>
		</Layout>
	);
};

export default Maintenance;
