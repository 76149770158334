import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('115', 'consulta-relaciones-laborales');

export const ENDPOINTS_CONSULTA_RELACIONES_LABORABLES: IBitacora = {
	'/relacion-laboral': _bitacora.Read(
		'115100',
		'Consulta relaciones laborales'
	),
	'/relacion-laboral-pdf': _bitacora.Read(
		'115101',
		'Consulta PDF relaciones laborales'
	),
};
