/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';
import { IDetail } from '../../interfaces/serviceOffice';

interface IMap {
	markers: IDetail[];
	center: { lat: number; lng: number };
	lat?: number;
	lng?: number;
	zoom: number;
	onClickPoint: (item: IDetail) => void;
}

const Map = (props: IMap) => {
	const { markers, center, zoom, onClickPoint } = props;
	const mapRef = useRef<any>();

	useEffect(() => {
		fitBounds();
	}, [markers]);

	const fitBounds = () => {
		if (markers.length > 0) {
			const bounds = new window.google.maps.LatLngBounds();
			markers.forEach((item) => {
				bounds.extend({ lat: Number(item.latitud), lng: Number(item.longitud) });
			});
			if (mapRef.current) {
				mapRef.current?.fitBounds(bounds);
			}
		}
	};

	return (
		<GoogleMap
			ref={mapRef}
			defaultZoom={10}
			zoom={zoom}
			defaultCenter={center}
			center={center}
		>
			{markers.map((item, index) => (
				<Marker
					key={index}
					onClick={() => onClickPoint(item)}
					position={{ lat: Number(item.latitud), lng: Number(item.longitud) }}
				/>
			))}
		</GoogleMap>
	);
};

export default withScriptjs(withGoogleMap(Map));
