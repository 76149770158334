import { useState } from 'react';
import { Link } from '@mui/material';
import { useSelector } from 'react-redux';
import BaseModal from '../../../../../../components/Modal';
import { RoundBtn } from '../../../../../AssociateNssCredit/Styles';
import ModalLoading from '../../../../../../components/ModalLoading';
import iconoPDF from '../../../../../../assets/img/icono-descarga.png';
import { INTERNAL_MESSAGES } from '../../../../../../config/messageCatalog';
import { encodeAllJSONData } from '../../../../../../utils/encrypt';
import { RootState } from '../../../../../../store';
import CustomAlert from '../../../../../../components/CustomAlert';
import {
	SaldoMCIResponseModel,
	apiMIUC,
	IDataconsultarAgroconsultaRes,
	IAgroindustryDatosGenerales,
} from '../../../../../../api/modules/MIUC';
import { useStyles } from '../../styles';
import { RETURN_CODE } from '../../../../constants/returnCode';
import { useController } from '../../../../hooks/useController';

interface Props {
	open: boolean;
	setOpen: (arg: boolean) => void;
	balanceData: SaldoMCIResponseModel;
	datosGenerales: IAgroindustryDatosGenerales;
}

const FinishedRegisterModal = ({
	open,
	setOpen,
	balanceData,
	datosGenerales,
}: Props) => {
	const styles = useStyles();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const { user } = useSelector((state: RootState) => state.session);

	const { saveData } = useController();

	const getFullName = (): string => {
		let nombre: string = '';
		if (
			balanceData.nombre &&
			balanceData.apellidoPaterno &&
			balanceData.apellidoMaterno
		) {
			nombre = `${balanceData.nombre} ${balanceData.apellidoPaterno} ${balanceData.apellidoMaterno}`;
		} else {
			nombre = `${user?.nombres} ${user?.apPaterno} ${user?.apMaterno}`;
		}
		return nombre;
	};

	const getPdf = async () => {
		try {
			setLoading(true);
			const dataPdf = {
				pdfParamAhoMen: datosGenerales.ahorroMensual || '',
				pdfParamNombre: getFullName() || '',
				pdfParamNss: user?.nss.toString() || '',
			};
			const ENCODED_DATA = await encodeAllJSONData(
				{ data: JSON.stringify(dataPdf) },
				user?.k || ''
			);
			const { data } = await apiMIUC.getInscritoPDF(ENCODED_DATA);
			const { pdf } = data.result;
			if (pdf) {
				let link = document.createElement('a');
				link.download = 'consulta_inscripcion.pdf';
				link.href = 'data:application/octet-stream;base64,' + pdf;
				link.click();
				setMessage('');
			} else {
				setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
			}
		} catch (error) {
			setMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	const exitRegister = async () => {
		try {
			const data = user?.nss;
			const ENCODED_DATA = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const response = await apiMIUC.consultarAgroconsulta(ENCODED_DATA);
			let dataAgro: IDataconsultarAgroconsultaRes = response?.data;
			if (response?.code === RETURN_CODE.NO_RESGITRADO_AGRO) {
				dataAgro = { ...dataAgro, sitioRegistrado: 'SR' };
			} else if (
				dataAgro?.sitioRegistrado === 'TE' &&
				Number(dataAgro?.puntosTotalCorte) < 1080
			) {
				dataAgro = { ...dataAgro, sitioRegistrado: 'TX' };
			}
			saveData({
				agroData: dataAgro
			}, true);
		} finally {
			setOpen(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<BaseModal
				title={
					<span className={styles.colorMain}>
						Registro en el programa
					</span>
				}
				onClose={() => setOpen(false)}
				open={open}
				width="xs"
			>
				<div className={styles.alignCenter}>
					Ya estás inscrito en el programa.
					<Link className={styles.modalLink} onClick={() => getPdf()}>
						Descarga tu comprobante de registro
						<img
							src={iconoPDF}
							alt="iconoPDF"
							className={styles.modalDownloadPdf}
						/>
					</Link>
					<CustomAlert
						message={message}
						show={message ? true : false}
						severity={'warning'}
					/>
					<br />
					<RoundBtn
						className={styles.modalButton}
						variant="contained"
						onClick={() => exitRegister()}
					>
						Salir
					</RoundBtn>
				</div>
			</BaseModal>
		</>
	);
};

export default FinishedRegisterModal;
