import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		flowCard: {
			textAlign: 'center',
			// maxWidth: '412px',
			'&>.stepper-container': {
				marginLeft: '10px 0px',
				overflowY: 'auto',
				//maxHeight: 350,
			},
		},
		flowTitle: {
			display: 'flex',
			color: '#333333',
			fontWeight: 'bold',
			fontSize: 16,
			marginLeft: 25,
			marginBottom: 0,
		},
		seleccion: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			marginLeft: 5,
		},
		firstStep: {
			height: '25px',
			marginLeft: '30px',
		},
		steps: {
			marginLeft: '22px',
		},
		checkPasos: {
			color: '#00A300',
			padding: 0,
			margin: '5px',
			marginLeft: '2px',
			fontSize: 13,
		},
		checkConnect: {
			color: '#DFDFDF',
			height: '100%',
			width: 0,
			margin: 0,
			border: '1px solid',
		},
		stepTitle: {
			display: 'block',
			color: '#333333',
			fontWeight: 'bold',
			fontSize: 17,
		},
		stepDescription: {
			textAlign: 'left',
			marginLeft: 20,
			color: '#7B7B7C',
			fontSize: 13,
			marginBottom: '10px',
		},
		sinElementos: {
			display: 'flex',
			color: '#293990',
			margin: '10px 0px',
			marginLeft: '60px',
			justifyContent: 'start',
		},
		progress: {
			maxHeight: 8,
			maxWidth: '30px',
			marginTop: '16px',
			zIndex: 2
		},
		progressBackground: {
			maxHeight: 8,
			maxWidth: '30px',
			marginTop: '16px',
			position: 'absolute',
			zIndex: 1,
			color: '#DFDFDF',
		},
	})
);