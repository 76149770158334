import { makeStyles, createStyles } from '@mui/styles';
import theme from '../../config/theme';

export const useStyles = makeStyles(() =>
	createStyles({
		Header_Text_Blue: {
			color: '#32527A',
			textAlign: 'center',
			marginTop: '1%',
			marginBottom: '1%',
			fontSize: '12px',
		},
		Header_Text_Red: {
			color: 'Red',
		},

		spacingGridsRows: {
			padding: 20,
		},

		alignTextCenter: {
			justifyContent: 'center',
		},

		fechaSub: {
			fontSize: '13px',
			marginTop: '1.5%',
			marginBottom: '1.5%',
		},

		boxGray: {
			background: '#F2F2F2',
			borderRadius: '10px',
			border: '1px solid #F2F2F2',
			padding: '20px',
			textAlign: 'center',
			width: '100%',
		},
		boxGrayText: {
			color: 'black',
			fontSize: '13px',
		},
		imagenCentro1: {
			height: '81px',
			width: '101px',
			[theme.breakpoints.down('md')]: {
				height: '56.7px',
				width: '70.7px',
			},
			[theme.breakpoints.down('sm')]: {
				height: '56.7px',
				width: '70.7px',
			},
		},
		imagenCentro2: {
			height: '81px',
			width: '286px',
			[theme.breakpoints.down('md')]: {
				height: '56.7px',
				width: '200.2px',
			},
			[theme.breakpoints.down('sm')]: {
				height: '56.7px',
				width: '200.2px',
			},
		},
		imagenCentro: {
			width: '70%',
			height: 'auto',
		},
		Text_Blue: {
			color: '#43519D',
			fontSize: '13px',
			lineHeight: '0',
			paddingLeft: 25,
			paddingRight: 25,
		},
		Text_Blue_Black: {
			color: '#43519D',
			fontSize: '15px',
		},
		Text_Black: {
			color: 'black',
			fontSize: '18px',
		},

		Paper: {
			height: 'auto',
			background: 'red',
			overflow: 'auto',
			top: 35,
		},

		Paperfooter: {
			//width: '100%',
			textAlign: 'center',
			background: '#333333',
			padding: '20px',
			position: 'relative',
			left: '0',
			bottom: '0',
		},
		Text_White: {
			color: '#FFFFFF',
			fontSize: '12px',
			lineHeight: '0',
		},

		backgroundFull: {
			height: 'auto',
			background: '#F8F8F8',
			paddingBottom: '20px',
			overflow: 'auto',
			position: 'relative',
		},

		backgroundTop: {
			height: 'auto',
			background: '#F2F2F2',
			width: '100%',
			position: 'relative',
			left: 0,
			top: 0,
			overflow: 'auto',
		},

		ImgContainer: {
			justifyContent: 'center',
			display: 'flex',
			paddingLeft: '0px',
			paddingRight: '0px',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '18px',
				paddingRight: '18px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '18px',
				paddingRight: '18px',
			},
		},

		ImgCentro: {
			width: '100%',
			justifyContent: 'center',
			display: 'flex',
			paddingLeft: '230px',
			paddingRight: '230px',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '10px',
				paddingRight: '10px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '10px',
				paddingRight: '10px',
			},
		},

		logosSpacing: {
			// // marginTop: '3%',
			// // marginBottom: '5px',
			display: 'flex',
			flexDirection: 'row',
			// maxWidth: '550px',

			//justifyContent: 'center',
			// paddingLeft: '0px',
			// paddingRight: '0px',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '10px',
				paddingRight: '10px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '10px',
				paddingRight: '10px',
			},
		},
		boxGrayContainer: {
			display: 'flex',
			justifyContent: 'center',
			paddingLeft: 200,
			paddingRight: 200,
			paddingTop: 25,
			paddingBottom: 15,
			[theme.breakpoints.down('md')]: {
				paddingLeft: 10,
				paddingRight: 10,
				paddingTop: 25,
				paddingBottom: 15,
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 10,
				paddingRight: 10,
				paddingTop: 25,
				paddingBottom: 15,
			},
		},
		BoxButton: {
			display: 'flex',
			justifyContent: 'center',
			paddingLeft: 180,
			paddingRight: 180,
			marginLeft: 150,
			marginRight: 150,
			width: '100%',
			[theme.breakpoints.down('md')]: {
				marginLeft: 1,
				marginRight: 1,
				paddingLeft: 5,
				paddingRight: 5,
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 5,
				paddingRight: 5,
				marginLeft: 1,
				marginRight: 1,
			},
		},
		BoxRowContainer: {
			paddingLeft: 10,
			paddingRight: 10,
			marginLeft: 130,
			marginRight: 130,
			marginTop: 20,
			[theme.breakpoints.down('md')]: {
				marginLeft: 1,
				marginRight: 1,
				paddingLeft: 5,
				paddingRight: 5,
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 5,
				paddingRight: 5,
				marginLeft: 1,
				marginRight: 1,
			},
		},
	})
);
