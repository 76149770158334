import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		padding: '20px',
		marginTop: 20,
	},

	title: {
		textAlign: 'left',
		font: 'normal normal normal 18px/28px Geomanist',
		letterSpacing: '0px',
		color: '#333333',
		margin: '0px',
		lineHeight: '20px',
		paddingTop: '10px'
	},

	subTitle: {
		textAlign: 'left',
		font: 'normal normal normal 14px/18px Geomanist',
		letterSpacing: '0px',
		color: '#293990',
		margin: '0px',
	},

	buttonContainer: {
		display: 'flex',
		alignItems: 'end',
		paddingBottom: '5px'
	},
}));
