import React from 'react';
import mail from '../../../../../assets/img/mail.png';
import { useStyles } from '../confirmationStyles';
const EmailConfirmation2 = () => {
	const styles = useStyles();

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				gap: 10,
				marginBottom: 20,
				marginTop: 20,
			}}
		>
			<img alt="email" src={mail} />
			<span className={styles.VerificacionCodigoTitle}>
				<strong style={{ fontWeight: 700, fontSize: 32 }}>
					Confirmación de correo{' '}
				</strong>
			</span>
			<span className={styles.VerificacionText}>
				<strong>
					El correo eléctronico que nos proporcionaste no existe, por favor
					actualizalo para <br />continuar con tu registro.
				</strong>
			</span>
		</div>
	);
};

export default EmailConfirmation2;
