// DEPENDENCIES
import React, { useState } from 'react';
import { Checkbox, Divider, FormControl, FormControlLabel } from '@mui/material';
// COMPONENTS
import CustomAlert from '../../../components/CustomAlert';
import CustomButton from '../../../components/Button';
// RESOURCES
import { IAlert } from '../../../interfaces/alert';
import { useStyles } from './styles';

interface IDataClabe {
	banco: string;
	nombre: string;
	clabe: string;
}

interface Props {
	setPage: (data: number) => void;
	onConfirm: () => void;
	onCloseError: () => void;
	hasError: IAlert;
	DataClabe?: IDataClabe;
}

const LayoutNotice = ({
	setPage,
	DataClabe,
	onConfirm,
	onCloseError,
	hasError,
}: Props) => {
	const classes = useStyles();
	const [buttonValidate, setButtonValidate] = useState(true);

	const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked === true) {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	};

	const disabledButton = (): boolean => {
		if (hasError.show) {
			return true;
		} else if (buttonValidate) {
			return true;
		} else {
			return false;
		}
	};
	return (
		<>
			<div className={classes.titleNoticeContainer}>
				<div>
					<h2 className={classes.titleNotice}>
						Devolución de pagos en exceso{' '}
					</h2>
				</div>

				<div className={classes.subTitleNotice}></div>
			</div>

			<div className={classes.container}>
				<div>
					<h3 className={classes.principalTitle}>
						Verifica que los datos sean correctos
					</h3>
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>Banco</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									{DataClabe?.banco}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>Nombre</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									{DataClabe?.nombre}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Clave Bancaria Estandarizada (CLABE)
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									{DataClabe?.clabe}
								</p>
							</div>
						</div>
					</div>
					<FormControl>
						<FormControlLabel
							label="Autorizo que mis recursos sean depositados en la cuenta bancaria que se muestra"
							control={<Checkbox onChange={onChangeCheckbox} />}
						/>
					</FormControl>
					<CustomAlert
						show={hasError.show}
						severity={hasError.severity}
						message={hasError.message}
						onClose={onCloseError}
					/>
					<div className={classes.contentFileDataButtons}>
						<CustomButton
							onClick={() => {
								setPage(3);
							}}
							variant="outlined"
							styles={{ marginTop: 80, width: 200, marginInline: 10 }}
							label="Regresar"
						/>
						<CustomButton
							// onClick={() => {
							// 	setPage(5);
							// }}
							onClick={onConfirm}
							disabled={disabledButton()}
							variant="solid"
							styles={{ marginTop: 80, width: 200, marginInline: 10 }}
							label="Continuar"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default LayoutNotice;
