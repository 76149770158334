/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Paper } from '@mui/material';
import Register from './views/Register';
import Movements from './views/Movements';
import { useStyles } from './styles';
import ModalLoading from '../../../../components/ModalLoading';
import Booklet from './views/Booklet';
import { useAgroindustry } from './hooks/useAgroindustry';
import Layout from '../../../../components/Layout';
import { useGeneralStyles } from '../styles/general.styles';
import CustomAlert from '../../../../components/CustomAlert';

const Agroindustry = () => {
	const {
		agroData,
		balanceData,
		component,
		dataDatosGenerales,
		setAlertRegistro,
		handlerGoToPage,
		handlerConfirmRegister,
		flag_finishedModal,
		alertRegistro,
		handlerCancelMovements,
		loading,
		handlerSubmitMovements,
		alert,
	} = useAgroindustry();
	const generalStyle = useGeneralStyles();
	const classes = useStyles();

	return (
		<>
			<Layout>
				<Box className={generalStyle.container}>
					<Grid container spacing={2}>
						<CustomAlert
							show={alert.show}
							message={alert.message}
							severity={alert.severity}
						/>
						<Grid item xs={12}>
							<Paper>
								<div className={classes.content}>
									<h3 className="credit-request-title">
										Crédito para Trabajadores de la
										Agroindustria
									</h3>

									{component === 'INFOAGROINDUSTRIA' && (
										<Booklet
											AhorroMensual={
												dataDatosGenerales.ahorroMensual
											}
											handlerContinue={(e) => {
												setAlertRegistro({
													show: false,
													message: '',
													severity: 'error',
												});
												handlerGoToPage(e);
											}}
										/>
									)}

									{component === 'REGISTRO' && (
										<Register
											datosGenerales={dataDatosGenerales}
											agroData={agroData}
											handlerConfirmRegister={
												handlerConfirmRegister
											}
											flag_finishedModal={
												flag_finishedModal
											}
											handlerAlert={alertRegistro}
											handlerBack={(e: any) => {
												setAlertRegistro({
													show: false,
													message: '',
													severity: 'error',
												});
												handlerGoToPage(e);
											}}
											handlerFinish={
												handlerCancelMovements
											}
											balanceData={balanceData}
										/>
									)}
									{component === 'INFOAHORRO' && (
										<Movements
											agroData={agroData}
											balanceData={balanceData}
											datosGenerales={dataDatosGenerales}
											handlerSubmit={
												handlerSubmitMovements
											}
										/>
									)}
								</div>
							</Paper>
						</Grid>
					</Grid>
					<ModalLoading loading={loading} />
				</Box>
			</Layout>
		</>
	);
};

export default Agroindustry;
