// Material Components
import { Box } from '@mui/system';

//Components
import VerificationCode from '../../components/VerificationCode';

// Assets
import { useStyles } from './styles';

// Intefaces
import { IEventTab } from '../../interfaces/eventTab';

const VerificacionView = (ActionsButtons: IEventTab) => {
	const styles = useStyles();
	return (
		<Box id="container" className={styles.container}>
			<Box>
				<VerificationCode
					isEmail={
						ActionsButtons.getValue.medioComucacion ===
							'Por correo electrónico'
							? true
							: false
					}
					setCode={(props: string) => {
						ActionsButtons.onValues({
							name: 'codigoVerificacion',
							value: props,
						});

						ActionsButtons.onNextTab();
					}}
					isSixDigits={true}
					reSend={ActionsButtons.onReSendMessage}
					hasError={ActionsButtons.onAlert}
				/>
			</Box>
		</Box>
	);
};

export default VerificacionView;
