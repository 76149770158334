import React, { Fragment } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { useStyles } from './styles';
import annualReturns from '../../config/annualReturns';

export default function MyLastContributionTable() {
	const classes = useStyles();

	return (
		<Fragment>
			<p className={classes.containerTitle}>
				<strong>Tabla de rendimientos</strong>
			</p>
			<div className={classes.headerTableTitle}>
				<strong style={{ color: 'white', fontSize: 14 }}>
					Rendimientos generados
				</strong>
			</div>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								variant="head"
								className={classes.tableTitleHeader}
							>
								Año
							</TableCell>
							<TableCell
								variant="head"
								className={classes.tableTitleHeader}
								style={{ width: '50%' }}
							>
								Tasa de rendimiento nominal anual
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{annualReturns.map((row, index) => (
							<TableRow
								key={index}
								sx={{
									'&:last-child td, &:last-child th': {
										border: 0,
									},
								}}
							>
								<TableCell component="th" scope="row">
									{row.year}
								</TableCell>
								<TableCell>{row.tasaRen}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	);
}
