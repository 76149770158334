import { useEffect, useState } from 'react';
import { Paper, useMediaQuery, Box, Link } from '@mui/material';
import { Error } from '@mui/icons-material';
import { UseStyle } from './Style';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import {
	ICredito,
	ICreditoDetalle,
	Messages,
	formatNumber,
	formatDate,
	formatNumero,
	formatMessage,
} from '../../interfaces/SaldAndMovement';
import { apiSaldAndMovements } from '../../api/modules/SaldAndMovements';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import ModalLoading from '../../components/ModalLoading';
import { HeaderList } from '../../components/HeaderList';
import CustomAlert from '../../components/CustomAlert';
import TooltipCustom from '../../components/TooltipCustom';
import iconoPDF from '../../assets/img/icono-descarga.png';

interface Props {
	setView: (avg: number) => void;
	credito: ICredito;
	showBtnCalidad: string;
}

const MyCreditData = ({ setView, credito, showBtnCalidad }: Props) => {
	const classes = UseStyle();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [creditoDetalle, setCreditoDetalle] = useState<ICreditoDetalle>({});
	const [loagin, setLoagin] = useState(false);
	const [showError, setShowError] = useState('');

	useEffect(() => {
		const req = async () => {
			try {
				setLoagin(true);
				const no_credito = credito?.credito || '';
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const credit_encript = await encrypt(no_credito || '', key);
				const { result } = await apiSaldAndMovements.getCredito(
					credit_encript
				);
				setCreditoDetalle(result);
			} catch (error) {
				setShowError(
					INTERNAL_MESSAGES.CONNECTION_ERROR
				);
			} finally {
				setLoagin(false);
			}
		};
		req();
	}, [credito]);

	return (
		<>
			<ModalLoading loading={loagin} />

			<HeaderList
				title="Datos de mi crédito"
				list={[
					'En este servicio encuentras la información de tu crédito; saldo, mensualidad, pagos y estados de cuenta.',
				]}
			/>

			<Paper style={{ marginTop: 30, textAlign: 'center', padding: 5 }}>
				<CustomAlert
					severity="error"
					show={showError ? true : false}
					message={showError}
				/>

				<p
					style={{
						paddingBottom: 30,
						paddingTop: 10,
						fontSize: 16,
						fontWeight: 500,
					}}
				>
					Condiciones financieras con las cuales firmaste tu crédito
				</p>

				<div className={classes.myCreditDataItem}>
					<div
						style={{
							minWidth: matches ? '100%' : 150,
							margin: matches ? 4 : 20,
						}}
					>
						<p style={{ color: '#293990' }}>
							Monto de otorgamiento en pesos:
						</p>
						<span style={{ color: '#293990' }}>
							{formatNumber.format(
								Number(
									credito?.totalCredit
										? credito?.totalCredit
										: 0
								)
							)}
						</span>
						MXN
					</div>

					<div
						style={{
							minWidth: matches ? '100%' : 150,
							margin: matches ? 4 : 20,
						}}
					>
						<p>Tasa de interés:</p>
						<span>
							{formatNumero.format(
								creditoDetalle.origen?.tasInteres
									? creditoDetalle.origen?.tasInteres
									: 0
							)}{' '}
							%
						</span>
					</div>
					<div
						style={{
							minWidth: matches ? '100%' : 150,
							margin: matches ? 4 : 20,
						}}
					>
						<p>Tipo de crédito:</p>
						<span>
							{creditoDetalle?.origen?.tipoCredito ? creditoDetalle?.origen?.tipoCredito : ''}{' '}
							{creditoDetalle?.header?.tipoCredito ? creditoDetalle?.header?.tipoCredito.trim() : ''}
						</span>
					</div>
				</div>

				<Box
					paddingLeft={matches ? 1 : 2}
					paddingRight={matches ? 1 : 2}
				>
					<div className={classes.myCreditDataElemnt}>
						<span>Número de crédito</span>
						<label></label>
						<div>{credito?.credito}</div>
					</div>

					<div className={classes.myCreditDataElemnt}>
						<span>Fecha de otorgamiento</span>
						<label>
							<TooltipCustom
								title="Día, mes y año en que fue otorgado el crédito."
								placement="right"
								arrow
							>
								<Error
									style={{ fontSize: 15, marginRight: 5 }}
								/>
							</TooltipCustom>
						</label>
						<div>
							{formatDate(
								creditoDetalle.origen?.fechaOtorgamiento,
								'/'
							)}
						</div>
					</div>

					<div className={classes.myCreditDataElemnt}>
						<span>Tipo de moneda</span>
						<label>
							<TooltipCustom
								title="Unidad de pago con la que fue contratado el crédito. Puede ser
								en VSM o en pesos."
								placement="right"
								arrow
							>
								<Error
									style={{ fontSize: 15, marginRight: 5 }}
								/>
							</TooltipCustom>
						</label>
						<div>{creditoDetalle.origen?.tipoMoneda}</div>
					</div>

					<div className={classes.myCreditDataElemnt}>
						<span>Saldo de otorgamiento en VSM</span>
						<label>
							<TooltipCustom
								title="Monto de dinero que el Infonavit otorgó en VSM al autorizar el
								crédito."
								placement="right"
								arrow
							>
								<Error
									style={{ fontSize: 15, marginRight: 5 }}
								/>
							</TooltipCustom>
						</label>
						<div>
							{creditoDetalle.origen?.mtoOtorgaVsm
								? creditoDetalle.origen?.mtoOtorgaVsm
								: 0.0}
						</div>
					</div>

					<div className={classes.myCreditDataElemnt}>
						<span>Tipo de tasa de interés</span>
						<label>
							<TooltipCustom
								title="Puede ser fija o variable, de acuerdo con  las características del crédito."
								placement="right"
								arrow
							>
								<Error
									style={{ fontSize: 15, marginRight: 5 }}
								/>
							</TooltipCustom>
						</label>
						<div>{creditoDetalle.origen?.tipoTasaInteres}</div>
					</div>

					<div className={classes.myCreditDataElemnt}>
						<span>Plazo</span>
						<label>
							<TooltipCustom
								title="Plazo"
								placement="right"
								arrow
							>
								<Error
									style={{ fontSize: 15, marginRight: 5 }}
								/>
							</TooltipCustom>
						</label>
						<div>{creditoDetalle.origen?.v25Plazo || 0} años</div>
					</div>

					<div className={classes.myCreditDataElemnt}>
						<span>Tipo de pago</span>
						<label>
							<TooltipCustom
								title="Tipo de pago."
								placement="right"
								arrow
							>
								<Error
									style={{ fontSize: 15, marginRight: 5 }}
								/>
							</TooltipCustom>
						</label>
						<div>{creditoDetalle.origen?.tipoPago}</div>
					</div>
				</Box>

				{creditoDetalle.creditoActual &&
					Object.keys(creditoDetalle.creditoActual).length > 0 && (
						<>
							<p
								style={{
									fontSize: 16,
									fontWeight: 500,
									margin: '40px 0px',
								}}
							>
								Datos y características financieras de mi
								crédito actuales
							</p>

							<Box
								paddingLeft={matches ? 1 : 2}
								paddingRight={matches ? 1 : 2}
							>
								<div className={classes.myCreditDataElemnt}>
									<span>Fecha de movimiento</span>
									<label>
										<TooltipCustom
											title="Dia, mes y año en que se realizo el movimiento a pesos."
											placement="right"
											arrow
										>
											<Error
												style={{
													fontSize: 15,
													marginRight: 5,
												}}
											/>
										</TooltipCustom>
									</label>
									<div>
										{formatDate(
											creditoDetalle.creditoActual
												?.fechaMovimiento,
											'/'
										)}
									</div>
								</div>

								<div className={classes.myCreditDataElemnt}>
									<span>Tipo de moneda</span>
									<label>
										<TooltipCustom
											title="Unidad de pago actual del crédito."
											placement="right"
											arrow
										>
											<Error
												style={{
													fontSize: 15,
													marginRight: 5,
												}}
											/>
										</TooltipCustom>
									</label>
									<div>
										{
											creditoDetalle.creditoActual
												?.tipoMoneda
										}
									</div>
								</div>

								<div className={classes.myCreditDataElemnt}>
									<span>Tipo de tasa de interés</span>
									<label>
										<TooltipCustom
											title="Fija, de acuerdo a las características de tu crédito."
											placement="right"
											arrow
										>
											<Error
												style={{
													fontSize: 15,
													marginRight: 5,
												}}
											/>
										</TooltipCustom>
									</label>
									<div>
										{
											creditoDetalle.creditoActual
												?.tipoTasaInteres
										}
									</div>
								</div>

								<div className={classes.myCreditDataElemnt}>
									<span>Tipo de movimiento</span>
									<label>
										<TooltipCustom
											title="Nombre asignado por el Infonavit al apoyo que aceptaste."
											placement="right"
											arrow
										>
											<Error
												style={{
													fontSize: 15,
													marginRight: 5,
												}}
											/>
										</TooltipCustom>
									</label>
									<div>
										{
											creditoDetalle.creditoActual
												?.tipoMovimiento
										}
									</div>
								</div>

								<div className={classes.myCreditDataElemnt}>
									<span>Plazo</span>
									<label>
										<TooltipCustom
											title="Plazo"
											placement="right"
											arrow
										>
											<Error
												style={{
													fontSize: 15,
													marginRight: 5,
												}}
											/>
										</TooltipCustom>
									</label>
									<div>
										{creditoDetalle.creditoActual?.plazo}{' '}
										años
									</div>
								</div>

								<div className={classes.myCreditDataElemnt}>
									<span>Tasa de de interés</span>
									<label>
										<TooltipCustom
											title="Tasa anual de interés ordinario de tu crédito."
											placement="right"
											arrow
										>
											<Error
												style={{
													fontSize: 15,
													marginRight: 5,
												}}
											/>
										</TooltipCustom>
									</label>
									<div>
										{formatNumero.format(
											creditoDetalle.creditoActual
												?.tazaInteres
												? creditoDetalle.creditoActual
													?.tazaInteres
												: 0
										)}{' '}
										%
									</div>
								</div>

								<div className={classes.myCreditDataElemnt}>
									<span>Tipo de pago</span>
									<label>
										<TooltipCustom
											title="Tipo de pago"
											placement="right"
											arrow
										>
											<Error
												style={{
													fontSize: 15,
													marginRight: 5,
												}}
											/>
										</TooltipCustom>
									</label>
									<div>
										{creditoDetalle.creditoActual.tazaPago}
									</div>
								</div>
							</Box>
						</>
					)}

				<Box
					paddingLeft={matches ? 2 : 10}
					paddingRight={matches ? 2 : 10}
					marginTop={5}
					marginBottom={5}
				>
					{credito.msgs
						?.filter((elemento) =>
							elemento.key.match(Messages.CodeDatosMicredito)
						)
						.map((msg, index) => (
							<p
								key={index}
								style={{ margin: 0, textAlign: 'center' }}
							>
								{formatMessage(msg.value, msg.key, credito)}
							</p>
						))}
				</Box>

				{showBtnCalidad && (
					<Box
						display={'flex'}
						flexDirection={matches ? 'column' : 'row'}
						justifyContent={'space-around'}
						margin={'25px 0px'}
					>
						<Link
							style={{
								display: matches ? 'flex' : 'inline-flex',
								alignItems: 'center',
								margin: '10px 10px',
							}}
							underline="none"
							href={showBtnCalidad}
							target="_blank"
						>
							<img
								src={iconoPDF}
								alt="iconoPDF"
								style={{ marginRight: 5, width: 18 }}
							/>
							Descarga el certificado de Seguro de Calidad
						</Link>
						<Link
							style={{
								display: matches ? 'flex' : 'inline-flex',
								alignItems: 'center',
								margin: '10px 10px',
							}}
							href={require('../../assets/files/GUIA_SEGURO_DE_CALIDAD_2022.pdf')}
							target="_blank"
							underline="none"
						>
							<img
								src={iconoPDF}
								alt="iconoPDF"
								style={{ marginRight: 5, width: 18 }}
							/>
							Descarga la guía del Seguro de Calidad
						</Link>
					</Box>
				)}

				<div
					style={{
						maxWidth: matches ? '100%' : 250,
						margin: 'auto',
						marginBottom: 30,
						display: 'flex',
					}}
				>
					<CustomButton
						variant="solid"
						onClick={() => setView(0)}
						label="Regresar"
					/>
				</div>
			</Paper>
		</>
	);
};
export default MyCreditData;
