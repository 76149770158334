import { useState } from 'react';
import { Grid } from '@mui/material';
import Modal from '../../../components/Modal';
import { UseStyle } from './Style';
import PayBaks from './PayBaks';
import ServicesTrade from './ServicesTrade';
import PayStateUnite from './PayStateUnite';
import CardSald from './CardSald';

interface Props {
	openModal: boolean;
	setOpenModal: (avg: boolean) => void;
}

const ModalOptionsPay = ({ openModal, setOpenModal }: Props) => {
	const classes = UseStyle();
	const [tab, setTab] = useState(0);

	return (
		<Modal
			width="lg"
			open={openModal}
			onClose={() => setOpenModal(false)}
			children={
				<div style={{ fontFamily: 'Geomanist' }}>
					<p
						style={{
							fontSize: 20,
							fontWeight: 700,
							borderBottom: '1px solid rgba(0,0,0,0.1)',
							paddingBottom: 30,
							marginBottom: 40,
							color: '#293990',
							marginTop: 0,
						}}
					>
						Opciones de pago
					</p>
					<Grid
						container
						columns={12}
						justifyContent="space-around"
						style={{ maxWidth: 800, margin: 'auto' }}
					>
						<Grid item xs={12} md={3}>
							<div
								onClick={() => setTab(0)}
								className={
									tab === 0 ? classes.modalTabActive : classes.modalTab
								}
							>
								<label>Pago en bancos</label>
							</div>
						</Grid>
						<Grid item xs={12} md={3}>
							<div
								onClick={() => setTab(1)}
								className={
									tab === 1 ? classes.modalTabActive : classes.modalTab
								}
							>
								<label>
									Tiendas de autoservicio y comercios afiliados
								</label>
							</div>
						</Grid>
						<Grid item xs={12} md={3}>
							<div
								onClick={() => setTab(2)}
								className={
									tab === 2 ? classes.modalTabActive : classes.modalTab
								}
							>
								<label>Paga desde U.S.A y paga en línea</label>
							</div>
						</Grid>
						<Grid item xs={12} md={3}>
							<div
								onClick={() => setTab(3)}
								className={
									tab === 3 ? classes.modalTabActive : classes.modalTab
								}
							>
								<label>Carta Saldo</label>
							</div>
						</Grid>
					</Grid>
					{tab === 0 && <PayBaks setOpenModal={setOpenModal} />}
					{tab === 1 && <ServicesTrade setOpenModal={setOpenModal} />}
					{tab === 2 && <PayStateUnite setOpenModal={setOpenModal} />}
					{tab === 3 && <CardSald setOpenModal={setOpenModal} />}
				</div>
			}
		/>
	);
};

export default ModalOptionsPay;
