import { useTabs } from '../../../hooks/useTabs';
// Material Components
import { Box, Paper } from '@mui/material';
// COMPONENTS
import Tabs from '../../../components/Tabs';
import FirmaView from './firmaView';
import AmountView from './amountView';
import ClabeView from './clabeView';
import BankView from './bankView';
import VerifyView from './verifyView';
import ResumeView from './resumeView';
import { useState } from 'react';
import { IDataInfo, ISesionData, tabsFlag } from '../utils';

interface Props {
	setPage: (data: number) => void;
	caso: boolean;
	setLoading: (arg: boolean) => void;
	session: ISesionData;
}

const CreditProcess = ({ setPage, caso, session, setLoading }: Props) => {
	const {
		containerPosition,
		tabActive,
		tabsEle,
		changeTabNext,
		changeTabPrevious,
	} = useTabs(tabsFlag, 1, 0);
	const [dataInfo, setDataInfo] = useState<IDataInfo>({});

	return (
		<>
			<Tabs
				tabs={tabsEle}
				containerPosition={containerPosition}
				active={tabActive}
				cardsType
			>
				<Paper>
					{tabActive === 0 && (
						<Box sx={{ width: '100%' }}>
							<FirmaView
								setPage={setPage}
								onContinue={changeTabNext}
								setLoading={setLoading}
							/>
						</Box>
					)}
					{tabActive === 1 && (
						<Box sx={{ width: '100%' }}>
							<AmountView
								onContinue={changeTabNext}
								onReturn={changeTabPrevious}
								setLoading={setLoading}
								setDataInfo={setDataInfo}
                                dataInfo={dataInfo}
							/>
						</Box>
					)}
					{tabActive === 2 && (
						<Box sx={{ width: '100%' }}>
							<ClabeView
								onContinue={changeTabNext}
								onReturn={changeTabPrevious}
								setLoading={setLoading}
                                dataInfo={dataInfo}
								setDataInfo={setDataInfo}
							/>
						</Box>
					)}
					{tabActive === 3 && (
						<Box sx={{ width: '100%' }}>
							<BankView
								onContinue={changeTabNext}
								onReturn={changeTabPrevious}
								dataInfo={dataInfo}
                                setDataInfo={setDataInfo}
								setLoading={setLoading}
							/>
						</Box>
					)}
					{tabActive === 4 && (
						<Box sx={{ width: '100%' }}>
							<VerifyView
								onContinue={changeTabNext}
								onReturn={changeTabPrevious}
								setLoading={setLoading}
                                dataInfo={dataInfo}
							/>
						</Box>
					)}
					{tabActive === 5 && (
						<Box sx={{ width: '100%' }}>
							<ResumeView />
						</Box>
					)}
				</Paper>
			</Tabs>
		</>
	);
};

export default CreditProcess;
