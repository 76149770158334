/* eslint-disable jsx-a11y/anchor-is-valid */
// Dependencies
import React from 'react';

// Material Components
import { Box, Paper, Container, Grid, Divider } from '@mui/material';

// Components
import CustomButton from '../../components/Button';

// Resources

// Assets
import { useStyles } from './style';
import logoInfonavitRojo from '../../assets/img/logo-infonavit-rojo.png';
import logoMciColor2 from '../../assets/img/logo-mci-color-2.png';
import imaTema6 from '../../assets/img/ima-tema-6.png';

const PageConfirmation = () => {
	const [FechaSub] = React.useState('@FECHASUB@');
	const [DatosGenerales] = React.useState({
		Nombre: 'NOMBRE@',
		NSS: '@NSS@',
		FECHAPASS: '@FECHAPASS@',
		HORAPASS: '@HORAPASS@',
		CORREO: '@CORREO@',
	});

	const styles = useStyles();
	return (
		<>
			<Box className={styles.backgroundFull}>
				<Container maxWidth="lg">
					<Paper className={styles.Paper}>
						<Box sx={{ JustifyContent: 'center', paddingTop: 4 }}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Box className={styles.logosSpacing}>
									<img
										src={logoInfonavitRojo}
										className={styles.imagenCentro1}
										alt="Logo infonavit"
									/>
									<Divider
										orientation="vertical"
										sx={{ width: '2%' }}
										flexItem
									/>
									<img
										src={logoMciColor2}
										className={styles.imagenCentro2}
										alt="Logo MCI"
									/>
								</Box>
							</Box>

							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
								className={styles.spacingGridsRows}
							>
								<span className={styles.fechaSub}>{FechaSub}</span>
							</Box>

							<Box className={styles.boxGrayContainer}>
								<Box className={styles.boxGray}>
									<span className={styles.boxGrayText}>
										Nombre: <strong>{DatosGenerales.Nombre}</strong>{' '}
										<br /> Número de Seguridad Social (NSS):{' '}
										<strong>{DatosGenerales.NSS}</strong>{' '}
									</span>
								</Box>
							</Box>

							<Box style={{ display: 'flex', justifyContent: 'center' }}>
								<Box className={styles.ImgCentro}>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											width: '100%',
										}}
									>
										<img
											src={imaTema6}
											className={styles.imagenCentro}
											alt="imagen de fondo"
										/>
									</Box>
								</Box>
							</Box>

							<Box
								sx={{ textAlign: 'center' }}
								className={styles.BoxRowContainer}
							>
								<label className={styles.Text_Black}>
									<strong>
										Tu contraseña de Mi Cuenta Infonavit se actualizó
										el
									</strong>
								</label>
								<br />
								<label className={styles.Text_Blue}>
									{DatosGenerales.FECHAPASS} a las{' '}
									{DatosGenerales.HORAPASS}, hora del centro de México.
								</label>
							</Box>

							<Grid
								container
								spacing={0}
								justifyContent="center"
								className={styles.spacingGridsRows}
							>
								<Box className={styles.BoxButton}>
									<CustomButton
										onClick={() => {}}
										variant="solid"
										styles={{}}
										label="Entra a Mi Cuenta Infonavit"
										disabled={false}
									/>
								</Box>
							</Grid>

							<Grid
								container
								spacing={0}
								justifyContent="center"
								className={styles.spacingGridsRows}
							>
								<Grid
									item
									sx={{
										textAlign: 'center',
										paddingLeft: 2,
										paddingRight: 2,
										width: '100%',
									}}
								>
									<label className={styles.Text_Black}>
										<strong>
											Si tú no solicitaste el cambio de contraseña,
											llama a infonatel para hacer la aclaración.
										</strong>
									</label>
									<br />
									<br />
									<label className={styles.Text_Blue}>
										Marca al 55 9171 5050 en la Ciudad de México, o al
										800 008 3900 desde cualquier parte del país, de
										lunes a viernes de 7:30 de la mañana a 9 de la
										noche; sábado, domingo y días festivos de 9 de la
										mañana a 3 de la tarde.
									</label>
									<br />
									<br />
									<label className={styles.Text_Blue_Black}>
										<strong>
											En el infonavit todos los trámites son
											gratuitos
										</strong>
									</label>
								</Grid>
							</Grid>
							<div className={styles.Paperfooter}>
								<label className={styles.Text_White}>
									<strong>Aviso de privacidad: </strong>Para darte un
									mejor servicio reunimos tus datos sensibles y
									personales y contamos con medidas de seguridad que los
									protegen.
									<br />
									Si tienes dudas consulta nuestro{' '}
									<a href="#">
										Aviso de privacidad y la Politíca de privacidad
									</a>{' '}
									en <a href="#">www.infonavit.org.mx</a>
									<br />
									<br />
									Este correo fue enviado a {DatosGenerales.CORREO} si
									deseas cancelar tu subscripción,{' '}
									<a href="#">entra aquí</a>
								</label>
							</div>
						</Box>
					</Paper>
				</Container>
			</Box>
		</>
	);
};

export default PageConfirmation;
