import { Paper, Box, Grid } from '@mui/material';
import { formatMoney } from '../../../../utils/validators';
import iconoPesos from '../../../../assets/img/icono-pesos.png';
import { useStyles, centeredItems } from './styles';
import ModalLoading from '../../../../components/ModalLoading';
import { useSelectionResume } from '../SelectionResume/useSelectionResume';

const ResumeCard = ({ noTopMargin }: { noTopMargin?: boolean }) => {
	const classes = useStyles();
	const { loading, totalCuantity } = useSelectionResume();

	return (
		<>
			<Paper
				elevation={0}
				variant="outlined"
				className={classes.container}
				sx={{
					mt: noTopMargin ? 0 : '75px',
				}}
			>
				<Box padding={2} className={classes.centeredColumn}>
					<p className={classes.titulo}>Contarías con:</p>

					<Grid container>
						<Grid item xs={12} md>
							<h2 style={centeredItems}>
								<img
									src={iconoPesos}
									alt="Icono Pesos"
									className={classes.iconoPesos}
								/>
								<span className={classes.contarias}>
									{formatMoney(Number(totalCuantity))}
								</span>
							</h2>
						</Grid>
					</Grid>
				</Box>
			</Paper>
			<ModalLoading loading={loading} />
		</>
	);
};

export default ResumeCard;
