import React from 'react';
import { blue } from '@mui/material/colors';
import BaseModal from '../../../../components/Modal';
import { Divider } from '@mui/material';

interface IRejectProrogationModal {
	open: boolean;
	setOpen: any;
	handleReject: () => void;
}

const RejectProrogationModal = ({
	open,
	setOpen,
	handleReject,
}: IRejectProrogationModal) => {
	return (
		<BaseModal
			open={open}
			onClose={() => setOpen('')}
			onConfirm={handleReject}
			confirmLabel="Si"
			cancelLabel="No"
			cancelButton
			width="sm"
		>
			<>
				<Divider />
				<h3 style={{ color: blue[900], textAlign: 'center' }}>
					¿Estás seguro de que rechazas las condiciones de la prórroga?
				</h3>
			</>
		</BaseModal>
	);
};

export default RejectProrogationModal;
