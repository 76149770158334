import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// PRINCIPAL CONTAINER
		content: {
			overflow: 'auto',
			textAlign: 'center',
			padding: '5px 25px',
			height: 'auto',
			'&>.credit-request-title': {
				color: theme.palette.info.main,
			},
			'&>.credit-request-actions-container': {
				display: 'flex',
				justifyContent: 'space-evenly',
				'&>button': {
					width: 200,
				},
				[theme.breakpoints.down('sm')]: {
					flexDirection: 'column-reverse',
					'&>button': {
						width: '100%',
					},
				},
			},
			'&>.address-form': {
				display: 'flex',
				flexWrap: 'wrap',
				marginBottom: 15,
				'&>*': {
					width: 180,
					margin: '0 10px !important',
					[theme.breakpoints.down('md')]: {
						width: '45%',
						flexDirection: 'column',
					},
					[theme.breakpoints.down('sm')]: {
						width: '100%',
						flexDirection: 'column',
					},
				},
			},
			'&>.credit-calculate-cost-form': {
				display: 'flex',
				alignItems: 'flex-end',
				'&>div': {
					width: '100%',
				},
				'&>button': {
					width: 200,
				},
				[theme.breakpoints.down('sm')]: {
					width: '100%',
					flexDirection: 'column',
					'&>button': {
						width: '100%',
					},
				},
			},
			'&>.credit-online-view': {
				textAlign: 'left',
				'&>.online-detail': {
					display: 'flex',
					alignItems: 'center',
					'&>svg': {
						marginRight: 5,
						fontSize: 'smaller',
					},
				},
			},
			'&>.form-container': {
				display: 'flex',
				flexWrap: 'wrap',
				marginBottom: 10,
				'&>*': {
					flex: '1 1 300px',
					paddingRight: 10,
					[theme.breakpoints.down('sm')]: {
						flex: '1 1 100%',
						paddingRight: 0,
					},
				},
			},
			'&>button': {
				width: 200,
				[theme.breakpoints.down('sm')]: {
					width: '100%',
				},
			},
		},
		listItem: {
			justifyContent: 'center',
			textAlign: 'center',
			[theme.breakpoints.up('xs')]: {
				display: 'flex',
				flexDirection: 'row',
				fontSize: 15,
				margin: 0,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'row',
				fontSize: 16,
				margin: 0,
			},
		},
		divButtons: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
		},
		buttons: {
			width: '200px',
			margin: '15px',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				margin: '8px',
			},
			[theme.breakpoints.down('md')]: {
				width: '100%',
				margin: '8px',
			},
		},
		helpPanelMovements: {
			fontSize: 'medium',
			marginBottom: 3,
			marginLeft: 1,
		},
		helpPanelBooklet: {
			fontSize: 18, 
			marginLeft: 4
		},
		listStyle: {
			textAlign: 'left', 
			paddingLeft: 25
		},
		registerSubtitle: {
			...centeredItems,
			justifyContent: 'center',
			fontWeight: 700,
		},
		registerLink: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: 10,
			cursor: 'pointer',
		},
		downloadPdf: {
			width: 18, 
			display: 'flex'
		},
		modalLink: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			margin: '20px 0px',
		},
		modalDownloadPdf: {
			width: 18,
			marginLeft: 5,
		},
		modalButton: {
			maxWidth: 150
		},
		listTextMovements: {
			marginTop: 2.45
		},
		listCheckMovements: {
			marginRight: 5, 
			width: 22
		},
		linkMovements: {
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		cellMovements: {
			borderRight: '1px solid lightgray'
		},
		alignLeft: {
			textAlign: 'left'
		},
		alignCenter: {
			textAlign: 'center'
		},
		bookletAhorro: {
			...centeredItems, 
			marginRight: 10,
			fontSize: '18px',
		},
		colorMain: {
			color: theme.palette.info.main
		},

	})
);

export const centeredItems = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};