/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useController } from '../../../hooks/useController';
import { useProductTableMIUC } from '../../../hooks/useProductTableMIUC';
import { IAlert } from '../../../../../interfaces/alert';
import { IProductMonto } from '../../../../../interfaces/creditRequest';
import { apiMIUC } from '../../../../../api/modules/MIUC';

import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { isEmpty } from '../../../../../utils/validators';
import { isNumberFloat } from '../../../../../utils/expressions';
import { formatDate } from '../../../../../utils/dates';
import {
	getUMA3_001_MIUC,
	rowsTitleHipoteca,
} from '../../../utils/selectionResume.utils';
import { CREDIT_OPTIONS, MIUC_PRODUCTS } from '../../../constants/products';
import { downloadPDF } from '../../../../../utils/downloadPDF';
import { STAGE_ID } from '../../../constants/stageId';
import { useTrazabilidad } from '../../../hooks/useTrazabilidad';
import { creditRequestAPI } from '../../../../../api/modules/CreditRequest';
import { useCats } from '../../../hooks/useCats';
import { CODES_RESPONSE } from '../../../../../config/miucConstants';
import { useMontoPlazoFlexible } from '../../../hooks/useMontoPlazoFlexible';
import { HeaderTooltip } from '../../../components/HeaderTooltip/headerTooltip';
import { formatTypesRow } from '../../../components/ProductsMiuc/interfaces/product.interface';
import { useUserInfo } from '../../../hooks/useUserInfo';
import {
	IMontosSolicitados,
	PRODUCT_KEYS,
} from '../../../interfaces/montoPlazoFlexible';

export const useHipotecaTable = () => {
	const {
		returnStep,
		addSelection,
		nextStep,
		getProgressStep,
		dataProcessController,
		saveData,
	} = useController();

	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [alertHipoteca, setAlertHipoteca] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [alertInput, setAlertInput] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const { user } = useSelector((state: RootState) => state.session);
	const { active, total } = getProgressStep();
	const newStep = {
		title: 'Estas son las opciones de financiamiento que tenemos para ti',
		subtitle:
			'Revisa cada opción, compara entre ellas y elige la que más te convenga',
		active,
		total,
	};
	const [selectProduct, setSelectProduct] = useState('');
	const [productsOrigins, setProductsOrigins] = useState<any[]>([]);
	const [products, setProducts] = useState<any[]>([]);
	const disableButton = isEmpty(selectProduct);
	const [valorOperacionState, setValorOperacionState] = useState('');
	const [plazo, setPlazo] = useState<string>('');
	const [plazos, setPlazos] =
		useState<{ value: string | number; text: string }[]>();
	const [plazoCny, setPlazoCny] = useState<string>('');
	const [plazosCny, setPlazosCny] =
		useState<{ value: string | number; text: string }[]>();
	const [inputPlazosDisabled, setInputPlazosDisabled] = useState(false);

	const {
		getDataProductAmount,
		getDataCalculadora,
		isSearch,
		setIsSearch,
		productsCopy,
		getDataCat,
	} = useProductTableMIUC();
	const { createTrazabilidad } = useTrazabilidad();
	const { getAllCats } = useCats();
	const { conyuge } = useUserInfo();
	const {
		getInputMonto,
		loadProductData,
		disableSelectPlazo,
		onChangePlazo,
		disableInputMonto,
		disableAll,
		enableAll,
		disableCalculateBtn,
		getPlazos,
	} = useMontoPlazoFlexible();
	const [rowsTitle, setRowsTitle] = useState(rowsTitleHipoteca);
	const [productsTable, setProductsTable] = useState<any[]>([]);
	const [productsToCalculate, setProductsToCalculate] = useState<any[]>([]);

	useEffect(() => {
		getProducts();
	}, []);

	useEffect(() => {
		formatProducts();
	}, [products, isSearch, disableInputMonto]);

	const next = () => {
		const product = products.find(
			(prod) => prod.producto === selectProduct
		);
		handleTrazabilidad();
		addSelection({
			title: 'Producto Seleccionado:',
			description: 'Infonavit',
		});
		saveData({
			processInfo: {
				productSelect: product,
			},
			processData: {
				active: 4,
			},
		});
		nextStep();
	};

	const handleTrazabilidad = async () => {
		const selectedProduct = products.find(
			(prod) => prod.producto === selectProduct
		);
		createTrazabilidad({
			credit: dataProcessController.processInfo?.credit,
			subtipoTrazabilidad: selectedProduct?.subtipoTrazabilidad,
		});
	};

	const getGarantia = (cdcMon: any, cdcMonCny: any): string => {
		const uma3_001 = getUMA3_001_MIUC(
			Number(dataProcessController?.titular?.valorUMA)
		);
		const titularSaldo = Number(
			dataProcessController?.titular?.saldoSARTotal || '0'
		);
		const conyugeSaldo = Number(
			dataProcessController?.conyuge?.saldoSARTotal || '0'
		);
		let saldoTotal = titularSaldo + conyugeSaldo;
		let montosMon = Number(cdcMon) + Number(cdcMonCny);

		if (dataProcessController?.processInfo?.creditType === 'I') {
			saldoTotal = titularSaldo;
			montosMon = Number(cdcMon);
		}

		if (
			isSearch &&
			saldoTotal >
				Number(valorOperacionState.toString().replaceAll(',', '')) +
					uma3_001
		) {
			return 'Saldo de la Subcuenta de vivienda';
		}

		if (saldoTotal > montosMon + uma3_001) {
			return 'Saldo de la Subcuenta de vivienda';
		}

		return 'Hipotecaria';
	};

	const getProducts = async (isCalculate?: boolean) => {
		let productsFlag: IProductMonto[] = [];
		try {
			setLoading(true);
			setAlert({
				show: false,
				message: '',
				severity: 'error',
			});
			let montosMaximos: IMontosSolicitados = {
				montoTitular: ' ',
				montoConyuge: ' ',
			};
			if (isCalculate) {
				productsToCalculate.forEach((prod) => {
					if (
						Number(prod?.montoCreditoSolicitado) >
						Number(montosMaximos.montoTitular)
					) {
						montosMaximos = {
							...montosMaximos,
							montoTitular: prod.montoCreditoSolicitado,
						};
					}
					if (
						Number(prod?.montoCreditoSolicitadoCny) >
						Number(montosMaximos.montoConyuge)
					) {
						montosMaximos = {
							...montosMaximos,
							montoConyuge: prod.montoCreditoSolicitadoCny,
						};
					}
				});
			}
			const data = getDataProductAmount({
				valorTerreno: valorOperacionState || '',
				plazoPart1: isCalculate && disableInputMonto ? plazo : '',
				plazoPart2: isCalculate && disableInputMonto ? plazoCny : '',
				...montosMaximos,
			});
			const ENCODED_DATA = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const response = await apiMIUC.consultarProductosMontos(
				ENCODED_DATA
			);
			if (response.code !== CODES_RESPONSE.CORRECTO) {
				setAlertInput({
					show: true,
					message:
						response.message ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
					severity: 'error',
				});
				setLoading(false);
				return;
			}
			if (
				[
					STAGE_ID.FRAUDE_DEFUNCION,
					STAGE_ID.DEFUNCION,
					STAGE_ID.NO_AUTENTICA_BC,
					STAGE_ID.ENDEUDAMIENTO,
				].includes(dataProcessController?.titular?.stageId)
			) {
				productsFlag = response?.data?.productos.filter(
					(value) => value.producto.trim() !== 'CS'
					// value.producto.trim() !== 'IT' // POR CAMBIOS QUE PIDIERON
				);
			} else {
				productsFlag = response?.data?.productos.filter(
					(value) => value.producto.trim() !== 'CS'
				);
			}
			let plazoMaximo = '';
			let plazoMaximoCny = '';
			const products = productsFlag;
			const newProducts = products.map((product: any) => {
				let productos = {
					...product,
					ahorro: dataProcessController?.titular?.saldoSARTotal || 0,
					ahorroCny:
						dataProcessController?.conyuge?.saldoSARTotal || 0,
					complementos: '',
					diffCubrir: product.diffCubrir || 0,
					diffCubrirCny: product.diffCubrirCny || 0,
					ambosContarian:
						Number(product.cdcMon || '0') +
						Number(product.cdcMonCny || '0'),
				};
				plazoMaximo = product.pzoCre;
				plazoMaximoCny = product.pzoCreCny;
				return {
					...productos,
				};
			});
			setPlazos(getPlazos(Number(plazoMaximo)));
			setPlazo(plazoMaximo);
			if (conyuge) {
				setPlazosCny(getPlazos(Number(plazoMaximoCny)));
				setPlazoCny(plazoMaximoCny);
			}
			const productsCatsAll: any[] = await getAllCats(newProducts);
			setProductsOrigins(productsCatsAll);
			if (isCalculate) {
				await handleCalculate(productsCatsAll);
				return;
			}
			setProductsToCalculate(productsCatsAll);
			setProducts(
				productsCatsAll.map((item) => {
					item.garantia = getGarantia(item.cdcMon, item.cdcMonCny);
					return item;
				})
			);
			loadProductData({
				plazos: {
					plazo: plazoMaximo,
					plazoCny: plazoMaximoCny,
				},
				products: productsCatsAll.map((p) => ({
					producto: p.producto,
					monto: p.mmcMon,
					montoConyuge: p?.mmcMonCny || '0',
				})),
			});
			getValidationHipoteca(false, productsCatsAll);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			setAlert({
				show: true,
				message:
					error.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
				severity: 'error',
			});
		}
	};

	const formatProducts = () => {
		if (products) {
			const rows = rowsTitleHipoteca.map((row) => {
				if (row.key === 'mmcMon')
					return {
						...row,
						format: undefined,
					};
				return row;
			});
			if (conyuge)
				rows.push({
					title: (
						<HeaderTooltip
							title="Ambos contarían con:"
							tooltip="Cantidad que resulta de sumar el monto de crédito más tu ahorro del titular y cónyuge, restándole los gastos."
						/>
					),
					key: 'ambosContarian',
					textPrimary: true,
					format: { type: formatTypesRow.MONEY },
				});
			setRowsTitle(rows);
			setProductsTable(
				products.map((product: any) => ({
					...product,
					mmcMon: getInputMonto({
						key: PRODUCT_KEYS.monto,
						product: product.producto,
						onChange: (value) =>
							handleChangeProductMonto(
								'mmcMon',
								value,
								product.producto
							),
						value:
							products?.find(
								(v) => v.producto === product.producto
							)?.mmcMon || '',
					}),
					mmcMonCny: getInputMonto({
						key: PRODUCT_KEYS.montoConyuge,
						product: product.producto,
						onChange: (value) =>
							handleChangeProductMonto(
								'mmcMonCny',
								value,
								product.producto
							),
						value:
							products?.find(
								(v) => v.producto === product.producto
							)?.mmcMonCny || '',
					}),
				}))
			);
		}
	};

	const handleCalculate = async (productosItems: IProductMonto[]) => {
		if (!valorOperacionState) {
			return setAlertInput({
				show: true,
				message: 'Escribe el valor de la vivienda que quieres comprar',
				severity: 'warning',
			});
		}
		if (Number(valorOperacionState || '0') <= 0) {
			return setAlertInput({
				show: true,
				message: 'Escribe el valor de la vivienda que quieres comprar',
				severity: 'warning',
			});
		}
		try {
			setLoading(true);
			let productsPromise: Promise<any>[] = [];
			for (let index = 0; index < productosItems.length; index++) {
				const product = productosItems[index];
				const data = getDataCalculadora(
					product,
					valorOperacionState,
					false,
					plazo,
					plazoCny
				);
				const ENCODED_DATA = await encodeAllJSONData(
					{ data: JSON.stringify(data) },
					user?.k || ''
				);
				productsPromise.push(apiMIUC.calculadora(ENCODED_DATA));
			}

			const response = await Promise.all(productsPromise);

			const productsCalculated = (response || []).map((productCal) => {
				const productItem = productosItems.find(
					(item) => item.producto === productCal.data?.producto
				);
				let newRawProducts = {
					...productItem,
					pzoCre: productCal?.data?.pzoCre || productItem?.pzoCre,
					pzoCreCny:
						productCal?.data?.pzoCre || productItem?.pzoCreCny,
					mmcMon: productCal?.data?.montoMaximoCredito,
					mmcMonCny: productCal?.data?.montoMaximoCreditoCny,
					gasMon: productCal?.data?.cargasFinancieras,
					gasMonCny: productCal?.data?.cargasFinancierasCny,
					cdcMon: productCal?.data?.capacidadCompra || '0',
					cdcMonCny: productCal?.data?.capacidadCompraCny || '0',
					desTot: productCal?.data?.descuento,
					desTotCny: productCal?.data?.descuentoCny,
					desRoa: productCal?.data?.descuentoSinSubsidio,
					desRoaCny: productCal?.data?.descuentoSinSubsidioCny,
					diffCubrir: productCal?.data?.diferenciasCargoTrabajador,
					diffCubrirCny:
						productCal?.data?.diferenciasCargoTrabajadorCny,
					nvoFPP: productCal?.data?.nvoFPP || '0',
					nvoFPPCny: productCal?.data?.nvoFPPCny || '0',
					nvoCotaAdmin: productCal?.data?.nvoCotaAdmin || '0',
					nvoCotaAdminCny: productCal?.data?.nvoCotaAdminCny || '0',
					saldoSubcuentaVivienda:
						productCal?.data?.saldoSubcuentaVivienda || 0,
					saldoSubcuentaViviendaCny:
						productCal?.data?.saldoSubcuentaViviendaCny || 0,
					ahorro:
						productCal?.data?.saldoSubcuentaVivienda ||
						dataProcessController?.titular?.saldoSARTotal ||
						0,
					ahorroCny: productCal?.data?.saldoSubcuentaViviendaCny || 0,
					garantia: getGarantia(
						productCal?.data?.capacidadCompra || 0,
						productCal?.data?.capacidadCompraCny || 0
					),
					ambosContarian:
						Number(productCal?.data?.capacidadCompra || '0') +
						Number(productCal?.data?.capacidadCompraCny || '0'),
				} as IProductMonto;
				return {
					...newRawProducts,
				};
			});

			setProducts(productsCalculated);
			getValidationHipoteca(true, productsCalculated);
			setIsSearch(true);
			disableAll();
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
		} finally {
			setAlertInput({
				show: false,
				message: '',
				severity: 'warning',
			});
		}
	};

	const handleChangeProductMonto = (
		key: string,
		value: string,
		product: string
	) => {
		const subKey =
			key === 'mmcMon'
				? 'montoCreditoSolicitado'
				: 'montoCreditoSolicitadoCny';

		setProducts(
			products.map((input) => {
				if (input.producto === product) {
					return {
						...input,
						[key]: value,
					};
				}
				return input;
			})
		);
		setProductsToCalculate(
			productsToCalculate.map((input) => {
				if (input.producto === product) {
					return {
						...input,
						[subKey]: value,
					};
				}
				return input;
			})
		);
	};

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		if (isNumberFloat(value)) {
			setValorOperacionState(value);
		}
	};

	const handleClearSearch = () => {
		const productBeforeClean = JSON.parse(JSON.stringify(productsCopy));
		getValidationHipoteca(false, productBeforeClean);
		setIsSearch(false);
		setValorOperacionState('');
		getProducts();
		enableAll();
	};

	const getValidationHipoteca = (
		isSearchActive: boolean,
		productsItems: IProductMonto[]
	) => {
		const titularSaldo = Number(
			dataProcessController?.titular.saldoSARTotal || '0'
		);
		let conyugeSaldo = 0;
		const uma3_001 = getUMA3_001_MIUC(
			Number(dataProcessController?.titular?.valorUMA)
		);
		const mensajeSaldoCubiertoTitular =
			'Monto de deuda cubierto por titular, no aplica conyugal.';

		const saldoOperacionTotal =
			Number(valorOperacionState.toString().replaceAll(',', '')) +
			uma3_001;

		let saldoTotalCalculado =
			Number(productsItems[0]?.cdcMon || '0') + uma3_001;
		if (
			dataProcessController?.processInfo?.creditType !==
			CREDIT_OPTIONS.INDIVIDUAL
		) {
			conyugeSaldo = Number(
				dataProcessController?.conyuge?.saldoSARTotal || '0'
			);
			saldoTotalCalculado += Number(productsItems[0]?.cdcMonCny || '0');

			if (
				(isSearchActive && titularSaldo > saldoOperacionTotal) ||
				(titularSaldo > saldoTotalCalculado && productsItems.length > 0)
			) {
				setAlertHipoteca({
					show: true,
					message: mensajeSaldoCubiertoTitular,
					severity: 'error',
				});
			} else {
				setAlertHipoteca({
					show: false,
					message: '',
					severity: 'error',
				});
			}
		}

		const totSalarioAc = titularSaldo + conyugeSaldo;

		if (
			(isSearchActive && totSalarioAc > saldoOperacionTotal) ||
			(productsItems.length > 0 && totSalarioAc > saldoTotalCalculado)
		) {
			setInputPlazosDisabled(true);
			return;
		}
		setInputPlazosDisabled(false);
	};

	const handleGetAmortizationTable = async () => {
		setLoading(true);
		const data = await handleAmortizationTable(
			products[0],
			dataProcessController?.conyuge ? true : false
		);
		const ENCODED_DATA = await encodeAllJSONData(
			{
				data: JSON.stringify(data),
			},
			user?.k || ''
		);
		apiMIUC
			.tablaAmortizacion(ENCODED_DATA)
			.then((response) => {
				if (response?.code === '0000') {
					downloadPDF({
						payload: response?.data?.data,
						name: 'Tabla de amortización',
					});
					setAlert({ show: false, message: '', severity: 'error' });
				}
			})
			.catch((err) => {
				setAlert({
					show: true,
					message:
						err.description ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					severity: 'error',
				});
			})
			.finally(() => setLoading(false));
	};

	const handleAmortizationTable = async (
		product: IProductMonto,
		conyuge: boolean
	): Promise<any> => {
		let destTot: string = '0';
		let descCredit: string = '0';
		let fondoProPagos: string = '0';
		let descuentoEco: string = '0';
		let destinoSeleccionado: string = '0';
		let productFlag: IProductMonto = { ...product };

		if (isSearch) {
			const dataCat = getDataCat(product, conyuge);
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(dataCat) },
				user?.k || ''
			);
			const responseCAT = await creditRequestAPI.obtenerCAT(
				dataEncripted
			);
			if (responseCAT?.result.code === '0000') {
				productFlag = {
					...productFlag,
					cat: !conyuge ? responseCAT?.result?.data?.cat : null,
					catCny: conyuge ? responseCAT?.result?.data?.catCny : null,
				};
			}
		}

		if (!conyuge) {
			const desEcoF =
				product.producto !== 'MVIT'
					? Number(productFlag.desEco || '0')
					: 0;
			destTot = (
				Number(productFlag.desTot || '0') -
				Number(productFlag.segDan || '0') -
				desEcoF
			).toString();
		} else {
			destTot = (
				Number(productFlag.desTotCny) -
				Number(productFlag.segDanCny || '0')
			).toString();
		}

		if (isSearch) {
			descCredit = conyuge ? productFlag.desRoaCny : productFlag.desRoa;
		} else {
			descCredit = conyuge ? productFlag.desCreCny : productFlag.desCre;
		}

		if (isSearch) {
			fondoProPagos = conyuge
				? productFlag.nvoFPPCny
				: productFlag.nvoFPP;
		} else {
			fondoProPagos = conyuge ? productFlag.fppCny : productFlag.fpp;
		}

		if (conyuge) {
			descuentoEco = '0';
		} else {
			descuentoEco = productFlag.desEco || '0';
		}

		if (dataProcessController?.processInfo?.credit) {
			destinoSeleccionado =
				dataProcessController?.processInfo?.credit?.substring(1, 2);
		} else {
			destinoSeleccionado = '0';
		}
		/* EL cat se obtiene cuando se manda llamar al CAT y esta recalculado*/
		const data: any = {
			nss: conyuge
				? dataProcessController?.conyuge?.nss
				: dataProcessController?.titular?.nss,
			tipoCredito: conyuge
				? dataProcessController?.processInfo?.creditType
				: `${dataProcessController?.processInfo?.creditType}1`,
			producto: productFlag.producto,
			montoMaximoCredito: conyuge
				? productFlag.mmcMonCny
				: productFlag.mmcMon,
			descuentoTrabajador: destTot.toString(),
			tasaInteres: conyuge ? productFlag.tasIntCny : productFlag.tasInt,
			salarioDiarioIntegrado: conyuge
				? dataProcessController?.conyuge?.salarioDiario
				: dataProcessController?.titular?.salarioDiario,
			descuentoCredito: descCredit.toString(),
			salarioMinimoDiario: dataProcessController?.titular?.valorUMA,
			fondoProteccionPagos: (fondoProPagos || '').toString(),
			descuentoTrabajadorEcos: descuentoEco,
			destino: destinoSeleccionado,
			cat: conyuge ? productFlag.catCny : productFlag.cat,
			descuentoTrabajadorSegDan: conyuge
				? productFlag.segDanCny
				: productFlag.segDan,
			montoMaximoEcos: conyuge ? '0' : productFlag.monEco,
			conyuge,
		};
		return data;
	};

	const handleButtonCalculate = () => {
		if (disableInputMonto) {
			getProducts(true);
		} else {
			handleCalculate(productsToCalculate);
		}
	};

	return {
		loading,
		next,
		returnStep,
		setSelectProduct,
		selectProduct,
		products,
		productsOrigins,
		newStep,
		disableButton,
		alert,
		alertInput,
		handleCalculate,
		handleClearSearch,
		isSearch,
		valorOperacionState,
		setValorOperacionState,
		handleSetData,
		getProducts,
		inputPlazosDisabled,
		alertHipoteca,
		handleGetAmortizationTable,
		dataProcessController,
		rowsTitle,
		productsToCalculate,
		productsTable,
		disableSelectPlazo,
		onChangePlazo,
		handleButtonCalculate,
		disableCalculateBtn,
		plazo,
		plazos,
		setPlazo,
		plazoCny,
		plazosCny,
		setPlazoCny,
		conyuge,
	};
};
