import { useMediaQuery } from '@mui/material';
import theme from '../../config/theme';
import Check from '../../assets/img/check_red.png';

const Eyelashes = ({
	label,
	index,
	activeIndex,
	handleChange,
	activeStep,
	countTabs,
}: any) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<div
			onClick={() => !activeStep && handleChange(index)}
			style={{
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
				justifyContent: 'center',
				borderTop: '1px solid rgba(0,0,0,0.1)',
				borderRight: '1px solid rgba(0,0,0,0.1)',
				borderLeft: '1px solid rgba(0,0,0,0.1)',
				borderBottom: matches ? '1px solid rgba(0,0,0,0.1)' : undefined,
				backgroundColor: '#fff',
				marginTop: matches ? '5px' : undefined,
				marginBottom: matches ? '5px' : undefined,
				marginLeft: index !== 0 ? '5px' : undefined,
				marginRight: index + 1 !== countTabs ? '5px' : undefined,
				borderRadius: matches ? '10px' : '10px 10px 0px 0px',
				boxShadow:
					!matches && activeIndex !== index
						? '0px -4px 10px 0px rgba(0,0,0,0.1)'
						: undefined,
				padding: '10px 0px',
				fontWeight: 500,
				fontSize: '15px',
				color:
					(activeStep && index <= activeIndex) ||
						activeIndex === index
						? '#DD0528'
						: '#CBCBCB',
				cursor: 'pointer',
				userSelect: 'none',
				order: matches
					? index <= activeIndex
						? index
						: countTabs + index
					: undefined,
			}}
		>
			{activeStep && `${index + 1}.- `}
			{label}
			{activeStep && index < activeIndex && (
				<img
					src={Check}
					alt="check"
					style={{
						marginLeft: 10,
						width: 18,
					}}
				/>
			)}
			{!matches && index === activeIndex && (
				<i
					style={{
						background: '#fff',
						width: '100%',
						height: '1px',
						position: 'absolute',
						left: 0,
						bottom: '-1px',
					}}
				/>
			)}
		</div>
	);
};

export default Eyelashes;
