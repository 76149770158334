import { useSelector } from 'react-redux';
import { apiMIUC } from '../../../../../api/modules/MIUC';
import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { useController } from '../../../hooks/useController';
import { useProductTableMIUC } from '../../../hooks/useProductTableMIUC';
import { RootState } from '../../../../../store';
import { useCats } from '../../../hooks/useCats';
import { MIUC_PRODUCTS } from '../../../constants/products';
import { formatDate } from '../../../../../utils/dates';
import { useState } from 'react';
import { arrayPlazos, arrayPlazosSuelo } from '../pages/utils';
import { useTrazabilidad } from '../../../hooks/useTrazabilidad';
import { CODES_RESPONSE } from '../../../../../config/miucConstants';
import { useMontoPlazoFlexible } from '../../../hooks/useMontoPlazoFlexible';

export const useCrediterreno = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const { dataProcessController } = useController();
	const { getDataProductAmount } = useProductTableMIUC();
	const { getAllCats } = useCats();
	const [plazos, setPlazos] = useState<any[]>([]);
	const { createTrazabilidad } = useTrazabilidad();
	const { getPlazos } = useMontoPlazoFlexible();

	const handleTrazabilidad = async (selectedProduct: any) => {
		try {
			const request = {
				nss: user?.nss?.toString() || '',
				curp: user?.curp || '',
				rfc: user?.rfc || '',
				nrp: user?.curp || '',
				nombre: user?.nombres || '',
				apellidoPaterno: user?.apPaterno || '',
				apellidoMaterno: user?.apMaterno || '',
				fecha: formatDate('dd.MM.yyyy'),
				mensaje:
					dataProcessController.processInfo?.credit.toUpperCase() ===
					MIUC_PRODUCTS.COMPRA
						? `PRECALIFICACION ${formatDate('dd/MM/yyyy')} ${
								dataProcessController.processInfo?.credit
						  }`
						: 'MCI',
				subtipoTrazabilidad:
					selectedProduct?.subtipoTrazabilidad || 'ZN01',
			};
			const newRequest = await encodeAllJSONData(
				{ data: JSON.stringify(request) },
				user?.k || ''
			);
			await apiMIUC.trazabilidadCRM(newRequest);
		} catch (error) {}
	};

	const handleTrazaSuelo = async () => {
		createTrazabilidad({
			credit: dataProcessController.processInfo?.credit,
		});
	};

	const getProducts = async (
		monto: any,
		plazo: any,
		valorTerrenoComprar: number,
		montoSolTerreno?: string,
		montosMaximos?: {
			producto: string;
			montoSuelo: string;
			montoConstruccion: string;
		}[]
	) => {
		const data = getDataProductAmount({
			valorTerreno: monto,
			plazoPart1: plazo,
			montoTitular: montoSolTerreno || '',
		});
		const ENCODED_DATA = await encodeAllJSONData(
			{
				data: JSON.stringify(data),
			},
			user?.k || ''
		);
		const {
			data: response,
			code,
			message,
		} = await apiMIUC.consultarProductosMontos(ENCODED_DATA);
		if (code !== CODES_RESPONSE.CORRECTO) {
			return {
				code: code,
				message: message,
				products: [],
			};
		}
		const products = response.productos;

		if (products.length > 0 && plazos.length === 0) {
			let plazos = [];
			if (
				dataProcessController.processInfo?.credit ===
				MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION
			) {
				plazos = getPlazos(Number(products[0].pzoCre || '0'));
			} else {
				plazos = getPlazos(Number(products[0].pzoCre || '0'));
			}
			setPlazos(plazos);
		}

		const productsCatsAll = await getAllCats(products);

		const newProducts = productsCatsAll.map((product: any) => {
			const resultMont =
				Number(monto.replaceAll('$', '').replaceAll(',', '') || '0') -
				Number(product.cdcMon || '0');
			const enganche = resultMont > 0 ? resultMont : 0;
			if (
				dataProcessController.processInfo?.credit ===
				MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION
			) {
				const { valorUMA, mmcMon, monAhoMax } = product;
				const valorUMATotal = Math.round(
					Number(valorUMA || '0') * 30.4 * 3
				);
				const montoTotal = Number(mmcMon || '0');
				const monTerreno = montoTotal * 0.35;
				let valorTerreno = valorTerrenoComprar;

				const montoMaximoSol = montosMaximos?.find(
					(m) => m.producto === product.producto
				);
				if (
					montoMaximoSol &&
					Number(montoMaximoSol.montoSuelo) < monTerreno
				) {
					valorTerreno = Number(montoMaximoSol.montoSuelo);
				}

				const resultadoTerreno =
					valorTerreno - (Number(monAhoMax || '0') + enganche);
				const mcTerreno = Math.max(
					valorUMATotal,
					Math.min(
						resultadoTerreno < 0 ? monTerreno : resultadoTerreno,
						monTerreno
					)
				);
				const mcConstruccion = montoTotal - mcTerreno;

				return {
					...product,
					enganche,
					ahorro: dataProcessController?.titular?.saldoSARTotal,
					mmcMonSuelo: Math.round(mcTerreno * 100) / 100,
					montoConstruccion: Math.round(mcConstruccion * 100) / 100,
				};
			}

			return {
				...product,
				enganche,
				ahorro: dataProcessController?.titular?.saldoSARTotal,
				mmcMonSuelo: 0,
				montoConstruccion: 0,
			};
		});

		if (
			dataProcessController.processInfo?.credit ===
			MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION
		) {
			return {
				code: code,
				message: message,
				products: newProducts.filter((prod) => prod.producto !== 'COF'),
			};
		}

		return {
			code: code,
			message: message,
			products: newProducts,
		};
	};

	return {
		getProducts,
		handleTrazabilidad,
		handleTrazaSuelo,
		plazos,
	};
};
