/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PersonalData from './personalData';
import Password from './password';
import ContactData from './contactData';
import Layout from '../../components/Layout';
import Tabs from '../../components/Tabs';
import Confirmation from './confirmation';
import { useStyles } from './styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import { Grid, Paper, Divider } from '@mui/material';
import { createAccountAPI } from '../../api/modules/createAccount';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { encrypt } from '../../utils/encrypt';
import { IAlert } from '../../interfaces/alert';
import { keyAPI } from '../../api/modules/key';
import { useTabs } from '../../hooks/useTabs';
import VerificationCode from '../../components/VerificationCode';
import ModalLoading from '../../components/ModalLoading';
import BaseModal from '../../components/Modal';
import VerificationEmail from './VirificationEmail';
import VerificationUser from './verificationUser';
import ConsultationWorkRelations from '../ConsultationWorkRelations';

interface IUserDataRegister {
	nss: string;
	curp: string;
	rfc: string;
	password: string;
	email: string;
	phone: string;
	byPoE: string;
	name: string;
}

interface IUserData {
	nombre: string;
	apPaterno: string;
	apMaterno: string;
	curp: string;
	rfc: string;
	fechaNacimiento: string;
	sexo: string;
}

const tabsFlag = [
	{
		id: 1,
		title: '1.- Datos personales',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: 2,
		title: '2.- Verifica tu nombre',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 1,
		orderSM: 2,
	},
	{
		id: 3,
		title: '3.- Datos de contácto',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 2,
		orderSM: 3,
	},
	{
		id: 4,
		title: '4.- Contraseña',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 3,
		orderSM: 4,
	},
	{
		id: 5,
		title: '5.- Activación de cuenta',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 4,
		orderSM: 5,
	},
	{
		id: 6,
		title: '6.- Confirmación',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 5,
		orderSM: 6,
	},
];

const Register = () => {
	const [userData, setUserData] = useState<IUserDataRegister>({
		nss: '',
		curp: '',
		rfc: '',
		password: '',
		email: '',
		phone: '',
		byPoE: '',
		name: '',
	});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [loading, setLoading] = useState(false);
	const [keyParam, setKeyParam] = useState('');
	const [isActivated, setIsActivated] = useState(false);
	const [modalConfirm, setModalConfirm] = useState(false);
	const [superError, setSuperError] = useState(false);
	const [isActivatedEmail, setIsActivatedEmail] = useState(false);
	const [noResend, setNoResend] = useState(false);
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.down('md'));
	const [modalNameIncorrect, setModalNameIncorrect] = useState(false);
	const [messageNameIncorrect] = useState('');
	const [dataUser, setDataUser] = useState<IUserData>({
		nombre: '',
		apPaterno: '',
		apMaterno: '',
		curp: '',
		rfc: '',
		fechaNacimiento: '',
		sexo: '',
	});
	const {
		containerPosition,
		tabActive,
		tabsEle,
		changeTabNext,
		changeTabPrevious,
		changeTabNextCustom,
	} = useTabs(tabsFlag, 1, 0);
	const classes = useStyles();
	const [messageVerification, setMessageVerification] = useState('');

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (params.verification && params.key) {
			setUserData({ ...userData, byPoE: 'byEmail' });
			changeTabNextCustom(4);
			handleValidateEmail(params.key || '');
			setNoResend(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFirstStep = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(userData.nss || '', key);
			const curp_base64 = await encrypt(userData.curp || '', key);
			const rfc_base64 = await encrypt(userData.rfc || '', key);
			const dataFlag = {
				nss: nss_base64,
				curp: curp_base64,
				rfc: rfc_base64,
			};
			createAccountAPI
				.createAccountFirstStep({ ...dataFlag })
				.then((res) => {
					if (res?.ok) {
						changeTabNext();
					} else {
						setAlert({
							show: true,
							message:
								INTERNAL_MESSAGES.SAVE_DATA,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					if (error.description) {
						setAlert({
							show: true,
							message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					} else {
						setAlert({
							show: true,
							message:
								INTERNAL_MESSAGES.SAVE_DATA,
							severity: 'error',
						});
					}
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.SAVE_DATA,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleSecondStep = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(userData.nss || '', key);
			const dataFlag = {
				nss: nss_base64,
			};
			createAccountAPI
				.createAccountSecondStep({ ...dataFlag })
				.then((res) => {
					if (res?.ok) {
						changeTabNext();
					} else {
						setAlert({
							show: true,
							message:
								INTERNAL_MESSAGES.SAVE_DATA,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					if (error?.description) {
						setAlert({
							show: true,
							message: error.description,
							severity: 'error',
						});
					} else {
						setAlert({
							show: true,
							message:
								INTERNAL_MESSAGES.SAVE_DATA,
							severity: 'error',
						});
					}
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.SAVE_DATA,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleThirdStep = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_base64 = await encrypt(userData.email, key);
			const phone_base64 = await encrypt(userData.phone, key);
			const nss_base64 = await encrypt(userData.nss, key);
			const byPoE_base64 = await encrypt(userData.byPoE, key);
			// const passwordParseado = userData.password
			//           .replaceAll('%', '25%')
			//           .replaceAll('^', '%5E')
			//           .replaceAll('&', '26%')
			//           .replaceAll('+', '%2B')
			// 		  .replaceAll('=', '%3D');

			const password_base64 = await encrypt(userData.password, key);
			const dataFlag = {
				email: email_base64,
				phone: phone_base64,
				nss: nss_base64,
				byPoE: byPoE_base64,
				password: password_base64,
			};
			createAccountAPI
				.createAccountThirdStep({ ...dataFlag })
				.then((res) => {
					if (res?.ok) {
						changeTabNext();
					} else {
						setAlert({
							show: true,
							message:
								INTERNAL_MESSAGES.SAVE_DATA,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					if (error) {
						if (error.codeM && error.codeM === 'MCI0005') {
							setNoResend(true);
						} else {
							setNoResend(false);
						}
						if (error.description) {
							setAlert({
								show: true,
								message: error.description ? error.description : INTERNAL_MESSAGES.SAVE_DATA,
								severity: 'error',
							});
						} else {
							setAlert({
								show: true,
								message:
									INTERNAL_MESSAGES.SAVE_DATA,
								severity: 'error',
							});
						}
					} else {
						setAlert({
							show: true,
							message:
								INTERNAL_MESSAGES.SAVE_DATA,
							severity: 'error',
						});
					}

				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.SAVE_DATA,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleValidateEmail = (key: string) => {
		setLoading(true);
		createAccountAPI
			.validateEmail(key)
			.then((response) => {
				if (response.ok) {
					setIsActivated(true);
					setKeyParam(key);
					if(!response.epv){
						changeTabNextCustom(5);
					}
				}
			})
			.catch((error) => {
				if (error?.codeM && error?.codeM === 'MCI0005') {
					setNoResend(true);
				}
				setLoading(false);
				setKeyParam(key);
				if (
					error?.description ===
					INTERNAL_MESSAGES.VALIDATE_MAIL
				) {
					handleValidateNSS(error.nss as string);
				} else
					setAlert({
						show: true,
						message:
							error?.description ||
							INTERNAL_MESSAGES.ERROR_OCCURRED,
						severity: 'error',
					});
			})
			.finally(() => setLoading(false));
	};

	const handleValidateNSS = async (nss: string) => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const code_base64 = await encrypt(nss || '', key);
			createAccountAPI
				.validateNSS(code_base64)
				.then(() => {
					setIsActivatedEmail(true);
					setIsActivated(true);
				})
				.catch((err) => {
					if (err?.description) {
						let errorFlag = err.description;
						if (
							errorFlag === 'Cuenta en proceso de Unificación' ||
							errorFlag ===
							'Cuenta con marca de fallecido en SACI'
						) {
							errorFlag = err.message;
						}
						setAlert({
							show: true,
							message:
								errorFlag || INTERNAL_MESSAGES.ERROR_OCCURRED,
							severity: 'error',
						});
						setSuperError(true);
					}
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
		}
	};

	const handleCreateAccount = async (code: string) => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const code_base64 = await encrypt(code, key);
			const nss_base64 = await encrypt(userData.nss, key);
			createAccountAPI
				.valideteCreation({
					code: code_base64,
					key: keyParam,
					nss: nss_base64,
				})
				.then((res) => {
					if (res?.ok) {
						changeTabNext();
					} else {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.WRONG_NUMBER,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					if (error?.description) {
						setAlert({
							show: true,
							message: error.description,
							severity: 'error',
						});
					} else if (error === INTERNAL_MESSAGES.STATUS_NSS) {
						changeTabNext();
					} else {
						setAlert({
							show: true,
							message:
								INTERNAL_MESSAGES.ERROR_VALIDATION,
							severity: 'error',
						});
					}
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_VALIDATION,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const setUserDataRegister = (name: string, value: string | boolean) => {
		setUserData({ ...userData, [name]: value });
	};

	const handleResendCodeSms = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(userData.nss, key);
			createAccountAPI
				.resendSMS(keyParam, nss_base64)
				.then((res) => {
					if (res?.ok) {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.FORWARDED_NUMBER,
							severity: 'success',
						});
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_VALIDATION,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleResendEmail = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(userData.nss, key);
			createAccountAPI
				.resendEmail(keyParam, nss_base64)
				.then((res) => {
					if (res?.ok) {
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.EMAIL_SENT,
							severity: 'success',
						});
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.EMAIL_NOT_SENT,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const handleContinueNSS = (value: number) => {
		if (value === 4) {
			setIsActivatedEmail(true);
		}
		if (value === 3) {
			return changeTabNextCustom(value + 1);
		}
		changeTabNextCustom(value);
	};

	const createCaso = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(userData.nss || '', key);
			const curp_base64 = await encrypt(
				userData.curp ? userData.curp : dataUser.curp,
				key
			);
			const rfc_base64 = await encrypt(
				userData.rfc ? userData.rfc : dataUser.rfc,
				key
			);
			createAccountAPI
				.createCaso(nss_base64, curp_base64, rfc_base64)
				.then((res: any) => {
					if (res?.description) {
						setMessageVerification(res.description);
					}
					setAlert({
						show: false,
						message: '',
						severity: 'success',
					});
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const setData = (data: IUserData) => {
		setDataUser(data);
	};

	return (
		<Layout onlyTitle title="Crear una cuenta">
			<Tabs
				tabs={tabsEle}
				cardsType
				containerPosition={containerPosition}
				active={tabActive}
			>
				<Paper>
					{tabActive === 0 && (
						<PersonalData
							onContinue={handleFirstStep}
							onContinueNSS={handleContinueNSS}
							onChange={setUserDataRegister}
							hasError={alert}
							setDataUser={setData}
							onCloseError={() => {
								setAlert({
									show: false,
									message: '',
									severity: alert.severity,
								});
							}}
						/>
					)}
					{tabActive === 1 && (
						<VerificationUser
							infoUser={userData.name}
							onContinue={handleSecondStep}
							goBack={() => {
								setAlert({
									show: false,
									message: '',
									severity: alert.severity,
								});
								changeTabPrevious();
							}}
							createCaso={createCaso}
							hasError={alert}
							dataUser={dataUser}
							messageVerification={messageVerification}
							onCloseError={() => {
								setAlert({
									show: false,
									message: '',
									severity: alert.severity,
								});
							}}
						/>
					)}
					{tabActive === 2 && (
						<ContactData
							onContinue={() => changeTabNext()}
							onChange={setUserDataRegister}
							hasError={alert}
							onCloseError={() => {
								setAlert({
									show: false,
									message: '',
									severity: alert.severity,
								});
							}}
						/>
					)}
					{tabActive === 3 && (
						<Password
							onContinue={handleThirdStep}
							onChange={setUserDataRegister}
							hasError={alert}
							onCloseError={() => {
								setAlert({
									show: false,
									message: '',
									severity: alert.severity,
								});
							}}
						/>
					)}
					{tabActive === 4 && (
						<Grid
							container
							className={classes.container}
							columns={12}
							spacing={0}
						>
							<Grid item xs={0} sm={0} md={3} lg={3} />
							<Grid item xs={12} sm={12} md={6} lg={6}>
								{!isActivatedEmail ? (
									<VerificationEmail
										hasTimer
										onResend={handleResendEmail}
										onContinue={() =>
											setIsActivatedEmail(true)
										}
										isActivated={isActivated}
										hasError={alert}
										superError={superError}
										noResend={noResend}
										onCloseError={() => {
											setAlert({
												show: false,
												message: '',
												severity: alert.severity,
											});
										}}
									/>
								) : (
									<VerificationCode
										isEmail={false}
										setCode={handleCreateAccount}
										reSend={handleResendCodeSms}
										hasError={alert}
										onCloseError={() => {
											setAlert({
												show: false,
												message: '',
												severity: alert.severity,
											});
										}}
										isSixDigits={true}
									/>
								)}
							</Grid>
							<Grid item xs={0} sm={0} md={3} lg={3} />
						</Grid>
					)}
					{tabActive === 5 && <Confirmation />}
					{(tabActive === 0 ||
						tabActive === 2 ||
						tabActive === 3) && (
							<div className={classes.containerRequiredFields}>
								<Divider />
								<p className={classes.requiredFieldsLabel}>
									* Datos obligatorios
								</p>
							</div>
						)}
					<ModalLoading loading={loading} />
					<BaseModal
						title="Confirmar"
						open={modalConfirm}
						width="sm"
						onClose={() => setModalConfirm(!modalConfirm)}
						onConfirm={() => null}
					>
						<p>¿Estás seguro de crear la cuenta?</p>
					</BaseModal>
					<BaseModal
						open={modalNameIncorrect}
						width={md ? 'md' : 'sm'}
						onClose={() => {
							setModalNameIncorrect(!modalNameIncorrect);
							handleSecondStep();
						}}
					>
						<div className={classes.containerModalInfo}>
							<p>{messageNameIncorrect}</p>
							<div className={classes.containerBtnAcceptModal}>
								<CustomButton
									data-testid="buttonAceptar"
									onClick={() => {
										setModalNameIncorrect(
											!modalNameIncorrect
										);
										handleSecondStep();
									}}
									variant="solid"
									styles={{ marginBottom: 20 }}
									label="Aceptar"
								/>
							</div>
						</div>
					</BaseModal>
				</Paper>
			</Tabs>
		</Layout>
	);
};

export default Register;
