import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import { useStyles } from '../styles';
import CustomButton from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import CollapseTable from '../components/CollapseTable';
import CustomAlert from '../../../components/CustomAlert';
import ModalLoading from '../../../components/ModalLoading';
import { apiFollowComplaint } from '../../../api/modules/followComplaint';
import { encrypt } from '../../../utils/encrypt';
import { RootState } from '../../../store';
import { columns } from './utils';
import { keyAPI } from '../../../api/modules/key';

const MyComplaints = ({ setSelectedType, setQueja }: any) => {
	const { user } = useSelector((state: RootState) => state.session);
	const [oComplaints, setOComplaints] = useState([]);
	const [complaints, setComplaints] = useState([]);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState('');
	const [query, setQuery] = useState('');
	const [page, setPage] = useState(0);
	const classes = useStyles();

	const fetchMyComplaints = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const data = {
				nss: user?.nss || '',
				estatus: 'A', // 'A' PARA MODO BUSQUEDA | 'B' PARA CONSULTA GENERAL
				claseOperacion: 'ZQUE', // CONSTANTE
			};
			const dataEncripted = await encrypt(JSON.stringify(data) || '', key);
			const result = await apiFollowComplaint.getMyComplaints(
				dataEncripted
			);
			if (result.code && result.code === '0000') {
				let compl = result.data.casos.sort((x: any, y: any) =>
					y.caso > x.caso ? -1 : y.caso < x.caso ? 1 : 0
				);
				setComplaints(compl);
				setOComplaints(compl);
			} else
				setAlert(
					result.message ||
					'Por el momento el servicio no se encuentra disponible, intente más tarde.'
				);
		} catch (error: any) {
			setAlert(
				error.message ||
				'Por el momento el servicio no se encuentra disponible, intente más tarde.'
			);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchMyComplaints();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSearch = async () => {
		let filteredC = oComplaints.filter((c: any) => c.caso === query);
		setComplaints(filteredC);
		setPage(0);
	};

	const handleClearSearch = () => {
		fetchMyComplaints();
		setPage(0);
		setQuery('');
	};

	const handlePage = (e: any, newPage: number) => setPage(newPage - 1);

    const attachDocument = (item: any) => {
        setQueja(item);
        setSelectedType(4);
    }

	return (
		<>
			<ModalLoading loading={loading} />
			<Paper className={classes.mainContainer}>
				<div style={{ maxWidth: 300 }}>
					<TextInput
						id="follow-complaint-my_complaints-search"
						name="search"
						value={query}
						label="Buscar por número de queja"
						onChange={({ target }) => setQuery(target.value)}
						type="search"
						handleSearch={handleSearch}
						handleClearSearch={handleClearSearch}
					/>
				</div>
				<CollapseTable
					data={complaints}
					columns={columns}
					page={page}
					handlePage={handlePage}
					attachDocument={attachDocument}
				/>
				<div className="actions-container">
					<CustomButton
						onClick={() => setSelectedType(0)}
						variant="outlined"
						label={'Salir'}
					/>
				</div>
				{Boolean(alert) && (
					<CustomAlert message={alert} severity="error" show />
				)}
			</Paper>
		</>
	);
};

export default MyComplaints;
