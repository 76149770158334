import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('105', 'actualizar-datos-contacto');

export const ENDPOINTS_ACTUALIZACION_DATOS_CONTACTO: IBitacora = {
	'/contact-info': _bitacora.Read('105100', 'Consultar datos'),
	'/address-info': _bitacora.Read('105101', 'Consultar domicilio'),
	'/address-catalog': _bitacora.Read('105102', 'Consultar direccion'),
	'/update-address': _bitacora.Update('105103', 'Actualizar domicilio'),
	'/reference-info': _bitacora.Read('105104', 'Consultar referencia'),
	'/update-reference-info': _bitacora.Update(
		'105105',
		'Actualiza referencia'
	),
	'/validation-email': _bitacora.Read('105106', 'Validacion de correo'),
	'/update-email': _bitacora.Update('105107', 'Actualizacion de correo'),
	'/update-alternate-email': _bitacora.Update(
		'105108',
		'Actualizacion de correo alterno'
	),
	'registro/validate-phonenumber': _bitacora.Read(
		'105109',
		'Validacion de registro de telefono'
	),
	'/validation-mobile': _bitacora.Read('105110', 'Validacion de telefono'),
	'/update-phoneNumber': _bitacora.Update(
		'105111',
		'Envio de codigo por SMS'
	),
	'/confirm-phoneNumber-code': _bitacora.Read(
		'105112',
		'Confirmacion de codigo por SMS'
	),
};
