//DEPENDENCIAS
import { useNavigate } from 'react-router-dom';
import { RoundBtn } from '../../components/RoundBtn/roundBtn';
import { Box, Divider, Link } from '@mui/material';
import { useStyles } from './styles';
import iconoPDF from '../../../../assets/img/icono-descarga.png';
import FollowStepsCards from './followStepsCards/followStepsCard';
import { useController } from '../../hooks/useController';
import {
	centeredItems,
	useGeneralStyles,
} from '../../views/styles/general.styles';
import { CREDIT_TYPES, MIUC_PRODUCTS } from '../../constants/products';
interface IFollowSteps {
	guideLink?: string;
	creditLink?: string;
	imprimir?: boolean;
}

const FollowSteps = (props: IFollowSteps) => {
	const { guideLink, creditLink, imprimir } = props;
	const classes = useStyles();
	const navigate = useNavigate();
	const { returnStep, dataProcessController } = useController();
	const generalStyle = useGeneralStyles();

	const showFlexiPago = [
		// Producto
		CREDIT_TYPES.CREDITO_INFONAVIT,
		CREDIT_TYPES.INFONAVIT_TOTAL,
		CREDIT_TYPES.COFINAVIT,
		CREDIT_TYPES.COFINAVIT_INGRESOS_ADICIONALES,
		CREDIT_TYPES.SEGUNDO_CREDITO,
		// Linea
		MIUC_PRODUCTS.CREDITERRENO,
		MIUC_PRODUCTS.CICB,
	].includes(
		dataProcessController?.processInfo?.productSelect?.producto ||
			dataProcessController?.processInfo?.credit
	);

	return (
		<>
			<Box className={classes.mainContainer}>
				<div className={classes.header}>
					<span className={classes.headerTitulo}>
						Pasos a seguir para solicitar tu crédito
					</span>
					<span className={classes.headerSubtitulo}>
						Ya elegiste tu crédito, revisa los pasos para
						solicitarlo y recuerda que en el Infonavit todos los
						trámites son gratuitos
					</span>
				</div>
			</Box>

			<FollowStepsCards />

			<div className={classes.downloadButtons}>
				{creditLink && (
					<Link
						underline="hover"
						className={classes.downloadPDF}
						href={creditLink}
						target="_blank"
					>
						Características del Crédito
						<img
							src={iconoPDF}
							alt="iconoPDF"
							className={classes.icoDescarga}
						/>
					</Link>
				)}
				{guideLink && (
					<Link
						underline="hover"
						className={classes.downloadPDF}
						href={guideLink}
						target="_blank"
					>
						Guía de pasos a seguir
						<img
							src={iconoPDF}
							alt="iconoPDF"
							className={classes.icoDescarga}
						/>
					</Link>
				)}
				{showFlexiPago && (
					<Link
						underline="hover"
						className={classes.downloadPDF}
						href={require('../../../../assets/files/flyer_flexipago.pdf')}
						target="_blank"
					>
						Flyer Flexipago
						<img
							src={iconoPDF}
							alt="iconoPDF"
							className={classes.icoDescarga}
						/>
					</Link>
				)}
				{imprimir && (
					<Link
						underline="hover"
						className={classes.downloadPDF}
						onClick={() => {
							window.print();
						}}
					>
						Imprimir
						<img
							src={iconoPDF}
							alt="iconoPDF"
							className={classes.icoDescarga}
						/>
					</Link>
				)}
			</div>
			<div style={centeredItems}>
				<Divider
					flexItem
					orientation="horizontal"
					className={classes.division}
				/>
			</div>
			<div className={generalStyle.bottonsContainer}>
				<RoundBtn variant="outlined" onClick={() => returnStep()}>
					Regresar
				</RoundBtn>
				<RoundBtn variant="contained" onClick={() => navigate('/')}>
					Finalizar
				</RoundBtn>
			</div>
		</>
	);
};

export default FollowSteps;
