import { Divider, Link, useMediaQuery } from '@mui/material';
import theme from '../../../../config/theme';
import Modal from '../../../../components/Modal';
import CustomButton from '../../../../components/Button';
import { CheckCircleOutlined } from '@mui/icons-material';
import styles from './styles.module.css';

interface IProps {
	show: boolean;
	setShow: (arg: boolean) => void;
	nextProcess: () => void;
}

const stylesCssUrl: React.CSSProperties = {
	margin: '0px',
	padding: '0px 0px 0px 15px',
};

const stylesCheck: React.CSSProperties = {
	margin: '0px',
	fontSize: 15,
	color: '#293990',
	marginTop: '3px',
	marginRight: '4px',
};

const ModalSueloConstruccion = ({ show, setShow, nextProcess }: IProps) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Modal
			onClose={() => setShow(false)}
			open={show}
			width={'md'}
			children={
				<div
					style={{
						textAlign: 'left',
						fontSize: 15,
						margin: '0px 15px',
					}}
				>
					<h2
						style={{
							color: '#293990',
							margin: '0px 0px 0px 0px',
						}}
					>
						Crediterreno Para Mi Hogar
					</h2>

					<Divider style={{ margin: '20px 0px' }} />

					<p>
						En esta opción, con un solo crédito puedes comprar suelo
						(terreno) y contar con recursos para construir tu
						vivienda.
					</p>

					<p style={{ fontWeight: 500 }}>Solo considera:</p>

					<div className={styles.sectionInfo}>
						<div>
							<CheckCircleOutlined style={stylesCheck} />
						</div>
						<div>
							El suelo debe ser de uso habitacional o mixto que
							incluya uso habitacional y debe ser propiedad
							privada.
						</div>
					</div>
					<div className={styles.sectionInfo}>
						<div>
							<CheckCircleOutlined style={stylesCheck} />
						</div>
						<div>
							Debes cumplir con las leyes, normas y reglamentos
							aplicables a la construcción del municipio donde
							comprarás tu terreno y construirás tu vivienda, por
							lo que te sugerimos acercarte a sus oficinas.
						</div>
					</div>
					<div className={styles.sectionInfo}>
						<div>
							<CheckCircleOutlined style={stylesCheck} />
						</div>
						<div>
							Del monto máximo de crédito que se te otorgue, el
							35% del crédito más el Saldo de tu Subcuenta de
							Vivienda, lo puedes destinar para comprar tu
							terreno.
						</div>
					</div>
					<div className={styles.sectionInfo}>
						<div>
							<CheckCircleOutlined style={stylesCheck} />
						</div>
						<div>
							El monto de crédito otorgado se te entregará en
							cuatro partes o ministraciones, la primera se asigna
							a la compra del terreno y el resto para la
							construcción.
						</div>
					</div>
					<div className={styles.sectionInfo}>
						<div>
							<CheckCircleOutlined style={stylesCheck} />
						</div>
						<div>
							En caso de que el valor del terreno sea menor al 35%
							del monto de crédito otorgado la cantidad del
							porcentaje que no se ocupe, lo podrás usar para la
							construcción.
						</div>
					</div>

					<p style={{ fontWeight: 500 }}>Deberás elegir:</p>

					<div className={styles.sectionInfo}>
						<div>
							<CheckCircleOutlined style={stylesCheck} />
						</div>
						<div>
							Un{' '}
							<Link
								target="_blank"
								href="https://portalmx.infonavit.org.mx/wps/portal/infonavit.web/proveedores-externos/para-tu-gestion/unidades-valuacion/consulta_unidades_valuacion/!ut/p/z1/pZDLCsIwEEU_KbeJDnGZSE3rM4WG1mykKwlodSF-v6G7KrYV727gnJnhMs9q5tvmGc7NI9za5hLno6cTGSBbzpI9dlKjEHwzt8oiKwWr-oBZg6M46NI6rgURMf-Hb8uJPpeA0Yi-zCTUKs3TxVZw45JpPr5E4cf_PwE_vL5ivn_irUFLIwCNAF2HHTBU0tib96uLqRHyoF5Xp7Gw/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
							>
								valuador del padrón del Infonavit
							</Link>
							, quien es el encargado de realizar el estudio de
							valor, el cual contiene el avalúo del terreno y el
							estimado del valor de construcción. Este estudio de
							valor lo debes solicitar, pagar y presentar para
							tramitar tu crédito.
						</div>
					</div>

					<div className={styles.sectionInfo}>
						<div>
							<CheckCircleOutlined style={stylesCheck} />
						</div>
						<div>
							Un profesional de la construcción del padrón del
							Infonavit*, quien será el encargado de realizar:
							<ul style={stylesCssUrl}>
								<li>
									El <b>Ante Proyecto</b>, requisito para la
									inscripción de tu crédito.
								</li>
								<li>
									El <b>Proyecto Ejecutivo</b>, que deberás
									entregar al iniciar la construcción de tu
									vivienda para solicitar la Ministración dos
									y considera que este pago corre por tu
									cuenta.
								</li>
							</ul>
						</div>
					</div>

					<div className={styles.sectionInfo}>
						<div>
							<CheckCircleOutlined style={stylesCheck} />
						</div>
						<div>
							Al término de la construcción de tu vivienda deberás
							entregar al Infonavit los documentos de Aviso de
							Terminación de obra y el avalúo, considera que el
							pago de dichos documentos corren a tu cargo.
						</div>
					</div>

					<p>
						*En el Centro de Servicio Infonavit podrás consultarlo.
					</p>

					<Divider style={{ margin: '20px 0px' }} />

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: matches
								? 'column-reverse'
								: 'inherit',
						}}
					>
						<div
							style={{
								width: matches ? '100%' : 180,
								display: matches ? 'flex' : 'inline-flex',
								margin: '8px 10px',
							}}
						>
							<CustomButton
								label="Regresar"
								variant="outlined"
								onClick={() => setShow(false)}
							/>
						</div>
						<div
							style={{
								width: matches ? '100%' : 180,
								display: matches ? 'flex' : 'inline-flex',
								margin: '8px 10px',
							}}
						>
							<CustomButton
								label="Continuar"
								variant="solid"
								onClick={nextProcess}
							/>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default ModalSueloConstruccion;
