// DEPENDENCIES
import React, { useCallback, useEffect, useState } from 'react'
// COMPONENTS
import SelectInput from '../../../../components/SelectInput'
import TextInput from '../../../../components/TextInput'
import { RoundBtn } from '../../../AssociateNssCredit/Styles'
// RESOURCES
import { sharedResponsabilityAPI } from '../../../../api/modules/sharedResponsability'
import { isNumberValidate } from '../../../../utils/expressions'

const Location = ({ step, setStep, selectedCredit, setSelectedCredit }: any) => {
  const [stateOptions, setStateOptions] = useState([])

  const fetchStates = useCallback(async () => {
    const { result } = await sharedResponsabilityAPI.statesCatalog()
    if (result && result.length > 0)
    setStateOptions(result.map(({ codigo, descripcion }: any) => ({ value: codigo, text: descripcion })))
  }, [])

  useEffect(() => {
    fetchStates()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = ({ target }: any) => {
    const { name, value } = target
    setSelectedCredit({ ...selectedCredit, [name]: value })
  }

  return (
    <>
      <h5>Datos donde obtuviste tu crédito:</h5>
      <div className='location-form'>
        <SelectInput id='responsability-state' name='estado' value={selectedCredit?.estado || ''} label="Estado" options={stateOptions} onChange={handleChange} />
        <TextInput id='responsability-zipcode' name='cp' value={selectedCredit?.cp || ''} label="Código postal" onChange={(e: any) => isNumberValidate(e.target.value) && e.target.value.length <= 5 && handleChange(e)} />
      </div>
      <div className='actions-buttons'>
        <RoundBtn disabled={!selectedCredit?.estado || !selectedCredit?.cp || selectedCredit?.cp?.length !== 5} variant='contained' onClick={() => setStep(step +1)}>Continuar</RoundBtn>
      </div>
    </>
  )
}

export default Location