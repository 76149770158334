import API from '..';

export const ApiRfcCorrectionRegister = {
	getStatus: new API('/cambio-rfc-registro/verify-status'),
	consultaDbsat: new API('/cambio-rfc-registro/consulta-dbsat'),
	firmaElectronica: new API('/cambio-rfc-registro/firma-electronica'),
	consultaCFDI: new API('/cambio-rfc-registro/cfdi'),
	actualizaDbsat: new API('/cambio-rfc-registro/actualiza-dbsat'),
	createCase: new API('/cambio-rfc-registro/haz-cita/caso'),
	attachDocuments: new API('/cambio-rfc-registro/haz-cita/attach-documents'),
};
