import { Dialog, DialogContent, Box } from '@mui/material';
import CustomButton from '../../../components/Button';

interface Props {
	open: boolean;
	noQueja: string;
	setClose: () => void;
}

const ModalFinish = ({ open, setClose, noQueja }: Props) => {
	return (
		<Dialog maxWidth={'sm'} fullWidth open={open}>
			<DialogContent>
				<Box
					width={'100%'}
					height={'100%'}
					minHeight={100}
					color="#000"
					position={'relative'}
				>
					<h2 style={{ color: '#DD0528', textAlign: 'center' }}>
						¡Aviso!
					</h2>
					<p>
						Número de caso: <b>{noQueja}</b>
					</p>
					<p>
						Se han enviado tus documentos; si éstos están
						incompletos o ilegibles te lo notificaremos por medio
						del correo electrónico que tenemos registrado.
					</p>

					<div style={{ margin: '20px auto', width: 200, }}>
						<CustomButton label={'Cerrar'} onClick={setClose} />
					</div>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default ModalFinish;
