/* eslint-disable jsx-a11y/anchor-is-valid */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';
import CustomButton from '../../../components/Button';
import { HeaderList } from '../../../components/HeaderList';
import checkicon from '../../../assets/img/mini-check-azul.png';
import { IAlert } from '../../../interfaces/alert';
import CustomAlert from '../../../components/CustomAlert';
import MessageCustom from '../../../components/MessageCustom';
import { useNavigate } from 'react-router-dom';

interface ListToHave {
	id: number;
	icon: string;
	title: string;
}

const LISTTODO: ListToHave[] = [
	{
		id: 1,
		icon: checkicon,
		title: 'Nombre completo del derechohabiente',
	},
	{
		id: 2,
		icon: checkicon,
		title: 'Número de Crédito',
	},
	{
		id: 3,
		icon: checkicon,
		title: 'Número de Seguridad Social (opcional)',
	},
	{
		id: 4,
		icon: checkicon,
		title: 'Identificación oficial',
	},
	{
		id: 5,
		icon: checkicon,
		title: 'Correo electrónico',
	},
	{
		id: 6,
		icon: checkicon,
		title: 'Datos de contacto',
	},
];

interface Props {
	setPage: (data: number) => void;
	caso: boolean;
	hasError: IAlert;
}

export const ImportantAspects = ({ setPage, caso, hasError }: Props) => {
	const classes = useStyles();
	const navigate = useNavigate();

	// const handleClick = () => {
	// 	if (caso === true) {
	// 		setPage(6);
	// 	} else {
	// 		setPage(1);
	// 	}
	// };

	return (
		<div>
			<HeaderList
				title="Carta de instrucción de cancelación de hipoteca"
				date="Información al 12 julio 2021"
				list={[]}
			/>
			<Paper className={classes.divPrincipal}>
				<div
					style={{
						display: 'block',
						textAlign: 'center',
						color: '#293990',
						fontSize: '26px',
					}}
				>
					<h3>
						Gracias por utilizar los servicios digitales del
						Infonavit
					</h3>
				</div>
				<p className={classes.txt}>
					Gracias por llevar a cabo la cancelación de tu hipoteca de
					manera gratuita, por favor comunicate con el Gerente
					Jurídico de tu Delegación para entregar la copia de la
					escritura con datos de registro, en donde requerirán los
					siguientes datos:
				</p>

				{LISTTODO.map((sm) => (
					<Grid
						item
						xs={12}
						lg={12}
						className={classes.divChecks}
						key={sm.id}
					>
						<img
							className={classes.iconsImg}
							alt="checkicon"
							src={sm.icon}
						></img>
						<span className={classes.txtPrincipales}>
							{sm.title}
						</span>
						<br></br>
					</Grid>
				))}
				<p className={classes.txt}>
					Si quieres solicitar la entrega de escrituras, ingresa al servicio <a href="#" style={{ color: '#DD0528' }}>Solicitud de escrituras</a>
				</p>
				<br></br>
				<CustomAlert
					show={hasError.show}
					severity={hasError.severity}
					message={''}
				>
					<MessageCustom
						msg={hasError.message.toString()}
					></MessageCustom>
				</CustomAlert>
				<br></br>
				<CustomButton
					// onClick={handleClick}
					onClick={() => navigate('/')}
					variant="solid"
					styles={{ marginTop: 70, width: 200, margin: '0 auto' }}
					disabled={hasError.show}
					label="Salir"
				/>
			</Paper>
		</div>
	);
};

export default ImportantAspects;
