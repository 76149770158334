import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		stepPaper: {
			width: '100%',
			marginTop: '15px',
		},
		stepPaperBlue: {
			width: '100%',
			marginTop: '15px',
			backgroundColor: '#EEF4FA !important',
			border: '1px solid #293990',
		},
		blueTitle: {
			margin: '2px',
			marginTop: '5px',
			fontSize: 17,
			fontWeight: 'bold',
			color: '#293990',
		},
		blueDescription: {
			margin: '2px',
			fontSize: 13,
			color: '#293990',
		},
		paperLayout: {
			padding: '5px',
			paddingRight: '30px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'start',
		},
		icoTemas: {
			width: '65px',
			margin: '20px',
		},
		stepText: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
			textAlign: 'left',
			justifyContent: 'start',
			minHeight: '105px',
			maxWidth: '80%',
		},
		title: {
			margin: '2px',
			marginTop: '5px',
			fontSize: 17,
			fontWeight: 'bold',
		},
		description: {
			margin: '2px',
			fontSize: 15,
		},
		enlaces: {
			display: 'flex',
			flexDirection: 'row',
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				alignItems: 'start',
			},
			marginBottom: '10px',
			alignItems: 'center',
			width: '100%',
		},
		division: {
			marginTop: '5px !important',
			marginBottom: '10px !important',
			marginLeft: '30px !important',
			width: '90%',
			[theme.breakpoints.down('md')]: {
				marginLeft: '10px !important',
				width: '95%',
			},
		},
		viewMore: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
			padding: '5px',
			maxWidth: '80%',
		},
		viewMoreButton: {
			background: 'none',
			border: 'none',
			padding: 0,
			font: 'inherit',
			cursor: 'pointer',
			outline: 'none',
			display: 'flex',
			alignItems: 'center',
			marginRight: '10px !important',
			fontSize: 13,
			fontWeight: 'bold',
			color: '#D1001F',
		},
		viewLessButton: {
			background: 'none',
			border: 'none',
			padding: 0,
			font: 'inherit',
			cursor: 'pointer',
			outline: 'none',
			display: 'flex',
			alignItems: 'center',
			minHeight: '100%',
			marginRight: '10px !important',
			marginLeft: 100,
			fontSize: 13,
			fontWeight: 'bold',
			color: '#D1001F',
		},
		icoView: {
			marginLeft: 5,
			maxWidth: 20,
		},
		mainContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
		},
		header: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
		},
		headerTitulo: {
			fontSize: '24px',
			fontWeight: 'bold',
			color: '#293990',
			marginBottom: '5px',
		},
		headerSubtitulo: {
			fontSize: 13,
		},
		downloadPDF: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			margin: '20px !important',
			[theme.breakpoints.down('md')]: {
				margin: '5px !important',
			},
		},
		downloadButtons: {
			display: 'flex',
			flexDirection: 'row',
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				margin: '10px',
			},
			alignItems: 'center',
			justifyContent: 'center',
		},
		icoDescarga: {
			marginLeft: 5,
			maxWidth: 18,
		},
	})
);
