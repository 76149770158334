import React from 'react';
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import { useStyles } from './styles';

export interface IRadioButton {
	id?: string;
	name: string;
	label?: string | React.ReactChild;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value: string | number | '';
	options: any;
	row?: boolean;
	required?: boolean;
}

const RadioButtonInput = ({
	label = '',
	name = '',
	value = '',
	onChange,
	options = [],
	row = false,
	required = false,
}: IRadioButton) => {
	const classes = useStyles();
	return (
		<FormControl>
			<FormLabel>
				<span className={classes.labelContainerInput}>
					{label}
					{required && <span className={classes.labelRequired}>*</span>}
				</span>
			</FormLabel>
			<RadioGroup
				row={row} // DIRECTION ROW (HORIZONTAL)
				name={name}
				value={value}
				onChange={onChange}
				className={classes.optionsContainer}
			>
				{options.map((option: any) => (
					<FormControlLabel
						key={option.value}
						control={<Radio />}
						label={option.label}
						value={option.value}
						disabled={option.diabled}
						labelPlacement={option.labelPos}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};

export default RadioButtonInput;
