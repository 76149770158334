export interface IProductList {
	[key: string]: string | number | JSX.Element | JSX.Element[];
}

export enum formatTypesRow {
	MONEY = 'MONEY',
	PERCENTAGE = 'PERCENTAGE',
	YEAR = 'YEAR',
	MONTH = 'MONTH',
}

export interface IFormatRow {
	type: formatTypesRow;
	prefix?: string | JSX.Element;
}

export interface IKeyRow {
	key: string;
	title: string | JSX.Element | JSX.Element[];
	textPrimary?: boolean;
	textShadow?: boolean;
	hideRow?: boolean;
	format?: IFormatRow;
}

export interface IPropsProductsMiuc {
	keys: IKeyRow[];
	products: IProductList[];
	keyHeader: string;
	headerText?: string | JSX.Element | JSX.Element[];
	textFooter?: string | JSX.Element | JSX.Element[];
	selectProduct?: string;
	setSelectProduct?: (arg: string) => void;
	getTablaComparativa?: (arg: any) => void;
	options?: JSX.Element[];
	maxWidthCard?: number;
	rememberHiddenProducts?: boolean;
	refresh?: boolean;
}
