/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useGeneralStyles } from '../../views/styles/general.styles';
import { useController } from '../../hooks/useController';
import { MIUC_PROCESS_VIEWS } from '../../constants/process';
import ProofOfBalance from './views/ProofOfBalance/ProofOfBalance';
import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import Layout from '../../../../components/Layout';
import FollowSteps from '../../components/FollowSteps';
import theme from '../../../../config/theme';

const CICB = () => {
	const generalStyle = useGeneralStyles();
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const { getStepView, dataProcessController } = useController();
	const [view, setView] = useState(getStepView);

	useEffect(() => {
		navigation();
	}, [dataProcessController]);

	const navigation = () => {
		setView(getStepView());
	};

	const layoutSize =
		view === MIUC_PROCESS_VIEWS.SUMMARY_REQUEST && !matches
			? '70%'
			: '100%';

	return (
		<Layout>
			<Box sx={{ width: layoutSize }} className={generalStyle.container}>
				{view === MIUC_PROCESS_VIEWS.CICB ? (
					<Paper>
						<div className={generalStyle.content}>
							<ProofOfBalance />
						</div>
					</Paper>
				) : view === MIUC_PROCESS_VIEWS.SUMMARY_REQUEST ? (
					<Grid>
						<Grid item xs={12} md={8}>
							<FollowSteps
								guideLink={require('../../../../assets/files/guia-pasos-a-seguir/Guia_pasos_a_seguir_CICB.pdf')}
								creditLink={require('../../../../assets/files/guia-pasos-a-seguir/Guia_producto_CICB.pdf')}
							/>
						</Grid>
					</Grid>
				) : (
					<></>
				)}
			</Box>
		</Layout>
	);
};

export default CICB;
