import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Paper, Box, Grid, Divider, Link } from '@mui/material';
import ModalHowPoints from './ModalHowPoints';
import { formatMoney } from '../../../../utils/validators';
import iconoPesos from '../../../../assets/img/icono-pesos.png';
import { useStyles, centeredItems } from './styles';
import { useController } from '../../hooks/useController';
import { RootState } from '../../../../store';
import { useProfile } from '../../hooks/useProfile';

interface IProps {
	showPoints?: boolean;
}

const ViewPoints = (props: IProps) => {
	const { showPoints } = props;
	const [showModal, setShowModal] = useState(false);
	const classes = useStyles();
	const { dataProcessController } = useController();
	const { saldo } = useSelector((state: RootState) => state.saldo);
	const { saldoSARTotal } = useProfile({
		initProcess: false,
		initSaldo: true,
	});

	return (
		<>
			<Paper elevation={0} variant="outlined">
				<Box padding={3} className={classes.centeredColumn}>
					<p className={classes.titulo}>
						{'Tienes ahorrado en tu Subcuenta de Vivienda:'}
					</p>

					<Grid container>
						<Grid item xs={12} md>
							<h2 style={centeredItems}>
								<img
									src={iconoPesos}
									alt="Icono Pesos"
									className={classes.iconoPesos}
								/>
								<span className={classes.ahorroSub}>
									{formatMoney(
										Number(
											dataProcessController?.titular
												?.saldoSARTotal ||
												saldo ||
												saldoSARTotal ||
												0
										)
									)}
									{' MXN'}
								</span>
							</h2>
						</Grid>
					</Grid>

					{showPoints !== false && (
						<>
							<ModalHowPoints
								show={showModal}
								setShow={setShowModal}
							/>

							<Divider
								flexItem
								orientation="horizontal"
								className={classes.bMargin}
							/>

							<p className={classes.titulo}>
								Mis puntos Infonavit
							</p>

							<Grid container columns={12}>
								<Grid
									item
									xs={12}
									md
									className={classes.centeredColumn}
								>
									<p className={classes.centeredColumn}>
										Acumulados <br />
										<span className={classes.pAcumulados}>
											{Number(
												dataProcessController.titular
													.puntosTotales || 0
											)}
										</span>
									</p>
								</Grid>
							</Grid>

							<Box>
								<Link
									underline="hover"
									onClick={() => setShowModal(true)}
									component="button"
								>
									¿Cómo se calculó mi puntuación?
								</Link>
							</Box>
						</>
					)}
				</Box>
			</Paper>
		</>
	);
};

export default ViewPoints;
