import { Divider } from '@mui/material';
import { blue } from '@mui/material/colors';
import React from 'react';
import BaseModal from '../../../../components/Modal';

interface IAcceptProrogationModal {
	open: boolean;
	setOpen: any;
	handleAccept: () => void;
}

const AcceptProrogationModal = ({
	open,
	setOpen,
	handleAccept,
}: IAcceptProrogationModal) => {
	return (
		<BaseModal
			open={open}
			onClose={() => setOpen('')}
			onConfirm={handleAccept}
			confirmLabel="Si"
			cancelLabel="No"
			cancelButton
			width="sm"
		>
			<>
				<h3 style={{ color: blue[900], textAlign: 'center' }}>
					¿Estás seguro de que aceptas las condiciones de la prórroga?
				</h3>
				<Divider />
			</>
		</BaseModal>
	);
};

export default AcceptProrogationModal;
