/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import Tabs from '../../components/Tabs';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import jwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Select, MenuItem, FormControl, Link } from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';
import { encrypt } from '../../utils/encrypt';
import { IAlert } from '../../interfaces/alert';
import { keyAPI } from '../../api/modules/key';
import { impersonalizacionAPI } from '../../api/modules/Impersonalizacion';
import { useTabs } from '../../hooks/useTabs';
import { nssValid, isNumber } from '../../utils/expressions';
// Components
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';
// Resources
import { actions } from '../../store/modules/session';
import { actions as creditsAction } from '../../store/modules/credit';
import { isEmpty } from '../../utils/validators';

const tabsFlag = [
	{
		id: 1,
		title: '1.- Login Helpdesk',
		disabled: false,
		checked: false,
		columns: 6,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: 2,
		title: '2.- Mi Cuenta Infonavit 3.0',
		disabled: false,
		checked: false,
		columns: 6,
		orderMD: 1,
		orderSM: 2,
	},
];
interface ICredentials {
	id: string;
	password: string;
}
interface ISearch {
	id: string;
	word: string;
	group: string;
	search: string;
	nss: string;
}
interface IDataSearch {
	canalActivacion: string;
	canalRegistro: string;
	celular: number;
	celularVerificado: boolean;
	correoAternativo: string;
	correoVerificado: boolean;
	email: string;
	estatus: string;
	fechaCreacion: string;
	grupo: string;
	medioActivacion: string;
	nombreCompleto: string;
	nss: number;
	tipoCuenta: number;
	ultimoAcceso: string;
	ultimoIngreso: string;
}

const Impersonalizacion = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [credentials, setCredentials] = useState<ICredentials>({
		id: '',
		password: '',
	});
	const [search, setSearch] = useState<ISearch>({
		id: '',
		word: '',
		group: '',
		search: '',
		nss: '',
	});
	const [errorNSS, setErrorNSS] = useState({ valid: false, msg: '' });
	const [searchHelp, setSearchHelp] = useState<ISearch>({
		id: '',
		word: '',
		group: '',
		search: '',
		nss: '',
	});
	const [errorNSSHelp, setErrorNSSHelp] = useState({ valid: false, msg: '' });
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [showDataSearch, setShowDataSearch] = useState(false);
	const { containerPosition, tabActive, tabsEle, changeTabCustom } = useTabs(
		tabsFlag,
		0,
		0
	);
	const classes = useStyles();
	const [dataSearch, setDataSearch] = useState<IDataSearch>({
		canalActivacion: '',
		canalRegistro: '',
		celular: 0,
		celularVerificado: false,
		correoAternativo: '',
		correoVerificado: false,
		email: '',
		estatus: '',
		fechaCreacion: '',
		grupo: '',
		medioActivacion: '',
		nombreCompleto: '',
		nss: 0,
		tipoCuenta: 0,
		ultimoAcceso: '',
		ultimoIngreso: '',
	});

	useEffect(() => {
		const token: any = localStorage.getItem('tokenHelpDesk');
		if (token) {
			const user = jwt(token) as any;
			setSearch({
				id: user.id,
				word: user.word,
				group: '',
				search: '',
				nss: '',
			});
			setSearchHelp({
				id: user.id,
				word: user.word,
				group: '',
				search: '',
				nss: '',
			});
			changeTabCustom(1);
		}
	}, []);

	useEffect(() => {
		setAlert({
			show: false,
			message: '',
			severity: 'error',
		});
	}, [page]);

	const setDataCredentials = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		setCredentials({ ...credentials, [name]: value });
	};

	const handleLogin = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const user_base64 = await encrypt(credentials.id.toString() || '', key);
			const pass_base64 = await encrypt(credentials.password.toString() || '', key);
			impersonalizacionAPI.login
				.post<{ usuario: string; password: string }, any>('', {
					usuario: user_base64,
					password: pass_base64,
				})
				.then((res: any) => {
					if (res.result.login) {
						if (!isEmpty(res.result.token)) {
							const user = jwt(res.result.token) as any;
							if (user.id && user.word) {
								setSearch({
									id: user.id,
									word: user.word,
									group: '',
									search: '',
									nss: '',
								});
								setSearchHelp({
									id: user.id,
									word: user.word,
									group: '',
									search: '',
									nss: '',
								});
								changeTabCustom(1);
								localStorage.setItem('tokenHelpDesk', res.result.token);
								setAlert({
									show: false,
									message: '',
									severity: 'error',
								});
							} else {
								setAlert({
									show: true,
									message: user.description
										? user.description
										: 'Error al obtener la información',
									severity: 'error',
								});
							}
						} else {
							setAlert({
								show: true,
								message: 'Error al obtener la información',
								severity: 'error',
							});
						}
					}
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						message:
							error && error.description
								? error.description
								: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error: any) {
			setAlert({
				show: true,
				message:
					error && error.description
						? error.description
						: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const setData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		let validate = null;
		if (name === 'nss') {
			if (!isNumber(value)) return;
			if (value.length > 11) return;
			validate = nssValid(value);
			setErrorNSS(validate);
		}
		setSearch({ ...search, [name]: value });
	};

	const handleChange = async (event: any) => {
		const { name, value } = event.target;
		if (name === 'search') {
			setSearch({
				id: search.id,
				word: search.word,
				group: search.group,
				search: value,
				nss: '',
			});
			setErrorNSS({ valid: false, msg: '' });
		} else {
			setSearch({ ...search, [name]: value });
		}
	};

	const handleSearch = async () => {
		setLoading(true);
		setShowDataSearch(false);
		setDataSearch({
			canalActivacion: '',
			canalRegistro: '',
			celular: 0,
			celularVerificado: false,
			correoAternativo: '',
			correoVerificado: false,
			email: '',
			estatus: '',
			fechaCreacion: '',
			grupo: '',
			medioActivacion: '',
			nombreCompleto: '',
			nss: 0,
			tipoCuenta: 0,
			ultimoAcceso: '',
			ultimoIngreso: '',
		});
		setAlert({
			show: false,
			message: '',
			severity: 'error',
		});
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(search.nss.toString() || '', key);
			impersonalizacionAPI.search
				.post<{ id: string; word: string; nss: string }, any>('', {
					id: search.id,
					word: search.word,
					nss: nss_base64,
				})
				.then((res: any) => {
					if (res?.result?.detalle && res?.result?.detalle?.length > 0) {
						setDataSearch(res.result.detalle[0]);
						setShowDataSearch(true);
					} else {
						setAlert({
							show: true,
							message:
								res.result && res.result.description
									? res.result.description
									: 'Por el momento el servicio no está disponible. Intenta más tarde',
							severity: 'error',
						});
					}
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						message:
							error && error.description ? error.description : error.result && error.result.description ? error.result.description : 'Por el momento el servicio no está disponible. Intenta más tarde',
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error: any) {
			setAlert({
				show: true,
				message: error && error.description ? error.description : error.result && error.result.description ? error.result.description : 'Por el momento el servicio no está disponible. Intenta más tarde',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const setDataHelp = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		let validate = null;
		if (name === 'nss') {
			if (!isNumber(value)) return;
			if (value.length > 11) return;
			validate = nssValid(value);
			setErrorNSSHelp(validate);
		}
		setSearchHelp({ ...searchHelp, [name]: value });
	};

	const handleChangeHelp = async (event: any) => {
		const { name, value } = event.target;
		if (name === 'search') {
			setSearchHelp({
				id: search.id,
				word: search.word,
				group: '',
				search: value,
				nss: '',
			});
			setErrorNSSHelp({ valid: false, msg: '' });
		} else {
			setSearchHelp({ ...searchHelp, [name]: value });
		}
	};

	const handleImpersonalizacion = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const search_base64 = await encrypt(searchHelp.search.toString() || '', key);
			const nss_base64 = await encrypt(searchHelp.nss.toString() || '', key);

			impersonalizacionAPI.impersonalizar
				.post<{ usuario: string; nss: string }, any>('', {
					usuario: search_base64,
					nss: nss_base64,
				})
				.then((res: any) => {
					if (res?.result?.login) {
						if (!isEmpty(res.result.token)) {
							const user = jwt(res.result.token) as any;
							dispatch(
								actions.setUser({
									...user,
									k: key,
									token: res.result.token,
									remember: true,
									isImpersonalized: true
								})
							);
							dispatch(
								creditsAction.setAvailableCredits([...user.creditos])
							);
							if (user?.creditos?.length > 0) {
								if (user?.creditos[0]?.num_credito) {
									dispatch(
										creditsAction.setCredit(
											user?.creditos[0]?.num_credito
										)
									);
								}
							}
							localStorage.removeItem('tokenHelpDesk');
							navigate('/');
						} else {
							setAlert({
								show: true,
								message: 'Error al obtener la información',
								severity: 'error',
							});
						}
					}
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						message: error.description
							? error.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setAlert({
				show: true,
				message:
					'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const exitSesion = () => {
		localStorage.removeItem('tokenHelpDesk');
		changeTabCustom(0);
	}

	return (
		<Layout onlyTitle title="Impersonalización">
			<div>
				{page === 0 && (
					<Tabs
						tabs={tabsEle}
						cardsType
						containerPosition={containerPosition}
						active={tabActive}
					>
						<Paper>
							{tabActive === 0 && (
								<div style={{ width: '100%' }}>
									<Grid container className={classes.container}>
										<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
										<Grid item xs={12} sm={12} md={6} lg={6}>
											<TextInput
												id="id"
												name="id"
												label="User ID"
												value={credentials.id}
												size="small"
												isRequired
												onChange={setDataCredentials}
											/>
											<TextInput
												id="password"
												name="password"
												label="Password"
												type="password"
												isRequired
												labelStyles={{ fontSize: 16 }}
												value={credentials.password}
												onChange={setDataCredentials}
											/>
											<div style={{ width: '100%' }}>
												<CustomAlert
													show={alert.show}
													severity={alert.severity}
													message={alert.message}
												/>
											</div>
											<CustomButton
												styles={{
													width: '260px',
													margin: '30px auto 20px',
												}}
												disabled={credentials.id.trim().length === 0 || credentials.password.trim().length === 0}
												label="Login"
												onClick={handleLogin}
												variant="solid"
											/>
										</Grid>
										<Grid container columns={12}>
											<Grid
												item
												xs={12}
												sm={12}
												md={12}
												lg={12}
												p={2}
											>
												<hr
													style={{
														opacity: 0.4,

														marginTop: 0,
														marginBottom: 0,
													}}
												/>
												<h3
													style={{
														marginLeft: 20,
														marginTop: 8,
														color: '#293990',
														fontSize: 18,
													}}
												>
													* Datos obligatorios
												</h3>
											</Grid>
										</Grid>
									</Grid>
								</div>
							)}
							{tabActive === 1 && (
								<div style={{ width: '100%' }}>
									<Grid container className={classes.container}>
										<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
										<Grid item xs={12} sm={12} md={6} lg={6}>
											<h3
												style={{
													color: '#7E8094',
												}}
											>
												Elige un criterio de búsqueda
											</h3>
											<p
												style={{
													margin: 0,
													fontSize: 14,
													color: '#7E8094',
												}}
											>
												Grupo
												<label style={{ color: '#D1001F' }}>
													*{' '}
												</label>
											</p>
											<FormControl
												sx={{ width: '100%', marginBottom: 2 }}
											>
												<Select
													id="group"
													value={search.group}
													name="group"
													onChange={handleChange}
													displayEmpty={true}
													renderValue={
														search.group !== ''
															? undefined
															: () => (
																<span
																	style={{
																		color: '#7F7F7F',
																	}}
																>
																	Selecciona
																</span>
															)
													}
													style={{ height: 40 }}
												>
													<MenuItem value={'1'}>
														Trabajadores
													</MenuItem>
												</Select>
											</FormControl>
											<p
												style={{
													margin: 0,
													fontSize: 14,
													color: '#7E8094',
												}}
											>
												Criterio de búsqueda
												<label style={{ color: '#D1001F' }}>
													*{' '}
												</label>
											</p>
											<FormControl
												sx={{ width: '100%', marginBottom: 2 }}
											>
												<Select
													id="search"
													value={search.search}
													name="search"
													onChange={handleChange}
													displayEmpty={true}
													renderValue={
														search.search !== ''
															? undefined
															: () => (
																<span
																	style={{
																		color: '#7F7F7F',
																	}}
																>
																	Selecciona
																</span>
															)
													}
													style={{ height: 40 }}
												>
													<MenuItem value={'1'}>
														Número de Seguridad Social (NSS)
													</MenuItem>
													{/*<MenuItem value={'2'}>
														Registro Federal de Contribuyentes
														(RFC)
													</MenuItem>
													<MenuItem value={'3'}>
														Clave Única de Registro de
														Contribuyentes (CURP)
												</MenuItem>*/}
												</Select>
											</FormControl>
											<TextInput
												id="nss"
												name="nss"
												label="Escribe el NSS"
												value={search.nss}
												error={true}
												helperText={
													!errorNSS.valid ? errorNSS.msg : ''
												}
												disabled={search.search.length === 0}
												size="small"
												isRequired
												onChange={setData}
											/>
											<div style={{ width: '100%' }}>
												<CustomAlert
													show={alert.show}
													severity={alert.severity}
													message={alert.message}
												/>
											</div>
											<div
												className={
													classes.containerButtons
												}
											>
												<CustomButton
													styles={{
														width: '260px',
														margin: '10px auto 20px',
													}}
													label="Salir"
													onClick={() => exitSesion()}
													variant="outlined"
												/>
												<CustomButton
													styles={{
														width: '260px',
														margin: '10px auto 20px',
													}}
													disabled={!Boolean(errorNSS.valid)}
													label="Buscar"
													onClick={() => handleSearch()}
													variant="solid"
												/>
											</div>

											<p
												style={{
													textAlign: 'center',
												}}
												onClick={() => setPage(1)}
											>
												<Link>Herramienta de ayuda</Link>
											</p>
										</Grid>

										{showDataSearch && (
											<Grid container columns={12}>
												<Grid
													item
													xs={12}
													sm={12}
													md={12}
													lg={12}
													p={2}
												>
													<hr
														style={{
															opacity: 0.2,

															marginTop: 0,
															marginBottom: 0,
														}}
													/>
													<p className={classes.h3}>
														Los datos que tenemos registrados
														son:
													</p>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Número de seguridad social
															(NSS)
														</p>
														<p className={classes.divRight}>
															{dataSearch.nss}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Nombre
														</p>
														<p className={classes.divRight}>
															{dataSearch.nombreCompleto}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Correo electrónico
														</p>
														<p className={classes.divRight}>
															{dataSearch.email}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Correo electrónico alternativo
														</p>
														<p className={classes.divRight}>
															{dataSearch.correoAternativo}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Teléfono celular
														</p>
														<p className={classes.divRight}>
															{dataSearch.celular}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Correo electrónico verificado
														</p>
														<p className={classes.divRight}>
															{dataSearch.correoVerificado
																? 'Si'
																: 'No'}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Teléfono celular verificado
														</p>
														<p className={classes.divRight}>
															{dataSearch.celularVerificado
																? 'Si'
																: 'No'}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Tipo de cuenta
														</p>
														<p className={classes.divRight}>
															{dataSearch.tipoCuenta}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Grupo
														</p>
														<p className={classes.divRight}>
															{dataSearch.grupo}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Fecha creación
														</p>
														<p className={classes.divRight}>
															{dataSearch.fechaCreacion}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Estatus
														</p>
														<p className={classes.divRight}>
															{dataSearch.estatus}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Medio activación
														</p>
														<p className={classes.divRight}>
															{dataSearch.medioActivacion}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Canal registro
														</p>
														<p className={classes.divRight}>
															{dataSearch.canalRegistro}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Canal reactivación
														</p>
														<p className={classes.divRight}>
															{dataSearch.canalActivacion}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Canal último acceso
														</p>
														<p className={classes.divRight}>
															{dataSearch.ultimoAcceso}
														</p>
													</div>
													<div
														className={
															classes.divContainerWeb
														}
													>
														<hr className={classes.hr} />
														<p className={classes.divLeft}>
															Último ingreso
														</p>
														<p className={classes.divRight}>
															{dataSearch.ultimoIngreso}
														</p>
														<hr className={classes.hr} />
													</div>
													<div
														className={
															classes.containerButtons
														}
													>
														<p
															className={classes.back}
															onClick={() =>
																setShowDataSearch(false)
															}
														>
															<KeyboardBackspace
																style={{
																	marginRight: '5px',
																}}
															/>
															Regresar
														</p>
													</div>
												</Grid>
											</Grid>
										)}

										<Grid container columns={12}>
											<Grid
												item
												xs={12}
												sm={12}
												md={12}
												lg={12}
												p={2}
											>
												<hr
													style={{
														opacity: 0.2,

														marginTop: 0,
														marginBottom: 0,
													}}
												/>
												<h3
													style={{
														marginLeft: 20,
														marginTop: 8,
														color: '#293990',
														fontSize: 18,
													}}
												>
													* Datos obligatorios
												</h3>
											</Grid>
										</Grid>
									</Grid>
								</div>
							)}
							<ModalLoading loading={loading} />
						</Paper>
					</Tabs>
				)}
				{page === 1 && (
					<Paper>
						<div style={{ width: '100%' }}>
							<Grid container className={classes.container}>
								<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<h3
										style={{
											color: '#7E8094',
											textAlign: 'center',
										}}
									>
										Herramienta de ayuda
									</h3>
									<p
										style={{
											margin: 0,
											fontSize: 14,
											color: '#7E8094',
										}}
									>
										Criterio de búsqueda
										<label style={{ color: '#D1001F' }}>* </label>
									</p>
									<FormControl sx={{ width: '100%', marginBottom: 2 }}>
										<Select
											id="search"
											value={searchHelp.search}
											name="search"
											onChange={handleChangeHelp}
											displayEmpty={true}
											renderValue={
												searchHelp.search !== ''
													? undefined
													: () => (
														<span
															style={{
																color: '#7F7F7F',
															}}
														>
															Selecciona
														</span>
													)
											}
											style={{ height: 40 }}
										>
											<MenuItem value={'1'}>
												Número de Seguridad Social (NSS)
											</MenuItem>
											{/*<MenuItem value={'2'}>
												Registro Federal de Contribuyentes (RFC)
											</MenuItem>
											<MenuItem value={'3'}>
												Clave Única de Registro de Contribuyentes
												(CURP)
											</MenuItem>*/}
										</Select>
									</FormControl>
									<TextInput
										id={
											searchHelp.search === '1'
												? 'nss'
												: searchHelp.search === '2'
													? 'rfc'
													: 'curp'
										}
										name={
											searchHelp.search === '1'
												? 'nss'
												: searchHelp.search === '2'
													? 'rfc'
													: 'curp'
										}
										label="Escribe el NSS"
										value={searchHelp.nss}
										error={true}
										helperText={
											!errorNSSHelp.valid ? errorNSSHelp.msg : ''
										}
										disabled={searchHelp.search.length === 0}
										size="small"
										isRequired
										onChange={setDataHelp}
									/>
									<div style={{ width: '100%' }}>
										<CustomAlert
											show={alert.show}
											severity={alert.severity}
											message={alert.message}
										/>
									</div>
									<p
										style={{
											textAlign: 'center',
										}}
									>
										Si no conoces el ID del usuario accede a <br />
										<Link
											onClick={() => {
												changeTabCustom(1);
												setPage(0);
											}}
										>
											Herramienta de Administracion de LDPA
										</Link>
									</p>
								</Grid>

								<div
									className={classes.containerButtons}
									style={{ marginTop: 40 }}
								>
									<CustomButton
										styles={{
											width: '260px',
											height: '40px',
											margin: '0 auto'
										}}
										label="Regresar"
										onClick={() => {
											changeTabCustom(1);
											setPage(0);
										}}
										variant="outlined"
									/>
									<CustomButton
										styles={{
											width: '260px',
											height: '40px',
											margin: '0 auto'
										}}
										disabled={!Boolean(errorNSSHelp.valid)}
										label="Continuar"
										onClick={() => handleImpersonalizacion()}
										variant="solid"
									/>
								</div>

								<Grid container columns={12}>
									<Grid item xs={12} sm={12} md={12} lg={12} p={2}>
										<hr
											style={{
												opacity: 0.2,

												marginTop: 0,
												marginBottom: 0,
											}}
										/>
										<h3
											style={{
												marginLeft: 20,
												marginTop: 8,
												color: '#293990',
												fontSize: 18,
											}}
										>
											* Datos obligatorios
										</h3>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</Paper>
				)}
			</div>
		</Layout>
	);
};

export default Impersonalizacion;
