import { Box, Card, CardActionArea, Divider, Paper } from '@mui/material';
import casitaIcon from '../../../../../../assets/img/productsMIUC/casita.png';
import terrenoIcon from '../../../../../../assets/svg/casita2.svg';
import { Banner } from '../../../../components/Banner/banner';
import { RoundBtn } from '../../../../components/RoundBtn/roundBtn';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { useGeneralStyles } from '../../../../views/styles/general.styles';
import Layout from '../../../../../../components/Layout';
import { useController } from '../../../../hooks/useController';
import { MIUC_PRODUCTS, PRODUCT_LINES } from '../../../../constants/products';
import theme from '../../../../../../config/theme';

interface IProps {
	onSelect: (esMejora: boolean) => void;
}

export const NoWorkMenu = ({ onSelect }: IProps) => {
	const classes = useStyles();
	const generalStyle = useGeneralStyles();
	const navigate = useNavigate();
	const { dataProcessController } = useController();
	const disableMejoraDirecta = true;

	return (
		<Layout>
			<Box className={generalStyle.container}>
				<Paper className={classes.container}>
					<h2>
						Para ti que no tienes relación laboral, conoce la
						alternativa con la que cuentas
					</h2>
					<Box className={classes.cardContainer}>
						<Card className={classes.card}>
							{![
								PRODUCT_LINES.COMPRAR_VIVIENDA,
								PRODUCT_LINES.MEJORA_HOGAR,
							].includes(
								dataProcessController.processData.productLine
							) && (
								<CardActionArea
									className="actionArea"
									sx={{ color: disableMejoraDirecta ? '#7F7F7F' : theme.palette.info.main }}
									onClick={() => onSelect(true)}
									disabled={ disableMejoraDirecta }
								>
									<img
										src={terrenoIcon}
										alt="MejOraSi"
										style={{ width: 70, opacity: disableMejoraDirecta ? 0.36 : 1.00 }}
									/>
									<h4
										style={{ opacity: disableMejoraDirecta ? 0.36 : 1.00 }}
									>
										Reparar, ampliar o mejorar tu vivienda
										con mejOraSí
									</h4>
								</CardActionArea>
							)}

							{PRODUCT_LINES.COMPRAR_VIVIENDA ===
								dataProcessController.processData
									.productLine && (
								<CardActionArea
									className="actionArea"
									onClick={() => onSelect(false)}
								>
									<img
										src={casitaIcon}
										alt="Comprar vivienda con cuenta Infonavit + Crédito Bancario"
										style={{ width: 70 }}
									/>
									<h4>
										Comprar vivienda con cuenta Infonavit +
										Crédito Bancario
									</h4>
								</CardActionArea>
							)}

							{PRODUCT_LINES.MEJORA_HOGAR ===
								dataProcessController.processData
									.productLine && (
								<CardActionArea
									className="actionArea"
									sx={{ color: disableMejoraDirecta ? '#7F7F7F' : theme.palette.info.main }}
									onClick={() => onSelect(true)}
									disabled={ disableMejoraDirecta }
								>
									<img
										src={terrenoIcon}
										alt="MejOraSi"
										style={{ width: 70, opacity: disableMejoraDirecta ? 0.36 : 1.00 }}
									/>
									<h4
										style={{ opacity: disableMejoraDirecta ? 0.36 : 1.00 }}
									>
										Reparar, ampliar o mejorar tu vivienda
										con mejOraSí
									</h4>
								</CardActionArea>
							)}
						</Card>
					</Box>
					{![
						PRODUCT_LINES.COMPRAR_VIVIENDA,
						PRODUCT_LINES.MEJORA_HOGAR,
					].includes(
						dataProcessController.processData.productLine
					) && (
						<>
							<Divider sx={{ marginY: '40px' }} />
							<h3>También puedes optar por otra opción:</h3>
							<div className={classes.cicb}>
								<Banner
									size="medium"
									icon={casitaIcon}
									text="Comprar vivienda con cuenta Infonavit + Crédito Bancario"
									onClick={() => onSelect(false)}
								/>
							</div>
						</>
					)}

					<Box className={generalStyle.bottonsContainer}>
						<RoundBtn
							className="button"
							variant="contained"
							onClick={() => navigate(-1)}
						>
							Regresar
						</RoundBtn>
					</Box>
				</Paper>
			</Box>
		</Layout>
	);
};
