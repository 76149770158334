import { useState } from 'react';

import HalfUnlock from '../../NipInfonatel/UnlockPin/halfUnlock';
import LockConfirmation from './lockConfirmation';
import SuccessFuLuck from './successfuLock';
import ActivationMedium from '../activationMedium';
import TabsCustom from '../../../components/TabsCustom';

interface IData {
	nip: string;
	blockNip: boolean;
	typeInformation: number;
}

export const LockNip = ({ nip, blockNip, typeInformation }: IData) => {
	const [isEmail, setIsEmail] = useState(false);
	const [tabs, setTabs] = useState(0);

	const handleFirstStep = async () => {
		setTabs(tabs + 1);
	};

	return (
		<div>
			<TabsCustom
				selectIndex={tabs}
				activeStep
				setSelectIndex={setTabs}
				components={[
					{
						title: 'Medio de bloqueo de NIP',
						component: (
							<HalfUnlock
								onContinue={handleFirstStep}
								isEmail={isEmail}
								setIsEmail={setIsEmail}
								blockNip={blockNip}
								typeInformation={typeInformation}
							/>
						),
					},
					{
						title: 'Confirmación de bloqueo de NIP',
						component: (
							<LockConfirmation
								onContinue={handleFirstStep}
								isEmail={isEmail}
								nip={nip}
							/>
						),
					},
					{
						title: 'Código de confirmación',
						component: (
							<ActivationMedium
								onContinue={handleFirstStep}
								isEmail={isEmail}
								onCloseError={() => { }}
								reSend={() => { }}
								typeInformation={typeInformation}
								nip={nip}
								action={'bloquear'}
							/>
						),
					},
					{
						title: 'Bloqueo de NIP',
						component: (
							<SuccessFuLuck onContinue={handleFirstStep} />
						),
					},
				]}
			/>
		</div>
	);
};

export default LockNip;
