import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora(
	'141',
	'calculadora-pesos-unidad-medida-actualizacion-uma'
);

export const ENDPOINTS_CALCULADORA_DE_PESOS_UNIDAD_MEDIDA_UMA: IBitacora = {
	'/v1/calculadora-uma/type-uma': _bitacora.Read(
		'141100',
		'Consultar el tipo de UMA'
	),
	'/v1/calculadora-uma/calculator-uma': _bitacora.Read(
		'141101',
		'Consultar calculadora'
	),
};
