import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';

//Assets
import { useStyles } from './styles';
import { RoundBtn } from '../../pages/AssociateNssCredit/Styles';
export interface IModalProps {
	open: boolean;
	title?: React.ReactChild;
	cancelButton?: boolean;
	width?: 'md' | 'lg' | 'xl' | 'sm' | 'xs';
	onClose?: () => void;
	onCancel?: () => void;
	onConfirm?: () => void;
	cancelLabel?: string;
	confirmLabel?: string;
	disableOnConfirm?: boolean;
	showDialogTitle?: boolean;
	showDialogActions?: boolean;
	color?:
		| 'primary'
		| 'inherit'
		| 'secondary'
		| 'success'
		| 'error'
		| 'info'
		| 'warning'
		| undefined;
	children?: React.ReactChild;
	stylesContent?: React.CSSProperties;
	stylesActions?: React.CSSProperties;
	hideCloseButton?: boolean;
	hideCircularProgress?: boolean;
}

const BaseModal = (props: IModalProps) => {
	const {
		open,
		title,
		cancelButton,
		width = 'md',
		onClose,
		onCancel,
		onConfirm,
		cancelLabel,
		confirmLabel,
		disableOnConfirm,
		showDialogTitle,
		color,
		children,
		showDialogActions,
		stylesContent,
		stylesActions,
		hideCloseButton,
		hideCircularProgress,
	} = props;

	const classes = useStyles();

	return (
		<Dialog
			open={open}
			fullWidth
			onClose={onClose}
			maxWidth={width}
			PaperProps={{
				elevation: 1,
			}}
			TransitionProps={{
				style: {
					backgroundColor: 'rgb(0 0 0 / 50%)',
				},
			}}
		>
			<DialogTitle
				className={classes.header}
				style={{ display: showDialogTitle ? 'none' : 'flex' }}
			>
				<p className={classes.title}>{title}</p>
				{/* <IconButton disabled={disableOnConfirm} onClick={onClose}> */}
				{!hideCloseButton && (
					<IconButton
						onClick={onClose}
						style={{ position: 'absolute', top: 20, right: 20 }}
					>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent style={{ ...stylesContent }}>
				{children}
			</DialogContent>
			{cancelButton || onConfirm ? (
				<DialogActions
					style={{
						...stylesActions,
						display: showDialogActions ? 'none' : 'flex',
						justifyContent: 'space-evenly',
					}}
				>
					{cancelButton && (
						<RoundBtn
							className={classes.buttonLarge}
							disableElevation
							color={color}
							variant="outlined"
							//disabled={disableOnConfirm}
							onClick={onCancel ? onCancel : onClose}
						>
							{cancelLabel || 'Cancelar'}
						</RoundBtn>
					)}
					{onConfirm && (
						<RoundBtn
							disabled={disableOnConfirm}
							color={color}
							disableElevation
							variant="contained"
							className={classes.buttonLarge}
							onClick={onConfirm}
							endIcon={
								disableOnConfirm &&
								!hideCircularProgress && (
									<CircularProgress size={18} />
								)
							}
						>
							{confirmLabel || 'Aceptar'}
						</RoundBtn>
					)}
				</DialogActions>
			) : null}
		</Dialog>
	);
};

export default BaseModal;
