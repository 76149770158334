import { Box } from "@mui/material";
import theme from "../../../../config/theme";

interface IInfo {
  text: string;
  textPosition?: 'start' | 'center' | 'end';
}

export const InfoBanner = (props: IInfo) => {
  const { text, textPosition } = props;

  return (
    <Box
      sx={{
        borderRadius: '5px',
        fontWeight: 'bold',
        paddingY: '16px',
        display: 'flex',
        justifyContent: textPosition? textPosition : 'start',
        color: theme.palette.info.main,
        backgroundColor: theme.palette.background.default
      }}
    >
      {text}
    </Box>
  )
}