import { Divider, useMediaQuery } from '@mui/material';
import Modal from '../../../../components/Modal';
import theme from '../../../../config/theme';
import CustomButton from '../../../../components/Button';

interface IProps {
	show: boolean;
    setsShow: (arg: boolean) => void;
}

const ModalMessageTempMiuc = ({ show, setsShow }: IProps) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Modal
			hideCloseButton
			open={show}
			width={'md'}
			children={
				<div style={{ textAlign: 'center', fontSize: 15 }}>
					<h3
						style={{
							fontWeight: 500,
							margin: '0px 0px 20px 0px',
							color: 'rgb(41,57,144)',
						}}
					>
						Importante
					</h3>

					<p
						style={{
							fontWeight: 500,
							margin: 0,
							marginBottom: 20,
							textAlign: 'justify',
						}}
					>
						En el Infonavit buscamos la mejora continua en la
						atención y el servicio que les brindamos, por lo que
						estaremos dando mantenimiento a nuestros sistemas de 17
						al 26 de noviembre, aunado a este periodo, la semana
						posterior será considerada de estabilización, por lo que
						podría haber intermitencias en los servicios.
					</p>
					<p
						style={{
							fontWeight: 500,
							margin: 0,
							marginBottom: 30,
							textAlign: 'justify',
						}}
					>
						Debido a lo anterior, en caso de que vayas a solicitar
						un crédito ó ya lo estés tramitando, te informamos que
						el proceso podría llevar un poco más de tiempo habitual.
					</p>

					<Divider style={{ margin: '20px 0px' }} />

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: matches
								? 'column-reverse'
								: 'inherit',
						}}
					>
						<div
							style={{
								width: matches ? '100%' : 180,
								display: matches ? 'flex' : 'inline-flex',
								margin: '8px 10px',
							}}
						>
							<CustomButton
								label="Salir"
								variant="solid"
								onClick={() => setsShow(false)}
							/>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default ModalMessageTempMiuc;
