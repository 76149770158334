/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useStyles } from '../style';

// Components
import CustomButton from '../../../components/Button';

import { dataReturnSavingsContext } from '../contextData';
import { IRequestCreacionCaso } from '../interfaces';
import warning from '../../../assets/img/icono-advertencia-rojo.png';

const ExceptionPage = () => {
	const classes = useStyles();
	const {
		data,
		handleCreacionCaso,
		setPage,
		optionSelectedItem,
		handleGoHomePage,
		dataException,
		setDataException
	} = useContext(dataReturnSavingsContext);

	const handleAccept = () => {
		if (dataException?.action === 'negativa' || dataException?.action === 'aclaracion') {
			const params: IRequestCreacionCaso = {
				evento: '3',
				opcion: optionSelectedItem,
				demanda: false,
				tipoDemanda: '',
				montoDevolver: data?.ConsultaDatosDisponibilidadResponse?.data?.ahorroTotal || '0',
				montoSaldo92: data?.ConsultaDatosDisponibilidadResponse?.data?.montoSaldo92 || '0',
				montoSaldo97: data?.ConsultaDatosDisponibilidadResponse?.data?.montoSaldo97 || '0',
				montoSaldoFA: data?.ConsultaDatosDisponibilidadResponse?.data?.subCuenta || '0',
				montoAdicionalSaldoFA: data?.ConsultaDatosDisponibilidadResponse?.data?.cantidadAdicional || '0',
				descMontoAdicionalSaldoFA: data?.ConsultaDatosDisponibilidadResponse?.data?.desRechazoFa,
				numeroCredito: '',
				codigoRechazoFa: data?.ConsultaDatosDisponibilidadResponse?.data?.codRechazoFa || '',
				grupo: data?.ConsultaDatosDisponibilidadResponse?.data?.grupo || '',
			} as IRequestCreacionCaso;
			setPage(14);
			handleCreacionCaso(params);
		}
	}

	useEffect(() => {
		if (dataException?.list && dataException?.list.length > 0) {
			if (dataException?.list.includes('<strong>')) {
				let msg = dataException?.list.split('<br />')
				let titleStrong = msg[0].split('<strong>')
				let title = titleStrong[1].split('</strong>')
				setDataException({
					...dataException,
					list: title[0],
					question: title[1],
				})
			}
		}
	}, [dataException]);

	return (
		<div>
			<div style={{ textAlign: 'center' }}>
				<img src={warning} alt="warning" />
			</div>

			<div className={classes.message} style={{ marginTop: 10, marginBottom: 10, maxWidth: 800 }}>
				<span>
					{dataException?.list}
				</span>
			</div>

			<div style={{ textAlign: 'center', margin: '30px auto' }}>
				{dataException?.question}
			</div>
			{dataException?.title !== 'messageRechazo' && dataException?.question && dataException?.question?.length > 0 && (
				<div className={classes.containerButtons}>
					<CustomButton
						onClick={handleGoHomePage}
						variant="outlined"
						styles={{
							width: 260,
							height: 40,
							margin: '10px auto'
						}}
						label="No"
					/>
					<CustomButton
						label="Si"
						onClick={handleAccept}
						variant="solid"
						styles={{
							width: 260,
							margin: '10px auto'
						}}
					/>
				</div>
			)}
			{(!dataException?.question || dataException?.title === 'messageRechazo') && (
				<div className={classes.containerButtons}>
					<CustomButton
						onClick={handleGoHomePage}
						variant="solid"
						styles={{
							width: 260,
							height: 40,
							margin: '10px auto'
						}}
						label="Finalizar"
					/>
				</div>
			)}
		</div>
	);
};

export default ExceptionPage;