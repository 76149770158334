import Resumable from 'resumablejs';

export const getBinaryFile = async (file: any) => {
	return new Promise((resolve) => {
		const readerFile = new FileReader();
		readerFile.onload = function (event) {
			resolve((event?.target?.result as string) || '');
		};
		readerFile.readAsArrayBuffer(file);
	})
		.then((response) => response)
		.catch(() => '');
};

export const getBase64File = async (file: any) => {
	return new Promise((resolve) => {
		const readerFile = new FileReader();
		readerFile.readAsDataURL(file);
		readerFile.onload = function (event) {
			resolve((event.target?.result as string).replace(/^data:(.*;base64,)?/, ''));
		};
	})
		.then((response) => response)
		.catch(() => '');
};

export interface IFiles {
	file1: {
		name: string;
		data: null | string;
		type: string;
		binary: any;
		base64: any;
	};
	file2: {
		name: string;
		data: null | string;
		type: string;
		binary: any;
	};
	password: string;
}

export interface IFiles2 {
	file1: {
		name: string;
		data: null | string;
		type: string;
		binary: any;
		base64: any;
	};
	file2: {
		name: string;
		data: null | string;
		type: string;
		binary: any;
	};
}

export interface IDataInfo {
	banco?: string;
	clabe?: string;
	nombre?: string;
    saldo?: string;
    nombreArchivo?: string;
    noCaso?: string;
    pdfAcuse?: string;
}

export interface ISesionData {
	activo?: string;
	sesion?: string;
}

export const resumableFileCancelled = new Resumable({
	target: `${process.env.REACT_APP_API_URL_OPENTEXT}/CartasProduccion-web/upload`,
	testChunks: false,
	chunkSize: 1 * 1024 * 1024,
	simultaneousUploads: 1,
	maxFiles: 1,
	fileType: ['pdf'],
	method: 'multipart',
});

export const tabsFlag = [
	{
		id: 1,
		title: '1.- Firma electrónica',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: 2,
		title: '2.- Monto a devolver',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 1,
		orderSM: 2,
	},
	{
		id: 3,
		title: '3.- Datos bancarios',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 2,
		orderSM: 3,
	},
	{
		id: 4,
		title: '4.- Verificación de datos bancarios',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 3,
		orderSM: 4,
	},
	{
		id: 5,
		title: '5.- Validación de devolución',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 4,
		orderSM: 5,
	},
	{
		id: 6,
		title: '6.- Estatus',
		disabled: true,
		checked: false,
		columns: 2,
		orderMD: 5,
		orderSM: 6,
	},
];