import { Badge, Link } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useStyles } from './styles';
import TooltipCustom from '../../../components/TooltipCustom';
import { Info } from '@mui/icons-material';
import avaluo from '../../../assets/svg/avaluo.svg';
import expediente from '../../../assets/svg/expediente.svg';
import prorrogas from '../../../assets/svg/plazo_prorrogas.svg';
import finalizarVivienda from '../../../assets/svg/finalizar_vivienda.svg';
import ministro from '../../../assets/svg/ministro.svg';
import { ConstantSteps } from '../constantSteps/constantSteps';
import HelpPanel from '../../HelpPanel';

export const SueloConstruccionSteps = (data: {
	avanceTaller: number;
	alert: String;
}) => {
	const classes = useStyles();
	const {
		tallerSaberMas,
		verificaTusDatos,
		notarioTitulacion,
		solicitaTuCredito,
	} = ConstantSteps(data);

	return [
		tallerSaberMas,
		verificaTusDatos,
		{
			paperType: 'viewMore',
			paperIcon: avaluo,
			title: 'Gestión del Estudio de valor y dictamen técnico de terreno',
			description: '',
			viewMoreLink: (
				<a
					href="/precalificacion-puntos/unidades-valuacion"
					className={classes.optionEnlace}
				>
					{' '}
					Consulta el directorio <ChevronRightIcon />
				</a>
			),
			viewMoreButton: 'Consulta los requisitos',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<ul className={classes.viewMoreList}>
							<li>
								Solicita el Estudio de valor y dictamen técnico
								del terreno con cualquiera de las{' '}
								<Link
									href="https://portalmx.infonavit.org.mx/wps/portal/infonavit.web/proveedores-externos/para-tu-gestion/unidades-valuacion/consulta_unidades_valuacion/!ut/p/z1/pZDLCsIwEEU_KbeJDnGZSE3rM4WG1mykKwlodSF-v6G7KrYV727gnJnhMs9q5tvmGc7NI9za5hLno6cTGSBbzpI9dlKjEHwzt8oiKwWr-oBZg6M46NI6rgURMf-Hb8uJPpeA0Yi-zCTUKs3TxVZw45JpPr5E4cf_PwE_vL5ivn_irUFLIwCNAF2HHTBU0tib96uLqRHyoF5Xp7Gw/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
									target="_blank"
								>
									Unidades de Valuación
								</Link>{' '}
								con las que trabaja el Infonavit.
							</li>
							<li>
								Una vez que hayas elegido la unidad de
								valuación, prepara la siguiente documentación:
								<ul className={classes.viewMoreList}>
									<li>Escritura antecedente de propiedad</li>
									<li>
										Constancia de Alineamiento y número
										oficial.
									</li>
									<li>
										Documento oficial que acredite que el
										uso de suelo es habitacional o mixto que
										incluya uso habitacional.
									</li>
									<li>Boleta predial (última)</li>
									<li>Boleta de agua (última)</li>
									<li>
										Plano o croquis del inmueble (Si no
										cuentas con él, pueden hacértelo con un
										costo adicional)
									</li>
									<li>
										Copia de la Identificación oficial del
										vendedor y del comprador
									</li>
									<li>Ante Proyecto</li>
									<li>Estimado de costo</li>
								</ul>
							</li>
							<li>
								Adicionalmente te pedirán llenar una Solicitud
								de avalúo.{' '}
								<Link
									href={require('../../../../../assets/files/guia-pasos-a-seguir/Solicitud_de_estudio_de_valor_y_dictamen_tecnico_del_terreno.pdf')}
									target="_blank"
								>
									Descárgala aquí.
								</Link>{' '}
								Comunícate y haz una cita.
							</li>
							<li>
								Contacta a la unidad de valuación que elegiste,
								te solicitarán domicilio completo del inmueble y
								acordarán la visita.
							</li>
							<li>
								Indícale que se trata de un trámite de crédito
								con Infonavit pues se tienen tarifas
								preferenciales.
							</li>
						</ul>
					</div>
				</>
			),
		},
		{
			paperType: 'viewMore',
			paperIcon: expediente,
			title: 'Integra tu expediente',
			description: 'Reúne tus documentos para tramitar tu crédito',
			viewMoreButton: 'Consulta la documentación',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<b>Reúne estos documentos</b>
						<ul className={classes.viewMoreList}>
							<li>
								Solicitud de inscripción de crédito.{' '}
								<Link
									href={
										'https://portalmx.infonavit.org.mx/wps/wcm/connect/f2b56503-7a4c-44c3-9ef5-075b8325ae9e/SolicituddeInscripciondeCredito_CRED.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CVID=ozkrCXH'
									}
									target="_blank"
								>
									Descárgala aquí.
								</Link>
							</li>
							<li>
								Acta de nacimiento en original y copia. Ten en
								cuenta que, si tu acta de nacimiento es digital,
								debes traerla impresa.
								<Badge
									badgeContent={
										<HelpPanel type="ACTA_NACIMIENTO" />
									}
									className={classes.helpPanel}
								></Badge>
							</li>
							<li>
								Identificación oficial vigente en original y
								copia.{' '}
								<TooltipCustom
									arrow
									placement="right"
									title={
										<>
											<b>
												La identificación oficial puede
												ser:
											</b>
											<ul>
												<li>
													Credencial para votar
													vigente
												</li>
												<li>Pasaporte</li>
											</ul>
										</>
									}
								>
									<Info
										color="primary"
										className={classes.infoStyle}
									/>
								</TooltipCustom>
							</li>
							<li>
								Comprobante de domicilio con una antigüedad
								máxima de 3 meses y estar pagado.
							</li>
							<li>
								Estado de cuenta bancario del derechohabiente
								con Clave Interbancaria Estandarizada (CLABE)
								para el pago de los recursos destinados a la
								Construcción de la vivienda.
							</li>
							<li>
								<Link
									href={require('../../../../../assets/files/guia-pasos-a-seguir/Carta_bajo_protesta_de_decir_verdad_suelo.pdf')}
									target="_blank"
								>
									Carta bajo protesta de decir verdad
								</Link>
							</li>
							<li>
								Estado de cuenta bancario del vendedor con Clave
								Interbancaria Estandarizada (CLABE).
							</li>
							<li>
								Estudio de valor y dictamen técnico del terreno.
							</li>
							<li>
								Copia de la constancia del curso Saber más para
								decidir mejor
							</li>
						</ul>

						<b>Documentos de construcción:</b>
						<ul className={classes.viewMoreList}>
							<li>
								Ante Proyecto, el cual debe contener:
								<ul className={classes.viewMoreList}>
									<li>Plano arquitectónico.</li>
									<li>Memoria descriptiva.</li>
									<li>
										<Link
											href={require('../../../../../assets/files/guia-pasos-a-seguir/Especificaciones_Ante_Proyecto_230823_vf_suelo.xls')}
											download={
												'especificaciones_ante_proyecto.xls'
											}
										>
											Especificaciones
										</Link>
									</li>
								</ul>
							</li>
							<li>
								Estimado del costo, el cual debe contener:
								<p style={{ margin: 0 }}>
									<Link
										href={require('../../../../../assets/files/guia-pasos-a-seguir/Presupuesto_Parametrico_240823_vf.xls')}
										download={
											'presupuesto_para_la_construccion.xls'
										}
									>
										Presupuesto paramétrico de acuerdo con
										la superficie por construir
									</Link>
								</p>
							</li>
							<li>
								Estos documentos deberás enviarlos al correo
								electrónico{' '}
								<Link href="mailto:crediterrenoparamihogardh@infonavit.org.mx">
									crediterrenoparamihogardh@infonavit.org.mx
								</Link>
							</li>
						</ul>
						<b>Con la primera ministración compras tu terreno.</b>
						<p style={{ margin: '10px 0px 0px 0px' }}>
							Para solicitar la segunda ministración con la que
							podrás iniciar la construcción de tu vivienda,
							deberás enviar al correo electrónico{' '}
							<Link href="mailto:crediterrenoparamihogardh@infonavit.org.mx">
								crediterrenoparamihogardh@infonavit.org.mx
							</Link>
							, el Expedienté Técnico Integrado, el cual deberá
							contener la siguiente documentación:
						</p>
						<ol className={classes.viewMoreList}>
							<li>
								Proyecto Ejecutivo
								<ul className={classes.viewMoreList}>
									<li>Licencia de construcción</li>
									<li>Alineamiento y número oficial</li>
									<li>Croquis de localización</li>
									<li>Planos arquitectónicos</li>
									<li>Planos estructurales</li>
									<li>Planos de instalaciones</li>
									<li>Especificaciones de obra</li>
								</ul>
							</li>
							<li>
								Presupuesto para la construcción.{' '}
								<Link
									href={require('../../../../../assets/files/guia-pasos-a-seguir/Presupuesto_Para_La_Construccion_230823_vf.xls')}
									download={
										'presupuesto_para_la_construccion.xls'
									}
								>
									Descárgalo aquí
								</Link>
							</li>
							<li>
								Programa de Obra y Programa Físico Financiero.{' '}
								<Link
									href={require('../../../../../assets/files/guia-pasos-a-seguir/programa_de_obra_suelo.xls')}
									download={'programa_de_obra.xls'}
								>
									Descárgalo aquí
								</Link>
							</li>
							<li>
								Cédula de Presentación y ficha técnica.{' '}
								<Link
									href={require('../../../../../assets/files/guia-pasos-a-seguir/Cedula_de_Presentacion_y_Ficha_Tecnica_230823_vf.xls')}
									download={
										'cedula_de_presentacion_y_ficha_tecnica.xls'
									}
								>
									Descárgalo aquí
								</Link>
							</li>
							<li>
								Contrato de obra a precio alzado y tiempo.{' '}
								<Link
									href={require('../../../../../assets/files/guia-pasos-a-seguir/contrato_a_precio_alzado_suelo.pdf')}
									download={'contrato_a_precio_alzado.pdf'}
								>
									Descárgalo aquí
								</Link>
							</li>
						</ol>
					</div>
				</>
			),
		},
		{
			paperType: 'simple',
			paperIcon: ministro,
			title: 'Ministraciones subsecuentes',
			description: (
				<>
					<p style={{ margin: 0 }}>
						Para solicitar tus ministraciones subsecuentes (tres y
						cuatro) deberás realizar el pago de tu verificación y
						solicitar al verificador la visita para validar el
						avance de obra.
					</p>
					<ul style={{ margin: 0, padding: '12px 0px 12px 10px' }}>
						<li>
							El verificador será el encargado de entregar el
							reporte de verificación, para que así se te libere
							la ministración correspondiente y te sean
							depositados los recursos a tu cuenta bancaria.
						</li>
					</ul>
				</>
			),
		},
		{
			paperType: 'simple',
			paperIcon: finalizarVivienda,
			title: 'Al finalizar la construcción de tu vivienda',
			description: (
				<>
					<p style={{ margin: 0 }}>
						Recuerda que cuando termines de construir tu vivienda
						deberás entregar a través del correo electrónico{' '}
						<Link href="mailto:crediterrenoparamihogardh@infonavit.org.mx">
							crediterrenoparamihogardh@infonavit.org.mx
						</Link>{' '}
						la siguiente documentación:
					</p>
					<ul style={{ margin: 0, padding: '14px 0px 14px 22px' }}>
						<li>Aviso de Terminación de Obra.</li>
						<li>Avalúo de la vivienda concluida.</li>
					</ul>
					<p style={{ margin: 0 }}>
						Es importante que entregues estos documentos para cerrar
						tu expediente y en el futuro puedas solicitar un crédito
						subsecuente.
					</p>
				</>
			),
		},
		{
			paperType: 'simple',
			paperIcon: prorrogas,
			title: 'Plazos y prórrogas',
			description: (
				<>
					<ul style={{ margin: 0, padding: '0px 0px 0px 10px' }}>
						<li>
							Para solicitar tu segunda ministración, cuentas con
							60 días a partir de haber recibido la primera. En
							caso de que el tiempo no te haya sido suficiente,
							podrás solicitar una prórroga de 30 días
							adicionales.
						</li>
						<li>
							Una vez que se te deposita la segunda ministración,
							cuentas con 360 días para concluir la construcción
							de tu vivienda, con la posibilidad de solicitar una
							prórroga de 60 días adicionales, en el caso de que
							no concluyas dentro de dicho plazo.
						</li>
						<li>
							Deberás solicitar cualquier prórroga al correo
							electrónico{' '}
							<Link href="mailto:crediterrenoparamihogardh@infonavit.org.mx">
								crediterrenoparamihogardh@infonavit.org.mx
							</Link>
						</li>
					</ul>
				</>
			),
		},
		notarioTitulacion,
		solicitaTuCredito,
	];
};
