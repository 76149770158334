import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    textAlign: 'center',
    '& .title': {
      margin: '40px 0',
    }
  },
  header: {
    textAlign: 'left',
  },
  optionList: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '48px',
    gap: '35px',
  },
  alert: {
    marginBottom: '8px',
  },
}))