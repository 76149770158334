import Modal from '../../../components/Modal';

interface Props {
	show: boolean;
	setShow: (avg: boolean) => void;
	file?: any;
}

const ViewArchive = ({ show, setShow, file }: Props) => {
	return (
		<Modal
			open={show}
			onClose={() => setShow(false)}
			width="sm"
			children={
				file ? (
					<div style={{ textAlign: 'center' }}>
						{file.type.indexOf('image') > -1 && (
							<img alt="imagen" src={URL.createObjectURL(file)} />
						)}
						{file.type.indexOf('video') > -1 && (
							<video
								controls
								src={URL.createObjectURL(file)}
								style={{ maxWidth: 550, width: '100%' }}
							>
								Vídeo no es soportado
							</video>
						)}
						{file.type.indexOf('pdf') > -1 && (
							<iframe
								title="visor"
								src={URL.createObjectURL(file)}
								style={{ maxWidth: 550, width: '100%', height: 400 }}
							/>
						)}
					</div>
				) : (
					'Sin archivo'
				)
			}
		/>
	);
};

export default ViewArchive;