import React from 'react';
import { Grid } from '@mui/material';
import { useStyles } from '../Register/styles';
import CustomButton from '../../components/Button';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Confirmation = () => {
	const classes = useStyles();
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<div style={{ width: '100%' }}>
			<Grid container className={classes.container} spacing={0}>
				<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={7}
					lg={7}
					className={classes.textConfirmation}
				>
					<br />

					<span className={classes.VerificacionCodigoTitle}>
						<strong>¡Felicidades!</strong>
					</span>
					<br />
					<br />
					<br />
					<div className={classes.textMovil}>
						<label>
							Tu cuenta ha sido activada exitosamente comienza a
							disfrutar de los servicios que <br />
							Mi Cuenta Infonavit tiene para ti.
						</label>
					</div>
					<br />
					<div className={classes.buttonCenter}>
						<CustomButton
							label="Finalizar"
							onClick={() => navigate('/')}
							variant="solid"
							styles={{
								marginTop: '20px',
								width: '70%',
								textAlign: 'center',
								[theme.breakpoints.down('sm')]: {
									width: '100%',
								},
							}}
						/>
					</div>
					<br />
					<br />
				</Grid>
				<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
			</Grid>
		</div>
	);
};

export default Confirmation;
