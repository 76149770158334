import { Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CustomButton from '../../../components/Button';

interface Props {
	setPage: (arg: number) => void;
}

const InfoDoc = ({ setPage }: Props) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	return (
		<Paper style={{ marginTop: 30, padding: matches ? '30px 20px' : '40px 50px' }}>
			<p>
				Podrás realizar una aclaración de pago cuando no veas un pago aplicado a
				tu crédito, y lo realizó directamente el acreditado en una institución
				bancaria (ventanilla y pagos electrónicos) o en tiendas de conveniencia.
			</p>
			<p>
				Las aclaraciones de pagos REA solo se atenderán a partir del 2008 en
				adelante.
			</p>
			<p>
				Para poder iniciar una aclaración, deberás tener a la mano, el comprobante
				de pago que te dio el banco o la tienda de conveniencia, donde podrás
				localizar los siguientes datos que son necesarios para dar inicio a la
				misma:
			</p>
			<ul style={{ listStyle: 'none', padding: 0 }}>
				<li style={{ display: 'flex', alignItems: 'center' }}>
					<CheckCircleOutlineRoundedIcon
						style={{
							margin: 0,
							padding: 0,
							color: '#29398E',
							fontSize: 18,
							marginRight: 6,
						}}
					/>{' '}
					Monto de pago
				</li>
				<li style={{ display: 'flex', alignItems: 'center' }}>
					<CheckCircleOutlineRoundedIcon
						style={{
							margin: 0,
							padding: 0,
							color: '#29398E',
							fontSize: 18,
							marginRight: 6,
						}}
					/>{' '}
					Fecha de pago
				</li>
				<li style={{ display: 'flex', alignItems: 'center' }}>
					<CheckCircleOutlineRoundedIcon
						style={{
							margin: 0,
							padding: 0,
							color: '#29398E',
							fontSize: 18,
							marginRight: 6,
						}}
					/>{' '}
					Referencia bancaria
				</li>
				<li style={{ display: 'flex', alignItems: 'center' }}>
					<CheckCircleOutlineRoundedIcon
						style={{
							margin: 0,
							padding: 0,
							color: '#29398E',
							fontSize: 18,
							marginRight: 6,
						}}
					/>{' '}
					Folio de autorización de pago
				</li>
			</ul>

			<div style={{ width: matches ? '100%' : 250, margin: '30px auto' }}>
				<CustomButton
					label={'Continuar'}
					variant="solid"
					onClick={() => setPage(1)}
				/>
			</div>
		</Paper>
	);
};

export default InfoDoc;
