import { useStyles } from './styles/label-status.styles';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import StarIcon from '@mui/icons-material/Star';
import DoneIcon from '@mui/icons-material/Done';

export type TLabelStatus = 'pending' | 'completed' | 'updated';

export interface ILabelstatus {
	variantLabel: TLabelStatus;
}

export default function LabelStatus({ variantLabel }: ILabelstatus) {
	const styles = useStyles();

	const getColorBackground = (): string => {
		let color = '';
		switch (variantLabel) {
			case 'updated':
				color = '#CED5FF';
				break;
			case 'pending':
				color = '#FFE2E5';
				break;
			case 'completed':
				color = '#D8FDFF';
				break;
			default:
				break;
		}
		return color;
	};

	const getColorLabel = (): string => {
		let color = '';
		switch (variantLabel) {
			case 'updated':
				color = '#283990';
				break;
			case 'pending':
				color = '#D1001F';
				break;
			case 'completed':
				color = '#05B1BA';
				break;
			default:
				break;
		}
		return color;
	};

	const getLabel = (): JSX.Element => {
		let component = <></>;
		switch (variantLabel) {
			case 'updated':
				component = (
					<>
						<StarIcon
							style={{
								color: getColorLabel(),
								fontSize: 15,
							}}
						/>
						<label>Tema actualizado</label>
					</>
				);
				break;
			case 'completed':
				component = (
					<>
						<DoneIcon
							style={{
								color: getColorLabel(),
								fontSize: 15,
							}}
						/>
						<label>Completado</label>
					</>
				);
				break;
			case 'pending':
				component = (
					<>
						<PriorityHighIcon
							style={{
								color: getColorLabel(),
								fontSize: 15,
							}}
						/>
						<label>Pendiente</label>
					</>
				);
				break;
			default:
				break;
		}
		return component;
	};

	return (
		<div
			style={{
				backgroundColor: getColorBackground(),
				color: getColorLabel(),
			}}
			className={styles.containerLabel}
		>
			{getLabel()}
		</div>
	);
}
