/* eslint-disable @typescript-eslint/no-redeclare */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// COMPONENTS
import Layout from '../../components/Layout';
import ModalLoading from '../../components/ModalLoading';
// RESOURCES
import { returnOverpaymentsAPI } from '../../api/modules/returnOverpayments';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import useCustomScript from '../../hooks/useCustomScript';
import { IAlert } from '../../interfaces/alert';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { RootState } from '../../store';
// VIEWS
import InfoPayments from './InfoPayments';
import BankAccount from './BankAccount';
import Verify from './Verify';
import Amount from './Amount';
import Resume from './Resume';
import Finish from './Finish';
import Files from './Files';
import ModalMessageTemp from './ModalMessageTemp';
import { useBitacora } from '../../hooks/useBitacora';

interface IDataClabe {
	banco: string;
	nombre: string;
	clabe: string;
}

interface IDataCurp {
	activo: string;
	sesion: string;
}

interface IFiles {
	file1: {
		name: 'No se eligió ningún archivo';
		data: null;
	};
	file2: {
		name: 'No se eligió ningún archivo';
		data: null;
	};
}

interface IDataSello {
	codigo: string;
	sello: string;
	pdf: string;
}

interface IDataCaso {
	caso: string;
	codigo: string;
	descripcion: string;
	fecha: string;
	fechaEstatus: string;
}

interface IUserDataRegister {
	nss: string;
	clabe: string;
}

declare global {
	interface Window {
		firmar: any;
	}
}

declare global {
	interface Window {
		validarFiel: any;
	}
}

const LayoutNotice = () => {
	const { regBitacoraNotOld } = useBitacora();
	const [pdfSigned, setPdfSigned] = useState('');
	const [openModalTemp, setOpenModalTemp] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [buttonInfo, setButtonInfo] = useState(true);
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const [caso, setCaso] = useState(false);
	const [page, setPage] = useState(0);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const firmar = window.firmar;

	const [passw, setPassw] = useState('');
	const [cerB, setcerB64] = useState('');
	const [cer, setCer] = useState('');
	const [key, setKey] = useState('');
	const [pdfReintento, setPdfReintento] = useState('');

	useCustomScript('/jquery/jquery.min.js');
	useCustomScript('/efirma/firma-documento.js');
	useCustomScript('/efirma/fiel-validacion.ofu.min.js');

	// var serverIP = 'https://serviciosweb.infonavit.org.mx:8993'; //QA
	var serverIP = process.env.REACT_APP_URL_VALIDA_FIEL; //prod

	const [files, setFiles] = useState<IFiles>({
		file1: {
			name: 'No se eligió ningún archivo',
			data: null,
		},
		file2: {
			name: 'No se eligió ningún archivo',
			data: null,
		},
	});

	const [userData, setUserData] = useState<IUserDataRegister>({
		nss: '',
		clabe: '',
	});

	const [dataClabe, setDataClabe] = useState<IDataClabe>({
		banco: '',
		nombre: '',
		clabe: '',
	});

	const [dataCurp, setDataCurp] = useState<IDataCurp>({
		activo: '',
		sesion: '',
	});

	const [dataSello, setDataSello] = useState<IDataSello>({
		codigo: '',
		sello: '',
		pdf: '',
	});

	const [dataCaso, setDataCaso] = useState<IDataCaso>({
		caso: '',
		codigo: '',
		descripcion: '',
		fecha: '',
		fechaEstatus: '',
	});

	const setUserDataClabe = (name: string, value: string | boolean) => {
		setUserData({ ...userData, [name]: value });
	};

	useEffect(() => {
		consultCases();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const consultCases = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const curp_base64 = await encrypt(user?.curp || '', key);
			// const curp_base64 = await encrypt('VAVE931105HDGLRR05', key);
			const nss_base64 = await encrypt(user?.nss || '', key);
			const responseTotal = await returnOverpaymentsAPI.validateCURP(
				curp_base64
			);
			if (Number(responseTotal?.result?.code || '') === 9999) {
				setOpenModalTemp(true);
				return;
			}
			const responseAmount = await returnOverpaymentsAPI.consultCases(
				nss_base64
			);
			setDataCurp(responseTotal.result);
			if (responseTotal?.result?.descripcion !== 'activo') {
				setAlert({
					show: true,
					message:
						responseTotal.result.descripcion ||
						responseTotal.result.description ||
						INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			} else {
				if (responseAmount?.result) {
					if (responseAmount?.result?.codigo === '006') {
						setCaso(false);
						setAlert({
							show: false,
							message: '',
							severity: 'error',
						});
						setButtonInfo(false);
					} else if (responseAmount?.result?.codigo === '100') {
						setDataCaso(responseAmount.result);
						setPage(5);
					} else {
						setCaso(true);
						setAlert({
							show: false,
							message: '',
							severity: 'error',
						});
						setButtonInfo(false);
					}
				} else {
					setCaso(false);
					setAlert({
						show: true,
						message:
							responseAmount.error.description ||
							responseAmount.error.descripcion ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				}
			}
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setPassw(value);
	};

	const handleFileCer = (e: any) => {
		if (e.target.files.length > 0) {
			var readerFile = new FileReader();
			readerFile.readAsDataURL(e.target.files[0]);
			readerFile.onload = function (event) {
				setcerB64(
					(event.target?.result as string).replace(
						/^data:(.*;base64,)?/,
						''
					)
				);
			};
		}
		if (e.target.files.length > 0) {
			var readerFile = new FileReader();
			readerFile.onload = function (event) {
				setCer(event?.target?.result as string);
			};
			readerFile.readAsArrayBuffer(e.target.files[0]);
			setFiles({
				...files,
				[e.target.name]: {
					name: e.target.files[0].name,
					data: e.target.files[0],
					type: e.target.files[0].type,
				},
			});
			return cer;
		}
	};

	const handleFileKey = (e: any) => {
		if (e.target.files.length > 0) {
			var readerFile = new FileReader();
			readerFile.onload = function (event) {
				setKey((event?.target?.result as string) || '');
			};
			readerFile.readAsArrayBuffer(e.target.files[0]);
			setFiles({
				...files,
				[e.target.name]: {
					name: e.target.files[0].name,
					data: e.target.files[0],
				},
			});
		}
	};

	const handleSubmitFirmar = () => {
		var inputs = {
			keyBinary: key,
			cerBinary: cer,
			cerB64: cerB,
			pdfB64: dataSello.pdf,
			pdfContentType: 'application/pdf',
			password: passw,
			serverIP: serverIP,
		};

		firmar(inputs, (error: any, response: any) => {
			if (error === null) {
				setLoading(false);
				setPdfSigned(response.pdfSignedB64);
				handleOnSubmitVerify(response.pdfSignedB64);
			} else {
				setLoading(false);
				setAlert({
					show: true,
					message: error,
					severity: 'error',
				});
			}
		});
	};

	const handleOnSubmitSat = async (a: any, b: any) => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const rfcSat_base64 = await encrypt(a.toString() || '', key);
			const nombreSat_base64 = await encrypt(b.toString() || '', key);
			const responseFile = await returnOverpaymentsAPI.validEfirma(
				nss_base64,
				nombreSat_base64,
				rfcSat_base64
			);
			if (responseFile?.result?.valid === false) {
				setLoading(false);
				setAlert({
					show: true,
					message:
						responseFile.result.description ||
						INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			} else {
				if (dataCaso.codigo === '100') {
					setLoading(false);
					handleOnSubmitVerify(pdfReintento);
				} else {
					setLoading(false);
					setPage(2);
					setAlert({
						show: false,
						message: '',
						severity: 'error',
					});
				}
			}
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const handleReintentar = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const caso_base64 = await encrypt(
				dataCaso.caso.toString() || '',
				key
			);
			const responseReintent = await returnOverpaymentsAPI.reintentoDoct(
				caso_base64
			);
			if (responseReintent?.result?.codigo === 0) {
				setPage(1);
				setPdfReintento(responseReintent.result.pdf);
				setAlert({
					show: false,
					message: '',
					severity: 'error',
				});
			} else {
				setAlert({
					show: true,
					message:
						responseReintent.result.description ||
						INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			}
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const handleOnSubmitVerify = async (pdfFirmado: string) => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			//const nss_base64 = await encrypt('62755517802', key);
			const pdf_base64 = await encrypt(pdfFirmado, key);
			const responseFile = await returnOverpaymentsAPI.attachedFile(
				nss_base64,
				pdf_base64
			);
			if (responseFile.error) {
				setLoading(false);
				setAlert({
					show: true,
					message:
						responseFile?.error?.descripcion ||
						INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			} else {
				setDataCaso(responseFile.result);
				setAlert({
					show: false,
					message: '',
					severity: 'error',
				});
				setPage(5);
				setLoading(false);
			}
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const handleOnSubmitClabe = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const clabe_base64 = await encrypt(
				userData.clabe.toString() || '',
				key
			);
			const sesion_base64 = dataCurp.sesion;
			returnOverpaymentsAPI
				.validClabe(nss_base64, clabe_base64)
				.then((responseClabe) => {
					if (responseClabe?.result) {
						regBitacoraNotOld(returnOverpaymentsAPI.schemeUrl, {
							nss: user?.nss.toString(),
							clabe: userData.clabe.toString(),
						});
						setDataClabe(responseClabe.result);
						setPage(4);
						returnOverpaymentsAPI
							.validRetirement(
								nss_base64,
								clabe_base64,
								sesion_base64
							)
							.then((responseSession) => {
								if (responseSession?.result) {
									if (
										responseSession?.result?.code === '97'
									) {
										setAlert({
											show: true,
											message:
												INTERNAL_MESSAGES.PROCESS_PROCESS,
											severity: 'error',
										});
									} else if (
										responseSession?.result?.code === '2004'
									) {
										setAlert({
											show: true,
											message:
												responseSession?.result
													?.descripcion ||
												INTERNAL_MESSAGES.ERROR_MESSAGE,
											severity: 'error',
										});
									} else {
										setDataSello(responseSession.result);
										setAlert({
											show: false,
											message: '',
											severity: 'error',
										});
									}
								} else {
									setAlert({
										show: true,
										message:
											INTERNAL_MESSAGES.ERROR_MESSAGE,
										severity: 'error',
									});
								}
							})
							.catch((errorSession) => {
								setAlert({
									show: true,
									message:
										errorSession?.error?.descripcion ||
										errorSession?.error?.description ||
										INTERNAL_MESSAGES.ERROR_MESSAGE,
									severity: 'error',
								});
							})
							.finally(() => setLoading(false));
					} else {
						setLoading(false);
						setAlert({
							show: true,
							message: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				})
				.catch((errorClabe) => {
					setLoading(false);
					setAlert({
						show: true,
						message:
							errorClabe?.error?.descripcion ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				});
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	return (
		<Layout>
			<>
				<ModalMessageTemp show={openModalTemp} />
				{page === 0 && (
					<InfoPayments
						setPage={setPage}
						buttonInfo={buttonInfo}
						hasError={alert}
						onCloseError={() => {
							setAlert({
								show: false,
								message: '',
								severity: alert.severity,
							});
						}}
					/>
				)}
				{page === 1 && (
					<Files
						setPage={setPage}
						handleCer={handleFileCer}
						handleKey={handleFileKey}
						setAlert={setAlert}
						fileCer={cer}
						fileKey={key}
						fileCerB={cerB}
						handleOnSubmitSat={handleOnSubmitSat}
						onChange={onChangePassword}
						files={files}
						passw={passw}
						hasError={alert}
						openModal={openModal}
						setOpenModal={setOpenModal}
						caso={caso}
						onCloseError={() => {
							setAlert({
								show: false,
								message: '',
								severity: alert.severity,
							});
						}}
					/>
				)}
				{page === 2 && <Amount setPage={setPage} />}
				{page === 3 && (
					<BankAccount
						setPage={setPage}
						onConfirm={handleOnSubmitClabe}
						hasError={alert}
						onChange={setUserDataClabe}
						onCloseError={() => {
							setAlert({
								show: false,
								message: '',
								severity: alert.severity,
							});
						}}
					/>
				)}
				{page === 4 && (
					<Verify
						setPage={setPage}
						DataClabe={dataClabe}
						hasError={alert}
						onConfirm={handleSubmitFirmar}
						onCloseError={() => {
							setAlert({
								show: false,
								message: '',
								severity: alert.severity,
							});
						}}
					/>
				)}
				{page === 5 && (
					<Resume
						setPage={setPage}
						DataCaso={dataCaso}
						pdfSigned={pdfSigned}
						handleReintentar={handleReintentar}
						hasError={alert}
						onCloseError={() => {
							setAlert({
								show: false,
								message: '',
								severity: alert.severity,
							});
						}}
					/>
				)}
				{page === 6 && <Finish setPage={setPage} />}
				<ModalLoading loading={loading} />
			</>
		</Layout>
	);
};

export default LayoutNotice;
