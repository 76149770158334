import React from 'react';
import Divider from '@mui/material/Divider';
import checkBlue from '../../../assets/img/mini-check-azul.png';
import { useStyles } from './styles';

interface Props {
	setPage: (data: number) => void;
}
const CreditNone = ({ setPage }: Props) => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.container}>
				<div className={classes.containerMin}>
					<div className={classes.checkHeader}>
						<img
							src={checkBlue}
							className={classes.checkHeaderImg}
							alt="check"
						/>
						<div className={classes.checkHeaderText}>
							Revisa que los datos de tu Aviso sean correctos.
							<div style={{ marginLeft: 15, fontSize: 14 }}>
								<p>
									· Si hay alguna diferencia en los dígitos de tu Numero
									de Seguridad Social (NSS).{' '}
								</p>
								<p>
									· Si tu Aviso sale a nombre de una empresa a la que tú
									no perteneces.
								</p>
							</div>
						</div>
					</div>
					<div className={classes.checkHeader}>
						<img
							src={checkBlue}
							className={classes.checkHeaderImg}
							alt="check"
						/>
						<div className={classes.checkHeaderText}>
							Comunícate al Infonatel; ahí encontrarás ayuda para solucionar
							el problema.
						</div>
					</div>
				</div>

				<Divider style={{ marginBlock: 50 }} />
				<div className={classes.contentDescription}>
					<div>
						<h3 className={classes.contentDescriptionTopTitle}>
							No tenemos registro de este número de crédito.{' '}
						</h3>
						<p className={classes.contentDescriptionTopText}>
							Si estás haciendo tu trámite para obtenerlo, tu aviso tardará
							20 días hábiles.
						</p>
					</div>
					<div>
						<p className={classes.contentDescriptionBottomText}>
							Si tienes problemas para obtener tu Aviso de Suspensión y
							Retención de Descuentos,
						</p>
						<div>
							<label
								onClick={() => {
									setPage(3);
								}}
								className={classes.stepLink}
								style={{
									cursor: 'pointer',
									textDecoration: 'underline red',
								}}
							>
								esta información es importante para tí
							</label>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CreditNone;
