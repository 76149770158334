export const complaintForm = (entities = []) => [
	{
		id: 'follow-persona_queja',
		name: 'personaQueja',
		label: 'Empresa o persona causante de la queja',
		required: true,
	},
	{
		id: 'follow-tramite',
		name: 'tramite',
		label: 'Trámite o servicio solicitado',
		required: true,
	},
	{
		id: 'follow-estado',
		name: 'estado',
		label: 'Entidad en donde ocurrieron los hechos',
		required: true,
		options: entities,
	},
	{
		id: 'follow-direcPersona',
		name: 'direcPersona',
		label: 'Dirección donde ocurrieron los hechos',
		required: true,
		limit: 80,
	},
	{
		id: 'follow-telefono_persona',
		name: 'telPersona',
		label: 'Teléfono del causante de la queja',
		required: true,
	},
	{
		id: 'follow-caso_previo',
		name: 'casoPrevio',
		label: 'Caso asociado al trámite proporcionado',
		required: false,
		limit: 10,
	},
];
