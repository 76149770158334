import theme from '../../../config/theme';
import { MIUC_PRODUCTS } from '../constants/products';

import HelpPanel from '../../../components/HelpPanel';
import HelpPanelv2 from '../components/HelpPanel';
import { HeaderTooltip } from '../components/HeaderTooltip/headerTooltip';
import creditoInfonavit from '../../../assets/img/productsMIUC/credito-infonavit.svg';
import { formatMoney2, formatPor } from '../../../utils/validators';
import { Badge } from '@mui/material';
import {
	IKeyRow,
	formatTypesRow,
} from '../components/ProductsMiuc/interfaces/product.interface';
import TooltipCustom from '../components/TooltipCustom';
import ErrorIcon from '@mui/icons-material/Error';

export const textInfoBanner =
	'Considera que las condiciones financieras pueden cambiar al momento de originar tu crédito.';
export const textInfonavit =
	'Ya elegiste tu crédito, revisa los pasos para solicitarlo y recuerda que en el Infonavit todos los trámites son gratuitos.';

export const formatTypes = {
	MONEY: 'MONEY',
	PERCENTAGE: 'PERCENTAGE',
	YEAR: 'YEAR',
	MONTH: 'MONTH',
};

export interface IFormat {
	key: string;
	type: 'MONEY' | 'PERCENTAGE' | 'YEAR' | 'MONTH';
}

export const formatValues = (obj: any, formatTo: IFormat[]) => {
	let formatedValues: any = {};

	formatTo.forEach((format: IFormat) => {
		const { key, type } = format;
		if (obj[key] !== undefined) {
			switch (type) {
				case formatTypes.MONEY:
					if (isNaN(obj[key])) {
						formatedValues[key] = obj[key];
					} else {
						formatedValues[key] = formatMoney2(Number(obj[key]));
					}
					break;
				case formatTypes.PERCENTAGE:
					if (isNaN(obj[key])) {
						formatedValues[key] = obj[key];
					} else {
						const number = formatPor(obj[key]);
						formatedValues[key] = `${number}%`;
					}
					break;
				case formatTypes.YEAR:
					if (isNaN(obj[key])) {
						formatedValues[key] = obj[key];
					} else {
						formatedValues[key] = `${obj[key]} año${
							obj[key] > 1 ? 's' : ''
						}`;
					}
					break;
				case formatTypes.MONTH:
					if (isNaN(obj[key])) {
						formatedValues[key] = obj[key];
					} else {
						formatedValues[key] = `${obj[key]} meses`;
					}
					break;
			}
		}
	});

	return formatedValues;
};

interface IConfig {
	image?: string;
	tableRowTitles: {
		title: string;
		key: string;
		styleTitle?: any;
		styleDescription?: any;
		alignDescription?: any;
		type?: string;
	}[];
}

interface rowTitle {
	key: string;
	title: string | JSX.Element | JSX.Element[];
	textPrimary?: boolean;
	textShadow?: boolean;
	hideRow?: boolean;
	type?: string;
	format?: {
		type: string;
	};
}

export const selectionResumeConfig: {
	[x: string]: IConfig;
} = {
	[MIUC_PRODUCTS.COMPRA]: {
		tableRowTitles: [
			{
				title: 'Retención de nómina para pagar tu crédito',
				key: 'desTot',
				styleTitle: { fontWeight: 'bold' },
				styleDescription: {
					fontWeight: 'bold',
					color: theme.palette.info.main,
				},
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Monto del crédito',
				key: 'mmcMon',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Más tu ahorro',
				key: 'ahorro',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Menos gastos de titulación',
				key: 'gasMon',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Tasa de interés anual',
				key: 'tasInt',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.PERCENTAGE,
			},
		],
	},
	[MIUC_PRODUCTS.AUTOPRODUCCION]: {
		tableRowTitles: [
			{
				title: 'Retención de nómina para pagar tu crédito',
				key: 'pago',
				styleTitle: { fontWeight: 'bold' },
				styleDescription: {
					fontWeight: 'bold',
					color: theme.palette.info.main,
				},
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Tasa de interés',
				key: 'tasaInteres',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.PERCENTAGE,
			},
			{
				title: 'Plazo estimado en meses',
				key: 'plazoEstimado',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONTH,
			},
			{
				title: 'Garantía del crédito',
				key: 'garantia',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
		],
	},
	[MIUC_PRODUCTS.CONSTRUCCION]: {
		image: creditoInfonavit,
		tableRowTitles: [
			{
				title: 'Retención de nómina para pagar tu crédito',
				key: 'desTot',
				styleTitle: { fontWeight: 'bold' },
				styleDescription: {
					fontWeight: 'bold',
					color: theme.palette.info.main,
				},
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Monto del crédito',
				key: 'mmcMon',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Más tu ahorro',
				key: 'ahorro',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Menos gastos',
				key: 'gasMon',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Tasa de interés anual',
				key: 'tasInt',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.PERCENTAGE,
			},
		],
	},
	[MIUC_PRODUCTS.HIPOTECA]: {
		image: creditoInfonavit,
		tableRowTitles: [
			{
				title: 'Retención de nómina para pagar tu crédito',
				key: 'desTot',
				styleTitle: { fontWeight: 'bold' },
				styleDescription: {
					fontWeight: 'bold',
					color: theme.palette.info.main,
				},
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Monto del crédito',
				key: 'mmcMon',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Más tu ahorro',
				key: 'ahorro',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Menos gastos',
				key: 'gasMon',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Tasa de interés anual',
				key: 'tasInt',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.PERCENTAGE,
			},
		],
	},
	[MIUC_PRODUCTS.CREDITERRENO]: {
		tableRowTitles: [
			{
				title: 'Retención mensual',
				key: 'desTot',
				styleTitle: { fontWeight: 'bold' },
				styleDescription: {
					fontWeight: 'bold',
					color: theme.palette.info.main,
				},
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Monto del crédito',
				key: 'mmcMon',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Más tu ahorro',
				key: 'ahorro',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Menos gastos de titulación',
				key: 'gasMon',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Tasa de interés anual',
				key: 'tasInt',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.PERCENTAGE,
			},
		],
	},
	[MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION]: {
		tableRowTitles: [
			{
				title: 'Retención de nómina para pagar tu crédito',
				key: 'desTot',
				styleTitle: { fontWeight: 'bold' },
				styleDescription: {
					fontWeight: 'bold',
					color: theme.palette.info.main,
				},
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Monto de crédito para compra de terreno',
				key: 'mmcMonSuelo',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Monto de crédito para construcción',
				key: 'montoConstruccion',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Más tu ahorro',
				key: 'ahorro',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
		],
	},
	[MIUC_PRODUCTS.MEJORAVIT]: {
		image: creditoInfonavit,
		tableRowTitles: [
			{
				title: 'Retención de nómina para pagar tu crédito',
				key: 'desTot',
				styleTitle: { fontWeight: 'bold' },
				styleDescription: {
					fontWeight: 'bold',
					color: theme.palette.info.main,
				},
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Tasa de interés',
				key: 'tasInt',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.PERCENTAGE,
			},
			{
				title: 'Plazo',
				key: 'plazo',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.YEAR,
			},
		],
	},
	[MIUC_PRODUCTS.CREDITO_SEGURO]: {
		tableRowTitles: [
			{
				title: 'Retención de nómina para pagar tu crédito',
				key: 'desTot',
				styleTitle: { fontWeight: 'bold' },
				styleDescription: {
					fontWeight: 'bold',
					color: theme.palette.info.main,
				},
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Monto del crédito',
				key: 'mmcMon',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Más tu ahorro',
				key: 'ahorro',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Menos gastos',
				key: 'gasMon',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Tasa de interés anual',
				key: 'tasInt',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.PERCENTAGE,
			},
		],
	},
	[MIUC_PRODUCTS.MEJORA_DIRECTA]: {
		tableRowTitles: [
			{
				title: 'Pago mensual',
				key: 'pagoMensual',
				styleTitle: { fontWeight: 'bold' },
				styleDescription: {
					fontWeight: 'bold',
					color: theme.palette.info.main,
				},
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Monto del crédito',
				key: 'montoCredito',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Menos gastos',
				key: 'gastosOperacion',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.MONEY,
			},
			{
				title: 'Tasa de interés anual',
				key: 'tasaInteres',
				styleTitle: { fontWeight: 'bold' },
				alignDescription: 'right',
				type: formatTypes.PERCENTAGE,
			},
		],
	},
};

export const rowsTitleSeguro: IKeyRow[] = [
	{
		title: (
			<HeaderTooltip
				title="Retención mensual de tu salario"
				tooltip="Cantidad que tu patrón descontará vía nómina para el pago de tu crédito en Cofinavit, si tu pierdes tu relación laboral, deberás pagar este monto más la aportación patronal (5% de tu salario)."
			/>
		),
		key: 'desTot',
		textPrimary: true,
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Monto de crédito"
				tooltip="Cantidad que te presta el Infonavit."
			/>
		),
		key: 'mmcMon',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Más el ahorro que harás en Banco del Bienestar:"
				tooltip="Cantidad que deberás tener ahorrado al momento de solicitar tu crédito."
			/>
		),
		key: 'monAhoMin',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Más tu ahorro"
				tooltip="Cantidad que tienes en la Subcuenta de Vivienda conformada por las aportaciones de tu patrón."
			/>
		),
		key: 'ahorro',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Menos gastos:"
				tooltip="Estos gastos de titulación corresponden únicamente a honorarios notariales que serán descontados del monto de tu crédito. Para Crédito Infonavit, se determinan considerando el valor de avalúo. (En todos los casos se deberá de contemplar el IVA)."
			/>
		),
		key: 'gasMon',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Contarías con:"
				tooltip="Cantidad que resulta de sumar el monto de crédito más tu ahorro, restándole los gastos."
			/>
		),
		key: 'cdcMon',
		format: { type: formatTypesRow.MONEY },
		textShadow: true,
	},
	// {
	// 	title: (
	// 		<HeaderTooltip
	// 			title="Monto máximo para ecotecnologias:"
	// 			tooltip={<HelpPanel type="ECOTECNOLOGIAS" />}
	// 		/>
	// 	),
	// 	key: 'monEco',
	// 	format: { type: formatTypesRow.MONEY },
	// },
];

export const getRowTitleCompra = (
	conyuge: boolean,
	complementos: {
		tieneHipotecaVerde: boolean;
		tieneHogarATuMedida: boolean;
		tieneHipotecaConServicios: boolean;
	}
) => {
	// console.log({conyuge, complementos})
	const rowsTitleCompra: IKeyRow[] = [
		{
			title: (
				<HeaderTooltip
					title="Retención de nómina para pagar tu crédito:"
					tooltip="Cantidad que tu patrón descontará vía nómina para el pago de tu crédito. En Cofinavit, si pierdes tu relación laboral, deberás pagar este monto más la aportación patronal (5% de tu salario)."
				/>
			),
			key: 'desTot',
			textPrimary: true,
			format: { type: formatTypesRow.MONEY },
		},
		{
			title: (
				<HeaderTooltip
					title="Monto de crédito:"
					tooltip="Cantidad que te presta el Infonavit."
				/>
			),
			key: 'mmcMon',
			format: { type: formatTypesRow.MONEY },
		},
		{
			title: (
				<HeaderTooltip
					title="Más tu ahorro:"
					tooltip="Cantidad que tienes en la Subcuenta de Vivienda conformada por las aportaciones de tu patrón."
				/>
			),
			key: 'ahorro',
			format: { type: formatTypesRow.MONEY },
		},
		{
			title: (
			  <HeaderTooltip
				title="Más tus aportaciones voluntarias:"
				tooltip="Monto de las aportaciones que deseas utilizar."
			  />
			),
			key: 'aportaciones',
			format: { type: formatTypesRow.MONEY },
			hideRow: true,
		  },
		{
			title: (
				<HeaderTooltip
					title="Más monto de crédito Equipa tu casa:"
					tooltip="Monto adicional de crédito para mejorar tu vivienda que se otorga en caso de que el valor de la vivienda sea menor al monto máximo de crédito."
				/>
			),
			key: 'montoETC',
			format: { type: formatTypesRow.MONEY },
			hideRow: true,
		},
		{
			title: (
				<HeaderTooltip
					title="Más monto de crédito para uso en comercios:"
					tooltip="Monto para consumo en los comercios afiliados al Infonavit."
				/>
			),
			key: 'montoETC80',
			format: { type: formatTypesRow.MONEY },
			hideRow: true,
		},
		{
			title: (
				<HeaderTooltip
					title="Más monto de crédito para mano de obra:"
					tooltip="Monto para pagar mano de obra, será del 20% del importe que se te otorgó para Equipa tu casa."
				/>
			),
			key: 'montoETC20',
			format: { type: formatTypesRow.MONEY },
			hideRow: true,
		},
		{
			title: (
				<HeaderTooltip
					title="Menos gastos de titulación:"
					tooltip={
						"Estos gastos de titulación corresponden únicamente a honorarios notariales que serán descontados del monto de tu crédito. Para Crédito Infonavit, se determinan considerando el valor de avalúo. (En todos los casos se deberá de contemplar el IVA)."
					}
				/>
			),
			key: 'gasMon',
		},
		{
			title: (
				<HeaderTooltip
					title="Diferencia a cubrir:"
					tooltip="Cantidad a cubrir con tus propios recursos en caso de que el valor de la vivienda supere el monto con el que contarías."
				/>
			),
			key: 'diffCubrir',
			format: { type: formatTypesRow.MONEY },
		},
		{
			title: (
				<HeaderTooltip
					title="Tasa de interés anual:"
					tooltip="Procentaje a pagar por el monto de crédito otorgado."
				/>
			),
			key: 'tasInt',
			format: { type: formatTypesRow.PERCENTAGE },
		},
		{
			title: '',
			key: 'complementos',
		},
		{
			title: (
				<HeaderTooltip
					title="Contarías con:"
					tooltip="Cantidad que resulta de sumar el monto de crédito más tu ahorro restándole los gastos de titulación."
				/>
			),
			key: 'cdcMon',
			format: { type: formatTypesRow.MONEY },
			textShadow: true,
		},
	];

	const complementosIdx = rowsTitleCompra.findIndex(
		(row) => row.key === 'complementos'
	);

	rowsTitleCompra[complementosIdx] = {
		...rowsTitleCompra[complementosIdx],
		title: (
			<>
				<HeaderTooltip
					title="Complementos:"
					tooltip={
						<>
							<TooltipCustom
								placement="right"
								title={
									<>
										{/* {
                complementos.tieneHipotecaVerde && conyuge && <p>
                  <b>Hipoteca verde: </b>
                  Monto adicional de crédito para que instales
                  ecotecnologías que te permitan disminuir tus consumos de
                  agua, luz y gas y de esta forma, ahorres dinero.
                </p>
              } */}
										{complementos.tieneHogarATuMedida && (
											<p>
												<b>Hogar a tu medida: </b>
												Beneficio para personas con
												discapacidad, puede ser tú o un
												familiar que viva contigo.
											</p>
										)}
										{/* {
                complementos.tieneHipotecaConServicios && <p>
                  <b>Hipoteca con servicios: </b>
                  Es la opción para pagar el predial de tu vivienda o la cuota
                  de mantenimiento, sumándose a tu descuento vía nómina para el
                  pago de tu crédito.
                </p>
              } */}
									</>
								}
							>
								<ErrorIcon
									sx={{
										color: theme.palette.primary.main,
										'&:hover': {
											cursor: 'pointer',
										},
									}}
								/>
							</TooltipCustom>
						</>
					}
				/>
			</>
		),
	};
	return rowsTitleCompra;
};

export const rowsTitleHipoteca: IKeyRow[] = [
	{
		title: (
			<HeaderTooltip
				title="Retención de nómina para pagar tu crédito:"
				tooltip="Cantidad que tu patrón descontará vía nómina para el pago de tu crédito."
			/>
		),
		key: 'desTot',
		textPrimary: true,
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Monto de crédito"
				tooltip="Cantidad que te presta el Infonavit."
			/>
		),
		key: 'mmcMon',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Más tu ahorro"
				tooltip="Cantidad que tienes en la Subcuenta de Vivienda conformada por las aportaciones de tu patrón."
			/>
		),
		key: 'ahorro',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Menos gastos:"
				tooltip="Estos gastos de titulación corresponden únicamente a honorarios notariales que serán descontados del monto de tu crédito. Para Crédito Infonavit, se determinan considerando el valor de avalúo. (En todos los casos se deberá de contemplar el IVA)."
			/>
		),
		key: 'gasMon',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Diferencia a cubrir:"
				tooltip="Cantidad a cubrir con tus propios recursos en caso de que el valor de la vivienda supere el monto con el que contarías."
			/>
		),
		key: 'diffCubrir',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Tasa de interés:"
				tooltip="Procentaje a pagar por el monto de crédito otorgado durante el plazo seleccionado."
			/>
		),
		key: 'tasInt',
		format: { type: formatTypesRow.PERCENTAGE },
	},
	{
		title: (
			<HeaderTooltip
				title="Plazo:"
				tooltip="Plazo máximo en el que se deberá pagar el crédito más intereses."
			/>
		),
		key: 'pzoCre',
		format: { type: formatTypesRow.YEAR },
	},
	{
		title: (
			<HeaderTooltip
				title="Garantía del crédito:"
				tooltip="Puede ser Hipotecaría o Saldo de Subcuenta de vivienda."
			/>
		),
		key: 'garantia',
	},
	{
		title: (
			<HeaderTooltip
				title="Contarías con:"
				tooltip="Cantidad que resulta de sumar el monto de crédito más tu ahorro, restándole los gastos."
			/>
		),
		key: 'cdcMon',
		textShadow: true,
		format: { type: formatTypesRow.MONEY },
	},
];

export const rowsTitleMejoraDirecta = [
	{
		title: (
			<HeaderTooltip
				title="Tu pago mensual:"
				tooltip="Cantidad que deberás pagar mensualmente a tu crédito."
			/>
		),
		key: 'pagoMensual',
		textPrimary: true,
		type: formatTypes.MONEY,
	},
	{
		title: (
			<HeaderTooltip
				title="Monto del crédito"
				tooltip="Cantidad que te presta el Infonavit."
			/>
		),
		key: 'montoCredito',
		type: formatTypes.MONEY,
	},
	{
		title: (
			<HeaderTooltip
				title="Menos gastos:"
				tooltip="Gastos de originación. 10% del crédito otorgado con mínimo de $500 y máximo de $5,000."
			/>
		),
		key: 'gastosOperacion',
		type: formatTypes.MONEY,
	},
	{
		title: (
			<HeaderTooltip
				title="Tasa de interés:"
				tooltip="Porcentaje a pagar por el monto del crédito otorgado."
			/>
		),
		key: 'tasaInteres',
		type: formatTypes.PERCENTAGE,
	},
	{
		title: (
			<HeaderTooltip
				title="Contarías con:"
				tooltip="Cantidad que resulta de sumar el monto de crédito más tu ahorro, restándole los gastos."
			/>
		),
		key: 'contarias',
		type: formatTypes.MONEY,
		textShadow: true,
	},
	{
		title: (
			<HeaderTooltip
				title="Plazo:"
				tooltip="Periodo máximo en el que se deberá pagar el crédito."
			/>
		),
		key: 'plazo',
		type: formatTypes.MONTH,
	},
];

export const rowsTitleMVIT: IKeyRow[] = [
	{
		title: (
			<HeaderTooltip
				title="Retención de nómina para pagar tu crédito:"
				tooltip="Cantidad que tu empleador (patrón) descontará de nómina para el pago de tu crédito."
			/>
		),
		key: 'pagoMensual',
		textPrimary: true,
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Monto de crédito"
				tooltip="Cantidad que te presta el Infonavit."
			/>
		),
		key: 'montoCredito',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Monto de crédito para uso en comercios:"
				tooltip="Cantidad destinada para compra de productos autorizados únicamente en comercios afiliados al Infonavit."
			/>
		),
		key: 'montoCreComercio',
		hideRow: true,
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Monto de crédito para mano de obra:"
				tooltip="Cantidad destinada para pago de mano de obra que puedes retirar en efectivo, determinada al momento de contratar tu crédito."
			/>
		),
		key: 'montoCreManoObra',
		hideRow: true,
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Monto del crédito para la regularización de vivienda:"
				tooltip="Cantidad de apoyo para que realices la titulación de tu vivienda."
			/>
		),
		key: 'montoCreRegVivienda',
		hideRow: true,
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Tasa de interés:"
				tooltip="Procentaje a pagar por el monto de crédito otorgado durante el plazo seleccionado."
			/>
		),
		key: 'tasa',
		format: { type: formatTypesRow.PERCENTAGE },
	},
	{
		title: (
			<HeaderTooltip
				title="Plazo:"
				tooltip="Periodo máximo en el que se deberá pagar el crédito incluyendo sus intereses."
			/>
		),
		key: 'plazo',
		format: { type: formatTypesRow.YEAR },
	},
];

export const rowsTitleConstruYo: IKeyRow[] = [
	{
		title: (
			<HeaderTooltip
				title="Retención de nómina para pagar tu crédito:"
				tooltip="Cantidad que tu patrón descontará de tu salario para el pago de tu crédito o la que deberás pagar directamente si pierdes tu relación laboral."
			/>
		),
		key: 'pago',
		textPrimary: true,
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Monto del crédito:"
				tooltip="Cantidad que te presta el Infonavit."
			/>
		),
		key: 'contarias',
		textShadow: true,
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Tasa de interés:"
				tooltip="Es el porcentaje que debes pagar por el crédito."
			/>
		),
		key: 'tasaInteres',
		format: { type: formatTypesRow.PERCENTAGE },
	},
	{
		title: (
			<HeaderTooltip
				title="Plazo estimado en meses:"
				tooltip={
					<Badge
						badgeContent={<HelpPanelv2 type="PLAZO_ESTIMADO" />}
					></Badge>
				}
			/>
		),
		key: 'plazoEstimado',
		format: { type: formatTypesRow.MONTH },
	},
	{
		title: (
			<HeaderTooltip
				title="Garantía del crédito:"
				tooltip="Respaldo del crédito en caso de no cumplir con el pago."
			/>
		),
		key: 'garantia',
		format: { type: formatTypesRow.MONEY },
	},
];

export const rowsTitleConstruir: IKeyRow[] = [
	{
		title: (
			<HeaderTooltip
				title="Retención de nómina para pagar tu crédito:"
				tooltip="Cantidad que tu patrón descontará vía nómina para el pago de tu crédito."
			/>
		),
		key: 'desTot',
		textPrimary: true,
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Monto de crédito"
				tooltip="Cantidad que te presta el Infonavit."
			/>
		),
		key: 'mmcMon',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Más tu ahorro"
				tooltip="Cantidad que tienes en la Subcuenta de Vivienda conformada por las aportaciones de tu patrón."
			/>
		),
		key: 'ahorro',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Menos gastos:"
				tooltip="Estos gastos de titulación corresponden únicamente a honorarios notariales que serán descontados del monto de tu crédito. Para Crédito Infonavit, se determinan considerando el valor de avalúo. (En todos los casos se deberá de contemplar el IVA)."
			/>
		),
		key: 'gasMon',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Diferencia a cubrir:"
				tooltip="Cantidad a cubrir con tus propios recursos en caso de que el valor de la vivienda supere el monto con el que contarías."
			/>
		),
		key: 'diffCubrir',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Contarías con:"
				tooltip="Cantidad que resulta de sumar el monto de crédito más tu ahorro, restándole los gastos."
			/>
		),
		key: 'cdcMon',
		format: { type: formatTypesRow.MONEY },
		textShadow: true,
	},
];

export const getUMA3_001_MIUC = (salarioMinimoDF: number): number => {
	const uma_3_001 = Math.floor(3 * 0.01 * 30.4 * salarioMinimoDF * 10) / 10;

	return uma_3_001;
};
