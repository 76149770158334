import React, { useEffect, useState } from 'react';
import TextInput from '../../components/TextInput';
import { FormControlLabel, Paper, Radio, RadioGroup, useMediaQuery } from '@mui/material';
import { useStyles } from './styles';
import CustomButton from '../../components/Button';
import { useTheme } from '@mui/material/styles';
import { HeaderList } from '../../components/HeaderList';
import { useNavigate } from 'react-router-dom';
import CustomAlert from '../../components/CustomAlert';
import { IAlert } from '../../interfaces/alert';

interface Props {
	setPage: (data: number) => void;
	rfc?: string;
	alertInitialStatus: IAlert;
}

const RfcHome = ({ setPage, rfc, alertInitialStatus }: Props) => {
	const [displayView, setDisplayView] = useState(0);
	const [enableButton, setEnableButton] = useState(true);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [pageTemp, setPageTemp] = useState(0);
	const navigate = useNavigate();
	const classes = useStyles();
	const describeText = [
		'En este servicio puedes corregir el Registro Federal de Contribuyentes (RFC) que tenemos registrado en nuestro sistema.',
		'Para proceder con la corrección, es necesario que tu Clave Única de Registro de Población (CURP) sea la que está asociada al sistema del Servicio de Administración Tributaria (SAT).',
		'Si cuentas con firma electrónica avanzada, recibo de nómina digital o tu CURP es la misma que la registrada en el Sistema SAT, elige una opción y continua con el proceso.',
		'En caso de que no cuentes con ninguna de las anteriores, puedes hacer una cita en cualquiera de los centros de atención del Infonavit.',
	];
	const handleOnChangeRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name } = event.target;

		if (event.target.checked && name === 'firmElec') {
			setPageTemp(1);
			setEnableButton(false);
		} else if (event.target.checked && name === 'reciboDig') {
			setPageTemp(2);
			setEnableButton(false);
		} else if (event.target.checked && name === 'validSat') {
			setPageTemp(3);
			setEnableButton(false);
		} else if (event.target.checked && name === 'cita') {
			setPageTemp(4);
			setEnableButton(false);
		}
	};

	const [alert, setAlert] = useState<IAlert>(alertInitialStatus);

	useEffect(() => {
		setAlert({
			show: alertInitialStatus.show,
			message: alertInitialStatus.message,
			severity: alertInitialStatus.severity,
		});
	}, [alertInitialStatus]);

	useEffect(() => {
		rfc === '' && setDisplayView(1);
	}, [alert, rfc]);

	return (
		<>
			<HeaderList
				title="Registro o corrección de RFC"
				date="12 julio 2021"
				list={describeText}
			/>
			<Paper>
				<div
					id="rfcCorrection"
					style={{
						display: displayView === 0 ? 'inline' : 'none',
					}}
				>
					<h3 className={classes.h3}>
						Registro Federal de Contribuyentes (RFC) registrado:{' '}
					</h3>
					<div className={classes.inputContainer}>
						<div className={classes.inputRfc}>
							<TextInput
								data-testid="rfcInput"
								id="rfc"
								name="rfcInput"
								label="RFC"
								disabled={true}
								sx={{ color: 'red' }}
								value={rfc ? rfc : ''}
							/>
						</div>
					</div>
					<div className={classes.inputContainer}>
						<div className={classes.inputRfc}>
							<p className={classes.p}>
								¿El Registro Federal de Contribuyente que tenemos
								registrado es correcto?
							</p>
						</div>
					</div>

					<div className={classes.inputContainer}>
						<div className={classes.inputRfcAlter}>
							<CustomAlert
								data-testid="mensageAlerttwo"
								message={alert.message}
								severity={alert.severity}
								show={alert.show}
								onClose={() => {
									setAlert({
										show: false,
										message: '',
										severity: alert.severity,
									});
								}}
							/>
						</div>
					</div>
					<div className={classes.botonContainer}>
						<div>
							<CustomButton
								data-testid="buttonNo"
								label="No"
								onClick={() => setDisplayView(1)}
								variant="outlined"
								styles={{
									width: matches ? 340 : 204,
									height: 40,
									// marginRight: 20,
									marginBottom: 10,
								}}
								disabled={alert.show}
							/>
						</div>
						<div>
							<CustomButton
								data-testid="buttonSí"
								label="Sí"
								onClick={() => navigate('/inicio')}
								variant="solid"
								styles={{
									width: matches ? 340 : 204,
									height: 40,
									marginBottom: 15,
									marginLeft: matches ? 0 : 25,
								}}
								disabled={alert.show}
							/>
						</div>
					</div>
				</div>

				<div
					id="radioOptions"
					style={{ display: displayView === 1 ? 'inline' : 'none' }}
				>
					<h3
						className={classes.h3}
						style={{ textAlign: 'left', marginLeft: 30 }}
					>
						{' '}
						Elige una de las opciones:{' '}
					</h3>
					<RadioGroup>
						<FormControlLabel
							sx={{
								ml: {
									xs: 4,
									sm: 14,
									md: 14,
								},
								mb: 1,
							}}
							control={
								<Radio
									onChange={handleOnChangeRadioButton}
									name="firmElec"
								/>
							}
							style={{ color: '#000000' }}
							label="Tengo firma electrónica avanzada"
							value="firmElec"
						/>
						<FormControlLabel
							sx={{
								ml: {
									xs: 4,
									sm: 14,
									md: 14,
								},
								mb: 1,
							}}
							control={
								<Radio
									onChange={handleOnChangeRadioButton}
									name="reciboDig"
								/>
							}
							style={{ color: '#000000' }}
							label="Tengo recibo de nómina digital"
							value="reciboDig"
						/>
						<FormControlLabel
							sx={{
								ml: {
									xs: 4,
									sm: 14,
									md: 14,
								},
								mb: 1,
							}}
							control={
								<Radio
									onChange={handleOnChangeRadioButton}
									name="validSat"
								/>
							}
							style={{ color: '#000000' }}
							label="Validación con información del SAT"
							value="validSat"
						/>
						<FormControlLabel
							sx={{
								ml: {
									xs: 4,
									sm: 14,
									md: 14,
								},
								mb: 1,
							}}
							control={
								<Radio onChange={handleOnChangeRadioButton} name="cita" />
							}
							style={{ color: '#000000' }}
							label="Haz una cita"
							value="cita"
						/>
					</RadioGroup>
					<div style={{ padding: '0 40px' }}>
						<CustomAlert
							message={alert.message}
							severity={alert.severity}
							show={alert.show}
						/>
					</div>
					<div
						className={classes.botonContainerRadio}
						style={{ marginTop: 40, paddingBottom: 30 }}
					>
						<CustomButton
							data-testid="buttonContinuar"
							label="Continuar"
							onClick={() => setPage(pageTemp)}
							disabled={enableButton || alert.show}
							variant="solid"
							styles={{
								width: 282,
								height: 40,
								marginRight: 20,
								marginBottom: 10,
							}}
						/>
					</div>
				</div>
			</Paper>
		</>
	);
};

export default RfcHome;
