import { IUseController, useController } from './useController';
import { useEffect, useState } from 'react';

export const useNavigation = (props?: IUseController) => {

	const { saveData, addSelection, getStepView, nextStep, calcProgress, dataProcessController, returnStep, getProgressStep } =
		useController(props);
	const [currentView, setCurrentView] = useState<number>(getStepView());

	useEffect(() => {
		navigation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataProcessController]);

	const navigation = () => {
		const stepNext = getStepView();
		setCurrentView(stepNext);
	};

	return {
		saveData,
		addSelection,
		nextStep,
		returnStep,
		calcProgress,
		currentView,
		dataProcessController,
    getProgressStep,
	};
};
