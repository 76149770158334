import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	inputRoot: {
		'&>.MuiOutlinedInput-root': {
			height: 40,
		},
	},
	containerInput: {
		display: 'flex',
		alignItems: 'flex-start !important',
		margin: '0px !important',
		width: '100%',
	},
	labelContainerInput: {
		margin: 0,
		color: '#7E8094',
		fontSize: 14,
	},
	labelRequired: {
		color: theme.palette.primary.main,
	},
}));
