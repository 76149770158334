import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
    radioContainer: {
      textAlign: 'left',
    },
    radioOption: {
      '&.MuiFormControlLabel-root': {
        alignItems: 'flex-start'
      }
    }
  }))