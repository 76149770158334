// DEPENDENCIES
import React from 'react';
import { Divider, Link } from '@mui/material';
// COMPONENTS
import CustomButton from '../../../components/Button';
// ASSETS
import iconDownload from '../../../assets/img/icono-descarga.png';
// RESOURCES
import { downloadPDF } from '../../../utils/downloadPDF';
import { formatDate } from '../../../utils/dates';
import { useStyles } from './styles';
import { IAlert } from '../../../interfaces/alert';
import CustomAlert from '../../../components/CustomAlert';

interface IDataCaso {
	caso: string;
	codigo: string;
	descripcion: string;
	fecha: string;
	fechaEstatus: string;
}

interface Props {
	setPage: (data: number) => void;
	DataCaso?: IDataCaso;
	pdfSigned: string;
	handleReintentar: () => void;
	onCloseError: () => void;
	hasError: IAlert;
}

const LayoutNotice = ({
	setPage,
	DataCaso,
	pdfSigned,
	onCloseError,
	handleReintentar,
	hasError,
}: Props) => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.titleNoticeContainer}>
				<div>
					<h2 className={classes.titleNotice}>
						Devolución de pagos en exceso{' '}
					</h2>
				</div>

				<div className={classes.subTitleNotice}></div>
			</div>

			<div className={classes.container}>
				<div>
					<h3 className={classes.principalTitle}>
						Gracias por utilizar los servicios electrónicos del Infonavit
					</h3>
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Número de caso
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									{DataCaso?.caso}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Fecha de solicitud
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									{DataCaso?.fecha}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>Servicio</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									Devolución de pagos en exceso
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>Estatus</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									{DataCaso?.descripcion}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Fecha de estatus
								</h4>
							</div>
							<div>
								<p className={classes.contentFileDataLink}>
									{DataCaso?.fechaEstatus}
								</p>
							</div>
						</div>
					</div>
					<Divider style={{ marginBlock: 10 }} />
					<CustomAlert
						show={hasError.show}
						severity={hasError.severity}
						message={hasError.message}
						onClose={onCloseError}
					/>
					{DataCaso?.codigo === '100' ? (
						<div className={classes.contentFileDataButtons}>
							<CustomButton
								onClick={handleReintentar}
								variant="outlined"
								styles={{
									marginTop: 80,
									width: 200,
									marginInline: 10,
								}}
								label="Reintentar"
							/>
						</div>
					) : (
						<div className={classes.contentFooterData}>
							<p>
								Tu trámite en línea de devolución de pagos en exceso está
								en proceso. Tus recursos serán depositados en un lapso no
								mayor a 10 días hábiles.
							</p>
							<p className={classes.contentFooterDataLink}>
								Descarga tu acuse de recibo o consúltalo en tu correo
								electrónico.
								<Link
									underline="hover"
									onClick={() =>
										downloadPDF({
											payload: pdfSigned,
											name: `Devolucion-${formatDate('P')}`,
										})
									}
								>
									<img
										style={{ marginLeft: 5, width: 24 }}
										src={iconDownload}
										alt="download"
									></img>
								</Link>
							</p>
						</div>
					)}

					<div className={classes.contentFileDataButtons}>
						<CustomButton
							onClick={() => {
								setPage(0);
							}}
							variant="solid"
							styles={{
								marginTop: 70,
								width: 200,
								margin: '0 auto',
							}}
							disabled={false}
							label="Finalizar"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default LayoutNotice;
