// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useMediaQuery, IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Info } from '@mui/icons-material';
// COMPONENTS
import CustomButton from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import CustomAlert from '../../../components/CustomAlert';
import { HeaderList } from '../../../components/HeaderList';
// RESOURCES
import { isNumber, clabeValid } from '../../../utils/expressions';
import { IAlert } from '../../../interfaces/alert';
import { useStyles } from './styles';

interface ICredentials {
	nss: string;
	clabe: string;
	clabe2: string;
}

interface Props {
	setPage: (data: number) => void;
	onConfirm: () => void;
	onCloseError: () => void;
	onChange: (name: string, value: string | boolean) => void;
	hasError: IAlert;
}

const LISTTODO = [
	'Para hacer la transferencia asegúrate que tu cuenta bancaria esté en condiciones de recibir el traspaso, de lo contrario se podría rechazar la operación y tu trámite se retrasará.',
];

const LayoutNotice = ({ setPage, onConfirm, hasError, onChange, onCloseError }: Props) => {
	const classes = useStyles();
	const theme = useTheme();
	const [flagOpen, setFlagOpen] = useState(0);
	const [open, setOpen] = React.useState(false);
	const [buttonValidate, setButtonValidate] = useState(true);
	const [errorClabe, setErrorClabe] = useState({ valid: false, msg: '' });
	const [errorClabe2, setErrorClabe2] = useState({ valid: false, msg: '' });
	const [credentials, setCredentials] = useState<ICredentials>({
		nss: '',
		clabe: '',
		clabe2: '',
	});

	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		if (flagOpen === 1) {
			setOpen(false);
			setFlagOpen(-1);
		} else {
			setOpen(true);
			setFlagOpen(+1);
		}
	};

	const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.common.white,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: 'black',
			boxShadow: theme.shadows[1],
		},
	}));

	useEffect(() => {
		if (
			credentials.clabe !== '' &&
			credentials.clabe2 !== '' &&
			credentials.clabe === credentials.clabe2
		) {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	}, [credentials.clabe, credentials.clabe2]);

	const setData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		let validate = null;

		if (name === 'clabe') {
			if (!isNumber(value)) return;
			if (value.length > 18) return;
			validate = clabeValid(value);
			setErrorClabe(validate);
		}

		if (name === 'clabe2') {
			if (!isNumber(value)) return;
			if (value.length > 18) return;
			validate = clabeValid(value);
			setErrorClabe2(validate);
		}
		setCredentials({ ...credentials, [name]: value.toUpperCase() });
		onChange(name, value);
	};

	return (
		<>
			<HeaderList
				title="Devolución de pagos en exceso"
				list={LISTTODO}
			></HeaderList>

			<div className={classes.container}>
				<div>
					<div>
						<div className={classes.contentFile}>
							<div className={classes.contentFileData}>
								<h4 className={classes.contentFileDataTitle}>
									Clave bancaria estandarizada (CLABE)
									<BootstrapTooltip
										PopperProps={{
											disablePortal: true,
										}}
										onClose={handleTooltipClose}
										open={open}
										disableFocusListener
										disableHoverListener
										disableTouchListener
										title="Clave bancaria estandarizada (CLABE). Verifica que tu CLABE sea igual a la de tu estado de cuenta bancaria."
										placement={matches ? 'bottom' : 'right'}
										id="1"
									>
										<IconButton
											aria-label="add an alarm"
											onClick={handleTooltipOpen}
											size="small"
											disableRipple
											disableFocusRipple
											component="span"
										>
											<Info
												fontSize="small"
												color='primary'
												sx={{ ml: 0.5 }}
											/>
										</IconButton>
									</BootstrapTooltip>
								</h4>
							</div>
						</div>
					</div>
					<div className={classes.contentFormClabe}>
						<TextInput
							id="clabe"
							name="clabe"
							value={credentials.clabe}
							placeholder="Ingresa tu CLABE"
							onChange={setData}
							//onBlur={() => {}}
							error={!errorClabe.valid}
							helperText={!errorClabe.valid ? errorClabe.msg : ''}
						/>
						<TextInput
							id="clabe2"
							name="clabe2"
							value={credentials.clabe2}
							onChange={setData}
							placeholder="Confirma tu CLABE"
							// onBlur={handleValidateClabe}
							error={!errorClabe2.valid}
							helperText={!errorClabe2.valid ? errorClabe2.msg : ''}
						/>
						<CustomAlert
							show={hasError.show}
							severity={hasError.severity}
							message={hasError.message}
							onClose={onCloseError}
						/>
						<div className={classes.contentFileDataButtons}>
							<CustomButton
								onClick={() => {
									setPage(2);
								}}
								variant="outlined"
								styles={{
									width: 200,
									marginInline: 10,
								}}
								label="Regresar"
							/>
							<CustomButton
								onClick={onConfirm}
								disabled={buttonValidate}
								variant="solid"
								styles={{
									width: 200,
									marginInline: 10,
								}}
								label="Continuar"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LayoutNotice;
