import { Box, Grid, Link, Typography, List } from '@mui/material';
import { useStyles } from './styles';
import sipot from '../../assets/img/BotonSIPOT_150x44.png';
import pnt from '../../assets/img/BotonPNT_150x44.png';
import facebook from '../../assets/svg/facebook.svg';
import linkedin from '../../assets/svg/linkedin.svg';
import youtube from '../../assets/svg/youtube.svg';
import spotify from '../../assets/svg/spotify.svg';
import twitter from '../../assets/svg/twitter.svg';
import appleStore from '../../assets/svg/app-store.svg';
import huaweiStore from '../../assets/svg/huawei.svg';
import playStore from '../../assets/svg/google-play.svg';

interface SocialMedia {
	id: number;
	icon: string;
	title: string;
	url: string;
}

const SOCIALMEDIA: SocialMedia[] = [
	{
		id: 1,
		icon: facebook,
		title: 'Facebook',
		url: 'https://www.facebook.com/ComunidadInfonavit',
	},
	{
		id: 2,
		icon: linkedin,
		title: 'Linkedin',
		url: 'https://www.linkedin.com/company/infonavit/?originalSubdomain=mx',
	},
	{
		id: 3,
		icon: youtube,
		title: 'Youtube',
		url: 'https://www.youtube.com/user/ComunidadInfonavit',
	},
	{
		id: 4,
		icon: spotify,
		title: 'Spotify',
		url: 'https://open.spotify.com/show/3gUlQtXksmtoyEQvcxdVfH?si=70aa82859be44161&nd=1',
	},
	{
		id: 5,
		icon: twitter,
		title: 'twitter',
		url: 'https://twitter.com/Infonavit',
	},
];

const APPS: SocialMedia[] = [
	{
		id: 1,
		icon: appleStore,
		title: 'Apple Store',
		url: 'https://apps.apple.com/us/app/mi-cuenta-infonavit-m%C3%B3vil/id1542403564',
	},
	{
		id: 2,
		icon: playStore,
		title: 'Play Store',
		url: 'https://play.google.com/store/apps/details?id=com.infonavit.micuentainfonavit',
	},
	{
		id: 3,
		icon: huaweiStore,
		title: 'Huawei Store',
		url: 'https://appgallery.huawei.com/app/C104066105',
	},
];

const SocialIcons = () => {
	const classes = useStyles();
	return (
		<Grid item xs={12} md={2}>
			<div className={classes.containerSocialMediaList}>
				<label className={classes.labelTitle}>Síguenos</label>
				<List className={classes.socialMediaList}>
					{SOCIALMEDIA.map((sm) => (
						<Link
							className={classes.socialMediaItem}
							key={sm.id}
							target="_blank"
							rel="noopener"
							href={sm.url}
						>
							<img
								src={sm.icon}
								width={
									sm.title === 'Facebook'
										? '10'
										: sm.title === 'Linkedin'
											? '18'
											: '20'
								}
								alt="icon"
							/>
						</Link>
					))}
				</List>
			</div>
		</Grid>
	);
};

const LinksToDownloadApp = () => {
	const classes = useStyles();
	return (
		<Grid item xs={12} md={2}>
			<div className={classes.containerSocialMediaList}>
				<label className={classes.labelTitle}>Descarga la aplicación</label>
				<List className={classes.socialMediaList}>
					{APPS.map((app) => (
						<Link
							key={app.url}
							target="_blank"
							rel="noopener"
							className={classes.socialMediaItem}
							href={app.url}
						>
							<img src={app.icon} width="20" alt="icon_url" />
						</Link>
					))}
				</List>
			</div>
		</Grid>
	);
};

const Infonatel = () => {
	const classes = useStyles();
	return (
		<Grid item xs={12} md={3}>
			<label className={classes.labelTitle}>Infonatel</label>
			<Box className={classes.container}>
				<Grid item xs={12} md={6}>
					<label className={classes.labelSubTitle}>Ciudad de México</label>
					<Link
						href="tel:5591715050"
						underline="none"
						className={classes.labelSubTitlePhone}
					>
						55 9171 5050
					</Link>
				</Grid>
				<Grid item xs={12} md={6}>
					<label className={classes.labelSubTitle}>
						Desde cualquier parte del país
					</label>
					<Link
						href="tel:8000083900"
						underline="none"
						className={classes.labelSubTitlePhone}
					>
						800 008 3900
					</Link>
				</Grid>
			</Box>
		</Grid>
	);
};

const Copyright = () => {
	const classes = useStyles();
	return (
		<Grid item xs={12} md={5}>
			<Grid item xs={12} md={12}>
				<Box className={`${classes.margin} ${classes.row}`}>
					<Grid item xs={6} md={3}>
						<Link
							target="_blank"
							rel="noopener"
							href="https://consultapublicamx.inai.org.mx/vut-web/faces/view/consultaPublica.xhtml#inicio"
						>
							<img
								className={classes.img}
								src={sipot}
								alt="Consulta nuestras obligaciones de transparencia"
							/>
						</Link>
					</Grid>
					<Grid item xs={6} md={3}>
						<Link
							target="_blank"
							rel="noopener"
							href="https://www.plataformadetransparencia.org.mx/group/guest/inicio"
						>
							<img
								className={classes.img}
								src={pnt}
								alt="Consulta nuestras obligaciones de transparencia"
							/>
						</Link>
					</Grid>
				</Box>
			</Grid>
			<Box className={classes.container}>
				<Typography style={{ fontSize: 12, marginRight: 15 }} variant="smallText">
					Derechos reservados &copy; Infonavit {new Date().getFullYear()}
				</Typography>
				<Link
					href="https://portalmx.infonavit.org.mx/wps/portal/infonavit.web/transparencia/aviso-privacidad/!ut/p/z1/jY-7DoJAEEW_xYKWmQXF1W4xiA-CWBBxGwMGVw2wBBB-X0QbEyFON5Nzbu4AhwB4FtY3EVY3mYVJux-5cTJsxNViTNwd245xT00PzamnO0SDQwdoFNE2kbg2XVFkS2ttzRxdswkC_8cfAF4-9gzD1ucd0tfAJx9gIGMDXCQyer_LskinAngRX-IiLtRH0Z6vVZWXcwUVbJpGFVKKJFbPMlXwl3KVZQXBNwl56gd4nyS1w0ZPkMUZLw!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
					target="_blank"
					rel="noopener"
					color="inherit"
					variant="smallText"
					style={{ fontSize: 12, marginRight: 15 }}
				>
					Protección de datos personales
				</Link>
				<Link
					href="https://portalmx.infonavit.org.mx/wps/portal/infonavit.web/contactanos/terminos-condiciones/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zizdwNDDycTQz93L0MjAwC_Z1CAkKNnIzd_Yz0w8EKjCwMDNydDIAKLDwsDBzdXD1dLX2MjdxDDfWjiNFvgAM4GhCnH4-CKPzGh-tH4bPCwNgMqgCfFwlZUpAbGhphkOkJAHKYbMI!/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
					target="_blank"
					rel="noopener"
					color="inherit"
					variant="smallText"
					style={{ fontSize: 12, marginRight: 15 }}
				>
					Términos y condiciones
				</Link>
				<Link
					href={require('../../assets/files/aviso-de-privacidad.pdf')}
					target={'_blank'}
					download="aviso-de-privacidad.pdf"
					color="inherit"
					variant="smallText"
					style={{ fontSize: 12, marginRight: 15 }}
				>
					Aviso de Privacidad
				</Link>
			</Box>
		</Grid>
	);
};

const Footer = () => {
	const classes = useStyles();
	return (
		<Grid container className={classes.root}>
			<SocialIcons />
			<LinksToDownloadApp />
			<Infonatel />
			<Copyright />
		</Grid>
	);
};

export default Footer;
