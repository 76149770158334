import JSZip from 'jszip';
const download = require('downloadjs');

interface DownloadZipProps {
	base64Data: string;
	name: string;
}

export const downloadZip = ({ base64Data, name }: DownloadZipProps): void => {
	// Decodificar Base64
	const binaryData = atob(base64Data);

	// Crear un nuevo objeto JSZip
	const zip = new JSZip();

	// Generar el archivo .zip
	zip.file(`${name}.zip`, binaryData, { binary: true });
	zip.generateAsync({ type: 'blob' })
		.then((blob) => {
			download(blob, `Archivos.zip`);
		})
		.catch((error) => {
			console.error('Error al generar el archivo .zip', error);
		});
};
