// RESOURCES
import { Box } from '@mui/material';
// COMPONENTS
import CustomButton from '../../../components/Button';
// ASSETS
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import ModalLoading from '../../../components/ModalLoading';
import { useState } from 'react';
import CustomAlert from '../../../components/CustomAlert';
import { IAlert } from '../../../interfaces/alert';
import imgTel from '../../../assets/img/ima-socio-infonavit-img.jpg';
import TextInput from '../../../components/TextInput';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';
import { unpairDeviceAPI } from '../../../api/modules/unpairDevice';
import { useBitacora } from '../../../hooks/useBitacora';

interface Props {
	setPage: (data: number) => void;
	dataRequest: any;
	setRequest: (arg: any) => void;
}

const ConfirmPassword = ({ setPage, dataRequest, setRequest }: Props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState('');
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});
	const { regBitacoraNotOld, getMaskString } = useBitacora();

	//funcion para consumir api obtener informacion del dispositivo
	const getInformationDevice = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const grupo_encript = await encrypt(dataRequest.grupo, key);
			const idCat_encript = await encrypt(dataRequest.id_cat, key);
			const password_encript = await encrypt(password, key);

			const { result: consultar } = await unpairDeviceAPI.consultDevices(
				grupo_encript,
				idCat_encript
			);

			const idDevice_encript = await encrypt(
				consultar?.dispositivosHistoricos[0] || '',
				key
			);

			const { result } = await unpairDeviceAPI.getInfoDevice(
				grupo_encript,
				idCat_encript,
				password_encript,
				idDevice_encript
			);

			if ((result.codigo_login || '').includes('Datos Incorrectos')) {
				setAlert({
					show: true,
					message:
						'La contraseña que escribiste es incorrecta. Inténtalo de nuevo.',
					severity: 'error',
				});
				return;
			}

			if ((result.codigo_login || '').includes('locked')) {
				setAlert({
					show: true,
					message: 'NSS bloqueado.',
					severity: 'error',
				});
				return;
			}

			if (Number(result.respuesta.code) === 1001) {
				setRequest({
					fechaCreacionTDS: result.fechaCreacionTDS,
					idDispositivo: result.idDispositivo,
					datetime_creation: result.datetime_creation,
				});
				regBitacoraNotOld(unpairDeviceAPI.schemeUrl, { password: getMaskString(password) });
				setPage(1);
				return;
			}

			if (Number(result.respuesta.code) === 1002) {
				regBitacoraNotOld(unpairDeviceAPI.schemeUrl, { password: getMaskString(password) });
				setPage(5);
				return;
			}

			setAlert({
				show: true,
				message:
					result.respuesta.message ||
					'Ha ocurrido un error al obtener los datos, intente nuevamente.',
				severity: 'error',
			});
		} catch (error) {
			setPage(6);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<div className={classes.container}>
				<div className={classes.containerMin}>
					<div className={classes.containerInfoPassword}>
						{/* <p className={classes.txtContainer}></p> */}
						<p>
							Este servicio te permitirá desvincular tu
							dispositivo móvil que tienes asociado a tu número de
							seguridad social (NSS). Recuerda que para actualizar
							la aplicación móvil deberás volver a asociar tu
							nuevo dispositivo.
						</p>
						<p>
							Para tu seguridad deberás confirmar tu contraseña de
							Mi Cuenta Infonavit:
						</p>
					</div>
					<div className={classes.containerInfoImage}>
						<img src={imgTel} alt="img-tel"></img>
					</div>
				</div>

				<Box>
					<span>Confirma contraseña</span>
					<TextInput
						data-testid="inputCurrentPassword"
						id="currentPassword"
						name="currentPassword"
						type="password"
						onChange={(e) => setPassword(e.target.value)}
						value={password}
					/>
				</Box>

				<CustomAlert
					show={alert.show}
					severity={alert.severity}
					message={alert.message}
				/>

				<Box className={classes.containerButtons}>
					<div className={classes.custombuttom}>
						<CustomButton
							label={'Salir'}
							onClick={() => navigate('/')}
							styles={{ width: '200px', height: '100%' }}
							variant={'outlined'}
						/>
					</div>
					<div className={classes.custombuttom}>
						<CustomButton
							label={'Continuar'}
							onClick={getInformationDevice}
							styles={{ width: '200px', height: '100%' }}
							variant={'solid'}
							disabled={password === '' ? true : false}
						/>
					</div>
				</Box>
			</div>
		</>
	);
};

export default ConfirmPassword;
