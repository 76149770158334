import { Paper, Box, Link } from '@mui/material';
import Layout from '../../../../../components/Layout';
import { HeaderList } from '../../../../../components/HeaderList';
import CustomButton from '../../../../../components/Button';
import ModalMiEspacio from '../../../../CreditRequest/components/ModalMiEspacio';
import { IProfileResponse } from '../../../../../api/modules/MIUC';
import { useStyles } from './styles/styles';
import { useCreditReqCan } from './hooks/useCreditReqCan';
import { useNavigate } from 'react-router-dom';
import { useController } from '../../../hooks/useController';
import { useEffect, useState } from 'react';

interface ICreditReqCan {
	data: IProfileResponse;
}
const CreditReqCan = (props: ICreditReqCan) => {
  const navigate = useNavigate();
	const { getUrlMiespacio, openModal, setOpenModal } = useCreditReqCan();
  const { saveData, dataProcessController } = useController();
	const classes = useStyles();

  useEffect(() => {
    if(dataProcessController.processInfo?.redirecting) navigate('/mi-tramite-credito/precalificacion-puntos');
  }, [dataProcessController])
  
  const goToMiuc = async() => {
    await saveData({
      processInfo: {
        redirecting: true,
      }
    }, true);
  }
  
	return (
		<Layout>
			<div>
				<HeaderList
					title="Me interesa un crédito"
					list={[
						'En este servicio puedes dar seguimiento a tu solicitud de crédito en línea.',
					]}
				/>
				<Paper>
					<Box padding={3} maxWidth={900} margin="auto">
						<h3 className={classes.textCenter}>
							Solicitud de crédito cancelada
						</h3>
						<p className={classes.margin20p0p}>
							En caso de que hayas entregado a tu empresa el aviso
							de retención y aún no hayas obtenido tu aviso de
							suspensión de descuentos ingresa a{' '}
							<Link onClick={getUrlMiespacio}>
								Mi espacio Infonavit
							</Link>
						</p>
						<p className={classes.margin20p0p}>
							Si quieres solicitar nuevamente tu crédito, hazlo
							desde el servicio de Me interesa un crédito.
						</p>
						<Box maxWidth={250} display="flex" margin="40px auto">
							<CustomButton
								label="Me interesa un crédito"
                onClick={ goToMiuc }
								variant="solid"
							/>
						</Box>
					</Box>
				</Paper>
				<ModalMiEspacio
					openModal={openModal}
					setOpenModal={() => setOpenModal(!openModal)}
				/>
			</div>
		</Layout>
	);
};

export default CreditReqCan;
