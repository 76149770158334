// DEPENDENCIES
import React, { useState } from 'react';
import { Divider, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
// COMPONENTS
import VerificationCode from '../../../../components/VerificationCode';
import ModalLoading from '../../../../components/ModalLoading';
import CustomAlert from '../../../../components/CustomAlert';
import CustomButton from '../../../../components/Button';
import TextInput from '../../../../components/TextInput';
// RESOURCES
import { emailValid } from '../../../../utils/expressions';
import { INTERNAL_MESSAGES } from '../../../../config/messageCatalog';
import { encrypt } from '../../../../utils/encrypt';
import { defaultEmailForm } from '../utils';
import { IAlert } from '../../../../interfaces/alert';
// import {IAlert} from '../../../'
// ASSETS
import { contactRequestAPI } from '../../../../api/modules/profile';
import { keyAPI } from '../../../../api/modules/key';
import { RootState } from '../../../../store';
import { useStyles } from '../styles';
import EmailConfirmation2 from './component/EmailConfirmation2';
// import EmailV
interface IContactEmail {
	fetchContactData: () => void;
	cancel: () => void;
}

const ContactEmail = (props: IContactEmail) => {
	const { fetchContactData, cancel } = props;
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const [progress, setProgress] = useState(0);
	const [step, setStep] = useState(1);
	const [contact, setContact] = useState(defaultEmailForm);
	const [errors, setErrors] = useState(defaultEmailForm);
	const [loader, setLoader] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [errorValidation, setErrorValidation] = useState(false);

	const handleValidationEmail = async () => {
		handleClose();
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_pass_base64 = await encrypt(contact?.email?.toString() || '', key);
			contactRequestAPI.validationEmail
				.post<{ email: string }, any>('', {
					email: email_pass_base64,
				})
				.then((res) => {
					if (res?.result?.emailCodigoEstatus === '01') {
						handleConfirm();
					} else {
						if (errorValidation) {
							setAlert({
								show: true,
								message:
									INTERNAL_MESSAGES.NOT_EXISTING_MAIL,
								severity: 'error',
							});
						}
						setErrorValidation(true);
						setLoader(false);
					}
				})
				.catch((e) => {
					setAlert({
						show: true,
						message: e.description
							? e.description
							: INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
					setLoader(false);
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoader(false);
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		let validation = emailValid(value);
		setContact({ ...contact, [name]: value });
		if (name === 'email') {
			setProgress(50);
		}
		if (!value) {
			if (name === 'email') setProgress(0);
			setErrors({ ...errors, [name]: 'Escribe tu correo electrónico' });
		} else if (!validation.valid) {
			setErrors({ ...errors, [name]: validation.msg });
		} else if (name === 'confirm_email' && value !== contact.email) {
			setErrors({
				...errors,
				confirm_email:
					INTERNAL_MESSAGES.MAIL_DOES_NOT_MATCH,
			});
		} else {
			setErrors(defaultEmailForm);
		}

		setAlert({ show: false, message: '', severity: 'success' });
	};

	const handleStep = (newStep: number, newProgress: number) => {
		setStep(newStep);
		setProgress(newProgress);
	};

	const handleConfirm = async () => {
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_pass_base64 = await encrypt(contact?.email?.toString() || '', key);
			const USER_NSS_BASE64 = await encrypt(user?.nss?.toString() || '', key);

			contactRequestAPI.updateMainEmail
				.post<{ nss: string; email: string }, any>('', {
					nss: USER_NSS_BASE64,
					email: email_pass_base64,
				})
				.then((res) => {
					if (res?.ok) {
						handleStep(2, 50);
						handleClose();
						fetchContactData();
					}
				})
				.catch((e) => {
					handleStep(1, 0);
					setAlert({
						message:
							e && typeof e === 'string'
								? e
								: e?.description ||
								INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
						show: true,
					});
				})
				.finally(() => {
					setLoader(false);
					setErrorValidation(false);
				});
		} catch (error: any) {
			handleStep(1, 0);
			setAlert({
				show: true,
				message: error.description
					? error.description
					: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoader(false);
		}
	};

	const handleResend = async () => {
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_pass_base64 = await encrypt(contact?.email?.toString() || '', key);
			const USER_NSS_BASE64 = await encrypt(user?.nss?.toString() || '', key);

			contactRequestAPI.resendUpdateMainEmail
				.post<{ nss: string; email: string }, any>('', {
					nss: USER_NSS_BASE64,
					email: email_pass_base64,
				})
				.then((res) => {
					if (res?.ok) {
						handleClose();
						handleStep(2, 100);
						fetchContactData();
					}
				})
				.catch((e) => {
					setAlert({
						show: true,
						message:
							e && typeof e === 'string'
								? e
								: e?.description ||
								INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				message: error.description
					? error.description
					: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
				show: true,
			});
			setLoader(false);
		}
	};

	const handleClose = (): any => {
		setAlert({ show: false, message: '', severity: 'success' });
	};

	return (
		<>
			<ModalLoading loading={loader} />
			<p className={classes.textoNormal}>
				Tu correo electrónico se cambiará, este correo se utilizará para
				enviarte notificaciones sobre tu cuenta Infonavit.
			</p>
			<p className={classes.stepLabel}>{step} de 3 completados</p>
			<LinearProgress variant="determinate" value={progress} color="info" />
			{step === 1 && !errorValidation && (
				<>
					<div className={classes.mailformContainer}>
						<TextInput
							isRequired
							label="Correo electrónico"
							id="primary_email"
							name="email"
							value={contact.email}
							error={Boolean(errors.email)}
							helperText={errors.email}
							onChange={handleChange}
							noCopy={true}
							noPaste={true}
						/>
						<TextInput
							isRequired
							label="Confirmar correo electrónico"
							id="confirm_primary_email"
							name="confirm_email"
							value={contact.confirm_email}
							error={Boolean(errors.confirm_email)}
							helperText={errors.confirm_email}
							onChange={handleChange}
							noCopy={true}
							noPaste={true}
						/>
					</div>
					<div className={classes.actionsContainer}>
						<CustomButton
							disabled={false}
							variant="outlined"
							onClick={() => {
								setErrors(defaultEmailForm);
								setAlert({ show: false, message: '', severity: 'success' });
								setContact(defaultEmailForm);
								cancel();
							}}
							label="Cancelar"
						/>
						<CustomButton
							variant="solid"
							disabled={
								!Boolean(contact.email) ||
								contact.email !== contact.confirm_email
							}
							onClick={handleValidationEmail}
							label="Continuar"
						/>
					</div>
				</>
			)}
			{step === 2 && !errorValidation && (
				<div className={classes.confirmationContainer}>
					<VerificationCode
						isEmail
						setCode={() => handleStep(3, 100)}
						reSend={handleResend}
						isSixDigits={true}
					/>
				</div>
			)}
			{step === 3 && !errorValidation && (
				<div className={classes.congratulationsContainer}>
					<h3>¡Felicidades!</h3>
					<p>
						Tu correo electrónico se ha modificado exitosamente, ahora puedes
						seguir disfrutando de los servicios que Mi Cuenta <br />
						Infonavit tiene para ti.
					</p>
					<div className={classes.actionsContainer}>
						<CustomButton
							disabled={false}
							variant="solid"
							onClick={() => {
								handleStep(1, 0);
								cancel();
							}}
							label="Finalizar"
						/>
					</div>
				</div>
			)}
			{errorValidation && (
				<>
					<EmailConfirmation2 />
					<div className={classes.numbersVerification}>
						<TextInput
							isRequired
							label="Correo electrónico"
							id="primary_email"
							name="email"
							value={contact.email}
							error={Boolean(errors.email)}
							helperText={errors.email}
							onChange={handleChange}
							noCopy={true}
							noPaste={true}
						/>
						<TextInput
							isRequired
							label="Confirmar correo electrónico"
							id="confirm_primary_email"
							name="confirm_email"
							value={contact.confirm_email}
							error={Boolean(errors.confirm_email)}
							helperText={errors.confirm_email}
							onChange={handleChange}
							noCopy={true}
							noPaste={true}
						/>
					</div>
					<div style={{ width: '30%', margin: 'auto', marginTop: 10 }}>
						<CustomButton
							variant="solid"
							disabled={
								!Boolean(contact.email) ||
								contact.email !== contact.confirm_email
							}
							onClick={handleValidationEmail}
							label="Validar"
						/>
					</div>
				</>
			)}
			<CustomAlert
				show={Boolean(alert.message)}
				severity={alert.severity}
				message={alert.message}
				onClose={handleClose}
			/>

			<div className={classes.containerRequiredFields}>
				<Divider />
				<p className={classes.requiredFieldsLabel}>* Datos obligatorios</p>
			</div>
		</>
	);
};

export default ContactEmail;
