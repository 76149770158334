import { useNavigate } from 'react-router-dom';
import { Grid, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import { useStyles } from './styles';

interface Props {
	setPage: (data: number) => void;
	setViewHeader: (data: boolean) => void;
	dataStatus: any;
	makeDate: boolean;
	hasDate: boolean;
}

const Status = ({ setPage, makeDate, dataStatus, setViewHeader, hasDate }: Props) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();

	return (
		<Paper sx={{ pt: 5, pb: 5, pr: matches ? 5 : 15, pl: matches ? 5 : 15 }}>
			<h2 style={{ color: '#293990', textAlign: 'center', paddingBottom: 20 }}>
				Gracias por utilizar los servicios digitales del Infonavit
			</h2>
			<Grid
				container
				columns={12}
				sx={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<Grid item xs={12} sm={6} md={3} lg={3}>
					<h3
						style={{
							fontSize: 18,
							margin: 0,
							textAlign: matches ? 'center' : 'initial',
						}}
					>
						Número de caso
					</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={9}
					lg={9}
					style={{ textAlign: matches ? 'center' : 'right' }}
				>
					<label>{dataStatus?.noCaso}</label>
				</Grid>
			</Grid>
			<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />
			<Grid
				container
				columns={12}
				sx={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<Grid item xs={12} sm={6} md={3} lg={3}>
					<h3
						style={{
							fontSize: 18,
							margin: 0,
							textAlign: matches ? 'center' : 'initial',
						}}
					>
						Fecha de solicitud
					</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={9}
					lg={9}
					style={{ textAlign: matches ? 'center' : 'right' }}
				>
					<label>{dataStatus.fecha}</label>
				</Grid>
			</Grid>
			<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

			<Grid
				container
				columns={12}
				sx={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<Grid item xs={12} sm={6} md={3} lg={3}>
					<h3
						style={{
							fontSize: 18,
							margin: 0,
							textAlign: matches ? 'center' : 'initial',
						}}
					>
						Servicio
					</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={9}
					lg={9}
					style={{ textAlign: matches ? 'center' : 'right' }}
				>
					<label>{dataStatus.descripcionServicio}</label>
				</Grid>
			</Grid>
			<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

			<Grid
				container
				columns={12}
				sx={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<Grid item xs={12} sm={6} md={3} lg={3}>
					<h3
						style={{
							fontSize: 18,
							margin: 0,
							textAlign: matches ? 'center' : 'initial',
						}}
					>
						Estatus
					</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={9}
					lg={9}
					style={{ textAlign: matches ? 'center' : 'right' }}
				>
					{dataStatus.status?.description}
				</Grid>
			</Grid>
			<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

			<Grid
				container
				columns={12}
				sx={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<Grid item xs={12} sm={6} md={3} lg={3}>
					<h3
						style={{
							fontSize: 18,
							margin: 0,
							textAlign: matches ? 'center' : 'initial',
						}}
					>
						Fecha de estatus
					</h3>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					md={9}
					lg={9}
					style={{ textAlign: matches ? 'center' : 'right' }}
				>
					<label>{dataStatus.fechaStatus}</label>
				</Grid>
			</Grid>
			<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

			<div
				style={{
					maxWidth: 800,
					margin: 'auto',
					marginTop: 30,
				}}
			>

				{!makeDate && (
					<div className={classes.message}>
						<span>
							Cuando tus datos sean actualizados se te notificará a través de correo electrónico y podrás continuar con la creación de tu Cuenta Infonavit.
						</span>
					</div>
				)}
				{makeDate && !hasDate && (
					<div className={classes.message}>
						<span>
							Tienes 5 días para hacer tu cita, de lo contrario el caso se cerrará y deberás iniciar de nuevo tu trámite.
						</span>
					</div>
				)}
				<div
					style={{
						maxWidth: 260,
						display: 'flex',
						margin: 'auto',
						marginTop: 30,
					}}
				>
					{dataStatus.noCaso && dataStatus.status?.description === 'En espera de documentos' && (
						<CustomButton
							variant="solid"
							label={'Adjuntar documentos'}
							onClick={() => {
								setViewHeader(true)
								setPage(4)
							}}
						/>
					)}
					{dataStatus.noCaso && hasDate && (
						<CustomButton
							variant="solid"
							label={'Ver tu cita'}
							onClick={() => setPage(6)}
						/>
					)}
					{makeDate && !hasDate && (
						<CustomButton
							variant="solid"
							label={'Haz tu cita'}
							onClick={() => setPage(6)}
						/>
					)}
					{!makeDate && dataStatus.status?.description !== 'En espera de documentos' && (
						<CustomButton
							variant="solid"
							label={'Finalizar'}
							onClick={() => {
								localStorage.removeItem('dataUserRegister');
								navigate('/registro');
							}}
						/>
					)}
				</div>
			</div>

		</Paper>
	);
};

export default Status;