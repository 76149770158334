// import { createContext, Dispatch, SetStateAction, useContext } from 'react';
// import { IDataReturnSavings } from './utils';

import { createContext } from 'react';
import { IAlert } from '../../interfaces/alert';
import {
	IDataReturnSavings,
	IRequestAttachDocument,
	IRequestConsultaBanco,
	IRequestConsultaDocumentacion,
	IRequestCreacionCaso,
	IRequestObtieneArchivoAcuse,
	IRequestPersisteDatos,
	IRequestSignDocument,
	IRequestValidaCausaRaiz2,
	IRequestValidaFecha,
	IDataException,
} from './interfaces';

export interface IdataReturnSavingsContext {
	MessageToView?: string;
	data?: IDataReturnSavings;
	setData: (value: IDataReturnSavings | undefined) => void;
	page: number;
	states: { value: string; text: string }[];
	setPage: React.Dispatch<React.SetStateAction<number>>;
	GetPage: (
		currentPage: number,
		isNext: boolean,
		accordingAmount?: boolean,
		viewMessageScreen?: boolean
	) => void;
	getMessageErrorDefault: string;
	alert: IAlert;
	setAlert: (data: IAlert) => void;
	loading: boolean;
	dataException?: IDataException;
	setDataException: (value: IDataException) => void;
	setLoading: (value: boolean) => void;

	handleGoHomePage: () => void;
	handleGoPage: (page: number) => void;
	handleGoDate: () => void;
	handleNextPage: () => void;
	handlePrevPage: () => void;
	handleConsultaDatos: () => void;
	handlebusquedaCasos: () => void;
	handleConsultaDatosDisponibilidad: () => void;
	handleconsultaDatosDisponibilidadContinuaSolicitud: (data: any) => void;
	handleConsultaSaldoCero: () => void;
	handleCuentaDestino: (value: boolean) => void;
	handleGetAfore: () => void;
	handleValidaAfore: (value: string) => void;
	handleCreacionCaso: (
		data: IRequestCreacionCaso,
		valuePersisteDatos?: IRequestPersisteDatos
	) => void;
	handleMarcarCuenta: (data: any) => void;
	handleValidaCausaRaiz: (
		value: IRequestValidaCausaRaiz2,
		value2: IRequestSignDocument | undefined
	) => void;
	handleGetStates: () => void;
	handleValidaFechas: (value: IRequestValidaFecha) => void;
	handleValidaPension: (data: string) => void;
	handlerConsultaBanco: (value: IRequestConsultaBanco) => void;
	handlerObtieneArchivoAcuse: (value: IRequestObtieneArchivoAcuse) => void;
	handlerReintentoPDFAcuse: () => void;
	handlerConsultaDocumentacion: (
		value: IRequestConsultaDocumentacion
	) => void;
	//handleSignDocument: (value: IRequestSignDocument) => void;
	handlerAttachDocument: (value: IRequestAttachDocument) => void;
	citaActual?: any;
	setCitaActual?: (data: any) => void;
	doctosRequeridosArray?: any;
	setDoctosRequeridosArray?: (data: string) => void;
	doctosRequeridos?: any;
	setDoctosRequeridos: (data: string) => void;
	dataEfirm?: any,
	setDataEfirm: (data: any) => void;
	pdfSigned?: boolean,
	setPdfSigned: (data: boolean) => void;
	optionSelectedItem?: string;
	setOptionSelectedItem: (data: string) => void;
	showModalTemporal: boolean;
}

export const dataReturnSavingsContext = createContext({
	page: 0,
	showModalTemporal: false,
	data: { returnOptionType: '' },
} as IdataReturnSavingsContext);
