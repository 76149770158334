import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	title: {
		display: 'flex',
		alignItems: 'flex-start',

		[theme.breakpoints.up('xs')]: {
			fontSize: 14,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
		},
	},
	label: {
		[theme.breakpoints.up('xs')]: {
			fontSize: 14,
			marginBottom: 0,
			marginTop: 10,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			marginBottom: 0,
		},
	},
	bluetitle: {
		display: 'flex',
		alignItems: 'flex-start',
		color: '#293990',
		[theme.breakpoints.up('xs')]: {
			fontSize: 14,
			marginTop: 0,
			marginBottom: 0,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			marginTop: 2,
		},
	},
	divgris: {
		display: 'flex',
		alignItems: 'center',

		[theme.breakpoints.up('xs')]: {
			fontSize: 14,
			backgroundColor: '#F2F2F2',
			paddingLeft: 24,
			paddingRight: 20,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			paddingLeft: 100,
			paddingRight: 100,
			backgroundColor: '#F2F2F2',
		},
	},
	parrafo: {
		display: 'flex',
		alignItems: 'left',
		padding: '0 24px',
		[theme.breakpoints.up('xs')]: {
			fontSize: 14,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			paddingLeft: 100,
			paddingRight: 100,
		},
	},
	parrafocaso3: {
		display: 'block',
		alignItems: 'center',
		padding: '0 24px',
		[theme.breakpoints.up('xs')]: {
			fontSize: 14,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			paddingLeft: 100,
			paddingRight: 100,
		},
	},
	parrafogris: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',

		[theme.breakpoints.up('xs')]: {
			fontSize: 14,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			paddingLeft: 50,
			paddingRight: 50,
			color: '#7F7F7F',
		},
	},
	parrafocenter: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',

		[theme.breakpoints.up('xs')]: {
			fontSize: 14,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			paddingLeft: '100px !important',
			paddingRight: '100px !important',
		},
	},
	plist: {
		marginTop: 2,
		marginBottom: 2,
	},
	paddingAlert: {
		[theme.breakpoints.up('xs')]: {
			paddingTop: 5,
			paddingBottom: 5,
		},
		[theme.breakpoints.up('md')]: {
			paddingTop: 20,
			paddingBottom: 20,
		},
	},
}));
