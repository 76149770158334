import { useState } from 'react';
import { Help } from '@mui/icons-material';
import BaseModal from '../ModalConfirmation';
import { useStyles } from './style';
import LinksSpouse from './Panels/LinksSpouse';
import BirthCertificate from './Panels/BirthCertificate';
import DisabilityCertificate from './Panels/DisabilityCertificate';
import PluginInformation from './Panels/PluginInformation';
import Grounds from './Panels/Grounds';
import LetterOfInstruction from './Panels/LetterOfInstruction';
import InfonavitSupport from './Panels/InfonavitSupport';
import Ecotechnology from './Panels/Ecotechnology';
import AuthorizationSpouseBC from './Panels/AuthorizationSpouseBC';
import ChooseHousing from './Panels/ChooseHousing';
import AppraisalManagement from './Panels/AppraisalManagement';
import Build from './Panels/Build';
import Characteristic from './Panels/Characteristic';
import EstimatedTerm from './Panels/EstimatedTerm';
import CICB from './Panels/CICB';
import theme from '../../config/theme';
import { Link, Divider, Box } from '@mui/material';
import AviableCreditPanel from './Panels/AviableCreditPanel';
import CustomButton from '../Button';
import ETC from './Panels/ETC';

type props = {
	type: type;
	showInfo?: boolean;
	icon?: boolean;
	dataAviable?: { title: string; image: any; description: string };
	openModal?: boolean;
	setOpenModal?: (arg: boolean) => void;
};

export type type =
	| 'VINCULA_CONYUGE'
	| 'ACTA_NACIMIENTO'
	| 'CERTIFICADO_DISCAPACIDAD'
	| 'TERRENO'
	| 'INFORMACION_COMPLEMENTOS'
	| 'CARTA_INSTRUCCION'
	| 'APOYO_INFONAVIT'
	| 'ECOTECNOLOGIAS'
	| 'ELEGIR_VIVIENDA'
	| 'GESTION_AVALUO'
	| 'CONSTRUYO_RM'
	| 'CONSTRUYO_AT'
	| 'CONSTRUYO_CN'
	| 'PLAZO_ESTIMADO'
	| 'ENTIDADES'
	| 'AUTORIZACION_BC_CONYUGE'
	| 'CARACTERISTICAS_RM'
	| 'CARACTERISTICAS_AT'
	| 'CARACTERISTICAS_CN'
	| 'AVIABLE_CREDITS'
	| 'ETC';

const HelpPanel = ({
	type,
	showInfo,
	dataAviable,
	icon,
	openModal,
	setOpenModal,
}: props) => {
	const classes = useStyles();
	const [open, setOpen] = useState<boolean>(false);
	const getSize = () => {
		let result: 'md' | 'lg' | 'xl' | 'sm' | 'xs' | undefined;
		switch (type) {
			case 'VINCULA_CONYUGE':
			case 'CARTA_INSTRUCCION':
				result = 'sm';
				break;
			case 'ECOTECNOLOGIAS':
				result = 'md';
				break;
			case 'AUTORIZACION_BC_CONYUGE':
				//case 'INFORMACION_COMPLEMENTOS':
				result = 'sm';
				break;

			//case 'ACTA_NACIMIENTO':
			case 'CERTIFICADO_DISCAPACIDAD':
			case 'TERRENO':
			case 'APOYO_INFONAVIT':
				result = 'sm';
				break;
			case 'PLAZO_ESTIMADO':
				result = 'sm';
				break;
			default:
				result = 'md';
				break;
		}
		return result;
	};
	const getTitle = () => {
		let result: string = '';
		switch (type) {
			case 'VINCULA_CONYUGE':
				result =
					'Asocia el NSS de tu cónyuge, familiar o corresidente.';
				break;

			case 'ACTA_NACIMIENTO':
				result = 'Acta de nacimiento';
				break;
			case 'CERTIFICADO_DISCAPACIDAD':
				result = 'Certificado de discapacidad';
				break;
			case 'INFORMACION_COMPLEMENTOS':
				result = 'Complementos';
				break;
			case 'TERRENO':
				result = 'Pon el terreno a tu nombre';
				break;
			case 'CARTA_INSTRUCCION':
				result = 'Carta de instrucción';
				break;
			case 'APOYO_INFONAVIT':
				result = 'Entidades participantes con Apoyo Infonavit';
				break;
			case 'ECOTECNOLOGIAS':
				result = 'Monto de crédito para ecotecnologías';
				break;
			case 'AUTORIZACION_BC_CONYUGE':
				result =
					'Para obtener un mayor monto de crédito de tu cónyuge, bastará con que autorice la consulta a su historial crediticio';
				break;
			case 'ELEGIR_VIVIENDA':
				result = 'Elegir vivienda';
				break;
			case 'GESTION_AVALUO':
				result = 'Solicitud de avalúo';
				break;
			case 'CONSTRUYO_RM':
				result = 'ConstruYo Reparaciones Menores';
				break;
			case 'CONSTRUYO_AT':
				result = 'ConstruYo con Asistencia Técnica';
				break;
			case 'CONSTRUYO_CN':
				result = 'ConstruYo con Constructora';
				break;
			case 'CARACTERISTICAS_RM':
				result = 'ConstruYo Reparaciones menores';
				break;
			case 'CARACTERISTICAS_AT':
				result = 'ConstruYo con Asistencia Técnica';
				break;
			case 'CARACTERISTICAS_CN':
				result = 'ConstruYo con Constructora';
				break;
			case 'PLAZO_ESTIMADO':
				result = 'Lo que debes saber del plazo estimado:';
				break;
			case 'ENTIDADES':
				result = 'Entidades financieras participantes';
				break;
			case 'ETC':
				result = 'Equipa tu casa';
				break;
		}
		return result;
	};
	return (
		<>
			<div
				onClick={() => {
					setOpen(true);
					setOpenModal && setOpenModal(true);
				}}
				className={classes.divContainer}
				style={{
					cursor: 'pointer',
					display:
						typeof openModal !== 'undefined' ? 'none' : 'contents',
				}}
			>
				{showInfo && (
					<Link
						style={{
							position: 'absolute',
							width: 120,
							left: -48,
							fontSize: 14,
							fontWeight: 400,
						}}
					>
						Más información
					</Link>
				)}

				{icon && (
					<Link
						underline="none"
						style={{
							fontWeight: 700,
						}}
					>
						<Help
							style={{
								color: '#D1001F',
								fontSize: 18,
								marginLeft: 25,
							}}
						/>
					</Link>
				)}

				{!showInfo && !icon && (
					<Help style={{ color: '#D1001F', fontSize: 18 }} />
				)}
			</div>
			<BaseModal
				open={openModal ? openModal : open}
				width={getSize()}
				onClose={() => {
					setOpen(false);
					setOpenModal && setOpenModal(false);
				}}
				showTitle
				title={
					<h4 style={{ color: theme.palette.info.main, margin: 0 }}>
						{getTitle()}
					</h4>
				}
			>
				<>
					{!dataAviable && <Divider style={{ marginBottom: 10 }} />}
					{type === 'VINCULA_CONYUGE' && <LinksSpouse />}
					{type === 'ACTA_NACIMIENTO' && <BirthCertificate />}
					{type === 'CERTIFICADO_DISCAPACIDAD' && (
						<DisabilityCertificate />
					)}
					{type === 'INFORMACION_COMPLEMENTOS' && (
						<PluginInformation />
					)}
					{type === 'TERRENO' && <Grounds />}
					{type === 'CARTA_INSTRUCCION' && <LetterOfInstruction />}
					{type === 'APOYO_INFONAVIT' && <InfonavitSupport />}
					{type === 'ECOTECNOLOGIAS' && <Ecotechnology />}
					{type === 'AUTORIZACION_BC_CONYUGE' && (
						<AuthorizationSpouseBC />
					)}
					{type === 'ELEGIR_VIVIENDA' && <ChooseHousing />}
					{type === 'GESTION_AVALUO' && <AppraisalManagement />}
					{(type === 'CONSTRUYO_RM' ||
						type === 'CONSTRUYO_AT' ||
						type === 'CONSTRUYO_CN') && (
							<Build
								type={
									type === 'CONSTRUYO_RM'
										? 'RM'
										: type === 'CONSTRUYO_AT'
											? 'AT'
											: 'CN'
								}
							/>
						)}
					{(type === 'CARACTERISTICAS_RM' ||
						type === 'CARACTERISTICAS_AT' ||
						type === 'CARACTERISTICAS_CN') && (
							<Characteristic
								type={
									type === 'CARACTERISTICAS_RM'
										? 'RM'
										: type === 'CARACTERISTICAS_AT'
											? 'AT'
											: 'CN'
								}
							/>
						)}
					{type === 'PLAZO_ESTIMADO' && <EstimatedTerm />}
					{type === 'ENTIDADES' && <CICB />}
					{type === 'AVIABLE_CREDITS' && (
						<AviableCreditPanel
							setOpen={setOpen}
							setOpenModal={setOpenModal}
							dataAviable={dataAviable}
						/>
					)}
					{type === 'ETC' && (
						<ETC />
					)}

					{!dataAviable && (
						<>
							<Divider style={{ margin: '20px 0px' }} />
							<Box width={250} margin={'auto'}>
								<CustomButton
									label={'Regresar'}
									onClick={() => {
										setOpen(false);
										setOpenModal && setOpenModal(false);
									}}
									variant="solid"
								/>
							</Box>
						</>
					)}
				</>
			</BaseModal>
		</>
	);
};

export default HelpPanel;
