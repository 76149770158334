import { CircularProgress, Paper } from '@mui/material';
import { useStyles } from './styles';
import StepComponent from './Steps';

interface IProps{
	title: string;
	progress: number;
	hideProgress?: boolean;
	flow?: Array<{
		title?: string | JSX.Element | JSX.Element[];
		description?: string | JSX.Element | JSX.Element[];
	}>
}

const FlowCard = ({
	title = '',
	progress = 0,
	hideProgress = false,
	flow = [],
}: IProps) => {
	const classes = useStyles();
	return (
		<Paper className={classes.flowCard} variant="outlined" >
			{!hideProgress && (
				<div className={classes.seleccion}>
					<CircularProgress
						className={classes.progress}
						variant="determinate"
						color="success"
						value={ progress < 100 ? progress : 100 }
					/>
					<CircularProgress
						className={classes.progressBackground}
						variant="determinate"
						color="inherit"
						value={ flow?.length !== 0 ? 100
											 	   : 0 
							  }
					/>
					<h4 className={classes.flowTitle}>{title}</h4>
				</div>
			)}
			{flow?.length !== 0 && (
				<div className={classes.firstStep}>			
					<hr className={classes.checkConnect}/>
				</div>
			)}
			<div className="stepper-container">
				<StepComponent listItem={flow} />
			</div>
			{flow?.length === 0 && (
				<h5 className={classes.sinElementos}>
					Aún no hay elementos que mostrar
				</h5>
			)}
		</Paper>
	);
};

export default FlowCard;
