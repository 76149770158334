// DEPENDENCIES
import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';
import { Fragment, useState } from 'react';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import 'moment/locale/es';
// COMPONENTS
import ModalLoading from '../../../components/ModalLoading';
// RESOURCES
import { sharedResponsabilityAPI } from '../../../api/modules/sharedResponsability';
import { currencyFormat } from '../../../utils/expressions';
import { RootState } from '../../../store';
import { useStyles } from './styles';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';
import BaseModal from '../../../components/Modal';
import CustomAlert from '../../../components/CustomAlert';

type rowTableObj = {
	num_mes: number;
	mes: string;
	pago: string;
	saldo: string;
	interes: string;
	fpp: string;
	seguro: string;
	aportacion: string;
	abono: string;
	pendiente: string;
};

type rowTableDataUser = {
	title: string;
	description: string;
};

const AmortizationTable = ({ codigoGrupo, tasa }: any) => {
	const { user } = useSelector((state: RootState) => state.session);
	const [rowAmortization, setRowAmortization] = useState<Array<rowTableObj>>([]);
	const [rowDataUser, setRowDataUser] = useState<Array<rowTableDataUser>>([]);
	const [openModal, setopenModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errMessage, setErrMessage] = useState([]);
	const classes = useStyles();

	const creditUser: string | undefined = user?.credit;
	const tipoOperacion: string | undefined = '1';
	const nssUser: string | undefined = user?.nss;

	const getDataAmortization = async (
		tipoOperacion: string | undefined,
		creditUser: string | undefined,
		codigoGrupo: string | undefined
	) => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const tipoOperacion_BASE64 = await encrypt(tipoOperacion || '', key);
			const CREDIT_BASE64 = await encrypt(creditUser || '', key);
			const GRUPO_BASE64 = await encrypt(codigoGrupo || '', key);

			const resultCredit = await sharedResponsabilityAPI.elegibility(CREDIT_BASE64);
			const fechaOtorgamiento: string = resultCredit?.result?.datosCredito?.fechaOtorgamiento;
			const { result } = await sharedResponsabilityAPI.amortizationTable(
				tipoOperacion_BASE64,
				CREDIT_BASE64,
				GRUPO_BASE64,
			);
			if (!result[0].PLAZO)
				return setErrMessage(JSON.parse(result[0] || '[]'))
			const rowTableCreateData: Array<rowTableObj> = [];
			for (const row of result) {
				rowTableCreateData.push(
					createData(
						row['PLAZO'],
						row['FCH'],
						row['PGO'],
						row['SDO'],
						row['INTERES'],
						row['FPP'],
						row['SEGURO'],
						row['APORTACION'],
						row['CAPITAL'],
						row['SDO_NVO']
					)
				);
			}
			rowTableCreateData.unshift(
				createData(0, ' ', '', rowTableCreateData[0].saldo, '', '', '', '', '', '')
			);
			setRowAmortization(rowTableCreateData);
			function createUserData(title: any, description: any) {
				return {
					title,
					description,
				};
			}

			let fecha = moment(fechaOtorgamiento, 'YYYYMMDD').format('D [de] MMMM [de] YYYY');
			const dataUser = [
				createUserData('Número de seguridad social:', `${nssUser}`),
				createUserData('Número de crédito:', `${creditUser}`),
				createUserData('Fecha de otorgamiento:', `${fecha}`),
			];
			setRowDataUser(dataUser);

		} catch (error) {

		} finally {
			setLoading(false)
		}
	};

	const columns = [
		{
			key: 'num_mes',
			title: '#',
		},
		{
			key: 'mes',
			title: 'Mes',
		},
		{
			key: 'pago',
			title: 'Pago mensual acreditado ',
		},
		{
			key: 'saldo',
			title: 'Saldo inicial',
		},
		{
			key: 'interes',
			title: 'Interes',
		},
		{
			key: 'fpp',
			title: 'Fondo de protección de pagos ',
		},
		{
			key: 'seguro',
			title: 'Seguro de daños',
		},
		{
			key: 'aportacion',
			title: 'Aportación patronal ',
		},
		{
			key: 'abono',
			title: 'Abono a capital',
		},
		{
			key: 'pendiente',
			title: 'Saldo pendiente',
		},
	];

	function createData(
		num_mes: number,
		mes: string,
		pago: string,
		saldo: string,
		interes: string,
		fpp: string,
		seguro: string,
		aportacion: string,
		abono: string,
		pendiente: string
	) {
		return {
			num_mes,
			mes,
			pago,
			saldo,
			interes,
			fpp,
			seguro,
			aportacion,
			abono,
			pendiente,
		};
	}

	const openModalFunction = () => {
		setopenModal(!openModal);
	};
	const closeModal = () => {
		setopenModal(!openModal);
	};

	const generatorPDF = async () => {
		const input: HTMLElement | null = document.getElementById('divToPrint');
		const pdf = new jsPDF();
		pdf.html(input!, {
			margin: [5, 5, 5, 5],
			html2canvas: {
				scale: 0.14,
			},
			callback: function (pdf) {
				pdf.save('amortizacion.pdf');
			},
		});
	};

	return (
		<>
			<Link
				component={'button'}
				onClick={() => {
					openModalFunction();
					getDataAmortization(tipoOperacion, creditUser, codigoGrupo);
				}}
				style={{ display: 'flex', alignItems: 'center', padding: '2px 0' }}
			>
				Abrir
			</Link>
			<ModalLoading loading={loading} />
			<BaseModal open={openModal} onClose={closeModal} width="xl">
				<>
					{errMessage.length > 0 ? errMessage.map((err: any) => (
						<Fragment key={err.CODIGO}>
							<CustomAlert
								message={err.MENSAJE}
								severity={'error'}
								show={Boolean(err)}
							/>
						</Fragment>
					))
						:
						<>
							<p onClick={generatorPDF} className={classes.descargar}>
								Descargar
							</p>
							<div id="divToPrint">
								<div className={classes.divContainer}>
									<TableContainer>
										<p
											style={{
												background: 'red',
												height: '20px',
												margin: '0px',
												borderTopLeftRadius: '22px',
												borderTopRightRadius: '22px',
											}}
										/>
										<Table>
											<TableBody>
												{rowDataUser.map((element) => {
													return (
														<TableRow>
															<TableCell align="left">
																{element.title}
															</TableCell>
															<TableCell
																style={{
																	background: '#FCFFD2',
																}}
																align="right"
															>
																{element.description}
															</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
								<TableContainer>
									<p className={classes.title}>
										Tabla de amortización informativa con Responsabilidad
										Compartida
									</p>
									<p className={classes.tasa}>{`Tasa ${tasa}%`}</p>
									<Table>
										<TableHead>
											<TableRow>
												{columns.map((column: any) => (
													<TableCell
														key={column.key}
														style={{
															fontWeight: 'bold',
															color: 'black',
															background: '#DEDEDE',
														}}
														align="center"
													>
														{column.title}
													</TableCell>
												))}
											</TableRow>
										</TableHead>{' '}
										<TableBody>
											<TableRow
												style={{
													background: '#fdffe0',
													textAlign: 'center',
												}}
											/>

											{rowAmortization.map((row, index) => {
												let momentDate = moment(row.mes, 'YYYYMMDD').format('MMMM-YY');
												return (
													<>
														<TableRow
															style={{ background: index % 2 ? '#fdffe0' : '#FCFFD2' }}
														>
															<TableCell
																className={classes.boldClass}
																align="center"
															>
																{row.num_mes}
															</TableCell>
															<TableCell
																align="center"
																className={classes.boldClass}
															>
																{momentDate ===
																	'Fecha inválida'
																	? ''
																	: momentDate}
															</TableCell>
															<TableCell
																align="center"
																className={`${row.pago > '0'
																	? classes.boldClass
																	: classes.cell
																	}`}
															>
																{row.pago >= '0'
																	? `$${currencyFormat(
																		row.pago
																	)}`
																	: ' '}
															</TableCell>
															<TableCell
																align="center"
																className={`${row.saldo > '0'
																	? classes.boldClass
																	: classes.cell
																	}`}
															>
																{row.saldo >= '0'
																	? `$${currencyFormat(
																		row.saldo
																	)}`
																	: ' '}
															</TableCell>
															<TableCell
																align="center"
																className={`${row.interes > '0'
																	? classes.boldClass
																	: classes.cell
																	}`}
															>
																{row.interes >= '0'
																	? `$${currencyFormat(
																		row.interes
																	)}`
																	: ' '}
															</TableCell>
															<TableCell
																align="center"
																className={`${row.fpp > '0'
																	? classes.boldClass
																	: classes.cell
																	}`}
															>
																{row.fpp >= '0'
																	? `$${currencyFormat(
																		row.fpp
																	)}`
																	: ' '}
															</TableCell>
															<TableCell
																align="center"
																className={`${row.seguro > '0'
																	? classes.boldClass
																	: classes.cell
																	}`}
															>
																{row.seguro >= '0'
																	? `$${currencyFormat(
																		row.seguro
																	)}`
																	: ' '}
															</TableCell>
															<TableCell
																align="center"
																className={`${row.aportacion > '0'
																	? classes.boldClass
																	: classes.cell
																	}`}
															>
																{row.aportacion >= '0'
																	? `$${currencyFormat(
																		row.aportacion
																	)}`
																	: ' '}
															</TableCell>
															<TableCell
																align="center"
																className={`${row.abono > '0'
																	? classes.boldClass
																	: classes.cell
																	}`}
															>
																{row.abono >= '0'
																	? `$${currencyFormat(
																		row.abono
																	)}`
																	: ' '}
															</TableCell>
															<TableCell
																align="center"
																className={`${row.pendiente > '0'
																	? classes.boldClass
																	: classes.cell
																	}`}
															>
																{row.pendiente >= '0'
																	? `$${currencyFormat(
																		row.pendiente
																	)}`
																	: ' '}
															</TableCell>
														</TableRow>
													</>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						</>}
				</>
			</BaseModal>
		</>
	);
};

export default AmortizationTable;
