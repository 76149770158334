import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((Theme: Theme) =>
	createStyles({
		card: {
			position: 'relative',
			overflow: 'hidden',
			height: '100%',
			'&:hover img': {
				transform: 'scale(1.1)',
				transition: '0.3s',
			},
		},
		cardImage: {
			width: '100%',
			height: '100%',
			display: 'block',
		},
		mainCardBody: {
			position: 'absolute',
			top: '0',
			bottom: '0',
			left: '0',
			right: '0',
			height: '100%',
			width: '100%',
			backgroundColor: 'rgb(0 0 0 / 10%)',
			'&:hover': {
				transition: 'all .5s ease-in',
				backgroundColor: 'rgb(0 0 0 / 10%)',
			},
		},
		cardBody: {
			position: 'absolute',
			top: '0',
			bottom: '0',
			left: '0',
			right: '0',
			height: '100%',
			width: '100%',
			backgroundColor: 'rgb(0 0 0 / 40%)',
			'&:hover': {
				transition: 'all .5s ease-in',
				backgroundColor: 'rgb(0 0 0 / 60%)',
			},
		},
		cardAction: {
			position: 'absolute',
			bottom: '0',
			padding: Theme.spacing(1.5),
			[Theme.breakpoints.up('md')]: {
				padding: Theme.spacing(1, 2.5),
			},
			[Theme.breakpoints.down('md')]: {
				padding: '5px !important',
			},
		},
		linkText: {
			[Theme.breakpoints.down('md')]: {
				fontSize: '12px !important',
				lineHeight: '1 !important',
			},
		},
		mainContainer: {
			display: 'flex',
			flexDirection: 'column',
			[Theme.breakpoints.up('sm')]: {
				flexDirection: 'row',
			},
		},
		firstRow: {
			width: '70%',
			display: 'flex',
			flexDirection: 'column',
			marginRight: 15,
			[Theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				width: '100%',
				marginRight: 0,
			},
		},
		secondRow: {
			height: 'max-content',
			flexDirection: 'column',
			textAlign: 'center',
			justifyContent: 'space-between',
			display: 'flex',
			marginLeft: '5px',
			backgroundColor: '#FFFFFF',
			border: '1px solid #E8E8E8',
			borderRadius: '4px',
			padding: 10,
			maxWidth: 340,
			[Theme.breakpoints.down('md')]: {
				height: 'max-content',
			},
			[Theme.breakpoints.down('sm')]: {
				margin: '20px auto',
				padding: 10,
				backgroundColor: 'initial',
				border: '0px',
				borderRadius: '0px',
			},
		},
		secondRow2: {
			flexDirection: 'column',
			textAlign: 'center',
			justifyContent: 'space-between',
			display: 'flex',
			marginLeft: '5px',
			backgroundColor: '#FFFFFF',
			border: '1px solid #E8E8E8',
			borderRadius: '4px',
			padding: 10,
			maxWidth: '100%',
			[Theme.breakpoints.down('md')]: {
				height: 'max-content',
			},
			[Theme.breakpoints.down('sm')]: {
				margin: '20px auto',
				padding: 10,
				backgroundColor: 'initial',
				border: '0px',
				borderRadius: '0px',
			},
		},
		firstGrid: {
			width: '100%',
			[Theme.breakpoints.down('md')]: {
				flexDirection: '50%',
			},
		},
		secondGrid: {
			width: '100%',
			paddingLeft: 8,
			[Theme.breakpoints.down('md')]: {
				flexDirection: '50%',
				padding: 0,
			},
		},
		cont: {
			display: 'flex',
			flexDirection: 'row',
			[Theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
		},
		contButtom: {
			display: 'flex',
			backgroundColor: '#FFFFFF',
			border: '1px solid #E8E8E8',
			borderRadius: '4px',
			marginTop: 10,
			[Theme.breakpoints.down('md')]: {
				flexWrap: 'wrap',
			},
		},
		divSaber: {
			width: '25%',
			textAlign: 'center',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			[Theme.breakpoints.down('md')]: {
				width: '100%',
			},
		},
		divSaberImg: {
			width: '100%',
			[Theme.breakpoints.down('md')]: {
				width: '50%',
			},
			[Theme.breakpoints.down('sm')]: {
				width: '80%',
			},
		},
		divInfoSaber: {
			width: '75%',
			padding: '10px 20px',
			lineHeight: '1.3 !important',
			[Theme.breakpoints.down('md')]: {
				textAlign: 'center',
				width: '100%',
				padding: '0 20px 0px',
			},
		},
		txtSaberMas: {
			fontSize: '20px',
			fontWeight: 'bold',
			color: '#333333',
			marginBottom: '5px',
		},
		txtBlue: {
			fontSize: 13,
			color: '#173C69',
			letterSpacing: '0.13px',
			lineHeight: '1.3 !important',
			[Theme.breakpoints.down('sm')]: {
				fontSize: '16px',
				letterSpacing: '-0.11px',
			},
		},
		txtRed: {
			fontSize: 16,
			color: '#D1001F',
			lineHeight: 2,
			fontWeight: 600,
		},
		imgActions: {
			backgroundColor: Theme.palette.common.white,
			borderRadius: '4px',
			padding: 0,
			position: 'relative',
			maxHeight: '145px',
			marginTop: '10px',
			[Theme.breakpoints.down('md')]: {
				margin: Theme.spacing(2, 0),
			},
		},
		imgActions2: {
			backgroundColor: Theme.palette.common.white,
			borderRadius: '4px',
			padding: 0,
			position: 'relative',
			marginTop: '10px',
			[Theme.breakpoints.down('md')]: {
				margin: Theme.spacing(2, 0),
			},
		},
		makeADate: {
			backgroundColor: Theme.palette.primary.dark,
			borderRadius: '4px',
			border: '1px #E8E8E8 solid',
			padding: 10,
			marginBottom: 0,
			textAlign: 'center',
			display: 'block',
			position: 'relative',
			[Theme.breakpoints.down('sm')]: {
				display: 'none',
				marginBottom: 30,
			},
		},
		makeADateMobil: {
			display: 'none',
			[Theme.breakpoints.down('sm')]: {
				backgroundColor: Theme.palette.primary.dark,
				borderRadius: '4px',
				border: '1px #E8E8E8 solid',
				padding: 10,
				marginBottom: 30,
				textAlign: 'center',
				display: 'block',
			},
		},
		imgTemas: {
			maxWidth: '100%',
		},
		button: {
			display: 'none',
			[Theme.breakpoints.down('sm')]: {
				display: 'block',
			},
		},
		containerModalInfo: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			'& p': {
				margin: '16px 0 !important'
			}
		},
		containerBtnAcceptModal: {
			width: '50%',
			[Theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		containerBtns: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
		}
	})
);
