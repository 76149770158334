import { Paper } from '@mui/material';
import { useStyles } from '../styles/taller.styles';
import StarIcon from '@mui/icons-material/Star';
import useTallerMas from '../hooks/useElements';

interface ICardButton {
	title: string;
	subTitle: string;
	color: string;
	footerTitle?: string;
	isActive?: boolean;
	showStar?: boolean;
	imgLogo?: string;
	onClick: () => void;
}
const CardButton = ({
	title,
	subTitle,
	color,
	isActive,
	showStar,
	footerTitle,
	imgLogo,
	onClick,
}: ICardButton) => {
	const { matches } = useTallerMas();
	const styles = useStyles({ color });
	return (
		<Paper
			elevation={1}
			className={`${
				matches ? styles.containerCardSM : styles.containerCard
			} ${isActive ? styles.containerActiveCard : ''}`}
			onClick={onClick}
		>
			{showStar && (
				<div className={styles.startIconAbsolute}>
					<div>
						<StarIcon
							style={{
								color: matches ? color : '#fff',
								fontSize: matches ? 14 : 12,
								marginTop: matches ? 2: 5,
							}}
						/>
					</div>
				</div>
			)}
			<div className={styles.containerBackground}>
				<div className={styles.containerImg}>
					{imgLogo && <img src={imgLogo} alt={title} />}
				</div>
				{!matches && (
					<div className={styles.containerLabels}>
						<label className={styles.labelTitle}>{title}</label>
						<label className={styles.labelSubtitle}>
							{subTitle}
						</label>
					</div>
				)}
			</div>
			{matches && (
				<div className={styles.containerLabels}>
					<label className={styles.labelTitle}>{title}</label>
					<label className={styles.labelSubtitle}>{subTitle}</label>
				</div>
			)}
			<div className={styles.labelFooter}>{footerTitle}</div>
		</Paper>
	);
};

export default CardButton;
