import { Dialog, Box, Divider, useMediaQuery } from '@mui/material';
import CustomButton from '../../../../../components/Button';
import theme from '../../../../../config/theme';

interface IProps {
	open: boolean;
	setOpen: (arg: boolean) => void;
}

const ModalExtiSumary = ({ open, setOpen }: IProps) => {
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const handleOpen = () => {
		setOpen(false);
		window.open('https://www.yavocapital.com/mejorasi-registro', '_blank');
	};

	return (
		<Dialog open={open} fullWidth maxWidth={'md'}>
			<Box padding={'10px 25px'} textAlign={'center'}>
				<h3 style={{ color: '#293990' }}>¡Importante!</h3>
				<p>
					Estimado (a) derechohabiente tú trámite de inscripción de crédito continuará a través de un sitio web externo, por lo que saldrás de la página del Infonavit. Te informamos que al Aceptar continuar con tu trámite en línea, Yavo Capital será encargado del tratamiento de datos personales y por tanto es responsable de las obligaciones de protección y cuidado de tus datos personales
				</p>
				<p>¿Deseas continuar?</p>
			</Box>
			<Divider />
			<Box
				padding={'10px 25px'}
				display={'flex'}
				justifyContent={'center'}
				flexDirection={isMobile ? 'column-reverse' : 'row'}
			>
				<Box width={isMobile ? 'auto' : 200} margin={'5px'}>
					<CustomButton
						label={'No'}
						variant="outlined"
						onClick={() => setOpen(false)}
					/>
				</Box>
				<Box width={isMobile ? 'auto' : 200} margin={'5px'}>
					<CustomButton label={'Si'} onClick={handleOpen} />
				</Box>
			</Box>
		</Dialog>
	);
};

export default ModalExtiSumary;
