import { IUser } from '../../interfaces/user';

export interface SesionState {
	user: IUser | null;
	isLogged: boolean;
	remember: number;
	tokenExpired: boolean;
	isImpersonalized: boolean;
	validateDatos: boolean;
	validatePensionado: boolean;
	validateCorreccion: boolean;
}

export const SET_USER = 'session/setUser';
export const SIGN_OUT = 'session/signOut';
export const SIGN_IN = 'session/signIn';
export const UPDATE_USER = 'session/updateUser';
export const CHANGE_REMEMBER = 'session/changeRemember';
export const CHECK_TOKEN = 'session/checkToken';
export const CHANGE_VALIDATE_DATA = 'session/changeValidateData';
export const CHANGE_VALIDATE_PENSIONADO = 'session/changeValidatePensionado';
export const CHANGE_VALIDATE_CORRECCION = 'session/changeValidateCorreccion';

export interface SignInProps {
	email: string;
	password: string;
}

export const INITIAL_STATE: SesionState = {
	user: null,
	isLogged: false,
	remember: 0,
	tokenExpired: false,
	isImpersonalized: false,
	validateDatos: false,
	validatePensionado: false,
	validateCorreccion: false,
};
