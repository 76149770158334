import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    containerButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
		margin: '0px 20px',

        [theme.breakpoints.down("md")]: {
            flexDirection: 'column-reverse',
        },

        "& article": {
            width: '100%',
            maxWidth: '250px',
            margin: '20px',   
            
            [theme.breakpoints.down("md")]: {
                maxWidth: '100%',
				margin: '10px',  
            }
        }
    }
}));
