import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('136', 'sigue-caso-adjunta-documentos');

export const ENDPOINTS_SIGUE_TU_CASO_Y_ADJUNTA_DOCUMENTOS: IBitacora = {
	'/follow-case': _bitacora.Read('136100', 'Consultar caso'),
	'/list-documents': _bitacora.Read(
		'136101',
		'Consultar lista de documentos'
	),
	'/case-attach-documents': _bitacora.Read(
		'136102',
		'Adjuntar documento del caso'
	),
};
