export const defaultComplaintForm = {
	empDesarrolladora: '',
	entidad: '',
	cobertura: '',
	utilizado: '',

	personaQueja: '',
	tramite: '',
	estado: '',
	direcPersona: '',
	telPersona: '',
	casoPrevio: '',
	fechaOcurrido: new Date(),
	notas: '',
};
