import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	VerificacionLogo: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		marginBottom: 20,
	},
	VerificacionCodigoTitle: {
		fontSize: '35px',
		textAlign: 'center',
		color: '#293990',
		width: '100%',
		display: 'block',
		clear: 'both',
	},
	VerificacionText: {
		fontSize: '17px',
		textAlign: 'center',
		width: '100%',
	},
	divCodigos: {
		width: '100%',
		maxWidth: '300px',
		margin: '0 auto 35px',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		display: 'flex',
		alignContent: 'center',
	},
	VerificacionCodigo: {
		width: '50px',
		background: '#F2F2F2',
		borderRadius: '5px',
		height: '50px',
		color: '#000000',
		borderColor: '#BABABA',
		borderStyle: 'solid',
		borderWidth: '1',
		display: 'flex',
		alignItems: 'center',
		paddingleft: '10',
		fontSize: '2em',
		textAlign: 'center',
	},
	textGray14: {
		fontSize: '16px',
		color: '#858585',
	},
	link: {
		textDecoration: 'underline',
		color: 'red',
		cursor: 'pointer',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	divboton: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		width: 'auto',
		paddingTop: '5%',
		paddingLeft: 150,
		paddingRight: 150,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 1,
			paddingRight: 1,
		},
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 1,
			paddingRight: 1,
		},
	},
}));
