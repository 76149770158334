/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { RootState } from '../../store';

import { useStyles } from './style';
import CustomButton from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import iconDownload from '../../assets/img/icono-descarga.png';
import { formatDate } from '../../interfaces/SaldAndMovement';
import CustomAlert from '../../components/CustomAlert';
import LinearProgress from '@mui/material/LinearProgress';
import { IAlert } from '../../interfaces/alert';
import DocumentView from '../PaymentClarification/DocumentView';

interface Props {
	dataCaso: any;
	fileDowloaded: any;
	alertGral: any;
	dataToWait: any;
	onPrevPage: () => void;
	attachDocument: boolean;
	uploadRecibo: (file: any) => void;
	typeSelected: any;
}

const Status = ({ dataCaso, fileDowloaded, alertGral, dataToWait, attachDocument, uploadRecibo, onPrevPage, typeSelected }: Props) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const { credit } = useSelector((state: RootState) => state.credit);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const style = useStyles();
	const [modalView, setModalView] = useState(null);
	const [file, setFile] = useState({
		nombre: 'No se eligió ningún archivo',
		archivo: null as any,
		size: 0,
		type: ''
	});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [downloadLink, setDownloadLink] = useState('' as any);

	const getPdf = async () => {
		let b64Data = fileDowloaded.payload
		const contentType = 'application/pdf';
		const fileName = 'Convenio.pdf';
		const blob = b64toBlob(b64Data, contentType);
		downloadFile(blob, fileName);
	};

	const b64toBlob = (b64Data: any, contentType: any) => {
		contentType = contentType || '';
		const sliceSize = 512;
		const byteCharacters = window.atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	};

	const downloadFile = (blob: any, fileName: any) => {
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = fileName;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleDocument = (e: any) => {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		const file = e.target.files[0];
		const type = e.target.files[0].type;
		const ext = type === 'image/jpeg' ? 'jpeg' : type === 'application/pdf' ? 'pdf' : 'docx';
		let size = e.target.files[0].size / 1024 ** 2
		if (size < 20) {
			if (/jpg|jpeg|pdf|docx|word/g.test(type)) {
				const url = URL.createObjectURL(file);
				setDownloadLink(url);
				const customName = `${dataCaso.noCaso}_${credit}_${typeSelected === 'NTP' ? 'ZNTP' : 'ZSRE'}_Z906.${ext}`;
				const renamedFile = new File([file], customName, { type: file.type });
				setFile({ nombre: `${dataCaso.noCaso}_${credit}_${typeSelected === 'NTP' ? 'ZNTP' : 'ZSRE'}_Z906.${ext}`, archivo: renamedFile, size: size, type: ext });
				e.target.value = null;
			} else {
				setAlert({
					show: true,
					message: 'Tu documento no está en el formato, recuerda que sólo puedes enviar documentos con los siguientes formatos: .pdf, .jpeg o word(.docx).',
					severity: 'error',
				});
			}
		} else {
			setAlert({
				show: true,
				message:
					'El tamaño del archivo no debe de ser mayor a 20 MB.',
				severity: 'error',
			});
		}
	};

	return (
		<>
			{!attachDocument && (
				<div style={{ padding: '5px 30px' }}>
					<h2 style={{ color: '#293990', textAlign: 'center', paddingBottom: 20 }}>
						Gracias por utilizar los servicios digitales del Infonavit
					</h2>
					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matches ? 'center' : 'initial',
								}}
							>
								Número de caso
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matches ? 'center' : 'right' }}
						>
							<label>{dataCaso.noCaso}</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.1 }} />
					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matches ? 'center' : 'initial',
								}}
							>
								Fecha de solicitud
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matches ? 'center' : 'right' }}
						>
							<label>{formatDate(dataCaso?.fecha, '/')}</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.1 }} />

					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matches ? 'center' : 'initial',
								}}
							>
								Servicio
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matches ? 'center' : 'right' }}
						>
							<label>Solicitud de reestructura</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.1 }} />

					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matches ? 'center' : 'initial',
								}}
							>
								Estatus
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matches ? 'center' : 'right' }}
						>
							{!dataToWait.wait ? dataCaso.estatusDetalle : 'Estamos cargando tu documentación'}
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.1 }} />

					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matches ? 'center' : 'initial',
								}}
							>
								Fecha de estatus
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matches ? 'center' : 'right' }}
						>
							<label>{formatDate(dataCaso?.fechaEstatus, '/')}</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.1 }} />

					<Grid
						container
						columns={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<h3
								style={{
									fontSize: 18,
									margin: 0,
									textAlign: matches ? 'center' : 'initial',
								}}
							>
								Descripción
							</h3>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={9}
							lg={9}
							style={{ textAlign: matches ? 'center' : 'right' }}
						>
							<label>{dataCaso?.descripcion}</label>
						</Grid>
					</Grid>
					<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.1 }} />

					{dataToWait.wait && (
						<div>
							<div>
								<div className={style.contentFile}>
									<Box sx={{ width: '50%', marginTop: 5 }}>
										<LinearProgress />
									</Box>
								</div>
							</div>
							<div style={{ margin: matches ? '5px 10px' : '20px 200px', textAlign: 'center' }}>
								<span style={{ color: '#7F7F7F', fontSize: 15 }}>
									Tiempo aproximado de espera 1 min.
								</span>
							</div>
						</div>
					)}

					{!dataToWait.wait && (
						<div>
							{fileDowloaded.valid && (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										alignItems: 'center',
										marginTop: '1em',
									}}
								>
									<div className={style.contentFooterDataLink}>
										<div onClick={getPdf}
											style={{
												cursor: 'pointer',
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<label style={{ marginTop: 7, cursor: 'pointer' }}>
												Descargar tu convenio
											</label>
											<img src={iconDownload} alt="download" />
										</div>
									</div>
								</Box>
							)}

							<Box className={style.BoxAlert}>
								<CustomAlert
									message={alertGral?.message}
									severity={alertGral?.severity}
									show={alertGral?.show}
								/>
							</Box>

							<div
								style={{
									maxWidth: 800,
									margin: 'auto',
									marginTop: 30,
								}}
							>
								<div
									style={{
										width: matches ? '100%' : '600px',
										margin: '0px auto',
										display: matches ? 'block' : 'flex',
										justifyContent: 'space-between',
									}}
								>
									<CustomButton
										label="Finalizar"
										onClick={() => navigate('/')}
										variant="solid"
										styles={{
											width: 260,
											height: 40,
											margin: '0px auto',
										}}
									/>
								</div>
							</div>
						</div>
					)}

				</div>
			)}
			{attachDocument && (
				<div style={{ padding: '20px 30px' }}>
					<div className={style.textAlign_Center_spaciado}>
						<Grid container columns={12}
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}>
							<Grid item xs={12} style={{ textAlign: 'center', marginBottom: '50px' }}>
								<b>Adjunta tu recibo de luz en formato PDF, JPEG o WORD(docx), con una antigüedad no mayor a 3 meses.</b><br />
								<b>Si no adjuntas tu recibo de luz, tu caso no procederá.</b>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={6} style={{ textAlign: 'left' }}>
								<b>Número de caso</b>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={6} style={{ textAlign: 'right' }}>
								{dataCaso.noCaso}
							</Grid>
						</Grid>
						<hr
							style={{
								opacity: 0.2,
								marginTop: 20,
								marginBottom: 20,
							}}
						/>

						<Grid container columns={12}
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}>
							<Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'left' }}>
								<b>Recibo de luz</b>
							</Grid>
							<Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'left' }}>
								{file.archivo ? 'Recibo de luz' : ''}
							</Grid>
							<Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'right' }}>
								{!file.archivo && (
									<>
										<label
											style={{
												cursor: 'pointer',
												color: 'red',
											}}
										>
											<input
												type="file"
												accept=".pdf,.jpg,.jpeg,.docx"
												name="file"
												onChange={handleDocument}
												style={{ display: 'none' }}
											/>
											Elegir archivo
										</label>
									</>
								)}

								{file.archivo && (
									<div
										style={{
											display: 'flex',
											justifyContent: matches ? 'center' : 'right',
										}}
									>
										<div
											style={{
												display: 'flex',
												justifyContent: 'right',
												marginLeft: 10,
											}}
										>
											<label
												style={{
													cursor: 'pointer',
													color: 'red',
												}}
												onClick={() => {
													setFile({
														nombre: 'No se eligió ningún archivo',
														archivo: null,
														size: 0,
														type: ''
													});
												}}
											>
												<ClearIcon
													style={{
														fontSize: 16,
														color: 'red',
													}}
												/>{' '}
												Eliminar archivo
											</label>
										</div>
										{file.type !== 'docx' && (
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													marginLeft: 10,
												}}
											>
												<label
													style={{
														cursor: 'pointer',
														color: 'red',
													}}
													onClick={() => setModalView(file.archivo)}
												>
													<SearchIcon
														style={{
															fontSize: 16,
															color: 'red',
														}}
													/>{' '}
													Ver archivo
												</label>
											</div>
										)}
										{file.type === 'docx' && (
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													marginLeft: 10,
												}}
											>
												<a href={downloadLink} download="recibo_de_luz.docx" style={{ textDecoration: 'none' }}>
													<label style={{ color: 'red', display: 'flex', cursor: 'pointer' }}>
														<img src={iconDownload} alt="download" style={{ height: '20px' }} />
														Descargar archivo
													</label>
												</a>
											</div>
										)}
									</div>
								)}
							</Grid>
						</Grid>
					</div>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
						}}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								width: '540px',
								paddingTop: '50px',
							}}>
							<Box className={style.BoxButton}>
								<CustomButton
									onClick={() => navigate('/')}
									variant="outlined"
									label="Salir"
									styles={{ height: '100%' }}
								/>
							</Box>
							<Box className={style.BoxButton}>
								<CustomButton
									onClick={() => uploadRecibo(file)}
									variant="solid"
									label="Continuar"
									disabled={file.archivo ? false : true}
								/>
							</Box>
						</Box>
					</Box>
					<CustomAlert
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
					/>
				</div>
			)}
			<DocumentView file={modalView} setClose={setModalView} />
		</>
	);
};

export default Status;
