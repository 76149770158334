import { Link } from '@mui/material';
import { red } from '@mui/material/colors';
import { formatDate } from '../../utils/dates';
import { centeredItems } from './styles';
import AmortizationTableAdviser from './components/AmortizationTableAdviser';
import { formatMoney } from '../../utils/validators';
import iconoPDF from '../../assets/img/icono-descarga.png';

export const tabsOptions = [
	{
		id: 1,
		title: 'Trámite',
		disabled: false,
		checked: false,
		columns: 4,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: 2,
		title: 'Video',
		disabled: false,
		checked: false,
		columns: 4,
		orderMD: 1,
		orderSM: 2,
	},
	{
		id: 3,
		title: 'Preguntas frecuentes',
		disabled: false,
		checked: false,
		columns: 4,
		orderMD: 2,
		orderSM: 3,
	},
];

export const actualConditionsRows = [
	{
		key: 'numeroCredito',
		title: 'Número de crédito',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'fechaOtorgamiento',
		title: 'Fecha de otorgamiento',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) =>
			`${value.slice(6)}/${value.slice(4, 6)}/${value.slice(0, 4)}`,
	},
	{
		key: 'saldoOriginacion',
		title: 'Saldo de originación en pesos',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'saldoProyecto',
		title: 'Saldo actual en pesos',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'regimen',
		title: 'Régimen actual',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'estatusContable',
		title: 'Estatus contable actual',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'numeroPagosOmisos',
		title: 'Número de pagos omisos',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
];

export const actualConditionsCompareRows = [
	{
		key: 'pagasHoy',
		title: (
			<span>
				Pago mensual actual (en pesos)
				<sup style={{ color: red[800] }}>*</sup>
			</span>
		),
		titleTooltip: 'Esto es lo que pagas hoy en pesos',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'pagoAcordado',
		title: 'Pago en la Originación (en pesos)',
		titleTooltip:
			'Esto es lo que se acordó que pagarias cuando obtuviste tu crédito',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'tasaInteres',
		title: 'Tasa de interés anual actual',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${Number(value).toFixed(2)}%`,
	},
	{
		key: 'plazoPermanente',
		title: 'Pagos efectivos pendientes',
		titleTooltip:
			'Plazo máximo de pagos efectivos pendientes (los pagos que te hacen falta realizar)',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
	},
];

export const responsabilituyCompareRows = (grupo: string) => [
	{
		key: grupo === '2B' ? 'pagoMensual' : 'factor',
		title: (
			<>
				Pago fijo en pesos <sup style={{ color: red[800] }}>*</sup>
			</>
		),
		titleTooltip:
			'Esto es lo que pagarás de manera fija en pesos con el programa Responsabilidad Compartida',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'tasaInteres',
		title: 'Tasa de interés anual',
		titleTooltip:
			'Tu tasa de interés con base en el salario registrado por tu patrón. En caso que te encuentres desempleado, se calcula con base en los pagos que has realizado o en tu salñario de originación',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${value}%`,
	},
	{
		key: 'plazo',
		title: (
			<>
				Pagos efectivos pendientes
				<sup style={{ color: red[800] }}>1</sup>
			</>
		),
		titleTooltip:
			'Plazo máximo de pagos efectivos pendientes (los pagos que te hacen falta realizar) con el programa Responsabilidad Compartida',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
	},
	grupo === '2A' && {
		key: 'montoBeneficio',
		title: 'Monto de beneficio (pesos)',
		titleTooltip:
			'Plazo máximo de pagos efectivos pendientes (los pagos que te hacen falta realizar) con el programa Responsabilidad Compartida',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	grupo === '2B' && {
		key: 'montoDescuento',
		title: 'Monto de beneficio (pesos)',
		titleTooltip:
			'Plazo máximo de pagos efectivos pendientes (los pagos que te hacen falta realizar) con el programa Responsabilidad Compartida',
		styleTitle: {
			fontWeight: 'bold',
			...centeredItems,
			justifyContent: 'start',
		},
		alignDescription: 'right',
		renderValue: (value: any) => `${formatMoney(value)}`,
	},
	{
		key: 'codigoGrupo',
		title: 'Tabla de amortización',
		styleTitle: { fontWeight: 'bold' },
		styleDescription: {
			...centeredItems,
			justifyContent: 'end',
			color: red[500],
		},
		renderValue: (value: any, data: any) => (
			<AmortizationTableAdviser
				codigoGrupo={value}
				tasa={data?.tasaInteres}
			/>
		),
	},
];

export const summaryRows = [
	{
		key: 'case',
		title: 'Número de crédito',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'date',
		title: 'Fecha de otorgamiento',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'service',
		title: 'Saldo de originación en pesos',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'estatus',
		title: 'Saldo actual en pesos',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'estatus_date',
		title: 'Régimen actual',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
];

export const mockSummary = {
	case: '2343456',
	date: formatDate('P'),
	service: 'Responsabilidad Compartida',
	estatus: 'XXXXX',
	estatus_date: formatDate('P'),
};

export const mockCompareData = {
	payment: '$2500.00',
	origin: '$2300.00',
	interes: '10.0%',
	pending: '222/150',
	amortization: (
		<Link style={centeredItems} component={'button'} underline="hover">
			Descargar{' '}
			<img src={iconoPDF} alt="iconoPDF" style={{ width: 24 }} />
		</Link>
	),
};

export const selectedCreditRows = [
	{
		key: 'payment',
		title: 'Pagarás mensualmente',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'interes',
		title: 'Tasa de interés será de',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'months',
		title: 'Meses restantes para pagar de manera completa',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		key: 'discount',
		title: 'El monto de descuento en tu saldo es de',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
];

export const mockSelectedCredit = {
	payment: '$XXXX.00',
	interes: 'XX%',
	months: 'XX',
	discount: '$XX.XX',
};

export const mockActualConditions = {
	credit: '436216',
	date: formatDate('P'),
	balance: '$512,232.32',
	balance_pesos: '$133,433.42',
	regimen: 'Con relación laboral/Sin relación laboral',
	estatus: 'Vigente',
	payments: '10',
};

export const accordionQuestions = [
	{
		id: 1,
		title: '¿Todos los tipos de crédito pueden tener este beneficio?',
		description:
			'Responsabilidad Compartida ha ampliado el número de acreditadas con créditos en VSM que pondrán ser beneficiados sin restricción por edad, saldo insoluto ni monto de la originación.',
	},
	{
		id: 2,
		title: '¿Aplica para acreditados con o sin relación laboral?',
		description: ' ',
	},
	{
		id: 3,
		title: '¿Cuántos años debe tener mi crédito para poder aplicar a este programa?',
		description: '',
	},
	{
		id: 4,
		title: '¿Todos los créditos obtienen el mismo descuento?',
		description: '',
	},
	{
		id: 5,
		title: '¿Se tiene que firmar algún documento para aceptar este beneficio?',
		description: '',
	},
	{
		id: 6,
		title: '¿Deberé entregar un nuevo aviso de retención una vez que se aplique la conversión de VSM a pesos?',
		description: '',
	},
	{
		id: 7,
		title: '¿Se cobrará una cuota de adminisrtación?',
		description: '',
	},
	{
		id: 8,
		title: '¿En dónde se reflejará la aplicación del beneficio de Responsabilidad Compartida?',
		description: '',
	},
	{
		id: 9,
		title: '¿Se puede liquidar el saldo del nuevo crédito convertido a pesos?',
		description: '',
	},
	{
		id: 10,
		title: 'Después de aceptar, ¿puedo cambiar mis condiciones financieras a VSM?',
		description: '',
	},
];
