/* eslint-disable react-hooks/exhaustive-deps */
import {
	Alert,
	Box,
	Checkbox,
	FormControlLabel,
	Paper,
	useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import TextInput from '../../components/TextInput';
import { useStyles } from './styles';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { CheckCircleOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import ModalLoading from '../../components/ModalLoading';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { apiRfcCorrection } from '../../api/modules/rfcCorrection';
import { IAlert } from '../../interfaces/alert';
import CustomAlert from '../../components/CustomAlert';
import { useNavigate } from 'react-router-dom';
import warningRedAssets from '../../assets/img/icono-advertencia-rojo.png';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';

interface Props {
	setPage: (data: number) => void;
}
interface IDataRFC {
	nombre?: string;
	nss?: string;
	rfcBDSAT?: {
		_text?: string;
	};
	curp?: string;
	email?: string;
	mensaje?: string;
}

const GotSAT = ({ setPage }: Props) => {
	const classes = useStyles();

	const [displayView, setDisplayView] = useState(0);
	const theme = useTheme();
	const { user } = useSelector((state: RootState) => state.session);
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [disableButton, setDisableButton] = useState(true);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [dataRFC, setDataRFC] = useState<IDataRFC>({});
	const [errorWithLink, setErrorWithLink] = useState(false);

	const handleOnChangeCheck = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.checked && !alert.show) {
			setDisableButton(false);
		}
	};

	useEffect(() => {
		const handleOnSubmit = async () => {
			let messageError =
				'No se localizó RFC asociado a la CURP registrada en el Infonavit. Ingresa a la opción de CURP y nombre y verifica tus datos, posteriormente podrás intentar nuevamente el trámite de corrección de RFC. Si quieres verificar tus datos registrados en el SAT ingresa aquí.';
			try {
				setLoading(true);
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const nss_base64 = await encrypt(
					user?.nss.toString() || '',
					key
				);
				const responseStatusRFC = await apiRfcCorrection.consultaDbsat(
					nss_base64
				);
				if (
					responseStatusRFC?.result?.mensaje === messageError ||
					responseStatusRFC?.result?.description === messageError
				) {
					setErrorWithLink(true);
				} else if (
					responseStatusRFC?.result?.mensaje ===
					'CURP_NO_LOCALIZADA_EN_BDSAT'
				) {
					setPage(7);
				} else if (
					responseStatusRFC?.result?.mensaje ===
					'NOMBRES_NO_CORRESPONDEN'
				) {
					setPage(9);
				} else if (
					responseStatusRFC?.result?.mensaje === 'PROCEDE_ACTUALIZACION'
				) {
					const { rfcBDSAT } = responseStatusRFC.result;
					setDataRFC({ ...dataRFC, rfcBDSAT: rfcBDSAT });
				}
			} catch (error: any) {
				if (error?.description === messageError) {
					setErrorWithLink(true);
				} else {
					setAlert({
						show: true,
						message: error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				}
			} finally {
				setLoading(false);
			}
		};
		handleOnSubmit();
	}, []);
	const actualizarRFC = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const email_base64 = await encrypt(
				user?.email.toString() || '',
				key
			);
			const celular_base64 = await encrypt(
				user?.telefonoCelular.toString() || '',
				key
			);
			const rfcBdsat_base64 = await encrypt(
				dataRFC.rfcBDSAT?._text?.toString() || '',
				key
			);

			const responseUpgradeRFC = await apiRfcCorrection.actualizaDbsat(
				nss_base64,
				rfcBdsat_base64,
				email_base64,
				celular_base64
			);
			if (responseUpgradeRFC) {
				setDisplayView(1);
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box>
			<HeaderList
				title="Registro o corrección de RFC"
				date=" 12 julio 2021"
			/>
			<ModalLoading loading={loading} />
			<Paper>
				<div
					id="rfcCorrection"
					style={{ display: displayView === 0 ? 'inline' : 'none' }}
				>
					<h3 className={classes.h3}>
						El RFC obtenido de la consulta a la información del SAT
						es:
					</h3>
					<div className={classes.inputContainer}>
						<div
							className={classes.inputRfc}
							style={{ marginTop: 0 }}
						>
							<TextInput
								data-testid="inputRFC"
								id="rfc"
								name="rfcInput"
								label="RFC"
								value={
									dataRFC.rfcBDSAT?._text
										? dataRFC.rfcBDSAT?._text
										: ''
								}
								disabled
							/>
						</div>
					</div>
					<div>
						<FormControlLabel
							control={
								<Checkbox
									onChange={handleOnChangeCheck}
									data-testid="checkRFC"
									checkedIcon={<CheckBoxOutlinedIcon />}
								/>
							}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								marginTop: matches ? 5 : 2,
								paddingRight: matches ? 0 : 13,
								paddingLeft: matches ? 3 : 0,
							}}
							label="Acepto que se realice el registro o actualización de mi
								RFC en los sistemas del Infonavit"
						/>
					</div>
					<div
						className={classes.inputContainer}
						style={{ display: alert.show ? 'flex' : 'none' }}
					>
						<div
							className={classes.inputRfc}
							style={{ marginTop: 10, marginBottom: 15 }}
						>
							<CustomAlert
								show={alert.show}
								severity={alert.severity}
								message={alert.message}
							/>
						</div>
					</div>
					{errorWithLink && (
						<div
							className={classes.inputContainer}
							style={{ display: 'flex' }}
						>
							<div
								className={classes.inputRfc}
								style={{
									marginTop: 10,
									marginBottom: 15,
									height: 'auto',
								}}
							>
								<Alert
									style={{
										borderRadius: '6px',
										display: 'flex',
										boxSizing: 'border-box',
										alignItems: 'center',
										marginTop: 10,
										marginBottom: 10,
										backgroundColor: '#FFE2E5',
										color: '#D1001F',
									}}
									className={classes.alertIcon}
									severity={'error'}
									sx={{ width: '100%' }}
									icon={
										<img
											src={warningRedAssets}
											className={classes.sizeIcon}
											alt="close"
										/>
									}
								>
									<p>
										No se localizó RFC asociado a la CURP
										registrada en el Infonavit. Ingresa a la
										opción de CURP y nombre y verifica tus
										datos, posteriormente podrás intentar
										nuevamente el trámite de corrección de
										RFC. Si quieres verificar tus datos
										registrados en el SAT ingresa{' '}
										<a
											style={{ color: 'rgb(209, 0, 31)' }}
											href="https://www.sat.gob.mx/home"
											target="blank"
										>
											aquí.
										</a>
									</p>
								</Alert>
							</div>
						</div>
					)}

					<div className={classes.botonContainer}>
						<CustomButton
							data-testid="gotSATExit"
							label="Salir"
							onClick={() => {
								setPage(0);
							}}
							variant="outlined"
							styles={{
								width: matches ? 350 : 250,
								height: 40,
								marginBottom: 10,
							}}
						/>
						<CustomButton
							data-testid="gotSATSave"
							label="Continuar"
							onClick={actualizarRFC}
							variant="solid"
							disabled={disableButton}
							styles={{
								width: matches ? 350 : 250,
								height: 40,
								marginBottom: 15,
								marginLeft: matches ? 0 : 25,
							}}
						/>
					</div>
				</div>
				<div
					id="thanksSAT"
					style={{ display: displayView === 1 ? 'inline' : 'none' }}
				>
					<h3 className={classes.title} style={{ paddingTop: 20 }}>
						Tu RFC ha sido actualizado con éxito
					</h3>
					<h3 className={classes.h3} style={{ paddingTop: 0 }}>
						Tu clave de RFC es {dataRFC.rfcBDSAT?._text}
					</h3>
					<br />
					<div className={classes.listContainer}>
						<CheckCircleOutlined
							color="info"
							style={{ marginTop: 2, marginRight: 5 }}
							fontSize="small"
						/>
						<p className={classes.pGotSAT}>
							Para efecto de precalificación para un crédito, la
							actualización se verá reflejada en un plazo máximo
							de 5 días hábiles.
						</p>
					</div>
					<div className={classes.listContainer}>
						<CheckCircleOutlined
							color="info"
							style={{ marginTop: 2, marginRight: 5 }}
							fontSize="small"
						/>
						<p className={classes.pGotSAT}>
							Para la Constancia de Intereses de Crédito deberás
							acudir al CESI para el trámite de actualización de
							datos correspondientes.
						</p>
					</div>
					<div className={classes.listContainer}>
						<CheckCircleOutlined
							color="info"
							style={{ marginTop: 2, marginRight: 5 }}
							fontSize="small"
						/>
						<p className={classes.pGotSAT}>
							Es importante que también tengas tus datos
							actualizados en tu Afore; revisa tu estado de cuenta
							y en caso de que no esté correcto alguno de tus
							datos, solicita su corrección.
						</p>
					</div>
					<div className={classes.botonContainerThanksSAT}>
						<CustomButton
							label="Finalizar"
							styles={{ width: matches ? 280 : 500 }}
							onClick={() => navigate('/inicio')}
							variant="solid"
						/>
					</div>
				</div>
			</Paper>
		</Box>
	);
};

export default GotSAT;
