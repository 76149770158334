import { HeaderList } from "../../../../../components/HeaderList";

const LinksSpouse = () => {
	return (
		<>
			<span>
				Al registrar el NSS de tu cónyuge, familiar o corresidente en tu
				perfil, podrás usar la opción de crédito que más te convenga.
				<br></br>Sigue estos pasos:
			</span>
			{/* <hr className="mt40"> */}
			<HeaderList
				listSizeIcon={18}
				list={[
					<>Entra a la opción Mi perfil en el menú principal.</>,
					<>Haz clic en Asocia tu NSS.</>,
					<>Elige el tipo de crédito que quieres solicitar.</>,
					<>Escribe el NSS con el que vas asociar tu cuenta.</>,
					<>
						Si tu cónyuge, familiar o corresidente está registrado
						en Mi Cuenta Infonavit, recibirá una notificación para
						que apruebe tu solicitud de asociación.
					</>,
					<>
						Si aún no está registrado, te lo indicaremos para que le
						solicites que se registre a fin de que puedan asociar
						sus cuentas.
					</>,
					<>
						Una vez que tu cónyuge, familiar o corresidente acepte
						la solicitud, tendrás activa la opción de crédito
						conyugal, familiar y corresidente.
					</>,
				]}
			/>
		</>
	);
};

export default LinksSpouse;
