import React, { useState } from 'react';
import {
	AppBar,
	Box,
	Container,
	Divider,
	Grid,
	IconButton,
	Toolbar,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import MovilDrawer from './MovilDrawer';
import SubHeader from './SubHeader';
import TopBar from './TopBar';
import Menu from './Menu';
import { menusNotLogged } from '../../config/menusNoLogged';
import logo from '../../assets/svg/logo-actualizado.svg';
import { menuOptionsByProfile } from './utils';
import { formatDate } from '../../utils/dates';
import { RootState } from '../../store';

type Props = {
	subheaderChild?: React.ReactNode;
	onlyTitle?: boolean;
	title?: string;
};

const Navbar = ({ subheaderChild, onlyTitle, title }: Props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const { user } = useSelector((state: RootState) => state.session);
	const [open, setOpen] = useState<boolean>(false);

	const isOpenDrawer = () => {
		setOpen(!open);
	};

	const userLoggedWithMenus =
		user !== null && Object.entries(user.perfilamiento).length > 0;

	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<AppBar
					sx={{ position: 'relative' }}
					className={classes.header}
					color="transparent"
					position="static"
				>
					<Toolbar>
						{user !== null ? (
							<Box className={classes.hiddenDesktop}>
								<IconButton
									size="large"
									edge="start"
									color="inherit"
									aria-label="menu"
									onClick={isOpenDrawer}
								>
									<MenuIcon />
								</IconButton>
							</Box>
						) : (
							location.pathname.split('/')[1] !== 'registro' &&
							location.pathname.split('/')[1] !== 'cambio-contrasenia' && (
								<Box className={classes.hiddenDesktop}>
									<IconButton
										size="large"
										edge="start"
										color="inherit"
										aria-label="menu"
										onClick={isOpenDrawer}
									>
										<MenuIcon />
									</IconButton>
								</Box>
							)
						)}
						<Box className={classes.container}>
							<img
								className={classes.imgInfonavit}
								src={logo}
								alt="mi cuenta unica"
								onClick={() => navigate('/')}
							/>
						</Box>
						{user !== null ? (
							<Box className={`${classes.divider} ${classes.hiddenMobile}`}>
								<TopBar userInfo={user} />
								<Divider />
								{userLoggedWithMenus && (
									<Menu
										menuElements={menuOptionsByProfile(
											user!.perfilamiento
										)}
									/>
								)}
							</Box>
						) : (
							location.pathname.split('/')[1] !== 'registro' &&
							location.pathname.split('/')[1] !== 'cambio-contrasenia' && (
								<Box
									className={`${classes.divider} ${classes.hiddenMobile}`}
								>
									<Divider style={{ marginTop: 10 }} />
									<Menu menuElements={menusNotLogged} />
								</Box>
							)
						)}
					</Toolbar>
				</AppBar>
				{onlyTitle && (
					<Box className={classes.root}>
						<Container maxWidth="lg">
							<Grid
								container
								spacing={1}
								sx={{ display: 'flex', alignItems: 'center' }}
							>
								<Grid item md={5} xs={12}></Grid>
								<Grid item md={4} xs={12}>
									<p className={classes.onlyTitle}>{title}</p>
								</Grid>
								<Grid item md={3} xs={12}>
									{location.pathname.split('/')[1] === 'registro' && (
										<p
											className={classes.onlyTitleDate}
										>{`Fecha de creación: ${formatDate(
											'dd - MMM - yyyy / hh:MM a'
										)}`}</p>
									)}
								</Grid>
							</Grid>
						</Container>
					</Box>
				)}
				{user !== null
					? userLoggedWithMenus && (
						<>
							<SubHeader
								userLastLoginInfo={user.last_login}
								child={subheaderChild}
							/>
							<MovilDrawer
								menuElements={menuOptionsByProfile(
									user!.perfilamiento
								)}
								open={open}
								setOpen={setOpen}
								userInfo={user}
							/>
						</>
					)
					: location.pathname.split('/')[1] !== 'registro' &&
					location.pathname.split('/')[1] !== 'cambio-contrasenia' && (
						<MovilDrawer
							menuElements={menusNotLogged}
							open={open}
							setOpen={setOpen}
						/>
					)}
			</Box>
		</>
	);
};

export default Navbar;
