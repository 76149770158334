import { Box, Divider, Link } from '@mui/material';
import { useStyles } from './styles';
import { RoundBtn } from '../RoundBtn/roundBtn';
import { IProductCard, ProductCard } from '../ProductCard/productCard';
import { CREDIT_OPTIONS, construYoOptions } from '../../utils/creditOptions.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import Header from '../Header';
import { useController } from '../../hooks/useController';
import { useGeneralStyles } from '../../views/styles/general.styles';
import iconoPDF from '../../../../assets/img/iconsMIUC/icono-descarga.svg';

export const ConstruYoOptions = () => {
  const { returnStep, addSelection, saveData, nextStep, getProgressStep } = useController();
  const classes = useStyles();
  const generalStyle = useGeneralStyles();
  const { dataProcessController } = useSelector((state: RootState) => state.miuc);
  
  //Datos necesarios para el header con ProgressBar
  const currentLine = dataProcessController.processInfo?.credit;
	const { active, total } = getProgressStep();
  const newStep = {
    title: "Elige el tipo de crédito que más te convenga",
    subtitle: "Nos ayuda a encontrar un crédito adaptado a tus necesidades",
    active,
    total,
  }
    
	const onSelectCreditType = (creditType: string) => {
		const selectionItem = {
			title: 'Opción ConstruYO:',
			description: ''
		}
		
		saveData({
			processInfo: {
				creditType,
			},
      processData: {
        active: getProgressStep().active + 1,
      }
		})

		switch (creditType) {
			case CREDIT_OPTIONS.REPARACIONES_MENORES:
				selectionItem.description = 'Reparaciones menores';
				addSelection(selectionItem)
				nextStep(2);
				break;
				
			case CREDIT_OPTIONS.ASISTENCIA_TECNICA:
				selectionItem.description = 'Asistencia técnica';
				addSelection(selectionItem);
				nextStep();
				break;
			case CREDIT_OPTIONS.CONSTRUCTORA:
				selectionItem.description = 'Crédito con constructora';
				addSelection(selectionItem);
				nextStep();
				break;
		}
	};

	return (
		<Box className={classes.container}>
			<Box className={classes.header}>
				<Header steps={newStep}></Header>
			</Box>
			<Box className={generalStyle.content}>
				<h4 className="title">
					Conoce las opciones de ConstruYO y elige la que más te convenga:
				</h4>
				<Box className={classes.optionList}>
					{construYoOptions.map((card: IProductCard) => (
						<ProductCard
							key={card.product}
							product={card.product}
							icon={card.icon}
							title={card.title}
							description={card.description}
							onClick={() => onSelectCreditType(card.product)}
						/>
					))}
				</Box>
				<Box className={classes.linkBox}>
					<Link
						underline="hover"
						href={require('../../../../assets/files/guia-pasos-a-seguir/modalidadesdeConstruYOInfonavit.pdf')}
						target="_blank"
						className={classes.link}
					>
						Conoce más de las modalidades de ConstruYo <img
																		src={iconoPDF}
																		alt="iconoPDF"
																		className={classes.icoDescarga}
																	/>
					</Link>
				</Box>
				<Divider sx={{ marginBottom: '32px' }} />
				<div className="credit-request-actions-container">
					<RoundBtn variant="contained" onClick={() => returnStep()}>
						Regresar
					</RoundBtn>
				</div>
			</Box>
		</Box>
	);
};
