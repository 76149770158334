import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface IProps {
	horizontal?: boolean;
}

export const useStyles = makeStyles<Theme, IProps>((theme: Theme) => ({
	containerVideos: {
		maxHeight: 450,
		height: '100%',
		display: ({ horizontal }) => (horizontal ? '-webkit-box' : 'flex'),
		flexDirection: ({ horizontal }) => (horizontal ? 'row' : 'column'),
		alignItems: 'center',

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	generalVideoContainer: {
		display: ({ horizontal }) => (horizontal ? 'grid' : 'inline'),
		gridTemplateColumns: '50px auto 50px',
		height: '100%',
	},
	arrowVideo: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		'& div': {
			color: '#d8d8d9',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 30,
			width: 30,
			borderRadius: '100%',
			border: '2px solid #d8d8d9',
			cursor: 'pointer',
			margin: '5px 0px',

			'&:hover': {
				color: 'gray',
				borderColor: 'gray',
			},
		},
	},
}));
