import { Box, Divider } from '@mui/material';
import { useStyles } from './styles';
import { RoundBtn } from '../RoundBtn/roundBtn';
import { mappedCredits } from '../../utils/selectionCredit.utils';
import { IProductCard, ProductCard } from '../ProductCard/productCard';
import Header from '../Header';
import { useGeneralStyles } from '../../views/styles/general.styles';
import { useNavigate } from 'react-router-dom';
import { useController } from '../../hooks/useController';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

interface ICard {
	extraMessage?: string;
	onSelectCredit: (credit: string) => void;
	ignoreDestinies?: boolean;
}

export const SelectionCredit = ({
	extraMessage,
	onSelectCredit,
	ignoreDestinies,
}: ICard) => {
	const { dataProcessController } = useController();
	const currentLine = dataProcessController?.processData?.productLine;
	const classes = useStyles();
	const generalStyle = useGeneralStyles();
	const navigate = useNavigate();
	const { user } = useSelector((state: RootState) => state.session);

	return (
		<Box className={classes.container}>
			<Header
				title={`¡Bienvenid${user?.gender === 'H' ? 'o' : 'a'} ${user?.nombre || user?.nombres}!`}
				subtitle="¡Felicidades ya cuentas con la puntuación requerida para solicitar un crédito!"
			/>
			<Box className={generalStyle.content}>
				<h4 className="title">
					Con tus puntos puedes solicitar un crédito para:
				</h4>
				<Box className={classes.creditList}>
					{mappedCredits[currentLine].credits.map(
						(credit: IProductCard) => {
							const destino = (
								dataProcessController.destinosAplicables || []
							).find(
								(destino) => destino.codigo === credit.product
							);
							return (
								<ProductCard
									key={credit.product}
									onClick={onSelectCredit}
									product={credit.product}
									icon={credit.icon}
									title={credit.title}
									description={credit.description}
									hoverDetails={credit.hoverDetails}
									disabled={
										ignoreDestinies
											? !ignoreDestinies
											: !destino?.estatus
									}
								/>
							);
						}
					)}
				</Box>
				{extraMessage && (
					<Box className={classes.extraMessage}>
						<p className="asterisk">*</p>
						<p>{extraMessage}</p>
					</Box>
				)}
				<Divider sx={{ marginY: '32px' }} />
				<div className="credit-request-actions-container">
					<RoundBtn variant="contained" onClick={() => navigate(-1)}>
						Regresar
					</RoundBtn>
				</div>
			</Box>
		</Box>
	);
};
