import { Grid, Link, Badge } from '@mui/material';
import { useStyles } from './styles';
import HelpPanel from '../../HelpPanel';
import seleccion from '../../../assets/svg/seleccion.svg';
import expediente from '../../../assets/svg/expediente.svg';
import { ConstantSteps } from '../constantSteps/constantSteps';

export const AsistTecnicaSteps = (data: {
	avanceTaller: number;
	alert: String;
}) => {
	const classes = useStyles();
	const {
		tallerSaberMas,
		verificaTusDatos,
		hazTusCalculos,
		solicitaTuCredito,
	} = ConstantSteps(data);

	return [
		tallerSaberMas,
		verificaTusDatos,
		hazTusCalculos,
		{
			paperType: 'viewMore',
			paperIcon: expediente,
			title: 'Integra tu expediente',
			description: 'Reúne tus documentos para tramitar tu crédito',
			viewMoreButton: 'Consulta la documentación',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<b>Reúne estos documentos.</b>
						<ul className={classes.viewMoreList}>
							<li>
								Solicitud de inscripción de crédito.{' '}
								<Link
									underline="hover"
									href={require('../../../../../assets/files/guia-pasos-a-seguir/Solicitud_inscripcion_credito_L6.pdf')}
									target="_blank"
								>
									Descárgala aquí
								</Link>
							</li>
							<li>
								Identificación oficial INE/IFE o pasaporte
								vigente, en original y copia.
							</li>
							<li>
								Acta de nacimiento en original y copia. Ten en
								cuenta que, si tu acta de nacimiento es digital,
								debes traerla impresa.{' '}
								<Badge
									badgeContent={
										<HelpPanel type="ACTA_NACIMIENTO" />
									}
									className={classes.helpPanel}
								></Badge>
							</li>
							<li>
								Impresión de tu estado de cuenta bancario con
								cuenta CLABE Interbancaria Estandarizada a 18
								dígitos, con una antigüedad máxima de 3 meses.
							</li>
							<li>Impresión de la CURP.</li>
							<li>
								<Link
									underline="hover"
									href={require('../../../../../assets/files/guia-pasos-a-seguir/CONTRATO_ASESORIA_TECNICA_SEP20.pdf')}
									target="_blank"
								>
									Contrato de Servicios con el Asesor Técnico.
								</Link>
							</li>
							<li>
								Documento que acredite la propiedad o la{' '}
								<Link
									underline="hover"
									href={require('../../../../../assets/files/POSESION_SEGURA_VC_03.pdf')}
									target="_blank"
								>
									posesión segura.
								</Link>
							</li>
						</ul>
						<Grid container columns={12} spacing={2}>
							<Grid item xs={12} md>
								<b>PROPIEDAD PRIVADA</b>
								<ol className={classes.viewMoreList}>
									<li>Testimonio de escritura pública.</li>
									<li>Otros títulos de propiedad.</li>
									<li>
										Certificados o constancias de derechos
										fiduciarios.
									</li>
									<li>Contrato poder irrevocable.</li>
									<li>Contrato privado de compraventa.</li>
									<li>
										Acuerdo, convenio o Contrato de Cesión
										de Derechos.
									</li>
									<li>Contrato de donación.</li>
									<li>Adjudicación de herencia o legado.</li>
									<li>Información Ad perpetuam.</li>
									<li>
										Resolución de Inmatriculación Judicial.
									</li>
									<li>
										Resolución de Inmatriculación
										Administrativa.
									</li>
									<li>Sentencias Judiciales.</li>
								</ol>
							</Grid>
							<Grid item xs={12} md>
								<b>PROPIEDAD SUJETA AL RÉGIMEN AGRARIO</b>
								<ol className={classes.viewMoreList}>
									<li>
										Título, certificado agrario o de
										derechos parcelanos o constancia de acta
										de asamblea sancionada por la
										Procuraduría Agraria e inscrita en el
										Registro Agrario Nacional.
									</li>
									<li>
										Título, certificado o constancia de
										adquisición de dominio pleno del
										Registro Nacional
									</li>
									<li>Cesión de derechos agrarios</li>
									<li>
										Acta de Asamblea General y/o Constancia
										del Comisariado Ejidal o Comunal que
										otorga la posesión.
									</li>
									<li>
										Constancia del proceso de titulación por
										instituciones facultadas para
										regularización de la tenencia de la
										tierra.
									</li>
									<li>
										Sentencia o resolución judicial en
										materia agraria.
									</li>
								</ol>
							</Grid>
							<Grid item xs={12} md>
								<b>
									PROPIEDAD DE DEPENDENCIAS Y ENTIDADES
									PÚBLICAS
								</b>
								<ol className={classes.viewMoreList}>
									<li>
										Acta, constancias o contrato de entrega
										o recepción del lote, terreno o vivienda
										por parte de las dependencias o
										entidades públicas
									</li>
								</ol>
							</Grid>
						</Grid>
						<div className={classes.columnContent}>
							<strong>
								1. Para vivienda urbana, será suficiente que
								presente uno de los siguientes
							</strong>
							<ul className={classes.viewMoreList}>
								<li>Recibo de Luz</li>
								<li>Recibo de Agua</li>
								<li>Recibo de Gas</li>
								<li>
									Recibo Predial (en caso de que el pago se
									realizó de forma anual y la fecha de emisión
									sea mayor ados meses, deberá presentar algún
									otro del listado)
								</li>
								<li>Recibo de Teléfono Fijo</li>
								<li>Recibo de Internet</li>
								<li>
									Recibo de Telefonía Móvil /Celular
									(domiciliado)
								</li>
								<li>Recibo de TV por Cable</li>
								<li>
									Estado de Movimientos, Estado de Cuenta
									Bancario o Contrato de Apertura de Cuenta
									Bancaria
								</li>
								<li>
									Constancia de Domicilio expedida por el
									Instituto Nacional Electoral (INE)
								</li>
							</ul>
							<strong>
								2. Para la vivienda rural, será suficiente que
								presente cualquiera de los siguientes documentos
							</strong>
							<ul className={classes.viewMoreList}>
								<li>
									Constancia Vecinal expedida por la autoridad
									Municipal, o
								</li>
								<li>Cartilla de Identidad Postal.</li>
							</ul>
						</div>
						<strong>Toma en cuenta que:</strong>
						<ul className={classes.viewMoreList}>
							<li>
								El comprobante de domicilio de la vivienda a la
								que se le harán las mejoras debe pertenecer a la
								misma entidad donde se tramite el crédito.
							</li>
							<li>
								El comprobante de domicilio deberá ser de la
								vivienda en la que se llevarán a cabo las
								mejoras, puede estar a tu nombre, al de tu
								cónyuge, o bien, al de un familiar (padres,
								hijos, hermanos y abuelos) es necesario que
								presentes acta de nacimiento o de matrimonio
								para validar la relación familiar.
							</li>
						</ul>
						<p>
							<b>
								En los casos en que no seas el dueño del terreno
								o la vivienda en donde deseas hacer la mejora,
								ampliación o construcción, deberás declarar lo
								siguiente:
							</b>
						</p>
						<ul className={classes.viewMoreList}>
							<li>
								Que el propietario o poseedor originario te
								autoriza a hacer uso de su propiedad como
								vivienda permanente.
							</li>
							<li>
								En caso de tratarse de coherederos o
								copropietarios que habiten y/o tengan derecho al
								inmueble, también deberás declarar que están de
								acuerdo en que construyas, realices una mejora o
								una ampliación en el inmueble del que son
								poseedores o propietarios.
							</li>
							<li>
								Finalmente deberás asegurar, bajo protesta de
								decir verdad, que conoces los riesgos de
								realizar una obra en un terreno que es propiedad
								de otra persona (se enumerarán extensamente los
								riesgos asociados), que con pleno conocimiento
								de los mismos solicitas el crédito, que
								proporcionas la información fidedigna respecto
								de la propiedad originaria y la posesión que
								ostenta, que aceptas las reglas y manifiestas
								que no existe conflicto en la tenencia ni riesgo
								de pérdida de la posesión.
							</li>
						</ul>
						Las declaraciones se incluirán en el contrato de crédito
						individual.
					</div>
				</>
			),
		},
		{
			paperType: 'simple',
			paperIcon: expediente,
			title: 'Selecciona un Asesor Técnico',
			description:
				'En el Portal Infonavit para que visite y diseñe tu proyecto',
		},
		solicitaTuCredito,
		{
			paperType: 'viewMore',
			paperIcon: seleccion,
			title: 'Responsabilidades del asesor técnico',
			description:
				'Conocer cual es la función del Asesor Técnico y lo que debes tener en cuenta.',
			viewMoreButton: 'Consulta la documentación',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<ul className={classes.viewMoreList}>
							<li>
								Conocer la línea de financiamiento y su forma de
								operar para explicarlo y asesorar al
								derechohabiente incluso en la integración del
								expediente.
							</li>
							<li>
								Visitar al derechohabiente para identificar las
								necesidades, deseos y posibilidades.
							</li>
							<li>
								Asegurarse que el terreno o la vivienda no esté
								en zona de riesgo y que tenga servicios, en caso
								de que tengas una vivienda, que sea segura
								estructuralmente.
							</li>
							<li>
								Si tienes algún problema que ponga en riesgo tu
								vida que no se pueda solucionar con el crédito,
								éste no procederá.
							</li>
							<li>
								Permitir la participación de la familia en todo
								lo que sea posible, pero logrando explicar lo
								que no se puede negociar como es la seguridad
								estructural y habitabilidad (ventilación, luz
								natural etc.).
							</li>
							<li>
								Presentar una propuesta que satisfaga lo
								acordado y que cumpla con las condiciones de
								seguridad estructural y habitabilidad.
							</li>
							<li>
								Presentar el proyecto y presupuesto, el que
								formará parte del contrato y deberán cumplir, el
								Asesor Técnico le debe dar seguimiento a sus
								avances hasta alcanzar la terminación de obra.
							</li>
							<li>Deberá entregar una responsiva estructural.</li>
							<li>
								El Derechohabiente y el Asesor Técnico son
								corresponsables de la ejecución de la obra,
								firman un contrato de prestación de servicio.
							</li>
						</ul>
						<strong>¿Cuáles son mis responsabilidades?</strong>
						<ul className={classes.viewMoreList}>
							<li>
								Deberás administrar el dinero que recibas de
								acuerdo con el proyecto y presupuesto acordado
								con Asesor Técnico.
							</li>
							<li>
								Podrás comprar los materiales y contratar al
								personal, pero deberás cuidar que se apegue a tu
								presupuesto.
							</li>
							<li>
								La mano de obra que contrates deberá seguir el
								proyecto acordado con el Asesor Técnico, para
								cumplir con la calidad y seguridad de la
								vivienda.
							</li>
							<li>
								Deberás cumplir con los tiempos pactados en el
								proyecto y presupuesto para terminar la obra en
								tiempo y forma.
							</li>
							<li>
								Deberás recibir al verificador en tu vivienda y
								cumplir con la fecha y hora acordada para la
								cita.
							</li>
							<li>
								Junto con el Asesor Técnico eres responsable de
								la calidad de la ejecución de la obra y del
								cumplimiento de los tiempos acordados en el
								contrato.
							</li>
						</ul>
						<strong>Importante:</strong>
						<ul className={classes.viewMoreList}>
							<li>
								Deberás contar con un teléfono celular
								inteligente para que puedas darle seguimiento a
								tu obra.
							</li>
							<li>
								Ninguna persona o empresa puede ofrecerte dinero
								en efectivo a cambio de tu crédito o el saldo de
								la Subcuenta de Vivienda, esta actividad está
								prohibida e infringe la normatividad del
								Infonavit. Reporta de manera anónima a:{' '}
								<Link
									href="mailto:prevencionConstruYo@infonavit.org.mx"
									target="_blank"
								>
									prevencionConstruYo@infonavit.org.mx
								</Link>
							</li>
						</ul>
					</div>
				</>
			),
		},
	];
};
