import API from '..';

class ApiConsultationWorkRelations extends API {
	getRelacion(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/relacion-laboral`,
				method: 'POST',
				data: { nss },
			})
		);
	}
	getPdfRelacion(
		nss: string,
		fullName: string,
		rfc: string,
		curp: string
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/relacion-laboral-pdf`,
				method: 'POST',
				data: { nss, fullName, rfc, curp },
			})
		);
	}
}

export const apiConsultationWorkRelations = new ApiConsultationWorkRelations('');
