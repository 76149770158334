import { Box, Divider } from '@mui/material';
import { creditTypes, mockCreditOptions, mockRequestCredit } from '../../../CreditRequest/MIUC/utils';

interface IProps {
  destino: string;
	creditType: string;
	producto: string;
	title: string;
	customTitle: boolean;
	hideTuSeleccion: boolean;
	showExtraTitle: string | JSX.Element;
}

const BarLocation = ({
  destino,
	creditType,
	producto,
	title,
	customTitle,
	hideTuSeleccion,
	showExtraTitle,
}: IProps) => {

	return (
		<>
			<Box
				display={'flex'}
				flexDirection={'row'}
				flexWrap={'wrap'}
				padding={'20px 0px'}
				fontSize={15}
				justifyContent={'center'}
			>
				{!hideTuSeleccion && (
					<Box
						fontWeight={'bold'}
						marginRight={'10px'}
						display={'flex'}
						alignItems={'center'}
					>
						Tu selección:{' '}
						{creditTypes[producto].title}
					</Box>
				)}
				<Box
					fontWeight={'bold'}
					margin={'0px 20px'}
					display={'flex'}
					alignItems={'center'}
				>
					{mockCreditOptions[creditType].Icono}
					{mockCreditOptions[creditType].title}
				</Box>
				<Box
					fontWeight={'bold'}
					margin={'0px 20px'}
					display={'flex'}
					alignItems={'center'}
				>
					{mockRequestCredit[destino].icon(
						true,
						24
					)}
					<span style={{ marginLeft: 5 }}>
						{customTitle ? title : `Quíero ${title}`}
					</span>
				</Box>
			</Box>
			<Divider />
			{showExtraTitle && showExtraTitle}
		</>
	);
};

export default BarLocation;
