/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { Paper, useMediaQuery, Grid, Divider, Link, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import { Close, Search } from '@mui/icons-material';
import ViewArchive from './ViewArchive';
import { HeaderList } from '../../components/HeaderList';
import { formatDate } from '../../utils/dates';
import ModalLoading from '../../components/ModalLoading';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import CustomAlert from '../../components/CustomAlert';

import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { casesRequestAPI } from '../../api/modules/FollowCases';
import { useStyles } from './styles';

import ConfirmAttachDocument from './ConfirmAttachDocument';
import ConfirmDeleteAttachDocument from './ConfirmDeleteAttachDocument';

const AttachDocuments = ({ setPage, dataCaso, setStatus }: any) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<any>({
		message: '',
		color: '',
		show: false,
	});
	const [documentsRequired, setDocumentsRequired] = useState([]);
	const [indexFile, setIndexFile] = useState(1);
	const [indexFileDelete, setIndexFileDelete] = useState(0);
	const [files, setFiles] = useState({
		file1: {
			name: INTERNAL_MESSAGES.NO_FILE,
			data: null,
			type: '',
			size: 0,
		},
		file2: {
			name: INTERNAL_MESSAGES.NO_FILE,
			data: null,
			type: '',
			size: 0,
		},
		file3: {
			name: INTERNAL_MESSAGES.NO_FILE,
			data: null,
			type: '',
			size: 0,
		},
		file4: {
			name: INTERNAL_MESSAGES.NO_FILE,
			data: null,
			type: '',
			size: 0,
		},
		file5: {
			name: INTERNAL_MESSAGES.NO_FILE,
			data: null,
			type: '',
			size: 0,
		},
		file6: {
			name: INTERNAL_MESSAGES.NO_FILE,
			data: null,
			type: '',
			size: 0,
		},
		file7: {
			name: INTERNAL_MESSAGES.NO_FILE,
			data: null,
			type: '',
			size: 0,
		},
		file8: {
			name: INTERNAL_MESSAGES.NO_FILE,
			data: null,
			type: '',
			size: 0,
		},
		file9: {
			name: INTERNAL_MESSAGES.NO_FILE,
			data: null,
			type: '',
			size: 0,
		},
		file10: {
			name: INTERNAL_MESSAGES.NO_FILE,
			data: null,
			type: '',
			size: 0,
		},
	});
	const [itemActiveModal, setItemActiveModal] = useState({
		data: null,
	});
	const [modalAcceptActive, setModalAcceptActive] = useState(false);
	const [modalDeleteActive, setmodalDeleteActive] = useState('');

	const [sizeTotal, setSizeTotal] = useState(0);

	const handleFile = (e: any) => {
		setAlert({ message: '', color: '', show: false });
		const type = e.target.files[0].type;
		if (/jpg|jpeg|png|tiff|pdf|mpeg|avi|wmv|mp4|mp3/g.test(type)) {
			if (e.target.files[0].size / 1024 ** 2 + sizeTotal < 20) {
				if (indexFileDelete > 0) {
					setFiles({
						...files,
						[e.target.name + indexFileDelete]: {
							name: e.target.files[0].name,
							data: e.target.files[0],
							type: e.target.files[0].type,
							size: e.target.files[0].size / 1024 ** 2,
						},
					});
					try {
						Object.entries(files).forEach(([key, value]) => {
							if (value.size === 0) {
								let idxKey = parseInt(key.substr(-1)) !== 0 ? parseInt(key.substr(-1)) : 10
								if (indexFileDelete !== idxKey) {
									setIndexFile(idxKey);
									setIndexFileDelete(idxKey);
									// eslint-disable-next-line no-throw-literal
									throw '';
								}
							}
						});
					}
					catch (e) {

					}
				} else {
					setFiles({
						...files,
						[e.target.name + indexFile]: {
							name: e.target.files[0].name,
							data: e.target.files[0],
							type: e.target.files[0].type,
							size: e.target.files[0].size / 1024 ** 2,
						},
					});
					let idx = indexFile + 1;
					setIndexFile(idx);
				}
			} else {
				setAlert({
					show: true,
					message:
						INTERNAL_MESSAGES.FILE_SIZE,
					color: 'error',
				});
			}
		} else {
			setAlert({
				show: true,
				message:
					INTERNAL_MESSAGES.INCORRECT_FORMAT,
				color: 'error',
			});
		}
	};
	const handleFileInline = (e: any, index: any) => {
		setAlert({ message: '', color: '', show: false });
		const type = e.target.files[0].type;
		if (/jpg|jpeg|png|tiff|pdf|mpeg|avi|wmv|mp4|mp3/g.test(type)) {
			if (e.target.files[0].size / 1024 ** 2 + sizeTotal < 20) {
				setFiles({
					...files,
					[e.target.name + indexFile]: {
						name: e.target.files[0].name,
						data: e.target.files[0],
						type: e.target.files[0].type,
						size: e.target.files[0].size / 1024 ** 2,
					},
				});
				let idx = indexFile + 1;
				setIndexFile(idx);
			} else {
				setAlert({
					show: true,
					message:
						INTERNAL_MESSAGES.FILE_SIZE,
					color: 'error',
				});
			}
		} else {
			setAlert({
				show: true,
				message:
				INTERNAL_MESSAGES.INCORRECT_FORMAT,
				color: 'error',
			});
		}
	};
	const handleDeleteFile = (index: any) => {
		if (index.substr(-1) === '0') {
			setIndexFileDelete(10)
		} else {
			setIndexFileDelete(parseInt(index.substr(-1)))
		}
		setAlert({ message: '', color: '', show: false });
		setFiles({
			...files,
			[index]: {
				name: INTERNAL_MESSAGES.NO_FILE,
				data: null,
				size: 0,
			},
		});
		setmodalDeleteActive('');
	};
	const blobToBase64 = (blob: any) => {
		if (blob) {
			return new Promise((resolve, reject) => {
				const reader: any = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = () => {
					resolve(reader.result.split(',')[1]);
				};
			});
		} else {
			return '';
		}
	};
	const sendDataCaso = async () => {
		setModalAcceptActive(false);
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			const file64_1: any = await blobToBase64(
				files.file1.data !== null ? files.file1.data : ''
			);
			const file64_2: any = await blobToBase64(
				files.file2.data !== null ? files.file2.data : ''
			);
			const file64_3: any = await blobToBase64(
				files.file3.data !== null ? files.file3.data : ''
			);
			const file64_4: any = await blobToBase64(
				files.file4.data !== null ? files.file4.data : ''
			);
			const file64_5: any = await blobToBase64(
				files.file5.data !== null ? files.file5.data : ''
			);
			const file64_6: any = await blobToBase64(
				files.file6.data !== null ? files.file6.data : ''
			);
			const file64_7: any = await blobToBase64(
				files.file7.data !== null ? files.file7.data : ''
			);
			const file64_8: any = await blobToBase64(
				files.file8.data !== null ? files.file8.data : ''
			);
			const file64_9: any = await blobToBase64(
				files.file9.data !== null ? files.file9.data : ''
			);
			const file64_10: any = await blobToBase64(
				files.file10.data !== null ? files.file10.data : ''
			);

			const CASO_BASE64 = await encrypt(
				dataCaso.caso.toString() || '',
				key
			);
			const MARCA_BASE64 = await encrypt(
				dataCaso.marcaOrigen.toString() || '',
				key
			);

			let dataToSend = [];

			if (file64_1) {
				const NOMBRE1_BASE64 = await encrypt(
					files.file1.name.toString() || '',
					key
				);
				const FILE1_BASE64 = await encrypt(
					file64_1.toString() || '',
					key
				);
				let type = {
					caso: CASO_BASE64,
					marca: MARCA_BASE64,
					nombreArchivo: NOMBRE1_BASE64,
					archivo: FILE1_BASE64,
				};
				dataToSend.push(type);
			}
			if (file64_2) {
				const NOMBRE2_BASE64 = await encrypt(
					files.file2.name.toString() || '',
					key
				);
				const FILE2_BASE64 = await encrypt(
					file64_2.toString() || '',
					key
				);
				let type = {
					caso: CASO_BASE64,
					marca: MARCA_BASE64,
					nombreArchivo: NOMBRE2_BASE64,
					archivo: FILE2_BASE64,
				};
				dataToSend.push(type);
			}
			if (file64_3) {
				const NOMBRE3_BASE64 = await encrypt(
					files.file3.name.toString() || '',
					key
				);
				const FILE3_BASE64 = await encrypt(
					file64_3.toString() || '',
					key
				);
				let type = {
					caso: CASO_BASE64,
					marca: MARCA_BASE64,
					nombreArchivo: NOMBRE3_BASE64,
					archivo: FILE3_BASE64,
				};
				dataToSend.push(type);
			}
			if (file64_4) {
				const NOMBRE4_BASE64 = await encrypt(
					files.file4.name.toString() || '',
					key
				);
				const FILE4_BASE64 = await encrypt(
					file64_4.toString() || '',
					key
				);
				let type = {
					caso: CASO_BASE64,
					marca: MARCA_BASE64,
					nombreArchivo: NOMBRE4_BASE64,
					archivo: FILE4_BASE64,
				};
				dataToSend.push(type);
			}
			if (file64_5) {
				const NOMBRE5_BASE64 = await encrypt(
					files.file5.name.toString() || '',
					key
				);
				const FILE5_BASE64 = await encrypt(
					file64_5.toString() || '',
					key
				);
				let type = {
					caso: CASO_BASE64,
					marca: MARCA_BASE64,
					nombreArchivo: NOMBRE5_BASE64,
					archivo: FILE5_BASE64,
				};
				dataToSend.push(type);
			}
			if (file64_6) {
				const NOMBRE6_BASE64 = await encrypt(
					files.file6.name.toString() || '',
					key
				);
				const FILE6_BASE64 = await encrypt(
					file64_6.toString() || '',
					key
				);
				let type = {
					caso: CASO_BASE64,
					marca: MARCA_BASE64,
					nombreArchivo: NOMBRE6_BASE64,
					archivo: FILE6_BASE64,
				};
				dataToSend.push(type);
			}
			if (file64_7) {
				const NOMBRE7_BASE64 = await encrypt(
					files.file7.name.toString() || '',
					key
				);
				const FILE7_BASE64 = await encrypt(
					file64_7.toString() || '',
					key
				);
				let type = {
					caso: CASO_BASE64,
					marca: MARCA_BASE64,
					nombreArchivo: NOMBRE7_BASE64,
					archivo: FILE7_BASE64,
				};
				dataToSend.push(type);
			}
			if (file64_8) {
				const NOMBRE8_BASE64 = await encrypt(
					files.file8.name.toString() || '',
					key
				);
				const FILE8_BASE64 = await encrypt(
					file64_8.toString() || '',
					key
				);
				let type = {
					caso: CASO_BASE64,
					marca: MARCA_BASE64,
					nombreArchivo: NOMBRE8_BASE64,
					archivo: FILE8_BASE64,
				};
				dataToSend.push(type);
			}
			if (file64_9) {
				const NOMBRE9_BASE64 = await encrypt(
					files.file9.name.toString() || '',
					key
				);
				const FILE9_BASE64 = await encrypt(
					file64_9.toString() || '',
					key
				);
				let type = {
					caso: CASO_BASE64,
					marca: MARCA_BASE64,
					nombreArchivo: NOMBRE9_BASE64,
					archivo: FILE9_BASE64,
				};
				dataToSend.push(type);
			}
			if (file64_10) {
				const NOMBRE10_BASE64 = await encrypt(
					files.file10.name.toString() || '',
					key
				);
				const FILE10_BASE64 = await encrypt(
					file64_10.toString() || '',
					key
				);
				let type = {
					caso: CASO_BASE64,
					marca: MARCA_BASE64,
					nombreArchivo: NOMBRE10_BASE64,
					archivo: FILE10_BASE64,
				};
				dataToSend.push(type);
			}

			casesRequestAPI.attachDocument
				.post<any, any>('', dataToSend)
				.then((res: any) => {
					if (res.result) {
						if (
							res.result.status &&
							res.result.status ===
							'Falta configurar tabla ZCRM_CSTAT para cambio de estatus.'
						) {
							setAlert({
								show: true,
								message: res.description
									? res.description
									: 'Por el momento el servicio no está disponible. Intenta más tarde.',
								color: 'error',
							});
						} else {
							setStatus(res.result);
							setPage(2);
						}
					} else {
						setAlert({
							show: true,
							message:
								'Por el momento el servicio no está disponible. Intenta más tarde.',
							color: 'error',
						});
					}
				})
				.catch((err) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						color: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				color: 'error',
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		const getList = async () => {
			try {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const CASO_BASE64 = await encrypt(
					dataCaso.caso.toString() || '',
					key
				);
				const OPERACION_BASE64 = await encrypt(
					dataCaso.claseOperacion.toString() || '',
					key
				);
				const ORIGEN_BASE64 = await encrypt(
					dataCaso.marcaOrigen.toString() || '',
					key
				);
				casesRequestAPI.listDocuments
					.post<
						{
							caso: string;
							operacion: string;
							origen: string;
						},
						any
					>('', {
						caso: CASO_BASE64,
						operacion: OPERACION_BASE64,
						origen: ORIGEN_BASE64,
					})
					.then((res: any) => {
						if (
							res.result.listaDocumentos &&
							res.result.listaDocumentos.length > 0
						) {
							setDocumentsRequired(res.result.listaDocumentos);
						} else {
							/*setAlert({
								show: true,
								message: res.result.description
									? res.result.description
									: 'Por el momento el servicio no está disponible. Intenta más tarde.',
								color: 'error',
							});*/
						}
					})
					.catch((err: any) => {
						setAlert({
							show: true,
							message:
								err && err.description
									? err.description
									: 'Por el momento el servicio no está disponible. Intenta más tarde.',
							color: 'error',
						});
					});
			} catch (error: any) {
				setAlert({
					show: true,
					message:
						error && error.description
							? error.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
					color: 'error',
				});
			}
		};
		getList();
	}, []);

	useEffect(() => {
		let size = 0;
		Object.entries(files).forEach(([key, value]) => {
			if (value.size !== 99) {
				size = size + value.size;
			}
		});
		setSizeTotal(size);
	}, [files]);

	return (
		<div style={{ fontSize: 15 }}>
			<ModalLoading loading={loading} />
			<ConfirmAttachDocument
				open={modalAcceptActive}
				setOpen={setModalAcceptActive}
				sendDataCaso={sendDataCaso}
			/>
			<ConfirmDeleteAttachDocument
				open={modalDeleteActive}
				setOpen={setmodalDeleteActive}
				sendDeleteCaso={handleDeleteFile}
			/>
			<ViewArchive
				show={openModal}
				setShow={setOpenModal}
				file={itemActiveModal?.data}
			/>
			<HeaderList
				title="Sigue tu caso y adjunta documentos"
				date={formatDate('PP')}
			/>
			<Paper style={{ padding: '20px 40px' }}>
				<Grid container columns={12}>
					<Grid item xs={12} md={12} order={1}>
						<p
							style={{
								color: '#293990',
								fontWeight: 500,
								margin: 0,
							}}
						>
							Adjuntar documentos
						</p>
					</Grid>
					<Grid item xs={12} md={12} order={2}>
						<Divider style={{ margin: '10px 0px' }} />
					</Grid>
					<Grid item xs={12} md={8} order={3}>
						<span style={{ fontWeight: 500 }}>Número de caso</span>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						order={4}
						style={{ textAlign: 'right' }}
					>
						{dataCaso.caso}
					</Grid>
					<Grid item xs={12} md={12} order={5}>
						<Divider style={{ margin: '10px 0px' }} />
					</Grid>
					<Grid item xs={12} md={12} order={6}>
						<span style={{ fontWeight: 500 }}>
							Los documentos necesarios que debes adjuntar para la
							atención de tu caso son:
						</span>
						{documentsRequired.map((dato: any, index) => (
							<p key={index} className={classes.text}>
								{dato.descripcion}
							</p>
						))}
					</Grid>
					<Grid item xs={12} md={12} order={9}>
						<Divider style={{ margin: '10px 0px 20px' }} />
					</Grid>
					<Grid
						item
						xs={12}
						md={12}
						style={{ textAlign: 'center' }}
						order={10}
					>
						<p
							style={{
								maxWidth: 800,
								margin: 'auto',
								marginBottom: 10,
							}}
						>
							Te sugerimos que antes de que envíes los documentos
							que se te piden, verifiques que estén correctos. Si
							necesitas anexar otro documento o quitarlo, solo da
							clic en Adjuntar archivo o Eliminar.
						</p>
						<p
							style={{
								maxWidth: 800,
								margin: 'auto',
								marginBottom: 10,
							}}
						>
							<b>
								Los formatos permitidos son jpg, png, tiff, pdf,
								mpeg, avi, wmv, mp4 o mp3 con un tamaño máximo
								de 20 MB por todos los documentos adjuntos.
							</b>
							<br />
							Solo podrás subir hasta 10 archivos y el tamaño
							total de los mismos no deberá ser mayor a 20 MB.
						</p>
					</Grid>

					<Grid
						item
						xs={12}
						md={12}
						style={{
							justifyContent: 'center',
							marginBottom: 20,
							display: 'flex',
							flexDirection: matches
								? 'column-reverse'
								: 'inherit',
						}}
						order={11}
					>
						<div
							style={{
								display: 'flex',
								width: matches ? 'auto' : 250,
								margin: '10px 10px',
							}}
						>
							<label
								className={
									(files.file1.size > 0 &&
										files.file2.size > 0 &&
										files.file3.size > 0 &&
										files.file4.size > 0 &&
										files.file5.size > 0 &&
										files.file6.size > 0 &&
										files.file7.size > 0 &&
										files.file8.size > 0 &&
										files.file9.size > 0 &&
										files.file10.size > 0) || sizeTotal > 20
										? classes.buttonLogInSolidDisabled
										: classes.buttonLogInSolid
								}
							>
								<input
									type="file"
									name="file"
									onChange={handleFile}
									style={{ display: 'none' }}
									disabled={
										(files.file1.size > 0 &&
											files.file2.size > 0 &&
											files.file3.size > 0 &&
											files.file4.size > 0 &&
											files.file5.size > 0 &&
											files.file6.size > 0 &&
											files.file7.size > 0 &&
											files.file8.size > 0 &&
											files.file9.size > 0 &&
											files.file10.size > 0) || sizeTotal > 20
											? true
											: false
									}
								/>
								Adjuntar
							</label>
						</div>
					</Grid>

					<Grid item xs={12} md={12} order={11}>
						<Box>
							{files.file1.size > 0 && (
								<Grid
									container
									columns={12}
									style={{
										paddingLeft: 20,
										paddingRight: 20,
										marginBottom: 20,
									}}
								>
									<Grid item xs={3} sm={5} md={5} lg={4}>
										<span style={{ fontWeight: 500 }}>
											Nombre del archivo
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={2}>
										<span style={{ fontWeight: 500 }}>
											Tamaño
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={3}>
										<span style={{ fontWeight: 500 }}>
											Vista previa
										</span>
									</Grid>
									<Grid item xs={3} sm={3} md={3} lg={3}>
										<span style={{ fontWeight: 500 }}>
											Eliminar
										</span>
									</Grid>
								</Grid>
							)}

							{files.file1.size > 0 && (
								<Grid
									container
									columns={12}
									className={classes.table}
								>
									<Grid item xs={3} sm={5} md={5} lg={4}>
										<span className={classes.textName}>
											{files.file1.name}
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={2}>
										<span>
											{files.file1.size !== 99
												? files.file1.size.toFixed(2)
												: 0}{' '}
											MB
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={3}>
										<span>
											{files.file1.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													onClick={() => {
														setItemActiveModal(
															files.file1
														);
														setOpenModal(true);
													}}
													style={{
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Ver archivo
												</Link>
											)}
										</span>
									</Grid>
									<Grid item xs={3} sm={3} md={3} lg={3}>
										<span>
											{files.file1.size === 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													<label>
														<input
															type="file"
															name="file"
															onChange={(e) => {
																handleFileInline(
																	e,
																	1
																);
															}}
															style={{
																display: 'none',
															}}
														/>
														Adjuntar archivo
													</label>
												</Link>
											)}
											{files.file1.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
													onClick={() =>
														setmodalDeleteActive('file1')
													}
												>
													<Close
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Eliminar archivo
												</Link>
											)}
										</span>
									</Grid>
								</Grid>
							)}

							{files.file2.size > 0 && (
								<Grid
									container
									columns={12}
									className={classes.table}
								>
									<Grid item xs={3} sm={5} md={5} lg={4}>
										<span className={classes.textName}>
											{files.file2.name}
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={2}>
										<span>
											{files.file2.size !== 99
												? files.file2.size.toFixed(2)
												: 0}{' '}
											MB
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={3}>
										<span>
											{files.file2.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													onClick={() => {
														setItemActiveModal(
															files.file2
														);
														setOpenModal(true);
													}}
													style={{
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Ver archivo
												</Link>
											)}
										</span>
									</Grid>
									<Grid item xs={3} sm={3} md={3} lg={3}>
										<span>
											{files.file2.size === 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													<label>
														<input
															type="file"
															name="file"
															onChange={(e) => {
																handleFileInline(
																	e,
																	2
																);
															}}
															style={{
																display: 'none',
															}}
														/>
														Adjuntar archivo
													</label>
												</Link>
											)}
											{files.file2.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
													onClick={() =>
														setmodalDeleteActive('file2')
													}
												>
													<Close
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Eliminar archivo
												</Link>
											)}
										</span>
									</Grid>
								</Grid>
							)}

							{files.file3.size > 0 && (
								<Grid
									container
									columns={12}
									className={classes.table}
								>
									<Grid item xs={3} sm={5} md={5} lg={4}>
										<span className={classes.textName}>
											{files.file3.name}
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={2}>
										<span>
											{files.file3.size !== 99
												? files.file3.size.toFixed(2)
												: 0}{' '}
											MB
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={3}>
										<span>
											{files.file3.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													onClick={() => {
														setItemActiveModal(
															files.file3
														);
														setOpenModal(true);
													}}
													style={{
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Ver archivo
												</Link>
											)}
										</span>
									</Grid>
									<Grid item xs={3} sm={3} md={3} lg={3}>
										<span>
											{files.file3.size === 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													<label>
														<input
															type="file"
															name="file"
															onChange={(e) => {
																handleFileInline(
																	e,
																	3
																);
															}}
															style={{
																display: 'none',
															}}
														/>
														Adjuntar archivo
													</label>
												</Link>
											)}
											{files.file3.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
													onClick={() =>
														setmodalDeleteActive('file3')
													}
												>
													<Close
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Eliminar archivo
												</Link>
											)}
										</span>
									</Grid>
								</Grid>
							)}

							{files.file4.size > 0 && (
								<Grid
									container
									columns={12}
									className={classes.table}
								>
									<Grid item xs={3} sm={5} md={5} lg={4}>
										<span className={classes.textName}>
											{files.file4.name}
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={2}>
										<span>
											{files.file4.size !== 99
												? files.file4.size.toFixed(2)
												: 0}{' '}
											MB
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={3}>
										<span>
											{files.file4.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													onClick={() => {
														setItemActiveModal(
															files.file4
														);
														setOpenModal(true);
													}}
													style={{
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Ver archivo
												</Link>
											)}
										</span>
									</Grid>
									<Grid item xs={3} sm={3} md={3} lg={3}>
										<span>
											{files.file4.size === 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													<label>
														<input
															type="file"
															name="file"
															onChange={(e) => {
																handleFileInline(
																	e,
																	4
																);
															}}
															style={{
																display: 'none',
															}}
														/>
														Adjuntar archivo
													</label>
												</Link>
											)}
											{files.file4.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
													onClick={() =>
														setmodalDeleteActive('file4')
													}
												>
													<Close
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Eliminar archivo
												</Link>
											)}
										</span>
									</Grid>
								</Grid>
							)}

							{files.file5.size > 0 && (
								<Grid
									container
									columns={12}
									className={classes.table}
								>
									<Grid item xs={3} sm={5} md={5} lg={4}>
										<span className={classes.textName}>
											{files.file5.name}
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={2}>
										<span>
											{files.file5.size !== 99
												? files.file5.size.toFixed(2)
												: 0}{' '}
											MB
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={3}>
										<span>
											{files.file5.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													onClick={() => {
														setItemActiveModal(
															files.file5
														);
														setOpenModal(true);
													}}
													style={{
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Ver archivo
												</Link>
											)}
										</span>
									</Grid>
									<Grid item xs={3} sm={3} md={3} lg={3}>
										<span>
											{files.file5.size === 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													<label>
														<input
															type="file"
															name="file"
															onChange={(e) => {
																handleFileInline(
																	e,
																	5
																);
															}}
															style={{
																display: 'none',
															}}
														/>
														Adjuntar archivo
													</label>
												</Link>
											)}
											{files.file5.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
													onClick={() =>
														setmodalDeleteActive('file5')
													}
												>
													<Close
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Eliminar archivo
												</Link>
											)}
										</span>
									</Grid>
								</Grid>
							)}

							{files.file6.size > 0 && (
								<Grid
									container
									columns={12}
									className={classes.table}
								>
									<Grid item xs={3} sm={5} md={5} lg={4}>
										<span className={classes.textName}>
											{files.file6.name}
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={2}>
										<span>
											{files.file6.size !== 99
												? files.file6.size.toFixed(2)
												: 0}{' '}
											MB
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={3}>
										<span>
											{files.file6.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													onClick={() => {
														setItemActiveModal(
															files.file6
														);
														setOpenModal(true);
													}}
													style={{
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Ver archivo
												</Link>
											)}
										</span>
									</Grid>
									<Grid item xs={3} sm={3} md={3} lg={3}>
										<span>
											{files.file6.size === 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													<label>
														<input
															type="file"
															name="file"
															onChange={(e) => {
																handleFileInline(
																	e,
																	6
																);
															}}
															style={{
																display: 'none',
															}}
														/>
														Adjuntar archivo
													</label>
												</Link>
											)}
											{files.file6.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
													onClick={() =>
														setmodalDeleteActive('file6')
													}
												>
													<Close
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Eliminar archivo
												</Link>
											)}
										</span>
									</Grid>
								</Grid>
							)}

							{files.file7.size > 0 && (
								<Grid
									container
									columns={12}
									className={classes.table}
								>
									<Grid item xs={3} sm={5} md={5} lg={4}>
										<span className={classes.textName}>
											{files.file7.name}
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={2}>
										<span>
											{files.file7.size !== 99
												? files.file7.size.toFixed(2)
												: 0}{' '}
											MB
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={3}>
										<span>
											{files.file7.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													onClick={() => {
														setItemActiveModal(
															files.file7
														);
														setOpenModal(true);
													}}
													style={{
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Ver archivo
												</Link>
											)}
										</span>
									</Grid>
									<Grid item xs={3} sm={3} md={3} lg={3}>
										<span>
											{files.file7.size === 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													<label>
														<input
															type="file"
															name="file"
															onChange={(e) => {
																handleFileInline(
																	e,
																	7
																);
															}}
															style={{
																display: 'none',
															}}
														/>
														Adjuntar archivo
													</label>
												</Link>
											)}
											{files.file7.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
													onClick={() => setmodalDeleteActive('file7')
													}
												>
													<Close
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Eliminar archivo
												</Link>
											)}
										</span>
									</Grid>
								</Grid>
							)}

							{files.file8.size > 0 && (
								<Grid
									container
									columns={12}
									className={classes.table}
								>
									<Grid item xs={3} sm={5} md={5} lg={4}>
										<span className={classes.textName}>
											{files.file8.name}
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={2}>
										<span>
											{files.file8.size !== 99
												? files.file8.size.toFixed(2)
												: 0}{' '}
											MB
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={3}>
										<span>
											{files.file8.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													onClick={() => {
														setItemActiveModal(
															files.file8
														);
														setOpenModal(true);
													}}
													style={{
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Ver archivo
												</Link>
											)}
										</span>
									</Grid>
									<Grid item xs={3} sm={3} md={3} lg={3}>
										<span>
											{files.file8.size === 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													<label>
														<input
															type="file"
															name="file"
															onChange={(e) => {
																handleFileInline(
																	e,
																	8
																);
															}}
															style={{
																display: 'none',
															}}
														/>
														Adjuntar archivo
													</label>
												</Link>
											)}
											{files.file8.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
													onClick={() =>
														setmodalDeleteActive('file8')
													}
												>
													<Close
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Eliminar archivo
												</Link>
											)}
										</span>
									</Grid>
								</Grid>
							)}

							{files.file9.size > 0 && (
								<Grid
									container
									columns={12}
									className={classes.table}
								>
									<Grid item xs={3} sm={5} md={5} lg={4}>
										<span className={classes.textName}>
											{files.file9.name}
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={2}>
										<span>
											{files.file9.size !== 99
												? files.file9.size.toFixed(2)
												: 0}{' '}
											MB
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={3}>
										<span>
											{files.file9.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													onClick={() => {
														setItemActiveModal(
															files.file9
														);
														setOpenModal(true);
													}}
													style={{
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Ver archivo
												</Link>
											)}
										</span>
									</Grid>
									<Grid item xs={3} sm={3} md={3} lg={3}>
										<span>
											{files.file9.size === 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													<label>
														<input
															type="file"
															name="file"
															onChange={(e) => {
																handleFileInline(
																	e,
																	9
																);
															}}
															style={{
																display: 'none',
															}}
														/>
														Adjuntar archivo
													</label>
												</Link>
											)}
											{files.file9.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
													onClick={() =>
														setmodalDeleteActive('file9')
													}
												>
													<Close
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Eliminar archivo
												</Link>
											)}
										</span>
									</Grid>
								</Grid>
							)}

							{files.file10.size > 0 && (
								<Grid
									container
									columns={12}
									className={classes.table}
								>
									<Grid item xs={3} sm={5} md={5} lg={4}>
										<span className={classes.textName}>
											{files.file10.name}
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={2}>
										<span>
											{files.file10.size !== 99
												? files.file10.size.toFixed(2)
												: 0}{' '}
											MB
										</span>
									</Grid>
									<Grid item xs={3} sm={2} md={2} lg={3}>
										<span>
											{files.file10.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													onClick={() => {
														setItemActiveModal(
															files.file10
														);
														setOpenModal(true);
													}}
													style={{
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Ver archivo
												</Link>
											)}
										</span>
									</Grid>
									<Grid item xs={3} sm={3} md={3} lg={3}>
										<span>
											{files.file10.size === 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<Search
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													<label>
														<input
															type="file"
															name="file"
															onChange={(e) => {
																handleFileInline(
																	e,
																	10
																);
															}}
															style={{
																display: 'none',
															}}
														/>
														Adjuntar archivo
													</label>
												</Link>
											)}
											{files.file10.size !== 99 && (
												<Link
													href="#ver"
													underline="none"
													style={{
														width: '100%',
														display: 'inline-flex',
														alignItems: 'center',
														cursor: 'pointer',
													}}
													onClick={() =>
														setmodalDeleteActive('file10')
													}
												>
													<Close
														style={{
															fontSize: 20,
															marginRight: 2,
														}}
													/>
													Eliminar archivo
												</Link>
											)}
										</span>
									</Grid>
								</Grid>
							)}
						</Box>
					</Grid>

					<Grid item xs={12} md={12} order={11}>
						<CustomAlert
							message={alert.message}
							severity={'error'}
							show={alert.show}
						/>
						<CustomAlert
							message={
								'El tamaño total de los archivos no debe de ser mayor a 20 MB.'
							}
							severity={'error'}
							show={sizeTotal > 20}
						/>
					</Grid>

					<Grid item xs={12} md={12} order={11}>
						<div
							style={{
								display: 'flex',
								width: matches ? 'auto' : 600,
								margin: '10px auto',
								gap: 40,
							}}
						>
							<CustomButton
								label="Regresar"
								variant="outlined"
								onClick={() => setPage(0)}
							/>

							<CustomButton
								disabled={
									alert.show ||
									((files.file1.data ||
										files.file2.data ||
										files.file3.data ||
										files.file4.data ||
										files.file5.data ||
										files.file6.data ||
										files.file7.data ||
										files.file8.data ||
										files.file9.data ||
										files.file10.data) &&
										sizeTotal < 20
										? false
										: true)
								}
								label="Enviar"
								variant="solid"
								onClick={() => setModalAcceptActive(true)}
							/>
						</div>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export default AttachDocuments;
