import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((Theme: Theme) =>
	createStyles({
		content: {
			textAlign: 'center',
		},
		contentPadding: {
			padding: '5px 25px',
		},
		inputs: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			width: '90%',
		},
		bCalcular: {
			display: 'flex',
			alignItems: 'end',
			margin: '5px',
			paddingBottom: '5px',
		},
		bConsultar: {
			display: 'flex',
			alignItems: 'center',
			margin: '5px',
			paddingBottom: '10px',
		},
		boxAportaciones: {
			display: 'flex',
			flexDirection: 'row',
			backgroundColor: '#F2F2F2',
			textAlign: 'left',
			width: '100%',
			margin: 0,
			justifyContent: 'center',
			left: 0,
		},
		divAportaciones: {
			width: '80%',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		infoStyle: {
			fontSize: '20px !important',
			marginBottom: -2,
			marginLeft: 4,
		},
		gridCreditosRequeridos: {
			display: 'flex',
			flexDirection: 'row',
			width: '75%',
			justifyContent: 'space-evenly',
			marginBottom: '20px',
		},
		divSeleccionCreditos: {
			display: 'flex',
			flexDirection: 'column',
		},
		radioGrid: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'start',
		},
		optionsGrid: {
			minWidth: '100%',
		},
		radioButtons: {
			width: '55%', 
			textAlign: 'start',
		},
		centeredContent: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			minWidth: '100%',
		},
		helpPanel: {
			color: 'rgb(221, 5, 40)',
			marginLeft: 8,
		},
		inputLabel: {
			display: 'flex',
			justifyContent: 'start',
		},
	})
);
