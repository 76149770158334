/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JSEncrypt from 'jsencrypt';
import { Paper, FormControlLabel, Checkbox, Link } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { RootState } from '../../store';

import Layout from '../../components/Layout';
import ModalLoading from '../../components/ModalLoading';
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import CustomAlert from '../../components/CustomAlert';

import { IAlert } from '../../interfaces/alert';
import { useStyles } from './styles';
import logosocio from '../../assets/img/socioInfonavit/logo.png';
import categoriasSocio from '../../assets/img/socioInfonavit/categorias.png';
import startIcon from '../../assets/img/socioInfonavit/start.png';
import notificationIcon from '../../assets/img/socioInfonavit/notification.png';
import percentageIcon from '../../assets/img/socioInfonavit/percentage.png';
import imgPersona from '../../assets/img/socioInfonavit/person.png';
import cartIcon from '../../assets/img/socioInfonavit/cart.png';
import imgPersona2 from '../../assets/img/socioInfonavit/person2.png';

import { socioInfonavitAPI } from '../../api/modules/InfonavitPartner';
import { actions } from '../../store/modules/session';
import { actions as creditActions } from '../../store/modules/credit';
import { actions as MiucActions } from '../../store/modules/miuc';

const InfonavitPartnetView = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [userRegister, setUserRegister] = useState(false);
	const [acceptConditions, setAcceptConditions] = useState(false);


	useEffect(() => {
		validateRegisterSocio();
	}, []);

	const validateRegisterSocio = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				email: user?.email || '',
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			const Response = await socioInfonavitAPI.IsRegistered(dataSendEncrypt);
			if (Response?.result?.code === '0000') {
				const value = Response.result.data?.registered || false;
				setUserRegister(value);
			} else {
				setAlert({
					show: true,
					message: Response.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleEncrypt = async () => {
		setLoading(true);
		const email = user?.email || '';
		const rfc = user?.rfc || '';
		const name = user?.nombres || '';
		const lastName = user?.apPaterno || '';
		const phone = user?.telefonoCelular || '';

		const params = `${email}:${rfc}:${name}:${lastName}:${phone}`;
		const publicKey = process.env.REACT_APP_KEY_SOCIO || '';

		const encrypt = new JSEncrypt();
		encrypt.setPublicKey(publicKey);
		const base64_credentials = encrypt.encrypt(params);

		setLoading(false);
		window.open(`${process.env.REACT_APP_URL_REDIRECT_SOCIO}?${base64_credentials}`, '_blank');

		dispatch(actions.signOut());
		dispatch(creditActions.clearCredit());
		dispatch(MiucActions.clearData());
		setTimeout(() => {
			user?.isImpersonalized ? window.location.href = 'https://micuenta.infonavit.org.mx/' : navigate('/')
		}, 100)
	};

	return (
		<>
			<Layout>
				<>
					<HeaderList title="Conoce Socio Infonavit"></HeaderList>
					<Paper className={classes.container}>

						<div className={classes.divLogo}>
							<img src={logosocio} alt="logo-socio"></img>
						</div>

						{!userRegister && (
							<>
								<div className={classes.divText}>
									<h3 className={classes.spanTitle}>¡Bienvenido!</h3>
									<h3 className={classes.spanTitle}>Registrate hoy y accede a todos los beneficios</h3>
									<p className={classes.txtInicial}>
										Es un programa gratuito con descuentos, ofertas y beneficios exclusivos
										en tus marcas preferidas para distintos productos y servicios.
									</p>
								</div>

								<div className={classes.divLogos}>
									<img src={categoriasSocio} alt="Categorias socio infonavit" />
								</div>

								<div className={classes.divFlexSmall}>
									<div className={classes.itemFlex50}>
										<div>
											<div className={classes.divFlexSmall}>
												<div>
													<img className={classes.iconsImg} src={startIcon} alt="img-persona"></img>
												</div>
												<div>
													<p className={classes.textInfo}>
														No te pierdas de las diferentes marcas
														que te ofrecen una experiencia única
														con nosotros.
													</p>
												</div>
											</div>
											<div className={classes.divFlexSmall}>
												<div>
													<img className={classes.iconsImg} src={notificationIcon} alt="img-persona"></img>
												</div>
												<div>
													<p className={classes.textInfo}>
														Cada mes nuevas marcas y beneficios.
													</p>
												</div>
											</div>
											<div className={classes.divFlexSmall}>
												<div>
													<img className={classes.iconsImg} src={percentageIcon} alt="img-persona"></img>
												</div>
												<div>
													<p className={classes.textInfo}>
														Recibe correos semanales con los beneficios
														más atractivos.
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className={classes.itemFlex50}>
										<img src={imgPersona} alt="img-persona"></img>
									</div>
								</div>

								<div className={classes.divText}>
									<p className={classes.txtInicial}>
										Autoriza tu acceso en la sección de términos y condiciones en tu perfil de
										Mi Cuenta Infonavit y déjanos encaminarte hacia todas las oportunidades y
										beneficios que Socio Infonavit tiene para ofrecerte.
									</p>
								</div>

								<div className={classes.footer}>
									<FormControlLabel
										control={
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon />}
												checkedIcon={<CheckBoxIcon />}
												onChange={(e) => setAcceptConditions(e.target.checked)}
												name="checkModal"
											/>
										}
										label={
											<div className={classes.textFooter}>
												Aceptar{' '}
												<Link rel="noopener" href="https://portalmx.infonavit.org.mx/wps/wcm/connect/eca2a7b7-eeb3-4fec-a4d0-cd49f219503c/terminos_condiciones_socio_Infonavit.pdf?MOD=AJPERES" className={classes.link} target="_blank">
													términos y condiciones
												</Link>
											</div>
										}
										value="checkAviso"
									/>
									<CustomButton
										data-testid="buttonContinuar"
										label="Ingresa a Socio Infonavit"
										onClick={() => handleEncrypt()}
										variant="solid"
										styles={{
											width: 282,
											height: 50,
											margin: '10px auto 20px'
										}}
										disabled={acceptConditions ? false : true}
									/>
									<p className={classes.textFooter}>
										Al ingresar a Socio Infonavit por seguridad de tu cuenta la
										sesión se cerrará en automático, puedes volver a ingresar a
										Mi Cuenta Infonavit en cualquier momento.
									</p>
								</div>
							</>
						)}

						{userRegister && (
							<>
								<div className={classes.divText}>
									<h3 className={classes.spanTitle}>
										Acércate a un mundo de posibilidades y diviértete ahorrando
									</h3>
								</div>

								<div className={classes.divFlex}>
									<div>
										<img className={classes.iconsImgLarge} src={cartIcon} alt="img-persona"></img>
									</div>
									<div>
										<p className={classes.textInfoCustom}>
											No te olvides que <b>Socio Infonavit</b> cuenta
											con una variedad de promociones en servicios de
											salud, viajes, cursos en línea, entretenimiento
											y muchas cosas más.
										</p>
									</div>
								</div>

								<div className={classes.divLogos}>
									<img src={categoriasSocio} alt="Categorias socio infonavit" />
								</div>

								<div className={classes.divText}>
									<h3 className={classes.spanTitle}>
										Ingresa a Socio Infonavit y descubre un abanico
										de beneficios especialmente ideados para ti
									</h3>
								</div>

								<div className={classes.divFlex}>
									<div>
										<img className={classes.iconsImgLarge} src={imgPersona2} alt="img-persona"></img>
									</div>
									<div>
										<p className={classes.textInfoCustom}>
											No te pierdas la oportunidad de seguir siendo
											parte de esta experiencia. Atrévete a disfrutar
											a lo grande y aprovecha de nuestros beneficios.
										</p>
									</div>
								</div>

								<div className={classes.footer}>
									<CustomButton
										data-testid="buttonContinuar"
										label="Ingresa a Socio Infonavit"
										onClick={() => handleEncrypt()}
										variant="solid"
										styles={{
											width: 282,
											height: 50,
											margin: '10px auto 20px'
										}}
									/>
									<p className={classes.textFooter}>
										Al ingresar a Socio Infonavit por seguridad de tu cuenta la
										sesión se cerrará en automático, puedes volver a ingresar a
										Mi Cuenta Infonavit en cualquier momento.
									</p>
								</div>
							</>
						)}

						<CustomAlert
							message={alert.message}
							severity={alert.severity}
							show={alert.show}
							onClose={() => {
								setAlert({
									show: false,
									message: '',
									severity: alert.severity,
								});
							}}
						/>

					</Paper>
					<ModalLoading loading={loading} />
				</>
			</Layout>
		</>
	);
};

export default InfonavitPartnetView;
