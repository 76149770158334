import styles from './styles/styles.module.css';

const NeedToKnow = () => {
	return (
		<>
			<p className={styles.fuenteIzquierda}>
				Si cuentas con un monto de Aportaciones Voluntarias en tu Afore
				y quieres incrementar tu capacidad de compra para adquirir
				vivienda, al momento de solicitar tu crédito deberás indicar en
				tu solicitud el monto que deseas transferir de tus Aportaciones
				Voluntarias a tu Subcuenta de Vivienda para que éstas sean
				aplicadas.
				<br />
				<br />
				Para solicitar tu crédito es requisito que cuentes con tu
				expediente biométrico actualizado en tu Afore. En este servicio
				podrás conocer su estatus, en caso de que no esté completo,
				acude a tu Afore.
				<br />
				<br />
				A partir del momento en que autorices la transferencia de las
				Aportaciones Voluntarias y hasta que se formalice tu crédito
				estas no generarán rendimientos.
				<br />
				<br />
				En caso de una cancelación del trámite de crédito, si los
				recursos de las Aportaciones Voluntarias ya se hubieran recibido
				en el Infonavit, estos serán restituidos a la Afore como
				inversión de corto plazo en tu cuenta individual. Mientras los
				recursos permanezcan en el Infonavit, no generarán rendimientos.
				<br />
				<br />
				A partir de la formalización de la solicitud para la
				transferencia de Ahorro Voluntario, de la Afore al Infonavit, no
				podrás realizar un cambio de Afore; esto hasta que concluya tu
				trámite de transferencia de recursos al Infonavit.
				<br />
				<br />
				Si antes de la transferencia de los recursos al Infonavit se
				presentara una minusvalía o disminución del valor del saldo del
				Ahorro Voluntario, tu Afore podrá entregar un saldo menor al que
				hayas señalado en la Solicitud de Inscripción de Crédito. En
				este caso, el importe de la diferencia se aplicará como un cargo
				a capital al crédito ejercido. El importe recibido en el
				Infonavit lo podrás consultar en el Resumen de Movimientos de mi
				Ahorro, de la sección Mi Ahorro de Mi Cuenta Infonavit. Si
				existiera un cargo a capital, el monto se verá reflejado en el
				estado de cuenta de tu crédito.
				<br />
				<br />
				Al solicitar la transferencia de Ahorro Voluntario, aceptas que
				tu Afore realice la retención de los impuestos correspondientes.
				Podrás acudir a tu Afore para que te entregue el CFDI
				(Comprobante Fiscal Digital por Internet).
				<br />
				<br />
				Para obtener información sobre el saldo que visualices como
				monto disponible, minusvalías o la retención de impuestos al
				Ahorro Voluntario, acude a tu Afore.
			</p>
		</>
	);
};

export default NeedToKnow;
