import React from 'react';
import BaseModal from '../../components/Modal';

const ConfirmCodyPayment = ({ open, setOpen, onConfirm }: any) => {
	return (
		<BaseModal
			showDialogTitle
			open={open}
			width="sm"
			onClose={() => setOpen(!open)}
			onConfirm={onConfirm}
			cancelButton
		>
			<>
				<div style={{ textAlign: 'center', paddingTop: 10 }}>
					<p>
						Te redireccionaremos a la plataforma de pagos para que hagas tu
						transacción. En caso de que se interrumpa la comunicación, inicia
						nuevamente la sesión en Mi Cuenta Infonavit
					</p>
				</div>
			</>
		</BaseModal>
	);
};

export default ConfirmCodyPayment;
