import { useStyles } from './styles/play-button.styles';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';

export interface IPlayButton {
	onClick: () => void;
	isPlaying?: boolean;
	isComplete?: boolean;
}

export default function PlayButton({
	onClick,
	isPlaying,
	isComplete,
}: IPlayButton) {
	const styles = useStyles({ isComplete, isPlaying });
	return (
		<button onClick={onClick} className={styles.buttonContainer}>
			{isPlaying ? (
				<>
					<label>En reproducción</label>
					<QueueMusicIcon
						style={{
							fontSize: 20,
							marginLeft: 10,
						}}
					/>
				</>
			) : isComplete ? (
				<>
					<label>Ver otra vez</label>
					<PlayCircleIcon
						style={{
							fontSize: 20,
							marginLeft: 10,
						}}
					/>
				</>
			) : (
				<>
					<label>Ver tema</label>
					<PlayCircleIcon
						style={{
							fontSize: 20,
							marginLeft: 10,
						}}
					/>
				</>
			)}
		</button>
	);
}
