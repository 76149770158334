import API from '../';

export const PaymentRequestAPI = {
	consultaCaso: new API('/v1/aclaracion-pagos/consulta-caso'),
	consultaSici: new API('/v1/aclaracion-pagos/consulta-sici'),
	consultaAls: new API('/v1/aclaracion-pagos/consulta-als'),
	catalogoEmisoras: new API('/v1/aclaracion-pagos/catalogo-emisoras'),
	crearCaso: new API('/v1/aclaracion-pagos/crea-caso'),
	crearFondoAhorro: new API('/v1/aclaracion-pagos/crea-fondo-ahorro'),
	adjuntaArchivos: new API('/v1/aclaracion-pagos/adjunta-archivos'),
};
