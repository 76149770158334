import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// PRINCIPAL CONTAINER
		header: {
			display: 'flex',
			alignItems: 'center',
			background: grey[100],
			justifyContent: 'center',
			padding: 20,
			textAlign: 'center',
		},
		content: {
			overflow: 'auto',
			textAlign: 'center',
			padding: '5px 25px',
			height: 'auto',
			'&>.credit-request-title': {
				color: theme.palette.info.main,
			},
			'&>.credit-request-actions-container': {
				display: 'flex',
				justifyContent: 'space-evenly',
				'&>button': {
					width: 200,
				},
				[theme.breakpoints.down('sm')]: {
					flexDirection: 'column-reverse',
					'&>button': {
						width: '100%',
					},
				},
			},
			'&>.address-form': {
				display: 'flex',
				flexWrap: 'wrap',
				marginBottom: 15,
				'&>*': {
					width: 180,
					margin: '0 10px !important',
					[theme.breakpoints.down('md')]: {
						width: '45%',
						flexDirection: 'column',
					},
					[theme.breakpoints.down('sm')]: {
						width: '100%',
						flexDirection: 'column',
					},
				},
			},
			'&>.credit-calculate-cost-form': {
				display: 'flex',
				alignItems: 'flex-end',
				'&>div': {
					width: '100%',
				},
				'&>button': {
					width: 200,
				},
				[theme.breakpoints.down('sm')]: {
					width: '100%',
					flexDirection: 'column',
					'&>button': {
						width: '100%',
					},
				},
			},
			'&>.credit-online-view': {
				textAlign: 'left',
				'&>.online-detail': {
					display: 'flex',
					alignItems: 'center',
					'&>svg': {
						marginRight: 5,
						fontSize: 'smaller',
					},
				},
			},
			'&>.form-container': {
				display: 'flex',
				flexWrap: 'wrap',
				marginBottom: 10,
				'&>*': {
					flex: '1 1 300px',
					paddingRight: 10,
					[theme.breakpoints.down('sm')]: {
						flex: '1 1 100%',
						paddingRight: 0,
					},
				},
			},
			'&>button': {
				width: 200,
				[theme.breakpoints.down('sm')]: {
					width: '100%',
				},
			},
		},
		// COMPONENTS
		// FLOW CARD
		flowCard: {
			padding: '1px 20px 10px',
			textAlign: 'center',
			'&>.stepper-container': {
				margin: '10px 0px',
				overflowY: 'auto',
				//maxHeight: 350,
			},
		},
		// CURRENT SAVING
		currentSaving: {
			display: 'flex',
			padding: '5px 10px',
			alignItems: 'center',
			background: 'white',
			border: `1px solid ${theme.palette.info.main}`,
			borderRadius: 5,
			'&>.savings': {
				textAlign: 'center',
			},
		},
		// SELECTION CARD
		selectionCard: {
			maxWidth: 200,
			minWidth: 170,
			height: '100%',
			flexDirection: 'column',
			padding: '10px !important',
			'&>.selection-card-title': {
				margin: 0,
				color: theme.palette.info.main,
				display: 'flex',
				alignItems: 'center',
				placeContent: 'center',
			},
		},
		// STEPPER
		stepperConnector: {
			'&>.MuiStepConnector-line': {
				borderLeftStyle: 'dashed',
			},
		},
		fontSize: {
			[theme.breakpoints.down('sm')]: {
				fontSize: 12,
			},
		},
        fontSize2: {
            textAlign: 'justify',

			[theme.breakpoints.down('sm')]: {
				fontSize: 12,
			},
		},
		accordion: {
			'&>.MuiAccordionSummary-root': {
				flexDirection: 'row-reverse',
				'&>.MuiAccordionSummary-expandIconWrapper': {
					marginRight: 10,
				},
			},
			'&>.MuiCollapse-root': {
				paddingLeft: 34,
			},
		},

		// VIWES
		// SELECTION CREDIT
		selectionCreditCardsContainer: {
			display: 'flex',
			justifyContent: 'center',
			flexWrap: 'wrap',
			'&>*': {
				margin: 10,
				[theme.breakpoints.down('xs')]: {
					width: '100%',
					textAlign: '-webkit-center',
				},
			},
		},
		containerRowTable: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			height: 'auto',
		},
		containerRowTableElement: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: 'auto',
		},
		subContainerRowTable: {
			display: 'flex',
			alignItems: 'center',
			textAlign: 'left',
			justifyContent: 'space-between',
			width: '100%',
			height: 54,
			borderColor: '#EDEEEF',
			borderWidth: 0.5,
			borderStyle: 'solid',
			paddingLeft: 10,
			paddingRight: 10,
		},
		subContainerRowTableHeader: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
			minHeight: 130,
			borderColor: '#EDEEEF',
			borderWidth: 0.5,
			borderStyle: 'solid',
		},
		subContainerRowTableInfoNoBorder: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: 60,
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column-reverse',
				height: 'auto',
			},
		},
		subContainerRowTableInfo: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			minHeight: 54,
			borderColor: '#EDEEEF',
			borderWidth: 0.5,
			borderStyle: 'solid',
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				padding: '10px 0px',
			},
		},
		subContainerRowTableInfoHeaderIconsSinBor: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: 54,
		},
		subContainerRowTableInfoHeaderIcons: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: 54,
			borderTopColor: '#EDEEEF',
			borderTopWidth: 0.5,
			borderTopStyle: 'solid',
		},
		subContainerRowTableInfoHeader: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%',
			minHeight: 130,
			borderColor: '#EDEEEF',
			borderWidth: 0.5,
			borderStyle: 'solid',
			position: 'relative',
		},
		subContainerRowTableInfo2: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flex: 1,
			position: 'relative',
		},
		subContainerRowTableInfo2Border: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flex: 1,
			//height: 50,
			borderRight: '#e4e4e4',
			borderRightWidth: 1,
			borderRightStyle: 'solid',
		},
		containerTitleIcons: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: theme.palette.info.main,
		},
		parrafo: {
			fontFamily: 'Geomanist, Regular',
			fontSize: '14px',
			lineHeight: '1.5',
		},
		listItem: {
			[theme.breakpoints.up('xs')]: {
				display: 'flex',
				flexDirection: 'row',
				fontSize: 15,
				margin: 0,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'row',
				fontSize: 16,
				margin: 0,
			},
		},
		listItemChild: {
			fontSize: 14,
			margin: 0,
			marginLeft: 15,
		},
		divButtons: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
		},
		buttons: {
			width: '200px',
			margin: '15px',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				margin: '8px',
			},
			[theme.breakpoints.down('md')]: {
				width: '100%',
				margin: '8px',
			},
		},
		textRed: {
			fontWeight: 500,
			color: theme.palette.primary.dark,
		},
		dialogCheck: {
			textAlign: 'center',
			marginTop: theme.spacing(3),
			marginBottom: '-20px',
			lineHeight: '8px',
			borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
			paddingBottom: '20px',
		},
		formControlLabel: {
			marginLeft: '50px',
		},
		dialogButtonGroup: {
			justifyContent: 'space-between',
			display: 'flex',
			width: '100%',
			[theme.breakpoints.down('md')]: {
				display: 'flex',
				flexWrap: 'wrap',
				flexDirection: 'column-reverse',
			},
		},
		dialogButtonRight: {
			width: '50%',
			[theme.breakpoints.down('md')]: {
				margin: 0,
				width: '100%',
			},
		},
		//Questionaire
		QuestionHeader: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: '15px 30px',
			boxShadow: '0 4px 8px rgba(0, 0, 0, 0.08)',
		},
		QuestionBody: {
			paddingBottom: '40px',
			height: '70vh',
			overflowY: 'auto',
			scrollbarWidth: 'none',
		},
		QuestionBodyMessage: {
			padding: '5px 80px',
			background: 'rgb(238, 244, 250)',
			display: 'flex',
		},
		QuestionBodyItem: {
			textAling: 'left',
			padding: '30px 70px 0px',
		},
		QuestionFooter: {
			padding: '0px 50px 40px',
			marginTop: '20px',
		},
		cardClass: {
			width: '350px',
			padding: '20px 30px',
			textAlign: 'center',
			'&>h4': {
				margin: '0px 0px 10px 0px',
			},
			'&>p': {
				margin: '2px 0px',
			},
		},
		textDisclaimer: {
			textAlign: 'left',
			padding: '10px 20px',
			backgroundColor: 'rgb(250, 226, 149)',
			borderRadius: '6px',
			'&>p': {
				margin: 0,
				color: 'rgb(227, 87, 0)',
				fontSize: '0.875rem'
			}
		}
	})
);

export const flatParagraph = {
	fontWeight: 400,
};

export const centeredItems = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

export const outlineBorder = {
	border: `${grey[500]} solid 1.2px`,
	borderRadius: 3,
};
