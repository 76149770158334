/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../../../utils/validators';
//Assets
import imgChecaContacto from '../../../assets/img/ChecaDatosConto.png';
import imgInsSocio from '../../../assets/img/inscribete-socio.png';
import imgAportaciones from '../../../assets/img/aportaciones-banner.png';
import imgConsulta from '../../../assets/img/consulta-banner.png';
import imgCoin from '../../../assets/img/coin.jpg';

import { useProfile } from '../../MIUC/hooks/useProfile';

const SaverNewAffiliate = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { saldoSARTotal } = useProfile({ initProcess: true });

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={12} lg={4}>
				<Paper className={classes.card}>
					<div className={classes.titleCard}>
						<h3>¡Bienvenido al Infonavit!</h3>
					</div>
					<div className={classes.textCard}>
						<p>
							Para estar en contacto contigo, te recomendamos mantener
							actualizados tus datos.
						</p>
						<p>
							Verifica que tu RFC, CURP y nombre estén correctos
							tanto en el Infonavit como en tu Afore, el IMSS y el SAT.
						</p>
						<p>
							Revisa constantemente las aportaciones que tu patrón
							realiza a tu Subcuenta de Vivienda. Si encuentras
							una inconsistencia, pregunta acerca del proceso de
							Indicios de evasión.
						</p>
					</div>
				</Paper>
			</Grid>
			<Grid item sm={12} md={12} lg={8}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<Paper
							className={classes.mainContainerCardMoney}
							style={{
								height: '100%',
								justifyContent: 'center',
							}}
						>
							<div>
								<label className={classes.titleCardMoney}>
									Tienes ahorrado en tu Subcuenta de Vivienda:
								</label>
								<div className={classes.containerCardMoney}>
									<img
										src={imgCoin}
										alt="icono pesos"
										className={classes.iconPesos}
									/>
									<label className={classes.labelSSV}>
										{formatMoney(Number(saldoSARTotal) || 0)} MXN
									</label>
								</div>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-perfil/actualizar-datos-contacto')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgChecaContacto}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>

				<Grid container spacing={2} mt={1}>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-ahorro/cuanto-ahorro-tengo')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgConsulta}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-perfil/socio-infonavit')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgInsSocio}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>

				<Grid container spacing={2} mt={1}>
					<Grid item lg={12}>
						<div
							onClick={() =>
								navigate(
									'/mi-ahorro/haz-aportaciones-extraordinarias'
								)
							}
						>
							<img
								src={imgAportaciones}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SaverNewAffiliate;
