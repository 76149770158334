import { useTheme } from '@mui/material/styles';
import { Box, Divider, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomButton from '../../../../components/Button';
import CustomAlert from '../../../../components/CustomAlert';
import SelectCustom from '../../../../components/SelectCustom';
import { MOTIVOS_CANCELACION } from '../../../../config/miucConstants';
import { IAlert } from '../../../../interfaces/alert';

interface IModalCancelWFI {
	setSelectOption: (value: string | number) => void;
	onContinue: () => void;
	selectOption: string | number;
	hasError: IAlert;
}

const QuestionModal = ({
	hasError,
	selectOption,
	setSelectOption,
	onContinue,
}: IModalCancelWFI) => {
	const [options, setOptions] = useState<{ label: string; value: string }[]>(
		[]
	);
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	useEffect(() => {
		const optionsFlag = [];
		for (const property in MOTIVOS_CANCELACION) {
			optionsFlag.push({
				value: property,
				label: MOTIVOS_CANCELACION[property],
			});
		}
		setOptions(optionsFlag);
	}, []);

	return (
		<>
			<Box
				maxWidth={500}
				marginLeft="auto"
				marginRight="auto"
				marginTop={4}
				marginBottom={7}
			>
				<SelectCustom
					label="Seleccione la razón"
					name="razon"
					options={options}
					placeholder="Selecciona"
					value={selectOption}
					onChange={(e) => setSelectOption(e.target.value)}
				/>
			</Box>
			<Divider />

			<CustomAlert
				show={hasError.show}
				message={hasError.message}
				severity={hasError.severity}
			/>
			<Box
				display="flex"
				flexDirection={matches ? 'column-reverse' : 'row'}
				marginTop={3}
			>
				<Box
					width={matches ? '100%' : 250}
					display="flex"
					margin="10px auto"
				>
					<CustomButton
						disabled={selectOption ? false : true}
						label="Continuar"
						onClick={() => onContinue()}
						variant="solid"
					/>
				</Box>
			</Box>
		</>
	);
};

export default QuestionModal;
