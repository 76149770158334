import { makeStyles } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';

import fondoHome from '../../assets/img/fondo-home.jpg';
import { borderRadius, margin } from '@mui/system';
import { Button, Select, Radio } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
	titleNoticeContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	titleNotice: {
		marginTop: 0,
		fontSize: 22,
		color: '#293990',
	},
	subTitleNotice: {
		fontSize: 12,
		color: '#333333',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			textAlign: 'right',
			marginTop: 15,
		},
	},
	checkHeader: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 7,
	},
	checkHeaderMargin: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 7,
		marginLeft: 20,
		'& > img': {
			width: 13,
		},
	},
	checkHeaderImg: {
		width: 17,
		marginRight: 5,
	},
	checkHeaderText: {
		color: '#333',
		fontSize: 13,
		padding: 0,
		margin: 0,
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		paddingBlock: 50,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
	},
	containerSectionTitle: {
		fontSize: 14,
		marginBlock: 20,
	},

	containerMin: {
		maxWidth: 900,
		margin: '0 auto',
	},
	containerDatePicker: {
		display: 'flex',
		alignItems: 'center',
		// paddingBlock: 20,
		marginBottom: 40,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
		'& > div': {
			flex: 1,
		},
		'& > *': {
			width: '100%',
		},
	},
	containerInfo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	containerDatePickerBox: {
		paddingInline: 15,
	},
	containerSectionTitleFooter: {
		fontSize: 16,
		color: '#293990',
	},
	labelContainerInput: {
		margin: 0,
		color: '#7E8094',
		fontSize: 14,
	},
	labelRequired: {
		color: theme.palette.primary.main,
	},
	titleModalAlert: {
		textAlign: 'center',
	},
	titleModalAlertPDF: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 22,
	},
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
	margin: 0,
	padding: 0,
	marginRight: 5,
	marginTop: 3,
}));
