import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import ButtonCustom from '../../../components/Button';

interface Props {
	setPage: (arg: number) => void;
	AceptPaperless: () => void;
}

const ThanksServices = ({ setPage, AceptPaperless }: Props) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	const PaperlestFunc = async () => {
		await AceptPaperless();
		setPage(0);
	};

	return (
		<div style={{ textAlign: 'center', fontSize: 15 }}>
			<h2 style={{ color: '#293990', textAlign: 'center' }}>
				Gracias por utilizar los servicios electrónicos del Infonavit
			</h2>
			<p>
				Confirma si quieres seguir recibiendo por correo electrónico el aviso de
				que tu estado de cuenta está disponible en el portal.
			</p>
			<p style={{ fontWeight: 500 }}>¡Rápido y confiable!</p>
			<p>Beneficios de recibirlo por correo electrónico:</p>
			<p>
				1. Contribuyes a la conservación de los árboles
				<br />
				2. Es seguro, el aviso llega a tu correo personal
				<br />
				3. Puedes consultar y descargar tu estado de cuenta desde cualquier lugar
				<br />
				4. Está disponible las 24 horas del día, los 365 días del año
				<br />
				5. No tiene costo
			</p>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: matches ? 'column-reverse' : 'inherit',
				}}
			>
				<div
					style={{ width: matches ? '100%' : 250, display: 'flex', margin: 10 }}
				>
					<ButtonCustom
						label="No acepto"
						variant="outlined"
						onClick={() => setPage(2)}
					/>
				</div>
				<div
					style={{ width: matches ? '100%' : 250, display: 'flex', margin: 10 }}
				>
					<ButtonCustom
						label="Acepto"
						variant="solid"
						onClick={() => PaperlestFunc()}
					/>
				</div>
			</div>
			<p
				style={{
					maxWidth: 900,
					margin: 'auto',
					marginTop: 20,
					marginBottom: 20,
				}}
			>
				En caso de no elegir alguna de las dos, enviar menaje:
				<br />
				Al no recibir respuesta se da por hecho que estás de acuerdo en recibir
				por correo electrónico el aviso de que tu estado de cuenta está disponible
				en el portal.
			</p>
		</div>
	);
};

export default ThanksServices;
