import { useEffect, useState } from "react";
import { useController } from "../../../hooks/useController";
import { formatMoney2 } from "../../../../../utils/validators";
import { isNumberFloat } from "../../../../../utils/expressions";

export interface IProps {
    show: boolean,
    setShow: (show: boolean) => void,
    gastos?: number,
    setGastos: (gastos: number) => void,
}

export const useModalTitulacion = ({ setGastos, setShow, gastos }: IProps) => {
    const { dataProcessController } = useController();
    const [valorTitulacion, setValorTitulacion] = useState<string>('');
    const [inputError, setInputError] = useState('');
    const [gastosTitulacion, setGastosTitulacion] = useState(0);
    const isCalculated = gastosTitulacion !== 0;

    useEffect(() => {
        setGastosTitulacion(gastos || 0);
    },[gastos])

    const handleConfirm = () => {
        if(isCalculated) {
            setGastos(gastosTitulacion);
            setValorTitulacion('');
            handleClose();
        }
    }

    const handleCalculate = () => {
        const valorCantidad = Number(valorTitulacion
            .toString()
            .replaceAll(',', '')
            .replaceAll(' ', ''));

        if(valorCantidad === 0) return setInputError('Debe ingresar una cantidad mayor a 0.');

        const valorUMA = Number(dataProcessController.titular.valorUMA);
        const valorMinimo = Math.floor(59 * valorUMA * 10) / 10;
        const uma20 = Math.floor(20 * valorUMA * 10) / 10;
        const valorCalculado = (valorCantidad * (1.025 / 100)) + uma20;

        const gastosFinales = (valorCalculado < valorMinimo)
        ? valorMinimo
        : valorCalculado;

        setGastosTitulacion(gastosFinales);
        setInputError('');
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        if(isNumberFloat(value)) setValorTitulacion(value);
    }

    const handleClose = () => {
        setShow(false);
    }

    const onBlur = () => {
        if (valorTitulacion) {
            if (valorTitulacion === '.') {
                setValorTitulacion('');
            } else {
                setValorTitulacion(
                    formatMoney2(
                        Number(valorTitulacion)
                    ).substring(1)
                );
            }
        }
    }

    const onFocus = () => {
        if (valorTitulacion) {
            setValorTitulacion(
                valorTitulacion
                    .toString()
                    .replaceAll(',', '')
                    .replaceAll(' ', '')
            );
        }
    }
    return {
        onBlur,
        onFocus,
        handleClose,
        handleConfirm,
        handleChange,
        handleCalculate,
        isCalculated,
        inputError,
        valorTitulacion,
        gastosTitulacion,
    }
}