import { Box, Link } from "@mui/material";

// ASSETS
import arrowRight from '../../../../assets/img/arrow-right.png';
import ViewMoreButton from "../ViewMoreButton";
import theme from "../../../../config/theme";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";

interface IBanner {
  icon: string;
  text?: string | React.ReactElement;
  link?: string
  size: 'small' | 'medium';
  buttonText?: string;
  onClick?: () => void;
}

export const Banner = ({icon, text, size, link, buttonText, onClick}: IBanner) => {

  const classes = useStyles();
  const container = size === 'small'
  ? classes.menuBanner
  : text 
  ? classes.bannerWithText
  : classes.banner;

  const navigate = useNavigate();

  const onDefaultClick = () => {
    if(link){
      navigate(link);
    }
  }

  return (
      <div className={container} onClick={ () => {
        onClick && onClick();
        onDefaultClick();
      }}>
        <Box className="icon">
          <img src={icon} style={{ width: 70 }} />
        </Box>
        {
          text
          ? (
            <Box className="text"> 
              {text}
            </Box>
          )
          : ''
        }
        <ViewMoreButton link={link} text={buttonText}></ViewMoreButton>
      </div>
  )
}