import API from '../';

// interface IDataFileRequired {
// 	nss: string;
// 	fechaInicio: string;
// 	fechaFin: string;
// 	tipoDocumento: string;
// 	email: string;
// }

interface IDataFileRequired {
	docTodo: number;
	docViv97Todo: number;
	subCatA: number;
	subCatB: number;
	subCatC: number;
	docViv92: number;
	docFa: number;
	tipoFormato: number;
}

class SummarySavingsAPI extends API {
	// verifyFile(nss: string): Promise<any> {
	verifyFile(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/verify-file`,
				method: 'POST',
				// data: { nss },
			})
		);
	}
	fileRequired(data: IDataFileRequired): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/file-required`,
				method: 'POST',
				data,
			})
		);
	}
	// validStatus(nss: string): Promise<any> {
	validStatus(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/valid-status`,
				method: 'POST',
				// data: { nss },
			})
		);
	}
	//consula-decreto así lo envía el backend
	consultaDecreto(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/consula-decreto`,
				method: 'POST',
			})
		);
	}

	validMark(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/valid-mark`,
				method: 'POST',
				//data: {},
			})
		);
	}
}
export const summarySavingsAPI = new SummarySavingsAPI('');
