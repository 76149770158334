/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useController } from '../../../hooks/useController';
import { useProductTableMIUC } from '../../../hooks/useProductTableMIUC';
import { IAlert } from '../../../../../interfaces/alert';
import { IProductMonto } from '../../../../../interfaces/creditRequest';
import { apiMIUC } from '../../../../../api/modules/MIUC';
import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { formatMoney2, isEmpty } from '../../../../../utils/validators';
import { formatDate } from '../../../../../utils/dates';
import {
	IFormat,
	formatValues,
	rowsTitleConstruir,
} from '../../../utils/selectionResume.utils';
import { downloadPDF } from '../../../../../utils/downloadPDF';
import { isNumberFloat } from '../../../../../utils/expressions';
import { creditTypes } from '../../../../CreditRequest/MIUC/utils';
import { useProfile } from '../../../hooks/useProfile';
import { useUserInfo } from '../../../hooks/useUserInfo';
import TextInput from '../../../../../components/TextInput';
import { Box, InputAdornment } from '@mui/material';
import { actions } from '../../../../../store/modules/loading';
import { STAGE_ID } from '../../../constants/stageId';
import { useTrazabilidad } from '../../../hooks/useTrazabilidad';
import { CODES_RESPONSE } from '../../../../../config/miucConstants';
import { useMontoPlazoFlexible } from '../../../hooks/useMontoPlazoFlexible';
import {
	IMontosSolicitados,
	PRODUCT_KEYS,
} from '../../../interfaces/montoPlazoFlexible';
import { useCats } from '../../../hooks/useCats';

export const useConstruirTable = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state: RootState) => state.session);

	const {
		returnStep,
		addSelection,
		nextStep,
		getProgressStep,
		dataProcessController,
		saveData,
	} = useController();
	const {
		isSearch,
		getDataProductAmount,
		getDataCalculadora,
		getDataCat,
		setIsSearch,
	} = useProductTableMIUC();
	const { conyuge } = useUserInfo();
	const { createTrazabilidad } = useTrazabilidad();

	// const [loading, setLoading] = useState(false);
	// const [rawProducts, setRawProducts] = useState<IProductMonto[]>([]);
	// const [productsCopy, setProductsCopy] = useState<IProductMonto[]>([]);
	const [productsOriginal, setProductsOriginal] = useState<IProductMonto[]>(
		[]
	);
	const [products, setProducts] = useState<any[]>([]);
	const [productsToCalculate, setProductsToCalculate] = useState<any[]>([]);
	const [productsTable, setProductsTable] = useState<any[]>([]);
	const [selectProduct, setSelectProduct] = useState('');
	const [valorConstruir, setValorConstruir] = useState('');
	const [rowsTitle, setRowsTitle] = useState(rowsTitleConstruir);
	const [alertInput, setAlertInput] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const { active, total } = getProgressStep();
	const disableButton = isEmpty(selectProduct);
	const newStep = {
		title: 'Estas son las opciones de financiamiento que tenemos para ti',
		subtitle:
			'Revisa cada opción, compara entre ellas y elige la que más te convenga',
		active,
		total,
	};

	const {
		getInputMonto,
		loadProductData,
		disableSelectPlazo,
		onChangePlazo,
		getPlazos,
		disableInputMonto,
		disableAll,
		enableAll,
		disableCalculateBtn,
	} = useMontoPlazoFlexible();
	const [plazo, setPlazo] = useState<string>('');
	const [plazos, setPlazos] =
		useState<{ value: string | number; text: string }[]>();
	const [plazoCny, setPlazoCny] = useState<string>('');
	const [plazosCny, setPlazosCny] =
		useState<{ value: string | number; text: string }[]>();
	const { getAllCats } = useCats();

	const getProducts = async (
		isCalculate?: boolean,
		recalculate?: boolean
	) => {
		let productsFlag: IProductMonto[] = [];
		try {
			dispatch(actions.startLoading());
			// setLoading(true);
			setAlert({
				show: false,
				message: '',
				severity: 'error',
			});
			let montosMaximos: IMontosSolicitados = {
				montoTitular: ' ',
				montoConyuge: ' ',
			};
			if (isCalculate) {
				productsToCalculate.forEach((prod) => {
					if (
						Number(prod?.montoCreditoSolicitado) >
						Number(montosMaximos.montoTitular)
					) {
						montosMaximos = {
							...montosMaximos,
							montoTitular: prod.montoCreditoSolicitado,
						};
					}
					if (
						Number(prod?.montoCreditoSolicitadoCny) >
						Number(montosMaximos.montoConyuge)
					) {
						montosMaximos = {
							...montosMaximos,
							montoConyuge: prod.montoCreditoSolicitadoCny,
						};
					}
				});
			}
			const data = getDataProductAmount({
				valorTerreno: valorConstruir || '',
				plazoPart1: isCalculate && disableInputMonto ? plazo : '',
				plazoPart2: isCalculate && disableInputMonto ? plazoCny : '',
				...montosMaximos,
			});

			const ENCODED_DATA = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const response = await apiMIUC.consultarProductosMontos(
				ENCODED_DATA
			);
			if (response.code !== CODES_RESPONSE.CORRECTO) {
				setAlertInput({
					show: true,
					message:
						response.message ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
					severity: 'error',
				});
				dispatch(actions.stopLoading());
				return;
			}
			// console.log(response)
			if (
				[
					STAGE_ID.FRAUDE_DEFUNCION,
					STAGE_ID.DEFUNCION,
					STAGE_ID.NO_AUTENTICA_BC,
					STAGE_ID.ENDEUDAMIENTO,
				].includes(dataProcessController?.titular?.stageId)
			) {
				productsFlag = response?.data?.productos.filter(
					(value) => value.producto.trim() !== 'CS'
					// value.producto.trim() !== 'IT' // POR CAMBIOS QUE PIDIERON
				);
			} else {
				productsFlag = response?.data?.productos.filter(
					(value) => value.producto.trim() !== 'CS'
				);
			}

			const products = productsFlag;
			const newProducts = products.map((product: any) => ({
				...product,
				diffCubrir: product.diffCubrir || 0,
			}));
			const productsCatsAll: IProductMonto[] = await getAllCats(
				newProducts
			);
			let plazoMaximo = '';
			let plazoMaximoCny = '';
			const rawProds = newProducts.map((product: any) => {
				if (Number(product.pzoCre) > Number(plazoMaximo))
					plazoMaximo = product.pzoCre;
				if (Number(product.pzoCreCny) > Number(plazoMaximoCny))
					plazoMaximoCny = product.pzoCreCny;
				return {
					...product,
					ahorro: dataProcessController.titular.saldoSARTotal,
					ahorroCny: dataProcessController.conyuge?.saldoSARTotal,
					totalContarias:
						Number(product.cdcMon) + Number(product.cdcMonCny),
				};
			});

			loadProductData({
				plazos: {
					plazo: plazoMaximo,
					plazoCny: plazoMaximoCny,
				},
				products: rawProds.map((p) => ({
					producto: p.producto,
					monto: p.mmcMon,
					montoConyuge: p?.mmcMonCny || '0',
				})),
			});
			setPlazos(getPlazos(Number(plazoMaximo)));
			setPlazo(plazoMaximo);
			setPlazosCny(getPlazos(Number(plazoMaximoCny)));
			setPlazoCny(plazoMaximoCny);

			setProductsOriginal([...rawProds]);
			// setRawProducts(rawProds);
			setProductsToCalculate([...rawProds]);
			// setProductsCopy(JSON.parse(JSON.stringify(productsCatsAll)));
			setProducts([...rawProds]);
			// setLoading(false);
			dispatch(actions.stopLoading());

			if (isCalculate) {
				await handleCalculate(rawProds);
				return;
			}
		} catch (error: any) {
			// setLoading(false);
			dispatch(actions.stopLoading());
			setAlert({
				show: true,
				message:
					error.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
				severity: 'error',
			});
		}
	};

	useEffect(() => {
		getProducts();
	}, []);

	const handleChangeProductMonto = (
		key: string,
		value: string,
		product: string
	) => {
		const subKey =
			key === 'mmcMon'
				? 'montoCreditoSolicitado'
				: 'montoCreditoSolicitadoCny';

		setProducts(
			products.map((input) => {
				if (input.producto === product) {
					return {
						...input,
						[key]: value,
					};
				}
				return input;
			})
		);
		setProductsToCalculate(
			productsToCalculate.map((input) => {
				if (input.producto === product) {
					return {
						...input,
						[subKey]: value,
					};
				}
				return input;
			})
		);
	};

	const formatProducts = () => {
		if (products) {
			setRowsTitle(
				rowsTitle.map((row) => {
					if (row.key === 'mmcMon')
						return {
							...row,
							format: undefined,
						};
					if (row.key === 'cdcMon' && conyuge)
						return {
							...row,
							key: 'totalContarias',
						};
					return row;
				})
			);
			setProductsTable(
				products.map((product: any) => ({
					...product,
					mmcMon: getInputMonto({
						key: PRODUCT_KEYS.monto,
						product: product.producto,
						onChange: (value) =>
							handleChangeProductMonto(
								'mmcMon',
								value,
								product.producto
							),
						value:
							products?.find(
								(v) => v.producto === product.producto
							)?.mmcMon || '',
					}),
					mmcMonCny: getInputMonto({
						key: PRODUCT_KEYS.montoConyuge,
						product: product.producto,
						onChange: (value) =>
							handleChangeProductMonto(
								'mmcMonCny',
								value,
								product.producto
							),
						value:
							products?.find(
								(v) => v.producto === product.producto
							)?.mmcMonCny || '',
					}),
				}))
			);
		}
	};

	useEffect(() => {
		formatProducts();
	}, [products, isSearch, disableInputMonto]);

	const handleTrazabilidad = async () => {
		const selectedProduct = products.find(
			(prod) => prod.producto === selectProduct
		);
		createTrazabilidad({
			credit: dataProcessController.processInfo?.credit,
			subtipoTrazabilidad: selectedProduct?.subtipoTrazabilidad,
		});
	};

	const handleContinue = async () => {
		let product = products.find((prod) => prod.producto === selectProduct);
		// setProduct(rawProducts.find((prod) => prod.producto === selectProduct));
		await handleTrazabilidad();
		addSelection({
			title: 'Producto Seleccionado:',
			description: `${creditTypes[selectProduct].title}`,
		});
		saveData({
			processInfo: {
				productSelect: product,
			},
			processData: {
				active: 4,
			},
		});
		nextStep();
	};

	const handleCalculate = async (productosItems: IProductMonto[]) => {
		let valueBuilding = valorConstruir;
		if (!valorConstruir && conyuge) {
			valueBuilding = '0';
		} else if (Number(valorConstruir || '0') <= 0 && !conyuge) {
			return setAlertInput({
				show: true,
				message: 'Escribe el valor de la vivienda que quieres comprar',
				severity: 'warning',
			});
		} else {
			setAlertInput({
				show: false,
				message: '',
				severity: 'warning',
			});
		}

		try {
			dispatch(actions.startLoading());
			// setLoading(true);
			let productsMap = productosItems;
			let productsPromise: Promise<any>[] = [];
			for (let index = 0; index < productsMap.length; index++) {
				const product = productsMap[index];
				const data = getDataCalculadora(product, valueBuilding);
				const ENCODED_DATA = await encodeAllJSONData(
					{ data: JSON.stringify(data) },
					user?.k || ''
				);
				productsPromise.push(apiMIUC.calculadora(ENCODED_DATA));
			}
			const response = await Promise.all(productsPromise);
			const productsCalculated = (response || []).map((productCal) => {
				const productItem = productosItems.find(
					(item) => item.producto === productCal.data?.producto
				);
				const newRawProducts = {
					...productItem,
					pzoCre: productCal?.data?.pzoCre || productItem?.pzoCre,
					pzoCreCny:
						productCal?.data?.pzoCre || productItem?.pzoCreCny,
					mmcMon: productCal?.data?.montoMaximoCredito,
					mmcMonCny: productCal?.data?.montoMaximoCreditoCny,
					gasMon: productCal?.data?.cargasFinancieras,
					gasMonCny: productCal?.data?.cargasFinancierasCny,
					cdcMon: productCal?.data?.capacidadCompra || '0',
					cdcMonCny: productCal?.data?.capacidadCompraCny || '0',
					desTot: productCal?.data?.descuento,
					desTotCny: productCal?.data?.descuentoCny,
					desRoa: productCal?.data?.descuentoSinSubsidio,
					desRoaCny: productCal?.data?.descuentoSinSubsidioCny,
					diffCubrir: productCal?.data?.diferenciasCargoTrabajador,
					// diffCubrirCny: productCal?.data?.diferenciasCargoTrabajadorCny,
					nvoFPP: productCal?.data?.nvoFPP || '0',
					nvoFPPCny: productCal?.data?.nvoFPPCny || '0',
					nvoCotaAdmin: productCal?.data?.nvoCotaAdmin || '0',
					nvoCotaAdminCny: productCal?.data?.nvoCotaAdminCny || '0',
					saldoSubcuentaVivienda:
						productCal?.data?.saldoSubcuentaVivienda || 0,
					saldoSubcuentaViviendaCny:
						productCal?.data?.saldoSubcuentaViviendaCny || 0,
					ahorro: productCal?.data?.saldoSubcuentaVivienda || 0,
					ahorroCny: productCal?.data?.saldoSubcuentaViviendaCny || 0,
					complementos: '',
				} as IProductMonto;

				return {
					...newRawProducts,
				};
			});
			const productsCatsAll: IProductMonto[] = await getAllCats(
				productsCalculated
			);
			const rawProds = productsCatsAll.map((product: any) => {
				return {
					...product,
					ahorro: dataProcessController.titular.saldoSARTotal,
					totalContarias:
						Number(product.cdcMon) + Number(product.cdcMonCny),
				};
			});
			// setRawProducts(rawProds);
			setProducts(rawProds);
			setIsSearch(true);
			disableAll();
			dispatch(actions.stopLoading());
			// setLoading(false);
		} catch (error) {
			dispatch(actions.stopLoading());
			// setLoading(false);
		} finally {
			dispatch(actions.stopLoading());
			setAlertInput({
				show: false,
				message: '',
				severity: 'warning',
			});
		}
	};

	const handleClearSearch = () => {
		// const productBeforeClean = JSON.parse(JSON.stringify(productsCopy));
		setIsSearch(false);
		setValorConstruir('');
		// setProducts(productBeforeClean);
		// setProductsToCalculate([...productsOriginal]);
		// setProducts([...productsOriginal]);
		getProducts();
		enableAll();
	};

	const handleSetDataConstruir = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const { value } = event.target;
		if (isNumberFloat(value)) {
			setValorConstruir(value);
		}
	};

	const handleButtonCalculate = () => {
		if (disableInputMonto) {
			getProducts(true);
		} else {
			handleCalculate(productsToCalculate);
		}
	};

	return {
		handleContinue,
		returnStep,
		setSelectProduct,
		selectProduct,
		products,
		newStep,
		disableButton,
		alert,
		isSearch,
		valorConstruir,
		setValorConstruir,
		handleSetDataConstruir,
		handleCalculate,
		handleClearSearch,
		productsOriginal,
		alertInput,
		productsTable,
		productsToCalculate,
		rowsTitle,
		conyuge,
		disableSelectPlazo,
		getPlazos,
		onChangePlazo,
		plazo,
		setPlazo,
		plazos,
		handleButtonCalculate,
		disableCalculateBtn,
		plazoCny,
		plazosCny,
		setPlazoCny,
	};
};
