import { Box, Paper } from '@mui/material';
import CustomButton from '../../components/Button';
import { IAlert } from '../../interfaces/alert';
import CustomAlert from '../../components/CustomAlert';

interface IProps {
	setPage: (arg: number) => void;
	alert: IAlert;
	disabledButton: boolean;
}

const MainSumary = ({ setPage, alert, disabledButton }: IProps) => {
	return (
		<Paper>
			<Box
				padding={2}
				maxWidth={700}
				textAlign={'center'}
				margin={'auto'}
			>
				<p style={{ fontWeight: 400, margin: '50px 0px' }}>
					De acuerdo con nuestros registros, se identifican recursos
					acumulados correspondientes al periodo de mayo de 1992 a
					junio de 1997, denominado "SAR 92". Dichos recursos no
					fueron unificados a una cuenta en una Afore, sino que fueron
					transferidos a una cuenta concentradora, por lo que para su
					conciliación e integración en la subcuenta, deberás acudir a
					tu Afore y solicitar el trámite de "Solicitud de traspaso de
					SAR 92-97".
				</p>
				<CustomAlert
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
				/>
				<Box maxWidth={250} margin={'20px auto'}>
					<CustomButton
						label={'Continuar'}
						onClick={() => setPage(1)}
						variant={'solid'}
						disabled={disabledButton}
					/>
				</Box>
			</Box>
		</Paper>
	);
};

export default MainSumary;
