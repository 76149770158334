import master_card from '../../../assets/img/master_card.png'
import visa_card from '../../../assets/img/visa_card.png'
import codi from '../../../assets/img/codi-icon.png'
import { Link } from '@mui/material'
import { formatDate } from '../../../utils/dates'
import { red } from '@mui/material/colors'

export const paymentMetods = [
	{
		name: "method_payment",
		label: 'Pago con CoDi',
		value: 'codi',
		id: 'cody_method_input',
		images: [codi]
	},
	{
		name: "method_payment",
		label: 'Pago con tarjeta',
		value: 'card',
		id: 'card_method_input',
		images: [master_card, visa_card]
	},
	{
		name: "method_payment",
		label: 'Pago con cuenta bancaria en México',
		value: 'cuenta',
		id: 'card_method_input',
		images: []
	},
]

export const accountStatusRadios = (setCalendar: any) => [
	{
		label: 'Mensual',
		value: 'monthly',
		sublabel: 'Movimientos de tu crédito mes con mes. Se actualiza el día 10 de cada mes, pero con corte al último día del mes anterior.',
	},
	{
		label: 'Resumen de pago por servicios',
		value: 'services',
		sublabel: <>
			Pago del predial y cuota de conservación. <Link component='button' onClick={() => setCalendar(true)}>Calendario de pagos de servicios</Link>
		</>,
	},
	{
		label: 'Histórico',
		value: 'history',
		sublabel: 'Movimientos desde el inicio de tu crédito. El estado de cuenta se actualiza el día 10 de cada mes.',
	},
]

export const accountColumns = [
	{ column: 'date', label: 'Fecha' },
	{ column: 'concept', label: 'Concepto' },
	{ column: 'total', label: 'Pago total', renderValue: (value: any, i: number, oArray: any) => `$${value}` },
	{ column: 'insurance', label: 'Pago a seguros y cuotas', renderValue: (value: any, i: number, oArray: any) => `$${value}` },
	{ column: 'interest', label: 'Pago a intereses', renderValue: (value: any, i: number, oArray: any) => `$${value}` },
	{ column: 'capital', label: 'Pago a capital', renderValue: (value: any, i: number, oArray: any) => `$${value}` },
]

export const mockAccount = [
	{ id: 1, date: formatDate('P'), concept: 'Pago regular', total: 423434.43, insurance: 423434.43, interest: 423434.43, capital: 423434.43 },
	{ id: 2, date: formatDate('P'), concept: 'Pago regular', total: 423434.43, insurance: 423434.43, interest: 423434.43, capital: 423434.43 },
	{ id: 3, date: formatDate('P'), concept: 'Pago regular', total: 423434.43, insurance: 423434.43, interest: 423434.43, capital: 423434.43 },
]

export const paymentsCalendarColumns = [
	{ column: 'id', label: 'Bimestre', headerStyles: { background: red[800], color: 'white', fontWeight: 600 } },
	{ column: 'month', label: 'Mes que corresponde', headerStyles: { background: red[800], color: 'white', fontWeight: 600 } },
	{ column: 'date', label: 'Fecha de pago del patrón', headerStyles: { background: red[800], color: 'white', fontWeight: 600 } },
	{ column: 'corresponding', label: 'Mes que corresponde', headerStyles: { background: red[800], color: 'white', fontWeight: 600 } },
]

export const mockCalendar = [
	{ id: 1, month: 'Enero - Febrero', date: '17 Enero', corresponding: '15 Febrero' },
	{ id: 2, month: 'Marzo - Abril', date: '17 Marzo', corresponding: '15 Abril' },
	{ id: 3, month: 'Mayo - Junio', date: '17 Mayo', corresponding: '15 Junio' },
	{ id: 4, month: 'Julio - Agosto', date: '17 Julio', corresponding: '15 Agosto' },
	{ id: 5, month: 'Septiembre - Octubre', date: '17 Septiembre', corresponding: '15 Octubre' },
	{ id: 6, month: 'Noviembre - Diciembre', date: '17 Noviembre', corresponding: '15 Diciembre' },
]