import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icono: {
			color: 'white',
			fontSize: 8,
		},
		divContainer: {
			height: '20px',
			width: '20px',
			display: 'contents',
		},
		divCircle: {
			border: '1px solid red',
			borderRadius: '50%',
			background: 'red',
			display: 'flex',
			height: '100%',
			width: '100%',
			justifyContent: 'center',
			alignContent: 'center',
			textAlign: 'center',
		},
		colorTitle: {
			color: theme.palette.info.main,
		},
		parrafo: {
			fontFamily: 'Geomanist, Regular',
			fontSize: '14px',
			lineHeight: '1.5',
		},
	})
);
