/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { Radio } from '@mui/material';
import HeaderStep from './HeaderStep';
import { useStyles } from '../styles/styles';

const QuestStep1 = ({
	quest,
	nextStep,
	handleChange,
	afterQuestionarySteps,
	alert
}: any) => {
	const classes = useStyles();
	const [quests, setQuests] = useState([] as any);

	useEffect(() => {
		drawQuestions();
	}, [quest]);

	const drawQuestions = () => {
		const questions: any = [];
		quest.map((item: any) => {
			const newItem = {
				title: `${item.numero.split('.')[1]}.- ${item.pregunta}`,
				body: (
					<>
						{item.opciones.map((opcion: any) => (
							<div
								className={classes.itemsFlexCenter}
								key={opcion + item.pregunta}
							>
								<Radio
									checked={
										item.respuesta === opcion.split(')')[0]
									}
									onChange={(e: any) => {
										let value =
											e.target.value.split(')')[0];
										handleChange(value, item.numero);
									}}
									name={opcion.split(')')[0]}
									value={opcion.split(')')[0]}
								/>
								{opcion}
							</div>
						))}
					</>
				),
			};
			return questions.push(newItem);
		});
		setQuests(questions);
	};

	const verifyComplete = () => {
		let respuesta = quest.every((item: any) => {
			if (item.respuesta.length === 0) return false
			else return true
		})
		return respuesta
	};

	return (
		<HeaderStep
			title={'Datos personales'}
			nextStep={nextStep}
			afterQuestionarySteps={afterQuestionarySteps}
			disabledContinue={!verifyComplete()}
			disabledButton={!verifyComplete()}
			children={quests}
			alert={alert}
		/>
	);
};

export default QuestStep1;
