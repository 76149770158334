/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import ModalLoading from '../../components/ModalLoading';
import InfoView from './InfoLetter';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { IAlert } from '../../interfaces/alert';
import {
	cancelLetterAPI,
	IDataConsultaCasos,
} from '../../api/modules/cancellationLetter';
import WrittingView from './Writing';
import MedioView from './Medio';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import FormOneView from './FormOne';
import FilesView from './Files';
import ChargeView from './ChargeFile';
import FinishView from './Finish';
import FormCancellationContext, {
	FormCancellation,
} from './contextFormCancellation';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { downloadPDF } from '../../utils/downloadPDF';
import { formatDate } from '../../utils/dates';
import { SelectStateView } from './SelectState';
import axios from 'axios';
import InfoViewUMAS from './InfoLetterUMAS';
import ImportantAspects from './importantAspects';

interface IParams {
	codigo?: string;
	estatus?: string;
	fechaEstatus?: string;
	fechaSolicitud?: string;
	numeroCaso?: string;
	tipificacion?: string;
	hash?: string;
	description?: string;
	descarga?: string;
}
interface IDataHipoteca {
	caso: string;
	fecha_liq: string;
	est_credito: string;
	casollave: string;
	tipificacion: string;
}
export const CancellationView = () => {
	const [loading, setLoading] = useState(false);
	const [caso, setCaso] = useState(false);
	const [page, setPage] = useState(-1);
	const [resp, setResp] = useState('');
	const [resp2, setResp2] = useState('');
	const [dataHipoteca, setDataHipoteca] = useState<IDataHipoteca>(
		{} as IDataHipoteca
	);
	const [data, setData] = useState<FormCancellation>({} as FormCancellation);
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [alertFile, setAlertFile] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [params, setParams] = useState<IParams>({
		codigo: '',
		estatus: '',
		fechaEstatus: '',
		fechaSolicitud: '',
		numeroCaso: '',
		tipificacion: '',
		descarga: '',
		hash: '',
	});
	const [stateSelected, setStateSelected] = useState<string>('');

	const [files, setFiles] = useState({
		file1: {
			name: INTERNAL_MESSAGES.NO_FILE,
			data: null,
			type: '',
			size: 0,
		},
	});
	const [escrituras_pub_priv, setEscrituras_pub_priv] = useState<{
		escrituraPublica: boolean;
		escrituraPrivada: boolean;
	}>({
		escrituraPublica: false,
		escrituraPrivada: false,
	});

	const TypeOfCancellationByState = [
		{
			name: 'Aguascalientes',
			escrituraPublica: true,
			escrituraPrivada: true,
		},
		{
			name: 'Baja California',
			escrituraPublica: true,
			escrituraPrivada: false,
		},
		{
			name: 'Baja California Sur',
			escrituraPublica: true,
			escrituraPrivada: true,
		},
		{
			name: 'Campeche',
			escrituraPublica: true,
			escrituraPrivada: false,
		},
		{
			name: 'Coahuila',
			escrituraPublica: true,
			escrituraPrivada: false,
		},
		{ name: 'Colima', escrituraPublica: true, escrituraPrivada: true },
		{ name: 'Chiapas', escrituraPublica: true, escrituraPrivada: true },
		{
			name: 'Chihuahua',
			escrituraPublica: true,
			escrituraPrivada: true,
		},
		{
			name: 'Ciudad de México',
			escrituraPublica: true,
			escrituraPrivada: false,
		},
		{ name: 'Durango', escrituraPublica: true, escrituraPrivada: true },
		{
			name: 'Guanajuato',
			escrituraPublica: true,
			escrituraPrivada: false,
		},
		{
			name: 'Guerrero',
			escrituraPublica: true,
			escrituraPrivada: true,
		},
		{
			name: 'Hidalgo',
			escrituraPublica: true,
			escrituraPrivada: false,
		},
		{
			name: 'Jalisco',
			escrituraPublica: true,
			escrituraPrivada: true,
		},
		{
			name: 'Estado de México',
			escrituraPublica: true,
			escrituraPrivada: false,
		},
		{
			name: 'Michoacán',
			escrituraPublica: true,
			escrituraPrivada: false,
		},
		{ name: 'Morelos', escrituraPublica: true, escrituraPrivada: true },
		{ name: 'Nayarit', escrituraPublica: true, escrituraPrivada: true },
		{
			name: 'Nuevo León',
			escrituraPublica: true,
			escrituraPrivada: true,
		},
		{ name: 'Oaxaca', escrituraPublica: true, escrituraPrivada: false },
		{ name: 'Puebla', escrituraPublica: true, escrituraPrivada: false },
		{
			name: 'Querétaro',
			escrituraPublica: true,
			escrituraPrivada: true,
		},
		{
			name: 'Quintana Roo',
			escrituraPublica: true,
			escrituraPrivada: true,
		},
		{
			name: 'San Luis Potosí',
			escrituraPublica: true,
			escrituraPrivada: true,
		},
		{ name: 'Sinaloa', escrituraPublica: true, escrituraPrivada: true },
		{ name: 'Sonora', escrituraPublica: true, escrituraPrivada: true },
		{ name: 'Tabasco', escrituraPublica: true, escrituraPrivada: true },
		{
			name: 'Tamaulipas',
			escrituraPublica: true,
			escrituraPrivada: true,
		},
		{
			name: 'Tlaxcala',
			escrituraPublica: true,
			escrituraPrivada: true,
		},
		{
			name: 'Veracruz',
			escrituraPublica: true,
			escrituraPrivada: false,
		},
		{ name: 'Yucatán', escrituraPublica: true, escrituraPrivada: true },
		{
			name: 'Zacatecas',
			escrituraPublica: true,
			escrituraPrivada: true,
		},
	];

	useEffect(() => {
		handleConsultCase();
	}, []);

	const handleChangeEscritura = (value: string) => {
		let newValue: {
			escrituraPublica: boolean;
			escrituraPrivada: boolean;
		} = {
			escrituraPublica: false,
			escrituraPrivada: false,
		};
		const filter = TypeOfCancellationByState.filter(
			(x) => x.name === value
		);

		if (filter.length > 0) {
			newValue = {
				escrituraPublica: filter[0].escrituraPublica,
				escrituraPrivada: filter[0].escrituraPrivada,
			};
		}

		setEscrituras_pub_priv(newValue);
		setStateSelected(value);
	};

	const handleConsultCase = async () => {
		setLoading(true);
		try {
			setFiles({
				file1: {
					name: INTERNAL_MESSAGES.NO_FILE,
					data: null,
					type: '',
					size: 0,
				},
			});

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			let parameters = {
				nss: await encrypt(user?.nss ? user?.nss : '', key),
				orden: await encrypt('', key),
				claseOperacion: await encrypt('ZCHI', key),
			} as IDataConsultaCasos;
			cancelLetterAPI
				.consultaCasos(parameters)
				.then(async (response) => {
					if (response) {
						if (response.result?.codigo === '0000') {
							/* Clase de Operación ZCHI */
							switch (response.result?.estatus) {
								case 'E0002':
									/* 
										ZCHI_ESPERA_DOCUMENTO("E0002"),
										mandar al paso de subir documento, ya hay un caso creado pero no tiene un documento 
									*/
									setCaso(true);
									setParams({
										...params,
										hash: response.result?.data?.hash,
										numeroCaso: response.result?.data?.caso,
										estatus:
											response.result?.data
												?.descripcionEstatus,
										fechaSolicitud: String(
											response.result?.data
												?.fechaAbierta || ''
										),
										descarga:
											response.result?.data?.descarga,
									});
									handleMortgageConsultation(key, 4, true);
									break;
								default:
									/*
										ZCHI_GENERAR_CARTA("E0003"),
										ZCHI_ESPERA_CARTA("E0018"),
										ZCHI_CERRADO("E0019"),
									*/
									setParams({
										...params,
										hash: response.result?.data?.hash,
										numeroCaso: response.result?.data?.caso,
										estatus:
											response.result?.data
												?.descripcionEstatus,
										fechaSolicitud: String(
											response.result?.data
												?.fechaAbierta || ''
										),
										descarga:
											response.result?.data?.descarga,
									});
									setCaso(true);
									handleMortgageConsultation(key, 6, true);
									break;
								//------------------------------------------------------------------------------------------------
								// case 'E0019':
								// 	/* mandar a la ultima pantalla para descargar el documento */
								// 	/* Caso cerrado */
								// 	setParams(response.result);
								// 	setCaso(true);
								// 	handleMortgageConsultation(key, 6);
								// 	break;
								// case 'E0018':
								// 	/* mandar al paso de subir documento, ya hay un caso creado pero no tiene un documento */
								// 	setParams(response.result);
								// 	handleMortgageConsultation(key);
								// 	setCaso(true);
								// 	setPage(6);
								// 	break;
								// case 'E0007':
								// 	/* mandar a la ultima pantalla para descargar el documento */
								// 	/* Caso cerrado */
								// 	setParams(response.result);
								// 	setCaso(true);
								// 	handleMortgageConsultation(key, 6);
								// 	break;
								// case 'E0001':
								// 	/* continuar guardando info */
								// 	/* Caso abierto */
								// 	setParams(response.result);
								// 	handleMortgageConsultation(key, 4);
								// 	setCaso(true);
								// 	break;
								// case 'E0002':
								// 	/* Enviar a la carga de documento */
								// 	setCaso(true);
								// 	setParams(response.result);
								// 	handleMortgageConsultation(key, 4);
								// 	break;
								// default:
								// 	handleMortgageConsultation(key);
								// 	break;
								//------------------------------------------------------------------------------------------------
							}
						} else {
							/* Validar cancelacion de hipoteca */
							/* Consumir otro endpoint */
							parameters = {
								nss: await encrypt(
									user?.nss ? user?.nss : '',
									key
								),
								orden: await encrypt('', key),
								claseOperacion: await encrypt('ZCHB', key),
							} as IDataConsultaCasos;

							cancelLetterAPI
								.consultaCasos(parameters)
								.then((responseZCHB) => {
									if (responseZCHB) {
										if (
											responseZCHB.result?.codigo ===
											'0000'
										) {
											switch (
												responseZCHB.result?.estatus
											) {
												case 'E0002':
													/* 
													ZCHB_ESPERA_DOCUMENTO("E0002"),
													mandar al paso de subir documento, ya hay un caso creado pero no tiene un documento 
												*/
													setCaso(true);
													setParams({
														...params,
														hash: response.result
															?.data?.hash,
														numeroCaso:
															response.result
																?.data?.caso,
														estatus:
															response.result
																?.data
																?.descripcionEstatus,
														fechaSolicitud: String(
															response.result
																?.data
																?.fechaAbierta ||
																''
														),
														descarga:
															response.result
																?.data
																?.descarga,
													});
													handleMortgageConsultation(
														key,
														4,
														true
													);
													break;
												default:
													/*
													ZCHB_ESCALADO_ESPECIALISTA("E0003"),
													ZCHB_EN_TRATAMIENTO("E0004"),
													ZCHB_GENERAR_CARTA("E0005"),
													ZCHB_ESPERA_CARTA("E0006"),
													ZCHB_CERRADO("E0007"),
												*/
													setParams({
														...params,
														hash: response.result
															?.data?.hash,
														numeroCaso:
															response.result
																?.data?.caso,
														estatus:
															response.result
																?.data
																?.descripcionEstatus,
														fechaSolicitud: String(
															response.result
																?.data
																?.fechaAbierta ||
																''
														),
														descarga:
															response.result
																?.data
																?.descarga,
													});
													handleMortgageConsultation(
														key,
														6,
														true
													);
													break;
												//------------------------------------------------------------------------------------------------
												// case 'E0019':
												// 	/* mandar a la ultima pantalla para descargar el documento */
												// 	/* Caso cerrado */
												// 	setParams(responseZCHB.result);
												// 	setCaso(true);
												// 	handleMortgageConsultation(key, 6);
												// 	break;
												// case 'E0018':
												// 	/* mandar al paso de subir documento, ya hay un caso creado pero no tiene un documento */
												// 	setParams(responseZCHB.result);
												// 	handleMortgageConsultation(key);
												// 	setCaso(true);
												// 	setPage(6);
												// 	break;
												// case 'E0007':
												// 	/* mandar a la ultima pantalla para descargar el documento */
												// 	/* Caso cerrado */
												// 	setParams(responseZCHB.result);
												// 	setCaso(true);
												// 	handleMortgageConsultation(key, 6);
												// 	break;
												// case 'E0001':
												// 	/* continuar guardando info */
												// 	/* Caso abierto */
												// 	setParams(response.result);
												// 	handleMortgageConsultation(key, 4);
												// 	setCaso(true);
												// 	break;
												// case 'E0002':
												// 	/* Enviar a la carga de documento */
												// 	setCaso(true);
												// 	setParams(responseZCHB.result);
												// 	handleMortgageConsultation(key, 4);
												// 	break;
												// default:
												// 	handleMortgageConsultation(key);
												// 	break;
												//------------------------------------------------------------------------------------------------
											}
										} else {
											/* Validar cancelacion de hipoteca */
											/* Consumir otro endpoint */
											handleMortgageConsultation(key);
											setCaso(false);
										}
									}
									setLoading(false);
								})
								.catch((err) => {
									setAlert({
										show: true,
										message: err.description
											? err.description
											: INTERNAL_MESSAGES.ERROR_GETTING_INFORMATION,
										severity: 'error',
									});
									setLoading(false);

									return { tieneCaso: false };
								})
								.finally(() => setLoading(false));
						}
					}
					setLoading(false);
				})
				.catch((err) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: INTERNAL_MESSAGES.ERROR_GETTING_INFORMATION,
						severity: 'error',
					});
					setLoading(false);

					return { tieneCaso: false };
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_GETTING_INFORMATION,
				severity: 'error',
			});
			return { tieneCaso: false };
		}
	};

	const handleMortgageConsultation = async (
		key: string,
		pageNumber?: number,
		caso: boolean = false
	) => {
		try {
			const credit_base64 = await encrypt(
				credit ? credit.toString() : '',
				key
			);
			cancelLetterAPI
				.consultaHipoteca(credit_base64)
				.then((response) => {
					if (response.result && !response.result?.error) {
						setDataHipoteca(response.result);
						if (
							response.result?.isUmaDosOcho === true &&
							caso === false
						) {
							setPage(7);
						} else {
							if (pageNumber) {
								setPage(pageNumber);
							}
						}
					}
				})
				.catch((err) => {
					const msg: string =
						err !== undefined
							? err.description
							: INTERNAL_MESSAGES.ERROR_GETTING_PDF;

					setAlert({
						show: true,
						message: msg,
						severity: 'error',
					});
				});
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_GETTING_DATA,
				severity: 'error',
			});
		}
	};

	const handleGetPDF = async () => {
		try {
			setLoading(true);
			// Descarga por CRM
			if (params.descarga === 'CRM') {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const caso_base64 = await encrypt(
					params.numeroCaso ? params.numeroCaso : '',
					key
				);
				const { result } = await cancelLetterAPI.descargaDoc({
					caso: caso_base64,
				});
				if (result.archivo) {
					downloadPDF({
						payload: result.archivo,
						name: `Carta-Cancelacion-${formatDate('P')}`,
					});
					setAlertFile({
						show: false,
						message: '',
						severity: 'error',
					});
					return;
				}
			}
			// Descarga por openText
			if (params.descarga === 'OPENTEXT') {
				try {
					const keyRes = await keyAPI.get<'', { k: string }>();
					const key = keyRes['k'].toString();
					const caso_base64 = await encrypt(
						params.numeroCaso ?? '',
						key
					);
					const clavedoc_base64 = await encrypt('A004', key);
					const hash_base64 = await encrypt(params.hash || '', key);
					const { result } = await cancelLetterAPI.descargaDocOtx({
						tramite: caso_base64,
						clavedoc: clavedoc_base64,
						hash: hash_base64,
					});

					if (result?.pdf) {
						downloadPDF({
							payload: result?.pdf,
							name: `Carta-Cancelacion-${formatDate('P')}`,
						});
						return;
					}

					setAlertFile({
						show: true,
						message:
							result?.mensaje ||
							INTERNAL_MESSAGES.ERROR_GETTING_DATA,
						severity: 'error',
					});
				} catch (error) {
					setAlertFile({
						show: true,
						message: INTERNAL_MESSAGES.ERROR_GETTING_DATA,
						severity: 'error',
					});
				}
				return;
			}
			setAlertFile({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_GETTING_DATA,
				severity: 'error',
			});
		} catch (error: any) {
			setAlertFile({
				show: true,
				message: error.description
					? error.description
					: INTERNAL_MESSAGES.ERROR_GETTING_DATA,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleAttachCase = async () => {
		let formData = new FormData();

		formData.append('numeroCredito', credit);
		formData.append(
			'numeroCaso',
			data.noCaso
				? data.noCaso
				: params.numeroCaso
				? params.numeroCaso
				: ''
		);
		formData.append(
			'tipificacion',
			data.tipificacion
				? data.tipificacion
				: dataHipoteca.tipificacion
				? dataHipoteca.tipificacion
				: ''
		);
		formData.append(
			'nombreArchivo',
			`escritura-${user?.nss.toString()}.pdf`
		);
		formData.append('nss', user?.nss ? user?.nss.toString() : '');
		formData.append(
			'claseOperacion',
			data.clase_operacion
				? data.clase_operacion
				: dataHipoteca.casollave
				? dataHipoteca.casollave
				: ''
		);
		formData.append('cveDocumento', 'Z801'); // Se realiza cambios antes era A004
		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL_OPENTEXT}/CartasProduccion-web/api/AltaEscrituras/envioOpenText`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
			.then((res) => {})
			.catch((err) => {
				setAlert({
					show: true,
					message: err.description
						? err.description
						: INTERNAL_MESSAGES.ERROR_GETTING_DATA,
					severity: 'error',
				});
			});
	};

	return (
		<Layout>
			<FormCancellationContext.Provider value={[data, setData]}>
				{page === -1 && (
					<InfoView setPage={setPage} caso={caso} hasError={alert} />
				)}
				{page === 0 && (
					<SelectStateView
						setPage={setPage}
						value={stateSelected}
						setValue={handleChangeEscritura}
					/>
				)}

				{page === 1 && (
					<WrittingView
						setPage={setPage}
						setResp={setResp}
						escrituras_pub_priv={escrituras_pub_priv}
					/>
				)}
				{page === 2 && (
					<MedioView setPage={setPage} setResp2={setResp2} />
				)}
				{page === 3 && (
					<FormOneView
						setPage={setPage}
						tipo={resp}
						medio={resp2}
						dataHipoteca={dataHipoteca}
						handlerConsultaCaso={handleConsultCase}
					/>
				)}
				{page === 4 && (
					<FilesView
						setPage={setPage}
						files={files}
						setFiles={setFiles}
						noCaso={
							data.noCaso
								? data.noCaso
								: params.numeroCaso
								? params.numeroCaso
								: ''
						}
					/>
				)}
				{page === 5 && (
					<ChargeView
						handleConsultCase={handleConsultCase}
						files={files}
						handleAttachCase={handleAttachCase}
					/>
				)}
				{page === 6 && (
					<FinishView
						hasError={alertFile}
						setPage={setPage}
						downloadFile={handleGetPDF}
						value={stateSelected}
						setValue={setStateSelected}
						params={params}
						handleConsultCase={handleConsultCase}
					/>
				)}
				{page === 7 && (
					<InfoViewUMAS
						setPage={setPage}
						caso={caso}
						hasError={alert}
					/>
				)}
				{page === 8 && (
					<ImportantAspects
						setPage={setPage}
						caso={caso}
						hasError={alert}
					/>
				)}
				<ModalLoading loading={loading} />
			</FormCancellationContext.Provider>
		</Layout>
	);
};
export default CancellationView;
