/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';

import { formatMoney } from '../../../utils/validators';
//Assets
import imgCoin from '../../../assets/img/coin.jpg';
import imgPension from '../../../assets/img/Te-pensionaste-529x458.png';
import imgAgendaCita from '../../../assets/img/Agenda-tu-cita.png';
import imgConsultaAhorro from '../../../assets/img/consulta-ahorro-banner.png';
import imgHistorialLaboral from '../../../assets/img/historial-laboral.png';

import { useProfile } from '../../MIUC/hooks/useProfile';

const PensionerWithoutCredit = () => {
	const classes: any = useStyles();
	const navigate = useNavigate();
	const { saldoSARTotal } = useProfile({ initProcess: true });

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={12} lg={4}>
				<Paper className={classes.card}>
					<div className={classes.titleCard}>
						<h3>
							¿Estás a punto de pensionarte o eres pensionado(a)?
						</h3>
					</div>
					<div className={classes.textCard}>
						<p>
							Recuerda que una vez que nos hagas llegar tu
							resolución de pensión por parte del IMSS o
							ISSSTE, o si alguna vez trabajaste para el
							Estado, puedes solicitar tu ahorro de la Subcuenta
							de Vivienda; te sugerimos que previamente valides
							el monto de ahorro que tienes y valides que todos
							los empleadores con los que has trabajado se
							encuentren registrados.
						</p>
					</div>
				</Paper>
			</Grid>
			<Grid item sm={12} md={12} lg={8}>
				<Grid container spacing={2} direction="row">
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<Paper
							style={{
								height: '100%',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							className={classes.mainContainerCardMoney}
						>
							<div>
								<label className={classes.titleCardMoney}>
									Tienes ahorrado en tu Subcuenta de Vivienda:
								</label>
								<div className={classes.containerCardMoney}>
									<img
										src={imgCoin}
										alt="icono pesos"
										className={classes.iconPesos}
									/>
									<label className={classes.labelSSV}>
										{formatMoney(Number(saldoSARTotal) || 0)} MXN
									</label>
								</div>
							</div>
						</Paper>
					</Grid>
					<Grid item sm={12} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/contactanos/haz-una-cita')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgAgendaCita}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>

				<Grid container spacing={2} direction="row" mt={1}>
					<Grid item sm={12} md={6} lg={6}>
						<div
							onClick={() =>
								navigate(
									'/mi-ahorro/devolucion-fondo-ahorro-72-92'
								)
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgPension}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item md={6} lg={6}>
						<Grid lg={12}>
							<div
								onClick={() =>
									navigate(
										'/mi-ahorro/resumen-movimientos-ahorro'
									)
								}
								style={{ height: '100%' }}
							>
								<img
									src={imgConsultaAhorro}
									className={classes.imageStyle}
									alt="img banner"
								/>
							</div>
						</Grid>
						<Grid lg={12} mt={1}>
							<div
								onClick={() =>
									navigate(
										'/mi-ahorro/consulta-relaciones-laborales'
									)
								}
								style={{ height: '100%' }}
							>
								<img
									src={imgHistorialLaboral}
									className={classes.imageStyle}
									alt="img banner"
								/>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PensionerWithoutCredit;
