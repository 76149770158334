import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		menuBanner: {
      position: 'relative',
			backgroundColor: 'white',
			display: 'flex',
			alignItems: 'center',
			border: '1px solid #BEBEBE',
			borderRadius: '4px',
			'&>.icon': {
				margin: '12px',
        '&> img':{
          width: '32px'
        }
			},
			'&>.text': {
				flex: '1',
				fontSize: '16px',
				color: '#333333',
				fontWeight: 'bold',
				marginRight: '8px',
        textAlign: 'start',
			},
			'&:hover': {
				'& a': {
					maxWidth: 240,
				},
				border: `1px solid ${theme.palette.primary.main}`,
				cursor: 'pointer',
			},
		},
    bannerWithText: {
      position: 'relative',
      backgroundColor: 'white',
			display: 'flex',
			alignItems: 'center',
			border: '1px solid #BEBEBE',
			borderRadius: '4px',
			minWidth: '300px',
			'&:hover': {
				cursor: 'pointer',
			},
			'&>.icon': {
				margin: '24px 12px',
				'&> img':{
          width: '64px'
        }
			},
			'&>.text': {
				flex: '1',
				fontSize: '18px',
				color: theme.palette.info.main,
				fontWeight: 'bold',
				marginRight: '16px',
			},
    },
    banner: {
      position: 'relative',
      backgroundColor: 'white',
			display: 'flex',
			alignItems: 'center',
			border: '1px solid #BEBEBE',
			borderRadius: '4px',
			'&>.icon': {
				margin: '16px 24px',
        '&> img':{
          width: '100%'
        }
			},
			'&>.text': {
				flex: '1',
				fontSize: '18px',
				color: theme.palette.info.main,
				fontWeight: 'bold',
				marginLeft: '8px',
			},
			'&:hover': {
				cursor: 'pointer',
			},
    }
	})
);
