import { HeaderList } from '../../HeaderList';

const PluginInformation = () => {
	return (
		<>
			<p>
				Beneficios, apoyos o complementos que pueden o deben aplicarse a
				tu crédito
			</p>

			<HeaderList
				listSizeIcon={18}
				list={[
					/*<div style={{ marginBottom: 10 }}>
						<b>Hipoteca verde: </b>
						Monto adicional de crédito para que instales
						ecotecnologías que te permitan disminuir tus consumos de
						agua, luz y gas y de esta forma, ahorres dinero.
					</div>,
					<div style={{ marginBottom: 10 }}>
						<b>Subsidio federal de CONAVI: </b>
						Apoyo económico del gobierno federal para los
						trabajadores con menores ingresos.
					</div>,*/
					<div style={{ marginBottom: 10 }}>
						<b>Hogar a tu medida: </b>
						Beneficio para personas con discapacidad, puede ser tú o
						un familiar que viva contigo.
					</div>,
					<>
						<b>Hipoteca con servicios: </b>
						Es la opción para pagar el predial de tu vivienda o la
						cuota de mantenimiento, sumándose a tu descuento vía
						nómina para el pago de tu crédito.
					</>,
				]}
			/>
		</>
	);
};

export default PluginInformation;
