import { Paper, useMediaQuery, Link, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/Button';
import imgDowload from '../../assets/img/icono-descarga.png';
import CustomAlert from '../../components/CustomAlert';

interface Props {
	pdf: string | null;
	error: string;
}

const ElectroNotifyConfirm = ({ pdf, error }: Props) => {
	const navigate = useNavigate();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	const downloadPdf = () => {
		if (pdf) {
			let link = document.createElement('a');
			link.download = 'Notificación_electrónica.pdf';
			link.href = 'data:application/octet-stream;base64,' + pdf;
			link.click();
		}
	};

	return (
		<>
			<Paper style={{ padding: matches ? 20 : 50, textAlign: 'center' }}>
				{pdf && (
					<>
						<div style={{ maxWidth: 900, margin: 'auto' }}>
							<p>
								Tu patrón confirmó que está enterado del trámite de tu
								crédito, puedes continuar con el proceso de formalización
								del crédito.
							</p>
							<p>
								Al concluir tu trámite de crédito tu patrón deberá
								descargar desde el Portal Empresarial Infonavit, el aviso
								de retención de descuentos para iniciar con la retención a
								tu salario correspondiente al pago de tu crédito.
							</p>
						</div>
					</>
				)}

				{error ? (
					<Box maxWidth={900} width={'100%'} margin={'auto'}>
						<CustomAlert
							message={
								error ||
								'Por el momento el servicio no está disponible, intenta más tarde.'
							}
							severity="error"
							show={true}
						/>
					</Box>
				) : (
					<>
						<Link
							onClick={() => downloadPdf()}
							underline="none"
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								margin: '30px 0px',
								userSelect: 'none',
							}}
						>
							<img
								src={imgDowload}
								alt="Pdf Download"
								style={{ marginRight: 10 }}
							/>
							Documento de carácter informativo para el derechohabiente.
						</Link>
					</>
				)}

				<CustomButton
					label="Salir"
					variant="solid"
					onClick={() => navigate('/')}
					styles={{ width: matches ? '100%' : 250, margin: '20px auto' }}
				/>
			</Paper>
		</>
	);
};

export default ElectroNotifyConfirm;
