import { makeStyles, createStyles } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Button, Select, Radio } from '@mui/material';

export const Base = makeStyles((theme: Theme) =>
	createStyles({
		h4: {
			color: '#293990',
			fontSize: 22,
			fontWeight: 'bold',
			marginTop: 0,
			marginBottom: 14,
		},
		divp: {
			color: '#333333',
			fontSize: 15,
			width: '100%',
			maxWidth: '965px',
			display: 'flex',
			alignItems: 'flex-start',
			fontWeight: 'lighter',
			transition: 'all 1s',
		},
		divForm: {
			//minHeight: '200px',
			paddingLeft: '150px',
			paddingRight: '150px',
			//maxWidth: '650px',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '10px',
				paddingRight: '10px',
			},
			// [theme.breakpoints.up('sd')]: {
			// 	paddingLeft: '10px',
			// 	paddingRight: '10px',
			// },
		},
		formLabel: {
			fontWeight: '800',
			[theme.breakpoints.up('md')]: {
				marginLeft: 30,
			},
		},
		panelAsociacion: {
			[theme.breakpoints.up('md')]: {
				marginLeft: 60,
			},
		},
		panelTop: {
			[theme.breakpoints.up('md')]: {
				marginLeft: 30,
			},
		},
		papper: {
			width: '100%',
			minHeight: 520,
			padding: '40px 20px 40px 20px',
			[theme.breakpoints.up('md')]: {
				padding: '20px',
			},
		},
		selectCustom: {
			marginTop: 20,
			marginBottom: 20,
			'& p': {
				margin: 0,
				color: '#7E8094',
				fontSize: 14,
			},
		},
		radioButton: {
			marginTop: 20,
			display: 'flex',
			alignItems: 'flex-start',
			'& p': {
				margin: 0,
				color: theme.palette.info.main,
				fontSize: 14,
				display: 'flex',
				alignItems: 'center',
			},
			'& label': {
				display: 'block',
				fontSize: 16,
				fontWeight: 400,
				marginBottom: 5,
			},
		},
		visorItem: {
			borderBottom: '1px solid rgba(0,0,0,0.1)',
			paddingBottom: 12,
			marginBottom: 20,
			'& label': {
				fontSize: 16,
				fontWeight: 600,
			},
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				justifyContent: 'space-between',
			},
		},
		associatedNotificationContainer: {
			textAlign: 'center',
			'&>.actions-container': {
				display: 'flex',
			},
		},
	})
);

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.root}`]: {
		fontWeight: 'bolder',
	},
}));

export const CustomButton = styled(Button)(({ theme }) => ({
	textTransform: 'none',
}));

export const RoundBtn = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	borderRadius: 40,
	width: '100%',
	margin: 10,
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
	margin: 0,
	padding: 0,
	marginRight: 5,
	marginTop: 3,
}));

export const CustomSelect = styled(Select)(({ theme }) => ({
	height: 40,
	width: '100%',
}));
