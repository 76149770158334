// DEPENDENCIES
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
// RESOURCES
import { useStyles, outlineBorder } from '../styles';
import { accordionQuestions } from '../utils';

const Questions = () => {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState<number | false>(false);

	const handleChange =
		(panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};

	return (
		<>
			{accordionQuestions.map(({ id, title, description }: any) => (
				<Accordion
					disableGutters
					square
					expanded={expanded === id}
					onChange={handleChange(id)}
					className={classes.accordion}
				>
					<AccordionSummary
						expandIcon={
							expanded === id ? (
								<Remove fontSize="small" style={outlineBorder} />
							) : (
								<Add fontSize="small" style={outlineBorder} />
							)
						}
					>
						<p
							className={`${
								expanded === id ? classes.withBold : classes.withoutBold
							}`}
						>
							{title}
						</p>
					</AccordionSummary>
					<AccordionDetails>{description}</AccordionDetails>
				</Accordion>
			))}
		</>
	);
};

export default Questions;
