/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TextInput from '../../../components/TextInput';
import { INotaryData, MortgageCancellationType } from '.';
import { states } from '../../../config/states';
import SelectInput from '../../../components/SelectInput';
import { emailValid } from '../../../utils/expressions';

interface INotarioView {
	data: INotaryData;
	changeData: (event: { name: string; value: string }) => void;
	docType: MortgageCancellationType;
}

export const NotarioView = ({ data, changeData, docType }: INotarioView) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [optionsSelect, setOptionsSelect] = useState<{ value: string; text: string }[]>(
		[]
	);
	const [errors, setErrors] = useState<{
		valid: boolean;
		msg: string;
	}>({
		valid: false,
		msg: '',
	});

	useEffect(() => {
		let newArray: { value: string; text: string }[] = [];
		states.forEach((state: { name: string; code: string }) => {
			newArray.push({ value: state.code, text: state.name });
		});

		setOptionsSelect(newArray);
	}, [states]);

	return (
		<div>
			<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
				<TextInput
					id="nombre"
					name="nombre"
					onChange={(event) => {
						changeData({
							name: event.target.name,
							value: event.target.value,
						});
					}}
					value={data.nombre}
					label="Nombre del notario que cancelará la hipoteca"
					isRequired
				/>
			</div>
			<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
				<TextInput
					id="numero"
					name="numero"
					onChange={(event) => {
						const { value, name } = event.target;
						if (!isNaN(Number(value))) {
							if (Number(value) >= 0) {
								changeData({
									name: name,
									value: value,
								});
							}
						}
					}}
					value={data.numero}
					label="Número de la notaría que cancelará la hipoteca"
					isRequired
				/>
			</div>
			<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
				<SelectInput
					data-testid="estado"
					id="estado"
					name="estado"
					label={'Estado donde se localiza la notaría'}
					isRequired
					value={data.estado}
					helperText={''}
					onChange={(event: any) => {
						changeData({
							name: event.target.name,
							value: event.target.value,
						});
					}}
					options={optionsSelect}
					placeholder="Selecciona"
				/>
			</div>
			<div style={{ paddingLeft: matches ? 0 : 200, paddingTop: 10 }}>
				<TextInput
					id="correo"
					name="correo"
					onChange={(event) => {
						let validation = emailValid(event.target.value);
						setErrors(validation);
						changeData({
							name: event.target.name,
							value: event.target.value,
						});
					}}
					value={data.correo}
					label="Correo electrónico del notario"
					isRequired
					error={!errors.valid}
					helperText={errors.valid ? '' : errors.msg}
				/>
			</div>
		</div>
	);
};

export default NotarioView;
