import { useState } from 'react';
import SuccesfulCancellation from './successfulCancellation';
import CancellationConfirmation from './cancellationConfirmation';
import HalfUnlock from '../UnlockPin/halfUnlock';
import TabsCustom from '../../../components/TabsCustom';
import ActivationMedium from '../activationMedium';

interface IData {
	blockNip: boolean;
	typeInformation: number;
	nip: string;
}

const CancellationPin = ({ blockNip, typeInformation, nip }: IData) => {
	const [isEmail, setIsEmail] = useState(false);
	const [tabs, setTabs] = useState(0);

	const handleFirstStep = async () => {
		setTabs(tabs + 1);
	};

	return (
		<div>
			<TabsCustom
				selectIndex={tabs}
				activeStep
				setSelectIndex={setTabs}
				components={[
					{
						title: 'Medio de cancelación de NIP',
						component: (
							<HalfUnlock
								onContinue={handleFirstStep}
								isEmail={isEmail}
								setIsEmail={setIsEmail}
								blockNip={blockNip}
								typeInformation={typeInformation}
							/>
						),
					},
					{
						title: 'Confirmación',
						component: (
							<CancellationConfirmation
								onContinue={handleFirstStep}
								isEmail={isEmail}
								nip={nip}
							/>
						),
					},
					{
						title: 'Código de confirmación',
						component: (
							<ActivationMedium
								onContinue={handleFirstStep}
								isEmail={isEmail}
								onCloseError={() => { }}
								reSend={() => { }}
								typeInformation={typeInformation}
								nip={nip}
								action={'cancelar'}
							/>
						),
					},

					{
						title: 'Cancelación de NIP',
						component: (
							<SuccesfulCancellation
								onContinue={handleFirstStep}
							/>
						),
					},
				]}
			/>
		</div>
	);
};

export default CancellationPin;
