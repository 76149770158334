import Modal from '../../components/Modal';

interface Props {
	file: any;
	setClose: (arg: any) => void;
}

const DocumentView = ({ file, setClose }: Props) => {
	return (
		<Modal
			open={file ? true : false}
			onClose={() => setClose(null)}
			children={
				file ? (
					<div style={{ textAlign: 'center' }}>
						{file.type.indexOf('image') > -1 && (
							<img alt="imagen" src={URL.createObjectURL(file)} />
						)}
						{file.type.indexOf('video') > -1 && (
							<video
								controls
								src={URL.createObjectURL(file)}
								style={{ maxWidth: 550, width: '100%' }}
							>
								Vídeo no es soportado
							</video>
						)}
						{file.type.indexOf('audio') > -1 && (
							<audio src={URL.createObjectURL(file)} controls>
								este es un elemento de audio no soportado por tu
								navegador, prueba con otro
							</audio>
						)}
						{file.type.indexOf('pdf') > -1 && (
							<iframe
								title="visor"
								src={URL.createObjectURL(file)}
								style={{ maxWidth: 550, width: '100%', height: 400 }}
							/>
						)}
					</div>
				) : (
					'Archivo no soportado'
				)
			}
		/>
	);
};

export default DocumentView;
