import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			textAlign: 'center',
			padding: '40px 60px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			'&>*': {
				margin: 0,
			},
			'&>.prorogation-card': {
				border: `solid 1px ${theme.palette.info.main}`,
				borderRadius: 5,
				color: theme.palette.info.main,
				width: 250,
				margin: '1rem 0',
				'&>*': {
					margin: 0,
				},
			},
			'&>.title': {
				color: theme.palette.info.main,
			},
			'&>.terms-header': {
				display: 'flex',
				position: 'relative',
				width: '100%',
				alignItems: 'center',
				'&>h4': {
					marginLeft: '7vw',
				},
			},
			'&>.terms-content': {
				textAlign: 'start',
				'&>.terms-user-info': {
					listStyleType: 'none',
					'&>li': {
						fontSize: '0.83em',
					},
					'&>li>strong': {
						fontWeight: 500,
					},
				},
			},
			'&>.actions-container': {
				marginTop: 15,
				width: '100%',
				maxWidth: 500,
				display: 'flex',
				justifyContent: 'space-evenly',
				alignItems: 'center',
				'&>button': {
					width: 200,
				},
				[theme.breakpoints.down('sm')]: {
					flexDirection: 'column-reverse',
				},
			},
		},
	})
);
