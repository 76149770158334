/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Box, Paper, Grid, useMediaQuery } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from './styles';

//Components
import CustomButton from '../../components/Button';
import { useTheme } from '@mui/material/styles';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';
import DocumentView from './DocumentView';

//Resources
import { IAlert } from '../../interfaces/alert';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { ApiRfcCorrectionRegister } from '../../api/modules/rfcCorrectionRegister';

interface Props {
	setPage: (data: number) => void;
	setMakeDate: (data: boolean) => void;
	setViewHeader: (data: boolean) => void;
	getStatus: () => void;
	params: {
		nss: string;
		curp: string;
		rfc: string;
		name: string;
		phone: string;
		email: string;
		rfcCrm: string;
	};
	dataStatus: any;
	setDataStatus: (data: any) => void;
}

const AttachDocument = ({ setPage, setMakeDate, params, dataStatus, setDataStatus, getStatus, setViewHeader }: Props) => {
	const theme = useTheme();
	const [loading, setLoading] = useState(false);
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [pageAttach, setPageAttach] = useState(false);
	const classes = useStyles();
	const [modalView, setModalView] = useState(null);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [file, setFile] = useState({
		nombre: 'No se eligió ningún archivo',
		archivo: null,
	});
	const [document, setDocument] = useState({
		nombre: 'No se eligió ningún archivo',
		archivo: null,
	});
	const [dataCase, setDataCase] = useState({
		noCaso: ''
	})

	useEffect(() => {
		if (!dataStatus.noCaso) {
			const crearCaso = async () => {
				try {
					setLoading(true);
					const keyRes = await keyAPI.get<'', { k: string }>();
					const key = keyRes['k'].toString();
					const nss_base64 = await encrypt(params?.nss.toString() || '', key);
					const rfc_base64 = await encrypt(params?.rfc.toString() || '', key);
					const email_base64 = await encrypt(params?.email.toString() || '', key);
					const celular_base64 = await encrypt(params?.phone.toString() || '', key);
					ApiRfcCorrectionRegister.createCase
						.post<
							{
								nss: string;
								rfc: string;
								email: string;
								telefonoCelular: string;
							},
							any
						>('', {
							nss: nss_base64,
							rfc: rfc_base64,
							email: email_base64,
							telefonoCelular: celular_base64
						})
						.then((res: any) => {
							setDataCase(res.result);
							setDataStatus({
								...dataStatus, setDataStatus,
								noCaso: res.result.noCaso
							})
						})
						.catch((err: any) => {
							setAlert({
								show: true,
								message: err.description ? err.description : 'Por el momento el servicio no está disponible. Intenta más tarde',
								severity: 'error',
							});
						})
						.finally(() => setLoading(false));
				} catch (error: any) {
					setAlert({
						show: true,
						message: error.description ? error.description : 'Por el momento el servicio no está disponible. Intenta más tarde',
						severity: 'error',
					});
					setLoading(false);
				}
			};
			crearCaso();
		}
	}, [dataStatus]);
	const handleDocument = (e: any) => {
		const type = e.target.files[0].type;
		if (e.target.files[0].size / 1024 ** 2 < 10) {
			if (/jpg|png|tiff|pdf|mpeg|avi|wmv|mp4|mp3/g.test(type)) {
				setDocument({ nombre: e.target.files[0].name, archivo: e.target.files[0] });
				e.target.value = null;
			}
		} else {
			setAlert({
				show: true,
				message:
					'El tamaño del archivo no debe de ser mayor a 10 MB.',
				severity: 'error',
			});
		}
	};
	const handleFile = (e: any) => {
		const type = e.target.files[0].type;
		if (e.target.files[0].size / 1024 ** 2 < 10) {
			if (/jpg|png|tiff|pdf|mpeg|avi|wmv|mp4|mp3/g.test(type)) {
				setFile({ nombre: e.target.files[0].name, archivo: e.target.files[0] });
				e.target.value = null;
			}
		} else {
			setAlert({
				show: true,
				message:
					'El tamaño del archivo no debe de ser mayor a 10 MB.',
				severity: 'error',
			});
		}
	};
	const handleSubmit = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const noCaso_base64 = await encrypt(dataStatus.noCaso ? dataStatus.noCaso.toString() : dataCase.noCaso?.toString(), key);
			const nss_base64 = await encrypt(params?.nss.toString() || '', key);
			let formData = new FormData();
			formData.append('noCaso', noCaso_base64);
			formData.append('nss', nss_base64);
			formData.append('rfc', document.archivo ? document.archivo : '');
			formData.append('ine', file.archivo ? file.archivo : '');
			const response = await uploadToFileServer(
				formData,
				`${process.env.REACT_APP_API_URL}/cambio-rfc-registro/haz-cita/attach-documents`
			);
			if (response) {
				setMakeDate(true)
				getStatus()
			} else {
				setAlert({
					show: true,
					message:
						'Por el momento el servicio no está disponible. Intenta más tarde',
					severity: 'error',
				});
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description ? error.description : 'Por el momento el servicio no está disponible. Intenta más tarde',
				severity: 'error',
			});
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};
	const uploadToFileServer = (formdata: any, url: string) => {
		return new Promise((resolve) => {
			const xhr = new XMLHttpRequest();
			xhr.responseType = 'json';
			xhr.open('POST', url, true);
			xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			xhr.send(formdata);

			xhr.onload = () => {
				if (xhr.status === 200) {
					resolve(xhr.response.result);
				} else {
					resolve(null);
				}
			};
		});
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<DocumentView file={modalView} setClose={setModalView} />
			<Paper>
				<Box className={classes.container}>
					{!pageAttach && (
						<div className={classes.form}>
							<div style={{
								width: '100%',
								maxWidth: '800px',
								margin: '10px auto',
								textAlign: 'center'
							}}>
								<label>
									Para hacer más ágil el trámite, antes de hacer una cita adjunta el
									comprobante del RFC emitido por el Servicio de Administración
									Tributaria (SAT) y tu identificación oficial vigente.
								</label>
								<CustomAlert
									message={alert.message}
									severity={alert.severity}
									show={alert.show}
								/>
							</div>
							<div
								style={{
									width: matches ? '100%' : '540px',
									margin: '30px auto 20px',
									display: matches ? 'block' : 'flex',
									justifyContent: 'space-between',
								}}
							>
								<CustomButton
									label="Cancelar"
									onClick={() => {
										getStatus()
									}}
									variant="outlined"
									styles={{
										width: 260,
										height: 40,
										margin: '10px auto',
									}}
								/>
								<CustomButton
									label="Adjuntar documento"
									onClick={() => setPageAttach(true)}
									variant="solid"
									disabled={alert.show}
									styles={{
										width: 260,
										height: 40,
										margin: '10px auto',
									}}
								/>
							</div>
						</div>
					)}
					{pageAttach && (
						<div
							id="gotPayslip"
							style={{ display: 'inline' }}
						>
							<div className={classes.form}>
								<Grid
									container
									columns={12}
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<Grid
										item
										xs={6}
										sm={6}
										md={6}
										lg={6}
										style={{ textAlign: 'left' }}
									>
										<b style={{
											color: '#293990',
										}}>Adjuntar documentos</b>
									</Grid>
								</Grid>
								<hr
									style={{
										opacity: 0.2,
										marginTop: 20,
										marginBottom: 20,
									}}
								/>

								<Grid
									container
									columns={12}
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<Grid
										item
										xs={6}
										sm={6}
										md={6}
										lg={6}
										style={{ textAlign: 'left' }}
									>
										<b>Número de caso</b>
									</Grid>
									<Grid
										item
										xs={6}
										sm={6}
										md={6}
										lg={6}
										style={{ textAlign: 'right' }}
									>
										{dataStatus.noCaso}
									</Grid>
								</Grid>
								<hr
									style={{
										opacity: 0.2,
										marginTop: 20,
										marginBottom: 20,
									}}
								/>

								<div className={classes.containerFiles}>
									<div className={classes.containerFilesOne}>
										<h4 style={{ marginTop: 0 }}>
											Comprobante del RFC emitido por el SAT
										</h4>
									</div>
									<div className={classes.containerFilesTwo}>
										<p className={classes.textFile}>{document.nombre}</p>
									</div>
									<div className={classes.containerFilesThree}>
										{!document.archivo && (
											<>
												<label
													style={{
														cursor: 'pointer',
														color: 'red',
													}}
												>
													<input
														type="file"
														accept=".pdf"
														name="file"
														onChange={handleDocument}
														style={{ display: 'none' }}
													/>
													Elegir archivo
												</label>
											</>
										)}

										{document.archivo && (
											<div
												style={{
													display: 'flex',
													justifyContent: matches ? 'center' : 'right',
												}}
											>
												<div
													style={{
														display: 'flex',
														justifyContent: 'right',
														marginLeft: 10,
													}}
												>
													<label
														style={{
															cursor: 'pointer',
															color: 'red',
														}}
														onClick={() =>
															setDocument({
																nombre: 'No se eligió ningún archivo',
																archivo: null,
															})
														}
													>
														<ClearIcon
															style={{
																fontSize: 16,
																color: 'red',
															}}
														/>{' '}
														Eliminar archivo
													</label>
												</div>
												<div
													style={{
														display: 'flex',
														justifyContent: 'center',
														marginLeft: 10,
													}}
												>
													<label
														style={{
															cursor: 'pointer',
															color: 'red',
														}}
														onClick={() => setModalView(document.archivo)}
													>
														<SearchIcon
															style={{
																fontSize: 16,
																color: 'red',
															}}
														/>{' '}
														Ver archivo
													</label>
												</div>
											</div>
										)}
									</div>
								</div>
								<hr
									style={{
										opacity: 0.2,
										marginTop: 20,
										marginBottom: 20,
									}}
								/>

								<div className={classes.containerFiles}>
									<div className={classes.containerFilesOne}>
										<h4 style={{ marginTop: 0 }}>
											Identificación oficial vigente
											<span style={{ color: 'red' }}>*</span>
										</h4>
										<p
											className={classes.p}
											style={{ color: 'black', margin: '-10px 0 20px' }}
										>
											Adjunta solo 1 documento de la lista:
										</p>
										<p className={classes.p}>INE / IFE del titular</p>
										<p className={classes.p}>Pasaporte del titular</p>
										<p className={classes.p}>Cartilla militar del titular</p>
										<p className={classes.p}>Cédula profesional del titular</p>
										<p className={classes.p}>Tarjeta de Residencia temporal</p>
										<p className={classes.p}>Tarjeta de Residencia permanente</p>
									</div>
									<div className={classes.containerFilesTwo}>
										<p className={classes.textFile}>{file.nombre}</p>
									</div>
									<div className={classes.containerFilesThree}>
										{!file.archivo && (
											<>
												<label
													style={{
														cursor: 'pointer',
														color: 'red',
													}}
												>
													<input
														type="file"
														accept=".pdf"
														name="archivo"
														onChange={handleFile}
														style={{ display: 'none' }}
													/>
													Elegir archivo
												</label>
											</>
										)}

										{file.archivo && (
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: matches ? 'center' : 'right',
												}}
											>
												<div
													style={{
														display: 'flex',
														justifyContent: 'right',
														marginLeft: 10,
													}}
												>
													<label
														style={{
															cursor: 'pointer',
															color: 'red',
														}}
														onClick={() =>
															setFile({
																nombre: 'No se eligió ningún archivo',
																archivo: null,
															})
														}
													>
														<ClearIcon
															style={{
																fontSize: 16,
																color: 'red',
															}}
														/>{' '}
														Eliminar archivo
													</label>
												</div>
												<div
													style={{
														display: 'flex',
														justifyContent: 'center',
														marginLeft: 10,
													}}
												>
													<label
														style={{
															cursor: 'pointer',
															color: 'red',
														}}
														onClick={() => setModalView(file.archivo)}
													>
														<SearchIcon
															style={{
																fontSize: 16,
																color: 'red',
															}}
														/>{' '}
														Ver archivo
													</label>
												</div>
											</div>
										)}
									</div>
								</div>
								<hr
									style={{
										opacity: 0.2,
										marginTop: 20,
										marginBottom: 20,
									}}
								/>

								<div className={classes.message}>
									<span>
										Antes de enviar tus documentos verifica que estén
										correctos. <br /> Los formatos permitidos son jpg, png,
										tiff, pdf, mpeg, avi, wmv, mp4 o mp3 con un tamaño máximo de 20 MB por todos los
										documentos adjuntos.
									</span>
								</div>

								<div className={classes.buttons}>
									<CustomButton
										onClick={() => {
											setFile({
												nombre: 'No se eligió ningún archivo',
												archivo: null,
											})
											setDocument({
												nombre: 'No se eligió ningún archivo',
												archivo: null,
											})
											setPageAttach(false);
										}}
										variant="outlined"
										styles={{
											width: 260,
											margin: matches ? '10px auto' : 10,
										}}
										label="Regresar"
									/>

									<CustomButton
										disabled={(file.archivo && document.archivo ? false : true) || alert.show}
										onClick={() => {
											setViewHeader(false)
											handleSubmit();
										}}
										variant="solid"
										styles={{
											width: 260,
											margin: matches ? '10px auto' : 10,
										}}
										label="Enviar documentos"
									/>
								</div>

								<CustomAlert
									data-testid="mensageAlertthree"
									message={alert.message}
									severity={alert.severity}
									show={alert.show}
								/>
							</div>
						</div>
					)}
					{pageAttach && (
						<div>
							<hr className={classes.hr} style={{}} />
							<h3
								className={classes.h4}
								style={{
									paddingBottom: 30,
									width: '100%',
									marginTop: 15,
									marginLeft: 15,
									fontSize: 16
								}}
							>
								*Documentos obligatorios
							</h3>
						</div>
					)}
				</Box>
			</Paper>
		</>
	);
};

export default AttachDocument;