/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Divider, Link, Paper } from '@mui/material';
// COMPONENTS
import CustomButton from '../../../../components/Button';
// RESOURCES
import { formatDate } from '../../../../utils/dates';
import { useStyles } from './confirmationStyles';
// ASSETS
import logoInfonavitRojo from '../../../../assets/img/logo-infonavit-rojo.png';
import logoMciColor2 from '../../../../assets/img/logo-mci-color-2.png';
import imaTema6 from '../../../../assets/img/ima-tema-6.png';
import { contactRequestAPI } from '../../../../api/modules/profile';
import ModalLoading from '../../../../components/ModalLoading';

interface IDataContractConfirmation {
	fullName: string;
	nss: string;
}

const ContractConfirmation = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		fullName: '',
		nss: '',
	} as IDataContractConfirmation);
	let navigate = useNavigate();
	const classes = useStyles();
	const [searchParams] = useSearchParams();
	let key = searchParams.get('key');

	const validate = useCallback(async () => {
		if (key) {
			setLoading(true);
			contactRequestAPI.validateEmail
				.post<{ key: string }, any>('', { key })
				.then((res: any) => {
					if (res?.fullName && res?.nss) {
						setData({
							fullName: res.fullName,
							nss: res.nss,
						});
					}
				})
				.catch()
				.finally(() => setLoading(false));
		}
	}, [key]);

	useEffect(() => {
		validate();
	}, [validate]);

	return (
		<>
			<ModalLoading loading={loading} />

			<div className={classes.container}>
				<div className={classes.infoHeader}>
					<small>
						Para asegurar la entrega de los comunicados del Infonavit, por
						favor agréganos a tu libreta de contactos. <br /> Si no puede ver
						correctamente este correo <Link>entra aquí</Link>
					</small>
				</div>
				<Paper className={classes.paperContainer}>
					<div className={classes.imageContainer}>
						<img
							src={logoInfonavitRojo}
							alt="logo-infonavit-rojo"
							className={classes.imagenInfonavit}
						/>
						<Divider orientation="vertical" flexItem />
						<img
							src={logoMciColor2}
							alt="logo-mci"
							className={classes.imagenMCI}
						/>
					</div>
					{formatDate('dd - MMM - yyyy / hh:MM a')}
					<div className={classes.contactInfoContainer}>
						<p>
							Nombre: <b>{data.fullName}</b>
						</p>
						<p>
							Numero de Seguro social (NSS): <b>{data.nss}</b>
						</p>
					</div>
					<div>
						<img src={imaTema6} alt="alt" width="40%" />
					</div>
					<b>Tu correo electrónico ha sido actualizado con éxito</b>
					<CustomButton
						onClick={() => navigate('/')}
						variant="solid"
						styles={{}}
						label="Entra a Mi Cuenta Infonavit"
						disabled={false}
					/>
					<b>
						Si tú no solicitaste el cambio de correo, llama a Infonatel para
						hacer la aclaración
					</b>
					<small>
						Marca al 55 9171 5050 en la Ciudad de México, o al 800 008 3900
						desde cualquier parte del país, de lunes a viernes de 7:30 de la
						mañana a 9:00 de la noche; sábado, domingo y días festivos de 9:00
						de la mañana a 3:00 de la tarde.
						<br />
						<br />
						<strong>En el infonavit todos los trámites son gratuitos.</strong>
					</small>
				</Paper>
				<div className={classes.footerContainer}>
					<strong>Aviso de privacidad: </strong>Para darte un mejor servicio
					reunimos tus datos sencibles y personales y contamos con medidas de
					seguridad que los protegen.
					<br />
					Si tienes dudas consulta nuestro{' '}
					<a href="#">
						Aviso de privacidad y la Politíca de privacidad
					</a> en{' '}
					<a
						target="_blank"
						href="https://www.infonavit.org.mx"
						rel="noopener noreferrer"
					>
						www.infonavit.org.mx
					</a>
					<br />
					<br />
					{/* Este correo fue enviado a @CORREO@ si deseas cancelar tu subscripción,{' '}
				<a href="#">entra aquí</a> */}
				</div>
			</div>
		</>
	);
};

export default ContractConfirmation;
