import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			maxWidth: '900px',
			margin: '0 auto'
		},
		pHeader: {
			[theme.breakpoints.down('sm')]: {
				paddingTop: 20,
				marginLeft: 20,
				marginRight: 20,
			},
			[theme.breakpoints.down('xl')]: {
				paddingTop: 20,
				marginLeft: 20,
			},
		},
		link1: {
			[theme.breakpoints.down('sm')]: {
				textDecoration: 'underline #D1001F',
				color: '#D1001F',
			},
			[theme.breakpoints.down('xl')]: {
				textDecoration: 'underline #D1001F',
				color: '#D1001F',
			},
		},
		parrafo1: {
			textAlign: 'left',
			paddingLeft: 40,
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 20
			},
		},
		list: {
			[theme.breakpoints.up('xs')]: {
				paddingLeft: 20,
				paddingRight: 0,
			},
			[theme.breakpoints.up('md')]: {
				paddingLeft: 70,
			},
			[theme.breakpoints.up('sm')]: {
				paddingLeft: 40,
			},
		},
		title: {
			[theme.breakpoints.up('xs')]: {
				fontSize: 20,
				fontWeight: 'bold',
				paddingBottom: 20,
				paddingTop: 55,
			},
			[theme.breakpoints.up('sm')]: {
				fontSize: 22,
				fontWeight: 'bold',
				paddingBottom: 20,
				paddingTop: 55,
			},
		},
		pText: {
			[theme.breakpoints.up('xs')]: {
				fontSize: 16,
				width: '80%',
				marginLeft: 37,
				paddingBottom: 50,
			},
			[theme.breakpoints.up('sm')]: {
				fontSize: 18,
				width: '80%',
				marginLeft: 80,
				paddingBottom: 50,
			},
			[theme.breakpoints.up('md')]: {
				fontSize: 18,
				width: '80%',
				marginLeft: 120,
				paddingBottom: 50,
			},
		},
	})
);
