import { useState, useEffect } from 'react';
import {
	Paper,
	Radio,
	Divider,
	RadioGroup,
	FormControlLabel,
} from '@mui/material';
import { useStyles } from './styles';
import { defaultLetter } from '../utils';
import CustomButton from '../../../components/Button';
import publicImg from '../../../assets/img/Grupo_14739.png';
import privateImg from '../../../assets/img/Grupo_14667.png';
import { HeaderList } from '../../../components/HeaderList';

interface Props {
	setPage: (data: number) => void;
	setResp: (data: string) => void;
	escrituras_pub_priv: {
		escrituraPublica: boolean;
		escrituraPrivada: boolean;
	};
}

export const WrittingView = ({
	setPage,
	setResp,
	escrituras_pub_priv,
}: Props) => {
	const [form, setForm] = useState(defaultLetter);
	const [buttonValidate, setButtonValidate] = useState(true);
	const classes = useStyles();

	useEffect(() => {
		if (form.tipo !== '') {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	}, [form.tipo]);

	const handleChange = ({ target }: any) => {
		const { name, value } = target;
		setForm({ ...form, [name]: value });
	};

	return (
		<div>
			<HeaderList
				title="Carta de instrucción de cancelación de hipoteca"
				date="Información al 12 julio 2021"
				list={[
					'Esta carta debes entregarla al notario que elegiste para que lleve a cabo la liberación de tu hipoteca y quede registrada en el Registro Público de la Propiedad.',
					'La vigencia de la carta de cancelación es de 180 días naturales, si en ese tiempo no la entregas al notario (solo notario), deberás iniciar el trámite nuevamente.',
				]}
			/>
			<Paper className={classes.divPrincipal}>
				<p>
					<b>Elige el tipo de escritura que tienes:</b>
				</p>
				<RadioGroup
					aria-labelledby="demo-radio-buttons-group-label"
					name="radio-buttons-group"
					sx={{ marginLeft: '30px', marginTop: '10px' }}
				>
					{escrituras_pub_priv.escrituraPrivada && (
						<FormControlLabel
							value="privado"
							control={
								<Radio
									name="privado"
									onChange={(e: any) =>
										handleChange({
											target: {
												name: 'tipo',
												value: e.target.value,
											},
										})
									}
								/>
							}
							label="Instrumento privado"
						/>
					)}

					{escrituras_pub_priv.escrituraPublica && (
						<FormControlLabel
							value="publica"
							control={
								<Radio
									name="publica"
									onChange={(e: any) =>
										handleChange({
											target: {
												name: 'tipo',
												value: e.target.value,
											},
										})
									}
								/>
							}
							label="Escritura pública"
						/>
					)}
				</RadioGroup>
				{form.tipo === 'privado' && (
					<>
						<Divider></Divider>
						<br></br>
						<div className={classes.divDocType}>
							<span>
								<b>
									¿Cómo identificar el tipo de documento que
									tienes?
								</b>
							</span>
						</div>
						<br></br>
						<div className={classes.divDocType}>
							<div className={classes.divImg}>
								<img
									className={classes.imgs}
									alt="Instrumento privado"
									src={privateImg}
								></img>
							</div>
							<div className={classes.divTxt}>
								<span>
									En el Instrumento privado, el sello de
									Infonavit y el número de instrumento lo
									encuentras en la primera hoja.
								</span>
							</div>
						</div>
					</>
				)}
				{form.tipo === '' && <div style={{ height: 350 }} />}
				{form.tipo === 'publica' && (
					<>
						<Divider></Divider>
						<br></br>
						<div className={classes.divDocType}>
							<span>
								<b>
									¿Cómo identificar el tipo de documento que
									tienes?
								</b>
							</span>
						</div>
						<br></br>
						<div className={classes.divDocType}>
							<div className={classes.divImg}>
								<img
									className={classes.imgs}
									alt="Escritura pública"
									src={publicImg}
								></img>
							</div>
							<div className={classes.divTxt}>
								<span>
									En la Escritura pública, el libro, fecha de
									originación, nombre, número de escritura y
									Estado de notaría, se encuentran en la
									primera hoja.
								</span>
							</div>
						</div>
					</>
				)}
				<div className={classes.contentFileDataButtons}>
					<CustomButton
						onClick={() => {
							setPage(0);
						}}
						variant="outlined"
						styles={{
							width: 200,
							marginInline: 10,
						}}
						label="Regresar"
					/>
					<CustomButton
						onClick={() => {
							setPage(2);
							setResp(form.tipo);
						}}
						disabled={buttonValidate}
						variant="solid"
						styles={{
							width: 200,
							marginInline: 10,
						}}
						label="Continuar"
					/>
				</div>
			</Paper>
		</div>
	);
};

export default WrittingView;
