import { useState, useEffect, useCallback } from 'react';
import { Paper, Box, Grid, useMediaQuery, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary } from './UseStyles';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import TextInput from '../../components/TextInput';
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import { formatDate } from '../../utils/dates';
import ThanksDocument from './ThanksDocument';
import CustomAlert from '../../components/CustomAlert';

import { casesRequestAPI } from '../../api/modules/FollowCases';

const headerList = [
	'En este servicio puedes consultar los casos que tengan menos de seis meses de haber sido atendidos. Si ya rebasó ese tiempo, llama a Infonatel.',
	'Si empezaste un trámite en algunos de los canales de servicio del Infonavit, podrás darle seguimiento al estatus de tu caso en esta sección.',
	'Cuando tu trámite requiera que adjuntes documentos, es muy importante que los envíes en el transcurso de 5 días hábiles siguientes a que recibas tu número de caso, ya que de lo contrario tu caso se cerrará y deberás iniciar de nuevo tu trámite.',
];

const headerListLink = [
	['Si quieres levantar una queja o hacer una denuncia tienes que ir al servicio'],
	['Presenta una queja o haz una denuncia'],
	[', donde también puedes adjuntar los documentos que te solicitan.'],
	['/contactanos/presenta-sigue-queja'],
	[99]
];

const FollowAttachDocument = ({ setPage, setData, getData }: any) => {
	const [cases, setCases] = useState([]);
	const [expanded, setExpanded] = useState('');
	const [search, setSearch] = useState('');
	const theme = useTheme();
	const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
	const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));
	const [dataActive, setDataActive] = useState({});
	const [showDetail, setShowDetail] = useState(0);
	const [alert, setAlert] = useState<any>({ message: '', color: '', show: false });

	const handlChange = (tabs: string) => {
		setExpanded(expanded !== tabs ? tabs : '');
	};

	const handlSearch = (e: any) => {
		setSearch(e.target.value.replace(/[^0-9]/g, ''));
	};

	const setCasesToSort = (data: any) => {
		const resultSort = data.sort(function (a: any, b: any) {
			if (a.caso > b.caso) {
				return 1;
			}
			if (a.caso < b.caso) {
				return -1;
			}
			return 0;
		});
		setCases(resultSort);
	};

	const getCases = useCallback(async () => {
		try {
			casesRequestAPI.getCases
				.post<any, any>('')
				.then((res: any) => {
					if (res?.result?.listCasos) {
						setCasesToSort(res.result.listCasos);
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message:
							err && err.description
								? err.description
								: INTERNAL_MESSAGES.ERROR_MESSAGE,
						color: 'error',
					});
				});
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'error',
			});
		}
	}, []);

	useEffect(() => {
		getCases();
	}, [getCases]);

	useEffect(() => {
		if (getData) {
			getCases();
		}
	}, [getCases, getData]);

	return (
		<>
			{showDetail === 0 && (
				<>
					<HeaderList
						title="Sigue tu caso y adjunta documentos"
						list={headerList}
						listLink={headerListLink}
						date={formatDate('PP')}
					/>
					<Paper style={{ padding: !matchesXs ? '35px 30px' : '35px 20px' }}>
						<div style={{ width: '100%', marginBottom: 30 }}>
							<TextInput
								id="search"
								name="search"
								value={search}
								label="Buscar por número de caso"
								placeholder="Buscar..."
								onChange={handlSearch}
							/>
						</div>
						<Box style={{ margin: '40px 0px' }}>
							<Grid
								container
								columns={12}
								style={{
									paddingLeft: !matchesXs ? 20 : 5,
									paddingRight: !matchesXs ? 20 : 5,
									marginBottom: 30,
								}}
							>
								<Grid item xs={3} sm={2} md={3} lg={3}>
									<Grid container columns={12}>
										<Grid item xs={12} sm={12} md={6} lg={6}>
											<span style={{ fontWeight: 500 }}>
												No. Caso
											</span>
										</Grid>
										{!matchesMd && (
											<Grid item xs={12} sm={12} md={6} lg={6}>
												<span style={{ fontWeight: 500 }}>
													Fecha de solicitud
												</span>
											</Grid>
										)}
									</Grid>
								</Grid>

								<Grid item xs={6} sm={8} md={6} lg={6}>
									<Grid container columns={12}>
										{!matchesXs && (
											<Grid item xs={6} sm={6} md={6} lg={6} pl={2}>
												<span style={{ fontWeight: 500 }}>
													Servicio
												</span>
											</Grid>
										)}
										<Grid item xs={6} sm={6} md={6} lg={6} pl={2}>
											<span
												style={{
													fontWeight: 500,
													paddingLeft: '14px',
												}}
											>
												Estatus
											</span>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={3} sm={2} md={3} lg={3}>
									<Grid container columns={12}>
										{!matchesMd && (
											<Grid item xs={6} sm={6} md={6} lg={6}>
												<span style={{ fontWeight: 500 }}>
													Fecha de estatus
												</span>
											</Grid>
										)}
										<Grid item xs={12} sm={12} md={6} lg={6}>
											<span style={{ fontWeight: 500 }}>
												Acciones
											</span>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							{cases
								.filter(
									(elemento: any) => elemento.caso.indexOf(search) > -1
								)
								.map((dato: any, index) => (
									<Accordion
										key={index}
										expanded={expanded === dato.caso}
										onChange={() => {
											if (
												dato.textoStatus !== 'Cerrado' &&
												dato.textoStatus !== 'Aclarado'
											) {
												handlChange(dato.caso);
											} else {
												setDataActive(dato);
												setShowDetail(1);
											}
										}}
									>
										<AccordionSummary
											aria-controls="panel1d-content"
											id="panel1d-header"
										>
											<Grid container columns={12}>
												<Grid item xs={3} sm={2} md={3} lg={3}>
													<Grid container columns={12}>
														<Grid
															item
															xs={12}
															sm={12}
															md={6}
															lg={6}
														>
															{dato.caso}
														</Grid>
														{!matchesMd && (
															<Grid
																item
																xs={6}
																sm={6}
																md={6}
																lg={6}
															>
																{dato.fechaCreacion.substr(
																	6,
																	2
																)}
																/
																{dato.fechaCreacion.substr(
																	4,
																	2
																)}
																/
																{dato.fechaCreacion.substr(
																	0,
																	4
																)}
															</Grid>
														)}
													</Grid>
												</Grid>

												<Grid item xs={6} sm={8} md={6} lg={6}>
													<Grid container columns={12}>
														{!matchesXs && (
															<Grid
																item
																xs={6}
																sm={6}
																md={6}
																lg={6}
																style={{
																	whiteSpace: 'nowrap',
																	overflow: 'hidden',
																	textOverflow:
																		'ellipsis',
																}}
															>
																{dato.tipificacion}
															</Grid>
														)}
														<Grid
															item
															xs={12}
															sm={6}
															md={6}
															lg={6}
														>
															<div
																style={{
																	whiteSpace: 'nowrap',
																	overflow: 'hidden',
																	textOverflow:
																		'ellipsis',
																	paddingLeft: '14px',
																	textAlign: 'initial',
																}}
															>
																<span
																	style={{
																		background:
																			dato.textoStatus ===
																				'Cerrado'
																				? '#CBFFE0'
																				: '#FFF4DE',
																		width: 10,
																		height: 10,
																		borderRadius:
																			'50%',
																		display:
																			'inline-block',
																		marginRight: 5,
																	}}
																/>
																<span
																	style={{
																		fontWeight: 500,
																		color:
																			dato.textoStatus ===
																				'Cerrado'
																				? '#00C951'
																				: '#FFA800',
																	}}
																>
																	{dato.textoStatus}
																</span>
															</div>
														</Grid>
													</Grid>
												</Grid>

												<Grid item xs={3} sm={2} md={3} lg={3}>
													<Grid container columns={12}>
														{!matchesMd && (
															<Grid
																item
																xs={6}
																sm={6}
																md={6}
																lg={6}
															>
																{dato.fechaModificacion.substr(
																	6,
																	2
																)}
																/
																{dato.fechaModificacion.substr(
																	4,
																	2
																)}
																/
																{dato.fechaModificacion.substr(
																	0,
																	4
																)}
															</Grid>
														)}
														<Grid
															item
															xs={12}
															sm={12}
															md={6}
															lg={6}
															style={{
																textAlign: 'initial',
															}}
														>
															<Link
																href="#view"
																underline="none"
															>
																{expanded === dato.caso
																	? 'Ocultar detalles'
																	: 'Ver detalles'}
															</Link>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</AccordionSummary>
										<AccordionDetails
											style={{
												backgroundColor: 'rgba(0, 0, 0, .03)',
												borderTop: 'none',
											}}
										>
											<Grid container spacing={2} columns={12}>
												{matchesXs && (
													<Grid item xs={4} sm={4} md={2}>
														<span style={{ fontWeight: 500 }}>
															Servicio
														</span>
													</Grid>
												)}
												{matchesXs && (
													<Grid item xs={8} sm={8} md={10}>
														<div
															style={{
																paddingLeft: '45px',
															}}
														>
															{dato.tipificacion}
														</div>
													</Grid>
												)}
												{matchesMd && (
													<Grid item xs={4} sm={4} md={2}>
														<span style={{ fontWeight: 500 }}>
															Fecha de solicitud
														</span>
													</Grid>
												)}
												{matchesMd && (
													<Grid item xs={8} sm={8} md={10}>
														<div
															style={{
																paddingLeft: '45px',
															}}
														>
															{dato.fechaCreacion.substr(
																6,
																2
															)}
															/
															{dato.fechaCreacion.substr(
																4,
																2
															)}
															/
															{dato.fechaCreacion.substr(
																0,
																4
															)}
														</div>
													</Grid>
												)}
												{matchesMd && (
													<Grid item xs={4} sm={4} md={2}>
														<span style={{ fontWeight: 500 }}>
															Fecha de estatus
														</span>
													</Grid>
												)}
												{matchesMd && (
													<Grid item xs={8} sm={8} md={10}>
														<div
															style={{
																paddingLeft: '45px',
															}}
														>
															{dato.fechaModificacion.substr(
																6,
																2
															)}
															/
															{dato.fechaModificacion.substr(
																4,
																2
															)}
															/
															{dato.fechaModificacion.substr(
																0,
																4
															)}
														</div>
													</Grid>
												)}
												<Grid item xs={4} sm={4} md={2}>
													<span style={{ fontWeight: 500 }}>
														Descripción
													</span>
												</Grid>
												<Grid item xs={8} sm={8} md={10}>
													{(dato.textoStatus ===
														'En Espera de Documentación' ||
														dato.textoStatus ===
														'En espera de documentación' ||
														dato.textoStatus ===
														'En espera de documentos' ||
														dato.textoStatus ===
														'En Espera de Documentos' ||
														dato.textoStatus ===
														'Adjuntar Documentación' ||
														dato.textoStatus ===
														'Reenvío de documentos' ||
														dato.textoStatus ===
														'Reenvío de Documentos' ||
														dato.textoStatus ===
														'Reenvio de documentos' ||
														dato.textoStatus ===
														'Reenvio de Documentos' ||
														dato.textoStatus ===
														'En proceso de atención' ||
														dato.textoStatus ===
														'En proceso de atencion' ||
														dato.textoStatus ===
														'Integrador') && (
															<div
																style={{
																	paddingLeft: '45px',
																}}
															>
																Tienes 5 días hábiles para
																adjuntar tus documentos, de lo
																contrario tu caso se cerrará y
																deberás iniciar de
																<br />
																nuevo tu trámite.
															</div>
														)}
													{dato.textoStatus !==
														'En Espera de Documentación' &&
														dato.textoStatus !==
														'En espera de documentación' &&
														dato.textoStatus !==
														'En espera de documentos' &&
														dato.textoStatus !==
														'En Espera de Documentos' &&
														dato.textoStatus !==
														'Adjuntar Documentación' &&
														dato.textoStatus !==
														'Reenvío de documentos' &&
														dato.textoStatus !==
														'Reenvío de Documentos' &&
														dato.textoStatus !==
														'Reenvio de documentos' &&
														dato.textoStatus !==
														'Reenvio de Documentos' &&
														dato.textoStatus !==
														'En proceso de atención' &&
														dato.textoStatus !==
														'En proceso de atencion' &&
														dato.textoStatus !==
														'Integrador' && (
															<div
																style={{
																	paddingLeft: '45px',
																}}
															>
																{dato.tipificacion}
															</div>
														)}
												</Grid>
												{(dato.textoStatus ===
													'En Espera de Documentación' ||
													dato.textoStatus ===
													'En espera de documentación' ||
													dato.textoStatus ===
													'En espera de documentos' ||
													dato.textoStatus ===
													'En Espera de Documentos' ||
													dato.textoStatus ===
													'Adjuntar Documentación' ||
													dato.textoStatus ===
													'Reenvío de documentos' ||
													dato.textoStatus ===
													'Reenvío de Documentos' ||
													dato.textoStatus ===
													'Reenvio de documentos' ||
													dato.textoStatus ===
													'Reenvio de Documentos' ||
													dato.textoStatus ===
													'En proceso de atención' ||
													dato.textoStatus ===
													'En proceso de atencion' ||
													dato.textoStatus ===
													'Integrador') && (
														<Grid
															order={12}
															item
															xs={12}
															md={12}
															style={{ margin: '10px 0px' }}
														>
															<div
																style={{
																	display: 'flex',
																	width: 260,
																	margin: 'auto',
																}}
															>
																<CustomButton
																	variant="solid"
																	label="Adjuntar documentos"
																	onClick={() => {
																		setPage(1);
																		setData(dato);
																	}}
																/>
															</div>
														</Grid>
													)}
											</Grid>
										</AccordionDetails>
									</Accordion>
								))}
						</Box>
						<Grid item xs={12} md={12}>
							<CustomAlert
								message={alert.message}
								severity={'error'}
								show={alert.show}
							/>
						</Grid>
						{cases.length === 0 && (
							<div style={{ width: '100%', textAlign: 'center' }}>
								<p>
									Aún no tienes casos registrados para el seguimiento.
								</p>
							</div>
						)}
					</Paper>
				</>
			)}
			{showDetail === 1 && (
				<ThanksDocument data={dataActive} setShowDetail={setShowDetail} />
			)}
		</>
	);
};

export default FollowAttachDocument;
