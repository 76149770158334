import { HeaderTooltip } from '../../../../components/HeaderTooltip/headerTooltip';
import { IKeyRow, formatTypesRow } from '../../../../components/ProductsMiuc/interfaces/product.interface';

export const numberFormat = new Intl.NumberFormat('es-MX', {
	maximumFractionDigits: 2,
	minimumFractionDigits: 2,
});

export const arrayPlazos = (longitud?: number): any[] => {
	const numeros: any[] = [];
	if (!longitud || longitud < 5) {
		return [{ value: '5', label: '5 años' }];
	}
	for (let i = 5; i <= (longitud || 5); i++) {
		numeros.push({ value: String(i), label: String(i) + ' años' });
	}
	return numeros;
};

export const arrayPlazosSuelo = (longitud?: number): any[] => {
	const numeros: any[] = [];
	if (!longitud || longitud < 15) {
		return [{ value: '15', label: '15 años' }];
	}
	for (let i = 15; i <= (longitud || 15); i++) {
		numeros.push({ value: String(i), label: String(i) + ' años' });
	}
	return numeros;
};

export const colCrediterreno: IKeyRow[] = [
	{
		title: (
			<HeaderTooltip
				title="Retención mesual:"
				tooltip="Cantidad que tu patrón descontara vía nómina para el pago de tu crédito. En Cofinavit, si pierdes tu relación laboral, deberás pagar este monto más la aportación patronal (5% de tu salario)."
			/>
		),
		key: 'desTot',
		format: { type: formatTypesRow.MONEY },
		textPrimary: true,
	},
	{
		title: (
			<HeaderTooltip
				title="Monto de crédito:"
				tooltip="Cantidad que te presta el Infonavit."
			/>
		),
		key: 'mmcMon',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Más tu ahorro:"
				tooltip="Cantidad que tienes en la Subcuenta de Vivienda conformada por las aportaciones de tu patrón."
			/>
		),
		key: 'monAhoMax',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Más tu enganche:"
				tooltip="Cantidad que tienes en la Subcuenta de Vivienda conformada por las aportaciones de tu patrón."
			/>
		),
		key: 'enganche',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Menos gastos de titulación:"
				tooltip={
					"Estos gastos de titulación corresponden únicamente a honorarios notariales que serán descontados del monto de tu crédito. Para Crédito Infonavit, se determinan considerando el valor de avalúo. (En todos los casos se deberá de contemplar el IVA)."
				}
			/>
		),
		key: 'gasMon',
	},
	{
		title: (
			<HeaderTooltip
				title="Plazo:"
				tooltip="Periodo máximo en el que deberás pagar el crédito e intereses."
			/>
		),
		key: 'pzoCre',
		format: { type: formatTypesRow.YEAR },
	},
	{
		title: (
			<HeaderTooltip
				title="Contarías con:"
				tooltip="Cantidad que resulta de sumar el monto de crédito más tu ahorro restándole los gastos de titulación."
			/>
		),
		key: 'cdcMon',
		format: { type: formatTypesRow.MONEY },
		textShadow: true,
	},
];

export const colSueloContruccion: IKeyRow[] = [
	{
		title: (
			<HeaderTooltip
				title="Retención de nómina para pagar tu crédito:"
				tooltip="Cantidad que tu patrón descontará vía nómina para el pago de tu crédito."
			/>
		),
		key: 'desTot',
		format: { type: formatTypesRow.MONEY },
		textPrimary: true,
	},
	{
		title: (
			<HeaderTooltip
				title="Monto de crédito para compra de terreno:"
				tooltip="Cantidad que te presta el Infonavit para comprar un terreno."
			/>
		),
		key: 'mmcMonSuelo',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Monto de crédito para construcción:"
				tooltip="Cantidad que te presta el Infonavit para construir tu vivienda."
			/>
		),
		key: 'montoConstruccion',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Más tu ahorro:"
				tooltip="Cantidad que tienes en la Subcuenta de Vivienda conformada por las aportaciones de tu patrón."
			/>
		),
		key: 'monAhoMax',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Más tu enganche:"
				tooltip="Es el monto de los recursos propios con los que cuentas para la compra de tu terreno, este será uno de los factores que determinarán las condiciones financieras."
			/>
		),
		key: 'enganche',
		format: { type: formatTypesRow.MONEY },
	},
	{
		title: (
			<HeaderTooltip
				title="Menos gastos:"
				tooltip="Estos gastos de titulación corresponden únicamente a honorarios notariales que serán descontados del monto de tu crédito. Para Crédito Infonavit, se determinan considerando el valor de avalúo. (En todos los casos se deberá de contemplar el IVA)."
			/>
		),
		key: 'gasMon',
	},
	{
		title: (
			<HeaderTooltip
				title="Plazo:"
				tooltip="Periodo máximo en el que deberás pagar el crédito e intereses."
			/>
		),
		key: 'pzoCre',
		format: { type: formatTypesRow.YEAR },
	},
	{
		title: (
			<HeaderTooltip
				title="Contarías con:"
				tooltip="Cantidad que resulta de sumar el monto de crédito más tu ahorro, restándole los gastos."
			/>
		),
		key: 'cdcMon',
		format: { type: formatTypesRow.MONEY },
		textShadow: true,
	},
];
