import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useStyles } from '../styles';
import { useController } from '../../../hooks/useController';
import { useUserInfo } from '../../../hooks/useUserInfo';
import { useEffect, useState } from 'react';
import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { isEmpty } from '../../../../../utils/validators';
import { isNumberFloat } from '../../../../../utils/expressions';
import { ReactComponent as ATuMedida } from '../../../../../assets/img/iconsMIUC/aTuMedida.svg';
import TooltipCustom from '../../../components/TooltipCustom';
import { useProductTableMIUC } from '../../../hooks/useProductTableMIUC';
import { apiMIUC } from '../../../../../api/modules/MIUC';
import { IProductMonto } from '../../../../../interfaces/creditRequest';
import { Box, Grid, InputAdornment } from '@mui/material';
import {
	creditTypes,
	getUMA3_001_MIUC,
} from '../../../../CreditRequest/MIUC/utils';
import { useCats } from '../../../hooks/useCats';
import { useAlertCustom } from '../../../hooks/useAlertCustom';
import { IKeyRow } from '../../../components/ProductsMiuc/interfaces/product.interface';
import { getRowTitleCompra } from '../../../utils/selectionResume.utils';
import TextInput from '../../../../../components/TextInput';
import { actions } from '../../../../../store/modules/loading';
import { STAGE_ID } from '../../../constants/stageId';
import { useTrazabilidad } from '../../../hooks/useTrazabilidad';
import { FormatMoney } from '../../../utils/tableFormat.utils';
import { CalculateLabel } from '../../../components/CalculateLabel/calculateLabel';
import { useGastosTitulacion } from '../../../hooks/useGastosTitulacion';
import { CODES_RESPONSE } from '../../../../../config/miucConstants';
import { useMontoPlazoFlexible } from '../../../hooks/useMontoPlazoFlexible';
import {
	IMontosSolicitados,
	PRODUCT_KEYS,
} from '../../../interfaces/montoPlazoFlexible';

export const useComprarViviendaTable = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { user } = useSelector((state: RootState) => state.session);

	const { getDataProductAmount, getDataCalculadora, isSearch, setIsSearch } =
		useProductTableMIUC();
	const {
		returnStep,
		addSelection,
		nextStep,
		getProgressStep,
		dataProcessController,
		saveData,
	} = useController();
	const { createTrazabilidad } = useTrazabilidad();

	const { conyuge } = useUserInfo();
	const { getAllCats } = useCats();
	const { setAlertClean, setAlertCustom, AlertInput, getAlertStatus } =
		useAlertCustom();
	const {
		setAlertClean: setAlertCleanInput,
		setAlertCustom: setAlertCustomInput,
		AlertInput: AlertInputCalc,
	} = useAlertCustom();

	const [productsOriginal, setProductsOriginal] = useState<any[]>([]);
	const [products, setProducts] = useState<any[]>([]);
	const [productsToCalculate, setProductsToCalculate] = useState<any[]>([]);
	const [productsTable, setProductsTable] = useState<any[]>([]);
	// const [productsCopy, setProductCopy] = useState<any[]>([]);
	const [Etc, setEtc] = useState({
		ETC: false,
		ETC80: false,
		ETC20: false,
	});
	// const [loading, setLoading] = useState(false);
	const [selectProduct, setSelectProduct] = useState('');
	const [rowsTitleCompra, setRowsTitleCompra] = useState<IKeyRow[]>([]);
	const [valorVivienda, setValorVivienda] = useState('');
	// const [rawProductsOriginal, setRawProductsOriginal] = useState<
	// 	IProductMonto[]
	// >([]);

	const [valueOptionEquipa, setValueOptionEquipa] = useState(false);
	const [valueOptionObra, setValueOptionObra] = useState(false);
	const [gastosTitulacion, setGastosTitulacion] = useState<number>(0);
	const [showGastosModal, setShowGastosModal] = useState(false);
	const { recalcularMontosTitulacion, enableCalcularGastos } =
		useGastosTitulacion();
	const {
		getInputMonto,
		loadProductData,
		disableSelectPlazo,
		onChangePlazo,
		disableInputMonto,
		getPlazos,
		disableAll,
		enableAll,
		disableCalculateBtn,
	} = useMontoPlazoFlexible();
	const [plazo, setPlazo] = useState<string>('');
	const [plazos, setPlazos] =
		useState<{ value: string | number; text: string }[]>();
	const [plazoCny, setPlazoCny] = useState<string>('');
	const [plazosCny, setPlazosCny] =
		useState<{ value: string | number; text: string }[]>();
	const [refreshProducts, setRefreshProducts] = useState<boolean>(false);

	const { active, total } = getProgressStep();
	const newStep = {
		title: 'Estas son las opciones de financiamiento que tenemos para ti',
		subtitle:
			'Revisa cada opción, compara entre ellas y elige la que más te convenga',
		active,
		total,
	};
	const disableButton = isEmpty(selectProduct);
	const radioOptionsEquipa = {
		Si: {
			label: 'Si',
			value: true,
		},
		No: {
			label: 'No',
			value: false,
		},
	};
	const radioOptionsObra = {
		Si: {
			label: 'Si',
			value: true,
		},
		No: {
			label: 'No',
			value: false,
		},
	};

	useEffect(() => {
		recalcularMontosTitulacion(gastosTitulacion, products, (newProducts) =>
			setProducts(newProducts)
		);
	}, [gastosTitulacion]);

	const getProducts = async (isCalculate?: boolean) => {
		setRefreshProducts(false);
		let productsFlag: IProductMonto[] = [];
		try {
			dispatch(actions.startLoading());
			// setLoading(true);
			setAlertClean();
			let montosMaximos: IMontosSolicitados = {
				montoTitular: ' ',
				montoConyuge: ' ',
			};
			if (isCalculate) {
				productsToCalculate.forEach((prod) => {
					if (
						Number(prod?.montoCreditoSolicitado) >
						Number(montosMaximos.montoTitular)
					) {
						montosMaximos = {
							...montosMaximos,
							montoTitular: prod.montoCreditoSolicitado,
						};
					}
					if (
						Number(prod?.montoCreditoSolicitadoCny) >
						Number(montosMaximos.montoConyuge)
					) {
						montosMaximos = {
							...montosMaximos,
							montoConyuge: prod.montoCreditoSolicitadoCny,
						};
					}
				});
			}
			const data = getDataProductAmount({
				valorTerreno: valorVivienda || '',
				plazoPart1: isCalculate && disableInputMonto ? plazo : '',
				plazoPart2: isCalculate && disableInputMonto ? plazoCny : '',
				...montosMaximos,
			});
			const ENCODED_DATA = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const response = await apiMIUC.consultarProductosMontos(
				ENCODED_DATA
			);
			if (response.code !== CODES_RESPONSE.CORRECTO) {
				setAlertCustomInput({
					show: true,
					message:
						response.message ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
					severity: 'error',
				});
				dispatch(actions.stopLoading());
				return;
			}
			if (dataProcessController?.titular?.esPrecaAnticipada) {
				productsFlag = response?.data?.productos.filter(
					(value) => value.producto === 'CI'
				);
			} else if (
				[
					STAGE_ID.FRAUDE_DEFUNCION,
					STAGE_ID.DEFUNCION,
					STAGE_ID.NO_AUTENTICA_BC,
					STAGE_ID.ENDEUDAMIENTO,
				].includes(dataProcessController?.titular?.stageId)
			) {
				productsFlag = response?.data?.productos.filter(
					(value) => value.producto !== 'CS' //&& value.producto !== 'IT'
				);
			} else {
				productsFlag = response?.data?.productos.filter(
					(value) => value.producto !== 'CS'
				);
			}

			const productsCatsAll: IProductMonto[] = await getAllCats(
				productsFlag,
				Etc.ETC
			);
			// setRawProductsOriginal([...productsCatsAll]);
			const productsWithSavings = productsCatsAll.map((product) => ({
				...product,
				ahorro: dataProcessController.titular.saldoSARTotal,
				ahorroCny: dataProcessController.conyuge?.saldoSARTotal || '0',
				totalContarias:
					Number(product.cdcMon) + Number(product.cdcMonCny) +
					(Number(
						dataProcessController?.titular?.saldoAportaciones
					) || 0),
			}));
			setProductsOriginal([...productsWithSavings]);
			setProductsToCalculate([...productsWithSavings]);
			let plazoMaximo = '';
			let plazoMaximoCny = '';
			const newProducts = productsWithSavings.map((product) => {
				const productos = {
					...product,
					diffCubrir: '0',
					montoETC: 0,
					montoETC80: 0,
					montoETC20: 0,
					cdcMon: product.cdcMon + (dataProcessController?.titular?.saldoAportaciones || 0),
				} as IProductMonto;
				if (Number(product.pzoCre) > Number(plazoMaximo))
					plazoMaximo = product.pzoCre;
				if (Number(product.pzoCreCny) > Number(plazoMaximoCny))
					plazoMaximoCny = product.pzoCreCny;
				return {
					...productos,
				};
			});
			recalcularMontosTitulacion(
				gastosTitulacion,
				newProducts,
				(newProducts) => setProducts(newProducts)
			);
			// setProductCopy([...newProducts]);
			// setProducts([...newProducts]);
			loadProductData({
				plazos: {
					plazo: plazoMaximo,
					plazoCny: plazoMaximoCny,
				},
				products: newProducts.map((p) => ({
					producto: p.producto,
					monto: p.mmcMon,
					montoConyuge: p?.mmcMonCny || '0',
				})),
			});
			setPlazos(getPlazos(Number(plazoMaximo)));
			setPlazo(plazoMaximo);
			if (conyuge) {
				setPlazosCny(getPlazos(Number(plazoMaximoCny)));
				setPlazoCny(plazoMaximoCny);
			}
			dispatch(actions.stopLoading());
			// setLoading(false);

			if (isCalculate) {
				handleCalculate(
					Etc.ETC
						? newProducts.filter(
								(product) => product.producto !== 'COF'
						  )
						: newProducts
				);
			}
		} catch (error: any) {
			dispatch(actions.stopLoading());
			// setLoading(false);
			// setProducts(productsFlag)
			setAlertCustom({
				show: true,
				message:
					error.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde. Get Products',
				severity: 'error',
			});
		}
	};

	useEffect(() => {
		getProducts();
	}, []);

	const handleChangeProductMonto = (
		key: string,
		value: string,
		product: string
	) => {
		const subKey =
			key === 'mmcMon'
				? 'montoCreditoSolicitado'
				: 'montoCreditoSolicitadoCny';

		setProducts(
			products.map((input) => {
				if (input.producto === product) {
					return {
						...input,
						[key]: value,
					};
				}
				return input;
			})
		);
		setProductsToCalculate(
			productsToCalculate.map((input) => {
				if (input.producto === product) {
					return {
						...input,
						[subKey]: value,
					};
				}
				return input;
			})
		);
	};

	const getRowComponent = (conyuge: boolean, product: any) => {
		return {
			mmcMon: getInputMonto({
				key: PRODUCT_KEYS.monto,
				product: product.producto,
				value:
					products?.find((v) => v.producto === product.producto)
						?.mmcMon || '',
				onChange: (value) =>
					handleChangeProductMonto('mmcMon', value, product.producto),
			}),
			mmcMonCny: getInputMonto({
				key: PRODUCT_KEYS.montoConyuge,
				product: product.producto,
				value:
					products?.find((v) => v.producto === product.producto)
						?.mmcMonCny || '',
				onChange: (value) =>
					handleChangeProductMonto(
						'mmcMonCny',
						value,
						product.producto
					),
			}),
		};
	};

	const formatProducts = () => {
		if (products) {
			const complementos = {
				tieneHipotecaVerde: products.some(
					(product: any) => product.tieneHipotecaVerde === true
				),
				tieneHogarATuMedida: products.some(
					(product: any) => product.tieneHogarATuMedida === true
				),
				tieneHipotecaConServicios: products.some(
					(product: any) => product.tieneHipotecaConServicios === true
				),
			};
			let newRowsTitleCompra = rowsTitleCompra;
			if (newRowsTitleCompra.length === 0) {
				newRowsTitleCompra = getRowTitleCompra(conyuge, complementos);
			} else {
				const idxMonETC = rowsTitleCompra.findIndex(
					(row) => row.key === 'montoETC'
				);
				const idxMonETC80 = rowsTitleCompra.findIndex(
					(row) => row.key === 'montoETC80'
				);
				const idxMonETC20 = rowsTitleCompra.findIndex(
					(row) => row.key === 'montoETC20'
				);
				newRowsTitleCompra[idxMonETC] = rowsTitleCompra[idxMonETC];
				newRowsTitleCompra[idxMonETC80] = rowsTitleCompra[idxMonETC80];
				newRowsTitleCompra[idxMonETC20] = rowsTitleCompra[idxMonETC20];
			}

			newRowsTitleCompra = newRowsTitleCompra.map((row) => {
				if (row.key === 'mmcMon')
					return {
						...row,
						format: undefined,
					};
				if (row.key === 'cdcMon' && conyuge)
					return {
						...row,
						key: 'totalContarias',
					};
				return row;
			});

			const complementosIdx = newRowsTitleCompra.findIndex(
				(obj) => obj.key === 'complementos'
			);
			if (
				products.some(
					(product: any) =>
						product.tieneHipotecaVerde ||
						product.tieneHogarATuMedida ||
						product.tieneHipotecaConServicios
				)
			) {
				newRowsTitleCompra[complementosIdx].hideRow = false;
			} else {
				newRowsTitleCompra[complementosIdx].hideRow = true;
			}

			const aportacionesIdx = rowsTitleCompra.findIndex(
				(obj) => obj.key === 'aportaciones'
			);
			if (aportacionesIdx !== -1) {
				if (Number(dataProcessController.titular.saldoAportaciones) > 0) {
					rowsTitleCompra[aportacionesIdx].hideRow = false;
				} else {
					rowsTitleCompra[aportacionesIdx].hideRow = true;
				}
			}

			setRowsTitleCompra(newRowsTitleCompra);
			setProductsTable(
				products.map((product: any) => ({
					...product,
					...getRowComponent(conyuge, product),
					gasMonCny: (
						<FormatMoney moneyToFormat={product.gasMonCny} />
					),
					gasMon:
						product.producto !== 'CI' ? (
							<FormatMoney moneyToFormat={product.gasMon} />
						) : (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								{
								(gastosTitulacion > 0 || product.gasMon > 0 || !enableCalcularGastos) && 
								<FormatMoney moneyToFormat={gastosTitulacion || product.gasMon} />
							}
								{enableCalcularGastos && (
									<CalculateLabel
										onClick={() => setShowGastosModal(true)}
									/>
								)}
							</Box>
						),
					complementos: (
						<>
							{product.tieneHogarATuMedida && (
								<Grid container>
									<Grid item xs={12}>
										<TooltipCustom
											arrow
											placement="top"
											title={<>Hogar a tu medida</>}
										>
											<ATuMedida
												color="primary"
												className={classes.infoStyle}
											/>
										</TooltipCustom>
									</Grid>
									<Grid item xs={12}>
										Hogar a<br />
										tu medida.
									</Grid>
								</Grid>
							)}
						</>
					),
					aportaciones:
						dataProcessController?.titular?.saldoAportaciones,
				}))
			);
		}
	};

	useEffect(() => {
		formatProducts();
	}, [products, isSearch, disableInputMonto]);

	const handleTrazabilidad = async () => {
		const selectedProduct = products.find(
			(prod: any) => prod.producto === selectProduct
		);
		createTrazabilidad({
			credit: dataProcessController.processInfo?.credit,
			subtipoTrazabilidad: selectedProduct?.subtipoTrazabilidad,
		});
	};

	const handleContinue = async () => {
		const product = products.find(
			(prod: any) => prod.producto === selectProduct
		);
		product.complementos = '';
		product.ahorro = product.ahorro + (Number(dataProcessController?.titular?.saldoAportaciones) || 0);
		// product.desTot = conyuge
		// 	? Number(product.desTot) - Number(product.segDan)
		// 	: product.desTot;
		// product.desTotCny =
		// 	Number(product.desTotCny) - Number(product.segDanCny);

		handleTrazabilidad();

		await saveData({
			processInfo: {
				productSelect: product,
				isCalculated: isSearch,
			},
			processData: {
				active: getProgressStep().active + 1,
			},
		});
		addSelection({
			title: 'Producto Seleccionado:',
			description: `${creditTypes[selectProduct].title}`,
		});
		nextStep();
	};

	const getValidationConyuge = (
		isSearchActive: boolean,
		productsItems: any
	) => {
		const titularSaldo = Number(
			dataProcessController?.titular.saldoSARTotal || '0'
		);
		let conyugeSaldo = 0;
		const uma3_001 = getUMA3_001_MIUC(
			Number(dataProcessController?.titular?.valorUMA)
		);
		const mensajeSaldoCubiertoTitular =
			'Monto de deuda cubierto por titular, no aplica conyugal.';

		const saldoOperacionTotal =
			Number(valorVivienda.toString().replaceAll(',', '')) + uma3_001;

		let saldoTotalCalculado =
			Number(productsItems[0]?.cdcMon || '0') + uma3_001;

		if (dataProcessController.processInfo?.creditType !== 'I') {
			conyugeSaldo = Number(
				dataProcessController?.conyuge.saldoSARTotal || '0'
			);
			saldoTotalCalculado += Number(productsItems[0]?.cdcMonCny || '0');

			if (
				(isSearchActive && titularSaldo > saldoOperacionTotal) ||
				(titularSaldo > saldoTotalCalculado && productsItems.length > 0)
			) {
				setAlertCustomInput({
					show: true,
					message: mensajeSaldoCubiertoTitular,
					severity: 'error',
				});
			} else {
				setAlertCustomInput({
					show: false,
					message: '',
					severity: 'error',
				});
			}
		}
	};

	const handleCalculate = async (productosItems: IProductMonto[]) => {
		setRefreshProducts(true);
		let valueLiving = valorVivienda;
		if (!valorVivienda && conyuge) {
			valueLiving = '0';
		} else if (Number(valorVivienda || '0') <= 0 && !conyuge) {
			return setAlertCustomInput({
				show: true,
				message: 'Escribe el valor de la vivienda que quieres comprar',
				severity: 'warning',
			});
		} else {
			setAlertCustomInput({
				show: false,
				message: '',
				severity: 'warning',
			});
		}

		try {
			dispatch(actions.startLoading());
			// setLoading(true);
			let productsMap = productosItems;
			let productsPromise: Promise<any>[] = [];
			for (let index = 0; index < productsMap.length; index++) {
				const product = productsMap[index];

				const data = getDataCalculadora(
					product,
					valueLiving,
					Etc.ETC,
					plazo,
					plazoCny
				);
				const ENCODED_DATA = await encodeAllJSONData(
					{ data: JSON.stringify(data) },
					user?.k || ''
				);
				productsPromise.push(apiMIUC.calculadora(ENCODED_DATA));
			}

			const response = await Promise.all(productsPromise);

			const productsCalculated = (response || []).map((productCal) => {
				const productItem = productosItems.find(
					(item) => item.producto === productCal.data?.producto
				);
				const newRawProduct = {
					...productItem,
					totalContarias:
						Number(productCal?.data?.capacidadCompra) +
						Number(productCal?.data?.capacidadCompraCny) +
						(Number(
							dataProcessController?.titular?.saldoAportaciones
						) || 0),
					pzoCre: productCal?.data?.pzoCre || productItem?.pzoCre,
					pzoCreCny:
						productCal?.data?.pzoCre || productItem?.pzoCreCny,
					mmcMon: productCal?.data?.montoMaximoCredito,
					mmcMonCny: productCal?.data?.montoMaximoCreditoCny,
					gasMon: productCal?.data?.cargasFinancieras,
					gasMonCny: productCal?.data?.cargasFinancierasCny,
					cdcMon: productCal?.data?.capacidadCompra +
							(Number(
								dataProcessController?.titular
									?.saldoAportaciones
							) || 0) || '0',
					cdcMonCny: productCal?.data?.capacidadCompraCny || '0',
					desTot: productCal?.data?.descuento,
					desTotCny: productCal?.data?.descuentoCny,
					desRoa: productCal?.data?.descuentoSinSubsidio,
					desRoaCny: productCal?.data?.descuentoSinSubsidioCny,
					diffCubrir:
						Number(productCal?.data?.diferenciasCargoTrabajador) > 0
							? productCal?.data?.diferenciasCargoTrabajador
							: 0,
					// diffCubrirCny:
					// 	Number(
					// 		productCal?.data?.diferenciasCargoTrabajadorCny
					// 	) > 0
					// 		? productCal?.data?.diferenciasCargoTrabajadorCny
					// 		: 0,
					nvoFPP: productCal?.data?.nvoFPP || '0',
					nvoFPPCny: productCal?.data?.nvoFPPCny || '0',
					nvoCotaAdmin: productCal?.data?.nvoCotaAdmin || '0',
					nvoCotaAdminCny: productCal?.data?.nvoCotaAdminCny || '0',
					saldoSubcuentaVivienda:
						productCal?.data?.saldoSubcuentaVivienda || 0,
					saldoSubcuentaViviendaCny:
						productCal?.data?.saldoSubcuentaViviendaCny || 0,
					ahorro: productCal?.data?.saldoSubcuentaVivienda || 0,
					ahorroCny: productCal?.data?.saldoSubcuentaViviendaCny || 0,
					montoETC: productCal?.data?.montoETC || 0,
					// Si se quiere mano de obra entonces se asigna 80% en ETC80 sino es el 100%
					montoETC80: valueOptionObra
						? productCal?.data?.montoETC80 || 0
						: productCal?.data?.montoETC || 0,
					// Si se quiere mano de obra entonces se asigna 20% en ETC20 sino es el 0%
					montoETC20: valueOptionObra
						? productCal?.data?.montoETC20 || 0
						: 0,
				} as IProductMonto;
				return newRawProduct;
			});
			const newProducts = productsCalculated.map((product) => {
				return {
					...product,
					totalContarias:
						Number(product.cdcMon) + 
						Number(product.cdcMonCny) +
						(Number(
							dataProcessController?.titular?.saldoAportaciones
						) || 0),
				};
			});
			// No se encuantra COFIA en los calculados
			const notCofia =
				newProducts.find((p) => p.producto === 'COFIA') === undefined;
			// COFIA existe en los productos disponibles
			const cofia = productsOriginal.find((p) => p.producto === 'COFIA');
			let productos = newProducts;
			if (cofia && notCofia) {
				productos = [
					...newProducts,
					{
						...cofia,
						totalContarias:
							Number(cofia.cdcMon) + 
							Number(cofia.cdcMonCny) +
							(Number(
								dataProcessController?.titular
									?.saldoAportaciones
							) || 0),
						ahorro: dataProcessController.titular.saldoSARTotal,
						ahorroCny:
							dataProcessController.conyuge?.saldoSARTotal || '0',
					},
				];
			}
			recalcularMontosTitulacion(
				gastosTitulacion,
				productos,
				(newProducts) => setProducts(newProducts)
			);
			// getValidationConyuge(true, newProducts);
			setIsSearch(true);
			disableAll();
			// setLoading(false);
			showMontoETC();
			dispatch(actions.stopLoading());
		} catch (error) {
			// setLoading(false);
			dispatch(actions.stopLoading());
		} finally {
			dispatch(actions.stopLoading());
		}
	};

	const handleSetDataVivienda = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const { value } = event.target;
		if (isNumberFloat(value)) {
			setValorVivienda(value);
		}
	};

	const onChangeEquipa = ({ target }: any) => {
		const value = target.value === 'true';
		setValueOptionEquipa(value);
		setEtc({ ...Etc, ETC: value });
	};

	const onChangeObra = ({ target }: any) => {
		const value = target.value === 'true' ? true : false;
		setValueOptionObra(value);
	};

	const showMontoETC = () => {
		const isSetMontoETC = rowsTitleCompra.findIndex(
			(obj) => obj.key === 'montoETC'
		);
		const isSetMontoETC80 = rowsTitleCompra.findIndex(
			(obj) => obj.key === 'montoETC80'
		);
		const isSetMontoETC20 = rowsTitleCompra.findIndex(
			(obj) => obj.key === 'montoETC20'
		);
		const rowCopy = [...rowsTitleCompra];
		if (isSetMontoETC !== -1) {
			rowCopy[isSetMontoETC].hideRow = valueOptionEquipa ? false : true;
		}

		if (isSetMontoETC80 !== -1) {
			rowCopy[isSetMontoETC80].hideRow = valueOptionEquipa ? false : true;
		}

		if (isSetMontoETC20 !== -1) {
			rowCopy[isSetMontoETC20].hideRow =
				valueOptionObra && valueOptionEquipa ? false : true;
		}
		setRowsTitleCompra(rowCopy);
	};

	const hideMontoETC = () => {
		const isSetMontoETC = rowsTitleCompra.findIndex(
			(obj) => obj.key === 'montoETC'
		);
		const isSetMontoETC80 = rowsTitleCompra.findIndex(
			(obj) => obj.key === 'montoETC80'
		);
		const isSetMontoETC20 = rowsTitleCompra.findIndex(
			(obj) => obj.key === 'montoETC20'
		);
		const rowCopy = [...rowsTitleCompra];
		if (isSetMontoETC !== -1) {
			rowCopy[isSetMontoETC].hideRow = true;
		}

		if (isSetMontoETC80 !== -1) {
			rowCopy[isSetMontoETC80].hideRow = true;
		}

		if (isSetMontoETC20 !== -1) {
			rowCopy[isSetMontoETC20].hideRow = true;
		}
		setRowsTitleCompra(rowCopy);
	};

	const handleClearSearch = () => {
		setIsSearch(false);
		setValorVivienda('');
		setGastosTitulacion(0);
		hideMontoETC();
		getValidationConyuge(false, productsOriginal);
		enableAll();
		getProducts();
		// setRefreshProducts(true);
	};

	const handleButtonCalculate = () => {
		if (disableInputMonto) {
			getProducts(true);
		} else {
			handleCalculate(
				Etc.ETC
					? productsToCalculate.filter(
							(product) => product.producto !== 'COF'
					  )
					: productsToCalculate
			);
		}
	};

	return {
		handleContinue,
		returnStep,
		setSelectProduct,
		selectProduct,
		products,
		productsTable,
		newStep,
		disableButton,
		valorVivienda,
		setValorVivienda,
		handleSetDataVivienda,
		handleCalculate,
		isSearch,
		handleClearSearch,
		radioOptionsEquipa,
		onChangeEquipa,
		radioOptionsObra,
		onChangeObra,
		valueOptionEquipa,
		valueOptionObra,
		productsOriginal,
		handleTrazabilidad,
		dataProcessController,
		AlertInput,
		AlertInputCalc,
		Etc,
		getAlertStatus,
		rowsTitleCompra,
		conyuge,
		productsToCalculate,
		setGastosTitulacion,
		gastosTitulacion,
		setShowGastosModal,
		showGastosModal,
		disableSelectPlazo,
		getPlazos,
		onChangePlazo,
		plazo,
		setPlazo,
		plazos,
		handleButtonCalculate,
		disableCalculateBtn,
		plazoCny,
		plazosCny,
		setPlazoCny,
		refreshProducts,
	};
};
