/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { CheckCircleOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useController } from '../../../hooks/useController';

import NoticePrivacy from '../components/NoticePrivacy';
import QuestionnaireSteps from './QuestionnaireSteps';
import BaseModal from '../../../../../components/Modal';
import ModalLoading from '../../../../../components/ModalLoading';
import CustomAlert from '../../../../../components/CustomAlert';
import { RoundBtn } from '../../../components/RoundBtn/roundBtn';

import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { RootState } from '../../../../../store';
import { useStyles } from '../styles/styles';
import { IAlert } from '../../../../../interfaces/alert';
import { apiMIUC } from '../../../../../api/modules/MIUC';
import logoMejorasi from '../../../../../assets/img/productsMIUC/MejOraSi.png';

const Questionnaire = ({
	setActiveQuestMejora,
	setCuestionarioCompleto,
}: any) => {
	const { returnStep, dataProcessController } = useController();
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [modalOpenPriv, setModalOpenPriv] = useState(false);
	const [ready, setReady] = useState(false);
	const [questionnaireData, setQuestionnaireData] = useState([] as any);
	const [questionnairePending, setQuestionnairePending] = useState(0);
	const [finish, setFinish] = useState(false);
	const [modalComplete, setModalComplete] = useState(false);

	useEffect(() => {
		const statusCuestionario = async () => {
			try {
				setLoading(true);
				setAlert({ show: false, message: '', severity: 'error' });
				const data: any = {
					nss: dataProcessController?.titular?.nss,
				};
				const dataEncript = await encodeAllJSONData(
					{ data: JSON.stringify(data) },
					user?.k || ''
				);
				const result = await apiMIUC.statusCuestionario(dataEncript);
				if (result.code === '0000') {
					if (result.data.code === '0004') {
						searchCuestionario();
						return;
					} else if (result.data.code === '0000') {
						nextProcess();
					} else {
						setAlert({
							show: true,
							message:
								result.message ||
								result.data.description ||
								'Por el momento el servicio no se encuentra disponible, intenta más tarde',
							severity: 'error',
						});
						return;
					}
				}
			} catch (error: any) {
				setAlert({
					show: true,
					message:
						error.description ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					severity: 'error',
				});
			} finally {
				setLoading(false);
			}
		};
		statusCuestionario();
	}, []);

	const searchCuestionario = async () => {
		try {
			setLoading(true);
			setAlert({ show: false, message: '', severity: 'error' });
			const data: any = {
				nss: dataProcessController?.titular?.nss,
			};
			const dataEncript = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			apiMIUC
				.searchCuestionario(dataEncript)
				.then((result: any) => {
					if (result.code !== '0000') {
						setAlert({
							show: true,
							message: result.description
								? result.description
								: 'Por el momento el servicio no está disponible. Intenta más tarde.',
							severity: 'error',
						});
					}
					//console.log(result);
					setQuestionnaireData(result?.data?.cuestionario);
					setQuestionnairePending(
						parseInt(result?.data?.cuestionario?.paginas[0]?.pagina)
					);
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
					setLoading(false);
				});
		} catch (error: any) {
			setAlert({
				show: true,
				message:
					error.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const encolaCuestionario = async (cuestions: any) => {
		try {
			setLoading(true);
			const data = {
				nss: questionnaireData?.nss || '',
				nombreDH: questionnaireData?.nombreDH,
				paginas: [
					{
						pagina: questionnaireData?.paginas[0]?.pagina,
						preguntas: cuestions,
					},
				],
			};
			const ENCODED_DATA = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const result = await apiMIUC.encolaCuestionario(ENCODED_DATA);
			let newData = {
				...questionnaireData,
				paginas: result.data.cuestionario.paginas,
			};
			setQuestionnaireData(newData);
			setAlert({ show: false, message: '', severity: 'error' });
		} catch (error: any) {
			setAlert({
				show: true,
				message:
					error.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const completoCuestionario = async (cuestions: any) => {
		try {
			setLoading(true);
			const data = {
				nss: questionnaireData?.nss || '',
				nombreDH: questionnaireData?.nombreDH,
				paginas: [
					{
						pagina: questionnaireData?.paginas[0]?.pagina,
						preguntas: cuestions,
					},
				],
			};
			const ENCODED_DATA = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const result = await apiMIUC.completoCuestionario(ENCODED_DATA);
			//console.log(result);
			if (result.code === '0000') {
				if (result.data.code === '03') {
					setModalComplete(true);
				}
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message:
					error.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const nextProcess = () => {
		setCuestionarioCompleto(true);
	};

	const afterQuestionarySteps = async () => {
		setReady(false);
		setActiveQuestMejora(false);
	};

	const readyQuestionary = () => {
		setReady(true);
		setActiveQuestMejora(true);
	};

	return (
		<>
			<NoticePrivacy
				modalOpenPriv={modalOpenPriv}
				setModalOpenPriv={setModalOpenPriv}
				setProcess={readyQuestionary}
			/>
			{!ready && (
				<div className={classes.content}>
					<div>
						<div>
							<div>
								<img
									src={logoMejorasi}
									className={classes.logo}
								/>
							</div>
							<div className={classes.infoHeader}>
								<div className={classes.flex}>
									<CheckCircleOutlined
										className={classes.checkCircle}
										color="info"
									/>{' '}
									<span>
										Para conocer el monto de crédito al que
										puedes acceder deberás responder por
										única ocasión este cuestionario.
									</span>
								</div>
								<div className={classes.flex}>
									<CheckCircleOutlined
										className={classes.checkCircle}
										color="info"
									/>{' '}
									<span>
										Realizarlo en un solo momento te llevará
										aproximadamente 30 minutos, de no
										terminarlo, una vez iniciado dispondrás
										de 72 hrs. para concluirlo y en caso de
										no hacerlo deberás iniciar nuevamente.
									</span>
								</div>
								<div className={classes.flex}>
									<CheckCircleOutlined
										className={classes.checkCircle}
										color="info"
									/>{' '}
									<span>
										No olvides GUARDAR en cada sección.
									</span>
								</div>
							</div>
							<div className={classes.containerTableCuestionary}>
								<div className={classes.containerRowTable}>
									<div
										className={
											classes.subContainerRowTable +
											' ' +
											classes.rowGray
										}
									>
										<h4 className={classes.titleBlue}>
											Cuestionario de estudio
											socioeconómico
										</h4>
									</div>
									<div
										className={
											classes.subContainerRowTable +
											' ' +
											classes.rowTransparent
										}
									>
										<div
											className={classes.containerRowItem}
										>
											<div>Datos personales</div>
											<div>
												{questionnairePending === 1 && (
													<p
														className={
															classes.linkInit
														}
														onClick={() => {
															setModalOpenPriv(
																true
															);
														}}
													>
														Iniciar
													</p>
												)}
												{questionnairePending > 1 && (
													<p
														className={
															classes.linkInit
														}
													>
														Completo
													</p>
												)}
											</div>
										</div>
									</div>
									<div
										className={
											classes.subContainerRowTable +
											' ' +
											classes.rowTransparent
										}
									>
										<div
											className={classes.containerRowItem}
										>
											<div>Situación laboral</div>
											<div>
												<div>
													{(questionnairePending ===
														2 ||
														questionnairePending ===
															3 ||
														questionnairePending ===
															4) && (
														<p
															className={
																classes.linkInit
															}
															onClick={
																readyQuestionary
															}
														>
															Iniciar
														</p>
													)}
													{questionnairePending >
														4 && (
														<p
															className={
																classes.linkInit
															}
														>
															Completo
														</p>
													)}
												</div>
											</div>
										</div>
									</div>
									<div
										className={
											classes.subContainerRowTable +
											' ' +
											classes.rowTransparent
										}
									>
										<div
											className={classes.containerRowItem}
										>
											<div>Ingresos</div>
											<div>
												{questionnairePending === 5 && (
													<p
														className={
															classes.linkInit
														}
														onClick={
															readyQuestionary
														}
													>
														Iniciar
													</p>
												)}
												{questionnairePending > 5 && (
													<p
														className={
															classes.linkInit
														}
													>
														Completo
													</p>
												)}
											</div>
										</div>
									</div>
									<div
										className={
											classes.subContainerRowTable +
											' ' +
											classes.rowTransparent
										}
									>
										<div
											className={classes.containerRowItem}
										>
											<div>Egresos y deudas</div>
											<div>
												{(questionnairePending === 6 ||
													questionnairePending ===
														7 ||
													questionnairePending ===
														8) && (
													<p
														className={
															classes.linkInit
														}
														onClick={
															readyQuestionary
														}
													>
														Iniciar
													</p>
												)}
												{questionnairePending > 8 && (
													<p
														className={
															classes.linkInit
														}
													>
														Completo
													</p>
												)}
											</div>
										</div>
									</div>
									<div
										className={
											classes.subContainerRowTable +
											' ' +
											classes.rowTransparent
										}
									>
										<div
											className={classes.containerRowItem}
										>
											<div>
												Situación residencial actual
											</div>
											<div>
												{(questionnairePending === 9 ||
													questionnairePending ===
														10) && (
													<p
														className={
															classes.linkInit
														}
														onClick={
															readyQuestionary
														}
													>
														Iniciar
													</p>
												)}
												{questionnairePending > 10 && (
													<p
														className={
															classes.linkInit
														}
													>
														Completo
													</p>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<CustomAlert
							show={alert.show}
							message={alert.message}
							severity={alert.severity}
						/>

						<div className={classes.footer}>
							<RoundBtn
								variant="outlined"
								onClick={() => returnStep()}
							>
								Regresar
							</RoundBtn>
							<RoundBtn
								disabled={!finish}
								variant="contained"
								onClick={() => nextProcess()}
							>
								Continuar
							</RoundBtn>
						</div>
					</div>
				</div>
			)}

			{ready && (
				<div className={classes.contentQuestionnaireSteps}>
					<div className={classes.header}>
						<h2 className={classes.titleBlue}>
							Cuestionario para otorgamiento de crédito
						</h2>
					</div>
					<QuestionnaireSteps
						questionnaireData={questionnaireData}
						encolaCuestionario={encolaCuestionario}
						completoCuestionario={completoCuestionario}
						afterQuestionarySteps={afterQuestionarySteps}
						alert={alert}
					/>
				</div>
			)}

			<ModalLoading loading={loading} />
			<BaseModal
				open={modalComplete}
				onConfirm={() => {
					setModalComplete(false);
					setFinish(true);
					nextProcess();
				}}
				onClose={() => {
					setModalComplete(false);
					setFinish(true);
					nextProcess();
				}}
				cancelButton={false}
				confirmLabel="Aceptar"
				hideCircularProgress={true}
				hideCloseButton={true}
				width="sm"
				children={
					<div>
						<h3 className={classes.titleModal}>
							Cuestionario completo
						</h3>
						<div
							className={
								classes.dialogCheck + ' ' + classes.textModal
							}
						>
							<p>
								Has completado satisfactoriamente el
								cuestionario de estudio socioeconómico.
							</p>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default Questionnaire;
