import { Paper, Box, Link } from '@mui/material';
import { IProfileResponse } from '../../../../../api/modules/MIUC';
import Layout from '../../../../../components/Layout';
import { HeaderList } from '../../../../../components/HeaderList';
import ModalCancelWFI from '../../../components/ModalCancelWFI';
import { URL_APLICACION } from '../../../../../config/miucConstants';
import { useStyles } from './styles/styles';
import { useWaitingValProcess } from './hooks/useWaitingValProcess';

interface IWaitingValProcess {
	data: IProfileResponse;
}
const WaitingValProcess = (props: IWaitingValProcess) => {
	const {
		openModalCancel,
		setOpenModalCancel,
		selectOption,
		setSelectOption,
		alert,
		setAlert,
		getUrlWFI,
	} = useWaitingValProcess(props);
	const classes = useStyles();
	return (
		<Layout>
			<div>
				<HeaderList
					title="Me interesa un crédito"
					list={['Registro de solicitud de crédito en línea']}
				/>
				<Paper>
					<Box padding={3} maxWidth={900} margin={'auto'}>
						<h3 className={classes.textCenter}>
							Tu expediente está en proceso de validación
						</h3>
						<p className={classes.margin20p0p}>
							Continúa con el registro de tu solicitud cuando tu
							expediente esté completo y correcto.
						</p>
						<p className={classes.margin20p0p}>
							En un máximo de 10 días hábiles te enviaremos a tu
							correo electrónico el estatus de tu caso. Te
							indicaremos si puedes seguir con el trámite o si te
							falta algún requisito.
						</p>
						<Link
							className={classes.linkPaddingDisplay}
							onClick={() => {
								setAlert({
									show: false,
									severity: 'error',
									message: '',
								});
								setOpenModalCancel(!openModalCancel);
							}}
						>
							Cancelar mi solicitud de crédito
						</Link>
					</Box>
				</Paper>
				<ModalCancelWFI
					selectOption={selectOption}
					setSelectOption={setSelectOption}
					openModal={openModalCancel}
					setOpenModal={() => {
						setOpenModalCancel(!openModalCancel);
						setSelectOption('');
						setAlert({
							show: false,
							severity: 'error',
							message: '',
						});
					}}
					onContinue={() => getUrlWFI(URL_APLICACION.CANCELACION_WFI)}
					hasError={alert}
				/>
			</div>
		</Layout>
	);
};

export default WaitingValProcess;
