import { Box } from "@mui/material";
import { formatMoney2 } from "../../../utils/validators";

export interface Props { 
    moneyToFormat: string | number;
}
export const FormatMoney = ({moneyToFormat}: Props) => {
    return (
        <Box
            sx={{
                fontWeight:
                    Number(moneyToFormat) > 0 ? 500 : 'normal',
            }}
        >
            {formatMoney2(Number(moneyToFormat))}
        </Box>
    )
};