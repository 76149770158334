import { Box, InputAdornment } from '@mui/material';
import { useState } from 'react';
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import { formatMoney } from '../../utils/validators';
import { isNumber } from '../../utils/expressions';
import { useStyles } from './style';

interface IInput {
	valid: boolean;
	msg: string;
}

interface Props {
	callEndPoint: (data: any) => void;
}

const FormAmounts = ({ callEndPoint }: Props) => {
	const [ingresosError] = useState({ msg: '' } as IInput);
	const [currentSalary, setCurrentSalary] = useState<number | string>('');
	const [egresosError] = useState({ msg: '' } as IInput);
	const [ingresos, setIngresos] = useState(0);
	const [egresos, setEgresos] = useState(0);

	const onCalcular = () => {
		let montos = { ingresos: ingresos, egresos: egresos };
		callEndPoint(montos);
	};

	const style = useStyles();
	return (
		<>
			<p className={style.bold_Left}>Completa los siguientes campos</p>
			<Box className={style.Box_Amount}>
				<Box className={style.Input_Amount}>
					<TextInput
						id="Ingresos"
						name="Ingresos"
						label="Monto de ingresos"
						value={ingresos}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const { value } = e.target;
							if (!isNumber(value)) return;
							if (value.length > 5) return;
							setIngresos(parseInt(value || '0'));
						}}
						error={!ingresosError.valid}
						onBlur={() => {
							if (currentSalary) {
								if (currentSalary === '.') {
									setCurrentSalary('');
								} else {
									setCurrentSalary(
										formatMoney(
											Number(currentSalary)
										).substring(1)
									);
								}
							}
						}}
						onFocus={() => {
							if (currentSalary) {
								setCurrentSalary(
									currentSalary
										.toString()
										.replaceAll(',', '')
										.replaceAll(' ', '')
								);
							}
						}}
						startAdornment={
							<InputAdornment position="start">
								$
							</InputAdornment>
						}
						helperText={
							!ingresosError.valid ? ingresosError.msg : ''
						}
						tooltipText="Son los ingresos económicos fijos que recibes mensualmente, ya sea de una persona, empresa o un familiar."
					/>
				</Box>
				<Box className={style.Input_Amount}>
					<TextInput
						id="Egresos"
						name="Egresos"
						label="Monto de egresos"
						value={egresos}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const { value } = e.target;
							if (!isNumber(value)) return;
							if (value.length > 5) return;
							setEgresos(parseInt(value || '0'));
						}}
						onBlur={() => {
							if (currentSalary) {
								if (currentSalary === '.') {
									setCurrentSalary('');
								} else {
									setCurrentSalary(
										formatMoney(
											Number(currentSalary)
										).substring(1)
									);
								}
							}
						}}
						onFocus={() => {
							if (currentSalary) {
								setCurrentSalary(
									currentSalary
										.toString()
										.replaceAll(',', '')
										.replaceAll(' ', '')
								);
							}
						}}
						startAdornment={
							<InputAdornment position="start">
								$
							</InputAdornment>
						}
						error={!egresosError.valid}
						helperText={!egresosError.valid ? egresosError.msg : ''}
						tooltipText="Es el monto total de los gastos económicos que tienes mensualmente, exclusivamente debe de ser tuyos y de tus dependientes económicos."
					/>
				</Box>
			</Box>
			<Box>
				<Box className={style.Button_Amount}>
					<CustomButton
						onClick={onCalcular}
						variant="solid"
						styles={{}}
						disabled={egresos === 0 ? true : false}
						label="Calcular mensualidad"
					/>
				</Box>
			</Box>
		</>
	);
};

export default FormAmounts;
