import API from '..';

class CancelledPayments extends API {
	setCaso(data: { data: string }): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/dpc/crea-caso`,
				method: 'POST',
				data,
			})
		);
	}

	getCaso(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/dpc/consulta-caso`,
				method: 'POST',
				//data,
			})
		);
	}

	getMontoDevolver(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/dpc/monto-devolver`,
				method: 'POST',
				data: {},
			})
		);
	}

	getMarcaCuenta(data: { data: string }): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/dpc/marcar-cuenta`,
				method: 'POST',
				data,
			})
		);
	}

	crearSolicitud(data: { data: string }): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/dpc/crea-solicitud`,
				method: 'POST',
				data,
			})
		);
	}

	getPdfAcuse(data: { data: string }): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/dpc/pdf-acuse`,
				method: 'POST',
				data,
			})
		);
	}

	cargarArchivo(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/dpc/carga-archivo`,
				method: 'POST',
				data: { data },
			})
		);
	}

	descargarArchivo(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/dpc/descarga-archivo`,
				method: 'POST',
				data: { data },
			})
		);
	}

	validEfirma(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/valid-data-efirma`,
				method: 'POST',
				data,
			})
		);
	}
}

export const apiCancelledPayments = new CancelledPayments('');
