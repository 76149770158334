import React, { useEffect, useState } from 'react';
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import ModalLoading from '../../../../components/ModalLoading';
import SelectInput from '../../../../components/SelectInput';
import { apiFollowComplaint } from '../../../../api/modules/followComplaint';
import { encrypt } from '../../../../utils/encrypt';
import { keyAPI } from '../../../../api/modules/key';

const ComplaintTypes = ({
	complaintType,
	setComplaintType,
	setTipificacion = () => { },
	setDisableContinue,
}: any) => {
	const [optionsL1, setOptionsL1] = useState<{ idFinal2: string }[]>([]);
	const [optionsL2, setOptionsL2] = useState<any>([]);
	const [optionsL3, setOptionsL3] = useState<any>([]);
	const [l1, setL1] = useState('');
	const [l2, setL2] = useState('');
	const [l3, setL3] = useState('');
	const [badLivingOptions, setBadLivingOptions] = useState<any>([]);
	const [badLiving, setBadLiving] = useState('');
	const [loading, setLoading] = useState(false);

	const fetchCatalog = async (
		catalog = 'Z0001',
		clave_n1 = '',
		clave_n2 = ''
	) => {
		try {
			setLoading(true);
			const data = {
				catalogo: catalog,
				idN1: clave_n1,
				idN2: clave_n2,
			};
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const dataEncripted = await encrypt(
				JSON.stringify(data) || '',
				key
			);
			const result = await apiFollowComplaint.consultarCatalogo(
				dataEncripted
			);
			if (result.code && result.code === '000') {
				if (catalog === 'Z0001') {
					let catalogs = result?.data?.catalogos;
					let otherIndex = result?.data?.catalogos.findIndex(
						(x: any) => x.n1Txt === 'Otro'
					);
					if (otherIndex !== -1) {
						let otherElement = catalogs.splice(otherIndex, 1);
						catalogs = catalogs.concat(otherElement);
					}
					setOptionsL1(
						catalogs
							.filter(
								(value: any, index: number, self: any) =>
									self.findIndex(
										(t: any) => t.idN1 === value.idN1
									) === index
							)
							.map(({ n1Txt, idN1, ...other }: any) => ({
								value: idN1,
								text: n1Txt,
								...other,
							}))
					);
				} else {
					setBadLivingOptions(
						result?.data?.catalogos?.map(
							({ n2Txt, idN2, ...other }: any) => ({
								value: idN2,
								text: n2Txt,
								...other,
							})
						)
					);
				}
			}
		} catch (error) {
			//console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const fetchOptionsL2 = async () => {
		try {
			setLoading(true);
			setOptionsL2([]);
			setOptionsL3([]);
			setL2('');
			setL3('');
			const data = {
				catalogo: 'Z0001',
				idN1: l1,
				idN2: '',
			};
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const dataEncripted = await encrypt(JSON.stringify(data), key);
			const result = await apiFollowComplaint.consultarCatalogo(
				dataEncripted
			);
			if (result.code === '000') {
				setOptionsL2(
					result.data.catalogos
						.filter(
							(value: any, index: number, self: any) =>
								self.findIndex(
									(t: any) => t.idN2 === value.idN2
								) === index
						)
						.map(({ n2Txt, idN2, ...other }: any) => ({
							value: idN2,
							text: n2Txt,
							...other,
						}))
				);
			}
		} catch (error) {
			//console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const fetchOptionsL3 = async () => {
		try {
			setLoading(true);
			setOptionsL3([]);
			setL3('');
			const data = {
				catalogo: 'Z0001',
				idN1: l1,
				idN2: l2,
			};
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const dataEncripted = await encrypt(JSON.stringify(data), key);
			const result = await apiFollowComplaint.consultarCatalogo(
				dataEncripted
			);
			if (result.code === '000') {
				setOptionsL3(
					result.data.catalogos
						.map(({ n3Txt, idN3, ...other }: any) => ({
							value: idN3,
							text: n3Txt,
							...other,
						}))
						.filter((v: any) => v.value)
				);
			}
		} catch (error) {
			//console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCatalog('Z0001');
		fetchCatalog('Z0003', 'ZN100005');
	}, []);

	useEffect(() => {
		if (l1) fetchOptionsL2();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [l1]);

	useEffect(() => {
		if (l1 && l2) fetchOptionsL3();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [l1, l2]);

	useEffect(() => {
		if (complaintType === 1) {
			if (optionsL2.length > 0 && l2 && optionsL3.length > 0 && l3)
				setDisableContinue(false);
			else if (optionsL2.length > 0 && l2 && optionsL3.length === 0)
				setDisableContinue(false);
			else if (optionsL2.length === 0 && l1) setDisableContinue(false);
			else setDisableContinue(true);
		} else if (badLiving) setDisableContinue(false);
		else setDisableContinue(true);
	}, [
		badLiving,
		complaintType,
		l1,
		l2,
		l3,
		optionsL1.length,
		optionsL2.length,
		optionsL3.length,
		setDisableContinue,
	]);

	useEffect(() => {
		const selectTipificationsType1 = () => {
			let saveTipification = '';

			if (l1 && l2 !== '' && l3 === '') {
				for (let i = 0; i < optionsL2.length; i++) {
					if (optionsL2[i].value === l2) {
						saveTipification = optionsL2[i]?.idFinal2;
					}
				}
			} else if (l1 && l2 && l3 !== '') {
				for (let i = 0; i < optionsL3.length; i++) {
					if (optionsL3[i].value === l3) {
						saveTipification = optionsL3[i]?.idFinal3;
					}
				}
			}

			return saveTipification;
		};

		const selectTipificationsType2 = () => {
			let saveTipification = '';

			if (badLiving !== '') {
				for (let i = 0; i < badLivingOptions.length; i++) {
					if (badLivingOptions[i].value === badLiving) {
						saveTipification = badLivingOptions[i]?.idFinal2;
					}
				}
			}
			return saveTipification;
		};

		if (complaintType === 1) {
			setTipificacion(selectTipificationsType1());
		} else if (complaintType === 2) {
			setTipificacion(selectTipificationsType2());
		}
	}, [
		complaintType,
		setTipificacion,
		badLiving,
		badLivingOptions,
		l1,
		l2,
		l3,
		optionsL1,
		optionsL2,
		optionsL3,
	]);

	return (
		<>
			<ModalLoading loading={loading} />
			<FormControl>
				<FormLabel style={{ color: 'black' }}>
					<b>Elige una de las siguientes opciones</b>
				</FormLabel>
				<RadioGroup
					value={complaintType}
					onChange={({ target }: any) =>
						setComplaintType(Number(target.value))
					}
					style={{ padding: '0 45px' }}
				>
					<FormControlLabel
						label={
							'Recibí una mala atención o tuve problemas al utilizar'
						}
						control={<Radio />}
						value={1}
					/>
					{complaintType === 1 && (
						<>
							<SelectInput
								id="bad-attention"
								name="badAttention-l1"
								value={l1}
								options={optionsL1}
								onChange={({ target }: any) =>
									setL1(target.value)
								}
							/>
							{optionsL2.length > 0 && (
								<SelectInput
									id="bad-attention"
									name="badAttention-l2"
									value={l2}
									options={optionsL2}
									onChange={({ target }: any) =>
										setL2(target.value)
									}
								/>
							)}
							{optionsL3.length > 0 && (
								<SelectInput
									id="bad-attention"
									name="badAttention-l3"
									value={l3}
									options={optionsL3}
									onChange={({ target }: any) =>
										setL3(target.value)
									}
								/>
							)}
						</>
					)}
					<FormControlLabel
						label={
							'Tengo problemas con la vivienda nueva que adquirí'
						}
						style={{ marginTop: 10 }}
						control={<Radio />}
						value={2}
					/>
					{complaintType === 2 && (
						<SelectInput
							id="bad-living"
							name="badLiving"
							value={badLiving}
							onChange={({ target }: any) =>
								setBadLiving(target.value)
							}
							options={badLivingOptions}
						/>
					)}
					<FormControlLabel
						label="No estoy de acuerdo con la atención que le han dado a mi caso"
						style={{ marginTop: 10 }}
						control={<Radio />}
						value={3}
					/>
				</RadioGroup>
			</FormControl>
		</>
	);
};

export default ComplaintTypes;
