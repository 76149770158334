import { MIUC_PROCESS_VIEWS } from '../../constants/process';
import Layout from '../../../../components/Layout';
import { Box, Grid, Paper } from '@mui/material';
import { useGeneralStyles } from '../styles/general.styles';
import { BuroForm } from '../../components/BuroForm/buroForm';
import { ComprarViviendaTable } from './views/comprarViviendaTable';
import FlowCard from '../../components/FlowCard';
import ViewPoints from '../../components/ViewPoints';
import { SelectionCredit } from '../../components/SelectionCredit/selectionCredit';
import { CreditOptions } from '../../components/CreditOptions/creditOptions';
import FollowSteps from '../../components/FollowSteps';
import { SelectionResume } from '../../components/SelectionResume/selectionResume';
import { useNavigation } from '../../hooks/useNavigation';
import ResumeCard from '../../components/ResumeCard';
import { useEffect, useState } from 'react';
import { BuroMssg } from '../../components/BuroMssg/BuroMssg';

export const ComprarVivienda = () => {
	const generalStyle = useGeneralStyles();
	const [mssgFlag, setMssgFlag] = useState(true);

	const {
		calcProgress,
		currentView,
		dataProcessController,
		saveData,
		addSelection,
		nextStep,
	} = useNavigation();

	const onSelectCredit = (credit: string) => {
		addSelection({
			title: 'Uso de tu crédito para:',
			description: 'Comprar',
		});
		saveData({
			processInfo: {
				credit,
			},
			processData: {
				active: 1,
				total: 4,
			},
		});
		nextStep();
	};

	useEffect(() => {
		if (dataProcessController.processData.step === undefined) {
			addSelection({
				title: 'Crédito seleccionado para:',
				description: 'Comprar',
			});
			saveData(
				{
					processData: {
						active: 1,
						total: 4,
					},
				},
				true
			);
			nextStep(0);
		}
	}, []);

	const showViewPoints = ![
		MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
		MIUC_PROCESS_VIEWS.NEXT_STEPS,
	].includes(currentView);

	return (
		<>
			<Layout>
				<Box className={generalStyle.container}>
					<Grid container spacing={2}>
						{dataProcessController.processInfo?.lineaBC8 &&
						mssgFlag ? (
							<Grid item xs={12} md={12}>
								<Paper>
									<BuroMssg setFlag={setMssgFlag} />
								</Paper>
							</Grid>
						) : (
							<>
								<Grid item xs={12} md={8}>
									<Paper>
										{
											// currentView ===
											// MIUC_PROCESS_VIEWS.SELECTION_CREDIT ? (
											//   <SelectionCredit
											//     onSelectCredit={onSelectCredit}
											//   />
											// ) :
											currentView ===
											MIUC_PROCESS_VIEWS.CREDIT_OPTIONS ? (
												<CreditOptions returnMain />
											) : currentView ===
											  MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO ? (
												<BuroForm />
											) : currentView ===
											  MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT ? (
												<ComprarViviendaTable />
											) : currentView ===
											  MIUC_PROCESS_VIEWS.SUMMARY_REQUEST ? (
												<SelectionResume />
											) : (
												<></>
											)
										}
									</Paper>
									{currentView ===
										MIUC_PROCESS_VIEWS.NEXT_STEPS && (
										<FollowSteps
											guideLink={require('../../../../assets/files/guia-pasos-a-seguir/GPSLII.pdf')}
										/>
									)}
								</Grid>
								<Grid item xs={12} md={4}>
									{showViewPoints && (
										<Box
											sx={{ textAlign: 'center' }}
											mb={2}
										>
											<ViewPoints />
										</Box>
									)}
									{currentView ===
										MIUC_PROCESS_VIEWS.NEXT_STEPS && (
										<Box
											sx={{ textAlign: 'center' }}
											mb={2}
										>
											<ResumeCard />
										</Box>
									)}
									<Box>
										<FlowCard
											title="Tu selección"
											flow={
												dataProcessController
													.processInfo.selection
											}
											progress={calcProgress()}
										/>
									</Box>
								</Grid>
							</>
						)}
					</Grid>
				</Box>
			</Layout>
		</>
	);
};
