import { HeaderList } from '../../HeaderList';

const ChooseHousing = () => {
	return (
		<>
			<span>
				Al elegir la vivienda considera que la ubicación del lugar es un
				aspecto muy importante que debes atender porque no se puede
				modificar. Vivir cerca del trabajo, escuelas, clínicas de salud,
				hospitales, tiendas, mercados y de la familia, son factores que
				pueden hacer la diferencia entre sólo tener un lugar para
				alojarte o disfrutar de tu hogar.
			</span>
			<HeaderList
				listSizeIcon={18}
				list={[
					<>
						Conoce los servicios públicos que hay en la zona de tu
						interés: recolección de basura, alumbrado público,
						drenaje, abasto de agua.
					</>,
					<>
						Rutas alternativas y/o transporte público. Estudia las
						opciones de transporte público y las vialidades
						disponibles para llegar a tu casa (distancia, costo y
						tráfico).
					</>,
					<>
						Espacios públicos de esparcimiento. Considera que te
						queden cerca parques, canchas y otros.
					</>,
				]}
			/>
			<p>
				Reflexiona antes de decidir, no compres a ciegas, no hagas una
				sola visita a la casa que te interesa y date una vuelta por la
				zona en diferentes horas y días para que observes la dinámica
				del entorno.
			</p>
			<p>
				Evalúa si es el momento adecuado y revisa tus finanzas, no
				compres si en vez de beneficios te traerá dificultades
				económicas.
			</p>
			<p>
				No olvides revisar los aspectos legales, asegúrate que esté al
				corriente en las cuotas de mantenimiento, predial y agua, así
				como de gas y luz.
			</p>
		</>
	);
};

export default ChooseHousing;
