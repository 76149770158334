import { Paper, Box, Link } from '@mui/material';
import Layout from '../../../../../components/Layout';
import { HeaderList } from '../../../../../components/HeaderList';
import ModalCancelWFI from '../../../components/ModalCancelWFI';
import CustomButton from '../../../../../components/Button';
import CustomAlert from '../../../../../components/CustomAlert';
import {
	INIT_PROFILE,
	URL_APLICACION,
} from '../../../../../config/miucConstants';
import { IProfileResponse } from '../../../../../api/modules/MIUC';
import ModalMiEspacio from '../../../components/ModalMiEspacio';
import { useStyles } from './styles/styles';
import { useValidateIdentity } from './hooks/useValidateIndentity';
import { useNavigate } from 'react-router-dom';

interface ICreditReqCan {
	data: IProfileResponse;
}
const ValidateIdentity = (props: ICreditReqCan) => {
	const { data } = props;
	const {
		getUrlMiespacio,
		getUrlWFI,
		openModal,
		setOpenModal,
		openModalCancel,
		setOpenModalCancel,
		selectOption,
		setSelectOption,
		alert,
		setAlert,
		alertDocument,
		setAlertDocument,
	} = useValidateIdentity(props);
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Layout>
			<div>
				<HeaderList
					title="Me interesa un crédito"
					list={[
						'En este servicio puedes dar seguimiento a tu solicitud de crédito en línea.',
					]}
				/>
				<Paper>
					<Box padding={3} maxWidth={900} margin="auto">
						<h3 className={classes.textCenter}>
							Realiza tu validación biométrica
						</h3>
						<p className={classes.margin20p0p}>
							Pensando en tí y en tu seguridad, hemos implementado
							nuevos mecanismos de identidad a través de
							biométricos por lo que es importante que antes de
							presentarte con el notario debes validar tu
							identificación y huellas dactilares para poder
							continuar con tu trámite. Haz una cita y valida tu
							identidad.
						</p>

						<p className={classes.margin20p0p}>
							Para conocer el estatus en el que se encuentra tu
							solicitud entra a{' '}
							<Link onClick={getUrlMiespacio}>
								Mi espacio Infonavit
							</Link>
						</p>

						<p className={classes.margin20p0p}>
							Si tienes problemas para consultar tu información
							llama a Infonatel al 9171 5050 en la ciudad de
							México, o al 800 008 3900 desde cualquier parte del
							país, de lunes a viernes de 7:30 de la mañana a 9 de
							la noche; sábados, domingos o días festivos de 9 de
							la mañana a 3 de la tarde.
						</p>
						<p className={classes.margin20p0p}>
							{data.opcionCancelar && (
								<Link
									onClick={() => {
										setAlert({
											show: false,
											severity: 'error',
											message: '',
										});
										setAlertDocument({
											show: false,
											severity: 'error',
											message: '',
										});
										setOpenModalCancel(!openModalCancel);
									}}
								>
									Cancelar mi solicitud de crédito
								</Link>
							)}
						</p>
						<p className={classes.margin20p0p}>
							{data.opcionImpresion && (
								<Link
									onClick={() => {
										setAlertDocument({
											show: false,
											severity: 'error',
											message: '',
										});
										getUrlWFI(
											URL_APLICACION.REIMPRESION_WFI
										);
									}}
								>
									Impresión de documentos
								</Link>
							)}
						</p>
						<CustomAlert
							show={alertDocument.show}
							message={alertDocument.message}
							severity={alertDocument.severity}
						/>
						<Box maxWidth={250} display="flex" margin="40px auto">
							<CustomButton
								label="Haz tu cita"
								onClick={() => {
									if (
										data.casoActivo?.tipificacion !==
										INIT_PROFILE.BIOMETRICOS
									) {
										return setAlertDocument({
											show: true,
											severity: 'error',
											message:
												'No existe un número de caso asociado',
										});
									}
									navigate(
										`/contactanos/haz-una-cita/${data.casoActivo?.noCaso}/precalificacion-puntos/`
									);
								}}
								variant="solid"
							/>
						</Box>
					</Box>
				</Paper>
				<ModalMiEspacio
					openModal={openModal}
					setOpenModal={() => setOpenModal(!openModal)}
				/>
				<ModalCancelWFI
					selectOption={selectOption}
					setSelectOption={setSelectOption}
					openModal={openModalCancel}
					setOpenModal={() => {
						setOpenModalCancel(!openModalCancel);
						setSelectOption('');
						setAlert({
							show: false,
							severity: 'error',
							message: '',
						});
					}}
					onContinue={() => getUrlWFI(URL_APLICACION.CANCELACION_WFI)}
					hasError={alert}
				/>
			</div>
		</Layout>
	);
};

export default ValidateIdentity;
