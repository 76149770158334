import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('111', 'cuanto-ahorro-tengo');

export const ENDPOINTS_CUANTO_AHORRO_TENGO: IBitacora = {
	'/cat/consulta-saldo/total': _bitacora.Read(
		'111100',
		'Consulta Saldo Total'
	),
	'/cat/consulta-saldo/detalle': _bitacora.Read(
		'111101',
		'Consulta Saldo Detalle'
	),
	'/cat/consulta-saldo/anterior': _bitacora.Read(
		'111102',
		'Consulta Saldo Anterior'
	),
};
