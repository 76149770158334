import { useSelector } from 'react-redux';
import { associateNssAPI } from '../../api/modules/AssociateNSS';
import { keyAPI } from '../../api/modules/key';
import Modal from '../../components/Modal';
import { RootState } from '../../store';
import { encrypt } from '../../utils/encrypt';
import { Base, RoundBtn } from './Styles';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';

const AssociatedNotification = ({
	open,
	setOpen,
	associated = null,
	setLoading,
	fetchAffiliated,
	setAlert,
}: any) => {
	const { user } = useSelector((state: RootState) => state.session);
	const classes = Base();
	// const [alert, setAlert] = useState<IAlert>({
	// 	message: '',
	// 	severity: 'success',
	// 	show: false,
	// });

	const handleAccept = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				key
			);
			const ASSOCIATED_NSS_BASE64 = await encrypt(
				associated?.nssAsociado,
				key
			);
			const ASSOCIATED_TYPE_ASSOS_BASE64 = await encrypt(
				associated?.tipoAsociacion,
				key
			);
			const ASSOCIATED_TYPE_RELATION_BASE64 = await encrypt(
				associated?.tipoRelacion || '',
				key
			);
			const ASSOCIATED_GENDER_BASE64 = await encrypt(
				associated?.genero || '',
				key
			);

			let REQ_BASE64 = {
				nss: USER_NSS_BASE64,
				nssSecundario: ASSOCIATED_NSS_BASE64,
				tipoAsociacion: ASSOCIATED_TYPE_ASSOS_BASE64,
				tipoRelacion: ASSOCIATED_TYPE_RELATION_BASE64,
				genero: ASSOCIATED_GENDER_BASE64,
			};
			associateNssAPI.associatesNSS
				.post<
					{
						nss: string;
						nssSecundario: string;
						tipoAsociacion: string;
						tipoRelacion: string;
						genero: string;
					},
					any
				>('', REQ_BASE64)
				.then(async (res) => {
					if (res.error) {
						setAlert({
							message:
								INTERNAL_MESSAGES.CHECK_DATA,
							severity: 'error',
							show: true,
						});
						setOpen(false);
						return;
					}

					if (res.msg?.code) {
						if (res.msg?.code?.includes('MCI')) {
							setAlert({
								message:
									res.msg?.description ||
									INTERNAL_MESSAGES.CHECK_DATA,
								severity: 'error',
								show: true,
							});
							setOpen(false);
							return;
						}
					}

					if (res.msg?.nombre) {
						await fetchAffiliated();
						setAlert({
							message: res.msg?.description,
							severity: 'success',
							show: true,
						});
						setOpen(false);
					}
				})
				.catch((error) => {
					setAlert({
						message: error.msg?.code.includes('MCI')
							? error.msg?.description
							: INTERNAL_MESSAGES.CHECK_DATA,
						severity: 'error',
						show: true,
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				message: error.msg?.code.includes('MCI')
					? error.msg?.description
					: INTERNAL_MESSAGES.CHECK_DATA,
				severity: 'error',
				show: true,
			});
			setLoading(false);
		}
	};

	const handleCancel = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				key
			);
			const ASSOCIATED_NSS_BASE64 = await encrypt(
				associated.nssAsociado,
				key
			);

			associateNssAPI.delete
				.post<{ nss: string; nssSecundario: string }, any>('', {
					nss: USER_NSS_BASE64,
					nssSecundario: ASSOCIATED_NSS_BASE64,
				})
				.then(async (res: any) => {
					await fetchAffiliated();
					setOpen(false);
				})
				.catch(() => {})
				.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<>
			<Modal
				title="Asocia tu NSS"
				showDialogTitle={false}
				width={'sm'}
				open={open}
				onClose={() => setOpen(false)}
			>
				<div className={classes.associatedNotificationContainer}>
					<b>
						El siguiente derechohabiente solicita asociar su NSS con
						el tuyo
					</b>
					<p>
						<b>Número de Seguridad Social:</b>{' '}
						{associated?.nssAsociado}
					</p>
					<p>
						<b>Nombre:</b> {associated?.nombreAsociado}
					</p>
					<p>
						<b>CURP</b> {associated?.curpAsociado}
					</p>
					<p>
						<b>RFC</b> {associated?.rfcAsociado}
					</p>
					<b>¿Quieres asociar este NSS con el tuyo?</b>

					<div className="actions-container">
						<RoundBtn
							onClick={handleCancel}
							variant="outlined"
							data-testid="buttonNo"
						>
							No
						</RoundBtn>
						<RoundBtn
							onClick={handleAccept}
							variant="contained"
							data-testid="buttonSi"
						>
							Sí
						</RoundBtn>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default AssociatedNotification;
