import API from '..';
import {
	IGeneralInformation,
	IProductosMontos,
	ITiposCredito,
	IConsultarProductosMontosData,
	ICalculadoraRes,
	ITrazabilidadCRM,
	IObtenerCatRes,
	ITablaAmortizacionData,
	ITablaAmortizacionRes,
	IListaElementos,
	IActualizarElementosRes,
	IObtenerCertificadoRes,
	IConsultaAutoproduccion,
	IConsultaAutoproduccionRes,
	IResponseEntidades,
	IConsultarSaldoRes,
	IConsultarHistorialRes,
	IObtenerListaElementosRes,
	IObtenerElementosUbicacionRes,
} from '../../interfaces/creditRequest';
import { IMainResponse } from '../../interfaces/mainResponse';
import { ICasoDTO, INSSAsociado } from '../../interfaces/user';

interface IDefaultRequest {
	data: string;
}
class CreditRequestAPI extends API {
	consultarEstados(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/consultar-estados`,
				method: 'POST',
			})
		);
	}
	consultarUnidades(clave: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/consultar-unidades`,
				method: 'POST',
				data: { clave },
			})
		);
	}
	consultarAsentamientos(cp: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/address-catalog`,
				method: 'POST',
				data: { cp },
			})
		);
	}
	consultarInfoGeneral(
		data: IDefaultRequest
	): Promise<{ result: IMainResponse<IGeneralInformation> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/consultar-info-general`,
				method: 'POST',
				data,
			})
		);
	}
	consultarTiposCredito(
		data: IDefaultRequest
	): Promise<{ result: IMainResponse<{ creditos: ITiposCredito[] }> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/consultar-tipos-credito`,
				method: 'POST',
				data,
			})
		);
	}
	consultarProductosMontos(
		data: IConsultarProductosMontosData
	): Promise<{ result: IMainResponse<IProductosMontos> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/consultar-productos-montos`,
				method: 'POST',
				data,
			})
		);
	}
	trazabilidadCRM(
		data: IDefaultRequest
	): Promise<{ result: IMainResponse<ITrazabilidadCRM[]> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/trazabilidad-crm`,
				method: 'POST',
				data,
			})
		);
	}
	calculadora(
		data: IDefaultRequest
	): Promise<{ result: IMainResponse<ICalculadoraRes> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/calculadora`,
				method: 'POST',
				data,
			})
		);
	}
	consultarHistorial(
		data: IDefaultRequest
	): Promise<{ result: IMainResponse<IConsultarHistorialRes> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/consultar-historial-crediticio`,
				method: 'POST',
				data,
			})
		);
	}
	obtenerCAT(
		data: IDefaultRequest
	): Promise<{ result: IMainResponse<IObtenerCatRes> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/consultar-cat`,
				method: 'POST',
				data,
			})
		);
	}
	tablaAmortizacion(
		data: ITablaAmortizacionData
	): Promise<{ result: IMainResponse<ITablaAmortizacionRes> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/consultar-tabla-amortizacion`,
				method: 'POST',
				data,
			})
		);
	}
	obtenerListaElementos(
		data: IListaElementos
	): Promise<{ result: IMainResponse<IObtenerListaElementosRes> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/gestor-web/obtener-lista-elementos`,
				method: 'POST',
				data,
			})
		);
	}
	obtenerElementosUbicacion(
		data: IDefaultRequest
	): Promise<{ result: IMainResponse<IObtenerElementosUbicacionRes> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/gestor-web/obtener-elementos-ubicacion`,
				method: 'POST',
				data,
			})
		);
	}
	actualizarElementosUbicacion(
		data: IDefaultRequest
	): Promise<{ result: IMainResponse<IActualizarElementosRes> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/gestor-web/actualizar-elemento`,
				method: 'POST',
				data,
			})
		);
	}
	obtenerCertificado(
		data: IDefaultRequest
	): Promise<{ result: IMainResponse<IObtenerCertificadoRes> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/gestor-web/obtener-certificado`,
				method: 'POST',
				data,
			})
		);
	}
	consultarAutoproduccion(
		data: IConsultaAutoproduccion
	): Promise<{ result: IMainResponse<IConsultaAutoproduccionRes> }> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/consultar-autoproduccion`,
				method: 'POST',
				data,
			})
		);
	}
	obtenerEntidadesFinancieras(): Promise<{
		result: IMainResponse<{ entidades: IResponseEntidades[] }>;
	}> {
		return this.requestWrapper(
			this.request({
				url: `miuc/consultar-entidades-financieras`,
				method: 'POST',
			})
		);
	}
	consultarSaldo(): Promise<{ result: IMainResponse<IConsultarSaldoRes> }> {
		return this.requestWrapper(
			this.request({
				url: `miuc/consultar-saldo`,
				method: 'POST',
			})
		);
	}
	consultarCaso(data: {
		data: string;
	}): Promise<{ result: IMainResponse<ICasoDTO> }> {
		return this.requestWrapper(
			this.request({
				url: `miuc/consultar-caso`,
				method: 'POST',
				data,
			})
		);
	}
	consultarCasoCRV(data: {
		data: string;
	}): Promise<{ result: IMainResponse<ICasoDTO> }> {
		return this.requestWrapper(
			this.request({
				url: `miuc/consultar-caso-crv`,
				method: 'POST',
				data,
			})
		);
	}
	consultarNssAsociado(): Promise<{ result: IMainResponse<INSSAsociado> }> {
		return this.requestWrapper(
			this.request({
				url: `miuc/consultar-asociacion`,
				method: 'POST',
			})
		);
	}
	consultarProductosMejoraDirecta(): Promise<{ result: any }> {
		return this.requestWrapper(
			this.request({
				url: `miuc/consultar-productos-mejora-directa`,
				method: 'POST',
			})
		);
	}
	getUrlEncripted(
		data: IDefaultRequest
	): Promise<{ result: IMainResponse<{ path: string }> }> {
		return this.requestWrapper(
			this.request({
				url: `miuc/encriptar-url`,
				method: 'POST',
				data,
			})
		);
	}
	getPdfDocuments(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/constancia-elegibilidad`,
				method: 'POST',
			})
		);
	}
	statusCuestionario(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/consultar-estatus-cuestionario`,
				method: 'POST',
			})
		);
	}
	searchCuestionario(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/busqueda-cuestionario`,
				method: 'POST',
			})
		);
	}
	encolaCuestionario(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/encola-cuestionario`,
				method: 'POST',
				data,
			})
		);
	}
	completoCuestionario(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/cuestionario-completo`,
				method: 'POST',
				data,
			})
		);
	}
    consultaMejoraVit(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/miuc/consultar-productos-micromejoras-mejoras-mayores`,
				method: 'POST',
				data,
			})
		);
	}
  descargarCertificado(): Promise<{ result: any }> {
		return this.requestWrapper(
			this.request({
				url: `miuc/pdf/certificado/apoyo`,
				method: 'POST',
			})
		);
	}
}

export const creditRequestAPI = new CreditRequestAPI('');
