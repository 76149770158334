/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import Tabs from '../../components/Tabs';
import { Box } from '@mui/system';
import { useTabs } from '../../hooks/useTabs';
import Tab1Appointment from './tab1Appointment';
import Tab2Appointment from './tab2Appointment';
import { IResponseCitasActuales } from '../../interfaces/makeDate24';
import { IAlert } from '../../interfaces/alert';

interface Props {
	setPage: (data: number) => void;
	setTabAct: number;
	modulo: any;
	services: any;
	motivo: any;
	handleCancelar: (pAppoimentId: string) => void;
	setMotivo: (motivo: any) => void;
	setServices: (services: any) => void;
	setService: (data: string) => void;
	downloadPDF: (value: IResponseCitasActuales, bash: any) => void;
	handlecitasActuales: (nss: string) => void;
	ResponseCitasActuales: any;
	ResponseServices: any;
	alertCancelar?:
	| IAlert
	| {
		show: false;
		message: '';
		severity: 'success';
	};
	errorResponseCitas: boolean;
	errorResponseServices: boolean;
	sendDataReprogramar: (data: any) => void;
	alertCitasActuales: any;
	setServiceDes: (data: string) => void;
}

const tabsFlag = [
	{
		id: 1,
		title: 'Haz tu cita aquí',
		disabled: false,
		checked: false,
		columns: 6,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: 2,
		title: 'Citas programadas',
		disabled: false,
		checked: false,
		columns: 6,
		orderMD: 1,
		orderSM: 2,
	},
];

const TabsAppointment = ({
	setPage,
	setTabAct,
	setService,
	setMotivo,
	motivo,
	downloadPDF,
	services,
	alertCancelar,
	handleCancelar,
	handlecitasActuales,
	modulo,
	setServices,
	ResponseCitasActuales,
	ResponseServices,
	errorResponseCitas,
	errorResponseServices,
	sendDataReprogramar,
	alertCitasActuales,
	setServiceDes
}: Props) => {
	let { containerPosition, tabActive, tabsEle, changeTabCustom } = useTabs(
		tabsFlag,
		1,
		setTabAct
	);

	useEffect(() => {
		handlecitasActuales('');
	}, []);

	const setDataReprogramar = (data: any) => {
		sendDataReprogramar(data);
	}

	return (
		<Tabs
			tabs={tabsEle}
			containerPosition={containerPosition}
			active={tabActive}
			containerColumns={12}
			onChangeTab={(indexTab: number) => {
				changeTabCustom(indexTab);
			}}
			cardsType
		>
			<>
				{tabActive === 0 && (
					<Box sx={{ width: '100%' }}>
						<Tab1Appointment
							responseCitasActuales={ResponseCitasActuales}
							ResponseServices={ResponseServices}
							errorResponseCitas={errorResponseCitas}
							errorResponseServices={errorResponseServices}
							setPage={setPage}
							setService={setService}
							setServiceDes={setServiceDes}
							modulo={modulo}
							setServices={setServices}
							services={services}
						/>
					</Box>
				)}
				{tabActive === 1 && (
					<Box sx={{ width: '100%' }}>
						<Tab2Appointment
							errorResponseCitas={errorResponseCitas}
							responseCitasActuales={ResponseCitasActuales}
							downloadPDF={downloadPDF}
							alertCancelar={alertCancelar}
							motivo={motivo}
							setMotivo={setMotivo}
							handleCancelar={handleCancelar}
							setDataReprogramar={setDataReprogramar}
						/>
					</Box>
				)}
			</>
		</Tabs>
	);
};

export default TabsAppointment;
