import { useState, useEffect } from 'react';
import { WarningAmberRounded } from '@mui/icons-material';
import {
	useMediaQuery,
	Alert,
	Paper,
	Box,
	Grid,
	Divider,
	Link,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
	ICredito,
	Messages,
	formatMessage,
} from '../../../interfaces/SaldAndMovement';
import { HeaderList } from '../../../components/HeaderList';
import CreditDetail from './CreditDetail';
import MonthlyPayment from './MonthlyPayment';
import MonthlyPaymentFpp from './MonthlyPaymentFpp';
import { UseStyle } from '../Style';
import CustomButton from '../../../components/Button';
import ModalOtherPay from './ModalOtherPay';

interface Props {
	setView: (arg: number) => void;
	credito: ICredito | null;
}

const SaldAndMovement = ({ setView, credito }: Props) => {
	const classes = UseStyle();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [formasPagos, setFormasPagos] = useState<Array<{ value: string }>>(
		[]
	);
	const [modal, setModal] = useState(false);

	useEffect(() => {
		setFormasPagos(
			credito?.msgs?.filter((elemento) =>
				elemento.key.match(Messages.CodePay)
			) || []
		);
	}, [credito]);

	const messages = credito?.msgs?.filter((elemento) =>
		elemento.key.match(Messages.CodeCabeceraPrincipal)
	);

	const codigoDemanda = Messages.Juridico.includes(
		credito?.tipoCasoCredito?.key ? credito?.tipoCasoCredito?.key : 0
	);

	const creditoDemanda =
		credito && credito.msgs
			? credito.msgs?.filter((elemento) =>
					elemento.key.match(Messages.CodeDemanda)
			  )
			: [];

	return (
		<>
			<ModalOtherPay
				openModal={modal}
				closeModal={setModal}
				credito={credito}
				formasPagos={formasPagos}
				matches={matches}
			/>

			<HeaderList
				title="Saldos y movimientos"
				list={[
					'En este servicio encuentras la información necesaria de tu crédito; saldo,mensualidad, pagos y estados de cuenta.',
				]}
			/>

			<CreditDetail
				credito={credito}
				setView={setView}
				matches={matches}
			/>

			{codigoDemanda ? (
				<>
					<Alert
						icon={
							<WarningAmberRounded
								fontSize="inherit"
								style={{ color: '#E35700' }}
							/>
						}
						severity="warning"
						style={{
							marginBottom: 10,
							background: '#FAE295',
							color: '#E35700',
						}}
					>
						{creditoDemanda.map((element, index) => (
							<span key={index} style={{ display: 'block' }}>
								{formatMessage(element.value, '23')}
							</span>
						))}
					</Alert>
				</>
			) : (
				<>
					{messages && credito?.tipoCasoEspecial?.code !== 4 && (
						<Alert
							icon={
								<WarningAmberRounded
									fontSize="inherit"
									style={{ color: '#E35700' }}
								/>
							}
							severity="warning"
							style={{
								marginBottom: 28,
								background: '#FAE295',
								color: '#E35700',
							}}
						>
							{messages.map((msg, index) => (
								<span
									key={index}
									style={{
										display: 'block',
										fontSize: 15,
									}}
								>
									{formatMessage(msg.value, msg.key, credito)}
								</span>
							))}
						</Alert>
					)}

					{credito?.tipoCasoEspecial?.code === 4 && messages && (
						<Paper>
							<Box padding={3} textAlign="center">
								<h4>Pagos de mi mensualidad</h4>
								{messages.map((msg, index) => (
									<span
										key={index}
										style={{
											display: 'block',
											fontSize: 15,
											margin: 0,
										}}
									>
										{formatMessage(
											msg.value,
											msg.key,
											credito
										)}
									</span>
								))}
							</Box>
						</Paper>
					)}

					{credito?.tipoCasoCredito?.key === 2 ? (
						<MonthlyPaymentFpp
							matches={matches}
							credito={credito}
							formasPagos={formasPagos}
						/>
					) : (
						<MonthlyPayment
							matches={matches}
							credito={credito}
							formasPagos={formasPagos}
						/>
					)}

					{credito?.apoyoFlexible?.tp32benefDispFpp &&
						credito?.apoyoFlexible?.tp33MesesDispProrr &&
						(credito?.apoyoFlexible?.tp32benefDispFpp > 0 ||
							credito?.apoyoFlexible?.tp33MesesDispProrr > 0) &&
						!['00200', '00220', '00010', '00030'].includes(
							credito?.tipoCasoCredito?.wsTipoCaso || ''
						) && (
							<>
								<h4
									style={{
										margin: '25px 0px',
										textAlign: 'center',
									}}
								>
									Flexipago por desempleo
								</h4>
								<Paper
									variant="outlined"
									style={{
										padding: '10px 20px',
										textAlign: 'left',
									}}
								>
									<Grid container>
										<Grid
											item
											xs={12}
											md={2}
											style={{
												display: 'flex',
												justifyContent: matches
													? 'left'
													: 'center',
												alignItems: 'center',
											}}
										>
											<div
												className={
													classes.PaysModuleItem
												}
											>
												<span>Meses disponibles:</span>
											</div>
										</Grid>
										<Grid
											item
											xs={12}
											md
											style={{
												display: 'flex',
												justifyContent: matches
													? 'left'
													: 'center',
											}}
										>
											<div
												className={
													classes.PaysModuleItem
												}
											>
												<span>Prórroga:</span>
												<label>
													{
														credito?.apoyoFlexible
															?.tp33MesesDispProrr
													}{' '}
													meses
												</label>
											</div>
										</Grid>
										{credito?.apoyoFlexible
											?.tp32benefDispFpp && (
											<>
												<Divider
													style={{
														width: matches
															? '100%'
															: 'auto',
														margin: matches
															? '5px 0px'
															: '0px',
													}}
													orientation={
														matches
															? 'horizontal'
															: 'vertical'
													}
													flexItem
												/>
												<Grid
													item
													xs={12}
													md
													style={{
														display: 'flex',
														justifyContent: matches
															? 'left'
															: 'center',
													}}
												>
													<div
														className={
															classes.PaysModuleItem
														}
													>
														<span>FPP:</span>
														<p>
															{
																credito
																	?.apoyoFlexible
																	?.tp32benefDispFpp
															}{' '}
															meses
														</p>
													</div>
												</Grid>
											</>
										)}
									</Grid>
								</Paper>
							</>
						)}

					{!['00200', '00220', '00010', '00030'].includes(
						credito?.tipoCasoCredito?.wsTipoCaso || ''
					) &&
						credito?.apoyoFlexible?.tp32benefDispFpp &&
						credito?.apoyoFlexible?.tp33MesesDispProrr &&
						(credito?.apoyoFlexible?.tp32benefDispFpp > 0 ||
							credito?.apoyoFlexible?.tp33MesesDispProrr > 0) && (
							<>
								<div
									style={{
										maxWidth: 300,
										margin: 'auto',
										marginTop: 40,
										marginBottom: 20,
										display: 'flex',
									}}
								>
									<CustomButton
										label="Otras opciones de pago"
										variant="solid"
										onClick={() => setModal(true)}
									/>
								</div>
							</>
						)}

					{!['00200', '00220', '00010', '00030'].includes(
						credito?.tipoCasoCredito?.wsTipoCaso || ''
					) && (
						<div style={{ textAlign: 'center' }}>
							<p>
								Tu pago se refleja 10 días después de efectuado;
								consúltalo en la sección{' '}
								<Link
									href="#Movimientos"
									onClick={() => setView(2)}
								>
									Mis Movimientos y estados de cuenta.
								</Link>
							</p>
							<Link href="#pagos" onClick={() => setView(3)}>
								Consulta los establecimientos en los que puedes
								realizar tus pagos
							</Link>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default SaldAndMovement;
