export enum DevFondosPresencialEnum {
	E0001 = 'E0001', //EstatusCrmEnum.ABIERTO
	E0002 = 'E0002', //SOLICITAR_CITA
	E0003 = 'E0003', //ABIERTO_CON_CITA
	E0004 = 'E0004', //VALIDACION_DE_DOCUMENTOS
	E0005 = 'E0005', //ESCALADO_A_SUPERVISOR
	E0006 = 'E0006', //DEVOLUCION_AUTORIZADA
	E0007 = 'E0007', //GENERAR_CARTA
	E0008 = 'E0008', //PENDIENTE_POR_APLICAR
	E0009 = 'E0009', //EstatusCrmEnum.CERRADO
	E0010 = 'E0010', //CONSULTAR_DISPONIBILIDAD_SALDO
	E0011 = 'E0011', //ESCALADO_A_ESPECIALISTA
}

export enum DevDemandaEfirmaEnum {
	E0001 = 'E0001', //ABIERTO
	E0002 = 'E0002', //EN_ESPERA_DOCUMENTOS
	E0024 = 'E0024', //EN_ESPERA_DE_ACUSE
	E0003 = 'E0003', //ESCALADO_A_SUPERVISOR
	E0021 = 'E0021', //DEVOLUCION_AUTORIZADA
	E0022 = 'E0022', //PENDIENTE_POR_APLICAR
	E0023 = 'E0023', //CERRADO
}

export enum NuevoPensionadoDigitalSSVEnum {
	E0001 = 'E0001', //ABIERTO
	E0019 = 'E0019', //EN_ESPERA_DOCUMENTOS
	E0020 = 'E0020', //DEVOLUCION_AUTORIZADA
	E0002 = 'E0002', //PENDIENTE_POR_APLICAR
	E0003 = 'E0003', //CERRADO
}

export enum RespuestasSiciEnum {
	EXITO = '10', //10
	ERROR = '100', //100
}

export enum AclaracionesFondoAhorroEnum {
	E0001 = 'E0001', // ABIERTO_ACLARACION
	E0002 = 'E0002', // EN_ESPERA_DOCUMENTOS
	E0003 = 'E0003', // ESCALADO_A_ESPECIALISTA
	E0004 = 'E0004', // CERRADO
}

export enum AclaracionDevoluciónSSVEnum {
	E0001 = 'E0001', // ABIERTO_ACLARACION
	E0002 = 'E0002', // EN_ESPERA_DOCUMENTOS
	E0003 = 'E0003', // ESCALADO_A_ESPECIALISTA
	E0004 = 'E0004', // CERRADO
}

export enum FondoAhorroBeneficiarioUnicoEnum {
	E0001 = 'E0001', // ABIERTO
	E0002 = 'E0002', // SOLICITAR_CITA
	E0003 = 'E0003', // ABIERTO_CON_CITA
	E0004 = 'E0004', // VALIDACION_DE_DOCUMENTOS
	E0005 = 'E0005', // ESCALADO_A_SUPERVISOR
	E0006 = 'E0006', // DEVOLUCION_AUTORIZADA
	E0007 = 'E0007', // GENERAR_CARTA
	E0008 = 'E0008', // PENDIENTE_POR_APLICAR
	E0009 = 'E0009', // CERRADO
	E0010 = 'E0010', // ESCALADO_A_ESPECIALISTA
}

export enum FondoAhorroBenMultiplesEnum {
	E0001 = 'E0001', // ABIERTO
	E0002 = 'E0002', // EN_ESPERA_DOCUMENTOS
	E0003 = 'E0003', // ESCALADO_A_ESPECIALISTA
	E0004 = 'E0004', // PROCEDE_GESTION
	E0005 = 'E0005', // SOLICITAR_CITA
	E0006 = 'E0006', // ABIERTO_CON_CITA
	E0012 = 'E0012', // VALIDACION_DE_DOCUMENTOS
	E0013 = 'E0013', // GESTION_DE_AUTORIZACION
	E0014 = 'E0014', // CERRADO
}

export enum BeneficiariosSSVEnum {
	E0001 = 'E0001', // ABIERTO
	E0002 = 'E0002', // GENERAR_CITA
	E0003 = 'E0003', // ABIERTO_CON_CITA
	E0004 = 'E0004', // VALIDACION_DE_DOCUMENTOS
	E0005 = 'E0005', // INGRESAR_DATOS
	E0006 = 'E0006', // ESCALADO_A_JURIDICO
	E0007 = 'E0007', // DEVOLUCION_AUTORIZADA
	E0008 = 'E0008', // PENDIENTE_POR_APLICAR
	E0009 = 'E0009', // CERRADO
	E0019 = 'E0019', // CONFIRMAR_MARCA
}

export enum ConsultaDatosResponseCodes {
	EXITO = '00', // Trae Grupo y Causal
	ERROR = '07', // Error
}

export enum BusquedaCasosResponseCodes {
	TIENE_CASOS = '0000',
	NO_TIENE_CASOS = '0001',
	INCONSISTENCIA_DATOS = '0002',
}

export enum ConsultaDatosDisponibilidad {
	EXITO = '0000',
	RECHAZO_10 = '10', //Consultar EndoPoint Consulta Saldo Cero
	RECHAZO_10_CAUSAL = '10C', // Va a regresar datos Tipo, Prengunta, mensaje y grupo
	RECHAZO_20_CAUSAL = '20C', // Va a regresar datos Tipo, Prengunta, mensaje y grupo
	RECHAZO_47_CAUSAL = '47C', // Va a regresar datos Tipo, Prengunta, mensaje y grupo
	RECHAZO_53_CAUSAL = '53C', // Va a regresar datos Tipo, Prengunta, mensaje y grupo
	RECHAZO_90_CAUSAL = '90C', // Va a regresar datos Tipo, Prengunta, mensaje y grupo
	RECHAZO_97 = '97', // Va a regresar datos Tipo, Prengunta, mensaje y grupo
	RECHAZO_COMBINADO = '3000C', // Va a regresar mensaje HTML
	ERROR = 'I9000', // Servicio no Disponible
}

export enum CrearCaso {
	EXITO = '0000',
	EXITE_CASO = '0001',
}

export enum CuentaDestinoCodes {
	EXITO = '0000',
}

export enum ValidaFecha {
	EXITO = '10',
}
export enum ValidaPension {
	EXITO = '10',
}
export enum ValidaAfore {
	EXITO = '10',
}

export enum catalogoEntidades {
	EXITO = '0000',
}

export enum catalogoAfore {
	EXITO = '0000',
}
export enum validaCausaRaiz {
	EXITO = '0000',
}
export enum consultaBanco {
	EXITO = '0000',
}

export enum persisteDatos {
	EXITO = '00',
	YA_EXISTE = '03',
	CADUCADO = '05',
}

export enum DisponibilidadEstatusEnum {
	GRUPO1 = '0',
	OTROS_GRUPOS = '201',
	PROCEDENTE = '10',
	NO_PROCEDENTE = '100',
}

export enum RechazosDisponibilidadEnum {
	SIN_RECHAZO = '0',
	RECHAZO_MENOS_5 = '-5',
	RECHAZO_7 = '7',
	RECHAZO_10 = '10',
	RECHAZO_20 = '20',
	RECHAZO_22 = '22',
	RECHAZO_40 = '40',
	RECHAZO_47 = '47',
	RECHAZO_50 = '50',
	RECHAZO_53 = '53',
	RECHAZO_90 = '90',
	RECHAZO_92 = '92',
	RECHAZO_97 = '97',
	RECHAZO_100 = '100',
	RECHAZO_127 = '127',
	RECHAZO_130 = '130',
	RECHAZO_140 = '140',
	RECHAZO_145 = '145',
	RECHAZO_201 = '201',
	RECHAZO_593 = '593',
	RECHAZO_594 = '594',
	RECHAZO_595 = '595',
	RECHAZO_596 = '596',
	RECHAZO_597 = '597',
	RECHAZO_599 = '599',
	RECHAZO_652 = '652',
	RECHAZO_782 = '782',
	RECHAZO_810 = '810',
	RECHAZO_815 = '815',
	RECHAZO_820 = '820',
	RECHAZO_821 = '821',
	RECHAZO_822 = '822',
	RECHAZO_823 = '823',
	RECHAZO_824 = '824',
	RECHAZO_825 = '825',
	RECHAZO_826 = '826',
	RECHAZO_827 = '827',
	RECHAZO_828 = '828',
	RECHAZO_829 = '829',
	RECHAZO_830 = '830',
	RECHAZO_831 = '831',
	RECHAZO_833 = '833',
	RECHAZO_835 = '835',
	RECHAZO_836 = '836',
	RECHAZO_837 = '837',
	RECHAZO_839 = '839',
	RECHAZO_840 = '840',
	RECHAZO_841 = '841',
	RECHAZO_842 = '842',
	RECHAZO_843 = '843',
	RECHAZO_999 = '999',
	RECHAZO_1001 = '1001',
	RECHAZO_1002 = '1002',
	RECHAZO_1003 = '1003',
	RECHAZO_1004 = '1004',
	RECHAZO_1005 = '1005',
	RECHAZO_1006 = '1006',
	RECHAZO_1007 = '1007',
	RECHAZO_1008 = '1008',
	RECHAZO_1009 = '1009',
	RECHAZO_1010 = '1010',
	RECHAZO_1011 = '1011',
	RECHAZO_1012 = '1012',
	RECHAZO_1013 = '1013',
	RECHAZO_1014 = '1014',
	RECHAZO_1015 = '1015',
	RECHAZO_1016 = '1016',
	RECHAZO_1300 = '1300',
	RECHAZO_1301 = '1301',
	RECHAZO_1302 = '1302',
	RECHAZO_2830 = '2830',
	RECHAZO_2836 = '2836',
	RECHAZO_2837 = '2837',
	RECHAZO_2838 = '2838',
	RECHAZO_2839 = '2839',
	RECHAZO_2840 = '2840',
}
