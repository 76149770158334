import { Box, useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../styles';
import CustomButtom from '../../../components/Button';

interface IPersonalData {
	onContinue: () => void;
}
const SuccessFuLuck = (props: IPersonalData) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<>
			<br />
			<Box className={classes.VerificacionCodigoTitle}>
				<h1>¡Bloqueo Exitoso!</h1>
			</Box>
			<br />
			<Box className={classes.VerificacionText}>
				<strong>Tu NIP Infonatel se bloqueo exitosamente,</strong>{' '}
				continua disfrutando de los servicios <br />
				que Mi Cuenta Infonavit tiene para ti.
			</Box>
			<br />
			<Box
				display={'flex'}
				justifyContent={'center'}
				flexDirection={matches ? 'column-reverse' : 'row'}
				alignItems={matches ? 'center' : ''}
			>
				<Box margin={'20px 10px'} width={matches ? '80%' : 250}>
					<CustomButtom
						label={'Finalizar'}
						onClick={() => navigate('/')}
						variant="solid"
					/>
				</Box>
			</Box>
		</>
	);
};

export default SuccessFuLuck;
