import { Paper } from '@mui/material';
import { useContext } from 'react';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import CaseInformationPage from './pages/caseInformationPage';

import { itemCheck } from './interfaces';
import { Dataprovider } from './providerData';
import { useStyles } from './style';
import { getHeaderList } from './utils';
import InfoPage from './pages/infoPage';
import ReturnOptions from './pages/returnOptions';
import NRPRequestPage from './pages/NRPRequest';
import SavingsInformation from './pages/savingsInformationPage';
import SelectReturnMethod from './pages/SelectReturnMethodPage';
import SelectStateClaim from './pages/selectStateClaim';
import QuestionDemand from './pages/questionDemand';
import SelectTypeDemand from './pages/selectTypeDemand';
import UploadCertificatesFiles from './pages/uploadCertificatesFiles';
import PensionResolutionDate from './pages/pensionResolutionDate';
import SelectTypePensionPage from './pages/selectTypePension';
import SelectAforePage from './pages/selectAfore';
import InterbankCLABERequest from './pages/interbankCLABERequest';
import VerifyBanckDetailsPage from './pages/verifyBankDetails';
import UploadFiles from './pages/uploadFiles';
import MessageHSR from './pages/message_HISR_91_92';
import MakeDate from './pages/makeDate';
import { dataReturnSavingsContext } from './contextData';
import MessagePage from './pages/MessagesPage';
import ExceptionPage from './pages/ExceptionPage';

const checksReturnOptions: itemCheck[] | undefined = [
	{
		Id: 1,
		label: 'Por terminación de relación laboral',
		value: 'terminacionLaboral',
		sublabel:
			'El trabajador tiene por lo menos un año sin relación laboral y 50 años de edad.',
	},
	{
		Id: 2,
		label: 'Por resolución de pensión del IMSS',
		value: 'resolucionPensionImss',
		sublabel: 'Dictamen de otorgamiento de pensión emitido por el IMSS.',
	},
	{
		Id: 3,
		label: 'Por plan privado de pensión de aportaciones solo al Infonavit',
		value: 'planPrivadoPension',
		sublabel:
			'Esquema de retiro establecido por el patrón y soportado por el contrato colectivo de trabajo que busca otorgar un beneficio a sus trabajadores.',
	},
	{
		Id: 4,
		label: 'Por resolución de la comisión de inconformidades',
		value: 'porInconformidades',
		sublabel:
			'Dictamen administrativo que emite la Comisión de Inconformidades del Infonavit para que sean devueltas al trabajador las aportaciones que constituyen el Fondo de Ahorro 72-92.',
	},
	{
		Id: 5,
		label: 'Tengo una pensión anterior a julio de 1997',
		value: 'pensionAnterior1997',
		sublabel:
			'Dictamen de otorgamiento de pensión emitido por el IMSS con fecha anterior a julio 1997.',
	},
];

const ReturnSavingsConten = () => {
	const { page } = useContext(dataReturnSavingsContext);
	const style = useStyles();

	return (
		<div>
			<Layout>
				<div>
					<HeaderList
						key="HeaderDevolucionAhorros"
						title="Devolución de mis ahorros"
						date="12 julio 2021"
						list={getHeaderList(page)}
					/>
					<Paper>
						<div className={style.divContainer}>
							{page === 0 && <InfoPage></InfoPage>}
							{page === 1 && (
								<ReturnOptions
									ListChecksReturnOptions={
										checksReturnOptions
											? checksReturnOptions
											: undefined
									}
								></ReturnOptions>
							)}
							{page === 2 && <NRPRequestPage></NRPRequestPage>}
							{page === 3 && (
								<SavingsInformation></SavingsInformation>
							)}
							{page === 4 && (
								<SelectReturnMethod></SelectReturnMethod>
							)}
							{page === 5 && (
								<SelectStateClaim></SelectStateClaim>
							)}
							{page === 6 && <QuestionDemand></QuestionDemand>}
							{page === 7 && (
								<SelectTypeDemand></SelectTypeDemand>
							)}
							{page === 8 && (
								<UploadCertificatesFiles></UploadCertificatesFiles>
							)}
							{page === 9 && (
								<PensionResolutionDate></PensionResolutionDate>
							)}
							{page === 10 && (
								<SelectTypePensionPage></SelectTypePensionPage>
							)}
							{page === 11 && <SelectAforePage></SelectAforePage>}
							{page === 12 && (
								<InterbankCLABERequest></InterbankCLABERequest>
							)}
							{page === 13 && (
								<VerifyBanckDetailsPage></VerifyBanckDetailsPage>
							)}
							{page === 14 && (
								<CaseInformationPage></CaseInformationPage>
							)}
							{page === 15 && <UploadFiles></UploadFiles>}
							{page === 16 && <MessageHSR></MessageHSR>}
							{page === 17 && <MakeDate></MakeDate>}
							{page === 18 && <MessagePage></MessagePage>}
							{page === 19 && <ExceptionPage></ExceptionPage>}
						</div>
					</Paper>
				</div>
			</Layout>
		</div>
	);
};

const ReturnSavings = () => {
	return (
		<Dataprovider>
			<ReturnSavingsConten></ReturnSavingsConten>
		</Dataprovider>
	);
};

export default ReturnSavings;
