import { AlertColor } from '@mui/material';
import { encrypt } from '../../../utils/encrypt';

export const tabsOptions = [
	{
		id: 1,
		title: 'Datos de contacto',
		disabled: false,
		checked: false,
		columns: 4,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: 2,
		title: 'Domicilio',
		disabled: false,
		checked: false,
		columns: 4,
		orderMD: 1,
		orderSM: 2,
	},
	{
		id: 3,
		title: 'Referencia',
		disabled: false,
		checked: false,
		columns: 4,
		orderMD: 2,
		orderSM: 3,
	},
];

export const defaultContactInfo = {
	email: '',
	emailAlternativo: '',
	phoneNumber: '',
	verificationSms: false,
	verificationEmail: false,
	verificationEmailAlternate: false,
	enableUpdateEmail: false,
	enableUpdatePhone: false,
};

export const defaultEmailForm = {
	email: '',
	confirm_email: '',
};

export const defaultMobileForm = {
	phoneNumber: '',
	confirm_phoneNumber: '',
};

export const defaultReferenceForm = {
	name: '',
	firstSurname: '',
	secondSurname: '',
	phoneNumber: '',
};

export const defaultAddressForm = {
	cp: '',
	state: '',
	delegacion: '',
	colony: '',
	street: '',
	noExterior: '',
	noInterior: '',
};

export const address_base64 = async (values: any, key: string) => ({
	nss: await encrypt(values.nss, key),
	cp: await encrypt(values.cp, key),
	state: await encrypt(values.state, key),
	delegacion: await encrypt(values.delegacion, key),
	colony: await encrypt(values.colony, key),
	street: await encrypt(values.street, key),
	noExterior: await encrypt(values.noExterior, key),
	noInterior: await encrypt(values.noInterior || ' ', key),
});

export const reference_base64 = async (values: any, key: string) => ({
	nss: await encrypt(values.nss, key),
	name: await encrypt(values.name, key),
	firstSurname: await encrypt(values.firstSurname, key),
	secondSurname: await encrypt(values.secondSurname, key),
	phoneNumber: await encrypt(values.phoneNumber, key),
});

export interface IAlert {
	message: string;
	color: AlertColor;
}
