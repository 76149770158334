import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	icon: {
		color: theme.palette.primary.main,
		fontSize: 18,
		'&:hover': {
			cursor: 'pointer'
		}
	},
	container: {
		display: 'flex', 
		alignItems: 'center', 
		gap: '8px'
	},

}));
