import theme from '../../../../config/theme';

export const registerList = [
	<>
		Obtén tus fichas de depósito{' '}
		<b style={{ color: theme.palette.info.main }}>cada mes</b>, ingresando a
		Mi Cuenta Infonavit, en el menú del lado superior, en la sección de{' '}
		<b style={{ color: theme.palette.info.main }}>Mi ahorro</b>, ingresa al
		servicio de{' '}
		<b style={{ color: theme.palette.info.main }}>
			Haz Aportaciones Extraordinarias
		</b>
		, seleccionando la opción{' '}
		<b style={{ color: theme.palette.info.main }}>
			Hacer aportación, captura el importe de tu ahorro mínimo
		</b>{' '}
		y obtener tu ficha de depósito referenciado.
	</>,
	'Realiza varios pagos, siempre y cuando cubras el monto mínimo mensual.',
];
