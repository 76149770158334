import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	iconsImg: {
		width: 18,
		marginRight: 5,
	},
	txtPrincipales: {
		color: '#333333',
		fontSize: '15px !important',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		[theme.breakpoints.down('sm')]: {
			fontSize: '13px !important',
		},
	},
	divPrincipal: {
		padding: '40px 80px',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '30px',
		},
	},
	divChecks: {
		paddingTop: '10px',
	},
	contentFileDataButtons: {
		display: 'flex',
		justifyContent: 'center',
		marginBlock: 10,
		marginBottom: 50,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
		},
		[theme.breakpoints.down('md')]: {},

		'&>button': {
			[theme.breakpoints.down('sm')]: {
				margin: '20px 0px !important',
				width: '100% !important',
			},
		},
	},
	root: {
		'& .MuiLinearProgress-barColorPrimary': {
			backgroundColor: 'blue',
		},
	},
	contentFile: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	contentFileData: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
		[theme.breakpoints.down('md')]: {},
	},
	contentFileDataTitle: {
		textAlign: 'left',
		fontSize: 15,
		minWidth: 250,
		[theme.breakpoints.down('sm')]: {
			fontSize: 15,
			minWidth: 200,
		},
		[theme.breakpoints.down('md')]: {},
	},
	contentFileDataText: {
		fontSize: 15,
		color: '#7F7F7F',
		[theme.breakpoints.down('sm')]: {
			fontSize: 13,
			textAlign: 'left',
		},
		[theme.breakpoints.down('md')]: {},
	},
	contentFileDataLink: {
		color: '#D1001F',
		fontSize: 14,
		[theme.breakpoints.down('sm')]: {
			fontSize: 13,
		},
	},
}));
