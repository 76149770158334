import { Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/Button';

const PayOtherService = () => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const navigate = useNavigate();

	return (
		<Paper style={{ marginTop: 30, padding: 20 }}>
			<h3 style={{ color: '#333333', textAlign: 'center', marginBottom: 30 }}>
				El pago que realizaste se encuentra aplicado a otro crédito
			</h3>
			<p
				style={{
					color: '#333333',
					textAlign: 'center',
					margin: 0,
					marginBottom: 4,
				}}
			>
				Haz una cita para hacer la aclaración.
			</p>
			<p style={{ color: '#333333', textAlign: 'center', margin: 0 }}>
				Sítieles varios pagos que aclarar, lleva a tu cita todos los comprobantes
				de pago.
			</p>
			<div
				style={{
					display: 'flex',
					flexDirection: matches ? 'column-reverse' : 'initial',
					justifyContent: 'center',
					margin: '30px 0px',
				}}
			>
				<CustomButton
					styles={{
						width: matches ? 'auto' : 250,
						margin: '10px 10px',
						display: 'inline-flex',
					}}
					label={'Salir'}
					variant="outlined"
					onClick={() => navigate('/')}
				/>

				<CustomButton
					styles={{
						width: matches ? 'auto' : 250,
						margin: '10px 10px',
						display: 'inline-flex',
					}}
					label={'Hacer cita'}
					variant="solid"
					onClick={() => navigate('/')}
				/>
			</div>
		</Paper>
	);
};

export default PayOtherService;
