import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import { HeaderList } from '../../components/HeaderList';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';

interface Props {
	setPage: (data: number) => void;
	status: any;
}

const Status = ({ setPage, status }: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Box>
			<HeaderList title="Sigue tu caso y adjunta documentos" />
			<Paper sx={{ pt: 10, pb: 15, pr: 15, pl: 15 }}>
				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: matches ? 'column' : 'row',
					}}
				>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<h2 style={{ color: '#293990', textAlign: 'center' }}>
							Gracias por utilizar los servicios digitales del Infonavit
						</h2>
					</Grid>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Número de caso</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: 'right' }}
					>
						<label>{status.caso}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: matches ? 'column' : 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Fecha de solicitud</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: 'right' }}
					>
						<label>{status.fechaCreacion}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: matches ? 'column' : 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Servicio</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: 'right' }}
					>
						<label>{status.tipificacion}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: matches ? 'column' : 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Estatus</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: 'right' }}
					>
						<label>{status.estatus}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: matches ? 'column' : 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Fecha de estatus</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: 'right' }}
					>
						<label>{status.fechaModificacion}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />

				<div
					style={{
						maxWidth: 260,
						display: 'flex',
						margin: 'auto',
						marginTop: 30,
					}}
				>
					<CustomButton
						variant="solid"
						label={'Finalizar'}
						onClick={() => {
							setPage(0);
						}}
					/>
				</div>
			</Paper>
		</Box>
	);
};

export default Status;
