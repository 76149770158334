import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		backgroundColor: '#F8F8F8',
	},
	contConfirm: {
		textAlign: 'center',
		paddingTop: 15,
		[theme.breakpoints.down('sm')]: {
			paddingTop: 0,
		},
	},
	subContainer: {
		width: '100%',
		backgroundColor: '#FFFFFF',
		borderRadius: 8,
		boxShadow: '0px 5px 30px #00000017',
		marginTop: 15,
		padding: 10,
		[theme.breakpoints.down('sm')]: {
			overflow: 'auto',
			paddingTop: 20,
			paddingBottom: 20,
			border: '0.5px solid #707070',
			borderRadius: 2,
		},
	},
	txtInicial: {
		color: theme.palette.info.main,
		fontWeight: 'bold',
	},
	iconsImg: {
		width: 20,
		marginRight: 5,
	},
	txtPrincipales: {
		color: '#333333',
		fontSize: '16px !important',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px !important',
		},
	},
	txtReq: {
		color: '#333333',
		fontSize: '14px !important',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px !important',
		},
	},
	txtDescargas: {
		verticalAlign: 'top',
		cursor: 'pointer',
		letterSpacing: 0,
		opacity: 1,
	},
	txtImportante: {
		fontSize: '14px !important',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px !important',
		},
	},
	txtContainer: {
		color: '#000000',
		fontSize: 18,
		letterSpacing: 0,
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
		},
	},
	txtInfo: {
		color: '#333333',
		fontSize: 16,
		textAlign: 'right',
		[theme.breakpoints.down('sm')]: {
			fontSize: 14,
		},
	},
	txtBlue: {
		fontSize: '18px',
		color: '#293990',
		letterSpacing: '0px',
		fontWeight: 'bold',
	},
	txtBlueSmall: {
		fontSize: '15px',
		color: '#293990',
		letterSpacing: '0px',
		fontWeight: 'bold',
	},
	txtInfoBlack: {
		fontSize: '14px',
		color: 'black',
		letterSpacing: '0px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px !important',
		},
	},
	checkHeader: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 7,
	},
	checkHeaderImg: {
		width: 18,
		marginRight: 5,
	},
	checkHeaderText: {
		verticalAlign: 'top',
		color: '#333',
		fontSize: 14,
		padding: 0,
		margin: 0,
	},
	txtRed: {
		color: '#D1001F',
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	labelContainerInput: {
		margin: 0,
		color: '#7E8094',
		fontSize: 14,
	},
	links: {
		color: '#D1001F',
		float: 'right',
		letterSpacing: 0,
		fontSize: 18,
		paddingTop: 15,
		paddingRight: 15,
		opacity: 1,
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
		},
	},
	inputs: {
		width: '80%',
		marginBottom: 20,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: 10,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginBottom: 10,
		},
	},
	backgroundContenMargin: {
		paddingTop: '4%',
		marginLeft: '15%',
		marginRight: '15%',
		paddingBottom: '2%',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '10%',
			marginRight: '10%',
		},
	},
	divTable: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	divServices: {
		width: '70%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	divName: {
		width: '30%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	divBtn: {
		width: '100%',
		maxWidth: '260px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	divPagos: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
	divPagosOnly: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		marginTop: '30px',
	},
	contPagos: {
		width: '48%',
		height: '70px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: '1px solid #C7C6C5',
		borderRadius: '3px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginTop: '15px',
			justifyContent: 'left',
			padding: '10px',
		},
	},
	divButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '15px',
		textAlign: 'center',
	},
	txtP: {
		verticalAlign: 'top',
	},
	divAlert: {
		padding: '25px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '5px',
		},
		'&>.contribution-actions': {
			width: 430,
			display: 'flex',
			justifyContent: 'space-evenly',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				flexDirection: 'column',
			},
			'&>button': {
				height: 37,
			},
		},
	},
	summary: {
		display: 'flex',
		justifyContent: 'space-between',
		width: 400,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	divTextMessage: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: '10px',
		'& ul': {
			margin: '0px !important'
		},
	},
	divTextVideo: {
		paddingTop: '3%',
		marginLeft: '10%',
		marginRight: '10%',
	}
}));
