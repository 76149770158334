//TODO
export const RETURN_CODE = {
	RC_10007: '10007',
	RC_10: '10',
	CORRECT: '0000',
	NO_RESGITRADO_AGRO: '1504',
	//Temporales
	SIN_RELACION_LABORAL: '2102',
	NO_POINTS: '2102',
	ERROR: '1102',
};
