import API from '..';
import {
	ICalculadoraRes,
	IConsultaAutoproduccion,
	IConsultaAutoproduccionRes,
	IConsultarHistorialRes,
	IConsultarProductosMontosData,
	IMevi,
	IObtenerCatRes,
	IPersonInformation,
	IProductosMontos,
	ITablaAmortizacionData,
	IEstatusCuestionario,
	IBusquedaCuestionario,
	ICompletoCuestionario,
	ITablaAmortizacionRes,
	ITrazabilidadCRM,
	IListaElementos,
	IObtenerListaElementosRes,
	IDataconsultarAgroRegistroRes,
	IPagosAgroconsulta,
} from '../../interfaces/creditRequest';
import { MIUC_MODELS } from '../../pages/MIUC/constants/models';

export enum DESTINO_PERFILAMIENTO_ENUM {
	MIUC = 'MIUC',
	EN_PROCESO_CANCELACION = 'EN_PROCESO_CANCELACION',
	SOLICITUD_CANCELADA = 'SOLICITUD_CANCELADA',
	VALIDAR_IDENTIDAD = 'VALIDAR_IDENTIDAD',
	EN_ESPERA_DE_DOCUMENTOS = 'EN_ESPERA_DE_DOCUMENTOS',
	VALIDACION_DOCUMENTOS_EN_PROCESO = 'VALIDACION_DOCUMENTOS_EN_PROCESO',
	DOCUMENTACION_COMPLETA = 'DOCUMENTACION_COMPLETA',
	COMO_VA_MI_TRAMITE = 'COMO_VA_MI_TRAMITE',
	TIENE_CREDITO = 'TIENE_CREDITO',
}

export interface IProfileResponse {
	destino?: DESTINO_PERFILAMIENTO_ENUM;
	tipoCredito?: string;
	casoActivo?: {
		tipificacion?: string;
		noCaso?: string;
		estatus?: string;
		descripcion?: string;
		razonEstatus?: string;
		descripcionEstatus?: string;
		fecha?: string;
		fechaEstatus?: string;
	};
	opcionCancelar?: boolean;
	opcionImpresion?: boolean;
	urlMEI?: string;
	urlWFI?: string;
	conyuge?: {
		nss?: string;
		tipoAsociacion: string;
	};
    bajaTemporal?: string;
}

export interface IMIUCProfileData {
	modelo: MIUC_MODELS;
	estadoMunicipio: string;
}

export interface PerfilamientoMIUCResponseModel {
	titular: MIUCUserResponseModel;
	conyuge: MIUCUserResponseModel;
	destinosAplicables: IDestino[];
}
export interface IDestino {
	modelo: string;
	codigo: string;
	descripcion: string;
	estatus: boolean;
	validaciones: string[];
}
export interface MIUCUserResponseModel {
  code: string;
	sexo: string; // temporal
	nss: string;
	tipoAsociacion?: string;
	estatusAsociacion?: string;
	mensajeAsociacion?: string;
	stageId: string;
	esPrecaAnticipada: boolean;
	esSegundoCredito: boolean;
	puntosMinimos: string;
	sueldo: string;
	esPolicia: boolean;
	nrp: string;
	estadoMunicipio: string;
	bimestreTope: string;
	valorUMA: string;
	salarioDiario: string;
	edad: string;
	saldoAportaciones: string;
	saldoSAR92: string;
	saldoSAR97: string;
	saldoSARTotal: string;
	tipoTrabajador: string;
	fechaPreca: string;
	bimestresContinuos: string;
	bimestresContinuosMismoPatron: string;
	puntosEdad: string;
	puntosSalario: string;
	puntosEdadSalario: string;
	puntosUsuarioTotales: string;
	puntosTipoTrabajador: string;
	puntosBono: string;
	puntosPatron: string;
	puntosPatronEstabilidad: string;
	puntosPatronComportamiento: string;
	puntosPatronContexto: string;
	puntosPatronTotales?: string;
	puntosSaldoSAR: string;
	puntosBimestresContinuos: string;
	puntosTotales: string;
	indiceRiesgo: string;
	mesPara116Puntos: string;
	anioPara116Puntos: string;
	nombreMesPara116: string;
	puntosFaltantesPara116: string;
	montoPensionAlimenticia: string;
	// mevi: IMevi;
	puntosMujer: string;
  creditoHipotecario: string;
}
export interface SaldoMCIResponseModel {
	fecha: string;
	rfc: string;
	curp: string;
	nombre: string;
	apellidoPaterno: string;
	apellidoMaterno: string;
	afore: string;
	saldoSAR92: string;
	saldoSAR97: string;
	saldoSARTotal: string;
}

export interface IConsultarHistorialParams {
	nss: string;
	contacto: {
		fechaNacimiento: string;
		nombre: string;
		segundoNombre: string;
		apPaterno: string;
		apMaterno: string;
		rfc: string;
		curp: string;
		telefonoCelular: string;
		email: string;
		cp: string;
		estadoId: string;
		municipio: string;
		colonia: string;
		calle: string;
		numeroExterior: string;
		numeroInterior: string;
	};
	referencia: {
		tieneTDC: boolean;
		tieneHipotecario: boolean;
		tieneAuto: boolean;
		salarioDiario: string;
		ultimos4DigitosTDC: string;
		salarioMensual?: string;
	};
}

export interface IDataconsultarAgroconsultaRes {
	codigo: string;
	mensaje: string;
	nombre: string;
	nrp: string;
	edad: string;
	salarioDiario: string;
	valorUMA: string;
	numEdoPat: string;
	producto: string;
	metaAhorro: string;
	ahorroMensual: string;
	fechaInscripcion: string;
	fechaInicioAhorro: string;
	fechaFinalAhorro: string;
	fechaCorte: string;
	sitioRegistrado: string;
	fechaCierre: string;
	puntosTotalCorte: string;
	saldoAhorro: string;
	numeroMesesContinuos: string;
	contadorDepositos: string;
	pagos: IPagosAgroconsulta[];
}

export interface IConsultaAportacionesVoluntarias {
	resultadoOperacion: string;
	diagnosticoProceso: string;
	marcaCreditoAV: string;
	diagnosticoAfore: string;
	tramiteJudicial: string;
	saldoAportacionesVoluntariasCP: string;
	saldoAportacionesVoluntariasPILP: string;
}

export interface IAgroindustryDatosGenerales {
	sitioRegistrado: string;
	nss: string;
	ahorroMensual: string;
	saldoAhorro: string;
	numeroMesesContinuos: string;
	puntos: number;
	paramComponent: string;
}

export interface IEstados {
  clave: string;
  descripcion: string;
}

interface IMainResponse<I> {
	code: string;
	message: string;
	data: I;
}

interface IDefaultRequest {
	data: string;
}
class MIUC extends API {
	getProfile(): Promise<IMainResponse<IProfileResponse>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-perfilamiento/perfilamiento-inicial`,
				method: 'POST',
			})
		);
	}

	getMIUCProfile(
		data: IDefaultRequest
	): Promise<IMainResponse<PerfilamientoMIUCResponseModel>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-perfilamiento/perfilamiento-miuc`,
				method: 'POST',
				data,
			})
		);
	}

	getBalance(): Promise<IMainResponse<SaldoMCIResponseModel>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-perfilamiento/consultar-saldo`,
				method: 'POST',
			})
		);
	}

	consultarHistorial(
		data: IDefaultRequest
	): Promise<IMainResponse<IConsultarHistorialRes>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-perfilamiento/buro/registrar`,
				method: 'POST',
				data,
			})
		);
	}
	getUrlEncripted(
		data: IDefaultRequest
	): Promise<IMainResponse<{ path: string }>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-perfilamiento/auxiliares/encriptar-url`,
				method: 'POST',
				data,
			})
		);
	}
	consultarProductosMontos(
		data: IConsultarProductosMontosData
	): Promise<IMainResponse<IProductosMontos>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/consultar-productos/montos`,
				method: 'POST',
				data,
			})
		);
	}
	getPDFCICB(data: IDefaultRequest): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/agroindustrias/obtener-alternativa-financiera-pdf`,
				method: 'POST',
				data,
			})
		);
	}
	obtenerCAT(data: IDefaultRequest): Promise<IMainResponse<IObtenerCatRes>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-perfilamiento/consultar-cat`,
				method: 'POST',
				data,
			})
		);
	}
	calculadora(
		data: IDefaultRequest
	): Promise<{ result: IMainResponse<ICalculadoraRes> }> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/calculadora`,
				method: 'POST',
				data,
			})
		);
	}
	statusCuestionario(
		data: IDefaultRequest
	): Promise<IMainResponse<IEstatusCuestionario>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/md-cuestionario/consultar-estatus`,
				method: 'POST',
				data,
			})
		);
	}
	tablaAmortizacion(
		data: IDefaultRequest
	): Promise<IMainResponse<ITablaAmortizacionRes>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/consultar-tabla-amortizacion`,
				method: 'POST',
				data,
			})
		);
	}
	trazabilidadCRM(
		data: IDefaultRequest
	): Promise<IMainResponse<ITrazabilidadCRM[]>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-perfilamiento/generar-trazabilidad`,
				method: 'POST',
				data,
			})
		);
	}
	searchCuestionario(
		data: IDefaultRequest
	): Promise<IMainResponse<IBusquedaCuestionario>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/md-cuestionario/buscar`,
				method: 'POST',
				data,
			})
		);
	}
	consultarAutoproduccion(
		data: IDefaultRequest
	): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/consultar-productos/autoproduccion`,
				method: 'POST',
				data,
			})
		);
	}
	consultarMejoraDirecta(data: IDefaultRequest): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/consultar-productos/mejora-directa`,
				method: 'POST',
				data,
			})
		);
	}
	encolaCuestionario(
		data: IDefaultRequest
	): Promise<IMainResponse<IBusquedaCuestionario>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/md-cuestionario/encolar`,
				method: 'POST',
				data,
			})
		);
	}
	consultaMejoraVit(data: IDefaultRequest): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/consultar-productos/micromejoras-mejoras-mayores`,
				method: 'POST',
				data,
			})
		);
	}
	completoCuestionario(
		data: IDefaultRequest
	): Promise<IMainResponse<ICompletoCuestionario>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/md-cuestionario/consultar-completo`,
				method: 'POST',
				data,
			})
		);
	}
	obtenerListaElementos(data: IListaElementos): Promise<IMainResponse<IObtenerListaElementosRes>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/taller-saber-mas/obtener-lista-elementos`,
				method: 'POST',
				data,
			})
		);
	}

	consultarAgroconsulta(
		data: IDefaultRequest
	): Promise<IMainResponse<IDataconsultarAgroconsultaRes>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-perfilamiento/agroindustrias/consultar`,
				method: `POST`,
				data,
			})
		);
	}

	getRegistroPDF(data: { data: string }): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/agroindustrias/obtener-registro-pdf`,
				method: 'POST',
				data,
			})
		);
	}
    actualizarElemento(data: { data: string }): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/taller-saber-mas/actualizar-elemento`,
				method: 'POST',
				data,
			})
		);
	}

	getComprobanteRegistroPDF(data: { data: string }): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/agroindustrias/obtener-comprobante-pdf`,
				method: 'POST',
				data,
			})
		);
	}
    obtenerCertificado(data: { data: string }): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/taller-saber-mas/obtener-certificado-pdf`,
				method: 'POST',
				data,
			})
		);
	}

	getMovimientoPDF(data: { data: string }): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/agroindustrias/obtener-movimientos-pdf`,
				method: 'POST',
				data,
			})
		);
	}

	getInscritoPDF(data: { data: string }): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/agroindustrias/obtener-inscrito-pdf`,
				method: 'POST',
				data,
			})
		);
	}

	consultarAgroRegistro(data: { data: string }): Promise<IMainResponse<IDataconsultarAgroRegistroRes>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/agroindustrias/registrar`,
				method: `POST`,
				data,
			})
		);
	}

	consultarAportaciones(data: { data: string }): Promise<IMainResponse<IConsultaAportacionesVoluntarias>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/aportaciones-voluntarias`,
				method: `POST`,
				data,
			})
		);
	}

	consultarBitacora(data: { data: string }): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/aportaciones-voluntarias/bitacora`,
				method: `POST`,
				data,
			})
		);
	}
  
  consultarTiposCredito(data: IDefaultRequest): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/auxiliares/consultar-tipos-credito`,
				method: `POST`,
        data,
			})
		);
	}

  consultarEstados(): Promise<IMainResponse<{ estados: IEstados[] }>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/catalogos/consultar-estados`,
				method: `POST`,
			})
		);
	}

  consultarUnidades(data: IDefaultRequest): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/catalogos/consultar-unidades`,
				method: `POST`,
        data,
			})
		);
	}

  consultarEntidadesFinancieras(): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/catalogos/consultar-entidades-financieras`,
				method: `POST`,
			})
		);
	}
  
  consultarEntidadesParticipantes(): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/catalogos/consultar-entidades-participantes`,
				method: `POST`,
			})
		);
	}
  
  descargarCertificadoApoyo(): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/pdf/certificado/apoyo`,
				method: 'POST',
			})
		);
	}

  descargarConstanciaElegibilidad(): Promise<IMainResponse<any>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-adicionales/md-cuestionario/obtener-constancia-elegibilidad-pdf`,
				method: 'POST',
			})
		);
	}

	trazabilidadSueloConstruccion(
		data: IDefaultRequest
	): Promise<IMainResponse<ITrazabilidadCRM[]>> {
		return this.requestWrapper(
			this.request({
				url: `/v1/miuc-perfilamiento/suelo-construccion`,
				method: 'POST',
				data,
			})
		);
	}
}

export const apiMIUC = new MIUC('');
