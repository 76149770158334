import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			zIndex: '1',
			position: 'fixed',
			background: 'white',
			top: '3%',
			left: '5%',
			maxHeight: '95vh',
			width: '90%',
			overflow: 'scroll',
			boxShadow:
				'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
			padding: '30px',
			borderRadius: '10px',
		},
		divContainer: {
			width: '40%',
			height: 'auto',
			marginBottom: '50px',
			borderTopLeftRadius: '22px',
			borderTopRightRadius: '22px',
			boxShadow:
				'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
		},
		title: {
			fontWeight: 'bold',
			color: 'white',
			fontSize: '14px',
			position: 'relative',
			background: '#03045e',
			margin: '0',
			height: '40px',
			padding: '9px ',
			textAlign: 'center',
		},
		close: {
			position: 'absolute',
			top: '-15px',
			right: '30px',
			fontSize: '23px',
			color: 'black',
			cursor: 'pointer',
		},
		descargar: {
			position: 'absolute',
			top: '17px',
			right: '70px',
			fontSize: '14px',
			color: 'red',
			cursor: 'pointer',
			margin: '0px',
			textDecoration: 'underline',
		},
		tasa: {
			fontWeight: 'bold',
			color: 'white',
			fontSize: '14px',
			background: '#959595',
			margin: '0',
			height: '40px',
			padding: '10px',
		},
		boldClass: {
			color: 'black !important',
			textTransform: 'capitalize',
			fontWeight: '700 !important',
			width: '9%',
		},
		cell: {
			color: '#7F7F7F !important',

			textTransform: 'capitalize',
		},
	})
);
