import { mappedEstatus } from '../../MyComplaints/utils';

export const columns = [
	{
		title: 'Número de queja',
		key: 'caso',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		title: 'Fecha de solicitud',
		key: 'fechaCreacion',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
		renderValue: (value: string) => {
			let day = value.substring(6);
			let month = value.substring(4, 6);
			let year = value.substring(0, 4);

			return `${day}/${month}/${year}`;
		},
	},
	{
		title: 'Estatus',
		key: 'descripcionEstatus',
		styleTitle: { fontWeight: 'bold' },
		alignDescription: 'right',
	},
	{
		title: 'Descripción',
		key: 'estatus',
		styleTitle: { fontWeight: 'bold' },
		styleDescription: { maxWidth: 300 },
		alignDescription: 'right',
		renderValue: (value: string) => mappedEstatus(value).descripcion2,
	},
];
