const download = require('downloadjs');

interface IDownload {
	payload: string | undefined;
	name: string;
}

interface IDownloadGeneric {
	payload: string | undefined;
	name: string;
	type?: 'PDF' | 'Excel';
}

type FileType = 'pdf' | 'xml';

export const downloadPDF = ({ payload, name }: IDownload) => {
	downloadGeneric({ payload, name }, 'pdf');
};
export const downloadXML = ({ payload, name }: IDownload) => {
	downloadGeneric({ payload, name }, 'xml');
};
// export const downloadPDF = ({ payload, name }: IDownload) => {
// 	download(
// 		'data:application/pdf;base64,' + payload,
// 		`${name || 'file'}.pdf`,
// 		'application/pdf'
// 	);
// };
const downloadGeneric = ({ payload, name }: IDownload, type: FileType) => {
	download(
		`data:application/${type};base64,` + payload,
		`${name || 'file'}.${type}`,
		`application/${type}`
	);
};
export const downloadPDFCartaCancellation = (
	file: string | ArrayBuffer | null,
	name: string
) => {
	download(file, `${name || 'file'}.pdf`, 'application/pdf');
};

export const getBase64Image = (img: any) => {
	let canvas = document.createElement('canvas');
	canvas.width = img.width;
	canvas.height = img.height;
	let ctx = canvas.getContext('2d');
	ctx?.drawImage(img, 0, 0);
	let dataURL = canvas.toDataURL();
	return dataURL;
};

// function image2Base64(img:any) {
// 	var canvas = document.createElement('canvas');
// 	canvas.width = img.width;
// 	canvas.height = img.height;
// 	var ctx = canvas.getContext('2d');
// 	ctx.drawImage(img, 0, 0, img.width, img.height);
// 	var dataURL = canvas.toDataURL('image/png');
// 	return dataURL;
// }

export const downloadFileGeneric = ({
	payload,
	name,
	type,
}: IDownloadGeneric) => {
	let data = `data:application/pdf;base64,${payload}`;
	let strFileName = `${name || 'file.pdf'}`;
	let strMimeType = 'application/pdf';

	if (type === 'Excel') {
		data = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${payload}`;
		strMimeType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		strFileName = `${name || 'file.xlsx'}`;
	}

	download(data, strFileName, strMimeType);
};
