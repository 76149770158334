import { useEffect, useState } from 'react';
import { creditRequestAPI } from '../../../api/modules/CreditRequest';
import { CODES_RESPONSE } from '../../../config/miucConstants';
import { IResponseEntidades } from '../../../interfaces/creditRequest';
import { HeaderList } from '../../HeaderList';
import ModalLoading from '../../ModalLoading';

const CICB = () => {
	const [loading, setLoading] = useState(false);
	const [entidades, setEntidades] = useState<IResponseEntidades[]>([]);

	useEffect(() => {
		handlerObtenerEntidadesFinancieras();
	}, []);

	const handlerObtenerEntidadesFinancieras = async () => {
		setLoading(true);
		creditRequestAPI
			.obtenerEntidadesFinancieras()
			.then((response) => {
				if (response?.result?.code === CODES_RESPONSE.CORRECTO) {
					setEntidades(response?.result?.data?.entidades);
				}
				setLoading(false);
			})
			.catch((error) => setLoading(false))
			.finally(() => setLoading(false));
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<HeaderList
				listSizeIcon={18}
				list={entidades.map((item: IResponseEntidades) => (
					<span key={item.nrp}>{item.razonSocial}</span>
				))}
			/>
		</>
	);
};

export default CICB;
