import Header from '../../../components/Header';
import { useGeneralStyles } from '../../styles/general.styles';
import { Box, Divider } from '@mui/material';
import CustomAlert from '../../../../../components/CustomAlert';
import HelpPanel from '../../../components/HelpPanel';
import { RoundBtn } from '../../../components/RoundBtn/roundBtn';
import ModalLoading from '../../../../../components/ModalLoading';
import ProductsMiuc from '../../../components/ProductsMiuc';
import { useStyles } from '../styles/styles';
import { rowsTitleSeguro } from '../../../utils/selectionResume.utils';
import { useSecureCreditTable } from '../hooks/useSecureCreditTable';

export const SecureCreditTable = () => {
	const {
		loading,
		next,
		returnStep,
		setSelectProduct,
		selectProduct,
		products,
		newStep,
		disableButton,
    AlertInput,
    getAlertStatus,
	} = useSecureCreditTable();

	const generalStyle = useGeneralStyles();
	const classes = useStyles();

	return (
		<Box>
			<Header steps={newStep}></Header>
			<Box className={generalStyle.content}>
				{/* <h3>Este es el crédito que tenemos para ti</h3>
				<p>
					Ten en cuenta que al momento de solicitar tu crédito deberás
					tener una relación laboral vigente.
				</p>

				<Box className={classes.container}>
					<Box>
						{mockRequestCredit['IND']?.icon(true, 24)}
						<label>{mockRequestCredit['IND']?.description}</label>
					</Box>
					<Box>
						{mockRequestCredit['CS']?.icon(true, 24)}
						<label>{mockRequestCredit['CS']?.description}</label>
					</Box>
				</Box> */}
        {
          getAlertStatus().status && (
            <CustomAlert
              show={getAlertStatus().status}
              severity={'warning'}
              message={getAlertStatus().message}
            />
          )
        }
				<Box sx={{ maxWidth: '100%' }}>
          <AlertInput />
					<ProductsMiuc
						keys={rowsTitleSeguro}
						products={products}
						keyHeader={'producto'}
						selectProduct={selectProduct}
						setSelectProduct={setSelectProduct}
					/>
				</Box>
				<Divider sx={{ marginY: '32px' }} />
				<div className={generalStyle.bottonsContainer}>
					<RoundBtn variant="outlined" onClick={() => returnStep()}>
						Regresar
					</RoundBtn>
					<RoundBtn
						disabled={disableButton}
						variant="contained"
						onClick={() => next()}
					>
						Continuar
					</RoundBtn>
				</div>
			</Box>
			<ModalLoading loading={loading} />
		</Box>
	);
};
