import {
	FormControl,
	Grid,
	IconButton,
	MenuItem,
	Paper,
	Select,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import TextInput from '../../components/TextInput';
import InfoIcon from '@mui/icons-material/Info';
import CustomButton from '../../components/Button';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import ModalLoading from '../../components/ModalLoading';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { IAlert } from '../../interfaces/alert';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { apiDeedsDelivery } from '../../api/modules/Deeds';
import CustomAlert from '../../components/CustomAlert';
import { useBitacora } from '../../hooks/useBitacora';

interface Props {
	caso: object;
	setPage: (data: number) => void;
	setCaso: (data: any) => void;
}
interface IDataEstados {
	id: string;
	nombre: string;
}
interface IDataCESI {
	Nombre: string;
	delCesis: string;
	idDelEdo: string;
	idPlaza: string;
}

const DataForm = ({ setPage, setCaso, caso }: Props) => {
	const { regBitacoraNotOld } = useBitacora();
	const theme = useTheme();
	const matchesm = useMediaQuery(theme.breakpoints.down('md'));
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [estado, setEstado] = useState('');
	const [centro, setCentro] = useState('');
	const [open, setOpen] = React.useState(false);
	const [flagOpen, setFlagOpen] = useState(0);
	const [loading, setLoading] = useState(false);
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [colonia, setColonia] = useState<Array<string>>([]);
	const [dataEstados, setDataEstados] = useState<Array<IDataEstados>>([]);
	const [dataCesi, setDataCesi] = useState<Array<IDataCESI>>([]);
	const [form, setForm] = useState({
		cp: '',
		estado: '',
		municipio: '',
		colonia: '',
		calle: '',
		num_ext: '',
		num_int: '',
	});

	useEffect(() => {
		const req = async () => {
			try {
				setLoading(true);
				const { result } = await apiDeedsDelivery.getEstados();
				setDataEstados(result);
			} catch (error) {
				setAlert({
					show: true,
					message:
						INTERNAL_MESSAGES.ERROR_GETTING_DATA,
					severity: 'error',
				});
			} finally {
				setLoading(false);
			}
		};
		req();
	}, []);

	const crearCaso = async () => {
		try {
			setLoading(true);
			let domicilio =
				form.estado +
				' ' +
				form.municipio +
				' ' +
				form.colonia +
				' ' +
				form.calle +
				' ' +
				form.cp +
				' ' +
				form.num_ext +
				' ' +
				form.num_int;
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const credito_base64 = await encrypt(credit.toString() || '', key);
			const domicilio_base64 = await encrypt(domicilio.trim(), key);
			const estado_base64 = await encrypt(estado.toString(), key);
			const centro_base64 = await encrypt(centro.toString(), key);
			const { result } = await apiDeedsDelivery.getCrearCaso(
				nss_base64,
				credito_base64,
				domicilio_base64,
				estado_base64,
				centro_base64
			);
			if (result.codigo_respuesta && result.descripcion) {
				setAlert({
					show: true,
					message: result.descripcion,
					severity: 'warning',
				});
			} else {
				regBitacoraNotOld(apiDeedsDelivery.schemeUrl, {
					nss: user?.nss.toString(),
					credito: credit.toString(),
					domicilio: domicilio.trim(),
					estado: estado.toString(),
					centro: centro.toString()
				});
				setCaso(result);
				setPage(2);
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_GETTING_DATA,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleChangeForm = (event: any) => {
		const { name, value } = event.target;
		if (name === 'cp') setForm({ ...form, [name]: value, colonia: '' });
		else setForm({ ...form, [name]: value });
	};

	const handleCpChage = async () => {
		if (form.cp.trim() !== '' && form.cp.length > 4) {
			try {
				setLoading(true);
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const cp_base64 = await encrypt(form.cp.toString() || '', key);
				const { result } = await apiDeedsDelivery.getCp(cp_base64);
				setColonia(Array.isArray(result.colonias) ? result.colonias : []);
				setForm({
					...form,
					estado: result.denominacionSociedad,
					municipio: result.delegacion,
				});
			} catch (error: any) {
				if (error?.code && error?.code === '0001') {
					setForm({
						...form,
						estado: '',
						municipio: '',
					});
					setColonia([]);
				}
			} finally {
				setLoading(false);
			}
		}
	};

	const handleChange = async (event: any) => {
		const { name, value } = event.target;
		if (name === 'estado') {
			setEstado(value);
			setCentro('');
			try {
				setLoading(true);
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const estado_base64 = await encrypt(value.toString() || '', key);
				const { result } = await apiDeedsDelivery.getCESI(estado_base64);
				setDataCesi(Array.isArray(result) ? result : []);
			} catch (error) {
			} finally {
				setLoading(false);
			}
		} else if (name === 'centro') {
			setCentro(value);
		}
	};
	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		if (flagOpen === 1) {
			setOpen(false);
			setFlagOpen(-1);
		} else {
			setOpen(true);
			setFlagOpen(+1);
		}
	};

	const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.common.white,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: 'black',
			boxShadow: theme.shadows[1],
		},
	}));

	return (
		<div>
			<Paper>
				<ModalLoading loading={loading} />
				<div style={{ margin: 15 }}>
					<Grid container columns={12}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<h3
								style={{
									marginLeft: matches ? 0 : 20,
									marginTop: 40,
									fontSize: 18,
								}}
							>
								Elige el centro de servicio donde quieres recoger tus
								escrituras. Puedes seleccionar la oficina más cercana a tu
								domicilio.
							</h3>
						</Grid>
					</Grid>
					<Grid
						container
						columns={15}
						style={{
							display: 'flex',
							alignItems: 'center',
							margin: 0,
							justifyContent: 'center',
							flexDirection: 'row',
							width: '100%',
							paddingLeft: matches ? 0 : 40,
							paddingRight: matches ? 0 : 20,
						}}
					>
						<Grid item xs={0} sm={0} md={2} lg={2}></Grid>
						<Grid item xs={15} sm={15} md={12} lg={12}>
							<p style={{ margin: 0, fontSize: 18 }}>
								Estado de la República
								<label style={{ color: '#D1001F' }}>* </label>:
							</p>
							<FormControl sx={{ width: '100%' }}>
								<Select
									name="estado"
									value={estado}
									onChange={handleChange}
									displayEmpty
									sx={{ height: 52 }}
									inputProps={{ 'aria-label': 'Without label' }}
								>
									<MenuItem value="">Selecciona</MenuItem>

									{dataEstados.map((element) => (
										<MenuItem key={element.id} value={element.id}>
											{element.nombre}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={0} sm={0} md={1} lg={1}></Grid>
					</Grid>
					<Grid
						container
						columns={15}
						style={{
							display: 'flex',
							alignItems: 'center',
							margin: 0,
							justifyContent: 'center',
							flexDirection: 'row',
							width: '100%',
							paddingLeft: matches ? 0 : 40,
							paddingRight: matches ? 0 : 20,
							marginTop: 20,
							paddingBottom: matches ? 0 : 20,
						}}
					>
						<Grid item xs={0} sm={0} md={3} lg={2}></Grid>
						<Grid item xs={15} sm={15} md={12} lg={12}>
							<div>
								<p style={{ margin: 0, fontSize: 18 }}>
									Centro de servicio Infonavit
									<label style={{ color: '#D1001F' }}>* </label>:
								</p>
								<FormControl sx={{ width: '100%', paddingTop: 0.5 }}>
									<Select
										name="centro"
										value={centro}
										onChange={handleChange}
										displayEmpty
										sx={{ height: 52 }}
										inputProps={{ 'aria-label': 'Without label' }}
										disabled={estado ? false : true}

									>
										<MenuItem value="">Selecciona</MenuItem>

										{dataCesi.map((element, i) => (
											<MenuItem key={i} value={element.idPlaza}>
												{element.Nombre}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						</Grid>
						<Grid item xs={0} sm={0} md={1} lg={1}></Grid>
					</Grid>

					<Grid container columns={12}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<hr
								style={{
									opacity: 0.4,
									marginTop: matches ? 25 : 40,
									marginBottom: matches ? 25 : 40,
								}}
							/>
						</Grid>
					</Grid>
					{/* 2a Sección */}
					<Grid container columns={12}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<h3
								style={{
									display: 'flex',
									alignItems: 'center',
									marginTop: 0,
									marginLeft: matches ? 0 : 20,
									fontSize: 18,
								}}
							>
								Dirección del inmueble{' '}
								<div>
									<BootstrapTooltip
										PopperProps={{
											disablePortal: true,
										}}
										onClose={handleTooltipClose}
										open={open}
										disableFocusListener
										disableHoverListener
										disableTouchListener
										title="Es muy importante que escribas la información tal como aparece en tus documentos, ya que posteriormente se verificará y podrás continuar con tu trámite."
										placement={matches ? 'bottom' : 'right'}
										id="1"
									>
										<IconButton
											aria-label="add an alarm"
											onClick={handleTooltipOpen}
											size="small"
											disableRipple
											disableFocusRipple
											component="span"
										>
											<InfoIcon
												fontSize="small"
												sx={{ ml: 0.5, color: '#D1001F' }}
											/>
										</IconButton>
									</BootstrapTooltip>
								</div>
							</h3>
						</Grid>
					</Grid>
					{/* Inputs Columna 1 */}
					<Grid
						container
						columns={15}
						spacing={matches ? 0 : 1}
						style={{
							display: 'flex',
							alignItems: 'center',
							margin: 0,
							justifyContent: 'center',
							flexDirection: 'row',
							width: '100%',
							paddingLeft: matches ? 0 : 40,
							paddingRight: matches ? 0 : 20,
						}}
					>
						<Grid item xs={12} sm={12} md={2} lg={2}></Grid>
						<Grid item xs={15} sm={15} md={4} lg={4}>
							<p style={{ margin: 0, fontSize: 18 }}>C.P.</p>
							<TextInput
								id="cp"
								name="cp"
								value={form.cp}
								onChange={handleChangeForm}
								onBlur={handleCpChage}
							/>
						</Grid>

						<Grid item xs={15} sm={15} md={4} lg={4}>
							<p style={{ margin: 0, fontSize: 18 }}>Estado</p>
							<TextInput
								id="estado"
								name="estado"
								value={form.estado}
								onChange={handleChangeForm}
								disabled
							/>
						</Grid>

						<Grid item xs={15} sm={15} md={4} lg={4}>
							<p style={{ margin: 0, fontSize: 18 }}>
								Municipio o Delegación
							</p>
							<TextInput
								id="municipio"
								name="municipio"
								value={form.municipio}
								onChange={handleChangeForm}
								disabled
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={1} lg={1}></Grid>
					</Grid>
					<Grid
						container
						columns={15}
						spacing={matches ? 0 : 1}
						style={{
							display: 'flex',
							alignItems: 'center',
							margin: 0,
							justifyContent: 'center',
							flexDirection: 'row',
							width: '100%',
							paddingLeft: matches ? 0 : 40,
							paddingRight: matches ? 0 : 20,
							marginTop: 20,
							paddingBottom: 20,
						}}
					>
						<Grid item xs={0} sm={0} md={2} lg={2}></Grid>
						<Grid item xs={15} sm={15} md={4} lg={4}>
							<p style={{ margin: 0, fontSize: 18 }}>Colonia</p>
							<FormControl sx={{ width: '100%', paddingTop: 0.5 }}>
								<Select
									name="colonia"
									value={form.colonia}
									onChange={handleChangeForm}
									displayEmpty
									sx={{ height: 41, paddingTop: 0 }}
									inputProps={{ 'aria-label': 'Without label' }}
									disabled={form.estado ? false : true}
								//	disabled={form.cp.length === 5 ? false : true}
								>
									<MenuItem value="">Selecciona</MenuItem>

									{colonia.map((element, i) => (
										<MenuItem key={i} value={element}>
											{element}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid
							item
							xs={15}
							sm={15}
							md={4}
							lg={4}
							sx={{ mt: matches ? 2 : 0 }}
						>
							<p style={{ margin: 0, fontSize: 18 }}>Calle</p>
							<TextInput
								id="calle"
								name="calle"
								value={form.calle}
								onChange={handleChangeForm}
								disabled={form.colonia ? false : true}
							/>
						</Grid>

						<Grid
							item
							xs={6}
							sm={6}
							md={2}
							lg={2}
							sx={{
								mr: matches ? 8 : matchesm ? 17 : 0,
								mt: matches ? 2 : 0,
							}}
						>
							<p style={{ margin: 0, fontSize: 18 }}>Num. Ext.</p>
							<TextInput
								id="num_ext"
								name="num_ext"
								value={form.num_ext}
								onChange={handleChangeForm}
								disabled={form.colonia ? false : true}

							/>
						</Grid>
						<Grid
							item
							xs={6}
							sm={6}
							md={2}
							lg={2}
							sx={{ mt: matches ? 2 : 0 }}
						>
							<p style={{ margin: 0, fontSize: 18 }}>Num. Int.</p>
							<TextInput
								id="num_int"
								name="num_int"
								value={form.num_int}
								onChange={handleChangeForm}
								disabled={form.colonia ? false : true}

							/>
						</Grid>

						<Grid item xs={0} sm={0} md={1} lg={1}></Grid>
					</Grid>
					<Grid container columns={12}>
						<Grid item xs={0} sm={0} md={2} lg={2}></Grid>
						<Grid item xs={12} sm={12} md={9} lg={9}>
							<CustomAlert
								show={alert.show}
								message={alert.message}
								severity="warning"
								onClose={() =>
									setAlert({
										show: false,
										message: '',
										severity: 'warning',
									})
								}
							/>
						</Grid>
						<Grid item xs={0} sm={0} md={1} lg={1}></Grid>
					</Grid>

					<Grid container columns={12}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div
								style={{
									display: 'flex',
									flexDirection: matches ? 'column-reverse' : 'row',
									justifyContent: 'center',
									alignItems: 'center',
									paddingLeft: matches ? 15 : 60,
									paddingRight: matches ? 15 : 40,
									paddingTop: 20,
									paddingBottom: 20,
								}}
							>
								<CustomButton
									label="Regresar"
									onClick={() => {
										setPage(0);
									}}
									variant="outlined"
									styles={{
										width: matches ? 320 : 200,
										height: 40,
										marginRight: matches ? 0 : 40,
										marginBottom: 20,
									}}
								/>
								<CustomButton
									label="Continuar"
									onClick={() => crearCaso()}
									disabled={
										estado &&
											centro &&
											form.cp &&
											form.estado &&
											form.municipio &&
											form.calle
											? false
											: true
									}
									variant="solid"
									styles={{
										width: matches ? 320 : 200,
										height: 40,
										marginBottom: 20,
									}}
								/>
							</div>
							<hr
								style={{
									opacity: 0.4,
									marginTop: 0,
									marginBottom: 0,
								}}
							/>
							<h3
								style={{
									marginLeft: matches ? 10 : 20,
									marginTop: 8,
									color: '#293990',
									fontSize: matches ? 16 : 18,
									paddingBottom: 15,
								}}
							>
								* Datos obligatorios
							</h3>
						</Grid>
					</Grid>
				</div>
			</Paper>
		</div>
	);
};

export default DataForm;
