/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect } from 'react';
import {
	Grid,
	InputAdornment,
	Pagination,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { CheckCircleOutlined } from '@mui/icons-material';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';

// Components
import TextInput from '../../components/TextInput';
import CustomButton from '../../components/Button';

// Resources
import annualReturns from '../../config/annualReturns';

// Assets
import { useStyles } from './styles';
import iconMoney from '../../assets/img/icono-pesos.png';
import { formatMoney } from '../../utils/validators';
import { isNumberFloat } from '../../utils/expressions';
import CustomAlert from '../../components/CustomAlert';
import { IAlert } from '../../interfaces/alert';
import { Box } from '@mui/system';
import { savingCalculator } from '../../api/modules/savingCalculator';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import ModalLoading from '../../components/ModalLoading';

const list: Array<string> = [
	'Estos son los factores que consideramos. Tómalos en cuenta para que estimes con más exactitud el valor de tu ahorro en el momento que te retires.',
	'El rendimiento de la Subcuenta de Vivienda y la Tasa de Inflación por año.',
	'Incremento anual del salario mínimo e incremento adicional de tu salario, en su caso.',
	'Tu ahorro y salario mensual a la fecha.',
];

const totalAge = 65;
const incrementoSalarioActual = 0.05;
const rendimientoSCV2011 = 0.0468;
const tasaDescuentoInflacion = 0.041;
const incrementoSalarioActualAux = 12;
const VSMDF25 = 55510.4;
const topeAportacionPatronal = 33306.24;

// Service

const SavingCalculator = () => {
	const [viewTable, setViewTable] = useState(false);
	const [loading, setLoading] = useState(false);
	const [disabledButton, setDisabledButton] = useState(false);
	const [page, setPage] = useState(1);
	const [rowsPerPage] = useState(8);
	const [currentAge, setCurrentAge] = useState(50);
	const [inYears, setInYears] = useState(0);
	const [currentSalary, setCurrentSalary] = useState<number | string>('');
	const [currentSaving, setCurrentSaving] = useState<number>(0);
	const [retireSaving, setRetireSaving] = useState<number>(0);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [rows, setRows] = useState<
		{
			columnaAhorro: number;
			columnaSalarioAnual: number;
			columnaValorPresente: number;
			columnaAportacionPatronalAnual: number;
			edad: number;
		}[]
	>([]);
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			savingCalculator
				.post<any, any>('', {
					nss: nss_base64,
				})
				.then((response) => {
					if (response?.result?.retCode === 1) {
						const salary9297 = {
							...(response?.result?.saldo9297 || {}),
						};
						const saldosar92 = Number(salary9297.saldosar92);
						const saldosar97 = Number(salary9297.saldosar97);
						const ed = salary9297.rfc.substring(4, 6);
						const fechaCorte = salary9297.fechaCorte.substring(
							salary9297.fechaCorte.lastIndexOf('/') + 1
						);
						const iEdad = Number(fechaCorte) - (1900 + Number(ed));
						const inAgeFlag = totalAge - iEdad;
						setInYears(inAgeFlag);
						setCurrentAge(iEdad);
						setCurrentSaving(saldosar92 + saldosar97);
					} else {
						setAlert({
							show: true,
							message:
								'Por el momento el servicio no está disponible. Intenta más tarde',
							severity: 'error',
						});
						setDisabledButton(true);
					}
				})
				.catch((err) => {
					setLoading(false);
					setAlert({
						show: true,
						message:
							err.description ||
							'Por el momento el servicio no está disponible. Intenta más tarde',
						severity: 'error',
					});
					setDisabledButton(true);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
			setDisabledButton(true);
		}
	};

	const handleChangePage = (newPage: number) => {
		setPage(newPage);
	};

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		if (isNumberFloat(value)) {
			setCurrentSalary(value);
		}
	};

	const handleCalculate = () => {
		if (!currentSalary) {
			return setAlert({
				show: true,
				message: 'Escribe el sueldo',
				severity: 'warning',
			});
		}
		const newArray = [];
		const currentSalaryFlag = Number(
			currentSalary.toString().replaceAll(',', '')
		);
		let columnaSalarioAnual = 0;
		let columnaAportacionPatronalAnual = 0;
		let columnaAhorro = 0;
		let columnaValorPresente = 0;
		let topePatronal = false;
		let tuAhorroActualidad = 0;
		let tuAhorroCuantoTeJubiles = 0;

		for (let fila = 0; fila <= inYears; fila++) {
			if (fila === 0) {
				columnaAhorro = currentSaving;
				columnaValorPresente =
					columnaAhorro / Math.pow(1 + tasaDescuentoInflacion, fila);
				tuAhorroActualidad = columnaValorPresente;
				newArray.push({
					columnaAhorro,
					columnaSalarioAnual,
					columnaValorPresente,
					columnaAportacionPatronalAnual,
					edad: currentAge,
				});
			} else if (fila === 1) {
				columnaSalarioAnual =
					currentSalaryFlag * incrementoSalarioActualAux;

				if (currentSalaryFlag >= VSMDF25) {
					columnaAportacionPatronalAnual = topeAportacionPatronal;
					topePatronal = true;
				} else {
					columnaAportacionPatronalAnual =
						columnaSalarioAnual * incrementoSalarioActual;
				}

				columnaAhorro =
					(columnaAhorro + columnaAportacionPatronalAnual) *
					(1 + rendimientoSCV2011);

				columnaValorPresente =
					columnaAhorro / Math.pow(1 + tasaDescuentoInflacion, fila);
				newArray.push({
					columnaAhorro,
					columnaSalarioAnual,
					columnaValorPresente,
					columnaAportacionPatronalAnual,
					edad: currentAge + fila,
				});
			} else {
				columnaSalarioAnual =
					columnaSalarioAnual * (1 + incrementoSalarioActual);
				if (topePatronal) {
					columnaAportacionPatronalAnual =
						columnaAportacionPatronalAnual *
						(1 + incrementoSalarioActual);
				} else {
					columnaAportacionPatronalAnual =
						columnaSalarioAnual * incrementoSalarioActual;
				}
				columnaAhorro =
					(columnaAhorro + columnaAportacionPatronalAnual) *
					(1 + rendimientoSCV2011);
				columnaValorPresente =
					columnaAhorro / Math.pow(1 + tasaDescuentoInflacion, fila);
				newArray.push({
					columnaAhorro,
					columnaSalarioAnual,
					columnaValorPresente,
					columnaAportacionPatronalAnual,
					edad: currentAge + fila,
				});
			}

			tuAhorroCuantoTeJubiles = columnaValorPresente;
		}
		setCurrentSaving(tuAhorroActualidad);
		setRetireSaving(tuAhorroCuantoTeJubiles);
		setRows([...newArray]);
		setAlert({
			show: false,
			message: '',
			severity: 'warning',
		});
	};

	return (
		<Layout>
			<Fragment>
				<HeaderList
					title="Calculadora del ahorro"
					list={list}
					date="12 julio 2021"
				/>
				<Paper elevation={0}>
					<div className={classes.containerCalculator}>
						<p className={classes.titleCalculator}>
							Calcula tu ahorro
						</p>
						<CustomAlert
							message={alert.message}
							severity={alert.severity}
							show={alert.show}
							onClose={() => {
								setAlert({
									show: false,
									message: '',
									severity: alert.severity,
								});
							}}
						/>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<TextInput
									id="currentSalary"
									name="currentSalary"
									label="Escribe tu sueldo actual mensual"
									placeholder="0.00"
									value={currentSalary}
									onChange={handleSetData}
									onBlur={() => {
										if (currentSalary) {
											if (currentSalary === '.') {
												setCurrentSalary('');
											} else {
												setCurrentSalary(
													formatMoney(
														Number(currentSalary)
													).substring(1)
												);
											}
										}
									}}
									onFocus={() => {
										if (currentSalary) {
											setCurrentSalary(
												currentSalary
													.toString()
													.replaceAll(',', '')
													.replaceAll(' ', '')
											);
										}
									}}
									startAdornment={
										<InputAdornment position="start">
											$
										</InputAdornment>
									}
								/>
							</Grid>
							<Grid
								className={classes.containerElementsCalculator}
								item
								xs={12}
								sm={6}
								md={4}
								lg={3}
							>
								<CustomButton
									onClick={handleCalculate}
									label="Calcular"
									variant="solid"
									styles={{ height: 40 }}
									disabled={disabledButton}
								/>
							</Grid>
						</Grid>
					</div>
					<Grid
						container
						spacing={0}
						className={classes.containerTotals}
					>
						<Grid item xs={12} md={4} lg={4}>
							<div className={classes.containerCurrentSaving}>
								<p className={classes.titleCurrentSaving}>
									Tu ahorro en la actualidad
								</p>
								<div
									className={
										classes.containerQtyCurrentSaving
									}
								>
									<p className={classes.qtyCurrentSaving}>
										<strong>
											{formatMoney(currentSaving || 0)}
										</strong>
									</p>
									<p
										className={
											classes.qtyCurrencyCurrentSaving
										}
									>
										MXN
									</p>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} md={4} lg={4}>
							<div className={classes.containerMoneyYears}>
								<img
									src={iconMoney}
									className={classes.imgMoney}
									alt="img money years"
								/>
								<p className={classes.titleYears}>
									En {inYears} años
								</p>
							</div>
						</Grid>
						<Grid item xs={12} md={4} lg={4}>
							<div className={classes.containerCurrentSaving}>
								<p className={classes.titleRetirement}>
									Tu ahorro cuando te jubiles podría ser de
								</p>
								<div
									className={
										classes.containerQtyCurrentSaving
									}
								>
									<p className={classes.qtyRetirement}>
										<strong>
											{formatMoney(retireSaving || 0)}
										</strong>
									</p>
									<p
										className={
											classes.qtyCurrencyRetirement
										}
									>
										MXN
									</p>
								</div>
							</div>
						</Grid>
					</Grid>
					<div className={classes.infoContainer}>
						{rows.length > 0 && (
							<Fragment>
								<TableContainer>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell
													className={
														classes.tableTitleHeader
													}
												>
													Tu edad
												</TableCell>
												<TableCell
													className={
														classes.tableTitleHeader
													}
												>
													Tu salario acumulado en el
													año (1)
												</TableCell>
												<TableCell
													className={
														classes.tableTitleHeader
													}
												>
													La aportación de tu patrón
													acumulada en el año (2)
												</TableCell>
												<TableCell
													className={
														classes.tableTitleHeader
													}
												>
													Tu ahorro con rendimientos
													(3)
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{(rows || [])
												.slice(
													(page - 1) * rowsPerPage,
													(page - 1) * rowsPerPage +
													rowsPerPage
												)
												.map((row, index) => (
													<TableRow
														key={index}
														sx={{
															'&:last-child td, &:last-child th':
															{
																border: 0,
															},
														}}
													>
														<TableCell
															className={
																classes.tableTitleHeader
															}
															component="th"
															scope="row"
														>
															{row.edad}
														</TableCell>
														<TableCell>
															{row.columnaSalarioAnual >
																0 ? (
																<strong>
																	{formatMoney(
																		row.columnaSalarioAnual
																	)}
																</strong>
															) : (
																formatMoney(
																	row.columnaSalarioAnual
																)
															)}
														</TableCell>
														<TableCell>
															{row.columnaAportacionPatronalAnual >
																0 ? (
																<strong>
																	{formatMoney(
																		row.columnaAportacionPatronalAnual
																	)}
																</strong>
															) : (
																formatMoney(
																	row.columnaAportacionPatronalAnual
																)
															)}
														</TableCell>
														<TableCell>
															{row.columnaAhorro >
																0 ? (
																<strong>
																	{formatMoney(
																		row.columnaAhorro
																	)}
																</strong>
															) : (
																formatMoney(
																	row.columnaAhorro
																)
															)}
														</TableCell>
													</TableRow>
												))}
										</TableBody>
									</Table>
								</TableContainer>
								<Box
									display="flex"
									justifyContent="center"
									flex={1}
									padding={1}
									margin={2}
								>
									<Stack spacing={2}>
										<Pagination
											color="primary"
											count={Math.ceil(
												rows.length / rowsPerPage
											)}
											variant="text"
											shape="rounded"
											onChange={(e, page) =>
												handleChangePage(page)
											}
										/>
									</Stack>
								</Box>
							</Fragment>
						)}
					</div>
					<div className={classes.divider} />
					<div className={classes.infoContainer}>
						<p>
							<strong>Aspectos importantes</strong>
						</p>
						<p className={classes.listItem}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 20 }}
							/>
							<label style={{ marginTop: 3 }}>
								Tu sueldo anual considerando un porcentaje de
								inflación
							</label>
							<br />
						</p>
						<p className={classes.listItem}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 20 }}
							/>
							<label style={{ marginTop: 3 }}>
								Son las aportaciones de tu patrón a lo largo del
								año, que corresponden al 5% de tu salario el
								cual está topado al 25 VSMDF
							</label>
							<br />
						</p>
						<p className={classes.listItem}>
							<CheckCircleOutlined
								color="info"
								style={{ marginRight: 5, width: 20 }}
							/>
							<label style={{ marginTop: 3 }}>
								Es la suma de tu ahorro más los rendimientos
								estimados a futuro que otorga el Instituto
							</label>
							<br />
						</p>
						<p>
							Los rendimientos que genera tu ahorro se abonan al
							final de cada mes. El consejo de Administración de
							Infonavit establece la tasa de interés y es superior
							al incremento del salario mínimo que se paga en la
							CDMX.
						</p>
						<p>
							<label className={classes.colorNote}>Nota:</label>{' '}
							Recuerda que esta es una proyección de carácter
							informativo, no oficial derivado del comportamiento
							salarial y todos los factores que intervienen en el
							mismo; por otro lado recuerda que si tienes un
							crédito vigente tus aportaciones son destinadas al
							pago del mismo.
						</p>
						<p className={classes.savingLink}>
							Tu ahorro genera rendimientos
							<label
								onClick={() => setViewTable(!viewTable)}
								className={classes.link}
							>
								consulta aquí.
							</label>
						</p>
						{viewTable && (
							<Fragment>
								<p className={classes.containerTitle}>
									<strong>Tabla de rendimientos</strong>
								</p>
								<div className={classes.headerTableTitle}>
									<strong
										style={{ color: 'white', fontSize: 14 }}
									>
										Rendimientos generados
									</strong>
								</div>
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell
													variant="head"
													className={
														classes.tableTitleHeader
													}
													style={{ width: '50%' }}
												>
													Año
												</TableCell>
												<TableCell
													variant="head"
													className={
														classes.tableTitleHeader
													}
												>
													Tasa de rendimiento nominal
													anual
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{annualReturns.map((row, index) => (
												<TableRow
													key={index}
													sx={{
														'&:last-child td, &:last-child th':
														{
															border: 0,
														},
													}}
												>
													<TableCell
														component="th"
														scope="row"
													>
														{row.year}
													</TableCell>
													<TableCell>
														{row.tasaRen}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Fragment>
						)}
					</div>
				</Paper>
				<ModalLoading loading={loading} />
			</Fragment>
		</Layout>
	);
};

export default SavingCalculator;
