/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies
import React, { useState, useEffect } from 'react';

// Assets
import { useStyles } from './styles';

// Material Components
import { Box } from '@mui/material';

// Components
import TextInput from '../../components/TextInput';
import CustomAlert from '../../components/CustomAlert';
import Captcha from '../../components/Captcha';

// Interfaces
import { IAlert } from '../../interfaces/alert';

// Resources
import { nssValid, curpValid, rfcValid, isNumber } from '../../utils/expressions';
// import { encrypt } from '../../utils/encrypt';

// Api-Modules
// import { keyAPI } from '../../api/modules/key';
// import { deleteAccountAPI } from '../../api/modules/deleteAcount';

export interface IDeleteAcount {
	onCancel: () => void;
	toHome: (value: boolean) => void;
}

interface ICredentials {
	nss: string;
	rfc: string;
	curp: string;
}

const DeleteAcount = (props: IDeleteAcount) => {
	const [credentials, setCredentials] = useState<ICredentials>({
		nss: '',
		rfc: '',
		curp: '',
	});

	const [codeValidate, setCodeValidate] = useState(false);

	const [code] = useState('');
	// const [loading, setLoading] = useState(false);
	const [codeInput, setCodeInput] = useState('');
	const setButtonValidate = useState(true)[1];
	// const [buttonValidate_AcountDeleted, setButtonValidate_AcountDeleted] =
	// 	useState(false);
	const [errorNSS, setErrorNSS] = useState({ valid: false, msg: '' });
	const [errorCurp, setErrorCurp] = useState({ valid: false, msg: '' });
	const [errorRFC, setErrorRFC] = useState({ valid: false, msg: '' });
	const [refreshCaptcha] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	useEffect(() => {
		if (errorNSS.valid && errorCurp.valid && errorRFC.valid && codeValidate) {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	}, [errorNSS.valid, errorCurp.valid, errorRFC.valid, codeValidate]);

	const setData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		let validate = null;

		if (name === 'nss') {
			if (!isNumber(value)) return;
			if (value.length > 11) return;
			validate = nssValid(value);
			setErrorNSS(validate);
		}
		if (name === 'rfc') {
			if (value.length > 13) return;
			validate = rfcValid(value);
			setErrorRFC(validate);
		}
		if (name === 'curp') {
			if (value.length > 18) return;
			validate = curpValid(value);
			setErrorCurp(validate);
		}
		if (name === 'code') {
			setCodeInput(value);
			setCodeValidate(code === value ? true : false);
		}

		if (name !== 'code') {
			setCredentials({ ...credentials, [name]: value.toUpperCase() });
		}
	};

	// const handleOnSubmit = async () => {
	// 	setLoading(true);
	// 	try {
	// 		const keyRes = await keyAPI.get<'', { k: string }>();
	// 		const key = keyRes['k'].toString();
	// 		const nss_base64 = await encrypt(credentials.nss, key);
	// 		const curp_base64 = await encrypt(credentials.curp, key);
	// 		const rfc_base64 = await encrypt(credentials.rfc, key);
	// 		deleteAccountAPI
	// 			.deleteAcount(nss_base64, curp_base64, rfc_base64)
	// 			.then((res) => {
	// 				if (res.result.code == '00') {
	// 					setAlert({
	// 						show: true,
	// 						message: 'Eliminación correcta',
	// 						severity: 'success',
	// 					});
	// 					props.toHome(true);
	// 					setButtonValidate_AcountDeleted(true);
	// 				} else if (res.result.description) {
	// 					setAlert({
	// 						show: true,
	// 						message: res.result.description,
	// 						severity: 'error',
	// 					});
	// 				} else {
	// 					setAlert({
	// 						show: true,
	// 						message: 'Error en el servicio, favor de intentar más tarde',
	// 						severity: 'error',
	// 					});
	// 				}
	// 				setRefreshCaptcha(!refreshCaptcha);
	// 			})
	// 			.catch((err) => {
	// 				setAlert({
	// 					show: true,
	// 					message: err?.description,
	// 					severity: 'error',
	// 				});
	// 				setRefreshCaptcha(!refreshCaptcha);
	// 			})
	// 			.finally(() => setLoading(false));
	// 	} catch (error) {
	// 		setAlert({
	// 			show: true,
	// 			message: 'Error en el servicio, favor de intentar más tarde',
	// 			severity: 'error',
	// 		});
	// 		setRefreshCaptcha(!refreshCaptcha);
	// 		setLoading(false);
	// 	}
	// };

	const styles = useStyles();
	return (
		<Box className={styles.divDelete}>
			<Box
				sx={{
					textAlign: 'center',
					marginBottom: 3,
				}}
			>
				<h3>Para borrar tu cuenta, Ingresa los siguientes datos:</h3>
			</Box>

			<TextInput
				id="nss"
				name="nss"
				label="Número de seguridad social (NSS)"
				value={credentials.nss}
				onChange={setData}
				error={!errorNSS.valid}
				helperText={!errorNSS.valid ? errorNSS.msg : ''}
				isRequired
				noCopy
				noPaste
			/>

			<TextInput
				id="curp"
				name="curp"
				label="Clave Única de Registro de Población (CURP)"
				value={credentials.curp}
				onChange={setData}
				error={!errorCurp.valid}
				helperText={!errorCurp.valid ? errorCurp.msg : ''}
				isRequired
				noCopy
				noPaste
			/>

			<TextInput
				id="rfc"
				name="rfc"
				label="Registro Federal de Contribuyentes (RFC)"
				value={credentials.rfc}
				onChange={setData}
				error={!errorRFC.valid}
				helperText={!errorRFC.valid ? errorRFC.msg : ''}
				isRequired
				noCopy
				noPaste
			/>

			<h3>
				Datos obligatorios (<span className={styles.fontRed}>*</span>)
			</h3>
			<div className={styles.containerCaptcha}>
				<Captcha
					value={codeInput}
					getNew={refreshCaptcha}
					onChange={(newValue: string, codeCaptcha: string) => {
						setCodeInput(newValue);
						setCodeValidate(codeCaptcha === newValue ? true : false);
					}}
				/>
			</div>
			<div>
				<CustomAlert
					severity={alert.severity}
					message={alert.message}
					show={alert.show}
					onClose={() => {
						setAlert({
							show: false,
							message: '',
							severity: alert.severity,
						});
					}}
				/>
				{/* <CustomButton
					onClick={handleOnSubmit}
					disabled={buttonValidate || buttonValidate_AcountDeleted}
					variant="solid"
					label="Borrar cuenta"
				/> */}
			</div>
			{/* <ModalLoading loading={loading} /> */}
		</Box>
	);
};

export default DeleteAcount;
