import { useState } from 'react';
import Layout from '../../components/Layout';
import FollowAttachDocument from './FollowAttachDocument';
import AttachDocuments from './AttachDocuments';
import Status from './Status';
import '../../assets/font/Geomanist.css';

const FollowAttachDocuments = () => {
	const [page, setPage] = useState(0);
	const [dataCaso, setDataCaso] = useState({});
	const [getData] = useState(false);
	const [status, setStatus] = useState({});
	const setData = (dato: any) => {
		setDataCaso(dato);
	};

	return (
		<Layout>
			<div style={{ fontFamily: 'Geomanist', fontSize: 15 }}>
				{page === 0 && (
					<FollowAttachDocument
						setPage={setPage}
						setData={setData}
						getData={getData}
					/>
				)}
				{page === 1 && (
					<AttachDocuments
						setPage={setPage}
						dataCaso={dataCaso}
						setStatus={setStatus}
					/>
				)}
				{page === 2 && <Status setPage={setPage} status={status} />}
			</div>
		</Layout>
	);
};

export default FollowAttachDocuments;
