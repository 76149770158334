import { Grid, Paper, useMediaQuery } from '@mui/material';
import CustomButton from '../../components/Button';
import { useTheme } from '@mui/material/styles';
import { formatDate } from '../../interfaces/SaldAndMovement';

interface ICaso {
	or_description?: string;
	or_fecha?: string;
	or_object_id?: string;
	or_status?: string;
	or_status_reason?: string;
	or_fecha_stat?: string;
	or_status_message?: string;
	descripcion_estatus?: string;
}

interface Props {
	caso: ICaso;
	setPage: (data: number) => void;
}

const DataInfo = ({ setPage, caso }: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<Paper>
			<div style={{ padding: matches ? 20 : 40 }}>
				<Grid
					container
					columns={12}
					sx={{ display: 'flex', flexDirection: matches ? 'column' : 'row' }}
				>
					<Grid item xs={0} sm={0} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Número de caso</h3>
					</Grid>
					<Grid item xs={0} sm={0} md={9} lg={9} style={{ textAlign:'right'}}>
						<label>{caso.or_object_id}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />
				<Grid
					container
					columns={12}
					sx={{ display: 'flex', flexDirection: matches ? 'column' : 'row' }}
				>
					<Grid item xs={0} sm={0} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Fecha de solicitud</h3>
					</Grid>
					<Grid item xs={0} sm={0} md={9} lg={9}  style={{ textAlign:'right'}}>
						<label>{formatDate(caso.or_fecha, '/')}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />

				<Grid
					container
					columns={12}
					sx={{ display: 'flex', flexDirection: matches ? 'column' : 'row' }}
				>
					<Grid item xs={0} sm={0} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Servicio</h3>
					</Grid>
					<Grid item xs={0} sm={0} md={9} lg={9} style={{ textAlign:'right'}}>
						<label>{caso.or_description}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />
				<Grid
					container
					columns={12}
					sx={{ display: 'flex', flexDirection: matches ? 'column' : 'row' }}
				>
					<Grid item xs={0} sm={0} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Estatus</h3>
					</Grid>
					<Grid item xs={0} sm={0} md={9} lg={9} style={{ textAlign:'right'}}>
						<label >
							{caso.or_status === 'E0002'
								? 'En espera de documentos'
								: caso.descripcion_estatus}
						</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />

				<Grid
					container
					columns={12}
					sx={{ display: 'flex', flexDirection: matches ? 'column' : 'row' }}
				>
					<Grid item xs={0} sm={0} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Fecha de estatus</h3>
					</Grid>
					<Grid item xs={0} sm={0} md={9} lg={9} style={{ textAlign:'right'}}>
						<label>{formatDate(caso.or_fecha_stat, '/')}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />
				<Grid
					container
					columns={12}
					sx={{ display: 'flex', flexDirection: matches ? 'column' : 'row' }}
				>
					<Grid item xs={0} sm={0} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Descripción</h3>
					</Grid>
					<Grid item xs={0} sm={0} md={9} lg={9} style={{ textAlign:'right'}}>
						<label>{caso.or_status_message}</label>
					</Grid>
				</Grid>

				<Grid
					container
					columns={12}
					sx={{ display: 'flex', flexDirection: matches ? 'column' : 'row' }}
				>
					<Grid item xs={0} sm={0} md={1} lg={1}></Grid>
					<Grid item xs={0} sm={0} md={10} lg={10}>
						<div
							style={{
								display: 'flex',
								flexDirection: matches ? 'column-reverse' : 'row',
								alignItems: 'center',
								justifyContent:
									caso.or_status === 'E0002'
										? 'space-between'
										: 'center',
								paddingTop: 60,
								paddingBottom: 20,
							}}
						>
							<CustomButton
								label="Finalizar"
								onClick={() => {
									setPage(4);
								}}
								variant={
									caso.or_status === 'E0002' ? 'outlined' : 'solid'
								}
								styles={{
									width: matches ? 300 : 250,
									height: 40,
									marginTop: matches ? 20 : 0,
									marginBottom: matches ? 10 : 0,
								}}
							/>
							{caso.or_status === 'E0002' && (
								<CustomButton
									label="Adjuntar documentos"
									onClick={() => {
										setPage(3);
									}}
									variant="solid"
									styles={{ width: matches ? 300 : 250, height: 40 }}
								/>
							)}
						</div>
					</Grid>
				</Grid>
			</div>
		</Paper>
	);
};

export default DataInfo;
