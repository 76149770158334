import { useStyles, themBackdrop } from './styles';
import { Backdrop } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import gifLoading from '../../assets/gif/logo-min.gif';

interface IModalLoading {
	loading: boolean;
}

export const ModalLoading = (props: IModalLoading) => {
	const classes = useStyles();
	const { loading } = props;
	return (
		<ThemeProvider theme={themBackdrop}>
			<Backdrop open={loading}>
				<div className={classes.containerLoading}>
					<img
						src={gifLoading}
						alt="loagindGif"
						style={{ width: 100, display: 'block', margin: 0 }}
					/>
					<p className={classes.textLoading}>Cargando...</p>
				</div>
			</Backdrop>
		</ThemeProvider>
	);
};

export default ModalLoading;
