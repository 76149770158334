import { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import { useStyles } from './styles';
import AvailableProrogation from './views/AvailableProrogation';
import Terms from './views/Terms';
import Summary from './views/Summary';
import ModalLoading from '../../components/ModalLoading';
import { apiProrogation } from '../../api/modules/Prorogation';
import { IProrroga, IResponseSearch, IResponseRegister } from './utils';
import CustomAlert from '../../components/CustomAlert';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { RootState } from '../../store';

const Prorogation = () => {
	const classes = useStyles();
	const { credit } = useSelector((state: RootState) => state.credit);
	const [step, setStep] = useState(1);
	const [loagin, setLoagin] = useState(false);
	const [susceptible, setSusceptible] = useState(true);
	const [prorroga, setProrroga] = useState<IProrroga>({
		register: {},
		search: {},
	});

	useEffect(() => {
		const req = async () => {
			try {
				setLoagin(true);
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const credit_encript = await encrypt(
					credit.toString() || '',
					key
				);

				// Registro de una prorroga.
				const registrerResponse =
					await apiProrogation.getRegistroSolicitud(credit_encript);
				const register: IResponseRegister = registrerResponse.result;
				// Consulta una prorroga si existe
				const searchResponse = await apiProrogation.getProrroga();
				const search: IResponseSearch = searchResponse.result;

				setProrroga({
					search,
					register,
				});

				if (search.code === '0000') {
					setStep(3);
					return;
				}

				if (
					register.mensajes?.validaciones.mca_suscept !== 'S' ||
					register?.code !== '0000'
				) {
					setSusceptible(false);
				}
			} catch (error) {
				setStep(0);
			} finally {
				setLoagin(false);
			}
		};
		req();
	}, []);

	const refreshData = async () => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const credit_encript = await encrypt(
					credit.toString() || '',
					key
				);
			// Registro de una prorroga.
			const registrerResponse =
				await apiProrogation.getRegistroSolicitud(credit_encript);
			const register: IResponseRegister = registrerResponse.result;
			// Consulta una prorroga si existe
			const searchResponse = await apiProrogation.getProrroga();
			const search: IResponseSearch = searchResponse.result;

			setProrroga({
				search,
				register,
			});
		} catch (error) {}
	};

	return (
		<Layout>
			<>
				<HeaderList title="Solicitud de prórroga" />
				<Paper className={classes.container}>
					{step === 0 && (
						<CustomAlert
							message={
								'Por el momento el servicio no se encuentra disponible, intenta más tarde'
							}
							severity={'warning'}
							show
						/>
					)}
					{step === 1 && (
						<AvailableProrogation
							setStep={setStep}
							susceptible={susceptible}
						/>
					)}
					{step === 2 && (
						<Terms
							prorroga={prorroga}
							setStep={setStep}
							refreshData={refreshData}
						/>
					)}
					{step === 3 && <Summary prorroga={prorroga} />}
					{/* {step === 4 && <RejectionMotive setStep={setStep} />}
					{step === 5 && <Rejection prorroga={prorroga} />} */}
				</Paper>
				<ModalLoading loading={loagin} />
			</>
		</Layout>
	);
};

export default Prorogation;
