/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
//Assets
import imgMovCredito from '../../../assets/img/movimiento-credito.png';
import imgReestruct from '../../../assets/img/reestructura.png';
import imgAclaracion from '../../../assets/img/aclaracion.png';
import imgAgendaCita from '../../../assets/img/Agenda-tu-cita.png';
import imgPagaCredit from '../../../assets/img/paga-credito-banner.jpg';
import imgOpcionesPago from '../../../assets/img/opciones-pago-credito.png';

const AccreditedJudicialProcess = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={12} lg={4}>
				<Paper className={classes.card}>
					<div className={classes.titleCard}>
						<h3>
							¿Tienes problemas con los pagos de tus mensualidades?
						</h3>
					</div>
					<div className={classes.textCard}>
						<p>
							Ingresa a Saldos y Movimientos para conocer el
							estatus de tu crédito. Si tienes problemas de
							pago, agenda tu cita para recibir una asesoría
							en tu Centro de Servicio Infonavit (Cesi) más
							cercano.
						</p>
						<p>
							¿Identificaste que no se te registró algún pago
							de tu crédito? Levanta una Aclaración de pagos
							para que analicen tu situación.
						</p>
					</div>
				</Paper>
			</Grid>

			<Grid item lg={8}>
				<Grid container spacing={2}>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-credito/saldos-movimientos')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgMovCredito}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/contactanos/haz-una-cita')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgAgendaCita}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>
				<Grid container spacing={2} mt={1}>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-credito/solicitud-reestructuras')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgReestruct}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item sm={6} md={6} lg={6}>
						<a href="/mi-ahorro/resumen-movimientos-ahorro">
							<img
								src={imgPagaCredit}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</a>
					</Grid>
				</Grid>
				<Grid container spacing={2} mt={1}>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-credito/aclaracion-pagos')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgAclaracion}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-ahorro/haz-aportaciones-extraordinarias')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgOpcionesPago}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default AccreditedJudicialProcess;
