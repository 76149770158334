import {
	Grid,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableBody,
} from '@mui/material';
import CustomButton from '../../../components/Button';
import { StyledTableCell, StyledTableRow } from './Style';

interface Props {
	setOpenModal: (avg: boolean) => void;
}

const rows: Array<{ entidad: string; clave: string; viaPago: string }> = [
	{ entidad: 'Banco Azteca', clave: 'Infonavit', viaPago: 'Efectivo' },
	{ entidad: 'Banco del Bienestar', clave: 'Infonavit', viaPago: 'Efectivo o Cheque' },
	{ entidad: 'BanCoppel', clave: 'Infonavit', viaPago: 'Efectivo o Cheque' },
	{ entidad: 'Banorte', clave: 'Infonavit', viaPago: 'Banca Móvil Transferencia' },
	{ entidad: 'BanRegio', clave: 'Infonavit', viaPago: 'Banca Móvil Transferencia' },
	{ entidad: 'BBVA', clave: 'CIE 828289', viaPago: 'Efectivo o Transferencia' },
	{ entidad: 'Citibanamex', clave: 'Infonavit', viaPago: 'Efectivo o Cheque' },
	{ entidad: 'HSBC', clave: 'RAP 5081', viaPago: 'Efectivo o Cheque' },
	{ entidad: 'Santander', clave: 'CUENTA 1234', viaPago: 'Efectivo o Cheque' },
	{ entidad: 'Scotiabank', clave: 'Infonavit', viaPago: 'Efectivo o Cheque' },
];

const CardSald = ({ setOpenModal }: Props) => {
	return (
		<Grid container columns={12} spacing={1} style={{ marginTop: 50 }}>
			<Grid item xs={12} md={12}>
				<TableContainer>
					<Table
						sx={{ minWidth: 650, maxWidth: 850 }}
						style={{
							margin: 'auto',
							marginBottom: 20,
							boxShadow: '1px 1px 5px #888888',
						}}
					>
						<TableHead>
							<TableRow>
								<StyledTableCell>Entidad recaudadora</StyledTableCell>
								<StyledTableCell>Clave de Servicio</StyledTableCell>
								<StyledTableCell>Via de Pago</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row, index) => (
								<StyledTableRow key={index}>
									<StyledTableCell>{row.entidad}</StyledTableCell>
									<StyledTableCell>{row.clave}</StyledTableCell>
									<StyledTableCell>{row.viaPago}</StyledTableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item xs={12} md={12}>
				<div style={{ maxWidth: 250, margin: 'auto', marginBottom: 40 }}>
					<CustomButton
						variant="solid"
						onClick={() => setOpenModal(false)}
						label="Regresar"
					/>
				</div>
			</Grid>
		</Grid>
	);
};

export default CardSald;
