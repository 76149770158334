import React, { Fragment } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useStyles } from './styles';

function createData(bimestre: string, pagoPatron: string, saldoMov: string) {
	return { bimestre, pagoPatron, saldoMov };
}

const rows = [
	createData('Enero - Febrero', 'Marzo', 'Primera semana de abril'),
	createData('Marzo - Abril', 'Mayo', 'Primera semana de junio'),
	createData('Mayo - Junio', 'Julio', 'Primera semana de agosto'),
	createData('Julio - Agosto', 'Septiembre', 'Primera semana de octubre'),
	createData(
		'Septiembre - Octubre',
		'Noviembre',
		'Primera semana de diciembre'
	),
	createData('Noviembre - Diciembre', 'Enero', 'Primera semana de febrero'),
];

export default function MySavingTable() {
	const classes = useStyles();
	return (
		<Fragment>
			<p className={classes.containerTitle}>
				<strong>
					Calendario de actualización de los movimientos y del saldo
					de la Subcuenta de Vivienda, por aportaciones patronales
				</strong>
			</p>
			<div className={classes.headerTableTitle}>
				<strong style={{ color: 'white', fontSize: 14 }}>
					Verifica cuándo aparecerá tu aportación en nuestro sistema
				</strong>
			</div>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								variant="head"
								className={classes.tableTitleHeader}
							>
								Bimestre
							</TableCell>
							<TableCell
								variant="head"
								className={classes.tableTitleHeader}
							>
								Pago de tu patrón
							</TableCell>
							<TableCell
								variant="head"
								className={classes.tableTitleHeader}
							>
								Saldo y movimientos actualizados*
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => (
							<TableRow
								key={index}
								sx={{
									'&:last-child td, &:last-child th': {
										border: 0,
									},
								}}
							>
								<TableCell component="th" scope="row">
									{row.bimestre}
								</TableCell>
								<TableCell
									align="left"
									sx={{
										color: '#293990',
									}}
								>
									{row.pagoPatron}
								</TableCell>
								<TableCell>{row.saldoMov}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	);
}
