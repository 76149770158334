//AuthorizationSpouse_BC

import { HeaderList } from '../../HeaderList';

const AuthorizationSpouseBC = () => {
	return (
		<>
			<div>
				<p>Tu cónyuge debe seguir estos pasos:</p>
				<HeaderList
					listSizeIcon={18}
					list={[
						<>Ingresar con su usuario a Mi cuenta Infonavit</>,
						<>Entrar a la opción "Me interesa un crédito"</>,
						<>
							Elegir lo que desean hacer con su crédito, el tipo
							de crédito (individual o conyugal) y enseguida
							estará en posibilidades de "
							<b>
								Autorizar la consulta de su historial crediticio
							</b>
							" y con ello incrementar el monto de su crédito.
						</>,
					]}
				/>
			</div>
			<p>
				Una vez que tu cónyuge haya <b>autorizado</b> la consulta a su
				historial y <b>capturado</b> los datos requeridos, conocerá sus
				nuevas condiciones financieras y verá reflejado el incremento en
				su monto de crédito, independientemente de si lo hace en forma
				individual o conyugal.
			</p>
			<p>
				De igual manera cuando ingreses nuevamente a Mi cuenta Infonavit
				podrás notar el incremento en su monto de crédito.
			</p>
		</>
	);
};

export default AuthorizationSpouseBC;
