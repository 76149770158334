import { Paper, useMediaQuery, Grid, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../../components/Button';
import { useNavigate } from 'react-router-dom';

interface Props {
	setPage: (arg: number) => void;
}

const ThankServices = ({ setPage }: Props) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const navigate = useNavigate();

	return (
		<Paper style={{ marginTop: 30, padding: matches ? '20px 20px' : '20px 50px' }}>
			<h2 style={{ color: '#293990', textAlign: 'center', marginBottom: 40 }}>
				Gracias por utilizar los servicios electrónicos del Infonavit
			</h2>

			<Grid container columns={12} margin={'20px auto'} maxWidth={950}>
				<Grid item xs={12} md={6} fontWeight={700}>
					Número de caso
				</Grid>
				<Grid item xs={12} md={6} textAlign={matches ? 'initial' : 'end'}>
					0123456789
				</Grid>
				<Grid item xs={12} md={12} margin={'20px 0px'}>
					<Divider />
				</Grid>
				<Grid item xs={12} md={6} fontWeight={700}>
					Fecha de solicitud
				</Grid>
				<Grid item xs={12} md={6} textAlign={matches ? 'initial' : 'end'}>
					18/12/2022
				</Grid>
				<Grid item xs={12} md={12} margin={'20px 0px'}>
					<Divider />
				</Grid>
				<Grid item xs={12} md={6} fontWeight={700}>
					Servicio
				</Grid>
				<Grid item xs={12} md={6} textAlign={matches ? 'initial' : 'end'}>
					XXXXX
				</Grid>
				<Grid item xs={12} md={12} margin={'20px 0px'}>
					<Divider />
				</Grid>
				<Grid item xs={12} md={6} fontWeight={700}>
					Estatus
				</Grid>
				<Grid item xs={12} md={6} textAlign={matches ? 'initial' : 'end'}>
					XXXXXXXXXX
				</Grid>
				<Grid item xs={12} md={12} margin={'20px 0px'}>
					<Divider />
				</Grid>
				<Grid item xs={12} md={6} fontWeight={700}>
					Fecha de estatus
				</Grid>
				<Grid item xs={12} md={6} textAlign={matches ? 'initial' : 'end'}>
					18/12/2022
				</Grid>
				<Grid item xs={12} md={12} margin={'20px 0px'}>
					<Divider />
				</Grid>
				<Grid item xs={12} md={6} fontWeight={700}>
					Descripción
				</Grid>
				<Grid item xs={12} md={6} textAlign={matches ? 'initial' : 'end'}>
					XXXXXXXXX
				</Grid>
				<Grid item xs={12} md={12} margin={'20px 0px'}>
					<Divider />
				</Grid>
			</Grid>

			<div
				style={{
					width: matches ? '100%' : 250,
					display: 'flex',
					margin: '30px auto',
				}}
			>
				<CustomButton
					label={'Finalizar'}
					onClick={() => navigate('/')}
					variant="solid"
				/>
			</div>
		</Paper>
	);
};

export default ThankServices;
