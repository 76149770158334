// DEPENDENCIES
import React, { Fragment, useState } from 'react';
import { Paper } from '@mui/material';
// COMPONENTS
import Tabs from '../../components/Tabs';
import Layout from '../../components/Layout';
import { HeaderList } from '../../components/HeaderList';
// VIEWS
import NewContributionView from './tabNewContribution';
import ConsultFilesView from './tabConsultFiles';
import ContributionsView from './tabContributions';
// RESOURCES
import { formatDate } from '../../utils/dates';
import { useTabs } from '../../hooks/useTabs';
import { useStyles } from './styles';
import ModalSuspendido from './ModalSuspendido';

const listItems = [
	{
		name: 'Con este servicio puedes:',
		childs: [
			'Aumentar tu ahorro en el Infonavit por tu propia cuenta sin importar si tienes o no una relación laboral, ya que las Aportaciones Extraordinarias son adicionales a las aportaciones obligatorias que debe realizar tu empleador de manera bimestral al Infonavit.',
			'Consultar el estado de las fichas de pago generadas o de los casos que hayas realizado.',
		],
	},
	{
		name: 'Recuerda que no debes exceder el límite mensual permitido en tus Aportaciones Extraordinarias y que éstas se verán reflejadas en tu Resumen de Movimientos 72 horas después de que realices tu pago.',
		childs: [],
	},
];

const ExtraordinaryCont = () => {
	const classes = useStyles();
	const [dataObj, setdataObj] = useState({});
	const tabsFlag = [
		{
			id: 1,
			title: 'Hacer una aportación',
			disabled: false,
			checked: false,
			columns: 2,
			orderMD: 0,
			orderSM: 0,
		},
		{
			id: 2,
			title: 'Consulta de aportaciones',
			disabled: false,
			checked: false,
			columns: 2,
			orderMD: 1,
			orderSM: 2,
		},
		{
			id: 3,
			title: '¿Qué son las Aportaciones Extraordinarias?',
			disabled: false,
			checked: false,
			columns: 2,
			orderMD: 2,
			orderSM: 3,
		},
	];

	const { containerPosition, tabActive, tabsEle, changeTabCustom } = useTabs(
		tabsFlag,
		1,
		0
	);

	const [modalActive, setModalActive] = useState(false);

	const onValues = (values: any) => {
		let ObjTemp: any = dataObj;

		if (values.name === 'HacerAportacion') {
			ObjTemp.hacerAportacion = values.value;
			setdataObj(ObjTemp);
		}

		if (values.name === 'ConsultarFichas') {
			ObjTemp.consultaFichas = values.value;
			setdataObj(ObjTemp);
		}
		if (values.name === 'DefinicionAportaciones') {
			ObjTemp.Definicion = values.value;
			setdataObj(ObjTemp);
		}
	};

	return (
		<Layout>
			<Fragment>
				<ModalSuspendido showModal={modalActive} />
				<HeaderList
					title="Haz Aportaciones Extraordinarias"
					date={formatDate('PPP').toLowerCase()}
					listChilds={listItems}
				/>
				<Tabs
					tabs={tabsEle}
					containerPosition={containerPosition}
					active={tabActive}
					onChangeTab={(indexTab: number) => changeTabCustom(indexTab)}
					containerColumns={6}
					cardsType
				>
					<Paper sx={{ width: '100%' }}>
						{tabActive === 0 && (
							<NewContributionView
								onValues={onValues}
								setModalActive={setModalActive}
								onChangeTab={(indexTab: number) =>
									changeTabCustom(indexTab)
								}
							/>
						)}
						{tabActive === 1 && (
							<ConsultFilesView onValues={onValues} getValue="" />
						)}
						{tabActive === 2 && (
							<ContributionsView onValues={onValues} getValue="" />
						)}
					</Paper>
				</Tabs>
				<br></br>
				<br></br>
				<div className={classes.divButton}>
					<span>
						Responsable de la información:
						<b>
							&nbsp;Gerencia Sr. de Administración del Patrimonio Social y
							Servicios.
						</b>
					</span>
				</div>
			</Fragment>
		</Layout>
	);
};

export default ExtraordinaryCont;
