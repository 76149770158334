/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import { HeaderList } from '../../components/HeaderList';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import { useStyles } from './styles';
import { formatDate } from '../../interfaces/SaldAndMovement';
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';

import { IAlert } from '../../interfaces/alert';
import { makeDate24API } from '../../api/modules/makeDate24';
import { encodeAllJSONData } from '../../utils/encrypt';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IDataCitasActuales } from '../../interfaces/makeDate24';

interface Props {
	setPage: (data: number) => void;
	dataCaso: {
		noCaso: string;
		estatus: string;
		fecha: string;
		fechaEstatus: string;
		descripcion: string;
		tipificacion: string;
		estatusDetalle: {
			descripcion: string;
			estatus: string
		},
		casoCerradoDetalle: {
			razon: string;
			id: string;
			descripcion: string;
		};
	};
	setCitaActual: (data: any) => void;
	setDoctosRequeridosArray: (data: any) => void;
	setMakeDateNow: (data: boolean) => void;
}

const Status = ({ setPage, dataCaso, setCitaActual, setDoctosRequeridosArray, setMakeDateNow }: Props) => {
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [attachDocument, setAttachDocument] = useState(false);
	const [makeDate, setMakeDate] = useState(false);
	const [dateActual, setDateActual] = useState(false);
	const classes = useStyles();
	const navigate = useNavigate();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	useEffect(() => {
		if (dataCaso.tipificacion === 'ZPOC') {
			if (dataCaso.estatusDetalle.estatus === 'Solicitar Cita' || dataCaso.estatusDetalle.estatus === 'Cambiar Cita' || dataCaso.estatusDetalle.estatus === 'Abierto con cita') {
				handlecitasActuales(user?.nss)
			}
		} else if (dataCaso.tipificacion === 'Z2C5' && dataCaso.estatus === 'E0002') {
			setAttachDocument(true)
		} else if (dataCaso.tipificacion === 'ZPEX' && dataCaso.estatus === 'E0002') {
			setAttachDocument(true)
		} else if (dataCaso.tipificacion === 'ZSUA' && dataCaso.estatus === 'E0003') {
			setAttachDocument(true)
		}
	}, [dataCaso]);

	const handlecitasActuales = async (nss: string | any) => {
		try {
			setLoading(true);
			const data: IDataCitasActuales = {
				pAppointmentCode: '',
				nss: nss || ''
			}
			const dataEncript = await encodeAllJSONData({ data: JSON.stringify(data), }, user?.k || '');
			makeDate24API
				.citasActuales(dataEncript)
				.then((response) => {
					if (response.code === '0000') {
						if (response.data?.citas && response.data.citas.length > 0) {
							let doctosRequerido: string = '';
							let hasDate = false
							response.data.citas.forEach(async (element: any) => {
								if (element.serviceId === '0231') {
									setCitaActual(element)
									hasDate = true
								}
								const params = {
									pOrganizationCode: element?.organizationCode,
									pServiceId: element?.serviceId,
									nss: nss || ''
								};
								const dataEncript = await encodeAllJSONData(
									{
										data: JSON.stringify(params),
									},
									user?.k || ''
								);
								const responseDoctosRequeridos = await makeDate24API.documentosRequeridos(dataEncript);
								if (responseDoctosRequeridos.code === '0000') {
									const listado = saltosDeLinea(
										responseDoctosRequeridos?.data?.requirementsList[0]?.requirementText
									);
									if (listado && listado.length > 0) {
										doctosRequerido = responseDoctosRequeridos?.data?.requirementsList[0]?.requirementText.replace(
											'style="color:windowtext"',
											"style={{color:'windowtext'}}",
										) || '';
										doctosRequerido = doctosRequerido.replace(/&bull;/g, '• ');
										doctosRequerido = doctosRequerido.replace(/&bull;• /g, '• ');
									}
									setDoctosRequeridosArray(doctosRequerido);
								}
							})
							hasDate ? setDateActual(true) : setMakeDate(true)
						}
					}
				})
				.catch((err: any) => {
					if (!err.res || !err.res.id || err.res.id !== '2') {
						setAlert({
							show: true,
							message:
								err.description ? err.description : 'Por el momento el servicio no está disponible. Intenta más tarde',
							severity: 'error',
						});
					} else {
						setMakeDate(true)
					}
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (err: any) {
			setAlert({
				show: true,
				message: err.description ? err.description : 'Por el momento el servicio no está disponible. Intenta más tarde',
				severity: 'error',
			});
		}
	};
	const saltosDeLinea = (docs: any) => {
		let str = docs;
		let search = '';
		let replacer;

		str = str.replace(replacer, '\n');

		search = '</p>';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '');

		search = '<br />';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '\n');

		search = '&nbsp;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, ' ');

		search = '&gt;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '->');

		search = '&bull;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '');
		str = str.split('<p>');

		str = str.map((a: any) => {
			return a.trim();
		});

		return str;
	};
	const stripHTML = (html: any) => {
		let div = document.createElement('div');
		div.innerHTML = html;
		return div.textContent || div.innerText || '';
	};

	return (
		<Box>
			<HeaderList title="Aclaración de pagos" />
			<ModalLoading loading={loading} />
			<Paper sx={{ pt: 5, pb: 5, pr: matches ? 5 : 15, pl: matches ? 5 : 15 }}>
				<h2 style={{ color: '#293990', textAlign: 'center', paddingBottom: 20 }}>
					Gracias por utilizar los servicios digitales del Infonavit
				</h2>
				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Número de caso
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'right' }}
					>
						<label>{dataCaso.noCaso}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />
				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Fecha de solicitud
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'right' }}
					>
						<label>{formatDate(dataCaso?.fecha, '/')}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Servicio
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'right' }}
					>
						<label>{dataCaso.descripcion}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Estatus
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'right' }}
					>
						{dataCaso.estatusDetalle.estatus}
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Fecha de estatus
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'right' }}
					>
						<label>{formatDate(dataCaso?.fechaEstatus, '/')}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.2 }} />

				<CustomAlert
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
				/>

				{dateActual && (
					<div
						style={{
							maxWidth: 800,
							margin: 'auto',
							marginTop: 30,
						}}
					>
						<div className={classes.message}>
							<span>
								{dataCaso.estatusDetalle.descripcion}
							</span>
						</div>
						<div
							style={{
								width: matches ? '100%' : '600px',
								margin: '30px auto 20px',
								display: matches ? 'block' : 'flex',
								justifyContent: 'space-between',
							}}
						>
							<CustomButton
								label="Ver cita"
								disabled={alert.show}
								onClick={() => {
									setMakeDateNow(false)
									setPage(5)
								}}
								variant="solid"
								styles={{
									width: 260,
									height: 40,
									margin: '10px auto',
								}}
							/>
						</div>
					</div>
				)}
				{makeDate && (
					<div
						style={{
							maxWidth: 800,
							margin: 'auto',
							marginTop: 30,
						}}
					>
						<div className={classes.message}>
							<span>
								{dataCaso.estatusDetalle.descripcion}
							</span>
						</div>
						<div
							style={{
								maxWidth: 260,
								display: 'flex',
								margin: 'auto',
								marginTop: 30,
							}}
						>
							<CustomButton
								variant="solid"
								label={'Hacer cita'}
								onClick={() => {
									setMakeDateNow(false)
									setPage(5)
								}}
							/>
						</div>
					</div>
				)}
				{attachDocument && (
					<div
						style={{
							maxWidth: 800,
							margin: 'auto',
							marginTop: 30,
						}}
					>
						<div className={classes.message}>
							<span>
								{dataCaso.estatusDetalle.descripcion}
							</span>
						</div>
						<div
							style={{
								maxWidth: 260,
								display: 'flex',
								margin: 'auto',
								marginTop: 30,
							}}
						>
							<CustomButton
								variant="solid"
								label={'Adjuntar documentos'}
								onClick={() => setPage(3)}
							/>
						</div>
					</div>
				)}

				<div
					style={{
						maxWidth: 800,
						margin: 'auto',
						marginTop: 30,
					}}
				>
					<div className={classes.message}>
						{!attachDocument && !makeDate && !dateActual && (
							<span style={{ display: 'block', marginBottom: '-10px' }}>
								{dataCaso.estatusDetalle.estatus === 'Cerrado' ? dataCaso.casoCerradoDetalle.descripcion : dataCaso.estatusDetalle.descripcion}
							</span>
						)}
						<span><br />
							¿Quieres hacer una aclaración de otros pagos?
						</span>
					</div>
					<div
						style={{
							width: matches ? '100%' : '600px',
							margin: '0px auto',
							display: matches ? 'block' : 'flex',
							justifyContent: 'space-between',
						}}
					>
						<CustomButton
							label="Solicitar aclaración"
							onClick={() => {
								setMakeDateNow(true)
								setPage(0)
							}}
							variant="outlined"
							styles={{
								width: 260,
								height: 40,
								margin: '0px auto',
							}}
						/>
						<CustomButton
							label="Finalizar"
							onClick={() => navigate('/')}
							variant="solid"
							styles={{
								width: 260,
								height: 40,
								margin: '0px auto',
							}}
						/>
					</div>
				</div>
			</Paper>
		</Box>
	);
};

export default Status;
