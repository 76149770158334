import { useSelector } from 'react-redux';
import {
	CODES_RESPONSE,
	CREDITOS,
	DESTINOS,
} from '../../../config/miucConstants';
import {
	ICalculadoraData,
	IConsultarProductosMontosData,
	IMevi,
	IObtenerCatData,
	IPersonInformation,
	IProductMonto,
	IProductoAutoproduccion,
	IProductoMejora,
	ITablaAmortizacionData,
} from '../../../interfaces/creditRequest';
import { RootState } from '../../../store';
import { encodeAllJSONData } from '../../../utils/encrypt';
import { isEmpty } from '../../../utils/validators';
import { useState } from 'react';
import { apiMIUC } from '../../../api/modules/MIUC';
import { CREDIT_OPTIONS } from '../utils/creditOptions.utils';
import { MIUC_PRODUCTS } from '../constants/products';

export const useProductTableMIUC = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const { dataProcessController } = useSelector(
		(state: RootState) => state.miuc
	);
	const [productsAuto, setProductsAuto] = useState<IProductoAutoproduccion[]>(
		[]
	);
	const [productsMejora, setProductsMejora] = useState<IProductoMejora[]>([]);
	const [products, setProducts] = useState<IProductMonto[]>([]);
	const [productsCopy, setProductsCopy] = useState<IProductMonto[]>([]);
	const [isSearch, setIsSearch] = useState<boolean>(
		dataProcessController.processInfo?.isCalculated || false
	);
	const { creditType, credit: destino } = dataProcessController?.processInfo;

	const getAmountModified = (
		user?: IPersonInformation,
		product?: IProductMonto,
		mount?: number
	): {
		montoMaximoModificado: number;
		saldoSARTotalModificado: number | string;
	} => {
		let montoMaximoModificado = mount || 0;
		let saldoSARTotalModificado = user?.saldoSARTotal || 0;
		if (user?.esPolicia) {
			const aforo = Number(product?.mmcMon) + Number(user?.saldoSARTotal);
			const mountSale95 = Number(mount) * 0.95;
			const DIAS: number = 30.4;
			const PRECISION: number = 0.005;
			const valorUmas = Number(user?.valorUMA) * DIAS * 3 + PRECISION;
			if (aforo > mountSale95) {
				if (Number(user?.saldoSARTotal) + valorUmas < mountSale95) {
					montoMaximoModificado =
						mountSale95 - Number(user?.saldoSARTotal);
				} else {
					saldoSARTotalModificado = mountSale95 - valorUmas;
					montoMaximoModificado = valorUmas;
				}
			}
		}
		return { montoMaximoModificado, saldoSARTotalModificado };
	};

	// ? Siempre que salga una linea de me interesa un crédito se agrega en el switch
	const getIndicator = (): string => {
		let esSegundoCreditoFlag = '  ';
		switch (destino) {
			case DESTINOS.COMPRA.codigo:
			case DESTINOS.CREDITERRENO.codigo:
			case MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION:
			case DESTINOS.CONSTRUCCION.codigo:
			case DESTINOS.HIPOTECA.codigo:
				esSegundoCreditoFlag = dataProcessController?.titular
					?.esSegundoCredito
					? '4'
					: '8';
				break;
			default:
				esSegundoCreditoFlag = dataProcessController?.titular
					?.esSegundoCredito
					? '4'
					: '7';
				break;
		}
		return esSegundoCreditoFlag;
	};

	const getDataProductAmount = (values: {
		valorTerreno: string;
		plazoPart1: string;
		plazoPart2?: string;
		montoTitular?: string;
		montoConyuge?: string;
	}): IConsultarProductosMontosData => {
		const { valorTerreno, plazoPart1, plazoPart2, montoTitular, montoConyuge } = values;
		let data: IConsultarProductosMontosData = {
			destino: destino,
			moneda: 'P',
			lineaCredito: ' ',
			estadoMunicipio: '0',
			indicador1erO2do: getIndicator(),
			conyuge: {
				nss: dataProcessController?.conyuge?.nss,
				salarioDiario: dataProcessController?.conyuge?.salarioDiario,
				bimestresContinuos:
					dataProcessController?.conyuge?.bimestresContinuos,
				saldoSARTotal: dataProcessController?.conyuge?.saldoSARTotal,
				edad: dataProcessController?.conyuge?.edad,
				puntosTotales: dataProcessController?.conyuge?.puntosTotales,
				indiceRiesgo: dataProcessController?.conyuge?.indiceRiesgo,
				sexo: dataProcessController?.conyuge?.sexo,
				mevi: {
					stageId:
						dataProcessController?.conyuge?.stageId === '0' ||
						!dataProcessController?.conyuge?.stageId
							? '4'
							: dataProcessController.processInfo?.lineaBC8
							? '0'
							: dataProcessController?.conyuge?.stageId,
				} as IMevi,
			} as IPersonInformation,
			titular: {
				nss: dataProcessController?.titular?.nss,
				valorUMA: dataProcessController.titular.valorUMA,
				salarioDiario: dataProcessController.titular.salarioDiario,
				bimestresContinuos:
					dataProcessController.titular.bimestresContinuos,
				bimestresContinuosMismoPatron:
					dataProcessController.titular.bimestresContinuosMismoPatron,
				saldoSARTotal: dataProcessController.titular.saldoSARTotal,
				edad: dataProcessController.titular.edad,
				puntosTotales: dataProcessController.titular.puntosTotales,
				indiceRiesgo: dataProcessController.titular.indiceRiesgo,
				tipoTrabajador: dataProcessController.titular.tipoTrabajador,
				sexo: dataProcessController?.titular?.sexo,
				mevi: {
					stageId:
						dataProcessController.titular?.stageId === '0' ||
						!dataProcessController?.titular?.stageId
							? '4'
							: dataProcessController.processInfo?.lineaBC8
							? '0'
							: dataProcessController.titular?.stageId,
				} as IMevi,
			} as IPersonInformation,
			numPart: ' ',
			tipoCredito: ' ',
			producto: ' ',
			opcionViviendaPart1: ' ',
			enganchePart1: ' ',
			montoAvaluo: valorTerreno || ' ',
		};

		if (destino === DESTINOS.COMPRA.codigo) {
			data = {
				...data,
				numPart: '2',
				tipoCredito: creditType,
				producto: dataProcessController?.titular?.esPolicia
					? 'TRADB'
					: ' ',
				destino: dataProcessController?.titular?.esPolicia
					? ' '
					: destino,
				lineaCredito: '2',
				montoTitular: montoTitular || ' ',
				montoConyuge: montoConyuge || ' ',
				plazoPart1: plazoPart1,
				plazoPart2: plazoPart2 || ' ',
			};
		} else if (destino === DESTINOS.CREDITO_SEGURO.codigo) {
			data = {
				...data,
				producto: destino,
				destino: ' ',
				indicador1erO2do: '8',
				numPart: '2',
				tipoCredito: CREDITOS.INDIVIDUAL.codigo,
			};
		} else if (destino === DESTINOS.CREDITERRENO.codigo) {
			data = {
				...data,
				tipoCredito: CREDITOS.INDIVIDUAL.codigo,
				destino: ' ',
				producto: 'SUELO',
				estadoMunicipio:
					dataProcessController.processInfo?.selectedState,
				opcionViviendaPart1: getPerfil(),
				valorTerreno:
					(valorTerreno || '').toString().replaceAll(',', '') || ' ',
				enganchePart1: '0',
				plazoPart1: plazoPart1,
				indicador1erO2do: dataProcessController?.titular
					?.esSegundoCredito
					? '4'
					: '8',
				montoTitular: montoTitular || ' ',
				montoConyuge: ' ',
			};
		} else if (destino === MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION) {
			data = {
				...data,
				tipoCredito: CREDITOS.INDIVIDUAL.codigo,
				destino: ' ',
				lineaCredito: '3',
				producto: 'SUELO',
				estadoMunicipio:
					dataProcessController.processInfo?.selectedState,
				opcionViviendaPart1: getPerfil(),
				valorTerreno:
					(valorTerreno || '').toString().replaceAll(',', '') || '',
				enganchePart1: '0',
				plazoPart1: plazoPart1,
				indicador1erO2do: dataProcessController?.titular
					?.esSegundoCredito
					? '4'
					: '8',
				montoTitular: montoTitular || ' ',
				montoConyuge: ' ',
			};
		} else if (destino === DESTINOS.CONSTRUCCION.codigo) {
			data = {
				...data,
				indicador1erO2do: '8',
				lineaCredito: '3',
				tipoCredito: creditType,
				montoTitular: montoTitular || ' ',
				montoConyuge: montoConyuge || ' ',
				plazoPart1: plazoPart1,
				plazoPart2: plazoPart2 || ' ',
			};
		} else if (destino === DESTINOS.HIPOTECA.codigo) {
			data = {
				...data,
				indicador1erO2do: '8',
				plazoPart1: plazoPart1,
				plazoPart2: plazoPart2 || ' ',
				lineaCredito: '5',
				tipoCredito: creditType,
				montoTitular: montoTitular || ' ',
				montoConyuge: montoConyuge || ' ',
			};
		}
		return data;
	};

	const getDataCat = (
		product: IProductMonto,
		conyuge: boolean
	): IObtenerCatData => {
		const data = {
			valorUMA: conyuge
				? dataProcessController?.conyuge?.valorUMA
				: dataProcessController?.titular?.valorUMA,
			salarioDiario: conyuge
				? dataProcessController?.conyuge?.salarioDiario
				: dataProcessController?.titular?.salarioDiario,
			producto: product.producto === 'CII' ? 'SC' : ' ',
			claveProducto: product.producto,
			pagCre: conyuge ? product.desRoaCny : product.desRoa,
			monCre: conyuge ? product.mmcMonCny : product.mmcMon,
			monCfi: conyuge ? product.gasMonCny : product.gasMon,
			tasInt: conyuge ? product.tasIntCny : product.tasInt,
			ctaAdm: isSearch
				? conyuge
					? product.nvoCotaAdminCny
					: product.nvoCotaAdmin
				: conyuge
				? product.ctaAdmCny
				: product.ctaAdm,
			monEco: product.monEco,
			desEco: conyuge ? '0' : product.desEco,
			segDan: conyuge ? product.segDanCny : product.segDan,
			fpp: isSearch
				? conyuge
					? product.nvoFPPCny
					: product.nvoFPP
				: conyuge
				? product.fppCny
				: product.fpp,
			conyuge,
		};
		return data;
	};

	/* TODO Maquetar para obtener propietario */
	const getPerfil = (): string => {
		return dataProcessController?.titular?.creditoHipotecario !== '1' &&
			(
				dataProcessController?.processInfo.landOwner || ''
			).toLowerCase() === 'no'
			? 'A'
			: 'B';
	};

	const hasConyugeOrI = (value: string | number): string | number => {
		if (
			isEmpty(dataProcessController.conyuge) ||
			creditType === CREDIT_OPTIONS.INDIVIDUAL
		) {
			return ' ';
		}
		return value;
	};

	const getDataCalculadora = (
		product: IProductMonto,
		value: string,
		etc?: boolean,
		plazoPart1?: string,
		plazoPart2?: string
	): ICalculadoraData => {
		const valorOperacionFlag = Number(value.toString().replaceAll(',', ''));
		let data: ICalculadoraData = {
			nss: user?.nss,
			salarioMinimoDF: dataProcessController?.titular?.valorUMA,
			programaEspecial: product.producto,
			montoCredito: product.mmcMon || 0,
			sarTotal: Number(
				dataProcessController?.titular?.saldoSARTotal || '0'
			),
			cargasFinancieras: product.gasMon || 0,
			descuento: Number(product.desTot || '0'),
			descuentoSinSubsidio: Number(product.desRea || '0'),
			montoCreditoTabla: Number(product.mmcMonTab || '0'),
			sueldoTrabajador:
				dataProcessController?.titular?.salarioDiario || 0,
			factorROA: product.facRoaTra || 0,
			factorREA: product.facReaTra || 0,
			factorROACredito: product.facRoaCre || 0,
			factorREACredito: product.facReaCre || 0,
			seguroDeDanos: product.segDan || 0,
			participacionInfonavitCII: 0,
			fpp: product.fpp || 0,
			valorOperacion: valorOperacionFlag,
			montoCreditoSolicitado: product.mmcMon || 0,
			descuentoCreditoSolicitado: 0,
			descuentoCreditoSolicitadoCny: 0,
			tipoCredito: [
				CREDITOS.CORRESIDENCIAL.codigo,
				CREDITOS.FAMILIAR.codigo,
				CREDITOS.CONYUGAL.codigo,
			].includes(creditType)
				? 'C'
				: creditType,
			esquema: ' ',
			montoCreditoCny: hasConyugeOrI(Number(product.mmcMonCny || '0')),
			montoCreditoSolicitadoCny: ' ',
			sarTotalCny: hasConyugeOrI(
				getAmountModified(
					dataProcessController?.conyuge as unknown as IPersonInformation
				).saldoSARTotalModificado
			),
			cargasFinancierasCny: hasConyugeOrI(product.gasMonCny || '0'),
			descuentoSinSubsidioCny: hasConyugeOrI(product.desReaCny),
			participacionInfonavitCIICny: 0,
			montoCreditoTablaCny: hasConyugeOrI(
				Number(product.mmcMonTabCny || '0')
			),
			sueldoTrabajadorCny: hasConyugeOrI(
				dataProcessController?.conyuge?.salarioDiario || '0'
			),
			descuentoCny: Number(product.desTotCny || '0'),
			factorROACny: hasConyugeOrI(Number(product.facRoaTraCny || '0')),
			factorREACny: hasConyugeOrI(Number(product.facReaTraCny || '0')),
			factorROACreditoCny: hasConyugeOrI(
				Number(product.facRoaCreCny || '0')
			),
			factorREACreditoCny: hasConyugeOrI(
				Number(product.facReaCreCny || '0')
			),
			seguroDeDanosCny: hasConyugeOrI(Number(product.segDanCny || '0')),
			fppCny: hasConyugeOrI(Number(product.fppCny || '0')),
			// montoAvaluo: valorOperacionFlag,
		};

		// Ajuste para calculadora monto minimos, avaluo
		if (
			[DESTINOS.COMPRA.codigo, DESTINOS.CREDITERRENO.codigo].includes(
				destino
			) &&
			product.producto === 'CI'
		) {
			data.montoAvaluo = valorOperacionFlag;
		}

		if (
			destino === DESTINOS.COMPRA.codigo ||
			destino === DESTINOS.CONSTRUCCION.codigo ||
			destino === DESTINOS.HIPOTECA.codigo ||
			destino === DESTINOS.CREDITERRENO.codigo
		) {
			const montoTablaModificado = Number(product.mmcMonTab || '0');
			let factor = getIndicator();
			data = {
				...data,
				sarTotal: getAmountModified(
					dataProcessController?.titular as unknown as IPersonInformation
				).saldoSARTotalModificado,
				descuento:
					Number(product.desTot || '0') -
					Number(product.desEco || '0'),
				montoCreditoTabla: montoTablaModificado,
				factorROA:
					factor === '8' ? product.facRoaCre : product.facRoaTra,
				factorROACny:
					factor === '8' ? product.facRoaCreCny : product.facRoaTraCny,
				factorREA:
					factor === '8' ? product.facReaCre : product.facReaTra,
				factorREACny:
					factor === '8' ? product.facReaCreCny : product.facReaTraCny,
				factorROACredito:
					destino === DESTINOS.HIPOTECA.codigo
						? '-5.0'
						: factor === '8'
						? 0
						: product.facRoaCre,
				factorREACredito: factor === '8' ? 0 : product.facReaCre,
				esquema: factor === '8' ? '8' : ' ',
				montoCreditoSolicitado: etc
					? product.mmcMon
					: getAmountModified(
							dataProcessController?.titular as unknown as IPersonInformation,
							product,
							valorOperacionFlag
					  ).montoMaximoModificado,
				montoCreditoSolicitadoCny: etc
					? product.mmcMonCny || '0'
					: getAmountModified(
							dataProcessController?.conyuge as unknown as IPersonInformation,
							product,
							valorOperacionFlag
					  ).montoMaximoModificado,
				plazoPart1: plazoPart1 || ' ',
				plazoPart2: plazoPart2 || ' ',
				montoMaximoETC: etc ? product.monEco || 0 : 0,
			};
		}
		// Ajuste para calculadora montos minimos y plazo flexibles
		if (
			[
				MIUC_PRODUCTS.COMPRA,
				MIUC_PRODUCTS.CONSTRUCCION,
				MIUC_PRODUCTS.HIPOTECA,
				MIUC_PRODUCTS.CREDITERRENO,
				MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION,
			].includes(
				destino
			)
		) {
			if (product.montoCreditoSolicitado) {
				data.montoCreditoSolicitado = product.montoCreditoSolicitado;
			}
			if (product.montoCreditoSolicitadoCny) {
				data.montoCreditoSolicitadoCny =
					product.montoCreditoSolicitadoCny;
			}
		}
		data.lineaCredito = data.lineaCredito = (destino || '00').substring(1, 2);
		return data;
	};

	const handleAmortizationTable = async (
		product: IProductMonto,
		conyuge: boolean
	): Promise<ITablaAmortizacionData> => {
		let destTot: string = '0';
		let descCredit: string = '0';
		let fondoProPagos: string = '0';
		let descuentoEco: string = '0';
		let destinoSeleccionado: string = '0';
		let productFlag: IProductMonto = { ...product };

		const destinoNvo =
			destino === MIUC_PRODUCTS.MEJORAVIT
				? MIUC_PRODUCTS.REMODELACION
				: destino;

		// console.log(isSearch)
		if (isSearch) {
			if (productFlag.producto !== 'COFIA') {
				const dataCat = getDataCat(product, conyuge);
				const dataEncripted = await encodeAllJSONData(
					{ data: JSON.stringify(dataCat) },
					user?.k || ''
				);
				const responseCAT = await apiMIUC.obtenerCAT(dataEncripted);
				if (responseCAT?.code === CODES_RESPONSE.CORRECTO) {
					productFlag = {
						...productFlag,
						cat: !conyuge ? responseCAT?.data?.cat : null,
						catCny: conyuge ? responseCAT?.data?.catCny : null,
					};
				}
			}
		}

		if (
			(destinoNvo === DESTINOS.COMPRA.codigo ||
				destinoNvo === DESTINOS.HIPOTECA.codigo ||
				destinoNvo === DESTINOS.CONSTRUCCION.codigo) &&
			!conyuge
		) {
			const desEcoF =
				product.producto !== 'MVIT'
					? Number(productFlag.desEco || '0')
					: 0;
			destTot = (
				Number(productFlag.desTot || '0') -
				Number(productFlag.segDan || '0') -
				desEcoF
			).toString();
		} else if (
			(destinoNvo === DESTINOS.COMPRA.codigo ||
				destinoNvo === DESTINOS.HIPOTECA.codigo ||
				destinoNvo === DESTINOS.CONSTRUCCION.codigo) &&
			conyuge
		) {
			destTot = Number(productFlag.desTotCny).toString();
		} else {
			destTot = conyuge
				? productFlag.desTotCny || '0'
				: productFlag.desTot || '0';
		}

		if (productFlag.producto === 'MVIT') {
			descCredit = destTot;
		} else if (isSearch) {
			if (productFlag.producto !== 'COFIA') {
				descCredit = conyuge
					? productFlag.desRoaCny
					: productFlag.desRoa;
			}
		} else {
			descCredit = conyuge ? productFlag.desCreCny : productFlag.desCre;
		}

		if (productFlag.producto === 'MVIT') {
			fondoProPagos = '0';
		} else if (isSearch) {
			if (productFlag.producto === 'COFIA') {
				fondoProPagos = conyuge ? productFlag.fppCny : productFlag.fpp;
			} else {
				fondoProPagos = conyuge
					? productFlag.nvoFPPCny
					: productFlag.nvoFPP;
			}
		} else {
			fondoProPagos = conyuge ? productFlag.fppCny : productFlag.fpp;
		}

		if (productFlag.producto === 'MVIT' || conyuge) {
			descuentoEco = '0';
		} else {
			descuentoEco = productFlag.desEco || '0';
		}

		if (destinoNvo) {
			destinoSeleccionado = destinoNvo.substring(1, 2);
			if (destinoNvo === DESTINOS.CREDITERRENO.codigo) {
				destinoSeleccionado = '2' + getPerfil();
			}
			if (destinoNvo === DESTINOS.SUELO_CONSTRUCCION.codigo) {
				destinoSeleccionado = '3' + getPerfil();
			}
		} else {
			destinoSeleccionado = '0';
		}

		// Nuevos cambios de Equipa tu casa
		let tipoCredito = '';
		if (creditType === 'I') {
			tipoCredito = 'I';
		} else {
			if (destinoNvo === DESTINOS.COMPRA.codigo) {
				if (creditType === 'C') {
					!conyuge ? (tipoCredito = 'C1') : (tipoCredito = 'C2');
				} else if (creditType === 'F') {
					!conyuge ? (tipoCredito = 'F1') : (tipoCredito = 'F2');
				} else if (creditType === 'R') {
					!conyuge ? (tipoCredito = 'R1') : (tipoCredito = 'R2');
				}
			} else if (
				[
					DESTINOS.CREDITERRENO.codigo,
					DESTINOS.SUELO_CONSTRUCCION.codigo,
				].includes(destinoNvo)
			) {
				tipoCredito = 'I';
			} else {
				if (creditType === 'C') {
					!conyuge ? (tipoCredito = 'I') : (tipoCredito = 'C');
				} else {
					conyuge
						? (tipoCredito = creditType)
						: (tipoCredito = `${creditType}1`);
				}
			}
		}

		// console.log(productFlag)
		/* EL cat se obtiene cuando se manda llamar al CAT y esta recalculado*/
		const data: ITablaAmortizacionData = {
			nss: conyuge
				? dataProcessController?.conyuge?.nss
				: dataProcessController?.titular?.nss,
			tipoCredito: tipoCredito,
			producto: productFlag.producto,
			montoMaximoCredito: conyuge
				? productFlag.mmcMonCny || '0'
				: productFlag.mmcMon || '0',
			descuentoTrabajador: destTot.toString() || '0',
			tasaInteres: conyuge
				? productFlag.tasIntCny || '0'
				: productFlag.tasInt || '0',
			salarioDiarioIntegrado: conyuge
				? dataProcessController?.conyuge?.salarioDiario || '0'
				: dataProcessController?.titular?.salarioDiario || '0',
			descuentoCredito: descCredit.toString() || '0',
			salarioMinimoDiario:
				dataProcessController?.titular?.valorUMA || '0',
			fondoProteccionPagos: (fondoProPagos || '0').toString(),
			descuentoTrabajadorEcos: descuentoEco || '0',
			destino: destinoSeleccionado || '0',
			cat: conyuge ? productFlag.catCny : productFlag.cat || '0',
			descuentoTrabajadorSegDan: conyuge
				? productFlag.segDanCny || '0'
				: productFlag.segDan || '0',
			montoMaximoEcos: conyuge
				? '0'
				: productFlag.montoETC?.toString() || '0',
			conyuge,
		};
		return data;
	};

	return {
		products,
		setProducts,
		productsCopy,
		setProductsCopy,
		isSearch,
		setIsSearch,
		getDataProductAmount,
		getDataCalculadora,
		handleAmortizationTable,
		getDataCat,
		productsAuto,
		setProductsAuto,
		productsMejora,
		setProductsMejora,
	};
};
