import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useController } from '../../hooks/useController';
import { useStyles } from './styles';
import { defaultAddress } from '../../../CreditRequest/MIUC/utils';
import { IAlert } from '../../../../interfaces/alert';
import TextInput from '../../../../components/TextInput';
import { isNumberValidate } from '../../../../utils/expressions';
import { creditRequestAPI } from '../../../../api/modules/CreditRequest';
import { keyAPI } from '../../../../api/modules/key';
import { encodeAllJSONData, encrypt } from '../../../../utils/encrypt';
import {
	ERROR_MESSAGE,
	IButtons,
	creditOptionsInfo,
	mappedStageErrors,
} from '../../utils/buroForm.utils';
import { formatDate, getDateFromCURP } from '../../../../utils/dates';
import {
	IConsultarHistorialParams,
	apiMIUC,
} from '../../../../api/modules/MIUC';
import { STAGE_ID } from '../../constants/stageId';
import { CODES_RESPONSE } from '../../../../config/miucConstants';
import { RETURN_CODE } from '../../constants/returnCode';
import { isEmpty } from '../../../../utils/validators';
import { Box, Tooltip } from '@mui/material';
import { Error } from '@mui/icons-material';
import { useBitacora } from '../../../../hooks/useBitacora';

export const useBuro = () => {
	const {
		returnStep,
		addSelection,
		saveData,
		nextStep,
		getProgressStep,
		dataProcessController,
	} = useController();
	const { regBitacoraNotOld } = useBitacora();
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const [loader, setLoader] = useState<boolean>(false);
	const [form, setForm] = useState<any>(defaultAddress);
	const [valid, setValid] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [attempts, setAttempts] = useState<number>(0);
	const [authorization, setAuthorization] = useState<boolean>(true);
	const [creditOptionValue, setCreditOptionValue] = useState({
		tieneTDC: false,
		tieneAuto: false,
		tieneHipotecario: false,
	});

	const { active, total } = getProgressStep();
	const newStep = {
		title: 'Autoriza la consulta y aumenta tus posibilidades',
		subtitle:
			'Al autorizar el conocer cómo manejas tus créditos, se te podrán ofrecer más alternativas de financiamiento y un mayor monto de crédito.',
		active,
		total,
	};

	const changeChekbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCreditOptionValue({
			...creditOptionValue,
			[event.target.name]: event.target.checked,
		});
	};

	const getRadioButtons = (buttonsInfo: IButtons[]) => {
		return buttonsInfo.map((buttonInfo) => {
			return {
				...buttonInfo,
				label: (
					<Box className={classes.radioContainer}>
						<p className="label">{buttonInfo.label}</p>
						{buttonInfo.tooltip && (
							<Tooltip
								title={buttonInfo.tooltip}
								placement="right"
								arrow
							>
								<Error color="error" fontSize="small" />
							</Tooltip>
						)}
					</Box>
				),
				container: buttonInfo?.container && (
					<>
						{creditOptionValue.tieneTDC && (
							<div className={classes.creditDigit}>
								<TextInput
									type="password"
									placeholder="****"
									value={form.ultimos4DigitosTDC}
									id={'input-ultimos4DigitosTDC'}
									name="ultimos4DigitosTDC"
									label="Últimos 4 dígitos de la TDC"
									onChange={(e: any) =>
										isNumberValidate(e.target.value) &&
										handleChange(e)
									}
								/>
							</div>
						)}
					</>
				),
			};
		});
	};

	const creditOptions = getRadioButtons(creditOptionsInfo);

	const disableContinueButton = useMemo(() => {
		return (
			authorization &&
			(!form.cp ||
				!form.colonia ||
				!form.calle ||
				!form.numeroExterior ||
				!form.telefono ||
				(Number(form.salarioMensual || '') === 0 &&
					dataProcessController.processInfo?.esMejoraDirecta) ||
				!valid ||
				attempts >= 3)
		);
	}, [authorization, form, attempts, valid, dataProcessController]);

	useEffect(() => {
		if (Number(dataProcessController?.titular?.stageId || 0) > 0) {
			addSelection({
				title: 'Historial crediticio:',
				description: 'Autorizado',
			});
			saveData(
				{
					processInfo: {
						autorization: true,
					},
					processData: {
						step: dataProcessController.processData.step + 1,
						active: getProgressStep().active + 1,
					},
				},
				true
			);
		}
	}, [dataProcessController?.titular?.stageId]);

	useEffect(() => {
		if (form?.cp?.length === 5) fetchAsentamientos();
	}, [form?.cp?.length]);

	useEffect(() => {
		validaciones();
	}, [form, creditOptionValue]);

	const fetchAsentamientos = async () => {
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const CP_BASE64 = await encrypt(form?.cp, key);
			const { result } = await creditRequestAPI.consultarAsentamientos(
				CP_BASE64
			);
			setForm({ ...form, ...result, salarioMensual: 0 });
			setAlert({
				show: false,
				severity: 'error',
				message: '',
			});
		} catch (error: any) {
			setForm(defaultAddress);
			setAlert({
				show: true,
				severity: 'error',
				message: error?.description || ERROR_MESSAGE.NO_SERVICE,
			});
		} finally {
			setLoader(false);
		}
	};

	const handleChange = ({ target }: any) => {
		const regex = new RegExp('^[A-Za-z0-9 ]+$');

		if (target.name === 'cp' && target.value.length > 5) return;

		if (target.name === 'ultimos4DigitosTDC' && target.value.length > 4)
			return;

		if (target.name === 'ultimos4DigitosTDC' && isNaN(Number(target.value)))
			return;

		if (target.name === 'telefono' && target.value.length > 10) return;

		if (
			target.name === 'calle' &&
			target.value.length > 0 &&
			!regex.test(target.value)
		) {
			return;
		}

		if (
			target.name === 'numeroExterior' &&
			target.value.length > 0 &&
			!regex.test(target.value)
		) {
			return;
		}

		if (
			target.name === 'numeroInterior' &&
			target.value.length > 0 &&
			!regex.test(target.value)
		) {
			return;
		}

		setForm({ ...form, [target.name]: target.value });
	};

	const validaciones = () => {
		let check = {
			message: '',
			status: true,
		};

		if (form.telefono && (form.telefono || '').length < 10) {
			check.message = 'Tu número de teléfono debe constar de 10 dígitos';
			check.status = false;
		}

		if (form.cp && (form.cp || '').length < 5) {
			check.status = false;
			check.message = 'El codigo postal debe constar de 5 digitos';
		}

		if (creditOptionValue.tieneTDC) {
			const cadena = String(form.ultimos4DigitosTDC || '').length;
			if (cadena < 4) {
				check.message =
					'Tus ultimos dígitos de TDC debe constar de 4 números';
				check.status = false;
			}
		}

		setAlert({
			show: !check.status,
			severity: 'error',
			message: check.message,
		});

		setValid(check.status);
	};

	const getNames = (): { firstName: string; secondName: string } => {
		if ((user?.nombres || '').split(' ').length > 1) {
			const firstName = (user?.nombres || '').split(' ').shift() || '';
			let secondNameArry = (user?.nombres || '').split(' ');
			secondNameArry.splice(0, 1);
			const secondName = secondNameArry.reduce(
				(previousValue: any, currentValue: string) =>
					(previousValue += ` ${currentValue}`),
				''
			);
			return {
				firstName,
				secondName: secondName.trim(),
			};
		}
		return {
			firstName: (user?.nombres || '').split(' ').shift() || '',
			secondName: '',
		};
	};

	const nextProcess = async () => {
		let information = {};
		if (!authorization) {
			information = {
				processInfo: {
					autorization: false,
				},
				processData: {
					active: getProgressStep().active + 1,
				},
				titular: {
					stageId: dataProcessController.titular?.stageId,
				},
			};
			addSelection({
				title: 'Historial crediticio:',
				description: authorization ? 'Autorizado' : 'No Autorizado',
			});
			saveData(information);
			nextStep();
		} else {
			try {
				setLoader(true);
				information = {
					processInfo: {
						autorization: true,
						userInfo: {
							creditInfo: {
								tieneTDC: form?.tieneTDC,
								creditLast4Digits: form?.tieneTDC
									? form?.ultimos4DigitosTDC
									: '',
								tieneAuto: form?.tieneAuto,
								tieneHipotecario: form?.tieneHipotecario,
							},
							address: {
								codigoPostal: form?.cp,
								// los sig 2 pueden o no ser omitidos
								estado: form?.entidadFederativa,
								delegacion: form?.delegacion,
								colonia: form?.colonia,
								calle: form?.calle,
								numeroExterior: form?.numeroExterior,
								numeroInterior: form?.numeroInterior,
								telefonoCelular: form?.telefono,
							},
						},
					},
					processData: {
						active: getProgressStep().active + 1,
					},
				};
				if (isEmpty(user?.curp)) {
					return setAlert({
						show: true,
						severity: 'error',
						message: (
							<>
								<p>
									Debes verificar que tengas tu CURP
									registrada.{' '}
									<a href="/mi-perfil/correccion-curp-nombre">
										Ir ahí.
									</a>
								</p>
							</>
						),
					});
					// Lo veo mejor al momento de loguearse
					// Hacer que aparesca modal con mensaje sobre ir al modulo de coreccion de CURP
				}
				const data: IConsultarHistorialParams = {
					nss: dataProcessController?.titular?.nss,
					contacto: {
						fechaNacimiento: formatDate(
							'yyyy-MM-dd',
							getDateFromCURP(
								user?.curp && user?.curp?.length > 0
									? user?.curp
									: ''
							)
						),
						nombre: getNames().firstName,
						segundoNombre: getNames().secondName,
						apPaterno: user?.apPaterno || '',
						apMaterno: user?.apMaterno || '',
						rfc: user?.rfc || '',
						curp: user?.curp || '',
						email: user?.email || '',
						telefonoCelular: form?.telefono,
						cp: form?.cp,
						estadoId: form?.entidadFederativa,
						municipio: form?.delegacion,
						colonia: form?.colonia,
						calle: form?.calle,
						numeroExterior: form?.numeroExterior,
						numeroInterior: form?.numeroInterior,
					},
					referencia: {
						ultimos4DigitosTDC: creditOptionValue.tieneTDC
							? form?.ultimos4DigitosTDC
							: '',
						tieneTDC: creditOptionValue.tieneTDC,
						tieneHipotecario: creditOptionValue.tieneHipotecario,
						tieneAuto: creditOptionValue.tieneAuto,
						salarioDiario:
							dataProcessController?.titular?.salarioDiario ||
							'0',
						salarioMensual: form?.salarioMensual || '0',
					},
				};
				const dataEncript = await encodeAllJSONData(
					{
						data: JSON.stringify(data),
					},
					user?.k || ''
				);
				const result = await apiMIUC.consultarHistorial(dataEncript);
				if (result.code === CODES_RESPONSE.CORRECTO) {
					if(Number(result?.data?.stageId || '0') > 0){
						regBitacoraNotOld(apiMIUC.schemeUrl, data);
					}
					if (
						[
							STAGE_ID.BUENO,
							STAGE_ID.REGULAR,
							STAGE_ID.MALO,
						].includes(result?.data?.stageId)
					) {
						saveData({
							titular: {
								stageId: result.data?.stageId,
							},
						});
						setAlert({
							show: false,
							severity: 'error',
							message: '',
						});
						addSelection({
							title: 'Historial crediticio:',
							description: authorization
								? 'Autorizado'
								: 'No Autorizado',
						});
						saveData(information);
						nextStep();
					} else if (
						result?.data?.stageId ===
						STAGE_ID.SIN_REFERENCIA_CREDITICIA
					) {
						saveData({
							titular: {
								stageId: STAGE_ID.REGULAR,
							},
						});
						setAlert({
							show: false,
							severity: 'error',
							message: '',
						});
						saveData(information);
						nextStep();
					} else if (
						[
							STAGE_ID.FRAUDE_DEFUNCION,
							STAGE_ID.DEFUNCION,
							STAGE_ID.NO_AUTENTICA_BC,
							STAGE_ID.ENDEUDAMIENTO,
						].includes(result?.data?.stageId)
					) {
						if (
							dataProcessController.processInfo?.esMejoraDirecta &&
							result.data.stageId === STAGE_ID.ENDEUDAMIENTO
						) {
							saveData(information);
							nextStep();
						}
						setAlert({
							show: true,
							severity: 'error',
							message: mappedStageErrors(
								Number(result?.data?.stageId || 0)
							),
						});
						setAttempts(attempts + 1);
					} else if (
						result?.data?.codRet === RETURN_CODE.RC_10007 ||
						result?.data?.codRet === RETURN_CODE.RC_10
					) {
						setAlert({
							show: true,
							severity: 'error',
							message: result?.data?.message || '',
						});
					} else if (
						result?.data?.stageId ===
						STAGE_ID.SERVICIO_NO_DISPONIBLE
					) {
						setAlert({
							show: true,
							severity: 'error',
							message: ERROR_MESSAGE.NO_VERIFICATION_SERVICE,
						});
					}
				} else {
					setAlert({
						show: true,
						severity: 'error',
						message: result?.message,
					});
				}
			} catch (error: any) {
				setAlert({
					show: true,
					severity: 'error',
					message:
						error?.description || ERROR_MESSAGE.BAD_INFORMATION,
				});
			} finally {
				setLoader(false);
			}
		}
	};

	return {
		newStep,
		loader,
		authorization,
		setAuthorization,
		creditOptionValue,
		changeChekbox,
		creditOptions,
		form,
		attempts,
		alert,
		returnStep,
		disableContinueButton,
		handleChange,
		nextProcess,
		dataProcessController,
	};
};
