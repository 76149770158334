/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { Radio } from '@mui/material';
import { useStyles } from '../styles/styles';
import HeaderStep from './HeaderStep';
import TextInput from '../../../../../components/TextInput';
import { isNumber } from '../../../../../utils/expressions';

const QuestStep7 = ({
	quest,
	nextStep,
	handleChange,
	afterQuestionarySteps,
	alert,
	nextDisabled
}: any) => {
	const classes = useStyles();
	const [quests, setQuests] = useState([] as any);
	const [date, setDate] = useState({
		month: '',
		year: '',
	});
	const [valor, setValor] = useState('');
	const [tasaInteres, setTasaInteres] = useState('');
	const [amount, setAmount] = useState('');
	const [yearsCredit, setYearsCredit] = useState('');
	const [tasaInteres2, setTasaInteres2] = useState('');
	const [valor2, setValor2] = useState('');

	useEffect(() => {
		drawQuestions();
	}, [quest, date, valor, tasaInteres, valor2, tasaInteres2]);

	useEffect(() => {
		let respuestaDate = `Mes ${date.month} Año ${date.year}`;
		handleChange(respuestaDate, '4.15');
	}, [date]);

	useEffect(() => {
		let respuesta = `$${valor}`;
		handleChange(respuesta, '4.12');
	}, [valor]);

	useEffect(() => {
		let respuesta = `$${amount}`;
		handleChange(respuesta, '4.16');
	}, [amount]);

	useEffect(() => {
		let respuesta = ''
		if (tasaInteres.length > 0) {
			respuesta = `${tasaInteres} tasa de interés`;
		}
		handleChange(respuesta, '4.11');
	}, [tasaInteres]);

	useEffect(() => {
		let respuestaDate = `${yearsCredit} Años`;
		handleChange(respuestaDate, '4.17');
	}, [yearsCredit]);

	useEffect(() => {
		let respuesta = `$${valor2}`;
		handleChange(respuesta, '4.19');
	}, [valor2]);

	useEffect(() => {
		let respuesta = ''
		if (tasaInteres2.length > 0) {
			respuesta = `${tasaInteres2} tasa de interés`;
		}
		handleChange(respuesta, '4.18');
	}, [tasaInteres2]);

	const drawQuestions = () => {
		const questions: any = [];
		quest.map((item: any, index: any) => {
			const newItem = {
				title: `${item.numero.split('.')[1]}.- ${item.pregunta}`,
				body: (
					<>
						{item.opciones.length > 0 &&
							item.numero !== '4.15' &&
							item.opciones.map((opcion: any) => (
								<div
									className={classes.itemsFlexCenter}
									key={opcion + item.pregunta}
								>
									<Radio
										checked={
											item.respuesta ===
											opcion.split(')')[0]
										}
										onChange={(e: any) => {
											let value =
												e.target.value.split(')')[0];
											handleChange(value, item.numero);
										}}
										name={opcion.split(')')[0]}
										value={opcion.split(')')[0]}
										disabled={((index === 9) && (quest[3].respuesta === '2')) || nextDisabled.includes(item.numero)}
									/>
									{opcion}
								</div>
							))}

						{item.numero === '4.11' && (
							<div
								style={{
									maxWidth: 300,
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<TextInput
									id="valor"
									name="valor"
									value={tasaInteres}
									placeholder="0"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										setTasaInteres(e.target.value);
									}}
									disabled={nextDisabled.includes(item.numero)}
								/>
								<div
									style={{ paddingLeft: 10, width: '200px' }}
								>
									Tasa de interés
								</div>
							</div>
						)}

						{item.numero === '4.12' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor"
									name="valor"
									value={valor}
									placeholder="$0.00"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										if (e.target.value === '0') return;
										setValor(e.target.value);
									}}
									disabled={nextDisabled.includes(item.numero)}
								/>
							</div>
						)}

						{item.numero === '4.15' && (
							<div
								style={{ maxWidth: 400, display: 'flex' }}
								key={'mes' + item.numero}
							>
								<div
									style={{
										textAlign: 'left',
										marginRight: '20px',
									}}
								>
									<TextInput
										id={'question-month-' + item.numero}
										name={'month'}
										placeholder="Mes"
										onChange={(e: any) => {
											if (!isNumber(e.target.value))
												return;
											if (e.target.value.length > 2)
												return;
											if (e.target.value > 12)
												return;
											if (e.target.value === '00') {
												setDate({
													...date,
													month: '01',
												});
											} else {
												setDate({
													...date,
													month: e.target.value,
												});
											}
										}}
										contentStyles={{ maxWidth: 100 }}
										value={date.month}
										disabled={quest[3].respuesta === '2'}
									/>
								</div>
								<div
									style={{
										textAlign: 'left',
										marginRight: '20px',
									}}
								>
									<TextInput
										id={'question-year-' + item.numero}
										name={'year'}
										placeholder="Año"
										onChange={(e: any) => {
											if (!isNumber(e.target.value))
												return;
											if (e.target.value.length > 4)
												return;
											if (e.target.value.length === 4 && e.target.value < '1900') {
												setDate({
													...date,
													year: '1900',
												});
											} else {
												setDate({
													...date,
													year: e.target.value,
												});
											}
										}}
										contentStyles={{ maxWidth: 300 }}
										value={date.year}
										disabled={quest[3].respuesta === '2'}
									/>
								</div>
							</div>
						)}

						{item.numero === '4.16' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="amount"
									name="amount"
									value={amount}
									placeholder="$0.00"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										if (e.target.value === '0') return;
										setAmount(e.target.value);
									}}
									disabled={quest[3].respuesta === '2'}
								/>
							</div>
						)}

						{item.numero === '4.17' && (
							<div
								style={{ maxWidth: 400, display: 'flex' }}
								key={'mes' + item.numero}
							>
								<div
									style={{
										textAlign: 'left',
										marginRight: '20px',
										maxWidth: 300,
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<TextInput
										id={
											'question-yearsCredit-' +
											item.numero
										}
										name={'yearsCredit'}
										onChange={(e: any) => {
											if (!isNumber(e.target.value))
												return;
											setYearsCredit(e.target.value);
										}}
										contentStyles={{ maxWidth: 300 }}
										value={yearsCredit}
										disabled={quest[3].respuesta === '2'}
									/>
									<div
										style={{
											paddingLeft: 10,
											width: '200px',
										}}
									>
										Años
									</div>
								</div>
							</div>
						)}

						{item.numero === '4.18' && (
							<div
								style={{
									maxWidth: 300,
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<TextInput
									id="valor1"
									name="valor1"
									value={tasaInteres2}
									placeholder="0"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										setTasaInteres2(e.target.value);
									}}
									disabled={quest[3].respuesta === '2'}
								/>
								<div
									style={{ paddingLeft: 10, width: '200px' }}
								>
									Tasa de interés
								</div>
							</div>
						)}

						{item.numero === '4.19' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor2"
									name="valor2"
									value={valor2}
									placeholder="$0.00"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										if (e.target.value === '0') return;
										setValor2(e.target.value);
									}}
									disabled={quest[3].respuesta === '2'}
								/>
							</div>
						)}
					</>
				),
				disabled: ((index === 4 || index === 5 || index === 6 || index === 7 || index === 8 || index === 9) && (quest[3].respuesta === '2')) || (nextDisabled.includes(item.numero)) ? true : false
			};
			return questions.push(newItem);
		});
		setQuests(questions);
	};

	const verifyComplete = () => {
		let questHabilitados: any = []
		for (let i = 0; i < quests.length; i++) {
			if (quests[i].disabled === false) {
				questHabilitados.push(i)
			}
		}
		let respuesta = quest.every((item: any, index: any) => {
			if (questHabilitados.includes(index) && item.respuesta.length === 0) return false
			else return true
		})
		return respuesta
	};

	const sendData = () => {
		nextStep();
	};

	return (
		<HeaderStep
			title={'Egresos y deudas'}
			subTitle={'Crédito personal'}
			nextStep={sendData}
			afterQuestionarySteps={afterQuestionarySteps}
			disabledContinue={!verifyComplete()}
			disabledButton={!verifyComplete()}
			children={quests}
			alert={alert}
		/>
	);
};

export default QuestStep7;
