// Dependencies
import React from 'react';

// Material Components
import { Box, Link } from '@mui/material';

// Assets
import { useStyles } from './styles';

const ContributionsView = (tabs: any) => {
	const styles = useStyles();
	return (
		<>
			<Box>
				<div className={styles.divTextVideo}>
					<span>
						Son depósitos que realizas por tu cuenta para incrementar
						el ahorro que tienes en tu Subcuenta de Vivienda. <br />
						<br />
						Tus Aportaciones Extraordinarias también generan rendimientos
						y te ayudan a precalificar en menos tiempo para obtener un
						crédito Infonavit, además de incrementar tu capacidad de
						compra, ya que al sumarse con el monto de crédito que se te
						puede dar cuentas con más recursos para ocuparlos en tu vivienda.  <br />
						<br />
						Para conocer más consulta esta &nbsp;
						<Link
							className={styles.txtRed}
							target="_blank"
							rel="noopener"
							href={'https://portalmx.infonavit.org.mx/wps/wcm/connect/2ede00b1-9a34-4496-857d-ee316d889821/Uso_Subcuenta_Vivienda-.pdf?MOD=AJPERES&CVID=nIiJpVX'}
						>
							infografía
						</Link>
						&nbsp;y ve este video:
					</span>
				</div>
				<Box className={styles.backgroundContenMargin}>
					<iframe
						width="100%"
						height="400px"
						src="https://www.youtube.com/embed/w1h1-BmB87c"
						title="¿Qué son las Aportaciones Extraordinarias?"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					></iframe>
				</Box>
			</Box>
		</>
	);
};

export default ContributionsView;
