import { makeStyles } from '@mui/styles';

export const useStyles = (color: string) =>
	makeStyles({
		alertIcon: {
			'& .MuiAlert-icon': {
				color: `${color} !important`,
			},
			'& .MuiAlert-message': {
				padding: '0px !important',
			},
		},
		sizeIcon: {
			width: '22px',
			height: '22px',
		},
	});
