import BaseModal from '../../../../../components/Modal';

import { useStyles } from '../styles/styles';

interface IProps {
	open: boolean;
	setOpen: (arg: boolean) => void;
	handleSave: () => void;
	handleReturn?: () => void;
	disabledSave?: boolean;
}

const ModalExit = ({ open, setOpen, handleSave, handleReturn, disabledSave }: IProps) => {
	const classes = useStyles();
	return (
		<BaseModal
			open={open}
			onConfirm={handleSave}
			onCancel={handleReturn}
			onClose={() => setOpen(false)}
			cancelButton={true}
			confirmLabel="Guardar"
			disableOnConfirm={disabledSave}
			cancelLabel="Salir"
			hideCircularProgress={true}
			width="md"
			children={
				<>
					<div>
						<h2 className={classes.titleModal}>
							¡Importante!
						</h2>
						<div className={classes.dialogCheck + ' ' + classes.textModal}>
							<p>Vas a salir del cuestionario para otorgamiento de crédito.</p>
							<p>Si continúas tu progreso se perderá.</p>
							<p><b>¿Quieres salir sin guardar?</b></p>
						</div>
					</div>
				</>
			}
		/>
	);
};

export default ModalExit;
