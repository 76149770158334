import { Box, Paper, useMediaQuery } from '@mui/material';
import React from 'react';
import { HeaderList } from '../../components/HeaderList';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import { useStyles } from './styles';
import CustomAlert from '../../components/CustomAlert';

interface IDataStatus {
	apMaterno?: string;
	apPaterno?: string;
	consecutivo?: string;
	curp?: string;
	nombre?: string;
	nss?: string;
	rfc?: string;
	status?: {
		code?: string;
		description?: string;
	};
	CodigoError?: string;
	TipoError?: string;
	descripcionServicio?: string;
	fecha?: string;
	fechaStatus?: string;
	noCaso?: string;
	nombreOperacion?: string;
	operacion?: string;
}
interface Props {
	setPage: (data: number) => void;
	status: IDataStatus;
}

const CloseCase = ({ setPage, status }: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const classes = useStyles();

	return (
		<Box>
			<HeaderList title="Correción de RFC" date="12 Julio 2021" />
			<Paper sx={{ pt: 4, pb: 4, pr: matches ? 5 : 10, pl: matches ? 5 : 10 }}>
				<CustomAlert
					show={true}
					severity="success"
					message="Tu RFC ha sido corregido, para realizar un trámite deberás esperar 10 días hábiles."
				/>
				<div>
					<p style={{ paddingBottom: matches ? 10 : 25, textAlign: 'center' }}>
						Tu RFC se corrigió con el número de caso {status.noCaso}, ya no es
						necesario que inicies el trámite nuevamente.
					</p>
				</div>
				<div className={classes.botonContainer}>
					<div>
						<CustomButton
							data-testid="buttonSí"
							label="Aceptar"
							onClick={() => setPage(0)}
							variant="solid"
							styles={{
								width: matches ? 340 : 264,
								height: 40,
								marginBottom: matches ? 20 : 5,
								marginLeft: matches ? 0 : 25,
							}}
						/>
					</div>
				</div>
			</Paper>
		</Box>
	);
};

export default CloseCase;
