/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Link, Box, Paper, Grid } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../../../utils/validators';
//Assets

import imgCoin from '../../../assets/img/coin.jpg';
import ViewMoreButton from '../components/viewMoreButton';
import terrenoIcon from '../../../assets/svg/casita2.svg';
import imgSavingIcon from '../../../assets/svg/icono-ahorro.svg';
import imgAgendaCita from '../../../assets/img/Agenda-tu-cita.png';
import imgConsulta from '../../../assets/img/consulta-banner.png';
// import imgInsSocio from '../../../assets/img/inscribete-socio.png';
import imgSaberMas from '../../../assets/img/taller-saber-mas/Banner_SaberMas.png';
import { CheckCircleOutlined } from '@mui/icons-material';

import { useProfile } from '../../MIUC/hooks/useProfile';
import CustomAlert from '../../../components/CustomAlert';

const SaverUnemployed = () => {
	const classes = useStyles();
	const [hover, setHover] = useState(false);
	const { saldoSARTotal, errorMesage } = useProfile({ initProcess: true });

	const handleMouseEnter = () => {
		setHover(true);
	};

	const handleMouseLeave = () => {
		setHover(false);
	};

	const navigate = useNavigate();

	const itemsList = [
		{
			id: 1,
			text: 'Comprar una vivienda con Cuenta Infonavit + Crédito Bancario.',
		},
		// {
		// 	id: 2,
		// 	text: 'Mejorar tu hogar con MejOraSí.',
		// },
	];

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={12} lg={4}>
				<Paper className={classes.card}>
					<div className={classes.titleCard}>
						<h3>¡En el Infonavit cuidamos tu ahorro!</h3>
					</div>
					<div className={classes.textCard}>
						<p>
							Incluso si en este momento no cuentas con una
							relación laboral bajo el esquema de sueldos y
							salarios.
						</p>
						<p>
							Consulta el importe que tienes acumulado a la fecha,
							así como los intereses que genera de acuerdo con la
							normativa vigente.
						</p>
						<p>
							Incrementar tu ahorro haciendo Aportaciones
							extraordinarias.
						</p>
						<p>
							Y recuerda que aún sin relación laboral vigente
							puedes:
						</p>
						<div style={{ marginLeft: '0', marginRight: '0' }}>
							{itemsList.map((item) => (
								<Box key={item.id} className={classes.lineText}>
									<CheckCircleOutlined
										color="info"
										style={{
											marginRight: 5,
											width: 14,
											height: '90%',
										}}
									/>{' '}
									<span
										style={{
											marginBottom: 5,
											textAlign: 'left',
										}}
									>
										{item.text}
									</span>
								</Box>
							))}
						</div>
					</div>
				</Paper>
			</Grid>
			<Grid item sm={12} md={6} lg={4}>
				<Grid item lg={12}>
					<Paper className={classes.mainContainerCardMoney}>
						<label className={classes.titleCardMoney}>
							Tienes ahorrado en tu Subcuenta de Vivienda:
						</label>
						<div className={classes.containerCardMoney}>
							<img
								src={imgCoin}
								alt="icono pesos"
								className={classes.iconPesos}
							/>
							<label className={classes.labelSSV}>
								{formatMoney(Number(saldoSARTotal) || 0)} MXN
							</label>
						</div>
					</Paper>
				</Grid>
				<Grid item lg={12} mt={2}>
					<Paper className={classes.mainContainerCard}>
						<div className={classes.containerPaperList}>
							<label className={classes.titleCardCredit}>
								Tenemos opciones de crédito para ti, conócelas.
							</label>
							<p className={classes.textCardPaperList}>
								¿Qué quieres hacer con tu crédito Infonavit?{' '}
								<br />
								Conoce las opciones que tenemos para ti
							</p>

							<div
								className={classes.containerCardLine}
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}
							>
								<img
									src={terrenoIcon}
									alt="icon compra"
									className={classes.iconCompra}
								/>
								<label
									style={{
										marginLeft: '3%',
										fontSize: '10px',
										fontWeight: 'bold',
									}}
								>
									Comprar vivienda
								</label>

								<div>
									<ViewMoreButton
										link="/mi-tramite-credito/precalificacion-puntos"
										labelText="Iniciar"
										hover={hover}
									/>
								</div>
							</div>
							<CustomAlert
								message={errorMesage}
								show={Boolean(errorMesage)}
								severity="warning"
							/>
							<p className={classes.labelRestrictions}>
								<span>*</span>Aplican restricciones,{' '}
								<Link
									href="/mi-tramite-credito/precalificacion-puntos"
									color="primary"
								>
									conoce más.
								</Link>
							</p>
						</div>
					</Paper>
				</Grid>
				<Grid item lg={12} mt={2}>
					<Paper className={classes.mainContainerCard}>
						<div className={classes.containerPaperList}>
							<p className={classes.textCardPaperList}>
								{/* Lorem ipsum: */}
							</p>
							<div className={classes.containerCardLine}>
								<img
									src={imgSavingIcon}
									alt="icon-ahorro"
									style={{ width: '25%' }}
								/>
								<label
									className={classes.textCreditInfonaBanck}
								>
									Credito Infonavit + Bancario
								</label>
							</div>
							<br />
						</div>
					</Paper>
				</Grid>
			</Grid>
			<Grid item sm={12} md={6} lg={4}>
				<Grid container spacing={2}>
					<Grid item sm={12} md={12} lg={12}>
						<div
							onClick={() =>
								navigate('/contactanos/haz-una-cita')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgAgendaCita}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item sm={12} md={12} lg={12}>
						<div
							onClick={() =>
								navigate('/mi-ahorro/cuanto-ahorro-tengo')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgConsulta}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item sm={12} md={12} lg={12}>
						<div
							onClick={() =>
								navigate('/mi-tramite-credito/taller-saber-mas')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgSaberMas}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SaverUnemployed;
