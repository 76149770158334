import * as openpgp from 'openpgp';
import { keyAPI } from '../api/modules/key';

interface IData<I> {
	data: I;
}
export const encrypt = async (text: string, key: string): Promise<string> => {
	// const keyRes = await keyAPI.get<'', { k: string }>();
	// const key = keyRes['k'].toString();
	if (text === '' || text === null || text === undefined || text === 'NaN')
		return '';
	const publicKey = await openpgp.readKey({ armoredKey: key });
	const encrypted: any = await openpgp.encrypt({
		message: await openpgp.createMessage({ text: text.toString() || '' }),
		encryptionKeys: publicKey,
	});
	const text_base64: string = btoa(encrypted);
	return text_base64;
};

export const getDataEncripted = async <Type>({ data }: IData<Type>) => {
	const keyRes = await keyAPI.get<'', { k: string }>();
	const key = keyRes['k'].toString();
	Object.entries(data).forEach(async ([keyData, value]) => {
		const value_encripted = await encrypt(value, key);
		data = { ...data, [keyData]: value_encripted };
	});
	return data;
};

export const createObjectEncript = async (data: Object) => {
	let newObject: any = {};

	try {
		const keyRes = await keyAPI.get<'', { k: string }>();
		const key = keyRes['k'].toString();
		const claves = Object.keys(data);
		for (let i = 0; i < claves.length; i++) {
			const clave = claves[i];
			const value = data[clave as keyof typeof data];
			const value_encript = await encrypt(value.toString() || '', key);
			newObject[clave] = value_encript;
		}
	} catch (error) {
		return {};
	}

	return newObject;
};

export const createArrayWithObjectEncript = async (data: Array<Object>) => {
	let newArrayObject: any = [];

	try {
		const keyRes = await keyAPI.get<'', { k: string }>();
		const key = keyRes['k'].toString();
		for (let j = 0; j < data.length; j++) {
			let newObject: any = {};
			const objectSelect = data[j];
			const claves = Object.keys(objectSelect);
			for (let i = 0; i < claves.length; i++) {
				const clave = claves[i];
				const value = objectSelect[clave as keyof typeof objectSelect];
				const value_encript = await encrypt(
					value.toString() || '',
					key
				);
				newObject[clave] = value_encript;
			}
			newArrayObject.push(newObject);
		}
	} catch (error) {
		newArrayObject = [];
	}

	return newArrayObject;
};

export const createArrayEncript = async (data: Array<string | number>) => {
	let newObject: Array<string> = [];

	try {
		const keyRes = await keyAPI.get<'', { k: string }>();
		const key = keyRes['k'].toString();
		for (let i = 0; i < data.length; i++) {
			const value = data[i];
			const value_encript = await encrypt(value.toString() || '', key);
			newObject.push(value_encript);
		}
	} catch (error) {
		return [];
	}

	return newObject;
};

export const encodeAllJSONData = async (
	bodyEncrypted: any,
	keyValue: string
) => {
	// El body se regresa en la siguiente expresion
	let body: any = {};
	try {
		// const keyRes = await keyAPI.get<'', { k: string }>();
		// const keyValue = keyRes['k'].toString();
		// Si el body es un objeto
		if (typeof bodyEncrypted === 'object') {
			// iteramos por cada key del body
			for (const key in bodyEncrypted) {
				const valueEncrypted = bodyEncrypted[key];
				if (valueEncrypted === '') {
					body[key] = '';
				} else if (Array.isArray(valueEncrypted)) {
					const array = [];
					// Iteramos por cada uno de los elementos del arreglo
					for (const element of valueEncrypted) {
						// Valida si el elemento es vacio
						if (element === '') {
							array.push('');
						}
						// Valida si el elemento es un objeto
						else if (typeof element === 'string') {
							array.push(await encrypt(element, keyValue));
						}
						// Valida si el elemento es un objeto
						else if (typeof element === 'object') {
							array.push(
								await encodeAllJSONData(element, keyValue)
							);
						}
					}
					body[key] = array;
				}
				// Validamos si es un objeto
				else if (typeof valueEncrypted === 'object') {
					// Si es un objeto la función se vuelve recursiva
					body[key] = await encodeAllJSONData(
						valueEncrypted,
						keyValue
					);
				}
				// Proceso de encriptado del value del objeto
				else {
					// Proceso para encriptar
					body[key] = await encrypt(valueEncrypted, keyValue);
				}
			}
		}
	} catch (error) {
		throw new Error('Error al encriptar');
	}
	return body;
};
