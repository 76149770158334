import { Box } from "@mui/material";
import TooltipCustom from "../TooltipCustom";
import ErrorIcon from '@mui/icons-material/Error';
import { useStyles } from "./styles";

interface IProps {
  title: string;
  tooltip?: string | JSX.Element;
}

export const HeaderTooltip = ({ title, tooltip }: IProps) => {

  const classes = useStyles()

  return (
    <Box className={classes.container}>
        <p>{title}</p>
        { 
          typeof tooltip === 'string' ? (
            <TooltipCustom
              placement="right"
              title={tooltip}
            ><ErrorIcon className={classes.icon} />
            </TooltipCustom>
          )
          : tooltip && tooltip
        }
        
      </Box>
  )
}