// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// COMPONENTS
import ModalLoading from '../../../components/ModalLoading';
import CustomButton from '../../../components/Button';
// RESOURCES
import { returnOverpaymentsAPI } from '../../../api/modules/returnOverpayments';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { encrypt } from '../../../utils/encrypt';
import { keyAPI } from '../../../api/modules/key';
import { RootState } from '../../../store';
import { useStyles } from './styles';
import { IAlert } from '../../../interfaces/alert';
import CustomAlert from '../../../components/CustomAlert';

interface IDataTotal {
	saldo: string;
}

interface Props {
	setPage: (data: number) => void;
}

const LayoutNotice = ({ setPage }: Props) => {
	const navigate = useNavigate();
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [dataTotal, setDataTotal] = useState<IDataTotal>({
		saldo: '0',
	});
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [hasMark, setHasMark] = useState(false)

	const consultAvailibility = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const responseTotal = await returnOverpaymentsAPI.availabilityRetirement(
				nss_base64
			);
			if (responseTotal?.result?.codigo === '0') {
				setDataTotal(responseTotal.result);
			} else if (responseTotal?.result?.codigo === '97') {
				setHasMark(true);
			} else {
				setAlert({
					show: true,
					message: responseTotal?.result?.descripcion || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			}
			// setDataAnterior(responseTotal.result);
			// setLoading(false);
		} catch (error: any) {
			setAlert({
				show: true,
				message:
					error?.error ||
					INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		consultAvailibility();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ModalLoading loading={loading} />
			<div className={classes.titleNoticeContainer}>
				<div>
					<h2 className={classes.titleNotice}>
						Devolución de pagos en exceso{' '}
					</h2>
				</div>

				<div className={classes.subTitleNotice}></div>
			</div>
			<div className={classes.container}>
				<div className={classes.containerMin}>
					<div className={classes.contentDescription}>
						{!hasMark && (
							<div className={classes.descriptionContainer}>
								<div className={classes.descriptionContainerTitles}>
									<h4 className={classes.descriptionContainerTitle}>
										Este es el monto de tus pagos en exceso
									</h4>
								</div>
								<div>
									<div className={classes.amountContainer}>
										<p className={classes.amountText}>Monto</p>
										<h3 className={classes.amount}>
											$ {dataTotal.saldo}
											<p className={classes.currency}>MXN</p>
										</h3>
									</div>

									<p className={classes.contentDescriptionBottomText}>
										La devolución de tus pagos en exceso es viable. Tus
										recursos serán depositados en un lapso no mayor a 10
										días hábiles.<br></br> Para continuar deberás ingresar
										tu Clabe Bancaria Estandarizada (CLABE).
									</p>
									<CustomAlert
										message={alert.message}
										severity={alert.severity}
										show={alert.show}
										onClose={() => {
											setAlert({
												show: false,
												message: '',
												severity: alert.severity,
											});
										}}
									/>
									<div className={classes.contentFileDataButtons}>
										<CustomButton
											onClick={() => {
												setPage(1);
											}}
											variant="outlined"
											styles={{
												marginTop: 25,
												width: 200,
												marginInline: 10,
											}}
											label="Regresar"
										/>
										<CustomButton
											onClick={() => {
												setPage(3);
											}}
											variant="solid"
											disabled={Number(dataTotal.saldo) === 0.0}
											styles={{
												marginTop: 25,
												width: 200,
												marginInline: 10,
											}}
											label="Si"
										/>
									</div>
								</div>
							</div>
						)}
						{hasMark && (
							<div className={classes.descriptionContainer}>
								<div className={classes.descriptionContainerTitles}>
									<h3>
										Para continuar con tu trámite en línea, por seguridad,
										debes acudir al Centro de Servicio Infonavit más cercano
										y solicitar una desmarca de tu Devolución de pagos en
										exceso, no olvides que es indispensable presentar una
										identificación oficial vigente.
									</h3>
								</div>
								<div>
									<div className={classes.contentFileDataButtons}>
										<CustomButton
											onClick={() => {
												setPage(1);
											}}
											variant="outlined"
											styles={{
												width: 260,
												marginInline: 10,
											}}
											label="Regresar"
										/>
										<CustomButton
											onClick={() => navigate('/inicio')}
											variant="solid"
											styles={{
												width: 260,
												marginInline: 10,
											}}
											disabled={false}
											label="Salir"
										/>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default LayoutNotice;
