import { Paper } from '@mui/material';
import { useStyles } from './styles';
import { HeaderList } from '../../../components/HeaderList';
import PrivNotarioView from './privadoNotario';
import PrivRegistroView from './privadoRegistro';
import PublicRegistroView from './publicoRegistro';
import PublicNotarioView from './publicNotario';
import { useEffect, useState } from 'react';

interface Props {
	setPage: (data: number) => void;
	tipo: string;
	medio: string;
	dataHipoteca: {
		caso: string;
		fecha_liq: string;
		est_credito: string;
		casollave: string;
		tipificacion: string;
	};
	handlerConsultaCaso: () => void;
}

export interface IAddressData {
	cp: string;
	estado: string;
	municipio: string;
	colonia: string;
	calle: string;
	noint: string;
	noext: string;
}
export interface IInstrumentData {
	noInstrumento: string;
	date: string | Date | null;
	folio: string;
	libro: string;
	seccion: string;
}
export interface INotaryData {
	nombre: string;
	numero: string;
	estado: string;
	correo: string;
}

export type MortgageCancellationType = {
	value:
		| 'Private-Notary'
		| 'Private-Registry'
		| 'Public-Notary'
		| 'Public-Registry'
		| '';
};

export const FormOneView = ({
	setPage,
	tipo,
	medio,
	dataHipoteca,
	handlerConsultaCaso,
}: Props) => {
	const classes = useStyles();
	const [docType, setDocType] = useState<MortgageCancellationType>({
		value: '',
	} as MortgageCancellationType);

	useEffect(() => {
		setDocType({
			value: '',
		} as MortgageCancellationType);

		if (tipo === 'privado' && medio === 'notario') {
			setDocType({
				value: 'Private-Notary',
			} as MortgageCancellationType);
		}
		if (tipo === 'privado' && medio === 'registro') {
			setDocType({
				value: 'Private-Registry',
			} as MortgageCancellationType);
		}
		if (tipo === 'publica' && medio === 'notario') {
			setDocType({
				value: 'Public-Notary',
			} as MortgageCancellationType);
		}
		if (tipo === 'publica' && medio === 'registro') {
			setDocType({
				value: 'Public-Registry',
			} as MortgageCancellationType);
		}
	}, [tipo, medio]);

	return (
		<div>
			<HeaderList
				title="Carta de instrucción de cancelación de hipoteca"
				date="Información al 12 julio 2021"
				list={[
					'Esta carta debes entregarla al notario que elegiste para que lleve a cabo la liberación de tu hipoteca y quede registrada en el Registro Público de la Propiedad.',
					'La vigencia de la carta de cancelación es de 180 días naturales, si en ese tiempo no la entregas al notario (solo notario), deberás iniciar el trámite nuevamente.',
				]}
			/>
			<Paper className={classes.divPrincipal}>
				{tipo === 'privado' && medio === 'notario' && (
					<PrivNotarioView
						setPage={setPage}
						dataHipoteca={dataHipoteca}
						handlerConsultaCaso={handlerConsultaCaso}
						docType={docType}
					/>
				)}
				{tipo === 'privado' && medio === 'registro' && (
					<PrivRegistroView
						setPage={setPage}
						dataHipoteca={dataHipoteca}
						handlerConsultaCaso={handlerConsultaCaso}
						docType={docType}
					/>
				)}
				{tipo === 'publica' && medio === 'notario' && (
					<PublicNotarioView
						setPage={setPage}
						dataHipoteca={dataHipoteca}
						handlerConsultaCaso={handlerConsultaCaso}
						docType={docType}
					/>
				)}
				{tipo === 'publica' && medio === 'registro' && (
					<PublicRegistroView
						setPage={setPage}
						dataHipoteca={dataHipoteca}
						handlerConsultaCaso={handlerConsultaCaso}
						docType={docType}
					/>
				)}
			</Paper>
		</div>
	);
};

export default FormOneView;
