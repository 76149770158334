import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	containerCalculator: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		padding: 25,
	},
	containerGlobal: {
		padding: '40px',
	},
	containerInfo: {
		padding: '0 20px',
	},
	h3: {
		fontSize: '16px !important',
	},
	botonContainerRadio: {
		[theme.breakpoints.up('xs')]: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			marginLeft: 30,
			paddingRight: 40,
		},
		[theme.breakpoints.up('sm')]: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
		},
		[theme.breakpoints.up('md')]: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
		},
	},
	secondary: {
		[theme.breakpoints.down('md')]: {
			marginLeft: '-16px !important',
		},
	},
	containerSecond: {
		[theme.breakpoints.down('md')]: {
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
		},
	},
	titleAmount: {
		color: '#283990',
		margin: 0,
		fontSize: '16px !important',
	},
	amount: {
		fontSize: 28,
		fontWeight: 500,
		color: '#283990',
		margin: 0,
		display: 'inline-block',
		justifyContent: 'center',
		alignItems: 'center',
	},
	amountCustom: {
		color: '#7E8094',
		fontSize: '16px !important',
		marginLeft: 5,
		fontWeight: 'normal',
	},
	smallText: {
		color: '#7E8094',
		fontSize: '10px !important',
		fontWeight: 'normal',
	},
	containerAmountHeader: {
		width: '100%',
		border: '1px solid #283990',
		textAlign: 'center',
		padding: '10px 0',
	},
	containerAmountBody: {
		width: '100%',
		background: 'rgb(238, 244, 250)',
		padding: '20px 20px 0px',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		overflow: 'auto'
	},
	containerText: {
		marginBottom: '14px !important',
		fontSize: '14px !important',
	},
	containerResult: {
		color: '#283990',
		textAlign: 'right',
		fontWeight: 500,
	},
	icon: {
		position: 'absolute',
		fontSize: '14px !important',
		left: 0,
	},
}));
