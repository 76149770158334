import * as React from 'react';

// MUI
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


// ASSETS
import arrowRight from '../../../../assets/img/arrow-right.png';
import { useStyles } from './styles';

type IProps = {
	style?: React.CSSProperties;
	link?: string;
	target?: '_blank' | '_parent' | '_self' | '_top';
  text?: string;
};

const ViewMoreButton = ({ link, style, target, text }: IProps) => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Link href={link} underline="none" target={target} className={classes.button}>
        { 
          text
          ? (
            <Typography sx={{ fontSize: 12 }} className={classes.title}>
              {text}
            </Typography>
            ) 
          : ''
        }
				<ArrowForwardIosIcon fontSize='inherit' />
			</Link>
		</Box>
	);
};

export default ViewMoreButton;
