import { Paper, Box, Divider, Grid, InputAdornment } from '@mui/material';

import Header from '../../../components/Header';
import ProductsMiuc from '../../../components/ProductsMiuc';
import TextInput from '../../../../../components/TextInput';
import CustomButton from '../../../../../components/Button';
import SelectInput from '../../../../../components/SelectInput';
import { RoundBtn } from '../../../components/RoundBtn/roundBtn';
import ModalLoading from '../../../../../components/ModalLoading';
import CustomAlert from '../../../../../components/CustomAlert';

import { useGeneralStyles } from '../../styles/general.styles';
import { useStyles } from './styles/product.styles';
import { useHipotecaTable } from '../hooks/useHipotecaTable';
import { rowsTitleHipoteca } from '../../../utils/selectionResume.utils';
import { formatMoney } from '../../../../../utils/validators';
import { HeaderTooltip } from '../../../components/HeaderTooltip/headerTooltip';
import { formatTypesRow } from '../../../components/ProductsMiuc/interfaces/product.interface';
import HelpPanel from '../../../components/HelpPanel';
import { useAlertCustom } from '../../../hooks/useAlertCustom';

const Products = () => {
	const styles = useStyles();
	const generalStyle = useGeneralStyles();
	const {
		loading,
		next,
		returnStep,
		setSelectProduct,
		selectProduct,
		newStep,
		disableButton,
		alertInput,
		handleClearSearch,
		isSearch,
		valorOperacionState,
		setValorOperacionState,
		handleSetData,
		inputPlazosDisabled,
		alertHipoteca,
		handleGetAmortizationTable,
		rowsTitle,
		productsTable,
		disableSelectPlazo,
		onChangePlazo,
		handleButtonCalculate,
		disableCalculateBtn,
		plazo,
		plazos,
		setPlazo,
		plazoCny,
		plazosCny,
		setPlazoCny,
		conyuge,
	} = useHipotecaTable();

	const { getAlertStatus } = useAlertCustom();

	return (
		<Paper>
			<Header steps={newStep}></Header>
			<Box className={styles.container}>
				<Box textAlign="center">
					{getAlertStatus().status && (
						<CustomAlert
							show={getAlertStatus().status}
							severity={'warning'}
							message={getAlertStatus().message}
						/>
					)}
					{alertInput.message && (
						<CustomAlert
							show={alertInput.show}
							message={alertInput.message}
							severity={alertInput.severity}
						/>
					)}
				</Box>
				<Grid container columns={12} spacing={2}>
					<Grid item xs={12} md={12}>
						<p className={styles.title}>
							Ingresa el plazo que más te convenga <br />
							<span className={styles.subTitle}>
								Puede ser desde 1 y hasta 30 años (edad más
								plazo no debe exceder 70 años)
							</span>
						</p>
					</Grid>
					<Grid item xs={12} md={6}>
						<SelectInput
							id="hipoteca_plazo"
							name="hipoteca_plazo"
							value={plazo}
							disabled={inputPlazosDisabled || disableSelectPlazo}
							hiddenTextSelecciona={true}
							onChange={(event) => {
								setPlazo(event.target.value);
								onChangePlazo(event.target.value);
							}}
							options={plazos}
							label="Ingresa el plazo que más te convenga"
						/>
					</Grid>
					{conyuge && (
						<Grid item xs={12} md={6}>
							<SelectInput
								id="hipoteca_plazo"
								name="hipoteca_plazo"
								value={plazoCny}
								disabled={
									inputPlazosDisabled || disableSelectPlazo
								}
								hiddenTextSelecciona={true}
								onChange={(event) => {
									setPlazoCny(event.target.value);
									onChangePlazo(event.target.value, true);
								}}
								options={plazosCny}
								label="Ingresa el plazo asociado que más te convenga"
							/>
						</Grid>
					)}
				</Grid>

				<Divider sx={{ margin: '30px 0px' }} />

				<Grid container columns={12} spacing={2}>
					<Grid item xs={12} md={8}>
						<TextInput
							id="deuda"
							name="deuda"
							startAdornment={
								<InputAdornment position="start">
									$
								</InputAdornment>
							}
							placeholder="0.00"
							value={valorOperacionState}
							label="Mi deuda actual es de"
							onChange={handleSetData}
							onBlur={() => {
								if (valorOperacionState) {
									if (valorOperacionState === '.') {
										setValorOperacionState('');
									} else {
										setValorOperacionState(
											formatMoney(
												Number(valorOperacionState)
											).substring(1)
										);
									}
								}
							}}
							onFocus={() => {
								if (valorOperacionState) {
									setValorOperacionState(
										valorOperacionState
											.toString()
											.replaceAll(',', '')
											.replaceAll(' ', '')
									);
								}
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						className={styles.buttonContainer}
					>
						<CustomButton
							onClick={handleButtonCalculate}
							label="Calcular"
							variant="solid"
							disabled={isSearch || disableCalculateBtn}
						/>
						{isSearch && (
							<CustomButton
								onClick={handleClearSearch}
								label="Limpiar"
								variant="outlined"
							/>
						)}
					</Grid>
				</Grid>

				<CustomAlert
					severity="warning"
					show={isSearch}
					message={
						'Con los datos ingresados estas son las condiciones a las que puedes acceder.'
					}
				/>
				<Divider sx={{ marginTop: '20px' }} />

				{alertHipoteca.message && (
					<CustomAlert
						message={alertHipoteca.message}
						severity={alertHipoteca.severity}
						show={alertHipoteca.show}
					/>
				)}

				<ProductsMiuc
					keys={rowsTitle}
					products={productsTable}
					keyHeader={'producto'}
					selectProduct={selectProduct}
					setSelectProduct={setSelectProduct}
					textFooter="Considera que en cualquier momento de tu crédito podrás adelantar pagos o realizar la liquidación de tu crédito sin tener ninguna penalización."
					// getTablaComparativa={() => handleGetAmortizationTable()}
				/>
			</Box>
			<Divider />
			<div className={generalStyle.bottonsContainer}>
				<RoundBtn variant="outlined" onClick={() => returnStep()}>
					Regresar
				</RoundBtn>
				<RoundBtn
					disabled={disableButton}
					variant="contained"
					onClick={() => next()}
				>
					Continuar
				</RoundBtn>
			</div>
			<ModalLoading loading={loading} />
		</Paper>
	);
};

export default Products;
