import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('112', 'haz-aportaciones-extraordinarias');

export const ENDPOINTS_HAZ_APORTACIONES_EXTRAORDINARIAS: IBitacora = {
	'/aportaciones-ext-segmentos': _bitacora.Read(
		'112100',
		'Consultar segmentos ext.'
	),
	'/aportaciones-ext-consulta-maxmin': _bitacora.Read(
		'112101',
		'Consultar MaxMin'
	),
	'/aportaciones-validar-marca-separacion-unificacion': _bitacora.Read(
		'112102',
		'Validar separacion de marca'
	),
	'/aportaciones-ext-consulta-maxmin-codi': _bitacora.Read(
		'112103',
		'Consultar MaxMin codi'
	),
	'/aportaciones-ext-estados': _bitacora.Read('112104', 'Consultar estados'),
	'/aportaciones-ext-consulta-casos': _bitacora.Read(
		'112105',
		'Consultar casos'
	),
	'/aportaciones-ext-crear-caso': _bitacora.Create('112106', 'Crear caso'),
	'/aportaciones-ext-pago-codi': _bitacora.Read('112107', 'Pago codi'),
	'/aportaciones-ext-pago-cuenta': _bitacora.Read('112108', 'Pago Cuenta'),
};
