import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('126', 'constancia-intereses-declaracion-anual');

export const ENDPOINTS_CONSTANCIA_INTERES_PARA_TU_DECLARACION_ANUAL: IBitacora =
	{
		'/constancia-interes-declaracion-anual': _bitacora.Read(
			'126100',
			'Consultar anualidad'
		),

		'/constancia-interes-declaracion-anual-files': _bitacora.Update(
			'126101',
			'Carga de archivos'
		),
	};
