import { Box } from '@mui/material';
import { useStyles } from './style';

interface ICURPCorrectionItems {
	// [x: string]: any;
	items: ICURPCorrectionItem[];
	links?: ICURPCorrectionItem[];
}

interface ICURPCorrectionItem {
	key: string;
	value: string;
	bold: boolean;
	link?: boolean | undefined;
	Event?: () => void;
	alignItems?: 'left' | 'center' | 'right';
}

export const RowComponent = (props: ICURPCorrectionItems) => {
	const onjustifyContent = (value: string | undefined) => {
		if (window.innerWidth <= 851) {
			return 'start';
		}

		let newValue = '';
		if (value === 'left') {
			newValue = 'start';
		}
		if (value === 'center') {
			newValue = 'center';
		}
		if (value === 'right') {
			newValue = 'end';
		}

		return newValue;
	};

	const style = useStyles();
	return (
		<>
			<Box className={style.containerCenter}>
				<Box
					className={style.BoxCell3Container}
					sx={{
						width: (props.links || []).length === 0 ? '100%' : '85%',
					}}
				>
					{props.items.map((item) => (
						<Box
							key={item.key}
							className={style.BoxCell3}
							sx={{
								alignItems: onjustifyContent(item.alignItems),
							}}
						>
							<span
								onClick={item.link ? item.Event : () => {}}
								className={
									item.link
										? style.fuenteRed
										: item.bold
										? style.TextBlack_H4
										: style.Text_H3
								}
								style={{ textAlign: item.alignItems }}
							>
								{item.value}
							</span>
						</Box>
					))}
				</Box>
				{(props.links || []).map((item) => (
					<Box
						key={'link_' + item.value}
						className={style.BoxCell3Link}
						sx={{
							width: '15%',
							alignItems: onjustifyContent(item.alignItems),
						}}
					>
						<span
							onClick={item.link ? item.Event : () => {}}
							className={
								item.link
									? style.fuenteRed
									: item.bold
									? style.TextBlack_H4
									: style.Text_H3
							}
						>
							{item.value}
						</span>
					</Box>
				))}
			</Box>
			<hr className={style.hrColor} />
		</>
	);
};

export default RowComponent;
