import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
// Added new props to themes
declare module '@mui/material/styles' {
	interface TypographyVariants {
		smallText: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		smallText?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		smallText: true;
	}
}

const theme = createTheme(
	{
		palette: {
			primary: {
				main: '#DD0528',
				dark: '#CE0020',
			},
			secondary: {
				main: '#000000',
				light: '#293990',
			},
			success: {
				main: '#0abb87',
				dark: '#079531',
				light: '#acf7c2',
				contrastText: '#ffffff',
			},
			warning: {
				main: '#f1d190',
				dark: '#976807',
				light: '#FFF4DE',
				contrastText: '#FFA800',
			},
			error: {
				main: '#DD0528',
			},
			info: {
				main: '#293990',
				light: '#a7b2eb',
				dark: '#283990',
				contrastText: '#ffffff',
			},
			background: {
				default: '#f8f8f8',
			},
		},
		typography: {
			fontFamily: 'Geomanist, sans-serif',
			subtitle2: {
				fontWeight: 600,
			},
			smallText: {
				fontSize: '0.65rem',
			},
		},
		components: {
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						fontSize: 12,
						background: 'white',
						color: 'black',
						border: '#f8f8f8 1px solid',
						boxShadow:
							'1px 1px 5px 0px rgb(0 0 0 / 20%), 0px 0px 1px 1px rgb(0 0 0 / 14%), 0px 0px 2px 0px rgb(0 0 0 / 12%)',
						fontWeight: 400,
					},
					arrow: {
						color: 'white',
						'&:before': {
							border: '#c2c2c2 1px solid',
						},
					},
				},
			},
		},
	},
	esES
);

export default theme;
