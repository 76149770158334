import input from '../../../assets/svg/input.svg';
import ticket from '../../../assets/svg/ticket.svg';
import build from '../../../assets/svg/build.svg';

export const CreditoSeguroSteps = () => {

	return [
		{
			paperType: 'simple',
			paperIcon: input,
			title: 'Acude a Banco del Bienestar para iniciar tu ahorro.',
			description: 'Recuerda que puedes elegir el plazo con el que quieres realizar tu ahorro, desde 4 hasta 24 meses.',
		},
    {
			paperType: 'simple',
			paperIcon: ticket,
			title: 'Completa tu ahorro y solicita a Banco del Bienestar tu Carta de Certificación de saldo.',
		},
    {
			paperType: 'simple',
			paperIcon: build,
			title: 'Solicita un crédito en las oficinas del Infonavit',
			description: 'Registra tu soliciud de crédito en una de las oficinas del Infonavit. Regresa a la página principal de Mi Cuenta Infonavit, en el menú del lado superior, puedes hacer tu cita para acudir al centro de servicio Infonavit más cercano.',
		},
	];
};
