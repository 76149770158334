import { useEffect, useState } from "react";
import { MIUC_PROCESS_VIEWS } from "../../constants/process";
import { useController } from "../../hooks/useController";
import { MIUC_PRODUCTS } from "../../constants/products";
import { msgErrorMevi } from "../../../CreditRequest/MIUC/utils";

export const useMejoraHogar = () => {
  const [view, setView] = useState<number>();
  const newLocal = useController();
  const { saveData, getStepView, nextStep, addSelection, dataProcessController } = newLocal;
  const [errorMessage, setErrorMessage] = useState<JSX.Element | string>('');

  useEffect(() => {
    navigation();
  }, [dataProcessController]);

  const navigation = () => {
    const newView = getStepView();
    if (
      newView === MIUC_PROCESS_VIEWS.SELECTION_CREDIT ||
      newView === MIUC_PROCESS_VIEWS.MEJORAVIT ||
      newView === MIUC_PROCESS_VIEWS.AUTOPRODUCCION
    ) {
      setView(getStepView());
    } else if (
      dataProcessController.processInfo?.credit ===
      MIUC_PRODUCTS.MEJORAVIT
    ) {
      setView(MIUC_PROCESS_VIEWS.MEJORAVIT);
    } else if (
      dataProcessController.processInfo?.credit ===
      MIUC_PRODUCTS.AUTOPRODUCCION
    ) {
      setView(MIUC_PROCESS_VIEWS.AUTOPRODUCCION);
    }
  };

  const onSelect = (option: string) => {
    let credit = '';
    let view: number;

    if (option === MIUC_PRODUCTS.MEJORAVIT) {
      credit = MIUC_PRODUCTS.MEJORAVIT;
      view = MIUC_PROCESS_VIEWS.MEJORAVIT;
      addSelection({
        title: 'Crédito seleccionado para:',
        description: 'Mejora tu hogar',
      });
      saveData({
        processData: {
          active: 1,
          total: 3,
        }
      });
    } else {
      credit = MIUC_PRODUCTS.AUTOPRODUCCION;
      view = MIUC_PROCESS_VIEWS.AUTOPRODUCCION;

      if ([4, 5, 7].includes(Number(dataProcessController.titular.stageId))) {
        return setErrorMessage(msgErrorMevi(Number(dataProcessController.titular.stageId)));
      } else {
        addSelection({
          title: 'Crédito seleccionado para:',
          description: 'ConstruYO',
        });
        saveData({
          processData: {
            active: 1,
            total: 4,
          }
        });
      }
    }
    saveData({
      processInfo: {
        credit: credit,
      },
    });
    nextStep();
    setView(view);
  };

  const extraMessage = 'Recuerda que esto es una simulación y las condiciones finales se darán al formalizar tu crédito';

  return {
    view,
    onSelect,
    extraMessage,
    errorMessage,
  }
}
