import { PROFILE_ROUTES } from '../config/profile';

export const firstLetterStringToCapital = (text: string): string => {
	return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};

export const cleanStringRoute = (route: string): string => {
	const withoutAccents = (PROFILE_ROUTES[route] || '')
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');
	return withoutAccents.replace(/[^a-zA-Z\s]/g, "");
};
