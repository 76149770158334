import Modal from '../../components/Modal';
import CustomButton from '../../components/Button';
import { Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

interface Props {
	showModal: boolean;
}

const ModalSuspendido = ({ showModal }: Props) => {
	const navigate = useNavigate();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	return (
		<Modal
			open={showModal}
			width={'sm'}
			hideCloseButton
			children={
				<div style={{ textAlign: 'center', fontSize: 15 }}>
					<h2
						style={{
							color: 'rgb(41, 57, 144)',
							fontSize: 30,
							margin: 0,
						}}
					>
						Aviso
					</h2>

					<p style={{ color: 'rgb(41, 57, 144)', fontWeight: 500 }}>
						Estamos mejorando este servicio, por lo que se encuentra
						temporalmente suspendido.
					</p>

					<p style={{ fontWeight: 500, margin: 0, marginBottom: 40 }}>
						Te ofrecemos una disculpa por los inconvenientes que
						pueda ocasionarte.
						<br /> Te invitamos a ingresar nuevamente a partir del
						27 de noviembre de 2023.
					</p>

					<Divider style={{ margin: '20px 0px' }} />

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: matches
								? 'column-reverse'
								: 'inherit',
						}}
					>
						<div
							style={{
								width: matches ? '100%' : 200,
								display: matches ? 'flex' : 'inline-flex',
								margin: '8px 10px',
							}}
						>
							<CustomButton
								label="Aceptar"
								variant="solid"
								onClick={() => navigate('/')}
							/>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default ModalSuspendido;
