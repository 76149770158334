/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Input } from '@mui/material';
import { phrases } from './utills';
import { Replay } from '@mui/icons-material';
import { centeredItems, useStyles } from './styles';

const randomPhrase = () => phrases[Math.floor(Math.random() * phrases.length)];

const Captcha = ({ value, onChange, getNew }: any) => {
	const classes = useStyles();
	let [random1, setRandom1] = useState(randomPhrase());
	let [random2, setRandom2] = useState(randomPhrase());

	useEffect(() => {
		reloadCaptcha();
	}, [getNew]);

	const reloadCaptcha = () => {
		setRandom1(randomPhrase());
		setRandom2(randomPhrase());
		onChange('', `${random1} ${random2}`);
	};

	return (
		<div className={classes.container}>
			<div className="phrase-container">
				<svg viewBox="0 0 200 50">
					<path fill="transparent" id="curve" d="M0,4 C77,50 117,30 197,10" />
					<text x="20">
						<textPath xlinkHref="#curve" className={classes.textLine}>
							{random1} {random2}
						</textPath>
					</text>
				</svg>
			</div>
			<div className="input-container">
				<Input
					disableUnderline
					size="small"
					placeholder="Introduce el código"
					name="captcha"
					id="captcha"
					value={value}
					onChange={(e: any) =>
						onChange(e.target.value, `${random1} ${random2}`)
					}
				/>
				<div
					className="reload-button"
					style={centeredItems}
					onClick={reloadCaptcha}
				>
					<Replay />
				</div>
			</div>
		</div>
	);
};

export default Captcha;
