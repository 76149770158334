import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		position: 'absolute',
		right: -7,
		top: 'calc((100% - 16px)/2)',
	},
	button: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.dark,
		borderRadius: 60,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
		overflow: 'hidden',
		width: 'auto',
		height: 18,
		maxWidth: 16,
		boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
		transition: 'max-width 0.3s',
		'&:hover': {
			maxWidth: 240,
		},
		fontSize: '16px',
	},
	title: {
		whiteSpace: 'nowrap',
		color: theme.palette.primary.main,
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
}));