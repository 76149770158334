import { HeaderList } from '../../../../../components/HeaderList';

const AuthorizedVouchers = () => {
	return (
		<>
			<HeaderList
				listSizeIcon={18}
				list={[
					'Recibo de luz',
					'Agua',
                    'Predial',
                    'Teléfono fijo',
                    'Gas natural',
                    'TV por cable',
                    'Recibo de internet',
                    'Recivo de telefonía móvil / Celular',
				]}
			/>
		</>
	);
};

export default AuthorizedVouchers;
