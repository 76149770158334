import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('122', 'taller-saber-mas');

export const ENDPOINTS_TALLER_MAS_PARA_DECIDIR_MEJOR: IBitacora = {
	'/v1/miuc-adicionales/taller-saber-mas/obtener-lista-elementos':
		_bitacora.Read('122100', 'Consultar elementos'),
	'/v1/miuc-adicionales/taller-saber-mas/actualizar-elemento':
		_bitacora.Update('122101', 'Actualizar elementos'),
	'/v1/miuc-adicionales/taller-saber-mas/obtener-certificado-pdf':
		_bitacora.Read('122102', 'Descargar certificado'),
};
