import { useState } from 'react';
import { RootState } from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { apiMIUC } from '../../../../../../api/modules/MIUC';
import { encodeAllJSONData } from '../../../../../../utils/encrypt';
import { actions } from '../../../../../../store/modules/loading';
import { CODES_RESPONSE } from '../../../../../../config/miucConstants';

export const useCreditReqCan = () => {
	const [openModal, setOpenModal] = useState(false);
	const { user } = useSelector((state: RootState) => state.session);
	const dispatch = useDispatch();

	const getUrlMiespacio = async () => {
		try {
			dispatch(actions.startLoading());
			const data = {
				nss: user?.nss,
				tipo: 'MEI',
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const response = await apiMIUC.getUrlEncripted(dataEncripted);
			if (response.code === CODES_RESPONSE.CORRECTO) {
				window.open(response.data.path, '_blank');
			}
		} catch (error) {
			dispatch(actions.stopLoading());
		} finally {
			dispatch(actions.stopLoading());
		}
	};
	return {
		getUrlMiespacio,
		openModal,
		setOpenModal,
	};
};
