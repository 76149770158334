import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	titleNoticeContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	titleNotice: {
		marginTop: 0,
		fontSize: 22,
		color: '#293990',
	},
	descriptionContainerTitle: {
		fontSize: 14,
		marginRight: 5,
	},
	subTitleNotice: {
		fontSize: 12,
		color: '#333333',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			textAlign: 'right',
			marginTop: 15,
		},
	},
	checkHeader: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 7,
	},
	checkHeaderImg: {
		width: 17,
		marginRight: 5,
	},
	checkHeaderText: {
		color: '#333',
		fontSize: 13,
		padding: 0,
		margin: 0,
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		paddingBlock: 50,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
	},
	container2: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: '20px 170px',
		paddingBlock: 50,
		textAlign: 'center',
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
		[theme.breakpoints.down('sm')]: {
			padding: '10px',
		},
	},
	contentDescription: {
		textAlign: 'center',
		margin: '0 auto',
		maxWidth: 600,
	},
	contentDescriptionTopTitle: {
		fontSize: 16,
	},
	contentDescriptionTopText: {
		fontSize: 14,
	},
	contentDescriptionBottomText: {
		fontSize: 14,
	},
	contentDescriptionBottomLink: {
		color: '#D1001F',
		marginLeft: 5,
	},
	stepLink: {
		color: '#D1001F',
		marginLeft: 5,
		paddingTop: 30,
		fontSize: 14,
	},
	containerMin: {
		maxWidth: 900,
		margin: '0 auto',
	},
	principalTitle: {
		color: '#293990',
		fontSize: 16,
	},
	contentFile: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	contentFileData: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
		[theme.breakpoints.down('md')]: {},
	},
	contentFileDataTitle: {
		fontSize: 16,
		fontWeight: 'bold',
		minWidth: 250,
		[theme.breakpoints.down('sm')]: {
			fontSize: 15,
			minWidth: 200,
		},
		[theme.breakpoints.down('md')]: {},
	},
	contentFileDataText: {
		fontSize: 15,
		color: '#7F7F7F',
		[theme.breakpoints.down('sm')]: {
			fontSize: 13,
		},
		[theme.breakpoints.down('md')]: {},
	},
	contentFileDataLink: {
		color: '#D1001F',
		fontSize: 14,
	},
	contentFileDataInput: {
		width: 300,
		border: '1px solid #CFD6D9',
		borderRadius: 4,
		padding: 10,
		marginLeft: 250,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginLeft: 0,
		},
	},
	contentFileDataButtons: {
		display: 'flex',
		justifyContent: 'center',
		marginBlock: 30,
		marginBottom: 80,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
		},
		[theme.breakpoints.down('md')]: {},

		'&>button': {
			[theme.breakpoints.down('sm')]: {
				margin: '20px 0px !important',
				width: '100% !important',
			},
		},
	},
}));
