import { Box, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CustomButtom from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';

interface Props {
	msgString: string | JSX.Element;
	fullPage?: boolean;
	btnErrDisabled?: boolean;
}

const ErrnoPage = ({ msgString, fullPage, btnErrDisabled }: Props) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const navigate = useNavigate();

	const refreshPage = () => {
		window.location.reload();
	};

	return (
		<>
			{fullPage ? (
				<>
					<Layout>
						<div>
							<HeaderList title="Me interesa un crédito" />
							<Paper>
								<Box textAlign={'center'} padding={3}>
									<h3>{msgString}</h3>
									<Box
										display={'flex'}
										justifyContent={'center'}
										flexDirection={
											matches ? 'column-reverse' : 'row'
										}
									>
										<Box
											margin={'20px 10px'}
											width={matches ? '100%' : 250}
										>
											<CustomButtom
												label={'Salir'}
												onClick={() => navigate('/')}
												variant="outlined"
											/>
										</Box>
										<Box
											margin={'20px 10px'}
											width={matches ? '100%' : 250}
										>
											<CustomButtom
												label={'Volver a intentar'}
												onClick={() => refreshPage()}
												variant="solid"
											/>
										</Box>
									</Box>
								</Box>
							</Paper>
						</div>
					</Layout>
				</>
			) : (
				<>
					<Box textAlign={'center'} padding={3}>
						<h3>{msgString}</h3>
						<Box
							display={'flex'}
							justifyContent={'center'}
							flexDirection={matches ? 'column-reverse' : 'row'}
						>
							<Box
								margin={'20px 10px'}
								width={matches ? '100%' : 250}
							>
								<CustomButtom
									label={'Salir'}
									onClick={() => navigate('/')}
									variant="outlined"
								/>
							</Box>
							{!btnErrDisabled && (
								<Box
									margin={'20px 10px'}
									width={matches ? '100%' : 250}
								>
									<CustomButtom
										label={'Volver a intentar'}
										onClick={() => refreshPage()}
										variant="solid"
									/>
								</Box>
							)}
						</Box>
					</Box>
				</>
			)}
		</>
	);
};

export default ErrnoPage;
