import { ChangeEvent, useState } from 'react';
import { Paper, Box, Radio, Divider } from '@mui/material';
import Header from '../../../components/Header';
import ButtonGroup from '../../../components/ButtonGroup';
import { HeaderList } from '../../../../../components/HeaderList';
import { useNavigation } from '../../../hooks/useNavigation';
import { MIUC_PRODUCTS } from '../../../constants/products';

const CreditConditions = () => {
	const {
		nextStep,
		returnStep,
		saveData,
		addSelection,
		getProgressStep,
		dataProcessController,
	} = useNavigation();
	const [selectedValue, setSelectedValue] = useState('');

	const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const { value } = target;
		setSelectedValue(value);
	};

	const selectOptions = [
		{
			label: 'Sí',
			value: 'Sí',
		},
		{
			label: 'No',
			value: 'No',
		},
	];

	const next = () => {
		saveData({
			processInfo: {
				landOwner: selectedValue,
			},
			processData: {
				active: getProgressStep().active + 1,
			},
		});
		addSelection({
			title: 'Propietario de una vivienda:',
			description: selectedValue,
		});
		nextStep();
	};

	const { active, total } = getProgressStep();

	return (
		<Paper>
			<Header
				steps={{
					title: 'Condiciones del credito',
					subtitle:
						dataProcessController.processInfo?.credit ===
						MIUC_PRODUCTS.CREDITERRENO
							? 'Selecciona la entidad en la que harás la compra de terreno y completa la información'
							: 'Para determinar las condiciones financieras de tu crédito, necesitamos la siguiente información:',
					active,
					total,
				}}
			/>

			<Box
				maxWidth={970}
				width={'100%'}
				margin={'20px auto auto auto'}
				padding={'0px 30px'}
			>
				{dataProcessController.processInfo?.credit ===
					MIUC_PRODUCTS.CREDITERRENO && (
					<p style={{ margin: '20px 0px' }}>
						Para determinar las condiciones financieras de tu
						crédito, necesitamos la siguiente información:
					</p>
				)}

				<h4 style={{ margin: '10px 0px 0px 0px' }}>
					Indica si ya eres propietario de una vivienda
				</h4>

				<div
					style={{
						display: 'flex',
						maxWidth: 250,
						width: '100%',
						justifyContent: 'space-between',
						marginBottom: 20,
					}}
				>
					{selectOptions.map((option) => (
						<div key={option.label}>
							<Radio
								checked={selectedValue === option.value}
								onChange={handleChange}
								value={option.value}
								name="radio-buttons"
								inputProps={{ 'aria-label': option.value }}
							/>
							{option.label}
						</div>
					))}
				</div>

				<HeaderList
					list={[
						'Recuerda que las condiciones finales de tu crédito dependerán del enganche que aportes y si cuentas o no con una vivienda propia.',
						'Al momento de solicitar tu crédito, se te preguntará lo mismo y deberás firmar una carta bajo promesa de decir la verdad en la que se quedarán plasmadas tus respuestas.',
					]}
				/>
			</Box>

			<Divider />

			<ButtonGroup
				buttons={[
					{
						label: 'Regresar',
						execute: () => returnStep(),
						variant: 'outlined',
					},
					{
						label: 'Continuar',
						execute: () => next(),
						disabled: selectedValue !== '' ? false : true,
					},
				]}
			/>
		</Paper>
	);
};

export default CreditConditions;
