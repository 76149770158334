import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';
import MyLastContributionTable from './myLastContributionsTable';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import MySavingTable from './mySavingTable';
import { apiMySaving } from '../../api/modules/mySaving';
import { formatMoney } from '../../utils/validators';
import { IAlert } from '../../interfaces/alert';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { RootState } from '../../store';
import { useStyles } from './styles';

import logoPensiones from '../../assets/svg/logo-pensiones-bienestar.svg';

interface IDataTotal {
	apellMAt: string;
	apellPat: string;
	chdate: string;
	nombre: string;
	rfc: string;
	saldoSar92: string;
	saldoSar97: string;
	saldoFPB: string;
}
interface IDataAnterior {
	nombre: string;
	nss: string;
	rfc: string;
	saldo: string;
}

const MySaving = () => {
	const classes = useStyles();
	const [openModal, setOpenModal] = React.useState(false);
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [dataAnterior, setDataAnterior] = useState<IDataAnterior>({
		nombre: '',
		nss: '',
		rfc: '',
		saldo: '',
	});
	const [dataTotal, setDataTotal] = useState<IDataTotal>({
		apellMAt: '',
		apellPat: '',
		chdate: '',
		nombre: '',
		rfc: '',
		saldoSar92: '',
		saldoSar97: '',
		saldoFPB: ''
	});

	useEffect(() => {
		const handleOnSubmit = async () => {
			setLoading(true);
			try {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const nss_base64 = await encrypt(user?.nss.toString() || '', key);
				const responseTotal = await apiMySaving.getSavingBienestar(nss_base64);
				const responseAnterior = await apiMySaving.getSavingAnterior(nss_base64);
				if (responseTotal?.result?.data) {
					setDataTotal(responseTotal?.result?.data);
				}
				if (responseAnterior?.result) {
					setDataAnterior(responseAnterior.result);
				}
			} catch (error: any) {
				setAlert({
					show: true,
					message:
						error && error.description
							? error.description
							: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			} finally {
				setLoading(false);
			}
		};
		handleOnSubmit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ModalLoading loading={loading} />
			<Paper>
				<div>
					<div className={classes.labelheader}>
						De acuerdo a nuestros registros cuentas con los siguientes
						ahorros:
					</div>
					<br />
					<div id="web" className={classes.divContainerWeb}>
						<div className={classes.div}>
							<h3 className={classes.h3}>Tipo de ahorro</h3>
							<hr className={classes.hr} />
							<p className={classes.p}>Subcuenta de Vivienda 1997</p>
							<hr className={classes.hr} />
							<p className={classes.p}>Fondo de ahorro 1972</p>
							<hr className={classes.hr} />
							<p className={classes.p}>Subcuenta de Vivienda SAR 1992</p>
							<hr className={classes.hr} />
							<div className={classes.pensiones}>
								<p className={classes.p}>Fondo de Pensiones para el Bienestar*</p>
								<img
									src={logoPensiones}
									className={classes.imgLogoPensiones}
									alt="logo-pensiones-bienestar"
								/>
							</div>

							<hr className={classes.hr} />
						</div>
						<div className={classes.div}>
							<h3 className={classes.h3}>Periodo</h3>
							<hr className={classes.hr} />
							<p className={classes.p} style={{ color: '#293990' }}>
								Del 1/jul/1997 a la actualidad
							</p>
							<hr className={classes.hr} />
							<p className={classes.p} style={{ color: '#293990' }}>
								Del 1972 al 28/feb/1992
							</p>
							<hr className={classes.hr} />
							<p className={classes.p} style={{ color: '#293990' }}>
								Del 1/mar/1992 al 30/jun/1997
							</p>
							<hr className={classes.hr} />
							<p className={classes.pPensiones} style={{ color: '#293990' }}></p>
							<hr className={classes.hr} />
						</div>
						<div style={{ width: '20%' }}>
							<div style={{ textAlign: 'left' }}>
								<h3 className={classes.h3}>Monto</h3>
								<hr className={classes.hr} />
								<p className={classes.p}>
									{Number(dataTotal.saldoSar97) > 0 ? (
										<strong>
											{formatMoney(
												Number(dataTotal.saldoSar97) || 0
											)}
										</strong>
									) : (
										formatMoney(Number(dataTotal.saldoSar97) || 0)
									)}
								</p>
								<hr className={classes.hr} />
								<p className={classes.p}>
									{Number(dataAnterior.saldo) > 0 ? (
										<strong>
											{formatMoney(Number(dataAnterior.saldo) || 0)}
										</strong>
									) : (
										formatMoney(Number(dataAnterior.saldo) || 0)
									)}
								</p>
								<hr className={classes.hr} />
								<p className={classes.p}>
									{Number(dataTotal.saldoSar92) > 0 ? (
										<strong>
											{formatMoney(
												Number(dataTotal.saldoSar92) || 0
											)}
										</strong>
									) : (
										formatMoney(Number(dataTotal.saldoSar92) || 0)
									)}
								</p>
								<hr className={classes.hr} />
								<p className={classes.pPensiones}>
									{Number(dataTotal.saldoFPB) > 0 ? (
										<strong>
											{formatMoney(
												Number(dataTotal.saldoFPB) || 0
											)}
										</strong>
									) : (
										formatMoney(Number(dataTotal.saldoFPB) || 0)
									)}
								</p>
								<hr className={classes.hr} />
							</div>
						</div>
					</div>
					<div id="movil" className={classes.divContainerMovil}>
						<div className={classes.divContainer}>
							<div className={classes.div}>
								<h3 className={classes.h3}>Tipo de ahorro</h3>
								<p className={classes.p}>Subcuenta de vivienda 1997</p>

								<h3 className={classes.h3}>Periodo</h3>
								<p className={classes.p}>
									Del 1/jul/1997 a la actualidad
								</p>

								<h3 className={classes.h3}>Monto</h3>
								<p className={classes.p}>
									{Number(dataTotal.saldoSar97) > 0 ? (
										<strong>
											{formatMoney(
												Number(dataTotal.saldoSar97) || 0
											)}
										</strong>
									) : (
										formatMoney(Number(dataTotal.saldoSar97) || 0)
									)}
								</p>
								<br />
								<hr className={classes.hr} />
							</div>
						</div>
						<div className={classes.divContainer}>
							<div className={classes.div}>
								<h3 className={classes.h3}>Tipo de ahorro</h3>
								<p className={classes.p}>Fondo de ahorro 1972</p>

								<h3 className={classes.h3}>Periodo</h3>
								<p className={classes.p}>
									Del 1972 al 28/feb/1992
								</p>

								<h3 className={classes.h3}>Monto</h3>
								<p className={classes.p}>
									{Number(dataAnterior.saldo) > 0 ? (
										<strong>
											{formatMoney(Number(dataAnterior.saldo) || 0)}
										</strong>
									) : (
										formatMoney(Number(dataAnterior.saldo) || 0)
									)}
								</p>
								<br />
								<hr className={classes.hr} />
							</div>
						</div>
						<div className={classes.divContainer}>
							<div className={classes.div}>
								<h3 className={classes.h3}>Tipo de ahorro</h3>
								<p className={classes.p}>
									Subcuenta de Vivienda SAR 1992
								</p>

								<h3 className={classes.h3}>Periodo</h3>
								<p className={classes.p}>
									Del 1/mar/1992 al 30/jun/1997
								</p>

								<h3 className={classes.h3}>Monto</h3>
								<p className={classes.p}>
									{Number(dataTotal.saldoSar92) > 0 ? (
										<strong>
											{formatMoney(
												Number(dataTotal.saldoSar92) || 0
											)}
										</strong>
									) : (
										formatMoney(Number(dataTotal.saldoSar92) || 0)
									)}
								</p>
								<br />
								<hr className={classes.hr} />
							</div>
						</div>
						<div className={classes.divContainer}>
							<div className={classes.div}>
								<h3 className={classes.h3}>Tipo de ahorro</h3>
								<p className={classes.p}>
									Fondo de Pensiones para el Bienestar*
								</p>
								<img
									src={logoPensiones}
									className={classes.imgLogoPensionesMovil}
									alt="logo-pensiones-bienestar"
								/>

								<h3 className={classes.h3}>Periodo</h3>
								<p className={classes.p}>

								</p>

								<h3 className={classes.h3}>Monto</h3>
								<p className={classes.p}>
									{Number(dataTotal.saldoFPB) > 0 ? (
										<strong>
											{formatMoney(
												Number(dataTotal.saldoFPB) || 0
											)}
										</strong>
									) : (
										formatMoney(Number(dataTotal.saldoFPB) || 0)
									)}
								</p>
								<br />
								<hr className={classes.hr} />
							</div>
						</div>
					</div>
					<div className={classes.labelfooter}>
						{' '}
						Los rendimientos que genera tu ahorro en la Subcuenta de Vivienda
						se abonan al final de cada mes. El consejo de Administración del
						Infonavit establece la tasa de interés y es superior al incremento
						del salario mínimo que se paga en la CDMX.
						<br />
						<p className={classes.savingLink}>
							Tu ahorro genera rendimientos{' '}
							<label
								onClick={() => setOpenModal(!openModal)}
								className={classes.link}
							>
								consulta aquí.
							</label>
						</p>
						Los recursos que se transfieren al Fondo de Pensiones para el Bienestar
						generarán rendimientos que serán determinados por el Comité Técnico
						establecido en el Decreto del Fondo de Pensiones para el Bienestar,
						publicado en fecha 1° de mayo de 2024 en el Diario Oficial de la Federación.
						<br />
						<br />
						Responsable de la información: <b>Gerencia de Administración del Patrimonio Social y Servicios</b>
						<br />
						<div style={{ padding: '0 40px' }}>
							<CustomAlert
								show={alert.show}
								message={alert.message}
								severity={alert.severity}
							/>
						</div>
						<div style={{ color: '#293990' }}>
							<br />
							<hr className={classes.hr} />
							<br />
							*En este fondo se encuentran los recursos de tu Subcuenta de
							Vivienda 92 y 97 que son transferidos al cumplir 70 años y no
							haber tenido actividad laboral durante un año calendario
							contado a partir del último depósito realizado, para saber más
							ingresa
							<a href='https://infonavitfacil.mx/pensiones' className={classes.link} target="blank">
								aquí.
							</a>
						</div>
					</div>
				</div>
				<div style={{ paddingLeft: 20, paddingRight: 20 }}>
					{openModal && <MyLastContributionTable />}
				</div>
				<div style={{ paddingLeft: 20, paddingRight: 20 }}>
					{openModal && <MySavingTable />}
				</div>
			</Paper>
		</>
	);
};

export default MySaving;
