/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Paper, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import IconDes from '../../assets/img/icono-descarga.png';
import ModalLoading from '../../components/ModalLoading';
import { RoundBtn } from './styles';
import BaseModal from '../../components/ModalConfirmation';
import { IResponseCitasActuales } from '../../interfaces/makeDate24';
import { getFormatDateShort, getFormatHour } from '../../utils/dates';
import { IAlert } from '../../interfaces/alert';
import CustomAlert from '../../components/CustomAlert';
import TextInput from '../../components/TextInput';

interface Props {
	responseCitasActuales: IResponseCitasActuales[];
	motivo: any;
	setMotivo: (motivo: any) => void;
	handleCancelar: (pAppoimentId: string) => void;
	downloadPDF: (value: IResponseCitasActuales, bash: any) => void;
	alertCancelar?:
	| IAlert
	| {
		show: false;
		message: '';
		severity: 'success';
	};
	errorResponseCitas?: boolean;
	setDataReprogramar: (data: any) => void;
}

const Tab2Appointment = ({
	responseCitasActuales,
	motivo,
	setMotivo,
	handleCancelar,
	downloadPDF,
	alertCancelar,
	errorResponseCitas,
	setDataReprogramar
}: Props) => {
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [paramsToCancel, setParamsToCancel] = useState({ pAppointmentId: '' });
	const [expanded, setExpanded] = useState('');
	const [loading, setLoading] = useState(false);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	const [modalCancelar, setModalCancelar] = useState(false);

	const handlChange = (tabs: string) => {
		setExpanded(expanded !== tabs ? tabs : '');
	};

	useEffect(() => {
		if (errorResponseCitas) {
			setAlert({
				show: true,
				message:
					'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
				severity: 'error',
			});
		}
	}, [alertCancelar, errorResponseCitas]);

	useEffect(() => {
		if (loading) {
			return;
		}
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 5000);
	}, [responseCitasActuales]);

	return (
		<Paper style={{ padding: matches ? 0 : '35px 30px' }}>
			<Box>
				<Grid
					container
					columns={15}
					style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 30 }}
				>
					<Grid item xs={12} md={3}>
						<span style={{ fontWeight: 500, fontSize: matches ? 15 : 16 }}>
							Id cita
						</span>
					</Grid>

					{!matches && (
						<>
							<Grid item xs={12} md={3}>
								<span style={{ fontWeight: 500 }}>
									Fecha de solicitud
								</span>
							</Grid>
							{/* <Grid item xs={12} md={3}>
								<span style={{ fontWeight: 500 }}>Estatus</span>
							</Grid>
							<Grid item xs={12} md={3}>
								<span style={{ fontWeight: 500 }}>Fecha de estatus</span>
							</Grid>
							<Grid item xs={12} md={3}>
								<span style={{ fontWeight: 500 }}>
									Comprobante de cita
								</span>
							</Grid> */}
						</>
					)}
				</Grid>
				{responseCitasActuales.map((dato: IResponseCitasActuales) => (
					<Accordion
						key={dato?.id || ''}
						expanded={expanded === dato?.id}
						onChange={() => handlChange(dato?.id || '')}
					>
						<AccordionSummary
							aria-controls="panel1d-content"
							id="panel1d-header"
						>
							<Grid
								container
								columns={15}
								style={{ fontSize: matches ? 15 : 16 }}
							>
								<Grid item xs={6} md={3}>
									{dato?.id || ''}
								</Grid>
								{!matches && (
									<>
										<Grid item xs={6} md={3}>
											{getFormatDateShort(dato.creationDate)}
										</Grid>
										{/* <Grid item xs={6} md={3}>
											<span
												style={{
													background:
														dato?.StateId === '2'
															? '#CBFFE0'
															: '#FFF4DE',
													width: 10,
													height: 10,
													borderRadius: '50%',
													display: 'inline-block',
													marginRight: 5,
												}}
											/>
											<span
												style={{
													fontWeight: 500,
													color:
														dato.StateId === '2'
															? '#00C951'
															: '#FFA800',
												}}
											>
												{dato?.caso?.textoStatus || 'N/A'}
											</span>
										</Grid>
										<Grid item xs={6} md={3}>
											{formatStringToDate(
												dato?.caso?.fechaModificacion
											) || 'N/A'}
										</Grid> */}
										<Grid item xs={6} md={3}>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
												}}
												onClick={() => {
													downloadPDF(dato, '');
												}}
											>
												<div>
													<img
														src={IconDes}
														style={{
															marginLeft: 5,
															width: 20,
														}}
														alt="Descarga"
													/>
												</div>
												<span
													style={{
														color: '#D1001F',
														paddingLeft: 10,
														cursor: 'pointer',
													}}
												>
													Descargar
												</span>
											</div>
										</Grid>
									</>
								)}
							</Grid>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container spacing={2} columns={12}>
								{matches && (
									<>
										<Grid item xs={12} md={3}>
											<span
												style={{
													fontWeight: 500,
													fontSize: matches ? 15 : 16,
												}}
											>
												Fecha de solicitud
											</span>
										</Grid>
										<Grid item xs={12} md={9}>
											{dato.creationDate}
										</Grid>
										<Grid item xs={12} md={12}>
											<Divider />
										</Grid>
										{/* <Grid item xs={12} md={3}>
											<span
												style={{
													fontWeight: 500,
													fontSize: matches ? 15 : 16,
												}}
											>
												Estatus
											</span>
										</Grid>
										<Grid item xs={12} md={9}>
											<span
												style={{
													background:
														dato?.StateId === '2'
															? '#CBFFE0'
															: '#FFF4DE',
													width: 10,
													height: 10,
													borderRadius: '50%',
													display: 'inline-block',
													marginRight: 5,
												}}
											/>
											<span
												style={{
													fontWeight: 500,
													color:
														dato.StateId === '2'
															? '#00C951'
															: '#FFA800',
												}}
											>
												{dato?.caso?.textoStatus || 'N/A'}
											</span>
										</Grid>
										<Grid item xs={12} md={12}>
											<Divider />
										</Grid>
										<Grid item xs={12} md={3}>
											<span
												style={{
													fontWeight: 500,
													fontSize: matches ? 15 : 16,
												}}
											>
												Fecha de estatus
											</span>
										</Grid>
										<Grid item xs={12} md={9}>
											{formatStringToDate(
												dato?.caso?.fechaModificacion
											) || 'N/A'}
										</Grid>
										<Grid item xs={12} md={12}>
											<Divider />
										</Grid>
										<Grid item xs={12} md={3}>
											<span
												style={{
													fontWeight: 500,
													fontSize: matches ? 15 : 16,
												}}
											>
												Comprobante de cita
											</span>
										</Grid>
										<Grid item xs={12} md={9}>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
												}}
												onClick={() => {
													// downloadPDF(dato, '');
												}}
											>
												<div>
													<img
														src={IconDes}
														style={{
															marginLeft: 5,
															width: 20,
														}}
														alt="Descarga"
													/>
												</div>
												<span
													style={{
														color: '#D1001F',
														paddingLeft: 10,
														cursor: 'pointer',
													}}
												>
													Descargar
												</span>
											</div>
										</Grid> */}
										<Grid item xs={12} md={12}>
											<Divider />
										</Grid>
									</>
								)}
								<Grid item xs={12} sm={12} md={3} lg={3}>
									<h3
										style={{
											fontSize: matches ? 15 : 16,
											marginBottom: matches ? 0 : 16,
											color: '#000000',
										}}
									>
										Servicio
									</h3>
								</Grid>
								<Grid item xs={12} sm={12} md={9} lg={9}>
									<p
										style={{
											fontSize: matches ? 15 : 16,
											marginTop: matches ? 0 : 16,
											color: '#000000',
										}}
									>
										{dato?.serviceDes || ''}
									</p>
								</Grid>

								<Grid item xs={12} md={12}>
									<Divider />
								</Grid>

								<Grid item xs={12} md={3}>
									<h3
										style={{
											fontSize: matches ? 15 : 16,
											marginBottom: matches ? 0 : 16,
											color: '#000000',
										}}
									>
										Descripción
									</h3>
								</Grid>
								<Grid
									item
									xs={12}
									md={9}
									sx={{ fontSize: matches ? 15 : 16 }}
								>
									<span
										style={{
											fontSize: '16px',
											textAlign: 'left',
											fontFamily: 'Geomanist',
										}}
									>
										Tu cita{' '}
										<span
											style={{
												fontSize: '16px',
												textAlign: 'left',
												fontFamily: 'Geomanist, Medium',
												fontWeight: '600',
											}}
										>
											es personal
										</span>{' '}
										y cuando acudas a ella debes presentar{' '}
										<span
											style={{
												fontSize: '16px',
												textAlign: 'left',
												fontFamily: 'Geomanist, Medium',
												fontWeight: '600',
											}}
										>
											tu Identificación oficial vigente
										</span>
										, requisito indispensable para efectuar tu
										trámite. Te recomendamos que llegues con{' '}
										<span
											style={{
												fontSize: '16px',
												textAlign: 'left',
												fontFamily: 'Geomanist, Medium',
												fontWeight: '600',
											}}
										>
											10 minutos de anticipación
										</span>{' '}
										para que no corras el riesgo de perder tu cita y
										tengas que programar una nueva. Si por alguna
										razón no puedes acudir, reprográmala o cancélala.
									</span>
								</Grid>
								<Grid item xs={12} md={12}>
									<Divider />
								</Grid>

								<Grid item xs={12} sm={12} md={3} lg={3}>
									<h3
										style={{
											fontSize: matches ? 15 : 16,
											marginBottom: matches ? 0 : 16,
											color: '#000000',
										}}
									>
										Código de cita
									</h3>
								</Grid>
								<Grid item xs={12} sm={12} md={9} lg={9}>
									<p
										style={{
											fontSize: matches ? 15 : 16,
											marginTop: matches ? 0 : 16,
											color: '#000000',
										}}
									>
										{dato?.appointmentCode || ''}
									</p>
								</Grid>
								<Grid item xs={12} md={12}>
									<Divider />
								</Grid>
							</Grid>
							<Grid container columns={12}>
								<Grid item xs={12} sm={12} md={3} lg={3}>
									<h3
										style={{
											fontSize: matches ? 15 : 16,
											color: '#000000',
										}}
									>
										Documentos necesarios
									</h3>
								</Grid>
								<Grid
									item
									xs={12}
									sm={12}
									md={9}
									lg={9}
									sx={{
										pr: matches ? 0 : 15,
										fontSize: matches ? 15 : 16,
									}}
								>
									<p
										dangerouslySetInnerHTML={{
											__html: dato?.doctosRequerido || '',
										}}
									/>
								</Grid>

								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Divider />
								</Grid>
								<Grid item xs={12} sm={12} md={3} lg={3}>
									<h3
										style={{
											fontSize: matches ? 15 : 16,
											marginBottom: matches ? 0 : 16,
											color: '#000000',
										}}
									>
										Fecha de cita
									</h3>
								</Grid>
								<Grid item xs={12} sm={12} md={9} lg={9}>
									<p
										style={{
											fontSize: matches ? 15 : 16,
											marginTop: matches ? 0 : 16,
											color: '#000000',
										}}
									>
										{getFormatDateShort(dato?.date) || undefined}
									</p>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Divider />
								</Grid>
								<Grid item xs={12} sm={12} md={3} lg={3}>
									<h3
										style={{
											fontSize: matches ? 15 : 16,
											marginBottom: matches ? 0 : 16,
											color: '#000000',
										}}
									>
										Horario de cita
									</h3>
								</Grid>
								<Grid item xs={12} sm={12} md={9} lg={9}>
									<p
										style={{
											fontSize: matches ? 15 : 16,
											marginTop: matches ? 0 : 16,
											color: '#000000',
										}}
									>
										{getFormatHour(dato?.date)}
									</p>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Divider />
								</Grid>
								<Grid item xs={12} sm={12} md={3} lg={3}>
									<h3
										style={{
											fontSize: matches ? 15 : 16,
											marginBottom: matches ? 0 : 16,
											color: '#000000',
										}}
									>
										Ubicación
									</h3>
								</Grid>
								<Grid item xs={12} sm={12} md={9} lg={4} sx={{ pr: 5 }}>
									<p
										style={{
											fontSize: matches ? 15 : 16,
											marginTop: matches ? 0 : 16,
											color: '#000000',
											lineHeight: 1.3,
										}}
									>
										{dato?.organizationLocation || ''}
									</p>
								</Grid>
								<Grid
									item
									xs={12}
									sm={12}
									md={9}
									lg={5}
									sx={{ pt: 2, pb: 4 }}
								></Grid>

								<Grid item xs={12} sm={12} md={12} lg={12}>
									<CustomAlert
										message={alert.message}
										severity={alert.severity}
										show={alert.show}
										onClose={() => {
											setAlert({
												show: false,
												message: '',
												severity: alert.severity,
											});
										}}
									/>
									<div
										style={{
											textAlign: 'center',
											margin: '20px 0px',
											display: 'flex',
											flexDirection: matches
												? 'column-reverse'
												: 'inherit',
											justifyContent: 'center',
										}}
									>
										<div
											style={{
												width: matches ? '100%' : 250,
												display: 'inline-flex',
											}}
										>
											<RoundBtn
												style={{
													width: '260px',
													height: '40px',
													margin: matches ? '10px auto' : '10px'
												}}
												onClick={() => {
													setParamsToCancel({
														pAppointmentId: dato.id || ''
													});
													setModalCancelar(true);
												}}
											>
												Cancelar cita
											</RoundBtn>
										</div>
										<div
											style={{
												width: matches ? '100%' : 250,
												display: 'inline-flex',
											}}
										>
											<RoundBtn
												variant="outlined"
												onClick={() => {
													setDataReprogramar({
														cedis: dato.organizationCode || '',
														data: {
															appointmentCode: dato.appointmentCode || '',
															creationDate: dato.creationDate?.toString() || '',
															organizationCode: dato.organizationCode || '',
															date: dato.date?.toString() || '',
															id: dato.id || '',
															caso: dato.caso || '',
															organizationDes: dato.organizationDes || '',
															organizationLocation: dato.organizationLocation || '',
															service: dato.serviceId || '',
															serviceCode: dato.serviceCode || '',
															ts: dato.TS || '',
															serviceDes: dato.serviceDes || ''
														}
													})
												}}
												style={{
													width: '260px',
													height: '40px',
													margin: matches ? '10px auto' : '10px'
												}}
											>
												Reprogramar
											</RoundBtn>
										</div>
										<div
											style={{
												width: matches ? '100%' : 250,
												display: 'inline-flex',
											}}
										>
											<RoundBtn
												variant="contained"
												onClick={() => {
													window.location.reload();
												}}
												style={{
													width: '260px',
													height: '40px',
													margin: matches ? '10px auto' : '10px'
												}}
											>
												Finalizar
											</RoundBtn>
										</div>
									</div>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				))}
				<CustomAlert
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
				/>
				<BaseModal
					open={modalCancelar}
					title="S"
					disableOnConfirm={!motivo || motivo.length === 0 ? true : false}
					onConfirm={() => {
						handleCancelar(
							paramsToCancel.pAppointmentId
						);
						setModalCancelar(false);
					}}
					onClose={() => {
						setModalCancelar(false)
						setMotivo('')
					}}
					cancelButton={true}
					confirmLabel="Sí"
					cancelLabel="No"
					width="sm"
					children={
						<div
							style={{
								paddingLeft: 40,
								paddingRight: 40,
								paddingTop: 30,
								paddingBottom: 10,
							}}
						>
							<h3
								style={{
									color: '#293990',
									fontSize: 18,
									display: 'flex',
									textAlign: 'center',
									justifyContent: 'center',
									marginBottom: 20,
								}}
							>
								¿Estas seguro que quieres cancelar esta cita?
							</h3>
							<TextInput
								id="motivo"
								name="motivo"
								value={motivo}
								placeholder="Escribe el motivo"
								onChange={(e: any) => {
									if (e.target.value.length > 50) {
										return;
									} else {
										setMotivo(e.target.value);
									}
								}}
							/>
						</div>
					}
				/>
				<ModalLoading loading={loading} />
			</Box>
		</Paper>
	);
};

export default Tab2Appointment;
