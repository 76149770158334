import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';


export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
    container: {
      textAlign: 'center'
    },
    info: {
      '&> h2':{
        color: theme.palette.info.main,
      },
      '& a': {
        color: theme.palette.primary.main,
      },
      '&> .bold': {
        fontWeight: 700,
      }
    }
  })
);
