import { Bitacora, IBitacora } from "../../interfaces/bitacora";
const _bitacora = new Bitacora('118', 'precalificacion-puntos');

export const ENDPOINTS_PRECALIFICACION_PUNTOS: IBitacora = {
    '/v1/miuc-perfilamiento/perfilamiento-inicial': _bitacora.Read('118100', 'Consulta de perfilamiento inicial'),
    '/v1/miuc-perfilamiento/perfilamiento-miuc': _bitacora.Read('118101', 'Consulta de perfilamiento MIUC'),
    '/v1/miuc-perfilamiento/consultar-saldo': _bitacora.Read('118102', 'Consulta saldo'),
    '/v1/miuc-perfilamiento/buro/registrar': _bitacora.Read('118103', 'Consultar buro'),
    '/v1/miuc-perfilamiento/auxiliares/encriptar-url': _bitacora.Read('118104', 'Encriptar URL'),
    '/v1/miuc-adicionales/consultar-productos/montos': _bitacora.Read('118105', 'Consultar Montos'),
    '/v1/miuc-adicionales/agroindustrias/obtener-alternativa-financiera-pdf': _bitacora.Read('118106', 'Descargar alternativa financiera PDF'),
    '/v1/miuc-perfilamiento/consultar-cat': _bitacora.Read('118107', 'Consultar CAT'),
    '/v1/miuc-adicionales/calculadora': _bitacora.Read('118108', 'Consultar calculadora'),
    '/v1/miuc-adicionales/md-cuestionario/consultar-estatus': _bitacora.Read('118109', 'Consultar estatus'),
    '/v1/miuc-adicionales/consultar-tabla-amortizacion': _bitacora.Read('118110', 'Consultar tabla de amortizacion'),
    '/v1/miuc-perfilamiento/generar-trazabilidad': _bitacora.Read('118111', 'Generar trazabilidad'),
    '/v1/miuc-adicionales/md-cuestionario/buscar': _bitacora.Read('118112', 'Consultar cuestionario'),
    '/v1/miuc-adicionales/consultar-productos/autoproduccion': _bitacora.Read('118113', 'Consultar productos de autoproduccion'),
    '/v1/miuc-adicionales/consultar-productos/mejora-directa': _bitacora.Read('118114', 'Consultar productos de mejora directa'),
    '/v1/miuc-adicionales/md-cuestionario/encolar': _bitacora.Read('118115', 'Encolar cuestionario'),
    '/v1/miuc-adicionales/consultar-productos/micromejoras-mejoras-mayores': _bitacora.Read('118116', 'Consultar productos micromejoras'),
    '/v1/miuc-adicionales/md-cuestionario/consultar-completo': _bitacora.Read('118117', 'Consultar cuestionario completo'),
    '/v1/miuc-perfilamiento/agroindustrias/consultar': _bitacora.Read('118118', 'Consultar agroindustrias'),
    '/v1/miuc-adicionales/agroindustrias/obtener-registro-pdf': _bitacora.Read('118119', 'Descargar registro agroindustria PDF'),
    '/v1/miuc-adicionales/agroindustrias/obtener-comprobante-pdf': _bitacora.Read('118120', 'Descargar comprobante agroindustria PDF'),
    '/v1/miuc-adicionales/agroindustrias/obtener-movimientos-pdf': _bitacora.Read('118121', 'Descargar movimientos agroindustria PDF'),
    '/v1/miuc-adicionales/agroindustrias/obtener-inscrito-pdf': _bitacora.Read('118122', 'Descargar inscrito agroindustria PDF'),
    '/v1/miuc-adicionales/agroindustrias/registrar': _bitacora.Read('118123', 'Consultar registro en agroindustrias'),
    '/v1/miuc-adicionales/aportaciones-voluntarias': _bitacora.Read('118124', 'Consultar aportaciones voluntarias'),
    '/v1/miuc-adicionales/aportaciones-voluntarias/bitacora': _bitacora.Read('118125', 'Consultar bitacora aportaciones voluntarias'),
    '/v1/miuc-adicionales/auxiliares/consultar-tipos-credito': _bitacora.Read('118126', 'Consultar tipos de credito'),
    '/v1/miuc-adicionales/catalogos/consultar-estados': _bitacora.Read('118127', 'Consultar estados'),
    '/v1/miuc-adicionales/catalogos/consultar-unidades': _bitacora.Read('118128', 'Consultar unidades'),
    '/v1/miuc-adicionales/catalogos/consultar-entidades-financieras': _bitacora.Read('118129', 'Consultar entidades financieras'),
    '/v1/miuc-adicionales/catalogos/consultar-entidades-participantes': _bitacora.Read('118130', 'Consultar entidades participantes'),
    '/v1/miuc-adicionales/pdf/certificado/apoyo': _bitacora.Read('118131', 'Consultar certificado de apoyo'),
    '/v1/miuc-adicionales/md-cuestionario/obtener-constancia-elegibilidad-pdf': _bitacora.Read('118132', 'Descargar constancia de elegibilidad PDF'),
    '/v1/miuc-perfilamiento/suelo-construccion': _bitacora.Read('118133', 'Consultar suelo y construccion perfilamiento'),
};
