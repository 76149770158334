export const isEmpty = (prop: any) => {
	return (
		prop === false ||
		prop === null ||
		prop === undefined ||
		(prop.hasOwnProperty('length') && prop.length === 0) ||
		(prop.constructor === Object && Object.keys(prop).length === 0)
	);
};

export const formatMoney = (value: number) => {
	return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' })
		.format(Math.trunc(value * 100) / 100)
		.replace('$', '$');
};

export const formatMoneyUnsigned = (value: any) => {
	return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' })
		.format(Math.trunc(value * 100) / 100)
		.replace('$', '');
};

export const formatMoney2 = (value: number) => {
	return Intl.NumberFormat('es-MX', {
		style: 'currency',
		currency: 'MXN',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})
		.format(value)
		.replace('$', '$ ');
};
export const formatMoneyByDigits = (value: any, digits: any, signo: string) => {
	return Intl.NumberFormat('es-MX', {
		style: 'currency',
		currency: 'MXN',
		minimumFractionDigits: digits,
		maximumFractionDigits: digits,
	})
		.format(value)
		.replace('$', signo);
};

export const formatPor = (value: number) => {
	return Intl.NumberFormat('es-MX', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(value);
};

export const numberWithCommas = (value: number) => {
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
