import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		textAlign: 'center',
	},
	information: {
    width: '100%',
		backgroundColor: '#F2F2F2',
		padding: '16px',
		fontSize: '20px',
	},
	options: {
		display:'flex', 
		justifyContent:'center',
		alignItems: 'center',
		flexWrap: 'wrap',
		[theme.breakpoints.only('sm')]: {
			flexDirection: {
				sm: 'row',
			},
		},
		gap: '16px'
	},
	products: {
		justifyContent: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			gap: '16px'
		},
		[theme.breakpoints.up('sm')]: {
			gap: '35px'
		},
	}
}));
