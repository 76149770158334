import API from '../';

class InfonavityPartnerAPI extends API {
	IsRegistered(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: '/socio-infonavit/registro',
				method: 'POST',
				data: { data: data },
			})
		);
	}
}

export const socioInfonavitAPI = new InfonavityPartnerAPI('');
