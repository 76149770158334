import React, { useCallback, useEffect, useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { red, green, yellow } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { associateNssAPI } from '../../api/modules/AssociateNSS';
import { StyledTableCell, CustomButton } from './Styles';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { RootState } from '../../store';

interface Props {
	setDataModal: (nss: string) => void;
	affiliated: any;
}

const getStatus = (estatus: string) => {
	switch (estatus) {
		case '00':
			return <div style={{ color: red[400], fontWeight: 'bold' }}>Cancelada</div>;
		case '04':
			return (
				<div style={{ color: green[400], fontWeight: 'bold' }}>Confirmada</div>
			);
		case '02':
			return (
				<div style={{ color: yellow[700], fontWeight: 'bold' }}>Pendiente</div>
			);
		case '03':
			return (
				<div style={{ color: yellow[700], fontWeight: 'bold' }}>Pendiente</div>
			);
		default:
			return <div></div>;
	}
};

const ListAdminNss: React.FC<Props> = ({ setDataModal, affiliated }) => {
	const { user } = useSelector((state: RootState) => state.session);
	let [type, setType] = useState('');
	const fetchTypeAssociates = useCallback(async () => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const USER_NSS_BASE64 = await encrypt(user?.nss?.toString() || '', key);

			associateNssAPI.typeAssociates
				.post<{ nss: string }, any>('', {
					nss: USER_NSS_BASE64,
				})
				.then((res: any) => {
					if (res.data && typeof res.data === 'object') {
						let types = Object.keys(res.data).map((key) => ({
							value: key,
							label: res.data[key],
						}));
						let newType =
							types.find((t) => t.value === affiliated?.tipoAsociacion)
								?.label || '';
						setType(newType);
					}
				})
				.catch(() => { });
		} catch (error) { }
	}, [affiliated?.tipoAsociacion, user?.nss]);

	useEffect(() => {
		fetchTypeAssociates();
	}, [fetchTypeAssociates]);

	return (
		<TableContainer>
			<Table
				sx={{ minWidth: 650 }}
				aria-label="simple table"
				data-testid="tableNSS"
			>
				<TableHead>
					<TableRow>
						<StyledTableCell align="left">NSS</StyledTableCell>
						<StyledTableCell align="left">Nombre</StyledTableCell>
						<StyledTableCell align="left">CURP</StyledTableCell>
						<StyledTableCell align="left">RFC</StyledTableCell>
						<StyledTableCell align="left">Tipo de asociación</StyledTableCell>
						<StyledTableCell align="left">Estatus</StyledTableCell>
						<StyledTableCell align="left"></StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{affiliated?.nssAsociado && (
						<TableRow>
							<TableCell align="left">{affiliated?.nssAsociado}</TableCell>
							<TableCell align="left">
								{affiliated?.nombreAsociado}
							</TableCell>
							<TableCell align="left">{affiliated?.curpAsociado}</TableCell>
							<TableCell align="left">{affiliated?.rfcAsociado}</TableCell>
							<TableCell align="left">{type}</TableCell>
							<TableCell align="left">
								{getStatus(affiliated?.estatus)}
							</TableCell>
							<TableCell align="left">
								<CustomButton
									onClick={() => setDataModal(affiliated?.nssAsociado)}
									variant="text"
									color="error"
								>
									Eliminar
								</CustomButton>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default ListAdminNss;
