// DEPENDENCIES
import React, { useState } from 'react'
import { Paper } from '@mui/material'
// COMPONENTS
import { HeaderList } from '../../components/HeaderList'
import Layout from '../../components/Layout'
import Tabs from '../../components/Tabs'
//  VIEWS
import Procedure from './Procedure'
import Questions from './Questions'
import VideoTab from './VideoTab'
// RESOURCES
import { formatDate } from '../../utils/dates'
import { tabsOptions } from './utils'
import { useStyles } from './styles'

const SharedResponsability = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const classes = useStyles()
  return (
    <Layout>
      <>
        <HeaderList title='Responsabilidad Compartida' date={formatDate('P')} />
        <Tabs
          tabs={tabsOptions}
          onChangeTab={(index, tab) => setSelectedTab(index)}
          active={selectedTab}
          containerPosition={selectedTab+1}
          >
          <Paper className={classes.paperContainer}>
            {selectedTab===0&&<Procedure />}
            {selectedTab===1&&<VideoTab />}
            {selectedTab===2&&<Questions />}
          </Paper>
        </Tabs>
      </>
    </Layout>
  )
}

export default SharedResponsability