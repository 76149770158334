import { MIUC_PROCESS_VIEWS } from './process';

export const MIUC_BASIC_STEPS = [
	MIUC_PROCESS_VIEWS.CREDIT_OPTIONS,
	MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO,
	MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT,
	MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
];

export const MIUC_FLOW_STEPS = [
	MIUC_PROCESS_VIEWS.CREDIT_OPTIONS,
	MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO,
	MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT,
	MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
	MIUC_PROCESS_VIEWS.CREDIT_QUESTIONS_CONSTRUIR,
	MIUC_PROCESS_VIEWS.CREDIT_CONDITIONS,
	MIUC_PROCESS_VIEWS.INFORMATIVE_CONTENT,
];