import React from 'react';

const ProrogationCard = () => {
	return (
		<div className="prorogation-card">
			<h5>Prorroga</h5>
			<br />
			<h2>CI+CB</h2>
		</div>
	);
};

export default ProrogationCard;
