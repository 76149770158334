import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	labelContainerInput: {
		margin: 0,
		color: 'black',
		fontSize: 14,
		fontWeight: 700,
	},
	labelRequired: {
		color: theme.palette.primary.main,
	},
	optionsContainer: {
		padding: '0 45px',
	},
}));
