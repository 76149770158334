import { useStyles } from './styles';

const PrivacyTerms = () => {
	const classes = useStyles();

	return (
		<div className={classes.dialogBodyPrivacy}>
			<p className={classes.titleTerms}>
				<strong>I. Denominación y domicilio del responsable</strong>
			</p>
			El Instituto del Fondo Nacional de la Vivienda para los Trabajadores
			(Infonavit), con domicilio en Barranca del Muerto número 280, Colonia
			Guadalupe Inn, Alcaldía Álvaro Obregón, C.P. 01029, Ciudad de México, a través
			de la Gerencia Senior de Soporte y Monitoreo de la Operación, adscrita a la
			Subdirección General de Operaciones, es responsable del tratamiento de los
			datos personales que se refieren en el presente aviso de privacidad, los
			cuales serán protegidos en cumplimiento a lo dispuesto por la Ley General de
			Protección de Datos Personales en Posesión de Sujetos Obligados (LGPDPPSO), y
			demás normativa que resulte aplicable.
			<p className={classes.titleTerms}>
				<strong>
					II. Finalidades del tratamiento para las cuales se obtiene datos
					personales
				</strong>
			</p>
			La Gerencia Senior de Soporte y Monitoreo de la Operación utilizará sus datos
			personales para llevar a cabo las finalidades derivadas del ámbito de sus
			funciones, relativas a asesoría y atención a los titulares respecto a los
			diferentes beneficios o estatus de sus trámites o servicios; consultar su
			información y hacer trámites en línea, fomentar la cultura financiera de los
			acreditados y analizar información para crear o mejorar productos y servicios,
			las cuales se traducen en las siguientes:
			<ol>
				<li>Contar con un registro en MI Cuenta Infonavit</li>
				<li>Cambiar contraseña.</li>
				<li>Actualizar datos de contacto.</li>
				<li>Enviar información vía correo electrónico.</li>
				<li>Enviar información vía SMS (mensaje a teléfono celular).</li>
				<li>Evaluar el servicio prestado por este canal de contacto.</li>
				<li>
					Reseteo, modificación o eliminación del registro de Mi Cuenta
					Infonavit.
				</li>
				<li>
					Obtener información de las respuestas dadas, en su caso, en los cursos
					que se ofrecen en Mi Cuenta Infonavit.
				</li>
			</ol>
			Por regla general, el consentimiento para el tratamiento de sus datos
			personales será tácito, es decir, cuando habiéndose puesto a su disposición el
			presente aviso de privacidad, y usted no manifieste su voluntad en sentido
			contrario; por tanto, no se requerirá autorización expresa para el tratamiento
			de sus datos personales, puesto que a través del ingreso de los datos
			personales a través de su solicitud, petición, consulta, trámite, correo
			electrónico, mensaje, ingreso a herramienta o aplicativo electrónico o
			cualquier otro medio de contacto reconocido por el Infonavit o la Gerencia
			Senior de Soporte y Monitoreo de la Operación, adscrita a la Subdirección
			General de Operaciones, usted autoriza de manera tácita a dicha Gerencia el
			tratamiento de sus datos personales en términos del presente aviso de
			privacidad.
			<p className={classes.titleTerms}>
				<strong>III. Datos personales sometidos a tratamiento</strong>
			</p>
			Se hace de su conocimiento que el Infonavit, a través de la Gerencia Senior de
			Soporte y Monitoreo de la Operación recaba, procesa y utiliza datos personales
			de las y los siguientes titulares: las y los acreditados, las y los jubilados,
			así como las y los derechohabientes.
			<br />
			De acuerdo con las finalidades establecidas, se recaban los siguientes datos
			personales:
			<br />
			<br />
			<table className={classes.privacyTable}>
				<thead>
					<tr>
						<th>Finalidad</th>
						<th>Datos personales recabados</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>1. Contar con registro en Mi Cuenta Infonavit</td>
						<td rowSpan={3}>
							• Correo electrónico particular.
							<br />
							• Teléfono celular.
							<br />
							• Contraeña.
							<br />
						</td>
					</tr>
					<tr>
						<td>2. Cambiar contraseña</td>
					</tr>
					<tr>
						<td>3. Actualizar datos de contacto</td>
					</tr>
					<tr>
						<td>4. Enviar información vía correo electrónico</td>
						<td>• Correo electrónico particular.</td>
					</tr>
					<tr>
						<td>
							5. Enviar información vía SMS (mensaje a teléfono celular).
						</td>
						<td>• Teléfono celular.</td>
					</tr>
					<tr>
						<td>
							6. Evaluar el servicio prestado por este canal de contacto
						</td>
						<td>
							• Correo electrónico particular.
							<br />• Teléfono celular.
						</td>
					</tr>
					<tr>
						<td>
							7. Reseteo, modificación o eliminación del registro de Mi
							Cuenta Infonavit
						</td>
						<td>
							• Número de Seguridad Social (NSS compuesto de once dígitos).
							<br />
							• Clave Única de Registro de Población (CURP secuencia
							alfanumérica de 18 caracteres).
							<br />• Registro Federal de Contribuyentes (RFC clave
							alfanumérica de 13 caracteres).
							<br />• Correo electrónico particular
						</td>
					</tr>
					<tr>
						<td>
							7. Obtener información de las respuestas dadas, en su caso, en
							los cursos que se ofrecen en Mi Cuenta Infonavit.
						</td>
						<td>
							• Información de la situación y necesidades de vivienda
							(actuales y futuras).
							<br />
							• Información de la situación personal (etapa de vida).
							<br />• Presupuesto destinado.
							<br />• Expectativa de vivienda.
							<br />• Seguridad física y jurídica en tu trámite.
						</td>
					</tr>
				</tbody>
			</table>
			<p className={classes.titleTerms}>
				<strong>
					IV. Fundamento legal para el tratamiento de los datos personales
				</strong>
			</p>
			La Gerencia Senior de Soporte y Monitoreo de la Operación, tratará los datos
			personales señalados previamente con fundamento en lo dispuesto en el artículo
			29 Quater de la Ley del Instituto del Fondo Nacional de la Vivienda para los
			Trabajadores; artículo 56, fracciones I, III, IV, V, X y XII del Estatuto
			Orgánico del Instituto del Fondo Nacional de la Vivienda para los
			Trabajadores, artículo 7, sección V de las Disposiciones de carácter general
			aplicables a los Organismos de Fomento y Entidades de Fomento y la Ley General
			de Protección de Datos Personales en Posesión de Sujetos Obligados.
			<p className={classes.titleTerms}>
				<strong>V. Transferencia de datos personales</strong>
			</p>
			Se hace de su conocimiento que la Gerencia Senior de Soporte y Monitoreo de la
			Operación, no realiza transferencias de datos personales, salvo aquéllas que
			sean necesarias para atender requerimientos de información de una autoridad
			competente, así como aquellas en donde se actualice algunos de los supuestos
			del artículo 70 de la Ley General de Protección de Datos Personales en
			Posesión de Sujetos Obligados, para las cuales no se requiere su
			consentimiento para poder realizarse.
			<p className={classes.titleTerms}>
				<strong>
					VI. Mecanismos y medios disponibles para que el titular pueda
					manifestar su negativa para el tratamiento de sus datos personales
					para finalidades y transferencias de datos personales que requieran el
					consentimiento del titular
				</strong>
			</p>
			Para cualquier asunto relacionado con el presente aviso de privacidad podrá
			establecer comunicación directa al correo electrónico:
			unidadtransparencia@infonavit.org.mx o presencialmente, en las oficinas que
			ocupa la Unidad de Transparencia a fin de que se le oriente sobre el alcance
			de la negativa respecto al tratamiento de datos personales y los medios
			disponibles para tal efecto.
			<p className={classes.titleTerms}>
				<strong>
					VII. Mecanismos, medios y procedimientos disponibles para ejercer los
					derechos de acceso, rectificación, cancelación y oposición de datos
					personales (derechos ARCO).
				</strong>
			</p>
			Como titular de los datos personales, usted tiene{' '}
			<strong>derecho de acceso,</strong> es decir, solicitar el acceso a los datos
			personales que estén en posesión del Infonavit, o bien, conocer información
			relacionada con las condiciones y generalidades del uso que el Infonavit da a
			sus datos personales; <strong>derecho de rectificación:</strong> lo que
			implica requerir la corrección de sus datos personales por ser inexactos,
			incompletos o no estar actualizados; <strong>derecho de cancelación:</strong>{' '}
			es decir, tendrá derecho de solicitar la eliminación, supresión o borrado de
			los datos personales de los archivos, registros, expedientes y sistemas del
			Infonavit de que se trate; y <strong>derecho de oposición:</strong> es decir
			solicitar que se concluya el tratamiento de los datos personales, a fin de
			evitar un daño o perjuicio al titular, o bien, si los datos son objeto de un
			tratamiento automatizado, sin intervención humana, que produzca efectos
			jurídicos no deseados o afecten los intereses, derechos o libertades del
			titular.
			<br />
			Usted podrá presentar solicitudes para el ejercicio de derechos ARCO ante el
			Infonavit, por los siguientes medios:
			<ol>
				<li>
					A través de la Plataforma Nacional de Transparencia (PNT), en el
					hipervínculo:{' '}
					<a
						target="_blank"
						href="http://www.plataformadetransparencia.org.mx/"
						rel="noreferrer"
					>
						http://www.plataformadetransparencia.org.mx/
					</a>
					. En este caso, le será asignado automáticamente un número de folio
					con el que podrá dar seguimiento a su requerimiento.
				</li>
				<li>
					A través del correo electrónico: unidadtransparencia@infonavit.org.mx,
					utilizando, para el caso de solicitudes para el ejercicio de derechos
					ARCO, el formato disponible en:{' '}
					<a
						target="_blank"
						href="https://home.inai.org.mx/wp-content/documentos/formatos/PDP/FormatoDerechosARCO.docx"
						rel="noreferrer"
					>
						https://home.inai.org.mx{' '}
					</a>
					o mediante escrito libre.
				</li>
				<li>Presencialmente ante la Unidad de Transparencia del Infonavit.</li>
			</ol>
			Los requisitos para la solicitud serán los siguientes:
			<ol>
				<li>
					El nombre del titular y su domicilio o cualquier otro medio para
					recibir notificaciones;
				</li>
				<li>
					Los documentos que acrediten la identidad del titular y, en su caso,
					la personalidad e identidad de su representante;
				</li>
				<li>
					De ser posible, el área responsable que trata los datos personales y
					ante el cual se presenta la solicitud;
				</li>
				<li>
					La descripción clara y precisa de los datos personales respecto de los
					que se busca ejercer alguno de los derechos ARCO, salvo que se trate
					del derecho de acceso;
				</li>
				<li>
					La descripción del derecho ARCO que se pretende ejercer, o bien, lo
					que solicita el titular, y
				</li>
				<li>
					Cualquier otro elemento o documento que facilite la localización de
					los datos personales, en su caso.
				</li>
			</ol>
			Si desea conocer el procedimiento para el ejercicio de los derechos ARCO,
			puede visitar la siguiente liga:{' '}
			<a
				target="_blank"
				href="http://inicio.inai.org.mx/Guias/Guia%20Titulares-03_PDF.pdf"
				rel="noreferrer"
			>
				http://inicio.ifai.org.mx.
			</a>
			<br />
			<strong>Portabilidad de los datos personales:</strong>
			<br />
			El ejercicio del derecho a la portabilidad no es compatible con el tratamiento
			de datos personales y finalidades que se señalan en el presente aviso de
			privacidad.
			<p className={classes.titleTerms}>
				<strong>VIII. Domicilio de la Unidad de Transparencia</strong>
			</p>
			Avenida Barranca del Muerto, núm. 280, piso 1, oficina 104, colonia Guadalupe
			Inn, Alcaldía Álvaro Obregón, Ciudad de México, C.P. 01020, entre Manuel M.
			Ponce y Macedonio Alcalá (a una cuadra de Avenida Insurgentes), o bien,
			unidadtransparencia@infonavit.org.mx.
			<p className={classes.titleTerms}>
				<strong>
					X. Sitio donde podrá consultar el Aviso de privacidad integral y
					medios a través de los cuales se le comunicará los cambios de este.
				</strong>
			</p>
			Nuestro aviso de privacidad se encuentra a su disposición en el portal del
			Infonavit, en el vínculo electrónico:{' '}
			<a
				target="_blank"
				rel="noreferrer"
				href="https://portalmx.infonavit.org.mx/wps/portal/infonavit.web/transparencia/aviso-privacidad/!ut/p/z1/jZFBDoJADEXP4gnaAQJ1OYM4qISBxInQjcENISK4MJ5fZOfCge6avJ_X5g
								NDBTw0765tXt04NP201xxeQ42YxoHIjTwFWJIqUEWFnwkPLjPgEaJWKHJNKaHcJ4dkm_meFgi8J
								u8Avnn8MxIX_Fas8zsEi3meEdcFSz_wr0QrIiz1OVexMT7tIjgCt_14m_p4Pqy1FXbFXW4-
								mJLnsg!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
			>
				https://portalmx.infonavit.org.mx.
			</a>
			<br />
			<br />
			En caso de que se realicen modificaciones o cambios al aviso de privacidad
			integral y simplificado se harán de su conocimiento y se podrán identificar en
			cada documento conforme a cada actualización, a través del portal electrónico
			del Infonavit.
		</div>
	);
};

export default PrivacyTerms;
