import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
export const useStyles = makeStyles((Theme: Theme) =>
	createStyles({
		contenedor: {
			display: 'flex',
			[Theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
			},
		},
		cont1: {
			width: '100%',
			flexDirection: 'column',
			[Theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		contButtom: {
			display: 'flex',
			backgroundColor: '#FFFFFF',
			border: '1px solid #E8E8E8',
			borderRadius: '4px',
			[Theme.breakpoints.down('sm')]: {
				flexWrap: 'wrap',
			},
		},
		notFoundImage: {
			padding: 10,
		},
		image: {
			width: 280,
			height: 'auto',
			[Theme.breakpoints.down('sm')]: {
				width: 180,
			},
		},
		container: {
			padding: '50px 150px',
			textAlign: 'center',
			[Theme.breakpoints.down('sm')]: {
				textAlign: 'center',
				padding: '20px',
			},
		},
	})
);
