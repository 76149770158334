import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Box } from '@mui/material';
import cicb_logo from '../../../../../..//assets/img/Cuenta-Infonavit_Credito-Bancario.png';
import iconoPDF from '../../../../../../assets/img/icono-descarga.png';
import HelpPanel from '../../../../components/HelpPanel';
import { RootState } from '../../../../../../store';
import { IAlert } from '../../../../../../interfaces/alert';
import ModalLoading from '../../../../../../components/ModalLoading';
import CustomAlert from '../../../../../../components/CustomAlert';
import CustomButton from '../../../../../../components/Button';
import { useStyles } from './styles';
import { encodeAllJSONData } from '../../../../../../utils/encrypt';
import { useGeneralStyles } from '../../../../views/styles/general.styles';
import { useController } from '../../../../hooks/useController';
import { apiMIUC } from '../../../../../../api/modules/MIUC';

const ProofOfBalance = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const { saldo } = useSelector((state: RootState) => state.saldo);
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const { returnStep, nextStep, dataProcessController } = useController();
	const classes = useStyles();
	const generalStyle = useGeneralStyles();

	const getPdf = async () => {
		try {
			setLoading(true);
			const data = {
				pdfParamFeInsc: new Date().toLocaleString() || '',
				pdfParamNss: user?.nss.toString() || '',
				pdfParamNombre: user?.given_name.toString() || '',
				pdfParamSsv:
					saldo.toString() ||
					'',
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const result = await apiMIUC.getPDFCICB(dataEncripted);
			const { pdf } = result.data.result;
			if (pdf) {
				let link = document.createElement('a');
				link.download =
					'CERTIFICADO_CUENTA_INFONAVIT_CREDITO_BANCARIO.pdf';
				link.href = 'data:application/octet-stream;base64,' + pdf;
				link.click();
			} else {
				setAlert({
					show: true,
					message:
						'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					severity: 'error',
				});
			}
		} catch (error) {
			setAlert({
				show: true,
				message:
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<HelpPanel
				type={'ENTIDADES'}
				openModal={openModal}
				setOpenModal={setOpenModal}
			/>
			<Box padding={3}>
				<h3>
					Tenemos una alternativa de financiamiento para adquisición
					de vivienda para nuestros derechohabientes que no tienen una
					relación asalariada vigente, pero que tienen ahorro en su
					subcuenta de vivienda.
				</h3>
				<img src={cicb_logo} alt="ci+cb-logo" width={150} />
				<p>
					Con esta alternativa tú realizas el trámite con la entidad
					financiera participante que más te convenga y del monto de
					crédito autorizado, Infonavit participa otorgando un
					porcentaje de ese monto, además, puedes disponer de lo que
					tengas ahorrado en tu Subcuenta de Vivienda. El crédito
					puede ser individual o conyugal, en este caso, ambos deben
					cumplir con los requisitos.
				</p>
				<p>
					Conoce las entidades financieras participantes , descarga tu
					Certificado Cuenta Infonavit + Crédito Bancario y continúa
					para conocer características, requisitos y pasos a seguir.
				</p>
				<CustomAlert
					show={alert.show}
					message={alert.message}
					severity={alert.severity}
				/>
				<Box className={classes.linkContainer}>
					{Number(
						saldo.toString() ||
							'0'
					) > 0 && (
						<Link
							underline="none"
							className={classes.link}
							onClick={() => getPdf()}
						>
							Certificado Cuenta Infonavit + Crédito Bancario
							<img src={iconoPDF} alt={'iconoPDF'} />
						</Link>
					)}
				</Box>
			</Box>
			<div className={generalStyle.bottonsContainer}>
				<CustomButton
					label={'Regresar'}
					onClick={() => returnStep()}
					variant="outlined"
				/>
				<CustomButton
					label="Continuar"
					onClick={() => nextStep()}
					variant="solid"
				/>
			</div>
		</>
	);
};

export default ProofOfBalance;
