import { useState } from "react";
import BaseModal from "../../../components/Modal";
import { useStyles } from "./styles";
import { Box, Divider } from "@mui/material";

interface IProps {
	open: boolean;
	setOpen: (value: boolean) => void;
	onFinish: () => void;
}

export const SumarNssModal = ({
	open,
	setOpen,
	onFinish
}: IProps) => {
	const classes = useStyles();

	return (
		<BaseModal
			open={open}
			onConfirm={ () => onFinish() }
			confirmLabel="Finalizar"
			onClose={ () => setOpen(!open) }
			width="md"
		>
			<Box className={classes.container}>
				<Box className={classes.info}>
					<h2>Tu solicitud ha sido realizada con éxito</h2>
					<p className="bold">Para continuar tu asociado recibirá un correo de confirmación</p>
					<p>Una vez aceptado, se unirán sus créditos y podrás continuar con tu precalificación.</p>
					<p>Para consultar el estatus de tu asociación ingresa a</p>
					<p>Quiero un crédito /{' '} 
						<a>Sumar créditos</a>
					</p>
				</Box>
				<Divider />
			</Box>
		</BaseModal>
	);
};