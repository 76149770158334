import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import {
	INITIAL_STATE,
	LoadingState,
	SET_START_LOADING,
	SET_STOP_LOADING,
} from '../types/loading';

export const actions = {
	startLoading: createAsyncThunk(SET_START_LOADING, () => {
		return true;
	}),
	stopLoading: createAsyncThunk(SET_STOP_LOADING, () => {
		return false;
	}),
};

export default createReducer(INITIAL_STATE, {
	[actions.startLoading.fulfilled.type]: (_state: LoadingState) => ({
		..._state,
		isLoading: true,
	}),
	[actions.stopLoading.pending.type]: (_state: LoadingState) => ({
		..._state,
		isLoading: false,
	}),
});
