import CustomButton from '../../../../../components/Button';
import { useStyles } from './styles';

interface IProps {
	buttons: Array<{
		label: string;
		execute?: () => void;
		variant?: 'solid' | 'outlined';
	}>;
	styles?: React.CSSProperties;
}

const ButtonGroup = ({ buttons, styles }: IProps) => {
    const style = useStyles();

	return (
		<section className={style.containerButton} style={styles}>
			{buttons.map((b, k) => (
				<article key={k}>
					<CustomButton
						label={b.label}
						variant={b.variant}
						onClick={b.execute}
					/>
				</article>
			))}
		</section>
	);
};

export default ButtonGroup;
