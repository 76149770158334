/* eslint-disable react-hooks/exhaustive-deps */
import { useStyles } from '../style';
import { useContext, useEffect, useState } from 'react';
import CustomAlert from '../../../components/CustomAlert';
import { RowUploadFile } from '../utils';
import { dataReturnSavingsContext } from '../contextData';
import { IFile, IFileItem } from '../interfaces';
import CustomButton from '../../../components/Button';
import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ModalLoading from '../../../components/ModalLoading';
import Resumable from 'resumablejs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import axios from 'axios';
import { useBitacora } from '../../../hooks/useBitacora';

const UploadFiles = () => {
	const [files, setFiles] = useState<IFileItem[]>([]);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const {
		data,
		handlerConsultaDocumentacion,
		alert,
		setAlert,
		getMessageErrorDefault,
		loading,
		setLoading,
		handlebusquedaCasos,
		setPage,
		handleGoPage
	} = useContext(dataReturnSavingsContext);
	const { regBitacoraNotOld } = useBitacora();

	useEffect(() => {
		let response = data?.ConsultaDocumentacionResponse;
		if (response === undefined) {
			return;
		}

		if (response.code !== '0000') {
			setAlert({
				show: true,
				severity: 'error',
				message: getMessageErrorDefault,
			});
		}

		let _doctos: string[] = [];
		let _doctosOptional: string[] = [];

		let _clave: string = '';
		let _claveOptional: string = '';

		if (data?.claseOperacion === 'ZG1C') {
			response.data?.documentacion.forEach((item) => {
				if (item.obligatorio !== undefined && item.obligatorio !== '') {
					if (item.obligatorio.includes('A') && !item.obligatorio.includes('X')) {
						_doctos.push(item.descripcion);
						if (_clave === '') {
							_clave = item.clave;
						}
					} else {
						_doctosOptional.push(item.descripcion);
						_claveOptional = item.clave;
					}
				}
			});
		}

		if (data?.claseOperacion === 'ZAFA') {
			response.data?.documentacion.forEach((item) => {
				if (item.obligatorio !== undefined && item.obligatorio !== '') {
					if (item.obligatorio.includes('A') && !item.obligatorio.includes('X')) {
						_doctos.push(item.descripcion);
						if (_clave === '') {
							_clave = item.clave;
						}
					}
					if (item.obligatorio.includes('X')) {
						_doctosOptional.push(item.descripcion);
						_claveOptional = item.clave;
					}
				}
			});
		}

		if (data?.claseOperacion === 'ZASV') {
			response.data?.documentacion.forEach((item) => {
				_doctos.push(item.descripcion);
				if (_clave === '') {
					_clave = item.clave;
				}
			});
		}

		let values: IFileItem[] = [];

		if (data?.claseOperacion === 'ZG1C' || data?.claseOperacion === 'ZAFA') {
			let newDoc: IFileItem = {
				label: 'Identificación oficial vigente',
				clave: _clave,
				subtitle: true,
				file: {
					name: 'No se eligió ningún archivo',
					data: null,
					type: '',
					size: 0,
				},
				listDoctos: _doctos,
			};
			let newDocOptional: IFileItem = {
				label: 'Documento obligatorio',
				clave: _claveOptional,
				subtitle: true,
				file: {
					name: 'No se eligió ningún archivo',
					data: null,
					type: '',
					size: 0,
				},
				listDoctos: _doctosOptional,
			};
			values.push(newDoc);
			values.push(newDocOptional);
		}

		if (data?.claseOperacion === 'ZASV') {
			let newDoc: IFileItem = {
				label: 'Identificación oficial vigente',
				clave: _clave,
				file: {
					name: 'No se eligió ningún archivo',
					data: null,
					type: '',
					size: 0,
				},
				listDoctos: _doctos,
			};
			values.push(newDoc);
		}
		setFiles(values);
	}, [data?.ConsultaDocumentacionResponse]);

	useEffect(() => {
		handlerConsultaDocumentacion({ caso: data?.noCaso || '' });
	}, []);

	const handleAttachCase = async (file: any) => {
		setLoading(true);
		let formData = new FormData();
		let nameArray = file.file.name.split('.')
		let nameArrayClave = nameArray[nameArray.length - 2].split('_')
		formData.append('numeroCredito', credit);
		formData.append('numeroCaso', data?.noCaso || '');
		formData.append('tipificacion', data?.tipificacion || '');
		formData.append('nombreArchivo', file.file.name);
		formData.append('nss', user?.nss ? user?.nss.toString() : '');
		formData.append('claseOperacion', data?.claseOperacion || 'A004');
		formData.append('cveDocumento', nameArrayClave[nameArrayClave.length - 1] || '');
		formData.append('extension', nameArray[nameArray.length - 1]);
		if (file.file.name.includes('DOCUMENTOOBLIGA') || data?.claseOperacion === 'ZASV') {
			formData.append('cambioEstatus', 'X');
		}
		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL_OPENTEXT}/CartasProduccion-web/api/AltaEscrituras/envioOpenText`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' },
		}).then((res) => {
			setTimeout(() => {
				setLoading(false);
				if (data?.returnOptionType === 'resolucionPensionImss' && (data?.grupo === '2' || data?.grupo === '3')) {
					handleGoPage(8)
					regBitacoraNotOld('/CartasProduccion-web/api/AltaEscrituras/envioOpenText', formData);
				} else {
					handlebusquedaCasos();
					regBitacoraNotOld('/CartasProduccion-web/api/AltaEscrituras/envioOpenText', formData);
				}
			}, 15000);
		}).catch((err) => {
			setAlert({
				show: true,
				message: err.description
					? err.description
					: 'Ha ocurrido un error al obtener los datos, intente nuevamente',
				severity: 'error',
			});
		});
	};

	const handleSubmit = () => {
		if (files.length === 0) {
			setAlert({
				show: true,
				severity: 'error',
				message: 'Debe seleccionar todos los archivo.',
			});
			return;
		}

		let missingFiles: number = 0;

		files.forEach((_file) => {
			if (_file.file.size === 0) {
				missingFiles += 1;
			}
		});

		if (missingFiles !== 0) {
			setAlert({
				show: true,
				severity: 'error',
				message: 'Debe seleccionar todos los archivo.',
			});
			return;
		}
		handleAddFiles();
	};

	const resumableFile = new Resumable({
		//REACT_APP_API_URL_OPENTEXT = https://10.85.6.12:9444
		target: `${process.env.REACT_APP_API_URL_OPENTEXT}/CartasProduccion-web/upload`,
		chunkSize: 1 * 1024 * 1024,
		simultaneousUploads: 4,
		testChunks: false,
		testMethod: 'POST',
		uploadMethod: 'POST',
		method: 'octet',

	});
	resumableFile.on('fileAdded', function () {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		resumableFile.upload();
	});
	resumableFile.on('progress', function () {
		setLoading(true);
	});
	resumableFile.on('fileSuccess', function (file: any, message: string) {
		setLoading(true);
		setTimeout(() => {
			handleAttachCase(file);
		}, 1000);

	});
	resumableFile.on('fileError', function (file, message) {
		setLoading(false);
		setAlert({
			show: true,
			message:
				'Por el momento el servicio no se encuentra disponible, intenta más tarde',
			severity: 'error',
		});
	});
	const handleAddFiles = async () => {
		files.forEach(async (item) => {
			resumableFile.addFile(item.file.data);
		});
	};
	const style = useStyles();

	return (
		<div>
			<ModalLoading loading={loading}></ModalLoading>
			<div className={style.form}>
				<Grid
					container
					columns={12}
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Grid
						item
						xs={6}
						sm={6}
						md={6}
						lg={6}
						style={{ textAlign: 'left' }}
					>
						<b>Número de caso</b>
					</Grid>
					<Grid
						item
						xs={6}
						sm={6}
						md={6}
						lg={6}
						style={{ textAlign: 'right' }}
					>
						{data?.noCaso}
					</Grid>
				</Grid>
				<hr
					style={{
						opacity: 0.2,
						marginTop: 20,
						marginBottom: 20,
					}}
				/>

				<p style={{ textAlign: matches ? 'center' : 'initial' }}>
					<b>
						Estos son los documentos que debes adjuntar.
					</b>
				</p>

				{files.map((item: IFileItem, index) => (
					<RowUploadFile
						label={item.label}
						subtitle={item.subtitle ? item.subtitle : false}
						file={item.file}
						setFile={(e: IFile) => {
							let values: IFileItem[] = [];
							for (let index = 0; index < files.length; index++) {
								if (item.label === files[index].label && files[index].clave === item.clave) {
									let nameArray = e.name.split('.')
									let nameFile = index === 0 ? 'IDENTIFICACIONOFICIA' : 'DOCUMENTOOBLIGA'
									let newDoc: IFileItem = {
										...item,
										label: item.label,
										clave: item.clave,
										file: {
											name: e.size > 0 ? 'VU_' + data?.noCaso + '_' + nameFile + '_' + item.clave + '.' + nameArray[nameArray.length - 1] : e.name,
											data: new File([e.data], `${e.size > 0 ? 'VU_' + data?.noCaso + '_' + nameFile + '_' + item.clave + '.' + nameArray[nameArray.length - 1] : e.name}`, {
												type: e.type,
											}),
											type: e.type,
											size: e.size,
										},
									};
									values.push(newDoc);
								} else {
									let newDoc: IFileItem = {
										...files[index],
										label: files[index].label,
										clave: files[index].clave,
										file: {
											name: files[index].file.name,
											data: files[index].file.data,
											type: files[index].file.type,
											size: files[index].file.size,
										},
									};
									values.push(newDoc);
								}
							}
							setFiles(values);
						}}
						formats={'.jpg,.jpeg,.png,.tiff,.pdf'}
						setAlert={setAlert}
						separatorDown={true}
						key={'RowUploadFile_' + index}
						subItems={files[index].listDoctos}
					></RowUploadFile>
				))}

				<div className={style.message}>
					<span>
						Antes de enviar tus documentos verifica que estén
						correctos. <br /> Los formatos permitidos son jpg, png,
						tiff, pdf con un tamaño máximo de 20 MB por todos los
						documentos adjuntos.
					</span>
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<div style={{ width: '50%' }}>
					<CustomAlert
						data-testid="mensageAlertthree"
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
						onClose={() => {
							setAlert({
								show: false,
								message: '',
								severity: 'success',
							});
						}}
					/>
				</div>
			</div>

			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divButtons}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={() => {
								setPage(0)
							}}
							variant="outlined"
							styles={{
								width: '100%',
								height: '100%'
							}}
							label="Cancelar"
						/>
					</div>

					<div className={style.Buttons}>
						<CustomButton
							onClick={handleSubmit}
							variant="solid"
							styles={{
								width: '100%',
							}}
							label="Continuar"
							disabled={files[0]?.file?.size === 0 || files[1]?.file?.size === 0}
						/>
					</div>
				</div>
			</div>
			<hr
				style={{
					opacity: 0.2,
					marginTop: 20,
					marginBottom: 20,
				}}
			/>
			<h4 style={{ color: '#293990', lineHeight: '0px' }}>
				* Documentos obligatorios
			</h4>
		</div>
	);
};

export default UploadFiles;
