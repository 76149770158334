import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Layout from '../../components/Layout';
import { HeaderList } from '../../components/HeaderList';
import ElectroNotifyDenegad from './ElectroNotifyDenegad';
import ElectroNotifyConfirm from './ElectroNotifyConfirm';
import { apiElectroNotify } from '../../api/modules/ElectroNotify';
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';

const ElectroNotify = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [page, setPage] = useState(0);
	const [loagin, setLoagin] = useState(false);
	const [pdf, setPdf] = useState(null);
	const [error, setError] = useState('');

	useEffect(() => {
		const req = async () => {
			try {
				setLoagin(true);
				const { result } = await apiElectroNotify.getValid();
				if (result?.code && result?.code === '0000') setPage(1);
				if (!result.document) setError(result?.description || '');
				setPdf(result?.document || '');
			} catch (error) {
				setPage(3);
			} finally {
				setLoagin(false);
			}
		};
		req();
	}, [credit, user?.nss]);

	return (
		<Layout>
			<div>
				<ModalLoading loading={loagin} />
				<HeaderList
					title="Confirmación de trámite de crédito"
					list={[
						'Consulta y descarga el acuse que se generó como resultado de la confirmación que realizó tu patrón de enterado respecto a tu trámite de crédito.',
					]}
				/>
				{page === 0 && <ElectroNotifyDenegad />}
				{page === 1 && <ElectroNotifyConfirm pdf={pdf} error={error} />}
				{page === 3 && (
					<CustomAlert
						message="Por el momento el servicio no está disponible, intenta más tarde."
						severity="error"
						show={true}
					/>
				)}
			</div>
		</Layout>
	);
};

export default ElectroNotify;
