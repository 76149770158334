import { CheckCircleOutlined } from '@mui/icons-material';
import { Card, Box, Divider } from '@mui/material';
import logoMejorasi from '../../../../../assets/img/productsMIUC/MejOraSi.png'

import { RoundBtn } from '../../../components/RoundBtn/roundBtn';

import { useStyles } from "../styles/styles";

import { useController } from '../../../hooks/useController';

const Home = () => {
	const { returnStep, addSelection, saveData, nextStep } = useController();
	const classes = useStyles();
	const nextProcess = async (credit: string) => {
		addSelection({
			title: 'Crédito seleccionado para:',
			description: 'mejOraSí',
		});
		saveData({
			processInfo: {
				credit,
			},
			processData: {
				active: 1,
				total: 3
			},
		});
		nextStep();
	};

	return (
		<>
			<div className={classes.content}>
				<div>
          <img src={logoMejorasi} className={classes.logo} />
				</div>
				<p className={classes.text}>
					Esta alternativa de financiamiento es para ti,
					derechohabiente que no tienes relación laboral, que no has
					ejercido un crédito hipotecario con Infonavit, pero que
					tienes ahorro en tu Subcuenta de Vivienda y estás registrado
					en una Afore.
				</p>
				<p className={classes.text}>
					<b>
						Con este crédito puedes mejorar o remodelar tu vivienda
						sin afectar su estructura.
					</b>
					<br />
					Tu Subcuenta de Vivienda quedará como garantía del crédito.
					<br />
					<br />
					<b>{/*Elige una de las siguientes opciones*/'Esta es la opción que tenemos para ti:'}</b>
				</p>
				<div className={classes.selectionCreditCardsContainer}>
					<div>
						<Card className={classes.card}>
							<div className={classes.cardClass}>
								<h4 className={classes.titleBlue}>
									Modalidad sin interés
								</h4>
								<p>
									Crédito de hasta el 90% del SSCV (Saldo de
									la Subcuenta de Vivienda).
								</p>
								<p>Plazo a 5 años.</p>
								<p>Mensualidades sin intereses.</p>
							</div>
						</Card>
					</div>
					{/*
						<div>
							<Card className={classes.card}>
								<div className={classes.cardClass}>
									<h4 className={classes.titleBlue}>
										Modalidad con interés
									</h4>
									<p>
										Crédito de hasta el 115% del SSCV (Saldo de
										la Subcuenta de Vivienda).
									</p>
									<p>Plazo a 5 años.</p>
									<p>Tasa de interés fija del 4%.</p>
								</div>
							</Card>
						</div>
					*/}
				</div>
				<small>
					<b>
						Monto mínimo de crédito $4,856.70 y máximo $139,109.71
					</b>
				</small>
				<p className={classes.margin}>
					Para conocer el monto de crédito al que puedes acceder debes:
				</p>
				<div className={classes.containerCheckCircle}>
					<div>
						<CheckCircleOutlined className={classes.checkCircle} color="info" />{' '}
						<span>
							Autorizar la consulta a tu historial crediticio,
							ingresa los datos para realizarla.
						</span>
					</div>
					<div>
						<CheckCircleOutlined className={classes.checkCircle} color="info" />{' '}
						<span>
							Responder por única ocasión, un cuestionario
							socioeconómico.
						</span>
					</div>
				</div>
				<Divider />
				<Box className={classes.footer}>
					<RoundBtn variant="outlined" onClick={() => returnStep()}>Regresar</RoundBtn>
					<RoundBtn variant="contained" onClick={() => nextProcess('MD')}>Continuar</RoundBtn>
				</Box>
			</div>
		</>
	)
}

export default Home;
