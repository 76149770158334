import { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useStyles } from '../../NipInfonatel/styles';

import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CustomButtom from '../../../components/Button';

import { IAlert } from '../../../interfaces/alert';

import { nipInfonatelAPI } from '../../../api/modules/NipInfonatel';
import ModalLoading from '../../../components/ModalLoading';
import CustomAlert from '../../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';

interface IPersonalData {
	onContinue: () => void;
	isEmail: boolean;
	nip: string;
}

const CancellationConfirmation = (props: IPersonalData) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});
	const { onContinue, isEmail } = props;
	const navigate = useNavigate();
	const style = useStyles();

	const sendCode = async () => {
		try {
			setLoading(true);
			setAlert({
				...alert,
				show: false,
			});

			if (isEmail) {
				const { result } = await nipInfonatelAPI.sendEmail();
				if (
					result.description ===
					'Se ha enviado correctamente el correo'
				) {
					onContinue();
				} else {
					setAlert({
						show: true,
						message: result.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				}
			} else {
				const { result } = await nipInfonatelAPI.sendSMS();
				if (result.description === 'Mensaje enviado con exito') {
					onContinue();
				} else {
					setAlert({
						show: true,
						message: result.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				}
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<br />
			<br />
			<Box>
				<span className={style.VerificacionCodigoTitle}>
					<h1>Confirmación de cancelación de NIP</h1>
				</span>
			</Box>
			<Box
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
					marginBottom: '35px',
				}}
			>
				<span className={style.VerificacionText}>
					¿Estás seguro que quieres cancelar tu NIP Infonatel?
					<br />
					Si lo cancelas no podrás acceder a los servicios telefónicos de Infonatel.
				</span>
			</Box>
			<Box className={style.lookConfirmationAlert}>
				<div style={{ width: '100%' }}>
					<CustomAlert
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
					/>
				</div>
			</Box>
			<Box className={style.containerButtonsEnd}>
				<Box margin={'20px 10px'} width={matches ? '80%' : 250}>
					<CustomButtom
						label={'No'}
						onClick={() => navigate('/')}
						variant="outlined"
					/>
				</Box>
				<Box margin={'20px 10px'} width={matches ? '80%' : 250}>
					<CustomButtom
						label={'Sí'}
						onClick={() => sendCode()}
						variant="solid"
					/>
				</Box>
			</Box>
		</>
	);
};

export default CancellationConfirmation;
