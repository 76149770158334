import * as React from 'react';
import { useStyles } from './styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

type IProps = {
	id?: string;
	title: string;
	link: string;
	icon?: any;
	style?: React.CSSProperties;
	iconStyle: React.CSSProperties;
	Click?: () => void;
};

const ExpandButton = ({ id, title, link, icon, style, iconStyle, Click }: IProps) => {
	const classes = useStyles();

	return (
		<Box sx={style}>
			<Link
				id={id}
				underline="none"
				onClick={() => (Click ? Click() : () => {})}
				className={classes.button}
			>
				{icon && (
					<img
						id={id}
						src={icon}
						alt={title}
						className={classes.icon}
						style={iconStyle}
					/>
				)}
				<h5 id={id} className={classes.title}>
					{title}
				</h5>
			</Link>
		</Box>
	);
};

export default ExpandButton;
