import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Divider, Paper } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useStyles } from '../styles';
import { HeaderList } from '../../../components/HeaderList';
import ModalLoading from '../../../components/ModalLoading';
import CustomAlert from '../../../components/CustomAlert';
import CustomButton from '../../../components/Button';
import SuggestionForm from '../views/SuggestionForm';
import Summary from '../views/Summary';
import { apiFollowComplaint } from '../../../api/modules/followComplaint';
import { encrypt } from '../../../utils/encrypt';
import { RootState } from '../../../store';
import { defaultResponse, pattern } from '../utils';
import { keyAPI } from '../../../api/modules/key';
import { useBitacora } from '../../../hooks/useBitacora';

const Suggestions = ({ setSelectedType }: any) => {
	const { regBitacoraNotOld } = useBitacora();
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const navigation = useNavigate();
	const [step, setStep] = useState(0);
	const [sugg, setSugg] = useState<string>('');
	const [loader, setLoader] = useState(false);
	const [alert, setAlert] = useState('');
	const [resp, setResp] = useState(defaultResponse);

	const createSuggestion = async () => {
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const data = {
				nss: user?.nss || '',
				tipificacion: 'A1ZNQ00006Z002',
				mensaje: sugg,
				claseOperacion: 'ZAPO',
			};
			const dataEncripted = await encrypt(JSON.stringify(data) || '', key);
			const result = await apiFollowComplaint.crearCaso(dataEncripted);
			if (result.code && result.code === '00000') {
				const data = {
					nss: user?.nss || '',
					estatus: 'B',
					claseOperacion: 'ZQUE',
					numeroOrden: result?.data?.orderServicio || '',
				};
				const dataEncripted = await encrypt(JSON.stringify(data) || '', key);
				const suggestion = await apiFollowComplaint.getMyComplaints(
					dataEncripted
				);
				if (suggestion.code && suggestion.code === '0000') {
					regBitacoraNotOld(apiFollowComplaint.schemeUrl, data);
					setAlert('');
					setStep(step + 1);
					setResp({
						...suggestion.data.casos[0],
					});
				}
			}
		} catch (error: any) {
			setAlert(
				error?.message ||
				'Por el momento el servicio no se encuentra disponible, intente más tarde.'
			);
		} finally {
			setLoader(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loader} />
			<HeaderList
				title="Haz y sigue una queja"
				list={
					step === 0
						? [
							'Escribe el motivo de tu felicitación, sugerencia o petición de la manera más clara posible.',
							'En caso de que necesitemos datos adicionales, nos comunicaremos contigo por medio de correo electrónico o vía telefónica.',
						]
						: [
							'Para conocer la respuesta o estatus de tu queja, ingresa a Mi cuenta Infonavit al apartado Consultar mis quejas, o bien comunicate al Infonatel al 5591715050 en la Ciudad de México, o al 800 008 3900 desde cualquier parte del país o ingresa al correo electrónico que nos proporcionaste.',
						]
				}
			/>
			<Paper className={classes.mainContainer}>
				{step === 0 && <SuggestionForm sugg={sugg} setSugg={setSugg} />}
				{step === 1 && <Summary response={resp} />}
				{Boolean(alert) && (
					<CustomAlert message={alert} severity="error" show />
				)}
				<div className="actions-container">
					{step !== 1 ? (
						<>
							<CustomButton
								onClick={() => setSelectedType(0)}
								variant="outlined"
								label={'Regresar'}
							/>
							<CustomButton
								onClick={createSuggestion}
								variant="solid"
								label="Continuar"
								disabled={!sugg || !pattern.test(sugg)}
							/>
						</>
					) : (
						<CustomButton
							onClick={() => navigation('/')}
							variant="solid"
							label="Finalizar"
						/>
					)}
				</div>
				{step === 0 && (
					<>
						<Divider style={{ marginTop: 15 }} />
						<h4 style={{ color: blue[900] }}>
							*Datos obligatorios
						</h4>
					</>
				)}
			</Paper>
		</>
	);
};

export default Suggestions;
