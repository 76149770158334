import { Box, Divider, Paper } from '@mui/material';
import { Banner } from '../Banner/banner';

//Components
import { IProductCard, ProductCard } from '../ProductCard/productCard';
//Dependencies
import pigIcon from '../../../../assets/svg/apoyoinfonavit.svg';
import casitaIcon from '../../../../assets/svg/casita.svg';
import ampliarIcon from '../../../../assets/img/productsMIUC/ampliar.png';
import { useStyles } from './styles';
import { mappedCredits } from '../../utils/selectionCredit.utils';
import Header from '../Header';
import { useGeneralStyles } from '../../views/styles/general.styles';
import { RoundBtn } from '../RoundBtn/roundBtn';
import { MIUC_PRODUCTS, PRODUCT_LINES } from '../../constants/products';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useController } from '../../hooks/useController';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

interface IProps {
	onSelect: (product: string) => void;
}

const MESSAGE_CODES = {
	IMPOSIBLE_CALCULAR: '9999',
	CALCULO_ESTIMADO: '8888',
	CALCULO_PRECISO: '20236',
};

export const NoPoints = ({ onSelect }: IProps) => {
	const navigate = useNavigate();
	const classes = useStyles();
	const generalStyle = useGeneralStyles();
	const { dataProcessController } = useController();
	const { user } = useSelector((state: RootState) => state.session);

	const currentLine = dataProcessController.processData.productLine;

	const hideAmpliar = [
		PRODUCT_LINES.COMPRAR_VIVIENDA,
		PRODUCT_LINES.PAGAR_HIPOTECA,
	].includes(currentLine);

	const message1 = useMemo(() => {
		return (
			!Number(dataProcessController.titular?.puntosTotales || 0) &&
			!dataProcessController.titular?.anioPara116Puntos &&
			!dataProcessController.titular?.mesPara116Puntos
		);
	}, [dataProcessController.titular]);

	const message2 = useMemo(() => {
		return (
			Number(dataProcessController.titular?.puntosTotales || 0) &&
			dataProcessController.titular?.anioPara116Puntos &&
			dataProcessController.titular?.mesPara116Puntos &&
			dataProcessController.titular.anioPara116Puntos !==
				MESSAGE_CODES.IMPOSIBLE_CALCULAR &&
			dataProcessController.titular.anioPara116Puntos !==
				MESSAGE_CODES.CALCULO_ESTIMADO &&
			dataProcessController.titular.puntosFaltantesPara116 !==
				MESSAGE_CODES.CALCULO_PRECISO
		);
	}, [dataProcessController.titular]);

	const bimestreAnio = dataProcessController.titular?.bimestreTope
		? dataProcessController.titular?.bimestreTope.substring(0, 4)
		: 'xxxx';
	const bimestre = dataProcessController.titular?.bimestreTope
		? dataProcessController.titular?.bimestreTope.substring(5, 6)
		: 'x';

	const message = message1 ? (
		<p>
			Aún no cumples con los criterios mínimos para acceder a un crédito,
			consulta estas alternativas:
		</p>
	) : message2 ? (
		<p>
			<strong style={{ color: '#293990' }}>
				Hoy tienes {dataProcessController.titular?.puntosTotales || 0}{' '}
				puntos
			</strong>
			, aún no cumples con el mínimo requerido (1080) para poder solicitar
			tu crédito. Si mantienen tú y tu patrón sus condiciones actuales,{' '}
			<strong style={{ color: '#293990' }}>
				estimamos que podrías solicitar tu crédito en{' '}
				{(
					dataProcessController.titular?.nombreMesPara116 || ''
				).toLowerCase()}{' '}
				de{' '}
				{dataProcessController.titular?.anioPara116Puntos ||
					new Date().getFullYear().toString()}
			</strong>
			, puedes optar por alguna de estas opciones
		</p>
	) : dataProcessController.titular?.puntosFaltantesPara116 ===
	  MESSAGE_CODES.CALCULO_PRECISO ? (
		<p>
			No es posible determinar cuándo podrías alcanzar la puntuación con
			la información que tenemos al corte del{' '}
			<strong>
				bimestre {bimestre} de {bimestreAnio}
			</strong>
			. Te sugerimos consultar en{' '}
			{(
				dataProcessController.titular?.nombreMesPara116 || ''
			).toLowerCase()}{' '}
			de{' '}
			{dataProcessController.titular?.anioPara116Puntos ||
				new Date().getFullYear().toString()}
			.
		</p>
	) : dataProcessController.titular?.anioPara116Puntos ===
	  MESSAGE_CODES.CALCULO_ESTIMADO ? (
		<p>
			No es posible determinar cuándo podrías alcanzar la puntuación con
			la información que tenemos al corte del{' '}
			<strong>
				bimestre {bimestre} de {bimestreAnio}
			</strong>
			. Te sugerimos consultar en tres o cuatro meses
		</p>
	) : dataProcessController.titular?.anioPara116Puntos ===
	  MESSAGE_CODES.IMPOSIBLE_CALCULAR ? (
		<p>
			No es posible calcular tu puntuación, aún no se actualiza la
			información necesaria para evaluarte. Te sugerimos consultar
			nuevamente en un periodo de 3 a 4 meses.
		</p>
	) : (
		<></>
	);

	const noPointsDescription =
		'Comprar vivienda nueva o usada, construir en terreno propio, mejorar tu vivienda y pagar hipoteca';

	return (
		<Paper className={classes.container}>
			<Header
				title={`¡Bienvenid${user?.gender === 'H' ? 'o' : 'a'} ${user?.nombre || user?.nombres}!`}
			/>
			<Box className={classes.information}>{message}</Box>
			<Box className={generalStyle.content}>
				<Box>
					<h4>
						Las opciones con las que cuentas en este momento son:
					</h4>
				</Box>
				<Box className={classes.options}>
					<Banner
						size="medium"
						icon={casitaIcon}
						text="Ahorrar para solicitar tu crédito"
						onClick={() => onSelect(MIUC_PRODUCTS.CREDITO_SEGURO)}
					/>
					<Banner
						size="medium"
						icon={pigIcon}
						text="Usa tu ahorro como garantia"
						onClick={() => onSelect(MIUC_PRODUCTS.APOYO_INFONAVIT)}
					/>
					{!hideAmpliar && (
						<Banner
							size="medium"
							icon={ampliarIcon}
							text="Ampliar o reparar"
							onClick={() => onSelect(MIUC_PRODUCTS.MEJORAVIT)}
						/>
					)}
				</Box>
				<Divider sx={{ marginY: 4 }} />
				<Box>
					<h4>Esto es lo que podrás hacer con tus puntos</h4>
				</Box>
				<Box className={classes.products}>
					{mappedCredits[currentLine].credits.map(
						(credit: IProductCard) => (
							<ProductCard
								key={credit.product}
								product={credit.product}
								icon={credit.icon}
								title={''}
								description={noPointsDescription}
								disabled
							/>
						)
					)}
				</Box>
				<Divider sx={{ marginTop: '32px' }} />
				<div className={generalStyle.bottonsContainer}>
					<RoundBtn variant="contained" onClick={() => navigate(-1)}>
						Regresar
					</RoundBtn>
				</div>
			</Box>
		</Paper>
	);
};
