// RESOURCES
import { Box } from '@mui/material';
// COMPONENTS
import CustomButton from '../../../components/Button';
// ASSETS
import phone from '../../../assets/img/telefono.png';
import { useStyles } from './styles';

interface Props {
	setPage: (data: number) => void;
	request: {
		datetime_creation: string;
		fechaCreacionTDS: string;
		idDispositivo: string;
	};
}

const InfoDevice = ({ setPage, request }: Props) => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.container}>
				<div className={classes.containerMin}>
					<div className={classes.containerImage}>
						<img
							className={classes.phoneImage}
							src={phone}
							alt="check-blue"
						/>
					</div>
					<div className={classes.containerInfo}>
						<p className={classes.txtContainer}>
							<b>ID de Dispositivo</b>
						</p>
						<p className={classes.normalText}>
							{request.idDispositivo}
						</p>
						{/* <p className={classes.txtContainer}>
							<b>Fecha de vinculación</b>
						</p>
						<p className={classes.normalText}>
							{request.fechaCreacionTDS || request.datetime_creation}
						</p> */}
					</div>
				</div>

				<Box className={classes.containerButtons}>
					<div className={classes.custombuttom}>
						<CustomButton
							label={'Regresar'}
							onClick={() => setPage(0)}
							styles={{ width: '200px', height: '100%' }}
							variant="outlined"
						/>
					</div>
					<div className={classes.custombuttom}>
						<CustomButton
							onClick={() => setPage(2)}
							styles={{ width: '200px', height: '100%' }}
							variant="solid"
							label="Desvincular"
						/>
					</div>
				</Box>
			</div>
		</>
	);
};

export default InfoDevice;
