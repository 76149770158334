import { Paper, Divider, Collapse, Box, Fade } from '@mui/material';
import verMas from '../../../assets/svg/verMas.svg';
import verMenos from '../../../assets/svg/verMenos.svg';
import { useStyles } from './styles';
import { useFollowStepsCard } from './useFollowStepsCard';
import { grey } from '@mui/material/colors';

const FollowStepsCards = () => {
	const { productSteps, viewMoreStates, handleViewMore, handleViewLess } =
		useFollowStepsCard();
	const classes = useStyles();

	return (
		<>
			{productSteps &&
				productSteps.map((data: any, index: number) => {
					const PaperIcon = data.paperIcon;
					return (
						<Paper
							elevation={1}
							className={
								data.paperType === 'blue'
									? classes.stepPaperBlue
									: classes.stepPaper
							}
							key={index}
						>
							<div className={classes.paperLayout}>
								<div>
									{typeof data.paperIcon === 'string' ? (
										<img
											src={data.paperIcon}
											className={classes.icoTemas}
											alt={data.paperIcon}
										/>
									) : (
										<Box
											sx={{
												background: grey[100],
												borderRadius: 100,
												margin: '20px',
												padding: '8px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												'&> .MuiSvgIcon-root': {
													fontSize: '50px',
												},
											}}
										>
											<PaperIcon
												color="info"
												fontSize="large"
											/>
										</Box>
									)}
								</div>
								<div className={classes.stepText}>
									<span className={classes.title}>
										{data.title}
									</span>
									<span className={classes.description}>
										{data.description}
									</span>
									<div className={classes.enlaces}>
										{data.link}
									</div>
									{data.paperType === 'viewMore' && (
										<Fade
											in={!viewMoreStates[index]}
											timeout={1000}
										>
											<div className={classes.enlaces}>
												<button
													className={
														classes.viewMoreButton
													}
													onClick={() =>
														handleViewMore(index)
													}
												>
													{data.viewMoreButton}
													<img
														src={verMas}
														className={
															classes.icoView
														}
														alt="Ver más"
													/>
												</button>
												{data.viewMoreLink}
											</div>
										</Fade>
									)}
								</div>
							</div>
							{data.paperType === 'viewMore' && (
								<Collapse
									in={viewMoreStates[index]}
									timeout={1000}
								>
									<Divider
										flexItem
										orientation="horizontal"
										className={classes.division}
									/>
									<div
										id="viewMore"
										className={classes.viewMore}
									>
										{data.viewMoreContent}
									</div>
									<Divider
										flexItem
										orientation="horizontal"
										className={classes.division}
									/>
									<div className={classes.enlaces}>
										<button
											className={classes.viewLessButton}
											onClick={() =>
												handleViewLess(index)
											}
										>
											Ver menos{' '}
											<img
												src={verMenos}
												className={classes.icoView}
												alt="Ver menos"
											/>
										</button>
										{data.viewMoreLink}
									</div>
								</Collapse>
							)}
						</Paper>
					);
				})}
		</>
	);
};

export default FollowStepsCards;
