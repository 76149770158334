import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Paper, useMediaQuery, Radio } from '@mui/material';
import CustomButton from '../../../components/Button';
import ErrorIcon from '@mui/icons-material/Error';

interface Props {
	setPage: (arg: number) => void;
}

const HowPaymentMade = ({ setPage }: Props) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [howPay, setHowPay] = useState('');

	return (
		<Paper style={{ marginTop: 30, padding: matches ? '10px 20px' : '10px 50px' }}>
			<h2 style={{ color: '#293990', textAlign: 'center' }}>
				¿Cómo fue realizado tu pago?
			</h2>
			<p style={{ fontWeight: 700 }}>Elige una de las opciones</p>

			<div style={{ padding: matches ? 0 : '0px 50px' }}>
				<div style={{ display: 'flex', alignItems: 'baseline' }}>
					<div>
						<Radio
							checked={howPay === 'mipago'}
							onChange={(e) => setHowPay(e.target.value)}
							value="mipago"
						/>
					</div>
					<span>Realicé mi pago</span>
				</div>
				<div style={{ display: 'flex', alignItems: 'baseline' }}>
					<div>
						<Radio
							checked={howPay === 'mipatron'}
							onChange={(e) => setHowPay(e.target.value)}
							value="mipatron"
						/>
					</div>
					<span>Mi patrón realizó el pago</span>
				</div>
				<div
					style={{ marginBottom: 30, display: 'flex', alignItems: 'baseline' }}
				>
					<div>
						<Radio
							checked={howPay === 'pagopasivo'}
							onChange={(e) => setHowPay(e.target.value)}
							value="pagopasivo"
						/>
					</div>
					<div>
						<span>Pago a pasivo</span>
						<p
							style={{
								margin: 0,
								fontSize: 14,
								color: '#293990',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<ErrorIcon
								style={{
									margin: 0,
									padding: 0,
									color: '#29398E',
									fontSize: 18,
									marginRight: 4,
								}}
							/>
							Vendí mi casa y se liquidó con otro crédito Infonavit
						</p>
					</div>
				</div>
			</div>

			<h3 style={{ textAlign: 'center' }}>
				Para hacer una aclaración debes esperar 10 días hábiles después de haber
				realizado tu pago
			</h3>

			<div
				style={{
					display: 'flex',
					flexDirection: matches ? 'column-reverse' : 'initial',
					justifyContent: 'center',
					margin: '40px 0px',
				}}
			>
				<CustomButton
					styles={{
						width: matches ? 'auto' : 250,
						margin: '10px 10px',
						display: 'inline-flex',
					}}
					label={'Regresar'}
					variant="outlined"
					onClick={() => setPage(0)}
				/>

				<CustomButton
					disabled={howPay !== '' ? false : true}
					styles={{
						width: matches ? 'auto' : 250,
						margin: '10px 10px',
						display: 'inline-flex',
					}}
					label={'Continuar'}
					variant="solid"
					onClick={() => setPage(howPay === 'mipatron' ? 3 : 2)}
				/>
			</div>
		</Paper>
	);
};

export default HowPaymentMade;
