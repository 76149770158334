import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, Link } from '@mui/material';
import RegisterModal from '../../../../../CreditRequest/components/RegisterModal';
import FinishedRegisterModal from '../FinishedRegisterModal';
import { INTERNAL_MESSAGES } from '../../../../../../config/messageCatalog';
import { RoundBtn } from '../../../../../AssociateNssCredit/Styles';
import { useStyles } from '../../styles';
import { registerList } from '../../utils';
import CustomAlert from '../../../../../../components/CustomAlert';
import { IAlert } from '../../../../../../interfaces/alert';
import { encodeAllJSONData } from '../../../../../../utils/encrypt';
import { RootState } from '../../../../../../store';
import iconoPDF from '../../../../../../assets/img/icono-descarga.png';
import ModalLoading from '../../../../../../components/ModalLoading';
import {
	SaldoMCIResponseModel,
	apiMIUC,
	IDataconsultarAgroconsultaRes,
	IAgroindustryDatosGenerales,
} from '../../../../../../api/modules/MIUC';

interface props {
	handlerConfirmRegister: () => void;
	handlerBack: (value: string) => void;
	handlerFinish: () => void;
	flag_finishedModal?: boolean | undefined;
	handlerAlert: IAlert;
	agroData: IDataconsultarAgroconsultaRes;
	datosGenerales: IAgroindustryDatosGenerales;
	balanceData: SaldoMCIResponseModel;
}

const Register = ({
	handlerConfirmRegister,
	flag_finishedModal,
	handlerAlert,
	handlerBack,
	handlerFinish,
	agroData,
	datosGenerales,
	balanceData,
}: props) => {
	const styles = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const [auth, setAuth] = useState(false);
	const [registerModal, setRegisterModal] = useState(false);
	const [finishedModal, setFinishedModal] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const onConfirmRegister = () => {
		setRegisterModal(false);
		handlerConfirmRegister();
	};

	useEffect(() => {
		setFinishedModal(
			flag_finishedModal === undefined ? false : flag_finishedModal
		);
	}, [flag_finishedModal]);

	useEffect(() => {
		setAlert(handlerAlert);
	}, [handlerAlert]);

	const getFullName = (): string => {
		let nombre: string = '';
		if (
			balanceData.nombre &&
			balanceData.apellidoPaterno &&
			balanceData.apellidoMaterno
		) {
			nombre = `${balanceData.nombre} ${balanceData.apellidoPaterno} ${balanceData.apellidoMaterno}`;
		} else {
			nombre = `${user?.nombres} ${user?.apPaterno} ${user?.apMaterno}`;
		}
		return nombre;
	};

	const getPdfRegistro = async () => {
		try {
			setLoading(true);
			const dataPdfRegistro = {
				pdfParamFeInsc: agroData.fechaInscripcion,
				pdfParamNombre: getFullName(),
				pdfParamNss: user?.nss.toString(),
				pdfParamAhoMen: datosGenerales.ahorroMensual,
				paramTotalDepositos: datosGenerales.saldoAhorro,
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(dataPdfRegistro) },
				user?.k || ''
			);
			const { data } = await apiMIUC.getRegistroPDF(dataEncripted);
			const { pdf } = data.result;
			if (pdf) {
				setAlert({
					message: '',
					severity: 'success',
					show: false,
				});
				let link = document.createElement('a');
				link.download = 'condiciones_registro.pdf';
				link.href = 'data:application/octet-stream;base64,' + pdf;
				link.click();
			} else {
				setAlert({
					message: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'warning',
					show: true,
				});
			}
		} catch (error) {
			setAlert({
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'warning',
				show: true,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			{registerList.map((reg, index) => (
				<p key={index} className={styles.listStyle}>
					{reg}
				</p>
			))}
			<div className={styles.alignLeft}>
				<div>
					<label className={styles.registerSubtitle}>
						Registro en el Programa
					</label>
				</div>
				<p className={styles.listStyle}>
					Para dar inicio con tu programa de ahorro es necesario que
					te registres, y a partir de que lo hagas quedará establecido
					tu compromiso de cumplir con al menos 12 meses
					<br />
					<br />
					<b>
						Antes de registrarte, descarga y conoce las Condiciones
						previas a la contratación del crédito para trabajadores
						de la Agroindustria.
					</b>
				</p>
			</div>
			<br />

			<Link
				underline="none"
				className={styles.registerLink}
				onClick={() => getPdfRegistro()}
			>
				<span>Descargar carta</span>
				<img
					src={iconoPDF}
					alt="iconoPDF"
					className={styles.downloadPdf}
				/>
			</Link>

			<FormControlLabel
				label="He leído y conozco las condiciones"
				control={
					<Checkbox
						value={auth}
						onChange={(e) => setAuth(e.target.checked)}
					/>
				}
			/>
			<CustomAlert
				message={alert.message}
				severity={alert.severity}
				show={alert.show}
				onClose={() => {
					setAlert({
						show: false,
						message: '',
						severity: alert.severity,
					});
				}}
			/>
			<div className="credit-request-actions-container">
				<RoundBtn
					variant="outlined"
					onClick={() => handlerBack('INFOAGROINDUSTRIA')}
				>
					Regresar
				</RoundBtn>
				<RoundBtn
					variant="contained"
					onClick={() => setRegisterModal(true)}
					disabled={!auth}
				>
					Registrarme
				</RoundBtn>
			</div>
			<RegisterModal
				open={registerModal}
				setOpen={setRegisterModal}
				onConfirm={onConfirmRegister}
			/>
			<FinishedRegisterModal
				balanceData={balanceData}
				datosGenerales={datosGenerales}
				open={finishedModal}
				setOpen={(e: boolean) => {
					setFinishedModal(e);
					handlerFinish();
				}}
			/>
		</>
	);
};

export default Register;
