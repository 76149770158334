// Dependencies
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Material Components
import {
	TableRow,
	TableCell,
	Collapse,
	IconButton,
	Table,
	TableHead,
	TableBody,
	TableContainer,
	Box,
	Link,
	Paper,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

// Resources
import { contributionsAPI } from '../../api/modules/contribution';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { downloadPDF } from '../../utils/downloadPDF';
import { keyAPI } from '../../api/modules/key';
import { formatDate } from '../../utils/dates';
import { encrypt } from '../../utils/encrypt';
import { RootState } from '../../store';
import { useStyles } from './styles';
import { currencyFormat } from '../../utils/expressions';
import { IAlert } from '../../interfaces/alert';

// Assets
import descargaicon from '../../assets/img/icono-descarga.png';

// Components
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';

function Row(props: { row: ReturnType<any> }) {
	const styles = useStyles();
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const dt = row.fecha_solicitud.split('/');
	const dtSol = dt[2] + '/' + dt[1] + '/' + dt[0];

	return (
		<Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell>
					<IconButton
						data-testid="buttonExpandRow"
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					{row.numero_caso}
				</TableCell>
				<TableCell align="left">{dtSol}</TableCell>
				<TableCell
					align="left"
					style={{ color: green['A700'], fontWeight: 'bold' }}
				>
					{row.status}
				</TableCell>
				<TableCell align="left">{formatDate('P')}</TableCell>
				<TableCell align="left" style={{ color: red[400] }}>
					{row.pdf && (
						<Link
							underline="hover"
							onClick={() =>
								downloadPDF({
									payload: row.pdf,
									name: `Aportacion-${formatDate('P')}`,
								})
							}
						>
							<img
								className={styles.iconsImg}
								src={descargaicon}
								alt="donwload"
							></img>
							<span className={styles.txtDescargas}>Descargar</span>
						</Link>
					)}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={{
						paddingBottom: 0,
						paddingTop: 0,
						backgroundColor: '#f7f7f7',
					}}
					colSpan={6}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Table size="small" aria-label="purchases">
								<TableBody>
									<TableRow>
										<TableCell />
										<TableCell>Servicio</TableCell>
										<TableCell colSpan={4}>{row.servicio}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell />
										<TableCell>Monto de aportación</TableCell>
										{row.monto_aportacion > '0' ? (
											<TableCell colSpan={4}>
												<b>
													$
													{currencyFormat(row.monto_aportacion)}
												</b>
											</TableCell>
										) : (
											<TableCell colSpan={4}>
												${currencyFormat(row.monto_aportacion)}
											</TableCell>
										)}
									</TableRow>
									<TableRow>
										<TableCell />
										<TableCell>Descripción</TableCell>
										<TableCell colSpan={4}>
											{row.descripcion}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</Fragment>
	);
}

const ConsultFilesView = (tabs: any) => {
	const { user } = useSelector((state: RootState) => state.session);
	const [results, setResults] = useState([]);
	const [loading, setLoading] = useState(false);
	const styles = useStyles();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const fetchStateOptions = useCallback(async () => {
		const keyRes = await keyAPI.get<'', { k: string }>();
		const key = keyRes['k'].toString();
		try {
			setLoading(true);

			const USER_NSS_BASE64 = await encrypt(user?.nss.toString() || '', key);

			contributionsAPI.consultAportations
				.post<{ nss: any }, any>('', {
					nss: USER_NSS_BASE64,
				})
				.then(({ result }: any) => {
					if (result?.codigo && result?.codigo === '0001') {
						setAlert({
							show: true,
							message:
								result.description ||
								INTERNAL_MESSAGES.SEARCH_CRITERIA,
							severity: 'success',
						});
					} else {
						setResults(result || []);
					}
				})
				// .then(
				// 	({ result }: any) => {
				// 		if (result.codigo === '0001') {
				// 			setAlert({
				// 				show: true,
				// 				message:
				// 					result.description ||
				// 					'No hay resultados para los criterios de búsqueda seleccionados',
				// 				severity: 'success',
				// 			});
				// 		} else {
				// 			setResults(result.result);
				// 		}
				// 	}
				// 	//
				// )
				.catch((error) => {
					setLoading(false);
					setAlert({
						show: true,
						message:
							error.error?.descripcion ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message:
					INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		}
	}, [user?.nss]);

	useEffect(() => {
		fetchStateOptions();
	}, [fetchStateOptions]);

	return (
		<>
			<ModalLoading loading={loading} />
			<Box>
				<Box className={styles.backgroundContenMargin}>
					<TableContainer component={Paper}>
						<Table aria-label="collapsible table">
							<TableHead>
								<TableRow>
									<TableCell />
									<TableCell>
										<b>Núm. Caso</b>
									</TableCell>
									<TableCell align="left">
										<b>Fecha de solicitud</b>
									</TableCell>
									<TableCell align="left">
										<b>Estatus</b>
									</TableCell>
									<TableCell align="left">
										<b>Fecha de estatus</b>
									</TableCell>
									<TableCell align="left">
										<b>Ficha de aportación</b>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{results.map((row: any, i: number) => (
									<Row key={row.numero_caso + i} row={row} />
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<CustomAlert
						show={alert.show}
						severity={alert.severity}
						message={alert.message}
						onClose={() => {
							setAlert({
								show: false,
								message: '',
								severity: alert.severity,
							});
						}}
					/>
				</Box>
			</Box>
		</>
	);
};

export default ConsultFilesView;
