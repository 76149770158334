import API from '..';

class ApiProrogation extends API {
    getProrroga(): Promise<any> {
        return this.requestWrapper(
            this.request({
                url: `/prorroga-search-case`,
                method: 'POST',
                //data: { credit },
            })
        );
    }

    createCaso(credito: string, direccionInmueble: string): Promise<any> {
        return this.requestWrapper(
            this.request({
                url: `/prorroga-create-case`,
                method: 'POST',
                data: { credito, direccionInmueble },
            })
        );
    }

    getRegistroSolicitud(numero_credito: string): Promise<any> {
        return this.requestWrapper(
            this.request({
                url: `/registro-solicitud-prorroga`,
                method: 'POST',
                data: { numero_credito },
            })
        );
    }

    generatePDF(fecha_solicitud: string, domicilio: string, nss: string, numero_credito: string): Promise<any> {
        return this.requestWrapper(
            this.request({
                url: `/prorroga-generate-pdf`,
                method: 'POST',
                data: { fecha_solicitud, domicilio, nss, numero_credito },
            })
        );
    }

    uploadDoc(idTramite: string, base64: string): Promise<any> {
        return this.requestWrapper(
            this.request({
                url: `/prorroga-upload-doc`,
                method: 'POST',
                data: { idTramite, base64 },
            })
        );
    }

    dowloadDocMq(idTramite: string): Promise<any> {
        return this.requestWrapper(
            this.request({
                url: `/prorroga-get-doc-mq`,
                method: 'POST',
                data: { idTramite },
            })
        );
    }

    dowloadDocOt(idTramite: string): Promise<any> {
        return this.requestWrapper(
            this.request({
                url: `/prorroga-get-doc-otx`,
                method: 'POST',
                data: { idTramite },
            })
        );
    }
}

export const apiProrogation = new ApiProrogation('');