/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper, useMediaQuery } from '@mui/material';
import { Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';

// Components
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';

interface Props {
	setPage: (data: number) => void;
	panelErrorStatus: number;
}

const PanelError = ({ setPage, panelErrorStatus }: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const classes = useStyles();
	const navigate = useNavigate();
	return (
		<Box>
			<HeaderList title="Aclaración de pagos" />
			<Paper sx={{ pt: 5, pb: 5, pr: matches ? 5 : 15, pl: matches ? 5 : 15 }}>
				{panelErrorStatus === 0 && (
					<div id="radioOptions">
						<h3 style={{ textAlign: 'center', marginTop: '30px', marginBottom: '-20px' }}>
							Ya tienes un caso abierto
						</h3>
						<div className={classes.message}>
							<span>
								Si quieres consultar el estatus de tu caso ingresa a {' '}
								<Link href="/contactanos/sigue-caso-adjunta-documentos">
									Sigue tu caso y adjunta documentos.
								</Link>
							</span>
						</div>
						<div style={{ marginTop: 40, paddingBottom: 30 }}>
							<CustomButton
								data-testid="buttonContinuar"
								label="Aceptar"
								onClick={() => setPage(1)}
								variant="solid"
								styles={{
									width: 260,
									height: 40,
									margin: '0 auto',
								}}
							/>
						</div>
					</div>
				)}
				{panelErrorStatus === 1 && (
					<div id="radioOptions">
						<div className={classes.message}>
							<span style={{ lineHeight: '30px' }}>
								El pago por el que estás solicitando una aclaración no ha completado tu mensualidad, <br />
								por lo que se está considerando como un pago parcial que verás reflejado en el <br />siguiente mes.
							</span>
						</div>
						<div style={{ marginTop: 40, paddingBottom: 20 }}>
							<CustomButton
								data-testid="buttonContinuar"
								label="Continuar"
								onClick={() => setPage(1)}
								variant="solid"
								styles={{
									width: 260,
									height: 40,
									margin: '0 auto',
								}}
							/>
						</div>
					</div>
				)}
				{panelErrorStatus === 2 && (
					<div id="radioOptions">
						<div className={classes.message}>
							<span style={{ lineHeight: '30px' }}>
								En nuestro sistema no tenemos registrado tu pago, revisa que la referencia con la que <br />pagaste esté correcta y acude a la institución financiera para su aclaración.
							</span>
						</div>
						<div style={{ marginTop: 40, paddingBottom: 20 }}>
							<CustomButton
								data-testid="buttonContinuar"
								label="Finalizar"
								onClick={() => navigate('/')}
								variant="solid"
								styles={{
									width: 260,
									height: 40,
									margin: '0 auto',
								}}
							/>
						</div>
					</div>
				)}
			</Paper>
		</Box>
	);
};

export default PanelError;
