import { useEffect, useState } from 'react';
import { IAlert } from '../../../../../interfaces/alert';
import { INTERNAL_MESSAGES } from '../../../../../config/messageCatalog';
import { CODES_RESPONSE } from '../../../../../config/miucConstants';
import {
	SaldoMCIResponseModel,
	apiMIUC,
	IDataconsultarAgroconsultaRes,
	IAgroindustryDatosGenerales,
} from '../../../../../api/modules/MIUC';
import { useController } from '../../../hooks/useController';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { RETURN_CODE } from '../../../constants/returnCode';
import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { IDataconsultarAgroRegistroRes } from '../../../../../interfaces/creditRequest';

export const useAgroindustry = () => {
	const { dataProcessController, saveData } = useController();
	const navigate = useNavigate();
	const { user } = useSelector((state: RootState) => state.session);
	const agroData: IDataconsultarAgroconsultaRes =
		dataProcessController.agroData;
	const [dataDatosGenerales, setDataDatosGenerales] =
		useState<IAgroindustryDatosGenerales>(
			{} as IAgroindustryDatosGenerales
		);
	const setDataConsultaRegistro = useState<IDataconsultarAgroRegistroRes>(
		{} as IDataconsultarAgroRegistroRes
	)[1];
	const [component, setComponent] = useState<string>('');
	const [flag_finishedModal, setFlag_finishedModal] =
		useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [alertRegistro, setAlertRegistro] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	useEffect(() => {
		handleConsultarAgroconsulta();
	}, [agroData]);

	useEffect(() => {
		if (dataProcessController.processInfo?.salioDeAgroindustria) {
			navigate('/mi-tramite-credito/precalificacion-puntos');
		}
	}, [dataProcessController.processInfo?.salioDeAgroindustria]);

	const handleViewed = async () => {
		await saveData(
			{
				processInfo: {
					salioDeAgroindustria: true,
				},
			},
			true
		);
	};

	const handlerSubmitMovements = () => {
		switch (agroData.sitioRegistrado) {
			case 'AC':
				break;
			case 'TX':
				break;
			case 'CA':
				setComponent('INFOAGROINDUSTRIA');
				agroData.sitioRegistrado = 'SR';
				break;
			case 'TE':
				handleViewed();
				break;
			case 'BJ':
				handleViewed();
				break;
		}
	};

	const handlerGoToPage = (page: string) => {
		setComponent(page);
	};

	const handlerCancelMovements = () => {
		window.location.reload();
	};

	const handleConsultarAgroconsulta = () => {
		setLoading(true);
		let ahorroMensual = (
			(Number(dataProcessController?.titular?.salarioDiario) || 0) *
			30.4 *
			0.3
		).toFixed(2);

		setDataDatosGenerales({
			...dataDatosGenerales,
			nss: user?.nss || dataDatosGenerales.nss,
			sitioRegistrado: agroData.sitioRegistrado,
			ahorroMensual: agroData.ahorroMensual
				? agroData.ahorroMensual
				: ahorroMensual,
			saldoAhorro: agroData.saldoAhorro,
			numeroMesesContinuos: agroData.numeroMesesContinuos,
			puntos: Number(agroData.puntosTotalCorte),
		});

		if (
			(agroData.sitioRegistrado && agroData.sitioRegistrado !== '') ||
			agroData.sitioRegistrado !== 'null'
		) {
			switch (agroData.sitioRegistrado) {
				case 'SR':
					setComponent('INFOAGROINDUSTRIA');
					break;
				case 'AC':
					setComponent('INFOAHORRO');
					break;
				case 'TX':
					setComponent('INFOAHORRO');
					break;
				case 'CA':
					setComponent('INFOAHORRO');
					break;
				case 'TE':
					setComponent('INFOAHORRO');
					break;
				case 'BJ':
					setComponent('INFOAHORRO');
					break;
			}
		}
		setLoading(false);
	};

	const handlerConfirmRegister = async () => {
		setLoading(true);
		setAlertRegistro({
			show: false,
			message: '',
			severity: 'error',
		});
		try {
			const dataRegistro = user?.nss;
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(dataRegistro) },
				user?.k || ''
			);
			apiMIUC
				.consultarAgroRegistro(dataEncripted)
				.then((response) => {
					if (response.code === RETURN_CODE.CORRECT) {
						setDataConsultaRegistro(response.data);
						setFlag_finishedModal(true);
					} else {
						setAlertRegistro({
							show: true,
							message:
								response.message ||
								INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					setAlertRegistro({
						show: true,
						message:
							error.description ||
							INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
			setAlertRegistro({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		}
	};

	const [balanceData, setBalanceData] = useState<SaldoMCIResponseModel>(
		{} as SaldoMCIResponseModel
	);

	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	useEffect(() => {
		getBalance();
	}, []);

	const getBalance = async () => {
		try {
			const response = await apiMIUC.getBalance();
			if (response.code === CODES_RESPONSE.CORRECTO) {
				setBalanceData(response.data);
			} else {
				setAlert({
					show: true,
					message: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			}
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
		}
	};

	return {
		agroData,
		balanceData,
		component,
		dataDatosGenerales,
		setAlertRegistro,
		handlerGoToPage,
		handlerConfirmRegister,
		flag_finishedModal,
		alertRegistro,
		handlerCancelMovements,
		loading,
		handlerSubmitMovements,
		alert,
	};
};
