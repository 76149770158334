import { HeaderList } from "../../../../../components/HeaderList";

const LetterOfInstruction = () => {
	return (
		<>
			<span>
				Solicita esta carta a la entidad financiera con la que tienes la
				deuda y asegúrate que tenga la siguiente información:
			</span>

			<HeaderList
				listSizeIcon={18}
				list={[
					<>Nombre del trabajador (deudor) y RFC con homoclave.</>,
					<>Ubicación de la vivienda.</>,
					<>Monto de la deuda proyectada para su liquidación.</>,
					<>
						Nombre del notario público que deberá estar vigente en
						el padrón de Notario Públicos de Infonavit.
					</>,
					<>Nombre de la entidad financiera.</>,
					<>
						Clave Bancaria Estandarizada (CLABE) para abono en
						cuenta.
					</>,
					<>Nombre del banco depositario o receptor de pago.</>,
					<>Vigencia de la carta.</>,
				]}
			/>
		</>
	);
};

export default LetterOfInstruction;
