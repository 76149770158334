import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			padding: `${theme.spacing(1)} 0`,
		},
		root: {
			backgroundColor: '#f2f2f2',
			padding: `${theme.spacing(2.5)} 0`,
		},
		hiddenDesktop: {
			[theme.breakpoints.up('md')]: {
				display: 'none',
			},
		},
		hiddenMobile: {
			display: 'none',
			[theme.breakpoints.up('md')]: {
				display: 'block',
			},
		},
		container: {
			display: 'flex',
			flexGrow: 1,
			justifyContent: 'space-around',
			[theme.breakpoints.up('md')]: {
				flexGrow: 0,
			},
		},
		divider: {
			flexGrow: 1,
			padding: `${theme.spacing(1.5)} ${theme.spacing(4)} ${theme.spacing(0.5)}`,
		},
		imgLogo: {
			width: 140,
			height: 'fit-content',
			cursor: 'pointer',
			[theme.breakpoints.up('md')]: {
				marginLeft: theme.spacing(1.5),
			},
		},
		imgInfonavit: {
			width: 250,
			// height: 'fit-content',
			cursor: 'pointer',
			[theme.breakpoints.up('md')]: {
				marginRight: theme.spacing(1.5),
			},
		},
		onlyTitle: {
			fontSize: 22,
			fontWeight: 'bold',
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center',
				margin: 0,
			},
		},
		onlyTitleDate: {
			fontSize: 12,
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center',
				margin: 0,
			},
		},
	})
);
