import { useState, useEffect } from 'react';
import BaseModal from '../../../../components/Modal';
import { IAlert } from '../../../../interfaces/alert';
import ConfirmModal from './ConfirmModal';
import QuestionModal from './QuestionModal';

interface IModalCancelWFI {
	openModal: boolean;
	setOpenModal: (value: boolean) => void;
	setSelectOption: (value: string | number) => void;
	onContinue: () => void;
	selectOption: string | number;
	hasError: IAlert;
	isMejoravit?: boolean;
}

const ModalCancelWFI = ({
	openModal,
	setOpenModal,
	setSelectOption,
	selectOption,
	onContinue,
	hasError,
	isMejoravit,
}: IModalCancelWFI) => {
	const [page, setPage] = useState(0);

	useEffect(() => {
		if (!openModal) setPage(0);
	}, [openModal]);

	return (
		<BaseModal
			title="Cancelar mi solicitud de crédito"
			open={openModal}
			onClose={() => setOpenModal(!openModal)}
		>
			<>
				{page === 0 && (
					<ConfirmModal
						setPage={setPage}
						setOpenModal={setOpenModal}
						isMejoravit={isMejoravit}
					/>
				)}
				{page === 1 && (
					<QuestionModal
						hasError={hasError}
						selectOption={selectOption}
						setSelectOption={setSelectOption}
						onContinue={onContinue}
					/>
				)}
			</>
		</BaseModal>
	);
};
export default ModalCancelWFI;
