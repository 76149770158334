import { useContext, useEffect, useState } from 'react';

import CustomButton from '../../../components/Button';
import ModalLoading from '../../../components/ModalLoading';

import { dataReturnSavingsContext } from '../contextData';
import { useStyles } from '../style';

const SelectReturnMethod = () => {
	const style = useStyles();
	const {
		//page,
		handleNextPage,
		handleGoHomePage,
		handleGoDate,
		data,
		loading,
		setPage
	} = useContext(dataReturnSavingsContext);
	const [disableOnlineOption, setDisableOnlineOption] = useState(true);

	// useEffect(() => set_Page(page), [page]);
	useEffect(() => {
		setDisableOnlineOption(
			data?.disableOnlineOption === undefined
				? true
				: data?.disableOnlineOption
		);
	}, [data?.disableOnlineOption]);

	return (
		<div>
			<ModalLoading loading={loading}></ModalLoading>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divTextCentered}>
					<p style={{ textAlign: 'center' }}>
						Elige la forma en la que quieres hacer la devolución de
						tus ahorros.<br></br> Para solicitar tu devolución en
						línea, deberás contar con tu firma electrónica avanzada
						(e.firma) vigente.<br></br> Si quieres obtener tu firma
						electrónica avanzada (e.firma), ingresa a{' '}
						<a
							style={{
								color: '#D1001F',
							}}
							href={'https://www.sat.gob.mx/'}
							target="blank"
						>
							www.sat.gob.mx
						</a>
					</p>
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divButtons3}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={handleGoHomePage}
							variant="outlined"
							styles={{
								width: '100%',
								border: '0px solid white',
							}}
							label="Salir"
						/>
					</div>
					<div className={style.Buttons}>
						<CustomButton
							onClick={() => {
								if (data?.ConsultaDatosDisponibilidadResponse?.data?.grupo === '1') {
									setPage(8)
								} else {
									handleNextPage()
								}
							}}
							variant="outlined"
							styles={{
								width: '100%',
								height: '100%'
							}}
							label="Devolución en línea"
							disabled={disableOnlineOption}
						/>
					</div>

					<div className={style.Buttons}>
						<CustomButton
							onClick={handleGoDate}
							variant="solid"
							styles={{
								width: '100%',
							}}
							label="Haz una cita"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectReturnMethod;
