/* eslint-disable react-hooks/exhaustive-deps */
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CustomButton from '../../../components/Button';
import { HeaderList } from '../../../components/HeaderList';
import SelectInput from '../../../components/SelectInput';
import { states } from '../../../config/states';
import { useStyles } from '../styles';

interface Props {
	setPage: (data: number) => void;
	value: string;
	setValue: (value: string) => void;
}

export const SelectStateView = ({ setPage, value, setValue }: Props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [optionsSelect, setOptionsSelect] = useState<{ value: string; text: string }[]>(
		[]
	);
	const [disableContinue, setDisableContinue] = useState<boolean>(true);

	useEffect(() => {
		let newArray: { value: string; text: string }[] = [];
		states.forEach((state: { name: string; code: string }) => {
			newArray.push({ value: state.name, text: state.name });
		});

		setOptionsSelect(newArray);
	}, [states]);
	useEffect(() => {
		setDisableContinue(value.replaceAll(' ', '').length !== 0 ? false : true);
	}, [value]);
	return (
		<>
			<HeaderList
				title="Carta de instrucción de cancelación de hipoteca"
				date="Información al 12 julio 2021"
				list={[
					'Esta carta debes entregarla al notario que elegiste para que lleve a cabo la liberación de tu hipoteca y quede registrada en el Registro Público de la Propiedad.',
					'La vigencia de la carta de cancelación es de 180 días naturales, si en ese tiempo no la entregas al notario (solo notario), deberás iniciar el trámite nuevamente.',
				]}
			/>
			<Paper className={classes.divPrincipal}>
				<div>
					<h3 style={{ fontSize: 16 }}>
						Elige el estado donde se encuentra tu vivienda
					</h3>
				</div>

				<div className={classes.divSelect}>
					<SelectInput
						data-testid="estado"
						id="estado"
						name="estado"
						label=""
						value={value}
						helperText={''}
						onChange={(event: any) => {
							setValue(event.target.value);
						}}
						options={optionsSelect}
						placeholder="Selecciona"
					/>
				</div>
				<div className={classes.divSelectBTN}>
					<div className={classes.divSelectBTNdiv}>
						<CustomButton
							onClick={() => {
								setValue('');
								navigate('/');
							}}
							variant="outlined"
							styles={{ width: '100%' }}
							label="Salir"
						/>
					</div>
					<div className={classes.divSelectBTNdiv}>
						<CustomButton
							onClick={() => {
								setPage(1);
							}}
							variant="solid"
							styles={{ width: '100%' }}
							disabled={disableContinue}
							label="Continuar"
						/>
					</div>
				</div>
			</Paper>
		</>
	);
};

export default SelectStateView;