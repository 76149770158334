import { Box } from '@mui/material';
import { DoneRounded } from '@mui/icons-material';
import checkVerde from '../../assets/svg/checkVerde.svg';
import { useStyles } from './styles';

interface Props {
	listItem: Array<{
		title?: string | JSX.Element | JSX.Element[];
		description?: string | JSX.Element | JSX.Element[];
	}>;
}

const StepComponent = ({ listItem }: Props) => {
	const classes = useStyles();
	return (
		<Box className={classes.steps}>
			{listItem.map((list, key) => (
				<Box display={'flex'} key={key}>
					<Box
						display={'flex'}
						alignItems={'center'}
						flexDirection={'column'}
					>
						<img
							src={checkVerde}
							className={classes.checkPasos}
							alt='check'
						/>

						{key < listItem.length - 1 && (
							<hr className={classes.checkConnect}/>
						)}
					</Box>
					<Box className={classes.stepDescription}>
						{list.title && (
							<span className={classes.stepTitle}>
								{list.title}
							</span>
						)}
						{list.description}
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default StepComponent;
