import { useState } from 'react';
import { RootState } from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { IProfileResponse, apiMIUC } from '../../../../../../api/modules/MIUC';
import { encodeAllJSONData } from '../../../../../../utils/encrypt';
import { actions } from '../../../../../../store/modules/loading';
import { IAlert } from '../../../../../../interfaces/alert';
import {
	CODES_RESPONSE,
	URL_APLICACION,
} from '../../../../../../config/miucConstants';
import { IUrlEncripted } from '../../../../../../interfaces/creditRequest';
import { INTERNAL_MESSAGES } from '../../../../../../config/messageCatalog';
import { formatDate } from '../../../../../../utils/dates';

interface IHowCreditGoing {
	data: IProfileResponse;
}
export const useHowCreditGoing = ({ data }: IHowCreditGoing) => {
	const [openModal, setOpenModal] = useState(false);
	const [openModalCancel, setOpenModalCancel] = useState(false);
	const [selectOption, setSelectOption] = useState<string | number>('');
	const { user } = useSelector((state: RootState) => state.session);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [alertDocument, setAlertDocument] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const dispatch = useDispatch();

	const getUrlWFI = async (aplicacion: string) => {
		try {
			dispatch(actions.startLoading());
			if (!data.casoActivo?.noCaso) {
				dispatch(actions.stopLoading());

				if (aplicacion === URL_APLICACION.REIMPRESION_WFI) {
					return setAlertDocument({
						show: true,
						severity: 'error',
						message: INTERNAL_MESSAGES.NO_ASSOCIATED_CASE,
					});
				}
				return setAlert({
					show: true,
					severity: 'error',
					message: INTERNAL_MESSAGES.NO_ASSOCIATED_CASE,
				});
			}
			const dataFlag: IUrlEncripted = {
				miuc: 'L2',
				tipo: aplicacion,
				aplicacion,
				nss: user?.nss || '',
				nssConyuge: data?.conyuge?.nss || '',
				noCaso: data.casoActivo?.noCaso || '',
				motivoCancelacion: selectOption.toString() || '',
				lada: user?.ladaCelular || '',
				telefono: user?.telefonoCelular || '',
				correo: user?.email,
				tiempo: formatDate(
					'yyyy-MM-dd HH:mm:ss',
					new Date()
				).toString(),
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(dataFlag) },
				user?.k || ''
			);
			const response = await apiMIUC.getUrlEncripted(dataEncripted);
			if (response?.code === CODES_RESPONSE.CORRECTO) {
				window.open(response?.data?.path, '_blank');
			}
		} catch (error: any) {
			dispatch(actions.stopLoading());
			if (aplicacion === URL_APLICACION.REIMPRESION_WFI) {
				return setAlertDocument({
					show: true,
					severity: 'error',
					message:
						error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				});
			}
			setAlert({
				show: true,
				severity: 'error',
				message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
			});
		} finally {
			dispatch(actions.stopLoading());
		}
	};

	const getUrlMiespacio = async () => {
		try {
			dispatch(actions.startLoading());
			const data = {
				nss: user?.nss,
				tipo: 'MEI',
			};
			const dataEncripted = await encodeAllJSONData(
				{ data: JSON.stringify(data) },
				user?.k || ''
			);
			const response = await apiMIUC.getUrlEncripted(dataEncripted);
			window.open(response.data.path, '_blank');
		} catch (error) {
			dispatch(actions.stopLoading());
		} finally {
			dispatch(actions.stopLoading());
		}
	};

	return {
		getUrlMiespacio,
		getUrlWFI,
		openModal,
		setOpenModal,
		openModalCancel,
		setOpenModalCancel,
		selectOption,
		setSelectOption,
		alert,
		setAlert,
		alertDocument,
		setAlertDocument,
	};
};
