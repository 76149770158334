import React from 'react';
import SelectUnstyled, {
	SelectUnstyledProps,
	selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/system';

const blue = {
	light: '#DAE2EC',
	main: '#293990',
	dark: '#003A75',
};

const grey = {
	light: '#E7EBF0',
	main: '#B2BAC2',
	dark: '#1A2027',
};

const StyledButton = styled('button')(
	() => `
  font-size: 14px;
  min-width: 160px;
  background: #DAE2EC;
  border: 0;
  border-radius: 25px;
  margin: 1em;
  padding: 10px;
  text-align: left;
  color: ${blue['main']};
  font-weight: 600;

  &:hover {
    background: #E7EBF0;
	cursor: pointer;
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled('ul')(
	({ theme }) => `
  font-size: 14px;
  box-sizing: border-box;
  padding: 0px;
  margin-top: 0px;
  min-width: 160px;
  background: ${theme.palette.common.white};
  border: 0;
  border-radius: 8px;
  color: ${blue['main']};
  font-weight: 600;
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
	() => `
  list-style: none;
  padding: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${blue['main']};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${blue['main']};
    color: ${grey['dark']};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${blue['light']};
    color: ${blue['dark']};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${grey['main']};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${grey['light']};
    color: ${grey['dark']};
  }
  &:hover {
	cursor: pointer
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
	z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect<TValue>(
	props: SelectUnstyledProps<TValue>,
	ref: React.ForwardedRef<HTMLUListElement>
) {
	const components: SelectUnstyledProps<TValue>['components'] = {
		Root: StyledButton,
		Listbox: StyledListbox,
		Popper: StyledPopper,
		...props.components,
	};

	return <SelectUnstyled {...props} ref={ref} components={components} />;
}) as <TValue>(
	props: SelectUnstyledProps<TValue> & React.RefAttributes<HTMLUListElement>
) => JSX.Element;

type IInput = {
	name: string;
	value: string;
};

type IProps = {
	data: IInput[];
	onChange: any;
};

const CustomSelectInput = ({ data, onChange }: IProps) => {
	const { credit } = useSelector((state: RootState) => state.credit);
	const handleValuesChange = (e: any) => onChange(e);

	return (
		<CustomSelect onChange={handleValuesChange} defaultValue={credit}>
			<StyledOption value={'0'}>
				{data.length > 0 ? 'Selecciona un crédito' : 'Sin crédito vigente'}
			</StyledOption>
			{data.length > 0 &&
				data.map((d, i) => (
					<StyledOption key={i} value={d.value}>
						{d.name}
					</StyledOption>
				))}
		</CustomSelect>
	);
};

export default CustomSelectInput;
