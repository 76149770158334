import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	titleNoticeContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	titleNotice: {
		marginTop: 0,
		fontSize: 22,
		color: '#293990',
	},
	subTitleNotice: {
		fontSize: 12,
		color: '#333333',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			textAlign: 'right',
			marginTop: 15,
		},
	},
	checkHeader: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 7,
	},
	checkHeaderImg: {
		width: 17,
		marginRight: 5,
	},
	checkHeaderText: {
		color: '#333',
		fontSize: 13,
		padding: 0,
		margin: 0,
	},
	checkHeaderText2: {
		color: '#333',
		fontSize: 13,
		textAlign: 'center',
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		paddingBlock: 50,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
	},
	contentDescription: {
		textAlign: 'center',
		margin: '0 auto',
		maxWidth: 600,
	},
	contentDescriptionTopTitle: {
		fontSize: 16,
	},
	contentDescriptionTopText: {
		fontSize: 14,
	},
	contentDescriptionBottomText: {
		fontSize: 14,
	},
	contentDescriptionBottomLink: {
		color: '#D1001F',
		marginLeft: 5,
	},
	stepLink: {
		color: '#D1001F',
		marginLeft: 5,
		paddingTop: 30,
		fontSize: 14,
	},
	containerMin: {
		maxWidth: 900,
		margin: '0 auto',
	},
}));
