import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useController } from '../../../hooks/useController';
import { useEffect, useState } from 'react';
import { IAlert } from '../../../../../interfaces/alert';
import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { isNumberFloat } from '../../../../../utils/expressions';
import { formatDate } from '../../../../../utils/dates';
import { apiMIUC } from '../../../../../api/modules/MIUC';
import { RETURN_CODE } from '../../../constants/returnCode';
import { isEmpty } from '../../../../../utils/validators';
import { MIUC_PRODUCTS } from '../../../constants/products';
import { creditTypes } from '../../../../CreditRequest/MIUC/utils';
import { useAlertCustom } from '../../../hooks/useAlertCustom';

export const useConstruYoTable = () => {
	const {
		returnStep,
		addSelection,
		nextStep,
		saveData,
		getProgressStep,
		dataProcessController,
	} = useController();

	const [loading, setLoading] = useState(false);
  const { setAlertClean, setAlertCustom, AlertInput } = useAlertCustom();
  const { 
    setAlertClean: setAlertCleanInput,
    setAlertCustom: setAlertCustomInput,
    AlertInput: AlertInputCalc 
  } = useAlertCustom();
	const { user } = useSelector((state: RootState) => state.session);
	const { active, total } = getProgressStep();
	const newStep = {
		title: 'Estas son las opciones de financiamiento que tenemos para ti',
		subtitle:
			'Revisa cada opción, compara entre ellas y elige la que más te convenga',
		active,
		total,
	};
	const [selectProduct, setSelectProduct] = useState('');
	const [products, setProducts] = useState<any[]>([]);
	const disableButton = isEmpty(selectProduct);
	const [productsSelect, setProductsSelect] = useState<any[]>([]);
	const [valorObra, setValorObra] = useState('');
	const [plazo, setPlazo] = useState(Number);

	const handleSetDataObra = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		if (isNumberFloat(value)) {
			setValorObra(value);
		}
	};

	const plazoOptions: { label: string; value: number }[] = products.map(
		(producto) => ({
			label: `${producto.plazo} meses`,
			value: producto.plazo,
		})
	);

	useEffect(() => {
		const filteredMonths = products.filter(
			(product) => product.plazo === plazo
		);

		const filteredProducts = [...filteredMonths];
		setProductsSelect(filteredProducts);
	}, [products, plazo]);

	const next = () => {
		const product = products.find(
			(prod) =>
				prod.producto === selectProduct &&
				prod.plazo === plazo
		);

		// handleTrazabilidad();
    saveData({
      processInfo: {
        productSelect: product
      },
      processData: {
        active: getProgressStep().active + 1,
      }
    });
		addSelection({
			title: 'Producto Seleccionado:',
			description: `${creditTypes[selectProduct].title}`,
		});
		nextStep();
	};

	const handleTrazabilidad = async () => {
		try {
			setLoading(true);
			const request = {
				nss: user?.nss?.toString() || '',
				curp: user?.curp || '',
				rfc: user?.rfc || '',
				nrp: user?.curp || '',
				nombre: user?.nombres || '',
				apellidoPaterno: user?.apPaterno || '',
				apellidoMaterno: user?.apMaterno || '',
				fecha: formatDate('dd.MM.yyyy'),
				mensaje:
					dataProcessController.processInfo?.credit.toUpperCase() ===
					MIUC_PRODUCTS.MEJORAVIT
						? `PRECALIFICACION ${formatDate('dd/MM/yyyy')} ${
								dataProcessController.processInfo?.credit
						  }`
						: 'MCI',
				subtipoTrazabilidad:
					products.find((prod) => prod.producto === selectProduct)
						?.subtipoTrazabilidad || 'ZN01',
			};
			const newRequest = await encodeAllJSONData(
				{ data: JSON.stringify(request) },
				user?.k || ''
			);
			await apiMIUC.trazabilidadCRM(newRequest);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const resq = async () => {
			await getProductsAutoproduction();
			return;
		};
		resq();
	}, []);

	const getProductsAutoproduction = async () => {
		try {
			setLoading(true);
			const requestData = {
				nss: user?.nss || '',
				stageId: dataProcessController?.titular?.stageId || '4',
				producto: dataProcessController.processInfo?.creditType,
			};
			const ENCODED_DATA = await encodeAllJSONData(
				{ data: JSON.stringify(requestData) },
				user?.k || ''
			);

			const result = await apiMIUC.consultarAutoproduccion(ENCODED_DATA);

			if (result.code === RETURN_CODE.CORRECT) {
        const { data } = result;

        const productos = data.productos;
        const tasaInteres = data.tasaInteres;
        const valorUMA = data.valorUMA;

				const newProducts = productos.map((products: any) => {
					const producto = {
						...products,
						tasaInteres: tasaInteres,
						producto: 'L6',
						garantia: dataProcessController.titular.saldoSARTotal,
						pagoCopia: products.pago,
						contariasCopia: products.contarias,
						montoCopia: products.monto,
						valorUMA: valorUMA,
					};
					return producto;
				});
				setPlazo(productos[0]?.plazo);
				setProducts(newProducts);
        setAlertClean();
        return;
			}
      setAlertCustom({
        show: true,
				message:
					result.message ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
      });
		} catch (error: any) {
      setAlertCustom({
        show: true,
				message:
					error.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
      });
		} finally {
			setLoading(false);
		}
	};

	const handleCalculate = () => {
		if (!valorObra) {
      return setAlertCustomInput({
        show: true,
				message: 'Escribe el valor de la vivienda que quieres comprar',
				severity: 'warning',
      });
		}

		if (Number(valorObra || '0') <= 0) {
      return setAlertCustomInput({
        show: true,
				message: 'Escribe el valor de la vivienda que quieres comprar',
				severity: 'warning',
      });
		}
		setLoading(true);
		let index = products.findIndex(
			(product: any) => product.plazo === plazo
		);

		if (index !== -1) {
			const montofake = Number(
				valorObra?.replaceAll(',', '').trim() || 0
			);

			if (montofake > Number(products[index]?.monto || 0)) {
				setLoading(false);
        return setAlertCustomInput({
          show: true,
          message: 'El presupuesto no puede ser mayor al monto de crédito',
          severity: 'warning',
        });
			}
      setAlertCleanInput();

			let pagoCalculado: number =
				(Number(valorObra?.replaceAll(',', '')?.trim()) *
					Number(products[index].pagoCopia)) /
				Number(products[index].montoCopia);
			let newProducts = products;
			newProducts[index].pago = pagoCalculado;
			newProducts[index].contarias = valorObra
				?.replaceAll(',', '')
				.trim();
			newProducts[index].monto = valorObra
				?.replaceAll(',', '')
				.trim();

			setProducts(newProducts);
		}
		setLoading(false);
	};

	const handleChangePlazo = (event: any) => {
		setPlazo(event.target.value);
	};

	return {
		loading,
		next,
		returnStep,
		setSelectProduct,
		selectProduct,
		newStep,
		AlertInput,
    	AlertInputCalc,
		handleCalculate,
		plazoOptions,
		handleChangePlazo,
		productsSelect,
		disableButton,
		valorObra,
		setValorObra,
		handleSetDataObra,
		plazo,
	};
};
