import API from '../';

interface IDataNSS {
	nss: string;
}

interface IData_validChangenCurp {
	nss: string;
	curp: string;
}

interface IData_createOrder {
	nss: string;
	curp: string;
	nombre: string;
	paterno: string;
	materno: string;
	fechaNacimiento: string;
}

class CorrectionCurpAPI extends API {
	correctionCurpName(data: IDataNSS): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `correction-curp-name`,
				method: 'POST',
				data,
			})
		);
	}

	ValidChangeCurp(data: IData_validChangenCurp): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `valid-changen-curp`,
				method: 'POST',
				data,
			})
		);
	}

	CreateOrder(data: IData_createOrder): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `create-order`,
				method: 'POST',
				data,
			})
		);
	}
}

export const correctionCurpAPI = new CorrectionCurpAPI('');
