import { useState } from 'react';
import { Box, Card, CardActionArea } from '@mui/material';
import checkBlue from '../../../../assets/img/mini-check-azul.png';
import { useStyles } from './styles';
import ModalSueloConstruccion from '../ModalSueloConstruccion';
import { MIUC_PRODUCTS } from '../../constants/products';

export interface IProductCard {
	icon:
		| string
		| JSX.Element
		| React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	product: string;
	title: string | JSX.Element;
	description: string | JSX.Element;
	hoverDetails?: string[];
	disabled?: boolean;
	onClick?: (product: string) => void;
}

export const ProductCard = ({
	icon,
	product,
	title,
	description,
	hoverDetails,
	disabled,
	onClick = () => {},
}: IProductCard) => {
	const classes = useStyles();
	const [showModal, setShowModal] = useState(false);

	/* 
		Si recive disable entonces se desactiva
		Si recibe el arreglo 'hoverDetail' consigue el efecto de transicion,
		Si no recibe ninguno de esos 2 se vuelve una card normal
		*/
	const containerClass = disabled
		? `${classes.container} ${classes.disabled}`
		: hoverDetails
		? `${classes.container} ${classes.withHover}`
		: `${classes.container} ${classes.normalCard}`;

	const nextProcess = () => {
		setShowModal(false);
		onClick(product);
	};

	return (
		<>
			<ModalSueloConstruccion
				show={showModal}
				setShow={setShowModal}
				nextProcess={nextProcess}
			/>
			<Card className={containerClass}>
				<CardActionArea
					className={classes.actionArea}
					onClick={() =>
						product === MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION
							? setShowModal(true)
							: onClick(product)
					}
				>
					<Box>
						<Box className={classes.icon}>
							{typeof icon === 'string' && (
								<img src={icon} alt="" />
							)}
							{typeof icon === 'object' && icon}
						</Box>
						<Box className={classes.precalificate}>
							<ul>
								<li>Precalifícate</li>
							</ul>
						</Box>
					</Box>
					<Box className={classes.title}>
						<h2>{title}</h2>
						<p className={classes.description}>{description}</p>
						<Box className={classes.list}>
							{hoverDetails?.map((info, index) => (
								<Box className={classes.listItem} key={index}>
									<img src={checkBlue} />
									<p>{info}</p>
								</Box>
							))}
						</Box>
					</Box>
				</CardActionArea>
			</Card>
		</>
	);
};
