import { useState } from 'react';
import {
	Divider,
	FormControlLabel,
	Grid,
	Link,
	Paper,
	Radio,
	Stack,
	useMediaQuery,
	Alert,
	Box,
	InputAdornment,
} from '@mui/material';
import { Error, WarningAmberRounded, InfoOutlined } from '@mui/icons-material';
import { HeaderList } from '../../../components/HeaderList';
import PaymentRejected from '../Components/PaymentRejected';
import PaymentMethods from '../Components/PaymentMethods';
import ProceedPayment from '../Components/ProceedPayment';
import { apiSaldAndMovements } from '../../../api/modules/SaldAndMovements';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { keyAPI } from '../../../api/modules/key';
import { encrypt, encodeAllJSONData } from '../../../utils/encrypt';
import { currencyFormat } from '../../../utils/expressions';
import { UseStyle } from '../Style';
import CustomButton from '../../../components/Button';
import { useTheme } from '@mui/material/styles';
import ModalOptionsPay from '../ModalOptionsPay';
import { formatMessage, formatDate } from '../../../interfaces/SaldAndMovement';
import TextInput from '../../../components/TextInput';
import { formatMoney2 } from '../../../utils/validators';
import theme from '../../../config/theme';
import { useBitacora } from '../../../hooks/useBitacora';

const PaymentOptions = ({ credit, user, setView, onlyCredit }: any) => {
	const classes = UseStyle();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [loading, setLoading] = useState(false);
	const [proceed, setProceed] = useState(false);
	const [openModalPay, setOpenModalPay] = useState(false);
	const [rejected, setRejected] = useState('');
	const [form, setForm] = useState({
		type_payment: '',
		method_payment: '',
		amount: '',
		period: '',
		max: ''
	});
	const [paymentAlert, setPaymentAlert] = useState<any>({
		message: '',
		severity: '',
	});
	const { regBitacoraNotOld } = useBitacora();

	const handleChange = ({ target }: any) => {
		const { name, value } = target;
		if (value === 'currently') {
			setForm({
				...form,
				type_payment: value,
				max: credit?.opcionesPago?.v15PgocorrienteDiaconsulta?.trim(),
				amount: ''
			});
		} else if (value === 'monthly') {
			setForm({
				...form,
				type_payment: value,
				max: credit?.opcionesPago?.v16PgomensualidadDiaconsulta?.trim(),
				amount: ''
			});
		} else if (value === 'other') {
			if (name === 'amount') {
				setForm({
					...form,
					[name]: value,
					max: value
				});
			} else {
				setForm({
					...form,
					[name]: value,
					amount: '',
					max: ''
				});
			}
		} else {
			setForm({
				...form,
				[name]: value,
			});
		}

		if (name === 'method_payment') {
			if (value === 'codi')
				setPaymentAlert({
					message: (
						<>
							<p style={{ margin: 0, fontWeight: '500' }}>
								Si requieres pago con CoDi.
							</p>
							<span style={{ fontWeight: '500' }}>
								La cantidad máxima por operación al pagar con
								CoDi es de $8,000.00
							</span>{' '}
							y puedes hacer varios pagos, hasta cubrir el monto
							que requieres.
						</>
					),
					severity: 'info',
				});
			if (value === 'card')
				setPaymentAlert({
					message: (
						<>
							<p style={{ margin: 0, fontWeight: '500' }}>
								Si requieres pago con Visa o MasterCard.
							</p>
							<span style={{ fontWeight: '500' }}>
								La cantidad máxima por operación con tarjeta es
								de $30,000
							</span>{' '}
							y puedes hacer hasta tres pagos en el mes, los
							cuales{' '}
							<span style={{ fontWeight: '500' }}>
								no deben exceder un total de $90,000.00
							</span>
							, con cualquier tarjeta Visa o MasterCard.
						</>
					),
					severity: 'info',
				});
			if (value === 'cuenta')
				setPaymentAlert({
					message: (
						<>
							En caso de pago con cuenta bancaria en México, considerar
							que antes de hacer la transferencia debe dar de alta, en su
							banca en línea, la cuenta CLABE que aparecerá en la "Ficha
							para pago interbancario BBVA" que se muestra al dar clic en
							el botón continuar; dependiendo del banco, el tiempo de espera
							es de 30 minutos a 3 horas.
						</>
					),
					severity: 'info',
				});
		}
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const NSS_BASE64 = await encrypt(user?.nss.toString() || '', key);
			const CREDIT_BASE64 = await encrypt(credit?.credito || '', key);
			const MONTO_BASE64 = await encrypt(
				form.type_payment === 'monthly'
					? credit?.opcionesPago?.v16PgomensualidadDiaconsulta?.trim()
					: form.type_payment === 'currently'
						? credit?.opcionesPago?.v15PgocorrienteDiaconsulta?.trim()
						: Number(form.amount.toString().replaceAll(',', '')),
				key
			);
			const FULLNNAME_BASE64 = await encrypt(user?.given_name || '', key);
			const CORREO_BASE64 = await encrypt(user?.email || '', key);
			let req = {
				result: ''
			}
			let dataBitacora = {
				nss: user?.nss,
				credit: credit?.credito,
				monto:
					form.type_payment === 'monthly'
						? credit?.opcionesPago?.v16PgomensualidadDiaconsulta?.trim()
						: form.type_payment === 'currently'
						? credit?.opcionesPago?.v15PgocorrienteDiaconsulta?.trim()
						: Number(form.amount.toString().replaceAll(',', '')),
				fullname: user?.given_name,
				correo: user?.email,
			};
			if (form.method_payment === 'codi') {
				req = await apiSaldAndMovements.paymentCodi(
					NSS_BASE64,
					CREDIT_BASE64,
					MONTO_BASE64,
					FULLNNAME_BASE64,
					CORREO_BASE64
				)
			} else if (form.method_payment === 'cuenta') {
				const data = {
					email: user?.email || '',
					acreditado: user?.given_name || '',
					numero_credito: onlyCredit || '',
					segmento: '0',
					codigo_estado: '',
					monto: form.type_payment === 'monthly'
						? credit?.opcionesPago?.v16PgomensualidadDiaconsulta?.trim()
						: form.type_payment === 'currently'
							? credit?.opcionesPago?.v15PgocorrienteDiaconsulta?.trim()
							: form.amount.toString().replaceAll(',', '') || '',
					referencia: 'SM'
				};
				const dataEncript = await encodeAllJSONData(
					{
						data: JSON.stringify(data),
					},
					user?.k || ''
				);
				req = await apiSaldAndMovements.paymentCuenta(dataEncript)
			} else {
				req = await apiSaldAndMovements.paymentBBVA(
					NSS_BASE64,
					CREDIT_BASE64,
					MONTO_BASE64,
					FULLNNAME_BASE64,
					CORREO_BASE64
				);
			}
			if (req) {
				let html = window.atob(req.result);
				let wnd = window.open('about:blank', '_blank');
				wnd?.document.write(`${html}`);
				wnd?.document.close();
				regBitacoraNotOld(apiSaldAndMovements.schemeUrl, dataBitacora)
			}
		} catch (error: any) {
			setProceed(false);
			setRejected(error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	if (proceed)
		return (
			<ProceedPayment
				method_payment={form.method_payment}
				showProceed={setProceed}
				onSubmit={handleSubmit}
				loading={loading}
			/>
		);

	if (rejected)
		return (
			<PaymentRejected rejected={rejected} showRejected={setRejected} />
		);

	return (
		<>
			<HeaderList
				title="Opciones de pago"
				list={
					!['00220', '00030'].includes(
						credit?.tipoCasoCredito?.wsTipoCaso
					)
						? [
							'Haz tus pagos completos antes del último día del mes actual.',
							'Si vas a liquidar tu crédito, paga directamente en cualquier banco autorizado.',
						]
						: []
				}
			/>

			<ModalOptionsPay
				openModal={openModalPay}
				setOpenModal={setOpenModalPay}
			/>

			<Paper style={{ padding: matches ? '30px 30px' : '30px 140px' }}>

				{!['00220', '00010', '00030'].includes(
					credit?.tipoCasoCredito?.wsTipoCaso
				) && (
						<p
							style={{
								color: '#6b6b6b',
								margin: '10px 0px',
								textAlign: 'center',
							}}
						>
							Información actualizada al{' '}
							<strong>
								{formatDate(credit.v6FechaActualAls, 'full')}
							</strong>
						</p>
					)}

				<Grid container spacing={2}>
					{!['00220', '00010', '00030'].includes(
						credit?.tipoCasoCredito?.wsTipoCaso
					) && (
							<>
								<Grid item xs={12}>
									<h3>Elige una de las opciones:</h3>
								</Grid>
								{credit?.opcionesPago?.v11Sdoliqpes && (
									<>
										<Grid
											item
											xs={12}
											sm={8}
											style={{ paddingLeft: 45 }}
										>
											<span>Saldo a liquidar</span>
											<br />
											<small
												style={{
													color: theme.palette.info.main,
												}}
											>
												<Error fontSize="inherit" />
												Para liquidar tu crédito, paga
												directamente a cualquier banco
												autorizado.
											</small>
										</Grid>
										<Grid item xs={12} sm={4} textAlign="right">
											<h4 style={{ marginTop: 0 }}>
												$
												{currencyFormat(
													credit?.opcionesPago?.v11Sdoliqpes?.trim()
												)}
											</h4>
										</Grid>
									</>
								)}
							</>
						)}

					{credit?.opcionesPago?.v13SdoliqpesProymesCondesc &&
						!['00200', '00220', '00010', '00030'].includes(
							credit?.tipoCasoCredito?.wsTipoCaso
						) && (
							<>
								<Grid
									item
									xs={12}
									sm={8}
									style={{ paddingLeft: 45 }}
								>
									<span>Saldo a liquidar con descuento</span>
									<br />
									<small
										style={{
											color: theme.palette.info.main,
										}}
									>
										<Error fontSize="inherit" />
										Para liquidart tu crédito, paga
										directamente a cualquier banco
										autorizado.
									</small>
								</Grid>
								<Grid item xs={12} sm={4} textAlign="right">
									<h4 style={{ marginTop: 0 }}>
										$
										{currencyFormat(
											credit?.opcionesPago?.v13SdoliqpesProymesCondesc?.trim()
										)}
									</h4>
								</Grid>
							</>
						)}
					{credit?.opcionesPago?.v15PgocorrienteDiaconsulta &&
						!['00200', '00220', '00010', '00030', '00550'].includes(
							credit?.tipoCasoCredito?.wsTipoCaso
						) && (
							<>
								<Grid item xs={12} sm={6}>
									<FormControlLabel
										label="Pago para estar al corriente"
										control={
											<Radio
												checked={
													form.type_payment ===
													'currently'
												}
												onChange={handleChange}
												id="currently_payment_radio"
												name="type_payment"
												value="currently"
											/>
										}
									/>
								</Grid>
								<Grid item xs={12} sm={6} textAlign="right">
									<h4 style={{ marginTop: 0 }}>
										$
										{currencyFormat(
											credit?.opcionesPago?.v15PgocorrienteDiaconsulta?.trim()
										)}
									</h4>
								</Grid>
							</>
						)}
					{credit?.opcionesPago?.v16PgomensualidadDiaconsulta &&
						!['00200', '00220', '00010', '00030', '00550'].includes(
							credit?.tipoCasoCredito?.wsTipoCaso
						) && (
							<>
								<Grid item xs={12} sm={6}>
									<FormControlLabel
										label="Pago de mi mensualidad"
										control={
											<Radio
												checked={
													form.type_payment ===
													'monthly'
												}
												onChange={handleChange}
												id="monthly_payment_radio"
												name="type_payment"
												value="monthly"
											/>
										}
									/>
								</Grid>
								<Grid item xs={12} sm={6} textAlign="right">
									<h4 style={{ marginTop: 0 }}>
										$
										{currencyFormat(
											credit?.opcionesPago?.v16PgomensualidadDiaconsulta?.trim()
										)}
									</h4>
								</Grid>
							</>
						)}
					{/* Otro pago */}
					{credit?.opcionesPago?.v15PgocorrienteDiaconsulta &&
						!['00200', '00220', '00010', '00030'].includes(
							credit?.tipoCasoCredito?.wsTipoCaso
						) && (
							<>
								<Grid item xs={12} sm={8}>
									<FormControlLabel
										label="Otro"
										control={
											<Radio
												checked={
													form.type_payment ===
													'other'
												}
												onChange={handleChange}
												id="other_payment_radio"
												name="type_payment"
												value="other"
											/>
										}
									/>
								</Grid>
								<Grid item xs={12} sm={4} textAlign="right">
									<TextInput
										name="amount"
										placeholder="0.00"
										onChange={(e) =>
											!isNaN(Number(e.target.value)) &&
											handleChange(e)
										}
										onBlur={() => {
											if (form.amount) {
												if (form.amount === '.') {
													setForm({
														...form,
														amount: '',
														max: ''
													});
												} else {
													setForm({
														...form,
														amount: formatMoney2(
															Number(form.amount)
														).substring(1),
														max: formatMoney2(
															Number(form.amount)
														).substring(1),
													});
												}
											}
										}}
										onFocus={() => {
											if (form.amount) {
												setForm({
													...form,
													amount: form.amount
														.toString()
														.replaceAll(',', '')
														.replaceAll(' ', ''),
												});
											}
										}}
										startAdornment={
											<InputAdornment position="start">
												$
											</InputAdornment>
										}
										id="amount_payment_text"
										value={form.amount}
										disabled={form.type_payment !== 'other'}
									/>
								</Grid>
							</>
						)}
				</Grid>

				{['00200', '00220', '00010', '00030'].includes(
					credit?.tipoCasoCredito?.wsTipoCaso
				) && (
						<Box style={{ margin: '20px 0px' }}>
							{credit?.msgs?.map(
								(msg: any) =>
									['01', '04', '07'].includes(msg.key) && (
										<Alert
											icon={
												<WarningAmberRounded
													fontSize="inherit"
													style={{ color: '#E35700' }}
												/>
											}
											severity="warning"
											style={{
												marginBottom: 10,
												background: '#FAE295',
												color: '#E35700',
											}}
											key={msg.key}
										>
											{formatMessage(
												msg.value,
												msg.key,
												credit
											)}
										</Alert>
									)
							)}
						</Box>
					)}

				{form.type_payment === 'other' && (
					<>
						<h4 style={{ textAlign: 'center' }}>Pagos parciales</h4>
						<p
							style={{
								margin: 0,
								color: '#293990',
								fontWeight: 700,
							}}
						>
							¿Qué son y cómo se aplican los pagos parciales?
						</p>
						<p>
							Los pagos parciales son aquellos pagos que puedes
							hacer durante el mes, para completar poco a poco la
							cantidad que se observa en el renglón{' '}
							<b>de “Pago por tu cuenta REA”</b> en{' '}
							<b>la sección Mensualidades REA y ROA</b>,
							considerando como fecha límite para completarla
							antes del último día del mes.
							<br />
							<br />
							En caso de que con las parcialidades que realices
							antes del último día del mes, no cubras el total
							indicado en “Pago por tu cuenta REA”, los pagos
							hechos, se aplicarán al saldo de tu crédito, no se
							tomará en cuenta como pago efectivo del mes
							efectivo.
							<br />
							<br />
							Si antes del último día del mes terminas de cubrir
							el monto total de “Pago por tu cuenta REA” y
							continúas realizando pagos, lo que pagues adicional,
							se aplicará al saldo de crédito.
						</p>
					</>
				)}

				{!['00220', '00010', '00030'].includes(
					credit?.tipoCasoCredito?.wsTipoCaso
				) && (
						<>
							<Link
								href="#VerPagos"
								onClick={() => setOpenModalPay(true)}
								style={{
									display: 'block',
									maxWidth: 255,
									margin: 'auto',
									marginTop: 20,
									marginBottom: 20,
								}}
							>
								Consulta en que lugares puedes pagar
							</Link>
						</>
					)}

				{!['00200', '00220', '00010', '00030'].includes(
					credit?.tipoCasoCredito?.wsTipoCaso
				) && (
						<>
							<Divider style={{ marginTop: 10 }} />
							<h4>Forma de pago</h4>
							<PaymentMethods
								form={form}
								handleChange={handleChange}
							/>
							<Stack
								spacing={2}
								alignItems="center"
								className={classes.paymentMessage}
							>
								{Boolean(paymentAlert.message) && (
									<Alert
										severity={paymentAlert.severity}
										icon={
											<InfoOutlined
												fontSize="large"
												color="info"
											/>
										}
										style={{
											marginTop: 10,
											color: '#293990',
										}}
									>
										{paymentAlert.message}
									</Alert>
								)}

								<div className={classes.centeredItems}>
									<div
										style={{
											display: 'flex',
											width: '100%',
											justifyContent: 'center',
											flexDirection: matches
												? 'column-reverse'
												: 'row',
										}}
									>
										<CustomButton
											label="Regresar"
											variant="outlined"
											styles={{
												maxWidth: matches ? '100%' : 200,
												margin: 10,
											}}
											onClick={() => setView(0)}
										/>
										<CustomButton
											label="Continuar"
											disabled={
												!form.type_payment ||
												!form.method_payment ||
												(form.method_payment === 'codi' &&
													Number(
														form.max
															.toString()
															.replaceAll(',', '')
													) > 8000) ||
												(form.method_payment === 'cuenta' &&
													Number(
														form.max
															.toString()
															.replaceAll(',', '')
													) === 0) ||
												(form.method_payment === 'card' &&
													Number(
														form.max
															.toString()
															.replaceAll(',', '')
													) > 30000) ||
												(Number(form.max) === 0 &&
													form.type_payment === 'other')
											}
											variant="solid"
											styles={{
												maxWidth: matches ? '100%' : 200,
												margin: 10,
											}}
											onClick={() => {
												setProceed(true);
											}}
										/>
									</div>
									<p>
										Tienes 90 días naturales para aclarar tus
										pagos. Si tienes dudas, llámanos al 559 171
										5050 en la Ciudad de México o al 800 008
										3900 desde el interior del país.
									</p>
								</div>
							</Stack>
						</>
					)}

				{['00200', '00220', '00010', '00030'].includes(
					credit?.tipoCasoCredito?.wsTipoCaso
				) && (
						<div className={classes.centeredItems}>
							<CustomButton
								label={'Regresar'}
								variant="solid"
								styles={{ maxWidth: 200, margin: 10 }}
								onClick={() => setView(0)}
							/>
						</div>
					)}
			</Paper>
		</>
	);
};

export default PaymentOptions;
