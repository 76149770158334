import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	textCenter: {
		textAlign: 'center',
	},
	margin20p0p: {
		margin: '20px 0px',
	},
	linkPaddingDisplay: {
		padding: '20px 0px',
		display: 'inline-block',
	},
	marginTextCenter: {
		textAlign: 'center',
		margin: '20px 0px',
	},
	colorBlue: {
		color: '#283990',
	},
}));
