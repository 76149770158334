/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import CustomButton from '../../../components/Button';
import { dataReturnSavingsContext } from '../contextData';
import { useStyles } from '../style';

const QuestionDemand = () => {
	const style = useStyles();
	const { handleNextPage, handlePrevPage, setData, data } = useContext(
		dataReturnSavingsContext
	);
	const [optionSelected, setOptionSelected] = useState<boolean | undefined>(
		undefined
	);

	useEffect(() => {
		if (optionSelected === undefined) {
			return;
		}

		setData({ ...data, hasDemand: optionSelected });
	}, [optionSelected]);

	useEffect(() => {
		if (optionSelected === undefined) {
			setData({ ...data, hasDemand: undefined });
			return;
		}

		if (data?.hasDemand === undefined) {
			return;
		}
		handleNextPage();
	}, [data?.hasDemand]);

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divTextCentered}>
					<p style={{ textAlign: 'center' }}>
						Para continuar con tu devolución, por favor contesta la
						siguiente pregunta:
					</p>
					<p style={{ textAlign: 'center' }}>¿Tienes una demanda?</p>
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divButtons3}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={handlePrevPage}
							variant="outlined"
							styles={{
								width: '100%',
								height: '100%',
								border: '0px solid white',
							}}
							label="Regresar"
						/>
					</div>
					<div className={style.Buttons}>
						<CustomButton
							onClick={() => {
								setOptionSelected(false);
								//handleGoPage(0);
							}}
							variant="outlined"
							styles={{
								width: '100%',
								height: '100%'
							}}
							label="No"
						/>
					</div>

					<div className={style.Buttons}>
						<CustomButton
							onClick={() => {
								setOptionSelected(true);
								//handleGoPage(7);
							}}
							variant="solid"
							styles={{
								width: '100%',
							}}
							label="Sí"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default QuestionDemand;
