import { Dialog } from '@mui/material';
import CustomButton from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';

interface IProps {
	show: boolean;
	setShow: (arg: boolean) => void;
	message: any[];
}

const ModalAlertCredit = ({ show, setShow, message }: IProps) => {
	const navigate = useNavigate();
	const styles = useStyles();
	return (
		<Dialog open={show} fullWidth maxWidth="md">
			<div className={styles.container}>
				<h3>Importante</h3>

				{message.map((m) => (
					<div className={styles.containerMessage}>
						<div dangerouslySetInnerHTML={{ __html: m.descripcion }} />
					</div>
				))}

				<div className={styles.containerButton}>
					<div className={styles.buttonComponent}>
						<CustomButton
							label={'Salir'}
							variant="outlined"
							onClick={() => setShow(false)}
						/>
					</div>
					<div className={styles.buttonComponent}>
						<CustomButton
							label={'Aviso de suspension y retención'}
							variant="solid"
							onClick={() =>
								navigate(
									'/mi-credito/avisos-suspension-retencion-modificacion-descuentos'
								)
							}
						/>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default ModalAlertCredit;
