import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material';
import { RoundBtn } from '../../pages/AssociateNssCredit/Styles';

interface IModalProps {
	open: boolean;
	title?: React.ReactChild;
	width?: 'md' | 'lg' | 'xl' | 'sm' | 'xs';
	onClose?: () => void;
	hideCloseButton?: boolean;
	disabledButton?: boolean;
	hideDialogTitle?: boolean;
  buttonLabel?: string;
	buttonVariant?:
	| 'contained'
	| 'outlined'
	| 'text';
  contentDividers?: boolean;
	buttonColor?:
		| 'primary'
		| 'inherit'
		| 'secondary'
		| 'success'
		| 'error'
		| 'info'
		| 'warning'
		| undefined;
	children?: React.ReactChild;
}

const SimpleModal = ({ open, title, width='md', onClose, hideCloseButton=false, buttonLabel="Regresar", buttonColor="primary", buttonVariant="contained", disabledButton=false, hideDialogTitle=false, contentDividers=false, children,}: IModalProps) => {

  return (
    <Dialog
      fullWidth
      open={open}
			onClose={onClose}
			maxWidth={width}
    >
      {!hideDialogTitle&&<DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
				{title}
				{!hideCloseButton&&<IconButton onClick={onClose}>
					<Close />
				</IconButton>}
			</DialogTitle>}
			{contentDividers&&<Divider variant='middle' />}
      <DialogContent>
			  {children}
      </DialogContent>
			{contentDividers&&<Divider variant='middle' />}
      <DialogActions style={{ justifyContent: 'center' }}>
        <RoundBtn onClick={onClose} color={buttonColor} variant={buttonVariant} disabled={disabledButton} style={{ maxWidth: 180 }}>
          {buttonLabel}
        </RoundBtn>
      </DialogActions>
    </Dialog>
  )
}

export default SimpleModal