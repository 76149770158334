import { CREDIT_OPTIONS } from '../constants/products';
import { useController } from './useController'

export const useUserInfo = () => {

  const { dataProcessController } = useController();
  
  const conyuge = [
		CREDIT_OPTIONS.CONYUGAL,
		CREDIT_OPTIONS.CORRESIDENCIAL,
		CREDIT_OPTIONS.FAMILIAR
	].includes(dataProcessController.processInfo?.creditType);

  return {
    conyuge
  }
}