import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: 32,
			paddingBottom: 0,
			[theme.breakpoints.down('md')]: {
				padding: 32,
				paddingBottom: 16,
			},
			textAlign: 'center',
			'&> h2': {
				color: theme.palette.info.main,
				fontSize: '30px',
				margin: 30,
			},
			'&> .button': {
				marginTop: '31px'
			},
		},
		cardContainer: {
			display: 'flex',
			justifyContent: 'center',
		},
		card: {
			boxShadow: '0px 3px 9px #00000029',
			border: '1px solid #C7C6C5',
			maxWidth: '310px',
			'& .actionArea': {
				padding: '31px 10px',
				fontSize: '16px',
				color: theme.palette.info.main,
			}
		},
		cicb: {
			display: 'flex',
			justifyContent: 'center',
			'&> *': {
				maxWidth: '346px',
			}
		}
	}))