import { useEffect, useState } from "react";
import { MIUC_PROCESS_VIEWS } from "../../constants/process";
import { useController } from "../../hooks/useController";
import { MIUC_PRODUCTS } from "../../constants/products";

export const useSinPuntos = () => {

	const [view, setView] = useState<number>();
	const { saveData, getStepView, nextStep, addSelection, dataProcessController } = useController({
    firstPage: MIUC_PROCESS_VIEWS.NO_POINTS,
  });

	useEffect(() => {
		navigation();
	}, [dataProcessController]);

	const navigation = () => {
		const newView = getStepView();
    const credit = dataProcessController.processInfo?.credit

    if (newView === MIUC_PROCESS_VIEWS.NO_POINTS){
      setView(newView);
    }else{
      switch (credit) {
        case MIUC_PRODUCTS.CREDITO_SEGURO:
          setView(MIUC_PROCESS_VIEWS.SECURE_CREDIT);
          break;
        case MIUC_PRODUCTS.APOYO_INFONAVIT:
          setView(MIUC_PROCESS_VIEWS.INFONAVIT_SUPPORT);
          break;
        case MIUC_PRODUCTS.MEJORAVIT:
          setView(MIUC_PROCESS_VIEWS.MEJORAVIT)
          break;
      }
    }
	};

	const onSelect = (option: string) => {
		let credit = '';
		let view: number;
    let total: number;

		if (option === MIUC_PRODUCTS.CREDITO_SEGURO) {
			credit = MIUC_PRODUCTS.CREDITO_SEGURO;
			view = MIUC_PROCESS_VIEWS.SECURE_CREDIT;
			total = 2;
      addSelection({
				title: 'Crédito selecionado para:',
				description: 'Crédito seguro',
			});
		} else if(option === MIUC_PRODUCTS.APOYO_INFONAVIT) {
			credit = MIUC_PRODUCTS.APOYO_INFONAVIT;
			view = MIUC_PROCESS_VIEWS.INFONAVIT_SUPPORT;
      total = 2;
			addSelection({
				title: 'Crédito selecionado para:',
				description: 'Apoyo Infonavit',
			});
		} else {
			credit = MIUC_PRODUCTS.MEJORAVIT;
			view = MIUC_PROCESS_VIEWS.MEJORAVIT;
			addSelection({
				title: 'Crédito selecionado para:',
				description: 'Mejora tu hogar',
			});
      total = 3
		}
		saveData({
			processInfo: {
				credit: credit,
			},
      processData: {
        active: 1,
        total
      }
		});
		nextStep();
		setView(view);
	};

  return {
    view,
    onSelect,
  }
}