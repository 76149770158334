import { useState } from "react";

import { Grid } from "@mui/material";
import { useStyles } from "./styles/styles";
import { AddBoxOutlined, IndeterminateCheckBoxOutlined } from "@mui/icons-material";

interface IProps {
	dataCard: {
		id: string;
		name: string;
		company: string;
		state: string;
		address: string;
		agent: string;
		data: string;
	};
}

const AddressCard = ({ dataCard }: IProps) => {
	const classes = useStyles();
	const [showContent, setShowContent] = useState(false);

	return (
		<div>
			<div className={showContent ? classes.paperCustomMoreShow : classes.paperCustomMore}>
				<Grid container columns={12} spacing={2}>
					<Grid item xs={12} md={9} className={classes.seccionItem}>
						<p className={classes.paperCustomTitle}><b>{dataCard.name}</b></p>
					</Grid>
					<Grid item xs={12} md={3} className={classes.seccionItem}>
						<div className={classes.textOpen} onClick={() => setShowContent(!showContent)}>
							<span>Datos de contacto</span>
							{showContent && (<IndeterminateCheckBoxOutlined />)}
							{!showContent && (<AddBoxOutlined />)}
						</div>
					</Grid>
					<Grid item xs={12} md={12} className={classes.seccionItem}>
						<p className={classes.paperCustomTitle}>{dataCard.company}</p>
						<p className={classes.paperCustomTitleBlue}>{dataCard.state}</p>
					</Grid>
					<Grid item xs={12} md={3} className={classes.seccionItem}>
						<b>Ubicación</b>
					</Grid>
					<Grid item xs={12} md={9} className={classes.seccionItem}>
						{dataCard.address}
					</Grid>
					<Grid item xs={12} md={3} className={classes.seccionItem}>
						<b>Representante</b>
					</Grid>
					<Grid item xs={12} md={9} className={classes.seccionItem}>
						{dataCard.agent}
					</Grid>
					<Grid item xs={12} md={3} className={classes.seccionItem}>
						<b>Datos de Contacto</b>
					</Grid>
					<Grid item xs={12} md={9} className={classes.seccionItem}>
						{dataCard.data}
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

export default AddressCard;