import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Paper } from '@mui/material';

import { HeaderList } from '../../components/HeaderList';
import ModalLoading from '../../components/ModalLoading';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import CustomAlert from '../../components/CustomAlert';
import CustomButton from '../../components/Button';
import Layout from '../../components/Layout';

import CreditNone from './CreditNone';
import DownloadDoc from './DownloadDoc';
import Info from './Info';

import { apiNotices } from '../../api/modules/notices';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { RootState } from '../../store';

interface IDataNotice {
	responsable_nombre: string;
	pdf: string;
	aviso: string;
	codigo_respuesta: string;
}

const Notices = () => {
	const navigate = useNavigate();
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [mensajeBaja, setMensajeBaja] = useState('');
	const [dataNotices, setDataNotices] = useState<IDataNotice>({
		responsable_nombre: '',
		pdf: '',
		aviso: '',
		codigo_respuesta: '',
	});
	const [alert, setAlert] = useState<string>('');

	useEffect(() => {
		const handleOnSubmit = async () => {
			setLoading(true);
			try {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const numero_credito_base64 = await encrypt(credit.toString() || '', key);
				const email_base64 = await encrypt(user?.email.toString() || '', key);
				const { result } = await apiNotices.getNoticesData(
					numero_credito_base64,
					email_base64
				);
				if (['01', '02'].includes(result?.codigo_respuesta)) {
					setPage(1);
				} else if (result?.codigo_respuesta && (result?.codigo_respuesta === '00' || result?.codigo_respuesta.toString() === '102')) {
					setDataNotices(result);
					setPage(2);
				} else {
					setAlert(result?.description || INTERNAL_MESSAGES.ERROR_MESSAGE);
				}
			} catch (error: any) {
				setMensajeBaja(
					error?.description ||
					INTERNAL_MESSAGES.ERROR_MESSAGE
				);
			} finally {
				setLoading(false);
			}
		};
		handleOnSubmit();
	}, [credit, user?.email]);

	return (
		<>
			<ModalLoading loading={loading} />
			<Layout>
				<>
					{page !== 3 && (
						<HeaderList title="Aviso de Suspensión, Retención y Modificación de Descuentos" />
					)}
					{(mensajeBaja || alert) && (
						<Paper
							style={{
								padding: '5% 8%',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							{mensajeBaja && (
								<CustomAlert
									show
									severity="error"
									message={
										<span
											dangerouslySetInnerHTML={{
												__html: mensajeBaja,
											}}
										/>
									}
								/>
							)}
							{alert}
							<CustomButton
								onClick={() => navigate('/')}
								label="Regresar"
								variant="solid"
								styles={{ maxWidth: 200, marginTop: 10 }}
							/>
						</Paper>
					)}
					{page === 1 && <CreditNone setPage={setPage} />}
					{page === 2 && (
						<DownloadDoc setPage={setPage} DataNotice={dataNotices} />
					)}
					{page === 3 && <Info setPage={setPage} />}
				</>
			</Layout>
		</>
	);
};

export default Notices;
