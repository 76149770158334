export const INTERNAL_MESSAGES = {
	ERROR_MESSAGE:
		'Por el momento el servicio no se encuentra disponible, intenta más tarde',
	ERROR_GETTING_INFORMATION: 'Error al obtener la información',
	SOCIAL_SEGURITY_NUMBRE:
		'Complete correctamente el campo de Número de Seguridad Social (NSS)',
	PASSWORD_FIELD: 'Complete el campo de contraseña',
	CAPTCHA_ERROR: 'Error en el Captcha',
	CORRECT_LOGIN: 'Login Correcto',
	CHECK_DATA:
		'Ha ocurrido un error al consultar sus datos, intente nuevamente',
	UNEXPECTED_ERROR: 'Se ha producido un error inesperado',
	ERROR_REQUEST: 'Ha ocurrido un problema con su solicitud',
	SELECT_STATE: 'Selecciona un estado para continuar',
	SEARCH_CRITERIA:
		'No hay resultados para los criterios de búsqueda seleccionados',
	INCOMPLETE_DATA: 'Datos incompletos',
	MOVE_NOT_FOUND: 'No se encontraron movimientos en el periodo seleccionado',
	ERROR_DATE: 'La fecha inicial no puede ser mayor a la fecha final',
	CONNECTION_ERROR:
		'Ha ocurrido un error de conexión al obtener datos, intente nuevamente',
	SAVE_DATA: 'Ha ocurrido un error al guardar sus datos, intente nuevamente',
	VALIDATE_MAIL: 'El usuario ya se encuentra validado con el correo',
	ERROR_OCCURRED: 'Ha ocurrido un error, intente nuevamente',
	WRONG_NUMBER: 'Número Incorrecto',
	STATUS_NSS: 'El NSS se encuentra en estatus Activo',
	ERROR_VALIDATION:
		'Ha ocurrido un error al validar los datos, intente nuevamente',
	FORWARDED_NUMBER: 'Número reenviado',
	EMAIL_SENT: 'Se reenvió el correo electrónico',
	EMAIL_NOT_SENT: 'No se pudo reenviar el correo electrónico',
	CHECK_PHONE_NUMBER:
		'Revisa tu número de celular proporcionado ya que no coinciden e intenta de nuevo',
	CHECK_EMAIL:
		'Revisa tu correo electrónico proporcionado ya que no coinciden e intenta de nuevo',
	LAST_TEN_DIGITS_CELL_PHONE:
		'Debes de escribir los últimos diez dígitos de tu número de teléfono celular',
	CELL_PHONE_NOT_EXIST:
		'El número de celular que nos proporcionaste no existe.',
	NOT_EXISTING_MAIL:
		'El correo eléctronico que nos proporcionaste no existe.',
	PROVIDES_CURP: 'Escribe tu Clave Única de Registro de Población (CURP)',
	CURP_MISSING_CHARACTERS:
		'La Clave Única de Registro de Población (CURP) debe contener 18 caracteres, favor de verificarlo',
	ACTIVE_ACCOUNT:
		'Ya tienes una cuenta activa, si no recuerdas tu usuario o contraseña, cámbiala en la sección de Olvidé mi contraseña',
	USER_BLOCKED:
		'El usuario se encuentra bloqueado, para poder desbloquearlo favor de visitar el siguiente link: Restaurar usuario',
	PROCESS_PROCESS:
		'Actualmente tienes un proceso en Trámite de Devolución de Pagos en Exceso, conclúyelo y en caso de ser necesario inicia uno nuevo',
	NO_FILE: 'No se eligió ningún archivo',
	ERROR_GETTING_DATA:
		'Ha ocurrido un error al obtener los datos, intente nuevamente',
	ERROR_GETTING_PDF:
		'Ha ocurrido un error al obtener el PDF, intente nuevamente',
	NO_FILE_TO_DOWNLOAD: 'No se ha encontrado un archivo a descargar',
	UPLOAD_FILES:
		'Ha ocurrido un error al subir los archivos, intente nuevamente',
	FILE_SIZE: 'El tamaño total de los archivos no debe de ser mayor a 20 MB.',
	FILE_NOT_SUPPORTED: 'Archivo no soportado',
	PRIVATE_INSTRUMENT_NUMBER: 'Número del instrumento privado',
	CFDI_PROOF: 'El comprobante CFDI es obligatorio, favor de revisar',
	SEARCH_CRITERIA_TWO:
		'No se encontraron casos para los criterios de búsqueda',
	PUBLIC_DEED_NUMBER: 'Número de escritura pública',
	PREPARATION_PRIVATE_INSTRUMENT: 'Fecha elaboración del instrumento privado',
	DATE_ELABORATION_PUBLIC_DEED:
		'Fecha de elaboración de la escritura pública',
	UNABLE_LOAD_PDF: 'No se ha podido descargar el PDF',
	NO_FILE_TWO: 'No hay Archivo',
	ERROR_DOWNLOAD_CERTIFICATE: 'Error al descargar su certificado',
	NO_ASSOCIATED_CASE: 'No existe un número de caso asociado',
	CORRECT_DIS: 'Eliminación correcta',
	ERROR_RETRIEVING_DATA: 'Eliminación correcta',
	APPLICATION_COMPLETED: 'Su solicitud se ha completado',
	INCORRECT_FORMAT:
		'Tu documento no está en el formato, recuerda que sólo puedes enviar documentos con los siguientes formatos: jpg, png, tiff, pdf, mpeg, avi, wmv, mp4 o mp3.',
	MAIL_DOES_NOT_MATCH:
		'El correo electrónico que proporcionaste no coincide, verifícalo e intenta de nuevo',
	WRITE_PHONE_NUMBER: 'Escribe tu número de teléfono celular',
	VERIFY_CELL_PHONE:
		'El número de teléfono celular que proporcionaste no coincide, verifícalo e intenta de nuevo',
	DATA_ERROR:
		'Ha ocurrido un error al actualizar sus datos, intente nuevamente',
	INCONSISTENT_SSN:
		'El Número de Seguridad Social (NSS) que proporcionaste presenta inconsistencias, para más información comunícate a Infonatel',
	NOT_CANDIDATE_FOR_RESTRUCTURING_SUPPORT:
		'Por el momento no eres candidato para recibir algún apoyo de reestructura, posiblemente tu crédito no se encuentre con problemas graves de pago. Te invitamos a que sigas manteniendo el sano manejo de tu crédito o regularices el pago de tu crédito',
	NOT_CREATE_ACCOUNT:
		'Aún no tienes Mi cuenta Infonavit, te invitamos a que crees tu cuenta para poder utilizar los servicios digitales del Infonavit.',
	NOT_REGISTERED:
		'No encontramos alguna cuenta con la información proporcionada, te recomendamos registrarte, o bien, verifica e intenta con otro dato.',
};
