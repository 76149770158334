import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const useStyles = makeStyles((theme: Theme) => ({
	mainContainer: {
		padding: '1em 2em',
		'&>.actions-container': {
			display: 'flex',
			justifyContent: 'space-evenly',
			marginTop: 60,
			padding: '0 250px',
			'&>button': {
				width: 200,
			},
			[theme.breakpoints.down('lg')]: {
				padding: '0 170px',
			},
			[theme.breakpoints.down('md')]: {
				padding: '0 40px',
			},
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column-reverse',
				padding: 0,
				'&>button': {
					width: '100%',
					margin: '5px 0',
				},
			},
		},
		'&>.form-container': {
			padding: '0 10vw',
			[theme.breakpoints.down('sm')]: {
				padding: '0 5vw',
			},
			'&>.notas-helper': {
				fontSize: 14,
				color: grey[600],
			},
		},
	},
}));
