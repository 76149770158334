import { useState } from 'react';
import ActivationMedium from '../activationMedium';
import NipGenaration from '../nipGeneration';
import HalfUnlock from '../../NipInfonatel/UnlockPin/halfUnlock';
import NipActivationChange from './nipActivationChange';
import TabsCustom from '../../../components/TabsCustom';

interface Validations {
	[index: number]: string;
}

interface IData {
	blockNip: boolean;
	typeInformation: number;
	nip: string;
	validations: Validations;
	nipsRecents: Validations;
}

const ChangeNip = ({ blockNip, typeInformation, nip, validations, nipsRecents }: IData) => {
	const [isEmail, setIsEmail] = useState(false);
	const [tabs, setTabs] = useState(0);

	const handleFirstStep = async () => {
		setTabs(tabs + 1);
	};

	return (
		<div>
			<TabsCustom
				selectIndex={tabs}
				activeStep
				setSelectIndex={setTabs}
				components={[
					{
						title: 'Medio de cambio de NIP',
						component: (
							<HalfUnlock
								onContinue={handleFirstStep}
								isEmail={isEmail}
								setIsEmail={setIsEmail}
								blockNip={blockNip}
								typeInformation={typeInformation}
							/>
						),
					},
					{
						title: 'Código de confirmación',
						component: (
							<ActivationMedium
								onContinue={handleFirstStep}
								isEmail={isEmail}
								onCloseError={() => { }}
								reSend={() => { }}
								typeInformation={typeInformation}
								nip={nip}
								action={'cambiar'}
							/>
						),
					},
					{
						title: 'Nuevo NIP',
						component: (
							<NipGenaration
								onContinue={handleFirstStep}
								typeInformation={typeInformation}
								validations={validations}
								nipsRecents={nipsRecents}
							/>
						),
					},
					{
						title: 'Cambio de NIP',
						component: (
							<NipActivationChange onContinue={handleFirstStep} />
						),
					},
				]}
			/>
		</div>
	);
};

export default ChangeNip;
