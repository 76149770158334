/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Link, Paper, Grid, Box } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
//Assets
import imgAgendaCita from '../../../assets/img/Agenda-tu-cita.png';
import imgIncrement from '../../../assets/img/incrementa-ahorro-banner.png';
// import imgInsSocio from '../../../assets/img/inscribete-socio.png';
import imgSaberMas from '../../../assets/img/taller-saber-mas/Banner_SaberMas.png';
import ViewMoreButton from '../components/viewMoreButton';
import { CheckCircleOutlined } from '@mui/icons-material';
import { useProfile } from '../../MIUC/hooks/useProfile';
import { MIUC_PRODUCTS_COMPONENT } from '../../MIUC/utils/home.utils';
import CustomAlert from '../../../components/CustomAlert';

const SavernoNoPunctuation = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	const { getInitialProfileHome, errorMesage } = useProfile({
		initProcess: true,
	});

	const [hoverStates, setHoverStates] = useState(
		MIUC_PRODUCTS_COMPONENT.map(() => false)
	);

	const handleMouseEnter = (index: any) => {
		setHoverStates((prevStates) => {
			const newStates = [...prevStates];
			newStates[index] = true;
			return newStates;
		});
	};

	const handleMouseLeave = (index: any) => {
		setHoverStates((prevStates) => {
			const newStates = [...prevStates];
			newStates[index] = false;
			return newStates;
		});
	};

	const itemsList = [
		{
			id: 1,
			text: 'En qué puedes usar tu crédito Infonavit.',
		},
		{
			id: 2,
			text: 'El monto y las condiciones financieras del crédito que se te puede otorgar y los',
		},
		{
			id: 3,
			text: 'Requisitos y documentos para solicitarlo.',
		},
	];

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={6} lg={4}>
				<Paper className={classes.paperHeight}>
					<div className={classes.titleCard}>
						<h3>¡Ya cuentas con los requisitos para un crédito!</h3>
					</div>
					<div className={classes.textCard}>
						<p className={classes.fontbold}>
							Ingresa a Quiero un crédito, en Precalificación y
							puntos y conoce:
						</p>
						<div style={{ marginLeft: '0', marginRight: '0' }}>
							{itemsList.map((item) => (
								<Box key={item.id} className={classes.lineText}>
									<CheckCircleOutlined
										color="info"
										style={{
											marginRight: 5,
											width: 14,
											height: '90%',
										}}
									/>{' '}
									<span
										style={{
											marginBottom: 5,
											textAlign: 'left',
										}}
									>
										{item.text}
									</span>
								</Box>
							))}
						</div>
						<p>
							Si te interesa comprar vivienda tienes la opción de
							hacer tu solicitud en línea en Precalificación y
							puntos o acudir al Centro de Servicio Infonavit más
							cercano.
						</p>
						<p>
							Incrementa tu ahorro en el Infonavit con
							Aportaciones extraordinarias. Lo que ahorres se
							sumará al monto del crédito que solicitaste o al
							importe de tu Subcuenta de Vivienda que recibirás
							cuando te pensiones.
						</p>
					</div>
					<br />
				</Paper>
			</Grid>
			<Grid item sm={12} md={6} lg={4}>
				<Grid item lg={12}>
					<Paper className={classes.mainContainerCard}>
						<div className={classes.containerPaperList}>
							<label className={classes.titleCardCredit}>
								¿Qué quieres hacer con tu crédito Infonavit?
							</label>

							<p className={classes.textCardPaperList}>
								Para conocer tus opciones y condiciones de
								crédito, selecciona el destino de tu interés.
							</p>
							{MIUC_PRODUCTS_COMPONENT.map(
								(productEle, index) => (
									<div
										key={productEle.alt}
										className={classes.containerCardLine}
										onMouseEnter={() =>
											handleMouseEnter(index)
										}
										onMouseLeave={() =>
											handleMouseLeave(index)
										}
									>
										<img
											src={productEle.icon}
											alt={'iconMiuc' + index}
											className={classes.iconCompra}
										/>
										<label className={classes.labelBtnLine}>
											{productEle.name}
										</label>

										<div>
											<ViewMoreButton
												onclick={() =>
													getInitialProfileHome(
														productEle
													)
												}
												labelText="Iniciar"
												hover={hoverStates[index]}
											/>
										</div>
									</div>
								)
							)}
							<CustomAlert
								message={errorMesage}
								show={Boolean(errorMesage)}
								severity="warning"
							/>
							<p className={classes.labelRestrictions}>
								<span>*</span>Aplican restricciones,{' '}
								<Link
									href="/mi-tramite-credito/precalificacion-puntos"
									color="primary"
								>
									conoce más.
								</Link>
							</p>
						</div>
					</Paper>
				</Grid>
			</Grid>
			<Grid item sm={12} md={6} lg={4}>
				<Grid container spacing={2}>
					<Grid item sm={12} md={12} lg={12}>
						<div
							onClick={() =>
								navigate('/contactanos/haz-una-cita')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgAgendaCita}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item sm={12} md={12} lg={12}>
						<div
							onClick={() =>
								navigate(
									'/mi-ahorro/haz-aportaciones-extraordinarias'
								)
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgIncrement}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item sm={12} md={12} lg={12}>
						<div
							onClick={() =>
								navigate('/mi-tramite-credito/taller-saber-mas')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgSaberMas}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SavernoNoPunctuation;
