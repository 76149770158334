import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	fontRed: {
		color: 'red',
	},
	borderBlack: {
		border: '1px solid black',
	},
	borderRed: {
		border: '1px solid red',
	},
	borderGreen: {
		border: '1px solid green',
	},
	borderBlue: {
		border: '1px solid blue',
	},
	pad: {
		paddingleft: '20%',
		paddingright: '20%',
	},
	Title: {
		fontWeight: 'bold',
		fontSize: 22,
		color: 'black',
		display: 'block',
		clear: 'both',
		marginBotton: '20px',
	},
	subtitle: {
		fontWeight: 'bold',
		fontSize: 16,
		color: 'black',
		display: 'block',
		clear: 'both',
	},
	marginInput: {
		border: '1px solid red',
		paddingLeft: 65,
		paddingRight: 65,
		[theme.breakpoints.down('md')]: {
			marginRight: 1,
			marginLeft: 1,
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: 1,
			marginLeft: 1,
		},
	},
	divDelete: {
		padding: '5px 20px',
		[theme.breakpoints.down('sm')]: {
			padding: '5px',
		},
	},
	containerCaptcha: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		marginTop: 20,
		marginBottom: 20,
	},
}));
