import { HeaderList } from '../../HeaderList';

const AppraisalManagement = () => {
	return (
		<>
			<strong>
				Una vez que hayas elegido la unidad de valuación, prepara la
				siguiente documentación:
			</strong>

			<HeaderList
				listSizeIcon={18}
				list={[
					<>
						Escritura antecedente de propiedad o régimen de
						propiedad en condominio.
					</>,
					<>Boleta predial (última).</>,
					<>Boleta de agua (última).</>,
					<>
						Plano o croquis del inmueble (si no cuentas con él,
						pueden hacértelo con un costo adicional).
					</>,
					<>
						Recibos de servicios para comprobar que es vivienda
						usada o terminación de obra (en caso de que la escritura
						no lo indique).
					</>,
					<>
						Copia de la Identificación oficial del vendedor y del
						comprador.
					</>,
					<>
						Adicionalmente te pedirán llenar una Solicitud de
						avalúo.
					</>,
				]}
			/>

			<p>
				<strong>Comunícate y haz una cita:</strong>
			</p>

			<HeaderList
				listSizeIcon={18}
				list={[
					<>
						Contacta a la unidad de valuación que elegiste, te
						solicitarán domicilio completo del inmueble y acordarán
						la visita.
					</>,
					<>
						Indícale que se trata de un trámite de crédito con
						Infonavit pues se tienen tarifas preferenciales.
					</>,
				]}
			/>
		</>
	);
};

export default AppraisalManagement;
