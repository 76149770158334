import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { formatDate } from "../../../utils/dates";
import { MIUC_PRODUCTS } from "../constants/products";
import { encodeAllJSONData } from "../../../utils/encrypt";
import { apiMIUC } from "../../../api/modules/MIUC";

interface CreateProps {
    credit: string
    subtipoTrazabilidad?: string
}

export const useTrazabilidad = () => {
	const { user } = useSelector((state: RootState) => state.session);

    const createTrazabilidad = async ({credit, subtipoTrazabilidad}: CreateProps) => {
		try {
            let dataRequest = {};

            switch(credit){
                case MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION:
                    dataRequest = {
                        nss: user?.nss?.toString() || '',
                        email: user?.email.toString() || '',
                        telefono: user?.telefonoCelular.toString() || '',
                        numeroCredito: credit.toString() || '',
                    };
                    break;
                default:
                    dataRequest = {
                        nss: user?.nss?.toString() || '',
                        curp: user?.curp || '',
                        rfc: user?.rfc || '',
                        nrp: user?.curp || '',
                        nombre: user?.nombres || '',
                        apellidoPaterno: user?.apPaterno || '',
                        apellidoMaterno: user?.apMaterno || '',
                        fecha: formatDate('dd.MM.yyyy'),
                        mensaje:
                            credit === MIUC_PRODUCTS.COMPRA
                                ? `PRECALIFICACION ${formatDate('dd/MM/yyyy')} ${credit}`
                                : 'MCI',
                        subtipoTrazabilidad: subtipoTrazabilidad || 'ZN01',
                    };
                    break;
            }
			const newRequest = await encodeAllJSONData(
				{ data: JSON.stringify(dataRequest) },
				user?.k || ''
			);

            switch(credit){
                case MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION:
                    await apiMIUC.trazabilidadSueloConstruccion(newRequest);
                    break;
                default:
                    await apiMIUC.trazabilidadCRM(newRequest);
                    break;
            }
			
		} catch (error) {}
	};

    return {
        createTrazabilidad
    }
}