import { ICredit } from '../../interfaces/credit';

export interface CreditState {
	availableCredits: ICredit[];
	credit: string;
	isLoading: boolean;
}

export const SET_AVAILABLE_CREDITS = 'credit/setAvailableCredits';
export const SET_SELECTED_CREDIT = 'credit/setSelectedCredit';
export const IS_LOADING = 'credit/isLoading';
export const CLEAR_CREDITS = 'credit/clearCredits';

export const INITIAL_STATE: CreditState = {
	availableCredits: [],
	credit: '0',
	isLoading: false,
};
