// DEPENDENCIES
import { useState } from 'react';
import { Box, Divider, Grid, Paper } from '@mui/material';
// COMPONENTS
import Layout from '../../../../components/Layout';
import CustomAlert from '../../../../components/CustomAlert';
import { ListRequirements } from './components/listRequirements';
import { HeaderList } from '../../../../components/HeaderList';
import { useNavigate } from 'react-router-dom';
import { useController } from '../../hooks/useController';
import { onlineExceptions, onlineProgressFlow } from '../../../CreditRequest/MIUC/utils';
import { centeredItems, useGeneralStyles } from '../../views/styles/general.styles';
import ButtonGroup from '../../views/hipoteca/components/buttonGroup';
import OnlineRequestModal from './components/onlineRequestModal';
import theme from '../../../../config/theme';
import { Help } from '@mui/icons-material';
import ChooseLivingModal from '../../../CreditRequest/components/ChooseLivingModal';
import { MIUC_PRODUCTS } from '../../constants/products';
import ResumeCard from '../ResumeCard';
import FlowCard from '../FlowCard';

import logoMejRep from '../../../../assets/img/productsMIUC/Mejoravit_REPARA_2023.png';
import logoMejRen from '../../../../assets/img/productsMIUC/Mejoravit_RENUEVA_2023.png';
import OldFlowCard from '../../../CreditRequest/components/FlowCard';
import BarLocation from '../BarLocation';

import mejoravitRepara from '../../../../assets/img/productsMIUC/Mejoravit_REPARA_2023.png';
import mejoravitRenueva from '../../../../assets/img/productsMIUC/Mejoravit_RENUEVA_2023.png';
import { grey } from '@mui/material/colors';


export const OnlineCredit = () => {
  const [requestModal, setRequestModal] = useState(false);
  const [livingModal, setLivingModal] = useState(false);

  const navigate = useNavigate();
  const { dataProcessController, calcProgress } = useController();
  const { credit: destino, tipoVivienda, creditType, productSelect } = dataProcessController.processInfo;
  const generalStyle = useGeneralStyles()
  const destinoFinal = destino === MIUC_PRODUCTS.MEJORAVIT 
  ? MIUC_PRODUCTS.REMODELACION 
  : destino;

  return (
    <Layout>
      <>
        <ChooseLivingModal open={livingModal} setOpen={setLivingModal} />
        <OnlineRequestModal
          open={requestModal}
          setOpen={setRequestModal}
          creditType={creditType}
        />
        <Grid container spacing={2}>
          {
            destinoFinal === MIUC_PRODUCTS.COMPRA && 
            <Grid item xs={12}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}>
                <h2
                  style={{
                    color: theme.palette.info.main,
                    ...centeredItems,
                  }}
                >
                  Solicitud de crédito en línea{' '}
                  <Help
                    color="primary"
                    onClick={() => setLivingModal(true)}
                    style={{
                      marginLeft: 5,
                      fontSize: 18,
                      cursor: 'pointer',
                    }}
                  />
                </h2>
              </Box>
            </Grid>
          }
          <Grid item xs={12} md={8}>
            <Paper >
              {
                destinoFinal === MIUC_PRODUCTS.REMODELACION &&
                <Box sx={{
                  // boxShadow: '0px 3px 14px #00000014',
                  backgroundColor: grey[100],
                  paddingY: 2,
                }}>
                  <h2
                    style={{
                      textAlign: 'center',
                      color: '#293990',
                    }}
                  >
                    Solicitud de crédito en línea{' '}
                    <Help
                      color="primary"
                      onClick={() => setLivingModal(true)}
                      style={{
                        marginLeft: 5,
                        fontSize: 18,
                        cursor: 'pointer',
                      }}
                    />
                  </h2>
                </Box>
              }
              <Box className={generalStyle.container}>
                {
                  destinoFinal === MIUC_PRODUCTS.COMPRA
                  ? <SolicitudCompra destinoFinal={destinoFinal} />
                  : <SolicitudMejoravit 
                    destinoFinal={destinoFinal} 
                    selectedProduct={dataProcessController.processInfo?.tipoMVIT}
                  />
                }

                <Divider variant="middle" style={{ margin: '20px 0' }} />
                <ButtonGroup
                  buttons={[
                    {
                      label: 'Regresar',
                      execute: () => navigate(-1),
                      variant: 'outlined'
                    },
                    {
                      label: 'Iniciar registro',
                      execute: () => setRequestModal(true),
                      variant: 'solid'
                    }
                  ]}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            {
              destinoFinal === 'L2' &&
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <ResumeCard noTopMargin />
              </Box>
            }

            <Box>
              {
                destinoFinal === 'L2'
                ? <FlowCard
                  title={"Tu selección"}
                  flow={dataProcessController.processInfo?.selection}
                  progress={calcProgress()}
                />
                : <OldFlowCard
                  title={'Registro de solicitud'}
                  hideProgress
                  flow={onlineProgressFlow(destinoFinal)}
                />
              }
            </Box>
          </Grid>
        </Grid>
      </>
    </Layout>
  );
};

const CustomMessage = (
  <CustomAlert
    message={
      <p style={{ margin: 0, textAlign: 'left' }}>
        Recuerda que para darle seguimiento a tu solicitud en
        línea, es necesario que entres a la sección de
        Precalificación y puntos, donde podrás visualizar
        mensajes relacionados a tu proceso.
      </p>
    }
    show
    severity="warning"
  />
)
const parrafoRegistro = (
  <p>
    Al iniciar el registro de tu solicitud de crédito
    utilizaremos la información que seleccionaste en tu
    recorrido por este servicio, así como la que tienes
    registrada en tu perfil, de modo que es importante que
    revises que tu selección es la correcta, en caso contrario,
    bastará con que regreses y elijas nuevamente.
  </p>
)

const SolicitudCompra = ({ destinoFinal }: {
  destinoFinal: string,
}) => {
  return (
    <Box pt={'20px'}>
      {CustomMessage}
      <div>
        {parrafoRegistro}
        <p>
          Si tu selección de crédito es conyugal, familiar o
          corresidencial considera que únicamente quien inicie el
          registro quedará como titular siendo el único que pueda
          dar continuidad al trámite.
        </p>
        
        <h4>Registro de solicitud</h4>
        <HeaderList
          listSizeIcon={18}
          list={onlineProgressFlow(destinoFinal).map(item => (item.description))}
        />

        <h4>Requisitos para hacer el trámite en línea:</h4>
        <HeaderList
          listSizeIcon={18}
          list={ListRequirements(destinoFinal)}
        />
        <p>
          Te sugerimos consultar en cada paso la sección{' '}
          <b>información que debes conocer</b>.
        </p>

        <h4>*Excepto si:</h4>
        <HeaderList
          listSizeIcon={18}
          list={onlineExceptions.map((text) => (
            <span key={text}>{text}</span>
          ))}
        />
      </div>
    </Box>
  )
}

const SolicitudMejoravit = ({ destinoFinal, selectedProduct }: {
  destinoFinal: string,
  selectedProduct: string,
}) => {
  const imgStyles = { 
    width: '200px',
    margin: '16px 32px'
  };
  const registroStyles = {
    background: '#f2f2f2',
    borderRadius: '4px',
    padding: '16px',
    textAlign: 'center',
    marginBottom: '32px',

  };
  return (
    <Box >
      <BarLocation
        destino={'L4'}
        creditType={'I'}
        producto={selectedProduct}
        title={'Ampliar o reparar'}
        customTitle={true}
        hideTuSeleccion={true}
        showExtraTitle={
            (
              <Box sx={{
                textAlign: 'center',
              }}>
                <img
                  alt="logoMejoraVit"
                  src={
                    selectedProduct === 'MREP'
                          ? mejoravitRepara
                          : mejoravitRenueva
                  }
                  width={120}
                />
              </Box>
            )
        }
      />
      {CustomMessage}
      {/* <Box sx={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <img src={logoMejRep} alt="" style={imgStyles} />
        <img src={logoMejRen} alt="" style={imgStyles} />
      </Box> */}

      <div>
        {parrafoRegistro}
        {/* <h4>¿Tienes o has tenido alguno de los siguientes créditos?</h4> */}
        <h4>Requisitos para hacer el trámite en linea:</h4>
        <HeaderList
          listSizeIcon={18}
          list={ListRequirements(destinoFinal).filter((i, j) => ![0,1].includes(j))}
        />
        <p>
          Deberás ingresar la información que te sea solicitada y
          cargar tu expediente digitalizado, una vez hecho esto tu
          expediente será revisado y te haremos saber por correo
          electrónico el resultado.
        </p>
        {/* <Divider sx={{ marginY: '32px'}} />

        <Box sx={registroStyles}>
          <h3 style={{ margin: '6px'}}>Registro de solicitud</h3>
          <p style={{ margin: '6px' }}>Antes de iniciar tu solicitud en línea consulta la siguiente información</p>
        </Box>
        <HeaderList
          listSizeIcon={18}
          list={onlineProgressFlow(destinoFinal).map(item => (item.description))}
        /> */}
      </div>
    </Box>
  )
}
export default OnlineCredit;
