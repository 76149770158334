import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		CustomUl: {
			textAlign: 'left',
			listStyle: 'inside',
			paddingLeft: '5px',
		},
		content: {
			textAlign: 'center',
		},
		mainContainer: {
			padding: '60px',
			justifyContent: 'center',
			[theme.breakpoints.down('md')]: {
				padding: '20px',
			},
		},
		productBox: {
			backgroundColor: '#F2F2F2',
			maxWidth: '100%',
			margin: '5px',
			padding: '10px',
		},
		centeredItems: {
			display: 'flex',
			justifyContent: 'center',
		},
		productsDisplay: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
		},
		divider: {
			minWidth: '100%',
			margin: '20px 0px 20px 0px !important',
		},
		selectionList: {
			maxWidth: '80%',
			textAlign: 'start',
			marginTop: '20px',
		},
		textDisclaimer: {
			marginTop: '20px',
			textAlign: 'left',
			padding: '10px 20px',
			backgroundColor: 'rgb(250, 226, 149)',
			borderRadius: '6px',
			'&>p': {
				margin: 0,
				color: 'rgb(227, 87, 0)',
				fontSize: '0.875rem',
			},
		},
		bottonsContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'&>button': {
				width: 260,
				[theme.breakpoints.down('md')]: {
					margin: '8px 8px',
				},
				margin: '32px 8px',
			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
		},
	})
);
