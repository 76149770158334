import API from '..';
class ApiNotices extends API {
	getNoticesData(numero_credito: string, email: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/avisos-suspension-retencion-descuento-consulta`,
				method: 'POST',
				data: { numero_credito, email },
			})
		);
	}
}

export const apiNotices = new ApiNotices('');
