import { useState, useEffect } from 'react';

import { Grid } from '@mui/material';
import { useStyles } from '../Register/styles';
import CustomButton from '../../components/Button';
import CustomAlert from '../../components/CustomAlert';
import { IAlert } from '../../interfaces/alert';
import {
	Checkbox,
	FormControlLabel,
} from '@mui/material';

interface IUserData {
	nombre: string;
	apPaterno: string;
	apMaterno: string;
	curp: string;
	rfc: string;
	fechaNacimiento: string;
	sexo: string;
}

interface IRegisterViewsProps {
	onContinue: () => void;
	goBack: () => void;
	createCaso: () => void;
	infoUser: string;
	hasError: IAlert;
	onCloseError: () => void;
	dataUser: IUserData;
	messageVerification: string;
}

const VerificationUser = (props: IRegisterViewsProps) => {
	const { onContinue, infoUser, goBack, hasError, onCloseError, createCaso, dataUser, messageVerification } = props;
	const [page, setPage] = useState(1)
	const [acceptChange, setAcceptChange] = useState(false)
	const classes = useStyles();

	useEffect(() => {
		if (messageVerification.length > 0) {
			setPage(3)
		}
	}, [messageVerification]);

	return (
		<div style={{ width: '100%' }}>
			<Grid container className={classes.container}>
				<Grid item xs={0} sm={0} md={3} lg={2}></Grid>
				<Grid item xs={12} sm={12} md={6} lg={8}>
					{page === 1 && (
						<div className={classes.containerInformation}>
							<p>
								Con los datos que proporcionaste tenemos registrado el
								siguiente nombre:
							</p>
							<p className={classes.labelName}>{infoUser}</p>
							<p>
								Si tu nombre y apellido no aparecen igual que en tu acta de
								nacimiento, corrígelos en tu Afore; (es normal que las "Ñ"
								aparezca sustituidas por otros signos, en esos casos no tienes
								que corregirlos).
							</p>
							<p>¿Tu nombre es correcto?</p>
							<div style={{ width: '100%' }}>
								<CustomAlert
									show={hasError.show}
									severity={hasError.severity}
									message={hasError.message}
									onClose={onCloseError}
								/>
							</div>
							<div className={classes.buttonCenter}>
								<CustomButton
									label="No"
									onClick={() => setPage(2)}
									variant="outlined"
									styles={{ marginRight: 10 }}
								/>
								<CustomButton
									label="Sí"
									onClick={onContinue}
									variant="solid"
									styles={{ marginLeft: 10 }}
								/>
							</div>
						</div>
					)}
					{page === 2 && (
						<div className={classes.containerInformationUpdate}>
							<p>
								De acuerdo con el Registro Nacional de Población (RENAPO), la CURP registrada tiene asociados los siguientes datos.
							</p>
							<Grid container>
								<Grid item xs={6} sm={6} md={6} lg={6}>
									<p style={{ textAlign: 'right', padding: '0 5px' }}><b>Clave Única de Registro de Población (CURP):</b></p>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6}>
									<p style={{ textAlign: 'left', padding: '0 5px' }}>{dataUser.curp}</p>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6}>
									<p style={{ textAlign: 'right', padding: '0 5px' }}><b>Nombre(s):</b></p>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6}>
									<p style={{ textAlign: 'left', padding: '0 5px' }}>{dataUser.nombre}</p>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6}>
									<p style={{ textAlign: 'right', padding: '0 5px' }}><b>Primer apellido:</b></p>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6}>
									<p style={{ textAlign: 'left', padding: '0 5px' }}>{dataUser.apPaterno}</p>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6}>
									<p style={{ textAlign: 'right', padding: '0 5px' }}><b>Segundo apellido:</b></p>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6}>
									<p style={{ textAlign: 'left', padding: '0 5px' }}>{dataUser.apMaterno}</p>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6}>
									<p style={{ textAlign: 'right', padding: '0 5px' }}><b>Fecha de nacimiento:</b></p>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6}>
									<p style={{ textAlign: 'left', padding: '0 5px' }}>{dataUser.fechaNacimiento}</p>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6}>
									<p style={{ textAlign: 'right', padding: '0 5px' }}><b>Género:</b></p>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6}>
									<p style={{ textAlign: 'left', padding: '0 5px' }}>{dataUser.sexo === 'H' ? 'Hombre' : 'Mujer'}</p>
								</Grid>
							</Grid>
							<FormControlLabel
								key={'1'}
								value={'1'}
								label={'Acepto que se realice la actualización de mis datos en los sistemas del Infonavit'}
								control={
									<Checkbox
										checked={acceptChange}
										onChange={() => setAcceptChange(!acceptChange)}
									/>
								}
							/>
							<div style={{ width: '100%' }}>
								<CustomAlert
									show={hasError.show}
									severity={hasError.severity}
									message={hasError.message}
									onClose={onCloseError}
								/>
							</div>
							<div className={classes.buttonCenter} style={{ width: '100%', maxWidth: '570px', margin: '20px auto 0' }}>
								<CustomButton
									label="Cancelar"
									onClick={() => {
										setPage(1)
										goBack()
									}}
									variant="outlined"
									styles={{ marginRight: 10 }}
								/>
								<CustomButton
									disabled={!acceptChange}
									label="Continuar"
									onClick={() => {
										createCaso()
									}}
									variant="solid"
									styles={{ marginLeft: 10 }}
								/>
							</div>
						</div>
					)}
					{page === 3 && (
						<div className={classes.containerInformation}>
							<p>
								{messageVerification}
							</p>
							<div className={classes.buttonCenter} style={{ width: '100%', maxWidth: '260px', margin: '20px auto 0' }}>
								<CustomButton
									disabled={hasError.show}
									label="Aceptar"
									onClick={onContinue}
									variant="solid"
									styles={{ marginLeft: 10 }}
								/>
							</div>
						</div>
					)}
				</Grid>
				<Grid item xs={0} sm={0} md={3} lg={2}></Grid>
			</Grid>
		</div>
	);
};

export default VerificationUser;
