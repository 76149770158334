import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
			minHeight: '80vh',
		},
	})
);
