import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HeaderList } from '../../../../components/HeaderList';
import BaseModal from '../../../../components/Modal';

interface IModalMiEspacion {
	openModal: boolean;
	setOpenModal: (value: boolean) => void;
}

const ModalMiEspacio = ({ openModal, setOpenModal }: IModalMiEspacion) => {
	const navigate = useNavigate();

	return (
		<BaseModal
			title="Mi espacio Infonavit"
			open={openModal}
			onClose={() => setOpenModal(!openModal)}
		>
			<div>
				<p>Recuerda que ahora para:</p>
				<HeaderList
					list={[
						<>
							<b>Conocer</b>, paso a paso, las acciones que debes
							realizar para formalizar tu crédito
						</>,
						<>
							<b>Obtener</b>, toda la información y condiciones
							financieras de tu crédito
						</>,
						<>
							<b>Descargar</b>, los documentos necesarios de tu
							trámite de credito tales como:
							<HeaderList
								list={[
									'Aviso de Retención.',
									'Aviso de Suspensión.',
									'Solicitud de Crédito.',
									'Constancia de Ecotecnologías',
									'Constancia de Hogar a tu medida.',
								]}
							/>
						</>,
					]}
				/>
				<p>
					Deberás ingresar a{' '}
					<Link onClick={() => navigate('/')}>
						Mi Cuenta Infonavit
					</Link>
					, donde te daremos toda la información acerca de tu trámite.
				</p>
			</div>
		</BaseModal>
	);
};
export default ModalMiEspacio;
