import { MIUC_BASIC_STEPS } from './basicSteps';
import { MIUC_PROCESS_VIEWS } from './process';
import { MIUC_PRODUCTS } from './products';

export const MIUC_PROCESS_STEPS = {
	[MIUC_PRODUCTS.COMPRA]: {
		codigo: MIUC_PRODUCTS.COMPRA,
		steps: [
			MIUC_PROCESS_VIEWS.CREDIT_OPTIONS,
			MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO,
			MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT,
			MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
			MIUC_PROCESS_VIEWS.NEXT_STEPS,
		],
	},
	[MIUC_PRODUCTS.CONSTRUCCION]: {
		codigo: MIUC_PRODUCTS.CONSTRUCCION,
		steps: [
			MIUC_PROCESS_VIEWS.CREDIT_OPTIONS,
			MIUC_PROCESS_VIEWS.CREDIT_QUESTIONS_CONSTRUIR,
			MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO,
			MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT,
			MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
		],
	},
	[MIUC_PRODUCTS.MEJORAVIT]: {
		codigo: MIUC_PRODUCTS.MEJORAVIT,
		steps: [
			MIUC_PROCESS_VIEWS.INFORMATIVE_CONTENT,
			MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT,
			MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
			MIUC_PROCESS_VIEWS.NEXT_STEPS
		],
	},
	[MIUC_PRODUCTS.HIPOTECA]: {
		codigo: MIUC_PRODUCTS.HIPOTECA,
		steps: MIUC_BASIC_STEPS,
	},
	[MIUC_PRODUCTS.AUTOPRODUCCION]: {
		codigo: MIUC_PRODUCTS.AUTOPRODUCCION,
		steps: [
			MIUC_PROCESS_VIEWS.CREDIT_OPTIONS,
			MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO,
			MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT,
			MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
			MIUC_PROCESS_VIEWS.NEXT_STEPS,
		],
	},
	[MIUC_PRODUCTS.CREDITO_SEGURO]: {
		codigo: MIUC_PRODUCTS.CREDITO_SEGURO,
		steps: [
			MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO,
			MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT,
			// MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
			MIUC_PROCESS_VIEWS.SECURE_CREDIT_REQUIREMENTS,
		],
	},
	[MIUC_PRODUCTS.CREDITERRENO]: {
		codigo: MIUC_PRODUCTS.CREDITERRENO,
		steps: [
			MIUC_PROCESS_VIEWS.COUNTRY_MAP,
			MIUC_PROCESS_VIEWS.CREDIT_CONDITIONS,
			MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO,
			MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT,
			MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
		],
	},
	[MIUC_PRODUCTS.CICB]: {
		codigo: MIUC_PRODUCTS.CICB,
		steps: [
			MIUC_PROCESS_VIEWS.CICB,
			MIUC_PROCESS_VIEWS.SUMMARY_REQUEST
		]
	},
	[MIUC_PRODUCTS.MEJORA_DIRECTA]: {
		codigo: MIUC_PRODUCTS.MEJORA_DIRECTA,
		steps: [
			//Remplazar por los correctos
			MIUC_PROCESS_VIEWS.MEJORASI,
			MIUC_PROCESS_VIEWS.CREDIT_OPTIONS,
			MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO,
			MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT,
			MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
			MIUC_PROCESS_VIEWS.NEXT_STEPS,
		]
	},
	[MIUC_PRODUCTS.APOYO_INFONAVIT]: {
		codigo: MIUC_PRODUCTS.APOYO_INFONAVIT,
		steps: [
			//Remplazar por los correctos
			MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO,
		]
	}
};
