import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora(
	'130',
	'responsabilidad-compartida-programa-conversion-pesos'
);

export const ENDPOINTS_RESPONSABILIDAD_COMPARTIDA: IBitacora = {
	'/responsabilidad-compartida/elegibilidad': _bitacora.Read(
		'130100',
		'Consultar eligibilidad'
	),
	'/consult-case-responsibility': _bitacora.Read('130101', 'Consultar casos'),
	'/responsibility-amortizacion': _bitacora.Read(
		'130102',
		'Consultar amortizacion'
	),
	'/create-case-responsibility': _bitacora.Read('130103', 'Crear caso'),
	'/rejection-case-responsibility': _bitacora.Read('130104', 'Ejecutar caso'),
	'/responsabilidad-compartida/descargar-archivos': _bitacora.Read(
		'130105',
		'Descargar archivos'
	),
	'/responsabilidad-compartida/catalogo-motivos-rechazo': _bitacora.Read(
		'130106',
		'Consultar catalogo de rechazos'
	),
	'/responsabilidad-compartida/catalogo-estados': _bitacora.Read(
		'130107',
		'Consultar catalogo de estados'
	),
};
