import React from 'react';
import { Paper } from '@mui/material';
import Procedure from './Procedure';
import { useStyles } from './styles';

const SharedResponsability = () => {
	const classes = useStyles()
	return (
		<div className={classes.mainCotainer}>
			<Paper className={classes.paperContainer}>
				<Procedure />
			</Paper>
		</div>
	);
};

export default SharedResponsability;
