import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: '100%',
		maxWidth: '800px',
		margin: '0 auto',
		padding: '20px 60px',
	},
	divLogo: {
		textAlign: 'center',
		marginBottom: '20px',
		'& > img': {
			width: '318px',
		}
	},
	divText: {
		padding: '0 20px',
	},
	divLogos: {
		width: '100%',
		maxWidth: '510px',
		minHeight: '100px',
		margin: '20px auto',
		'& > img': {
			width: '100%',
		},
	},
	spanTitle: {
		fontSize: '20px',
		fontWeight: 'bold',
		margin: 0,
		textAlign: 'center',
	},
	txtInicial: {
		fontSize: '18px',
		textAlign: 'center',
		justifyContent: 'space-around'
	},
	divFlexSmall: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	divFlex: {
		width: '100%',
		padding: '0 20px',
		display: 'flex',
		alignItems: 'center',
	},
	itemFlex50: {
		width: '50%',
		'& > img': {
			width: '236px',
		}
	},
	iconsImg: {
		width: '70px',
		marginRight: '10px',
		marginBotton: '20px'
	},
	iconsImgLarge: {
		width: '190px',
		marginRight: '20px'
	},
	textInfo: {
		fontSize: '18px',
	},
	textInfoCustom: {
		fontSize: '18px',
		marginRight: '40px'
	},
	footer: {
		width: '100%',
		maxWidth: '550px',
		margin: '0 auto',
		textAlign: 'center',
	},
	textFooter: {
		fontSize: '12px'
	},
	link: {
		color: theme.palette.primary.main,
	}
}));
