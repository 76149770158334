// DEPENDECIES
import React from 'react';
// RESOURCES
//import video from '../../../assets/mp4/responsabilidad.mp4';
import ReactPlayer from 'react-player';

const VideoTab = () => {
	return (
		<>
			{/* <iframe
				width="100%"
				height="400px"
				allowFullScreen
				frameBorder="0"
				title="Responsabilidad compartida"
				src={video}
				// allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			/> */}
			<ReactPlayer
				controls
				url={'https://www.youtube.com/watch?v=D3BOUuTr6Xg'}
				width={'100%'}
				height={400}
			/>
		</>
	);
};

export default VideoTab;
