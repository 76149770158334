import API from '..';
import { IBaseResponse } from '../../interfaces/followComplaint';
class ApiFollowComplaint extends API {
	getMyComplaints(data: string): Promise<IBaseResponse> {
		return this.requestWrapper(
			this.request({
				url: `v1/haz-tu-queja/consulta-caso-nota`,
				method: 'POST',
				data: { data },
			})
		);
	}
	consultarCatalogo(data: string): Promise<IBaseResponse> {
		return this.requestWrapper(
			this.request({
				url: `v1/haz-tu-queja/consulta-catalogo`,
				method: 'POST',
				data: { data },
			})
		);
	}
	crearCaso(data: string): Promise<IBaseResponse> {
		return this.requestWrapper(
			this.request({
				url: `v1/haz-tu-queja/crear-queja-felicitacion`,
				method: 'POST',
				data: { data },
			})
		);
	}
    adjuntarDocumento(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `v1/haz-tu-queja/adjuntar-documento`,
				method: 'POST',
				data: { data },
			})
		);
	}
    crearNota(data: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `v1/haz-tu-queja/crear-nota`,
				method: 'POST',
				data: { data },
			})
		);
	}
}

export const apiFollowComplaint = new ApiFollowComplaint('');
