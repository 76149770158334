/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { Radio } from '@mui/material';
import { useStyles } from '../styles/styles';
import HeaderStep from './HeaderStep';
import TextInput from '../../../../../components/TextInput';
import { isNumber } from '../../../../../utils/expressions';

const QuestStep8 = ({
	quest,
	nextStep,
	handleChange,
	afterQuestionarySteps,
	alert,
}: any) => {
	const classes = useStyles();
	const [quests, setQuests] = useState([] as any);
	const [date, setDate] = useState({
		month: '',
		year: '',
	});
	const [valor, setValor] = useState('');
	const [yearsCredit, setYearsCredit] = useState('');
	const [tasaInteres, setTasaInteres] = useState('');
	const [valor2, setValor2] = useState('');
	const [valor3, setValor3] = useState('');
	const [days, setDays] = useState('');

	useEffect(() => {
		drawQuestions();
	}, [quest, date, valor, valor2, valor3, tasaInteres]);

	useEffect(() => {
		let respuestaDate = `Mes ${date.month} Año ${date.year}`;
		handleChange(respuestaDate, '4.22');
	}, [date]);

	useEffect(() => {
		let respuesta = `$${valor}`;
		handleChange(respuesta, '4.23');
	}, [valor]);

	useEffect(() => {
		let respuestaDate = `${yearsCredit} Años`;
		handleChange(respuestaDate, '4.24');
	}, [yearsCredit]);

	useEffect(() => {
		let respuesta = `${tasaInteres} tasa de interés`;
		handleChange(respuesta, '4.25');
	}, [tasaInteres]);

	useEffect(() => {
		let respuesta = `$${valor2}`;
		handleChange(respuesta, '4.26');
	}, [valor2]);

	useEffect(() => {
		let respuesta = `$${valor3}`;
		handleChange(respuesta, '4.28');
	}, [valor3]);

	const drawQuestions = () => {
		const questions: any = [];
		quest.map((item: any, index: any) => {
			const newItem = {
				title: `${item.numero.split('.')[1]}.- ${item.pregunta}`,
				body: (
					<>
						{item.opciones.length > 0 &&
							item.numero !== '4.25' &&
							item.numero !== '4.28' &&
							item.opciones.map((opcion: any, idx: any) => (
								<div
									className={classes.itemsFlexCenter}
									key={opcion + item.pregunta}
								>
									<Radio
										checked={
											item.respuesta ===
											opcion.split(')')[0] || (index === 3 && item.respuesta === '4 ' + days + ' días')
										}
										onChange={(e: any) => {
											let value =
												e.target.value.split(')')[0];
											handleChange(value, item.numero);
										}}
										name={opcion.split(')')[0]}
										value={opcion.split(')')[0]}
										disabled={(index === 6) && (quest[0].respuesta === '2')}
									/>
									{opcion}
									{item.numero === '4.33' && index === 3 && (
										<div style={{ maxWidth: 200, paddingLeft: '10px' }}>
											<TextInput
												id="days"
												name="days"
												value={days}
												disabled={
													item.respuesta === '1' || item.respuesta === '2' || item.respuesta === '3'
												}
												onChange={(e) => {
													if (!isNumber(e.target.value)) return;
													if (e.target.value === '0') return;
													setDays(
														e.target.value
													);
													handleChange(
														'4 ' + e.target.value + ' días',
														item.numero
													);
												}}
											/>
										</div>
									)}
								</div>
							))}

						{item.numero === '4.22' && (
							<div
								style={{ maxWidth: 400, display: 'flex' }}
								key={'mes' + item.numero}
							>
								<div
									style={{
										textAlign: 'left',
										marginRight: '20px',
									}}
								>
									<TextInput
										id={'question-month-' + item.numero}
										name={'month'}
										placeholder="Mes"
										onChange={(e: any) => {
											if (!isNumber(e.target.value))
												return;
											if (e.target.value.length > 2)
												return;
											if (e.target.value > 12)
												return;
											if (e.target.value === '00') {
												setDate({
													...date,
													month: '01',
												});
											} else {
												setDate({
													...date,
													month: e.target.value,
												});
											}
										}}
										contentStyles={{ maxWidth: 100 }}
										value={date.month}
										disabled={quest[0].respuesta === '2'}
									/>
								</div>
								<div
									style={{
										textAlign: 'left',
										marginRight: '20px',
									}}
								>
									<TextInput
										id={'question-year-' + item.numero}
										name={'year'}
										placeholder="Año"
										onChange={(e: any) => {
											if (!isNumber(e.target.value))
												return;
											if (e.target.value.length > 4)
												return;
											if (e.target.value.length === 4 && e.target.value < '1900') {
												setDate({
													...date,
													year: '1900',
												});
											} else {
												setDate({
													...date,
													year: e.target.value,
												});
											}
										}}
										contentStyles={{ maxWidth: 300 }}
										value={date.year}
										disabled={quest[0].respuesta === '2'}
									/>
								</div>
							</div>
						)}

						{item.numero === '4.23' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor"
									name="valor"
									value={valor}
									placeholder="$0.00"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										if (e.target.value === '0') return;
										setValor(e.target.value);
									}}
									disabled={quest[0].respuesta === '2'}
								/>
							</div>
						)}

						{item.numero === '4.24' && (
							<div
								style={{ maxWidth: 400, display: 'flex' }}
								key={'mes' + item.numero}
							>
								<div
									style={{
										textAlign: 'left',
										marginRight: '20px',
										maxWidth: 300,
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<TextInput
										id={
											'question-yearsCredit-' +
											item.numero
										}
										name={'yearsCredit'}
										onChange={(e: any) => {
											if (!isNumber(e.target.value))
												return;
											setYearsCredit(e.target.value);
										}}
										contentStyles={{ maxWidth: 300 }}
										value={yearsCredit}
										disabled={quest[0].respuesta === '2'}
									/>
									<div
										style={{
											paddingLeft: 10,
											width: '200px',
										}}
									>
										Años
									</div>
								</div>
							</div>
						)}

						{item.numero === '4.25' && (
							<div
								style={{
									maxWidth: 300,
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<TextInput
									id="valor1"
									name="valor1"
									value={tasaInteres}
									placeholder="0"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										setTasaInteres(e.target.value);
									}}
									disabled={quest[0].respuesta === '2'}
								/>
								<div
									style={{ paddingLeft: 10, width: '200px' }}
								>
									Tasa de interés
								</div>
							</div>
						)}

						{item.numero === '4.26' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor2"
									name="valor2"
									value={valor2}
									placeholder="$0.00"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										if (e.target.value === '0') return;
										setValor2(e.target.value);
									}}
									disabled={quest[0].respuesta === '2'}
								/>
							</div>
						)}

						{item.numero === '4.28' && (
							<div style={{ maxWidth: 400 }}>
								<TextInput
									id="valor3"
									name="valor3"
									value={valor3}
									placeholder="$0.00"
									onChange={(e) => {
										if (!isNumber(e.target.value)) return;
										if (e.target.value === '0') return;
										setValor3(e.target.value);
									}}
								/>
							</div>
						)}
					</>
				),
				disabled: (index === 1 || index === 2 || index === 3 || index === 4 || index === 5 || index === 6) && (quest[0].respuesta === '2') ? true : false
			};
			return questions.push(newItem);
		});
		setQuests(questions);
	};

	const verifyComplete = () => {
		let questHabilitados: any = []
		for (let i = 0; i < quests.length; i++) {
			if (quests[i].disabled === false) {
				questHabilitados.push(i)
			}
		}
		let respuesta = quest.every((item: any, index: any) => {
			if (questHabilitados.includes(index) && item.respuesta.length === 0) return false
			else return true
		})
		return respuesta
	};

	const sendData = () => {
		nextStep();
	};

	return (
		<HeaderStep
			title={'Egresos y deudas'}
			subTitle={'Crédito automotriz'}
			nextStep={sendData}
			afterQuestionarySteps={afterQuestionarySteps}
			disabledContinue={!verifyComplete()}
			disabledButton={!verifyComplete()}
			children={quests}
			alert={alert}
		/>
	);
};

export default QuestStep8;
