import { Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import CustomAlert from '../../components/CustomAlert';

interface Props {
	setPage: (data: number) => void;
	dataStatus: any;
}

const CloseCase = ({ setPage, dataStatus }: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Paper sx={{ pt: 5, pb: 5, pr: matches ? 5 : 15, pl: matches ? 5 : 15 }}>
			<CustomAlert
				show={true}
				severity="success"
				message="Tu RFC ha sido corregido, para realizar un trámite deberás esperar 10 días hábiles."
			/>
			<div>
				<p style={{ paddingBottom: matches ? 10 : 25, textAlign: 'center' }}>
					Tu RFC se corrigió con el número de caso {dataStatus.noCaso}, ya no es
					necesario que inicies el trámite nuevamente.
				</p>
			</div>

			<div
				style={{
					maxWidth: 800,
					margin: 'auto',
					marginTop: 30,
				}}
			>
				<div
					style={{
						maxWidth: 260,
						display: 'flex',
						margin: 'auto',
						marginTop: 30,
					}}
				>
					<CustomButton
						variant="solid"
						label={'Aceptar'}
						onClick={() => setPage(0)}
					/>
				</div>
			</div>

		</Paper>
	);
};

export default CloseCase;