import React from 'react';
import BaseModal from '../../components/Modal';
import CustomButton from '../../components/Button';

const ConfirmDeleteAttachDocument = ({ open, setOpen, sendDeleteCaso }: any) => {
	return (
		<BaseModal
			title={
				<span style={{ color: '#293990', textAlign: 'center', display: 'block' }}>
					¿Estás seguro que deseas eliminar el documento?
				</span>
			}
			open={open !== '' ? true : false}
			width="sm"
			disableOnConfirm={true}
		>
			<div style={{ textAlign: 'center' }}>
				<div
					style={{
						display: 'flex',
						width: 540,
						justifyContent: 'space-between',
						margin: '20px auto 10px',
					}}
				>
					<div style={{ width: 260 }}>
						<CustomButton
							label="No"
							variant="outlined"
							onClick={() => setOpen('')}
						/>
					</div>
					<div style={{ width: 260 }}>
						<CustomButton
							label="Sí"
							variant="solid"
							onClick={() => sendDeleteCaso(open)}
						/>
					</div>
				</div>
			</div>
		</BaseModal>
	);
};

export default ConfirmDeleteAttachDocument;
