import Header from '../../../components/Header';
import { useGeneralStyles } from '../../styles/general.styles';
import { useStyles } from '../styles';
import { Box, Divider, Grid, InputAdornment } from '@mui/material';
import CustomAlert from '../../../../../components/CustomAlert';
import { RoundBtn } from '../../../components/RoundBtn/roundBtn';
import ModalLoading from '../../../../../components/ModalLoading';
import ProductsMiuc from '../../../components/ProductsMiuc';
import { formatMoney2 } from '../../../../../utils/validators';
import TextInput from '../../../../../components/TextInput';
import CustomButton from '../../../../../components/Button';
import { rowsTitleMVIT } from '../../../utils/selectionResume.utils';
import SelectCustom from '../../../../../components/SelectCustom';
import { useMejorarViviendaTable } from '../hooks/useMejorarViviendaTable';

export const MejorarViviendaTable = () => {
	const classes = useStyles();

	const generalStyle = useGeneralStyles();

	const {
		loading,
		next,
		returnStep,
		setSelectProduct,
		selectProduct,
		newStep,
		alertInput,
		handleCalculate,
		handleClearSearch,
		mejoravitItems,
		setMejoravitItems,
		regularizarOptions,
		handleChangeRegularizar,
		manoObraOptions,
		handleChangeManoObra,
		handleSetDataMejora,
		productsSelect,
		disableButton,
		plazoOptions,
		rowsTitle,
		alert
	} = useMejorarViviendaTable();

	return (
		<Box>
			<Header steps={newStep}></Header>
			<Box className={classes.content}>
				<div className={classes.contentPadding}>
					{alertInput.message && (
						<CustomAlert
							show={alertInput.show}
							message={alertInput.message}
							severity={alertInput.severity}
						/>
					)}
				</div>
				<div className={classes.contentPadding}>
					{alert.message && (
						<CustomAlert
							show={alert.show}
							message={alert.message}
							severity={alert.severity}
						/>
					)}
				</div>
				<Grid container justifyContent="center">
					<Grid className={classes.inputs} container spacing={2} justifyContent="center">
						<Grid item xs={12}>
							<Grid item xs={12} md={12} mt={2}>
								<SelectCustom
									value={mejoravitItems.porVivienda}
									options={regularizarOptions}
									label="¿Necesitas destinar un porcentaje de tu crédito para regularizar tu vivienda?"
									placeholder={'Selecciona'}
									onChange={handleChangeRegularizar}
								></SelectCustom>
							</Grid>
						</Grid>
						<Grid item xs={12} md={6}>
							<div className={classes.valueInput}>
								<SelectCustom
									value={mejoravitItems.porManoObra}
									options={manoObraOptions}
									label="Porcentaje para mano de obra"
									placeholder={'Selecciona'}
									onChange={handleChangeManoObra}
								></SelectCustom>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<span className={classes.labelInput}>Ingresa el valor de la mejora</span>
							<TextInput
								startAdornment={
									<InputAdornment position="start">
										$
									</InputAdornment>
								}
								placeholder="0.00"
								name="cost"
								id="mejora-costo"
								value={mejoravitItems.valorMejora}
								onChange={handleSetDataMejora}
								onBlur={() => {
									if (mejoravitItems.valorMejora) {
										if (
											mejoravitItems.valorMejora === '.'
										) {
											setMejoravitItems({
												...mejoravitItems,
												valorMejora: '',
											});
										} else {
											setMejoravitItems({
												...mejoravitItems,
												valorMejora: formatMoney2(
													Number(
														mejoravitItems.valorMejora
													)
												).substring(1),
											});
										}
									}
								}}
								onFocus={() => {
									if (mejoravitItems.valorMejora) {
										setMejoravitItems({
											...mejoravitItems,
											valorMejora:
												mejoravitItems.valorMejora
													.toString()
													.replaceAll(',', '')
													.replaceAll(' ', ''),
										});
									}
								}}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
              <Box mt={2}>
                <CustomAlert 
                  show
                  severity='warning'
                  message={'no'}
                >
                  <p style={{ textAlign: 'start', margin: 0 }}>
                    Las condiciones financieras mostradas a continuación
                    no consideran la consulta a tu historial crediticio.
                    Una vez que se lleve a cabo la inscripción de tu crédito,
                    se realizará la consulta a Sociedades de Información
                    Crediticia (Buró de Crédito) para determinar las condiciones
                    finales del crédito a otorgar.
                  </p>
                </CustomAlert>
              </Box>
							{/* <Box className={classes.textDisclaimer}>
								<p>
                  Las condiciones financieras mostradas a continuación
                  no consideran la consulta a tu historial crediticio.
                  Una vez que se lleve a cabo la inscripción de tu crédito,
                  se realizará la consulta a Sociedades de Información
                  Crediticia (Buró de Crédito) para determinar las condiciones
                  finales del crédito a otorgar.
								</p>
							</Box> */}
						</Grid>
						{mejoravitItems.cleanBtn && (
							<Grid item xs={12} md={3}>
								<div className={classes.buttonsMVIT}>
									<CustomButton
										onClick={handleClearSearch}
										label="Limpiar"
										variant="outlined"
									/>
								</div>
							</Grid>
							)}
						<Grid item xs={12} md={3}>
							<CustomButton
								onClick={handleCalculate}
								label="Calcular"
								variant="solid"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Divider sx={{ margin: '20px 50px' }} />
				<div className={classes.contentPadding}>
					<Box sx={{ maxWidth: '100%' }}>
						<ProductsMiuc
							keys={rowsTitle}
							products={productsSelect}
							keyHeader={'producto'}
							selectProduct={selectProduct}
							setSelectProduct={setSelectProduct}
							textFooter="Considera que en cualquier momento de tu crédito podras adelantar pagos o realizar la liquidación de tu crédito sin tener ninguna penalización."
							options={plazoOptions}
							maxWidthCard={380}
						/>
					</Box>
				</div>
				<Divider sx={{ margin: '20px 50px' }} />
        <p className={classes.regularizacion}>
          *El porcentaje para Regularización de Vivienda solo podrá ser hasta el 30% 
          del monto de crédito autorizado. Los recursos del crédito serán destinados 
          únicamente para viviendas que solo tengan pendiente el trámite de Escrituración 
          y Registro ante el RPP (formalizar la Propiedad).
        </p>
				<div className={generalStyle.bottonsContainer}>
					<RoundBtn id='regresar' variant="outlined" onClick={() => returnStep()}>
						Regresar
					</RoundBtn>
					<RoundBtn
						id='continuar'
						disabled={disableButton}
						variant="contained"
						onClick={() => next()}
					>
						Continuar
					</RoundBtn>
				</div>
			</Box>
			<ModalLoading loading={loading} />
		</Box>
	);
};
