import { Divider, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../../config/theme';
import Modal from '../../components/Modal';
import CustomButton from '../../components/Button';

interface IProps {
	show: boolean;
}

const ModalMessageTemp = ({ show }: IProps) => {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();

	return (
		<Modal
			hideCloseButton
			open={show}
			width={'md'}
			children={
				<div style={{ textAlign: 'center', fontSize: 15 }}>
					<p style={{ fontWeight: 500, margin: '0px 0px 10px 0px' }}>
						Importante
					</p>

					<p
						style={{
							margin: 0,
							marginBottom: 40,
							textAlign: 'left',
						}}
					>
						Te informamos que el servicio Aclaración de pagos,
						estará suspendido temporalmente debido a trabajos de
						mejoras programados. La interrupción del servicio está
						programada para el período del 14 al 26 de noviembre,
						retomando actividades el lunes 27 de noviembre. Durante
						este tiempo, no será posible acceder al servicio ni
						realizar ninguna actividad. Lamentamos cualquier
						inconveniente que esto pueda ocasionar y agradecemos su
						comprensión. Estamos trabajando arduamente para
						implementar mejoras que permitirán ofrecerles un
						servicio más eficiente y satisfactorio.
					</p>

					<Divider style={{ margin: '20px 0px' }} />

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: matches
								? 'column-reverse'
								: 'inherit',
						}}
					>
						<div
							style={{
								width: matches ? '100%' : 180,
								display: matches ? 'flex' : 'inline-flex',
								margin: '8px 10px',
							}}
						>
							<CustomButton
								label="Salir"
								variant="solid"
								onClick={() => navigate('/')}
							/>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default ModalMessageTemp;
