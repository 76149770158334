import Modal from '../../../components/Modal';
import { UseStyle } from '../Style';

interface Props {
	openModal: boolean;
	setOpenModal: (arg: boolean) => void;
}

const fechaPagos = [
	{
		bimestre: '1',
		mes: 'Enero - Febrero',
		fechaPatron: '17 Marzo',
		mesCorre: '15 Abril',
	},
	{ bimestre: '2', mes: 'Marzo - Abril', fechaPatron: '17 Mayo', mesCorre: '15 Junio' },
	{
		bimestre: '3',
		mes: 'Mayo - Junio',
		fechaPatron: '17 Julio',
		mesCorre: '15 Agosto',
	},
	{
		bimestre: '4',
		mes: 'Julio - Agosto',
		fechaPatron: '17 Septiembre',
		mesCorre: '15 Octubre',
	},
	{
		bimestre: '5',
		mes: 'Septiembre - Octubre',
		fechaPatron: '17 Noviembre',
		mesCorre: '15 Diciembre',
	},
	{
		bimestre: '6',
		mes: 'noviembre - Diciembre',
		fechaPatron: '17 Enero',
		mesCorre: '15 Febrero',
	},
];

const CalenderPays = ({ openModal, setOpenModal }: Props) => {
    const classes = UseStyle();

	return (
		<Modal
            width='lg'
			open={openModal}
			onClose={() => setOpenModal(false)}
			children={
				<div style={{ padding: '0px 30px' }}>
					<h2
						style={{ color: '#293990', textAlign: 'center', fontWeight: 900 }}
					>
						Predial y cuota de conservación
						<br />
						Calendario de pagos
					</h2>

					<table
                        className={classes.tableZebra}
						style={{
							width: '100%',
							borderCollapse: 'collapse',
							textAlign: 'left',
							margin: '50px 0px',
                            fontSize: 15
						}}
					>
						<thead>
							<tr style={{ background: '#D1001F', color: '#fff' }}>
								<th style={{ padding: 30 }}>Bimestre</th>
								<th style={{ padding: 30 }}>Mes que corresponde</th>
								<th style={{ padding: 30 }}>Fecha de pago del patrón</th>
								<th style={{ padding: 30 }}>Mes que corresponde</th>
							</tr>
						</thead>
						<tbody style={{ color: '#333333', border: '1px solid rgba(0,0,0,0.1)' }}>
							{fechaPagos.map((pago, index) => (
								<tr key={index}>
									<td style={{ padding: 30 }}>{pago.bimestre}</td>
									<td style={{ padding: 30 }}>{pago.mes}</td>
									<td style={{ padding: 30 }}>{pago.fechaPatron}</td>
									<td style={{ padding: 30 }}>{pago.mesCorre}</td>
								</tr>
							))}
						</tbody>
					</table>

					<p
						style={{
							textAlign: 'center',
							maxWidth: 930,
							fontSize: 15,
							margin: 'auto',
							minHeight: 100,
						}}
					>
						En el calendario se muestra el día y mes en el que tu patrón debe
						entregar al Infonavit lo que retuvo de tu sueldo para el pago
						bimestral de{' '}
						<strong>
							tu crédito así como de los servicios de predial y/o cuota de
							conservación.
						</strong>
						<br />
						En la última columna está la fecha en la que el administrador
						responsable recibe los recursos recaudados por el Infonavit.
					</p>
				</div>
			}
		/>
	);
};

export default CalenderPays;
