import { useEffect, useState } from 'react';
import CustomButton from '../../../../../../components/Button';
import { IAgroindustryDatosGenerales } from '../../../../../../api/modules/MIUC';
import { useStyles } from '../../styles';
import { useNavigate } from 'react-router-dom';

interface props {
	datosGenerales: IAgroindustryDatosGenerales;
	handlerSubmit: () => void;
}

const MovementsFooter = ({ datosGenerales, handlerSubmit}: props) => {
	const navigate = useNavigate();
	const styles = useStyles();
	const [disableButton, setDisableButton] = useState<boolean>(false);

	useEffect(() => {
		setDisableButton(datosGenerales.puntos < 1080 ? true : false);
	}, [datosGenerales]);

	return (
		<>
			{(datosGenerales.sitioRegistrado === 'AC' ||
				datosGenerales.sitioRegistrado === 'TX') && (
				<div className={styles.buttons}>
					<CustomButton onClick={() => navigate('/')} variant="solid" label="Salir" />
				</div>
			)}

			{datosGenerales.sitioRegistrado === 'CA' && (
				<>
					<div className={styles.buttons}>
						<CustomButton
							onClick={() => navigate('/')}
							variant="outlined"
							label="Salir"
						/>
					</div>
					<div className={styles.buttons}>
						<CustomButton
							onClick={handlerSubmit}
							variant="solid"
							label="Registrarme"
						/>
					</div>
				</>
			)}

			{datosGenerales.sitioRegistrado === 'TE' && (
				<div className={styles.buttons}>
					<CustomButton
						onClick={handlerSubmit}
						variant="solid"
						label="Me interesa un crédito"
						disabled={disableButton}
					/>
				</div>
			)}

			{datosGenerales.sitioRegistrado === 'BJ' && (
				<>
					<div className={styles.buttons}>
						<CustomButton
							onClick={() => navigate('/')}
							variant="outlined"
							label="Salir"
						/>
					</div>
					<div className={styles.buttons}>
						<CustomButton
							onClick={handlerSubmit}
							variant="solid"
							label="Me interesa un crédito"
						/>
					</div>
				</>
			)}
		</>
	);
};

export default MovementsFooter;
