import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
	FormControl,
	FormControlLabel,
	MenuItem,
	RadioGroup,
} from '@mui/material';
import { Base, CustomSelect, CustomRadio } from './Styles';
import TextInput from '../../components/TextInput';
import { associateNssAPI } from '../../api/modules/AssociateNSS';
import { keyAPI } from '../../api/modules/key';
import { isNumber, nssValid, toPascalCase } from '../../utils/expressions';
import { encrypt } from '../../utils/encrypt';
import { RootState } from '../../store';
import iconAd from '../../assets/img/admiracion-icon.png';
import CustomAlert from '../../components/CustomAlert';
import CustomButton from '../../components/Button';
import ModalLoading from '../../components/ModalLoading';
import { IAlert } from '../../interfaces/alert';

interface Props {
	setEtapa: React.Dispatch<React.SetStateAction<number>>;
	associated: any;
	setAssociated: any;
	msgError?: IAlert;
}

const descriptions: any = {
	CO: 'Si estás casada o casado legalmente y ambos cuentan con una relación laboral vigente, pueden sumar sus créditos.',
	CR: 'Si tienes una relación laboral vigente puedes sumar tu crédito con el de una persona sin parentesco cosanguíneo.',
	FA: 'Si tienes una relación laboral vigente, puedes sumar tu crédito con el de un familiar de parentesco cosanguíneo (padres, hijos o hermanos).',
};
const FormAdminNss = ({
	setEtapa,
	associated,
	setAssociated,
	msgError,
}: Props) => {
	const { user } = useSelector((state: RootState) => state.session);
	const classes = Base();
	const [typeAssociates, setTypeAssociates] = useState<any>([]);
	const [referenceAssociates, setReferenceAssociates] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [disableButton, setDisableButon] = useState<boolean | undefined>(
		true
	);

	const fetchTypeAssociates = useCallback(async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				key
			);

			associateNssAPI.typeAssociates
				.post<{ nss: string }, any>('', {
					nss: USER_NSS_BASE64,
				})
				.then((res: any) => {
					if (res.data && typeof res.data === 'object') {
						let types = Object.keys(res.data).map((key) => ({
							value: key,
							label: res.data[key],
						}));
						setTypeAssociates(types);
					}
				})
				.catch(() => {
					setLoading(false);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
		}
	}, [user?.nss]);

	useEffect(() => {
		fetchTypeAssociates();
	}, [fetchTypeAssociates]);

	useEffect(() => {
		//console.log('msgError => ', msgError);
	}, [msgError]);

	const fetchTypeSelected = useCallback(async () => {
		//Valida que solo se ejecute cuando el tipo de asociación se Familiar o Corresidencial
		if (
			associated.tipoAsociacion !== 'FA' &&
			associated.tipoAsociacion !== 'CR'
		) {
			return;
		}

		setLoading(true);

		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const TYPE_BASE64 = await encrypt(associated.tipoAsociacion || '', key);
			if (associated.tipoAsociacion)
				associateNssAPI.referenceAssociates
					.post<{ type: string }, any>('', {
						type: TYPE_BASE64,
					})
					.then((res: any) => {
						if (res.result && typeof res.result === 'object') {
							let ref = Object.keys(res.result).map((key) => ({
								name: key,
								options: res.result[key].flatMap((option: any) =>
									Object.keys(option).map((op) => ({
										value: op,
										label: option[op],
									}))
								),
							}));
							setReferenceAssociates(ref);
						}
					})
					.catch(() => {
						setLoading(false);
					})
					.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
		}
	}, [associated.tipoAsociacion]);

	useEffect(() => {
		fetchTypeSelected();
	}, [fetchTypeSelected]);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		let nsserror = '';

		if (name === 'tipoAsociacion') {
			associated.genero = '';
			associated.tipoRelacion = '';
			associated.nssSecundario = '';
			setAssociated({ ...associated, tipoRelacion: '', Genero: '' });
		}

		if (name === 'nssSecundario') {
			if (!isNumber(value)) return;
			if (value.length > 11) return;
			const { msg, valid } = nssValid(e.target.value);
			nsserror = valid ? '' : msg;
		}
		setAssociated({
			...associated,
			[name]: value,
			nssError: nsserror,
		});

		let result: boolean | undefined;

		if (!associated.nombre) {
			result = true;
		}

		if (!result && associated.nssSecundario.length < 11) {
			result = true;
		}

		result = result === undefined ? false : result;

		setDisableButon(result || msgError?.show);
	};

	useEffect(() => {
		let result: boolean | undefined;

		if (!associated.nombre) {
			result = true;
		}

		if (!result && associated.nssSecundario.length < 11) {
			result = true;
		}

		const { valid } = nssValid(associated.nssSecundario);

		if (!result && valid === false) {
			result = true;
		}

		if (
			associated.tipoAsociacion === 'CR' ||
			associated.tipoAsociacion === 'FA'
		) {
			if (!result && associated.genero === '') {
				result = true;
			}

			if (!result && associated.tipoRelacion === '') {
				result = true;
			}
		}

		result = result === undefined ? false : result;

		setDisableButon(result || msgError?.show);
	}, [msgError?.show, associated]);

	return (
		<>
			<ModalLoading loading={loading} />
			<div className={classes.panelTop}>
				<h4 style={{ marginTop: 0, fontSize: 16 }}>
					Elige una de las siguientes opciones:
				</h4>
				<div className={classes.panelAsociacion}>
					{/* <p
						style={{
							color: '#7F7F7F',
							fontSize: 16,
							marginLeft: 15,
						}}
					>
						Tipo de asociación
					</p>  */}
					<div className={classes.radioButton}>
						<FormControl>
							<RadioGroup
								value={associated.tipoAsociacion}
								name="tipoAsociacion"
								onChange={handleChange}
							>
								{typeAssociates.map((type: any) => {
									return (
										<>
											<FormControlLabel
												key={type.value}
												value={type.value}
												label={type.label}
												control={<CustomRadio />}
											/>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													marginBottom: 15,
													marginLeft: 20,
												}}
											>
												<img
													src={iconAd}
													alt="ayuda"
													style={{
														width: 15,
														marginRight: 7,
													}}
												/>
												<p style={{ marginTop: 3 }}>
													{descriptions[type.value]}
												</p>
											</div>
										</>
									);
								})}
							</RadioGroup>
						</FormControl>
					</div>
				</div>
			</div>

			<hr
				style={{
					marginTop: 30,
					color: '#8A919C',
					height: '1px',
					opacity: '20%',
				}}
			/>

			<p
				className={classes.formLabel}
				style={{
					display: associated.tipoAsociacion ? 'block' : 'none',
				}}
			>
				Completa la Información
			</p>
			<div className={classes.divForm}>
				{associated.tipoAsociacion !== 'CO' &&
					referenceAssociates.map((ref: any) => (
						<div key={ref.name} className={classes.selectCustom}>
							<p>
								{ref.name === 'tipoRelacion'
									? 'Parentesco'
									: toPascalCase(ref.name) === 'Genero'
										? 'Género' // Comentario
										: toPascalCase(ref.name)}
								<span style={{ color: '#DD0528' }}>*</span>
							</p>
							<CustomSelect
								data-testid="CustomSelect"
								value={associated[ref.name]}
								name={ref.name}
								onChange={handleChange}
							>
								{ref.options.map((option: any) => (
									<MenuItem
										key={option.value}
										value={option.value}
									>
										{option.label}
									</MenuItem>
								))}
							</CustomSelect>
						</div>
					))}

				<div
					style={{
						display: associated.tipoAsociacion ? 'block' : 'none',
					}}
				>
					<TextInput
						id="nssSecundario"
						name="nssSecundario"
						label="Escribe el Número de Seguridad Social (NSS) de la persona con la que te quieres asociar"
						value={associated.nssSecundario}
						onChange={handleChange}
						inputProps={{ maxLength: 11 }}
						helperText={associated.nssError}
						isRequired
					/>
				</div>
			</div>

			<div className={classes.divForm}>
				<CustomAlert
					message={msgError?.message || ''}
					severity={msgError?.severity || 'info'}
					show={msgError?.show || false}
				/>
			</div>

			<div
				style={{
					maxWidth: 350,
					display: 'block',
					margin: 'auto',
					marginTop: 20,
				}}
			>
				<CustomButton
					data-testid="Continuar"
					variant="solid"
					disabled={disableButton}
					onClick={() => setEtapa(1)}
					label={'Continuar'}
				/>
			</div>
			<hr
				style={{
					marginTop: 30,
					color: '#8A919C',
					height: '1px',
					opacity: '20%',
				}}
			/>
			<p style={{ color: '#293990', fontWeight: 500 }}>
				*Datos obligatorios
			</p>
		</>
	);
};

export default FormAdminNss;
