import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ButtonCustom from '../../../../../components/Button';
import theme from '../../../../../config/theme';


const NothingPage = () => {
	const navigate = useNavigate();

	return (
		<Box>
			<h2 style={{ color: theme.palette.info.main, paddingTop: '40px' }}>¡Importante!</h2>
			<p>
				Si deseas consultar si puedes tramitar otro crédito, es
				necesario que tengas relación laboral.
			</p>
			<p>
				En este momento al consultar a nuestras bases de datos te ubican:
			</p>
			<h4>Sin relación laboral vigente en el tercer bimestre del 2022</h4>
			<Box
				width={'100%'}
				maxWidth={400}
				margin={'auto'}
				padding={'20px 0px'}
				style={{ display: 'flex' }}
			>
				<ButtonCustom
					label={'Cancelar'}
					onClick={() => navigate('/')}
					variant={'outlined'}
					styles={{ margin: '0px 5px' }}
				/>
				<ButtonCustom
					label={'Aceptar'}
					onClick={() => navigate('/')}
					variant={'solid'}
					styles={{ margin: '0px 5px' }}
				/>
			</Box>
		</Box>
	);
};

export default NothingPage;
