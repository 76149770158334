export interface IProcessBitacora {
	[key: string]: {
		request?: any;
		valorAnterior?: any;
		valorNuevo?: any;
	};
}

export interface IDefaultData {
	dataBitacora: IProcessBitacora;
}

export const SET_DATA_BITACORA = 'setBitacora';
export const CLEAR_DATA = 'clearBitacora';

export const INITIAL_STATE: IDefaultData = {
	dataBitacora: {} as IProcessBitacora,
};
