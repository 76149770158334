import { IProductMonto } from "../../../interfaces/creditRequest"
import { CREDIT_TYPES } from "../constants/products";
import { useController } from "./useController";

export const useGastosTitulacion = () => {
    const { dataProcessController } = useController();

    const valorUMA = Number(dataProcessController.titular.valorUMA);
    const salarioUMA = Math.floor((Number(dataProcessController.titular.salarioDiario) / valorUMA) * 10) / 10;
    const enableCalcularGastos = salarioUMA > 2.9;

    const recalcularMontosTitulacion = (gastosTitulacion: number, products: IProductMonto[], callBack: (products: IProductMonto[]) => void) => {
        const newProducts = products.map((product) => {
            if(product.producto === CREDIT_TYPES.CREDITO_INFONAVIT){
                let titulacion = 0
                if (Number(gastosTitulacion) === 0){
                    titulacion = Number(product.gasMon);
                } else {
                    titulacion = gastosTitulacion;
                }
                const nvoMontoContarias = Number(product.mmcMon) + Number(product.ahorro) - Number(titulacion);
                const nvoGastoTitulacion = titulacion;
                const nvoTotal = nvoMontoContarias + Number(product.cdcMonCny);
                return {
                    ...product,
                    gasMon: nvoGastoTitulacion.toString(),
                    cdcMon: nvoMontoContarias.toString(),
                    totalContarias: nvoTotal,
                };
            }
            return product;
        });
        callBack(newProducts);
    }

    return {
        recalcularMontosTitulacion,
        enableCalcularGastos,
    }
}