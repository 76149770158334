import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('124', 'saldos-movimientos');

export const ENDPOINTS_SALDOS_Y_MOVIMIENTOS: IBitacora = {
	'/balance': _bitacora.Read('124100', 'Consultar informacion principal'),
	'/datos-mi-credito': _bitacora.Read(
		'124101',
		'Consultar datos de mi credito'
	),
	'/periods': _bitacora.Read('124102', 'Consultar periodos'),
	'/anios': _bitacora.Read('124103', 'Consultar años'),
	'/paperless/status': _bitacora.Read(
		'124104',
		'Actualizar estatus del paperless'
	),
	'/paperless': _bitacora.Read('124105', 'Consultar paperless'),
	'/genera-estado-cuenta-historico-pdf': _bitacora.Read(
		'124106',
		'Generar estado de cuenta historico PDF'
	),
	'/genera-estado-cuenta-mensual-pdf': _bitacora.Read(
		'124107',
		'Generar estado de cuenta mensual PDF'
	),
	'/genera-reporte-pagos-pdf': _bitacora.Read(
		'124108',
		'Generar reporte de pagos PDF'
	),
	'/balance/credit-movements': _bitacora.Read(
		'124109',
		'Consultar movimientos'
	),
	'/balance/credit-movements-pdf': _bitacora.Read(
		'124110',
		'Generar reporte de movimientos PDF'
	),
	'/saldos-movimientos/operacion-bancomer': _bitacora.Read(
		'124111',
		'Consultar operaciones bancomer'
	),
	'/saldos-movimientos/operacion-codi': _bitacora.Read(
		'124112',
		'Consultar operaciones codi'
	),
	'/aportaciones-ext-pago-cuenta': _bitacora.Read(
		'124113',
		'Consultar pago de cuenta ext'
	),
	'/salmov/pagos-parciales': _bitacora.Read(
		'124114',
		'Consultar pagos parciales'
	),
	'/saldos-movimientos/certificado-calidad': _bitacora.Read(
		'124115',
		'Consultar certificado de calidad'
	),
};
