export const nssValid = (value: string): { valid: boolean; msg: string } => {
	const reg = /^(\d{2})(\d{2})(\d{2})\d{5}$/;
	if (value === '')
		return {
			valid: false,
			msg: 'Escribe tu Número de Seguridad Social (NSS)',
		};
	if (value.length < 11) {
		return {
			valid: false,
			msg: 'El Número de Seguridad Social (NSS) debe tener 11 números, por favor verifícalo',
		};
	}
	if (!value.match(reg)) {
		return { valid: false, msg: 'NSS Incorrecto' };
	}
	return { valid: true, msg: 'NSS Correcto' };
};

export const passValid = (value: string): { valid: boolean; msg: string } => {
	const reg =
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#])([A-Za-z\d$@$!%*?&#]|[^ ]){8,20}$/;
	if (value === '') return { valid: false, msg: '' };
	if (!value.match(reg)) {
		return { valid: false, msg: 'Contraseña Incorrecta' };
	}
	return { valid: true, msg: 'Contraseña Correcta' };
};

export const emailValid = (value: string): { valid: boolean; msg: string } => {
	const reg =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (value === '') return { valid: false, msg: '' };
	if (!value.match(reg)) {
		return {
			valid: false,
			msg: 'El correo electrónico que proporcionaste no tiene un formato válido, por favor verifícalo',
		};
	}
	return { valid: true, msg: 'Correo electrónico correcto' };
};

export const isNumber = (value: string): boolean => {
	const reg = /^[0-9]+$/;
	if (value === '') return true;
	if (!value.match(reg)) {
		return false;
	}
	return true;
};

export const clabeValid = (value: string): { valid: boolean; msg: string } => {
	const reg = /^([a-zA-Z0-9_-]){18}$/;
	if (value === '') return { valid: false, msg: '' };
	if (!value.match(reg)) {
		return { valid: false, msg: 'Clabe Incorrecta' };
	}
	return { valid: true, msg: 'Clabe Correcta' };
};

export const rfcValid = (
	rfc: string,
	aceptarGenerico = true
): { valid: boolean; msg: string; code?: string } => {
	//if (rfc === '') return { valid: false, msg: '', code: '' };
	if (rfc.length === 0) {
		return {
			valid: false,
			msg: 'Escribe tu Registro Federal de Contribuyentes (RFC)',
			code: '',
		};
	}
	const re =
		// /^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$/;
		/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([\w]{3})$/;
	var validado = rfc.match(re);

	if (rfc.length < 13) {
		return {
			valid: false,
			msg: 'El Registro Federal de Contribuyentes (RFC) debe tener 13 caracteres, por favor verifícalo',
			code: '',
		};
	}
	if (!validado) {
		// Coincide con el formato general del regex?
		return {
			valid: false,
			// msg: 'El Registro Federal de Contribuyentes (RFC) que proporcionaste no corresponde al que tenemos registrado, debes realizar la actualización y posteriormente intentar nuevamente el registro',
			msg: 'El Registro Federal de Contribuyentes (RFC) no cumple con los criterios de integridad: 4 letras, 6 números, 3 alfanuméricos. (No permitir captura RFC que pueden ser no íntegros)',
			code: '',
		};
	}

	// Separar el dígito verificador del resto del RFC
	const digitoVerificador = validado.pop(),
		rfcSinDigito = validado.slice(1).join(''),
		len = rfcSinDigito.length,
		// Obtener el digito esperado
		diccionario = '0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ',
		indice = len + 1;
	var suma, digitoEsperado;

	if (len === 12) suma = 0;
	else suma = 481; // Ajuste para persona moral

	for (var i = 0; i < len; i++)
		suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
	digitoEsperado = 11 - (suma % 11);
	if (digitoEsperado === 11) digitoEsperado = 0;
	else if (digitoEsperado === 10) digitoEsperado = 'A';

	// El dígito verificador coincide con el esperado?
	// o es un RFC Genérico (ventas a público general)?
	/*if (
		digitoVerificador !== digitoEsperado.toString() &&
		(!aceptarGenerico ||
			rfcSinDigito + digitoVerificador !== 'XAXX010101000')
	)
		return {
			valid: false,
			msg: 'El Registro Federal de Contribuyentes (RFC) no cumple con los criterios de integridad: 4 letras, 6 números, 3 alfanuméricos. (No permitir captura RFC que pueden ser no íntegros)',
			msg: 'El Registro Federal de Contribuyentes (RFC) que proporcionaste no corresponde al que tenemos registrado, debes realizar la actualización y posteriormente intentar nuevamente el registro',
			code: '',
		};
	else */
	if (
		!aceptarGenerico &&
		rfcSinDigito + digitoVerificador === 'XEXX010101000'
	)
		return {
			valid: false,
			//msg: 'El Registro Federal de Contribuyentes (RFC) que proporcionaste no corresponde al que tenemos registrado, debes realizar la actualización y posteriormente intentar nuevamente el registro',
			msg: 'El Registro Federal de Contribuyentes (RFC) no cumple con los criterios de integridad: 4 letras, 6 números, 3 alfanuméricos. (No permitir captura RFC que pueden ser no íntegros)',
			code: '',
		};
	return { valid: true, msg: 'RFC Correcto', code: '' };
};

export const curpValid = (curp: string) => {
	if (curp === '')
		return {
			valid: false,
			msg: 'Escribe tu Clave Única de Registro de Población (CURP)',
		};

	if (curp.length > 0 && curp.length < 18)
		return {
			valid: false,
			msg: 'La Clave Única de Registro de Población (CURP)  debe tener 18 caracteres, por favor verifícalo',
		};

	var re =
			/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
		validado = curp.match(re);

	if (!validado) {
		//Coincide con el formato general?
		return {
			valid: false,
			//msg: 'La Clave Única de Registro de Población (CURP) que proporcionaste no coincide con la registrada en nuestras bases de datos, verifica que esté escrita correctamente',
			msg: 'La Clave Única de Registro de Población (CURP) no cumple con los criterios de integridad: 4 letras, 6 números, 6 letras, 2 alfanuméricos.',
		};
	}

	//Validar que coincida el dígito verificador
	function digitoVerificador(curp17: string) {
		//Fuente https://consultas.curp.gob.mx/CurpSP/
		var diccionario = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ',
			lngSuma = 0.0,
			lngDigito = 0.0;
		for (var i = 0; i < 17; i++)
			lngSuma =
				lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
		lngDigito = 10 - (lngSuma % 10);
		if (lngDigito === 10) return 0;
		return lngDigito.toString();
	}

	if (validado[2] !== digitoVerificador(validado[1]).toString())
		return {
			valid: false,
			msg: 'La Clave Única de Registro de Población (CURP) que proporcionaste no coincide con la registrada en nuestras bases de datos, verifica que esté escrita correctamente',
			// msg: 'La Clave Única de Registro de Población (CURP) no cumple con los criterios de integridad: 4 letras, 6 números, 6 letras, 2 alfanuméricos.',
		};

	return { valid: true, msg: 'CURP Correcta' };
};
export const isNumberValidate = (value: string): boolean => {
	const reg = /^[0-9]+$|^$/;
	if (!value.match(reg)) {
		return false;
	}
	return true;
};

export const isNumberFloat = (value: string) => {
	const reg = /^[0-9]*(\.[0-9]{0,2})?$/;
	if (value === '') return true;
	if (!value.match(reg)) {
		return false;
	}
	return true;
};

export const containsUppercaseValid = (value: string): boolean => {
	const reg = new RegExp('[A-Z]', 'g');
	if (!value.match(reg)) {
		return false;
	}
	return true;
};

export const containsLowercaseValid = (value: string): boolean => {
	const reg = new RegExp('[a-z]', 'g');
	if (!value.match(reg)) {
		return false;
	}
	return true;
};
export const containsDigitValid = (value: string): boolean => {
	const reg = new RegExp('[0-9]', 'g');
	if (!value.match(reg)) {
		return false;
	}
	return true;
};
export const containsSpecialCharactersValid = (value: string): boolean => {
	const reg = new RegExp('[$@$!%*?&#./]', 'g');
	if (!value.match(reg)) {
		return false;
	}
	return true;
};

export const toPascalCase = (query: string): string =>
	query
		.split(' ')
		.map((x) => x[0].toUpperCase() + x.substring(1))
		.join(' ');

export const currencyFormat = (value: any) =>
	Number(value)
		.toFixed(2)
		.replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const removeAccents = (value: string) => {
	return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
