import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: '100%',
		marginTop: 0,
		// [theme.breakpoints.down('md')]: {
		// 	marginTop: 0,
		// },
		backgroundColor: '#ECEFFC !important',
		border: '1px solid #293990 !important',
	},
	titulo: {
		color: '#333333',
		fontSize: 17,
		fontWeight: 700,
		marginBottom: 0,
		marginTop: 0,
	},
	contarias: {
		fontSize: 30,
		fontWeight: 800,
		margin: 0,
		color: theme.palette.info.main,
	},
	iconoPesos: {
		maxWidth: 50,
		margin: '0px 10px',
	},
	centeredColumn: {
		minWidth: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: 0,
	},
	linkDescarga: {
		fontsize: 20,
		fontWeight: '15px',
		margin: 0,
	},
	icoDescarga: {
		marginLeft: 3,
		maxWidth: '13px',
	},
}));

export const centeredItems = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};
