import { MIUC_PROCESS_VIEWS } from '../../constants/process';
import { useMemo } from 'react';
import { Box, Grid, Paper } from '@mui/material';
import { BuroForm } from '../../components/BuroForm/buroForm';
import { SecureCreditTable } from './views/secureCreditTable';
import CustomInfoView from '../../components/SecureCredit/CustomInfoView';
import FlowCard from '../../components/FlowCard';
import ViewPoints from '../../components/ViewPoints';
import { useNavigation } from '../../hooks/useNavigation';
import { SelectionResume } from '../../components/SelectionResume/selectionResume';
import FollowSteps from '../../components/FollowSteps';
import ResumeCard from '../../components/ResumeCard';

export const SecureCredit = () => {
	const { calcProgress, currentView, dataProcessController } = useNavigation({
		firstPage: MIUC_PROCESS_VIEWS.NO_POINTS,
	});

	// const size = useMemo(() => {
	// 	if (currentView == MIUC_PROCESS_VIEWS.SECURE_CREDIT_REQUIREMENTS) {
	// 		return 12;
	// 	} else {
	// 		return 8;
	// 	}
	// }, [currentView]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={8}>
				<Paper>
					{currentView ===
						MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO && (
						<BuroForm />
					)}
					{currentView === MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT && (
						<SecureCreditTable />
					)}
				</Paper>
					{currentView ===
						MIUC_PROCESS_VIEWS.SECURE_CREDIT_REQUIREMENTS && (
						<FollowSteps imprimir />
					)}
			</Grid>
      <Grid item xs={12} md={4}>
        <Box sx={{ textAlign: 'center' }} mb={2}>
        {
          currentView !== MIUC_PROCESS_VIEWS.SECURE_CREDIT_REQUIREMENTS 
          ? <ViewPoints />
          : <ResumeCard />
        }
          </Box>
        <Box>
          <FlowCard
            title="Tu selección"
            flow={dataProcessController.processInfo?.selection}
            progress={calcProgress()}
          />
        </Box>
      </Grid>
		</Grid>
	);
};
