import React, { useEffect, useState } from 'react';
import { apiFollowComplaint } from '../../../../api/modules/followComplaint';
import ModalLoading from '../../../../components/ModalLoading';
import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import { defaultComplaintForm } from '../../CreateComplaint/utils';
import { encrypt } from '../../../../utils/encrypt';
import { additionInfoForm } from './utils';
import { keyAPI } from '../../../../api/modules/key';
import { pattern } from '../../utils';

const AdditionalInfoReason = ({
	formValues = defaultComplaintForm,
	setFormValues = () => { },
}: any) => {
	const [loading, setLoading] = useState(false);
	const [entities, setEntities] = useState([]);
	const [secureOptions, setSecureOptions] = useState([]);
	const [usedOptions, setUsedOptions] = useState([]);

	const fetchCatalog = async (id = '') => {
		try {
			setLoading(true);
			const data = {
				catalogo: 'Z0003',
				idN1: id,
			};
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const dataEncripted = await encrypt(JSON.stringify(data) || '', key);
			const result = await apiFollowComplaint.consultarCatalogo(
				dataEncripted
			);
			let setterFunction;
			switch (id) {
				case 'ZN100002':
					setterFunction = setEntities;
					break;
				case 'ZN100003':
					setterFunction = setSecureOptions;
					break;
				case 'ZN100004':
					setterFunction = setUsedOptions;
					break;
				default:
					setterFunction = setEntities;
			}
			if (result.code && result.code === '000')
				setterFunction(
					result.data.catalogos.map(
						({ idFinal2, n2Txt, ...other }: any) => ({
							value: idFinal2,
							text: n2Txt,
							other,
						})
					)
				);
		} catch (error) {
			//console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCatalog('ZN100002');
		fetchCatalog('ZN100003');
		fetchCatalog('ZN100004');
	}, []);

	const handleChange = ({ target }: any) => {
		if (target.value.length > 50) return;
		setFormValues({ ...formValues, [target.name]: target.value });
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<h5 style={{ fontSize: 15 }}>
				Escribe la información que se solicita
			</h5>
			<div className="form-container">
				{additionInfoForm(entities, secureOptions, usedOptions).map(
					({ id, name, label, options }: any) =>
						options ? (
							<SelectInput
								key={id}
								id={id}
								name={name}
								label={label}
								value={formValues[name]}
								onChange={handleChange}
								options={options}
								isRequired
							/>
						) : (
							<TextInput
								key={id}
								id={id}
								name={name}
								label={label}
								value={formValues[name]}
								onChange={handleChange}
								isRequired
								helperText={
									!pattern.test(formValues[name]) &&
									'Caracter no permitido'
								}
							/>
						)
				)}
			</div>
		</>
	);
};

export default AdditionalInfoReason;
