/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { Box, Grid } from '@mui/material';
import CreditsSelect from './Credits';
import { useStyles } from './styles';
import SubheaderDate from './Date';
import Breadcrumb from '../../Breadcrumb';
import { useLocation } from 'react-router-dom';
import { firstLetterStringToCapital } from '../../../utils/strings';
import { PROFILE_ROUTES } from '../../../config/profile';

type Props = {
	userLastLoginInfo: string;
	child?: React.ReactNode;
};

const SubHeader = ({ userLastLoginInfo }: Props) => {
	const classes = useStyles();
	const location = useLocation();

	const [breadcrumbItems, setBreadcrumbItems] = useState<string[]>([]);

	const breadcrumbData = (): string[] => {
		const paths = location.pathname.split('/').filter((l) => l !== '');
		const formattedPaths = paths.map(
			(path) =>
				PROFILE_ROUTES[path] || firstLetterStringToCapital(path.replace(/-/g, ' '))
		);
		return formattedPaths;
	};

	useEffect(() => {
		setBreadcrumbItems(breadcrumbData());
	}, [location]);

	return (
		<Box className={classes.root}>
			<Container maxWidth="lg">
				<Grid
					container
					spacing={1}
					sx={{ display: 'flex', alignItems: 'center' }}
				>
					<Grid item md={6} xs={12}>
						<Breadcrumb items={breadcrumbItems} />
					</Grid>
					<Grid item md={3} xs={12}>
						<CreditsSelect />
					</Grid>
					<Grid item md={3} xs={12}>
						<SubheaderDate date={userLastLoginInfo} />
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default SubHeader;
