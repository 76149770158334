// DEPENDENCIES
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// COMPONENTS
import ModalLoading from '../../../../components/ModalLoading';
import SelectInput from '../../../../components/SelectInput';
import { RoundBtn } from '../../../AssociateNssCredit/Styles';
// RESOURCES
import { sharedResponsabilityAPI } from '../../../../api/modules/sharedResponsability';
import { RootState } from '../../../../store';
import { keyAPI } from '../../../../api/modules/key';
import { encrypt } from '../../../../utils/encrypt';
import CustomAlert from '../../../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../../../config/messageCatalog';

const DenailReason = ({ step, setStep, selectedCredit, setSelectedCredit }: any) => {
	const { creditNum, user } = useSelector((state: RootState) => ({
		user: state.session.user,
		creditNum: state.credit.credit,
	}));
	const navigation = useNavigate();
	const [rejectionOptions, setRejectionOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState('');

	const fetchRejectCatalog = useCallback(async () => {
		try {
			const { result } = await sharedResponsabilityAPI.rejectionCatalog();
			if (result && result.length > 0)
				setRejectionOptions(
					result.map(({ codigo, descripcion }: any) => ({
						value: codigo,
						text: descripcion,
					}))
				);
		} catch (error) { }
	}, []);

	useEffect(() => {
		fetchRejectCatalog();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const rejectCase = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const NSS_BASE64 = await encrypt(user?.nss || '', key);
			const CREDIT_BASE64 = await encrypt(creditNum || '', key);
			const GRUPO_BASE64 = await encrypt(selectedCredit?.codigoGrupo || '', key);
			const quita_BASE64 = await encrypt(
				selectedCredit?.montoBeneficio || selectedCredit?.montoDescuento || '0',
				key
			);
			const saldoPrevio_BASE64 = await encrypt(selectedCredit?.SDO_ACT || '', key);
			const marcaReestructura_BASE64 = await encrypt(
				selectedCredit?.reestructura || '',
				key
			);
			const reaVsm_BASE64 = await encrypt(selectedCredit?.PG_REA || '', key);
			const reaPesos_BASE64 = await encrypt(selectedCredit?.PG_REA || '', key);
			const roaVsm_BASE64 = await encrypt(selectedCredit?.PG_ROA || '', key);
			const roaPesos_BASE64 = await encrypt(selectedCredit?.PG_ROA || '', key);
			const intereses_BASE64 = await encrypt(selectedCredit?.TASA || '', key);
			const plazo_BASE64 = await encrypt(selectedCredit?.PLAZO || '', key);
			const porcentajeDescuento_BASE64 = await encrypt(
				selectedCredit?.DSC_SDO || '',
				key
			);
			const estado_BASE64 = await encrypt(selectedCredit?.estado || '', key);
			const cp_BASE64 = await encrypt(selectedCredit?.cp || '', key);
			const motivo_BASE64 = await encrypt(selectedCredit?.motivoRechazo || '', key);
			const notas_BASE64 = await encrypt('', key);
			const { result } = await sharedResponsabilityAPI.rejectCase(
				NSS_BASE64,
				CREDIT_BASE64,
				GRUPO_BASE64,
				quita_BASE64,
				saldoPrevio_BASE64,
				marcaReestructura_BASE64,
				reaVsm_BASE64,
				reaPesos_BASE64,
				roaVsm_BASE64,
				roaPesos_BASE64,
				intereses_BASE64,
				plazo_BASE64,
				porcentajeDescuento_BASE64,
				estado_BASE64,
				cp_BASE64,
				motivo_BASE64,
				notas_BASE64
			);
			if (result?.codigo === '0000') navigation('/');
			else setAlert(result?.descripcion || INTERNAL_MESSAGES.ERROR_MESSAGE);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};
	return (
		<>
			<ModalLoading loading={loading} />
			<div style={{ textAlign: 'center' }}>
				{alert && <CustomAlert show severity="error" message={alert} />}
				<p>
					Antes de continuar, recuerda que este programa otorga beneficio a
					quienes menos tienen y más lo necesitan, al reducir tu deuda mediante
					el cambio a pesos de tu crédito contratado en VSM.
					<br />
					En caso de que tengas dudas de tu beneficio, comunicate al Infonatel
					en la Ciudad de México al 55 9171 5050 ó 800 008 3900 desde cualquier
					parte del país
				</p>
			</div>
			<div className="location-form">
				<SelectInput
					id="responsability-motive"
					onChange={({ target }: any) =>
						setSelectedCredit({
							...selectedCredit,
							motivoRechazo: target.value,
						})
					}
					name="motivoRechazo"
					value={selectedCredit?.motivoRechazo}
					label="Motivo"
					options={rejectionOptions}
				/>
			</div>
			<div className="actions-buttons">
				<RoundBtn onClick={() => setStep(3)} variant="outlined">
					Regresar
				</RoundBtn>
				<RoundBtn
					onClick={rejectCase}
					disabled={!selectedCredit?.motivoRechazo}
					variant="contained"
				>
					Finalizar
				</RoundBtn>
			</div>
		</>
	);
};

export default DenailReason;
