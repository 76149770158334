import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		typography: {
			fontSize: 12,
			color: '#173C69',
		},
		avatar: {
			marginRight: 10,
		},
		divider: {
			marginLeft: '10px !important',
			marginRight: '10px !important',
			[theme.breakpoints.down('lg')]: {
				marginLeft: '5px !important',
				marginRight: '5px !important',
			},
		}
	})
);
