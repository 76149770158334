/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import { Box, Paper } from '@mui/material';
import Tabs from '../../components/Tabs';
import { Base } from './Styles';
import ListAdminNss from './ListAdminNss';
import FormAdminNss from './FormAdminNss';
import Modal from '../../components/ModalConfirmation';
import { useTabs } from '../../hooks/useTabs';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { associateNssAPI } from '../../api/modules/AssociateNSS';
import ModalLoading from '../../components/ModalLoading';
import AssociatedNotification from './AssociatedNotification';
import { HeaderList } from '../../components/HeaderList';
import { formatDate } from '../../utils/dates';
import { IAlert } from '../../interfaces/alert';
import { useNavigate } from 'react-router-dom';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';

const tabsFlag = [
	{
		id: 1,
		title: 'Asocia tu NSS y suma créditos',
		disabled: false,
		checked: false,
		columns: 5,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: 2,
		title: 'Administrar cuentas asociadas',
		disabled: false,
		checked: false,
		columns: 5,
		orderMD: 1,
		orderSM: 2,
	},
];

const headerList = [
	<>
		En este servicio puedes asociar tu Número de Seguridad Social (NSS) y
		asi sumar tu crédito con el de otra persona, como tu cónyuge, un
		familiar o un corresidencial.
	</>,
	<>
		Recuerda que para asociar tu NSS y sumar tu crédito con alguien más,{' '}
		<span style={{ fontWeight: 'bold' }}>
			ambas personas necesitan tener activa Mi Cuenta Infonavit.
		</span>
	</>,
];
interface Props {
	setEtapa: React.Dispatch<React.SetStateAction<number>>;
	etapa: number;
	associated: any;
	setAssociated: any;
	msgError: IAlert;

	setAlert: any;
}

const AdminNss = ({
	setEtapa,
	etapa,
	associated,
	setAssociated,
	msgError,
	setAlert,
}: Props) => {
	const { user } = useSelector((state: RootState) => state.session);
	const classes = Base();
	const { containerPosition, tabActive, tabsEle, changeTabCustom } = useTabs(
		tabsFlag,
		1,
		0
	);
	const [associatedNotification, setAssociatedNotification] = useState(false);
	const [affiliated, setAffiliated] = useState<any>(null);
	const [showModal, setShowModal] = useState('');
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const fetchAffiliated = useCallback(async () => {
		try {
			setAlert({
				show: false,
				message: '',
				severity: 'success',
			} as IAlert);

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				key
			);
			associateNssAPI.consult
				.post<{ nss: string }, any>('', {
					nss: USER_NSS_BASE64,
				})
				.then((res: any) => {
					if (res.msg?.detalle?.nombreAsociado) {
						setAffiliated(res.msg.detalle);
						if (res.msg?.detalle?.estatus === '02')
							setAssociatedNotification(true);
					} else setAffiliated(null);
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error?.err || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) { }
	}, [user?.nss]);

	useEffect(() => {
		fetchAffiliated();
	}, [fetchAffiliated]);

	const setDataModal = (nss: string): void => {
		setShowModal(nss);
	};

	useEffect(() => {
		if (etapa === 3) {
			changeTabCustom(1);
		}
	}, [etapa]);

	const handleDeleteAssociated = async () => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				key
			);
			const ASSOCIATED_NSS_BASE64 = await encrypt(showModal, key);
			associateNssAPI.delete
				.post<{ nss: string; nssSecundario: string }, any>('', {
					nss: USER_NSS_BASE64,
					nssSecundario: ASSOCIATED_NSS_BASE64,
				})
				.then(async (res: any) => {
					await fetchAffiliated();
					setShowModal('');
				})
				.catch((err) => { })
				.finally(() => setLoading(false));
			navigate('/');
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<>
			<AssociatedNotification
				open={associatedNotification}
				setOpen={setAssociatedNotification}
				associated={affiliated}
				setLoading={setLoading}
				fetchAffiliated={fetchAffiliated}
				setAlert={setAlert}
			/>
			<ModalLoading loading={loading} />
			<Modal
				width={'sm'}
				open={Boolean(showModal)}
				cancelButton
				cancelLabel="No"
				confirmLabel="Sí"
				onConfirm={handleDeleteAssociated}
				onClose={() => setShowModal('')}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
					}}
				>
					<div>
						<h4
							style={{
								color: '#293990',
								fontSize: 20,
								fontWeight: '600',
								textAlign: 'center',
								marginTop: 50,
							}}
						>
							¿Estás seguro que quieres cancelar la asociación?
						</h4>
					</div>
				</div>
			</Modal>
			<HeaderList
				title="Asocia tu NSS y suma créditos"
				list={headerList}
				date={formatDate('PP')}
				hiddenInfo={tabActive !== 0}
			/>

			<div style={{ marginTop: 20 }}>
				<Tabs
					tabs={tabsEle}
					containerPosition={containerPosition}
					active={tabActive}
					containerColumns={10}
					onChangeTab={(indexTab: number) =>
						changeTabCustom(indexTab)
					}
					cardsType
				>
					<Paper className={classes.papper}>
						{tabActive === 0 && (
							<Box sx={{ width: '100%' }}>
								<FormAdminNss
									setEtapa={setEtapa}
									associated={associated}
									setAssociated={setAssociated}
									msgError={msgError}
								/>
							</Box>
						)}
						{tabActive === 1 && (
							<Box sx={{ width: '100%' }}>
								<ListAdminNss
									setDataModal={setDataModal}
									affiliated={affiliated}
								/>
							</Box>
						)}
					</Paper>
				</Tabs>
			</div>
		</>
	);
};

export default AdminNss;
