import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';

export const formatDate = (form = '', date = new Date()) => {
	return format(date, form, { locale: esLocale }) // FOMAT DATE OBJECT ON A SPECIFIC FORM
		.split(' ')
		.map((x) => x[0].toUpperCase() + x.substring(1))
		.join(' '); // TRANSFORM STRINGS INTO  PASCAL CASE
};

export function getFormatDate(date: Date): string {
	let day = date.getDate();
	let month = getMonthName(date.getMonth() + 1);
	let year = date.getFullYear();
	let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
	let minutes =
		date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

	let result = day + '/' + month + '/' + year + ' ' + hour + ':' + minutes;

	return result;
}

export function getFormatDate2(date: Date): string {
	let day =
		date.getDate() < 10
			? '0' + date.getDate().toString()
			: date.getDate().toString();
	let month = getMonthName(date.getMonth() + 1);
	let year = date.getFullYear();
	let hour =
		date.getHours() < 10
			? '0' + date.getHours().toString()
			: date.getHours().toString();
	let minutes =
		date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
	let AMPM = date.getHours() < 12 ? 'AM' : 'PM';

	let result =
		day +
		' - ' +
		month +
		' - ' +
		year +
		' / ' +
		hour +
		':' +
		minutes +
		' ' +
		AMPM;

	return result;
}

export function getFormatDateShort(
	date?: Date | undefined,
	separate?: string
): string {
	if (date === undefined) return '';

	let _date: Date = new Date(date) || new Date();

	let day =
		_date.getDate() < 10
			? '0' + _date.getDate().toString()
			: _date.getDate().toString();
	let month = _date.getMonth() + 1 < 10 ? '0' : '';
	month += (_date.getMonth() + 1).toString();
	// let month = getMonthName(_date.getMonth() + 1);
	let year = _date.getFullYear().toString();

	let result =
		day +
		(separate === undefined ? '/' : separate) +
		month +
		(separate === undefined ? '/' : separate) +
		year;

	return result;
}

export function getFormatHour(date?: Date | string | undefined): string {
	if (date === undefined) return '';
	let _date: Date = new Date(date) || new Date();

	let hour =
		_date.getHours() < 10
			? '0' + _date.getHours().toString()
			: (_date.getHours() % 12).toString();
	hour = hour.length === 1 ? '0' + hour : hour;
	let minutes =
		_date.getMinutes() < 10 ? '0' + _date.getMinutes() : _date.getMinutes();
	let AMPM = _date.getHours() < 12 ? 'AM' : 'PM';

	let result =
		(hour === '00' || hour === '0' ? 12 : hour) +
		':' +
		minutes +
		' ' +
		AMPM;

	return result;
}

export function getHour(hour: string): string {
	let splitHour = hour.split(':', 2);
	const fecha = new Date(
		2022,
		4,
		15,
		parseInt(splitHour[0]),
		parseInt(splitHour[1])
	);
	return getFormatHour(fecha);
	// let hora =
	// 	parseInt(splitHour[0]) <= 12
	// 		? '0' + parseInt(splitHour[0])
	// 		: (parseInt(splitHour[0]) % 12).toString();
	// let minutes =
	// 	parseInt(splitHour[1]) < 10 ? '0' + parseInt(splitHour[1]) : splitHour[1];
	// let AMPM = splitHour[0] < '12' ? 'AM' : 'PM';

	// let result = hour + ':' + minutes + ' ' + AMPM;

	// return result;
}

function getMonthName(month: number) {
	switch (month) {
		case 1:
			return 'Ene';
		case 2:
			return 'Feb';
		case 3:
			return 'Mar';
		case 4:
			return 'Abr';
		case 5:
			return 'May';
		case 6:
			return 'Jun';
		case 7:
			return 'Jul';
		case 8:
			return 'Ago';
		case 9:
			return 'Set';
		case 10:
			return 'Oct';
		case 11:
			return 'Nov';
		case 12:
			return 'Dic';
	}
}

export const formatStringToDateChar = (date: string, char: string): string => {
	if (!date) return '';
	if (date.length === 8) {
		let anio = date.substring(0, 4);
		let mes = date.substring(4, 6);
		let dia = date.substring(6, date.length);
		return `${dia}${char}${mes}${char}${anio}`;
	}
	return '';
};

export const formatStringToDate = (date: string): string => {
	if (!date) return '';
	if (date.length === 8) {
		let anio = date.substring(0, 4);
		let mes = date.substring(4, 6);
		let dia = date.substring(6, date.length);
		return `${dia}/${mes}/${anio}`;
	}
	return '';
};

export const getDateFromCURP = (curp: string) => {
	let birthDate = curp.substring(4, 10);
	let yearCURP = parseInt(birthDate.substring(0, 2));
	let monthCURP = parseInt(birthDate.substring(2, 4)) - 1; //Enero inicia en 0
	let dayCURP = parseInt(birthDate.substring(4, 6));

	let currentYearCentury = parseInt(
		new Date().getFullYear().toString().substring(0, 2) + '00'
	);
	let lastYearCentury = currentYearCentury - 100;

	if (currentYearCentury + yearCURP > new Date().getFullYear()) {
		return new Date(lastYearCentury + yearCURP, monthCURP, dayCURP);
	} else {
		return new Date(currentYearCentury + yearCURP, monthCURP, dayCURP);
	}
};
/**
 * It takes a date string in the format of dd/mm/yyyy or dd-mm-yyyy
 * and returns a date string in the format of yyyy/mm/dd,
 * this is for transform the date into a valid string for Date object
 * @param {string} date - string - The date you want to convert.
 * @returns A string
 */
export const getDateToObjectDate = (date: string): string => {
	let newDate = '';
	if (date.length === 10) {
		let day = date.substring(0, 2);
		let month = date.substring(3, 5);
		let year = date.substring(6);
		return `${year}/${month}/${day}`;
	}
	return newDate;
};
