import { MIUC_PRODUCTS, PRODUCT_LINES } from "../constants/products";
import { ReactComponent as ConstruirIcon } from '../../../assets/img/productsMIUC/contruir.svg';
import ampliarIcon from '../../../assets/img/productsMIUC/ampliar.png';
import construyoIcon from '../../../assets/img/productsMIUC/construyo.png';
import hipotecaIcon from '../../../assets/img/productsMIUC/hipoteca.png';
import casitaIcon from '../../../assets/img/productsMIUC/casita.png';
import { ReactComponent as TerrenoIcon } from '../../../assets/svg/casita2.svg';
import { IProductCard } from "../components/ProductCard/productCard";

export interface ICredit {
  product: string;
  icon: string;
  title: string;
  description: string;
  hoverDetails: string[];
}

export const mappedCredits: any = {
  // no tienen pantalla de posibles productos
  [PRODUCT_LINES.COMPRAR_VIVIENDA]: {
    credits: [
      {
        product: MIUC_PRODUCTS.COMPRA,
        icon: casitaIcon,
        title: 'Compra de vivienda',
        description: 'Nueva, existente e hipotecada incluso con el infonavit.',
        hoverDetails: ['Requiere 1080pts',],
      }
    ]
  },
  [PRODUCT_LINES.COMPRAR_TERRENO]: {
    credits: [
      {
        product: MIUC_PRODUCTS.CREDITERRENO,
        icon: <TerrenoIcon />,
        title: 'Comprar terreno',
        description: 'Para que en él construyas tu vivienda poco a poco, conforme a tus necesidades actuales y futuras.',
        hoverDetails: [
          'Requiere 980pts',
          'La suma de tus puntos pueden variar dependiendo el estado en donde se encuentre el terreno.'
        ],
      },
      {
        product: MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION,
        icon: <TerrenoIcon />,
        title: 'Comprar terreno y construcción de vivienda',
        description: 'En esta opción, con un sólo crédito puedes comprar suelo (terreno) y contar con recursos para construir tu vivienda.',
        hoverDetails: [
          'Requiere 980pts',
          'La suma de tus puntos pueden variar dependiendo el estado en donde se encuentre el terreno.'
        ],
      }
    ]
  },
  [PRODUCT_LINES.CONTRUIR_VIVIENDA]: {
    credits: [
      {
        product: MIUC_PRODUCTS.CONSTRUCCION,
        icon: <ConstruirIcon />,
        title: 'Construir una vivienda',
        description: 'En terreno propio o en el de tu cónyuge.',
        hoverDetails: ['Requiere 880pts',],
      }
    ]
  },
  [PRODUCT_LINES.MEJORA_HOGAR]: {
    credits: [
      {
        product: MIUC_PRODUCTS.MEJORAVIT,
        icon: ampliarIcon,
        title: 'Mejora tu hogar',
        description: 'En una vivienda a tu nombre o a nombre de un familiar autorizado.',
        hoverDetails: [],
      },
      {
        product: MIUC_PRODUCTS.AUTOPRODUCCION,
        icon: construyoIcon,
        title: 'ConstruYO',
        description: 'Puedes construir, ampliar o remodelar tu vivienda sin necesidad de que el terreno o vivienda estén a tu nombre.',
        hoverDetails: ['Requiere 1080pts',],
      }
    ]
  },
  [PRODUCT_LINES.PAGAR_HIPOTECA]: {
    credits: [
      {
        product: MIUC_PRODUCTS.HIPOTECA,
        icon: hipotecaIcon,
        title: 'Pagar una hipoteca',
        description: 'Pagar la deuda adquirida con otra entidad financiera distinta al Infonavit.',
        hoverDetails: ['Requiere 1080pts',],
      }
    ]
  },
}