import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Paper } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { HeaderList } from '../../../../components/HeaderList';
import ModalLoading from '../../../../components/ModalLoading';
import DisagreementTable from '../../views/DisagreementTable';
import CustomAlert from '../../../../components/CustomAlert';
import CustomButton from '../../../../components/Button';
import DisagreementForm from '../../views/DisagreementForm';
import Summary from '../../views/Summary';
import { encrypt } from '../../../../utils/encrypt';
import { apiFollowComplaint } from '../../../../api/modules/followComplaint';
import { defaultComplaintForm } from '../utils';
import { defaultDisagreement } from './utils';
import { defaultResponse, pattern } from '../../utils';
import { RootState } from '../../../../store';
import { useStyles } from '../../styles';
import { keyAPI } from '../../../../api/modules/key';
import { formatDate } from '../../../../utils/dates';
import { useBitacora } from '../../../../hooks/useBitacora';

const Disagreement = ({ setSelectedComplaint }: any) => {
	const { regBitacoraNotOld } = useBitacora();
	const { user } = useSelector((state: RootState) => ({
		user: state.session.user,
	}));
	const classes = useStyles();
	const navigation = useNavigate();
	const [step, setStep] = useState(0);
	const [complaint, setComplaint] = useState(defaultDisagreement);
	const [loader, setLoader] = useState(false);
	const [alert, setAlert] = useState('');
	const [resp, setResp] = useState(defaultResponse);
	const [optionsReasonComplaint, setOptionsReasonComplaint] = useState<any>(
		[]
	);
	const [reasonComplaint, setReasonComplaint] = useState('');
	const [tipificacion, setTipificacion] = useState<string>('');

	const fetchCatalogDisagreement = async (
		catalog = 'Z0003',
		clave_n1 = 'ZN100007',
		clave_n2 = ''
	) => {
		try {
			setLoader(true);
			const data = {
				catalogo: catalog,
				idN1: clave_n1,
				idN2: clave_n2,
			};
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const dataEncripted = await encrypt(
				JSON.stringify(data) || '',
				key
			);
			const result = await apiFollowComplaint.consultarCatalogo(
				dataEncripted
			);
			if (result.code && result.code === '000') {
				if (catalog === 'Z0003') {
					let catalogs = result?.data?.catalogos;

					setOptionsReasonComplaint(
						catalogs.map(({ n2Txt, idN2, ...other }: any) => ({
							value: idN2,
							text: n2Txt,
							...other,
						}))
					);
				}
			}
		} catch (error) {
			//console.log(error);
		} finally {
			setLoader(false);
		}
	};

	useEffect(() => {
		fetchCatalogDisagreement('Z0003', 'ZN100007');
	}, []);

	useEffect(() => {
		const selectTipificationsType3 = () => {
			let saveTipification = '';

			if (reasonComplaint !== '') {
				for (let i = 0; i < optionsReasonComplaint.length; i++) {
					if (optionsReasonComplaint[i].value === reasonComplaint) {
						saveTipification = optionsReasonComplaint[i].idFinal2;
					}
				}
			}

			return saveTipification;
		};
		setTipificacion(selectTipificationsType3());
	}, [tipificacion, optionsReasonComplaint, reasonComplaint]);

	const createComplaint = async () => {
		try {
			setLoader(true);
			const data1 = {
				...complaint,
				credito: '',
				fechaOcurrido: formatDate(
					'P',
					defaultComplaintForm.fechaOcurrido
				),
				nss: user?.nss || '',
				claseOperacion: 'ZQES',
				casoPrevio: complaint.caso,
				tipificacion: tipificacion,
				notas: complaint.notas ? complaint.notas : '',
				// notas: `${complaint.razon}${
				// 	complaint.notas ? ': ' + complaint.notas : ''
				// }`,
			};

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const dataEncripted = await encrypt(JSON.stringify(data1) || '', key);
			const result = await apiFollowComplaint.crearCaso(dataEncripted);
			if (result.code && result.code === '00000') {
				regBitacoraNotOld(apiFollowComplaint.schemeUrl, data1);
				const data = {
					nss: user?.nss || '',
					estatus: 'B',
					claseOperacion: 'ZQUE',
					numeroOrden: result?.data?.orderServicio || '',
				};
				const dataEncripted = await encrypt(JSON.stringify(data) || '', key);
				const suggestion = await apiFollowComplaint.getMyComplaints(
					dataEncripted
				);
				if (suggestion.code && suggestion.code === '0000') {
					setAlert('');
					setStep(step + 1);
					setResp({
						...suggestion.data.casos[0],
					});
				}
			} else {
				setAlert(
					result.message ||
					'Por el momento el servicio no se encuentra disponible, intente más tarde.'
				);
			}
		} catch (error: any) {
			setAlert(
				error?.description ||
				'Por el momento el servicio no se encuentra disponible, intente más tarde.'
			);
		} finally {
			setLoader(false);
		}
	};

	const back = () => {
		setAlert('');
		if (step === 0) {
			setSelectedComplaint(0);
		} else {
			setStep(step - 1);
		}
	};

	return (
		<>
			<ModalLoading loading={loader} />
			<HeaderList
				title="Haz y sigue una queja"
				list={
					step === 0
						? [
							'Para conocer la respuesta a tu queja, selecciona la que deseas consultar.',
						]
						: step === 1
							? [
								'Para darte una mejor atención, escribe el motivo de tu queja de la manera más clara posible y proporciónanos toda la información que tengas disponible.',
								'En caso de que necesitemos datos adicionales, nos comunicaremos contigo por medio de correo electrónico o vía telefónica.',
							]
							: [
								'Para conocer la respuesta o estatus de tu queja, ingresa a Mi cuenta Infonavit al apartado Consultar mis quejas, o bien comunicate al Infonatel al 5591715050 en la Ciudad de México, o al 800 008 3900 desde cualquier parte del país o ingresa al correo electrónico que nos proporcionaste.',
							]
				}
			/>
			<Paper className={classes.mainContainer}>
				{step === 0 && (
					<DisagreementTable
						form={complaint}
						setForm={setComplaint}
					/>
				)}
				{step === 1 && (
					<DisagreementForm
						form={complaint}
						setForm={setComplaint}
						optionsReasonComplaint={optionsReasonComplaint}
						setReasonComplaint={setReasonComplaint}
						reasonComplaint={reasonComplaint}
					/>
				)}
				{step === 2 && <Summary response={resp} />}
				{Boolean(alert) && (
					<CustomAlert message={alert} severity="error" show />
				)}
				<div className="actions-container">
					{step !== 2 ? (
						<>
							<CustomButton
								onClick={back}
								variant="outlined"
								label={'Salir'}
							/>
							<CustomButton
								disabled={
									(step === 0 && !complaint.caso) ||
									(step === 1 &&
										(!complaint.razon ||
											!pattern.test(complaint.notas)))
								}
								onClick={() =>
									step === 1
										? createComplaint()
										: setStep(step + 1)
								}
								variant="solid"
								label="Continuar"
							/>
						</>
					) : (
						<CustomButton
							onClick={() => navigation('/')}
							variant="solid"
							label="Finalizar"
						/>
					)}
				</div>
				{step === 1 && (
					<>
						<Divider style={{ marginTop: 15 }} />
						<h4 style={{ color: blue[900] }}>
							*Datos obligatorios
						</h4>
					</>
				)}
			</Paper>
		</>
	);
};

export default Disagreement;
