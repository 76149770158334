import { useState } from 'react';
import { Box, Paper, Checkbox } from '@mui/material';
import {
	ErrorOutlined,
	RadioButtonUnchecked,
	RadioButtonChecked,
} from '@mui/icons-material';
import CustomButton from '../../components/Button';
import { summarySavingsAPI } from '../../api/modules/summarySavings';
import { IAlert } from '../../interfaces/alert';
import CustomAlert from '../../components/CustomAlert';
import { createObjectEncript } from '../../utils/encrypt';
import ModalLoading from '../../components/ModalLoading';

interface IProps {
	setPage: (arg: number) => void;
	alert: IAlert, 
	setAlert: (arg: IAlert) => void;
	disabledButton: boolean;
}

interface ISuboptions {
	title: string;
	value: string;
}

interface IOptions {
	title: string;
	value: string;
	children?: ISuboptions[];
	tooltip?: string;
}

const listOptions: IOptions[] = [
	{
		title: 'Subcuenta de Vivienda 1997 (1 de julio de 1997 a la fecha)',
		value: '2',
		children: [
			{
				title: 'Movimientos que incrementaron y/o disminuyeron tu ahorro (distinto de un pago a un crédito)',
				value: '21',
			},
			{
				title: 'Aportaciones y/o amortizaciones enviadas a tu crédito',
				value: '22',
			},
			{ title: 'Pagos en aclaración', value: '23' },
		],
		tooltip:
			'Visualiza los movimientos de tu subcuenta de vivienda, aportaciones y/o amortizaciones enviadas de manera temporal a tu crédito o tus pagos en aclaración.',
	},
	{
		title: 'Subcuenta de Vivienda 1992 (1 de marzo de 1992 a la fecha)',
		value: '3',
		tooltip:
			'Visualiza los movimientos de tu subcuenta de vivienda y tus aportaciones patronales de manera bimestral y anual.',
	},
	{
		title: 'Fondo de Ahorro (1 de mayo de 1972 a la fecha)',
		value: '4',
		tooltip:
			'Visualiza de manera anual las aportaciones patronales, devoluciones y traspasos a tu crédito.',
	},
];

const SelectionMovements = ({ setPage, alert, setAlert, disabledButton }: IProps) => {
	const [selectOption, setSelectOption] = useState<Array<string>>([]);
	const [selectAll, setSelectAll] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChangeAll = () => {
		const auxSelect = !selectAll;
		setSelectAll(auxSelect);

		if (auxSelect) {
			let hijos: any = [];
			listOptions.forEach((e) => {
				if (e.children) {
					const values = e.children.map((d) => d.value);
					hijos.push(...values);
				}
			});
			setSelectOption([...listOptions.map((e) => e.value), ...hijos]);
			return;
		}

		setSelectOption([]);
	};

	const handleChange = (newValue: string) => {
		const findValue = selectOption.indexOf(newValue);
		if (findValue > -1) {
			const filterValues = selectOption.filter((e) => e !== newValue);
			setSelectOption(filterValues);
			setSelectAll(false);
			return;
		}
		const valueItems = [...selectOption, newValue];
		if (valueItems.length === 6) {
			handleChangeAll();
			return;
		}
		setSelectOption(valueItems);
	};

	const validationCheckBox = (): boolean => {
		if (selectAll) return false;
		if (selectOption.every((e) => e !== '2')) {
			if (selectOption.length > 0) {
				return false;
			}
		}
		if (selectOption.some((e) => e === '2')) {
			const filterVal = selectOption.filter((e) =>
				['21', '22', '23'].includes(e)
			);
			if (filterVal.length > 0) {
				return false;
			}
		}
		return true;
	};

	const getDocumentType = () => {
		let docTodo = 0,
			docViv97Todo = 0,
			subCatA = 0,
			subCatB = 0,
			subCatC = 0,
			docViv92 = 0,
			docFa = 0,
			tipoFormato = 1;

		if (selectAll) {
			docTodo = 1;
			return {
				docTodo,
				docViv97Todo,
				subCatA,
				subCatB,
				subCatC,
				docViv92,
				docFa,
				tipoFormato,
			};
		}
		if (
			selectOption.includes('2') &&
			['21', '22', '23'].every((element) =>
				selectOption.includes(element)
			)
		) {
			docViv97Todo = 1;
		}
		if (selectOption.includes('2') && selectOption.includes('21')) {
			subCatA = 1;
		}
		if (selectOption.includes('2') && selectOption.includes('22')) {
			subCatB = 1;
		}
		if (selectOption.includes('2') && selectOption.includes('23')) {
			subCatC = 1;
		}
		if (selectOption.includes('3')) {
			docViv92 = 1;
		}
		if (selectOption.includes('4')) {
			docFa = 1;
		}

		return {
			docTodo,
			docViv97Todo,
			subCatA,
			subCatB,
			subCatC,
			docViv92,
			docFa,
			tipoFormato,
		};
	};

	const handleSendData = async () => {
		setLoading(true);
		const data = getDocumentType();
		localStorage.setItem('parameters', JSON.stringify(data));
		const dataEncripted = await createObjectEncript(data);
		summarySavingsAPI
			.fileRequired(dataEncripted)
			.then((res) => {
				setLoading(false);
				if (res.result.codigo === '01') {
					//Solicitud de Ticket enviada exitosa
					setPage(2);
					return;
				}
				setAlert({
					show: true,
					message: res.result.description,
					severity: 'error',
				});
			})
			.catch(() => {
				setLoading(false);
				setAlert({
					show: true,
					message:
						'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					severity: 'error',
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<Paper>
			<Box padding={2} maxWidth={1100} textAlign={'left'} margin={'auto'}>
				<p style={{ fontWeight: 500, marginTop: 30 }}>
					Selecciona los movimientos de acuerdo con el tipo de ahorro
					que tengas
				</p>

				<Box margin={'30px 0px 50px 30px'}>
					<Box>
						<Checkbox
							color="error"
							icon={<RadioButtonUnchecked />}
							checkedIcon={<RadioButtonChecked />}
							checked={selectAll}
							onChange={handleChangeAll}
						/>
						Seleccionar todo
					</Box>
					{listOptions.map((option, i) => (
						<Box key={i} display={'flex'} alignItems={'baseline'}>
							<Box>
								<Checkbox
									name={i === 0 ? 'todo' : 'padre'}
									color="error"
									checked={
										selectOption.some(
											(e) => e === option.value
										)
											? true
											: false
									}
									onChange={(e) =>
										handleChange(e.target.value)
									}
									value={option.value}
								/>
							</Box>
							<Box>
								<p style={{ margin: 0, fontWeight: 400 }}>
									{option.title}
								</p>

								{option.tooltip && (
									<small
										style={{
											color: '#283990',
											fontWeight: 500,
											fontSize: 12,
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<div
											style={{
												marginRight: 3,
												width: 13,
												height: 13,
												display: 'inline-flex',
												justifyItems: 'center',
											}}
										>
											<ErrorOutlined
												style={{
													fontSize: 13,
												}}
											/>
										</div>
										<label>{option.tooltip}</label>
									</small>
								)}

								{selectOption.some((e) => e === option.value) &&
									option.children &&
									option.children.map((s, j) => (
										<Box
											key={j}
											margin={'1px 0px'}
											display={'flex'}
											alignItems={'baseline'}
										>
											<Box>
												<Checkbox
													color="error"
													name="hijo"
													checked={
														selectOption.some(
															(e) => e === s.value
														)
															? true
															: false
													}
													onChange={(e) =>
														handleChange(
															e.target.value
														)
													}
													value={s.value}
												/>
											</Box>
											<Box>
												<p
													style={{
														margin: 0,
													}}
												>
													{s.title}
												</p>
											</Box>
										</Box>
									))}
							</Box>
						</Box>
					))}
				</Box>
				<CustomAlert
					message={alert.message}
					severity={alert.severity}
					show={alert.show}
				/>
				<Box maxWidth={250} margin={'20px auto'}>
					<CustomButton
						label={'Continuar'}
						onClick={handleSendData}
						disabled={disabledButton || validationCheckBox()}
						variant={'solid'}
					/>
				</Box>
			</Box>
			<ModalLoading loading={loading} />
		</Paper>
	);
};

export default SelectionMovements;
