/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useController } from '../../../hooks/useController';
import { useProductTableMIUC } from '../../../hooks/useProductTableMIUC';
import { IAlert } from '../../../../../interfaces/alert';
import { IProductMonto } from '../../../../../interfaces/creditRequest';

import { apiMIUC } from '../../../../../api/modules/MIUC';

import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { isEmpty } from '../../../../../utils/validators';
import { formatDate } from '../../../../../utils/dates';
import { IFormat, formatValues, rowsTitleMejoraDirecta } from '../../../utils/selectionResume.utils';
import { MIUC_PRODUCTS } from '../../../constants/products';
import { downloadPDF } from '../../../../../utils/downloadPDF';
import { RETURN_CODE } from '../../../constants/returnCode';
import { creditTypes } from '../../../../CreditRequest/MIUC/utils';
import { CODES_RESPONSE } from '../../../../../config/miucConstants';

interface IProps {
	setProduct: (pruduct: any) => void;
}

export const useMejoraSiTable = () => {
	const { returnStep, addSelection, nextStep, getProgressStep, dataProcessController, saveData } = useController();
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const { user } = useSelector((state: RootState) => state.session);
	const { active, total } = getProgressStep();
	const newStep = {
		title: 'Estas son las opciones de financiamiento que tenemos para ti',
		subtitle:
			'Revisa cada opción, compara entre ellas y elige la que más te convenga',
		active,
		total,
	};
  const tooltipConstancia = 'Documento informativo que indica que cumples con los requisitos para solicitar el crédito mejOraSi.';

	const [selectProduct, setSelectProduct] = useState('');
	const [products, setProducts] = useState<any[]>([]);
	const disableButton = isEmpty(selectProduct);
	const [rawProducts, setRawProducts] = useState<IProductMonto[]>([]);

	const { isSearch } = useProductTableMIUC();

	useEffect(() => {
		getProductsMejoraDirecta();
	}, []);

	const getProductsMejoraDirecta = async () => {
		try {
			setLoading(true);
			const requestData = {
				nss: user?.nss || '',
				stageId: dataProcessController?.titular?.stageId || '4',
				producto: dataProcessController.processInfo?.creditType,
			};
			const ENCODED_DATA = await encodeAllJSONData(
				{ data: JSON.stringify(requestData) },
				user?.k || ''
			);
			const result = await apiMIUC.consultarMejoraDirecta(ENCODED_DATA);
			const { data, code, message } = result;
			if(code !== CODES_RESPONSE.CORRECTO){
				setAlert({
					show: true,
					message:
						message ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					severity: 'error',
				});
				return;
			}
			const productos = [data.productos[0]];
			if (result.code === RETURN_CODE.CORRECT) {
				const newProducts = productos.map((products: any) => {
					const productos = {
						...products,
						tasaInteres: products.tasaInteres,
						contariasCopia: products.contarias,
						montoCopia: products.montoCredito,
						producto: 'MD'
					};
					const formatedValues = formatValues(
						productos,
						rowsTitleMejoraDirecta as IFormat[]
					);
					return {
						...productos,
						...formatedValues,
					};
				});
				const newRawProducts = productos.map((products: any) => {
					const productos = {
						...products,
						tasaInteres: products.tasaInteres,
						contariasCopia: products.contarias,
						montoCopia: products.montoCredito,
						producto: 'MD'
					};
					return {
						...productos,
					};
				});
				setRawProducts(newRawProducts);
				setProducts(newProducts);
				setAlert({ show: false, message: '', severity: 'error' });
			} else {
				setAlert({
					show: true,
					message:
						result?.message ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					severity: 'error',
				});
			}
		} catch (error: any) {
			setAlert({
				show: true,
				message:
					error.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const next = () => {
		let product = rawProducts.find((prod) => prod.producto === selectProduct)
		// setProduct(rawProducts.find((prod) => prod.producto === selectProduct));
		// handleTrazabilidad();
		addSelection({
			title: 'Producto Seleccionado:',
			description: `${creditTypes[selectProduct].title}`,
		});
		saveData({
			processInfo: {
				productSelect: product
			},
			processData: {
				active: 3,
			},
		})
		nextStep();
	};

	const handleTrazabilidad = async () => {
		try {
			setLoading(true);
			const request = {
				nss: user?.nss?.toString() || '',
				curp: user?.curp || '',
				rfc: user?.rfc || '',
				nrp: user?.curp || '',
				nombre: user?.nombres || '',
				apellidoPaterno: user?.apPaterno || '',
				apellidoMaterno: user?.apMaterno || '',
				fecha: formatDate('dd.MM.yyyy'),
				mensaje:
					dataProcessController.processInfo?.credit.toUpperCase() ===
						MIUC_PRODUCTS.HIPOTECA
						? `PRECALIFICACION ${formatDate('dd/MM/yyyy')} ${dataProcessController.processInfo?.credit
						}`
						: 'MCI',
				subtipoTrazabilidad:
					products.find(prod => prod.producto === selectProduct)?.subtipoTrazabilidad || 'ZN01',
			};
			const newRequest = await encodeAllJSONData(
				{ data: JSON.stringify(request) },
				user?.k || ''
			);
			await apiMIUC.trazabilidadCRM(newRequest);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const handleGetAmortizationTable = async () => {
		setLoading(true);
		const data = await handleAmortizationTable(products[0], dataProcessController?.conyuge ? true : false);
		//console.log(data)
		const ENCODED_DATA = await encodeAllJSONData(
			{
				data: JSON.stringify(data),
			},
			user?.k || ''
		);
		apiMIUC.tablaAmortizacion(ENCODED_DATA)
			.then((response) => {
				if (response?.code === '0000') {
					downloadPDF({
						payload: response?.data?.data,
						name: 'Tabla de amortización',
					});
					setAlert({ show: false, message: '', severity: 'error' });
				}
			})
			.catch((err) => {
				setAlert({
					show: true,
					message:
						err.description ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					severity: 'error',
				});
			})
			.finally(() => setLoading(false));
	};

	const handleAmortizationTable = async (
		product: IProductMonto,
		conyuge: boolean
	): Promise<any> => {
		let destTot: string = '0';
		let descCredit: string = '0';
		let fondoProPagos: string = '0';
		let descuentoEco: string = '0';
		let destinoSeleccionado: string = '0';
		let productFlag: any = { ...product };
		//console.log(productFlag)

		if (dataProcessController?.processInfo?.credit) {
			destinoSeleccionado = dataProcessController?.processInfo?.credit?.substring(1, 2);
		} else {
			destinoSeleccionado = '0';
		}
		/* EL cat se obtiene cuando se manda llamar al CAT y esta recalculado*/
		const data: any = {
			nss: dataProcessController?.titular?.nss,
			tipoCredito: conyuge ? dataProcessController?.processInfo?.creditType : `${dataProcessController?.processInfo?.creditType}1`,
			producto: productFlag.producto,
			montoMaximoCredito: productFlag.montoCredito,
			descuentoTrabajador: destTot.toString(),
			tasaInteres: productFlag.tasaInteres,
			salarioDiarioIntegrado: dataProcessController?.titular?.salarioDiario,
			descuentoCredito: descCredit.toString(),
			salarioMinimoDiario: dataProcessController?.titular?.valorUMA,
			fondoProteccionPagos: (fondoProPagos || '').toString(),
			descuentoTrabajadorEcos: descuentoEco,
			destino: destinoSeleccionado,
			cat: productFlag.cat,
			descuentoTrabajadorSegDan: productFlag.segDan || '',
			montoMaximoEcos: productFlag.monEco || '',
			conyuge,
		};
		return data;
	};

  const handleDownloadConstancia = async() => {
    try {
      setLoading(true);
      const result = await apiMIUC.descargarConstanciaElegibilidad();
      const { pdf } = result.data;
      if (pdf) {
          let link = document.createElement('a');
          link.download = 'constancia_elegibilidad.pdf';
          link.href = 'data:application/octet-stream;base64,' + pdf;
          link.click();
      }
    } catch (error) {
    } finally {
        setLoading(false);
    }
  }

	return {
		loading,
		next,
		returnStep,
		setSelectProduct,
		selectProduct,
		products,
		newStep,
		disableButton,
		alert,
		isSearch,
		rawProducts,
		handleGetAmortizationTable,
    tooltipConstancia,
    handleDownloadConstancia,
	};
};