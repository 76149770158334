import React, { useEffect } from 'react';
import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import { pattern } from '../../utils';

const DisagreementForm = ({
	form,
	setForm,
	optionsReasonComplaint,
	setReasonComplaint,
	reasonComplaint,
}: any) => {
	useEffect(() => {
		// Limpiar los valores del campo de entrada de texto y el selector al cargar la vista
		setForm({ ...form, notas: '', razon: '' });
		setReasonComplaint('');
	}, []);

	const handleChange = ({ target }: any) => {
		const { name, value } = target;
		if (name === 'notas' && value.length > 1500) return;
		setForm({ ...form, [name]: value });
	};

	const handleReasonComplaintChange = ({ target }: any) => {
		const { name, value } = target;
		setReasonComplaint(target.value);
		setForm({ ...form, [name]: value });
	};

	return (
		<>
			<h5 style={{ fontSize: 15 }}>
				Escribe la información que se solicita
			</h5>
			<div className="form-container">
				<SelectInput
					isRequired
					id="follow-complaint-razon"
					name="razon"
					value={reasonComplaint}
					onChange={handleReasonComplaintChange}
					label="¿Cuál es el motivo de tu queja?"
					options={optionsReasonComplaint}
				/>
				<TextInput
					multiline
					isRequired
					rows={4}
					id="follow-complaint-notas"
					placeholder="Máximo 1,500 caracteres."
					label="Escribe el motivo de tu queja de la manera más clara posible"
					value={form.notas}
					onChange={handleChange}
					name="notas"
					helperText={
						!pattern.test(form.notas) && 'Caracter no permitido'
					}
				/>
				<span className="notas-helper">
					Te quedan {1500 - form.notas.length} caracteres.
				</span>
			</div>
		</>
	);
};

export default DisagreementForm;
