import API from '..';
class ApiDeedsDelivery extends API {
	getEstados(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/entrega-escritura-obtener-estados`,
				method: 'POST',
				data: {},
			})
		);
	}

	getCESI(estado_codigo: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/entrega-escritura-cesi-estado`,
				method: 'POST',
				data: { estado_codigo },
			})
		);
	}

	getConsultaCaso(nss: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/entrega-escritura-consulta-caso`,
				method: 'POST',
				data: { nss },
			})
		);
	}

	getCp(cp: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/address-catalog`,
				method: 'POST',
				data: { cp },
			})
		);
	}

	getEscrituraValida(numero_credito: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/entrega-escritura-valida`,
				method: 'POST',
				data: { numero_credito },
			})
		);
	}

	getCrearCaso(
		nss: string,
		numero_credito: string,
		domicilio: string,
		codigo_estado: string,
		codigo_cesi: string
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/entrega-escritura-crea-caso`,
				method: 'POST',
				data: {
					nss,
					numero_credito,
					domicilio,
					codigo_estado,
					codigo_cesi,
				},
			})
		);
	}

	getListarDocs(numero_caso: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/entrega-escritura-lista-documentos`,
				method: 'POST',
				data: { numero_caso },
			})
		);
	}

	getUploadWhitouFile(numero_caso: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/entrega-escritura-sin-documentos`,
				method: 'POST',
				data: { numero_caso },
			})
		);
	}

	getUploadFile(formData: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/entrega-escritura-subir-documentos`,
				method: 'POST',
				data: { formData },
				headers: { 'Content-Type': 'multipart/form-data' },
			})
		);
	}
}

export const apiDeedsDelivery = new ApiDeedsDelivery('');
