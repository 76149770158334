import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				//display: 'block',
				overflowX: 'auto',
			},
		},
		divContainerWeb: {
			display: 'flex',
			justifyContent: 'space-around',
			marginRight: 30,
			marginLeft: 30,

			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
		},
		divContainerMovil: {
			display: 'none',
			[theme.breakpoints.down('sm')]: {
				display: 'inherit',
			},
		},
		divContainer: {
			justifyContent: 'space-between',
			marginRight: 10,
			marginLeft: 10,
			[theme.breakpoints.down('sm')]: {
				display: 'block',
			},
		},
		div: {
			width: '40%',
			[theme.breakpoints.down('sm')]: {
				textAlign: 'left',
				width: '100%',
				marginLeft: 10,
			},
		},
		h3: {
			[theme.breakpoints.down('sm')]: {
				marginTop: 30,
				marginBottom: 0,
				fontSize: 16,
			},
		},
		p: {
			minHeight: '26px',
			[theme.breakpoints.down('sm')]: {
				margin: 0,
				fontSize: 16,
			},
		},
		hr: {
			opacity: 0.3,
			[theme.breakpoints.down('xs')]: {
				marginRight: 18,
				opacity: 0.5,
			},
		},
		labelheader: {
			fontWeight: 'bold',
			fontSize: 16,
			paddingTop: 40,
			marginBottom: 3,
			textAlign: 'center',
			[theme.breakpoints.down('sm')]: {
				fontSize: 16,
				marginLeft: 30,
				marginRight: 30,
			},
		},
		labelfooter: {
			marginTop: '40px',
			width: '100%',
			border: 'none',
			fontSize: 15,
			textAlign: 'center',
			padding: '0 50px 40px',
			[theme.breakpoints.down('sm')]: {
				fontSize: 15,
				paddingLeft: 30,
				paddingRight: 5,
				width: '90%',
				letterSpacing: 0,
			},
			[theme.breakpoints.down('md')]: {
				fontSize: 15,
				paddingLeft: 20,
				paddingRight: 20,
				width: '100%',
				letterSpacing: 0,
			},
		},
		labelheadertable: {
			fontWeight: 'bold',
			fontSize: 18,
			mt: 7,
			mb: 3,
			[theme.breakpoints.down('sm')]: {
				fontWeight: 'bold',
				fontSize: 14,
				mt: 3,
				mb: 3,
			},
		},
		labelheadertablemovil: {
			width: '95%',
			height: '50px',
			backgroundColor: '#333333',
			justifyContent: 'center',
			display: 'flex',
			alignItems: 'center',
			marginLeft: 35,
			[theme.breakpoints.up('xs')]: {
				marginLeft: 16,
				width: '91%',
				textAlign: 'center',
				height: '58px',
			},
			[theme.breakpoints.up('sm')]: {
				marginLeft: 22,
				width: '95%',
				textAlign: 'center',
				height: '58px',
			},
			[theme.breakpoints.up('md')]: {
				width: '95%',
				height: '50px',
				backgroundColor: '#333333',
				justifyContent: 'center',
				display: 'flex',
				alignItems: 'center',
				marginLeft: 30,
			},
		},
		stronglabel: {
			color: 'white',
			[theme.breakpoints.down('sm')]: {
				marginLeft: 5,
				marginRight: 5,
				marginTop: 3,
			},
		},
		labelheadertablemovilC: {
			width: '75%',
			height: '50px',
			backgroundColor: '#333333',
			justifyContent: 'center',
			display: 'flex',
			alignItems: 'center',
			marginLeft: 0,
			[theme.breakpoints.up('xs')]: {
				marginLeft: 20,
				width: '90%',
				textAlign: 'center',
				height: '58px',
			},
			[theme.breakpoints.up('sm')]: {
				marginLeft: 20,
				width: '95%',
				textAlign: 'center',
				height: '58px',
			},
			[theme.breakpoints.up('md')]: {
				// width: '75%',
				height: '50px',
				backgroundColor: '#333333',
				justifyContent: 'center',
				display: 'flex',
				alignItems: 'center',
			},
		},
		labelheadertablemovilModal: {
			width: '75%',
			height: '50px',
			backgroundColor: '#333333',
			justifyContent: 'center',
			display: 'flex',
			alignItems: 'center',
			marginLeft: 0,
			[theme.breakpoints.up('xs')]: {
				marginLeft: 0,
				width: '100%',
				textAlign: 'center',
				height: '58px',
			},
			[theme.breakpoints.up('sm')]: {
				marginLeft: 0,
				width: '100%',
				textAlign: 'center',
				height: '58px',
			},
			[theme.breakpoints.up('md')]: {
				width: '100%',
				height: '50px',
				backgroundColor: '#333333',
				justifyContent: 'center',
				display: 'flex',
				alignItems: 'center',
				marginLeft: 0,
			},
		},

		inputcontainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 100,
			marginBottom: 10,
			paddingLeft: 15,
			paddingRight: 15,
			[theme.breakpoints.down('sm')]: {
				display: 'block',
				marginLeft: 20,
				marginRight: 20,
				height: 'auto',
			},
		},
		inputsearch: {
			width: '40%',
			marginTop: 0,
			[theme.breakpoints.down('md')]: {
				flex: '1',
				// marginTop: 10,
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				marginTop: 10,
			},
		},
		hrvertical: {
			height: '100%',
			width: 1,
			marginLeft: 25,
			marginRight: 25,
			backgroundColor: '#CBCBCB',
			opacity: '0.5',
			[theme.breakpoints.down('md')]: {
				marginLeft: 14,
				marginRight: 14,
			},
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
		},
		backgroundContenMargin: {
			marginLeft: '15%',
			marginRight: '15%',
			marginTop: '3%',
			paddingBottom: '2%',
			[theme.breakpoints.down('sm')]: {
				marginLeft: '10%',
				marginRight: '10%',
			},
		},
		video: {
			width: '100%',
			height: '400px',
			[theme.breakpoints.down('sm')]: {
				// marginLeft: '10%',
				// marginRight: '10%',
				height: '200px',
			},
		},
		tableContainer: {
			marginLeft: 35,
			width: '94%',

			[theme.breakpoints.down('sm')]: {
				// marginLeft: 20,
				width: 50,
				marginLeft: 20,
			},
			[theme.breakpoints.down('md')]: {
				width: '30%',
				// padding: 10,
			},
		},
		tablein: {
			[theme.breakpoints.down('sm')]: {
				// marginLeft: 20,
				marginRight: 10,
				marginLeft: 0,
			},
			[theme.breakpoints.down('md')]: {
				width: '30%',
				// padding: 10,
			},
		},
		tableTitleHeader: {
			fontWeight: 'bold !important',
		},
		containerTitle: {
			display: 'flex',
			width: '100%',
			justifyContent: 'center',
			marginTop: 40,
		},
		headerTableTitle: {
			width: '100%',
			height: '50px',
			backgroundColor: '#333333',
			justifyContent: 'center',
			display: 'flex',
			alignItems: 'center',
		},
		savingLink: {
			display: 'flex',
			width: '100%',
			justifyContent: 'center',
			[theme.breakpoints.down('sm')]: {
				display: 'block',
			},
		},
		link: {
			color: theme.palette.primary.main,
			marginLeft: 5,
			cursor: 'pointer',
			textDecoration: 'underline',
		},
		pensiones: {
			position: 'relative',
			[theme.breakpoints.down('md')]: {
				minHeight: '88px',
			},
		},
		imgLogoPensiones: {
			position: 'absolute',
			height: '100px',
			top: '-37px',
			left: 275,
			[theme.breakpoints.down('lg')]: {
				position: 'relative',
				left: 0,
				top: 0,
				marginTop: -60,
				marginBottom: -40
			},
		},
		pPensiones: {
			minHeight: '26px',
			[theme.breakpoints.down('lg')]: {
				minHeight: '58px',
			},
			[theme.breakpoints.down('md')]: {
				minHeight: '80px',
			},
			[theme.breakpoints.down('sm')]: {
				margin: 0,
				fontSize: 16,
			},
		},
		imgLogoPensionesMovil: {
			height: '100px',
			marginTop: '-20px',
			marginBottom: '-40px'
		}
	})
);
