import {
	createAsyncThunk,
	createReducer,
	PayloadAction,
} from '@reduxjs/toolkit';
import {
	INITIAL_STATE,
	CLEAN_SALDO,
	SET_SALDO,
	SaldoState,
} from '../types/saldo';

export const actions = {
	setSaldo: createAsyncThunk(SET_SALDO, (saldo: string) => {
		return saldo;
	}),
	cleanSaldo: createAsyncThunk(CLEAN_SALDO, () => {
		return '';
	}),
};

export default createReducer(INITIAL_STATE, {
	[actions.setSaldo.fulfilled.type]: (
		_state: SaldoState,
		action: PayloadAction<string>
	): SaldoState => {
		return {
			..._state,
			saldo: action.payload,
		};
	},
	[actions.cleanSaldo.fulfilled.type]: () => ({
		saldo: '',
	}),
});
