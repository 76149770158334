export const MIUC_PROCESS_VIEWS = {
	SELECTION_CREDIT: 0,
	CREDIT_OPTIONS: 1,
	AUTHORIZATION_AND_USER_COMPLETE_INFO: 2,
	AVAILABLE_CREDIT: 3,
	SUMMARY_REQUEST: 4,
	NEXT_STEPS: 5,
	COUNTRY_MAP: 6,
	CREDIT_QUESTIONS_CONSTRUIR: 7,
	INFORMATIVE_CONTENT: 8,
	SECURE_CREDIT_REQUIREMENTS: 9,
	CREDIT_CONDITIONS: 10,
	NO_WORK_MENU: 11,
	CICB: 12,
	MEJORASI: 13,
	NO_POINTS: 14,
	SECURE_CREDIT: 15,
	INFONAVIT_SUPPORT: 16,
	MEJORAVIT: 17,
	AUTOPRODUCCION: 18,
};
