import { Box, useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../NipInfonatel/styles';
import CustomButtom from '../../components/Button';

interface IPersonalData {
	onContinue: () => void;
}
const ActivationNip = (props: IPersonalData) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const classes = useStyles();
	const navigate = useNavigate();
	const style = useStyles();

	return (
		<>
			<br />
			<Box className={classes.VerificacionCodigoTitle}>
				<h1>NIP Infonatel activado</h1>
			</Box>
			<br />
			<Box className={classes.VerificacionText}>
				<strong>Tu NIP Infonatel fue generado correctamente,</strong>{' '}
				recuerda que lo utilizaras cuando requieras <br />atención telefónica,
				de esta manera tu información se mantendrá segura.
			</Box>
			<br />
			<Box
				className={style.containerButtonsEnd}
				style={{ paddingBottom: '60px' }}
			>
				<Box margin={'20px 10px'} width={matches ? '80%' : 250}>
					<CustomButtom
						label={'Finalizar'}
						onClick={() => navigate('/')}
						variant="solid"
					/>
				</Box>
			</Box>
		</>
	);
};

export default ActivationNip;
