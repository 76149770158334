import { Grid, Divider } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { UseStyle } from './Style';
import CustomButton from '../../../components/Button';
import { LinkCustom, TiendaList } from './TiendaList';
import ModalConfirmUrl from './ModalConfirmUrl';
import { useState } from 'react';

interface Props {
	setOpenModal: (avg: boolean) => void;
}

const ServicesTrade = ({ setOpenModal }: Props) => {
	const classes = UseStyle();
	const [urlOut, setUrlOut] = useState('');

	return (
		<>
			<ModalConfirmUrl url={urlOut} setUrl={setUrlOut} />

			<Grid container columns={12} spacing={1} style={{ marginTop: 50 }}>
				<Grid item xs={12} md={3}>
					<label style={{ fontWeight: 500 }}>
						Tiendas de autoservicio y<br /> comercios afiliados
					</label>
				</Grid>
				<Grid item xs={12} md={9}>
					<div className={classes.PayStateUniteItem}>
						<CheckCircleOutlineRoundedIcon
							style={{
								fontSize: 18,
								color: '#29398E',
								borderColor: '#EDF4FF',
								marginTop: 2,
								marginRight: 5,
							}}
						/>
						<label>El pago mínimo es de $50.00 y en efectivo</label>
					</div>
					<div className={classes.PayStateUniteItem}>
						<CheckCircleOutlineRoundedIcon
							style={{
								fontSize: 18,
								color: '#29398E',
								borderColor: '#EDF4FF',
								marginTop: 2,
								marginRight: 5,
							}}
						/>
						<label>
							Sólo dale al cajero tu número de crédito o muestra
							tu estado de cuenta y dile cuánto vas a pagar
						</label>
					</div>
					<div className={classes.PayStateUniteItem}>
						<CheckCircleOutlineRoundedIcon
							style={{
								fontSize: 18,
								color: '#29398E',
								borderColor: '#EDF4FF',
								marginTop: 2,
								marginRight: 5,
							}}
						/>
						<label>
							En estos establecimientos no se cobra comisión por
							este servicio.
						</label>
					</div>

					<Divider style={{ marginTop: 30 }} />

					<div className={classes.ServicesTradeItemFirst}>
						<label style={{ fontWeight: 500, color: '#000' }}>
							Tiendas
						</label>
						<label style={{ fontWeight: 500, color: '#000' }}>
							Corresponsales/Filiales de Pago
						</label>
					</div>
					{TiendaList.map((elemnt, index) => (
						<div
							key={index}
							className={
								index === 0
									? classes.ServicesTradeItemFirst
									: classes.ServicesTradeItem
							}
						>
							<label>{elemnt.nombre}</label>
							<label>
								{(elemnt.list1Url || []).map((list, j) => (
									<article key={j}>
										{!list.text && (
											<LinkCustom
												title={list.title}
												url={list.url}
												setUrlOut={setUrlOut}
											/>
										)}
										{list.text && <b>{list.title}</b>}
										<br />
									</article>
								))}
								{(elemnt.list1 || []).map((list, j) => (
									<article key={j}>
										{list}
										<br />
									</article>
								))}
							</label>
							<label>
								{(elemnt.list2 || []).map((list, j) => (
									<article key={j}>
										{list}
										<br />
									</article>
								))}
							</label>
						</div>
					))}
				</Grid>
				<Grid item xs={12} md={12}>
					<Divider style={{ margin: '20px 0px' }} />
				</Grid>
				<Grid item xs={12} md={12}>
					<div
						style={{
							maxWidth: 250,
							margin: 'auto',
							marginBottom: 40,
						}}
					>
						<CustomButton
							variant="solid"
							onClick={() => setOpenModal(false)}
							label="Regresar"
						/>
					</div>
				</Grid>
			</Grid>
		</>
	);
};

export default ServicesTrade;
