// DEPENDENCIES
import React from 'react';
import { useNavigate } from 'react-router-dom';
// COMPONENTS
import CustomButton from '../../../components/Button';
import Layout from '../../../components/Layout';
// RESOURCES
import { useStyles } from './styles';

interface Props {
	setPage: (data: number) => void;
}

const LayoutNotice = ({ setPage }: Props) => {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Layout>
			<>
				<div className={classes.titleNoticeContainer}>
					<div>
						<h2 className={classes.titleNotice}>
							Devolución de pagos en exceso{' '}
						</h2>
					</div>
					<div className={classes.subTitleNotice}></div>
				</div>

				<div className={classes.container}>
					<div>
						<div className={classes.containerMin}>
							<h3 className={classes.principalTitle}>
								Verifica que los datos sean correctos
							</h3>
							<div className={classes.textInformation}>
								<p>Información actualizada al:</p>
								<p>DD/MM/AAAA</p>
								<p>
									Actualmente estás en un proceso de aclaración por
									devolución de pagos en exceso; comunícate sin costo a
									Infonatel (Ciudad de México al 5591715050 ó 800 0083
									900 para cualquier parte del país) para verificar el
									estado de tu aclaración.
								</p>
							</div>

							<div className={classes.contentFileDataButtons}>
								<CustomButton
									onClick={() => navigate('/inicio')}
									variant="solid"
									styles={{
										marginTop: 50,
										width: 200,
										marginInline: 10,
									}}
									disabled={false}
									label="Salir"
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		</Layout>
	);
};

export default LayoutNotice;
