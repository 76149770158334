import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
    titleBlue: {
      color: '#293990',
      margin: '10px 0 0'
    },
    container: {
      textAlign: 'left',
      padding: '40px'
    },
    options: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    documentList: {
      '&>.MuiListItem-root': {
        paddingY: '4px',
        '&>.MuiListItemIcon-root': {
          color: theme.palette.info.main,
          minWidth: 'auto',
          marginRight: '8px',
        },
        '&>.MuiListItemText-root': {
          margin: 0,
        }
      }
		},
    title: {
      color: theme.palette.info.main,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
    },
    noMargin: {
      margin: 0,
    },
    mainContainer: {
      width: '90%',
      margin: '0 auto',
    },
  }))