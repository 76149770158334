import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			textAlign: 'center',
		},
		creditContainer: {
			textAlign: 'left',
		},
		sectionTitle: {
			'& h4': {
				margin: 5,
			},
			'&>.subtitle': {
				display: 'flex',
				alignItems: 'center',
				color: theme.palette.info.main,
				'&> p': {
					margin: 0,
					marginLeft: 4,
					fontSize: 12,
					fontWeight: 'normal',
				}
			},
			marginBottom: 16
		},
		mejoraContainer: {
			margin: '20px 0',
			'& p': {
				margin: 0,
				fontSize: '16px',
				'& label': {
					color: '#D1001F',
					position: 'relative',
				}
			},
			'& .MuiTextField-root': {
				maxWidth: '320px',
			}
		},
		formContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			marginBottom: 10,
			'&>*': {
				flex: '1 1 300px',
				paddingRight: 10,
				[theme.breakpoints.down('sm')]: {
					flex: '1 1 100%',
					paddingRight: 0,
				},
			},
		},
		buttons: {
			display: 'flex',
			justifyContent: 'center',
		},
		info: {
			textAlign: 'left',
			color: theme.palette.info.main,
			paddingBottom: 16
		},
		creditDigit: {
			marginLeft: '50px',
			'& .MuiTextField-root': {
				maxWidth: '180px',
			}
		},
		paperCustomListItem: {
			display: 'flex',
			gap: '4px',
			margin: '0px 0px 6px'
		},
		containerListCheck: {
			margin: '50px 0px'
		},
    radioContainer: {
      display: 'flex',
      alignItems: 'center',
      '&> .label': {
        marginRight: '8px',
        marginY: '0px'
      }
    }
	}))