import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('137', 'presenta-sigue-queja');

export const ENDPOINTS_HAZ_Y_SIGUE_TU_QUEJA: IBitacora = {
	'/v1/haz-tu-queja/crear-queja-felicitacion': _bitacora.Read(
		'137100',
		'Crear queja'
	),
	'/v1/haz-tu-queja/consulta-caso-nota': _bitacora.Read(
		'137101',
		'Consultar caso'
	),
	'/v1/haz-tu-queja/consulta-catalogo': _bitacora.Read(
		'137102',
		'Consultar catalogo'
	),
	'/v1/haz-tu-queja/adjuntar-documento': _bitacora.Read(
		'137103',
		'Adjuntar documento'
	),
	'/v1/haz-tu-queja/crear-nota': _bitacora.Read('137104', 'Crear nota'),
};
