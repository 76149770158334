// DEPENDENCIES

// COMPONENTS
import BaseModal from '../../../components/Modal';
// RESOURCES
import { RoundBtn } from '../../AssociateNssCredit/Styles';

const AlertModal = ({ open, setOpen, title, description }: any) => {
	return (
		<BaseModal open={open} onClose={() => setOpen(!open)}>
			<div style={{ textAlign: 'center' }}>
				<h3>{title}</h3>
				<br />
				<span>{description}</span>
				<br />
				<small>
					Para cualquier duda o aclaración llama a Infonatel al 55 9171 5050 en
					la Ciudad de México, o al 800 008 3900 desde cualquier parte del país
					o acude al Centro de Servicio Infonavit más cercano.
				</small>
				<br />
				<RoundBtn
					style={{ maxWidth: 180 }}
					disableElevation
					variant="contained"
					onClick={() => setOpen(!open)}
				>
					Salir
				</RoundBtn>
			</div>
		</BaseModal>
	);
};

export default AlertModal;
