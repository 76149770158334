import { createContext, useContext, useState } from 'react';
import Layout from '../../../../../components/Layout';
import { IContextTaller } from './interfaces';
import { IRequestTaller, IWorkshopItem, WORKSHOP_VIEWS } from '../utils';

const ContextTaller = createContext<IContextTaller>({} as IContextTaller);

const TallerContext: React.FC = ({ children }) => {
	const [elementos, setElementos] = useState<IRequestTaller>(
		{} as IRequestTaller
	);
	const [activeView, setActiveView] = useState<IWorkshopItem>(
		WORKSHOP_VIEWS.FINANTIAL_EDUCATION
	);
	const [finishLearn, setFinishLearn] = useState(false);

	return (
		<Layout>
			<ContextTaller.Provider
				value={{
					elementos,
					setElementos,
					activeView,
					setActiveView,
					finishLearn,
					setFinishLearn
				}}
			>
				{children}
			</ContextTaller.Provider>
		</Layout>
	);
};

const useContextTaller = () => {
	const context = useContext(ContextTaller);
	if (context === undefined) {
		throw new Error('Error Context');
	}
	return context;
};

export { TallerContext, useContextTaller };
