import HelpPanel from '../../HelpPanel';
import birrete from '../../../assets/svg/curso.svg';
import sello from '../../../assets/svg/seleccion.svg';

export const ApoyoInfonavitSteps = () => {

	return [
		{
			paperType: 'simple',
			paperIcon: sello,
			title: <>
        Infórmate{' '}
        <HelpPanel type="APOYO_INFONAVIT" />
      </>,
			description: 'Sobre las condiciones financieras que te ofrecen las entidades financieras participantes y elige la que más te convenga.',
		},
    {
			paperType: 'simple',
			paperIcon: birrete,
			title: 'Tramita tu crédito con la entidad financiera que elijas',
			description: 'Presenta tu Certificado Apoyo Infonavit',
		},
    {
			paperType: 'simple',
			paperIcon: birrete,
			title: 'Firma la Carta de Instrucción Irrevocable',
			description: <p>
        <b>Esta te la dará la entidad financiera.</b><br />
        Con ella autorizas al Infonavit a utilizar el saldo de tu
        Subcuenta como garantía en caso de incumplimiento de pago por
        pérdida laboral así como la aplicación de las aportaciones
        patronales al capital del crédito que te otorgue la entidad financiera.
      </p>,
		},
	];
};
