import API from '../';

class ReturnOverpaymentsAPI extends API {
	validateCURP(curp: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/valid-curp`,
				method: 'POST',
				data: { curp },
			})
		);
	}
	consultCases(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/consult-cases`,
				method: 'POST',
				data: { nss },
			})
		);
	}
	availabilityRetirement(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/availability-retirement`,
				method: 'POST',
				data: { nss },
			})
		);
	}
	validEfirma(nss: string, nombre: string, rfc: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/valid-data-efirma`,
				method: 'POST',
				data: { nss, nombre, rfc },
			})
		);
	}
	validClabe(nss: string, clabe: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/valid-clabe`,
				method: 'POST',
				data: { nss, clabe },
			})
		);
	}
	validRetirement(nss: string, clabe: string, sesion: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/valid-retirement`,
				method: 'POST',
				data: { nss, clabe, sesion },
			})
		);
	}
	attachedFile(nss: string, pdfArchivo: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/attached-file`,
				method: 'POST',
				data: { nss, pdfArchivo },
			})
		);
	}
	reintentoDoct(caso: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/reintento-documento`,
				method: 'POST',
				data: { caso },
			})
		);
	}
}

export const returnOverpaymentsAPI = new ReturnOverpaymentsAPI('');
