/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Link, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import SelectInput from '../../components/SelectInput';
import { useStyles } from './style';
import { RowComponent } from '../../components/Rows';
import iconoDescarga from '../../assets/img/icono-descarga.png';
import { IItemTable, ITableRow } from '../../interfaces/tableRow';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import ModalLoading from '../../components/ModalLoading';
import { constanciaInteresesDeclaracionAnualAPI } from '../../api/modules/ConstanciaInteresesDeclaracionAnual';
import CustomAlert from '../../components/CustomAlert';
import { IAlert } from '../../interfaces/alert';
import { downloadPDF, downloadXML } from '../../utils/downloadPDF';
import { CheckCircleOutlined } from '@mui/icons-material';
import { rfcValid } from '../../utils/expressions';

interface IAnioFiscal {
	value: string;
	text: string;
	data: IResponse_Anio_Item;
}

interface IResponse_Anio_Item {
	ejercicioFiscal: string;
	entidadFinanciera: string;
	codigoRespuesta: string;
	domicilioEntidadFinanciera: string;
}

interface IResponse_constanciaInteresDeclaracionAnualFiles {
	rfc: string;
	tipoErrorRFC: string;
	xml: string;
	pdf: string;
}

const ConstanciaDeclaracionInteresesAnual = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);

	const [loading, setLoading] = useState(false);

	const [anios, setAnios] = useState({} as IResponse_Anio_Item[]);
	const [aniosOptions, setAniosOptions] = useState<
		{ value: string; text: string }[]
	>([{ value: '', text: '' }]);
	const [anioSelected, setAnioSelected] = useState({} as IResponse_Anio_Item);
	const [direccion, setDireccion] = useState('');
	const [showAlert, setShowAlert] = useState(false);
	const [disabledConsulta, setDisabledConsulta] = useState(true);
	const describeText = [
		'Ya puedes obtener la Constancia de interés del ejercicio 2022 de tu crédito hipotecario.',
		'Consulta e imprime aquí tu constancia anual',
	];
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [disableSelect, setDisableSelect] = useState(true);
	const [showCustom, setShowCustom] = useState(false);
	const [responseCDAFiles, setResponseCDAFiles] =
		useState<IResponse_constanciaInteresDeclaracionAnualFiles>(
			{} as IResponse_constanciaInteresDeclaracionAnualFiles
		);

	const [TableHidden, setTableHidden] = useState(true);
	const [DataOfTable, setDataofTable] = useState({
		data: [
			{
				header: 'Número de crédito',
				value: credit.toString(),
				keyTitle: 'NumeroCredito_Title',
				keyValue: 'NumeroCredito_Value',
			},
			{
				header: 'Año fiscal',
				value: anioSelected.ejercicioFiscal,
				keyTitle: 'anioFiscal_Title',
				keyValue: 'anioFiscal_Value',
			},
			{
				header: 'Nombre del acreditado',
				value: user?.given_name,
				keyTitle: 'Acreditado_Title',
				keyValue: 'Acreditado_Value',
			},
			{
				header: 'R.F.C.',
				value: user?.rfc,
				keyTitle: 'RFC_Title',
				keyValue: 'RFC_Value',
			},
		],
	} as ITableRow);
	const [validateRFC, setValidateRFC] = useState<{
		valid: boolean;
		msg: string;
		code?: string;
	}>({ valid: true, msg: '' } as {
		valid: boolean;
		msg: string;
		code?: string;
	});

	useEffect(() => {
		setDataofTable({
			data: [
				{
					header: 'Número de crédito',
					value: credit.toString(),
					keyTitle: 'NumeroCredito_Title',
					keyValue: 'NumeroCredito_Value',
				},
				{
					header: 'Año fiscal',
					value: anioSelected.ejercicioFiscal,
					keyTitle: 'anioFiscal_Title',
					keyValue: 'anioFiscal_Value',
				},
				{
					header: 'Nombre del acreditado',
					value: user?.given_name.toString() || '',
					keyTitle: 'Acreditado_Title',
					keyValue: 'Acreditado_Value',
				},
				{
					header: 'R.F.C.',
					value:
						responseCDAFiles && responseCDAFiles.rfc !== ''
							? responseCDAFiles.rfc
							: '',
					keyTitle: 'RFC_Title',
					keyValue: 'RFC_Value',
				},
			],
		});
	}, [responseCDAFiles, credit, user]);

	useEffect(() => {
		if (anioSelected) {
			onValidateAnioSelected();
		}
	}, [anioSelected]);

	const getAnios = async () => {
		if (credit.toString() === '0') {
			setShowCustom(true);
			return;
		}
		setLoading(true);
		setDisableSelect(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const numero_credito_base64 = await encrypt(
				credit.toString() || '',
				key
			);
			const dataFlag = {
				credit: numero_credito_base64,
			};

			constanciaInteresesDeclaracionAnualAPI
				.constanciaInteresDeclaracionAnual({ ...dataFlag })
				.then((res) => {
					if (res && Array.isArray(res.result)) {
						let Options: IAnioFiscal[] = [];
						let _anios: IResponse_Anio_Item[] = [];

						res.result.forEach((item: IResponse_Anio_Item) => {
							setDisableSelect(false);
							Options.push({
								value: item.ejercicioFiscal,
								text: item.ejercicioFiscal,
								data: {
									ejercicioFiscal: item.ejercicioFiscal,
									entidadFinanciera: item.entidadFinanciera,
									codigoRespuesta: item.codigoRespuesta,
									domicilioEntidadFinanciera:
										item.domicilioEntidadFinanciera,
								},
							} as IAnioFiscal);
						});

						res.result.forEach((item: IResponse_Anio_Item) => {
							_anios.push({
								ejercicioFiscal: item.ejercicioFiscal,
								entidadFinanciera: item.entidadFinanciera,
								codigoRespuesta: item.codigoRespuesta,
								domicilioEntidadFinanciera:
									item.domicilioEntidadFinanciera,
							} as IResponse_Anio_Item);
						});

						setAniosOptions(Options);
						setAnios(_anios);
						setDisabledConsulta(true);
					}
				})
				.catch((error) => {
					setDisabledConsulta(true);
					setAlert({
						show: true,
						message: error.descripcionRespuesta
							? error.descripcionRespuesta
							: error.description
							? error.description
							: error.msg?.code.includes('MCI')
							? error.msg?.description
							: INTERNAL_MESSAGES.CHECK_DATA,
						severity: 'error',
					});
					setLoading(false);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setDisabledConsulta(true);
			setLoading(false);
		}
	};

	const getCDAFiles = async () => {
		try {
			setLoading(true);
			setAlert({
				show: false,
				message: '',
				severity: alert.severity,
			});

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const numero_credito_base64 = await encrypt(
				credit.toString() || '',
				key
			);
			const year_base64 = await encrypt(
				anioSelected.ejercicioFiscal.toString() || '',
				key
			);
			const entidadFinanciera_base64 = await encrypt(
				anioSelected.entidadFinanciera.toString() || '',
				key
			);
			const dataFlag = {
				credit: numero_credito_base64,
				year: year_base64,
				entidadFinanciera: entidadFinanciera_base64,
			};
			constanciaInteresesDeclaracionAnualAPI
				.constanciaInteresDeclaracionAnualFiles({ ...dataFlag })
				.then(
					(res: IResponse_constanciaInteresDeclaracionAnualFiles) => {
						if (res) {
							setResponseCDAFiles(res);
							//Valida RFC con expresión regular
							setValidateRFC(rfcValid(res.rfc, false));
							setTableHidden(false);
						}
					}
				)
				.catch((error) => {
					setAlert({
						show: true,
						message: error.descripcionRespuesta
							? error.descripcionRespuesta
							: error.description
							? error.description
							: error.msg?.code.includes('MCI')
							? error.msg?.description
							: INTERNAL_MESSAGES.CHECK_DATA,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.msg?.code?.includes('MCI')
					? error.msg?.description
					: INTERNAL_MESSAGES.CHECK_DATA,
				severity: 'error',
			});
			setLoading(false);
		}
	};

	const onChangeSelect = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setTableHidden(true);
		setAlert({
			show: false,
			message: '',
			severity: alert.severity,
		});
		const { value } = event.target;
		let filter: any = anios.filter(
			(_anio: any) => _anio.ejercicioFiscal === value
		);
		setAnioSelected({
			ejercicioFiscal: filter[0].ejercicioFiscal
				? filter[0].ejercicioFiscal
				: '',
			entidadFinanciera: filter[0].entidadFinanciera
				? filter[0].entidadFinanciera
				: '',
			codigoRespuesta: filter[0].codigoRespuesta
				? filter[0].codigoRespuesta
				: '',
			domicilioEntidadFinanciera: filter[0].domicilioEntidadFinanciera
				? filter[0].domicilioEntidadFinanciera
				: '',
		} as IResponse_Anio_Item);
	};

	const onValidateAnioSelected = () => {
		setDireccion('');

		if (
			anioSelected.codigoRespuesta &&
			anioSelected.codigoRespuesta !== '00'
		) {
			if (
				Number(anioSelected.ejercicioFiscal) > 2016 &&
				'INFONAVIT' !== anioSelected.entidadFinanciera.toUpperCase()
			) {
				const direccion =
					!!anioSelected.domicilioEntidadFinanciera &&
					anioSelected.domicilioEntidadFinanciera.trim() !== ''
						? anioSelected.domicilioEntidadFinanciera
						: 'Sin información';

				setDireccion(direccion);
			}
			setShowAlert(true);
			setDisabledConsulta(true);
		} else {
			setShowAlert(false);
			setDisabledConsulta(false);
		}
	};
	const onDownloadFile = (value: string) => {
		switch (value) {
			case 'pdf':
				downloadPDF({
					payload: responseCDAFiles.pdf,
					name: 'Constancia_Intereses_Declaracion_Anual.pdf',
				});
				break;
			case 'xml':
				downloadXML({
					payload: responseCDAFiles.xml,
					name: 'Constancia_Intereses_Declaracion_Anual.xml',
				});
				break;
		}
	};

	useEffect(() => {
		getAnios();
	}, [credit]);
	const style = useStyles();
	return (
		<Layout>
			<div>
				<HeaderList
					key="HeaderConstanciaInteresesDeclaracionAnual"
					title="Constancia de intereses para tu declaración anual"
					list={describeText}
				/>
				<Paper>
					<Box className={style.BoxContainer}>
						<Box className={style.BoxSubContainer}>
							<Box className={style.BoxTitle}>
								<span className={style.title}>
									Elije el año fiscal
								</span>
							</Box>
							<Box className={style.BoxSelectAnioFiscal}>
								<SelectInput
									disabled={disableSelect}
									data-testid="anioFiscal"
									id="anioFiscal"
									name="anioFiscal"
									label=""
									value={anioSelected.ejercicioFiscal}
									onChange={onChangeSelect}
									placeholder={'Selecciona'}
									hiddenTextSelecciona={true}
									options={aniosOptions}
								/>
							</Box>
							{showCustom && (
								<CustomAlert
									message={
										'No se encontró información del crédito'
									}
									severity={'error'}
									show={true}
								/>
							)}
							<CustomAlert
								data-testid="mensageAlerttwo"
								message={''}
								severity={'error'}
								show={showAlert}
								onClose={() => {
									setAlert({
										show: false,
										message: '',
										severity: alert.severity,
									});
								}}
							>
								<>
									Por el momento no está disponible la Carta
									Constancia de Intereses.
									<br />
									<br />
									Tu carta está en proceso de generación con
									la Entidad Financiera{' '}
									<b>{anioSelected.entidadFinanciera}</b> que
									fondeó tu crédito, por lo que te pedimos
									ponerte en contacto con la Entidad
									correspondiente, su domicilio fiscal es{' '}
									<b>{direccion}</b>
								</>
							</CustomAlert>
							<Box className={style.BoxSelectAnioFiscal}>
								<CustomButton
									data-testid="Consultarbutton"
									onClick={getCDAFiles}
									variant="solid"
									styles={{}}
									label="Consultar"
									disabled={disabledConsulta}
								/>
							</Box>

							{TableHidden === false && (
								<Box className={style.BoxInfoTable}>
									{DataOfTable.data.map(
										(item: IItemTable) => (
											<RowComponent
												items={[
													{
														value: item.header,
														bold: true,
														key: item.keyTitle,
													},
													{
														value: item.value,
														bold: false,
														alignItems: 'right',
														key: item.keyValue,
													},
												]}
												links={[]}
											/>
										)
									)}
								</Box>
							)}

							{TableHidden === true && (
								<CustomAlert
									data-testid="mensageAlerttwo"
									message={alert.message}
									severity={alert.severity}
									show={alert.show}
									onClose={() => {
										setAlert({
											show: false,
											message: '',
											severity: alert.severity,
										});
									}}
								/>
							)}

							{TableHidden === false && (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'column',
										justifyContent: 'center',
										width: '100%',
									}}
								>
									<CustomAlert
										data-testid="mensageAlerttwo"
										message={alert.message}
										severity={alert.severity}
										show={alert.show}
										onClose={() => {
											setAlert({
												show: false,
												message: '',
												severity: alert.severity,
											});
										}}
									/>

									{validateRFC.valid === false && (
										<>
											<div className={style.BoxInfoTable}>
												<p>
													En tu Carta constancia de
													intereses reales{' '}
													{
														anioSelected.ejercicioFiscal
													}{' '}
													el campo de RFC registrado
													en nuestra base de datos es
													erróneo. Solicita tu
													comprobante de RFC en el
													módulo de atención del SAT
													que te corresponda y
													contáctanos cuanto antes.
												</p>
												<p>
													O entra a Mi cuenta
													Infonavit, en la opción Mi
													perfil, submenú Corrección
													de RFC y solicita la
													actualización, solo
													necesitas tu efirma o recibo
													de nómina electrónico.
												</p>
												<p>
													<strong>
														Tambien puedes pedir una
														cita en el portal del
														internet
														www.infonavit.org.mx
														para que acudas al CESI
														del Infonavit más
														cercano y solicites que
														te lo corrijan. A la
														cita lleva tu
														identificación oficial y
														comprobante del RFC que
														te entrege el SAT.
													</strong>
												</p>
												<p>
													Contacto Servicio de
													Administración Tributaria
													(SAT)<br></br>
													<Link
														href="http://www.sat.gob.mx/sitio_internet/sitio_aplicaciones/modulos_atencion/"
														underline="none"
														target="_blank"
													>
														http://www.sat.gob.mx/sitio_internet/sitio_aplicaciones/modulos_atencion/
													</Link>
												</p>
											</div>
										</>
									)}

									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center',
											width: '100%',
										}}
									>
										{responseCDAFiles &&
											responseCDAFiles.pdf !== '' && (
												<span
													className={style.links}
													onClick={() =>
														onDownloadFile('pdf')
													}
													style={{
														marginLeft: 30,
														marginRight: 30,
													}}
												>
													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
														}}
													>
														<label
															className={
																style.links
															}
															style={{
																marginTop: 5,
															}}
														>
															Descargar carta{' '}
														</label>
														<img
															src={iconoDescarga}
															alt="download icon"
															style={{
																marginLeft: 5,
																width: 24,
															}}
														/>
													</div>
												</span>
											)}

										{responseCDAFiles &&
											responseCDAFiles.xml !== '' && (
												<span
													className={style.links}
													onClick={() =>
														onDownloadFile('xml')
													}
													style={{
														marginLeft: 30,
														marginRight: 30,
													}}
												>
													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
														}}
													>
														<label
															className={
																style.links
															}
															style={{
																marginTop: 5,
															}}
														>
															Descargar XML{' '}
														</label>
														<img
															src={iconoDescarga}
															alt="download icon"
															style={{
																marginLeft: 5,
																width: 24,
															}}
														/>
													</div>
												</span>
											)}
									</div>
								</Box>
							)}
							<div className={style.divider} />
							<Box className={style.BoxTextContainer}>
								<p className={style.parrafoBold}>
									El Registro Federal de Contribuyentes (RFC)
									con el que expedimos la Constancia de
									intereses para tu declaración anual de
									impuestos es el que tenemos en el Infonavit.
									Revísalo y, si no es correcto, haz lo
									siguiente:
								</p>
								<Box className={style.lineText}>
									<CheckCircleOutlined
										color="info"
										style={{ marginRight: 5, width: 22 }}
									/>{' '}
									<span className={style.parrafo}>
										Ten a la mano la constancia de RFC que
										te dio el Servicio de Administración
										Tributaria (SAT).
									</span>
								</Box>
								<Box className={style.lineText}>
									<CheckCircleOutlined
										color="info"
										style={{ marginRight: 5, width: 22 }}
									/>{' '}
									<span className={style.parrafo}>
										Si todavía no la tienes, acude al módulo
										de atención del SAT que te corresponda
										para solicitarla.
									</span>
								</Box>
								<Box className={style.lineTextTop}>
									<CheckCircleOutlined
										color="info"
										style={{ marginRight: 5, width: 22 }}
									/>{' '}
									<span className={style.parrafo}>
										Haz una cita para que acudas al Centro
										de Servicio del SAT más cercano y
										solicites que te lo corrijan. Debes
										llevar el original y copia de los
										siguientes documentos: El Registro
										Federal de Contribuyentes{' '}
									</span>
								</Box>

								<div className={style.parrafoSangria}>
									<ul>
										<li>
											Constancia o copia de su cédula de
											RFC emitida por el SAT.
										</li>
										<li>
											Identificación oficial vigente con
											fotografía.
										</li>
									</ul>
								</div>
								<p className={style.parrafoBold}>
									Para consultar este documento es necesario
									que:
								</p>

								<Box className={style.lineText}>
									<CheckCircleOutlined
										color="info"
										style={{ marginRight: 5, width: 22 }}
									/>{' '}
									<span className={style.parrafo}>
										Tengas instalada la última versión de
										Acrobat Reader.
										<a
											href="https://get.adobe.com/reader/?loc=es"
											target="_blank"
											className={style.links2}
											rel="noreferrer"
										>
											Descárgalo aquí.
										</a>
									</span>
								</Box>
								<Box className={style.lineText}>
									<CheckCircleOutlined
										color="info"
										style={{ marginRight: 5, width: 22 }}
									/>{' '}
									<span className={style.parrafo}>
										Permitas ventanas emergentes en tu
										explorador de Internet.{' '}
										<a
											href="https://support.google.com/chrome/answer/95472?hl=es-419"
											target="_blank"
											className={style.links2}
											rel="noreferrer"
										>
											Consulta la guía.
										</a>
									</span>
								</Box>
							</Box>
						</Box>
						<ModalLoading loading={loading} />
					</Box>
				</Paper>
			</div>
		</Layout>
	);
};

export default ConstanciaDeclaracionInteresesAnual;
