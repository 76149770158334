import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    textAlign: 'center',
    '& .title': {
      margin: '40px 0',
    }
  },
  header: {
    textAlign: 'left',
  },
  optionList: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '30px',
    gap: '35px',
  },
  icoDescarga: {
    marginLeft: 5, 
    maxWidth: '18px',
  },
  linkBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    fontSize: '16px',
    marginBottom: '50px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))