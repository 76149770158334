import { Button, styled } from "@mui/material";

export const RoundBtn = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: 40,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    margin: '16px 0px',
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '260px',
    margin: '16px 8px',
  },

}));