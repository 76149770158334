import { Divider, Box } from '@mui/material';
import CustomButton from '../../../../../components/Button';
import styleClass from './styles/styles.module.css';

interface Props {
	dataAviable?: {
		title: string;
		image: any;
		description: string;
	};
	setOpen: (arg: boolean) => void;
	setOpenModal?: (arg: boolean) => void;
}

const AviableCreditPanel = ({ dataAviable, setOpen, setOpenModal }: Props) => {
	return (
		<>
			<Box className={styleClass.AviableCreditPanelBox1}>
				<img
					className={styleClass.imgCustom}
					src={dataAviable?.image}
					alt="logo"
				/>
			</Box>
			<Divider className={styleClass.dividerClass} />

			<p className={styleClass.fueteFuerte}>Características</p>
			{dataAviable?.description}

			<Divider className={styleClass.dividerClass} />
			<Box className={styleClass.boxCustom}>
				<CustomButton
					label={'Cerrar'}
					onClick={() => {
						setOpen(false);
						setOpenModal && setOpenModal(false);
					}}
					variant={'solid'}
				/>
			</Box>
		</>
	);
};

export default AviableCreditPanel;
