import { Paper, Box } from '@mui/material';
import Layout from '../../../../../components/Layout';
import { HeaderList } from '../../../../../components/HeaderList';
import { useStyles } from './styles/styles';

const RequestProCan = () => {
	const classes = useStyles();
	return (
		<Layout>
			<div>
				<HeaderList
					title="Me interesa un crédito"
					list={[
						'En este servicio puedes dar seguimiento a tu solicitud de crédito en línea.',
					]}
				/>
				<Paper>
					<Box padding={3} maxWidth={900} margin="auto">
						<h3 className={classes.textCenter}>
							Solicitud en proceso de cancelación
						</h3>
						<p className={classes.marginTextCenter}>
							Aún no ha concluido el proceso de cancelación, te
							sugerimos ingresar nuevamente dentro de 24 hrs.
						</p>
					</Box>
				</Paper>
			</div>
		</Layout>
	);
};

export default RequestProCan;
