import API from '..';
class ApiMySaving extends API {
	getSavingTotal(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/cat/consulta-saldo/total`,
				method: 'POST',
				data: { nss },
			})
		);
	}
	getSavingDetalle(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/cat/consulta-saldo/detalle`,
				method: 'POST',
				data: { nss },
			})
		);
	}
	getSavingAnterior(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/cat/consulta-saldo/anterior`,
				method: 'POST',
				data: { nss },
			})
		);
	}
	getSavingBienestar(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/cat/consulta-fondo-pensiones/bienestar`,
				method: 'POST',
				data: { nss },
			})
		);
	}
}

export const apiMySaving = new ApiMySaving('');
