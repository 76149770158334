import { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
interface Props {
	signOff: () => void;
	active: () => void;
	timeToExpire: number;
	isLogged: boolean;
}

const timeInactividad = 1000 * 600;
const timeToModal = 1000 * 60;

export default function useIdle({
	signOff,
	active,
	timeToExpire,
	isLogged,
}: Props) {
	const timeout: number = timeToExpire > 0 ? timeToExpire : timeInactividad; //tiempo en milisegundos de espera de inactividad
	const promptBeforeIdle: number = timeToModal; //cantidad de milisegundos antes del tiempo de inactividad
	const [openModalSession, setOpenModalSession] = useState(false);
	const [remaining, setRemaining] = useState<number>(0);
	const [refreshToken, setRefreshToken] = useState<number>(0);

	useEffect(() => {
		if (isLogged) {
			const ejecute = () => {
				active();
				setRefreshToken(refreshToken + 1);
			};
			const interval = setInterval(ejecute, timeout - promptBeforeIdle);
			return () => {
				clearInterval(interval);
			};
		}
	}, [refreshToken]); // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		active();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	const onPrompt = () => {
		if (isLogged) {
			setOpenModalSession(true);
			setRemaining(promptBeforeIdle);
		}
	};
	const onIdle = () => {
		if (isLogged) {
			setOpenModalSession(false);
			setRemaining(0);
			signOff();
		}
	};
	const onActive = () => {
		if (isLogged) {
			setOpenModalSession(false);
			setRemaining(0);
		}
	};
	const onAction = (event?: Event) => {
		//console.log(event);
	};
	const { getRemainingTime, isPrompted, activate } = useIdleTimer({
		onAction,
		timeout,
		promptBeforeIdle,
		onPrompt,
		onIdle,
		onActive,
	});

	return {
		getRemainingTime,
		isPrompted,
		activate,
		remaining,
		setRemaining,
		openModalSession,
		setOpenModalSession,
		onActive,
	};
}
