import API from '..';

class ElectroNotify extends API {
	getValid(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/near-consult`,
				method: 'POST',
				data: {},
			})
		);
	}
}

export const apiElectroNotify = new ElectroNotify('');
