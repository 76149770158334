import { HeaderList } from '../../components/HeaderList';
import CustomAlert from '../../components/CustomAlert';
import { Box } from '@mui/material';

const NoFoundServices = () => {
	return (
		<>
			<HeaderList
				title="Saldos y movimientos"
				list={[
					'En este servicio encuentras la información necesaria de tu crédito; saldo,mensualidad, pagos y estados de cuenta.',
				]}
			/>

			<Box marginTop={4}>
				<CustomAlert
					message="Por el momento el servicio no está disponible, intenta más tarde."
					severity="error"
					show={true}
				/>
			</Box>
		</>
	);
};

export default NoFoundServices;
