import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		borderTest: {
			border: '1px solid black',
		},
		marginContainer: {
			paddingLeft: 280,
			paddingRight: 280,
		},
		container: {
			padding: 20,
		},
		containerInputs: {
			display: 'flex',
			justifyContent: 'space-around',
			flexDirection: 'column',
			width: 500,
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		containerCenter: {
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'column',
			alignItems: 'center',
			marginBottom: 25,
		},
		containerButton: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			width: 500,
			marginTop: 10,
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column-reverse',
				justifyContent: 'space-around',
				height: 120,
				width: '100%',
			},
		},
		containerButtonBoxAceptarCambios: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			width: 400,
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column-reverse',
				justifyContent: 'space-around',
				height: 120,
			},
		},
		button: {
			width: 170,
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},

		fuentefooter: {
			font: ' Arial',
			fontSize: 14,
		},
		fuentefooterRed: {
			font: ' Arial',
			color: 'red',
			fontSize: 14,
			textDecoration: 'underline',
			'&:hover': {
				cursor: 'pointer',
			},
		},
		hrColor: {
			borderTop: ' 1px solid #E1E3E6',
		},
		TextoCentrado: {
			textAlign: 'center',
		},
		TextoIzquierda: {
			textAlign: 'left',
		},
		fuenteRed: {
			color: 'red',
			fontSize: 16,
			'&:hover': {
				cursor: 'pointer',
			},
		},
		BoxCell3: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			[theme.breakpoints.down('md')]: {
				alignItems: 'start',
			},
			[theme.breakpoints.down('sm')]: {
				alignItems: 'start',
			},
		},
		BoxCell3Link: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'start',
		},
		BoxCell3Container: {
			display: 'flex',
			flexDirection: 'row',

			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
			},
		},
		BoxCell3_Left: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'end',
		},
		TextBlack_H4: {
			fontWeight: 'bold',
			fontSize: 14,
		},
		TextBlack_H3: {
			fontWeight: 'bold',
			fontSize: 16,
		},
		Text_H3: {
			fontFamily: 'Roboto',
			lineHeight: 1,
			fontSize: 14,
			letterSpacing: 0.00938,
		},
		Text_H3_lineHeight: {
			fontFamily: 'Roboto',
			lineHeight: 'normal',
			fontSize: 14,
		},
		Text_H4: {
			fontSize: 14,
		},

		BoxFooterAceptarCambio: {
			textAlign: 'center',
			paddingLeft: 160,
			paddingRight: 160,
			paddingTop: 20,
			display: 'block',

			[theme.breakpoints.down('md')]: {
				paddingLeft: 2,
				paddingRight: 2,
				paddingTop: 20,
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 5,
				paddingRight: 5,
				paddingTop: 20,
			},
		},
		title: {
			color: '#293990',
			display: 'flex',
			fontWeight: 'bold',
			fontSize: 22,
			lineHeight: 1,
			'&>h2': {
				color: theme.palette.info.dark,
			},
			'&>p': {
				[theme.breakpoints.down('sm')]: {
					display: 'none',
				},
			},
		},
		subtitle: {
			color: '#293990',
			marginBottom: -20,
			fontWeight: 'bold',
			fontSize: 16,
			lineHeight: 1,
		},
		boxButtonFinalizar: {
			marginLeft: 180,
			marginRight: 180,
			[theme.breakpoints.down('md')]: {
				marginLeft: 0,
				marginRight: 0,
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: 0,
				marginRight: 0,
			},
		},
		DatosObligatorios: {
			fontFamily: 'Geomanist, Medium',
			fontSize: '16px',
			color: '#293990',
			fontWeight: 'bold',
			marginTop: '15px',
			marginBottom: '0px',
			textAlign: 'left',
			width: '100%',
		},
	})
);
