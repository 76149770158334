export const menusNotLogged = [
	{
		id: 1,
		name: 'Oficinas de atención',
		path: 'oficinas-atencion',
		children: [],
	},
	{
		id: 2,
		name: 'Canales de servicio',
		path: 'canales-servicio',
		children: [],
	},
];
