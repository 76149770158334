import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('133', 'solicitud-reestructuras');

export const ENDPOINTS_SOLICITUD_DE_RESTRUCTURA: IBitacora = {
	'/reestructuras/consulta-caso': _bitacora.Read('133100', 'Consultar caso'),
	'/reestructuras/disponibilidad-convenios': _bitacora.Read(
		'133101',
		'Consultar disponibilidad'
	),
	'/reestructuras/factor-dcp': _bitacora.Read('133102', 'Consultar Factor'),
	'/reestructuras/consulta-documento': _bitacora.Read(
		'133103',
		'Consultar documentos'
	),
	'/reestructuras/crea-caso': _bitacora.Read('133104', 'Crear caso'),
	'/reestructuras/crea-rechazo': _bitacora.Read('133105', 'Crear rechazo'),
	'/reestructuras/tipos-rechazo': _bitacora.Read(
		'133106',
		'Consultar tipos de rechazo'
	),
	'/reestructuras/generar-convenio': _bitacora.Read(
		'133107',
		'Generar convenio'
	),
	'/reestructuras/generar-convenio-bcn': _bitacora.Read(
		'133108',
		'Generar convenio BCN'
	),
	'/reestructuras/firmar-documento': _bitacora.Read(
		'133109',
		'Firmar documento'
	),
	'/reestructuras/encolar-convenio': _bitacora.Read(
		'133110',
		'Encolar documento'
	),
};
