import { useEffect, useState } from "react";
import { MIUC_PROCESS_VIEWS } from "../../constants/process";
import { NoWorkMenu } from "../CICB/views/NoWorkMenu/noWorkMenu";
import CICB from "..//CICB/CICB";
import NothingPage from "../CICB/views/NothingPage";
import { useController } from "../../hooks/useController";
import { MIUC_PRODUCTS } from "../../constants/products";
import MejoraSi from "../MejoraSi";
import { msgErrorMevi } from "../../../CreditRequest/MIUC/utils";
import { STAGE_ID } from "../../constants/stageId";
import ErrnoPage from "../../../CreditRequest/ErrnoPage";

export const SinRelacionLaboral = () => {

	const { saveData, getStepView, nextStep, dataProcessController } = useController();
	const [view, setView] = useState(MIUC_PROCESS_VIEWS.NO_WORK_MENU);
  const [errorMessage, setErrorMessage] = useState<JSX.Element>();

	useEffect(() => {
		navigation();
	}, [dataProcessController])

	const navigation = () => {
    const credit = dataProcessController.processInfo?.credit

    if(![
      MIUC_PRODUCTS.CICB,
      MIUC_PRODUCTS.MEJORA_DIRECTA
    ].includes(credit)){
			setView(MIUC_PROCESS_VIEWS.NO_WORK_MENU)
		}else{
      switch (credit) {
        case MIUC_PRODUCTS.CICB:
          setView(MIUC_PROCESS_VIEWS.CICB);
          break;
        case MIUC_PRODUCTS.MEJORA_DIRECTA:
          setView(MIUC_PROCESS_VIEWS.MEJORASI);
          break;
      }
    }
	}

	const onSelect = (esMejoraDirecta: boolean) => {

		let credit = '';
		let view: number;

		if (esMejoraDirecta) {
			credit = MIUC_PRODUCTS.MEJORA_DIRECTA;
			view = MIUC_PROCESS_VIEWS.MEJORASI;
		} else {
			credit = MIUC_PRODUCTS.CICB;
			view = MIUC_PROCESS_VIEWS.CICB;
		}
    const stageId = dataProcessController.titular.stageId;
    const stageIds = [
      STAGE_ID.FRAUDE_DEFUNCION,
      STAGE_ID.DEFUNCION,
    ];

    if(esMejoraDirecta && stageIds.includes(stageId)){
      setErrorMessage(msgErrorMevi(Number(stageId)));
    } else {
      saveData({
        processInfo: {
          credit,
          esMejoraDirecta,
        },
      });
      nextStep();
      setView(view);
    }
	}

	return (
		<>
			{
        errorMessage !== undefined ? <ErrnoPage msgString={errorMessage} fullPage />
				: view === MIUC_PROCESS_VIEWS.NO_WORK_MENU ? (
					<NoWorkMenu onSelect={onSelect} />
				)
					: view === MIUC_PROCESS_VIEWS.CICB ? (<CICB />)
						: view === MIUC_PROCESS_VIEWS.MEJORASI ? (<MejoraSi />)
							: <NothingPage />
			}
		</>
	)
}
