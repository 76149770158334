import { useState } from 'react';
import { Link, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TableCustom from '../../../components/TableCustom';
import SelectCustom from '../../../components/SelectCustom';
import ButtonCustom from '../../../components/Button';
import icono from '../../../assets/img/icono-descarga.png';

const columnas2 = ['Fecha', 'Concepto', 'Monto Transacción'];

const columnas = [
	'Fecha',
	'Concepto',
	'Pago total',
	'Pago a seguros y cuotas',
	'Pago a intereses',
	'Pago a capital',
];

interface Props {
	datos: Array<Array<string | number>>;
	getMovimientos: (periodo: string, tipoCredito: string) => void;
	getPdfMovimientos: (tipoCredito: string) => void;
	codigoDemanda: boolean;
	creditoEspecial?: number;
	cleanData: () => void;
}

const ReportMovement = ({
	datos,
	getMovimientos,
	getPdfMovimientos,
	codigoDemanda,
	creditoEspecial,
	cleanData,
}: Props) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [filter, setFilter] = useState({
		search: '',
		mostrar: '',
	});

	if (!codigoDemanda) return null;
	return (
		<div style={{ margin: '30px 0px', fontSize: 15 }}>
			{creditoEspecial !== 4 && (
				<>
					<p style={{ margin: '20px 0px', fontSize: 15, fontWeight: 500 }}>
						Consulta los movimientos de Mi Crédito
					</p>
					<div
						style={{
							display: 'flex',
							alignItems: matches ? 'center' : 'end',
							marginBottom: 30,
							flexDirection: matches ? 'column' : 'inherit',
						}}
					>
						<div
							style={{
								width: matches ? '100%' : 300,
								margin: matches ? 10 : '0px 10px',
							}}
						>
							<SelectCustom
								name="search"
								value={filter.search}
								options={[
									{ value: '1', label: 'Mes actual' },
									{ value: '2', label: 'Mes anterior' },
									{ value: '3', label: 'Tres meses' },
									{ value: '4', label: 'Pagos Parciales' },
								]}
								label="Buscar desde:"
								placeholder="Selecciona"
								onChange={(e) => {
									setFilter({
										...filter,
										[e.target.name]: e.target.value,
									});
									cleanData();
								}}
							/>
						</div>
						<div
							style={{
								width: matches ? '100%' : 300,
								margin: matches ? 10 : '0px 10px',
							}}
						>
							<SelectCustom
								disabled={ filter.search === '4' && true }
								name="mostrar"
								value={filter.search === '4' ? 'PESOS' : filter.mostrar }
								options={[
									{ value: 'PESOS', label: 'PESOS' },
									{ value: 'VSM', label: 'VSM' },
								]}
								label="Mostrar cantidades en:"
								placeholder="Selecciona"
								onChange={(e) =>
									setFilter({
										...filter,
										[e.target.name]: e.target.value,
									})
								}
							/>
						</div>
						<div
							style={{
								flexGrow: 1,
								display: 'flex',
								justifyContent: matches ? 'inherit' : 'end',
								width: matches ? '100%' : 'auto',
							}}
						>
							<div
								style={{
									width: matches ? '100%' : 200,
									marginBottom: 5,
									marginTop: matches ? 20 : 0,
								}}
							>
								<ButtonCustom
									disabled={filter.search !== '' ? false : true}
									label="Buscar"
									variant="solid"
									onClick={() =>
										getMovimientos(filter.search, filter.mostrar)
									}
								/>
							</div>
						</div>
					</div>

					{filter.search === '4' ? (
						<TableCustom
							columns={columnas2}
							data={datos}
							orderByCol
							no_found_title="No se encontraron movimientos"
						/>
					) : (
						<TableCustom
							columns={columnas}
							data={datos}
							orderByCol
							no_found_title="No se encontraron movimientos"
						/>
					)}

					{datos.length > 0 && filter.search !== '4' && (
						<Link
							href="#DowloadPdf"
							underline="none"
							onClick={() => getPdfMovimientos(filter.mostrar)}
							style={{
								margin: 'auto',
								width: matches ? '100%' : 300,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							Descargar tu información en formato PDF
							<img
								alt="lgDes"
								src={icono}
								style={{ width: 15, marginLeft: 5 }}
							/>
						</Link>
					)}
				</>
			)}

			<p style={{ textAlign: 'center' }}>
				Para consultar los documentos anteriores es necesario que:
				<br />
				<br />
				Tengas instalada la última versión de Acrobat Reader.{' '}
				<Link href="https://get.adobe.com/reader/?loc=es" target="_blank">
					Descárgalo aquí.
				</Link>
				<br />
				Permitas las ventanas emergentes en tu explorador de internet.{' '}
				<Link
					href="https://support.google.com/chrome/answer/95472?hl=es-419&co=GENIE.Platform%3DDesktop"
					target="_blank"
				>
					Consulta la guía.
				</Link>
			</p>
			<p style={{ textAlign: 'center' }}>
				Conoce las últimas tres mensualidades de “Pago por tu cuenta REA”
				completadas con pagos parciales Considera que, en esta sección, no
				observarás los pagos que hayas realizados en una sola exhibición, ni las
				parcialidades que no completaron los importes de “Pago por tu cuenta REA”.
			</p>
			<p style={{ textAlign: 'center' }}>
				En el estado de cuenta mensual, en el concepto de Pago regular
				identificarás tanto los pagos parciales que no cubrieron una mensualidad,
				como las mensualidades que, si completaste con pagos parciales, la
				diferencia estará en los montos reflejados y en el caso de que se complete
				un pago se observará un asterisco indicando que ese pago se compone de
				parcialidades, mismas que podrás consultar en el módulo de pagos
				parciales.
			</p>
			<Divider style={{ margin: '30px 0px' }} />
		</div>
	);
};

export default ReportMovement;
