import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    textAlign: 'center',
    '& .title': {
      margin: '40px 0',
    }
  },
  creditList: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '28px',
    gap: '35px',
  },
  extraMessage: {
    display: 'flex',
    justifyContent: 'center',
    '&> .asterisk': {
      marginRight: '4px',
      color: theme.palette.primary.main,
    }
  },
}))