// Dependencies
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Material Components
import { Grid, Typography } from '@mui/material';
import theme from '../../config/theme';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

// Components
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import ModalConfirmation from '../../components/ModalConfirmation';
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';
import BaseModal from '../../components/Modal';

// Resources
import { nssValid, isNumber, rfcValid, curpValid } from '../../utils/expressions';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { IAlert } from '../../interfaces/alert';
import { MESSAGES, USERS_BLOCKED } from '../Main/utils/profilesData';

// Assets
import { useStyles } from '../Register/styles';
import { createAccountAPI } from '../../api/modules/createAccount';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import Captcha from '../../components/Captcha';
interface IUserData {
	nombre: string;
	apPaterno: string;
	apMaterno: string;
	curp: string;
	rfc: string;
	fechaNacimiento: string;
	sexo: string;
}
interface IPersonalData {
	onChange: (name: string, value: string | boolean) => void;
	onContinue: () => void;
	onCloseError: () => void;
	onContinueNSS?: (value: number) => void;
	hasError: IAlert;
	setDataUser: (data: IUserData) => void;
}
interface ICredentials {
	nss: string;
	rfc: string;
	curp: string;
}

const PersonalData = (props: IPersonalData) => {
	const navigate = useNavigate();
	const [credentials, setCredentials] = useState<ICredentials>({
		nss: '',
		rfc: '',
		curp: '',
	});
	const [errorNSS, setErrorNSS] = useState({ valid: false, msg: '' });
	const [errorRFC, setErrorRFC] = useState<{
		valid: boolean;
		msg: string;
		code?: string | undefined;
	}>({ valid: false, msg: '', code: '' });
	const [errorCURP, setErrorCURP] = useState({ valid: false, msg: '' });
	const [loading, setLoading] = useState(false);
	const [code, setCode] = useState('');
	const [codeInput, setCodeInput] = useState('');
	const [modalLogged, setModalLogged] = useState(false);
	const [modalLocked, setModalLocked] = useState(false);
	const { onChange, onContinue, onContinueNSS, hasError, onCloseError, setDataUser } = props;
	const [modalPrivacy, setModalPrivacy] = useState(true);
	const [nssValidateFinal, setNssValidateFinal] = useState(false);
	const [curpValidateFinal, setCurpValidateFinal] = useState(false);
	const [rfcValidateFinal, setRfcValidateFinal] = useState(false);
	const [modalCorrectionRfc, setModalCorrectionRfc] = useState(false);
	const classes = useStyles();
	const [name, setName] = useState('')
	const [errorRfcFinal, setErrorFinal] = useState(false)
	const [rfcCrm, setRfcCrm] = useState('')
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const setData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		let validate = null;

		if (name === 'nss') {
			if (!isNumber(value)) return;
			if (value.length > 11) return;
			if (!value) {
				setErrorNSS({ valid: false, msg: '' });
			}
			validate = nssValid(value);
			setErrorNSS(validate);
		}
		if (name === 'rfc') {
			if (value.length > 13) return;
			if (!value) {
				setErrorRFC({ valid: false, msg: '' });
			}
			validate = rfcValid(value);
			setErrorRFC(validate);
		}
		if (name === 'curp') {
			let validar: boolean = true;
			if (value.length > 18) return;

			if (value.length === 0) {
				setErrorCURP({
					valid: false,
					msg: INTERNAL_MESSAGES.PROVIDES_CURP,
				});
				validar = false;
			}

			if (value.length > 0 && value.length < 18) {
				setErrorCURP({
					valid: false,
					msg: INTERNAL_MESSAGES.CURP_MISSING_CHARACTERS,
				});
				validar = false;
			}
			if (validar) {
				validate = curpValid(value);
				setErrorCURP(validate);
			}
		}
		setCredentials({ ...credentials, [name]: value.toUpperCase() });
		onChange(name, value);
	};

	const handleValidateNSS = async () => {
		if (!errorNSS.valid) return;
		if (USERS_BLOCKED.includes(credentials.nss)) {
			setErrorNSS({ msg: MESSAGES.USERS_BLOCKED, valid: false });
		} else {
			setLoading(true);
			try {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const code_base64 = await encrypt(credentials.nss || '', key);
				createAccountAPI
					.validateNSS(code_base64)
					.then((res) => {
						setErrorNSS({ msg: '', valid: true });
						setNssValidateFinal(true)
						setName(`${res.result?.nombre} ${res.result?.apPaterno} ${res.result?.apMaterno}`)
						if (res?.result) {
							onChange(
								'name',
								`${res.result?.nombre} ${res.result?.apPaterno}  ${res.result?.apMaterno} `
							);
						}
						if (res.result?.currentStep && onContinueNSS) {
							setDataUser(res.result)
							onChange(
								'name',
								`${res.result?.nombreDACI} ${res.result?.apPaternoDACI}  ${res.result?.apMaternoDACI} `
							);
							onContinueNSS(res.result?.currentStep);
						}
					})
					.catch((err) => {
						if (err?.description) {
							let errorFlag = err.description;
							if (
								err.secondDescription ===
								INTERNAL_MESSAGES.ACTIVE_ACCOUNT
							) {
								setModalLogged(!modalLogged);
							}
							if (
								errorFlag ===
								INTERNAL_MESSAGES.USER_BLOCKED
							) {
								setModalLocked(!modalLocked);
							}
							if (
								errorFlag === 'Cuenta en proceso de Unificación' ||
								errorFlag === 'Cuenta con marca de fallecido en SACI'
							) {
								errorFlag = err.message;
							}
							setErrorNSS({ msg: errorFlag, valid: false });
						}
					})
					.finally(() => setLoading(false));
			} catch (error: any) {
				setNssValidateFinal(false)
				setErrorNSS({
					msg: error?.description || 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					valid: false
				});
				setLoading(false);
			}
		}
	};

	const handleValidateCURP = async () => {
		if (!errorCURP.valid) return;
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(credentials.nss || '', key);
			const curp_base64 = await encrypt(credentials.curp || '', key);
			createAccountAPI
				.validateCURP(nss_base64, curp_base64)
				.then((res) => {
					if (res?.result) {
						setDataUser(res.result)
						onChange(
							'name',
							`${res.result?.nombreDACI} ${res.result?.apPaternoDACI}  ${res.result?.apMaternoDACI} `
						);
						setCurpValidateFinal(true)
						setRfcCrm(res.result?.rfcCrm);
					}
					if (res?.description) {
						if (res.description === 'Por el momento el servicio no se encuentra disponible, intenta más tarde') {
							setErrorCURP({ msg: res.description, valid: false });
							setCurpValidateFinal(false)
						} else {
							setErrorCURP({ msg: res.description, valid: true });
							setCurpValidateFinal(true)
						}
					} else {
						setErrorCURP({ msg: '', valid: true });
						setCurpValidateFinal(true)
					}
				})
				.catch((err: any) => {
					setCurpValidateFinal(false)
					setErrorCURP({ msg: err.description ? err.description : 'Por el momento el servicio no se encuentra disponible, intenta más tarde', valid: false });
				})
				.finally(() => setLoading(false));
		} catch (err: any) {
			setCurpValidateFinal(false)
			setErrorCURP({ msg: err.description ? err.description : 'Por el momento el servicio no se encuentra disponible, intenta más tarde', valid: false });
			setLoading(false);
		}
	};

	const handleValidateRFC = async () => {
		if (!errorRFC.valid) return;
		setErrorFinal(false)
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(credentials.nss || '', key);
			const rfc_base64 = await encrypt(credentials.rfc || '', key);
			createAccountAPI
				.validateRFC(nss_base64, rfc_base64)
				.then((res) => {
					if (Object.entries(res).length === 0) {
						setErrorRFC({ msg: '', valid: true, code: '' })
						setRfcValidateFinal(true)
					}

					if (res?.description) {
						if (res.description === 'Por el momento el servicio no se encuentra disponible, intenta más tarde') {
							setErrorRFC({ msg: res.description, valid: false, code: '' })
							setRfcValidateFinal(false)
						} else {
							setErrorRFC({ msg: res.description, valid: true, code: '' })
							setRfcValidateFinal(true)
							setErrorFinal(true)
						}
					} else if (res?.result?.description) {
						if (res.result.description === 'Por el momento el servicio no se encuentra disponible, intenta más tarde') {
							setErrorRFC({ msg: res.result.description, valid: false, code: '' })
							setRfcValidateFinal(false)
						} else {
							setErrorRFC({ msg: res.result.description, valid: true, code: '' })
							setRfcValidateFinal(true)
							setErrorFinal(true)
						}
					} else {
						setErrorRFC({ msg: '', valid: true, code: '' })
						setRfcValidateFinal(true)
					}
				})
				.catch((err: any) => {
					if (err?.description) {
						if (err.description === 'Por el momento el servicio no se encuentra disponible, intenta más tarde') {
							setErrorRFC({
								msg: err?.description,
								valid: false,
								code: err?.code,
							});
							setRfcValidateFinal(false)
						} else {
							setErrorRFC({
								msg: err?.description,
								valid: true,
								code: err?.code,
							});
							setRfcValidateFinal(true)
							setErrorFinal(true)
						}

					}
				})
				.finally(() => setLoading(false));
		} catch (err: any) {
			if (err?.description) {
				if (err.description === 'Por el momento el servicio no se encuentra disponible, intenta más tarde') {
					setErrorRFC({
						msg: err?.description,
						valid: false,
						code: err?.code,
					});
					setRfcValidateFinal(false)
				} else {
					setErrorRFC({
						msg: err?.description,
						valid: true,
						code: err?.code,
					});
					setRfcValidateFinal(true)
					setErrorFinal(true)
				}
			}
			setLoading(false)
		}
	};

	const deletePreRegister = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(credentials.nss, key);
			createAccountAPI
				.deletePreRegister(nss_base64)
				.then((res) => {
					setModalCorrectionRfc(true)
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (err: any) {
			setAlert({
				show: true,
				message: err.description
					? err.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false)
		}
	}

	return (
		<div style={{ width: '100%' }}>
			<Grid container className={classes.container}>
				<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<TextInput
						id="nss"
						name="nss"
						label="Número de Seguridad Social (NSS)"
						value={credentials.nss}
						error={!errorNSS.valid}
						helperText={!errorNSS.valid ? errorNSS.msg : ''}
						placeholder="11 Dígitos"
						size="small"
						isRequired
						onChange={setData}
						onBlur={handleValidateNSS}
						onFocus={setData}
					/>
					<Typography variant="subtitle1" className={classes.p} gutterBottom>
						<a
							className={classes.a}
							target="_blank"
							href="https://serviciosdigitales.imss.gob.mx/gestionAsegurados-web-externo/asignacionNSS"
							rel="noreferrer"
						>
							Consulta tu NSS
						</a>
					</Typography>
					<TextInput
						id="curp"
						name="curp"
						label="Clave Única de Registro de Población (CURP)"
						value={credentials.curp}
						error={!errorCURP.valid}
						helperText={errorCURP.msg !== 'CURP Correcta' ? errorCURP.msg : ''}
						placeholder="18 Caracteres"
						size="small"
						isRequired
						onChange={setData}
						onBlur={handleValidateCURP}
						onFocus={setData}
						disabled={!nssValidateFinal}
					/>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography variant="subtitle1" className={classes.p} gutterBottom>
							<a
								className={classes.a}
								target="_blank"
								rel="noreferrer"
								href="https://www.gob.mx/curp"
							>
								Consulta tu CURP
							</a>
						</Typography>
						{ /*<Typography variant="subtitle1" className={classes.p} gutterBottom>
							<Link className={classes.a} to="/correccion-curp">Corregir CURP</Link>
						</Typography>*/}
					</div>

					<TextInput
						id="rfc"
						name="rfc"
						label="Registro Federal de Contribuyentes (RFC)"
						value={credentials.rfc}
						error={!errorRFC.valid}
						helperText={errorRFC.msg !== 'RFC Correcto' ? errorRFC.msg : ''}
						placeholder="13 Caracteres"
						size="small"
						isRequired
						onChange={setData}
						onFocus={setData}
						onBlur={handleValidateRFC}
						disabled={!curpValidateFinal}
					/>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography variant="subtitle1" className={classes.p} gutterBottom>
							<a
								className={classes.a}
								target="_blank"
								rel="noreferrer"
								href="https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp"
							>
								Consulta tu RFC
							</a>
						</Typography>
						{errorRfcFinal && errorRFC.valid && (
							<Typography variant="subtitle1" className={classes.p} onClick={() => deletePreRegister()} gutterBottom>
								<p className={classes.a} style={{ margin: 0, cursor: 'pointer', display: 'flex', alignItems: 'center', paddingTop: '2px' }}>Corregir RFC
									<span style={{ marginLeft: 10, fontSize: 0 }}>
										<ArrowForwardIosOutlinedIcon fontSize={'small'} />
									</span>
								</p>
							</Typography>
						)}
					</div>
					<div className={classes.containerCaptcha}>
						<Captcha
							value={codeInput}
							onChange={(newValue: string, codeCaptcha: string) => {
								setCodeInput(newValue);
								setCode(codeCaptcha);
							}}
						/>
					</div>
					<div style={{ width: '100%' }}>
						<CustomAlert
							show={hasError.show}
							severity={hasError.severity}
							message={hasError.message}
							onClose={onCloseError}
						/>
						<CustomAlert
							show={alert.show}
							severity={alert.severity}
							message={alert.message}
						/>
					</div>
					<CustomButton
						label="Continuar"
						onClick={onContinue}
						variant="solid"
						disabled={
							!Boolean(
								nssValidateFinal &&
								curpValidateFinal &&
								rfcValidateFinal &&
								codeInput &&
								code === codeInput
							)
						}
					/>
				</Grid>
				<Grid item xs={0} sm={0} md={3} lg={3}></Grid>
			</Grid>
			<ModalConfirmation
				open={modalLogged}
				confirmLabel="Cerrar"
				onConfirm={() => setModalLogged(!modalLogged)}
				width="sm"
			>
				<p className={classes.titleModalAlert}>
					Ya tienes una cuenta activa, si no recuerdas tu usuario o contraseña,
					cámbiala en la sección de{' '}
					<Link to="/cambio-contrasenia">Olvidé mi contraseña.</Link>
					<br />
				</p>
			</ModalConfirmation>
			<ModalConfirmation
				open={modalLocked}
				confirmLabel="Cerrar"
				onConfirm={() => setModalLocked(!modalLocked)}
				width="sm"
			>
				<p className={classes.titleModalAlert}>
					El usuario se encuentra bloqueado, para poder desbloquearlo favor de
					visitar el siguiente link:{' '}
					<Link to="/cambio-contrasenia">Restaurar usuario</Link>
					<br />
					<br />
					Si no has solicitado una cuenta comunícate a Infonatel para
					reportarlo.
					<br />
				</p>
			</ModalConfirmation>
			<BaseModal
				open={modalPrivacy}
				confirmLabel="Continuar"
				onClose={() => {
					localStorage.removeItem('dataUserRegister');
					setModalPrivacy && setModalPrivacy(false);
				}}
				onConfirm={() => {
					localStorage.removeItem('dataUserRegister');
					setModalPrivacy(!modalPrivacy)
				}}
				width="sm"
				title={
					<span style={{ color: theme.palette.info.main, margin: 0, width: '100%', display: 'block', textAlign: 'center' }}>
						¡Importante!
					</span>
				}
			>
				<p className={classes.titleModalAlert}>
					Antes de iniciar tu registro a Mi Cuenta Infonavit, es necesario que <br />consultes el{' '}
					<Link to={require('../../assets/files/aviso-de-privacidad.pdf')}
						target={'_blank'}
						download="aviso-de-privacidad.pdf">Aviso de Privacidad</Link>
					<br />
				</p>
			</BaseModal>
			<BaseModal
				open={modalCorrectionRfc}
				cancelButton={true}
				cancelLabel={'No'}
				confirmLabel="Sí"
				hideCloseButton={true}
				onClose={() => {
					setModalCorrectionRfc && setModalCorrectionRfc(false);
				}}
				onConfirm={() => {
					let values = {
						nss: credentials.nss,
						curp: credentials.curp,
						rfc: credentials.rfc,
						name: name,
						rfcCrm: rfcCrm
					};
					localStorage.setItem('dataUserRegister', JSON.stringify(values));
					setModalCorrectionRfc(!modalCorrectionRfc)
					navigate('/correccion-rfc');
				}}
				width="sm"
				title={
					<span style={{ color: theme.palette.info.main, margin: '20px 0px -20px', width: '100%', display: 'block', textAlign: 'center' }}>
						¿Quieres continuar con el proceso para corregir tu RFC?
					</span>
				}
				stylesActions={{ borderTop: '2px solid #eeeeee' }}
			>
				<p className={classes.titleModalAlert}></p>
			</BaseModal>
			<ModalLoading loading={loading} />
		</div>
	);
};

export default PersonalData;
