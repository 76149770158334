import { Grid, Link, Badge } from '@mui/material';
import { useStyles } from './styles';
import TooltipCustom from '../../../components/TooltipCustom';
import HelpPanel from '../../HelpPanel';
import { Info } from '@mui/icons-material';
import seleccion from '../../../assets/svg/seleccion.svg';
import expediente from '../../../assets/svg/expediente.svg';
import { IObtenerListaElementosRes } from '../../../../../interfaces/creditRequest';
import { ConstantSteps } from '../constantSteps/constantSteps';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import verificar from '../../../assets/svg/verificar.svg';
import curso from '../../../assets/svg/curso.svg';
import { useNavigate } from 'react-router-dom';

export const CICBSteps = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	return [
		{
			paperType: 'viewMore',
			paperIcon: verificar,
			title: 'Verifica tus datos',
			description:
				'Es muy importante que tu NSS, CURP y RFC estén correctos.',
			viewMoreButton: 'Ver más',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<p>
							1. Para corregir tu CURP, debes acudir a tu AFORE e
							indicar que requieres la corrección de tus datos.
							<br />
							2. Para corregir el RFC, puedes hacer la corrección
							a través de Mi Cuenta Infonavit, siempre y cuando
							cuentes con firma electrónica avanzada (e.firma) o
							recibo de nómina digital.
							<br />
							<br />
							Si no cuentas con ellos, al ingresar a Mi Cuenta
							Infonavit, en el menú del lado superior, puedes
							hacer tu cita para acudir al centro de servicio más
							cercano.
						</p>
					</div>
				</>
			),
		},
		{
			paperType: 'simple',
			paperIcon: seleccion,
			title: 'Obtén tu certificado',
			description:
				'Ingresa a Mi Cuenta Infonavit, dando clic en Quiero un crédito y genera tu certificado Cuenta Infonavit + Crédito Bancario para que la lleves a la entidad financiera con la que solicitarás el crédito.',
		},
		{
			paperType: 'simple',
			paperIcon: curso,
			title: 'Asegúrate de concluir todos los módulos del Curso Saber más para decidir mejor y obtén tu constancia.',
			link: (
				<a
					onClick={() =>
						navigate('/mi-tramite-credito/taller-saber-mas')
					}
					className={classes.optionEnlace}
				>
					Ir al curso <ChevronRightIcon />{' '}
				</a>
			),
		},
		{
			paperType: 'viewMore',
			paperIcon: expediente,
			title: 'Requisitos y pasos a seguir',
			viewMoreButton: 'Ver más',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<ol className={classes.viewMoreList}>
							<li>
								Solicita tu crédito con las{' '}
								<HelpPanel type="ENTIDADES" customText='entidades financieras participantes'/>
									
								
								, te evaluarán de acuerdo con sus políticas de
								crédito.
							</li>
							<li>
								El avalúo lo gestionará la entidad financiera
								que selecciones. Deberás preparar la siguiente
								documentación:
							</li>
							<ul className={classes.viewMoreList}>
								<li>
									Escritura antecedente de propiedad o régimen
									de propiedad en condominio
								</li>
								<li>Boleta predial (última)</li>
								<li>Boleta de agua (última)</li>
								<li>
									Plano o croquis del inmueble (si no cuentas
									con él, pueden hacértelo con un costo
									adicional)
								</li>
								<li>
									Recibos de servicios para comprobar que es
									vivienda usada o terminación de obra (en
									caso de que la escritura no lo indique)
								</li>
								<li>
									Copia de la Identificación oficial del
									vendedor y del comprador
								</li>
							</ul>
							<li>
								Integra tu expediente con la documentación que
								determine la entidad financiera.
							</li>
							<li>
								Para Infonavit deberás reunir los siguientes
								documentos:
							</li>
							<ul className={classes.viewMoreList}>
								<li>
									Solicitud de inscripción de crédito.{' '}
									<Link
										href={require('../../../../../assets/files/guia-pasos-a-seguir/Solicitud_Inscripcion_Credito_CICB.pdf')}
										target="_blank"
									>
										Descárgala aquí
									</Link>
								</li>
								<li>Acta de nacimiento en original y copia.</li>
								<li>
									Identificación oficial (INE/IFE) o pasaporte
									vigente, en original y copia.
								</li>
								<li>
									Comprobante de domicilio con una{' '}
									<label style={{ fontWeight: 'bold' }}>
										antigüedad máxima de 3 meses
									</label>{' '}
									y estar pagado.
								</li>
								<li>
									Estado de cuenta bancario del vendedor con
									Clave Interbancaria Estandarizada (CLABE).
								</li>
								<li>
									Estado de cuenta bancario del
									derechohabiente con Clave Interbancaria
									Estandarizada (CLABE), de la que se cobrará
									mensualmente de manera domiciliada, el pago
									del crédito.
								</li>
								<li>
									Acta de nacimiento en original y copia del
									cónyuge (en su caso).
								</li>
								<li>
									Identificación oficial (INE/IFE) o pasaporte
									vigente, en original y copia del cónyuge (en
									su caso).
								</li>
								<li>Acta de matrimonio (en su caso).</li>
								<li>
									Carta de autorización de crédito emitida por
									la entidad financiera.{' '}
								</li>
								<li>
									Carta de instrucción irrevocable.{' '}
									<Link
										href={require('../../../../../assets/files/guia-pasos-a-seguir/Carta_instruccion_irrevocable_CICB.pdf')}
										target="_blank"
									>
										Descárgala aquí
									</Link>
								</li>
							</ul>
							<li>
								La entidad financiera te asignará un notario
								para que realice el trámite de escrituración.
							</li>
						</ol>
					</div>
				</>
			),
		},
	];
};
