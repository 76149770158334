import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { creditRequestAPI } from '../../../../../api/modules/CreditRequest';
import { INTERNAL_MESSAGES } from '../../../../../config/messageCatalog';
import { IUrlEncripted } from '../../../../../interfaces/creditRequest';
import { useController } from '../../../hooks/useController';
import { DESTINOS } from '../../../../../config/miucConstants';
import { formatDate } from '../../../../../utils/dates';
import CustomAlert from '../../../../../components/CustomAlert';
import BaseModal from '../../../../../components/Modal';
import ModalLoading from '../../../../../components/ModalLoading';
import { encodeAllJSONData } from '../../../../../utils/encrypt';
import theme from '../../../../../config/theme';
import { apiMIUC } from '../../../../../api/modules/MIUC';
import { MIUC_PRODUCTS } from '../../../constants/products';

const OnlineRequestModal = ({
	open,
	setOpen,
	selectedProductTable,
	creditType,
}: any) => {
	const [msgError, setMsgError] = useState('');
	const [loading, setLoading] = useState(false);
	const { user } = useSelector((state: RootState) => state.session);
  const { dataProcessController } = useController()

  const { credit } = dataProcessController.processInfo;
  const destino = credit === MIUC_PRODUCTS.MEJORAVIT
  ? MIUC_PRODUCTS.REMODELACION
  : credit;

	const confirmResponse = async () => {
		try {
			setLoading(true);
			const request = await validacionPayload();
			const result = await apiMIUC.getUrlEncripted(request);
			const urlRedirect = result.data.path;
      // console.log(urlRedirect)
			if (urlRedirect) {
				window.open(urlRedirect, '_blank');
				setOpen(false);
			} else {
				setMsgError(INTERNAL_MESSAGES.ERROR_MESSAGE);
			}
		} catch (error) {
			setMsgError(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			setLoading(false);
		}
	};

	const validacionPayload = async () => {
		let data: IUrlEncripted = {
			aplicacion: 'AutoServicio',
			origen: 'MIUC',
			nss: user?.nss?.toString() || '',
			nssConyuge: dataProcessController?.conyuge?.nss?.toString() || '',
			tipoCredito: '',
			moneda: 'P',
			producto: '1',
			programaEspecial: '',
			tipoOperacion: creditType || '',
			destino: destino.substring(1, 2),
			tiempo: formatDate('yyyy/MM/dd HH:mm:ss'),
			miuc: 'L2',
      tipo: 'AutoServicio',
      correo: user?.email || '',
		};
    // console.log(data)
		if (destino === DESTINOS.REMODELACION.codigo) {
			data.miuc = 'L4';
			data.origen = 'MCI';
      data.tipo = 'Aterrizaje';
		}
		if (destino=== DESTINOS.COMPRA.codigo) {
			data.tipoCredito = dataProcessController.titular.esSegundoCredito ? '4' : '8';
		} else {
			data.tipoCredito = dataProcessController.titular.esSegundoCredito ? '4' : '7';
		}
		if (data.tipoOperacion === 'I') {
			data.nssConyuge = '';
		}
		return await encodeAllJSONData(
			{ data: JSON.stringify(data) },
			user?.k || ''
		);
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<BaseModal
				width="sm"
				open={open}
				cancelLabel="No"
				confirmLabel="Si"
				onClose={() => setOpen(!open)}
				onConfirm={() => confirmResponse()}
				cancelButton
        hideCloseButton
			>
				<div
					style={{
						textAlign: 'center',
						color: theme.palette.info.main,
						fontWeight: 600,
						fontSize: 20,
					}}
				>
					<p>
						Vas a iniciar el trámite de solicitud de crédito;
						asegúrate de que cumples con todos los requisitos.
					</p>
					{destino ===
					DESTINOS.REMODELACION.codigo ? (
						<p>¿Cuentas con tu expediente de crédito completo?</p>
					) : (
						<p>¿Ya tienes el avalúo y está vigente?</p>
					)}

					<CustomAlert
						message={msgError}
						show={msgError ? true : false}
						severity="warning"
					/>
				</div>
			</BaseModal>
		</>
	);
};

export default OnlineRequestModal;
