import { Link } from '@mui/material';
import { useStyles } from './styles';
import seleccion from '../../../assets/svg/seleccion.svg';
import expediente from '../../../assets/svg/expediente.svg';
import { ConstantSteps } from '../constantSteps/constantSteps';

export const ReparacionesMenoresSteps = (data: {
	avanceTaller: number;
	alert: String;
}) => {
	const classes = useStyles();
	const {
		tallerSaberMas,
		verificaTusDatos,
		hazTusCalculos,
		solicitaTuCredito,
	} = ConstantSteps(data);

	return [
		tallerSaberMas,
		verificaTusDatos,
		hazTusCalculos,
		{
			paperType: 'viewMore',
			paperIcon: expediente,
			title: 'Integra tu expediente',
			description: 'Reúne tus documentos para tramitar tu crédito',
			viewMoreButton: 'Consulta la documentación',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<b>Reúne estos documentos.</b>
						<ul className={classes.viewMoreList}>
							<li>
								Solicitud de inscripción de crédito.{' '}
								<Link
									underline="hover"
									href={require('../../../../../assets/files/guia-pasos-a-seguir/Solicitud_inscripcion_credito_L6.pdf')}
									target="_blank"
								>
									Descárgala aquí
								</Link>
							</li>
							<li>
								Identificación oficial INE/IFE o pasaporte
								vigente, en original y copia.
							</li>
							<li>
								Acta de nacimiento en original y copia. Ten en
								cuenta que, si tu acta de nacimiento es digital,
								debes traerla impresa.
							</li>
							<li>
								Impresión de tu estado de cuenta bancario con
								cuenta CLABE Interbancaria Estandarizada a 18
								dígitos, con una antigüedad máxima de 3 meses.
							</li>
							<li>Impresión de la CURP.</li>
							<li>
								Comprobante de domicilio con antigüedad no menor
								a tres meses.
							</li>
						</ul>
						<div className={classes.listColumn}>
							<b>
								1. Para vivienda urbana, será suficiente que
								presente uno de los siguientes:
							</b>
							<ul className={classes.viewMoreList}>
								<li>Recibo de Luz,</li>
								<li>Recibo de Agua,</li>
								<li>Recibo de Gas,</li>
								<li>
									Recibo Predial (en caso de que el pago se
									realizó de forma anual y la fecha de emisión
									sea mayor a dos meses, deberá presentar
									algún otro del listado),
								</li>
								<li>Recibo de Teléfono Fijo,</li>
								<li>Recibo de Internet,</li>
								<li>
									Recibo de Telefonía Móvil /Celular
									(domiciliado),
								</li>
								<li>Recibo de TV por Cable,</li>
								<li>
									Estado de Movimientos, Estado de Cuenta
									Bancario o Contrato de Apertura de Cuenta
									Bancaria,
								</li>
								<li>
									Constancia de Domicilio expedida por el
									Instituto Nacional Electoral (INE).
								</li>
							</ul>
							<b>
								2. Para la vivienda rural, será suficiente que
								presente cualquiera de los siguientes
								documentos:
							</b>
							<ul className={classes.viewMoreList}>
								<li>
									Constancia Vecinal expedida por la autoridad
									Municipal, o
								</li>
								<li>Cartilla de Identidad Postal.</li>
							</ul>
						</div>
						<div className={classes.viewMoreList}>
							<strong>
								Toma en cuenta que:
								<br />
								<br />
							</strong>
							El comprobante de domicilio de la vivienda a la que
							se le harán las mejoras debe pertenecer a la misma
							entidad donde se tramite el crédito.
						</div>
					</div>
				</>
			),
		},
		solicitaTuCredito,
		{
			paperType: 'viewMore',
			paperIcon: seleccion,
			title: 'Responsabilidad del verificador',
			description: 'Conoce la información sobre el verificador',
			viewMoreButton: 'Consulta la documentación',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<ul className={classes.viewMoreList}>
							<li>
								Una vez formalizado tu crédito se te asignará un
								Verificador.
							</li>
							<li>
								El Verificador será responsable de evaluar que
								la vivienda no se encuentre en zona de riesgo y
								si cuenta con servicios (agua, luz y drenaje) o
								si se pueden resolver con el crédito como por
								ejemplo falta de drenaje con un sistema de
								disposición de desechos sólidos.
							</li>
							<li>
								Si tienes algún problema que no se pueda
								resolver con reparaciones menores, el
								Verificador te recomendará cambiarte de
								modalidad a ConstruYo con Asistencia Técnica o
								ConstruYo con Constructora.
							</li>
							<li>
								Que la vivienda cuenta con seguridad
								estructural.
							</li>
							<li>
								Deberá visitar tu vivienda y conocer cuál será
								la mejora que deseas hacer y su presupuesto.
							</li>
						</ul>
						<strong>Toma en cuenta que:</strong>
						<ul className={classes.viewMoreList}>
							<li>
								Tu principal responsabilidad es aplicar el
								crédito que se te otorgue a mejorar tu vivienda.
								Para ello es importante que te precalifiques y
								te apoyes en las herramientas que el Infonavit
								te ofrece para calcular para qué te alcanza.{' '}
								<Link
									href={
										process.env
											.REACT_APP_URL_MIUC_SIMULADOR_PRESUPUESTO
									}
									target="_blank"
								>
									Simulador de presupuesto
								</Link>
								.
							</li>
							<li>
								Debes recibir al Verificador en tu vivienda y
								cumplir con la fecha y hora acordada para la
								cita.
							</li>
							<li>
								Deberás cumplir con los tiempos pactados en el
								proyecto y presupuesto para terminar la obra en
								tiempo y forma.
							</li>
							<li>
								Debes informar al Verificador sobre los avances
								para que reporte el avance de obra y se autorice
								la ministración correspondiente.
							</li>
						</ul>
						<strong>Importante:</strong>
						<ul className={classes.viewMoreList}>
							<li>
								Deberás contar con un teléfono celular
								inteligente para que puedas compartir los
								avances de obra con tu Verificador y así se
								puedan autorizar las ministraciones.
							</li>
							<li>
								Ninguna persona o empresa puede ofrecerte dinero
								en efectivo a cambio de tu crédito o el saldo de
								la Subcuenta de Vivienda, esta actividad está
								prohibida e infringe la normatividad del
								Infonavit. Reporta de manera anónima a:{' '}
								<Link
									href="mailto:prevencionConstruYo@infonavit.org.mx"
									target="_blank"
								>
									prevencionConstruYo@infonavit.org.mx
								</Link>
							</li>
						</ul>
					</div>
				</>
			),
		},
	];
};
