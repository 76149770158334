/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import WaitingDocument from './views/WaitingDocument';
import CreditReqCan from './views/CreditReqCan';
import { isEmpty } from '../../../../utils/validators';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	DESTINO_PERFILAMIENTO_ENUM,
	IProfileResponse,
	apiMIUC,
} from '../../../../api/modules/MIUC';
import RequestProCan from './views/RequestProCan';
import ValidateIdentity from './views/ValidateIdentity';
import WaitingValProcess from './views/WaitingValProcess';
import DocumentComplete from './views/DocumentComplete';
import HowCreditGoing from './views/HowCreditGoing';
import HaveCreditApp from './views/HaveCreditApp';
import { RETURN_CODE } from '../../constants/returnCode';
import ErrnoPage from '../../../CreditRequest/ErrnoPage';
import { INTERNAL_MESSAGES } from '../../../../config/messageCatalog';

interface ParamsState {
	params: IProfileResponse;
}
const CreditStatus = () => {
	const [data, setData] = useState<IProfileResponse>({} as IProfileResponse);
	// const [errorMessage, setErrorMessage] = useState('');
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (isEmpty((location?.state as unknown as ParamsState)?.params)) {
			navigate('/404');
			// getInitialProfile();
		} else {
			setData({ ...(location.state as ParamsState).params });
		}
	}, []);

	// const getInitialProfile = async() => {
	// 	try {
	// 		const response = await apiMIUC.getProfile();
	// 		const { data } = response;

	// 		if(response.code !== RETURN_CODE.CORRECT) return setErrorMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);

	// 		if(!data.destino) return setErrorMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);

	// 		if(data.destino === DESTINO_PERFILAMIENTO_ENUM.MIUC) return setErrorMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);

	// 		if(!(data.destino in DESTINO_PERFILAMIENTO_ENUM)) return setErrorMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);

	// 		setData({ ...response.data });
	// 	} catch (error: any) {
	// 		setErrorMessage(error.description || INTERNAL_MESSAGES.ERROR_MESSAGE);
	// 	}
	// }

	const getActiveView = (): JSX.Element => {
		let component: JSX.Element = <></>;
		switch (data?.destino) {
			// TODO: Posiblemente se quite CreditReqCan
			case DESTINO_PERFILAMIENTO_ENUM.SOLICITUD_CANCELADA:
				component = <CreditReqCan data={data} />;
				break;
			case DESTINO_PERFILAMIENTO_ENUM.EN_ESPERA_DE_DOCUMENTOS:
				component = <WaitingDocument data={data} />;
				break;
			case DESTINO_PERFILAMIENTO_ENUM.EN_PROCESO_CANCELACION:
				component = <RequestProCan />;
				break;
			case DESTINO_PERFILAMIENTO_ENUM.VALIDAR_IDENTIDAD:
				component = <ValidateIdentity data={data} />;
				break;
			case DESTINO_PERFILAMIENTO_ENUM.VALIDACION_DOCUMENTOS_EN_PROCESO:
				component = <WaitingValProcess data={data} />;
				break;
			case DESTINO_PERFILAMIENTO_ENUM.DOCUMENTACION_COMPLETA:
				component = <DocumentComplete data={data} />;
				break;
			case DESTINO_PERFILAMIENTO_ENUM.COMO_VA_MI_TRAMITE:
				component = <HowCreditGoing data={data} />;
				break;
			case DESTINO_PERFILAMIENTO_ENUM.TIENE_CREDITO:
				component = <HaveCreditApp data={data} />;
				break;
		}
		return component;
	};

	return <>
		{/* {errorMessage && <ErrnoPage msgString={errorMessage} fullPage />} */}
		{getActiveView()}
	</>;
};

export default CreditStatus;
