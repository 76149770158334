export interface SaldoState {
	saldo: string;
}

export const SET_SALDO = 'saldos/setSaldo';
export const CLEAN_SALDO = 'loading/limpiarSaldo';

export const INITIAL_STATE: SaldoState = {
	saldo: '',
};
