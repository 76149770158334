import { LinearProgress, Link } from '@mui/material';
import curso from '../../../assets/svg/curso.svg';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../productSteps/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import verificar from '../../../assets/svg/verificar.svg';
import seleccion from '../../../assets/svg/seleccion.svg';
import tramiteLinea from '../../../assets/svg/tramiteLinea.svg';
import formalizaCreditoIcon from '../../../assets/svg/formalizaCredito.svg';

export const ConstantSteps = (data?: {
	avanceTaller: number;
	alert: String;
}) => {
	const navigate = useNavigate();
	const classes = useStyles();

	const tallerSaberMas = {
		paperType: 'simple',
		paperIcon: curso,
		title: 'Termina tu curso Saber Más para decidir mejor',
		description:
			'Asegúrate de concluir todos los módulos del Curso Saber Más para decidir mejor, y cuando lo hagas, aquí mismo puedes obtener tu constancia.',
		link: (
			<>
				<Link
					underline="none"
					onClick={() =>
						navigate('/mi-tramite-credito/taller-saber-mas')
					}
					className={classes.optionEnlace}
				>
					Ir al curso <ChevronRightIcon />{' '}
				</Link>
				{!data?.alert ? (
					<div className={classes.progressBar}>
						<LinearProgress
							variant="determinate"
							value={data?.avanceTaller || 0}
							color="inherit"
							className={classes.porcentajeCurso}
						/>
						<b>{data?.avanceTaller}%</b>
					</div>
				) : (
					<span className={classes.alert}>
						No se encuentra disponible el avance del curso, intenta
						más tarde
					</span>
				)}
			</>
		),
	};
	const verificaTusDatos = {
		paperType: 'simple',
		paperIcon: verificar,
		title: 'Verifica tus datos',
		description:
			'Es muy importante que tu NSS, CURP y RFC estén correctos en el Infonavit y éstos coincidan con lo que tienes registrado en tu Afore y en el Servicio de Administración Tributaria "SAT", en caso de que alguno esté incorrecto o no coincidan, corrígelos en Mi Cuenta Infonavit o en la institución correspondiente.',
		link: (
			<>
				<Link
					underline="none"
					onClick={() =>
						navigate('/mi-perfil/correccion-curp-nombre')
					}
					className={classes.optionEnlace}
				>
					Verificar mi CURP <ChevronRightIcon />
				</Link>
				<Link
					underline="none"
					onClick={() => navigate('/mi-perfil/correccion-rfc')}
					className={classes.optionEnlace}
				>
					Verificar mi RFC <ChevronRightIcon />
				</Link>
			</>
		),
	};
	const hazTusCalculos = {
		paperType: 'simple',
		paperIcon: verificar,
		title: 'Revisa y haz tus cálculos antes de decidir',
		description: (
			<>
				<ul className={classes.viewMoreList}>
					<li>
						Usa el simulador de presupuesto con el que puedes
						calcular y determinar el costo aproximado de la obra que
						quieres hacer (lista de materiales y de mano de obra).
						Esto te ayudará a decidir si haces todo en una sola vez
						o si lo harás por etapas.
					</li>
					<li>
						Es importante que revises los videos y tutoriales de
						ConstruYo Infonavit, en ellos está toda la información
						que debes conocer antes de decidir.
					</li>
				</ul>
			</>
		),
		link: (
			<Link
				underline="none"
				href={process.env.REACT_APP_URL_MIUC_SIMULADOR_PRESUPUESTO}
				className={classes.optionEnlace}
				target="_blank"
			>
				Simulador de presupuesto <ChevronRightIcon />{' '}
			</Link>
		),
	};
	const notarioTitulacion = {
		paperType: 'simple',
		paperIcon: seleccion,
		title: 'Selecciona un notario',
		description:
			'Consulta el directorio de notarios que trabajan con el Infonavit y elige uno para que realice el proceso de titulación.',
		link: (
			<Link
				underline="none"
				target="_blank"
				href="https://portal.infonavit.org.mx/wps/portal/Infonavit/Servicios/infonavit%20Ampliado/ServicioInex/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziTZyMTJycDB0NDAIsXQw8vUICTEItLAwMjAz0w9EUmLkAFTiFOgYGG3gaW5gb60eRph9VgYEpcfoNcABHA8rsN4Lox2N8uH4UmhWYIYDXDcEmBBSAwoCQKwpyQ0NDIwwyPdMVFQFttxce/dz/d5/L2dBISEvZ0FBIS9nQSEh/#1102000090"
				className={classes.optionEnlace}
			>
				Consulta el directorio <ChevronRightIcon />{' '}
			</Link>
		),
	};
	const solicitaTuCredito = {
		paperType: 'blue',
		paperIcon: tramiteLinea,
		title: 'Solicita tu crédito en las oficinas de Infonavit',
		description:
			'Registra tu solicitud de crédito en una de las oficinas del Infonavit. Regresa a la página principal de Mi Cuenta Infonavit, en el menú del lado superior, puedes hacer tu cita para acudir al centro de servicio Infonavit más cercano.',
		link: (
			<Link
				underline="none"
				onClick={() => navigate('/contactanos/haz-una-cita')}
				className={classes.optionEnlace}
			>
				Hacer una cita{' '}
				<ChevronRightIcon className={classes.greyArrow} />{' '}
			</Link>
		),
	};
	const solicitaTuCreditoMejoraSi = (openModalExit: any) => {
		return {
			paperType: 'blue',
			paperIcon: tramiteLinea,
			title: 'Solicita tu crédito mediante alguna de las siguientes opciones',
			description: (
				<>
					<p>
						Registra tu solicitud de crédito en una de las oficinas
						del Infonavit. Regresa a la página principal de Mi
						Cuenta Infonavit, en el menú del lado superior, puedes
						hacer tu cita para acudir al centro de servicio
						Infonavit más cercano.
					</p>
					<p>
						<b>En línea.</b> Ingresa al siguiente{' '}
						<Link onClick={openModalExit}>enlace</Link> para
						realizar tu registro y la carga digital del expediente
						documental. Una vez enviada tu documentación será
						revisada por personal del Infonavit y recibirás
						notificaciones a tu correo electrónico registrado para
						que continues con tu trámite de formalización en
						cualquiera de las oficinas del Infonavit.
					</p>
				</>
			),
			link: (
				<Link
					underline="none"
					onClick={() => navigate('/contactanos/haz-una-cita')}
					className={classes.optionEnlace}
				>
					Hacer una cita{' '}
					<ChevronRightIcon className={classes.greyArrow} />{' '}
				</Link>
			),
		};
	};
	const formalizaCredito = {
		paperType: 'simple',
		paperIcon: formalizaCreditoIcon,
		title: 'Formaliza tu crédito',
		description:
			'Una vez inscrito tu trámite te indicaremos el momento a realizar tu cita para acudir a firmar tu contrato y recibir tu tarjeta con la que quedará formalizado tu crédito.',
	};

	return {
		tallerSaberMas,
		verificaTusDatos,
		hazTusCalculos,
		notarioTitulacion,
		solicitaTuCredito,
		solicitaTuCreditoMejoraSi,
		formalizaCredito,
	};
};
