import React from 'react';
import TwoColumnTable from '../../../../components/TwoColumnTable';
import { defaultResponse } from '../../utils';
import { columns } from './utils';

const Summary = ({ response = defaultResponse }: any) => {
	return (
		<>
			<h3 style={{ textAlign: 'center', color: '#283990' }}>
				Gracias por utilizar los servicios digitales del infonavit
			</h3>
			<TwoColumnTable principal={columns} data={response} />
		</>
	);
};

export default Summary;
