export const MIUC_PRODUCTS = {
	COMPRA: 'L2',
	CREDITERRENO: 'L8',
	CONSTRUCCION: 'L3',
	REMODELACION: 'L4',
	HIPOTECA: 'L5',
	AUTOPRODUCCION: 'L6',
	MEJORAVIT: 'MERMREN',
	CREDITO_SEGURO: 'CS',
	MEJORA_DIRECTA: 'MD',
	CICB: 'L7',
	APOYO_INFONAVIT: 'AP',
	SUELO_MAS_CONSTRUCCION: 'L9',
};

export const CREDIT_TYPES = {
	CREDITO_INFONAVIT: 'CI',
	INFONAVIT_TOTAL: 'IT',
	COFINAVIT: 'COF',
	COFINAVIT_INGRESOS_ADICIONALES: 'COFIA',
	MEJORAVIT_REPARA: 'MREP',
	MEJORAVIT_RENUEVA: 'MREN',
	SEGUNDO_CREDITO: 'CII',
	APOYO_INFONAVIT: 'AI',
	CREDITO_SEGURO: 'CS',
	MEJORASI: 'MD',
};

export const CREDIT_OPTIONS = {
	INDIVIDUAL: 'I',
	CONYUGAL: 'C',
	FAMILIAR: 'F',
	CORRESIDENCIAL: 'R',
	REPARACIONES_MENORES: 'RM',
	ASISTENCIA_TECNICA: 'AT',
	CONSTRUCTORA: 'CN',
};

export const PRODUCT_LINES = {
	COMPRAR_VIVIENDA: 'COMPRAR_VIVIENDA',
	COMPRAR_TERRENO: 'COMPRAR_TERRENO',
	CONTRUIR_VIVIENDA: 'CONTRUIR_VIVIENDA',
	MEJORA_HOGAR: 'MEJORA_HOGAR',
	PAGAR_HIPOTECA: 'PAGAR_HIPOTECA',
	SIN_RELACION_LABORAL: 'SIN_RELACION_LABORAL',
};
