import { Info } from '@mui/icons-material';
import { ObtenerElementosMCIResponseModel } from '../../../../../interfaces/tallerSaberMas';
import TooltipCustom from '../../../components/TooltipCustom';
import { ResponsiveType } from 'react-multi-carousel';
import { PROFILES } from '../../../../Main/utils/profilesData';

export const COLORS = {
	PINK: '#E51D76',
	GREEN: '#0dd920',
	ORANGE: '#FF8C04',
	BLUE: '#1390C8',
};

export interface IWorkshopItem {
	key: string;
	destino: string;
}

export interface IWorkshop {
	[key: string]: IWorkshopItem;
}

export const WORKSHOP_VIEWS: IWorkshop = {
	FINANTIAL_EDUCATION: {
		destino: '9',
		key: 'FINANTIAL_EDUCATION',
	},
	I_WANT_A_CREDIT: {
		destino: '10',
		key: 'I_WANT_A_CREDIT',
	},
	I_HAVE_A_CREDIT: {
		destino: '11',
		key: 'I_HAVE_A_CREDIT',
	},
	MY_SUB_ACCOUNT: {
		destino: '12',
		key: 'MY_SUB_ACCOUNT',
	},
	NOTHING: {
		destino: '-1',
		key: 'NOTHING',
	},
};

export interface IRequestTaller {
	[key: string | number]: ObtenerElementosMCIResponseModel;
}

export const getToolTip = (
	matches?: boolean
): { [key: string]: JSX.Element } => {
	return {
		33: (
			<TooltipCustom
				title={
					'Este video solo aplica para Derechohabientes con Saldo de Subcuenta de vivienda y sin relación laboral.'
				}
				placement={matches ? 'bottom' : 'right'}
				arrow
			>
				<Info color="primary" fontSize="small" />
			</TooltipCustom>
		),
	};
};

export const getResponse = (items?: number): ResponsiveType => {
	return {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: items && items > 5 ? 5 : items || 5,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: items && items > 3 ? 3 : items || 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: items && items > 2 ? 2 : items || 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};
};

export const PROFILES_VALID_CER = [
	PROFILES.ACREDITADO_CUMPLIDO,
	PROFILES.ACREDITADO_PROBLEMAS_PAGO,
	PROFILES.ACREDITADO_PROCESO_JUDICIAL,
	PROFILES.ACREDITADO_DEMANDADO,
	PROFILES.ACREDITADO_HISTORICO,
	PROFILES.PENSIONADO_SIN_CREDITO,
	PROFILES.PENSIONADO_CREDITO_CUMPLIDO,
	PROFILES.PENSIONADO_PROBLEMAS_PAGO,
	PROFILES.PENSIONADO_PROCESO_JUDICIAL,
	PROFILES.PENSIONADO_CREDITO_CERRADO,
	PROFILES.PENSIONADO_HISTORICO,
	PROFILES.PENSIONADO_RELACION_LABORAL,
];
