import API from '..';
class ApiHomeMeasure extends API {
	getStatus(nss: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/hogar-a-tu-medida-pdf`,
				method: 'POST',
				data: { nss },
			})
		);
	}
}

export const apiHomeMeasure = new ApiHomeMeasure('');
