import { Grid } from '@mui/material';
import CardButton from './CardButton';
import { COLORS, WORKSHOP_VIEWS } from '../utils';
import logoEducacion from '../../../../../assets/img/taller-saber-mas/educacion-financiera.png';
import logoCredito from '../../../../../assets/img/taller-saber-mas/credito.png';
import logoCartera from '../../../../../assets/img/taller-saber-mas/cartera.png';
import logoRecaudacion from '../../../../../assets/img/taller-saber-mas/recaudacion-fiscal.png';
import useTallerMas from '../hooks/useElements';

const CardsTaller = () => {
	const { activeView, handleChangeCard, elementos, matches } = useTallerMas();
	const element1 = elementos[WORKSHOP_VIEWS.FINANTIAL_EDUCATION.key];
	const element2 = elementos[WORKSHOP_VIEWS.I_WANT_A_CREDIT.key];
	const element3 = elementos[WORKSHOP_VIEWS.I_HAVE_A_CREDIT.key];
	const element4 = elementos[WORKSHOP_VIEWS.MY_SUB_ACCOUNT.key];
	return (
		<Grid container columns={12} spacing={matches ? 0 : 4} padding={matches ? 0 : 2}>
			<Grid item xs={3}>
				<CardButton
					title="Educación financiera"
					subTitle={`${element1?.numero_elementos || 0} temas`}
					onClick={() =>
						handleChangeCard(WORKSHOP_VIEWS.FINANTIAL_EDUCATION)
					}
					isActive={
						activeView.key ===
						WORKSHOP_VIEWS.FINANTIAL_EDUCATION.key
					}
					color={COLORS.PINK}
					showStar
					footerTitle="Indispensable"
					imgLogo={logoEducacion}
				/>
			</Grid>
			<Grid item xs={3}>
				<CardButton
					title="Quiero un crédito"
					subTitle={`${element2?.numero_elementos || 0} temas`}
					onClick={() =>
						handleChangeCard(WORKSHOP_VIEWS.I_WANT_A_CREDIT)
					}
					isActive={
						activeView.key === WORKSHOP_VIEWS.I_WANT_A_CREDIT.key
					}
					color={COLORS.GREEN}
					imgLogo={logoCredito}
				/>
			</Grid>
			<Grid item xs={3}>
				<CardButton
					title="Tengo un crédito"
					subTitle={`${element3?.numero_elementos || 0} temas`}
					onClick={() =>
						handleChangeCard(WORKSHOP_VIEWS.I_HAVE_A_CREDIT)
					}
					isActive={
						activeView.key === WORKSHOP_VIEWS.I_HAVE_A_CREDIT.key
					}
					color={COLORS.ORANGE}
					imgLogo={logoCartera}
				/>
			</Grid>
			<Grid item xs={3}>
				<CardButton
					title="Mi Subcuenta de Vivienda"
					subTitle={`${element4?.numero_elementos || 0} temas`}
					onClick={() =>
						handleChangeCard(WORKSHOP_VIEWS.MY_SUB_ACCOUNT)
					}
					isActive={
						activeView.key === WORKSHOP_VIEWS.MY_SUB_ACCOUNT.key
					}
					color={COLORS.BLUE}
					imgLogo={logoRecaudacion}
				/>
			</Grid>
		</Grid>
	);
};

export default CardsTaller;
