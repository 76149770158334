import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: '92px 15vw 0',
		textAlign: 'center',
		background: '#f0f0f0',
		position: 'absolute',
		overflow: 'auto',
		height: '90%',
	},
	infoHeader: {
		position: 'absolute',
		background: '#c7c6c5',
		paddingTop: 25,
		width: '100%',
		height: 135,
		left: 0,
		top: 0,
	},
	paperContainer: {
		overflow: 'auto',
		height: '82%',
		padding: '30px 10vw',
		textAlign: 'center',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'&>button': {
			maxWidth: '60%',
			margin: '40px 0',
		},
		[theme.breakpoints.down('sm')]: {
			height: '66%',
		},
	},
	imageContainer: {
		display: 'flex',
		justifyContent: 'center',
		margin: '0 15px 40px',
		'&>hr': {
			margin: '0 15px',
		},
	},
	contactInfoContainer: {
		background: '#f0f0f0',
		width: '100%',
		borderRadius: 10,
		padding: '1px 0',
		margin: '30px 0',
	},
	footerContainer: {
		padding: '30px 7vw',
		background: '#333333',
		textAlign: 'center',
		color: 'white',
		fontSize: 'x-small',
		'&>a': {
			color: 'white',
		},
	},
	imagenInfonavit: {
		height: '81px',
		width: '101px',
		[theme.breakpoints.down('md')]: {
			height: '56.7px',
			width: '70.7px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '56.7px',
			width: '70.7px',
		},
	},
	imagenMCI: {
		height: '81px',
		width: '286px',
		[theme.breakpoints.down('md')]: {
			height: '56.7px',
			width: '200.2px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '56.7px',
			width: '200.2px',
		},
	},
	VerificacionCodigoTitle: {
		fontSize: '32px',
		textAlign: 'center',
		color: '#293990',
		width: '100%',
		display: 'block',
		clear: 'both',
	},
	VerificacionText: {
		fontSize: '17px',
		textAlign: 'center',
		width: '100%',
	},
}));
