import { Divider, useMediaQuery, useTheme } from '@mui/material';
import CustomButton from '../../components/Button';
import Modal from '../../components/Modal';
import { useNavigate } from 'react-router';

interface IProps {
	show: boolean;
}

const ModalBajaTemporal = ({ show }: IProps) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const navigate = useNavigate();

	return (
		<Modal
			open={show}
			hideCloseButton
			width={'sm'}
			children={
				<div style={{ textAlign: 'center', fontSize: 15 }}>
					<h3
						style={{
							color: 'rgb(41, 57, 144)',
							fontSize: 30,
							margin: 0,
						}}
					>
						Aviso
					</h3>

					<p
						style={{
							color: 'rgb(41, 57, 144)',
							fontWeight: 500,
						}}
					>
						Estamos mejorando este servicio, por lo que se encuentra
						temporalmente suspendido.
					</p>

					<p style={{ fontWeight: 500, textAlign: 'left' }}>
						Te ofrecemos una disculpa por los inconvenientes que
						pueda ocasionarte.
					</p>

					<p style={{ fontWeight: 500, textAlign: 'left' }}>
						Te invitamos a ingresar nuevamente a partir del 27 de
						noviembre de 2023, o si prefieres acude a tu AFORE para
						realizar el trámite.
					</p>

					<Divider style={{ margin: '20px 0px' }} />

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: matches
								? 'column-reverse'
								: 'inherit',
						}}
					>
						<div
							style={{
								width: matches ? '100%' : 180,
								display: matches ? 'flex' : 'inline-flex',
								margin: '8px 10px',
							}}
						>
							<CustomButton
								label="Aceptar"
								variant="solid"
								onClick={() => navigate('/')}
							/>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default ModalBajaTemporal;
