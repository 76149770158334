/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { Radio, FormControlLabel, RadioGroup } from '@mui/material';
import { useStyles } from '../styles/styles';
import HeaderStep from './HeaderStep';
import TextInput from '../../../../../components/TextInput';
import { isNumber } from '../../../../../utils/expressions';

const QuestStep3 = ({
	quest,
	nextStep,
	handleChange,
	afterQuestionarySteps,
	alert,
}: any) => {
	const classes = useStyles();
	const [quests, setQuests] = useState([] as any);
	const [date, setDate] = useState({
		month: '',
		year: '',
	});
	const [daysWork, setDaysWork] = useState([
		{
			id: 1,
			text: 'Lunes',
			value: '',
			hrs: '',
			mins: '',
		},
		{
			id: 2,
			text: 'Martes',
			value: '',
			hrs: '',
			mins: '',
		},
		{
			id: 3,
			text: 'Miércoles',
			value: '',
			hrs: '',
			mins: '',
		},
		{
			id: 4,
			text: 'Jueves',
			value: '0',
			hrs: '',
			mins: '',
		},
		{
			id: 5,
			text: 'Viernes',
			value: '',
			hrs: '',
			mins: '',
		},
		{
			id: 6,
			text: 'Sábado',
			value: '',
			hrs: '',
			mins: '',
		},
		{
			id: 7,
			text: 'Domingo',
			value: '',
			hrs: '',
			mins: '',
		},
	]);
	const [nameCompany, setNameCompany] = useState('')
	const [daysDisabled, setDaysDisabled] = useState(false)

	useEffect(() => {
		drawQuestions();
	}, [quest, date, daysWork]);

	useEffect(() => {
		let respuestaDate = `Mes ${date.month} Año ${date.year}`;
		handleChange(respuestaDate, '2.19');
	}, [date]);

	const updatevalueQuestion = (newValue: any, index: any) => {
		const newQuestion = daysWork.map((item: any) => {
			if (item.id === index + 1) {
				if (newValue === '0') {
					return {
						...item,
						value: newValue,
						hrs: '',
						mins: '',
					};
				} else {
					return {
						...item,
						value: newValue,
						hrs: '0',
						mins: '0',
					};
				}
			}
			return item;
		});
		setDaysWork(newQuestion);
		//Se forma la respuesta
		let respuesta = `a) ${newQuestion[0].value === '1' ? 1 : 2}${newQuestion[0].hrs ? ' ' + newQuestion[0].hrs : ''
			}${newQuestion[0].hrs ? ' horas' : ''}${newQuestion[0].mins ? ' ' + newQuestion[0].mins : ''}${newQuestion[0].mins ? ' minutos' : ''}; b) ${newQuestion[1].value === '1' ? 1 : 2
			}${newQuestion[1].hrs ? ' ' + newQuestion[1].hrs : ''}${newQuestion[1].hrs ? ' horas' : ''}${newQuestion[1].mins ? ' ' + newQuestion[1].mins : ''}${newQuestion[1].mins ? ' minutos' : ''}; c) ${newQuestion[2].value === '1' ? 1 : 2
			}${newQuestion[2].hrs ? ' ' + newQuestion[2].hrs : ''}${newQuestion[2].hrs ? ' horas' : ''}${newQuestion[2].mins ? ' ' + newQuestion[2].mins : ''}${newQuestion[2].mins ? ' minutos' : ''}; d) ${newQuestion[3].value === '1' ? 1 : 2
			}${newQuestion[3].hrs ? ' ' + newQuestion[3].hrs : ''}${newQuestion[3].hrs ? ' horas' : ''}${newQuestion[3].mins ? ' ' + newQuestion[3].mins : ''}${newQuestion[3].mins ? ' minutos' : ''}; e) ${newQuestion[4].value === '1' ? 1 : 2
			}${newQuestion[4].hrs ? ' ' + newQuestion[4].hrs : ''}${newQuestion[4].hrs ? ' horas' : ''}${newQuestion[4].mins ? ' ' + newQuestion[4].mins : ''}${newQuestion[4].mins ? ' minutos' : ''}; f) ${newQuestion[5].value === '1' ? 1 : 2
			}${newQuestion[5].hrs ? ' ' + newQuestion[5].hrs : ''}${newQuestion[5].hrs ? ' horas' : ''}${newQuestion[5].mins ? ' ' + newQuestion[5].mins : ''}${newQuestion[5].mins ? ' minutos' : ''}; g) ${newQuestion[6].value === '1' ? 1 : 2
			}${newQuestion[6].hrs ? ' ' + newQuestion[6].hrs : ''}${newQuestion[6].hrs ? ' horas' : ''}${newQuestion[6].mins ? ' ' + newQuestion[6].mins : ''}${newQuestion[6].mins ? ' minutos' : ''};`;
		handleChange(respuesta, '2.18');
		let disabled = verifyCompleteDays(newQuestion)
		setDaysDisabled(disabled);
	};

	const verifyCompleteDays = (days: any) => {
		let respuesta = days.every((item: any) => {
			if (item.value.length === 0) return false
			else return true
		})
		return respuesta
	}

	const updateTime = (value: any, index: any, type: any) => {
		const newQuestion = daysWork.map((item: any) => {
			if (item.id === index + 1) {
				return {
					...item,
					[type]: value,
				};
			}
			return item;
		});
		setDaysWork(newQuestion);
		//Se forma la respuesta
		let respuesta = `a) ${newQuestion[0].value === '1' ? 1 : 2}${newQuestion[0].hrs ? ' ' + newQuestion[0].hrs : ''
			}${newQuestion[0].hrs ? ' horas' : ''}${newQuestion[0].mins ? ' ' + newQuestion[0].mins : ''}${newQuestion[0].mins ? ' minutos' : ''}; b) ${newQuestion[1].value === '1' ? 1 : 2
			}${newQuestion[1].hrs ? ' ' + newQuestion[1].hrs : ''}${newQuestion[1].hrs ? ' horas' : ''}${newQuestion[1].mins ? ' ' + newQuestion[1].mins : ''}${newQuestion[1].mins ? ' minutos' : ''}; c) ${newQuestion[2].value === '1' ? 1 : 2
			}${newQuestion[2].hrs ? ' ' + newQuestion[2].hrs : ''}${newQuestion[2].hrs ? ' horas' : ''}${newQuestion[2].mins ? ' ' + newQuestion[2].mins : ''}${newQuestion[2].mins ? ' minutos' : ''}; d) ${newQuestion[3].value === '1' ? 1 : 2
			}${newQuestion[3].hrs ? ' ' + newQuestion[3].hrs : ''}${newQuestion[3].hrs ? ' horas' : ''}${newQuestion[3].mins ? ' ' + newQuestion[3].mins : ''}${newQuestion[3].mins ? ' minutos' : ''}; e) ${newQuestion[4].value === '1' ? 1 : 2
			}${newQuestion[4].hrs ? ' ' + newQuestion[4].hrs : ''}${newQuestion[4].hrs ? ' horas' : ''}${newQuestion[4].mins ? ' ' + newQuestion[4].mins : ''}${newQuestion[4].mins ? ' minutos' : ''}; f) ${newQuestion[5].value === '1' ? 1 : 2
			}${newQuestion[5].hrs ? ' ' + newQuestion[5].hrs : ''}${newQuestion[5].hrs ? ' horas' : ''}${newQuestion[5].mins ? ' ' + newQuestion[5].mins : ''}${newQuestion[5].mins ? ' minutos' : ''}; g) ${newQuestion[6].value === '1' ? 1 : 2
			}${newQuestion[6].hrs ? ' ' + newQuestion[6].hrs : ''}${newQuestion[6].hrs ? ' horas' : ''}${newQuestion[6].mins ? ' ' + newQuestion[6].mins : ''}${newQuestion[6].mins ? ' minutos' : ''};`;
		handleChange(respuesta, '2.18');
	};

	const drawQuestions = () => {
		const questions: any = [];
		quest.map((item: any, index: any) => {
			const newItem = {
				title: `${item.numero.split('.')[1]}.- ${item.pregunta}`,
				body: (
					<>
						{item.opciones.length > 0 && item.numero !== '2.12' &&
							item.numero !== '2.18' &&
							item.opciones.map((opcion: any) => (
								<div
									className={classes.itemsFlexCenter}
									key={opcion + item.pregunta}
								>
									<Radio
										checked={
											item.respuesta ===
											opcion.split(')')[0]
										}
										onChange={(e: any) => {
											let value =
												e.target.value.split(')')[0];
											handleChange(value, item.numero);
										}}
										name={opcion.split(')')[0]}
										value={opcion.split(')')[0]}
										disabled={(
											((index === 2 || index === 3 || index === 4 || index === 5 || index === 6) && (quest[1].respuesta === '3' || quest[1].respuesta === '4')) ||
											((index === 3 || index === 4 || index === 5 || index === 6) && quest[2].respuesta === '1') ||
											((index === 3) && (quest[2].respuesta === '2' || quest[2].respuesta === '3')) ||
											((index === 4 || index === 5 || index === 6) && (quest[3].respuesta === '1' || quest[3].respuesta === '2')) ||
											((index === 5) && (quest[4].respuesta === '2')) ||
											((index === 5 || index === 6) && (quest[4].respuesta === '3'))
										)}
									/>
									{opcion}
								</div>
							))}
						{item.numero === '2.12' &&
							item.opciones.map((opcion: any, index: any) => (
								<div
									className={classes.itemsFlexCenter}
									key={opcion + item.pregunta}
								>
									{index === 0 && (
										<div style={{ minWidth: '450px', paddingLeft: '10px' }}>
											<TextInput
												id={'otherType'}
												name={'otherType'}
												value={nameCompany}
												label="Nombre de la empresa o negocio"
												onChange={(e) => {
													setNameCompany(e.target.value)
													handleChange(e.target.value, item.numero);
												}}
											/>
										</div>
									)}
									{index !== 0 && (
										<Radio
											checked={
												item.respuesta ===
												opcion.split(')')[0]
											}
											onChange={(e: any) => {
												let value =
													e.target.value.split(')')[0];
												handleChange(value, item.numero);
												setNameCompany('')
											}}
											name={opcion.split(')')[0]}
											value={opcion.split(')')[0]}
											disabled={(
												((index === 2 || index === 3 || index === 4 || index === 5 || index === 6) && (quest[1].respuesta === '3' || quest[1].respuesta === '4')) ||
												((index === 3 || index === 4 || index === 5 || index === 6) && quest[2].respuesta === '1') ||
												((index === 3) && (quest[2].respuesta === '2' || quest[2].respuesta === '3')) ||
												((index === 4 || index === 5 || index === 6) && (quest[3].respuesta === '1' || quest[3].respuesta === '2')) ||
												((index === 5) && (quest[4].respuesta === '2')) ||
												((index === 5 || index === 6) && (quest[4].respuesta === '3'))
											)}
										/>
									)}
									{index !== 0 ? opcion : ''}
								</div>
							))}
						{item.opciones.length === 0 &&
							item.numero !== '2.18' &&
							item.numero !== '2.19' && (
								<div style={{ maxWidth: 400 }}>
									<TextInput
										id={item.pregunta}
										name={item.pregunta}
										value={item.respuesta}
										onChange={(e) =>
											handleChange(
												e.target.value,
												item.numero
											)
										}
									/>
								</div>
							)}
						{item.numero === '2.18' &&
							daysWork.map((option: any, index: any) => {
								return (
									<div
										style={{
											padding: '0px',
											marginTop: index === 0 ? 10 : 0,
										}}
										key={option.text + index}
									>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'space-between',
											}}
										>
											<div
												style={{
													width: '150px',
													marginRight: '10px',
													textAlign: 'left',
												}}
											>
												{option.text}
											</div>
											<RadioGroup
												value={item.value}
												onChange={(e: any) => {
													updatevalueQuestion(
														e.target.value,
														index
													);
												}}
												style={{
													width: 440,
													display: 'flex',
													flexDirection: 'row',
												}}
											>
												<FormControlLabel
													control={<Radio />}
													style={{ color: '#000000' }}
													label="Sí trabajé"
													value={'1'}
												/>
												<FormControlLabel
													control={<Radio />}
													style={{ color: '#000000' }}
													label="No trabajé"
													value={'0'}
												/>
											</RadioGroup>
											<div
												style={{
													textAlign: 'left',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'flex-end',
												}}
											>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-end',
													}}
												>
													<div>Tiempo</div>
													<TextInput
														size="small"
														contentStyles={{
															maxWidth: 150,
															marginLeft: '10px',
														}}
														id={
															'question-multiple-radio' +
															item.id +
															'-hrs'
														}
														name={option.text}
														onChange={(e: any) => {
															if (!isNumber(e.target.value))
																return;
															updateTime(
																e.target.value,
																index,
																'hrs'
															);
														}}
														disabled={
															option.value === '0'
																? true
																: false
														}
														placeholder={'horas'}
														value={option.hrs}
													/>
													<TextInput
														size="small"
														contentStyles={{
															maxWidth: 150,
															marginLeft: '10px',
														}}
														id={
															'question-multiple-radio' +
															item.id +
															'-min'
														}
														name={option.text}
														onChange={(e: any) => {
															if (!isNumber(e.target.value))
																return;
															updateTime(
																e.target.value,
																index,
																'mins'
															);
														}}
														disabled={
															option.value === '0'
																? true
																: false
														}
														placeholder={'minutos'}
														value={option.mins}
													/>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						{item.opciones.length === 0 &&
							item.numero === '2.19' && (
								<div style={{ maxWidth: 400, display: 'flex' }}>
									<div style={{ textAlign: 'left', marginRight: '20px' }}>
										<div>Mes</div>
										<TextInput
											id={'question-month-' + item.numero}
											name={'month'}
											placeholder="01"
											onChange={(e: any) => {
												if (!isNumber(e.target.value))
													return;
												if (e.target.value.length > 2)
													return;
												if (e.target.value > 12)
													return;
												if (e.target.value === '00') {
													setDate({
														...date,
														month: '01',
													});
												} else {
													setDate({
														...date,
														month: e.target.value,
													});
												}
											}}
											contentStyles={{ maxWidth: 100 }}
											value={date.month}
										/>
									</div>
									<div style={{ textAlign: 'left', marginRight: '20px' }}>
										<div>Año</div>
										<TextInput
											id={'question-year-' + item.numero}
											name={'year'}
											placeholder="1999"
											onChange={(e: any) => {
												if (!isNumber(e.target.value))
													return;
												if (e.target.value.length > 4)
													return;
												if (e.target.value > 2023)
													return;
												if (e.target.value.length === 4 && e.target.value < '1900') {
													setDate({
														...date,
														year: '1900',
													});
												} else {
													setDate({
														...date,
														year: e.target.value,
													});
												}
											}}
											contentStyles={{ maxWidth: 300 }}
											value={date.year}
										/>
									</div>
								</div>
							)}
					</>
				),
				disabled: ((index === 2 || index === 3 || index === 4 || index === 5 || index === 6) && (quest[1].respuesta === '3' || quest[1].respuesta === '4')) ||
					((index === 3 || index === 4 || index === 5 || index === 6) && quest[2].respuesta === '1') ||
					((index === 3) && (quest[2].respuesta === '2' || quest[2].respuesta === '3')) ||
					((index === 4 || index === 5 || index === 6) && (quest[3].respuesta === '1' || quest[3].respuesta === '2')) ||
					((index === 5) && (quest[4].respuesta === '2')) ||
					((index === 5 || index === 6) && (quest[4].respuesta === '3'))
					? true : false
			};
			return questions.push(newItem);
		});
		setQuests(questions);
	};

	const verifyComplete = () => {
		if (daysDisabled) {
			let questHabilitados: any = []
			for (let i = 0; i < quests.length; i++) {
				if (quests[i].disabled === false) {
					questHabilitados.push(i)
				}
			}
			let respuesta = quest.every((item: any, index: any) => {
				if (questHabilitados.includes(index) && item.respuesta.length === 0) return false
				else return true
			})
			return respuesta
		}
		return false
	};

	const sendData = () => {
		nextStep();
	};

	return (
		<HeaderStep
			title={'Situación laboral'}
			subTitle={'Trabajo principal'}
			nextStep={sendData}
			afterQuestionarySteps={afterQuestionarySteps}
			disabledContinue={!verifyComplete()}
			disabledButton={!verifyComplete()}
			children={quests}
			alert={alert}
		/>
	);
};

export default QuestStep3;
