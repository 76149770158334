/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Paper, useMediaQuery } from '@mui/material';
import { CheckCircleOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// Components
import Layout from '../../components/Layout';
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import Status from './status';
import Home from './home';
import Form from './form';
import AttachDocument from './attachDocument';
import MakeDate from './date';
import PanelError from './panelError';
import InfoCase from './infoCase';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';

//Resources
import { IAlert } from '../../interfaces/alert';
import { PaymentRequestAPI } from '../../api/modules/PaymentClarification';
import { ResponseCaso } from '../../interfaces/PaymentClarification';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useTheme } from '@mui/material/styles';
import { IDataConfirm } from '../../interfaces/makeDate24';
import ModalMessageTemp from './ModalMessageTemp';

const PaymentClarification = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { user } = useSelector((state: RootState) => state.session);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [page, setPage] = useState(0);
	const [status, setStatus] = useState(0);
	const [dataCaso, setDataCaso] = useState<{
		noCaso: string;
		estatus: string;
		fecha: string;
		fechaEstatus: string;
		descripcion: string;
		tipificacion: string;
		estatusDetalle: {
			descripcion: string;
			estatus: string;
		};
		casoCerradoDetalle: {
			razon: string;
			id: string;
			descripcion: string;
		};
	}>({
		noCaso: '',
		estatus: '',
		fecha: '',
		fechaEstatus: '',
		descripcion: '',
		tipificacion: '',
		estatusDetalle: {
			descripcion: '',
			estatus: '',
		},
		casoCerradoDetalle: {
			razon: '',
			id: '',
			descripcion: ''
		}
	})
	const [citaActual, setCitaActual] = useState<IDataConfirm>({
		appointmentCode: '',
		appointmentEndDate: '',
		centroAtencionDes: '',
		centroAtencionId: '',
		centroAtencionLocation: '',
		clientNSS: '',
		creationDate: new Date(),
		customField1: 0,
		date: new Date(),
		day: '',
		hour: '',
		id: '',
		minute: '',
		serviceDes: '',
		serviceId: '',
		stateId: '',
		organizationLocation: ''
	})
	const [doctosRequeridosArray, setDoctosRequeridosArray] = useState('');
	const [panelErrorStatus, setPanelErrorStatus] = useState(0);
	const navigate = useNavigate();
	const [dataPayment, setDataPayment] = useState<{
		date: any;
		type: any;
		amount: any;
		payment: any;
		folio: any;
		banco: any;
		creditCorrect: any;
		detallePago: any;
	}>({
		date: '',
		type: '',
		amount: '',
		payment: '',
		folio: '',
		banco: '',
		creditCorrect: '',
		detallePago: '',
	});
	const [makeDateNow, setMakeDateNow] = useState(false);
	const [showModalTemp, setShowModalTemp] = useState(false);

	const handleSubmit = async () => {
		const keyRes = await keyAPI.get<'', { k: string }>();
		const key = keyRes['k'].toString();
		setLoading(true);
		try {
			const paramsData = {
				valida: '0',
				claseOperacion: '',
				nss: user?.nss,
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			PaymentRequestAPI.consultaCaso
				.post<
					{
						data: string;
					},
					ResponseCaso
				>('', {
					data: dataSendEncrypt,
				})
				.then((res: ResponseCaso) => {
					const resCode = res.code;

					if (Number(resCode || '') === 9999) {
						setShowModalTemp(true);
						return;
					}

					if (resCode === '0000' && res.data?.caso?.noCaso) {
						setDataCaso(res.data.caso);
						if (
							res.data.caso.estatusDetalle.estatus === 'Cerrado'
						) {
							setPage(7);
						} else {
							setPage(4);
						}
					} else if (resCode === '0001') {
						setPage(0);
					} else {
						setAlert({
							show: true,
							message: res.message
								? res.message
								: 'Por el momento el servicio no está disponible. Intenta más tarde.',
							severity: 'error',
						});
					}
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: error.description
					? error.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
		}
	};

	useEffect(() => {
		handleSubmit();
	}, []);

	return (
		<Layout>
			<>
				<ModalMessageTemp show={showModalTemp} />
				<ModalLoading loading={loading} />
				{page === 0 && (
					<Box>
						<HeaderList title="Aclaración de pagos" />
						<Paper sx={{ pt: 5, pb: 5, pr: 14, pl: 14 }}>
							<div>
								<div>
									<p>
										Podrás realizar una aclaración de pago
										cuando no veas un pago aplicado a tu
										crédito, y lo realizó directamente el
										acreditado en una institución bancaria
										(ventanilla y pagos electrónicos) o en
										tiendas de conveniencia.
									</p>
									<p>
										De los pagos que realizaste por tu
										cuenta (REA) solo se atenderán los que
										hayan sido efectuados a partir del 2008.
									</p>
									<p>
										Para poder realizar una aclaración,
										deberás tener a la mano, el comprobante
										de pago que te dio el banco o la tienda
										de conveniencia, donde podrás localizar
										los siguientes datos que son necesarios
										para dar inicio a la misma:
									</p>
									<div
										style={{
											display: 'flex',
											marginBottom: 5,
										}}
									>
										<CheckCircleOutlined
											color="info"
											style={{
												marginRight: 5,
												width: 20,
											}}
										/>
										<label style={{ marginTop: 2 }}>
											Monto de pago
										</label>
									</div>
									<div
										style={{
											display: 'flex',
											marginBottom: 5,
										}}
									>
										<CheckCircleOutlined
											color="info"
											style={{
												marginRight: 5,
												width: 20,
											}}
										/>
										<label style={{ marginTop: 2 }}>
											Fecha de pago
										</label>
									</div>
									<div
										style={{
											display: 'flex',
											marginBottom: 5,
										}}
									>
										<CheckCircleOutlined
											color="info"
											style={{
												marginRight: 5,
												width: 20,
											}}
										/>
										<label style={{ marginTop: 2 }}>
											Referencia bancaria
										</label>
									</div>
									<div
										style={{
											display: 'flex',
											marginBottom: 5,
										}}
									>
										<CheckCircleOutlined
											color="info"
											style={{
												marginRight: 5,
												width: 20,
											}}
										/>
										<label style={{ marginTop: 2 }}>
											Folio de autorización de pago
										</label>
									</div>
									<div
										style={{
											display: 'flex',
											marginBottom: 5,
										}}
									>
										<CheckCircleOutlined
											color="info"
											style={{
												marginRight: 5,
												width: 20,
											}}
										/>
										<label style={{ marginTop: 2 }}>
											Banco o tienda de conveniencia
										</label>
									</div>
								</div>
								<CustomAlert
									message={alert.message}
									severity={alert.severity}
									show={alert.show}
								/>
								<div
									style={{
										width: matches ? '100%' : '600px',
										margin: '40px auto 0px',
										display: matches ? 'block' : 'flex',
										justifyContent: 'space-between',
									}}
								>
									<CustomButton
										label="Salir"
										onClick={() => navigate('/')}
										variant="outlined"
										styles={{
											width: 260,
											height: 40,
											margin: '10px auto',
										}}
									/>
									<CustomButton
										data-testid="buttonContinuar"
										label="Continuar"
										disabled={alert.show}
										onClick={() => setPage(1)}
										variant="solid"
										styles={{
											width: 260,
											height: 40,
											margin: '10px auto',
										}}
									/>
								</div>
							</div>
						</Paper>
					</Box>
				)}
				{page === 1 && <Home setPage={setPage} setStatus={setStatus} />}
				{page === 2 && (
					<Form
						setPage={setPage}
						status={status}
						updateCaso={handleSubmit}
						setPanelErrorStatus={setPanelErrorStatus}
						setDataPayment={setDataPayment}
					/>
				)}
				{page === 3 && (
					<AttachDocument
						setPage={setPage}
						dataCaso={dataCaso}
						updateCaso={handleSubmit}
					/>
				)}
				{page === 4 && (
					<Status
						setPage={setPage}
						dataCaso={dataCaso}
						setCitaActual={setCitaActual}
						setDoctosRequeridosArray={setDoctosRequeridosArray}
						setMakeDateNow={setMakeDateNow}
					/>
				)}
				{page === 5 && (
					<MakeDate
						setPage={setPage}
						dataCaso={dataCaso}
						citaActual={citaActual}
						doctosRequeridosArray={doctosRequeridosArray}
						updateCaso={handleSubmit}
						dataPayment={dataPayment}
						setDataCaso={setDataCaso}
						makeDateNow={makeDateNow}
					/>
				)}
				{page === 6 && (
					<PanelError
						setPage={setPage}
						panelErrorStatus={panelErrorStatus}
					/>
				)}
				{page === 7 && (
					<InfoCase setPage={setPage} dataCaso={dataCaso} />
				)}
			</>
		</Layout>
	);
};

export default PaymentClarification;
