import { DESTINOS } from '../../../../../config/miucConstants';
import { Link } from '@mui/material';

const GPSLIIPDF = require('../../../../../assets/files/guia-pasos-a-seguir/GPSLII.pdf');
const GPSLIIIPDF = require('../../../../../assets/files/guia-pasos-a-seguir/GPSLIII.pdf');
const GPSTerrenoPDF = require('../../../../../assets/files/guia-pasos-a-seguir/GPSTerreno.pdf');
const GPSLVPDF = require('../../../../../assets/files/guia-pasos-a-seguir/GPSLV.pdf');
const GPSCICBPDF = require('../../../../../assets/files/guia-pasos-a-seguir/GPSCICB.pdf');
const GPSMEJORAVIT = require('../../../../../assets/files/guia-pasos-a-seguir/GPSMEJORAVIT.pdf');

export const ListRequirements = (
	destino: string,
): Array<JSX.Element> => {
	const getPdfGuiaPasos = () => {
		if (destino === DESTINOS.COMPRA.codigo)
			return GPSLIIPDF;

		if (destino === DESTINOS.CONSTRUCCION.codigo)
			return GPSLIIIPDF;

		if (destino === DESTINOS.REMODELACION.codigo)
			return GPSMEJORAVIT;

		if (destino === DESTINOS.HIPOTECA.codigo)
			return GPSLVPDF;

		if (destino === DESTINOS.CICB.codigo)
			return GPSCICBPDF;

		return GPSTerrenoPDF;
	};

	return [
		<>
      Haber cursado el Curso Saber más para decidir mejor.
			{/* {' '}<Link
				href={process.env.REACT_APP_URL_MIUC_TALLER_LINEA}
				target="_blank"
			>
				Saber para decidir mejor.
			</Link> */}
		</>,
		<>
			Contar con el avalúo de la vivienda que deseas comprar a un
			particular ya sea nueva o existente.
		</>,
		<>
			Llenar el formato de{' '}
			<Link
				href={
					destino === DESTINOS.REMODELACION.codigo
						? require('../../../../../assets/files/guia-pasos-a-seguir/solicitud_mejoravit_repara_renueva.pdf')
            : 'https://portalmx.infonavit.org.mx/wps/wcm/connect/f2b56503-7a4c-44c3-9ef5-075b8325ae9e/SolicituddeInscripciondeCredito_CRED.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CVID=ozkrCXH'
        }
				target="_blank"
			>
				solicitud de crédito
			</Link>{' '}
			para asegurar que tienes toda la información a la mano.
		</>,
		<>
			Tener digitalizados los documentos que integran tu expediente,
			utiliza tu{' '}
			<Link href={getPdfGuiaPasos()} target="_blank">
				Guía de pasos a seguir.
			</Link>
		</>
	];
};
