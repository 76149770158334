/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import CustomButton from '../../../components/Button';
import CustomAlert from '../../../components/CustomAlert';
import { dataReturnSavingsContext } from '../contextData';
import { useStyles } from '../style';
import { Select, MenuItem } from '@mui/material';

const SelectAforePage = () => {
	const style = useStyles();
	const [disabledButton, setDisabledButton] = useState<boolean>(true);
	const [optionsSelect, setOptionsSelect] = useState<
		{ value: string; text: string }[]
	>([]);
	const [optionSelected, setOptionSelected] = useState<string>('');
	const {
		handlePrevPage,
		data,
		handleGetAfore,
		handleValidaAfore,
		alert,
		setAlert,
	} = useContext(dataReturnSavingsContext);

	useEffect(() => {
		handleGetAfore();
	}, []);

	useEffect(() => {
		let array: { value: string; text: string }[] = [];
		data?.catalogoAforeResponse?.data?.afores?.forEach((item) => {
			array.push({
				value: item?.clave || '',
				text: item?.descripcion || '',
			});
		});
		setOptionsSelect(array);
	}, [data?.catalogoAforeResponse]);

	const handlerOnChange = (event: any) => {
		setAlert({
			show: false,
			message: '',
			severity: alert.severity,
		});
		setDisabledButton(false);
		setOptionSelected(event.target.value);
	};

	return (
		<div>
			<span className={style.TextBlack_H3}>AFORE</span>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divNRP}>
					<Select
						displayEmpty
						name="afore"
						value={optionSelected}
						onChange={handlerOnChange}
						renderValue={
							optionSelected !== ''
								? undefined
								: () => (
									<label
										style={{
											fontSize: 15,
											color: '#7F7F7F',
										}}
									>
										Selecciona tu AFORE
									</label>
								)
						}
						style={{
							height: 40,
							width: '100%',
						}}
					>
						{optionsSelect.map(
							(element: {
								value: string;
								text: string;
							}) => (
								<MenuItem
									key={element.value}
									value={element.value}
								>
									{element.text}
								</MenuItem>
							)
						)}
					</Select>
				</div>
			</div>
			<div className={style.divAlert}>
				<CustomAlert
					message={alert.message}
					show={alert.show}
					severity={alert.severity}
					onClose={() => {
						setAlert({
							show: false,
							message: '',
							severity: alert.severity,
						});
					}}
				/>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<div className={style.divButtons}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={handlePrevPage}
							variant="outlined"
							styles={{
								width: '100%',
								height: '100%'
							}}
							label="Regresar"
						/>
					</div>
					<div className={style.Buttons}>
						<CustomButton
							onClick={() => handleValidaAfore(optionSelected)}
							variant="solid"
							styles={{
								width: '100%',
							}}
							label="Continuar"
							disabled={disabledButton}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectAforePage;
