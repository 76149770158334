import { Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import CustomButton from '../../components/Button';

const ElectroNotifyDenegad = () => {
	const navigate = useNavigate();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	return (
		<>
			<Paper style={{ padding: matches ? 20 : 50, textAlign: 'center' }}>
				<div style={{ maxWidth: 900, margin: 'auto' }}>
					<ErrorIcon style={{ color: '#DD0528', fontSize: 100 }} />
					<p>
						Para poder continuar con la formalización de tu crédito, es
						necesario que tu patrón ingrese al Portal Empresarial del
						Infonavit y confirme que está enterado del trámite de tu crédito.
					</p>
					<p>
						Cuando realice la confirmación podrás consultar y descargar en
						este servicio el acuse correspondiente.
					</p>
				</div>

				<CustomButton
					label="Salir"
					variant="solid"
					onClick={() => navigate('/')}
					styles={{ width: matches ? '100%' : 250, margin: '20px auto' }}
				/>
			</Paper>
		</>
	);
};

export default ElectroNotifyDenegad;
