/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomButton from '../../components/Button';
import { HeaderList } from '../../components/HeaderList';
import { useStyles } from './styles';
import CustomAlert from '../../components/CustomAlert';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { apiRfcCorrection } from '../../api/modules/rfcCorrection';
import ModalLoading from '../../components/ModalLoading';
import { IAlert } from '../../interfaces/alert';
import { useNavigate } from 'react-router-dom';
import DocumentView from './DocumentView';
import { useBitacora } from '../../hooks/useBitacora';

interface IDataStatus {
	apMaterno?: string;
	apPaterno?: string;
	consecutivo?: string;
	curp?: string;
	nombre?: string;
	nss?: string;
	rfc?: string;
	status?: {
		code?: string;
		description?: string;
	};
	CodigoError?: string;
	TipoError?: string;
	descripcionServicio?: string;
	fecha?: string;
	fechaStatus?: string;
	noCaso?: string;
	nombreOperacion?: string;
	operacion?: string;
	description?: string;
	isEmpty?: boolean;
}
interface Props {
	setPage: (data: number) => void;
	status: IDataStatus;
}
interface IDataStatus {
	apMaterno?: string;
	apPaterno?: string;
	consecutivo?: string;
	curp?: string;
	nombre?: string;
	nss?: string;
	rfc?: string;
	status?: {
		code?: string;
		description?: string;
	};
	CodigoError?: string;
	TipoError?: string;
	descripcionServicio?: string;
	fecha?: string;
	fechaStatus?: string;
	noCaso?: string;
	nombreOperacion?: string;
	operacion?: string;
}

interface ICreateCase {
	noCaso?: string;
}

const MakeDate = ({ setPage, status }: Props) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const classes = useStyles();
	const [displayView, setDisplayView] = useState(0);
	const [modalView, setModalView] = useState(null);
	const setModalFile = useState(false)[1];
	const [loading, setLoading] = useState(false);
	const { user } = useSelector((state: RootState) => state.session);
	const { regBitacoraNotOld } = useBitacora();
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [dataStatus, setDataStatus] = useState<IDataStatus>({});

	const [dataCase, setDataCase] = useState<ICreateCase>({
		noCaso: '',
	});

	useEffect(() => {
		if (status.isEmpty || status.status?.code === 'E0007' || status.status?.code === 'E0015') {
			const crearCaso = async () => {
				try {
					setLoading(true);
					const keyRes = await keyAPI.get<'', { k: string }>();
					const key = keyRes['k'].toString();
					const nss_base64 = await encrypt(user?.nss.toString() || '', key);
					const rfc_base64 = await encrypt(user?.rfc.toString() || '', key);
					const email_base64 = await encrypt(user?.email.toString() || '', key);
					const celular_base64 = await encrypt(
						user?.telefonoCelular.toString() || '',
						key
					);

					const responseCreateCase = await apiRfcCorrection.createCase(
						nss_base64,
						rfc_base64,
						email_base64,
						celular_base64
					);

					const { noCaso } = responseCreateCase.result;

					setDataCase({ ...dataCase, noCaso: noCaso });
				} catch (error: any) {
					setAlert({
						show: true,
						message: error.description,
						severity: 'error',
					});
				} finally {
					setLoading(false);
				}
			};
			crearCaso();
		} else {
			setDataCase({ ...dataCase, noCaso: status.noCaso });
		}
	}, [status]);

	const [files, setFiles] = useState({
		file1: {
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
			size: 0,
		},
		file2: {
			name: 'No se eligió ningún archivo',
			data: null,
			type: '',
			size: 0,
		},
	});

	const consultaStatus = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();

			const rfc_base64 = await encrypt(user?.rfc.toString() || '', key);
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			const curp_base64 = await encrypt(user?.curp.toString() || '', key);

			const responseStatusRFC = await apiRfcCorrection.getInitialStatus(
				nss_base64,
				rfc_base64,
				curp_base64
			);
			if (responseStatusRFC?.result) {
				setDataStatus(responseStatusRFC.result);
				setDisplayView(2);
			}
		} catch (error: any) {
			setLoading(false);
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_GETTING_DATA,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const enviarArchivos = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const noCaso_base64 = await encrypt(dataCase.noCaso?.toString() || '', key);

			let formData = new FormData();

			formData.append('noCaso', noCaso_base64);
			formData.append('rfc', files?.file1.data ? files.file1.data : '');
			formData.append('ine', files?.file2.data ? files.file2.data : '');

			const response = await uploadToFileServer(
				formData,
				`${process.env.REACT_APP_API_URL}/cambio-rfc/haz-cita/attach-documents`
			);
			let dataBitacora = {
				noCaso: dataCase.noCaso,
				rfc: files.file1.name,
				ine: files.file2.name
			}
			if (response) {
				consultaStatus();
				regBitacoraNotOld('/cambio-rfc/haz-cita/attach-documents', dataBitacora);
			} else {
				setAlert({
					show: true,
					message:
						INTERNAL_MESSAGES.UPLOAD_FILES,
					severity: 'error',
				});
			}
		} catch (error) {
			//console.log(error);
		} finally {
			displayView === 2 &&
				setAlert({
					show: false,
					message:
						INTERNAL_MESSAGES.UPLOAD_FILES,
					severity: 'error',
				});
			setLoading(false);
		}
	};

	const uploadToFileServer = (formdata: any, url: string) => {
		return new Promise((resolve) => {
			const xhr = new XMLHttpRequest();
			const token = user?.token;
			xhr.responseType = 'json';
			xhr.open('POST', url, true);
			xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			xhr.setRequestHeader('Authorization', `Bearer ${token}`);
			xhr.send(formdata);

			xhr.onload = () => {
				if (xhr.status === 200) {
					resolve(xhr.response.result);
				} else {
					resolve(null);
				}
			};
		});
	};

	const handleFile = (e: any) => {
		setLoading(true);
		if (
			e.target.files[0].size < 20971520 &&
			(e.target.files[0].type === 'image/jpeg' ||
				e.target.files[0].type === 'image/png' ||
				e.target.files[0].type === 'application/pdf' ||
				e.target.files[0].type === 'image/tiff' ||
				e.target.files[0].type === 'video/mpeg' ||
				e.target.files[0].type === 'video/x-msvideo' ||
				e.target.files[0].type === 'video/x-ms-wmv' ||
				e.target.files[0].type === 'video/mp4' ||
				e.target.files[0].type === 'audio/mpeg')
		) {
			setFiles({
				...files,
				[e.target.name]: {
					name: e.target.files[0].name,
					data: e.target.files[0],
					type: e.target.files[0].type,
					size: e.target.files[0].size,
				},
			});
			setLoading(false);
		} else {
			setModalFile(true);
			setLoading(false);
		}
	};

	const handleDeleteFile = (index: string) => {
		setFiles({
			...files,
			[index]: {
				name: INTERNAL_MESSAGES.NO_FILE,
				data: null,
			},
		});
	};

	const describeText = [
		'Tienes 5 días para adjuntar tus documentos, de lo contrario tu caso se cerrará y tendrás que hacer tu trámite de nuevo.',
	];

	return (
		<Box>
			<HeaderList
				title="Registro o corrección de RFC"
				list={displayView === 2 ? describeText : []}
			/>
			<ModalLoading loading={loading} />
			<DocumentView file={modalView} setClose={setModalView} />
			<Paper>
				<div
					id="makeDateText"
					style={{ display: displayView === 0 ? 'inline' : 'none' }}
				>
					<p className={classes.pDate}>
						Para hacer más ágil el trámite, antes de hacer una cita adjunta el
						comprobante del RFC emitido por el Servicio de Administración
						Tributaria (SAT) y tu identificación oficial vigente.
					</p>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							paddingLeft: matches ? 15 : 80,
							paddingRight: matches ? 15 : 50,
							margin: 0,
						}}
					>
						<CustomAlert
							show={alert.show}
							severity={alert.severity}
							message={alert.message}
						/>
					</div>
					<div className={classes.botonContainer} style={{ paddingTop: 20 }}>
						<CustomButton
							label="Cancelar"
							variant="outlined"
							onClick={() => {
								//Resolver conflictos con staging por valores planchados
								setPage(0);
							}}
							styles={{
								width: matches ? 300 : 250,
								height: 40,
							}}
						/>
						<CustomButton
							label="Adjuntar documentos"
							disabled={alert.show && true}
							onClick={() => {
								setDisplayView(1);
							}}
							variant="solid"
							styles={{
								width: matches ? 300 : 250,
								height: 40,
								marginBottom: matches ? 25 : 0,

								marginLeft: matches ? 0 : 25,
							}}
						/>
					</div>
				</div>

				<div
					id="makeDateFile"
					style={{ display: displayView === 1 ? 'inline' : 'none' }}
				>
					<h3
						className={classes.h4Date}
						style={{
							paddingTop: 20,
							paddingBottom: 10,
							color: '#29398F',
						}}
					>
						Adjuntar documentos
					</h3>
					<hr className={classes.hr} />
					<div
						style={{
							display: 'flex',
							alignItems: matches ? 'normal' : 'center',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							paddingLeft: matches ? 25 : 20,
							paddingRight: 20,
							paddingBottom: matches ? 70 : 10,

							flexDirection:
								files.file1.data && matches ? 'column' : 'inherit',
						}}
					>
						<h3
							style={{
								color: 'black',
								width: 380,
								order: 1,
								marginBottom: matches ? 1 : 18.7,
							}}
						>
							Número de caso
						</h3>

						<p
							style={{
								flexGrow: 1,
								width: matches ? '100%' : 'auto',
								order: matches && !files.file1.data ? 3 : 2,
								margin: 0,
							}}
						>
							{dataCase.noCaso}
						</p>
					</div>

					<hr style={{ margin: '0px 25px', opacity: 0.6 }} />
					{/* ------------------------------------------------------ */}
					<div
						style={{
							display: 'flex',
							alignItems: matches ? 'normal' : 'center',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							paddingLeft: matches ? 25 : 20,
							paddingRight: 20,
							paddingBottom: matches ? 70 : 10,
							paddingTop: 5,

							flexDirection:
								files.file1.data && matches ? 'column' : 'inherit',
						}}
					>
						<h3
							style={{
								color: 'black',
								width: matches ? 180 : 380,
								fontSize: matches ? 16 : 18,
								order: 1,
							}}
						>
							Comprobante del RFC emitido por el SAT
						</h3>

						<p
							style={{
								flexGrow: 1,
								width: matches ? '100%' : 'auto',
								order: matches && !files.file1.data ? 3 : 2,
								margin: 0,
								opacity: 0.5,
							}}
						>
							{files.file1.name}
						</p>
						<div
							style={{
								textDecoration: 'none',
								color: '#D1001F',
								paddingTop: 0,
								fontSize: 16,
								order: matches && !files.file1.data ? 2 : 3,
								display: 'flex',
								alignItems:
									files.file1.data && matches ? 'normal' : 'center',
								flexDirection:
									files.file1.data && matches ? 'column' : 'inherit',
							}}
						>
							<label
								onClick={() => handleDeleteFile('file1')}
								style={{
									display: files.file1.data ? 'flex' : 'none',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
								}}
							>
								<Close style={{ fontSize: 20, marginRight: 2 }} />
								Eliminar archivo
							</label>
							<label
								style={{
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
								}}
								onClick={() =>
									files.file1.data && setModalView(files.file1.data)
								}
							>
								{files.file1.data ? (
									<>
										<Search
											style={{ fontSize: 20, marginRight: 2 }}
										/>
										Ver archivo
									</>
								) : (
									<label
										style={{
											cursor: 'pointer',
											paddingBottom: matches ? 25 : 0,
										}}
									>
										<input
											type="file"
											accept="*"
											name="file1"
											onChange={handleFile}
											className={classes.chooseFileOK}
										/>
										Elegir archivo
									</label>
								)}
							</label>
						</div>
					</div>

					<hr style={{ margin: '0px 25px', opacity: 0.6 }} />
					{/* ---------------------------------------------------- */}
					<div
						style={{
							display: 'flex',
							alignItems: matches ? 'normal' : 'center',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							paddingLeft: matches ? 25 : 20,
							paddingRight: 20,
							paddingBottom: matches ? 70 : 10,
							paddingTop: matches ? 0 : 5,
							flexDirection:
								files.file1.data && matches ? 'column' : 'inherit',
						}}
					>
						<h3
							style={{
								color: 'black',
								width: matches ? 180 : 380,
								order: 1,
								fontSize: matches ? 16 : 18,
							}}
						>
							Identificación oficial vigente
						</h3>

						<p
							style={{
								flexGrow: 1,
								width: matches ? '100%' : 'auto',
								order: matches && !files.file2.data ? 3 : 2,
								margin: 0,
								opacity: 0.5,
							}}
						>
							{files.file2.name}
						</p>
						<div
							style={{
								textDecoration: 'none',
								color: '#D1001F',
								paddingTop: 0,
								fontSize: 16,
								order: matches && !files.file2.data ? 2 : 3,
								display: 'flex',
								alignItems:
									files.file2.data && matches ? 'normal' : 'center',
								flexDirection:
									files.file2.data && matches ? 'column' : 'inherit',
							}}
						>
							<label
								onClick={() => handleDeleteFile('file2')}
								style={{
									display: files.file2.data ? 'flex' : 'none',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
								}}
							>
								<Close style={{ fontSize: 20, marginRight: 2 }} />
								Eliminar archivo
							</label>
							<label
								style={{
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
									margin: matches ? '5px 0px' : '0px 5px',
								}}
								onClick={() =>
									files.file2.data && setModalView(files.file2.data)
								}
							>
								{files.file2.data ? (
									<>
										<Search
											style={{ fontSize: 20, marginRight: 2 }}
										/>
										Ver archivo
									</>
								) : (
									<label
										style={{
											cursor: 'pointer',
											paddingBottom: matches ? 25 : 0,
										}}
									>
										<input
											type="file"
											accept="*"
											name="file2"
											onChange={handleFile}
											className={classes.chooseFileOK}
										/>
										Elegir archivo
									</label>
								)}
							</label>
						</div>
					</div>

					<hr style={{ margin: '0px 25px', opacity: 0.6 }} />
					{/* ------------------------------------------------------------- */}

					<div style={{}}>
						<p className={classes.pMakeDate}>
							Antes de enviar tus documento verifica que estén correctos.
							<br /> Los formatos permitidos son jpg, png, tiff, pdf, mpeg,
							avi, wmv, mp4 o mp3 con un tamaño máximo de 20 MB por todos
							los documentos adjuntos.
						</p>
						<div className={classes.botonContainerDate}>
							<CustomButton
								label="Regresar"
								onClick={() => {
									setDisplayView(0);
								}}
								variant="outlined"
								styles={{
									width: 250,
									height: 40,
									marginRight: 20,
									marginBottom: 20,
								}}
							/>
							<CustomButton
								label="Enviar documentos"
								onClick={() => enviarArchivos()}
								disabled={
									files.file1.data && files.file2.data ? false : true
								}
								variant="solid"
								styles={{ width: 250, height: 40, marginBottom: 20 }}
							/>
						</div>
					</div>
				</div>
				<div
					style={{
						paddingTop: 0,
						paddingBottom: 0,
						paddingLeft: matches ? 10 : 120,
						paddingRight: matches ? 10 : 120,
					}}
				>
					<div
						id="thanksMakeDate"
						className={classes.containerThanksElecSig}
						style={{
							display: displayView === 2 ? 'inline' : 'none',
						}}
					>
						<h3 className={classes.title} style={{ paddingTop: 20 }}>
							Gracias por utilizar los servicios electrónicos del Infonavit
						</h3>
						<br />
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<h3 className={classes.h4} style={{ color: 'black' }}>
								Número de caso
							</h3>
							<p className={classes.phidelargethanks}>{dataCase.noCaso}</p>
						</div>
						<p className={classes.phidesmallthanks}>{dataCase.noCaso}</p>
						<hr className={classes.hr} />

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<h3 className={classes.h4} style={{ color: 'black' }}>
								Fecha de solicitud
							</h3>
							<p className={classes.phidelargethanks}>{dataStatus.fecha}</p>
						</div>
						<p className={classes.phidesmallthanks}>{dataStatus.fecha}</p>
						<hr className={classes.hr} />

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<h3 className={classes.h4} style={{ color: 'black' }}>
								Servicio
							</h3>
							<p className={classes.phidelargethanks}>
								{dataStatus.descripcionServicio}
							</p>
						</div>
						<p className={classes.phidesmallthanks}>
							{dataStatus.descripcionServicio}
						</p>
						<hr className={classes.hr} />

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<h3 className={classes.h4} style={{ color: 'black' }}>
								Estatus
							</h3>
							<p className={classes.phidelargethanks}>
								{dataStatus.status?.description}
							</p>
						</div>
						<p className={classes.phidesmallthanks}>
							{dataStatus.status?.description}
						</p>
						<hr className={classes.hr} />

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<h3 className={classes.h4} style={{ color: 'black' }}>
								Fecha de estatus
							</h3>
							<p className={classes.phidelargethanks}>
								{dataStatus.fechaStatus}
							</p>
						</div>
						<p className={classes.phidesmallthanks}>
							{dataStatus.fechaStatus}
						</p>

						<div
							className={classes.botonContainer}
							style={{
								display: 'flex',
								margin: 'auto',
								height: 40,
								width: matches ? 280 : 282,
								paddingBottom: 60,
								paddingTop: 50,
							}}
						>
							<CustomButton
								label="Haz tu cita"
								onClick={() =>
									navigate(
										`/contactanos/haz-una-cita/${dataStatus.noCaso}/0125/`
									)
								}
								variant="solid"
							/>
						</div>
					</div>
				</div>
			</Paper>
		</Box>
	);
};

export default MakeDate;
