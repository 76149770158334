// DEPENDENCIES
import React, { useState } from 'react';
import { Divider, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
// COMPONENTS
import VerificationCode from '../../../../components/VerificationCode';
import ModalLoading from '../../../../components/ModalLoading';
import CustomAlert from '../../../../components/CustomAlert';
import CustomButton from '../../../../components/Button';
import TextInput from '../../../../components/TextInput';
// RESOURCES
import { emailValid } from '../../../../utils/expressions';
import { INTERNAL_MESSAGES } from '../../../../config/messageCatalog';
import { encrypt } from '../../../../utils/encrypt';
import { defaultEmailForm, IAlert } from '../utils';
// ASSETS
import { contactRequestAPI } from '../../../../api/modules/profile';
import { keyAPI } from '../../../../api/modules/key';
import { RootState } from '../../../../store';
import { useStyles } from '../styles';
import { useBitacora } from '../../../../hooks/useBitacora';
import { CONTACT_ACTIONS } from '../../../../config/actions-bitacora/Contact-actions';

interface IAltEmail {
	fetchContactData: () => void;
	cancel: () => void;
}

const ContactAltEmail = (props: IAltEmail) => {
	const { fetchContactData, cancel } = props;
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const [progress, setProgress] = useState(0);
	const [step, setStep] = useState(1);
	const [contact, setContact] = useState(defaultEmailForm);
	const [errors, setErrors] = useState(defaultEmailForm);
	const [loader, setLoader] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		message: '',
		color: 'success',
	});

	const { regBitacora } = useBitacora();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		let validation = emailValid(value);
		setContact({ ...contact, [name]: value });
		// if (name === 'email') {
		// 	setProgress(0);
		// }
		if (name === 'email') {
			setProgress(50);
		}

		if (!value) {
			if (name === 'email') setProgress(0);
			setErrors({ ...errors, [name]: 'Escribe tu correo electrónico' });
		} else if (!validation.valid) {
			setErrors({ ...errors, [name]: validation.msg });
		} else if (name === 'confirm_email' && value !== contact.email) {
			setErrors({
				...errors,
				confirm_email: INTERNAL_MESSAGES.MAIL_DOES_NOT_MATCH,
			});
		} else {
			setErrors(defaultEmailForm);
		}

		// if (name === 'email' && value && validation.valid) {
		// 	setProgress(50);
		// }
		setAlert({ message: '', color: 'success' });
	};

	const handleStep = (newStep: number, newProgress: number) => {
		setStep(newStep);
		setProgress(newProgress);
	};

	const handleConfirm = async () => {
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_pass_base64 = await encrypt(
				contact?.email?.toString() || '',
				key
			);
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				key
			);

			contactRequestAPI.updateAltEmail
				.post<{ nss: string; email: string }, any>('', {
					nss: USER_NSS_BASE64,
					email: email_pass_base64,
				})
				.then((res) => {
					if (res?.ok) {
						regBitacora({
							key: CONTACT_ACTIONS.UPDATE_EMAIL_ALTER,
							scheme: contactRequestAPI.updateAltEmail.scheme,
							valorNuevo: { email: contact?.email?.toString() },
						});
						handleStep(2, 100);
						handleClose();
						fetchContactData();
					}
				})
				.catch((e) => {
					setAlert({
						message:
							e && typeof e === 'string'
								? e
								: e.description ||
								  INTERNAL_MESSAGES.ERROR_MESSAGE,
						color: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'error',
			});
			setLoader(false);
		}
	};

	const handleResend = async () => {
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_pass_base64 = await encrypt(
				contact?.email?.toString() || '',
				key
			);
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				key
			);

			contactRequestAPI.resendUpdateAltEmail
				.post<{ nss: string; email: string }, any>('', {
					nss: USER_NSS_BASE64,
					email: email_pass_base64,
				})
				.then((res) => {
					if (res?.ok) {
						handleClose();
						handleStep(2, 100);
						fetchContactData();
					}
				})
				.catch((e) => {
					setAlert({
						message:
							e && typeof e === 'string'
								? e
								: e?.description ||
								  INTERNAL_MESSAGES.ERROR_MESSAGE,
						color: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'error',
			});
			setLoader(false);
		}
	};

	const handleClose = (): any => {
		setAlert({ message: '', color: 'success' });
	};

	return (
		<>
			<ModalLoading loading={loader} />

			<p className={classes.textoNormal}>
				Tu correo electrónico alterno se cambiará, este correo se
				utilizará para enviarte notificaciones sobre tu cuenta Infonavit
			</p>
			<p className={classes.stepLabel}>{step} de 2 completados</p>
			<LinearProgress
				variant="determinate"
				value={progress}
				color="info"
			/>
			{step === 1 && (
				<div className={classes.mailformContainer}>
					<TextInput
						isRequired
						label="Correo electrónico"
						id="altern_email"
						name="email"
						value={contact.email}
						onChange={handleChange}
						error={Boolean(errors.email)}
						helperText={errors.email}
					/>
					<TextInput
						isRequired
						label="Confirmar correo"
						id="confirm_altern_email"
						name="confirm_email"
						value={contact.confirm_email}
						onChange={handleChange}
						error={Boolean(errors.confirm_email)}
						helperText={errors.confirm_email}
					/>
				</div>
			)}
			{step === 2 && (
				<div className={classes.confirmationContainer}>
					<VerificationCode
						isEmail
						setCode={() => {
							handleStep(1, 0);
							setContact(defaultEmailForm);
							cancel();
						}}
						reSend={handleResend}
						isSixDigits={true}
					/>
				</div>
			)}
			<CustomAlert
				show={Boolean(alert.message)}
				severity={alert.color}
				message={alert.message}
				onClose={handleClose}
			/>
			<div className={classes.actionsContainer}>
				{step === 1 && (
					<CustomButton
						disabled={false}
						variant="outlined"
						onClick={() => {
							setErrors(defaultEmailForm);
							setAlert({ message: '', color: 'success' });
							setContact(defaultEmailForm);
							cancel();
						}}
						label="Cancelar"
					/>
				)}
				{step === 1 && (
					<CustomButton
						disabled={
							!Boolean(contact.email) ||
							contact.email !== contact.confirm_email
						}
						variant="solid"
						onClick={handleConfirm}
						label="Continuar"
					/>
				)}
			</div>
			<div className={classes.containerRequiredFields}>
				<Divider />
				<p className={classes.requiredFieldsLabel}>
					* Datos obligatorios
				</p>
			</div>
		</>
	);
};

export default ContactAltEmail;
