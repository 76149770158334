import API from '..';

export const contributionsAPI = {
	segments: new API('/aportaciones-ext-segmentos'),
	aportationMaxMin: new API('/aportaciones-ext-consulta-maxmin'),
	aportationSeparacion: new API(
		'/aportaciones-validar-marca-separacion-unificacion'
	),
	aportationMaxMinCoDi: new API('/aportaciones-ext-consulta-maxmin-codi'),
	aportationsStates: new API('/aportaciones-ext-estados'),
	consultAportations: new API('/aportaciones-ext-consulta-casos'),
	createAportation: new API('/aportaciones-ext-crear-caso'),
	createAportationCodi: new API('/aportaciones-ext-pago-codi'),
	createAportationCuenta: new API('/aportaciones-ext-pago-cuenta'),
};
