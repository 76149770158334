import { useState, useEffect } from 'react';
import { Paper, useMediaQuery, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import SelectCustom from '../../../components/SelectCustom';
import { DatePicker } from '@mui/lab';
import { UseStyle } from './Styles';

interface Props {
	setPage: (arg: number) => void;
}

interface IData {
	monto: string;
	referencia_pago: string;
	folio_pago: string;
	banco: string;
	fecha: Date | null;
}

const options = [{ value: 'asda', label: 'asdasd' }];

const FormData = ({ setPage }: Props) => {
	const classes = UseStyle();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [validacion, setValidacion] = useState(true);
	const [data, setData] = useState<IData>({
		monto: '',
		referencia_pago: '',
		folio_pago: '',
		banco: '',
		fecha: null,
	});

	const handleChange = ({ target }: any) => {
		const { name, value } = target;
		setData({ ...data, [name]: value });
	};

	useEffect(() => {
		if (
			data.banco !== '' &&
			data.monto !== '' &&
			data.folio_pago !== '' &&
			data.fecha
		) {
			setValidacion(false);
		} else {
			setValidacion(true);
		}
	}, [data]);

	return (
		<Paper style={{ marginTop: 30, padding: matches ? '20px 20px' : '20px 50px' }}>
			<p style={{ fontWeight: 700 }}>Escribe los siguientes datos:</p>

			<div style={{ width: matches ? '100%' : 800, margin: '20px auto' }}>
				<TextInput
					id="monto"
					name="monto"
					value={data.monto}
					onChange={(e) => !isNaN(Number(e.target.value)) && handleChange(e)}
					placeholder="0.00"
					label="Monto:"
					labelComponent={<span style={{ fontSize: 16 }}>Monto:</span>}
				/>

				<div className={classes.root}>
					<span>Fecha de pago:</span>
					<DatePicker
						value={data.fecha}
						onChange={(newValue: any) =>
							setData({ ...data, fecha: newValue })
						}
						maxDate={new Date()}
						renderInput={(params) => <TextField fullWidth {...params} />}
					/>
				</div>

				<TextInput
					id="referencia_pago"
					name="referencia_pago"
					value={data.referencia_pago}
					onChange={handleChange}
					label="Referencia de pago:"
					labelComponent={
						<span style={{ fontSize: 16 }}>Referencia de pago:</span>
					}
				/>

				<TextInput
					id="folio_pago"
					name="folio_pago"
					value={data.folio_pago}
					onChange={handleChange}
					label="Folio de autorización de pago:"
					labelComponent={
						<span style={{ fontSize: 16 }}>
							Folio de autorización de pago:
						</span>
					}
				/>

				<div style={{ marginTop: 5 }}>
					<SelectCustom
						label="Banco"
						name="banco"
						onChange={handleChange}
						value={data.banco}
						options={options}
						placeholder="Selecciona"
					/>
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: matches ? 'column-reverse' : 'initial',
					justifyContent: 'center',
					margin: '40px 0px',
				}}
			>
				<CustomButton
					styles={{
						width: matches ? 'auto' : 250,
						margin: '10px 10px',
						display: 'inline-flex',
					}}
					label={'Regresar'}
					variant="outlined"
					onClick={() => setPage(1)}
				/>

				<CustomButton
					disabled={validacion}
					styles={{
						width: matches ? 'auto' : 250,
						margin: '10px 10px',
						display: 'inline-flex',
					}}
					label={'Continuar'}
					variant="solid"
					onClick={() => setPage(3)}
				/>
			</div>
		</Paper>
	);
};

export default FormData;
