// DEPENDENCIES
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// COMPONENTS
import Tabs from '../../../components/Tabs';
import Layout from '../../../components/Layout';
// VIEWS
import ContactInformation from './ContactInformation';
import ContactAddress from './ContactAddress';
import ContactReference from './ContactReference';
import ValidateContact from './ValidateContact';
// RESOURCES
import { formatDate } from '../../../utils/dates';
// ASSETS
import { tabsOptions } from './utils';
import { HeaderList } from '../../../components/HeaderList';

const listItems = [
	'En este servicio puedes actualizar tus datos de contacto, domicilio actual y referencia. Asegúrate de escribir tus datos de manera correcta para facilitar futuros trámites.',
	'Recuerda que sólo puede haber un número celular y un correo electrónico vinculado a un Número de Seguridad Social (NSS).',
];

const ContactPage = () => {
	let params = useParams();
	const [selectedTab, setSelectedTab] = useState(0);
	const [validateDatos, setValidateDatos] = useState(false);

	useEffect(() => {
		setValidateDatos(params.validate ? true : false);
	}, [params])

	return (
		<Layout>
			<>
				<HeaderList
					title="Actualizar datos de contacto"
					date={formatDate('PP')}
					list={listItems}
				/>
				{validateDatos && <ValidateContact setValidateDatos={setValidateDatos} />}
				{!validateDatos && (
					<Tabs
						tabs={tabsOptions}
						onChangeTab={(index, tab) => setSelectedTab(index)}
						active={selectedTab}
						containerPosition={selectedTab + 1}
						cardsType
					>
						<div style={{ width: '100%' }}>
							{selectedTab === 0 && <ContactInformation />}
							{selectedTab === 1 && <ContactAddress />}
							{selectedTab === 2 && <ContactReference />}
						</div>
					</Tabs>
				)}
			</>
		</Layout>
	);
};

export default ContactPage;
