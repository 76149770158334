import Layout from '../../../../components/Layout';
import { Box, Grid, Paper } from '@mui/material';
import MapCrediterreno from './pages/MapCrediterreno';
import CreditConditions from './pages/CreditConditions';
import CreditBureau from './pages/CreditBureau';
import ProductsCrediterreno from './pages/ProductsCrediterreno';
import CreditSummary from './pages/CreditSummary';
import ViewPoints from '../../components/ViewPoints';
import FollowSteps from '../../components/FollowSteps';
import FlowCard from '../../components/FlowCard';
import { useController } from '../../hooks/useController';
import ResumeCard from '../../components/ResumeCard';
import { SelectionCredit } from '../../components/SelectionCredit/selectionCredit';
import { MIUC_PRODUCTS } from '../../constants/products';

const pageViews = (followStepPath: any) => [
  <MapCrediterreno />,
  <CreditConditions />,
  <CreditBureau />,
  <ProductsCrediterreno />,
  <CreditSummary />,
  <FollowSteps guideLink={followStepPath} />,
];

const CrediterrenoMIUC = () => {
  const { dataProcessController, calcProgress, saveData, nextStep } =
    useController();
  const step = dataProcessController?.processData?.step;
  const showViewPoints = [0, 1, 2, 3].includes(step);

  const onSelect = async (option: string) => {
    let credit = '';

    if (option === MIUC_PRODUCTS.CREDITERRENO) {
      credit = MIUC_PRODUCTS.CREDITERRENO;
    } else {
      credit = MIUC_PRODUCTS.SUELO_MAS_CONSTRUCCION;
    }
    saveData(
      {
        processInfo: {
          credit: credit,
        },
      },
      true
    );
    nextStep();
  };

  return (
    <Layout>
      <Grid container columns={12} spacing={2}>
        <Grid item xs={12} md={8}>
          {pageViews(
            dataProcessController.processInfo?.credit ===
              MIUC_PRODUCTS.CREDITERRENO
              ? require('../../../../assets/files/guia-pasos-a-seguir/GPSTerreno.pdf')
              : require('../../../../assets/files/guia-pasos-a-seguir/Guia_de_pasos_a_seguir_S+C_091123_vf.pdf')
          )[step] ?? (
              <Paper>
                <SelectionCredit
                  ignoreDestinies
                  onSelectCredit={onSelect}
                />
              </Paper>
            )}
        </Grid>
        <Grid item xs={12} md={4}>
          {showViewPoints && (
            <Box sx={{ textAlign: 'center' }} mb={2}>
              <ViewPoints showPoints={step >= 1} />
            </Box>
          )}
          {step == 5 && (
            <Box sx={{ textAlign: 'center' }} mb={2}>
              <ResumeCard />
            </Box>
          )}
          {step >= 1 && (
            <Box>
              <FlowCard
                title="Tu selección"
                flow={
                  dataProcessController.processInfo?.selection
                }
                progress={calcProgress()}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default CrediterrenoMIUC;
