import * as React from 'react';
import Box from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { MenuElements } from '../Navbar/utils';
import { useNavigate } from 'react-router-dom';

type Props = {
	open: boolean;
	anchorEl: null | HTMLElement;
	subMenuItems: MenuElements[];
	mainItem: null | string;
	setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
};

const FloatMenu = ({ open, setAnchorEl, anchorEl, subMenuItems, mainItem }: Props) => {
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDir = (path: string) => {
		if (mainItem !== null) {
			navigate(`/${mainItem}/${path}`);
		} else {
			navigate(`/${path}`);
		}
		setAnchorEl(null);
	};

	const navigate = useNavigate();

	return (
		<Box style={{ position: 'absolute', background: 'transparent !important' }}>
			<Menu
				id="fade-menu"
				MenuListProps={{
					'aria-labelledby': 'fade-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
			>
				{subMenuItems.map(
					(element) =>
						element.name.length > 0 && (
							<MenuItem
								sx={{ fontSize: 14 }}
								key={element.id}
								onClick={
									() => handleDir(element.path)
									// mainItem !== null
									// 	? navigate(`/${mainItem}/${element.path}`)
									// 	: navigate(`/${element.path}`)
								}
							>
								{element.name}
							</MenuItem>
						)
				)}
			</Menu>
		</Box>
	);
};

export default FloatMenu;
