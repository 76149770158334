/* eslint-disable react-hooks/exhaustive-deps */
import { CheckCircleOutlined } from '@mui/icons-material';
import { IconButton, Link, Paper, useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';
import { useStyles } from './styles';
import InfoIcon from '@mui/icons-material/Info';
import { apiDeedsDelivery } from '../../api/modules/Deeds';
import BaseModal from '../../components/ModalConfirmation';
import { styled } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import { useTheme } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';
import { IAlert } from '../../interfaces/alert';

interface ICaso {
	or_description?: string;
	or_fecha?: string;
	or_object_id?: string;
	or_status?: string;
	or_status_reason?: string;
	or_fecha_stat?: string;
	or_status_message?: string;
	descripcion_estatus?: string;
}

interface Props {
	setPage: (data: number) => void;
	documentos: any;
	setDocumentos: (arg: any) => void;
	caso: ICaso;
}

const tooltips = [
	{ clave: 'Z202', text: 'Instrumento Privado o Público.' },
	{
		clave: 'Z203',
		text: 'Documento expedido por el Infonavit que autoriza el crédito.',
	},
	{
		clave: 'Z204',
		text: 'Documento que acredita la entrega de la vivienda.',
	},
	{
		clave: 'Z205',
		text: 'Documento para el pago de impuesto por la Tesorería Municipal.',
	},
];

const DocumentList = ({ setPage, documentos, setDocumentos, caso }: Props) => {
	const classes = useStyles();
	const [modal, setModal] = useState(false);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	useEffect(() => {
		const req = async () => {
			try {
				setLoading(true);
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const caso_base64 = await encrypt(caso.or_object_id || '', key);
				const { result } = await apiDeedsDelivery.getListarDocs(
					caso_base64
				);
				setDocumentos(result);
			} catch (error) {
			} finally {
				setLoading(false);
			}
		};
		req();
	}, []);

	const updateFileWithou = async () => {
		try {
			setLoading(true);
			setModal(false);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const caso_base64 = await encrypt(caso.or_object_id || '', key);
			const { result } = await apiDeedsDelivery.getUploadWhitouFile(caso_base64);
			if (result?.codigo_respuesta) {
				setAlert({
					show: true,
					message: result.descripcion || '',
					severity: 'error',
				});
			} else {
				setPage(4);
			}
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.common.white,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: 'black',
			boxShadow: theme.shadows[1],
		},
	}));

	return (
		<Paper>
			<ModalLoading loading={loading} />
			<div
				style={{
					paddingLeft: matches ? 20 : 120,
					paddingRight: matches ? 15 : 160,
					paddingTop: matches ? 10 : 40,
					paddingBottom: matches ? 15 : 40,
				}}
			>
				<h3
					style={{
						fontSize: 18,
					}}
				>
					Si tienes alguno de los siguientes documentos, adjúntalo: de
					lo contrario continua con tu trámite.
				</h3>

				<CustomAlert
					show={alert.show}
					message={alert.message}
					severity="warning"
					onClose={() =>
						setAlert({
							show: false,
							message: '',
							severity: 'warning',
						})
					}
				/>

				{documentos.map((element: any, i: number) => (
					<div
						key={i}
						style={{
							display: 'flex',
							alignItems: 'center',
							paddingBottom: matches ? 1 : 10,
						}}
					>
						<CheckCircleOutlined
							color="info"
							style={{ marginRight: 5, width: 22 }}
						/>
						<label className={classes.label}>
							{element.nombre}
						</label>

						<div>
							<BootstrapTooltip
								title={
									tooltips.find(
										(too) => too.clave === element.clave
									)?.text || ''
								}
								placement={matches ? 'bottom' : 'right'}
								id={'' + i}
							>
								<IconButton
									aria-label="add an alarm"
									size="small"
									component="span"
								>
									<InfoIcon
										fontSize="small"
										sx={{ ml: 0.5, color: '#D1001F' }}
									/>
								</IconButton>
							</BootstrapTooltip>
						</div>
					</div>
				))}

				<div
					style={{
						display: 'flex',
						justifyContent: matches ? 'center' : 'space-between',
						flexDirection: matches ? 'column-reverse' : 'row',
						alignItems: 'center',
						paddingRight: 0,
						paddingTop: matches ? 25 : 60,
						paddingBottom: 20,
					}}
				>
					<Link
						href="#Regresar"
						underline="none"
						onClick={() => {
							setPage(2);
						}}
						style={{
							width: matches ? 300 : 250,
							textAlign: 'center',
							fontWeight: 'bold',
						}}
					>
						Regresar
					</Link>
					<CustomButton
						label="Continuar sin documentos"
						onClick={() => updateFileWithou()}
						variant="outlined"
						styles={{
							width: matches ? 300 : 250,
							height: 40,
							marginTop: matches ? 20 : 0,
							marginBottom: matches ? 10 : 0,
						}}
					/>
					<CustomButton
						label="Adjuntar documentos"
						onClick={() => {
							setPage(5);
						}}
						variant="solid"
						styles={{ width: matches ? 300 : 250, height: 40 }}
					/>
				</div>
			</div>
			<BaseModal
				open={modal}
				title="S"
				onConfirm={() => updateFileWithou()}
				onClose={() => setModal(false)}
				cancelButton={true}
				confirmLabel="Sí"
				cancelLabel="No"
				width="sm"
				children={
					<div
						style={{
							paddingLeft: 40,
							paddingRight: 40,
							paddingTop: 30,
							paddingBottom: 10,
						}}
					>
						<h3
							style={{
								color: '#293990',
								fontSize: 26,
								display: 'flex',
								textAlign: 'center',
								justifyContent: 'center',
								margin: 0,
							}}
						>
							¿Estás seguro de que adjuntaste todos tus
							documentos?
						</h3>
					</div>
				}
			/>
		</Paper>
	);
};

export default DocumentList;
