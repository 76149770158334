import * as React from 'react';

// MUI
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

// ASSETS
import arrowRight from '../../../assets/img/arrow-right.png';

type IProps = {
	style?: React.CSSProperties;
	link?: string;
	target?: '_blank' | '_parent' | '_self' | '_top';
	labelText: string;
	hover: boolean;
    onclick?: () => void;
};

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		position: 'absolute',
		right: -7,
		top: '35%',
	},
	button: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.dark,
		borderRadius: 60,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
		overflow: 'hidden',
		width: 'auto',
		height: 18,
		// maxWidth: 16,
		boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
		transition: 'max-width 0.5s',
		// '&:hover': {
		// 	maxWidth: 240,
		// },
	},
	icon: {
		padding: 2,
		height: 15,
	},
	title: {
		whiteSpace: 'nowrap',
		color: theme.palette.primary.main,
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
}));

const ViewMoreButton = ({ link, style, target, labelText, hover, onclick }: IProps) => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Link
				href={link}
				underline="none"
				target={target}
				className={classes.button}
				style={{ maxWidth: hover ? '240px' : '16px' }}
                onClick={onclick}
			>
				<Typography sx={{ fontSize: 12 }} className={classes.title}>
					{labelText}
				</Typography>
				<img src={arrowRight} alt="Ver más" className={classes.icon} />
			</Link>
		</Box>
	);
};

export default ViewMoreButton;
