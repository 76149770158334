import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useController } from '../../../hooks/useController';
import { useEffect, useState } from 'react';
import { IAlert } from '../../../../../interfaces/alert';
import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { isNumberFloat } from '../../../../../utils/expressions';
import { formatDate } from '../../../../../utils/dates';
import { rowsTitleMVIT } from '../../../utils/selectionResume.utils';
import { apiMIUC } from '../../../../../api/modules/MIUC';
import { isEmpty } from '../../../../../utils/validators';
import { IProductMontoMejoraTuHogar } from '../../../../../interfaces/creditRequest';
import { MIUC_PRODUCTS } from '../../../constants/products';
import { creditTypes } from '../../../../CreditRequest/MIUC/utils';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useStyles } from '../styles';
import { CODES_RESPONSE } from '../../../../../config/miucConstants';
import { useTrazabilidad } from '../../../hooks/useTrazabilidad';

export const useMejorarViviendaTable = () => {
	const {
		returnStep,
		addSelection,
		nextStep,
		saveData,
		getProgressStep,
		dataProcessController,
	} = useController();
	const { createTrazabilidad} = useTrazabilidad();
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [alertInput, setAlertInput] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const { user } = useSelector((state: RootState) => state.session);

	const { active, total } = getProgressStep();
	const newStep = {
		title: 'Estas son las opciones de financiamiento que tenemos para ti',
		subtitle:
			'Revisa cada opción, compara entre ellas y elige la que más te convenga',
		active,
		total,
	};
	const [selectProduct, setSelectProduct] = useState('');
	const [products, setProducts] = useState<any[]>([]);

	const disableButton = isEmpty(selectProduct);

	const [mejoravitItems, setMejoravitItems] = useState({
		porVivienda: 0,
		porManoObra: 0,
		valorMejora: '',
		cleanBtn: false,
	});

	const [productsSelect, setProductsSelect] = useState<any[]>([]);

	const [repara, setRepara] = useState(1);

	const [renueva, setRenueva] = useState(1);

	const manoObraOptions: {
		label: string;
		value: number;
	}[] = [
		{
			label: 'Si (20%)',
			value: 20,
		},
		{
			label: 'No (0%)',
			value: 0,
		},
	];

	const regularizarOptions: {
		label: string;
		value: number;
	}[] = [
		{
			label: 'Si (30%)',
			value: 30,
		},
		{
			label: 'No (0%)',
			value: 0,
		},
	];

	const next = () => {
		const productsSelect = products.map((values: any) => {
			const productos = {
				...values,
        producto: values.producto || '',
        mmcMon: values.montoCredito.toString() || '',
        desTot: values.pagoMensual || '',
        tasInt: values.tasa || '',
        desCre: values.pagoMensual || '',
        fpp: '0.0',
        cat: values.cat || '',
        segDan: '0.0',
        // monEco: values.montoCreRegVivienda.toString(),
			};
			return productos;
		});

    const plazo = selectProduct === 'MREP' ? repara : renueva;

    const product = productsSelect.find((prod) => prod.producto === selectProduct && prod.plazo === plazo.toString())

    handleTrazabilidad();
		addSelection({
			title: 'Producto Seleccionado:',
			description: `${creditTypes[selectProduct]?.title}`,
		});
		saveData({
			processInfo: {
				tipoMVIT: selectProduct,
        productSelect: product,
			},
      processData: {
        active: getProgressStep().active + 1,
      }
		});
		nextStep();
	};

	const handleTrazabilidad = async () => {
		const selectedProduct = products.find((prod) => prod.producto === selectProduct);
		createTrazabilidad({
			credit: dataProcessController.processInfo?.credit,
			subtipoTrazabilidad: selectedProduct?.subtipoTrazabilidad
		});
	};

	useEffect(() => {
		getProducts();
	}, []);

	const handleSetDataMejora = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const { value } = event.target;
		if (isNumberFloat(value)) {
			setMejoravitItems({
				...mejoravitItems,
				valorMejora: event.target.value,
			});
		}
	};

	const getProducts = async () => {
		try {
			setLoading(true);
			hideMontos();
			const dataSend = {
				nss: user?.nss || '',
				buro: '1',
			};
			const ENCODED_DATA = await encodeAllJSONData(
				{ data: JSON.stringify(dataSend) },
				user?.k || ''
			);
			const result = await apiMIUC.consultaMejoraVit(ENCODED_DATA);

			if (result.code !== CODES_RESPONSE.CORRECTO) {
				setAlert({
					show: true,
					message: result.message || 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					severity: 'error'
				});

				return;
			}

			const products = result.data.productos;
			const newProducts = products.map((products: any) => {
				const productos: IProductMontoMejoraTuHogar = {
					...products,
					montoCreComercio: 0,
					montoCreManoObra: 0,
					montoCreRegVivienda: 0,
					pagoMensualCopia: products.pagoMensual,
					montoCreditoCopia: products.montoCredito,
					subtipoTrazabilidad: result.data.subtipoTrazabilidad
				};
				return {
					...productos,
				};
			});
			setProducts(newProducts);
		} catch (error: any) {
			setAlert({
				show: true,
				message:
					error.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				severity: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const getFormatedPlazo = (plazo: number) => {
		if (plazo > 1){
		return `${plazo} años`
		}else{
		return `${plazo} año`
		}
	}

	const reparaOptions: { label: string; value: number }[] = products
		.filter((producto) => producto.producto === 'MREP')
		.map((producto) => ({
			label: getFormatedPlazo(producto.plazo),
			value: parseInt(producto.plazo),
		}));

	const handleChangeRepara = (event: any) => {
		setRepara(event.target.value);
	};

	const renuevaOptions: { label: string; value: number }[] = products
		.filter((producto) => producto.producto === 'MREN')
		.map((producto) => ({
			label: getFormatedPlazo(producto.plazo),
			value: parseInt(producto.plazo),
		}));

	const handleChangeRenueva = (event: any) => {
		setRenueva(event.target.value);
	};

	useEffect(() => {
		const filteredMREP = products.filter(
			(product) =>
				product.producto === 'MREP' &&
				parseInt(product.plazo) === repara
		);
		const filteredMREN = products.filter(
			(product) =>
				product.producto === 'MREN' &&
				parseInt(product.plazo) === renueva
		);

		const filteredProducts = [...filteredMREP, ...filteredMREN];
		setProductsSelect(filteredProducts);
		// console.log(rawProducts);
	}, [products, repara, renueva]);

	const handleCalculate = () => {
		const { porManoObra, porVivienda, valorMejora } = mejoravitItems;

		const valorMejoraNumero = Number(valorMejora.replace(',', '') || '0');

		if (!valorMejoraNumero) return;

		const newProducts = products.map((product: any) => {
			let valMont = product.montoCreditoCopia;
			if (valorMejoraNumero > 0) {
				const montCredito = Number(
					product.montoCreditoCopia || '0'
				);
				valMont =
					valorMejoraNumero >= montCredito
						? montCredito
						: valorMejoraNumero;
				product.montoCredito = valMont.toString();
				product.pagoMensual = (
					Number(product.factorPago || '0') * valMont
				).toString();
			}
			const totCreManoObra =
				Number(product.montoCredito || '0') * (porManoObra / 100);

			const totCreRegVivienda =
				porVivienda > 0
					? Number(product.montoCredito || '0') *
					  (porVivienda / 100)
					: 0;

			const totCreComercio =
				Number(product.montoCredito || '0') -
				(totCreManoObra + totCreRegVivienda);

			const producto: IProductMontoMejoraTuHogar = {
				...product,
				montoCredito: valMont,
				montoCreComercio: totCreComercio,
				montoCreManoObra: totCreManoObra,
				montoCreRegVivienda: totCreRegVivienda,
        		contarias: valMont,
			};
      
			return producto
		});
	
	if (porVivienda === 30 || porManoObra === 20) {
		showMontos();
	} else {
		hideMontos();
	}
    setProducts(newProducts)
		setMejoravitItems({
			...mejoravitItems,
			cleanBtn: true,
		});
	};

	const handleClearSearch = () => {
		const newProducts = products.map((product: any) => {
			const producto: IProductMontoMejoraTuHogar = {
				...product,
				pagoMensual: product.pagoMensualCopia,
				montoCredito: product.montoCreditoCopia,
				montoCreComercio: 0,
				montoCreManoObra: 0,
				montoCreRegVivienda: 0,
				contarias: product.montoCreditoCopia,
			};

			return producto
		});
		setProducts(newProducts);
		hideMontos();
		setMejoravitItems({
			...mejoravitItems,
			cleanBtn: false,
		});
	};

	const [rowsTitle, setRowsTitle] = useState([...rowsTitleMVIT]);

	const handleChangeRegularizar = (event: any) => {
		setRowsTitle(rowsTitleMVIT);

		setMejoravitItems({
			...mejoravitItems,
			porVivienda: event.target.value,
			// porManoObra: 0
		});
	};

	const handleChangeManoObra = (event: any) => {
		setMejoravitItems({
			...mejoravitItems,
			porManoObra: event.target.value,
		});
	};

	const showMontos = () => {
		const comercio = rowsTitle.findIndex(
			(obj) => obj.key === 'montoCreComercio'
		);
		const manoObra = rowsTitle.findIndex(
			(obj) => obj.key === 'montoCreManoObra'
		);
		const vivienda = rowsTitle.findIndex(
			(obj) => obj.key === 'montoCreRegVivienda'
		);
    const rowCopy = [...rowsTitle];
		if (comercio !== -1) {
			rowCopy[comercio].hideRow = false;
		}
		if (manoObra !== -1) {
			rowCopy[manoObra].hideRow = false;
		}
		if (vivienda !== -1) {
			rowCopy[vivienda].hideRow = false;
		}
    	setRowsTitle(rowCopy);
	};

	const hideMontos = () => {
		const comercio = rowsTitle.findIndex(
			(obj) => obj.key === 'montoCreComercio'
		);
		const manoObra = rowsTitle.findIndex(
			(obj) => obj.key === 'montoCreManoObra'
		);
		const vivienda = rowsTitle.findIndex(
			(obj) => obj.key === 'montoCreRegVivienda'
		);
    const rowCopy = [...rowsTitle];
		if (comercio !== -1) {
			rowCopy[comercio].hideRow = true;
		}
		if (manoObra !== -1) {
			rowCopy[manoObra].hideRow = true;
		}
		if (vivienda !== -1) {
			rowCopy[vivienda].hideRow = true;
		}
    	setRowsTitle(rowCopy);
	};

	const plazoOptions = [
		<FormControl fullWidth>
			<span>Plazo Mejoravit Repara:</span>
			<Select
				id='SelectRepara'
				value={repara}
				onChange={(e) => (handleChangeRepara(e))}
				displayEmpty={true}
				className={classes.cardSelect}
			>
				{reparaOptions.map((element, index) => (
					<MenuItem key={index} value={element.value}>
						{element.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>,
		<FormControl fullWidth>
			<span>Plazo Mejoravit Renueva:</span>
			<Select
				id='SelectRenueva'
				value={renueva}
				onChange={(e) => (handleChangeRenueva(e))}
				displayEmpty={true}
				className={classes.cardSelect}
			>
				{renuevaOptions.map((element, index) => (
					<MenuItem key={index} value={element.value}>
						{element.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>,
	];

	return {
		loading,
		next,
		returnStep,
		setSelectProduct,
		selectProduct,
		products,
		newStep,
		alertInput,
		handleCalculate,
		handleClearSearch,
		mejoravitItems,
		setMejoravitItems,
		regularizarOptions,
		handleChangeRegularizar,
		manoObraOptions,
		handleChangeManoObra,
		handleSetDataMejora,
		repara,
		reparaOptions,
		handleChangeRepara,
		renueva,
		renuevaOptions,
		handleChangeRenueva,
		productsSelect,
		disableButton,
		plazoOptions,
		rowsTitle,
		alert
	};
};
