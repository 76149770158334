interface Props {
	type: 'RM' | 'AT' | 'CN';
}

const Build = ({ type }: Props) => {
	return (
		<>
			{type === 'RM' && (
				<>
					<p>
						Algunos ejemplos de reparaciones menores son
						impermeabilizar, pintar, cambiar muebles de cocina o
						baño, cambiar azulejos, cambiar el piso, entre otros.
					</p>
					<p>
						Las mejoras que realices no deben afectar la estructura
						de la vivienda, lo cual quiere decir que no deberás
						tirar o construir muros, aumentar la superficie, colar
						una losa, etc.
					</p>
					<p>
						Tú recibes el dinero, eres responsable de los trabajos.
					</p>
					<p>
						El monto de crédito depende del plazo que elijas, de tu
						salario y lo que tengas ahorrado en tu Subcuenta de
						Vivienda.
					</p>
				</>
			)}
			{type === 'AT' && (
				<>
					<p>
						Puedes construir, mejorar, ampliar o remodelar tu
						vivienda.
					</p>
					<p>
						Estos trabajos afectan la estructura de la vivienda, se
						puede ampliar la superficie, realizar demoliciones,
						colar losas, entre otros.
					</p>
					<p>
						El Asesor Técnico que elijas, realizará tu proyecto, te
						asesorará durante la obra y registrará tanto el proyecto
						como los avances.
					</p>
					<p>
						Tú recibes el dinero, administras la obra, compras los
						materiales y decides quién la realiza.
					</p>
					<p>
						El monto de crédito depende del plazo que elijas, de tu
						salario y lo que tengas ahorrado en tu Subcuenta de
						Vivienda.
					</p>
				</>
			)}
			{type === 'CN' && (
				<>
					<p>
						Puedes construir, mejorar, ampliar o remodelar tu
						vivienda.
					</p>
					<p>
						Estos trabajos afectan la estructura de la vivienda, se
						puede ampliar la superficie, realizar demoliciones,
						colar losas, entre otros.
					</p>
					<p>
						Tú seleccionas una Constructora que diseñe tu proyecto,
						lo construya y lo entregue terminado.
					</p>
					<p>
						Tú autorizas que la Constructora reciba y administre el
						monto de tu crédito para que realice y te entregue la
						obra.
					</p>
					<p>
						El monto de crédito depende del plazo que elijas, de tu
						salario y lo que tengas ahorrado en tu Subcuenta de
						Vivienda.
					</p>
				</>
			)}
		</>
	);
};

export default Build;
