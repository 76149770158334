import { useEffect, useState } from 'react';
import { Box, Divider, Paper, Grid } from '@mui/material';
import styles from '../styles/styles.module.css';
import stylesMap from './styles/map.module.css';
import CustomButton from '../../../../../components/Button';
import CustomAlert from '../../../../../components/CustomAlert';
import SelectCustom from '../../../../../components/SelectCustom';
import mapa from '../../../../../assets/img/mapa-crediterreno.png';
import Header from '../../../components/Header';
import ModalLoading from '../../../../../components/ModalLoading';
import { INTERNAL_MESSAGES } from '../../../../../config/messageCatalog';
import { useAlertCustom } from '../../../hooks/useAlertCustom';
import { MIUC_PRODUCTS_COMPONENT } from '../../../utils/home.utils';
import { MIUC_PRODUCTS } from '../../../constants/products';
import { useProfile } from '../../../hooks/useProfile';
import { useNavigation } from '../../../hooks/useNavigation';
import { useNavigate } from 'react-router-dom';
import ErrnoPage from '../../../../CreditRequest/ErrnoPage';
import { RETURN_CODE } from '../../../constants/returnCode';
import { apiMIUC } from '../../../../../api/modules/MIUC';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

interface IOptions {
	value: string;
	label: string;
}

const MapCrediterreno = () => {
	const navigate = useNavigate();
	const { getMIUCProfile, errorMesage } = useProfile({
		initProcess: false,
	});
	const {
		addSelection,
		nextStep,
		saveData,
		dataProcessController,
		returnStep,
	} = useNavigation();
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState<IOptions[]>([]);
	const [selectOption, setSelectOption] = useState('');
	const { setAlertClean, setAlertCustom, AlertInput } = useAlertCustom();
	const { user } = useSelector((state: RootState) => state.session);

	useEffect(() => {
		const req = async () => {
			try {
				setLoading(true);
				setAlertClean();
				const result = await apiMIUC.consultarEstados();
				if (result.data && result.data?.estados?.length > 0) {
					const response = result.data?.estados?.map(
						(element: { clave: string; descripcion: string }) => {
							return {
								value: element.clave,
								label: element.descripcion,
							};
						}
					);
					setOptions(response);
				}
			} catch (error) {
				setAlertCustom({
					message: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'warning',
				});
			} finally {
				setLoading(false);
			}
		};

		req();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getNameEstadoLowerCase = (arg: string) => {
		let argReturn = arg[0].toUpperCase();
		argReturn += arg.slice(1, arg.length).toLowerCase();
		return argReturn;
	};

	const nextProccess = async () => {
		const credit = dataProcessController.processInfo?.credit;
		const productEle = MIUC_PRODUCTS_COMPONENT.find(
			(e) => e.code === MIUC_PRODUCTS.CREDITERRENO
		);
		if (productEle) {
			getMIUCProfile({
				code: productEle.code,
				model: productEle.model,
				name: productEle.name,
				line: productEle.line,
				estadoMunicipio: selectOption,
			}).then((result) => {
				if (result?.code === RETURN_CODE.CORRECT) {
					const estadoSeleccionado = options.find(
						(e) => e.value === selectOption
					)?.label;
					addSelection({
						title: 'Crédito seleccionado para:',
						description:
							dataProcessController.processInfo?.credit === 'L8'
								? 'Comprar terreno'
								: 'Crediterreno Para Mi hogar',
					});
					addSelection({
						title: 'Selección de estado:',
						description: getNameEstadoLowerCase(
							estadoSeleccionado ?? ''
						),
					});
					saveData({
						processData: {
							active: 1,
							total: 4,
						},
						processInfo: {
							credit,
						},
					});
					nextStep();
				}
			});
		}
	};

	return (
		<>
			{errorMesage === '' && (
				<Paper>
					<Box className={styles.container}>
						<Header title={`¡Bienvenid${user?.gender === 'H' ? 'o' : 'a'} ${user?.nombre || user?.nombres}!`} />
						<main>
							<p className={styles.subTitle}>
								Conoce la demanda en compra de terreno
							</p>
							<section className={stylesMap.container}>
								<CustomAlert
									message={
										'La suma de tus puntos pueden variar dependiendo de tu selección de estado en donde se encuentre el terreno que deseas adquirir.'
									}
									show
									severity="warning"
								/>
								<Grid
									container
									columns={12}
									className={stylesMap.mapContainer}
								>
									<Grid item xs={12} md={7}>
										<Box className={stylesMap.mapShow}>
											<Box className={stylesMap.infoMap}>
												<Box>
													<span
														className={
															stylesMap.roundColorOne
														}
													></span>
													Alta
												</Box>
												<Box>
													<span
														className={
															stylesMap.roundColorTwo
														}
													></span>
													Baja
												</Box>
											</Box>
											<img alt="mapa" src={mapa} />
										</Box>
									</Grid>
									<Grid item xs={12} md={5}>
										<SelectCustom
											name="entidad"
											options={options}
											placeholder="Selecciona"
											value={selectOption}
											onChange={(e: any) =>
												setSelectOption(e.target.value)
											}
											label="Selecciona el estado en el que quieras comprar el terreno*"
										/>
									</Grid>
								</Grid>

								<Divider />

								<AlertInput />

								<section className={styles.buttonActions}>
									<CustomButton
										label={'Regresar'}
										variant="outlined"
										onClick={() => navigate(-1)}
									// onClick={returnStep}
									/>
									<CustomButton
										label={'Continuar'}
										disabled={
											selectOption !== '' ? false : true
										}
										onClick={nextProccess}
									/>
								</section>
							</section>
							<Divider />
							<p className={styles.parrafoObligatorio}>
								* Dato obligatorio
							</p>
						</main>
					</Box>
					<ModalLoading loading={loading} />
				</Paper>
			)}
			{errorMesage !== '' && (
				<Paper>
					<ErrnoPage msgString={errorMesage} />
				</Paper>
			)}
		</>
	);
};

export default MapCrediterreno;
