import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		BoxContainer: {
			marginTop: '20px',
			marginBottom: '20px',
			marginLeft: '20px',
			marginRight: '20px',
		},
		BoxSubContainer: {
			marginTop: '20px',
			marginBottom: '20px',
			marginLeft: '20px',
			marginRight: '20px',
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'column',
			alignItems: 'center',
		},
		BoxSelectAnioFiscal: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'column',
			width: '350px',
			height: 'auto',

			[theme.breakpoints.down('md')]: {
				width: '100%',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		BoxTitle: {
			marginTop: '20px',
			width: '100%',
		},
		divider: {
			width: '100%',
			height: 1.5,
			marginTop: 15,
			marginBottom: 15,
			backgroundColor: '#E1E3E6',
		},
		BoxTextContainer: {
			// width: '100%',
			// paddingLeft: '70px',
			// paddingRight: '70px',
			marginBottom: '40px',

			// [theme.breakpoints.down('md')]: {
			// 	paddingLeft: '0px',
			// 	paddingRight: '0px',
			// },
			// [theme.breakpoints.down('sm')]: {
			// 	paddingLeft: '0px',
			// 	paddingRight: '0px',
			// },
			marginTop: '25px',
			width: '100%',
			paddingLeft: '100px',
			paddingRight: '100px',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '0',
				paddingRight: '0',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '0',
				paddingRight: '0',
			},
		},
		title: {
			fontFamily: 'Geomanist, Medium',
			fontSize: '18px',
			fontWeight: 'bold',
		},
		parrafoBold: {
			fontFamily: 'Geomanist, Regular',
			fontSize: '18px',
			lineHeight: '1.5',
			fontWeight: '500',
		},
		parrafo: {
			fontFamily: 'Geomanist, Regular',
			fontSize: '16px',
			lineHeight: '1.5',
		},
		parrafoSangria: {
			fontFamily: 'Geomanist, Regular',
			paddingLeft: '50px',
			fontSize: '16px',
			lineHeight: '1.5',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '20px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '20px',
			},
		},
		links: {
			fontSize: '16px',
			color: 'red',
			'&:hover': {
				cursor: 'pointer',
			},
		},
		links2: {
			fontFamily: 'Geomanist, Regular',
			fontSize: '16px',
			lineHeight: '1.5',
			color: 'red',
			cursor: 'pointer',
			textDecorationLine: 'underline',
		},
		links3: {
			fontFamily: 'Geomanist, Regular',
			fontSize: '16px',
			lineHeight: '1.5',
			color: 'red',
			cursor: 'pointer',
		},
		BoxInfoTable: {
			marginTop: '30px',
			width: '100%',
			paddingLeft: '100px',
			paddingRight: '100px',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '0',
				paddingRight: '0',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '0',
				paddingRight: '0',
			},
		},
		iconoDescarga: {
			width: '15px',
			height: 'auto',
		},
		lineText: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'start',
			alignItems: 'center',
			height: 'auto',
		},
		lineTextTop: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'start',
			alignItems: 'start',
			height: 'auto',
		},
	})
);
