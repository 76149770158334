import { Link } from '@mui/material';
const ETC = () => {
	return (
		<>
			<p>
				Es un monto adicional de crédito que se te otorga, si compras
				vivienda a través de un Crédito Infonavit o Infonavit Total con
				el que podrás reparar, ampliar o equipar, es decir, Mejorar tu
				vivienda.
			</p>
			<p>
				Puedes solicitar Equipa tu casa, si el monto máximo de crédito
				que se te otorga el Infonavit es mayor a lo que te costará la
				vivienda que deseas comprar, es decir, si el monto de crédito
				otorgado después de cubrir el costo de la vivienda presenta un
				monto disponible. Podrás usar este monto disponible para mejorar
				tu vivienda, con esto optimizarás el uso de tu crédito.
			</p>
			<p>
				El importe que se te podrá otorgar para Equipa tu casa, deberá
				cumplir con las reglas vigentes para este complemento.
				<Link href={'https://infonavitfacil.mx/'} target="_blank" underline="none">
					{' '}Si quieres ingresa a Infonavit fácil.
				</Link>
			</p>
			<p>
				Debes solicitar este monto adicional de crédito al inicio de tu
				trámite, es decir, dentro de tu solicitud de crédito ya que una
				vez cerrado este trámite no lo podrás solicitar.
			</p>
			<p>
				Después de firmar tus escrituras, deberás descargar en tu celular,
				la aplicación que se te indique para habilitar la tarjeta electrónica
				en la que se depositarán los recursos para que la utilices en los
				establecimientos participantes
				<Link href={'https://portalmx.infonavit.org.mx/wps/portal/infonavitmx/mx2/derechohabientes/quiero_credito/quiero_remodelar/comercios/!ut/p/z1/jZDPDoIwDIefxSdYO8f-HAkEmWSTxQC6i-Fklih6MD6_JISDJg56a_P92vQjnpyIH_p3uPav8Bj629ifPb9wnQPKDGhZCgWcFY6ZFtEYJN0E7ADKjKGVrUzBWWVpxbd0n3Li1-SVyhlWEmiNFsC1DnOdC0iArcvDn0phXT4C-Pj6jvjpxGzgIIoMXJHwo4F6nPFf4EuRlkijwOhQLAHziZjFpT-e96ZpThB00JsPJ3VRmg!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/'} target="_blank">
					{' ('}consulta la lista en el Portal Infonavit{') '}
				</Link>
				y el importe para el pago de mano de obra (en su caso), se te
				depositara a la cuenta que indicaste mediante tu estado de cuenta.
			</p>
		</>
	);
};

export default ETC;
