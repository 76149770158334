import { createContext, Dispatch, SetStateAction } from 'react';

export interface FormCancellation {
	nss: string;
	numero_credito: string;
	numero_instrumento: string;
	libro: string;
	calle: string;
	colonia: string;
	ciudad: string;
	seccion: string;
	numero_escritura: string;
	fecha_elaboracion: string;
	numero_notario: string;
	nombre_notario: string;
	correo_notario: string;
	ciudad_notario: string;
	folio_real: string;
	nombre_notario_origen: string;
	numero_notario_origen: string;
	ciudad_notario_origen: string;
	tipo_carta: string;
	fecha_liquidacion: string;
	clase_operacion: string;
	tipificacion: string;
	estado_credito: string;
	noCaso?: string;
}

const FormCancellationContext = createContext<
	[FormCancellation, Dispatch<SetStateAction<FormCancellation>>]
>([
	{
		nss: '',
		numero_credito: '',
		numero_instrumento: '',
		libro: '',
		calle: '',
		colonia: '',
		ciudad: '',
		seccion: '',
		numero_escritura: '',
		fecha_elaboracion: '',
		numero_notario: '',
		nombre_notario: '',
		correo_notario: '',
		ciudad_notario: '',
		folio_real: '',
		nombre_notario_origen: '',
		numero_notario_origen: '',
		ciudad_notario_origen: '',
		tipo_carta: '',
		fecha_liquidacion: '',
		clase_operacion: '',
		tipificacion: '',
		estado_credito: '',
	},
	() => {},
]);

export default FormCancellationContext;
