import {
	createAsyncThunk,
	createReducer,
	PayloadAction,
} from '@reduxjs/toolkit';
import { IProcessController } from '../../pages/MIUC/interfaces/processController';
import {
	CLEAR_DATA,
	IDefaultData,
	INITIAL_STATE,
	SET_DATA_PROCESS_CONTROLLER,
} from '../types/miuc';

export const actions = {
	setDataProcessController: createAsyncThunk(
		SET_DATA_PROCESS_CONTROLLER,
		(dataProcessController: IProcessController) => {
			return dataProcessController;
		}
	),
	backupState: createAsyncThunk(
		SET_DATA_PROCESS_CONTROLLER,
		(dataProcessController: IProcessController) => {
			return dataProcessController;
		}
	),
	clearData: createAsyncThunk(CLEAR_DATA, () => {
		return {};
	}),
};

export default createReducer(INITIAL_STATE, {
	[actions.setDataProcessController.fulfilled.type]: (
		_state: IDefaultData,
		action: PayloadAction<IProcessController>
	) => ({
		..._state,
		dataProcessController: action.payload,
	}),
	[actions.clearData.fulfilled.type]: () => ({
		dataProcessController: {} as IProcessController,
	}),
	[actions.backupState.fulfilled.type]: (
		_state: IDefaultData,
		action: PayloadAction<IProcessController>
	) => ({
		dataProcessController: action.payload,
	}),
});
