import { useContext } from 'react';
import CustomButton from '../../../components/Button';
import CustomAlert from '../../../components/CustomAlert';
import ModalLoading from '../../../components/ModalLoading';
import { dataReturnSavingsContext } from '../contextData';
import { IRequestCreacionCaso } from '../interfaces';
import { useStyles } from '../style';

const MessageHSR = () => {
	const style = useStyles();

	const {
		GetPage,
		handleCreacionCaso,
		page,
		setData,
		data,
		alert,
		loading,
	} = useContext(dataReturnSavingsContext);

	//useEffect(() => set_Page(page), [page]);

	const handleCancelar = () => {
		setData(
			{
				...(data || undefined),
				accordingAmount: undefined,
			} || undefined
		);
		GetPage(page, false, data?.accordingAmount);
	};

	const handleContinuar = () => {
		//TODO.- Mover a la pantalla de HISR
		const params: IRequestCreacionCaso = {
			evento: '2',
			opcion: data?.returnOptionType || '',
			demanda: false,
			tipoDemanda: '',
			montoDevolver: data?.savingsInformationTotal || '0',
			montoSaldo92: data?.savingsInformation?.subaccount_1992 || '0',
			montoSaldo97: data?.savingsInformation?.subaccount_1997 || '0',
			montoSaldoFA: data?.savingsInformation?.savingFund || '0',
			montoAdicionalSaldoFA:
				data?.savingsInformation?.additionalQuantity || '0',
			descMontoAdicionalSaldoFA: '',
			numeroCredito: '',
		} as IRequestCreacionCaso;
		handleCreacionCaso(params);
	};

	return (
		<div>
			<ModalLoading loading={loading}></ModalLoading>
			<div style={{ textAlign: 'center' }}>
				<p>
					Si tienes el formato HISR-91-2 o el Aviso de baja de la
					empresa que muestre las aportaciones que tu patrón enteró{' '}
					<br></br>entre mayo de 1972 al 29 de febrero de 1992,
					deberás hacer una aclaración; este documento te servirá para
					que<br></br>registremos las aportaciones que no están
					consideradas en tu saldo.
				</p>
			</div>
			<div className={style.divAlert}>
				<CustomAlert
					message={alert.message}
					show={alert.show}
					severity={alert.severity}
				/>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={style.divButtons}>
					<div className={style.Buttons}>
						<CustomButton
							onClick={handleCancelar}
							variant="outlined"
							styles={{
								width: '100%',
								height: '100%'
							}}
							label="Cancelar"
						/>
					</div>
					<div className={style.Buttons}>
						<CustomButton
							onClick={handleContinuar}
							variant="solid"
							styles={{
								width: '100%',
							}}
							label="Continuar"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MessageHSR;
