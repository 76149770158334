import ScrollContainer from 'react-indiana-drag-scroll';
import { useRef } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useStyles } from './styles/carouselCustom.style';

interface IProps {
	items: JSX.Element[];
	horizontal?: boolean;
}

const CarouselCustom = ({ items, horizontal }: IProps) => {
	const styles = useStyles({ horizontal });
	const videoScroll = useRef<HTMLElement>(null);
	const afterVideo = () => {
		if (videoScroll && !horizontal) {
			videoScroll.current?.scrollTo({
				top: videoScroll.current.scrollTop - 200,
				behavior: 'smooth',
			});
		}
		if (videoScroll && horizontal) {
			videoScroll.current?.scrollTo({
				left: videoScroll.current.scrollLeft - 200,
				behavior: 'smooth',
			});
		}
	};
	const nextVideo = () => {
		if (videoScroll && !horizontal) {
			videoScroll.current?.scrollTo({
				top: videoScroll.current.scrollTop + 200,
				behavior: 'smooth',
			});
		}
		if (videoScroll && horizontal) {
			videoScroll.current?.scrollTo({
				left: videoScroll.current.scrollLeft + 200,
				behavior: 'smooth',
			});
		}
	};
	return (
		<div className={styles.generalVideoContainer}>
			<div className={styles.arrowVideo}>
				<div onClick={afterVideo}>
					{horizontal ? <ChevronLeftIcon /> : <ExpandLessIcon />}
				</div>
			</div>
			<ScrollContainer
				vertical={horizontal ? false : true}
				horizontal={horizontal ? true : false}
				className={styles.containerVideos}
				innerRef={videoScroll}
			>
				{items.map((item) => item)}
			</ScrollContainer>
			<div className={styles.arrowVideo}>
				<div onClick={nextVideo}>
					{horizontal ? <ChevronRightIcon /> : <ExpandMoreIcon />}
				</div>
			</div>
		</div>
	);
};

export default CarouselCustom;
