// eslint-disable-next-line react-hooks/exhaustive-deps

// DEPENDENCIES
import { Box, Divider } from '@mui/material';
import { Info } from '@mui/icons-material';
// COMPONENTS
import ModalLoading from '../../../../components/ModalLoading';
import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import { Authorization } from '../Authorization/authorization';
import { RoundBtn } from '../RoundBtn/roundBtn';
import { RadioButtonInput } from '../RadioButtonInput/radioButtonInput';
import Header from '../Header';

// // RESOURCES
import { useStyles } from './styles';
import { isNumberFloat, isNumberValidate } from '../../../../utils/expressions';
import CustomAlert from '../../../../components/CustomAlert';
import {
	WARNING_MESSAGE,
	addressForm,
	list,
	months,
} from '../../utils/buroForm.utils';
import { useGeneralStyles } from '../../views/styles/general.styles';
import { useBuro } from './useBuro';
import { HeaderList } from '../../../../components/HeaderList';
import TooltipCustom from '../TooltipCustom';
import { CheckBoxInputs } from '../CheckBoxInputs';

const today = new Date();
const fullDate = `${today.getDate()}/${
	months[today.getMonth()]
}/${today.getFullYear()}`;

interface IProps {
	onlyAuthorization?: boolean;
}

export const BuroForm = ({ onlyAuthorization }: IProps) => {
	const classes = useStyles();
	const generalStyle = useGeneralStyles();
	const {
		newStep,
		loader,
		authorization,
		setAuthorization,
		changeChekbox,
		creditOptions,
		form,
		attempts,
		alert,
		returnStep,
		disableContinueButton,
		handleChange,
		nextProcess,
		dataProcessController,
	} = useBuro();

	return (
		<Box>
			<Header steps={newStep}></Header>
			<Box className={generalStyle.container}>
				<ModalLoading loading={loader} />
				<Box sx={{ mt: '32px' }}>
					<HeaderList listSizeIcon={18} list={list} />
				</Box>
				<Box className={classes.container}>
					<h4>Hoy {fullDate}</h4>
				</Box>
				<Authorization
					authorization={authorization}
					setAuthorization={setAuthorization}
					onlyAuthorization={onlyAuthorization}
				></Authorization>

				{authorization && (
					<>
						<Box className={classes.creditContainer}>
							<Divider sx={{ marginY: 2 }} />
							<CheckBoxInputs
								name="1"
								label={
									<Box>
										<Box className={classes.sectionTitle}>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<h4>
													¿Tienes o has tenido alguno
													de los siguientes créditos?
												</h4>
												<TooltipCustom
													title={
														'No omitas información porque no podremos realizar con éxito la consulta.'
													}
													placement="right"
													arrow
												>
													<Info
														color="primary"
														fontSize="small"
													/>
												</TooltipCustom>
											</Box>

											<Box className="subtitle">
												<Info fontSize="small" />
												<p>
													Elige los que apliquen y ten
													a la mano tu último estado
													de cuenta.
												</p>
											</Box>
										</Box>
									</Box>
								}
								options={creditOptions}
								onChange={(event) => changeChekbox(event)}
							></CheckBoxInputs>

							{dataProcessController.processInfo
								.esMejoraDirecta && (
								<Box className={classes.mejoraContainer}>
									<p>
										Captura tu ingreso mensual
										<label>*</label>
									</p>
									<TextInput
										id="salarioMensual"
										name="salarioMensual"
										placeholder="0.00"
										onChange={(e: any) => {
											isNumberFloat(e.target.value) &&
												handleChange(e);
										}}
										value={form.salarioMensual}
									/>
								</Box>
							)}

							<Divider sx={{ marginY: 2 }} />
							<Box className={classes.sectionTitle}>
								<h4>Ingresa tu domicilio</h4>
								<Box className="subtitle">
									<Info fontSize="small" />
									<p>
										El domicilio debe ser el mismo que está
										registrado en el crédito que
										seleccionaste.
									</p>
								</Box>
							</Box>
						</Box>

						<div className={classes.formContainer}>
							{addressForm.map((input) => {
								if (input.name === 'colonia')
									return (
										<SelectInput
											isRequired
											key={input.id}
											value={form[input.name] || ''}
											id={input.id}
											name={input.name}
											label={input.label}
											onChange={handleChange}
											options={
												form?.colonias?.map(
													(c: string) => ({
														value: c,
														text: c,
													})
												) || []
											}
										/>
									);
								return (
									<TextInput
										isRequired={input.required}
										key={input.id}
										value={form[input.name] || ''}
										id={input.id}
										name={input.name}
										label={input.label}
										tooltipText={input.tooltip}
										disabled={input.disabled}
										onChange={(e: any) =>
											input.isNumber
												? isNumberValidate(
														e.target.value
												  ) && handleChange(e)
												: handleChange(e)
										}
									/>
								);
							})}
						</div>
						{attempts > 0 && `Numero de intentos ${attempts} de 3`}
						{attempts === 3 && (
							<CustomAlert
								show
								severity={'warning'}
								message={
									<>
										<h4>
											¡Alcanzaste el limite de intentos
											permitidos!
										</h4>
										Puedes continuar sin la información que
										proporciona el buró de crédito o salir a
										la elección de créditos
									</>
								}
							/>
						)}
					</>
				)}
				{!authorization && (
					<CustomAlert
						show
						severity="warning"
						message={WARNING_MESSAGE}
					/>
				)}
				<Divider sx={{ marginY: 2 }} />
				<CustomAlert
					show={alert?.show}
					severity={alert?.severity}
					message={alert?.message}
				/>

				<div className={generalStyle.bottonsContainer}>
					<RoundBtn variant="outlined" onClick={() => returnStep()}>
						Regresar
					</RoundBtn>
					<RoundBtn
						variant="contained"
						onClick={nextProcess}
						disabled={disableContinueButton}
					>
						Continuar
					</RoundBtn>
				</div>
				{authorization && (
					<Box>
						<Divider sx={{ marginY: 2 }} />
						<h4 className={classes.info}>* Datos obligatorios</h4>
					</Box>
				)}
			</Box>
		</Box>
	);
};
