export const STAGE_ID = {
	SERVICIO_NO_DISPONIBLE: '0',
	BUENO: '1',
	REGULAR: '2',
	MALO: '3',
	FRAUDE_DEFUNCION: '4',
	DEFUNCION: '5',
	SIN_REFERENCIA_CREDITICIA: '6',
	NO_AUTENTICA_BC: '7',
	ENDEUDAMIENTO: '8',
	DATOS_ERRONEOS: '-1' // EN EL SWITCH SALE COMO DEFAULT
}