import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		height: '232px',
		maxWidth: '380px',
		width: '100%',
		boxShadow: '0px 10px 20px #A3AEBE26',
		border: '1px solid #E5E5E5',
		'& $icon img': {
			width: '45px',
		},
		'& $icon svg': {
			width: '45px',
			height: 'auto',
		},
		'&:hover': {
			boxShadow: '0px 10px 20px #2939904F',
			border: `1px solid ${theme.palette.info.main}`,
		},
	},
	normalCard: {
		'& $precalificate': {
			display: 'none',
		},
	},
	withHover: {
		'&:hover': {
			'& $icon': {
				opacity: 0,
			},
			'& $title': {
				top: '16px',
				height: 'auto'
			},
			'& $description': {
				display: 'none',
			},
			'& $list': {
				display: 'block',
			},
		}
	},
	disabled: {
		'& $precalificate': {
			display: 'none',
		},
		'& $title': {
			color: '#7F7F7F'
		},
		'& $icon': {
			opacity: 0.36,
		},
		pointerEvents: 'none',
	},
	actionArea: {
		height: '100%',
		textAlign: 'start',
		padding: '24px !important',
	},
	icon: {
		position: 'absolute',
		top: '24px',
		left: '24px',
		opacity: 1,
		transition: 'opacity 0.5s, height 0.5s',
	},
	precalificate: {
		position: 'absolute',
		top: '16px',
		right: '16px',
		color: '#07C100',
		fontWeight: 'bold',
	},
	title: {
		width: 'calc(100% - 48px)',
		position: 'absolute',
		top: 80,
		transition: 'top 0.5s',
		height: 100,
		textAlign: 'start',
		'&> h2': {
			margin: '0px 0px',
			// overflow: "hidden",
			// textOverflow: "ellipsis",
			// display: "-webkit-box",
			// "-webkit-box-orient": "vertical",
			// "-webkit-line-clamp": 1,
		},
		'&> p': {
			overflow: "hidden",
			textOverflow: "ellipsis",
			display: "-webkit-box",
			"-webkit-box-orient": "vertical",
			"-webkit-line-clamp": 3,
		}
	},
	list: {
		display: 'none',
		fontSize: '18px',
		marginTop: '15px',
	},
	description: {
		fontSize: '18px',
	},
	listItem: {
		display: 'flex',
		alignItems: 'baseline',
		height: '20px',
		marginBottom: '4px',
		'&> img': {
			width: '12px',
			marginRight: '8px',
		},
		'&> p': {
			margin: 0,
		},
	}
}));
