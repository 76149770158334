import { Box, Grid, FormControl, Select, MenuItem, Pagination, InputLabel } from '@mui/material';
import { CheckCircleOutlined } from '@mui/icons-material';

import { RoundBtn } from '../../../components/RoundBtn/roundBtn';
import AddressCard from '../../../components/AddressCard';

import { useStyles } from "../styles/styles";
import { useEffect, useState } from 'react';
import { CODES_RESPONSE } from '../../../../../config/miucConstants';
import { IEstados, apiMIUC } from '../../../../../api/modules/MIUC';
import { encodeAllJSONData } from '../../../../../utils/encrypt';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useAlertCustom } from '../../../hooks/useAlertCustom';
import { actions } from '../../../../../store/modules/loading';
import { isEmpty } from '../../../../../utils/validators';
import { useNavigate } from 'react-router-dom';

const ChooseValuation = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user } = useSelector((state: RootState) => state.session);
	const [states, setStates] = useState<IEstados[]>([]);
	const [selectedState, setSelectedState] = useState('');
	const [units, setUnits] = useState<any[]>([]);

	const { AlertInput, setAlertCustom, setAlertClean } = useAlertCustom();
	const { AlertInput: AlertInputTip, setAlertCustom: setAlertCustomTip, setAlertClean: setAlertCleanTip } = useAlertCustom();

	const initPage = 1;
	const maxPerPage = 4;
	const [page, setPage] = useState(initPage);

	useEffect(() => {
		fetchStates();

		isEmpty(units) && setAlertCustomTip({
			message: 'Seleccione un Estado para mostrar las unidades.',
			show: true,
			severity: 'warning'
		})
	}, []);

	useEffect(() => {
		setPage(initPage);
		!isEmpty(selectedState) && fetchUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedState]);

	const fetchStates = async () => {
		try {
			dispatch(actions.startLoading());
			const result = await apiMIUC.consultarEstados();

			if (result.code !== CODES_RESPONSE.CORRECTO) {
				return setAlertCustom({
					message: result.message,
					show: true,
					severity: 'error',
				});
			}

			setStates(result.data.estados);
			setAlertClean();
		} catch (error: any) {
			setAlertCustom({
				message: error.description ||
					'Por el momento el servicio no se encuentra disponible, intenta más tarde.',
				show: true,
				severity: 'error',
			});
		} finally {
			dispatch(actions.stopLoading());
		}
	};

	const fetchUnits = async () => {
		try {
			dispatch(actions.startLoading());

			const data = {
				clave: selectedState,
			}
			const dataEncript = await encodeAllJSONData(
				{
					data: JSON.stringify(data),
				},
				user?.k || ''
			);
			const result = await apiMIUC.consultarUnidades(
				dataEncript
			);
			if (result.code !== CODES_RESPONSE.CORRECTO) {
				return setAlertCustomTip({
					message: result.message,
					show: true,
					severity: 'error',
				});
			}
			setUnits(
				result?.data?.unidades?.map((unit: any) => ({
					name: unit.descripcion, //temporal
					company: unit.descripcion,
					state: unit.descripcionEstado,
					address: unit.direccion,
					agent: unit.responsable,
					data: `${unit.horario}, Tels. ${unit.telefono}`,
				}))
			);
			setAlertCleanTip();
		} catch (error: any) {
			setAlertCustomTip({
				message: error.description,
				severity: 'error',
				show: true,
			})
			setUnits([]);
		} finally {
			dispatch(actions.stopLoading());
		}
	};

	return (
		<div className={classes.container}>
			<Box className={classes.header}>
				<h2 className={classes.titleBlue}>Elige unidad de valuación</h2>
			</Box>
			<AlertInput />
			<div className={classes.paperCustom}>
				<p className={classes.paperCustomTitle}><b>Aspectos importantes</b></p>
				<p className={classes.paperCustomTitle}>Una vez que selecciones un valuador comunícate y haz una cita.</p>
				<div>
					<p className={classes.paperCustomListItem}>
						<CheckCircleOutlined color="info" />
						<label>Contacta a la unidad de valuación que elegiste, te solicitarán domicilio completo del inmueble y acordarán la visita.</label>
					</p>
					<p className={classes.paperCustomListItem}>
						<CheckCircleOutlined color="info" />
						<label>Indícale que se trata de un trámite de crédito con Infonavit pues se tienen tarifas preferenciales.</label>
					</p>
					<p className={classes.paperCustomListItem}>
						<CheckCircleOutlined color="info" />
						<label>Puedes seleccionar al valuador por estado.</label>
					</p>
				</div>
			</div>

			<Grid container columns={12} spacing={2}>
				<Grid item xs={12} md={4}>
					<div className={classes.formSelectContainer}>
						<div className={classes.formSelectItem}>
							<p className={classes.paperCustomTitle}>Estado:</p>
							<FormControl fullWidth>
								<InputLabel id='estado-label'>Selecciona un estado</InputLabel>
								<Select
									className={classes.selectBg}
									label='Selecciona un estado'
									labelId='estado-label'
									value={selectedState}
									onChange={(e: any) => {
										setSelectedState(e.target.value)
									}}
								>
									{
										states.map((state) => (<MenuItem value={state.clave} >{state.descripcion}</MenuItem>))
									}
								</Select>
							</FormControl>
						</div>
						{/* <div className={classes.formSelectItem}>
							<p className={classes.paperCustomTitle}>Municipio:</p>
							<FormControl fullWidth>
                <InputLabel id='municipio-label'>Selecciona una localidad</InputLabel>
								<Select
                  className={classes.selectBg}
                  label='Selecciona una localidad'
                  labelId='municipio-label'
									value={''}
								>
								</Select>
							</FormControl>
						</div>
						<div className={classes.formSelectItem}>
              <p className={classes.paperCustomTitle}>Distancia:</p>
							<FormControl fullWidth>
                <InputLabel id='distancia-label'>Selecciona</InputLabel>
								<Select
                  className={classes.selectBg}
                  label='Selecciona'
                  labelId='distancia-label'
									value={''}
								>
								</Select>
							</FormControl>
						</div>
						<div className={classes.formSelectItem}>
							<p className={classes.paperCustomTitle}>Compatibilidad:</p>
              <FormControl fullWidth>
                <InputLabel id='compatibilidad-label'>Selecciona un rango</InputLabel>
								<Select
                  className={classes.selectBg}
                  label='Compatibilidad'
                  labelId='compatibilidad-label'
									value={''}
								>
								</Select>
							</FormControl>
						</div> */}
					</div>
				</Grid>
				<Grid item xs={12} md={8}>
					<AlertInputTip />
					{
						units
							.slice((page - 1) * maxPerPage, page * maxPerPage)
							.map((card: any) => (
								<AddressCard dataCard={card} key={crypto.randomUUID()}></AddressCard>
							))}
					{!isEmpty(units) && (
						<div className={classes.paginationContainer}>
							<Pagination
								color="primary"
								count={Math.ceil(units.length / maxPerPage)}
								variant="text"
								shape="rounded"
								onChange={(e, v) =>
									setPage(Number(v))
								}
								page={page}
							/>
						</div>
					)}
					<Box className={classes.footer}>
						<RoundBtn onClick={() => navigate(-1)} variant="contained">Finalizar</RoundBtn>
					</Box>
				</Grid>
			</Grid>
		</div>
	)
}

export default ChooseValuation;