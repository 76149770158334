const GroundsVouchers = () => {
	return (
		<>
			<ul>
				<li>Recibo de luz,</li>
				<li>Agua,</li>
				<li>Internet,</li>
				<li>Telefonía móvil/celular,</li>
				<li>Teléfono fijo,</li>
				<li>Predial,</li>
				<li>Gas natural,</li>
				<li>Tv por cable,</li>
				<li>Contrato de arrendamiento,</li>
				<li>Contrato de comodato,</li>
				<li>
					INE/IFE siempre que el domicilio se encuentre completo y
					visible,
				</li>
				<li>Constancia vecinal expedida por la autoridad municipal,</li>
				<li>
					Cartilla de identidad postal emitida por correos de México,
				</li>
				<li>
					Comprobante de alineación y número oficial emitido por el
					gobierno estatal, municipal o su similar en la Ciudad de
					México,
				</li>
				<li>
					Estado de cuenta bancario que proporcionen las instituciones
					del sistema financiero,
				</li>
				<li>
					Contrato de apertura de cuenta bancaria suscrito por el
					derechohabiente,
				</li>
				<li>Comprobante de inscripción ante el RFC,</li>
				<li>
					Carta de radicación o de residencia a nombre del
					derechohabiente, expedida por los gobiernos estatal,
					municipal o sus similares en la Ciudad de México conforme a
					su ámbito territorial,
				</li>
				<li>
					Constancia de residencia emitida por autoridad municipal,
				</li>
				<li>
					Constancia de domicilio expedida por una autoridad federal,
					estatal, municipal o ejidal en la que se plasme sello y
					firma.
				</li>
			</ul>
			Para el caso de la INE, el contrato de arrendamiento y el contrato
			de comodato, estos deben estar vigentes a la fecha de solicitud de
			crédito.
		</>
	);
};

export default GroundsVouchers;
