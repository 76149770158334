import { useState } from 'react';

import { Grid, Box } from '@mui/material';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import Divider from '@mui/material/Divider';

import ModalExit from './ModalExit';
import ModalSaveProcess from './ModalSaveProcess';
import { IAlert } from '../../../../../interfaces/alert';
import CustomButton from '../../../../../components/Button';
import CustomAlert from '../../../../../components/CustomAlert';

import { useStyles } from '../styles/styles';


interface IChildren {
	title: string;
	body: JSX.Element | JSX.Element[];
	disabled?: boolean;
}

interface IProps {
	title: string;
	nextStep: () => void;
	subTitle?: string;
	disabledButton?: boolean;
	disabledContinue?: boolean;
	children: IChildren[];
	afterQuestionarySteps?: () => void;
	alert?: IAlert;
}

const HeaderStep = ({
	title,
	subTitle,
	disabledButton,
	disabledContinue,
	children,
	nextStep,
	afterQuestionarySteps,
	alert,
}: IProps) => {
	const classes = useStyles();
	const [modalExit, setModalExit] = useState(false);
	const [modalSave, setModalSave] = useState(false);

	const nextStepSave = () => {
		setModalSave(false);
		nextStep();
		afterQuestionarySteps && afterQuestionarySteps();
	};

	return (
		<>
			<ModalExit
				open={modalExit}
				setOpen={setModalExit}
				handleSave={() => setModalSave(true)}
				handleReturn={afterQuestionarySteps}
				disabledSave={disabledButton}
			/>

			<ModalSaveProcess open={modalSave} setConfirm={nextStepSave} />

			<Grid container columns={12} className={classes.headerStep}>
				<Grid item xs={12} md={9}>
					<Box className={classes.headerStepTitle}>
						<span>
							{title}
						</span>
					</Box>
				</Grid>
				<Grid item xs={12} md={3}>
					<Box className={classes.headerStepButton}>
						<CustomButton
							label={'Guardar'}
							onClick={() => nextStep()}
							disabled={disabledButton ?? true}
						/>
					</Box>
				</Grid>
			</Grid>

			<Box className={classes.subHeaderStep}>
				<WarningAmberRoundedIcon />
				<p>
					<b>Importante:</b> Dependiendo de tus respuestas, habrá
					algunas preguntas que no será necesario que contestes, por
					lo que las observarás en un color más tenue y no podrás
					contestarlas, cuando esto suceda, da clic en el botón
					"Continuar" para encontrar la siguiente pregunta habilitada.
				</p>
			</Box>

			<Box className={classes.questionCardContainer}>
				{subTitle && <h4>{subTitle}</h4>}

				{children.map((e, k) => (
					<div className={classes.questionCard} key={k} style={{ color: e.disabled ? 'gray' : 'black' }}>
						<span>{e.title}</span>
						<Box className={classes.questionContainerModal}>{e.body}</Box>
					</div>
				))}
			</Box>

			<Divider />

			<div className={classes.questionContainerModal}>
				<CustomAlert
					show={alert?.show || false}
					message={alert?.message || ''}
					severity={alert?.severity || 'error'}
				/>
			</div>

			<Box className={classes.questionButtonsContainer}>
				<Box>
					<CustomButton
						label={'Salir'}
						variant={'outlined'}
						onClick={() => setModalExit(true)}
					/>
				</Box>
				<Box>
					<CustomButton
						label={'Continuar'}
						onClick={() => nextStep()}
						disabled={disabledContinue ?? true}
					/>
				</Box>
			</Box>
		</>
	);
};

export default HeaderStep;
