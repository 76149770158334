import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	main: {
		width: '100%',
		minWidth: '100vh',
		position: 'relative',
	},
	tableCustom: {
		width: '100%',
		textAlign: 'center',
		borderCollapse: 'collapse',
		'& thead': {
			'& tr': {
				'& th': {
					fontWeight: 500,

					'& section': {
						width: '100%',
						margin: '5px auto',
						border: '1px solid transparent',
						borderRadius: '14px',
						padding: '5px 0px',

						'& div': {
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							margin: '5px 0px',
						},

						'& header':{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							margin: '10px 0px',
						},

						'& div.conyuge': {
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							margin: '0px',
							marginBottom: '12px',
						},
					},

					'& section.active': {
						boxShadow: '0px 10px 20px #29399050',
						borderColor: '#293990',
					},
				},
			},
		},
		'& tbody': {
			'& tr': {
				'& th': {
					height: '50px',
					background: '#F2F2F2',
					color: '#333333',
					position: 'relative',
				},
				'& td': {
					textAlign: 'center',
					height: 'auto',

					'& section': {
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						margin: '0px auto',
						padding: '10px 0px'
					},

					'& section.active': {
						boxShadow: '0px 10px 20px #29399050',
					},

					'& section.textPrimary': {
						color: '#293990',
						fontWeight: 500,
					},

					'& section.textShadow': {
						background: '#EEF4FA',
						color: '#293990',
						fontWeight: 500,
					},
				},
			},
		},
	},
	tableCustomTd: {
		minHeight: '50px',
		margin: 'auto',
	},
	textFooter: {
		textAlign: 'center',
		background: '#F2F2F2',
		color: '#293990',
		padding: '10px 10px',
		fontWeight: 500,
		marginTop: '5px',
	},
	cabeceraTabla: {
		display: 'block',
		width: '80%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		margin: '10px auto',
		textAlign: 'center',

		'& h2': {
			color: '#293990',
		},
		'& p': {
			textAlign: 'left',
			margin: '0px',
			fontSize: '18px',
			color: '#333333',
			fontWeight: 500,
		},
		'& section': {
			display: 'flex',
			alignItems: 'center',

			'& div': {
				display: 'inline-block',

				[theme.breakpoints.down('md')]: {
					display: 'block',
					width: '100%',
					textAlign: 'left',
				},
			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
		},
	},
	rowTitleSup: {
		position: 'absolute',
		top: '0',
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	tablaComparativa: {
		color: '#D1001F',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 400,
		cursor: 'pointer',
		userSelect: 'none',
		margin: '20px 0px',
	},
	iconoDescarga: {
		marginLeft: '5',
		width: '24',
	},
	helpPanelL6: {
		marginLeft: '10px',
		paddingBottom: '7px',
	},
	optionsMVIT: {
		fontWeight: 'normal',
		fontSize: '15px',
	},
}));
