import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora(
	'125',
	'avisos-suspension-retencion-modificacion-descuentos'
);

export const ENDPOINTS_AVISO_SUSPENSION_RETENCION_Y_MODIFICACION_DE_DESCUENTOS: IBitacora =
	{
		'/avisos-suspension-retencion-descuento-consulta': _bitacora.Read(
			'125100',
			'Consulta de aviso y suspension'
		),
	};
