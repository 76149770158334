import { useNavigate } from 'react-router-dom';
import { Box, Divider, Grid, useMediaQuery, Link } from '@mui/material';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import CustomAlert from '../../components/CustomAlert';
import { IResponse } from '../../interfaces/EcotechConstancy';

interface Props {
	data: IResponse;
}

const Caso3 = ({ data }: Props) => {
	const navigate = useNavigate();
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box>
			<h3 style={{ padding: '0px 40px', paddingTop: 40 }}>Aspectos importantes</h3>
			<Grid container columns={12}>
				<Grid item xs={12} sm={12} md={12} className={classes.parrafocaso3}>
					<p>
						Cuando te entreguen las ecotecnologías que compraste y que serán
						instaladas en tu casa, el Proveedor debe entregarte las garantías,
						así como los manuales de uso y mantenimiento. <br /> <br /> No
						olvides solicitarle la factura por el canje de tus Ecotecnologías,
						y recuerda que hasta no estar satisfecho con el servicio brindado
						y las tecnologías instaladas funcionando en su totalidad, no debe
						solicitarte la firma de conformidad en tu Constancia. En caso de
						tener alguna inconformidad con el servicio brindado por tu
						proveedor o alguna queja relacionada con este proceso, podrás
						dejar tu queja a través del servicio{' '}
						<Link
							onClick={() => navigate('/contactanos/presenta-sigue-queja')}
							style={{ cursor: 'pointer' }}
							underline="none"
						>
							Haz y sigue tu queja.
						</Link>
					</p>

					<div className={classes.paddingAlert}>
						<CustomAlert
							show={true}
							severity="warning"
							message={data.mensajeCanje || ''}
						/>
					</div>
					<p>
						Para aclarar cualquier duda relativa con el mensaje anterior,
						puedes comunicarte con el instituto, llamando al Infonatel al
						91715050 en la Ciudad de México, o al 018500 008 3900 desde
						cualquier parte del país, de lunes a viernes de 7:30 de la mañana
						a 9 de la noche; sábados, domingos y días festivos de 9 de la
						mañana a 3 de la tarde.
					</p>
				</Grid>
			</Grid>

			<Grid container columns={12} sx={{ pr: 3, pl: 3 }}>
				<Grid item xs={12} sm={12} md={12}>
					<Divider />
				</Grid>
			</Grid>
			<Grid
				container
				columns={12}
				sx={{
					pt: matches ? 1 : 1,
					pb: matches ? 4 : 5,
					pr: matches ? 3 : 6,
					pl: matches ? 3 : 6,
				}}
			>
				<Grid item xs={12} sm={12} md={12} className={classes.title}>
					<h3>Responsable de la información:</h3>
				</Grid>
				<Grid item xs={12} sm={12} md={12} className={classes.parrafogris}>
					<p className={classes.plist}>Gerencia de Evaluación y Análisis</p>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Caso3;
