import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	button: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.main,
		borderTopLeftRadius: 60,
		borderBottomLeftRadius: 60,
		height: 52,
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
		width: 'auto',
		maxWidth: 52,
		boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
		transition: 'max-width 0.9s',
		'&:hover': {
			maxWidth: 240,
		},
	},
	icon: {
		padding: 8,
	},
	title: {
		whiteSpace: 'nowrap',
		color: '#000',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(5),
	},
}));
