import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Box,
	Pagination,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useTheme } from '@mui/material/styles';
import TextInput from '../../components/TextInput';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';
import SelectCustom from '../../components/SelectCustom';
import MyLastContributionTable from './myLastContributionsTable';
import { useStyles } from './styles';
import { apiMySaving } from '../../api/modules/mySaving';
import { formatDate, getDateToObjectDate } from '../../utils/dates';
import { formatMoney } from '../../utils/validators';
import { IAlert } from '../../interfaces/alert';
import { keyAPI } from '../../api/modules/key';
import { encrypt } from '../../utils/encrypt';
import { RootState } from '../../store';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';

interface IData {
	amortizacion: string;
	aportacion: string;
	bimestre: string;
	fechaPago: string;
	nomEmpresa: string;
	nrp: string;
	nss: string;
	year: number;
	zlog: string;
}

interface Data {
	year: string;
	bimestre: string;
	numRegPat: string;
	nombreEmpresa: string;
	fechaPago: string;
	aportacion: string;
	amortizacion: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string }
) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
}

const headCells: readonly HeadCell[] = [
	{
		id: 'year',
		numeric: false,
		disablePadding: true,
		label: 'Año',
	},
	{
		id: 'bimestre',
		numeric: false,
		disablePadding: false,
		label: 'Bimestre',
	},
	{
		id: 'numRegPat',
		numeric: false,
		disablePadding: false,
		label: 'Num. de Registro Patronal (NRP)',
	},
	{
		id: 'nombreEmpresa',
		numeric: false,
		disablePadding: false,
		label: 'Nombre de la empresa',
	},
	{
		id: 'fechaPago',
		numeric: false,
		disablePadding: false,
		label: 'Fecha de Pago',
	},
	{
		id: 'aportacion',
		numeric: false,
		disablePadding: false,
		label: 'Aportación',
	},
	{
		id: 'amortizacion',
		numeric: false,
		disablePadding: false,
		label: 'Amortización',
	},
];

interface EnhancedTableProps {
	numSelected: number;
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler =
		(property: keyof Data) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align="left"
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{
							fontWeight: 'bold',
							fontSize: 16,
							mt: 7,
							mb: 3,
						}}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc'
										? 'sorted descending'
										: 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

export default function EnhancedTable() {
	const { user } = useSelector((state: RootState) => state.session);
	const [order, setOrder] = useState<Order>('desc');
	const [orderBy, setOrderBy] = useState<keyof Data>('year');
	const [selected, setSelected] = useState<readonly string[]>([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage] = useState(5);
	const [openModal, setOpenModal] = useState(false);
	const classes = useStyles();
	const theme = useTheme();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<IData[]>([]);
	const [search, setSearch] = useState('');
	const [menuItem, setMenuItem] = useState([]);
	const [yearSearch, setYearSearch] = useState('');
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});
	const [pagination, setPagination] = useState(1);

	useEffect(() => {
		const handleOnSubmit = async () => {
			setLoading(true);
			try {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const nss_base64 = await encrypt(user?.nss.toString() || '', key);
				const { result } = await apiMySaving.getSavingDetalle(nss_base64);
				const empresas = result.map(
					(elemento: { nomEmpresa?: string }, index: number) => {
						return {
							text: elemento.nomEmpresa,
							value: elemento.nomEmpresa,
						};
					}
				);
				const empresasFiltradas = empresas
					.filter(
						(
							value: { text: string; value: string },
							index: number,
							self: Array<{ text: string; value: string }>
						) => index === self.findIndex((t) => t.text === value.text)
					)
					.map((element: { text: string; value: string }) => {
						return { label: element.text, value: element.value };
					});
				let fixedData = result.map(({ fechaPago, ...other }: any) => ({
					...other,
					// SE ESPERA UN FORMATO DE FECHA TIPO DD/MM/YYYY o DD-MM-YYYY para que funcione esta función
					fechaPago: fechaPago
						? new Date(getDateToObjectDate(fechaPago)).getTime()
						: '',
				}));
				setData(fixedData);
				setPagination(result.length);
				setMenuItem(empresasFiltradas);
			} catch (error: any) {
				setAlert({
					show: true,
					message: error.description
						? error.description
						: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			} finally {
				setLoading(false);
			}
		};
		handleOnSubmit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (newPage: number) => {
		setPage(newPage);
	};

	const handleSetSearch = (value: any) => {
		setSearch(value);
		let newPagination = data.filter(
			(Element: any) =>
				Element.nomEmpresa.indexOf(value) > -1 &&
				(Element.year === Number(yearSearch) || !yearSearch)
		);
		setPagination(newPagination.length);
		setPage(1);
	};

	const handleSetYear = (value: any) => {
		setYearSearch(value);
		let newPagination = data.filter(
			(Element: any) => Element.year === Number(value) || !value
		);
		setPagination(newPagination.length);
		setPage(1);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<ModalLoading loading={loading} />
			<Paper sx={{ width: '100%', mb: 2 }}>
				<Typography
					sx={{
						fontWeight: 'bold',
						fontSize: 16,
						pt: 5,
						mb: 3,
						[theme.breakpoints.up('xs')]: {
							marginLeft: 5,
							marginRight: 5,
						},
					}}
					align="center"
				>
					De acuerdo a nuestros registros cuentas con los siguientes ahorros:
				</Typography>
				<div className={classes.inputcontainer}>
					<div className={classes.inputsearch}>
						<SelectCustom
							id="empresa"
							name="empresa"
							label="Buscar por empresa"
							placeholder={'Selecciona'}
							value={search}
							onChange={(e: any) => handleSetSearch(e.target.value)}
							options={menuItem}
						/>
					</div>
					<div className={classes.hrvertical} />
					<div className={classes.inputsearch}>
						<TextInput
							data-testid="inputyear"
							id="year"
							label="Año"
							name="year"
							value={yearSearch}
							onChange={(e: any) => handleSetYear(e.target.value)}
							size="small"
						/>
					</div>
				</div>
				<TableContainer
					sx={{
						ml: 5,
						[theme.breakpoints.up('xs')]: {
							ml: 3,
							width: '85%',
						},
						[theme.breakpoints.up('sm')]: {
							ml: 3,
							width: '93%',
						},
						[theme.breakpoints.up('md')]: {
							ml: 5,
							width: '100%',
						},
					}}
				>
					<Table
						sx={{ width: '93%' }}
						aria-labelledby="tableTitle"
						size={'medium'}
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={() => {}}
							onRequestSort={handleRequestSort}
							rowCount={data.length}
						/>
						<TableBody>
							{stableSort<any>(data, getComparator(order, orderBy))
								.filter(
									(Element: any) =>
										Element.nomEmpresa.indexOf(search) > -1 &&
										(Element.year === Number(yearSearch) ||
											!yearSearch)
								)
								.slice(
									(page - 1) * rowsPerPage,
									(page - 1) * rowsPerPage + rowsPerPage
								)
								.map((row, index) => {
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) =>
												handleClick(event, row.year)
											}
											role="checkbox"
											tabIndex={-1}
											key={index}
										>
											<TableCell
												component="th"
												id={labelId}
												scope="row"
												padding="none"
												style={{ fontSize: 16 }}
											>
												{row.year}
											</TableCell>
											<TableCell
												align="left"
												style={{ fontSize: 16 }}
											>
												{row.bimestre}
											</TableCell>
											<TableCell
												align="left"
												style={{ fontSize: 16 }}
											>
												{row.nrp}
											</TableCell>
											<TableCell
												align="left"
												style={{ fontSize: 16 }}
											>
												{row.nomEmpresa}
											</TableCell>
											<TableCell
												align="left"
												style={{
													fontSize: 16,
													color: theme.palette.info.main,
												}}
											>
												{row.fechaPago
													? formatDate('P', row.fechaPago)
													: ''}
											</TableCell>
											<TableCell
												align="left"
												style={{ fontSize: 16 }}
											>
												{Number(row.aportacion) > 0 ? (
													<strong>
														{formatMoney(
															Number(row.aportacion) || 0
														)}
													</strong>
												) : (
													formatMoney(
														Number(row.aportacion) || 0
													)
												)}
											</TableCell>
											<TableCell
												align="left"
												style={{ fontSize: 16 }}
											>
												{Number(row.amortizacion) > 0 ? (
													<strong>
														{formatMoney(
															Number(row.amortizacion) || 0
														)}
													</strong>
												) : (
													formatMoney(
														Number(row.amortizacion) || 0
													)
												)}
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
				<Box
					display="flex"
					justifyContent="center"
					flex={1}
					padding={1}
					margin={2}
				>
					<Stack spacing={2}>
						<Pagination
							color="primary"
							count={Math.ceil(pagination / rowsPerPage)}
							variant="text"
							shape="rounded"
							page={page}
							onChange={(e, page) => handleChangePage(page)}
						/>
					</Stack>
				</Box>
				<Typography
					sx={{
						marginTop: '20px',
						paddingLeft: 2,
						paddingRight: 3,
						paddingBottom: '40px',
						width: '100%',
						border: 'none',
						fontSize: 15,
					}}
					align="center"
				>
					<div style={{ width: '100%', marginBottom: 20 }}>
						<CustomAlert
							show={alert.show}
							severity={alert.severity}
							message={alert.message}
						/>
					</div>
					Los rendimientos que genera tu ahorro se abonan al final de cada mes.
					El consejo de Administración del Infonavit establece la tasa de
					interés y es superior al <br />
					incremento del salario mínimo que se paga en la CDMX. <br />
					<br />
					<p className={classes.savingLink}>
						Tu ahorro genera rendimientos{' '}
						<label
							onClick={() => setOpenModal(!openModal)}
							className={classes.link}
						>
							consulta aquí.
						</label>
					</p>
				</Typography>
				<div style={{ paddingLeft: 20, paddingRight: 20 }}>
					{openModal && <MyLastContributionTable />}
				</div>
			</Paper>
		</Box>
	);
}
