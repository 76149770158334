import { FormControlLabel, Radio, Stack } from '@mui/material';
import { paymentMetods } from '../PaymentOptions/utils';
import { UseStyle } from '../Style';

const PaymentMethods = ({ form, handleChange }: any) => {
	const classes = UseStyle();
	return (
		<Stack direction="row" spacing={1} alignItems="center" className={classes.paymentFormsContainer}>
			{paymentMetods.map((input: any, index) => (
				<div key={input.id + index} className={classes.paymentMethodContainer}>
					<FormControlLabel
						label={<strong style={{ fontSize: 13 }}>{input.label}</strong>}
						control={
							<Radio
								checked={form.method_payment === input.value}
								onChange={handleChange}
								id={input.id}
								name={input.name}
								value={input.value}
							/>
						}
					/>
					<div className="payment-image-container">
						{input.images.map((image: any) => (
							<img key={image} src={image} alt="logo_card" width={40} />
						))}
					</div>
				</div>
			))}
		</Stack>
	);
};

export default PaymentMethods;
