import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	content: {
		overflow: 'auto',
		textAlign: 'center',
		padding: '20px 30px',
		height: 'auto',
		backgroundColor: 'white',
		boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
	},
	contentQuestionnaireSteps: {
		overflow: 'auto',
		textAlign: 'center',
		margin: '20px 30px',
		height: 'auto',
		backgroundColor: 'white',
		boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		background: '#f5f5f5',
		justifyContent: 'center',
		padding: 20,
		textAlign: 'center',
	},
	selectionCreditCardsContainer: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'&>*': {
			margin: 10,
			[theme.breakpoints.down('xs')]: {
				width: '100%',
				textAlign: '-webkit-center',
			},
		},
	},
	title: {
		color: '#333333',
		fontWeight: 700,
	},
	text: {
		margin: '20px 0px',
		textAlign: 'center',
	},
	textWithTooltip: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
		gap: '5px',
		'&>h4': {
			marginBottom: '0px',
			color: 'rgba(0, 0, 0, 0.6)',
		},
		'&>svg': {
			marginTop: '-4px'
		},
	},
	fontSize: {
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
		},
	},
	margin: {
		margin: '30px 0px'
	},
	noMargin: {
		margin: '0px'
	},
	left: {
		textAlign: 'left',
	},
	error: {
		color: '#D1001F',
		position: 'relative',
	},
	cardClass: {
		width: '350px',
		padding: '20px 30px',
		textAlign: 'center',
		'&>h4': {
			margin: '0px 0px 10px 0px',
		},
		'&>p': {
			margin: '2px 0px',
		},
	},
	card: {
		boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.75)',
	},
	titleBlue: {
		color: theme.palette.info.main
	},
	containerCheckCircle: {
		paddingLeft: 25,
		marginBottom: 20,
		textAlign: 'initial',
		'&>div': {
			display: 'flex',
		},
	},
	checkCircle: {
		marginRight: 5,
		width: 22,
	},
	formContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginBottom: 20,
		'&>*': {
			flex: '1 1 300px',
			paddingRight: 10,
			[theme.breakpoints.down('sm')]: {
				flex: '1 1 100%',
				paddingRight: 0,
			},
		},
	},
	divider: {
		margin: '10px 0px !important',
	},
	tdcContainer: {
		marginLeft: '50px',
	},
	footer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0px 0px',
	},
	flex: {
		display: 'flex',
	},
	infoHeader: {
		paddingLeft: 25,
		marginBottom: 20,
		textAlign: 'initial',
	},
	containerTableCuestionary: {
		width: '100%',
		maxWidth: '700px',
		margin: '20px auto',
	},
	containerRowTable: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: 'auto',
	},
	subContainerRowTable: {
		display: 'flex',
		alignItems: 'center',
		textAlign: 'left',
		justifyContent: 'space-between',
		width: '100%',
		height: 50,
		borderColor: '#EDEEEF',
		borderWidth: 0.5,
		borderStyle: 'solid',
		paddingLeft: 10,
		paddingRight: 10,
	},
	rowTransparent: {
		color: 'inherit',
		background: 'transparent',
		borderLeft: 'none',
		borderRight: 'none',
	},
	rowGray: {
		color: 'inherit',
		background: 'rgba(242,242,242,0.6)',
		justifyContent: 'center',
		borderLeft: 'none',
		borderRight: 'none',
	},
	linkInit: {
		color: '#D1001F',
		cursor: 'pointer',
	},
	containerRowItem: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		'&>div': {
			display: 'flex',
			alignItems: 'center',
		}
	},
	dialogCheck: {
		textAlign: 'center',
		marginTop: theme.spacing(3),
		marginBottom: '-20px',
		lineHeight: '8px',
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
		paddingBottom: '20px',
	},
	questionCardContainer: {
		padding: '20px 50px',
		textAlign: 'left',
	},
	questionCard: {
		display: 'flex',
		flexDirection: 'column',
		margin: '15px 0px',
		'& > span': {
			margin: '5px 0px',
		},
		'& > ul': {
			textTransform: 'none',
			listStyle: 'none',
			padding: 0,
			margin: 0,
		},
	},
	questionContainerModal: {
		margin: '0px 20px'
	},
	questionButtonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		padding: '20px',
		'&>div': {
			width: '250px',
			margin: '10px',
			'&>button': {
				height: '100%',
			}
		}
	},
	itemsFlexCenter: {
		display: 'flex',
		alignItems: 'center',
	},
	itemsInlineFlexCenter: {
		display: 'inline-flex',
		alignItems: 'center',
	},
	itemsFlexInput: {
		display: 'flex',
		alignItems: 'center',
		'& > span': {
			marginRight: 10,
		},
		'& > div': {
			maxWidth: 80,
		},
	},
	arrowNextStep: {
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '100%',
		width: 30,
		height: 30,
		border: '1px solid #C7C6C5',
		color: '#C7C6C5',
		margin: '0px 5px',
		cursor: 'pointer',
	},
	itemsQuest9: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: '1px solid #C7C6C5',
		marginBottom: '10px',
		paddingBottom: '10px',
	},
	itemsSect2Quest18: {
		display: 'flex',
		alignItems: 'center',
		'& > span': {
			marginRight: 10,
			flexGrow: 1,
		},
		'& > div': {
			maxWidth: 120,
			margin: '0px 5px',
		},
		'& > label': {
			width: 100,
			textAlign: 'center',
		},
	},
	itemInputFlexBorder: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: '1px solid #C7C6C5',
		marginBottom: '10px',
		paddingBottom: '10px',
		'& > span': {
			marginRight: 10,
			flexGrow: 1,
		},
		'& > div': {
			maxWidth: 120,
			margin: '0px 5px',
		},
		'& > label': {
			width: 100,
			textAlign: 'center',
		},
	},
	formControlLabel: {
		marginLeft: '50px',
	},
	titleModal: {
		color: '#293990',
		fontSize: 26,
		display: 'flex',
		textAlign: 'center',
		justifyContent: 'center',
		marginTop: '0px',
		marginBottom: '-10px',
	},
	textModal: {
		lineHeight: '1.5'
	},
	headerStep: {
		padding: '15px 30px',
		boxShadow: '0px 4px 2px -2px gray',
		textAlign: 'left',
	},
	headerStepTitle: {
		display: 'inline-flex',
		alignItems: 'center',
		height: '100%',
		'&>span': {
			color: '#333333',
			fontWeight: 700,
			marginRight: 20,
		}
	},
	headerStepButton: {
		maxWidth: '170px',
		margin: '0px 0px 0px auto',
	},
	subHeaderStep: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#EEF4FA',
		padding: '10px 20px',
		color: '#293990',
		'&>svg': {
			marginRight: 16,
			fontSize: 30,
		},
		'&>p': {
			textAlign: 'center',
			fontSize: 16,
		}
	},
	containerCardMoney: {
		width: '100%',
		marginTop: 15,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	iconPesos: {
		width: 55,
		marginRight: 10,
	},
	labelSSV: {
		fontWeight: '500',
		fontSize: 32,
		color: theme.palette.info.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: 26,
		},
	},
	mainContainerCard: {
		padding: 25,
		textAlign: 'center',
		borderRadius: 5,
	},
	titleCardMoney: {
		fontWeight: '500',
		fontSize: 18,
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		padding: '20px',
		marginTop: 20,
	},
  logo: {
    width: '270px',
    margin: '20px',
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
  },
  donwloadLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    '&> img': {
      marginLeft: 5,
      width: 24,
    },
    textDecoration: 'none',
    '&:hover':{
      textDecoration: 'underline',
    }
  },
  icon: {
    marginLeft: '8px',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));
