import Modal from '../../../components/Modal';
import {
	ICredito,
	formatDate,
	formatNumber,
} from '../../../interfaces/SaldAndMovement';
import CustomButton from '../../../components/Button';
import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import TooltipCustom from '../../../components/TooltipCustom';

interface Props {
	openModal: boolean;
	closeModal: (arg: boolean) => void;
	credito: ICredito | null;
	formasPagos: any[];
	matches: boolean;
}

const ModalOtherPay = ({
	openModal,
	closeModal,
	credito,
	formasPagos,
	matches,
}: Props) => (
	<Modal
		showDialogTitle
		open={openModal}
		stylesContent={{ padding: 0 }}
		children={
			<div style={{ fontSize: 15 }}>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} aria-label="TabladePagos">
						<TableHead style={{ background: 'rgba(0,0,0,0.1)' }}>
							<TableRow>
								<TableCell width={250}></TableCell>
								<TableCell>Mensualidades</TableCell>
								<TableCell>¿Cuánto debo?</TableCell>
								{credito?.tipoCredito?.key === 10 && (
									<TableCell>
										VSM
										<TooltipCustom
											title="Es el monto de cuánto debo pero en Veces Salario Mínimo. El valor de VSM es actualizado cada año y entra en vigor en el mes de enero."
											placement="right"
											arrow
										>
											<InfoRoundedIcon
												style={{
													fontSize: 14,
													margin: 0,
													padding: 0,
													marginLeft: 5,
													color: '#D1001F',
												}}
											/>
										</TooltipCustom>
									</TableCell>
								)}
								<TableCell>¿Cuándo pago?</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>
									Pago para estar al corriente
									<TooltipCustom
										title="Monto de dinero que incluye la mensualidad, pagos atrasados o incompletos y seguros y cuotas."
										placement="right"
										arrow
									>
										<InfoRoundedIcon
											style={{
												fontSize: 14,
												margin: 0,
												padding: 0,
												marginLeft: 5,
												color: '#D1001F',
											}}
										/>
									</TooltipCustom>
								</TableCell>
								<TableCell>
									{credito?.pagosMensualidad
										?.tp11MensPgoCorriente || 0}
								</TableCell>
								<TableCell>
									{formatNumber.format(
										credito?.pagosMensualidad
											?.tp13PesPgoCorriente || 0
									)}
								</TableCell>
								{credito?.tipoCredito?.key === 10 && (
									<TableCell>
										{credito?.pagosMensualidad
											?.tp15VsmPgoCorriente || 0}
									</TableCell>
								)}
								<TableCell>
									{formatDate(
										credito?.pagosMensualidad
											?.tp17PgoCorriente
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									Pago de mi mensualidad
									<TooltipCustom
										title="Monto de dinero para pagar el mes actual; incluye seguros y cuotas."
										placement="right"
										arrow
									>
										<InfoRoundedIcon
											style={{
												fontSize: 14,
												margin: 0,
												padding: 0,
												marginLeft: 5,
												color: '#D1001F',
											}}
										/>
									</TooltipCustom>
								</TableCell>
								<TableCell>
									{credito?.pagosMensualidad
										?.tp12MensPgoMensualidad || 0}
								</TableCell>
								<TableCell>
									{formatNumber.format(
										credito?.pagosMensualidad
											?.tp14PesPgoMensualidad || 0
									)}
								</TableCell>
								{credito?.tipoCredito?.key === 10 && (
									<TableCell>
										{credito?.pagosMensualidad
											?.tp16VsmPgoMensualidad || 0}
									</TableCell>
								)}
								<TableCell>
									{formatDate(
										credito?.pagosMensualidad
											?.tp18PgoMensualidad
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									Forma de pago
									<TooltipCustom
										title="Medio por el cual el acreditado hace sus pagos. Sin relación laboral lo puede hacer en bancos y establecimientos autorizados por el Infonavit. Cuando sí hay una relación de trabajo formal, el patrón hace la retención y la entrega al Instituto."
										placement="right"
										arrow
									>
										<InfoRoundedIcon
											style={{
												fontSize: 14,
												margin: 0,
												padding: 0,
												marginLeft: 5,
												color: '#D1001F',
											}}
										/>
									</TooltipCustom>
								</TableCell>
								<TableCell colSpan={4} align="center">
									{formasPagos.length > 0 &&
										formasPagos[0].value}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<CustomButton
					label={'Cerrar'}
					onClick={() => closeModal(false)}
					variant="solid"
					styles={{
						maxWidth: 260,
						margin: 'auto',
						marginTop: 30,
						marginBottom: 20,
					}}
				/>
			</div>
		}
	/>
);

export default ModalOtherPay;
