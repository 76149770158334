import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	titleNoticeContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	titleNotice: {
		marginTop: 0,
		fontSize: 22,
		color: '#293990',
	},
	subTitleNotice: {
		fontSize: 12,
		color: '#333333',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {
			textAlign: 'right',
			marginTop: 15,
		},
	},

	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		paddingBlock: 50,
		marginTop: 30,
		boxShadow: '0px 5px 30px #00000017',
	},
	containerSectionTitle: {
		fontSize: 18,
		textAlign: 'center',
	},

	containerMin: {
		maxWidth: 900,
		margin: '0 auto',
	},
	containerSectionText: {
		textAlign: 'center',
		'& > span': {
			color: 'red',
			fontStyle: 'outline',
		},
	},
	containerSectionButtons: {
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
		},
		[theme.breakpoints.down('md')]: {},

		'&>button': {
			[theme.breakpoints.down('sm')]: {
				margin: '20px 0px !important',
				width: '100% !important',
			},
		},
	},
}));
