import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import { HeaderList } from '../../components/HeaderList';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

interface IDataStatus {
	apMaterno?: string;
	apPaterno?: string;
	consecutivo?: string;
	curp?: string;
	nombre?: string;
	nss?: string;
	rfc?: string;
	status?: {
		code?: string;
		description?: string;
	};
	CodigoError?: string;
	TipoError?: string;
	descripcionServicio?: string;
	fecha?: string;
	fechaStatus?: string;
	noCaso?: string;
	nombreOperacion?: string;
	operacion?: string;
}
interface Props {
	setPage: (data: number) => void;
	status: IDataStatus;
}

const Status = ({ setPage, status }: Props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();
	return (
		<Box>
			<HeaderList title="Corrección de RFC" />
			<Paper sx={{ pt: 10, pb: 15, pr: 15, pl: 15 }}>
				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: matches ? 'column' : 'row',
					}}
				>
					<Grid item xs={0} sm={0} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Número de caso</h3>
					</Grid>
					<Grid item xs={0} sm={0} md={9} lg={9} style={{ textAlign: 'right' }}>
						<label>{status.noCaso}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />
				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: matches ? 'column' : 'row',
					}}
				>
					<Grid item xs={0} sm={0} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Fecha de solicitud</h3>
					</Grid>
					<Grid item xs={0} sm={0} md={9} lg={9} style={{ textAlign: 'right' }}>
						<label>{status.fecha}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: matches ? 'column' : 'row',
					}}
				>
					<Grid item xs={0} sm={0} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Servicio</h3>
					</Grid>
					<Grid item xs={0} sm={0} md={9} lg={9} style={{ textAlign: 'right' }}>
						<label>{status.descripcionServicio}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: matches ? 'column' : 'row',
					}}
				>
					<Grid item xs={0} sm={0} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Fecha de estatus</h3>
					</Grid>
					<Grid item xs={0} sm={0} md={9} lg={9} style={{ textAlign: 'right' }}>
						<label>{status.fechaStatus}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.4 }} />
				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: matches ? 'column' : 'row',
					}}
				>
					<Grid item xs={0} sm={0} md={3} lg={3}>
						<h3 style={{ fontSize: 18, margin: 0 }}>Estatus</h3>
					</Grid>
					<Grid item xs={0} sm={0} md={9} lg={9} style={{ textAlign: 'right' }}>
						{status.status?.description === 'Hacer cita' && (
							<label
								style={{
									cursor: 'pointer',
									textDecoration: 'underline black',
								}}
								onClick={() =>
									navigate(
										`/contactanos/haz-una-cita/${status.noCaso}/0125/`
									)
								}
							>
								Hacer cita
							</label>
						)}
						{status.status?.description === 'En proceso' && (
							<label> En proceso </label>
						)}
						{status.status?.description === 'En revisión de documentos' && (
							<label>En revisión de documentos</label>
						)}
						{status.status?.description === 'Cambiar cita' && (
							<label
								style={{
									cursor: 'pointer',
									textDecoration: 'underline black',
								}}
								onClick={() => navigate('/contactanos/haz-una-cita')}
							>
								{' '}
								Cambiar cita{' '}
							</label>
						)}
						{status.status?.code === 'E0015' && setPage(6)}
						{status.status?.code === 'E0007' && setPage(0)}
						{status.status?.description === 'En espera de documentos' && (
							<label
								style={{
									cursor: 'pointer',
									textDecoration: 'underline black',
								}}
								onClick={() => {
									setPage(4);
								}}
							>
								{' '}
								En espera de documentos{' '}
							</label>
						)}
						{status.status?.description === 'Caso abierto' && (
							<label> Caso abierto </label>
						)}
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
};

export default Status;
