import { useDispatch } from 'react-redux';
import { Paper, Box, Link, useMediaQuery } from '@mui/material';
import theme from '../../../../../config/theme';
import descarga from '../../../../../assets/img/icono-descarga.png';
import Header from '../../../components/Header';
import ButtonGroup from '../../hipoteca/components/buttonGroup';
import { MIUC_PRODUCTS } from '../../../constants/products';
import { useController } from '../../../hooks/useController';
import { apiMIUC } from '../../../../../api/modules/MIUC';
import { downloadPDF } from '../../../../../utils/downloadPDF';
import { actions } from '../../../../../store/modules/loading';
import CustomAlert from '../../../../../components/CustomAlert';
import { useState } from 'react';
import { INTERNAL_MESSAGES } from '../../../../../config/messageCatalog';

const ApoyoInfonavit = () => {
	const dispatch = useDispatch();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { saveData, returnStep } = useController();
	const [alert, setAlert] = useState('');

	const afterProcess = () => {
		returnStep();
	};

	const nextProcess = () => {
		const parametros = {
			processInfo: {
				credit: MIUC_PRODUCTS.APOYO_INFONAVIT,
			},
			processData: {
				step: 1,
			},
		};
		saveData(
			{
				...parametros,
				history: [parametros],
			},
			true
		);
	};

	const downloadCertificate = async () => {
		try {
			setAlert('');
			dispatch(actions.startLoading());
			const result = await apiMIUC.descargarCertificadoApoyo();
			const pdf64 = result.data?.certificado;
			if (pdf64) {
				downloadPDF({
					name: 'certificado-de-apoyo-infonavit',
					payload: pdf64,
				});
			}
		} catch (error) {
			setAlert(INTERNAL_MESSAGES.ERROR_MESSAGE);
		} finally {
			dispatch(actions.stopLoading());
		}
	};

	// Servicio temporalmente no disponible por el infona
	// const downloadCertificate = () => {
	// 	setAlert(false);
	// 	const url = process.env.REACT_APP_URL_CERTIFICADO_APOYO_MIUC;
	// 	if (url) {
	// 		window.open(url, '_blank');
	// 		return;
	// 	}
	// 	setAlert(true);
	// };

	return (
		<Paper>
			<Header title="Apoyo Infonavit puede ser una alternativa para ti" />
			<Box padding={2}>
				<p style={{ margin: '30px 30px' }}>
					Con esta opción, lo que tienes ahorrado en tu Subcuenta de
					Vivienda queda como garantía de pago en caso de que pierdas
					tu empleo y tus aportaciones patronales serán abonadas para
					amortizar tu deuda con la entidad financiera y así pagarás
					más rápido tu crédito.
				</p>
				<Link
					underline="none"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						margin: '50px 0px',
						flexDirection: matches ? 'column' : 'initial',
						userSelect: 'none',
					}}
					onClick={downloadCertificate}
				>
					Descarga tu Certificado de Apoyo Infonavit
					<img
						src={descarga}
						alt="LogoDescarga"
						style={{ marginLeft: 5, fontSize: 30 }}
					/>
				</Link>

				<CustomAlert
					message={alert}
					severity="warning"
					show={Boolean(alert)}
				/>

				<ButtonGroup
					buttons={[
						{
							label: 'Regresar',
							variant: 'outlined',
							execute: afterProcess,
						},
						{ label: 'Continuar', execute: nextProcess },
					]}
				/>
			</Box>
		</Paper>
	);
};

export default ApoyoInfonavit;
