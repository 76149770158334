/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
	ICredito,
	IMovimientos,
	formatDate,
	formatNumber,
} from '../../../interfaces/SaldAndMovement';
import { apiSaldAndMovements } from '../../../api/modules/SaldAndMovements';
import { encrypt } from '../../../utils/encrypt';
import { keyAPI } from '../../../api/modules/key';
import ModalLoading from '../../../components/ModalLoading';
import ButtonCustom from '../../../components/Button';
import StateAccount from './StateAccount';
import ReportMovement from './ReportMovement';
import ThanksServices from './ThanksServices';
import ThanksNegative from './ThanksNegative';
import { HeaderList } from '../../../components/HeaderList';
import { contactRequestAPI } from '../../../api/modules/profile';

interface Props {
	setView: (avg: number) => void;
	credito: ICredito | null;
	paperless: boolean;
	AceptPaperless: () => void;
}

const MovementsAccount = ({
	setView,
	credito,
	paperless,
	AceptPaperless,
}: Props) => {
	const navigate = useNavigate();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [page, setPage] = useState<number>(0);
	const [periodo, setPeriodo] = useState([]);
	const [anios, setAnios] = useState([]);
	const [movimientos, setMovimientos] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [pdfMovNo, setPdfMovNo] = useState<string>('');
	const [linkRedirect, setLinkRedirect] = useState('');

	const returnMoney = (arg: string) => {
		const newNumber = Number(arg.replace(',', ''));
		return newNumber > 0 ? (
			<strong>{formatNumber.format(newNumber)}</strong>
		) : (
			'$0.00'
		);
	};

	const getRedirect = useCallback(async () => {
		try {
			contactRequestAPI.getRedirect
				.post<any, any>('')
				.then((res: any) => {
					setLinkRedirect(res?.redirect);
				})
				.catch();
		} catch (error) { }
	}, []);

	const creditoEspecial = credito?.tipoCasoEspecial?.code
		? credito?.tipoCasoEspecial?.code
		: 0;

	const codigoDemanda = !['00200', '00220', '00010', '00030'].includes(
		credito?.tipoCasoCredito?.wsTipoCaso || ''
	);

	useEffect(() => {
		getRedirect();
	}, []);

	useEffect(() => {
		const req = async () => {
			try {
				setLoading(true);
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const credit_encript = await encrypt(
					credito?.credito ? credito?.credito : '',
					key
				);
				const resAnios = await apiSaldAndMovements.getAnios(
					credit_encript
				);
				const resPeriodo = await apiSaldAndMovements.getPeriodo(
					credit_encript
				);
				const ArrayAnio = Array.isArray(resAnios.result)
					? resAnios.result.map(
						(anioss: { code: string; value: string }) => {
							return {
								value: anioss.value,
								label: anioss.value,
								code: anioss.code,
							};
						}
					)
					: [];
				const ArrayPeriodo = Array.isArray(resPeriodo.result)
					? resPeriodo.result.map(
						(periodo: { code: string; value: string }) => {
							return {
								value: periodo.value,
								label: periodo.value,
								code: periodo.code,
							};
						}
					)
					: [];

				setPeriodo(ArrayPeriodo);
				setAnios(
					ArrayAnio.sort((p1: any, p2: any) =>
						p1.value < p2.value ? 1 : p1.value > p2.value ? -1 : 0
					)
				);
			} catch (error) {
			} finally {
				setLoading(false);
			}
		};
		req();
	}, [credito]);

	const getMovimientos = async (no_periodo: string, tipoCredito: string) => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const credit_encript = await encrypt(credito?.credito || '', key);
			if (no_periodo === '4') {
				const { result } = await apiSaldAndMovements.getPagosParciales(
					credit_encript
				);
				const arrayResponse = result.map((element: any) => [
					formatDate(element.fecha, '/'),
					element.concepto,
					returnMoney(element.montotransaccion),
				]);
				setMovimientos(arrayResponse);
				setPdfMovNo('');
			} else {
				const periodo_encript = await encrypt(no_periodo, key);
				const { result } = await apiSaldAndMovements.getMovimientos(
					periodo_encript,
					credit_encript
				);
				const responseMap = result.data.map(
					(elemento: IMovimientos) => {
						return [
							formatDate(elemento.Fecha, '-'),
							elemento.Concepto,
							tipoCredito === 'VSM'
								? elemento.MontoTransaccionVSM
								: elemento.MontoTransaccion,
							elemento.PagoASeguro,
							tipoCredito === 'VSM'
								? elemento.PagoAInteresVSM
								: elemento.PagoAIntereses,
							tipoCredito === 'VSM'
								? elemento.SaldoACapitalVSM
								: elemento.PagoACapital,
						];
					}
				);
				setMovimientos(responseMap);
				setPdfMovNo(
					result.fechaInicioEntrada + '-' + result.fechaFinEntrada
				);
			}
		} catch (error) {
			setMovimientos([]);
			setPdfMovNo('');
		} finally {
			setLoading(false);
		}
	};

	const getPdfMovimientos = async (tipoCredito: string) => {
		try {
			setLoading(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const credit_encript = await encrypt(
				credito?.credito ? credito?.credito : '',
				key
			);
			const pdfMoviNo_encript = await encrypt(
				pdfMovNo.toString() || '',
				key
			);
			const nombre_encript = await encrypt(
				credito?.derechohabiente ? credito?.derechohabiente : '',
				key
			);
			const tipo_encript = await encrypt(
				tipoCredito ? tipoCredito : 'PESOS',
				key
			);
			const { result } = await apiSaldAndMovements.getPdfMovimientos(
				credit_encript,
				pdfMoviNo_encript,
				nombre_encript,
				tipo_encript
			);
			let link = document.createElement('a');
			link.download = credito?.nss + '_movimientos_cuenta.pdf';
			link.href = 'data:application/octet-stream;base64,' + result;
			link.click();
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<ModalLoading loading={loading} />
			<HeaderList title="Movimientos y estados de cuenta" />
			<Paper
				style={{
					marginTop: 30,
					padding: matches ? '10px 20px' : '10px 50px',
				}}
			>
				{page === 0 && (
					<>
						<StateAccount
							credito={credito}
							setPage={setPage}
							anios={anios}
							periodo={periodo}
							setLoading={setLoading}
							paperless={paperless}
						/>
						<ReportMovement
							datos={movimientos}
							getMovimientos={getMovimientos}
							codigoDemanda={codigoDemanda}
							creditoEspecial={creditoEspecial}
							getPdfMovimientos={getPdfMovimientos}
							cleanData={() => setMovimientos([])}
						/>

						<div style={{ fontSize: 15, textAlign: 'center' }}>
							<p style={{ fontWeight: 500 }}>
								Aclaración de pagos
							</p>
							<p>
								Si tu pago no se ve reflejado en tus movimientos
								y ya pasaron más de 10 días hábiles, haz una
								aclaración.
							</p>
						</div>

						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								margin: '40px 0px',
								flexDirection: matches
									? 'column-reverse'
									: 'inherit',
							}}
						>
							<div
								style={{
									width: matches ? 'auto' : 250,
									margin: '10px 20px',
								}}
							>
								<ButtonCustom
									label="Regresar"
									onClick={() => setView(0)}
									variant="outlined"
								/>
							</div>

							<div
								style={{
									width: matches ? 'auto' : 250,
									margin: '10px 20px',
								}}
							>
								<ButtonCustom
									label="Hacer aclaración"
									onClick={() => {
										//Descomentar para QA
										navigate(
											'/mi-credito/aclaracion-pagos'
										);
										//redirectPortal para master
										//redirectPortal()
									}}
									variant="solid"
								/>
							</div>
						</div>
					</>
				)}
				{page === 1 && (
					<ThanksServices
						setPage={setPage}
						AceptPaperless={AceptPaperless}
					/>
				)}
				{page === 2 && <ThanksNegative setPage={setPage} />}
			</Paper>
		</>
	);
};

export default MovementsAccount;
