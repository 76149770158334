import { useEffect, useState } from 'react';
import { Box, Grid, Paper } from '@mui/material';
// Componentes
import ViewPoints from '../../components/ViewPoints';
import FlowCard from '../../components/FlowCard';
import Layout from '../../../../components/Layout';

import { SelectionCredit } from '../../components/SelectionCredit/selectionCredit';
import { CreditOptions } from '../../components/CreditOptions/creditOptions';
import CivilStatus from '../../components/CivilStatus';
import { BuroForm } from '../../components/BuroForm/buroForm';
import Products from './pages/Product';
import { SelectionResume } from '../../components/SelectionResume/selectionResume';
import FollowSteps from '../../components/FollowSteps';
import ResumeCard from '../../components/ResumeCard';

import { useNavigation } from '../../hooks/useNavigation';
import { MIUC_PROCESS_VIEWS } from '../../constants/process';
import { useGeneralStyles } from "../styles/general.styles";
import { BuroMssg } from '../../components/BuroMssg/BuroMssg';

const ConstruirMIUC = () => {
	const generalStyle = useGeneralStyles();
	const {
		calcProgress,
		currentView,
		dataProcessController,
		saveData,
		addSelection,
		nextStep,
	} = useNavigation({
		firstPage: MIUC_PROCESS_VIEWS.SELECTION_CREDIT,
		pages: [
			MIUC_PROCESS_VIEWS.CREDIT_OPTIONS,
			// MIUC_PROCESS_VIEWS.CREDIT_QUESTIONS_CONSTRUIR,
			MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO,
			MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT,
			MIUC_PROCESS_VIEWS.SUMMARY_REQUEST,
			MIUC_PROCESS_VIEWS.NEXT_STEPS,
		],
	});
	const [mssgFlag, setMssgFlag] = useState(true);

	const onSelectCredit = (credit: string) => {
		addSelection({
			title: 'Uso de tu crédito para:',
			description: 'Construcción',
		});
		saveData({
			processInfo: {
				credit: 'L3',
			},
			processData: {
				active: 1,
				total: 5
			},
		});
		nextStep();
	};

  useEffect(() => {
    if (dataProcessController.processData.step === undefined){
      addSelection({
        title: 'Crédito seleccionado para:',
        description: 'Construcción',
      });
      saveData({
        processData: {
          active: 1,
          total: 4,
        },
      });
      nextStep(0);
    }
  }, [])

	return (
		<Layout>
			<Box className={generalStyle.container}>
				<Grid container columns={12} spacing={2}>
				{dataProcessController.processInfo?.lineaBC8 && mssgFlag ? (
							<Grid item xs={12} md={12}>
								<Paper>
								  <BuroMssg setFlag={setMssgFlag}/>
								</Paper>
							</Grid>
						) : (
							<>
					<Grid item xs={12} md={8}>
						<Paper>
							{
              //   currentView ===
              //     MIUC_PROCESS_VIEWS.SELECTION_CREDIT ? (
              //     <SelectionCredit
              //       onSelectCredit={onSelectCredit}
              //     />
              // ) : 
                currentView ===
								MIUC_PROCESS_VIEWS.CREDIT_OPTIONS ? (
								<CreditOptions returnMain />
							) 
              // : currentView === MIUC_PROCESS_VIEWS.CREDIT_QUESTIONS_CONSTRUIR ? (
							// 	<CivilStatus />
							// ) 
              : currentView === MIUC_PROCESS_VIEWS.AUTHORIZATION_AND_USER_COMPLETE_INFO ? (
								<BuroForm />
							) : currentView ===
								MIUC_PROCESS_VIEWS.AVAILABLE_CREDIT ? (
								<Products />
							) : currentView ===
								MIUC_PROCESS_VIEWS.SUMMARY_REQUEST ? (
								<SelectionResume />
							) : (
								<></>
							)}
						</Paper>
						{currentView === MIUC_PROCESS_VIEWS.NEXT_STEPS && (
							<FollowSteps guideLink={require('../../../../assets/files/guia-pasos-a-seguir/guia_pasos_a_seguir_LIII_v2_actualizado.pdf')} />
						)}
					</Grid>
					<Grid item xs={12} md={4}>
						{currentView !== MIUC_PROCESS_VIEWS.SUMMARY_REQUEST && currentView !== MIUC_PROCESS_VIEWS.NEXT_STEPS && (
							<Box sx={{ textAlign: 'center' }} mb={2}>
								<ViewPoints />
							</Box>
						)}
						{currentView === MIUC_PROCESS_VIEWS.NEXT_STEPS && (
							<Box sx={{ textAlign: 'center' }} mb={2}>
								<ResumeCard />
							</Box>
						)}
						<Box>
							<FlowCard
								title="Tu selección"
								flow={
									dataProcessController.processInfo
										.selection
								}
								progress={calcProgress()}
							/>
						</Box>
					</Grid>

					</>)}</Grid>
			</Box>
		</Layout>
	);
};

export default ConstruirMIUC;
