import { useState } from 'react';
import {
	Box,
	useMediaQuery,
	FormControlLabel,
	FormControl,
	RadioGroup,
	Radio,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../../NipInfonatel/styles';
import CustomButtom from '../../../components/Button';

import { IAlert } from '../../../interfaces/alert';

import { nipInfonatelAPI } from '../../../api/modules/NipInfonatel';
import ModalLoading from '../../../components/ModalLoading';
import CustomAlert from '../../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';

interface IPersonalData {
	onContinue: () => void;
	isEmail: boolean;
	setIsEmail: (arg: boolean) => void;
	blockNip: boolean;
	typeInformation: number;
}

const HalfUnlock = (props: IPersonalData) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const { onContinue, isEmail, setIsEmail, typeInformation } = props;
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'error',
	});
	const navigate = useNavigate();
	const style = useStyles();

	const sendCode = async () => {
		//typeInformation === 2 es para el desbloqueo, se ejecuta en esta vista el envío de código, y 3 es para cambio y se debe enviar codigo
		if (typeInformation === 2 || typeInformation === 3) {
			try {
				setLoading(true);
				setAlert({
					...alert,
					show: false,
				});

				if (isEmail) {
					const { result } = await nipInfonatelAPI.sendEmail();
					if (
						result.description ===
						'Se ha enviado correctamente el correo'
					) {
						onContinue();
					} else {
						setAlert({
							show: true,
							message: result.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				} else {
					const { result } = await nipInfonatelAPI.sendSMS();
					if (result.description === 'Mensaje enviado con exito') {
						onContinue();
					} else {
						setAlert({
							show: true,
							message: result.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				}
			} catch (error: any) {
				setAlert({
					show: true,
					message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
			} finally {
				setLoading(false);
			}
		} else {
			onContinue();
		}
	};

	const textos = [
		'Si quieres bloquear tu NIP Infonatel deberás seleccionar una de las siguientes opciones.',
		'Para desbloquear tu NIP Infonatel selecciona una de las siguientes opciones para recibir un código de confirmación y continuar con tu proceso.',
		'Para cambiar tu NIP Infonatel, deberás seleccionar una de las siguientes opciones, para recibir un código de confirmación y continuar con tu proceso.',
		'Para cancelar tu NIP Infonatel, deberás seleccionar una de las siguientes opciones, para recibir un código de confirmación y continuar con tu proceso.',
	];

	return (
		<>
			<ModalLoading loading={loading} />
			<Box className={style.halfUnlokTextContainer}>
				<div style={{ padding: 0 }} className={style.parrafospace}>
					{textos[typeInformation - 1]}
				</div>

				<Box className={style.unlokNipContainerFormControl}>
					<FormControl>
						<RadioGroup
							aria-labelledby="type"
							name="typeRadio"
							value={isEmail}
							onChange={(e) => {
								setIsEmail(
									e.target.value === 'true' ? true : false
								);
							}}
						>
							<FormControlLabel
								value={false}
								label="Por mensaje a tu celular"
								control={<Radio />}
							/>
							<FormControlLabel
								value={true}
								label="Por correo electrónico"
								control={<Radio />}
							/>
						</RadioGroup>
					</FormControl>
				</Box>
			</Box>
			<Box className={style.lookConfirmationAlert}>
				<div style={{ width: '100%' }}>
					<CustomAlert
						message={alert.message}
						severity={alert.severity}
						show={alert.show}
					/>
				</div>
			</Box>
			<Box className={style.containerButtonsEnd}>
				<Box margin={'20px 10px'} width={matches ? '80%' : 250}>
					<CustomButtom
						label={'Salir'}
						onClick={() => navigate('/')}
						variant="outlined"
						styles={{ height: '40px' }}
					/>
				</Box>
				<Box margin={'20px 10px'} width={matches ? '80%' : 250}>
					<CustomButtom
						label={'Continuar'}
						onClick={() => sendCode()}
						variant="solid"
					/>
				</Box>
			</Box>
		</>
	);
};

export default HalfUnlock;
