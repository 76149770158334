import { useSelector } from "react-redux";
import { IObtenerCatRes, IProductMonto } from "../../../interfaces/creditRequest";
import { encodeAllJSONData } from "../../../utils/encrypt";
import { useProductTableMIUC } from "./useProductTableMIUC";
import { RootState } from "../../../store";
import { isEmpty } from "../../../utils/validators";
import { apiMIUC } from "../../../api/modules/MIUC";
import { IMainResponse } from "../../../interfaces/mainResponse";
import { isNumberFloat } from "../../../utils/expressions";

export const useCats = () => {
  const { getDataCat } = useProductTableMIUC();
	const { user } = useSelector((state: RootState) => state.session);
	const { dataProcessController } = useSelector((state: RootState) => state.miuc);

	const { creditType } = dataProcessController.processInfo;

  const getAllCats = async (
		productsFlag: IProductMonto[],
    Etc?: boolean,
	): Promise<IProductMonto[]> => {
    // throw new Error('Testing error');
		try {
			let productsPromise: Promise<IMainResponse<IObtenerCatRes>>[] = [];
			for (let index = 0; index < productsFlag.length; index++) {
				const element = productsFlag[index];
				const data = getDataCat(element, false);
				const ENCODED_DATA = await encodeAllJSONData(
					{
						data: JSON.stringify({
							...data,
							conyuge: data.conyuge,
              monEco: !Etc ? 0 : Number(element.mmcMon) + Number(data.monEco)
						}),
					},
					user?.k || ''
				);
				if (
					!isEmpty(dataProcessController?.conyuge) &&
					creditType !== 'I' && !!creditType
				) {
					const dataConyuge = getDataCat(element, true);
          // console.log(dataConyuge)
					const ENCODED_DATACONYUGUE = await encodeAllJSONData(
						{
							data: JSON.stringify({
								...dataConyuge,
								conyuge: dataConyuge.conyuge,
							}),
						},
						user?.k || ''
					);
					productsPromise.push(apiMIUC.obtenerCAT(ENCODED_DATA));
					productsPromise.push(
						apiMIUC.obtenerCAT(ENCODED_DATACONYUGUE)
					);
				} else {
					productsPromise.push(apiMIUC.obtenerCAT(ENCODED_DATA));
				}
			}
			const response = await Promise.all(productsPromise);

			if (response.length > 0) {
				const productsWithCat: IProductMonto[] = [];
				(response || []).forEach((cat) => {
					const product: any = productsFlag.find(
						(item) =>
							item.producto === cat?.data?.claveProducto
					);
					if (!isEmpty(product)) {
						let index: number = productsWithCat.findIndex(
							(item) => item.producto === product?.producto
						);
						let productsWithCatFlag = { ...product };
						if (index > -1) {
							productsWithCatFlag = {
								...(productsWithCat[index] || {}),
							};
						}
            const catVal = cat.data.cat;
            const catCnyVal = cat.data.catCny;
            const replaceCat = (Number(catVal) > 0) || !isNumberFloat(catVal)
            const replaceCatCny = (Number(catCnyVal) > 0) || !isNumberFloat(catCnyVal)

						productsWithCatFlag = {
							...productsWithCatFlag,
							cat: replaceCat
                ? catVal
                : productsWithCatFlag.cat || '0',
							catCny: replaceCatCny
								? catCnyVal
								: productsWithCatFlag.catCny || '0',
						};

						if (index > -1) {
							productsWithCat.splice(
								index,
								1,
								productsWithCatFlag
							);
						} else {
							productsWithCat.push({
								...productsWithCatFlag,
							} as IProductMonto);
						}
					}
				});
				if (!isEmpty(productsWithCat)) {
					return productsWithCat;
				} else {
					return productsFlag;
				}
			}
			return productsFlag;
		} catch (error: any) {
      return productsFlag;
    }
	};

  return {
    getAllCats
  }
}

